import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { SettingsScoringAgeAndSexEvaluationTab } from 'src/pages/Settings/Scoring/Tabs/SettingsScoringAgeAndSexEvaluationTab';
import { SettingsScoringCapitalEvaluationTab } from 'src/pages/Settings/Scoring/Tabs/SettingsScoringCapitalEvaluationTab';
import { SettingsScoringCurrentLoansEvaluationTab } from 'src/pages/Settings/Scoring/Tabs/SettingsScoringCurrentLoansEvaluationTab';
import { SettingsScoringEducationLevelEvaluationTab } from 'src/pages/Settings/Scoring/Tabs/SettingsScoringEducationLevelEvaluationTab';
import { SettingsScoringLoanHistoryEvaluationTab } from 'src/pages/Settings/Scoring/Tabs/SettingsScoringLoanHistoryEvaluationTab';
import { SettingsScoringMonthlyIncomeDtiEvaluationTab } from 'src/pages/Settings/Scoring/Tabs/SettingsScoringMonthlyIncomeDtiEvaluationTab';
import { SettingsScoringWorkingStabilityEvaluationTab } from 'src/pages/Settings/Scoring/Tabs/SettingsScoringWorkingStabilityEvaluationTab';

export const SettingsScoringPage = observer(() => {
    const { t } = useTranslation();

    const tabs: TabDefinition[] = [
        {
            title: t('Page.SettingsScoring.Tabs.AgeAndSexEvaluation'),
            tabKey: 'age-and-sex-evaluation',
            Component: <SettingsScoringAgeAndSexEvaluationTab />,
        },
        {
            title: t('Page.SettingsScoring.Tabs.EducationLevelEvaluation'),
            tabKey: 'education-level-evaluation',
            Component: <SettingsScoringEducationLevelEvaluationTab />,
        },
        {
            title: t('Page.SettingsScoring.Tabs.LoanHistoryEvaluation'),
            tabKey: 'loan-history-evaluation',
            Component: <SettingsScoringLoanHistoryEvaluationTab />,
        },
        {
            title: t('Page.SettingsScoring.Tabs.CurrentLoansEvaluation'),
            tabKey: 'current-loans-evaluation',
            Component: <SettingsScoringCurrentLoansEvaluationTab />,
        },
        {
            title: t('Page.SettingsScoring.Tabs.CapitalEvaluation'),
            tabKey: 'capital-evaluation',
            Component: <SettingsScoringCapitalEvaluationTab />,
        },
        {
            title: t('Page.SettingsScoring.Tabs.WorkingStabilityEvaluation'),
            tabKey: 'working-stability-evaluation',
            Component: <SettingsScoringWorkingStabilityEvaluationTab />,
        },
        {
            title: t('Page.SettingsScoring.Tabs.MonthlyIncomeDtiEvaluation'),
            tabKey: 'monthly-income-with-dti-evaluation',
            Component: <SettingsScoringMonthlyIncomeDtiEvaluationTab />,
        },
    ];

    return (
        <BasePage title={t('Page.SettingsScoring.Title')}>
            <Tabs tabs={tabs} />
        </BasePage>
    );
});
