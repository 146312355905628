import { applicationsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ApplicationPaymentScheduleStoreClass extends BasicStore<any> {
    constructor(private applicationId: number) {
        super();
    }

    api: BasicStoreApi<any, any, any, any> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.getPaymentSchedule1({ id: this.applicationId }),
            );

            this.loanAmount = result.loanAmount;
            this.termInMonths = result.termInMonths;

            return result.schedulePeriods || [];
        },
    };

    loanAmount?: number;

    termInMonths?: number;
}

export const ApplicationPaymentScheduleStore = new EntityProvider(
    ApplicationPaymentScheduleStoreClass,
);
