/* eslint-disable sonarjs/cognitive-complexity */
import { ConfigProvider, Form, FormItemProps, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Spinner } from 'src/components/Spinner/Spinner';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

export type SelectFieldProps = FormItemProps &
    SelectProps & {
        store?: BasicStore<any>;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        convertListItemToOption?: (item?: any) => DefaultOptionType;
        searchValueAsOption?: boolean;
    };

export const SelectField = observer(
    ({
        name,
        label,
        options,
        allowClear = true,
        showArrow = true,
        mode,
        disabled,
        store,
        convertListItemToOption,
        onChange,
        showSearch,
        onSearch,
        searchValueAsOption,
        optionFilterProp = 'label',
        children,
        ...props
    }: SelectFieldProps) => {
        const [searchValue, setSearchValue] = useState<string>(
            showSearch ? props.value : '',
        );
        const isLoading = store?.listLoader.isLoading;

        useEffect(() => {
            if (store && !store.listLoader.isLoaded) {
                store.loadList();
                if (!convertListItemToOption) {
                    console.error(
                        'No callback function getOptionsList. Please use the function to render options',
                    );
                }
            }

            return () => {
                // store && store.listLoader.reset();
            };
        }, []);

        const optionList = useMemo(() => {
            const storeOptionsList: DefaultOptionType[] =
                store?.list.length && convertListItemToOption
                    ? store?.list.map(convertListItemToOption)
                    : [];
            const result = [...(options || storeOptionsList)];

            if (searchValueAsOption && searchValue) {
                result.push({ label: searchValue, value: searchValue });
            }

            return result;
        }, [options, store?.list, searchValueAsOption, searchValue]);

        return (
            <ConfigProvider
                renderEmpty={isLoading ? isLoadingRenderEmpty : undefined}
            >
                <Form.Item {...props} name={name} label={label}>
                    <StyledSelect
                        loading={isLoading}
                        allowClear={!isLoading && allowClear}
                        showArrow={showArrow}
                        mode={mode}
                        disabled={disabled}
                        onChange={onChange as any}
                        showSearch={!isLoading && showSearch}
                        onSearch={(value) => {
                            showSearch && setSearchValue(value);
                            onSearch?.(value);
                        }}
                        optionFilterProp={optionFilterProp}
                        options={children ? undefined : optionList}
                        getPopupContainer={() =>
                            document.querySelector('.ant-modal-wrap') ||
                            document.querySelector('#layout-content') ||
                            document.body
                        }
                        dropdownRender={(originNode) => {
                            const dataTest = `select-menu-${name}`;

                            return <div data-test={dataTest}>{originNode}</div>;
                        }}
                    >
                        {children}
                    </StyledSelect>
                </Form.Item>
            </ConfigProvider>
        );
    },
);

export const getOptionsFromEnum = (
    i18nKeyPrefix: string,
    values: Record<string, any>,
): DefaultOptionType[] => {
    return Object.values(values).map((value) => ({
        label: <Trans i18nKey={`${i18nKeyPrefix}.${value}`} />,
        value,
        'data-test': <Trans i18nKey={`${i18nKeyPrefix}.${value}`} />,
    }));
};

const isLoadingRenderEmpty = () => (
    <Wrapper>
        <Spinner />
    </Wrapper>
);

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
`;

const StyledSelect = styled(Select)<{ props?: SelectProps }>`
    .ant-select-selection-item {
        opacity: ${(props) => props?.loading && 0};
    }
`;
