import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { Collateral } from 'src/utils/CollateralsHelper';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const store = CollateralsStore;

type EditCollateralEvaluationDialogFnProps = ModalRendererProps<{
    collateral?: Collateral;
}>;

const EditCollateralEvaluationDialogFn = observer(
    ({ collateral, ...props }: EditCollateralEvaluationDialogFnProps) => {
        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );

        return (
            <Modal
                isLoading={store.updateItemLoader.isLoading}
                title={t('Dialog.EditCollateralEvaluation.Title')}
                initialValues={collateral?.evaluation}
                onFinish={async (values) => {
                    await store.update(Number(collateral?.id), {
                        ...values,
                    });
                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }
                    if (!store.updateItemLoader.hasError) {
                        CollateralsStore.loadItem(collateral?.id);
                    }
                }}
                width={1000}
                formProps={{ fields }}
                {...props}
            >
                <Row gutter={16}>
                    <HiddenField
                        name="type"
                        initialValue="COLLATERAL_EVALUATION"
                    />
                    <Col xs={8}>
                        <NumberField
                            name="marketValue"
                            label={t(
                                'Dialog.EditCollateralEvaluation.MarketValue',
                            )}
                            thousandDivider
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <NumberField
                            name="rmValue"
                            label={t('Dialog.EditCollateralEvaluation.RmValue')}
                            thousandDivider
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <NumberField
                            name="mutuallyAgreedValuation"
                            label={t(
                                'Dialog.EditCollateralEvaluation.MutuallyAgreedValuation',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 100),
                            ]}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const EditCollateralEvaluationDialog = addModalRenderer(
    EditCollateralEvaluationDialogFn,
);
