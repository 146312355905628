import { t } from 'i18next';
import { ActionType, User } from 'src/generated-api-client';
import { ChangeActivityStatusDialog } from 'src/pages/UserDetailsPage/Components/ChangeActivityStatusDialog';
import { UsersStore } from 'src/stores/UsersStore/UsersStore';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getUserActions = (user?: User): ActionDefinition[] => {
    return [...getUIActions(user), ...getServerActions(user)];
};

const getUIActions = (_user?: User): ActionDefinition[] => {
    return [];
};

const getServerActions = (user?: User): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(user),
        user?.actions,
    );
};

export const getRegisteredServerActions = (
    user?: User,
): Array<ActionDefinition> => {
    return [
        {
            key: ActionType.USER_ACTIVATE,
            label: t('User.Actions.Activate'),
            onClick: () => {
                UsersStore.activateUser(Number(user?.id));
            },
        },
        {
            key: ActionType.USER_DEACTIVATE,
            label: t('User.Actions.Deactivate'),
            onClick: () => {
                UsersStore.deactivateUser(Number(user?.id));
            },
        },
        {
            key: ActionType.USER_RESEND_REGISTRATION_LINK,
            label: t('User.Actions.ResendRegistrationLink'),
            onClick: () => {
                UsersStore.resendRegistrationLink(Number(user?.id));
            },
        },
        {
            key: ActionType.USER_ACTIVITY_UPDATE,
            label: t('User.Actions.ChangeActivityStatus'),
            onClick: () => {
                ChangeActivityStatusDialog.render({ userId: user?.id });
            },
        },
    ];
};
