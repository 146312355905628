import { notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { PhoneField } from 'src/components/Form/PhoneField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = IndividualsStore;

export const IndividualCreateDialogFn = observer(
    ({ ...props }: ModalRendererProps) => {
        useEffect(() => {
            return () => {
                store.createItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.createItemLoader.errorData,
        );

        return (
            <Modal
                title={t('Page.IndividualCreate.Title')}
                isLoading={store.createItemLoader.isLoading}
                showOnlyLoading={store.createItemLoader.countLoads === 0}
                onFinish={async (values) => {
                    const item = await store.create(values);

                    if (store.createItemLoader.hasError) {
                        throw new Error(
                            store.createItemLoader.errorData.detail,
                        );
                    }

                    if (!store.createItemLoader.hasError) {
                        notification.success({
                            message: t('Page.IndividualCreate.Message.Success'),
                        });

                        const targetUrl = UrlHelper.getTargetUrl(
                            RoutePaths.individualDetails,
                            { individualId: item?.id },
                        );

                        Router.navigate(targetUrl);
                    }
                }}
                formProps={{ fields }}
                {...props}
            >
                <PhoneField
                    name="phoneNumber"
                    label={t('Page.IndividualCreate.PhoneNumber')}
                    rules={[ValidationRules.required]}
                />
            </Modal>
        );
    },
);

export const IndividualCreateDialog = addModalRenderer(
    IndividualCreateDialogFn,
);
