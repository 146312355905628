import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { ProvisionRatesEditDialog } from 'src/pages/Settings/ManageProvisionRatesPage/ProvisionRatesEditDialog';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { ManageProvisionRatesStore } from 'src/stores/ManageProvisionRatesStore/ManageProvisionRatesStore';

const store = ManageProvisionRatesStore;

const getMenuActions = () => {
    return [
        {
            key: 'edit',
            label: t('ManageProvisionRates.Actions.Edit'),
            onClick: () => {
                ProvisionRatesEditDialog.render();
            },
            disabled: !AppUser.hasPermissions(
                UserPermission.LoanSettingsProvisionRatesEdit,
            ),
        },
    ];
};

export const ManageProvisionRatesPage = observer(() => {
    useEffect(() => {
        store.loadItem();

        return () => {
            store.itemLoader.reset();
        };
    }, []);

    const settings = store.currentItem;
    const menuActions = getMenuActions().filter((action) => !action.disabled);

    return (
        <BasePage title={t('Page.ManageProvisionRates.Title')}>
            <Card
                title={t(
                    'Page.ManageProvisionRates.Card.Title',
                    'Provision rates',
                )}
                menuActions={menuActions}
                isLoading={store.itemLoader.isLoading}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            label={t('Page.ManageProvisionRates.NORMAL')}
                            value={settings?.NORMAL}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            label={t('Page.ManageProvisionRates.STRESSED')}
                            value={settings?.STRESSED}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            label={t('Page.ManageProvisionRates.SUB_STANDARD')}
                            value={settings?.SUB_STANDARD}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            label={t('Page.ManageProvisionRates.DOUBTFUL')}
                            value={settings?.DOUBTFUL}
                            format="percent"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            label={t('Page.ManageProvisionRates.LOSS')}
                            value={settings?.LOSS}
                            format="percent"
                        />
                    </Col>
                </Row>
            </Card>
        </BasePage>
    );
});
