import { useParams } from 'react-router-dom';

export function useLoanId() {
    const params = useParams<{ loanId: string }>();

    return {
        loanId: params?.loanId,
        hasLoanId: Boolean(params?.loanId),
    };
}
