import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { CustomerType, DebtCollection } from 'src/generated-api-client';
import { CollectionStatus } from 'src/pages/CollectionDetailsPage/Components/CollectionStatus';
import { UrlHelper } from 'src/utils/UrlHelper';

export type CollectionDetailsBriefProps = {
    collection?: DebtCollection;
};

export const CollectionDetailsBrief = observer(
    ({ collection }: CollectionDetailsBriefProps) => {
        const individualDetailsUrl = UrlHelper.getTargetUrl(
            RoutePaths.individualDetails,
            { individualId: collection?.customer?.id },
        );

        const legalDetailsUrl = UrlHelper.getTargetUrl(
            RoutePaths.legalDetails,
            { legalId: collection?.customer?.id },
        );

        const customerUrl =
            collection?.customer?.type === CustomerType.INDIVIDUAL
                ? individualDetailsUrl
                : legalDetailsUrl;

        const userDetailsUrl = UrlHelper.getTargetUrl(RoutePaths.userDetails, {
            userId: collection?.debtCollector?.id,
        });

        return (
            <Brief>
                <DataView
                    value={collection?.customer?.name}
                    label={t('Page.CollectionDetails.CustomerName')}
                    format="link-internal"
                    formatterProps={{
                        to: customerUrl,
                    }}
                />
                <DataView
                    value={
                        collection?.customer?.contactDetails
                            ?.customerPhoneNumbers?.[0]?.phoneNumber
                    }
                    label={t('Page.CollectionDetails.PhoneNumber')}
                />
                <DataView
                    value={collection?.customer?.registrationNumber}
                    label={t('Page.CollectionDetails.RegistrationNumber')}
                />
                <DataView
                    value={collection?.debtCollector?.name}
                    label={t('Page.CollectionDetails.DebtCollector')}
                    format="link-internal"
                    formatterProps={{
                        to: userDetailsUrl,
                    }}
                />
                <DataView
                    value={collection?.level}
                    label={t('Page.CollectionDetails.Level')}
                    format={(value) => (
                        <EnumView enumName="COLLECTION_LEVEL" value={value} />
                    )}
                />
                <DataView
                    value={collection?.status}
                    label={t('Page.CollectionDetails.Status')}
                    format={(value) => <CollectionStatus status={value} />}
                />
            </Brief>
        );
    },
);
