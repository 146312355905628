import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FormItemGroup } from 'src/components/Form/FormItemGroup/FormItemGroup';
import { HiddenField } from 'src/components/Form/HiddenField';
import { ImageField } from 'src/components/Form/ImageField';
import { PhoneField } from 'src/components/Form/PhoneField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { TimePickerField } from 'src/components/Form/TimePickerField';
import {
    FileFormats,
    FileSizeUnit,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { AddressFields } from 'src/pages/IndividualDetailsPage/Components/AddressFields';
import { BranchOptionsStore } from 'src/stores/BranchOptionsStore/BranchOptionsStore';
import styled from 'styled-components';

const MINUTE_STEP = 15;

export const ChildBranchFields = observer(() => {
    return (
        <Row gutter={16}>
            <Col xs={8}>
                <Row gutter={16}>
                    <Col xs={24}>
                        <StyledImageField
                            name="logoFile"
                            accept={FileFormats.png}
                            rules={[
                                ValidationRules.maxFileSize(
                                    10,
                                    FileSizeUnit.Mb,
                                ),
                                ValidationRules.fileFormats([FileFormats.png]),
                            ]}
                        />
                    </Col>
                    <Col xs={24}>
                        <Row gutter={16}>
                            <Col xs={24}>
                                <Typography.Title level={5}>
                                    {t('Dialog.CreateChildBranch.WorkingDays')}
                                </Typography.Title>
                            </Col>
                            <Col xs={12}>
                                <EnumSelectField
                                    name={[
                                        'workSchedule',
                                        'workingDays',
                                        'from',
                                    ]}
                                    label={t(
                                        'Dialog.CreateChildBranch.WorkingDaysFrom',
                                    )}
                                    rules={[ValidationRules.required]}
                                    enumName="DAY_OF_WEEK"
                                />
                            </Col>
                            <Col xs={12}>
                                <EnumSelectField
                                    name={['workSchedule', 'workingDays', 'to']}
                                    label={t(
                                        'Dialog.CreateChildBranch.WorkingDaysTo',
                                    )}
                                    rules={[ValidationRules.required]}
                                    enumName="DAY_OF_WEEK"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={16}>
                            <Col xs={24}>
                                <Typography.Title level={5}>
                                    {t('Dialog.CreateChildBranch.WorkingTime')}
                                </Typography.Title>
                            </Col>
                            <Col xs={12}>
                                <TimePickerField
                                    name={[
                                        'workSchedule',
                                        'workingHours',
                                        'from',
                                    ]}
                                    label={t(
                                        'Dialog.CreateChildBranch.WorkingTimeFrom',
                                    )}
                                    rules={[ValidationRules.required]}
                                    minuteStep={MINUTE_STEP}
                                />
                            </Col>
                            <Col xs={12}>
                                <TimePickerField
                                    name={[
                                        'workSchedule',
                                        'workingHours',
                                        'to',
                                    ]}
                                    label={t(
                                        'Dialog.CreateChildBranch.WorkingTimeTo',
                                    )}
                                    rules={[ValidationRules.required]}
                                    minuteStep={MINUTE_STEP}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={16}>
                <Row gutter={16}>
                    <Col xs={12}>
                        <SelectField
                            options={BranchOptionsStore.parentBranchOptions}
                            name="parentBranchId"
                            label={t(
                                'Dialog.CreateChildBranch.ParentBranchName',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={12}>
                        <TextField
                            name="name"
                            label={t(
                                'Dialog.CreateChildBranch.ChildBranchName',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={12}>
                        <TextField
                            name="activityAreas"
                            label={t(
                                'Dialog.CreateChildBranch.AreasOfActivity',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={12}>
                        <TextField
                            name="email"
                            label={t('Dialog.CreateChildBranch.EmailAddress')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <PhoneField
                            name="phone"
                            label={t('Dialog.CreateChildBranch.PhoneNumber')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            name="branchManager"
                            label={t('Dialog.CreateChildBranch.BranchManager')}
                            disabled
                        />
                    </Col>
                    <Col span={24}>
                        <FormItemGroup name="address">
                            <AddressFields hideFamiliarAddress />
                        </FormItemGroup>
                    </Col>
                    <Col xs={12}>
                        <HiddenField name="id" />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
});

const StyledImageField = styled(ImageField)`
    .ant-space {
        width: 100%;
        .ant-space-item:nth-child(1) {
            width: 100%;
        }
    }
    .image-view {
        width: 100%;
        height: 252px;
        border-radius: 8px;
    }
`;
