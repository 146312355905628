import { Col, notification, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { ManageProvisionRatesStore } from 'src/stores/ManageProvisionRatesStore/ManageProvisionRatesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const store = ManageProvisionRatesStore;

export const ProvisionRatesEditDialogFn = observer(
    ({ ...props }: ModalRendererProps) => {
        useEffect(() => {
            store.loadItem();

            return () => {
                store.updateItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );

        const isLoading =
            store.itemLoader.isLoading || store.updateItemLoader.isLoading;

        return (
            <Modal
                title={t('Dialog.ManageProvisionRatesEdit.Title')}
                isLoading={isLoading}
                width={720}
                onFinish={async (values) => {
                    await store.update(0, values);
                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.updateItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.ManageProvisionRatesEdit.Message.Success',
                            ),
                        });
                    }

                    store.loadItem();
                }}
                formProps={{ fields }}
                initialValues={{ setting: store.currentItem }}
                {...props}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <NumberField
                            name={['setting', 'NORMAL']}
                            label={t('Page.ManageProvisionRates.NORMAL')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 100),
                            ]}
                        />
                    </Col>

                    <Col span={12}>
                        <NumberField
                            name={['setting', 'STRESSED']}
                            label={t('Page.ManageProvisionRates.STRESSED')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 100),
                            ]}
                        />
                    </Col>

                    <Col span={12}>
                        <NumberField
                            name={['setting', 'SUB_STANDARD']}
                            label={t('Page.ManageProvisionRates.SUB_STANDARD')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 100),
                            ]}
                        />
                    </Col>

                    <Col span={12}>
                        <NumberField
                            name={['setting', 'DOUBTFUL']}
                            label={t('Page.ManageProvisionRates.DOUBTFUL')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 100),
                            ]}
                        />
                    </Col>

                    <Col span={12}>
                        <NumberField
                            name={['setting', 'LOSS']}
                            label={t('Page.ManageProvisionRates.LOSS')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 100),
                            ]}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const ProvisionRatesEditDialog = addModalRenderer(
    ProvisionRatesEditDialogFn,
);
