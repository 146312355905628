import { Link } from 'react-router-dom';
import logoImg from 'src/assets/left-side-logo.png';
import { RoutePaths } from 'src/core/router/RoutePaths';
import styled from 'styled-components';

export const Logo = () => {
    return (
        <LogoWrapper>
            <Link to={RoutePaths.index}>
                <img src={logoImg} alt="logo" />
            </Link>
        </LogoWrapper>
    );
};

const LogoWrapper = styled.div`
    margin-top: 30px;
    width: 100%;
    position: relative;
    padding: 0 20px;

    img {
        width: 100%;
    }
`;
