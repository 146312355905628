import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';

export const EquipmentCollateralGeneralInfoUpdateVariables = observer(() => {
    return (
        <Row gutter={16}>
            <HiddenField name="type" />
            <Col xs={6}>
                <EnumSelectField
                    name="equipmentType"
                    enumName="EQUIPMENT_TYPE"
                    label={t(
                        'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.ApproveConditions.EquipmentType',
                    )}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="name"
                    label={t(
                        'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.ApproveConditions.Name',
                    )}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="country"
                    label={t(
                        'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.ApproveConditions.Country',
                    )}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="brandName"
                    label={t(
                        'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.ApproveConditions.BrandName',
                    )}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="model"
                    label={t(
                        'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.ApproveConditions.Model',
                    )}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="serialNumber"
                    label={t(
                        'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.ApproveConditions.SerialNumber',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <DatePickerField
                    name="manufacturedDate"
                    label={t(
                        'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.ApproveConditions.ManufacturedDate',
                    )}
                />
            </Col>
            <Col xs={6}>
                <DatePickerField
                    name="importedDate"
                    label={t(
                        'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.ApproveConditions.ImportedDate',
                    )}
                />
            </Col>
            <Col xs={6}>
                <EnumSelectField
                    name="ownershipType"
                    enumName="COLLATERAL_OWNERSHIP_TYPE"
                    label={t(
                        'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.ApproveConditions.OwnershipType',
                    )}
                />
            </Col>
        </Row>
    );
});
