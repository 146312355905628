import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { TaskDecisionType } from 'src/generated-api-client';
import styled from 'styled-components';

export const N22EnterIATUserDecisionReturnVariables = observer(() => {
    return (
        <>
            <StyledText>
                {t('Dialog.Return.N22EnterIATUserDecision.Description')}
            </StyledText>
            <HiddenField
                name="type"
                initialValue={TaskDecisionType.N2_2_REQUEST_ADJUSTMENTS}
            />
            <TextAreaField
                name="comment"
                label={t('Dialog.Return.N22EnterIATUserDecision.Comment')}
                rules={[ValidationRules.required]}
            />
        </>
    );
});

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 16px;
    color: #4d6082;
    margin-bottom: 20px;
`;
