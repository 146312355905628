import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FilterForm } from 'src/components/Form/FilterForm';
import { TextField } from 'src/components/Form/TextField';
import { DateHelper } from 'src/utils/DateHelper';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

type CEOListFilterFormProps = {
    store: BasicStore<any>;
};
export const CEOListFilterForm = observer(
    ({ store }: CEOListFilterFormProps) => {
        return (
            <FilterForm
                store={store}
                convertFilterCriteriaToFormValues={
                    convertFilterCriteriaToFormValues
                }
                convertFormValuesToFilterCriteria={
                    convertFormValuesToFilterCriteria
                }
            >
                <TextField
                    name="customerName"
                    label={t(
                        'Dialog.LegalEntity.UpdateCEOInformation.Filter.Name',
                    )}
                />
                <TextField
                    name="registrationNumber"
                    label={t(
                        'Dialog.LegalEntity.UpdateCEOInformation.Filter.RegistrationNumber',
                    )}
                />
                <TextField
                    name="civilId"
                    label={t(
                        'Dialog.LegalEntity.UpdateCEOInformation.Filter.CivilId',
                    )}
                />
                <EnumSelectField
                    name="citizenship"
                    label={t(
                        'Dialog.LegalEntity.UpdateCEOInformation.Filter.Citizenship',
                    )}
                    enumName="CITIZENSHIP"
                />
                <DatePickerField
                    name="birthDate"
                    label={t(
                        'Dialog.LegalEntity.UpdateCEOInformation.Filter.BirthDate',
                    )}
                    disabledDate={(current) => current.isAfter(moment())}
                />
            </FilterForm>
        );
    },
);

const convertFilterCriteriaToFormValues = (values: any) => {
    return {
        ...values,
        birthDate: values?.birthDate && moment(values?.birthDate),
    };
};
const convertFormValuesToFilterCriteria = (values: any) => {
    return {
        ...values,
        birthDate: DateHelper.formatISODate(values?.birthDate),
    };
};
