import { Button, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'src/components/Form/Form';
import { HiddenField } from 'src/components/Form/HiddenField';
import { PasswordField } from 'src/components/Form/PssswordField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { useSearchParams } from 'src/hooks/useSeachParams';
import { AuthPage } from 'src/pages/Auth/AuthPage';
import { AuthPageWithMessage } from 'src/pages/Auth/AuthPageWithMessage';
import { PasswordPolicy, passwordRule } from 'src/pages/Auth/PasswordPolicy';
import {
    RecoveryForm,
    RecoveryPasswordStore,
} from 'src/stores/LocalAuth/RecoveryPasswordStore';
import styled from 'styled-components';

export const RecoveryPasswordPage = observer(() => {
    const searchParams = useSearchParams();
    const { code } = searchParams.params;

    const pageTitle = t('Page.Auth.RecoveryPassword.Title');

    useEffect(() => {
        return () => {
            RecoveryPasswordStore.reset();
        };
    }, []);

    useEffect(() => {
        if (code) {
            RecoveryPasswordStore.verifyCode(code as string);
        }
    }, [code]);

    if (!code) {
        return (
            <AuthPageWithMessage
                title={pageTitle}
                message={t('Page.Auth.RecoveryPassword.CodeIsNotProvided')}
                messageType="danger"
            />
        );
    }

    const showError =
        RecoveryPasswordStore.isCodeValidated &&
        !RecoveryPasswordStore.isValidCode;

    if (showError) {
        return (
            <AuthPageWithMessage
                title={pageTitle}
                message={t('Page.Auth.RecoveryPassword.CodeIsNotValid')}
                messageType="danger"
            />
        );
    }

    return (
        <AuthPage
            title={pageTitle}
            isLoading={
                RecoveryPasswordStore.isCodeValidating ||
                RecoveryPasswordStore.isPasswordSetting
            }
        >
            {RecoveryPasswordStore.isValidCode && (
                <Form
                    onFinish={async (values: RecoveryForm) => {
                        await RecoveryPasswordStore.recoveryPassword(values);
                    }}
                >
                    <HiddenField name="code" initialValue={code} />
                    <PasswordField
                        label={t('Page.Auth.RecoveryPassword.SetPassword')}
                        name="password"
                        rules={[ValidationRules.requiredPassword, passwordRule]}
                    />
                    <PasswordPolicy />

                    <StyledSpace direction="vertical" size={16}>
                        <Button type="primary" htmlType="submit" block>
                            {t('Page.Auth.RecoveryPassword.Submit')}
                        </Button>
                        <Link to={RoutePaths.authLogin}>
                            {t('Page.Auth.GoToLoginPage')}
                        </Link>
                    </StyledSpace>
                </Form>
            )}
        </AuthPage>
    );
});

const StyledSpace = styled(Space)`
    width: 100%;
`;
