import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { User } from 'src/generated-api-client';
import { UsersStatus } from 'src/pages/UsersPage/UsersStatus';

export type UserDetailsBriefProps = { user?: User };
export const UserDetailsBrief = observer(({ user }: UserDetailsBriefProps) => {
    return (
        <Brief>
            <Row gutter={16}>
                <Col span={6}>
                    <DataView
                        value={user?.status}
                        label={t('Page.UserDetails.Status')}
                        format={(value) => <UsersStatus status={value} />}
                        data-test="brief-status"
                    />
                </Col>

                <Col span={6}>
                    <DataView
                        value={user?.surname}
                        label={t('Page.UserDetails.Surname')}
                        data-test="brief-surname"
                    />
                </Col>
                <Col span={6}>
                    <DataView
                        value={user?.name}
                        label={t('Page.UserDetails.Name')}
                        data-test="brief-name"
                    />
                </Col>
                <Col span={6}>
                    <DataView
                        value={user?.phone}
                        label={t('Page.UserDetails.Phone')}
                        data-test="brief-phone"
                    />
                </Col>
            </Row>
        </Brief>
    );
});
