export enum AuthErrorType {
    UserAlreadyBlocked = 'userAlreadyBlocked',
    UserNotBlocked = 'userNotBlocked',
    TokenNotFound = 'tokenNotFound',
    TokenExpired = 'tokenExpired',
    BadCredentials = 'badCredentials',
    AccessDenied = 'accessDenied',
    InvalidCode = 'invalidCode',
    PasswordExpired = 'passwordExpired',
    UserIsBlocked = 'userBlocked',
    IPAddressNotFound = 'ipAddressNotFound',
}

const authErrorTypes: Array<AuthErrorType> = [
    AuthErrorType.AccessDenied,
    AuthErrorType.BadCredentials,
    AuthErrorType.InvalidCode,
    AuthErrorType.PasswordExpired,
    AuthErrorType.TokenExpired,
    AuthErrorType.TokenNotFound,
    AuthErrorType.UserAlreadyBlocked,
    AuthErrorType.UserNotBlocked,
    AuthErrorType.UserIsBlocked,
    AuthErrorType.IPAddressNotFound,
];

export type BaseAuthError = {
    type?: AuthErrorType;
};

export function isAuthError(error: any): error is BaseAuthError {
    return (
        error &&
        typeof error.type === 'string' &&
        authErrorTypes.includes(error.type)
    );
}
