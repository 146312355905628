import { t } from 'i18next';
import { ActionType } from 'src/generated-api-client';
import { CreateCollateralInsuranceDialog } from 'src/pages/CollateralDetailsPage/Components/CollateralInsurance/Dialogs/CreateCollateralInsuranceDialog';
import { Collateral } from 'src/utils/CollateralsHelper';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getCollateralInsuranceActions = (collateral: Collateral) => {
    return [...getUIActions(), ...getServerActions(collateral)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (collateral: Collateral): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(collateral),
        collateral.actions,
    );
};

const getRegisteredServerActions = (
    collateral: Collateral,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.INSURANCE_ADD,
            label: t('Insurance.Actions.Create'),
            onClick: () => {
                CreateCollateralInsuranceDialog.render({
                    collateralId: collateral?.id,
                });
            },
        },
    ];
};
