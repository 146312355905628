/* eslint-disable max-lines */
import { Button, Col, Form as AntdForm, notification, Row, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Card } from 'src/components/Card/Card';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { Form } from 'src/components/Form/Form';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { LegalEntitiesStore } from 'src/stores/LegalEntitiesStore/LegalEntitiesStore';
import { LegalIndustryOptionsStore } from 'src/stores/LegalIndustryOptionsStore/LegalIndustryOptionsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = LegalEntitiesStore;

export const LegalEntityCreatePage = observer(() => {
    const { t } = useTranslation();
    const fields = FormHelper.getFieldDataItems(
        store.createItemLoader.errorData,
    );

    const [form] = AntdForm.useForm();
    const industry = AntdForm.useWatch('industry', form);
    const isIndustryActivityTypesOptionsLoading =
        LegalIndustryOptionsStore.listLoader.isLoading;

    useEffect(() => {
        LegalIndustryOptionsStore.loadList();

        return () => {
            LegalIndustryOptionsStore.listLoader.reset();
        };
    }, []);

    useEffect(() => {
        const isTouchedIndustry = form.isFieldsTouched(['industry']);
        if (isTouchedIndustry) {
            form.setFields([{ name: 'activityId', value: undefined }]);
        }
    }, [industry]);

    const { industryOptions } = LegalIndustryOptionsStore;
    const industryActivityTypes =
        LegalIndustryOptionsStore.industryActivityTypesOptions(industry);

    return (
        <BasePage title={t('Page.LegalEntityCreate.Title')}>
            <Form
                onFinish={async (values) => {
                    const item = await store.create(values);
                    if (!store.createItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Page.LegalEntityCreate.Message.Success',
                            ),
                        });
                        const targetUrl = UrlHelper.getTargetUrl(
                            RoutePaths.legalDetails,
                            { legalId: item?.id },
                        );

                        Router.navigate(targetUrl);
                    }
                }}
                onReset={() => {
                    Router.navigate(RoutePaths.legalEntities);
                }}
                form={form}
                fields={fields}
            >
                <Card title={t('Page.LegalEntityCreate.Title.GeneralInfo')}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <TextField
                                name="name"
                                label={t('Page.LegalEntityCreate.CompanyName')}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col span={8}>
                            <EnumSelectField
                                enumName="ENTERPRISE_TYPE"
                                name="enterpriseType"
                                label={t(
                                    'Page.LegalEntityCreate.EnterpriseType',
                                )}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col span={8}>
                            <DatePickerField
                                name="establishedDate"
                                label={t(
                                    'Page.LegalEntityCreate.EstablishedDate',
                                )}
                                rules={[ValidationRules.required]}
                                disabledDate={(date) => {
                                    return date.isAfter(moment().endOf('day'));
                                }}
                            />
                        </Col>
                        <Col span={8}>
                            <TextField
                                name="stateRegistrationNumber"
                                label={t('Page.LegalEntityCreate.StateRegNum')}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col span={8}>
                            <TextField
                                name="registrationNumber"
                                label={t('Page.LegalEntityCreate.RegNum')}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.minRegNumLength,
                                ]}
                            />
                        </Col>
                        <Col span={8}>
                            <TextField
                                name="taxNumber"
                                label={t('Page.LegalEntityCreate.TaxNumber')}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col span={8}>
                            <SelectField
                                name="industry"
                                options={industryOptions}
                                label={t('Page.LegalEntityCreate.Industry')}
                                rules={[ValidationRules.required]}
                                showSearch
                            />
                        </Col>
                        <Col span={8}>
                            <SelectField
                                name="activityId"
                                label={t('Page.LegalEntityCreate.ActivityType')}
                                options={industryActivityTypes}
                                loading={isIndustryActivityTypesOptionsLoading}
                                disabled={isIndustryActivityTypesOptionsLoading}
                                rules={[ValidationRules.required]}
                                showSearch
                            />
                        </Col>
                        <Col span={8}>
                            <EnumSelectField
                                enumName="INCOME_SOURCE"
                                name="incomeSource"
                                label={t('Page.LegalEntityCreate.IncomeSource')}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col span={8}>
                            <NumberField
                                name="numberOfEmployees"
                                label={t(
                                    'Page.LegalEntityCreate.NumberOfEmployees',
                                )}
                                min={1}
                                onlyInteger
                                rules={[ValidationRules.greaterThanZero]}
                            />
                        </Col>
                        <Col span={8}>
                            <NumberField
                                name="numberOfBranches"
                                label={t(
                                    'Page.LegalEntityCreate.NumberOfBranches',
                                )}
                                min={1}
                                onlyInteger
                                rules={[ValidationRules.greaterThanZero]}
                            />
                        </Col>
                        <Col span={8}>
                            <EnumSelectField
                                enumName="INVESTMENT_TYPE"
                                name="investmentType"
                                label={t(
                                    'Page.LegalEntityCreate.InvestmentType',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <EnumSelectField
                                enumName="RATING_ORGANISATION"
                                name="ratingOrganisation"
                                label={t('Page.LegalEntityCreate.RatingOrg')}
                            />
                        </Col>
                        <Col span={8}>
                            <TextField
                                name="rating"
                                label={t('Page.LegalEntityCreate.Rating')}
                            />
                        </Col>
                    </Row>
                </Card>

                <Row gutter={16}>
                    <Col span={24}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                {t('Page.LegalEntityCreate.Btn.Save')}
                            </Button>
                            <Button type="default" htmlType="reset">
                                {t('Page.LegalEntityCreate.Btn.Reset')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </BasePage>
    );
});
