import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import { ExternalLinkIcon } from 'src/components/Icons/Icons';
import { MenuLink } from 'src/generated-api-client';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import styled from 'styled-components';

export const ExternalLinksMenu = observer(() => {
    const links = SettingsStore.menuLinks || [];

    return (
        <Wrapper>
            {links.map((link: MenuLink) => {
                const label =
                    (link.l10n as any)?.[i18next.language] || link.url;

                return (
                    <StyledLink key={link.url} target="_blank" href={link.url}>
                        <ExternalLinkIcon />
                        <span className="label">{label}</span>
                    </StyledLink>
                );
            })}
        </Wrapper>
    );
});

const Wrapper = styled.div`
    width: 100%;
`;

const StyledLink = styled.a`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    height: 68px;
    margin: 6px 0;
    padding: 0 16px 0 24px;
    cursor: pointer;
    background: #3e3f49;

    color: #d66a1b;
    transition: color 0.3s;

    .label {
        overflow: hidden;
        text-overflow: ellipsis;
        transition: color 0.3s;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffffa5;
    }

    &:hover {
        text-decoration: none;
        color: #fc842d;

        .label {
            color: #fff;
        }
    }
`;
