import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { SelectField } from 'src/components/Form/SelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { GracePeriod } from 'src/generated-api-client';
import { ProposeActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ProposeActionDialogComponent';
import { ProposeGracePeriodActionStore } from 'src/stores/LoanActionsStores/ProposeGracePeriodActionStore';
import { DateHelper } from 'src/utils/DateHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const asOption = (period: GracePeriod) => ({
    value: period.count,
    label: period.count,
});

const { Text } = Typography;

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

export const ProposeGracePeriodDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ProposeGracePeriodActionStore.getInstance(Number(loanId));
        const title = t('Dialog.ProposeGracePeriod.Title');
        const periodOption = store.initialValues?.gracePeriods.map(asOption);
        const [period, setPeriod] = useState('');

        const changePeriod = (value: any) => {
            if (!value) {
                setPeriod('');

                return;
            }

            const gracePeriod = store.initialValues?.gracePeriods.find(
                (period) => period.count === value,
            );

            setPeriod(
                `${DateHelper.formatDate(
                    gracePeriod?.from as string,
                )} - ${DateHelper.formatDate(gracePeriod?.to as string)}`,
            );
        };

        return (
            <ProposeActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <StyledText>{t('Dialog.ProposeGracePeriod.Text')}</StyledText>
                <DataView
                    value={period}
                    label={t('Dialog.ProposeGracePeriod.IntervalDate')}
                />
                <SelectField
                    options={periodOption}
                    name="count"
                    rules={[ValidationRules.required]}
                    onChange={(value) => changePeriod(value)}
                />
            </ProposeActionDialogComponent>
        );
    },
);

export const ProposeGracePeriodDialog = addModalRenderer(
    ProposeGracePeriodDialogFn,
);

const StyledText = styled(Text)`
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #4d6082;
`;
