import { Col, Form, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Card } from 'src/components/Card/Card';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { RepaymentFrequency, TaskDecisionType } from 'src/generated-api-client';
import { repaymentFrequencyOptions } from 'src/pages/TaskDetailsPage/Components/repaymentFrequencyOptions.config';

export const N18CsDecisionTaskApproveVariables = observer(() => {
    const form = Form.useFormInstance();
    const repaymentFrequency = Form.useWatch('repaymentFrequency');
    const isBiWeeklyRepaymentFrequency =
        repaymentFrequency === RepaymentFrequency.BI_WEEKLY;

    useEffect(() => {
        if (repaymentFrequency && !isBiWeeklyRepaymentFrequency) {
            form.setFields([
                {
                    name: 'secondRepaymentDate',
                    value: undefined,
                },
            ]);
        } else if (repaymentFrequency && isBiWeeklyRepaymentFrequency) {
            form.setFields([{ name: 'repaymentFrequency', value: undefined }]);
        }
    }, [isBiWeeklyRepaymentFrequency]);

    const isRequiredSecondRepaymentDate = isBiWeeklyRepaymentFrequency
        ? ValidationRules.required
        : {};

    return (
        <Col span={24}>
            <Card title={t('Task.Form.N18CsDecisionTask.FormDescription')}>
                <Row gutter={[16, 16]}>
                    <HiddenField
                        name="type"
                        initialValue={TaskDecisionType.N1_8_ADD_LOAN_CONDITIONS}
                    />
                    <Col span={12}>
                        <NumberField
                            name="amount"
                            label={t('Task.Form.N18CsDecisionTask.Amount')}
                            thousandDivider
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectField
                            name="repaymentFrequency"
                            label={t(
                                'Task.Form.N18CsDecisionTask.RepaymentFrequency',
                            )}
                            options={repaymentFrequencyOptions}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <DatePickerField
                            name="firstRepaymentDate"
                            label={t(
                                'Task.Form.N18CsDecisionTask.FirstRepaymentDate',
                            )}
                            rules={[ValidationRules.required]}
                            disabledDate={(date) => {
                                return date.isBefore(moment().endOf('day'));
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <DatePickerField
                            name="secondRepaymentDate"
                            label={t(
                                'Task.Form.N18CsDecisionTask.SecondRepaymentDate',
                            )}
                            rules={[isRequiredSecondRepaymentDate]}
                            disabled={!isBiWeeklyRepaymentFrequency}
                            disabledDate={(date) => {
                                return date.isBefore(moment().endOf('day'));
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="monthlyInterestRate"
                            label={t(
                                'Task.Form.N18CsDecisionTask.InterestRate',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 100),
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="requestedTerm"
                            label={t('Task.Form.N18CsDecisionTask.Term')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberMaxValue(180),
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <TextAreaField
                            name="comment"
                            label={t('Task.Form.N18CsDecisionTask.Comment')}
                        />
                    </Col>
                </Row>
            </Card>
        </Col>
    );
});
