/* eslint-disable max-lines */
import { Col, notification, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { ErrorField } from 'src/components/Form/ErrorField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { LoanOverdueDaysGapsStore } from 'src/stores/LoanOverdueDaysGapsStore/LoanOverdueDaysGapsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const store = LoanOverdueDaysGapsStore;

export const OverdueDaysGapsEditDialogFn = observer(
    ({ ...props }: ModalRendererProps) => {
        useEffect(() => {
            store.loadItem();

            return () => {
                store.updateItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );

        const isLoading =
            store.itemLoader.isLoading || store.updateItemLoader.isLoading;

        return (
            <Modal
                title={t('Dialog.LoanOverdueDaysGapsEdit.Title')}
                isLoading={isLoading}
                width={840}
                onFinish={async (values) => {
                    await store.update(0, values);
                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.updateItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.LoanOverdueDaysGapsEdit.Message.Success',
                            ),
                        });
                    }

                    store.loadItem();
                }}
                formProps={{ fields }}
                initialValues={{ setting: store.currentItem }}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={24}>
                        <StyledError name={['setting']} />
                    </Col>
                    <Col xs={24}>
                        <StyledTitle>
                            {t(
                                'Page.LoanOverdueDaysGaps.NORMAL.Title',
                                'Normal',
                            )}
                        </StyledTitle>
                        <ErrorField name={['setting', 'NORMAL']} />
                    </Col>
                    <Col xs={12}>
                        <TextField
                            name={['setting', 'NORMAL', 'from']}
                            label={t('Page.LoanOverdueDaysGaps.NORMAL.From')}
                            rules={[ValidationRules.required]}
                            disabled
                        />
                    </Col>
                    <Col xs={12}>
                        <NumberField
                            name={['setting', 'NORMAL', 'to']}
                            label={t('Page.LoanOverdueDaysGaps.NORMAL.To')}
                            rules={[ValidationRules.required]}
                            onlyInteger
                        />
                    </Col>

                    <Col xs={24}>
                        <StyledTitle>
                            {t(
                                'Page.LoanOverdueDaysGaps.PAST_DUE.Title',
                                'Past due',
                            )}
                        </StyledTitle>
                        <ErrorField name={['setting', 'PAST_DUE']} />
                    </Col>
                    <Col xs={12}>
                        <NumberField
                            name={['setting', 'PAST_DUE', 'from']}
                            label={t('Page.LoanOverdueDaysGaps.PAST_DUE.From')}
                            rules={[ValidationRules.required]}
                            onlyInteger
                        />
                    </Col>
                    <Col xs={12}>
                        <NumberField
                            name={['setting', 'PAST_DUE', 'to']}
                            label={t('Page.LoanOverdueDaysGaps.PAST_DUE.To')}
                            rules={[ValidationRules.required]}
                            onlyInteger
                        />
                    </Col>

                    <Col xs={24}>
                        <StyledTitle>
                            {t(
                                'Page.LoanOverdueDaysGaps.STRESSED_1.Title',
                                'Stressed 1',
                            )}
                        </StyledTitle>
                        <ErrorField name={['setting', 'STRESSED_1']} />
                    </Col>
                    <Col xs={12}>
                        <NumberField
                            name={['setting', 'STRESSED_1', 'from']}
                            label={t(
                                'Page.LoanOverdueDaysGaps.STRESSED_1.From',
                            )}
                            rules={[ValidationRules.required]}
                            onlyInteger
                        />
                    </Col>
                    <Col xs={12}>
                        <NumberField
                            name={['setting', 'STRESSED_1', 'to']}
                            label={t('Page.LoanOverdueDaysGaps.STRESSED_1.To')}
                            rules={[ValidationRules.required]}
                            onlyInteger
                        />
                    </Col>

                    <Col xs={24}>
                        <StyledTitle>
                            {t(
                                'Page.LoanOverdueDaysGaps.STRESSED_2.Title',
                                'Stressed 2',
                            )}
                        </StyledTitle>
                        <ErrorField name={['setting', 'STRESSED_2']} />
                    </Col>
                    <Col xs={12}>
                        <NumberField
                            name={['setting', 'STRESSED_2', 'from']}
                            label={t(
                                'Page.LoanOverdueDaysGaps.STRESSED_2.From',
                            )}
                            rules={[ValidationRules.required]}
                            onlyInteger
                        />
                    </Col>
                    <Col xs={12}>
                        <NumberField
                            name={['setting', 'STRESSED_2', 'to']}
                            label={t('Page.LoanOverdueDaysGaps.STRESSED_2.To')}
                            rules={[ValidationRules.required]}
                            onlyInteger
                        />
                    </Col>

                    <Col xs={24}>
                        <StyledTitle>
                            {t(
                                'Page.LoanOverdueDaysGaps.SUB_STANDARD.Title',
                                'Sub standard',
                            )}
                        </StyledTitle>
                        <ErrorField name={['setting', 'SUB_STANDARD']} />
                    </Col>
                    <Col xs={12}>
                        <NumberField
                            name={['setting', 'SUB_STANDARD', 'from']}
                            label={t(
                                'Page.LoanOverdueDaysGaps.SUB_STANDARD.From',
                            )}
                            rules={[ValidationRules.required]}
                            onlyInteger
                        />
                    </Col>
                    <Col xs={12}>
                        <NumberField
                            name={['setting', 'SUB_STANDARD', 'to']}
                            label={t(
                                'Page.LoanOverdueDaysGaps.SUB_STANDARD.To',
                            )}
                            rules={[ValidationRules.required]}
                            onlyInteger
                        />
                    </Col>

                    <Col xs={24}>
                        <StyledTitle>
                            {t(
                                'Page.LoanOverdueDaysGaps.DOUBTFUL.Title',
                                'Doubtful',
                            )}
                        </StyledTitle>
                        <ErrorField name={['setting', 'DOUBTFUL']} />
                    </Col>
                    <Col xs={12}>
                        <NumberField
                            name={['setting', 'DOUBTFUL', 'from']}
                            label={t('Page.LoanOverdueDaysGaps.DOUBTFUL.From')}
                            rules={[ValidationRules.required]}
                            onlyInteger
                        />
                    </Col>
                    <Col xs={12}>
                        <NumberField
                            name={['setting', 'DOUBTFUL', 'to']}
                            label={t('Page.LoanOverdueDaysGaps.DOUBTFUL.To')}
                            rules={[ValidationRules.required]}
                            onlyInteger
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const OverdueDaysGapsEditDialog = addModalRenderer(
    OverdueDaysGapsEditDialogFn,
);

const StyledTitle = styled(Typography.Title)`
    &.ant-typography {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #4d6082;
        margin-bottom: 0;
    }
`;

const StyledError = styled(ErrorField)`
    margin-bottom: 10px;
`;
