import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { LegalEntityDocumentsListCard } from 'src/pages/LegalEntityDetailsPage/Components/LegalEntityDocumentListCard';
import { LegalEntityOriginationCard } from 'src/pages/LegalEntityDetailsPage/Components/LegalEntityOriginationCard';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export type LegalEntityOtherInformationTabProps = {
    viewModel: LegalEntityDetailsViewModelClass;
};

export const LegalEntityOtherInformationTab = observer(
    ({ viewModel }: LegalEntityOtherInformationTabProps) => {
        const showDocuments = AppUser.hasPermissions(
            UserPermission.DocumentLegalEntityRead,
        );

        useEffect(() => {
            viewModel.loadOtherInformation();

            return () => {
                viewModel.otherInformationLoader.reset();
            };
        }, []);

        return (
            <Row gutter={24}>
                <Col xs={24}>
                    <LegalEntityOriginationCard viewModel={viewModel} />
                </Col>
                <Col xs={24}>
                    {showDocuments && (
                        <LegalEntityDocumentsListCard viewModel={viewModel} />
                    )}
                </Col>
            </Row>
        );
    },
);
