import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AddComment } from 'src/components/Comments/AddComment';
import { CommentsList } from 'src/components/Comments/CommentsList';
import styled from 'styled-components';

export type CommentsProps = {
    roomId: number;
};

export const Comments = observer(({ roomId }: CommentsProps) => {
    const { t } = useTranslation();

    return (
        <>
            <StyledTitle>{t('Component.Comments.Title')}</StyledTitle>

            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <AddComment roomId={roomId} />
                </Col>
                <Col xs={24}>
                    <CommentsList roomId={roomId} />
                </Col>
            </Row>
        </>
    );
});

const StyledTitle = styled(Typography.Title)`
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 32px !important;
    color: #4d6082 !important;
`;
