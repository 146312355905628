import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { SettingsProductLimitRiskPriceTab } from 'src/pages/Settings/ProductLimit/Tabs/SettingsProductLimitRiskPriceTab';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export const SettingsProductLimitPage = observer(() => {
    const { t } = useTranslation();

    const tabs: TabDefinition[] = [
        {
            title: t('Page.SettingsProductLimit.Tabs.RiskPrice'),
            tabKey: 'risk-price',
            Component: <SettingsProductLimitRiskPriceTab />,
            visible: AppUser.hasPermissions(
                UserPermission.LoanSettingsProductLimitRiskPriceRead,
            ),
        },
    ];

    return (
        <BasePage title={t('Page.SettingsProductLimit.Title')}>
            <Tabs tabs={tabs} />
        </BasePage>
    );
});
