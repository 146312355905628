import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { InstallmentLoanApplication } from 'src/generated-api-client';
import { ApplicationsStatus } from 'src/pages/ApplicationsPage/ApplicationsStatus';
import { UrlHelper } from 'src/utils/UrlHelper';

export type ApplicationDetailsBriefProps = {
    application?: InstallmentLoanApplication;
};

export const InstallmentLoanApplicationBrief = observer(
    ({ application }: ApplicationDetailsBriefProps) => {
        const individualDetailsUrl = UrlHelper.getTargetUrl(
            RoutePaths.individualDetails,
            { individualId: application?.customerId },
        );
        const productDetailsUrl = UrlHelper.getTargetUrl(
            RoutePaths.productInstallmentDetails,
            { productId: application?.product?.id },
        );

        return (
            <Brief>
                <DataView
                    value={application?.customerId}
                    label={t(
                        'Page.InstallmentLoanApplicationDetails.CustomerId',
                    )}
                    format="link-internal"
                    formatterProps={{
                        to: individualDetailsUrl,
                    }}
                />
                <DataView
                    value={application?.product?.name}
                    label={t('Page.InstallmentLoanApplicationDetails.Product')}
                    format="link-internal"
                    formatterProps={{
                        to: productDetailsUrl,
                    }}
                />
                <DataView
                    value={application?.product?.productLine?.type}
                    label={t(
                        'Page.InstallmentLoanApplicationDetails.ProductType',
                    )}
                    format={(value) => (
                        <EnumView enumName="PRODUCT_TYPE" value={value} />
                    )}
                />
                <DataView
                    value={application?.creditScore}
                    label={t(
                        'Page.InstallmentLoanApplicationDetails.CreditScore',
                    )}
                />
                <DataView
                    value={application?.status}
                    label={t('Page.InstallmentLoanApplicationDetails.Status')}
                    format={(value) => <ApplicationsStatus status={value} />}
                />
            </Brief>
        );
    },
);
