import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';

export const CreateRealEstateCollateralLandInfoVariables = observer(() => {
    return (
        <Card title={t('Page.CollateralCreatePage.RealEstate.LandInfo.Title')}>
            <Row gutter={16}>
                <Col xs={8}>
                    <TextField
                        name={['land', 'registrationNumber']}
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.LandInfo.RegistrationNumber',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <TextField
                        name={['land', 'certificateNumber']}
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.LandInfo.CertificateNumber',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <EnumSelectField
                        name={['land', 'ownershipType']}
                        enumName="LAND_OWNERSHIP_TYPE"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.LandInfo.OwnershipType',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <DatePickerField
                        name={['land', 'certificateStartDate']}
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.LandInfo.CertificateStartDate',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <DatePickerField
                        name={['land', 'certificateEndDate']}
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.LandInfo.CertificateEndDate',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <EnumSelectField
                        name={['land', 'useOfLand']}
                        enumName="USE_OF_LAND"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.LandInfo.UseOfLand',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <NumberField
                        name={['land', 'landSquare']}
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.LandInfo.LandSquare',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <TextField
                        name={['land', 'cadastralNumber']}
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.LandInfo.CadastralNumber',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <EnumSelectField
                        name={['land', 'collateralOwnershipType']}
                        enumName="COLLATERAL_OWNERSHIP_TYPE"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.LandInfo.CollateralOwnershipType',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <EnumSelectField
                        name={['land', 'coOwnerCount']}
                        enumName="CO_OWNER_COUNT"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.LandInfo.CoOwnerCount',
                        )}
                    />
                </Col>
            </Row>
        </Card>
    );
});
