import { Button, Col, notification, Row, Space } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { Form } from 'src/components/Form/Form';
import { TextField } from 'src/components/Form/TextField';
import {
    FieldMaxLength,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { NotFound } from 'src/components/NotFound/NotFound';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { Role } from 'src/generated-api-client';
import { useRoleId } from 'src/hooks/useRoleId';
import { RoleAuthorities } from 'src/pages/RoleEditPage/RoleAuthorities';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { UrlHelper } from 'src/utils/UrlHelper';

export const RoleEditPage = observer(() => {
    const { roleId, hasRoleId } = useRoleId();
    const { t } = useTranslation();

    useEffect(() => {
        if (hasRoleId) {
            RolesStore.loadItem(roleId);
        }

        return () => {
            RolesStore.itemLoader.reset();
            RolesStore.updateItemLoader.reset();
        };
    }, [roleId]);

    const fields = FormHelper.getFieldDataItems(
        RolesStore.updateItemLoader.errorData,
    );

    const role = RolesStore.currentItem;

    const loadingInfo = useMemo(() => {
        return new CombinedLoadingStatus([
            RolesStore.authorityLoader,
            RolesStore.itemLoader,
            RolesStore.updateItemLoader,
        ]);
    }, []);

    const pageTitle = t('Page.RolesEdit.Title', { roleId });

    const error = useMemo(() => {
        const showNotFound =
            !_.isInteger(Number(roleId)) ||
            RolesStore.itemLoader.errorData?.status === 404;
        if (showNotFound) {
            return (
                <NotFound
                    title={t('Page.RolesEdit.Error.NotFound.Title')}
                    description={t(
                        'Page.RolesEdit.Error.NotFound.Description',
                        { roleId },
                    )}
                >
                    <Button
                        onClick={() => {
                            Router.navigate(RoutePaths.roles);
                        }}
                    >
                        {t('Page.RolesEdit.GoToList')}
                    </Button>
                </NotFound>
            );
        }

        return null;
    }, [roleId, RolesStore.itemLoader.errorData]);

    return (
        <BasePage
            title={pageTitle}
            isLoading={loadingInfo.isLoading}
            showOnlyLoading={!role}
            error={error}
        >
            <Form
                onFinish={async (values: any) => {
                    await RolesStore.update(roleId, values);

                    if (!RolesStore.updateItemLoader.hasError) {
                        notification.success({
                            message: t('Page.RolesEdit.Message.Success'),
                        });
                        Router.navigate(
                            UrlHelper.getTargetUrl(RoutePaths.roleDetails, {
                                roleId,
                            }),
                        );
                    }
                }}
                onReset={() => {
                    Router.navigate(
                        UrlHelper.getTargetUrl(RoutePaths.roleDetails, {
                            roleId,
                        }),
                    );
                }}
                fields={fields}
                initialValues={getInitialValues(role)}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            name="name"
                            label={t('Page.RoleEdit.Name')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.maxFieldLength(
                                    FieldMaxLength.RoleName,
                                ),
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <EnumSelectField
                            name="startPage"
                            label={t('Page.RoleEdit.StartPage')}
                            rules={[ValidationRules.required]}
                            enumName="START_PAGE"
                        />
                    </Col>

                    <Col span={12}>
                        <TextField
                            name="description"
                            label={t('Page.RoleEdit.description')}
                            rules={[
                                ValidationRules.maxFieldLength(
                                    FieldMaxLength.RoleDescription,
                                ),
                            ]}
                        />
                    </Col>
                </Row>

                <RoleAuthorities />

                <Row>
                    <Col span={24}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                {t('Page.RoleEdit.Btn.Save')}
                            </Button>
                            <Button type="default" htmlType="reset">
                                {t('Page.RoleEdit.Btn.Reset')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </BasePage>
    );
});

const getInitialValues = (role?: Role) => {
    const authorities = role?.authorities.map((authority) =>
        RolesStore.authoritiesValueAuthorityMap.get(authority),
    );

    return {
        ...role,
        authorities,
    };
};
