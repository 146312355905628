import { ColumnType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { CheckIcon, RejectIcon, WarningIcon } from 'src/components/Icons/Icons';
import { ListView } from 'src/components/ListView/ListView';
import { Modal } from 'src/components/Modal/Modal';
import {
    CustomerDecision,
    CustomerLivestockIncomeData,
    IncomeCheckStatus,
    LivestockIncomeDetailsResponse,
    VerificationCheckDecision,
} from 'src/generated-api-client';
import { IncomeStatus } from 'src/pages/IndividualDetailsPage/Components/IncomeStatus';
import { IndividualLivestockIncomeStore } from 'src/stores/IndividualLivestockIncomeStore/IndividualLivestockIncomeStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type LiveStockIncomeDetailsDialogFnProps = ModalRendererProps<{
    individualId?: number;
}>;

export const LiveStockIncomeDetailsDialogFn = observer(
    ({ individualId, ...props }: LiveStockIncomeDetailsDialogFnProps) => {
        const store = IndividualLivestockIncomeStore.getInstance(
            Number(individualId),
        );
        const { isLoading } = store.itemLoader;
        const details: LivestockIncomeDetailsResponse = store.incomeDetails;
        const showIncomeData =
            !isLoading && details?.status === IncomeCheckStatus.APPROVED;
        const showDecisions =
            !isLoading && details?.status === IncomeCheckStatus.REJECT;

        useEffect(() => {
            store.loadItem();

            return () => {
                store.itemLoader.reset();
            };
        }, [individualId]);

        return (
            <Modal
                title={t('Dialog.LiveStockIncomeDetails.Title')}
                footer={null}
                maskClosable={false}
                width={1000}
                isLoading={isLoading}
                {...props}
            >
                <StyledDataView
                    value={details?.monthlyIncome}
                    label={t('Dialog.LiveStockIncome.MonthlyIncome')}
                    format="money"
                    align="horizontal"
                />
                <StyledDataView
                    value={details?.status}
                    label={t('Dialog.LiveStockIncome.Status')}
                    align="horizontal"
                    format={(value) => {
                        return <IncomeStatus status={value} />;
                    }}
                />

                {showIncomeData && (
                    <StyledListView
                        dataSource={details?.incomeData}
                        columns={approveColumns}
                    />
                )}

                {showDecisions && (
                    <>
                        <StyledWrapper>
                            <WarningIcon />
                        </StyledWrapper>
                        <StyledListView
                            dataSource={details?.decisions}
                            columns={rejectColumns}
                        />
                    </>
                )}
            </Modal>
        );
    },
);

export const LiveStockIncomeDetailsDialog = addModalRenderer(
    LiveStockIncomeDetailsDialogFn,
);

const StyledDataView = styled(DataView)`
    justify-content: space-between;
    align-items: baseline;
    width: 320px;

    .app-data-view__label,
    .app-data-view__value {
        font-size: 18px;
        color: #4d6082;
    }
`;

const StyledListView = styled(ListView)`
    padding: 0;
`;

const StyledWrapper = styled.div`
    padding: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const approveColumns: ColumnType<CustomerLivestockIncomeData>[] = [
    {
        title: <Trans i18nKey="Dialog.LiveStockIncome.List.IndexNumber" />,
        key: 'indexNumber',
        render: (text, record, index) => index + 1,
    },
    {
        title: <Trans i18nKey="Dialog.LiveStockIncome.List.HerdingMonths" />,
        key: 'herdingMonths',
        dataIndex: 'herdingMonths',
    },
    {
        title: <Trans i18nKey="Dialog.LiveStockIncome.List.TotalCount" />,
        key: 'isCountEnough',
        dataIndex: 'isCountEnough',
        render: (value) => {
            return <DataView value={value} format="yes-no" hideLabel />;
        },
    },
    {
        title: (
            <Trans i18nKey="Dialog.LiveStockIncome.List.IsCountedInLastTwoYears" />
        ),
        key: 'isCountedInLastTwoYears',
        dataIndex: 'isCountedInLastTwoYears',
        render: (value) => {
            return <DataView value={value} format="yes-no" hideLabel />;
        },
    },
    {
        title: <Trans i18nKey="Dialog.LiveStockIncome.List.HasVariance" />,
        key: 'hasVariance',
        dataIndex: 'hasVariance',
        render: (value) => {
            return <DataView value={value} format="yes-no" hideLabel />;
        },
    },
];

const rejectColumns: ColumnType<CustomerDecision>[] = [
    {
        title: <Trans i18nKey="Dialog.IncomeRejectDetails.List.IndexNumber" />,
        key: 'indexNumber',
        render: (text, record, index) => index + 1,
    },
    {
        title: <Trans i18nKey="Dialog.IncomeRejectDetails.List.Reason" />,
        key: 'reason',
        dataIndex: 'reason',
    },
    {
        title: <Trans i18nKey="Dialog.IncomeRejectDetails.List.Decision" />,
        key: 'decision',
        dataIndex: 'decision',
        render: (value: string) => {
            if (value === VerificationCheckDecision.APPROVED) {
                return <CheckIcon />;
            }

            return <RejectIcon />;
        },
    },
];
