import { ApplicationCollateralListEntry } from 'src/generated-api-client';
import { applicationsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ApplicationCollateralsStoreClass extends BasicStore<ApplicationCollateralListEntry> {
    constructor(private applicationId: number) {
        super();
    }

    api: BasicStoreApi<ApplicationCollateralListEntry> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.getCollaterals({
                    ...this.listParams,
                    id: this.applicationId,
                }),
            );
        },
    };

    pager?: Pager | undefined = new Pager();
}

export const ApplicationCollateralsStore = new EntityProvider(
    ApplicationCollateralsStoreClass,
);
