import {
    NewTrustProduct,
    ProductStatus,
    TrustProduct,
    TrustProductListEntry,
    TrustProductUpdate,
} from 'src/generated-api-client';
import { productsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type TrustPledgedProductsFilter = {
    id?: number;
    externalId?: number;
    name?: string;
    statuses?: ProductStatus[];
    interestRate: number;
};
const TrustPledgedProductsFilterKeys: Array<keyof TrustPledgedProductsFilter> =
    ['id', 'externalId', 'name', 'statuses', 'interestRate'];

export class TrustPledgedProductsStoreClass extends BasicStore<
    TrustProductListEntry,
    TrustPledgedProductsFilter,
    TrustProductUpdate,
    NewTrustProduct,
    TrustProduct
> {
    api: BasicStoreApi<
        TrustProductListEntry,
        TrustProductUpdate,
        NewTrustProduct,
        TrustProduct
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.getTrustProducts(this.listParams),
            );
        },

        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.getProduct({ id }),
            ) as Promise<TrustProduct>;
        },

        createItem: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.createProduct({
                    createProductRequest: {
                        ...data,
                        type: 'NewTrustProduct',
                    } as NewTrustProduct,
                }),
            );
        },

        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.updateProduct({
                    id: Number(id),
                    updateProductRequest: {
                        ...data,
                        type: 'TrustProductUpdate',
                    } as TrustProductUpdate,
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<TrustPledgedProductsFilter>(
        TrustPledgedProductsFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<TrustProductListEntry>('name');
}

export const TrustPledgedProductsStore = new TrustPledgedProductsStoreClass();
