import { Form, FormItemProps, Input } from 'antd';
import { useEffect } from 'react';

export type HiddenFieldProps = FormItemProps & {
    value?: any;
};

export const HiddenField = ({ name, value, ...props }: HiddenFieldProps) => {
    const form = Form.useFormInstance();

    useEffect(() => {
        if (name && value) {
            form.setFields([{ name, value }]);
        }
    }, [value, name]);

    return (
        <Form.Item {...props} name={name} hidden>
            <Input value={value} />
        </Form.Item>
    );
};
