import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DotsHorizontalIcon } from 'src/components/Icons/Icons';
import { LoanCollateralListEntry } from 'src/generated-api-client';
import { getCollateralsActions } from 'src/pages/LoanDetailsPage/Components/Collaterals/CollateralsActions.config';
import { LoanType } from 'src/stores/LoansStore/LoansStore';
import styled from 'styled-components';

type CoBorrowerActionsProps = {
    loan?: LoanType;
    collateral?: LoanCollateralListEntry;
};

export const CollateralsActions = observer(
    ({ loan, collateral }: CoBorrowerActionsProps) => {
        const coBorrowerActions = getCollateralsActions(loan, collateral);

        return (
            <Dropdown
                items={coBorrowerActions}
                disabled={!coBorrowerActions.length}
            >
                <StyledIconButton icon={<DotsHorizontalIcon />} />
            </Dropdown>
        );
    },
);

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;
