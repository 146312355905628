/* eslint-disable no-return-await */
import { notification } from 'antd';
import { t } from 'i18next';
import { action, computed } from 'mobx';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';

export abstract class BasicActionStore<
    InitialState,
    ApprovedState = InitialState,
    DeclinedState = InitialState,
> {
    abstract api: BasicActionStoreApi<
        InitialState,
        ApprovedState,
        DeclinedState
    >;

    okMessage?: string;
    cancelMessage?: string;

    initialValuesLoader = new AsyncOperationWithStatus((...args: any[]) => {
        return this.api.load?.(...args);
    });

    okLoader = new AsyncOperationWithStatus((data?: ApprovedState) => {
        return this.api.ok?.(data);
    });

    cancelLoader = new AsyncOperationWithStatus((data?: DeclinedState) => {
        return this.api.cancel?.(data);
    });

    @action async load(id?: string | number) {
        this.initialValuesLoader.reset();

        return await this.initialValuesLoader.call(id);
    }

    @action async ok(data?: ApprovedState) {
        this.okLoader.reset();
        await this.okLoader.call(data);

        if (!this.okLoader.hasError) {
            notification.success({
                message:
                    this.okMessage || t('BasicActionStore.Message.SuccessOk'),
            });
        }

        if (this.okLoader.hasError && this.okLoader.errorData.status === 409) {
            notification.error({
                message:
                    this.okLoader.errorData.detail ||
                    t('BasicActionStore.Message.409ActionConflict'),
            });
        }
    }

    @action async cancel(data?: DeclinedState) {
        this.cancelLoader.reset();
        await this.cancelLoader.call(data);

        if (!this.cancelLoader.hasError) {
            notification.success({
                message:
                    this.cancelMessage ||
                    t('BasicActionStore.Message.SuccessCancel'),
            });
        }

        if (
            this.cancelLoader.hasError &&
            this.cancelLoader.errorData.status === 409
        ) {
            notification.error({
                message:
                    this.cancelLoader.errorData.detail ||
                    t('BasicActionStore.Message.409ActionConflict'),
            });
        }
    }

    @computed get initialValues() {
        return this.initialValuesLoader.data;
    }

    @computed get isLoading() {
        return (
            this.initialValuesLoader.isLoading ||
            this.okLoader.isLoading ||
            this.cancelLoader.isLoading
        );
    }
}
