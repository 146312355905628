import { computed } from 'mobx';
import { ChildBranchOption } from 'src/generated-api-client';
import { brunchesApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ChildBranchOptionsStoreClass extends BasicStore<ChildBranchOption> {
    api: BasicStoreApi<ChildBranchOption> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                brunchesApi.childBranchOptions(),
            );

            return result || [];
        },
    };

    @computed get branchOptions() {
        return this.list.map(asOption);
    }

    @computed get branchOptionsMap() {
        return Object.fromEntries(
            this.list.map((branch) => {
                return [branch.id, branch];
            }),
        );
    }

    getBranchManager(branchId: number) {
        return this.branchOptionsMap[branchId]?.branchManager?.name;
    }

    getRelationshipManagerOptions(branchId: number) {
        return this.branchOptionsMap[branchId]?.relationshipManagers?.map(
            rmManagerAsOption,
        );
    }
}

export const ChildBranchOptionsStore = new ChildBranchOptionsStoreClass();

const asOption = (value: any) => ({
    value: String(value.id),
    label: value.name,
});

const rmManagerAsOption = (value: any) => ({
    value: String(value?.id),
    label: [value?.id, value?.name].join(' '),
});
