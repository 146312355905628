import { FilterOutlined } from '@ant-design/icons';
import {
    Badge,
    Button,
    Collapse,
    Form as AntForm,
    Space,
    Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterApplyIcon } from 'src/assets/filter-apply-icon.svg';
import { ReactComponent as FilterHideIcon } from 'src/assets/filter-hide-icon.svg';
import { ReactComponent as FilterResetIcon } from 'src/assets/filter-reset-icon.svg';
import { ReactComponent as FilterShowIcon } from 'src/assets/filter-show-icon.svg';
import { Form, FormProps } from 'src/components/Form/Form';
import { useSearchParams } from 'src/hooks/useSeachParams';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

const { Panel } = Collapse;

export type FilterFormType = Omit<FormProps, 'title'> & {
    children: React.ReactNode;
    store: BasicStore<any>;
    title?: React.ReactNode;
    panelExtra?: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    convertFilterCriteriaToFormValues?: (values: any) => any;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    convertFormValuesToFilterCriteria?: (values: any) => any;
};

const DEFAULT_PANEL_KEY = '1';

export const FilterForm = observer(
    ({
        children,
        store,
        title,
        panelExtra,
        form,
        convertFilterCriteriaToFormValues,
        convertFormValuesToFilterCriteria,
        ...props
    }: // eslint-disable-next-line sonarjs/cognitive-complexity
    FilterFormType) => {
        const { t } = useTranslation();
        const [isCollapsed, setIsCollapsed] = useState<number | undefined>();
        const [innerForm] = AntForm.useForm();
        const searchParams = useSearchParams();

        useEffect(() => {
            store?.filterCriteria?.setFilterFromSearchQuery();
            if (store.filterCriteria?.hasFilter) {
                const formValues = convertFilterCriteriaToFormValues
                    ? convertFilterCriteriaToFormValues?.(
                          store.filterCriteria.filter,
                      )
                    : store.filterCriteria.filter;

                targetForm?.setFieldsValue(formValues);
            }
        }, [searchParams.params]);

        const targetForm = useMemo(() => {
            return form || innerForm;
        }, [form]);

        const onChange = (key?: number) => {
            setIsCollapsed(Number(!key));
        };

        const hasFilter = Boolean(store.filterCriteria?.hasFilter);

        const filterBtnText = isCollapsed
            ? t('Component.FilterForm.Button.HideFilters')
            : t('Component.FilterForm.Button.Filters');
        const toggleFilterBtnHandler = () => {
            onChange(isCollapsed);
        };
        const hasModal = useMemo(() => {
            return Boolean(document.querySelector('.has-modal'));
        }, []);

        return (
            <StyledCollapse activeKey={isCollapsed} accordion ghost>
                <Panel
                    extra={
                        <Space size="middle">
                            {panelExtra}
                            {hasFilter ? (
                                <Badge dot>
                                    <Button
                                        onClick={toggleFilterBtnHandler}
                                        icon={<FilterOutlined />}
                                        data-test="show-filter"
                                    >
                                        {filterBtnText}
                                    </Button>
                                </Badge>
                            ) : (
                                <Button
                                    type={isCollapsed ? 'default' : 'primary'}
                                    onClick={toggleFilterBtnHandler}
                                    icon={
                                        isCollapsed ? (
                                            <FilterHideIcon />
                                        ) : (
                                            <FilterShowIcon />
                                        )
                                    }
                                    data-test={
                                        isCollapsed
                                            ? 'hide-filter'
                                            : 'show-filter'
                                    }
                                >
                                    {filterBtnText}
                                </Button>
                            )}
                        </Space>
                    }
                    header={<StyledTitle>{title}</StyledTitle>}
                    showArrow={false}
                    key={DEFAULT_PANEL_KEY}
                >
                    <Form
                        {...props}
                        form={targetForm}
                        onFinish={async (values) => {
                            const filterCriteria =
                                convertFormValuesToFilterCriteria
                                    ? convertFormValuesToFilterCriteria?.(
                                          values,
                                      )
                                    : values;
                            store.filterCriteria?.applyFilter(filterCriteria, {
                                applyToSearchParams: !hasModal,
                            });
                            store.pager?.reset();
                            await store.loadList();
                        }}
                        onReset={async () => {
                            store.filterCriteria?.resetFilter();
                            store.pager?.reset();
                            await store.loadList();
                        }}
                    >
                        <FieldsWrapper>{children}</FieldsWrapper>
                        <StyledSpace
                            align="end"
                            direction="horizontal"
                            size={20}
                        >
                            <Button
                                htmlType="reset"
                                icon={<FilterResetIcon />}
                                data-test="reset-filter"
                            >
                                {t('Component.FilterForm.Button.ResetFilters')}
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<FilterApplyIcon />}
                                data-test="apply-filter"
                            >
                                {t('Component.FilterForm.Button.ApplyFilters')}
                            </Button>
                        </StyledSpace>
                    </Form>
                </Panel>
            </StyledCollapse>
        );
    },
);

const StyledSpace = styled(Space)`
    width: 100%;
    justify-content: flex-end;
`;

const StyledCollapse = styled(Collapse)`
    .ant-collapse-header {
        padding: 0 !important;
    }
    .ant-collapse-extra {
        margin-left: 30px;
        display: flex;
    }

    .ant-collapse-content-box {
        padding: 0 !important;
    }
`;

const FieldsWrapper = styled.div`
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    .ant-form-item {
        margin-bottom: 25px;
    }
`;

const StyledTitle = styled(Typography.Title)`
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 32px !important;
    color: #2e3e5c !important;
`;
