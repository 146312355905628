import { Typography } from 'antd';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { Modal } from 'src/components/Modal/Modal';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type RemoveCivilServantDialogFnProps = ModalRendererProps<{
    civilServantIndex?: number;
    onSave?: Dispatch<SetStateAction<any[]>>;
}>;

const RemoveCivilServantDialogFn = ({
    civilServantIndex,
    onSave,
    ...props
}: RemoveCivilServantDialogFnProps) => {
    return (
        <Modal
            title={t('Dialog.RemoveCivilServantDialog.Title')}
            onFinish={() => {
                if (
                    civilServantIndex === undefined ||
                    civilServantIndex < 0 ||
                    !onSave
                ) {
                    return;
                }

                onSave((r: any[]) => {
                    return [...r]?.filter(
                        (_, index) => index !== civilServantIndex,
                    );
                });
            }}
            {...props}
        >
            <StyledText>{t('Dialog.RemoveCivilServantDialog.Text')}</StyledText>
        </Modal>
    );
};

export const RemoveCivilServantDialog = addModalRenderer(
    RemoveCivilServantDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 16px;
    color: #4d6082;
`;
