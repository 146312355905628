import { action, computed } from 'mobx';
import {
    GetTask200Response,
    ProcessName,
    SubmitTaskDecisionRequest,
    TaskMetadata,
    TaskName,
    TaskStatus,
} from 'src/generated-api-client';
import { tasksApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager, PageSizes } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type TasksFilter = {
    assignedToMe: boolean;
    taskNames?: TaskName[];
    processNames?: ProcessName[];
    loanApplicationId?: number;
    taskStatuses?: TaskStatus[];
    assigneeName?: string;
    individualName?: string;
    createdAt?: Date;
    completedAt?: Date;
    requestedAmount?: number;
    productLineId?: number;
};

const TasksFilterKeys: Array<keyof TasksFilter> = [
    'assignedToMe',
    'taskNames',
    'processNames',
    'loanApplicationId',
    'taskStatuses',
    'assigneeName',
    'individualName',
    'createdAt',
    'completedAt',
    'requestedAmount',
    'productLineId',
];

export const TaskFilterInitialValues = {
    taskStatuses: [TaskStatus.ACTIVE],
    assignedToMe: true,
};

export class TasksStoreClass extends BasicStore<
    TaskMetadata,
    TasksFilter,
    SubmitTaskDecisionRequest,
    any,
    GetTask200Response
> {
    api: BasicStoreApi<
        TaskMetadata,
        SubmitTaskDecisionRequest,
        any,
        GetTask200Response
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                tasksApi.getTasks({
                    ...this.listParams,
                    assignedToMe: this.filterCriteria.filter.assignedToMe,
                }),
            );
        },

        updateItem: (taskId, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                tasksApi.submitTaskDecision({
                    id: String(taskId),
                    submitTaskDecisionRequest: data,
                }),
            );
        },
    };

    assignTaskLoader = new AsyncOperationWithStatus(
        (taskId: string, userId: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                tasksApi.assignTask({ id: taskId, assignee: { id: userId } }),
            );
        },
        { defaultIsLoading: false },
    );

    itemLoader = new AsyncOperationWithStatus(
        (taskId: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                tasksApi.getTask({ id: taskId }),
            );
        },
        { defaultIsLoading: false },
    );

    taskMetadataLoader = new AsyncOperationWithStatus((taskId: string) => {
        return RequestHelper.unwrapFromAxiosPromise(
            tasksApi.getMetadataTask({ id: taskId }),
        );
    });

    @action getTaskMetadata(taskId: string) {
        return this.taskMetadataLoader.call(taskId);
    }

    @computed get taskMetadata() {
        return this.taskMetadataLoader.data;
    }

    @computed get hasTaskMetadata() {
        return this.taskMetadataLoader.hasData;
    }

    @action async assignTask(taskId: string, userId: number) {
        await this.assignTaskLoader.call(taskId, userId);
    }

    filterCriteria = new FilterCriteria<TasksFilter>(
        TasksFilterKeys,
        { applyToSearchParams: true },
        TaskFilterInitialValues,
    );

    pager?: Pager | undefined = new Pager(PageSizes.size20);
    sorter?: Sorter | undefined = new Sorter<TaskMetadata>();
}

export const TasksStore = new TasksStoreClass();
