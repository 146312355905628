import { notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { ParentBranchesStore } from 'src/stores/ParentBranchesStore/ParentBranchesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const store = ParentBranchesStore;

export const CreateParentBranchDialogFn = observer(
    ({ ...props }: ModalRendererProps) => {
        useEffect(() => {
            return () => {
                store.createItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.createItemLoader.errorData,
        );

        return (
            <Modal
                title={t('Dialog.CreateParentBranch.Title')}
                isLoading={store.createItemLoader.isLoading}
                onFinish={async (values) => {
                    await store.create(values);

                    if (store.createItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.createItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.CreateParentBranch.Message.Success',
                            ),
                        });
                    }
                    store.loadList();
                }}
                formProps={{ fields }}
                {...props}
            >
                <TextField
                    name="name"
                    label={t('Dialog.CreateParentBranch.ParentBranchName')}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.maxFieldLength(255),
                    ]}
                />
                <TextField
                    name="code"
                    label={t('Dialog.CreateParentBranch.ParentBranchCode')}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.maxFieldLength(255),
                    ]}
                />
            </Modal>
        );
    },
);

export const CreateParentBranchDialog = addModalRenderer(
    CreateParentBranchDialogFn,
);
