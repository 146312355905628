import {
    Document as OriginDocument,
    DocumentType,
    NewDocument,
} from 'src/generated-api-client';
import { documentsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import {
    BasicStoreApi,
    Entity,
} from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { RequestHelper } from 'src/utils/RequestHelper';

export type DocumentsStoreFilter = {
    documentTypes?: DocumentType[];
};
const DocumentsStoreFilterKeys: Array<keyof DocumentsStoreFilter> = [
    'documentTypes',
];
export type Document = Entity & OriginDocument;

export class DocumentsStoreClass extends BasicStore<
    Document,
    DocumentsStoreFilter,
    any,
    NewDocument,
    Document
> {
    api: BasicStoreApi<Document, any, NewDocument, Document> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                documentsApi.getDocumentsByRoomId({
                    roomId: this.roomId,
                    documentTypes: this.filterCriteria.filter.documentTypes,
                }),
            );
        },
        createItem: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                documentsApi.createDocument({
                    roomId: this.roomId,
                    newDocument: data,
                }),
            );
        },
        removeItem: (id) => {
            return RequestHelper.unwrapFromAxiosPromise(
                documentsApi.deleteDocument({
                    roomId: this.roomId,
                    documentId: Number(id),
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<DocumentsStoreFilter>(
        DocumentsStoreFilterKeys,
    );

    constructor(private roomId: number) {
        super();
    }
}

export const DocumentsStore = new EntityProvider(DocumentsStoreClass);
