import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { Brief } from 'src/components/Brief/Brief';
import { DataView, LONG_DASH } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { IdNameEntry } from 'src/generated-api-client';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';

export const CollateralDetailsBrief = observer(() => {
    const collateral = CollateralsStore.currentItem;

    return (
        <Brief>
            <DataView
                value={collateral?.id}
                label={t('Page.CollateralDetails.CustomerId')}
            />
            <DataView
                value={collateral?.type}
                label={t('Page.CollateralDetails.CollateralType')}
                format={(value) => (
                    <EnumView enumName="COLLATERAL_TYPE" value={value} />
                )}
            />
            <DataView
                value={collateral?.subType}
                label={t('Page.CollateralDetails.SubType')}
                format={(value) => (
                    <EnumView enumName="COLLATERAL_SUB_TYPE" value={value} />
                )}
            />
            <DataView
                value={collateral?.loans}
                label={t('Page.CollateralDetails.LoanId')}
                format={(loans) => {
                    return Array.isArray(loans) && loans.length
                        ? loans.map((item: IdNameEntry) => {
                              return (
                                  <>
                                      <Link
                                          to={EntityLinks.loans.itemDetails(
                                              item?.id,
                                          )}
                                      >
                                          {item?.id}
                                      </Link>
                                      <br />
                                  </>
                              );
                          })
                        : LONG_DASH;
                }}
            />
        </Brief>
    );
});
