import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { N22Task } from 'src/generated-api-client';
import { ConditionCardProps } from 'src/pages/TaskViewPage/TaskTypes';

export const BankStatementInformation = observer(
    ({ task }: ConditionCardProps<N22Task>) => {
        const { bankStatementStatus, bankStatementQualification, located } =
            task;

        return (
            <Card
                title={t(
                    'Task.Form.N22EnterIATUserDecision.BankStatementInformation.Title',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={bankStatementStatus}
                            label={t(
                                'Task.Form.N22EnterIATUserDecision.BankStatementInformation.BankStatementStatus',
                                'Bank statement status',
                            )}
                            format="string"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={bankStatementQualification}
                            label={t(
                                'Task.Form.N22EnterIATUserDecision.BankStatementInformation.BankStatementQualification',
                                'Bank statement qualification',
                            )}
                            format="string"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={located}
                            label={t(
                                'Task.Form.N22EnterIATUserDecision.BankStatementInformation.Located',
                                'Located',
                            )}
                            format="string"
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
