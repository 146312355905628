import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { LoanStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: LoanStatus;
};

export const LoansStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: LoanStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: <EnumView enumName="LOAN_STATUS" value={status} />,
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [LoanStatus.NORMAL]: {
        title: <EnumView enumName="LOAN_STATUS" value={LoanStatus.NORMAL} />,
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
    [LoanStatus.PAST_DUE]: {
        title: <EnumView enumName="LOAN_STATUS" value={LoanStatus.PAST_DUE} />,
        color: '#797064',
        backgroundColor: '#FBEED5',
    },
    [LoanStatus.STRESSED_1]: {
        title: (
            <EnumView enumName="LOAN_STATUS" value={LoanStatus.STRESSED_1} />
        ),
        color: '#797064',
        backgroundColor: '#FBEED5',
    },
    [LoanStatus.STRESSED_2]: {
        title: (
            <EnumView enumName="LOAN_STATUS" value={LoanStatus.STRESSED_2} />
        ),
        color: '#797064',
        backgroundColor: '#FBEED5',
    },
    [LoanStatus.SUB_STANDARD]: {
        title: (
            <EnumView enumName="LOAN_STATUS" value={LoanStatus.SUB_STANDARD} />
        ),
        color: '#0d0d0d',
        backgroundColor: '#f2f2f2',
    },
    [LoanStatus.DOUBTFUL]: {
        title: <EnumView enumName="LOAN_STATUS" value={LoanStatus.DOUBTFUL} />,
        color: '#0d0d0d',
        backgroundColor: '#f2f2f2',
    },
    [LoanStatus.LOSS]: {
        title: <EnumView enumName="LOAN_STATUS" value={LoanStatus.LOSS} />,
        color: '#fa656c',
        backgroundColor: '#FFE4E4',
    },
    [LoanStatus.LAWSUIT_1]: {
        title: <EnumView enumName="LOAN_STATUS" value={LoanStatus.LAWSUIT_1} />,
        color: '#fa656c',
        backgroundColor: '#FFE4E4',
    },
    [LoanStatus.BORROWER_DEATH_AND_LAWSUIT_2]: {
        title: (
            <EnumView
                enumName="LOAN_STATUS"
                value={LoanStatus.BORROWER_DEATH_AND_LAWSUIT_2}
            />
        ),
        color: '#fa656c',
        backgroundColor: '#FFE4E4',
    },
    [LoanStatus.REPAID]: {
        title: <EnumView enumName="LOAN_STATUS" value={LoanStatus.REPAID} />,
        color: '#959ca4',
        backgroundColor: '#F5F6F7',
    },
    [LoanStatus.REOPENED_AS_INSTALLMENT]: {
        title: (
            <EnumView
                enumName="LOAN_STATUS"
                value={LoanStatus.REOPENED_AS_INSTALLMENT}
            />
        ),
        color: '#959ca4',
        backgroundColor: '#F5F6F7',
    },
    [LoanStatus.RECALLED]: {
        title: <EnumView enumName="LOAN_STATUS" value={LoanStatus.RECALLED} />,
        color: '#959ca4',
        backgroundColor: '#F5F6F7',
    },
    [LoanStatus.WRITTEN_OFF]: {
        title: (
            <EnumView enumName="LOAN_STATUS" value={LoanStatus.WRITTEN_OFF} />
        ),
        color: '#9aa1a9',
        backgroundColor: '#F5F6F7',
    },
};
