import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';

export type StatusInfo = {
    color?: string;
    backgroundColor?: string;
    title?: React.ReactNode;
};

export type StatusViewProps = {
    statusInfo?: StatusInfo;
};

export const StatusView = observer(({ statusInfo }: StatusViewProps) => {
    return (
        <StyledStatus statusInfo={statusInfo}>{statusInfo?.title}</StyledStatus>
    );
});

const StyledStatus = styled.div<{ statusInfo?: StatusInfo }>`
    color: ${(props) => props?.statusInfo?.color || 'white'};
    background-color: ${(props) =>
        props?.statusInfo?.backgroundColor || '#82869b'};
    text-align: center;
    padding: 0 18px;
    border-radius: 100px;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    width: fit-content; ;
`;
