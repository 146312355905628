/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice API
 * Backoffice API
 *
 * The version of the OpenAPI document: 2.7.69
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const ActionType = {
    PROPOSE_INTEREST_ACCRUAL_STOP_RENEW: 'PROPOSE_INTEREST_ACCRUAL_STOP_RENEW',
    PROPOSE_WRITE_OFF: 'PROPOSE_WRITE_OFF',
    PROPOSE_PAYMENT_CANCELLATION: 'PROPOSE_PAYMENT_CANCELLATION',
    PROPOSE_PAST_DATE_PAYMENT: 'PROPOSE_PAST_DATE_PAYMENT',
    PROPOSE_INTEREST_RATE_CHANGING: 'PROPOSE_INTEREST_RATE_CHANGING',
    PROPOSE_PAYMENT_AMOUNT_CHANGING: 'PROPOSE_PAYMENT_AMOUNT_CHANGING',
    PROPOSE_PAYMENT_DATE_CHANGING: 'PROPOSE_PAYMENT_DATE_CHANGING',
    PROPOSE_GRACE_PERIOD: 'PROPOSE_GRACE_PERIOD',
    PROPOSE_NEW_GRACE_PERIOD_ALLOWING: 'PROPOSE_NEW_GRACE_PERIOD_ALLOWING',
    PROPOSE_MATURITY_DATE_EXTENSION: 'PROPOSE_MATURITY_DATE_EXTENSION',
    PROPOSE_REFINANCING: 'PROPOSE_REFINANCING',
    PROPOSE_ADDITIONAL_LOAN_ISSUING: 'PROPOSE_ADDITIONAL_LOAN_ISSUING',
    PROPOSE_INTEREST_ADJUSTMENT: 'PROPOSE_INTEREST_ADJUSTMENT',
    PROPOSE_PENALTY_ADJUSTMENT: 'PROPOSE_PENALTY_ADJUSTMENT',
    REVIEW_INTEREST_ACCRUAL_STOP_RENEW: 'REVIEW_INTEREST_ACCRUAL_STOP_RENEW',
    REVIEW_WRITE_OFF: 'REVIEW_WRITE_OFF',
    REVIEW_PAYMENT_CANCELLATION: 'REVIEW_PAYMENT_CANCELLATION',
    REVIEW_PAST_DATE_PAYMENT: 'REVIEW_PAST_DATE_PAYMENT',
    REVIEW_INTEREST_RATE_CHANGING: 'REVIEW_INTEREST_RATE_CHANGING',
    REVIEW_PAYMENT_AMOUNT_CHANGING: 'REVIEW_PAYMENT_AMOUNT_CHANGING',
    REVIEW_PAYMENT_DATE_CHANGING: 'REVIEW_PAYMENT_DATE_CHANGING',
    REVIEW_GRACE_PERIOD: 'REVIEW_GRACE_PERIOD',
    REVIEW_NEW_GRACE_PERIOD_ALLOWING: 'REVIEW_NEW_GRACE_PERIOD_ALLOWING',
    REVIEW_MATURITY_DATE_EXTENSION: 'REVIEW_MATURITY_DATE_EXTENSION',
    REVIEW_REFINANCING: 'REVIEW_REFINANCING',
    REVIEW_ADDITIONAL_LOAN_ISSUING: 'REVIEW_ADDITIONAL_LOAN_ISSUING',
    REVIEW_INTEREST_ADJUSTMENT: 'REVIEW_INTEREST_ADJUSTMENT',
    REVIEW_PENALTY_ADJUSTMENT: 'REVIEW_PENALTY_ADJUSTMENT',
    ENABLE_LAWSUIT_STATUS: 'ENABLE_LAWSUIT_STATUS',
    ENABLE_BORROWER_DEATH_STATUS: 'ENABLE_BORROWER_DEATH_STATUS',
    ENABLE_MANUAL_LOAN_STATUS: 'ENABLE_MANUAL_LOAN_STATUS',
    DISABLE_LAWSUIT_STATUS: 'DISABLE_LAWSUIT_STATUS',
    DISABLE_BORROWER_DEATH_STATUS: 'DISABLE_BORROWER_DEATH_STATUS',
    DISABLE_MANUAL_LOAN_STATUS: 'DISABLE_MANUAL_LOAN_STATUS',
    SET_CIB_STATUS_NORMAL: 'SET_CIB_STATUS_NORMAL',
    UPDATE_RELATIONSHIP_MANAGER: 'UPDATE_RELATIONSHIP_MANAGER',
    GET_EMONGOLIA_QR: 'GET_EMONGOLIA_QR',
    VIEW_DETAILS: 'VIEW_DETAILS',
    VIEW_HISTORY: 'VIEW_HISTORY',
    UPDATE_OTHER_INFORMATION: 'UPDATE_OTHER_INFORMATION',
    TASK_ASSIGN: 'TASK_ASSIGN',
    TASK_CLAIM: 'TASK_CLAIM',
    TASK_UNCLAIM: 'TASK_UNCLAIM',
    CO_BORROWER_ADD: 'CO_BORROWER_ADD',
    CO_BORROWER_APPROVE: 'CO_BORROWER_APPROVE',
    CO_BORROWER_DECLINE: 'CO_BORROWER_DECLINE',
    CO_BORROWER_DELETE: 'CO_BORROWER_DELETE',
    CO_BORROWER_UPDATE: 'CO_BORROWER_UPDATE',
    USER_ACTIVATE: 'USER_ACTIVATE',
    USER_ACTIVITY_UPDATE: 'USER_ACTIVITY_UPDATE',
    USER_DEACTIVATE: 'USER_DEACTIVATE',
    USER_RESEND_REGISTRATION_LINK: 'USER_RESEND_REGISTRATION_LINK',
    COLLATERAL_ADD: 'COLLATERAL_ADD',
    COLLATERAL_UPDATE: 'COLLATERAL_UPDATE',
    COLLATERAL_DELETE: 'COLLATERAL_DELETE',
    INSURANCE_ADD: 'INSURANCE_ADD',
    INSURANCE_UPDATE: 'INSURANCE_UPDATE',
    INSURANCE_DELETE: 'INSURANCE_DELETE',
    CO_OWNER_ADD: 'CO_OWNER_ADD',
    CO_OWNER_UPDATE: 'CO_OWNER_UPDATE',
    CO_OWNER_DELETE: 'CO_OWNER_DELETE',
    EVALUATION_UPDATE: 'EVALUATION_UPDATE',
    DOCUMENT_DELETE: 'DOCUMENT_DELETE',
    LEGAL_GENERAL_INFORMATION_UPDATE: 'LEGAL_GENERAL_INFORMATION_UPDATE',
    LEGAL_REPRESENTATIVE_CREATE: 'LEGAL_REPRESENTATIVE_CREATE',
    LEGAL_REPRESENTATIVE_UPDATE: 'LEGAL_REPRESENTATIVE_UPDATE',
    LEGAL_REPRESENTATIVE_DELETE: 'LEGAL_REPRESENTATIVE_DELETE',
    UPDATE_PRODUCT_OFFER_EXCLUSION: 'UPDATE_PRODUCT_OFFER_EXCLUSION',
    DEBT_COLLECTION_LEVEL_UPDATE: 'DEBT_COLLECTION_LEVEL_UPDATE',
    DEBT_COLLECTION_RECORD_ACTIVITY: 'DEBT_COLLECTION_RECORD_ACTIVITY'
} as const;

export type ActionType = typeof ActionType[keyof typeof ActionType];


/**
 * 
 * @export
 * @interface Actor
 */
export interface Actor {
    /**
     * 
     * @type {number}
     * @memberof Actor
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Actor
     */
    'name'?: string;
    /**
     * 
     * @type {ActorType}
     * @memberof Actor
     */
    'type': ActorType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ActorType = {
    SYSTEM: 'SYSTEM',
    BO_USER: 'BO_USER',
    INDIVIDUAL: 'INDIVIDUAL'
} as const;

export type ActorType = typeof ActorType[keyof typeof ActorType];


/**
 * 
 * @export
 * @interface AdditionalLoanIssuingPrecondition
 */
export interface AdditionalLoanIssuingPrecondition {
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalLoanIssuingPrecondition
     */
    'hasSecondRepaymentDate': boolean;
}
/**
 * 
 * @export
 * @interface AdditionalLoanIssuingProposal
 */
export interface AdditionalLoanIssuingProposal {
    /**
     * 
     * @type {number}
     * @memberof AdditionalLoanIssuingProposal
     */
    'additionalPrincipalDebtAmount': number;
    /**
     * 
     * @type {string}
     * @memberof AdditionalLoanIssuingProposal
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof AdditionalLoanIssuingProposal
     */
    'operationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdditionalLoanIssuingProposal
     */
    'repaymentsQuantity': number;
    /**
     * 
     * @type {string}
     * @memberof AdditionalLoanIssuingProposal
     */
    'secondRepaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdditionalLoanIssuingProposal
     */
    'totalNewPrincipalDebtAmount': number;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'apartmentName': string;
    /**
     * 
     * @type {AdministrativeUnit}
     * @memberof Address
     */
    'district': AdministrativeUnit;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'familiarAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'fullAddress': string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'id': number;
    /**
     * 
     * @type {AdministrativeUnit}
     * @memberof Address
     */
    'khoroo': AdministrativeUnit;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'latitudeLongitude'?: string;
    /**
     * 
     * @type {AdministrativeUnit}
     * @memberof Address
     */
    'province': AdministrativeUnit;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetName': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'townName': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AddressOwner = {
    OWN: 'OWN',
    PARENTS: 'PARENTS',
    RENTAL: 'RENTAL',
    RELATIVES: 'RELATIVES',
    OTHERS: 'OTHERS'
} as const;

export type AddressOwner = typeof AddressOwner[keyof typeof AddressOwner];


/**
 * 
 * @export
 * @enum {string}
 */

export const AddressType = {
    PERMANENT_RESIDENCE: 'PERMANENT_RESIDENCE',
    TEMPORARY_RESIDENCE: 'TEMPORARY_RESIDENCE',
    JOB: 'JOB',
    ID_ADDRESS: 'ID_ADDRESS'
} as const;

export type AddressType = typeof AddressType[keyof typeof AddressType];


/**
 * 
 * @export
 * @interface AddressUpdate
 */
export interface AddressUpdate {
    /**
     * 
     * @type {string}
     * @memberof AddressUpdate
     */
    'apartmentName': string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdate
     */
    'districtCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdate
     */
    'familiarAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdate
     */
    'fullAddress': string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdate
     */
    'khorooCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdate
     */
    'latitudeLongitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdate
     */
    'provinceCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdate
     */
    'streetName': string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdate
     */
    'townName': string;
}
/**
 * 
 * @export
 * @interface AdministrativeUnit
 */
export interface AdministrativeUnit {
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnit
     */
    'code': string;
    /**
     * 
     * @type {Localization}
     * @memberof AdministrativeUnit
     */
    'l10n': Localization;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AgeColumnKey = {
    RULES: 'RULES',
    VALUE: 'VALUE'
} as const;

export type AgeColumnKey = typeof AgeColumnKey[keyof typeof AgeColumnKey];


/**
 * 
 * @export
 * @interface AgeColumnMetadata
 */
export interface AgeColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof AgeColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {AgeColumnKey}
     * @memberof AgeColumnMetadata
     */
    'key'?: AgeColumnKey;
    /**
     * 
     * @type {object}
     * @memberof AgeColumnMetadata
     */
    'payload'?: object;
}


/**
 * 
 * @export
 * @interface AgeDto
 */
export interface AgeDto extends Settings {
    /**
     * 
     * @type {Array<AgeColumnMetadata>}
     * @memberof AgeDto
     */
    'columns'?: Array<AgeColumnMetadata>;
    /**
     * 
     * @type {Array<AgeRow>}
     * @memberof AgeDto
     */
    'rows'?: Array<AgeRow>;
}
/**
 * 
 * @export
 * @interface AgeRow
 */
export interface AgeRow {
    /**
     * 
     * @type {AgeSettingRule}
     * @memberof AgeRow
     */
    'ageSettingRule': AgeSettingRule;
    /**
     * 
     * @type {number}
     * @memberof AgeRow
     */
    'value': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AgeSettingRule = {
    AGE_MUST_BE_GREATER_OR_EQUAL: 'AGE_MUST_BE_GREATER_OR_EQUAL'
} as const;

export type AgeSettingRule = typeof AgeSettingRule[keyof typeof AgeSettingRule];


/**
 * 
 * @export
 * @interface ApplicationCollateralListEntry
 */
export interface ApplicationCollateralListEntry {
    /**
     * 
     * @type {number}
     * @memberof ApplicationCollateralListEntry
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCollateralListEntry
     */
    'importedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationCollateralListEntry
     */
    'ltv': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationCollateralListEntry
     */
    'marketValue': number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCollateralListEntry
     */
    'ownerName': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCollateralListEntry
     */
    'registrationNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationCollateralListEntry
     */
    'rmValue': number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCollateralListEntry
     */
    'sizeMark'?: string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof ApplicationCollateralListEntry
     */
    'subType': CollateralSubType;
}


/**
 * 
 * @export
 * @interface ApplicationPaymentScheduleEntry
 */
export interface ApplicationPaymentScheduleEntry {
    /**
     * 
     * @type {number}
     * @memberof ApplicationPaymentScheduleEntry
     */
    'loanAmount': number;
    /**
     * 
     * @type {Array<ApplicationSchedulePeriodEntry>}
     * @memberof ApplicationPaymentScheduleEntry
     */
    'schedulePeriods': Array<ApplicationSchedulePeriodEntry>;
    /**
     * 
     * @type {number}
     * @memberof ApplicationPaymentScheduleEntry
     */
    'termInMonths': number;
}
/**
 * 
 * @export
 * @interface ApplicationSchedulePeriodEntry
 */
export interface ApplicationSchedulePeriodEntry {
    /**
     * 
     * @type {number}
     * @memberof ApplicationSchedulePeriodEntry
     */
    'interest': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSchedulePeriodEntry
     */
    'loanBalance': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSchedulePeriodEntry
     */
    'paymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSchedulePeriodEntry
     */
    'paymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSchedulePeriodEntry
     */
    'principal': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApplicationSource = {
    MOBILE_APP: 'MOBILE_APP',
    BACK_OFFICE: 'BACK_OFFICE'
} as const;

export type ApplicationSource = typeof ApplicationSource[keyof typeof ApplicationSource];


/**
 * 
 * @export
 * @interface ApplicationsSummary
 */
export interface ApplicationsSummary {
    /**
     * 
     * @type {number}
     * @memberof ApplicationsSummary
     */
    'customerCancelledApplicationsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationsSummary
     */
    'disbursementSuccessApplicationsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationsSummary
     */
    'totalApplicationsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationsSummary
     */
    'waitingForDisbursementApplicationsCount': number;
}
/**
 * 
 * @export
 * @interface AssignLoanRequest
 */
export interface AssignLoanRequest {
    /**
     * 
     * @type {number}
     * @memberof AssignLoanRequest
     */
    'loanId': number;
}
/**
 * 
 * @export
 * @interface Assignee
 */
export interface Assignee {
    /**
     * 
     * @type {number}
     * @memberof Assignee
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface AuditBalance
 */
export interface AuditBalance {
    /**
     * 
     * @type {number}
     * @memberof AuditBalance
     */
    'diff': number;
    /**
     * 
     * @type {number}
     * @memberof AuditBalance
     */
    'newAmount': number;
    /**
     * 
     * @type {number}
     * @memberof AuditBalance
     */
    'oldAmount'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuthorityHierarchy = {
    DEFAULT_AUTHORITY: 'DEFAULT_AUTHORITY',
    BRANCH_LIST_READ: 'BRANCH_LIST_READ',
    BRANCH_DETAILS_READ: 'BRANCH_DETAILS_READ',
    BRANCH_CREATE: 'BRANCH_CREATE',
    BRANCH_OPTION: 'BRANCH_OPTION',
    BRANCH_EDIT: 'BRANCH_EDIT',
    GROUP_CIAT_USER: 'GROUP_CIAT_USER',
    GROUP_IAT_SENIOR: 'GROUP_IAT_SENIOR',
    GROUP_IAT_USER: 'GROUP_IAT_USER',
    GROUP_LAAT_LEADER: 'GROUP_LAAT_LEADER',
    GROUP_LAAT_USER: 'GROUP_LAAT_USER',
    GROUP_CREDIT_COMMITTEE_SECRETARY: 'GROUP_CREDIT_COMMITTEE_SECRETARY',
    GROUP_DMT_USER: 'GROUP_DMT_USER',
    GROUP_BM: 'GROUP_BM',
    GROUP_RM: 'GROUP_RM',
    GROUP_ACCOUNTANT: 'GROUP_ACCOUNTANT',
    GROUP_SOC: 'GROUP_SOC',
    PRODUCT_READ: 'PRODUCT_READ',
    PRODUCT_EDIT: 'PRODUCT_EDIT',
    PRODUCT_CREATE: 'PRODUCT_CREATE',
    PRODUCT_LINE_OPTION: 'PRODUCT_LINE_OPTION',
    LOAN_READ_LIST: 'LOAN_READ_LIST',
    LOAN_READ_DETAILS: 'LOAN_READ_DETAILS',
    LOAN_READ_OPERATION_AUDIT_EVENTS: 'LOAN_READ_OPERATION_AUDIT_EVENTS',
    LOAN_READ_PAYMENT_SCHEDULE: 'LOAN_READ_PAYMENT_SCHEDULE',
    LOAN_MANAGE_BORROWER_DEATH_STATUS: 'LOAN_MANAGE_BORROWER_DEATH_STATUS',
    LOAN_MANAGE_LAWSUIT_STATUS: 'LOAN_MANAGE_LAWSUIT_STATUS',
    LOAN_MANAGE_LOAN_STATUS: 'LOAN_MANAGE_LOAN_STATUS',
    LOAN_SET_CIB_STATUS_NORMAL: 'LOAN_SET_CIB_STATUS_NORMAL',
    LOAN_UPDATE_RELATIONSHIP_MANAGER: 'LOAN_UPDATE_RELATIONSHIP_MANAGER',
    LOAN_MANAGE_COLLATERALS: 'LOAN_MANAGE_COLLATERALS',
    LOAN_SETTINGS_DAYS_OVERDUE_READ: 'LOAN_SETTINGS_DAYS_OVERDUE_READ',
    LOAN_SETTINGS_PROVISION_RATES_READ: 'LOAN_SETTINGS_PROVISION_RATES_READ',
    LOAN_SETTINGS_SCORING_READ: 'LOAN_SETTINGS_SCORING_READ',
    LOAN_SETTINGS_PRODUCT_LIMIT_RISK_PRICE_READ: 'LOAN_SETTINGS_PRODUCT_LIMIT_RISK_PRICE_READ',
    LOAN_SETTINGS_RISKY_GROUP_READ: 'LOAN_SETTINGS_RISKY_GROUP_READ',
    LOAN_SETTINGS_VERIFICATION_READ: 'LOAN_SETTINGS_VERIFICATION_READ',
    LOAN_SETTINGS_BNPL_RISK_COEFFICIENT_READ: 'LOAN_SETTINGS_BNPL_RISK_COEFFICIENT_READ',
    LOAN_SETTINGS_BNPL_INCOME_AMOUNT_RANGE_READ: 'LOAN_SETTINGS_BNPL_INCOME_AMOUNT_RANGE_READ',
    LOAN_SETTINGS_TRUST_DEFAULT_PRODUCT_READ: 'LOAN_SETTINGS_TRUST_DEFAULT_PRODUCT_READ',
    LOAN_SETTINGS_DAYS_OVERDUE_EDIT: 'LOAN_SETTINGS_DAYS_OVERDUE_EDIT',
    LOAN_SETTINGS_PROVISION_RATES_EDIT: 'LOAN_SETTINGS_PROVISION_RATES_EDIT',
    LOAN_SETTINGS_SCORING_EDIT: 'LOAN_SETTINGS_SCORING_EDIT',
    LOAN_SETTINGS_PRODUCT_LIMIT_RISK_PRICE_EDIT: 'LOAN_SETTINGS_PRODUCT_LIMIT_RISK_PRICE_EDIT',
    LOAN_SETTINGS_RISKY_GROUP_EDIT: 'LOAN_SETTINGS_RISKY_GROUP_EDIT',
    LOAN_SETTINGS_VERIFICATION_EDIT: 'LOAN_SETTINGS_VERIFICATION_EDIT',
    LOAN_SETTINGS_BNPL_RISK_COEFFICIENT_EDIT: 'LOAN_SETTINGS_BNPL_RISK_COEFFICIENT_EDIT',
    LOAN_SETTINGS_BNPL_INCOME_AMOUNT_RANGE_EDIT: 'LOAN_SETTINGS_BNPL_INCOME_AMOUNT_RANGE_EDIT',
    LOAN_SETTINGS_TRUST_DEFAULT_PRODUCT_EDIT: 'LOAN_SETTINGS_TRUST_DEFAULT_PRODUCT_EDIT',
    CUSTOMER_SETTINGS_SEGMENTATION_READ: 'CUSTOMER_SETTINGS_SEGMENTATION_READ',
    CUSTOMER_SETTINGS_SEGMENTATION_EDIT: 'CUSTOMER_SETTINGS_SEGMENTATION_EDIT',
    RESTRICTION_LIST_READ: 'RESTRICTION_LIST_READ',
    RESTRICTION_LIST_WRITE: 'RESTRICTION_LIST_WRITE',
    RESTRICTION_LIST_REMOVE: 'RESTRICTION_LIST_REMOVE',
    APPLICATION_READ_LIST: 'APPLICATION_READ_LIST',
    APPLICATION_READ_GENERAL_INFORMATION: 'APPLICATION_READ_GENERAL_INFORMATION',
    APPLICATION_READ_REPAYMENT_SCHEDULE: 'APPLICATION_READ_REPAYMENT_SCHEDULE',
    APPLICATION_UPDATE_RELATIONSHIP_MANAGER: 'APPLICATION_UPDATE_RELATIONSHIP_MANAGER',
    CO_BORROWER_WRITE: 'CO_BORROWER_WRITE',
    COLLATERAL_READ_LIST: 'COLLATERAL_READ_LIST',
    COLLATERAL_READ_DETAILS: 'COLLATERAL_READ_DETAILS',
    COLLATERAL_EDIT: 'COLLATERAL_EDIT',
    COLLATERAL_CREATE: 'COLLATERAL_CREATE',
    INDIVIDUAL_READ: 'INDIVIDUAL_READ',
    INDIVIDUAL_READ_BRIEF_DETAILS: 'INDIVIDUAL_READ_BRIEF_DETAILS',
    INDIVIDUAL_READ_GENERAL_DETAILS: 'INDIVIDUAL_READ_GENERAL_DETAILS',
    INDIVIDUAL_READ_CS: 'INDIVIDUAL_READ_CS',
    INDIVIDUAL_READ_INCOME_PREVIEW: 'INDIVIDUAL_READ_INCOME_PREVIEW',
    INDIVIDUAL_READ_SALARY_INCOME: 'INDIVIDUAL_READ_SALARY_INCOME',
    INDIVIDUAL_READ_BUSINESS_INCOME: 'INDIVIDUAL_READ_BUSINESS_INCOME',
    INDIVIDUAL_READ_LIVESTOCK_INCOME: 'INDIVIDUAL_READ_LIVESTOCK_INCOME',
    INDIVIDUAL_READ_MONTHLY_INCOME: 'INDIVIDUAL_READ_MONTHLY_INCOME',
    INDIVIDUAL_READ_VERIFICATION_DECISIONS: 'INDIVIDUAL_READ_VERIFICATION_DECISIONS',
    INDIVIDUAL_READ_CREDIT_SCORING: 'INDIVIDUAL_READ_CREDIT_SCORING',
    INDIVIDUAL_READ_OTHER_INFORMATION: 'INDIVIDUAL_READ_OTHER_INFORMATION',
    INDIVIDUAL_READ_CIB_LOAN_DATA: 'INDIVIDUAL_READ_CIB_LOAN_DATA',
    INDIVIDUAL_READ_PRODUCT_OFFER: 'INDIVIDUAL_READ_PRODUCT_OFFER',
    INDIVIDUAL_CREATE: 'INDIVIDUAL_CREATE',
    INDIVIDUAL_UPDATE_GENERAL_INFO: 'INDIVIDUAL_UPDATE_GENERAL_INFO',
    INDIVIDUAL_UPDATE_REFERENCES: 'INDIVIDUAL_UPDATE_REFERENCES',
    INDIVIDUAL_UPDATE_RELATIONSHIP_MANAGER: 'INDIVIDUAL_UPDATE_RELATIONSHIP_MANAGER',
    INDIVIDUAL_UPDATE_JOB_INFO: 'INDIVIDUAL_UPDATE_JOB_INFO',
    INDIVIDUAL_UPDATE_CONTACT_DETAILS: 'INDIVIDUAL_UPDATE_CONTACT_DETAILS',
    INDIVIDUAL_UPDATE_BANK_ACCOUNTS: 'INDIVIDUAL_UPDATE_BANK_ACCOUNTS',
    INDIVIDUAL_UPDATE_OTHER_INFO: 'INDIVIDUAL_UPDATE_OTHER_INFO',
    INDIVIDUAL_ADD_INCOME: 'INDIVIDUAL_ADD_INCOME',
    DOCUMENT_COLLATERAL_READ: 'DOCUMENT_COLLATERAL_READ',
    DOCUMENT_COLLATERAL_UPLOAD: 'DOCUMENT_COLLATERAL_UPLOAD',
    DOCUMENT_COLLATERAL_DELETE: 'DOCUMENT_COLLATERAL_DELETE',
    DOCUMENT_CUSTOMER_READ: 'DOCUMENT_CUSTOMER_READ',
    DOCUMENT_CUSTOMER_UPLOAD: 'DOCUMENT_CUSTOMER_UPLOAD',
    DOCUMENT_CUSTOMER_DELETE: 'DOCUMENT_CUSTOMER_DELETE',
    DOCUMENT_LEGAL_ENTITY_READ: 'DOCUMENT_LEGAL_ENTITY_READ',
    DOCUMENT_LEGAL_ENTITY_UPLOAD: 'DOCUMENT_LEGAL_ENTITY_UPLOAD',
    DOCUMENT_LEGAL_ENTITY_DELETE: 'DOCUMENT_LEGAL_ENTITY_DELETE',
    DOCUMENT_LOAN_APPLICATION_READ: 'DOCUMENT_LOAN_APPLICATION_READ',
    DOCUMENT_LOAN_APPLICATION_UPLOAD: 'DOCUMENT_LOAN_APPLICATION_UPLOAD',
    DOCUMENT_LOAN_APPLICATION_DELETE: 'DOCUMENT_LOAN_APPLICATION_DELETE',
    DOCUMENT_LOAN_READ: 'DOCUMENT_LOAN_READ',
    DOCUMENT_LOAN_UPLOAD: 'DOCUMENT_LOAN_UPLOAD',
    DOCUMENT_LOAN_DELETE: 'DOCUMENT_LOAN_DELETE',
    DOCUMENT_TEMPLATE_READ: 'DOCUMENT_TEMPLATE_READ',
    DOCUMENT_TEMPLATE_EDIT: 'DOCUMENT_TEMPLATE_EDIT',
    LEGAL_READ_LIST: 'LEGAL_READ_LIST',
    LEGAL_READ_DETAILS: 'LEGAL_READ_DETAILS',
    LEGAL_CREATE: 'LEGAL_CREATE',
    LEGAL_EDIT: 'LEGAL_EDIT',
    LEGAL_READ_LOAN_LIST: 'LEGAL_READ_LOAN_LIST',
    LEGAL_CREATE_REPRESENTATIVE: 'LEGAL_CREATE_REPRESENTATIVE',
    LEGAL_DELETE_REPRESENTATIVE: 'LEGAL_DELETE_REPRESENTATIVE',
    LEGAL_UPDATE_REPRESENTATIVE: 'LEGAL_UPDATE_REPRESENTATIVE',
    MERCHANT_READ_LIST: 'MERCHANT_READ_LIST',
    MERCHANT_READ_DETAILS: 'MERCHANT_READ_DETAILS',
    MERCHANT_CREATE: 'MERCHANT_CREATE',
    MERCHANT_EDIT: 'MERCHANT_EDIT',
    ROLE_OPTION: 'ROLE_OPTION',
    ROLE_READ_LIST: 'ROLE_READ_LIST',
    ROLE_READ_DETAILS: 'ROLE_READ_DETAILS',
    ROLE_CREATE: 'ROLE_CREATE',
    ROLE_EDIT: 'ROLE_EDIT',
    USER_READ_LIST: 'USER_READ_LIST',
    USER_READ_DETAILS: 'USER_READ_DETAILS',
    USER_CREATE: 'USER_CREATE',
    USER_EDIT: 'USER_EDIT',
    USER_ACTIVITY_UPDATE: 'USER_ACTIVITY_UPDATE',
    USER_ACTIVATE: 'USER_ACTIVATE',
    USER_DEACTIVATE: 'USER_DEACTIVATE',
    USER_RESEND_LINK: 'USER_RESEND_LINK',
    LOAN_PROPOSE_INTEREST_ACCRUAL_STOP_RENEW: 'LOAN_PROPOSE_INTEREST_ACCRUAL_STOP_RENEW',
    LOAN_PROPOSE_WRITE_OFF: 'LOAN_PROPOSE_WRITE_OFF',
    LOAN_PROPOSE_PAST_DATE_PAYMENT: 'LOAN_PROPOSE_PAST_DATE_PAYMENT',
    LOAN_PROPOSE_PAYMENT_CANCELLATION: 'LOAN_PROPOSE_PAYMENT_CANCELLATION',
    LOAN_PROPOSE_GRACE_PERIOD: 'LOAN_PROPOSE_GRACE_PERIOD',
    LOAN_PROPOSE_ADDITIONAL_LOAN_ISSUING: 'LOAN_PROPOSE_ADDITIONAL_LOAN_ISSUING',
    LOAN_PROPOSE_REFINANCING: 'LOAN_PROPOSE_REFINANCING',
    LOAN_PROPOSE_PAYMENT_AMOUNT_CHANGING: 'LOAN_PROPOSE_PAYMENT_AMOUNT_CHANGING',
    LOAN_PROPOSE_INTEREST_RATE_CHANGING: 'LOAN_PROPOSE_INTEREST_RATE_CHANGING',
    LOAN_PROPOSE_PAYMENT_DATE_CHANGING: 'LOAN_PROPOSE_PAYMENT_DATE_CHANGING',
    LOAN_PROPOSE_NEW_GRACE_PERIOD_ALLOWING: 'LOAN_PROPOSE_NEW_GRACE_PERIOD_ALLOWING',
    LOAN_PROPOSE_MATURITY_DATE_EXTENSION: 'LOAN_PROPOSE_MATURITY_DATE_EXTENSION',
    LOAN_PROPOSE_INTEREST_ADJUSTMENT: 'LOAN_PROPOSE_INTEREST_ADJUSTMENT',
    LOAN_PROPOSE_PENALTY_ADJUSTMENT: 'LOAN_PROPOSE_PENALTY_ADJUSTMENT',
    LOAN_REVIEW_INTEREST_ACCRUAL_STOP_RENEW: 'LOAN_REVIEW_INTEREST_ACCRUAL_STOP_RENEW',
    LOAN_REVIEW_WRITE_OFF: 'LOAN_REVIEW_WRITE_OFF',
    LOAN_REVIEW_PAST_DATE_PAYMENT: 'LOAN_REVIEW_PAST_DATE_PAYMENT',
    LOAN_REVIEW_PAYMENT_CANCELLATION: 'LOAN_REVIEW_PAYMENT_CANCELLATION',
    LOAN_REVIEW_GRACE_PERIOD: 'LOAN_REVIEW_GRACE_PERIOD',
    LOAN_REVIEW_ADDITIONAL_LOAN_ISSUING: 'LOAN_REVIEW_ADDITIONAL_LOAN_ISSUING',
    LOAN_REVIEW_REFINANCING: 'LOAN_REVIEW_REFINANCING',
    LOAN_REVIEW_PAYMENT_AMOUNT_CHANGING: 'LOAN_REVIEW_PAYMENT_AMOUNT_CHANGING',
    LOAN_REVIEW_INTEREST_RATE_CHANGING: 'LOAN_REVIEW_INTEREST_RATE_CHANGING',
    LOAN_REVIEW_PAYMENT_DATE_CHANGING: 'LOAN_REVIEW_PAYMENT_DATE_CHANGING',
    LOAN_REVIEW_NEW_GRACE_PERIOD_ALLOWING: 'LOAN_REVIEW_NEW_GRACE_PERIOD_ALLOWING',
    LOAN_REVIEW_MATURITY_DATE_EXTENSION: 'LOAN_REVIEW_MATURITY_DATE_EXTENSION',
    LOAN_REVIEW_INTEREST_ADJUSTMENT: 'LOAN_REVIEW_INTEREST_ADJUSTMENT',
    LOAN_REVIEW_PENALTY_ADJUSTMENT: 'LOAN_REVIEW_PENALTY_ADJUSTMENT',
    BANK_STATEMENT_READ_LIST: 'BANK_STATEMENT_READ_LIST',
    BANK_STATEMENT_READ_DETAILS: 'BANK_STATEMENT_READ_DETAILS',
    BANK_STATEMENT_ASSIGN_TO_LOAN: 'BANK_STATEMENT_ASSIGN_TO_LOAN',
    BANK_STATEMENT_CHANGE_STATUS: 'BANK_STATEMENT_CHANGE_STATUS',
    TASK_READ_UNASSIGNED_LIST: 'TASK_READ_UNASSIGNED_LIST',
    TASK_ASSIGN: 'TASK_ASSIGN',
    TASK_FALLBACK_ASSIGN: 'TASK_FALLBACK_ASSIGN',
    DEBT_COLLECTION_ASSIGNMENT_SETTINGS: 'DEBT_COLLECTION_ASSIGNMENT_SETTINGS',
    DEBT_COLLECTION_READ_LIST: 'DEBT_COLLECTION_READ_LIST',
    DEBT_COLLECTION_RE_ASSIGNMENT: 'DEBT_COLLECTION_RE_ASSIGNMENT',
    DEBT_COLLECTION_READ_DETAILS: 'DEBT_COLLECTION_READ_DETAILS',
    DEBT_COLLECTION_READ_OWN_LIST: 'DEBT_COLLECTION_READ_OWN_LIST',
    DEBT_COLLECTION_EDIT_LEVEL: 'DEBT_COLLECTION_EDIT_LEVEL',
    DEBT_COLLECTION_READ_ACTIVITY_LIST: 'DEBT_COLLECTION_READ_ACTIVITY_LIST',
    DEBT_COLLECTION_READ_ACTIVITY_DETAILS: 'DEBT_COLLECTION_READ_ACTIVITY_DETAILS',
    DEBT_COLLECTION_RECORD_ACTIVITY: 'DEBT_COLLECTION_RECORD_ACTIVITY',
    DEBT_COLLECTION_LITIGATION_SPECIALIST: 'DEBT_COLLECTION_LITIGATION_SPECIALIST',
    DEBT_COLLECTION_DEBT_COLLECTOR: 'DEBT_COLLECTION_DEBT_COLLECTOR',
    DEBT_COLLECTION_PART_TIME_WORKER: 'DEBT_COLLECTION_PART_TIME_WORKER',
    ADMIN: 'ADMIN'
} as const;

export type AuthorityHierarchy = typeof AuthorityHierarchy[keyof typeof AuthorityHierarchy];


/**
 * 
 * @export
 * @interface AuthorityListEntry
 */
export interface AuthorityListEntry {
    /**
     * 
     * @type {AuthorityHierarchy}
     * @memberof AuthorityListEntry
     */
    'authority'?: AuthorityHierarchy;
    /**
     * 
     * @type {Array<AuthorityHierarchy>}
     * @memberof AuthorityListEntry
     */
    'enablesAuthorities'?: Array<AuthorityHierarchy>;
    /**
     * 
     * @type {string}
     * @memberof AuthorityListEntry
     */
    'value'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const BankInformation = {
    MONGOLBANK: 'MONGOLBANK',
    CAPITAL_BANK: 'CAPITAL_BANK',
    TRADE_AND_DEVELOPMENT_BANK: 'TRADE_AND_DEVELOPMENT_BANK',
    KHAN_BANK: 'KHAN_BANK',
    GOLOMT_BANK: 'GOLOMT_BANK',
    TRANSPORT_AND_DEVELOPMENT_BANK: 'TRANSPORT_AND_DEVELOPMENT_BANK',
    ARIG_BANK: 'ARIG_BANK',
    CREDIT_BANK: 'CREDIT_BANK',
    ULAANBAATAR_CITY_BANK: 'ULAANBAATAR_CITY_BANK',
    NATIONAL_INVESTMENT_BANK_OF_MONGOLIA: 'NATIONAL_INVESTMENT_BANK_OF_MONGOLIA',
    CAPITRON_BANK: 'CAPITRON_BANK',
    KHAS_BANK: 'KHAS_BANK',
    CHINGGIS_KHAAN_BANK: 'CHINGGIS_KHAAN_BANK',
    STATE_BANK: 'STATE_BANK',
    BOGD_BANK: 'BOGD_BANK',
    M_BANK: 'M_BANK',
    MOBIFINANCE_NBFI: 'MOBIFINANCE_NBFI',
    HIGH_PAYMENT_SOLUTIONS_LLC: 'HIGH_PAYMENT_SOLUTIONS_LLC',
    ARD_CREDIT_NBFI: 'ARD_CREDIT_NBFI'
} as const;

export type BankInformation = typeof BankInformation[keyof typeof BankInformation];


/**
 * 
 * @export
 * @interface BankInformationDto
 */
export interface BankInformationDto {
    /**
     * 
     * @type {string}
     * @memberof BankInformationDto
     */
    'bankCode'?: string;
    /**
     * 
     * @type {BankInformation}
     * @memberof BankInformationDto
     */
    'id'?: BankInformation;
    /**
     * 
     * @type {Localization}
     * @memberof BankInformationDto
     */
    'l10n'?: Localization;
}


/**
 * 
 * @export
 * @interface BankStatementListItem
 */
export interface BankStatementListItem {
    /**
     * 
     * @type {string}
     * @memberof BankStatementListItem
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankStatementListItem
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof BankStatementListItem
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof BankStatementListItem
     */
    'balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof BankStatementListItem
     */
    'bankCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankStatementListItem
     */
    'comment'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof BankStatementListItem
     */
    'currencyCode': Currency;
    /**
     * 
     * @type {string}
     * @memberof BankStatementListItem
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof BankStatementListItem
     */
    'id': number;
    /**
     * 
     * @type {BankStatementOperationType}
     * @memberof BankStatementListItem
     */
    'operationType': BankStatementOperationType;
    /**
     * 
     * @type {string}
     * @memberof BankStatementListItem
     */
    'selfAccountNo'?: string;
    /**
     * 
     * @type {BankStatementStatus}
     * @memberof BankStatementListItem
     */
    'status': BankStatementStatus;
    /**
     * 
     * @type {string}
     * @memberof BankStatementListItem
     */
    'transactionDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof BankStatementListItem
     */
    'type': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const BankStatementOperationType = {
    LOAN_REPAYMENT: 'LOAN_REPAYMENT',
    LOAN_DISBURSEMENT: 'LOAN_DISBURSEMENT',
    LOAN_FEE_AND_COMMISSION: 'LOAN_FEE_AND_COMMISSION',
    DOWN_PAYMENT: 'DOWN_PAYMENT',
    LOAN_LIMIT_CALCULATION_FEE: 'LOAN_LIMIT_CALCULATION_FEE',
    INSURANCE_FEE: 'INSURANCE_FEE'
} as const;

export type BankStatementOperationType = typeof BankStatementOperationType[keyof typeof BankStatementOperationType];


/**
 * 
 * @export
 * @enum {string}
 */

export const BankStatementStatus = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    CANCELLED: 'CANCELLED'
} as const;

export type BankStatementStatus = typeof BankStatementStatus[keyof typeof BankStatementStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const BnplIncomeAmountRangeColumnKey = {
    INCOME_AMOUNT_RANGE_FROM: 'INCOME_AMOUNT_RANGE_FROM',
    INCOME_AMOUNT_RANGE_TO: 'INCOME_AMOUNT_RANGE_TO'
} as const;

export type BnplIncomeAmountRangeColumnKey = typeof BnplIncomeAmountRangeColumnKey[keyof typeof BnplIncomeAmountRangeColumnKey];


/**
 * 
 * @export
 * @interface BnplIncomeAmountRangeColumnMetadata
 */
export interface BnplIncomeAmountRangeColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof BnplIncomeAmountRangeColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {BnplIncomeAmountRangeColumnKey}
     * @memberof BnplIncomeAmountRangeColumnMetadata
     */
    'key'?: BnplIncomeAmountRangeColumnKey;
    /**
     * 
     * @type {object}
     * @memberof BnplIncomeAmountRangeColumnMetadata
     */
    'payload'?: object;
}


/**
 * 
 * @export
 * @interface BnplIncomeAmountRangeDto
 */
export interface BnplIncomeAmountRangeDto extends Settings {
    /**
     * 
     * @type {Array<BnplIncomeAmountRangeColumnMetadata>}
     * @memberof BnplIncomeAmountRangeDto
     */
    'columns'?: Array<BnplIncomeAmountRangeColumnMetadata>;
    /**
     * 
     * @type {Array<BnplIncomeAmountRangeRow>}
     * @memberof BnplIncomeAmountRangeDto
     */
    'rows': Array<BnplIncomeAmountRangeRow>;
}
/**
 * 
 * @export
 * @interface BnplIncomeAmountRangeRow
 */
export interface BnplIncomeAmountRangeRow {
    /**
     * 
     * @type {number}
     * @memberof BnplIncomeAmountRangeRow
     */
    'incomeAmountRangeFrom': number;
    /**
     * 
     * @type {number}
     * @memberof BnplIncomeAmountRangeRow
     */
    'incomeAmountRangeTo': number;
}
/**
 * 
 * @export
 * @interface BnplLoan
 */
export interface BnplLoan extends Loan {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof BnplLoan
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'applicationId': number;
    /**
     * 
     * @type {string}
     * @memberof BnplLoan
     */
    'branchCode': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof BnplLoan
     */
    'branchManager'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof BnplLoan
     */
    'branchName': string;
    /**
     * 
     * @type {string}
     * @memberof BnplLoan
     */
    'cibSentDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BnplLoan
     */
    'cibSentStatus': boolean;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'commentRoomId': number;
    /**
     * 
     * @type {CreditBureauStatus}
     * @memberof BnplLoan
     */
    'creditBureauStatus': CreditBureauStatus;
    /**
     * 
     * @type {Customer}
     * @memberof BnplLoan
     */
    'customer': Customer;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'daysOverdue': number;
    /**
     * 
     * @type {string}
     * @memberof BnplLoan
     */
    'disbursementDate': string;
    /**
     * 
     * @type {IdEntry}
     * @memberof BnplLoan
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'downPayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'exceedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof BnplLoan
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof BnplLoan
     */
    'firstRepaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'incomeAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'interestRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'lastPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof BnplLoan
     */
    'lastPaymentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BnplLoan
     */
    'loanPurpose'?: string;
    /**
     * 
     * @type {LoanStatus}
     * @memberof BnplLoan
     */
    'loanStatus': LoanStatus;
    /**
     * 
     * @type {string}
     * @memberof BnplLoan
     */
    'maturityDate': string;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'nextPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof BnplLoan
     */
    'nextPaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'overdueAdvancePayment': number;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'principalBalance': number;
    /**
     * 
     * @type {ProductLoanInfo}
     * @memberof BnplLoan
     */
    'product': ProductLoanInfo;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof BnplLoan
     */
    'relationshipManager': IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'repaymentAmount': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof BnplLoan
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {string}
     * @memberof BnplLoan
     */
    'secondRepaymentDate'?: string;
    /**
     * 
     * @type {ApplicationSource}
     * @memberof BnplLoan
     */
    'source': ApplicationSource;
    /**
     * 
     * @type {number}
     * @memberof BnplLoan
     */
    'term': number;
}


/**
 * 
 * @export
 * @interface BnplLoanApplication
 */
export interface BnplLoanApplication extends LoanApplication {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof BnplLoanApplication
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'averageIncome': number;
    /**
     * 
     * @type {string}
     * @memberof BnplLoanApplication
     */
    'bankAccountNumber': string;
    /**
     * 
     * @type {BankInformationDto}
     * @memberof BnplLoanApplication
     */
    'bankInformation': BankInformationDto;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'commentRoomId': number;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'creditScore': number;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof BnplLoanApplication
     */
    'disbursementDate'?: string;
    /**
     * 
     * @type {IdEntry}
     * @memberof BnplLoanApplication
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {string}
     * @memberof BnplLoanApplication
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'firstPaymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof BnplLoanApplication
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'id': number;
    /**
     * 
     * @type {Array<CustomerIncomeType>}
     * @memberof BnplLoanApplication
     */
    'incomeTypes': Array<CustomerIncomeType>;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'limitOffer': number;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'loanId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BnplLoanApplication
     */
    'loanPurpose': string;
    /**
     * 
     * @type {string}
     * @memberof BnplLoanApplication
     */
    'maturityDate': string;
    /**
     * 
     * @type {string}
     * @memberof BnplLoanApplication
     */
    'merchantName'?: string;
    /**
     * 
     * @type {ProductBrief}
     * @memberof BnplLoanApplication
     */
    'product': ProductBrief;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof BnplLoanApplication
     */
    'relationshipManager': IdNameEntry;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof BnplLoanApplication
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'requestedAmount': number;
    /**
     * 
     * @type {string}
     * @memberof BnplLoanApplication
     */
    'requestedAt': string;
    /**
     * 
     * @type {number}
     * @memberof BnplLoanApplication
     */
    'requestedTerm': number;
    /**
     * 
     * @type {ApplicationSource}
     * @memberof BnplLoanApplication
     */
    'source': ApplicationSource;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof BnplLoanApplication
     */
    'status': LoanApplicationStatus;
}


/**
 * 
 * @export
 * @interface BnplProduct
 */
export interface BnplProduct extends Product {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof BnplProduct
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {boolean}
     * @memberof BnplProduct
     */
    'excludedFromOffer': boolean;
    /**
     * 
     * @type {number}
     * @memberof BnplProduct
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof BnplProduct
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof BnplProduct
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof BnplProduct
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BnplProduct
     */
    'productLineId': number;
    /**
     * 
     * @type {ProductLine}
     * @memberof BnplProduct
     */
    'reOpeningProductLine': ProductLine;
    /**
     * 
     * @type {number}
     * @memberof BnplProduct
     */
    'reOpeningRepaymentsQuantity': number;
    /**
     * 
     * @type {ProductStatus}
     * @memberof BnplProduct
     */
    'status': ProductStatus;
    /**
     * 
     * @type {number}
     * @memberof BnplProduct
     */
    'transferringFee': number;
}


/**
 * 
 * @export
 * @interface BnplProductListEntry
 */
export interface BnplProductListEntry {
    /**
     * 
     * @type {number}
     * @memberof BnplProductListEntry
     */
    'externalId': number;
    /**
     * 
     * @type {number}
     * @memberof BnplProductListEntry
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BnplProductListEntry
     */
    'name': string;
    /**
     * 
     * @type {ProductStatus}
     * @memberof BnplProductListEntry
     */
    'status': ProductStatus;
    /**
     * 
     * @type {number}
     * @memberof BnplProductListEntry
     */
    'transferringFee': number;
    /**
     * 
     * @type {number}
     * @memberof BnplProductListEntry
     */
    'version': number;
}


/**
 * 
 * @export
 * @interface BnplProductUpdate
 */
export interface BnplProductUpdate extends ProductUpdate {
    /**
     * 
     * @type {number}
     * @memberof BnplProductUpdate
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof BnplProductUpdate
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof BnplProductUpdate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BnplProductUpdate
     */
    'reOpeningProductLineId': number;
    /**
     * 
     * @type {number}
     * @memberof BnplProductUpdate
     */
    'reOpeningRepaymentsQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof BnplProductUpdate
     */
    'transferringFee': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BnplRiskCoefficientColumnKey = {
    BNPL_RISK_COEFFICIENT_SETTING: 'BNPL_RISK_COEFFICIENT_SETTING',
    RISK_COEFFICIENT: 'RISK_COEFFICIENT'
} as const;

export type BnplRiskCoefficientColumnKey = typeof BnplRiskCoefficientColumnKey[keyof typeof BnplRiskCoefficientColumnKey];


/**
 * 
 * @export
 * @interface BnplRiskCoefficientColumnMetadata
 */
export interface BnplRiskCoefficientColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof BnplRiskCoefficientColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {BnplRiskCoefficientColumnKey}
     * @memberof BnplRiskCoefficientColumnMetadata
     */
    'key'?: BnplRiskCoefficientColumnKey;
    /**
     * 
     * @type {object}
     * @memberof BnplRiskCoefficientColumnMetadata
     */
    'payload'?: object;
}


/**
 * 
 * @export
 * @interface BnplRiskCoefficientDto
 */
export interface BnplRiskCoefficientDto extends Settings {
    /**
     * 
     * @type {Array<BnplRiskCoefficientColumnMetadata>}
     * @memberof BnplRiskCoefficientDto
     */
    'columns'?: Array<BnplRiskCoefficientColumnMetadata>;
    /**
     * 
     * @type {Array<BnplRiskCoefficientRow>}
     * @memberof BnplRiskCoefficientDto
     */
    'rows'?: Array<BnplRiskCoefficientRow>;
}
/**
 * 
 * @export
 * @interface BnplRiskCoefficientRow
 */
export interface BnplRiskCoefficientRow {
    /**
     * 
     * @type {BnplRiskCoefficientSetting}
     * @memberof BnplRiskCoefficientRow
     */
    'bnplRiskCoefficientSetting': BnplRiskCoefficientSetting;
    /**
     * 
     * @type {number}
     * @memberof BnplRiskCoefficientRow
     */
    'riskCoefficient': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const BnplRiskCoefficientSetting = {
    BNPL_RISK_COEFFICIENT_SETTING: 'BNPL_RISK_COEFFICIENT_SETTING'
} as const;

export type BnplRiskCoefficientSetting = typeof BnplRiskCoefficientSetting[keyof typeof BnplRiskCoefficientSetting];


/**
 * 
 * @export
 * @interface BoUserAssigneeEntry
 */
export interface BoUserAssigneeEntry {
    /**
     * 
     * @type {UserActivity}
     * @memberof BoUserAssigneeEntry
     */
    'activity': UserActivity;
    /**
     * 
     * @type {number}
     * @memberof BoUserAssigneeEntry
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BoUserAssigneeEntry
     */
    'name': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const BoUserDepartment = {
    ACCOUNTING: 'ACCOUNTING',
    AUDIT_DEPARTMENT: 'AUDIT_DEPARTMENT',
    BRANCH_MANAGEMENT_DEPARTMENT: 'BRANCH_MANAGEMENT_DEPARTMENT',
    BUSINESS_DEVELOPMENT_AND_MARKETING_DEPARTMENT: 'BUSINESS_DEVELOPMENT_AND_MARKETING_DEPARTMENT',
    CUSTOMER_SERVICE_DEPARTMENT: 'CUSTOMER_SERVICE_DEPARTMENT',
    FINANCIAL_DEPARTMENT: 'FINANCIAL_DEPARTMENT',
    FINANCIAL_SERVICES: 'FINANCIAL_SERVICES',
    HUMAN_RESOURCE_DEPARTMENT: 'HUMAN_RESOURCE_DEPARTMENT',
    IT_AND_FINTECH_DEPARTMENT: 'IT_AND_FINTECH_DEPARTMENT',
    LEGAL_DIVISION: 'LEGAL_DIVISION',
    RISK_DIVISION: 'RISK_DIVISION',
    SPECIAL_ASSET_DIVISION: 'SPECIAL_ASSET_DIVISION'
} as const;

export type BoUserDepartment = typeof BoUserDepartment[keyof typeof BoUserDepartment];


/**
 * 
 * @export
 * @interface BusinessIncomeListEntry
 */
export interface BusinessIncomeListEntry {
    /**
     * 
     * @type {IncomeCheckStatus}
     * @memberof BusinessIncomeListEntry
     */
    'checkStatus': IncomeCheckStatus;
    /**
     * 
     * @type {string}
     * @memberof BusinessIncomeListEntry
     */
    'createdAt': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof BusinessIncomeListEntry
     */
    'createdBy'?: IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof BusinessIncomeListEntry
     */
    'incomeAmount': number;
}


/**
 * 
 * @export
 * @interface CalculateLoanStateRequest
 */
export interface CalculateLoanStateRequest {
    /**
     * 
     * @type {string}
     * @memberof CalculateLoanStateRequest
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface CarCollateral
 */
export interface CarCollateral extends Collateral {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof CarCollateral
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {string}
     * @memberof CarCollateral
     */
    'carBrand'?: string;
    /**
     * 
     * @type {CarCollateralCategory}
     * @memberof CarCollateral
     */
    'category'?: CarCollateralCategory;
    /**
     * 
     * @type {string}
     * @memberof CarCollateral
     */
    'chassisNumber'?: string;
    /**
     * 
     * @type {Array<CollateralCoOwner>}
     * @memberof CarCollateral
     */
    'coOwners': Array<CollateralCoOwner>;
    /**
     * 
     * @type {string}
     * @memberof CarCollateral
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarCollateral
     */
    'commentRoomId': number;
    /**
     * 
     * @type {number}
     * @memberof CarCollateral
     */
    'dateOfManufacture'?: number;
    /**
     * 
     * @type {IdEntry}
     * @memberof CarCollateral
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof CarCollateral
     */
    'engineCapacity'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarCollateral
     */
    'engineType'?: string;
    /**
     * 
     * @type {Evaluation}
     * @memberof CarCollateral
     */
    'evaluation'?: Evaluation;
    /**
     * 
     * @type {number}
     * @memberof CarCollateral
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CarCollateral
     */
    'importedDate'?: string;
    /**
     * 
     * @type {Array<Insurance>}
     * @memberof CarCollateral
     */
    'insurances': Array<Insurance>;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof CarCollateral
     */
    'loans': Array<IdNameEntry>;
    /**
     * 
     * @type {number}
     * @memberof CarCollateral
     */
    'mileage'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarCollateral
     */
    'model'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarCollateral
     */
    'overallGrade'?: string;
    /**
     * 
     * @type {Array<CollateralOwner>}
     * @memberof CarCollateral
     */
    'owners': Array<CollateralOwner>;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof CarCollateral
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof CarCollateral
     */
    'registrationNumber': string;
    /**
     * 
     * @type {SteeringWheel}
     * @memberof CarCollateral
     */
    'steeringWheel'?: SteeringWheel;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof CarCollateral
     */
    'subType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof CarCollateral
     */
    'type': CollateralType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CarCollateralCategory = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E'
} as const;

export type CarCollateralCategory = typeof CarCollateralCategory[keyof typeof CarCollateralCategory];


/**
 * 
 * @export
 * @interface CarCollateralPatch
 */
export interface CarCollateralPatch extends CollateralPatch {
    /**
     * 
     * @type {string}
     * @memberof CarCollateralPatch
     */
    'carBrand': string;
    /**
     * 
     * @type {CarCollateralCategory}
     * @memberof CarCollateralPatch
     */
    'category'?: CarCollateralCategory;
    /**
     * 
     * @type {string}
     * @memberof CarCollateralPatch
     */
    'chassisNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CarCollateralPatch
     */
    'color': string;
    /**
     * 
     * @type {number}
     * @memberof CarCollateralPatch
     */
    'dateOfManufacture': number;
    /**
     * 
     * @type {number}
     * @memberof CarCollateralPatch
     */
    'engineCapacity': number;
    /**
     * 
     * @type {string}
     * @memberof CarCollateralPatch
     */
    'engineType': string;
    /**
     * 
     * @type {string}
     * @memberof CarCollateralPatch
     */
    'importedDate': string;
    /**
     * 
     * @type {number}
     * @memberof CarCollateralPatch
     */
    'mileage': number;
    /**
     * 
     * @type {string}
     * @memberof CarCollateralPatch
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof CarCollateralPatch
     */
    'overallGrade'?: string;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof CarCollateralPatch
     */
    'ownershipType': CollateralOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof CarCollateralPatch
     */
    'registrationNumber': string;
    /**
     * 
     * @type {SteeringWheel}
     * @memberof CarCollateralPatch
     */
    'steeringWheel'?: SteeringWheel;
}


/**
 * 
 * @export
 * @interface CeoDetails
 */
export interface CeoDetails {
    /**
     * 
     * @type {string}
     * @memberof CeoDetails
     */
    'birthDate'?: string;
    /**
     * 
     * @type {Citizenship}
     * @memberof CeoDetails
     */
    'citizenship'?: Citizenship;
    /**
     * 
     * @type {string}
     * @memberof CeoDetails
     */
    'civilId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CeoDetails
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CeoDetails
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CeoDetails
     */
    'pep': boolean;
    /**
     * 
     * @type {string}
     * @memberof CeoDetails
     */
    'registrationNumber': string;
}


/**
 * 
 * @export
 * @interface ChangeBankStatementStatusForm
 */
export interface ChangeBankStatementStatusForm {
    /**
     * 
     * @type {BankStatementStatus}
     * @memberof ChangeBankStatementStatusForm
     */
    'bankStatementStatus': BankStatementStatus;
    /**
     * 
     * @type {string}
     * @memberof ChangeBankStatementStatusForm
     */
    'comment'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CheckServiceName = {
    AGE: 'AGE',
    BLACK_LIST: 'BLACK_LIST',
    SCORING: 'SCORING',
    SALARY_INCOME: 'SALARY_INCOME',
    BUSINESS_INCOME: 'BUSINESS_INCOME',
    LIVESTOCK_INCOME: 'LIVESTOCK_INCOME',
    CREDIT_HISTORY: 'CREDIT_HISTORY'
} as const;

export type CheckServiceName = typeof CheckServiceName[keyof typeof CheckServiceName];


/**
 * 
 * @export
 * @enum {string}
 */

export const CheckStepName = {
    AGE_CHECK: 'AGE_CHECK',
    BLACK_LIST_CHECK: 'BLACK_LIST_CHECK',
    SCORING_CHECK: 'SCORING_CHECK',
    SALARY_REPORT_CONTAINS_X_MONTHS_CHECK: 'SALARY_REPORT_CONTAINS_X_MONTHS_CHECK',
    SALARY_PAID_BY_SAME_ENTITY_LAST_X_MONTHS_CHECK: 'SALARY_PAID_BY_SAME_ENTITY_LAST_X_MONTHS_CHECK',
    SALARY_AVERAGE_INCOME_LAST_X_MONTHS_CHECK: 'SALARY_AVERAGE_INCOME_LAST_X_MONTHS_CHECK',
    SALARY_AVERAGE_INCOME_LAST_TWO_MONTH_CHECK: 'SALARY_AVERAGE_INCOME_LAST_TWO_MONTH_CHECK',
    SALARY_REPORT_PROVIDED_FOR_X_MONTHS_CHECK: 'SALARY_REPORT_PROVIDED_FOR_X_MONTHS_CHECK',
    SALARY_REPORT_CONTAINS_REPAID_VOLUNTARILY_MONTH_CHECK: 'SALARY_REPORT_CONTAINS_REPAID_VOLUNTARILY_MONTH_CHECK',
    BUSINESS_AVERAGE_INCOME_CHECK: 'BUSINESS_AVERAGE_INCOME_CHECK',
    BUSINESS_AVERAGE_INCOME_LAST_SIX_MONTH_CHECK: 'BUSINESS_AVERAGE_INCOME_LAST_SIX_MONTH_CHECK',
    BUSINESS_AVERAGE_INCOME_LAST_TWO_MONTH_CHECK: 'BUSINESS_AVERAGE_INCOME_LAST_TWO_MONTH_CHECK',
    BUSINESS_INCOME_ASSESSMENT_DECISION_CHECK: 'BUSINESS_INCOME_ASSESSMENT_DECISION_CHECK',
    BUSINESS_REPORT_CONTAINS_SIX_MONTH_CHECK: 'BUSINESS_REPORT_CONTAINS_SIX_MONTH_CHECK',
    LIVESTOCK_COUNTED_IN_LAST_TWO_YEARS_CHECK: 'LIVESTOCK_COUNTED_IN_LAST_TWO_YEARS_CHECK',
    LIVESTOCK_HAS_VARIANCE_CHECK: 'LIVESTOCK_HAS_VARIANCE_CHECK',
    LIVESTOCK_TOTAL_COUNT_ENOUGH_CHECK: 'LIVESTOCK_TOTAL_COUNT_ENOUGH_CHECK',
    CIB_LOAN_DATA_NOT_NULL_CHECK: 'CIB_LOAN_DATA_NOT_NULL_CHECK',
    HAVE_ACTIVE_LOANS_IN_BAD_STATUS_CHECK: 'HAVE_ACTIVE_LOANS_IN_BAD_STATUS_CHECK',
    HAVE_INACTIVE_LOANS_IN_BAD_STATUSES_CHECK: 'HAVE_INACTIVE_LOANS_IN_BAD_STATUSES_CHECK',
    HAVE_MORE_THEN_X_ACTIVE_LOANS_CHECK: 'HAVE_MORE_THEN_X_ACTIVE_LOANS_CHECK',
    HAVE_MORE_THEN_X_DIFFERENT_ACTIVE_LOANS_CHECK: 'HAVE_MORE_THEN_X_DIFFERENT_ACTIVE_LOANS_CHECK'
} as const;

export type CheckStepName = typeof CheckStepName[keyof typeof CheckStepName];


/**
 * 
 * @export
 * @interface ChildBranch
 */
export interface ChildBranch {
    /**
     * 
     * @type {string}
     * @memberof ChildBranch
     */
    'activityAreas': string;
    /**
     * 
     * @type {Address}
     * @memberof ChildBranch
     */
    'address': Address;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof ChildBranch
     */
    'branchManager'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof ChildBranch
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof ChildBranch
     */
    'id': number;
    /**
     * 
     * @type {FileMetadata}
     * @memberof ChildBranch
     */
    'logoFile'?: FileMetadata;
    /**
     * 
     * @type {string}
     * @memberof ChildBranch
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ChildBranch
     */
    'parentBranchId': number;
    /**
     * 
     * @type {string}
     * @memberof ChildBranch
     */
    'phone': string;
    /**
     * 
     * @type {ChildBranchStatus}
     * @memberof ChildBranch
     */
    'status': ChildBranchStatus;
    /**
     * 
     * @type {WorkSchedule}
     * @memberof ChildBranch
     */
    'workSchedule': WorkSchedule;
}


/**
 * 
 * @export
 * @interface ChildBranchListEntry
 */
export interface ChildBranchListEntry {
    /**
     * 
     * @type {number}
     * @memberof ChildBranchListEntry
     */
    'id': number;
    /**
     * 
     * @type {FileMetadata}
     * @memberof ChildBranchListEntry
     */
    'logoFile'?: FileMetadata;
    /**
     * 
     * @type {string}
     * @memberof ChildBranchListEntry
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ChildBranchOption
 */
export interface ChildBranchOption {
    /**
     * 
     * @type {IdNameEntry}
     * @memberof ChildBranchOption
     */
    'branchManager': IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof ChildBranchOption
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildBranchOption
     */
    'name': string;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof ChildBranchOption
     */
    'relationshipManagers': Array<IdNameEntry>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChildBranchStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;

export type ChildBranchStatus = typeof ChildBranchStatus[keyof typeof ChildBranchStatus];


/**
 * 
 * @export
 * @interface ChildBranchUpdate
 */
export interface ChildBranchUpdate {
    /**
     * 
     * @type {string}
     * @memberof ChildBranchUpdate
     */
    'activityAreas': string;
    /**
     * 
     * @type {AddressUpdate}
     * @memberof ChildBranchUpdate
     */
    'address': AddressUpdate;
    /**
     * 
     * @type {string}
     * @memberof ChildBranchUpdate
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof ChildBranchUpdate
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildBranchUpdate
     */
    'logoFileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildBranchUpdate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ChildBranchUpdate
     */
    'parentBranchId': number;
    /**
     * 
     * @type {string}
     * @memberof ChildBranchUpdate
     */
    'phone': string;
    /**
     * 
     * @type {WorkSchedule}
     * @memberof ChildBranchUpdate
     */
    'workSchedule': WorkSchedule;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CibColumnKey = {
    RULES: 'RULES',
    VALUE: 'VALUE'
} as const;

export type CibColumnKey = typeof CibColumnKey[keyof typeof CibColumnKey];


/**
 * 
 * @export
 * @interface CibColumnMetadata
 */
export interface CibColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof CibColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {CibColumnKey}
     * @memberof CibColumnMetadata
     */
    'key'?: CibColumnKey;
    /**
     * 
     * @type {object}
     * @memberof CibColumnMetadata
     */
    'payload'?: object;
}


/**
 * 
 * @export
 * @interface CibDto
 */
export interface CibDto extends Settings {
    /**
     * 
     * @type {Array<CibColumnMetadata>}
     * @memberof CibDto
     */
    'columns'?: Array<CibColumnMetadata>;
    /**
     * 
     * @type {Array<CibRow>}
     * @memberof CibDto
     */
    'rows'?: Array<CibRow>;
}
/**
 * 
 * @export
 * @interface CibLoanListEntry
 */
export interface CibLoanListEntry {
    /**
     * 
     * @type {string}
     * @memberof CibLoanListEntry
     */
    'classification'?: string;
    /**
     * 
     * @type {string}
     * @memberof CibLoanListEntry
     */
    'collateralSubType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CibLoanListEntry
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CibLoanListEntry
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof CibLoanListEntry
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CibLoanListEntry
     */
    'interestRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof CibLoanListEntry
     */
    'maturityDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CibLoanListEntry
     */
    'originatedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CibLoanListEntry
     */
    'originatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CibLoanListEntry
     */
    'outstandingBalance'?: number;
    /**
     * 
     * @type {string}
     * @memberof CibLoanListEntry
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof CibLoanListEntry
     */
    'totalMonthlyPayment'?: number;
    /**
     * 
     * @type {string}
     * @memberof CibLoanListEntry
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface CibRow
 */
export interface CibRow {
    /**
     * 
     * @type {CibSettingRule}
     * @memberof CibRow
     */
    'cibSettingRule': CibSettingRule;
    /**
     * 
     * @type {number}
     * @memberof CibRow
     */
    'value': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CibSettingRule = {
    HAS_LOAN_WITH_BALANCE_GREATER_THAN_0_MNT_FROM_LESS_OR_EQUAL_THAN_DIFFERENT_INSTITUTIONS: 'HAS_LOAN_WITH_BALANCE_GREATER_THAN_0_MNT_FROM_LESS_OR_EQUAL_THAN_DIFFERENT_INSTITUTIONS',
    HAS_LESS_OR_EQUAL_THAN_LOANS_WITH_LOAN_BALANCE_GREATER_THAN_0_MNT: 'HAS_LESS_OR_EQUAL_THAN_LOANS_WITH_LOAN_BALANCE_GREATER_THAN_0_MNT'
} as const;

export type CibSettingRule = typeof CibSettingRule[keyof typeof CibSettingRule];


/**
 * 
 * @export
 * @enum {string}
 */

export const Citizenship = {
    MONGOLIA: 'MONGOLIA'
} as const;

export type Citizenship = typeof Citizenship[keyof typeof Citizenship];


/**
 * 
 * @export
 * @interface CivilServant
 */
export interface CivilServant {
    /**
     * 
     * @type {string}
     * @memberof CivilServant
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CivilServant
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof CivilServant
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CivilServant
     */
    'position': string;
    /**
     * 
     * @type {string}
     * @memberof CivilServant
     */
    'workAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CivilServant
     */
    'workPhoneNumber': string;
}
/**
 * 
 * @export
 * @interface CoBorrowerPatch
 */
export interface CoBorrowerPatch {
    /**
     * 
     * @type {string}
     * @memberof CoBorrowerPatch
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface CoBorrowerRemoveFromLoan
 */
export interface CoBorrowerRemoveFromLoan extends CoBorrowerPatch {
}
/**
 * 
 * @export
 * @interface CoBorrowerSetStatus
 */
export interface CoBorrowerSetStatus extends CoBorrowerPatch {
    /**
     * 
     * @type {CoBorrowerStatus}
     * @memberof CoBorrowerSetStatus
     */
    'status': CoBorrowerStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CoBorrowerStatus = {
    APPROVED: 'APPROVED',
    DECLINED: 'DECLINED',
    DRAFT: 'DRAFT'
} as const;

export type CoBorrowerStatus = typeof CoBorrowerStatus[keyof typeof CoBorrowerStatus];


/**
 * 
 * @export
 * @interface CoBorrowerUpdate
 */
export interface CoBorrowerUpdate extends CoBorrowerPatch {
    /**
     * 
     * @type {string}
     * @memberof CoBorrowerUpdate
     */
    'relationshipType': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CoOwnerCount = {
    N1: 'N1',
    N2: 'N2',
    N3: 'N3',
    N4: 'N4',
    N5: 'N5',
    MORE_THAN_FIVE: 'MORE_THAN_FIVE'
} as const;

export type CoOwnerCount = typeof CoOwnerCount[keyof typeof CoOwnerCount];


/**
 * 
 * @export
 * @interface Collateral
 */
export interface Collateral {
    /**
     * 
     * @type {string}
     * @memberof Collateral
     */
    'detailsType': string;
}
/**
 * 
 * @export
 * @interface CollateralCoOwner
 */
export interface CollateralCoOwner {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof CollateralCoOwner
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwner
     */
    'civilId': string;
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwner
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwner
     */
    'fullAddress': string;
    /**
     * 
     * @type {number}
     * @memberof CollateralCoOwner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwner
     */
    'lastName': string;
    /**
     * 
     * @type {OwnerType}
     * @memberof CollateralCoOwner
     */
    'ownerType': OwnerType;
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwner
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwner
     */
    'registrationNumber': string;
    /**
     * 
     * @type {ReferenceRelationship}
     * @memberof CollateralCoOwner
     */
    'relationship': ReferenceRelationship;
}


/**
 * 
 * @export
 * @interface CollateralCoOwnerUpdate
 */
export interface CollateralCoOwnerUpdate {
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwnerUpdate
     */
    'civilId': string;
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwnerUpdate
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwnerUpdate
     */
    'fullAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwnerUpdate
     */
    'lastName': string;
    /**
     * 
     * @type {OwnerType}
     * @memberof CollateralCoOwnerUpdate
     */
    'ownerType': OwnerType;
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwnerUpdate
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CollateralCoOwnerUpdate
     */
    'registrationNumber': string;
    /**
     * 
     * @type {ReferenceRelationship}
     * @memberof CollateralCoOwnerUpdate
     */
    'relationship': ReferenceRelationship;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CollateralDetailsType = {
    CAR: 'CAR',
    EQUIPMENT: 'EQUIPMENT',
    INTANGIBLE_ASSET: 'INTANGIBLE_ASSET',
    LEASING: 'LEASING',
    LIVESTOCK: 'LIVESTOCK',
    REAL_ESTATE: 'REAL_ESTATE'
} as const;

export type CollateralDetailsType = typeof CollateralDetailsType[keyof typeof CollateralDetailsType];


/**
 * 
 * @export
 * @interface CollateralEvaluationPatch
 */
export interface CollateralEvaluationPatch extends CollateralPatch {
    /**
     * 
     * @type {number}
     * @memberof CollateralEvaluationPatch
     */
    'marketValue': number;
    /**
     * 
     * @type {number}
     * @memberof CollateralEvaluationPatch
     */
    'mutuallyAgreedValuation': number;
    /**
     * 
     * @type {number}
     * @memberof CollateralEvaluationPatch
     */
    'rmValue': number;
}
/**
 * 
 * @export
 * @interface CollateralListEntry
 */
export interface CollateralListEntry {
    /**
     * 
     * @type {string}
     * @memberof CollateralListEntry
     */
    'createdAt': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof CollateralListEntry
     */
    'createdBy'?: IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof CollateralListEntry
     */
    'dateOfManufacture'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollateralListEntry
     */
    'establishedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollateralListEntry
     */
    'id': number;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof CollateralListEntry
     */
    'loans': Array<IdNameEntry>;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof CollateralListEntry
     */
    'owners': Array<IdNameEntry>;
    /**
     * 
     * @type {string}
     * @memberof CollateralListEntry
     */
    'registrationNumber': string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof CollateralListEntry
     */
    'subType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof CollateralListEntry
     */
    'type': CollateralType;
}


/**
 * 
 * @export
 * @interface CollateralOwner
 */
export interface CollateralOwner {
    /**
     * 
     * @type {number}
     * @memberof CollateralOwner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollateralOwner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollateralOwner
     */
    'registrationNumber'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CollateralOwnershipType = {
    SELF_OWNED: 'SELF_OWNED',
    CO_OWNED: 'CO_OWNED',
    OTHER: 'OTHER'
} as const;

export type CollateralOwnershipType = typeof CollateralOwnershipType[keyof typeof CollateralOwnershipType];


/**
 * 
 * @export
 * @interface CollateralPatch
 */
export interface CollateralPatch {
    /**
     * 
     * @type {string}
     * @memberof CollateralPatch
     */
    'type': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CollateralSubType = {
    AGREEMENT_FOR_GOODS_AND_SERVICES: 'AGREEMENT_FOR_GOODS_AND_SERVICES',
    APARTMENTS: 'APARTMENTS',
    BOND: 'BOND',
    CAR_PARK: 'CAR_PARK',
    ELECTRON_PRODUCT: 'ELECTRON_PRODUCT',
    FACTORY_BUILDINGS: 'FACTORY_BUILDINGS',
    FACTORY_EQUIPMENT: 'FACTORY_EQUIPMENT',
    HOUSE_AND_LAND: 'HOUSE_AND_LAND',
    IMPORTED_CAR: 'IMPORTED_CAR',
    LAND: 'LAND',
    LEASING_PRODUCT: 'LEASING_PRODUCT',
    LICENSE: 'LICENSE',
    LIVESTOCK: 'LIVESTOCK',
    MANUAL_EQUIPMENT: 'MANUAL_EQUIPMENT',
    NEW_CAR: 'NEW_CAR',
    OFFICE: 'OFFICE',
    OTHER_PRODUCT: 'OTHER_PRODUCT',
    PHONE_NUMBER: 'PHONE_NUMBER',
    STOCK: 'STOCK',
    TRUST_ACCOUNT: 'TRUST_ACCOUNT',
    USED_CAR: 'USED_CAR'
} as const;

export type CollateralSubType = typeof CollateralSubType[keyof typeof CollateralSubType];


/**
 * 
 * @export
 * @enum {string}
 */

export const CollateralType = {
    REAL_ESTATE: 'REAL_ESTATE',
    MOVABLE_PROPERTY: 'MOVABLE_PROPERTY',
    INTANGIBLE_ASSETS: 'INTANGIBLE_ASSETS'
} as const;

export type CollateralType = typeof CollateralType[keyof typeof CollateralType];


/**
 * 
 * @export
 * @enum {string}
 */

export const CollectionActivityContactedPerson = {
    CO_BORROWER: 'CO_BORROWER',
    CUSTOMER: 'CUSTOMER',
    RELATED_PERSON: 'RELATED_PERSON',
    WORK_PLACE: 'WORK_PLACE'
} as const;

export type CollectionActivityContactedPerson = typeof CollectionActivityContactedPerson[keyof typeof CollectionActivityContactedPerson];


/**
 * 
 * @export
 * @enum {string}
 */

export const CollectionActivityGeneralType = {
    MESSAGE: 'MESSAGE',
    PHONE: 'PHONE',
    ONLINE: 'ONLINE',
    MEETING: 'MEETING',
    JOINT_OPERATION: 'JOINT_OPERATION',
    NOTICE: 'NOTICE',
    COLLATERAL: 'COLLATERAL',
    GOVERNMENT_ORG: 'GOVERNMENT_ORG',
    CLOSE_FROM_FUND: 'CLOSE_FROM_FUND',
    CHECKED_LOAN_PURPOSE: 'CHECKED_LOAN_PURPOSE'
} as const;

export type CollectionActivityGeneralType = typeof CollectionActivityGeneralType[keyof typeof CollectionActivityGeneralType];


/**
 * 
 * @export
 * @interface CollectionActivityGeneralTypeDictionaryItem
 */
export interface CollectionActivityGeneralTypeDictionaryItem {
    /**
     * 
     * @type {Array<CollectionActivityTypeDictionaryItem>}
     * @memberof CollectionActivityGeneralTypeDictionaryItem
     */
    'collectionActivityTypes': Array<CollectionActivityTypeDictionaryItem>;
    /**
     * 
     * @type {CollectionActivityGeneralType}
     * @memberof CollectionActivityGeneralTypeDictionaryItem
     */
    'key': CollectionActivityGeneralType;
    /**
     * 
     * @type {Localization}
     * @memberof CollectionActivityGeneralTypeDictionaryItem
     */
    'l10n': Localization;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CollectionActivityNextAction = {
    SEND_REMINDER_MESSAGE: 'SEND_REMINDER_MESSAGE',
    SEND_DEMANDING_MESSAGE: 'SEND_DEMANDING_MESSAGE',
    GIVE_INFORMATION: 'GIVE_INFORMATION',
    SEND_WARNING_MESSAGE: 'SEND_WARNING_MESSAGE',
    SCHEDULE_A_PAYMENT: 'SCHEDULE_A_PAYMENT',
    LOAN_AGREEMENT_RESTRUCTURE_OPERATIONS: 'LOAN_AGREEMENT_RESTRUCTURE_OPERATIONS',
    REQUEST_A_LOAN_CLASS_UPGRADE: 'REQUEST_A_LOAN_CLASS_UPGRADE',
    DO_DIGITAL_OPERATIONS: 'DO_DIGITAL_OPERATIONS',
    DO_MANUAL_OPERATIONS: 'DO_MANUAL_OPERATIONS',
    STAGE_OF_JOINT_OPERATIONS_WITH_THE_MUNICIPALITY: 'STAGE_OF_JOINT_OPERATIONS_WITH_THE_MUNICIPALITY',
    SEND_A_TICKET: 'SEND_A_TICKET',
    CHECK_THE_COLLATERAL: 'CHECK_THE_COLLATERAL',
    CONFISCATE_THE_COLLATERAL: 'CONFISCATE_THE_COLLATERAL',
    SELL_THE_COLLATERAL: 'SELL_THE_COLLATERAL',
    MAKE_RECONCILIATION_REQUEST: 'MAKE_RECONCILIATION_REQUEST',
    SEND_INVOICE_TO_THE_CIVIL_PRIMARY_COURT: 'SEND_INVOICE_TO_THE_CIVIL_PRIMARY_COURT',
    REQUEST_INVESTIGATION: 'REQUEST_INVESTIGATION',
    MAKE_PAYMENT_BY_ORDER: 'MAKE_PAYMENT_BY_ORDER',
    REQUEST_AN_EXECUTION_PAGE: 'REQUEST_AN_EXECUTION_PAGE',
    CLOSE_BY_THE_FUND: 'CLOSE_BY_THE_FUND',
    CHECK_THE_LOAN_PURPOSE: 'CHECK_THE_LOAN_PURPOSE'
} as const;

export type CollectionActivityNextAction = typeof CollectionActivityNextAction[keyof typeof CollectionActivityNextAction];


/**
 * 
 * @export
 * @enum {string}
 */

export const CollectionActivityOverdueReason = {
    ACCIDENT: 'ACCIDENT',
    ADDICTED_TO_GAMBLING: 'ADDICTED_TO_GAMBLING',
    BORROWED_THROUGH: 'BORROWED_THROUGH',
    BORROWER_S_DEATH: 'BORROWER_S_DEATH',
    FAMILY_PROBLEMS: 'FAMILY_PROBLEMS',
    FRAUD_COMMITTED: 'FRAUD_COMMITTED',
    HEALTH_REASONS: 'HEALTH_REASONS',
    LOCATION_HAS_CHANGED: 'LOCATION_HAS_CHANGED',
    MATERNITY_LEAVE: 'MATERNITY_LEAVE',
    MISSING: 'MISSING',
    NO_JOB: 'NO_JOB',
    NOT_INTERESTED_IN_PAYING: 'NOT_INTERESTED_IN_PAYING',
    PRESSURE_OF_DEBT: 'PRESSURE_OF_DEBT',
    PROBLEM_WITH_THE_POLICE: 'PROBLEM_WITH_THE_POLICE',
    WENT_ABROAD: 'WENT_ABROAD',
    WENT_TO_MILITARY: 'WENT_TO_MILITARY',
    WENT_TO_PRISON: 'WENT_TO_PRISON'
} as const;

export type CollectionActivityOverdueReason = typeof CollectionActivityOverdueReason[keyof typeof CollectionActivityOverdueReason];


/**
 * 
 * @export
 * @enum {string}
 */

export const CollectionActivityStatus = {
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS'
} as const;

export type CollectionActivityStatus = typeof CollectionActivityStatus[keyof typeof CollectionActivityStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const CollectionActivitySubType = {
    CONTACTED_PAYMENT_PERIOD_AGREED: 'CONTACTED_PAYMENT_PERIOD_AGREED',
    CONTACTED_DEMANDED: 'CONTACTED_DEMANDED',
    CONTACTED_AGREED_TO_RESTRUCTURE_THE_LOAN_AGREEMENT: 'CONTACTED_AGREED_TO_RESTRUCTURE_THE_LOAN_AGREEMENT',
    CONTACTED_REQUEST_TO_IMPROVE_CIB_STATUS: 'CONTACTED_REQUEST_TO_IMPROVE_CIB_STATUS',
    COULD_NOT_CONTACT_DID_NOT_PICK_UP_THE_PHONE: 'COULD_NOT_CONTACT_DID_NOT_PICK_UP_THE_PHONE',
    COULD_NOT_CONTACT_CAN_NOT_CONTACT: 'COULD_NOT_CONTACT_CAN_NOT_CONTACT',
    COULD_NOT_CONTACT_UNABLE_TO_RECEIVE_CALL: 'COULD_NOT_CONTACT_UNABLE_TO_RECEIVE_CALL',
    COULD_NOT_CONTACT_NOT_IN_USE: 'COULD_NOT_CONTACT_NOT_IN_USE',
    COULD_NOT_CONTACT_BLOCKED_TO_EMPLOYEE_PHONE_NUMBER: 'COULD_NOT_CONTACT_BLOCKED_TO_EMPLOYEE_PHONE_NUMBER',
    COULD_NOT_CONTACT_CHANGED_PHONE_NUMBER: 'COULD_NOT_CONTACT_CHANGED_PHONE_NUMBER',
    COULD_NOT_CONTACT_ROAMING_SERVICE: 'COULD_NOT_CONTACT_ROAMING_SERVICE',
    SEARCH_FOR_INFO_IN_THE_CORE_SYSTEM_CONTACT_INFORMATION_FOUND: 'SEARCH_FOR_INFO_IN_THE_CORE_SYSTEM_CONTACT_INFORMATION_FOUND',
    SEARCH_FOR_INFO_IN_THE_CORE_SYSTEM_ADDRESS_FOUND: 'SEARCH_FOR_INFO_IN_THE_CORE_SYSTEM_ADDRESS_FOUND',
    SEARCH_FOR_INFO_IN_THE_CORE_SYSTEM_COULD_NOT_FIND_INFORMATION: 'SEARCH_FOR_INFO_IN_THE_CORE_SYSTEM_COULD_NOT_FIND_INFORMATION',
    CHECK_PERSONAL_INFORMATION_CONTACT_INFORMATION_FOUND: 'CHECK_PERSONAL_INFORMATION_CONTACT_INFORMATION_FOUND',
    CHECK_PERSONAL_INFORMATION_ADDRESS_FOUND: 'CHECK_PERSONAL_INFORMATION_ADDRESS_FOUND',
    CHECK_PERSONAL_INFORMATION_COULD_NOT_FIND_INFORMATION: 'CHECK_PERSONAL_INFORMATION_COULD_NOT_FIND_INFORMATION',
    SEARCH_FOR_INFO_IN_OPEN_CHANNELS_CONTACT_INFORMATION_FOUND: 'SEARCH_FOR_INFO_IN_OPEN_CHANNELS_CONTACT_INFORMATION_FOUND',
    SEARCH_FOR_INFO_IN_OPEN_CHANNELS_ADDRESS_FOUND: 'SEARCH_FOR_INFO_IN_OPEN_CHANNELS_ADDRESS_FOUND',
    SEARCH_FOR_INFO_IN_OPEN_CHANNELS_COULD_NOT_FIND_INFORMATION: 'SEARCH_FOR_INFO_IN_OPEN_CHANNELS_COULD_NOT_FIND_INFORMATION',
    EMAIL_CONTACTED_VIA_WORK_EMAIL_ADDRESS: 'EMAIL_CONTACTED_VIA_WORK_EMAIL_ADDRESS',
    EMAIL_CONTACTED_VIA_PERSONAL_EMAIL_ADDRESS: 'EMAIL_CONTACTED_VIA_PERSONAL_EMAIL_ADDRESS',
    SOCIAL_NETWORKS_ADDRESS_FOUND: 'SOCIAL_NETWORKS_ADDRESS_FOUND',
    SOCIAL_NETWORKS_CONTACT_ESTABLISHED: 'SOCIAL_NETWORKS_CONTACT_ESTABLISHED',
    SOCIAL_NETWORKS_PAYMENT_PERIOD_AGREED: 'SOCIAL_NETWORKS_PAYMENT_PERIOD_AGREED',
    SOCIAL_NETWORKS_NOT_RESPOND: 'SOCIAL_NETWORKS_NOT_RESPOND',
    MEETING_MET_AT_THE_OFFICE: 'MEETING_MET_AT_THE_OFFICE',
    MEETING_MET_AT_THE_OFFICE_WITH_DEMANDS: 'MEETING_MET_AT_THE_OFFICE_WITH_DEMANDS',
    MEETING_MET_AT_AGREED_PLACE: 'MEETING_MET_AT_AGREED_PLACE',
    MEETING_MET_AT_THE_WORK_ADDRESS: 'MEETING_MET_AT_THE_WORK_ADDRESS',
    MEETING_MET_AT_THE_HOME_ADDRESS: 'MEETING_MET_AT_THE_HOME_ADDRESS',
    MEET_AT_THE_WORK_ADDRESS_NO_ADDRESS_FOUND: 'MEET_AT_THE_WORK_ADDRESS_NO_ADDRESS_FOUND',
    MEET_AT_THE_WORK_ADDRESS_NOT_WORKING: 'MEET_AT_THE_WORK_ADDRESS_NOT_WORKING',
    MEET_AT_THE_WORK_ADDRESS_QUIT_A_JOB: 'MEET_AT_THE_WORK_ADDRESS_QUIT_A_JOB',
    MEET_AT_THE_WORK_ADDRESS_WAS_ABSENT_FOR_A_WHILE: 'MEET_AT_THE_WORK_ADDRESS_WAS_ABSENT_FOR_A_WHILE',
    MEET_AT_THE_HOME_ADDRESS_NO_ADDRESS_FOUND: 'MEET_AT_THE_HOME_ADDRESS_NO_ADDRESS_FOUND',
    MEET_AT_THE_HOME_ADDRESS_DOES_NOT_LIVE_AT_THE_ADDRESS: 'MEET_AT_THE_HOME_ADDRESS_DOES_NOT_LIVE_AT_THE_ADDRESS',
    MEET_AT_THE_HOME_ADDRESS_MOVED: 'MEET_AT_THE_HOME_ADDRESS_MOVED',
    MEET_AT_THE_HOME_ADDRESS_SOLD: 'MEET_AT_THE_HOME_ADDRESS_SOLD',
    MEET_AT_THE_HOME_ADDRESS_WAS_ABSENT_FOR_A_WHILE: 'MEET_AT_THE_HOME_ADDRESS_WAS_ABSENT_FOR_A_WHILE',
    CHECK_INTEGRITY_NORMAL: 'CHECK_INTEGRITY_NORMAL',
    CHECK_INTEGRITY_ABNORMAL: 'CHECK_INTEGRITY_ABNORMAL',
    CHECK_INTEGRITY_THERE_WAS_NOT: 'CHECK_INTEGRITY_THERE_WAS_NOT',
    CONFISCATE_EMPLOYEE_FORECLOSURE: 'CONFISCATE_EMPLOYEE_FORECLOSURE',
    CONFISCATE_EMPLOYEE_SEIZURE_OF_ASSETS: 'CONFISCATE_EMPLOYEE_SEIZURE_OF_ASSETS',
    CONFISCATE_DECISION_EXECUTION_FORCIBLE: 'CONFISCATE_DECISION_EXECUTION_FORCIBLE',
    CONFISCATE_DECISION_EXECUTION_OPTIONAL: 'CONFISCATE_DECISION_EXECUTION_OPTIONAL',
    ASSETS_OPERATION_TO_ENSURE_INTEGRITY: 'ASSETS_OPERATION_TO_ENSURE_INTEGRITY',
    ASSETS_IMPROVEMENTS: 'ASSETS_IMPROVEMENTS',
    SALES_SOLD_BY_AN_EMPLOYEE: 'SALES_SOLD_BY_AN_EMPLOYEE',
    SALES_JOINTLY_WITH_SELLERS: 'SALES_JOINTLY_WITH_SELLERS',
    SALES_IN_PARTNERSHIP_WITH_LAW_FARM: 'SALES_IN_PARTNERSHIP_WITH_LAW_FARM',
    SALES_JOINTLY_WITH_THE_BROKERAGE_COMPANY: 'SALES_JOINTLY_WITH_THE_BROKERAGE_COMPANY',
    SALES_DECISION_EXECUTION_THE_FIRST_AUCTION: 'SALES_DECISION_EXECUTION_THE_FIRST_AUCTION',
    SALES_DECISION_EXECUTION_SECOND_AUCTION: 'SALES_DECISION_EXECUTION_SECOND_AUCTION',
    SEARCH_FOR_INFO_THROUGH_OPEN_ONLINE_CHANNELS: 'SEARCH_FOR_INFO_THROUGH_OPEN_ONLINE_CHANNELS',
    PHYSICAL_SEARCH: 'PHYSICAL_SEARCH',
    MEDIATION_FOR_RECONCILIATION_APPLICATION_MADE: 'MEDIATION_FOR_RECONCILIATION_APPLICATION_MADE',
    MEDIATION_FOR_RECONCILIATION_APPOINTMENT_SCHEDULED: 'MEDIATION_FOR_RECONCILIATION_APPOINTMENT_SCHEDULED',
    MEDIATION_FOR_RECONCILIATION_DEADLINE_HAS_BEEN_EXTENDED: 'MEDIATION_FOR_RECONCILIATION_DEADLINE_HAS_BEEN_EXTENDED',
    MEDIATION_FOR_RECONCILIATION_ATTENDED_THE_MEETING: 'MEDIATION_FOR_RECONCILIATION_ATTENDED_THE_MEETING',
    MEDIATION_FOR_RECONCILIATION_TAKE_DIRECTION: 'MEDIATION_FOR_RECONCILIATION_TAKE_DIRECTION',
    MEDIATION_FOR_RECONCILIATION_NOTE_ABOUT_THE_FINISHED: 'MEDIATION_FOR_RECONCILIATION_NOTE_ABOUT_THE_FINISHED',
    MEDIATION_FOR_RECONCILIATION_REJECTION_NOTE: 'MEDIATION_FOR_RECONCILIATION_REJECTION_NOTE',
    MEDIATION_FOR_RECONCILIATION_ACCEPTANCE_REFUSED: 'MEDIATION_FOR_RECONCILIATION_ACCEPTANCE_REFUSED',
    CIVIL_COURT_OF_FIRST_INSTANCE_INVOICE: 'CIVIL_COURT_OF_FIRST_INSTANCE_INVOICE',
    CIVIL_COURT_OF_FIRST_INSTANCE_CASE_ARISEN: 'CIVIL_COURT_OF_FIRST_INSTANCE_CASE_ARISEN',
    CIVIL_COURT_OF_FIRST_INSTANCE_INVOICE_SUBMITTED: 'CIVIL_COURT_OF_FIRST_INSTANCE_INVOICE_SUBMITTED',
    CIVIL_COURT_OF_FIRST_INSTANCE_RECEIVED_COURT_ORDER: 'CIVIL_COURT_OF_FIRST_INSTANCE_RECEIVED_COURT_ORDER',
    CIVIL_COURT_OF_FIRST_INSTANCE_DEADLINE_EXTENDED: 'CIVIL_COURT_OF_FIRST_INSTANCE_DEADLINE_EXTENDED',
    CIVIL_COURT_OF_FIRST_INSTANCE_COURT_HEARING_WAS_SCHEDULED: 'CIVIL_COURT_OF_FIRST_INSTANCE_COURT_HEARING_WAS_SCHEDULED',
    CIVIL_COURT_OF_FIRST_INSTANCE_RECEIVED_A_JUDGE_S_ORDER: 'CIVIL_COURT_OF_FIRST_INSTANCE_RECEIVED_A_JUDGE_S_ORDER',
    CIVIL_COURT_OF_FIRST_INSTANCE_TRIAL_WAS_ADJOURNED: 'CIVIL_COURT_OF_FIRST_INSTANCE_TRIAL_WAS_ADJOURNED',
    COURT_OF_FIRST_RECEIVED_REJECTION_ORDER_FROM_FILING_A_CASE: 'COURT_OF_FIRST_RECEIVED_REJECTION_ORDER_FROM_FILING_A_CASE',
    COURT_OF_FIRST_RECEIVED_REJECTION_ORDER_INVALIDATED: 'COURT_OF_FIRST_RECEIVED_REJECTION_ORDER_INVALIDATED',
    INVESTIGATION_REQUEST_MADE: 'INVESTIGATION_REQUEST_MADE',
    INVESTIGATION_COURT_DECISION_WAS_MADE: 'INVESTIGATION_COURT_DECISION_WAS_MADE',
    INVESTIGATION_OPERATIONS_IN_THE_POLICE: 'INVESTIGATION_OPERATIONS_IN_THE_POLICE',
    INVESTIGATION_RECEIVED_INFORMATION_ABOUT_THE_FOUND: 'INVESTIGATION_RECEIVED_INFORMATION_ABOUT_THE_FOUND',
    INVESTIGATION_FOUND_MAKING_NORMAL_PAYMENTS: 'INVESTIGATION_FOUND_MAKING_NORMAL_PAYMENTS',
    INVESTIGATION_FOUND_INVOICE: 'INVESTIGATION_FOUND_INVOICE',
    PAYMENT_BY_COURT_INSTRUCTION_NORMAL_REPAYMENTS: 'PAYMENT_BY_COURT_INSTRUCTION_NORMAL_REPAYMENTS',
    PAYMENT_BY_COURT_INSTRUCTION_UNDERPAYMENT: 'PAYMENT_BY_COURT_INSTRUCTION_UNDERPAYMENT',
    PAYMENT_BY_COURT_INSTRUCTION_OVERPAYMENT: 'PAYMENT_BY_COURT_INSTRUCTION_OVERPAYMENT',
    PAYMENT_BY_COURT_INSTRUCTION_EXECUTION_PAGE_REQUEST_SENT: 'PAYMENT_BY_COURT_INSTRUCTION_EXECUTION_PAGE_REQUEST_SENT',
    PAYMENT_BY_COURT_INSTRUCTION_RECEIVED_ORDER: 'PAYMENT_BY_COURT_INSTRUCTION_RECEIVED_ORDER',
    OPERATION_OF_DECISION_EXECUTION_REQUESTED_TO_ACTIVITY: 'OPERATION_OF_DECISION_EXECUTION_REQUESTED_TO_ACTIVITY',
    OPERATION_OF_DECISION_EXECUTION_TIME_AGREED_WITH_THE_CUSTOMER: 'OPERATION_OF_DECISION_EXECUTION_TIME_AGREED_WITH_THE_CUSTOMER',
    OPERATION_OF_DECISION_EXECUTION_VERIFICATION_PHASE: 'OPERATION_OF_DECISION_EXECUTION_VERIFICATION_PHASE',
    OPERATION_OF_DECISION_EXECUTION_PAYMENT_TRANSFER_PROCEDURE: 'OPERATION_OF_DECISION_EXECUTION_PAYMENT_TRANSFER_PROCEDURE',
    OPERATION_OF_DECISION_EXECUTION_OPERATION_TERMINATED: 'OPERATION_OF_DECISION_EXECUTION_OPERATION_TERMINATED',
    OPERATION_OF_DECISION_EXECUTION_ASSETS_PRICE_MATCHING: 'OPERATION_OF_DECISION_EXECUTION_ASSETS_PRICE_MATCHING',
    OPERATION_OF_DECISION_EXECUTION_ASSETS_APPOINTING_AN_ASSESSOR: 'OPERATION_OF_DECISION_EXECUTION_ASSETS_APPOINTING_AN_ASSESSOR',
    OPERATION_OF_DECISION_EXECUTION_ASSETS_APPEALING_AN_ASSESSMENT: 'OPERATION_OF_DECISION_EXECUTION_ASSETS_APPEALING_AN_ASSESSMENT',
    OPERATION_OF_DECISION_EXECUTION_ASSETS_THE_FIRST_AUCTION: 'OPERATION_OF_DECISION_EXECUTION_ASSETS_THE_FIRST_AUCTION',
    OPERATION_OF_DECISION_EXECUTION_ASSETS_SECOND_AUCTION: 'OPERATION_OF_DECISION_EXECUTION_ASSETS_SECOND_AUCTION',
    OPERATION_OF_DECISION_EXECUTION_ASSETS_COMPULSORY_RELEASE: 'OPERATION_OF_DECISION_EXECUTION_ASSETS_COMPULSORY_RELEASE',
    OPERATION_OF_DECISION_EXECUTION_ASSETS_TRANSFER_OF_OWNERSHIP: 'OPERATION_OF_DECISION_EXECUTION_ASSETS_TRANSFER_OF_OWNERSHIP'
} as const;

export type CollectionActivitySubType = typeof CollectionActivitySubType[keyof typeof CollectionActivitySubType];


/**
 * 
 * @export
 * @interface CollectionActivitySubtypeDictionaryItem
 */
export interface CollectionActivitySubtypeDictionaryItem {
    /**
     * 
     * @type {CollectionActivitySubType}
     * @memberof CollectionActivitySubtypeDictionaryItem
     */
    'key': CollectionActivitySubType;
    /**
     * 
     * @type {Localization}
     * @memberof CollectionActivitySubtypeDictionaryItem
     */
    'l10n': Localization;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CollectionActivityType = {
    MESSAGE_REMINDER_MESSAGE_SENT: 'MESSAGE_REMINDER_MESSAGE_SENT',
    MESSAGE_REQUIRED_MESSAGES_SENT: 'MESSAGE_REQUIRED_MESSAGES_SENT',
    MESSAGE_INFORMATION_PROVIDED: 'MESSAGE_INFORMATION_PROVIDED',
    MESSAGE_WARNING_MESSAGE_SENT: 'MESSAGE_WARNING_MESSAGE_SENT',
    PHONE_CONTACTED: 'PHONE_CONTACTED',
    PHONE_COULD_NOT_CONTACT: 'PHONE_COULD_NOT_CONTACT',
    ONLINE_SEARCH_FOR_INFO_IN_THE_CORE_SYSTEM: 'ONLINE_SEARCH_FOR_INFO_IN_THE_CORE_SYSTEM',
    ONLINE_CHECK_PERSONAL_INFORMATION: 'ONLINE_CHECK_PERSONAL_INFORMATION',
    ONLINE_SEARCH_FOR_INFO_IN_OPEN_CHANNELS: 'ONLINE_SEARCH_FOR_INFO_IN_OPEN_CHANNELS',
    ONLINE_EMAIL: 'ONLINE_EMAIL',
    ONLINE_SOCIAL_NETWORKS: 'ONLINE_SOCIAL_NETWORKS',
    MEETING_MEETING: 'MEETING_MEETING',
    MEETING_MEET_AT_THE_WORK_ADDRESS: 'MEETING_MEET_AT_THE_WORK_ADDRESS',
    MEETING_MEET_AT_THE_HOME_ADDRESS: 'MEETING_MEET_AT_THE_HOME_ADDRESS',
    JOINT_OPERATION_MEETING: 'JOINT_OPERATION_MEETING',
    JOINT_OPERATION_MEET_AT_THE_WORK_ADDRESS: 'JOINT_OPERATION_MEET_AT_THE_WORK_ADDRESS',
    JOINT_OPERATION_MEET_AT_THE_HOME_ADDRESS: 'JOINT_OPERATION_MEET_AT_THE_HOME_ADDRESS',
    NOTICE_PROVIDED_BY_HOME_ADDRESS: 'NOTICE_PROVIDED_BY_HOME_ADDRESS',
    NOTICE_PROVIDED_BY_WORK_ADDRESS: 'NOTICE_PROVIDED_BY_WORK_ADDRESS',
    NOTICE_PROVIDED_AT_AGREED_PLACE: 'NOTICE_PROVIDED_AT_AGREED_PLACE',
    NOTICE_PROVIDED_BY_OFFICE: 'NOTICE_PROVIDED_BY_OFFICE',
    NOTICE_SENT_BY_EMAIL: 'NOTICE_SENT_BY_EMAIL',
    NOTICE_SENT_BY_POST_OFFICE: 'NOTICE_SENT_BY_POST_OFFICE',
    COLLATERAL_CHECK_INTEGRITY: 'COLLATERAL_CHECK_INTEGRITY',
    COLLATERAL_APPROVED_FOR_SALE: 'COLLATERAL_APPROVED_FOR_SALE',
    COLLATERAL_CONFISCATE: 'COLLATERAL_CONFISCATE',
    COLLATERAL_PRESERVATION_AND_PROTECTION_OF_ASSETS: 'COLLATERAL_PRESERVATION_AND_PROTECTION_OF_ASSETS',
    COLLATERAL_SALES: 'COLLATERAL_SALES',
    COLLATERAL_SEARCH_FOR_COLLATERAL: 'COLLATERAL_SEARCH_FOR_COLLATERAL',
    GOVERNMENT_ORG_MEDIATION_FOR_RECONCILIATION: 'GOVERNMENT_ORG_MEDIATION_FOR_RECONCILIATION',
    GOVERNMENT_ORG_CIVIL_COURT_OF_FIRST_INSTANCE: 'GOVERNMENT_ORG_CIVIL_COURT_OF_FIRST_INSTANCE',
    GOVERNMENT_ORG_INVESTIGATION: 'GOVERNMENT_ORG_INVESTIGATION',
    GOVERNMENT_ORG_PAYMENT_BY_COURT_INSTRUCTION: 'GOVERNMENT_ORG_PAYMENT_BY_COURT_INSTRUCTION',
    GOVERNMENT_ORG_OPERATION_OF_DECISION_EXECUTION: 'GOVERNMENT_ORG_OPERATION_OF_DECISION_EXECUTION',
    CLOSE_FROM_FUND_PREPARED_MATERIALS: 'CLOSE_FROM_FUND_PREPARED_MATERIALS',
    CLOSE_FROM_FUND_RISK_COMMITTEE_IS_RESOLVED: 'CLOSE_FROM_FUND_RISK_COMMITTEE_IS_RESOLVED',
    CLOSE_FROM_FUND_DECIDED_BY_THE_BOARD: 'CLOSE_FROM_FUND_DECIDED_BY_THE_BOARD',
    CLOSE_FROM_FUND_CLOSING_TRANSACTIONS_MADE_BY_THE_FUND: 'CLOSE_FROM_FUND_CLOSING_TRANSACTIONS_MADE_BY_THE_FUND',
    CHECKED_LOAN_PURPOSE_SPENT_AS_INTENDED: 'CHECKED_LOAN_PURPOSE_SPENT_AS_INTENDED',
    CHECKED_LOAN_PURPOSE_FRAUD: 'CHECKED_LOAN_PURPOSE_FRAUD',
    CHECKED_LOAN_PURPOSE_BORROWED_THROUGH: 'CHECKED_LOAN_PURPOSE_BORROWED_THROUGH',
    CHECKED_LOAN_PURPOSE_NOT_SPENT_AS_INTENDED: 'CHECKED_LOAN_PURPOSE_NOT_SPENT_AS_INTENDED'
} as const;

export type CollectionActivityType = typeof CollectionActivityType[keyof typeof CollectionActivityType];


/**
 * 
 * @export
 * @interface CollectionActivityTypeDictionaryItem
 */
export interface CollectionActivityTypeDictionaryItem {
    /**
     * 
     * @type {Array<CollectionActivitySubtypeDictionaryItem>}
     * @memberof CollectionActivityTypeDictionaryItem
     */
    'collectionActivitySubtypes': Array<CollectionActivitySubtypeDictionaryItem>;
    /**
     * 
     * @type {CollectionActivityType}
     * @memberof CollectionActivityTypeDictionaryItem
     */
    'key': CollectionActivityType;
    /**
     * 
     * @type {Localization}
     * @memberof CollectionActivityTypeDictionaryItem
     */
    'l10n': Localization;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CollectionAssignmentColumnKey = {
    ROLE: 'ROLE',
    PRINCIPAL_BALANCE: 'PRINCIPAL_BALANCE',
    DAYS_OVERDUE: 'DAYS_OVERDUE'
} as const;

export type CollectionAssignmentColumnKey = typeof CollectionAssignmentColumnKey[keyof typeof CollectionAssignmentColumnKey];


/**
 * 
 * @export
 * @interface CollectionAssignmentColumnMetadata
 */
export interface CollectionAssignmentColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof CollectionAssignmentColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {CollectionAssignmentColumnKey}
     * @memberof CollectionAssignmentColumnMetadata
     */
    'key'?: CollectionAssignmentColumnKey;
    /**
     * 
     * @type {object}
     * @memberof CollectionAssignmentColumnMetadata
     */
    'payload'?: object;
}


/**
 * 
 * @export
 * @interface CollectionAssignmentDto
 */
export interface CollectionAssignmentDto extends Settings {
    /**
     * 
     * @type {Array<CollectionAssignmentColumnMetadata>}
     * @memberof CollectionAssignmentDto
     */
    'columns'?: Array<CollectionAssignmentColumnMetadata>;
    /**
     * 
     * @type {Array<CollectionAssignmentRow>}
     * @memberof CollectionAssignmentDto
     */
    'rows'?: Array<CollectionAssignmentRow>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CollectionAssignmentRole = {
    DEBT_COLLECTOR: 'DEBT_COLLECTOR',
    LITIGATION_SPECIALIST: 'LITIGATION_SPECIALIST'
} as const;

export type CollectionAssignmentRole = typeof CollectionAssignmentRole[keyof typeof CollectionAssignmentRole];


/**
 * 
 * @export
 * @interface CollectionAssignmentRow
 */
export interface CollectionAssignmentRow {
    /**
     * 
     * @type {number}
     * @memberof CollectionAssignmentRow
     */
    'daysOverdue': number;
    /**
     * 
     * @type {number}
     * @memberof CollectionAssignmentRow
     */
    'principalBalance'?: number;
    /**
     * 
     * @type {CollectionAssignmentRole}
     * @memberof CollectionAssignmentRow
     */
    'role': CollectionAssignmentRole;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CollectionLevel = {
    ONLINE_OPERATIONS_MESSAGE: 'ONLINE_OPERATIONS_MESSAGE',
    ONLINE_OPERATIONS_PHONE: 'ONLINE_OPERATIONS_PHONE',
    ONLINE_OPERATIONS_EMAIL: 'ONLINE_OPERATIONS_EMAIL',
    MANUAL_OPERATIONS: 'MANUAL_OPERATIONS',
    STAGE_OF_JOINT_OPERATIONS_WITH_THE_MUNICIPALITY: 'STAGE_OF_JOINT_OPERATIONS_WITH_THE_MUNICIPALITY',
    SENT_THE_TICKET: 'SENT_THE_TICKET',
    COLLATERAL: 'COLLATERAL',
    GOVERNMENT_RECONCILIATION: 'GOVERNMENT_RECONCILIATION',
    GOVERNMENT_CIVIL_PRIMARY_COURT: 'GOVERNMENT_CIVIL_PRIMARY_COURT',
    GOVERNMENT_INVESTIGATION: 'GOVERNMENT_INVESTIGATION',
    GOVERNMENT_PAYMENT_BY_ORDER: 'GOVERNMENT_PAYMENT_BY_ORDER',
    GOVERNMENT_EXECUTION_OPERATION: 'GOVERNMENT_EXECUTION_OPERATION',
    CLOSE_BY_THE_FUND: 'CLOSE_BY_THE_FUND',
    CHECKED_THE_LOAN_PURPOSE: 'CHECKED_THE_LOAN_PURPOSE'
} as const;

export type CollectionLevel = typeof CollectionLevel[keyof typeof CollectionLevel];


/**
 * 
 * @export
 * @enum {string}
 */

export const CollectionStatus = {
    FAILED: 'FAILED',
    PENDING: 'PENDING',
    SUCCESSFUL: 'SUCCESSFUL'
} as const;

export type CollectionStatus = typeof CollectionStatus[keyof typeof CollectionStatus];


/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {UserBrief}
     * @memberof Comment
     */
    'author': UserBrief;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'commentText': string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'roomId': number;
}
/**
 * @type CreateCoBorrowerRequest
 * @export
 */
export type CreateCoBorrowerRequest = NewApplicationCoBorrower | NewLoanCoBorrower;

/**
 * @type CreateCollateral200Response
 * @export
 */
export type CreateCollateral200Response = CarCollateral | EquipmentCollateral | IntangibleAssetCollateral | LeasingCollateral | LivestockCollateral | RealEstateCollateral | TrustAccountCollateral;

/**
 * @type CreateCollateralRequest
 * @export
 */
export type CreateCollateralRequest = NewCarCollateral | NewEquipmentCollateral | NewIntangibleAssetCollateral | NewLeasingCollateral | NewLivestockCollateral | NewRealEstateCollateral;

/**
 * 
 * @export
 * @interface CreateIndividualRequest
 */
export interface CreateIndividualRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateIndividualRequest
     */
    'phoneNumber': string;
}
/**
 * @type CreateLegalRepresentativeRequest
 * @export
 */
export type CreateLegalRepresentativeRequest = NewIndividualLegalRepresentative | NewNonExistingIndividualLegalRepresentative;

/**
 * 
 * @export
 * @interface CreateMerchantForm
 */
export interface CreateMerchantForm {
    /**
     * 
     * @type {MerchantCompany}
     * @memberof CreateMerchantForm
     */
    'company': MerchantCompany;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantForm
     */
    'companyAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantForm
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantForm
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantForm
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantForm
     */
    'surname': string;
}


/**
 * @type CreateProductRequest
 * @export
 */
export type CreateProductRequest = NewBnplProduct | NewInstallmentProduct | NewPayonProduct | NewPledgedProduct | NewTrustProduct;

/**
 * 
 * @export
 * @interface CreateRestrictionListEntryRequest
 */
export interface CreateRestrictionListEntryRequest {
    /**
     * 
     * @type {RestrictionCause}
     * @memberof CreateRestrictionListEntryRequest
     */
    'cause': RestrictionCause;
    /**
     * 
     * @type {number}
     * @memberof CreateRestrictionListEntryRequest
     */
    'customerId': number;
    /**
     * 
     * @type {RestrictionListType}
     * @memberof CreateRestrictionListEntryRequest
     */
    'listType': RestrictionListType;
}


/**
 * 
 * @export
 * @interface CreateRoleForm
 */
export interface CreateRoleForm {
    /**
     * 
     * @type {Array<AuthorityHierarchy>}
     * @memberof CreateRoleForm
     */
    'authorities': Array<AuthorityHierarchy>;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleForm
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleForm
     */
    'name': string;
    /**
     * 
     * @type {StartPage}
     * @memberof CreateRoleForm
     */
    'startPage': StartPage;
}


/**
 * 
 * @export
 * @interface CreateUserForm
 */
export interface CreateUserForm {
    /**
     * 
     * @type {number}
     * @memberof CreateUserForm
     */
    'childBranchId': number;
    /**
     * 
     * @type {BoUserDepartment}
     * @memberof CreateUserForm
     */
    'department': BoUserDepartment;
    /**
     * 
     * @type {string}
     * @memberof CreateUserForm
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserForm
     */
    'isBranchManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserForm
     */
    'isRelationshipManager': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserForm
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserForm
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserForm
     */
    'position': string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserForm
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateUserForm
     */
    'surname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserForm
     */
    'teamsProfile': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CreditBureauStatus = {
    DOUBTFUL: 'DOUBTFUL',
    LOSS: 'LOSS',
    NORMAL: 'NORMAL',
    STRESSED: 'STRESSED',
    SUB_STANDARD: 'SUB_STANDARD'
} as const;

export type CreditBureauStatus = typeof CreditBureauStatus[keyof typeof CreditBureauStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const CreditBureauTriggerType = {
    CO_BORROWER: 'CO_BORROWER',
    COLLATERAL: 'COLLATERAL',
    LOAN_CREATED: 'LOAN_CREATED',
    LOAN_PAYMENT: 'LOAN_PAYMENT',
    LOAN_PAYMENT_CANCELLATION: 'LOAN_PAYMENT_CANCELLATION',
    LOAN_RESTRUCTURE: 'LOAN_RESTRUCTURE'
} as const;

export type CreditBureauTriggerType = typeof CreditBureauTriggerType[keyof typeof CreditBureauTriggerType];


/**
 * 
 * @export
 * @interface CreditCommitteeDecisionListEntry
 */
export interface CreditCommitteeDecisionListEntry {
    /**
     * 
     * @type {number}
     * @memberof CreditCommitteeDecisionListEntry
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditCommitteeDecisionListEntry
     */
    'comment'?: string;
    /**
     * 
     * @type {PledgedLoanApplicationTaskDecisionType}
     * @memberof CreditCommitteeDecisionListEntry
     */
    'decision': PledgedLoanApplicationTaskDecisionType;
    /**
     * 
     * @type {number}
     * @memberof CreditCommitteeDecisionListEntry
     */
    'monthlyInterestRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditCommitteeDecisionListEntry
     */
    'term'?: number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof CreditCommitteeDecisionListEntry
     */
    'user': IdNameEntry;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CreditScoringColumnKey = {
    RULES: 'RULES',
    VALUE: 'VALUE'
} as const;

export type CreditScoringColumnKey = typeof CreditScoringColumnKey[keyof typeof CreditScoringColumnKey];


/**
 * 
 * @export
 * @interface CreditScoringColumnMetadata
 */
export interface CreditScoringColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof CreditScoringColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {CreditScoringColumnKey}
     * @memberof CreditScoringColumnMetadata
     */
    'key'?: CreditScoringColumnKey;
    /**
     * 
     * @type {object}
     * @memberof CreditScoringColumnMetadata
     */
    'payload'?: object;
}


/**
 * 
 * @export
 * @interface CreditScoringDto
 */
export interface CreditScoringDto extends Settings {
    /**
     * 
     * @type {Array<CreditScoringColumnMetadata>}
     * @memberof CreditScoringDto
     */
    'columns'?: Array<CreditScoringColumnMetadata>;
    /**
     * 
     * @type {Array<CreditScoringRow>}
     * @memberof CreditScoringDto
     */
    'rows'?: Array<CreditScoringRow>;
}
/**
 * 
 * @export
 * @interface CreditScoringListEntry
 */
export interface CreditScoringListEntry {
    /**
     * 
     * @type {string}
     * @memberof CreditScoringListEntry
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditScoringListEntry
     */
    'creditScore'?: number;
    /**
     * 
     * @type {VerificationCheckDecision}
     * @memberof CreditScoringListEntry
     */
    'decision'?: VerificationCheckDecision;
    /**
     * 
     * @type {number}
     * @memberof CreditScoringListEntry
     */
    'dti'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditScoringListEntry
     */
    'fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditScoringListEntry
     */
    'id'?: number;
}


/**
 * 
 * @export
 * @interface CreditScoringResult
 */
export interface CreditScoringResult {
    /**
     * 
     * @type {CurrentLoansScoreCustomerResult}
     * @memberof CreditScoringResult
     */
    'currentLoansScoreCustomerResult'?: CurrentLoansScoreCustomerResult;
    /**
     * 
     * @type {EducationScoreCustomerResult}
     * @memberof CreditScoringResult
     */
    'educationScoreCustomerResult'?: EducationScoreCustomerResult;
    /**
     * 
     * @type {LoanHistoryScoreCustomerResult}
     * @memberof CreditScoringResult
     */
    'loanHistoryScoreCustomerResult'?: LoanHistoryScoreCustomerResult;
    /**
     * 
     * @type {MonthlyIncomeForDtiScoreCustomerResult}
     * @memberof CreditScoringResult
     */
    'monthlyIncomeForDtiScoreCustomerResult'?: MonthlyIncomeForDtiScoreCustomerResult;
    /**
     * 
     * @type {OwnedCarEstateScoreCustomerResult}
     * @memberof CreditScoringResult
     */
    'ownedCarEstateScoreCustomerResult'?: OwnedCarEstateScoreCustomerResult;
    /**
     * 
     * @type {OwnedRealEstateScoreCustomerResult}
     * @memberof CreditScoringResult
     */
    'ownedRealEstateScoreCustomerResult'?: OwnedRealEstateScoreCustomerResult;
    /**
     * 
     * @type {number}
     * @memberof CreditScoringResult
     */
    'score'?: number;
    /**
     * 
     * @type {SexAgeScoreCustomerResult}
     * @memberof CreditScoringResult
     */
    'sexAgeScoreCustomerResult'?: SexAgeScoreCustomerResult;
    /**
     * 
     * @type {WorkingStabilityScoreCustomerResult}
     * @memberof CreditScoringResult
     */
    'workingStabilityScoreCustomerResult'?: WorkingStabilityScoreCustomerResult;
}
/**
 * 
 * @export
 * @interface CreditScoringRow
 */
export interface CreditScoringRow {
    /**
     * 
     * @type {CreditScoringSettingRule}
     * @memberof CreditScoringRow
     */
    'creditScoringSettingRule': CreditScoringSettingRule;
    /**
     * 
     * @type {number}
     * @memberof CreditScoringRow
     */
    'value': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CreditScoringSettingRule = {
    CREDIT_SCORING_MUST_BE_GREATER_OR_EQUAL: 'CREDIT_SCORING_MUST_BE_GREATER_OR_EQUAL'
} as const;

export type CreditScoringSettingRule = typeof CreditScoringSettingRule[keyof typeof CreditScoringSettingRule];


/**
 * 
 * @export
 * @interface CsValueResponse
 */
export interface CsValueResponse {
    /**
     * 
     * @type {number}
     * @memberof CsValueResponse
     */
    'csValue'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Currency = {
    MNT: 'MNT'
} as const;

export type Currency = typeof Currency[keyof typeof Currency];


/**
 * 
 * @export
 * @interface CurrentLoansScore
 */
export interface CurrentLoansScore {
    /**
     * 
     * @type {LoanClassification}
     * @memberof CurrentLoansScore
     */
    'loanClassification'?: LoanClassification;
    /**
     * 
     * @type {FromToPairInteger}
     * @memberof CurrentLoansScore
     */
    'loansCount'?: FromToPairInteger;
    /**
     * 
     * @type {number}
     * @memberof CurrentLoansScore
     */
    'score'?: number;
}


/**
 * 
 * @export
 * @interface CurrentLoansScoreCustomerResult
 */
export interface CurrentLoansScoreCustomerResult {
    /**
     * 
     * @type {CurrentLoansScore}
     * @memberof CurrentLoansScoreCustomerResult
     */
    'currentLoansScoreSetting'?: CurrentLoansScore;
    /**
     * 
     * @type {LoanClassification}
     * @memberof CurrentLoansScoreCustomerResult
     */
    'customerLoanClassification'?: LoanClassification;
    /**
     * 
     * @type {number}
     * @memberof CurrentLoansScoreCustomerResult
     */
    'customerLoansCount'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CurrentLoansScoringColumnKey = {
    LOAN_CLASSIFICATION: 'LOAN_CLASSIFICATION',
    LOANS_COUNT: 'LOANS_COUNT'
} as const;

export type CurrentLoansScoringColumnKey = typeof CurrentLoansScoringColumnKey[keyof typeof CurrentLoansScoringColumnKey];


/**
 * 
 * @export
 * @interface CurrentLoansScoringColumnMetadata
 */
export interface CurrentLoansScoringColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof CurrentLoansScoringColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {CurrentLoansScoringColumnKey}
     * @memberof CurrentLoansScoringColumnMetadata
     */
    'key'?: CurrentLoansScoringColumnKey;
    /**
     * 
     * @type {LoanClassification}
     * @memberof CurrentLoansScoringColumnMetadata
     */
    'payload'?: LoanClassification;
}


/**
 * 
 * @export
 * @interface CurrentLoansScoringRow
 */
export interface CurrentLoansScoringRow {
    /**
     * 
     * @type {FromToPairInteger}
     * @memberof CurrentLoansScoringRow
     */
    'loansCount'?: FromToPairInteger;
    /**
     * 
     * @type {Array<number>}
     * @memberof CurrentLoansScoringRow
     */
    'scores'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CurrentLoansScoringSettingDto
 */
export interface CurrentLoansScoringSettingDto extends Settings {
    /**
     * 
     * @type {Array<CurrentLoansScoringColumnMetadata>}
     * @memberof CurrentLoansScoringSettingDto
     */
    'columns'?: Array<CurrentLoansScoringColumnMetadata>;
    /**
     * 
     * @type {Array<CurrentLoansScoringRow>}
     * @memberof CurrentLoansScoringSettingDto
     */
    'rows'?: Array<CurrentLoansScoringRow>;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name'?: string;
    /**
     * 
     * @type {CustomerType}
     * @memberof Customer
     */
    'type': CustomerType;
}


/**
 * 
 * @export
 * @interface CustomerAddress
 */
export interface CustomerAddress {
    /**
     * 
     * @type {Address}
     * @memberof CustomerAddress
     */
    'address': Address;
    /**
     * 
     * @type {AddressOwner}
     * @memberof CustomerAddress
     */
    'addressOwner'?: AddressOwner;
    /**
     * 
     * @type {AddressType}
     * @memberof CustomerAddress
     */
    'addressType': AddressType;
}


/**
 * 
 * @export
 * @interface CustomerAddressUpdate
 */
export interface CustomerAddressUpdate {
    /**
     * 
     * @type {AddressUpdate}
     * @memberof CustomerAddressUpdate
     */
    'address': AddressUpdate;
    /**
     * 
     * @type {AddressOwner}
     * @memberof CustomerAddressUpdate
     */
    'addressOwner': AddressOwner;
    /**
     * 
     * @type {AddressType}
     * @memberof CustomerAddressUpdate
     */
    'addressType': AddressType;
}


/**
 * 
 * @export
 * @interface CustomerContactDetails
 */
export interface CustomerContactDetails {
    /**
     * 
     * @type {Array<CustomerAddress>}
     * @memberof CustomerContactDetails
     */
    'customerAddresses'?: Array<CustomerAddress>;
    /**
     * 
     * @type {Array<CustomerPhoneNumber>}
     * @memberof CustomerContactDetails
     */
    'customerPhoneNumbers'?: Array<CustomerPhoneNumber>;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetails
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerContactDetails
     */
    'emailVerified'?: boolean;
    /**
     * 
     * @type {Array<SocialMediaLink>}
     * @memberof CustomerContactDetails
     */
    'socialMediaLinks'?: Array<SocialMediaLink>;
}
/**
 * 
 * @export
 * @interface CustomerDecision
 */
export interface CustomerDecision {
    /**
     * 
     * @type {string}
     * @memberof CustomerDecision
     */
    'createdAt': string;
    /**
     * 
     * @type {CheckStepName}
     * @memberof CustomerDecision
     */
    'createdByCheck'?: CheckStepName;
    /**
     * 
     * @type {CheckServiceName}
     * @memberof CustomerDecision
     */
    'createdByService'?: CheckServiceName;
    /**
     * 
     * @type {number}
     * @memberof CustomerDecision
     */
    'customerId': number;
    /**
     * 
     * @type {VerificationCheckDecision}
     * @memberof CustomerDecision
     */
    'decision': VerificationCheckDecision;
    /**
     * 
     * @type {VerificationCheckDecisionType}
     * @memberof CustomerDecision
     */
    'decisionType': VerificationCheckDecisionType;
    /**
     * 
     * @type {number}
     * @memberof CustomerDecision
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDecision
     */
    'reason'?: string;
}


/**
 * 
 * @export
 * @interface CustomerGroup
 */
export interface CustomerGroup {
    /**
     * 
     * @type {string}
     * @memberof CustomerGroup
     */
    'createdAt': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof CustomerGroup
     */
    'createdBy': IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof CustomerGroup
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerGroup
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerGroup
     */
    'priority': number;
    /**
     * 
     * @type {Array<ProductLine>}
     * @memberof CustomerGroup
     */
    'productLines': Array<ProductLine>;
}
/**
 * 
 * @export
 * @interface CustomerGroupCompany
 */
export interface CustomerGroupCompany {
    /**
     * 
     * @type {string}
     * @memberof CustomerGroupCompany
     */
    'addedAt': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof CustomerGroupCompany
     */
    'addedBy': IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof CustomerGroupCompany
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CustomerGroupUpdate
 */
export interface CustomerGroupUpdate {
    /**
     * 
     * @type {string}
     * @memberof CustomerGroupUpdate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerGroupUpdate
     */
    'priority': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CustomerGroupUpdate
     */
    'productLineIds': Array<number>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerIncomeType = {
    BUSINESS: 'BUSINESS',
    SALARY: 'SALARY',
    LIVESTOCK: 'LIVESTOCK'
} as const;

export type CustomerIncomeType = typeof CustomerIncomeType[keyof typeof CustomerIncomeType];


/**
 * 
 * @export
 * @interface CustomerJobInformationRequest
 */
export interface CustomerJobInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerJobInformationRequest
     */
    'companyName': string;
    /**
     * 
     * @type {Education}
     * @memberof CustomerJobInformationRequest
     */
    'education': Education;
    /**
     * 
     * @type {Employment}
     * @memberof CustomerJobInformationRequest
     */
    'employment': Employment;
    /**
     * 
     * @type {Industry}
     * @memberof CustomerJobInformationRequest
     */
    'industry': Industry;
    /**
     * 
     * @type {AddressUpdate}
     * @memberof CustomerJobInformationRequest
     */
    'jobAddress': AddressUpdate;
    /**
     * 
     * @type {string}
     * @memberof CustomerJobInformationRequest
     */
    'position': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerJobInformationRequest
     */
    'workPhoneNumber': string;
}


/**
 * 
 * @export
 * @interface CustomerJobInformationResponse
 */
export interface CustomerJobInformationResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomerJobInformationResponse
     */
    'companyName': string;
    /**
     * 
     * @type {Education}
     * @memberof CustomerJobInformationResponse
     */
    'education': Education;
    /**
     * 
     * @type {Employment}
     * @memberof CustomerJobInformationResponse
     */
    'employment': Employment;
    /**
     * 
     * @type {Industry}
     * @memberof CustomerJobInformationResponse
     */
    'industry': Industry;
    /**
     * 
     * @type {Address}
     * @memberof CustomerJobInformationResponse
     */
    'jobAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CustomerJobInformationResponse
     */
    'position': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerJobInformationResponse
     */
    'workPhoneNumber'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerKycLevel = {
    KYC1: 'KYC1',
    KYC2: 'KYC2',
    KYC3: 'KYC3',
    KYC4: 'KYC4'
} as const;

export type CustomerKycLevel = typeof CustomerKycLevel[keyof typeof CustomerKycLevel];


/**
 * 
 * @export
 * @interface CustomerLivestockIncomeData
 */
export interface CustomerLivestockIncomeData {
    /**
     * 
     * @type {string}
     * @memberof CustomerLivestockIncomeData
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerLivestockIncomeData
     */
    'customerId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerLivestockIncomeData
     */
    'hasVariance'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomerLivestockIncomeData
     */
    'herdingMonths'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerLivestockIncomeData
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerLivestockIncomeData
     */
    'incomeTypeId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerLivestockIncomeData
     */
    'isCountEnough'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerLivestockIncomeData
     */
    'isCountedInLastTwoYears'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomerLivestockIncomeData
     */
    'monthlyIncome'?: number;
}
/**
 * 
 * @export
 * @interface CustomerLoanInfo
 */
export interface CustomerLoanInfo {
    /**
     * 
     * @type {string}
     * @memberof CustomerLoanInfo
     */
    'branchCode': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerLoanInfo
     */
    'civilId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerLoanInfo
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerLoanInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerLoanInfo
     */
    'registrationNumber': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerLoanInfo
     */
    'relationshipManagerId': number;
}
/**
 * 
 * @export
 * @interface CustomerPaymentAccount
 */
export interface CustomerPaymentAccount {
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentAccount
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentAccount
     */
    'accountNumber': string;
    /**
     * 
     * @type {BankInformationDto}
     * @memberof CustomerPaymentAccount
     */
    'bankInformation': BankInformationDto;
    /**
     * 
     * @type {Currency}
     * @memberof CustomerPaymentAccount
     */
    'currency': Currency;
    /**
     * 
     * @type {number}
     * @memberof CustomerPaymentAccount
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPaymentAccount
     */
    'mainAccount': boolean;
}


/**
 * 
 * @export
 * @interface CustomerPaymentAccountUpdate
 */
export interface CustomerPaymentAccountUpdate {
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentAccountUpdate
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentAccountUpdate
     */
    'accountNumber': string;
    /**
     * 
     * @type {BankInformation}
     * @memberof CustomerPaymentAccountUpdate
     */
    'bankInformation': BankInformation;
    /**
     * 
     * @type {Currency}
     * @memberof CustomerPaymentAccountUpdate
     */
    'currency': Currency;
    /**
     * 
     * @type {number}
     * @memberof CustomerPaymentAccountUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPaymentAccountUpdate
     */
    'mainAccount': boolean;
}


/**
 * 
 * @export
 * @interface CustomerPhoneNumber
 */
export interface CustomerPhoneNumber {
    /**
     * 
     * @type {number}
     * @memberof CustomerPhoneNumber
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPhoneNumber
     */
    'isMain': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerPhoneNumber
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface CustomerReference
 */
export interface CustomerReference {
    /**
     * 
     * @type {string}
     * @memberof CustomerReference
     */
    'firstName': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerReference
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerReference
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReference
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReference
     */
    'registrationNumber': string;
    /**
     * 
     * @type {ReferenceRelationship}
     * @memberof CustomerReference
     */
    'relationship': ReferenceRelationship;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerSegment = {
    NEW: 'NEW',
    PREMIUM: 'PREMIUM',
    GOLDEN: 'GOLDEN',
    SILVER: 'SILVER',
    BRONZE: 'BRONZE',
    SIGNATURE: 'SIGNATURE',
    VIP: 'VIP'
} as const;

export type CustomerSegment = typeof CustomerSegment[keyof typeof CustomerSegment];


/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerStatus = {
    ACTIVE: 'ACTIVE',
    DISABLED: 'DISABLED',
    CANCELLED_BY_CUSTOMER: 'CANCELLED_BY_CUSTOMER'
} as const;

export type CustomerStatus = typeof CustomerStatus[keyof typeof CustomerStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerType = {
    INDIVIDUAL: 'INDIVIDUAL',
    LEGAL: 'LEGAL'
} as const;

export type CustomerType = typeof CustomerType[keyof typeof CustomerType];


/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerVerificationStatus = {
    NEW: 'NEW',
    AGE_CHECK_APPROVED: 'AGE_CHECK_APPROVED',
    AGE_CHECK_REJECTED: 'AGE_CHECK_REJECTED',
    BLACK_LIST_CHECK_APPROVED: 'BLACK_LIST_CHECK_APPROVED',
    BLACK_LIST_CHECK_REJECTED: 'BLACK_LIST_CHECK_REJECTED',
    INCOME_CHECK_APPROVED: 'INCOME_CHECK_APPROVED',
    INCOME_CHECK_REJECTED: 'INCOME_CHECK_REJECTED',
    CREDIT_BUREAU_CHECK_APPROVED: 'CREDIT_BUREAU_CHECK_APPROVED',
    CREDIT_BUREAU_CHECK_REJECTED: 'CREDIT_BUREAU_CHECK_REJECTED',
    CREDIT_BUREAU_CHECK_ERROR: 'CREDIT_BUREAU_CHECK_ERROR',
    CREDIT_SCORING_APPROVED: 'CREDIT_SCORING_APPROVED',
    CREDIT_SCORING_REJECTED: 'CREDIT_SCORING_REJECTED',
    LIMIT_OFFERED: 'LIMIT_OFFERED',
    MA_SIGNED: 'MA_SIGNED'
} as const;

export type CustomerVerificationStatus = typeof CustomerVerificationStatus[keyof typeof CustomerVerificationStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const DayOfWeek = {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY'
} as const;

export type DayOfWeek = typeof DayOfWeek[keyof typeof DayOfWeek];


/**
 * 
 * @export
 * @interface DebtCollection
 */
export interface DebtCollection {
    /**
     * 
     * @type {Array<DebtCollectionActionsInner>}
     * @memberof DebtCollection
     */
    'actions': Array<DebtCollectionActionsInner>;
    /**
     * 
     * @type {DebtCollectionCustomer}
     * @memberof DebtCollection
     */
    'customer': DebtCollectionCustomer;
    /**
     * 
     * @type {number}
     * @memberof DebtCollection
     */
    'customerTotalLoansCount': number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof DebtCollection
     */
    'debtCollector': IdNameEntry;
    /**
     * 
     * @type {CollectionLevel}
     * @memberof DebtCollection
     */
    'level': CollectionLevel;
    /**
     * 
     * @type {number}
     * @memberof DebtCollection
     */
    'maxDaysOverdue': number;
    /**
     * 
     * @type {CollectionActivityOverdueReason}
     * @memberof DebtCollection
     */
    'overdueReason'?: CollectionActivityOverdueReason;
    /**
     * 
     * @type {number}
     * @memberof DebtCollection
     */
    'paymentAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DebtCollection
     */
    'principalAmount': number;
    /**
     * 
     * @type {CollectionStatus}
     * @memberof DebtCollection
     */
    'status': CollectionStatus;
    /**
     * 
     * @type {number}
     * @memberof DebtCollection
     */
    'totalLoansCount': number;
}


/**
 * @type DebtCollectionActionsInner
 * @export
 */
export type DebtCollectionActionsInner = StartProcessUserAction | TaskUserAction | TypedUserAction;

/**
 * 
 * @export
 * @interface DebtCollectionActivity
 */
export interface DebtCollectionActivity {
    /**
     * 
     * @type {Array<CivilServant>}
     * @memberof DebtCollectionActivity
     */
    'civilServants': Array<CivilServant>;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionActivity
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionActivity
     */
    'commitmentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionActivity
     */
    'commitmentDate'?: string;
    /**
     * 
     * @type {CollectionActivityContactedPerson}
     * @memberof DebtCollectionActivity
     */
    'contactedPerson': CollectionActivityContactedPerson;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionActivity
     */
    'createdAt': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof DebtCollectionActivity
     */
    'debtCollector': IdNameEntry;
    /**
     * 
     * @type {BoUserDepartment}
     * @memberof DebtCollectionActivity
     */
    'department': BoUserDepartment;
    /**
     * 
     * @type {CollectionActivityGeneralType}
     * @memberof DebtCollectionActivity
     */
    'generalType': CollectionActivityGeneralType;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionActivity
     */
    'id': number;
    /**
     * 
     * @type {CollectionLevel}
     * @memberof DebtCollectionActivity
     */
    'level': CollectionLevel;
    /**
     * 
     * @type {CollectionActivityNextAction}
     * @memberof DebtCollectionActivity
     */
    'nextAction': CollectionActivityNextAction;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionActivity
     */
    'nextActionDate': string;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionActivity
     */
    'ordinalNumber': number;
    /**
     * 
     * @type {CollectionActivityOverdueReason}
     * @memberof DebtCollectionActivity
     */
    'overdueReason'?: CollectionActivityOverdueReason;
    /**
     * 
     * @type {CollectionActivityStatus}
     * @memberof DebtCollectionActivity
     */
    'status': CollectionActivityStatus;
    /**
     * 
     * @type {CollectionActivitySubType}
     * @memberof DebtCollectionActivity
     */
    'subType': CollectionActivitySubType;
    /**
     * 
     * @type {CollectionActivityType}
     * @memberof DebtCollectionActivity
     */
    'type': CollectionActivityType;
}


/**
 * 
 * @export
 * @interface DebtCollectionActivityListEntry
 */
export interface DebtCollectionActivityListEntry {
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionActivityListEntry
     */
    'createdAt': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof DebtCollectionActivityListEntry
     */
    'debtCollector': IdNameEntry;
    /**
     * 
     * @type {CollectionActivityGeneralType}
     * @memberof DebtCollectionActivityListEntry
     */
    'generalType': CollectionActivityGeneralType;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionActivityListEntry
     */
    'id': number;
    /**
     * 
     * @type {CollectionLevel}
     * @memberof DebtCollectionActivityListEntry
     */
    'level': CollectionLevel;
    /**
     * 
     * @type {CollectionActivityNextAction}
     * @memberof DebtCollectionActivityListEntry
     */
    'nextAction': CollectionActivityNextAction;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionActivityListEntry
     */
    'nextActionDate': string;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionActivityListEntry
     */
    'ordinalNumber': number;
    /**
     * 
     * @type {CollectionActivityStatus}
     * @memberof DebtCollectionActivityListEntry
     */
    'status': CollectionActivityStatus;
    /**
     * 
     * @type {CollectionActivityType}
     * @memberof DebtCollectionActivityListEntry
     */
    'type': CollectionActivityType;
}


/**
 * 
 * @export
 * @interface DebtCollectionCustomer
 */
export interface DebtCollectionCustomer {
    /**
     * 
     * @type {CustomerContactDetails}
     * @memberof DebtCollectionCustomer
     */
    'contactDetails': CustomerContactDetails;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionCustomer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionCustomer
     */
    'name': string;
    /**
     * 
     * @type {Array<CustomerReference>}
     * @memberof DebtCollectionCustomer
     */
    'references'?: Array<CustomerReference>;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionCustomer
     */
    'registrationNumber': string;
    /**
     * 
     * @type {CustomerType}
     * @memberof DebtCollectionCustomer
     */
    'type': CustomerType;
}


/**
 * 
 * @export
 * @interface DebtCollectionLevelUpdate
 */
export interface DebtCollectionLevelUpdate extends DebtCollectionUpdate {
    /**
     * 
     * @type {CollectionLevel}
     * @memberof DebtCollectionLevelUpdate
     */
    'level': CollectionLevel;
}


/**
 * 
 * @export
 * @interface DebtCollectionListEntry
 */
export interface DebtCollectionListEntry {
    /**
     * 
     * @type {Customer}
     * @memberof DebtCollectionListEntry
     */
    'customer': Customer;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof DebtCollectionListEntry
     */
    'debtCollector'?: IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionListEntry
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionListEntry
     */
    'lastActivityDate'?: string;
    /**
     * 
     * @type {CollectionActivityGeneralType}
     * @memberof DebtCollectionListEntry
     */
    'lastActivityGeneralType'?: CollectionActivityGeneralType;
    /**
     * 
     * @type {CollectionLevel}
     * @memberof DebtCollectionListEntry
     */
    'level'?: CollectionLevel;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionListEntry
     */
    'maxDaysOverdue': number;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionListEntry
     */
    'nextActionDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionListEntry
     */
    'paymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionListEntry
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionListEntry
     */
    'principalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionListEntry
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {CollectionStatus}
     * @memberof DebtCollectionListEntry
     */
    'status': CollectionStatus;
}


/**
 * 
 * @export
 * @interface DebtCollectionLoanListEntry
 */
export interface DebtCollectionLoanListEntry {
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionLoanListEntry
     */
    'amount': number;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof DebtCollectionLoanListEntry
     */
    'coBorrowers': Array<IdNameEntry>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DebtCollectionLoanListEntry
     */
    'collateralIds': Array<number>;
    /**
     * 
     * @type {CreditBureauStatus}
     * @memberof DebtCollectionLoanListEntry
     */
    'creditBureauStatus': CreditBureauStatus;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionLoanListEntry
     */
    'daysOverdue': number;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionLoanListEntry
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionLoanListEntry
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionLoanListEntry
     */
    'interestAmount': number;
    /**
     * 
     * @type {LoanStatus}
     * @memberof DebtCollectionLoanListEntry
     */
    'loanStatus': LoanStatus;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionLoanListEntry
     */
    'maturityDate': string;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionLoanListEntry
     */
    'nextPaymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionLoanListEntry
     */
    'nextPaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionLoanListEntry
     */
    'paymentAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionLoanListEntry
     */
    'penaltyAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionLoanListEntry
     */
    'principalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionLoanListEntry
     */
    'totalPaidAmount': number;
}


/**
 * 
 * @export
 * @interface DebtCollectionReAssign
 */
export interface DebtCollectionReAssign {
    /**
     * 
     * @type {Array<number>}
     * @memberof DebtCollectionReAssign
     */
    'collectionIds': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionReAssign
     */
    'debtCollectorId': number;
    /**
     * 
     * @type {boolean}
     * @memberof DebtCollectionReAssign
     */
    'isAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface DebtCollectionSummary
 */
export interface DebtCollectionSummary {
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionSummary
     */
    'failed': number;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionSummary
     */
    'pending': number;
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionSummary
     */
    'successful': number;
}
/**
 * 
 * @export
 * @interface DebtCollectionUpdate
 */
export interface DebtCollectionUpdate {
    /**
     * 
     * @type {string}
     * @memberof DebtCollectionUpdate
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface DebtCollector
 */
export interface DebtCollector {
    /**
     * 
     * @type {string}
     * @memberof DebtCollector
     */
    'roleName': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof DebtCollector
     */
    'user': IdNameEntry;
}
/**
 * 
 * @export
 * @interface DistrictDto
 */
export interface DistrictDto {
    /**
     * 
     * @type {string}
     * @memberof DistrictDto
     */
    'districtCode': string;
    /**
     * 
     * @type {Array<KhorooDto>}
     * @memberof DistrictDto
     */
    'khorooDtoList': Array<KhorooDto>;
    /**
     * 
     * @type {Localization}
     * @memberof DistrictDto
     */
    'l10n': Localization;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {Array<DebtCollectionActionsInner>}
     * @memberof Document
     */
    'actions': Array<DebtCollectionActionsInner>;
    /**
     * 
     * @type {FileMetadata}
     * @memberof Document
     */
    'file': FileMetadata;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'id': number;
    /**
     * 
     * @type {DocumentSource}
     * @memberof Document
     */
    'source': DocumentSource;
    /**
     * 
     * @type {DocumentType}
     * @memberof Document
     */
    'type': DocumentType;
}


/**
 * 
 * @export
 * @interface DocumentRoom
 */
export interface DocumentRoom {
    /**
     * 
     * @type {Array<DocumentType>}
     * @memberof DocumentRoom
     */
    'filterableTypes': Array<DocumentType>;
    /**
     * 
     * @type {number}
     * @memberof DocumentRoom
     */
    'id': number;
    /**
     * 
     * @type {Array<DocumentType>}
     * @memberof DocumentRoom
     */
    'uploadableTypes': Array<DocumentType>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentSource = {
    BANK: 'BANK',
    CUSTOMER: 'CUSTOMER',
    EMONGOLIA: 'EMONGOLIA',
    HES: 'HES'
} as const;

export type DocumentSource = typeof DocumentSource[keyof typeof DocumentSource];


/**
 * 
 * @export
 * @interface DocumentTemplateListEntry
 */
export interface DocumentTemplateListEntry {
    /**
     * 
     * @type {FileMetadata}
     * @memberof DocumentTemplateListEntry
     */
    'file': FileMetadata;
    /**
     * 
     * @type {number}
     * @memberof DocumentTemplateListEntry
     */
    'id': number;
    /**
     * 
     * @type {DocumentTemplateType}
     * @memberof DocumentTemplateListEntry
     */
    'type': DocumentTemplateType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentTemplateType = {
    BNPL_LOAN_AGREEMENT: 'BNPL_LOAN_AGREEMENT',
    CIB_LOAN_STATUS_DECREASED_NOTICE: 'CIB_LOAN_STATUS_DECREASED_NOTICE',
    CIB_LOAN_STATUS_WILL_DECREASE_NOTICE: 'CIB_LOAN_STATUS_WILL_DECREASE_NOTICE',
    COLLATERAL_FIDUCIARY_AGREEMENT: 'COLLATERAL_FIDUCIARY_AGREEMENT',
    COLLATERAL_MOVABLE_ASSET_AGREEMENT: 'COLLATERAL_MOVABLE_ASSET_AGREEMENT',
    COLLATERAL_REAL_ESTATE_AGREEMENT: 'COLLATERAL_REAL_ESTATE_AGREEMENT',
    COLLATERAL_REAL_ESTATE_WITH_CO_OWNER_AGREEMENT: 'COLLATERAL_REAL_ESTATE_WITH_CO_OWNER_AGREEMENT',
    INSTALLMENT_LOAN_AGREEMENT: 'INSTALLMENT_LOAN_AGREEMENT',
    MASTER_AGREEMENT: 'MASTER_AGREEMENT',
    PAYON_LOAN_AGREEMENT: 'PAYON_LOAN_AGREEMENT',
    PLEDGED_CAR_CO_BORROWER_LOAN_AGREEMENT: 'PLEDGED_CAR_CO_BORROWER_LOAN_AGREEMENT',
    PLEDGED_CAR_LOAN_AGREEMENT: 'PLEDGED_CAR_LOAN_AGREEMENT',
    PLEDGED_LEASING_CO_BORROWER_LOAN_AGREEMENT: 'PLEDGED_LEASING_CO_BORROWER_LOAN_AGREEMENT',
    PLEDGED_LEASING_LOAN_AGREEMENT: 'PLEDGED_LEASING_LOAN_AGREEMENT',
    PLEDGED_LOAN_AGREEMENT: 'PLEDGED_LOAN_AGREEMENT',
    PLEDGED_LOAN_CO_BORROWER_AGREEMENT: 'PLEDGED_LOAN_CO_BORROWER_AGREEMENT',
    PLEDGED_TEXTILE_CO_BORROWER_LOAN_AGREEMENT: 'PLEDGED_TEXTILE_CO_BORROWER_LOAN_AGREEMENT',
    PLEDGED_TEXTILE_LOAN_AGREEMENT: 'PLEDGED_TEXTILE_LOAN_AGREEMENT',
    RESTRICTIONS_LIST_SAMPLE: 'RESTRICTIONS_LIST_SAMPLE',
    TRUST_PLEDGED_LOAN_AGREEMENT: 'TRUST_PLEDGED_LOAN_AGREEMENT',
    TRUST_PLEDGED_WITH_CO_BORROWER_LOAN_AGREEMENT: 'TRUST_PLEDGED_WITH_CO_BORROWER_LOAN_AGREEMENT'
} as const;

export type DocumentTemplateType = typeof DocumentTemplateType[keyof typeof DocumentTemplateType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentType = {
    BANK_STATEMENT: 'BANK_STATEMENT',
    CERTIFICATE_OF_REGISTRATION: 'CERTIFICATE_OF_REGISTRATION',
    CHARTER: 'CHARTER',
    COLLATERAL_AGREEMENT: 'COLLATERAL_AGREEMENT',
    COLLATERAL_DOCUMENT: 'COLLATERAL_DOCUMENT',
    COLLATERAL_FIDUCIARY_AGREEMENT: 'COLLATERAL_FIDUCIARY_AGREEMENT',
    COLLATERAL_MOVABLE_ASSET_AGREEMENT: 'COLLATERAL_MOVABLE_ASSET_AGREEMENT',
    COLLATERAL_REAL_ESTATE_AGREEMENT: 'COLLATERAL_REAL_ESTATE_AGREEMENT',
    CREDIT_INFORMATION_BUREAU_STATEMENT: 'CREDIT_INFORMATION_BUREAU_STATEMENT',
    CUSTOMER_ANKET: 'CUSTOMER_ANKET',
    ID_CARD_IMAGE: 'ID_CARD_IMAGE',
    ID_DOCUMENT: 'ID_DOCUMENT',
    LICENSE: 'LICENSE',
    LOAN_AGREEMENT: 'LOAN_AGREEMENT',
    LOGO: 'LOGO',
    MASTER_AGREEMENT: 'MASTER_AGREEMENT',
    OFFICIAL_DOCUMENT: 'OFFICIAL_DOCUMENT',
    OFFICIAL_REQUEST: 'OFFICIAL_REQUEST',
    OTHER: 'OTHER',
    REFUND_FEE_CHECK: 'REFUND_FEE_CHECK',
    RESTRUCTURED_AGREEMENT: 'RESTRUCTURED_AGREEMENT',
    SELFIE_IMAGE: 'SELFIE_IMAGE',
    SHAREHOLDERS_AGREEMENT: 'SHAREHOLDERS_AGREEMENT',
    SIGNATURE: 'SIGNATURE',
    SOCIAL_INSURANCE_STATEMENT: 'SOCIAL_INSURANCE_STATEMENT',
    STATEMENT_FILE: 'STATEMENT_FILE'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @interface EditMerchantForm
 */
export interface EditMerchantForm {
    /**
     * 
     * @type {MerchantCompany}
     * @memberof EditMerchantForm
     */
    'company': MerchantCompany;
    /**
     * 
     * @type {string}
     * @memberof EditMerchantForm
     */
    'companyAddress': string;
    /**
     * 
     * @type {string}
     * @memberof EditMerchantForm
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EditMerchantForm
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditMerchantForm
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof EditMerchantForm
     */
    'surname': string;
}


/**
 * 
 * @export
 * @interface EditUserForm
 */
export interface EditUserForm {
    /**
     * 
     * @type {number}
     * @memberof EditUserForm
     */
    'childBranchId': number;
    /**
     * 
     * @type {BoUserDepartment}
     * @memberof EditUserForm
     */
    'department': BoUserDepartment;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserForm
     */
    'isBranchManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserForm
     */
    'isRelationshipManager': boolean;
    /**
     * 
     * @type {string}
     * @memberof EditUserForm
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditUserForm
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof EditUserForm
     */
    'position': string;
    /**
     * 
     * @type {number}
     * @memberof EditUserForm
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof EditUserForm
     */
    'surname': string;
    /**
     * 
     * @type {string}
     * @memberof EditUserForm
     */
    'teamsProfile': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Education = {
    OTHER: 'OTHER',
    PRIMARY_SCHOOL: 'PRIMARY_SCHOOL',
    MIDDLE_SCHOOL: 'MIDDLE_SCHOOL',
    HIGH_SCHOOL: 'HIGH_SCHOOL',
    COLLEGE_DEGREE: 'COLLEGE_DEGREE',
    MBA: 'MBA',
    PHD: 'PHD'
} as const;

export type Education = typeof Education[keyof typeof Education];


/**
 * 
 * @export
 * @interface EducationScore
 */
export interface EducationScore {
    /**
     * 
     * @type {Education}
     * @memberof EducationScore
     */
    'education'?: Education;
    /**
     * 
     * @type {number}
     * @memberof EducationScore
     */
    'score'?: number;
}


/**
 * 
 * @export
 * @interface EducationScoreCustomerResult
 */
export interface EducationScoreCustomerResult {
    /**
     * 
     * @type {Education}
     * @memberof EducationScoreCustomerResult
     */
    'customerEducation'?: Education;
    /**
     * 
     * @type {EducationScore}
     * @memberof EducationScoreCustomerResult
     */
    'educationScoreSetting'?: EducationScore;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const EducationScoringColumnKey = {
    SCORES: 'SCORES',
    EDUCATION: 'EDUCATION'
} as const;

export type EducationScoringColumnKey = typeof EducationScoringColumnKey[keyof typeof EducationScoringColumnKey];


/**
 * 
 * @export
 * @interface EducationScoringColumnMetadata
 */
export interface EducationScoringColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof EducationScoringColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {EducationScoringColumnKey}
     * @memberof EducationScoringColumnMetadata
     */
    'key'?: EducationScoringColumnKey;
    /**
     * 
     * @type {object}
     * @memberof EducationScoringColumnMetadata
     */
    'payload'?: object;
}


/**
 * 
 * @export
 * @interface EducationScoringRow
 */
export interface EducationScoringRow {
    /**
     * 
     * @type {Education}
     * @memberof EducationScoringRow
     */
    'education'?: Education;
    /**
     * 
     * @type {Array<number>}
     * @memberof EducationScoringRow
     */
    'scores'?: Array<number>;
}


/**
 * 
 * @export
 * @interface EducationScoringSettingDto
 */
export interface EducationScoringSettingDto extends Settings {
    /**
     * 
     * @type {Array<EducationScoringColumnMetadata>}
     * @memberof EducationScoringSettingDto
     */
    'columns'?: Array<EducationScoringColumnMetadata>;
    /**
     * 
     * @type {Array<EducationScoringRow>}
     * @memberof EducationScoringSettingDto
     */
    'rows'?: Array<EducationScoringRow>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Employment = {
    CIVIL_SERVANT: 'CIVIL_SERVANT',
    COMPANY_EMPLOYEE: 'COMPANY_EMPLOYEE',
    INVESTOR: 'INVESTOR',
    EMPLOYER: 'EMPLOYER',
    SELF_EMPLOYED: 'SELF_EMPLOYED',
    RETIRED: 'RETIRED',
    STUDENT: 'STUDENT',
    FARMER: 'FARMER',
    OTHERS: 'OTHERS'
} as const;

export type Employment = typeof Employment[keyof typeof Employment];


/**
 * 
 * @export
 * @enum {string}
 */

export const EnterpriseType = {
    BANK: 'BANK',
    FIC: 'FIC',
    INVESTMENT_FUND: 'INVESTMENT_FUND',
    LC: 'LC',
    LLC: 'LLC',
    NBFI: 'NBFI',
    NGO: 'NGO',
    OTHER: 'OTHER',
    PARTNERSHIP: 'PARTNERSHIP',
    SOPC: 'SOPC'
} as const;

export type EnterpriseType = typeof EnterpriseType[keyof typeof EnterpriseType];


/**
 * 
 * @export
 * @interface EquipmentCollateral
 */
export interface EquipmentCollateral extends Collateral {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof EquipmentCollateral
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateral
     */
    'brandName'?: string;
    /**
     * 
     * @type {Array<CollateralCoOwner>}
     * @memberof EquipmentCollateral
     */
    'coOwners': Array<CollateralCoOwner>;
    /**
     * 
     * @type {number}
     * @memberof EquipmentCollateral
     */
    'commentRoomId': number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateral
     */
    'country'?: string;
    /**
     * 
     * @type {IdEntry}
     * @memberof EquipmentCollateral
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {EquipmentType}
     * @memberof EquipmentCollateral
     */
    'equipmentType'?: EquipmentType;
    /**
     * 
     * @type {Evaluation}
     * @memberof EquipmentCollateral
     */
    'evaluation'?: Evaluation;
    /**
     * 
     * @type {number}
     * @memberof EquipmentCollateral
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateral
     */
    'importedDate'?: string;
    /**
     * 
     * @type {Array<Insurance>}
     * @memberof EquipmentCollateral
     */
    'insurances': Array<Insurance>;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof EquipmentCollateral
     */
    'loans': Array<IdNameEntry>;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateral
     */
    'manufacturedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateral
     */
    'model'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateral
     */
    'name'?: string;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof EquipmentCollateral
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateral
     */
    'serialNumber': string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof EquipmentCollateral
     */
    'subType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof EquipmentCollateral
     */
    'type': CollateralType;
}


/**
 * 
 * @export
 * @interface EquipmentCollateralPatch
 */
export interface EquipmentCollateralPatch extends CollateralPatch {
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateralPatch
     */
    'brandName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateralPatch
     */
    'country'?: string;
    /**
     * 
     * @type {EquipmentType}
     * @memberof EquipmentCollateralPatch
     */
    'equipmentType'?: EquipmentType;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateralPatch
     */
    'importedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateralPatch
     */
    'manufacturedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateralPatch
     */
    'model'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateralPatch
     */
    'name'?: string;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof EquipmentCollateralPatch
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCollateralPatch
     */
    'serialNumber': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const EquipmentType = {
    AUTO_REPAIR_WASHING: 'AUTO_REPAIR_WASHING',
    INDUSTRIAL: 'INDUSTRIAL',
    BUILDING: 'BUILDING',
    BARRELS_CISTERNS: 'BARRELS_CISTERNS',
    COSMETIC: 'COSMETIC',
    SHOP_BOOTH: 'SHOP_BOOTH',
    COMMERCIAL_RESTAURANT: 'COMMERCIAL_RESTAURANT',
    CASH_REGISTER_TERMINAL_PAPER: 'CASH_REGISTER_TERMINAL_PAPER',
    AGRICULTURAL: 'AGRICULTURAL',
    PRINTING: 'PRINTING',
    COOKING: 'COOKING',
    SECURITY_CAMERA_TIME_REGISTRATION: 'SECURITY_CAMERA_TIME_REGISTRATION',
    ELECTRICITY: 'ELECTRICITY',
    MINING_DRILLING: 'MINING_DRILLING',
    MEDICAL: 'MEDICAL',
    OTHER: 'OTHER'
} as const;

export type EquipmentType = typeof EquipmentType[keyof typeof EquipmentType];


/**
 * 
 * @export
 * @enum {string}
 */

export const EstatePurpose = {
    LIVE: 'LIVE',
    SERVICE_PURPOSE: 'SERVICE_PURPOSE',
    INDUSTRIAL_PURPOSE: 'INDUSTRIAL_PURPOSE'
} as const;

export type EstatePurpose = typeof EstatePurpose[keyof typeof EstatePurpose];


/**
 * 
 * @export
 * @interface Evaluation
 */
export interface Evaluation {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof Evaluation
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof Evaluation
     */
    'evaluatedBy'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'evaluatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'ltv'?: number;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'marketValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'mutuallyAgreedValuation'?: number;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'riskEstimatedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'rmValue'?: number;
}
/**
 * 
 * @export
 * @interface FileMetadata
 */
export interface FileMetadata {
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'contentType': string;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'createdAt': string;
    /**
     * 
     * @type {Actor}
     * @memberof FileMetadata
     */
    'createdBy'?: Actor;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'presignedUrl'?: string;
}
/**
 * 
 * @export
 * @interface FileRequest
 */
export interface FileRequest {
    /**
     * 
     * @type {Array<FileMetadata>}
     * @memberof FileRequest
     */
    'files': Array<FileMetadata>;
    /**
     * 
     * @type {number}
     * @memberof FileRequest
     */
    'requestId': number;
    /**
     * 
     * @type {FileRequestStatus}
     * @memberof FileRequest
     */
    'requestStatus': FileRequestStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FileRequestStatus = {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED'
} as const;

export type FileRequestStatus = typeof FileRequestStatus[keyof typeof FileRequestStatus];


/**
 * 
 * @export
 * @interface FromToPairBigDecimal
 */
export interface FromToPairBigDecimal {
    /**
     * 
     * @type {number}
     * @memberof FromToPairBigDecimal
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FromToPairBigDecimal
     */
    'to': number;
}
/**
 * 
 * @export
 * @interface FromToPairInteger
 */
export interface FromToPairInteger {
    /**
     * 
     * @type {number}
     * @memberof FromToPairInteger
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FromToPairInteger
     */
    'to': number;
}
/**
 * @type GetLoan200Response
 * @export
 */
export type GetLoan200Response = BnplLoan | InstallmentLoan | PayonLoan | PledgedLoan | TrustPledgedLoan;

/**
 * @type GetLoanApplication200Response
 * @export
 */
export type GetLoanApplication200Response = BnplLoanApplication | InstallmentLoanApplication | PayonLoanApplication | PledgedLoanApplication | TrustPledgedLoanApplication;

/**
 * @type GetProduct200Response
 * @export
 */
export type GetProduct200Response = BnplProduct | InstallmentProduct | PayonProduct | PledgedProduct | TrustProduct;

/**
 * @type GetSetting200Response
 * @export
 */
export type GetSetting200Response = AgeDto | BnplIncomeAmountRangeDto | BnplRiskCoefficientDto | CibDto | CollectionAssignmentDto | CreditScoringDto | CurrentLoansScoringSettingDto | EducationScoringSettingDto | LoanHistoryScoringSettingDto | MonthlyIncomeForDtiScoringSettingDto | OwnedCarEstateScoringSettingDto | OwnedRealEstateScoringSettingDto | RiskPriceDto | RiskyGroupDto | SalaryIncomeDto | SexAgeScoringSettingsDto | TrustDefaultProductDto | WorkingStabilityScoringSettingDto;

/**
 * @type GetTask200Response
 * @export
 */
export type GetTask200Response = N110Task | N111Task | N112Task | N113Task | N11Task | N12Task | N13Task | N14Task | N15Task | N16Task | N17Task | N18Task | N19Task | N21Task | N22Task | N23Task | N24Task | N41Task;

/**
 * 
 * @export
 * @interface GracePeriod
 */
export interface GracePeriod {
    /**
     * 
     * @type {number}
     * @memberof GracePeriod
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof GracePeriod
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof GracePeriod
     */
    'to': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HerderInformation = {
    HERD_OWNER: 'HERD_OWNER',
    HERDSMAN: 'HERDSMAN'
} as const;

export type HerderInformation = typeof HerderInformation[keyof typeof HerderInformation];


/**
 * 
 * @export
 * @interface HoursAndMinutes
 */
export interface HoursAndMinutes {
    /**
     * 
     * @type {number}
     * @memberof HoursAndMinutes
     */
    'hours': number;
    /**
     * 
     * @type {number}
     * @memberof HoursAndMinutes
     */
    'minutes': number;
}
/**
 * 
 * @export
 * @interface IdEntry
 */
export interface IdEntry {
    /**
     * 
     * @type {number}
     * @memberof IdEntry
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface IdLocalizationEntry
 */
export interface IdLocalizationEntry {
    /**
     * 
     * @type {number}
     * @memberof IdLocalizationEntry
     */
    'id': number;
    /**
     * 
     * @type {Localization}
     * @memberof IdLocalizationEntry
     */
    'l10n': Localization;
}
/**
 * 
 * @export
 * @interface IdNameEntry
 */
export interface IdNameEntry {
    /**
     * 
     * @type {number}
     * @memberof IdNameEntry
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IdNameEntry
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IncomeCheckStatus = {
    APPROVED: 'APPROVED',
    REJECT: 'REJECT',
    PENDING: 'PENDING',
    ERROR: 'ERROR',
    EXPIRED: 'EXPIRED'
} as const;

export type IncomeCheckStatus = typeof IncomeCheckStatus[keyof typeof IncomeCheckStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const IncomeSource = {
    DIVIDEND: 'DIVIDEND',
    INCOME_FROM_SALE_OF_ASSETS: 'INCOME_FROM_SALE_OF_ASSETS',
    INVESTMENT: 'INVESTMENT',
    OPERATING_INCOME: 'OPERATING_INCOME'
} as const;

export type IncomeSource = typeof IncomeSource[keyof typeof IncomeSource];


/**
 * 
 * @export
 * @enum {string}
 */

export const IncomeType = {
    SALARY: 'SALARY',
    SALARY_BUSINESS_LIVESTOCK_OTHER: 'SALARY_BUSINESS_LIVESTOCK_OTHER',
    BUSINESS_LIVESTOCK_OTHER: 'BUSINESS_LIVESTOCK_OTHER'
} as const;

export type IncomeType = typeof IncomeType[keyof typeof IncomeType];


/**
 * 
 * @export
 * @interface IndividualBriefDetailsView
 */
export interface IndividualBriefDetailsView {
    /**
     * 
     * @type {number}
     * @memberof IndividualBriefDetailsView
     */
    'commentRoomId': number;
    /**
     * 
     * @type {IdEntry}
     * @memberof IndividualBriefDetailsView
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {string}
     * @memberof IndividualBriefDetailsView
     */
    'firstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof IndividualBriefDetailsView
     */
    'id': number;
    /**
     * 
     * @type {FileMetadata}
     * @memberof IndividualBriefDetailsView
     */
    'idCardImage'?: FileMetadata;
    /**
     * 
     * @type {string}
     * @memberof IndividualBriefDetailsView
     */
    'lastName'?: string;
    /**
     * 
     * @type {CustomerVerificationStatus}
     * @memberof IndividualBriefDetailsView
     */
    'verificationStatus': CustomerVerificationStatus;
}


/**
 * 
 * @export
 * @interface IndividualBusinessIncome
 */
export interface IndividualBusinessIncome {
    /**
     * 
     * @type {string}
     * @memberof IndividualBusinessIncome
     */
    'bankAccount': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualBusinessIncome
     */
    'bankCode': string;
    /**
     * 
     * @type {IncomeCheckStatus}
     * @memberof IndividualBusinessIncome
     */
    'checkStatus': IncomeCheckStatus;
    /**
     * 
     * @type {number}
     * @memberof IndividualBusinessIncome
     */
    'continuousEmploymentInMonths': number;
    /**
     * 
     * @type {Currency}
     * @memberof IndividualBusinessIncome
     */
    'currency': Currency;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualBusinessIncome
     */
    'dropAvgLast2Months': boolean;
    /**
     * 
     * @type {number}
     * @memberof IndividualBusinessIncome
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualBusinessIncome
     */
    'incomeAmountEnough': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualBusinessIncome
     */
    'incomeAmountEnoughForLast2Months': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualBusinessIncome
     */
    'incomeAmountStable': boolean;
    /**
     * 
     * @type {number}
     * @memberof IndividualBusinessIncome
     */
    'monthlyIncome': number;
    /**
     * 
     * @type {number}
     * @memberof IndividualBusinessIncome
     */
    'workingMonthsForLast2Years': number;
}


/**
 * 
 * @export
 * @interface IndividualChangeRelationshipManager
 */
export interface IndividualChangeRelationshipManager extends IndividualPatch {
    /**
     * 
     * @type {number}
     * @memberof IndividualChangeRelationshipManager
     */
    'relationshipManagerId': number;
}
/**
 * 
 * @export
 * @interface IndividualCoBorrowerListEntry
 */
export interface IndividualCoBorrowerListEntry {
    /**
     * 
     * @type {number}
     * @memberof IndividualCoBorrowerListEntry
     */
    'coBorrowerId': number;
    /**
     * 
     * @type {number}
     * @memberof IndividualCoBorrowerListEntry
     */
    'dti'?: number;
    /**
     * 
     * @type {string}
     * @memberof IndividualCoBorrowerListEntry
     */
    'firstName': string;
    /**
     * 
     * @type {number}
     * @memberof IndividualCoBorrowerListEntry
     */
    'id': number;
    /**
     * 
     * @type {CustomerKycLevel}
     * @memberof IndividualCoBorrowerListEntry
     */
    'kycLevel': CustomerKycLevel;
    /**
     * 
     * @type {string}
     * @memberof IndividualCoBorrowerListEntry
     */
    'lastName': string;
    /**
     * 
     * @type {number}
     * @memberof IndividualCoBorrowerListEntry
     */
    'monthlyIncome'?: number;
    /**
     * 
     * @type {string}
     * @memberof IndividualCoBorrowerListEntry
     */
    'permanentAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualCoBorrowerListEntry
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualCoBorrowerListEntry
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualCoBorrowerListEntry
     */
    'relationshipType': string;
    /**
     * 
     * @type {CoBorrowerStatus}
     * @memberof IndividualCoBorrowerListEntry
     */
    'status': CoBorrowerStatus;
}


/**
 * 
 * @export
 * @interface IndividualContactDetailsUpdate
 */
export interface IndividualContactDetailsUpdate {
    /**
     * 
     * @type {Array<CustomerAddressUpdate>}
     * @memberof IndividualContactDetailsUpdate
     */
    'customerAddressRequests': Array<CustomerAddressUpdate>;
    /**
     * 
     * @type {Array<CustomerPhoneNumber>}
     * @memberof IndividualContactDetailsUpdate
     */
    'customerPhoneNumbers': Array<CustomerPhoneNumber>;
    /**
     * 
     * @type {string}
     * @memberof IndividualContactDetailsUpdate
     */
    'email': string;
    /**
     * 
     * @type {Array<SocialMediaLink>}
     * @memberof IndividualContactDetailsUpdate
     */
    'socialMediaLinks'?: Array<SocialMediaLink>;
}
/**
 * 
 * @export
 * @interface IndividualDetails
 */
export interface IndividualDetails {
    /**
     * 
     * @type {string}
     * @memberof IndividualDetails
     */
    'companyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof IndividualDetails
     */
    'creditScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof IndividualDetails
     */
    'dti'?: number;
    /**
     * 
     * @type {string}
     * @memberof IndividualDetails
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualDetails
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof IndividualDetails
     */
    'id': number;
    /**
     * 
     * @type {Array<CustomerIncomeType>}
     * @memberof IndividualDetails
     */
    'incomeTypes'?: Array<CustomerIncomeType>;
    /**
     * 
     * @type {string}
     * @memberof IndividualDetails
     */
    'lastName': string;
    /**
     * 
     * @type {number}
     * @memberof IndividualDetails
     */
    'monthlyIncome'?: number;
    /**
     * 
     * @type {number}
     * @memberof IndividualDetails
     */
    'numberOfFamilyMembers'?: number;
    /**
     * 
     * @type {string}
     * @memberof IndividualDetails
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualDetails
     */
    'position'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualDetails
     */
    'registrationNumber': string;
}
/**
 * 
 * @export
 * @interface IndividualIncomeState
 */
export interface IndividualIncomeState {
    /**
     * 
     * @type {number}
     * @memberof IndividualIncomeState
     */
    'monthlyIncome': number;
    /**
     * 
     * @type {IncomeCheckStatus}
     * @memberof IndividualIncomeState
     */
    'status': IncomeCheckStatus;
}


/**
 * 
 * @export
 * @interface IndividualIncomes
 */
export interface IndividualIncomes {
    /**
     * 
     * @type {IndividualIncomeState}
     * @memberof IndividualIncomes
     */
    'business'?: IndividualIncomeState;
    /**
     * 
     * @type {Array<DebtCollectionActionsInner>}
     * @memberof IndividualIncomes
     */
    'businessActions': Array<DebtCollectionActionsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualIncomes
     */
    'feeRequired': boolean;
    /**
     * 
     * @type {IndividualIncomeState}
     * @memberof IndividualIncomes
     */
    'livestock'?: IndividualIncomeState;
    /**
     * 
     * @type {Array<DebtCollectionActionsInner>}
     * @memberof IndividualIncomes
     */
    'livestockActions': Array<DebtCollectionActionsInner>;
    /**
     * 
     * @type {IndividualIncomeState}
     * @memberof IndividualIncomes
     */
    'salary'?: IndividualIncomeState;
    /**
     * 
     * @type {Array<DebtCollectionActionsInner>}
     * @memberof IndividualIncomes
     */
    'salaryActions': Array<DebtCollectionActionsInner>;
}
/**
 * 
 * @export
 * @interface IndividualInformationTabView
 */
export interface IndividualInformationTabView {
    /**
     * 
     * @type {string}
     * @memberof IndividualInformationTabView
     */
    'birthDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualInformationTabView
     */
    'canUploadMasterAgreement': boolean;
    /**
     * 
     * @type {Citizenship}
     * @memberof IndividualInformationTabView
     */
    'citizenship'?: Citizenship;
    /**
     * 
     * @type {string}
     * @memberof IndividualInformationTabView
     */
    'civilId'?: string;
    /**
     * 
     * @type {CustomerContactDetails}
     * @memberof IndividualInformationTabView
     */
    'customerContactDetails'?: CustomerContactDetails;
    /**
     * 
     * @type {CustomerJobInformationResponse}
     * @memberof IndividualInformationTabView
     */
    'customerJobInformationResponse'?: CustomerJobInformationResponse;
    /**
     * 
     * @type {Array<CustomerReference>}
     * @memberof IndividualInformationTabView
     */
    'customerReferences'?: Array<CustomerReference>;
    /**
     * 
     * @type {CustomerSegment}
     * @memberof IndividualInformationTabView
     */
    'customerSegment'?: CustomerSegment;
    /**
     * 
     * @type {string}
     * @memberof IndividualInformationTabView
     */
    'familyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualInformationTabView
     */
    'fullAddressId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualInformationTabView
     */
    'fullAddressPermanentResidence'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualInformationTabView
     */
    'fullAddressTemporaryResidence'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualInformationTabView
     */
    'fullWorkplaceAddress'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualInformationTabView
     */
    'hasMasterAgreement': boolean;
    /**
     * 
     * @type {number}
     * @memberof IndividualInformationTabView
     */
    'id': number;
    /**
     * 
     * @type {FileMetadata}
     * @memberof IndividualInformationTabView
     */
    'idCardImage'?: FileMetadata;
    /**
     * 
     * @type {string}
     * @memberof IndividualInformationTabView
     */
    'idDocumentRegistrationNumber'?: string;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof IndividualInformationTabView
     */
    'maritalStatus'?: MaritalStatus;
    /**
     * 
     * @type {number}
     * @memberof IndividualInformationTabView
     */
    'numberOfFamilyMembers'?: number;
    /**
     * 
     * @type {Array<CustomerPaymentAccount>}
     * @memberof IndividualInformationTabView
     */
    'paymentAccounts'?: Array<CustomerPaymentAccount>;
    /**
     * 
     * @type {RestrictionListType}
     * @memberof IndividualInformationTabView
     */
    'restrictionListType'?: RestrictionListType;
    /**
     * 
     * @type {FileMetadata}
     * @memberof IndividualInformationTabView
     */
    'selfieImage'?: FileMetadata;
    /**
     * 
     * @type {Sex}
     * @memberof IndividualInformationTabView
     */
    'sex'?: Sex;
    /**
     * 
     * @type {FileMetadata}
     * @memberof IndividualInformationTabView
     */
    'signatureImage'?: FileMetadata;
    /**
     * 
     * @type {string}
     * @memberof IndividualInformationTabView
     */
    'taxNumber'?: string;
}


/**
 * 
 * @export
 * @interface IndividualListEntry
 */
export interface IndividualListEntry {
    /**
     * 
     * @type {string}
     * @memberof IndividualListEntry
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualListEntry
     */
    'branchCode': string;
    /**
     * 
     * @type {Citizenship}
     * @memberof IndividualListEntry
     */
    'citizenship'?: Citizenship;
    /**
     * 
     * @type {string}
     * @memberof IndividualListEntry
     */
    'civilId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualListEntry
     */
    'customerName': string;
    /**
     * 
     * @type {number}
     * @memberof IndividualListEntry
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IndividualListEntry
     */
    'idDocumentRegistrationNumber': string;
    /**
     * 
     * @type {CustomerKycLevel}
     * @memberof IndividualListEntry
     */
    'kycLevel': CustomerKycLevel;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualListEntry
     */
    'pep'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IndividualListEntry
     */
    'phoneNumber': string;
    /**
     * 
     * @type {number}
     * @memberof IndividualListEntry
     */
    'relationshipManagerId': number;
    /**
     * 
     * @type {CustomerVerificationStatus}
     * @memberof IndividualListEntry
     */
    'verificationStatus': CustomerVerificationStatus;
}


/**
 * 
 * @export
 * @interface IndividualPatch
 */
export interface IndividualPatch {
    /**
     * 
     * @type {string}
     * @memberof IndividualPatch
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface IndividualPaymentAccountUpdate
 */
export interface IndividualPaymentAccountUpdate {
    /**
     * 
     * @type {Array<CustomerPaymentAccountUpdate>}
     * @memberof IndividualPaymentAccountUpdate
     */
    'paymentAccounts': Array<CustomerPaymentAccountUpdate>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Industry = {
    BUILDING_INFRASTRUCTURE: 'BUILDING_INFRASTRUCTURE',
    LIVESTOCK: 'LIVESTOCK',
    FARMING: 'FARMING',
    MINING: 'MINING',
    FACTORY: 'FACTORY',
    TRADE: 'TRADE',
    TRANSPORTATION_WAREHOUSE: 'TRANSPORTATION_WAREHOUSE',
    HOTEL_RESTAURANT: 'HOTEL_RESTAURANT',
    INFORMATION_TELECOMMUNICATION: 'INFORMATION_TELECOMMUNICATION',
    FINANCE_INSURANCE: 'FINANCE_INSURANCE',
    REAL_ESTATE: 'REAL_ESTATE',
    SCIENCE_TECHNOLOGY: 'SCIENCE_TECHNOLOGY',
    GOVERMENT_ORGANIZATION: 'GOVERMENT_ORGANIZATION',
    GOVERNMENT_ORGANIZATION: 'GOVERNMENT_ORGANIZATION',
    EDUCATION_CONSULTING: 'EDUCATION_CONSULTING',
    HEALTH_SOCIETY: 'HEALTH_SOCIETY',
    TOURISM_CULTURE: 'TOURISM_CULTURE',
    INTERNATIONAL_ORGANIZATION: 'INTERNATIONAL_ORGANIZATION',
    OTHER: 'OTHER'
} as const;

export type Industry = typeof Industry[keyof typeof Industry];


/**
 * 
 * @export
 * @interface InstallmentLoan
 */
export interface InstallmentLoan extends Loan {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof InstallmentLoan
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'applicationId': number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoan
     */
    'branchCode': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof InstallmentLoan
     */
    'branchManager'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoan
     */
    'branchName': string;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoan
     */
    'cibSentDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstallmentLoan
     */
    'cibSentStatus': boolean;
    /**
     * 
     * @type {Array<LoanCollateralListEntry>}
     * @memberof InstallmentLoan
     */
    'collaterals': Array<LoanCollateralListEntry>;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'commentRoomId': number;
    /**
     * 
     * @type {CreditBureauStatus}
     * @memberof InstallmentLoan
     */
    'creditBureauStatus': CreditBureauStatus;
    /**
     * 
     * @type {Customer}
     * @memberof InstallmentLoan
     */
    'customer': Customer;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'daysOverdue': number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoan
     */
    'disbursementDate': string;
    /**
     * 
     * @type {IdEntry}
     * @memberof InstallmentLoan
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'downPayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'exceedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoan
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoan
     */
    'firstRepaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'incomeAmount'?: number;
    /**
     * 
     * @type {Array<IndividualCoBorrowerListEntry>}
     * @memberof InstallmentLoan
     */
    'individualCoBorrowers': Array<IndividualCoBorrowerListEntry>;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'interestRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'lastPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoan
     */
    'lastPaymentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoan
     */
    'loanPurpose'?: string;
    /**
     * 
     * @type {LoanStatus}
     * @memberof InstallmentLoan
     */
    'loanStatus': LoanStatus;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoan
     */
    'maturityDate': string;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'nextPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoan
     */
    'nextPaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'overdueAdvancePayment': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'principalBalance': number;
    /**
     * 
     * @type {ProductLoanInfo}
     * @memberof InstallmentLoan
     */
    'product': ProductLoanInfo;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof InstallmentLoan
     */
    'relationshipManager': IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'repaymentAmount': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof InstallmentLoan
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoan
     */
    'secondRepaymentDate'?: string;
    /**
     * 
     * @type {ApplicationSource}
     * @memberof InstallmentLoan
     */
    'source': ApplicationSource;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoan
     */
    'term': number;
}


/**
 * 
 * @export
 * @interface InstallmentLoanApplication
 */
export interface InstallmentLoanApplication extends LoanApplication {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof InstallmentLoanApplication
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'averageIncome': number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoanApplication
     */
    'bankAccountNumber': string;
    /**
     * 
     * @type {BankInformationDto}
     * @memberof InstallmentLoanApplication
     */
    'bankInformation': BankInformationDto;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'commentRoomId': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'creditScore': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoanApplication
     */
    'disbursementDate'?: string;
    /**
     * 
     * @type {IdEntry}
     * @memberof InstallmentLoanApplication
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoanApplication
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'firstPaymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoanApplication
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'id': number;
    /**
     * 
     * @type {Array<CustomerIncomeType>}
     * @memberof InstallmentLoanApplication
     */
    'incomeTypes': Array<CustomerIncomeType>;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'limitOffer': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'loanId'?: number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoanApplication
     */
    'loanPurpose': string;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoanApplication
     */
    'maturityDate': string;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoanApplication
     */
    'merchantName'?: string;
    /**
     * 
     * @type {ProductBrief}
     * @memberof InstallmentLoanApplication
     */
    'product': ProductBrief;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof InstallmentLoanApplication
     */
    'relationshipManager': IdNameEntry;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof InstallmentLoanApplication
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'requestedAmount': number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentLoanApplication
     */
    'requestedAt': string;
    /**
     * 
     * @type {number}
     * @memberof InstallmentLoanApplication
     */
    'requestedTerm': number;
    /**
     * 
     * @type {ApplicationSource}
     * @memberof InstallmentLoanApplication
     */
    'source': ApplicationSource;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof InstallmentLoanApplication
     */
    'status': LoanApplicationStatus;
}


/**
 * 
 * @export
 * @interface InstallmentProduct
 */
export interface InstallmentProduct extends Product {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof InstallmentProduct
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProduct
     */
    'dti': number;
    /**
     * 
     * @type {boolean}
     * @memberof InstallmentProduct
     */
    'excludedFromOffer': boolean;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProduct
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProduct
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProduct
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProduct
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentProduct
     */
    'name': string;
    /**
     * 
     * @type {PenaltyInterestAccrualMethod}
     * @memberof InstallmentProduct
     */
    'penaltyInterestAccrualMethod': PenaltyInterestAccrualMethod;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProduct
     */
    'penaltyInterestAccrualStartDay': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProduct
     */
    'penaltyInterestMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProduct
     */
    'productLineId': number;
    /**
     * 
     * @type {RepaymentAllocationRule}
     * @memberof InstallmentProduct
     */
    'repaymentAllocationRule': RepaymentAllocationRule;
    /**
     * 
     * @type {RepaymentCalculationMethod}
     * @memberof InstallmentProduct
     */
    'repaymentCalculationMethod': RepaymentCalculationMethod;
    /**
     * 
     * @type {ProductStatus}
     * @memberof InstallmentProduct
     */
    'status': ProductStatus;
}


/**
 * 
 * @export
 * @interface InstallmentProductUpdate
 */
export interface InstallmentProductUpdate extends ProductUpdate {
    /**
     * 
     * @type {number}
     * @memberof InstallmentProductUpdate
     */
    'dti': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProductUpdate
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProductUpdate
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProductUpdate
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof InstallmentProductUpdate
     */
    'name': string;
    /**
     * 
     * @type {PenaltyInterestAccrualMethod}
     * @memberof InstallmentProductUpdate
     */
    'penaltyInterestAccrualMethod': PenaltyInterestAccrualMethod;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProductUpdate
     */
    'penaltyInterestAccrualStartDay': number;
    /**
     * 
     * @type {number}
     * @memberof InstallmentProductUpdate
     */
    'penaltyInterestMultiplier': number;
    /**
     * 
     * @type {RepaymentAllocationRule}
     * @memberof InstallmentProductUpdate
     */
    'repaymentAllocationRule': RepaymentAllocationRule;
    /**
     * 
     * @type {RepaymentCalculationMethod}
     * @memberof InstallmentProductUpdate
     */
    'repaymentCalculationMethod': RepaymentCalculationMethod;
}


/**
 * 
 * @export
 * @interface Insurance
 */
export interface Insurance {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof Insurance
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof Insurance
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof Insurance
     */
    'brokerage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Insurance
     */
    'expireDate': string;
    /**
     * 
     * @type {number}
     * @memberof Insurance
     */
    'id': number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof Insurance
     */
    'insuranceCompany': IdNameEntry;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof Insurance
     */
    'insuranceProduct'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof Insurance
     */
    'registeredDate': string;
    /**
     * 
     * @type {InsuranceSource}
     * @memberof Insurance
     */
    'source': InsuranceSource;
    /**
     * 
     * @type {InsuranceStatus}
     * @memberof Insurance
     */
    'status': InsuranceStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const InsuranceSource = {
    MOBILE_APP: 'MOBILE_APP',
    BACK_OFFICE: 'BACK_OFFICE'
} as const;

export type InsuranceSource = typeof InsuranceSource[keyof typeof InsuranceSource];


/**
 * 
 * @export
 * @enum {string}
 */

export const InsuranceStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;

export type InsuranceStatus = typeof InsuranceStatus[keyof typeof InsuranceStatus];


/**
 * 
 * @export
 * @interface InsuranceUpdate
 */
export interface InsuranceUpdate {
    /**
     * 
     * @type {number}
     * @memberof InsuranceUpdate
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceUpdate
     */
    'brokerage'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceUpdate
     */
    'expireDate': string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceUpdate
     */
    'insuranceCompanyId': number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceUpdate
     */
    'insuranceProductId'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceUpdate
     */
    'registeredDate': string;
}
/**
 * 
 * @export
 * @interface IntangibleAssetCollateral
 */
export interface IntangibleAssetCollateral extends Collateral {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof IntangibleAssetCollateral
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {string}
     * @memberof IntangibleAssetCollateral
     */
    'additionalField1': string;
    /**
     * 
     * @type {string}
     * @memberof IntangibleAssetCollateral
     */
    'additionalField2'?: string;
    /**
     * 
     * @type {number}
     * @memberof IntangibleAssetCollateral
     */
    'availableAmount'?: number;
    /**
     * 
     * @type {Array<CollateralCoOwner>}
     * @memberof IntangibleAssetCollateral
     */
    'coOwners': Array<CollateralCoOwner>;
    /**
     * 
     * @type {number}
     * @memberof IntangibleAssetCollateral
     */
    'commentRoomId': number;
    /**
     * 
     * @type {IdEntry}
     * @memberof IntangibleAssetCollateral
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {Evaluation}
     * @memberof IntangibleAssetCollateral
     */
    'evaluation'?: Evaluation;
    /**
     * 
     * @type {string}
     * @memberof IntangibleAssetCollateral
     */
    'expiresAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof IntangibleAssetCollateral
     */
    'futureIncomeAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntangibleAssetCollateral
     */
    'id': number;
    /**
     * 
     * @type {Array<Insurance>}
     * @memberof IntangibleAssetCollateral
     */
    'insurances': Array<Insurance>;
    /**
     * 
     * @type {string}
     * @memberof IntangibleAssetCollateral
     */
    'issuedOrganization'?: string;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof IntangibleAssetCollateral
     */
    'loans': Array<IdNameEntry>;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof IntangibleAssetCollateral
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof IntangibleAssetCollateral
     */
    'startsAt'?: string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof IntangibleAssetCollateral
     */
    'subType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof IntangibleAssetCollateral
     */
    'type': CollateralType;
}


/**
 * 
 * @export
 * @interface IntangibleAssetCollateralPatch
 */
export interface IntangibleAssetCollateralPatch extends CollateralPatch {
    /**
     * 
     * @type {string}
     * @memberof IntangibleAssetCollateralPatch
     */
    'additionalField1': string;
    /**
     * 
     * @type {string}
     * @memberof IntangibleAssetCollateralPatch
     */
    'additionalField2'?: string;
    /**
     * 
     * @type {number}
     * @memberof IntangibleAssetCollateralPatch
     */
    'availableAmount': number;
    /**
     * 
     * @type {string}
     * @memberof IntangibleAssetCollateralPatch
     */
    'expiresAt': string;
    /**
     * 
     * @type {number}
     * @memberof IntangibleAssetCollateralPatch
     */
    'futureIncomeAmount': number;
    /**
     * 
     * @type {string}
     * @memberof IntangibleAssetCollateralPatch
     */
    'issuedOrganization': string;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof IntangibleAssetCollateralPatch
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof IntangibleAssetCollateralPatch
     */
    'startsAt': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const InvestmentType = {
    COMBINED: 'COMBINED',
    DOMESTIC: 'DOMESTIC',
    FOREIGN: 'FOREIGN',
    PUBLIC_PRIVATE_PARTNERSHIP: 'PUBLIC_PRIVATE_PARTNERSHIP',
    STATE: 'STATE'
} as const;

export type InvestmentType = typeof InvestmentType[keyof typeof InvestmentType];


/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'editedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'editedBy': string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'expiresAt': string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'goods': string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'id': number;
    /**
     * 
     * @type {InvoiceMerchant}
     * @memberof Invoice
     */
    'merchant': InvoiceMerchant;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'seriesCode': string;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof Invoice
     */
    'status': InvoiceStatus;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'totalAmount': number;
}


/**
 * 
 * @export
 * @interface InvoiceMerchant
 */
export interface InvoiceMerchant {
    /**
     * 
     * @type {string}
     * @memberof InvoiceMerchant
     */
    'branchName': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceMerchant
     */
    'commission': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceMerchant
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceStatus = {
    APPROVED: 'APPROVED',
    CHANGED_INVOICE: 'CHANGED_INVOICE',
    CUSTOMER_CANCELED: 'CUSTOMER_CANCELED',
    EXPIRED: 'EXPIRED',
    INITIAL: 'INITIAL',
    MERCHANT_CANCELED: 'MERCHANT_CANCELED',
    REQUEST_TO_RETURN: 'REQUEST_TO_RETURN',
    RETURNED: 'RETURNED',
    WAITING_FOR_LOAN_APPLICATION: 'WAITING_FOR_LOAN_APPLICATION'
} as const;

export type InvoiceStatus = typeof InvoiceStatus[keyof typeof InvoiceStatus];


/**
 * 
 * @export
 * @interface KhorooDto
 */
export interface KhorooDto {
    /**
     * 
     * @type {string}
     * @memberof KhorooDto
     */
    'khorooCode': string;
    /**
     * 
     * @type {Localization}
     * @memberof KhorooDto
     */
    'l10n': Localization;
}
/**
 * 
 * @export
 * @interface LandInformation
 */
export interface LandInformation {
    /**
     * 
     * @type {string}
     * @memberof LandInformation
     */
    'cadastralNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LandInformation
     */
    'certificateEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LandInformation
     */
    'certificateNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LandInformation
     */
    'certificateStartDate'?: string;
    /**
     * 
     * @type {CoOwnerCount}
     * @memberof LandInformation
     */
    'coOwnerCount'?: CoOwnerCount;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof LandInformation
     */
    'collateralOwnershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {number}
     * @memberof LandInformation
     */
    'landSquare'?: number;
    /**
     * 
     * @type {LandOwnershipType}
     * @memberof LandInformation
     */
    'ownershipType'?: LandOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof LandInformation
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {UseOfLand}
     * @memberof LandInformation
     */
    'useOfLand'?: UseOfLand;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LandOwnershipType = {
    POSSESSION: 'POSSESSION',
    OWNERSHIP: 'OWNERSHIP'
} as const;

export type LandOwnershipType = typeof LandOwnershipType[keyof typeof LandOwnershipType];


/**
 * 
 * @export
 * @interface LeasingCollateral
 */
export interface LeasingCollateral extends Collateral {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof LeasingCollateral
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {string}
     * @memberof LeasingCollateral
     */
    'assetNumber'?: string;
    /**
     * 
     * @type {Array<CollateralCoOwner>}
     * @memberof LeasingCollateral
     */
    'coOwners': Array<CollateralCoOwner>;
    /**
     * 
     * @type {number}
     * @memberof LeasingCollateral
     */
    'commentRoomId': number;
    /**
     * 
     * @type {IdEntry}
     * @memberof LeasingCollateral
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {Evaluation}
     * @memberof LeasingCollateral
     */
    'evaluation'?: Evaluation;
    /**
     * 
     * @type {boolean}
     * @memberof LeasingCollateral
     */
    'hasEcoStatus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LeasingCollateral
     */
    'id': number;
    /**
     * 
     * @type {Array<Insurance>}
     * @memberof LeasingCollateral
     */
    'insurances': Array<Insurance>;
    /**
     * 
     * @type {string}
     * @memberof LeasingCollateral
     */
    'itemName'?: string;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof LeasingCollateral
     */
    'loans': Array<IdNameEntry>;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof LeasingCollateral
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {number}
     * @memberof LeasingCollateral
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeasingCollateral
     */
    'specifications'?: string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof LeasingCollateral
     */
    'subType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof LeasingCollateral
     */
    'type': CollateralType;
    /**
     * 
     * @type {number}
     * @memberof LeasingCollateral
     */
    'unitPrice'?: number;
}


/**
 * 
 * @export
 * @interface LeasingCollateralPatch
 */
export interface LeasingCollateralPatch extends CollateralPatch {
    /**
     * 
     * @type {string}
     * @memberof LeasingCollateralPatch
     */
    'assetNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof LeasingCollateralPatch
     */
    'hasEcoStatus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeasingCollateralPatch
     */
    'itemName'?: string;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof LeasingCollateralPatch
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {number}
     * @memberof LeasingCollateralPatch
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeasingCollateralPatch
     */
    'specifications'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeasingCollateralPatch
     */
    'unitPrice'?: number;
}


/**
 * 
 * @export
 * @interface Legal
 */
export interface Legal {
    /**
     * 
     * @type {Array<DebtCollectionActionsInner>}
     * @memberof Legal
     */
    'actions'?: Array<DebtCollectionActionsInner>;
    /**
     * 
     * @type {IdLocalizationEntry}
     * @memberof Legal
     */
    'activity': IdLocalizationEntry;
    /**
     * 
     * @type {CeoDetails}
     * @memberof Legal
     */
    'ceo'?: CeoDetails;
    /**
     * 
     * @type {IdEntry}
     * @memberof Legal
     */
    'commentRoom': IdEntry;
    /**
     * 
     * @type {CustomerContactDetails}
     * @memberof Legal
     */
    'contactDetails'?: CustomerContactDetails;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    'createdAt': string;
    /**
     * 
     * @type {IdEntry}
     * @memberof Legal
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {EnterpriseType}
     * @memberof Legal
     */
    'enterpriseType': EnterpriseType;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    'establishedDate': string;
    /**
     * 
     * @type {number}
     * @memberof Legal
     */
    'id': number;
    /**
     * 
     * @type {IncomeSource}
     * @memberof Legal
     */
    'incomeSource': IncomeSource;
    /**
     * 
     * @type {IdLocalizationEntry}
     * @memberof Legal
     */
    'industry': IdLocalizationEntry;
    /**
     * 
     * @type {InvestmentType}
     * @memberof Legal
     */
    'investmentType'?: InvestmentType;
    /**
     * 
     * @type {FileMetadata}
     * @memberof Legal
     */
    'logo'?: FileMetadata;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Legal
     */
    'numberOfBranches'?: number;
    /**
     * 
     * @type {number}
     * @memberof Legal
     */
    'numberOfEmployees'?: number;
    /**
     * 
     * @type {Array<CustomerPaymentAccount>}
     * @memberof Legal
     */
    'paymentAccounts'?: Array<CustomerPaymentAccount>;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    'rating'?: string;
    /**
     * 
     * @type {RatingOrganisation}
     * @memberof Legal
     */
    'ratingOrganisation'?: RatingOrganisation;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    'registrationNumber': string;
    /**
     * 
     * @type {Array<LegalRepresentativeListEntry>}
     * @memberof Legal
     */
    'representatives'?: Array<LegalRepresentativeListEntry>;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    'stateRegistrationNumber': string;
    /**
     * 
     * @type {CustomerStatus}
     * @memberof Legal
     */
    'status': CustomerStatus;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    'taxNumber': string;
}


/**
 * 
 * @export
 * @interface LegalActivityItem
 */
export interface LegalActivityItem {
    /**
     * 
     * @type {string}
     * @memberof LegalActivityItem
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof LegalActivityItem
     */
    'id': number;
    /**
     * 
     * @type {Localization}
     * @memberof LegalActivityItem
     */
    'l10n': Localization;
}
/**
 * 
 * @export
 * @interface LegalCeoUpdate
 */
export interface LegalCeoUpdate extends LegalPatch {
    /**
     * 
     * @type {number}
     * @memberof LegalCeoUpdate
     */
    'ceoId': number;
}
/**
 * 
 * @export
 * @interface LegalCoBorrowerListEntry
 */
export interface LegalCoBorrowerListEntry {
    /**
     * 
     * @type {IdNameEntry}
     * @memberof LegalCoBorrowerListEntry
     */
    'ceo'?: IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof LegalCoBorrowerListEntry
     */
    'coBorrowerId': number;
    /**
     * 
     * @type {number}
     * @memberof LegalCoBorrowerListEntry
     */
    'id': number;
    /**
     * 
     * @type {IdLocalizationEntry}
     * @memberof LegalCoBorrowerListEntry
     */
    'industry': IdLocalizationEntry;
    /**
     * 
     * @type {string}
     * @memberof LegalCoBorrowerListEntry
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LegalCoBorrowerListEntry
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalCoBorrowerListEntry
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof LegalCoBorrowerListEntry
     */
    'relationshipType'?: string;
    /**
     * 
     * @type {CoBorrowerStatus}
     * @memberof LegalCoBorrowerListEntry
     */
    'status': CoBorrowerStatus;
}


/**
 * 
 * @export
 * @interface LegalContactDetailsUpdate
 */
export interface LegalContactDetailsUpdate extends LegalPatch {
    /**
     * 
     * @type {Array<CustomerAddressUpdate>}
     * @memberof LegalContactDetailsUpdate
     */
    'customerAddressRequests': Array<CustomerAddressUpdate>;
    /**
     * 
     * @type {Array<CustomerPhoneNumber>}
     * @memberof LegalContactDetailsUpdate
     */
    'customerPhoneNumbers': Array<CustomerPhoneNumber>;
    /**
     * 
     * @type {string}
     * @memberof LegalContactDetailsUpdate
     */
    'email': string;
    /**
     * 
     * @type {Array<SocialMediaLink>}
     * @memberof LegalContactDetailsUpdate
     */
    'socialMediaLinks'?: Array<SocialMediaLink>;
}
/**
 * 
 * @export
 * @interface LegalGeneralInformationUpdate
 */
export interface LegalGeneralInformationUpdate extends LegalPatch {
    /**
     * 
     * @type {number}
     * @memberof LegalGeneralInformationUpdate
     */
    'activityId': number;
    /**
     * 
     * @type {EnterpriseType}
     * @memberof LegalGeneralInformationUpdate
     */
    'enterpriseType': EnterpriseType;
    /**
     * 
     * @type {string}
     * @memberof LegalGeneralInformationUpdate
     */
    'establishedDate': string;
    /**
     * 
     * @type {IncomeSource}
     * @memberof LegalGeneralInformationUpdate
     */
    'incomeSource': IncomeSource;
    /**
     * 
     * @type {InvestmentType}
     * @memberof LegalGeneralInformationUpdate
     */
    'investmentType'?: InvestmentType;
    /**
     * 
     * @type {string}
     * @memberof LegalGeneralInformationUpdate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof LegalGeneralInformationUpdate
     */
    'numberOfBranches'?: number;
    /**
     * 
     * @type {number}
     * @memberof LegalGeneralInformationUpdate
     */
    'numberOfEmployees'?: number;
    /**
     * 
     * @type {string}
     * @memberof LegalGeneralInformationUpdate
     */
    'rating'?: string;
    /**
     * 
     * @type {RatingOrganisation}
     * @memberof LegalGeneralInformationUpdate
     */
    'ratingOrganisation'?: RatingOrganisation;
    /**
     * 
     * @type {string}
     * @memberof LegalGeneralInformationUpdate
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof LegalGeneralInformationUpdate
     */
    'stateRegistrationNumber': string;
    /**
     * 
     * @type {CustomerStatus}
     * @memberof LegalGeneralInformationUpdate
     */
    'status': CustomerStatus;
    /**
     * 
     * @type {string}
     * @memberof LegalGeneralInformationUpdate
     */
    'taxNumber': string;
}


/**
 * 
 * @export
 * @interface LegalIndustryItem
 */
export interface LegalIndustryItem {
    /**
     * 
     * @type {string}
     * @memberof LegalIndustryItem
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof LegalIndustryItem
     */
    'id': number;
    /**
     * 
     * @type {Localization}
     * @memberof LegalIndustryItem
     */
    'l10n': Localization;
    /**
     * 
     * @type {Array<LegalActivityItem>}
     * @memberof LegalIndustryItem
     */
    'legalActivities': Array<LegalActivityItem>;
}
/**
 * 
 * @export
 * @interface LegalListEntry
 */
export interface LegalListEntry {
    /**
     * 
     * @type {IdNameEntry}
     * @memberof LegalListEntry
     */
    'ceo'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof LegalListEntry
     */
    'companyName': string;
    /**
     * 
     * @type {number}
     * @memberof LegalListEntry
     */
    'id': number;
    /**
     * 
     * @type {IdLocalizationEntry}
     * @memberof LegalListEntry
     */
    'industry'?: IdLocalizationEntry;
    /**
     * 
     * @type {string}
     * @memberof LegalListEntry
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalListEntry
     */
    'registrationNumber': string;
}
/**
 * 
 * @export
 * @interface LegalLoanListEntry
 */
export interface LegalLoanListEntry {
    /**
     * 
     * @type {number}
     * @memberof LegalLoanListEntry
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof LegalLoanListEntry
     */
    'daysOverdue': number;
    /**
     * 
     * @type {string}
     * @memberof LegalLoanListEntry
     */
    'disbursementDate': string;
    /**
     * 
     * @type {number}
     * @memberof LegalLoanListEntry
     */
    'id': number;
    /**
     * 
     * @type {LoanStatus}
     * @memberof LegalLoanListEntry
     */
    'loanStatus': LoanStatus;
    /**
     * 
     * @type {number}
     * @memberof LegalLoanListEntry
     */
    'maxDaysOverdue': number;
    /**
     * 
     * @type {ProductType}
     * @memberof LegalLoanListEntry
     */
    'productType': ProductType;
}


/**
 * 
 * @export
 * @interface LegalOtherInformation
 */
export interface LegalOtherInformation {
    /**
     * 
     * @type {string}
     * @memberof LegalOtherInformation
     */
    'createdAt': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof LegalOtherInformation
     */
    'createdBy': IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof LegalOtherInformation
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof LegalOtherInformation
     */
    'updatedBy'?: IdNameEntry;
}
/**
 * 
 * @export
 * @interface LegalPatch
 */
export interface LegalPatch {
    /**
     * 
     * @type {string}
     * @memberof LegalPatch
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface LegalPaymentAccountUpdate
 */
export interface LegalPaymentAccountUpdate extends LegalPatch {
    /**
     * 
     * @type {Array<CustomerPaymentAccountUpdate>}
     * @memberof LegalPaymentAccountUpdate
     */
    'paymentAccounts': Array<CustomerPaymentAccountUpdate>;
}
/**
 * 
 * @export
 * @interface LegalRepresentativeListEntry
 */
export interface LegalRepresentativeListEntry {
    /**
     * 
     * @type {Citizenship}
     * @memberof LegalRepresentativeListEntry
     */
    'citizenship': Citizenship;
    /**
     * 
     * @type {string}
     * @memberof LegalRepresentativeListEntry
     */
    'civilId': string;
    /**
     * 
     * @type {string}
     * @memberof LegalRepresentativeListEntry
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof LegalRepresentativeListEntry
     */
    'firstName': string;
    /**
     * 
     * @type {number}
     * @memberof LegalRepresentativeListEntry
     */
    'individualId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LegalRepresentativeListEntry
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof LegalRepresentativeListEntry
     */
    'pep': boolean;
    /**
     * 
     * @type {number}
     * @memberof LegalRepresentativeListEntry
     */
    'percentageOfShare'?: number;
    /**
     * 
     * @type {string}
     * @memberof LegalRepresentativeListEntry
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof LegalRepresentativeListEntry
     */
    'registrationNumber': string;
    /**
     * 
     * @type {ReferenceRelationship}
     * @memberof LegalRepresentativeListEntry
     */
    'relationship'?: ReferenceRelationship;
    /**
     * 
     * @type {number}
     * @memberof LegalRepresentativeListEntry
     */
    'representativeId': number;
}


/**
 * 
 * @export
 * @interface LivestockCollateral
 */
export interface LivestockCollateral extends Collateral {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof LivestockCollateral
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {string}
     * @memberof LivestockCollateral
     */
    'additionalField': string;
    /**
     * 
     * @type {Array<CollateralCoOwner>}
     * @memberof LivestockCollateral
     */
    'coOwners': Array<CollateralCoOwner>;
    /**
     * 
     * @type {number}
     * @memberof LivestockCollateral
     */
    'commentRoomId': number;
    /**
     * 
     * @type {IdEntry}
     * @memberof LivestockCollateral
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {Evaluation}
     * @memberof LivestockCollateral
     */
    'evaluation'?: Evaluation;
    /**
     * 
     * @type {HerderInformation}
     * @memberof LivestockCollateral
     */
    'herderInformation': HerderInformation;
    /**
     * 
     * @type {number}
     * @memberof LivestockCollateral
     */
    'herdingNumber': number;
    /**
     * 
     * @type {number}
     * @memberof LivestockCollateral
     */
    'id': number;
    /**
     * 
     * @type {Array<Insurance>}
     * @memberof LivestockCollateral
     */
    'insurances': Array<Insurance>;
    /**
     * 
     * @type {number}
     * @memberof LivestockCollateral
     */
    'livestockTotalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LivestockCollateral
     */
    'livestockTotalCount': number;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof LivestockCollateral
     */
    'loans': Array<IdNameEntry>;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof LivestockCollateral
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof LivestockCollateral
     */
    'subType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof LivestockCollateral
     */
    'type': CollateralType;
    /**
     * 
     * @type {number}
     * @memberof LivestockCollateral
     */
    'weightedNumberIntoSheep': number;
}


/**
 * 
 * @export
 * @interface LivestockCollateralPatch
 */
export interface LivestockCollateralPatch extends CollateralPatch {
    /**
     * 
     * @type {string}
     * @memberof LivestockCollateralPatch
     */
    'additionalField': string;
    /**
     * 
     * @type {HerderInformation}
     * @memberof LivestockCollateralPatch
     */
    'herderInformation': HerderInformation;
    /**
     * 
     * @type {number}
     * @memberof LivestockCollateralPatch
     */
    'herdingNumber': number;
    /**
     * 
     * @type {number}
     * @memberof LivestockCollateralPatch
     */
    'livestockTotalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LivestockCollateralPatch
     */
    'livestockTotalCount': number;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof LivestockCollateralPatch
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {number}
     * @memberof LivestockCollateralPatch
     */
    'weightedNumberIntoSheep': number;
}


/**
 * 
 * @export
 * @interface LivestockIncomeDetailsResponse
 */
export interface LivestockIncomeDetailsResponse {
    /**
     * 
     * @type {Array<CustomerDecision>}
     * @memberof LivestockIncomeDetailsResponse
     */
    'decisions': Array<CustomerDecision>;
    /**
     * 
     * @type {Array<CustomerLivestockIncomeData>}
     * @memberof LivestockIncomeDetailsResponse
     */
    'incomeData'?: Array<CustomerLivestockIncomeData>;
    /**
     * 
     * @type {number}
     * @memberof LivestockIncomeDetailsResponse
     */
    'monthlyIncome'?: number;
    /**
     * 
     * @type {IncomeCheckStatus}
     * @memberof LivestockIncomeDetailsResponse
     */
    'status'?: IncomeCheckStatus;
}


/**
 * 
 * @export
 * @interface Loan
 */
export interface Loan {
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface LoanApplication
 */
export interface LoanApplication {
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface LoanApplicationChangeRelationshipManager
 */
export interface LoanApplicationChangeRelationshipManager extends LoanApplicationPatch {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationChangeRelationshipManager
     */
    'relationshipManagerId': number;
}
/**
 * 
 * @export
 * @interface LoanApplicationListEntry
 */
export interface LoanApplicationListEntry {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationListEntry
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationListEntry
     */
    'branchCode': string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationListEntry
     */
    'civilId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationListEntry
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationListEntry
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationListEntry
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationListEntry
     */
    'id': number;
    /**
     * 
     * @type {ProductType}
     * @memberof LoanApplicationListEntry
     */
    'productType': ProductType;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationListEntry
     */
    'registrationNumber': string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationListEntry
     */
    'relationshipManagerId': number;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof LoanApplicationListEntry
     */
    'status': LoanApplicationStatus;
}


/**
 * 
 * @export
 * @interface LoanApplicationPatch
 */
export interface LoanApplicationPatch {
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationPatch
     */
    'type': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LoanApplicationStatus = {
    INITIAL: 'INITIAL',
    WAITING_FOR_SIGN_AGREEMENT: 'WAITING_FOR_SIGN_AGREEMENT',
    AGREEMENT_SIGNED: 'AGREEMENT_SIGNED',
    WAITING_FOR_DISBURSEMENT: 'WAITING_FOR_DISBURSEMENT',
    PENDING: 'PENDING',
    SUCCESSFUL_DISBURSEMENT: 'SUCCESSFUL_DISBURSEMENT',
    ERROR_DISBURSEMENT: 'ERROR_DISBURSEMENT',
    EXPIRED: 'EXPIRED',
    REJECTED: 'REJECTED',
    REJECTED_BY_MERCHANT_PORTAL: 'REJECTED_BY_MERCHANT_PORTAL',
    CUSTOMER_CANCELED: 'CUSTOMER_CANCELED',
    WAITING_FOR_SIGN_AGREEMENT_BY_CO_BORROWERS: 'WAITING_FOR_SIGN_AGREEMENT_BY_CO_BORROWERS',
    AGREEMENT_SIGNED_BY_CO_BORROWERS: 'AGREEMENT_SIGNED_BY_CO_BORROWERS',
    CO_BORROWER_CANCELED: 'CO_BORROWER_CANCELED',
    CIAT_REVIEW: 'CIAT_REVIEW',
    REJECTED_BY_CIAT: 'REJECTED_BY_CIAT',
    LAAT_REVIEW: 'LAAT_REVIEW',
    REJECTED_BY_LAAT: 'REJECTED_BY_LAAT',
    CC_REVIEW: 'CC_REVIEW',
    REJECTED_BY_CC: 'REJECTED_BY_CC',
    RM_REVIEW: 'RM_REVIEW',
    REJECTED_BY_RM: 'REJECTED_BY_RM',
    WAITING_FOR_INVOICE_AMOUNT_CHANGING: 'WAITING_FOR_INVOICE_AMOUNT_CHANGING',
    ACCOUNTANT_REVIEW: 'ACCOUNTANT_REVIEW'
} as const;

export type LoanApplicationStatus = typeof LoanApplicationStatus[keyof typeof LoanApplicationStatus];


/**
 * 
 * @export
 * @interface LoanChangeRelationshipManager
 */
export interface LoanChangeRelationshipManager extends LoanPatch {
    /**
     * 
     * @type {number}
     * @memberof LoanChangeRelationshipManager
     */
    'relationshipManagerId': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LoanClassification = {
    PERFORMING: 'PERFORMING',
    SPECIAL_MENTION: 'SPECIAL_MENTION',
    SUBSTANDARD: 'SUBSTANDARD',
    DOUBTFUL: 'DOUBTFUL',
    BAD: 'BAD'
} as const;

export type LoanClassification = typeof LoanClassification[keyof typeof LoanClassification];


/**
 * 
 * @export
 * @interface LoanCollateralListEntry
 */
export interface LoanCollateralListEntry {
    /**
     * 
     * @type {number}
     * @memberof LoanCollateralListEntry
     */
    'ciaValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanCollateralListEntry
     */
    'collateralAgreementType'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanCollateralListEntry
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LoanCollateralListEntry
     */
    'importedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanCollateralListEntry
     */
    'marketValue'?: number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof LoanCollateralListEntry
     */
    'owner'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof LoanCollateralListEntry
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof LoanCollateralListEntry
     */
    'sizeMark': string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof LoanCollateralListEntry
     */
    'subType': CollateralSubType;
}


/**
 * 
 * @export
 * @interface LoanDetails
 */
export interface LoanDetails {
    /**
     * 
     * @type {string}
     * @memberof LoanDetails
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof LoanDetails
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof LoanDetails
     */
    'contractId': string;
    /**
     * 
     * @type {string}
     * @memberof LoanDetails
     */
    'customerName': string;
    /**
     * 
     * @type {number}
     * @memberof LoanDetails
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof LoanDetails
     */
    'loanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LoanDetails
     */
    'loanBalance': number;
    /**
     * 
     * @type {number}
     * @memberof LoanDetails
     */
    'nextPaymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof LoanDetails
     */
    'nextPaymentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanDetails
     */
    'phoneNumber': string;
    /**
     * 
     * @type {ProductType}
     * @memberof LoanDetails
     */
    'productType': ProductType;
    /**
     * 
     * @type {string}
     * @memberof LoanDetails
     */
    'registrationNumber': string;
    /**
     * 
     * @type {LoanStatus}
     * @memberof LoanDetails
     */
    'status': LoanStatus;
}


/**
 * 
 * @export
 * @interface LoanGracePeriodPrecondition
 */
export interface LoanGracePeriodPrecondition {
    /**
     * 
     * @type {Array<GracePeriod>}
     * @memberof LoanGracePeriodPrecondition
     */
    'gracePeriods': Array<GracePeriod>;
}
/**
 * 
 * @export
 * @interface LoanGracePeriodProposal
 */
export interface LoanGracePeriodProposal {
    /**
     * 
     * @type {number}
     * @memberof LoanGracePeriodProposal
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface LoanHistoryScore
 */
export interface LoanHistoryScore {
    /**
     * 
     * @type {FromToPairBigDecimal}
     * @memberof LoanHistoryScore
     */
    'paidHistory'?: FromToPairBigDecimal;
    /**
     * 
     * @type {number}
     * @memberof LoanHistoryScore
     */
    'score'?: number;
    /**
     * 
     * @type {WorstClassification}
     * @memberof LoanHistoryScore
     */
    'worstClassification'?: WorstClassification;
}


/**
 * 
 * @export
 * @interface LoanHistoryScoreCustomerResult
 */
export interface LoanHistoryScoreCustomerResult {
    /**
     * 
     * @type {number}
     * @memberof LoanHistoryScoreCustomerResult
     */
    'customerPaidHistory'?: number;
    /**
     * 
     * @type {WorstClassification}
     * @memberof LoanHistoryScoreCustomerResult
     */
    'customerWorstClassification'?: WorstClassification;
    /**
     * 
     * @type {LoanHistoryScore}
     * @memberof LoanHistoryScoreCustomerResult
     */
    'loanHistoryScoreSetting'?: LoanHistoryScore;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LoanHistoryScoringColumnKey = {
    WORST_CLASSIFICATION: 'WORST_CLASSIFICATION',
    PAID_HISTORY: 'PAID_HISTORY'
} as const;

export type LoanHistoryScoringColumnKey = typeof LoanHistoryScoringColumnKey[keyof typeof LoanHistoryScoringColumnKey];


/**
 * 
 * @export
 * @interface LoanHistoryScoringColumnMetadata
 */
export interface LoanHistoryScoringColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof LoanHistoryScoringColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {LoanHistoryScoringColumnKey}
     * @memberof LoanHistoryScoringColumnMetadata
     */
    'key'?: LoanHistoryScoringColumnKey;
    /**
     * 
     * @type {WorstClassification}
     * @memberof LoanHistoryScoringColumnMetadata
     */
    'payload'?: WorstClassification;
}


/**
 * 
 * @export
 * @interface LoanHistoryScoringRow
 */
export interface LoanHistoryScoringRow {
    /**
     * 
     * @type {FromToPairBigDecimal}
     * @memberof LoanHistoryScoringRow
     */
    'paidHistory'?: FromToPairBigDecimal;
    /**
     * 
     * @type {Array<number>}
     * @memberof LoanHistoryScoringRow
     */
    'scores'?: Array<number>;
}
/**
 * 
 * @export
 * @interface LoanHistoryScoringSettingDto
 */
export interface LoanHistoryScoringSettingDto extends Settings {
    /**
     * 
     * @type {Array<LoanHistoryScoringColumnMetadata>}
     * @memberof LoanHistoryScoringSettingDto
     */
    'columns'?: Array<LoanHistoryScoringColumnMetadata>;
    /**
     * 
     * @type {Array<LoanHistoryScoringRow>}
     * @memberof LoanHistoryScoringSettingDto
     */
    'rows'?: Array<LoanHistoryScoringRow>;
}
/**
 * 
 * @export
 * @interface LoanInterestAccrualPrecondition
 */
export interface LoanInterestAccrualPrecondition {
    /**
     * 
     * @type {boolean}
     * @memberof LoanInterestAccrualPrecondition
     */
    'interestAccrualStopped'?: boolean;
}
/**
 * 
 * @export
 * @interface LoanInterestAdjustment
 */
export interface LoanInterestAdjustment {
    /**
     * 
     * @type {number}
     * @memberof LoanInterestAdjustment
     */
    'totalInterest': number;
}
/**
 * 
 * @export
 * @interface LoanInterestRateChangingPrecondition
 */
export interface LoanInterestRateChangingPrecondition {
    /**
     * 
     * @type {number}
     * @memberof LoanInterestRateChangingPrecondition
     */
    'currentInterestRate': number;
}
/**
 * 
 * @export
 * @interface LoanInterestRateChangingProposal
 */
export interface LoanInterestRateChangingProposal {
    /**
     * 
     * @type {number}
     * @memberof LoanInterestRateChangingProposal
     */
    'interestRate': number;
}
/**
 * 
 * @export
 * @interface LoanListEntry
 */
export interface LoanListEntry {
    /**
     * 
     * @type {number}
     * @memberof LoanListEntry
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof LoanListEntry
     */
    'applicationId': number;
    /**
     * 
     * @type {CustomerLoanInfo}
     * @memberof LoanListEntry
     */
    'customer': CustomerLoanInfo;
    /**
     * 
     * @type {string}
     * @memberof LoanListEntry
     */
    'disbursementDate': string;
    /**
     * 
     * @type {string}
     * @memberof LoanListEntry
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof LoanListEntry
     */
    'id': number;
    /**
     * 
     * @type {LoanStatus}
     * @memberof LoanListEntry
     */
    'loanStatus': LoanStatus;
    /**
     * 
     * @type {number}
     * @memberof LoanListEntry
     */
    'principal': number;
    /**
     * 
     * @type {number}
     * @memberof LoanListEntry
     */
    'principalBalance': number;
    /**
     * 
     * @type {ProductType}
     * @memberof LoanListEntry
     */
    'productType': ProductType;
}


/**
 * 
 * @export
 * @interface LoanMaturityDateExtensionPrecondition
 */
export interface LoanMaturityDateExtensionPrecondition {
    /**
     * 
     * @type {number}
     * @memberof LoanMaturityDateExtensionPrecondition
     */
    'allowedAdditionalPaymentsCount': number;
    /**
     * 
     * @type {string}
     * @memberof LoanMaturityDateExtensionPrecondition
     */
    'maturityDate': string;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof LoanMaturityDateExtensionPrecondition
     */
    'repaymentFrequency': RepaymentFrequency;
}


/**
 * 
 * @export
 * @interface LoanMaturityDateExtensionProposal
 */
export interface LoanMaturityDateExtensionProposal {
    /**
     * 
     * @type {number}
     * @memberof LoanMaturityDateExtensionProposal
     */
    'additionalPaymentsCount': number;
}
/**
 * 
 * @export
 * @interface LoanOperationAuditEventListEntry
 */
export interface LoanOperationAuditEventListEntry {
    /**
     * 
     * @type {number}
     * @memberof LoanOperationAuditEventListEntry
     */
    'amount'?: number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof LoanOperationAuditEventListEntry
     */
    'createdBy'?: IdNameEntry;
    /**
     * 
     * @type {CreditBureauStatus}
     * @memberof LoanOperationAuditEventListEntry
     */
    'creditBureauStatus': CreditBureauStatus;
    /**
     * 
     * @type {string}
     * @memberof LoanOperationAuditEventListEntry
     */
    'effectiveAt': string;
    /**
     * 
     * @type {AuditBalance}
     * @memberof LoanOperationAuditEventListEntry
     */
    'interest': AuditBalance;
    /**
     * 
     * @type {AuditBalance}
     * @memberof LoanOperationAuditEventListEntry
     */
    'interestOverdue': AuditBalance;
    /**
     * 
     * @type {LoanStatus}
     * @memberof LoanOperationAuditEventListEntry
     */
    'loanStatus': LoanStatus;
    /**
     * 
     * @type {LoanOperationAuditEventOperationType}
     * @memberof LoanOperationAuditEventListEntry
     */
    'operationType': LoanOperationAuditEventOperationType;
    /**
     * 
     * @type {number}
     * @memberof LoanOperationAuditEventListEntry
     */
    'originalPrincipalDebt': number;
    /**
     * 
     * @type {AuditBalance}
     * @memberof LoanOperationAuditEventListEntry
     */
    'penalty': AuditBalance;
    /**
     * 
     * @type {AuditBalance}
     * @memberof LoanOperationAuditEventListEntry
     */
    'principal': AuditBalance;
    /**
     * 
     * @type {AuditBalance}
     * @memberof LoanOperationAuditEventListEntry
     */
    'principalOverdue': AuditBalance;
    /**
     * 
     * @type {number}
     * @memberof LoanOperationAuditEventListEntry
     */
    'totalPaidAmount': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LoanOperationAuditEventOperationType = {
    CIB_STATUS_CHANGING: 'CIB_STATUS_CHANGING',
    DISBURSEMENT: 'DISBURSEMENT',
    FIRST_OVERDUE_DAY: 'FIRST_OVERDUE_DAY',
    INTEREST_ADJUSTMENT: 'INTEREST_ADJUSTMENT',
    LATE_FEE_PAYMENT: 'LATE_FEE_PAYMENT',
    LOAN_STATUS_CHANGING: 'LOAN_STATUS_CHANGING',
    PAYMENT_CANCELLATION: 'PAYMENT_CANCELLATION',
    PENALTY_ADJUSTMENT: 'PENALTY_ADJUSTMENT',
    REPAYMENT: 'REPAYMENT',
    WRITE_OFF: 'WRITE_OFF'
} as const;

export type LoanOperationAuditEventOperationType = typeof LoanOperationAuditEventOperationType[keyof typeof LoanOperationAuditEventOperationType];


/**
 * 
 * @export
 * @interface LoanPastDatePaymentPrecondition
 */
export interface LoanPastDatePaymentPrecondition {
    /**
     * 
     * @type {string}
     * @memberof LoanPastDatePaymentPrecondition
     */
    'leastPossiblePaymentDate': string;
}
/**
 * 
 * @export
 * @interface LoanPastDatePaymentProposal
 */
export interface LoanPastDatePaymentProposal {
    /**
     * 
     * @type {number}
     * @memberof LoanPastDatePaymentProposal
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanPastDatePaymentProposal
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanPastDatePaymentProposal
     */
    'paymentDate'?: string;
}
/**
 * 
 * @export
 * @interface LoanPatch
 */
export interface LoanPatch {
    /**
     * 
     * @type {string}
     * @memberof LoanPatch
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface LoanPayment
 */
export interface LoanPayment {
    /**
     * 
     * @type {number}
     * @memberof LoanPayment
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'effectiveAt': string;
    /**
     * 
     * @type {number}
     * @memberof LoanPayment
     */
    'paymentId': number;
}
/**
 * 
 * @export
 * @interface LoanPaymentAmountChangingPrecondition
 */
export interface LoanPaymentAmountChangingPrecondition {
    /**
     * 
     * @type {number}
     * @memberof LoanPaymentAmountChangingPrecondition
     */
    'currentPrincipal': number;
    /**
     * 
     * @type {Array<LoanSchedulePrincipal>}
     * @memberof LoanPaymentAmountChangingPrecondition
     */
    'loanSchedulePrincipals': Array<LoanSchedulePrincipal>;
}
/**
 * 
 * @export
 * @interface LoanPaymentAmountChangingProposal
 */
export interface LoanPaymentAmountChangingProposal {
    /**
     * 
     * @type {Array<LoanSchedulePrincipal>}
     * @memberof LoanPaymentAmountChangingProposal
     */
    'loanSchedulePrincipals': Array<LoanSchedulePrincipal>;
}
/**
 * 
 * @export
 * @interface LoanPaymentCancellationPrecondition
 */
export interface LoanPaymentCancellationPrecondition {
    /**
     * 
     * @type {Array<LoanPayment>}
     * @memberof LoanPaymentCancellationPrecondition
     */
    'loanPayments': Array<LoanPayment>;
}
/**
 * 
 * @export
 * @interface LoanPaymentCancellationProposal
 */
export interface LoanPaymentCancellationProposal {
    /**
     * 
     * @type {string}
     * @memberof LoanPaymentCancellationProposal
     */
    'comment'?: string;
    /**
     * 
     * @type {Array<LoanPayment>}
     * @memberof LoanPaymentCancellationProposal
     */
    'loanPayments'?: Array<LoanPayment>;
}
/**
 * 
 * @export
 * @interface LoanPaymentDateChangingPrecondition
 */
export interface LoanPaymentDateChangingPrecondition {
    /**
     * 
     * @type {boolean}
     * @memberof LoanPaymentDateChangingPrecondition
     */
    'hasSecondPaymentDay': boolean;
}
/**
 * 
 * @export
 * @interface LoanPaymentDateChangingProposal
 */
export interface LoanPaymentDateChangingProposal {
    /**
     * 
     * @type {number}
     * @memberof LoanPaymentDateChangingProposal
     */
    'firstPaymentDay': number;
    /**
     * 
     * @type {number}
     * @memberof LoanPaymentDateChangingProposal
     */
    'secondPaymentDay'?: number;
}
/**
 * 
 * @export
 * @interface LoanPenaltyAdjustment
 */
export interface LoanPenaltyAdjustment {
    /**
     * 
     * @type {number}
     * @memberof LoanPenaltyAdjustment
     */
    'penalty': number;
}
/**
 * 
 * @export
 * @interface LoanPurposeDictionaryItem
 */
export interface LoanPurposeDictionaryItem {
    /**
     * 
     * @type {number}
     * @memberof LoanPurposeDictionaryItem
     */
    'id': number;
    /**
     * 
     * @type {Localization}
     * @memberof LoanPurposeDictionaryItem
     */
    'l10n': Localization;
    /**
     * 
     * @type {Array<LoanSubPurposeDictionaryItem>}
     * @memberof LoanPurposeDictionaryItem
     */
    'loanSubPurposes': Array<LoanSubPurposeDictionaryItem>;
}
/**
 * 
 * @export
 * @interface LoanRefinancingPrecondition
 */
export interface LoanRefinancingPrecondition {
    /**
     * 
     * @type {boolean}
     * @memberof LoanRefinancingPrecondition
     */
    'hasSecondPaymentDay': boolean;
    /**
     * 
     * @type {number}
     * @memberof LoanRefinancingPrecondition
     */
    'repaymentsQuantity': number;
}
/**
 * 
 * @export
 * @interface LoanRefinancingProposal
 */
export interface LoanRefinancingProposal {
    /**
     * 
     * @type {string}
     * @memberof LoanRefinancingProposal
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof LoanRefinancingProposal
     */
    'refinancingPrincipalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LoanRefinancingProposal
     */
    'repaymentsQuantity': number;
    /**
     * 
     * @type {string}
     * @memberof LoanRefinancingProposal
     */
    'secondRepaymentDate'?: string;
}
/**
 * 
 * @export
 * @interface LoanSchedulePeriodEntry
 */
export interface LoanSchedulePeriodEntry {
    /**
     * 
     * @type {number}
     * @memberof LoanSchedulePeriodEntry
     */
    'daysOverdue': number;
    /**
     * 
     * @type {number}
     * @memberof LoanSchedulePeriodEntry
     */
    'interest': number;
    /**
     * 
     * @type {number}
     * @memberof LoanSchedulePeriodEntry
     */
    'interestOverdue': number;
    /**
     * 
     * @type {string}
     * @memberof LoanSchedulePeriodEntry
     */
    'paymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof LoanSchedulePeriodEntry
     */
    'penaltyDue': number;
    /**
     * 
     * @type {number}
     * @memberof LoanSchedulePeriodEntry
     */
    'principal': number;
    /**
     * 
     * @type {number}
     * @memberof LoanSchedulePeriodEntry
     */
    'principalOverdue': number;
    /**
     * 
     * @type {SchedulePeriodStatus}
     * @memberof LoanSchedulePeriodEntry
     */
    'schedulePeriodStatus': SchedulePeriodStatus;
    /**
     * 
     * @type {number}
     * @memberof LoanSchedulePeriodEntry
     */
    'totalPayment': number;
}


/**
 * 
 * @export
 * @interface LoanSchedulePrincipal
 */
export interface LoanSchedulePrincipal {
    /**
     * 
     * @type {string}
     * @memberof LoanSchedulePrincipal
     */
    'paymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof LoanSchedulePrincipal
     */
    'periodId': number;
    /**
     * 
     * @type {number}
     * @memberof LoanSchedulePrincipal
     */
    'principal': number;
}
/**
 * 
 * @export
 * @interface LoanSetCibStatusNormal
 */
export interface LoanSetCibStatusNormal extends LoanPatch {
}
/**
 * 
 * @export
 * @interface LoanState
 */
export interface LoanState {
    /**
     * 
     * @type {number}
     * @memberof LoanState
     */
    'accruedInterest': number;
    /**
     * 
     * @type {number}
     * @memberof LoanState
     */
    'closingAmount': number;
    /**
     * 
     * @type {string}
     * @memberof LoanState
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof LoanState
     */
    'interestAccrualDays': number;
    /**
     * 
     * @type {number}
     * @memberof LoanState
     */
    'penalty': number;
    /**
     * 
     * @type {number}
     * @memberof LoanState
     */
    'penaltyAccrualDays': number;
    /**
     * 
     * @type {number}
     * @memberof LoanState
     */
    'principal': number;
    /**
     * 
     * @type {number}
     * @memberof LoanState
     */
    'totalAmount': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LoanStatus = {
    NORMAL: 'NORMAL',
    PAST_DUE: 'PAST_DUE',
    STRESSED_1: 'STRESSED_1',
    STRESSED_2: 'STRESSED_2',
    SUB_STANDARD: 'SUB_STANDARD',
    DOUBTFUL: 'DOUBTFUL',
    LOSS: 'LOSS',
    LAWSUIT_1: 'LAWSUIT_1',
    BORROWER_DEATH_AND_LAWSUIT_2: 'BORROWER_DEATH_AND_LAWSUIT_2',
    REPAID: 'REPAID',
    REOPENED_AS_INSTALLMENT: 'REOPENED_AS_INSTALLMENT',
    RECALLED: 'RECALLED',
    WRITTEN_OFF: 'WRITTEN_OFF'
} as const;

export type LoanStatus = typeof LoanStatus[keyof typeof LoanStatus];


/**
 * 
 * @export
 * @interface LoanSubPurposeDictionaryItem
 */
export interface LoanSubPurposeDictionaryItem {
    /**
     * 
     * @type {number}
     * @memberof LoanSubPurposeDictionaryItem
     */
    'id': number;
    /**
     * 
     * @type {Localization}
     * @memberof LoanSubPurposeDictionaryItem
     */
    'l10n': Localization;
}
/**
 * 
 * @export
 * @interface LoanTestPaymentModel
 */
export interface LoanTestPaymentModel {
    /**
     * 
     * @type {number}
     * @memberof LoanTestPaymentModel
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanTestPaymentModel
     */
    'effectiveAt': string;
}
/**
 * 
 * @export
 * @interface LoansSummary
 */
export interface LoansSummary {
    /**
     * 
     * @type {number}
     * @memberof LoansSummary
     */
    'activeLoansBalance': number;
    /**
     * 
     * @type {number}
     * @memberof LoansSummary
     */
    'activeLoansCount': number;
    /**
     * 
     * @type {number}
     * @memberof LoansSummary
     */
    'closedLoansCount': number;
    /**
     * 
     * @type {number}
     * @memberof LoansSummary
     */
    'normalLoansCount': number;
    /**
     * 
     * @type {number}
     * @memberof LoansSummary
     */
    'overdueLoansCount': number;
    /**
     * 
     * @type {number}
     * @memberof LoansSummary
     */
    'totalLoanApplications': number;
    /**
     * 
     * @type {number}
     * @memberof LoansSummary
     */
    'totalLoans': number;
}
/**
 * 
 * @export
 * @interface Localization
 */
export interface Localization {
    /**
     * 
     * @type {string}
     * @memberof Localization
     */
    'en': string;
    /**
     * 
     * @type {string}
     * @memberof Localization
     */
    'mn': string;
}
/**
 * 
 * @export
 * @interface LocalizedEnum
 */
export interface LocalizedEnum {
    /**
     * 
     * @type {string}
     * @memberof LocalizedEnum
     */
    'key'?: string;
    /**
     * 
     * @type {Localization}
     * @memberof LocalizedEnum
     */
    'l10n'?: Localization;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LocalizedEnumTypes = {
    ADDRESS_OWNER: 'ADDRESS_OWNER',
    ADDRESS_TYPE: 'ADDRESS_TYPE',
    APPLICATION_SOURCE: 'APPLICATION_SOURCE',
    BANK_INFORMATION: 'BANK_INFORMATION',
    BANK_STATEMENT_OPERATION_TYPE: 'BANK_STATEMENT_OPERATION_TYPE',
    BANK_STATEMENT_STATUS: 'BANK_STATEMENT_STATUS',
    BO_USER_DEPARTMENT: 'BO_USER_DEPARTMENT',
    CAR_COLLATERAL_CATEGORY: 'CAR_COLLATERAL_CATEGORY',
    CHECK_SERVICE_NAME: 'CHECK_SERVICE_NAME',
    CHECK_STEP_NAME: 'CHECK_STEP_NAME',
    CITIZENSHIP: 'CITIZENSHIP',
    COLLATERAL_OWNERSHIP_TYPE: 'COLLATERAL_OWNERSHIP_TYPE',
    COLLATERAL_SUB_TYPE: 'COLLATERAL_SUB_TYPE',
    COLLATERAL_TYPE: 'COLLATERAL_TYPE',
    COLLECTION_ACTIVITY_CONTACTED_PERSON: 'COLLECTION_ACTIVITY_CONTACTED_PERSON',
    COLLECTION_ACTIVITY_GENERAL_TYPE: 'COLLECTION_ACTIVITY_GENERAL_TYPE',
    COLLECTION_ACTIVITY_NEXT_ACTION: 'COLLECTION_ACTIVITY_NEXT_ACTION',
    COLLECTION_ACTIVITY_OVERDUE_REASON: 'COLLECTION_ACTIVITY_OVERDUE_REASON',
    COLLECTION_ACTIVITY_STATUS: 'COLLECTION_ACTIVITY_STATUS',
    COLLECTION_ACTIVITY_SUB_TYPE: 'COLLECTION_ACTIVITY_SUB_TYPE',
    COLLECTION_ACTIVITY_TYPE: 'COLLECTION_ACTIVITY_TYPE',
    COLLECTION_LEVEL: 'COLLECTION_LEVEL',
    COLLECTION_STATUS: 'COLLECTION_STATUS',
    CO_OWNER_COUNT: 'CO_OWNER_COUNT',
    CREDIT_BUREAU_STATUS: 'CREDIT_BUREAU_STATUS',
    CURRENCY: 'CURRENCY',
    CUSTOMER_INCOME_TYPE: 'CUSTOMER_INCOME_TYPE',
    CUSTOMER_KYC_LEVEL: 'CUSTOMER_KYC_LEVEL',
    CUSTOMER_SEGMENT: 'CUSTOMER_SEGMENT',
    CUSTOMER_STATUS: 'CUSTOMER_STATUS',
    CUSTOMER_TYPE: 'CUSTOMER_TYPE',
    CUSTOMER_VERIFICATION_STATUS: 'CUSTOMER_VERIFICATION_STATUS',
    DAY_OF_WEEK: 'DAY_OF_WEEK',
    DISTRICT: 'DISTRICT',
    DOCUMENT_SOURCE: 'DOCUMENT_SOURCE',
    DOCUMENT_TEMPLATE_TYPE: 'DOCUMENT_TEMPLATE_TYPE',
    DOCUMENT_TYPE: 'DOCUMENT_TYPE',
    EDUCATION: 'EDUCATION',
    EMPLOYMENT: 'EMPLOYMENT',
    ENTERPRISE_TYPE: 'ENTERPRISE_TYPE',
    EQUIPMENT_TYPE: 'EQUIPMENT_TYPE',
    ESTATE_PURPOSE: 'ESTATE_PURPOSE',
    HERDER_INFORMATION: 'HERDER_INFORMATION',
    INCOME_CHECK_STATUS: 'INCOME_CHECK_STATUS',
    INCOME_SOURCE: 'INCOME_SOURCE',
    INCOME_TYPE: 'INCOME_TYPE',
    INDUSTRY: 'INDUSTRY',
    INSURANCE_SOURCE: 'INSURANCE_SOURCE',
    INSURANCE_STATUS: 'INSURANCE_STATUS',
    INVESTMENT_TYPE: 'INVESTMENT_TYPE',
    KHOROO: 'KHOROO',
    LAND_OWNERSHIP_TYPE: 'LAND_OWNERSHIP_TYPE',
    LOAN_APPLICATION_STATUS: 'LOAN_APPLICATION_STATUS',
    LOAN_CLASSIFICATION: 'LOAN_CLASSIFICATION',
    LOAN_OPERATION_AUDIT_EVENT_OPERATION_TYPE: 'LOAN_OPERATION_AUDIT_EVENT_OPERATION_TYPE',
    LOAN_OPERATION_STATUS: 'LOAN_OPERATION_STATUS',
    LOAN_OPERATION_TYPE: 'LOAN_OPERATION_TYPE',
    LOAN_STATUS: 'LOAN_STATUS',
    MARITAL_STATUS: 'MARITAL_STATUS',
    MERCHANT_COMPANY: 'MERCHANT_COMPANY',
    MERCHANT_STATUS: 'MERCHANT_STATUS',
    NUMBER_OF_ROOMS: 'NUMBER_OF_ROOMS',
    OWNED_ESTATE_ASSET_SUBTYPE: 'OWNED_ESTATE_ASSET_SUBTYPE',
    OWNED_ESTATE_ASSET_TYPE: 'OWNED_ESTATE_ASSET_TYPE',
    OWNED_REAL_ESTATE: 'OWNED_REAL_ESTATE',
    OWNER_TYPE: 'OWNER_TYPE',
    PAYMENT_TYPE: 'PAYMENT_TYPE',
    PENALTY_INTEREST_ACCRUAL_METHOD: 'PENALTY_INTEREST_ACCRUAL_METHOD',
    PLATFORM_NAME: 'PLATFORM_NAME',
    PRODUCT_STATUS: 'PRODUCT_STATUS',
    PRODUCT_SUBTYPE: 'PRODUCT_SUBTYPE',
    PRODUCT_TYPE: 'PRODUCT_TYPE',
    PROVINCE: 'PROVINCE',
    RATING_ORGANISATION: 'RATING_ORGANISATION',
    REFERENCE_RELATIONSHIP: 'REFERENCE_RELATIONSHIP',
    RELATION_TO_NBFI: 'RELATION_TO_NBFI',
    REPAYMENT_ALLOCATION_RULE: 'REPAYMENT_ALLOCATION_RULE',
    REPAYMENT_CALCULATION_METHOD: 'REPAYMENT_CALCULATION_METHOD',
    REPAYMENT_FREQUENCY: 'REPAYMENT_FREQUENCY',
    RESTRICTION_CAUSE: 'RESTRICTION_CAUSE',
    RESTRICTION_LIST_TYPE: 'RESTRICTION_LIST_TYPE',
    RESTRICTION_TYPE: 'RESTRICTION_TYPE',
    RISKY_GROUP: 'RISKY_GROUP',
    SCHEDULE_PERIOD_STATUS: 'SCHEDULE_PERIOD_STATUS',
    SEX: 'SEX',
    START_PAGE: 'START_PAGE',
    STEERING_WHEEL: 'STEERING_WHEEL',
    TASK_STATUS: 'TASK_STATUS',
    TRANSACTION_STATUS: 'TRANSACTION_STATUS',
    TRANSACTION_TYPE: 'TRANSACTION_TYPE',
    USER_ACTIVITY: 'USER_ACTIVITY',
    USER_STATUS: 'USER_STATUS',
    USE_OF_LAND: 'USE_OF_LAND',
    VERIFICATION_CHECK_DECISION: 'VERIFICATION_CHECK_DECISION',
    VERIFICATION_CHECK_DECISION_TYPE: 'VERIFICATION_CHECK_DECISION_TYPE',
    WORST_CLASSIFICATION: 'WORST_CLASSIFICATION'
} as const;

export type LocalizedEnumTypes = typeof LocalizedEnumTypes[keyof typeof LocalizedEnumTypes];


/**
 * 
 * @export
 * @interface ManagersListEntry
 */
export interface ManagersListEntry {
    /**
     * 
     * @type {string}
     * @memberof ManagersListEntry
     */
    'branchCode': string;
    /**
     * 
     * @type {number}
     * @memberof ManagersListEntry
     */
    'customersCount': number;
    /**
     * 
     * @type {number}
     * @memberof ManagersListEntry
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ManagersListEntry
     */
    'loanApplicationsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ManagersListEntry
     */
    'loansCount': number;
    /**
     * 
     * @type {string}
     * @memberof ManagersListEntry
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ManagersListEntry
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface ManualLoanStatusPrecondition
 */
export interface ManualLoanStatusPrecondition {
    /**
     * 
     * @type {Array<LoanStatus>}
     * @memberof ManualLoanStatusPrecondition
     */
    'manualLoanStatuses': Array<LoanStatus>;
}
/**
 * 
 * @export
 * @interface ManualLoanStatusRequest
 */
export interface ManualLoanStatusRequest {
    /**
     * 
     * @type {LoanStatus}
     * @memberof ManualLoanStatusRequest
     */
    'manualLoanStatus': LoanStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MaritalStatus = {
    SINGLE: 'SINGLE',
    MARRIED: 'MARRIED',
    WIDOWED: 'WIDOWED',
    DIVORCED: 'DIVORCED',
    REGISTERED_PARTNERSHIP: 'REGISTERED_PARTNERSHIP'
} as const;

export type MaritalStatus = typeof MaritalStatus[keyof typeof MaritalStatus];


/**
 * 
 * @export
 * @interface MenuLink
 */
export interface MenuLink {
    /**
     * 
     * @type {Localization}
     * @memberof MenuLink
     */
    'l10n': Localization;
    /**
     * 
     * @type {string}
     * @memberof MenuLink
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface Merchant
 */
export interface Merchant {
    /**
     * 
     * @type {MerchantCompany}
     * @memberof Merchant
     */
    'company': MerchantCompany;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'companyAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof Merchant
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'phone': string;
    /**
     * 
     * @type {MerchantStatus}
     * @memberof Merchant
     */
    'status': MerchantStatus;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'surname': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MerchantCompany = {
    COMPANY_1: 'COMPANY_1',
    COMPANY_2: 'COMPANY_2'
} as const;

export type MerchantCompany = typeof MerchantCompany[keyof typeof MerchantCompany];


/**
 * 
 * @export
 * @interface MerchantListItem
 */
export interface MerchantListItem {
    /**
     * 
     * @type {MerchantCompany}
     * @memberof MerchantListItem
     */
    'company': MerchantCompany;
    /**
     * 
     * @type {string}
     * @memberof MerchantListItem
     */
    'companyAddress': string;
    /**
     * 
     * @type {string}
     * @memberof MerchantListItem
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof MerchantListItem
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MerchantListItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MerchantListItem
     */
    'phone': string;
    /**
     * 
     * @type {MerchantStatus}
     * @memberof MerchantListItem
     */
    'status': MerchantStatus;
    /**
     * 
     * @type {string}
     * @memberof MerchantListItem
     */
    'surname': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MerchantStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;

export type MerchantStatus = typeof MerchantStatus[keyof typeof MerchantStatus];


/**
 * 
 * @export
 * @interface MonthlyIncomeForDtiScore
 */
export interface MonthlyIncomeForDtiScore {
    /**
     * 
     * @type {FromToPairBigDecimal}
     * @memberof MonthlyIncomeForDtiScore
     */
    'dti'?: FromToPairBigDecimal;
    /**
     * 
     * @type {FromToPairBigDecimal}
     * @memberof MonthlyIncomeForDtiScore
     */
    'monthlyIncome'?: FromToPairBigDecimal;
    /**
     * 
     * @type {number}
     * @memberof MonthlyIncomeForDtiScore
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface MonthlyIncomeForDtiScoreCustomerResult
 */
export interface MonthlyIncomeForDtiScoreCustomerResult {
    /**
     * 
     * @type {number}
     * @memberof MonthlyIncomeForDtiScoreCustomerResult
     */
    'customerDti'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyIncomeForDtiScoreCustomerResult
     */
    'customerMonthlyIncome'?: number;
    /**
     * 
     * @type {MonthlyIncomeForDtiScore}
     * @memberof MonthlyIncomeForDtiScoreCustomerResult
     */
    'monthlyIncomeForDtiScoreSetting'?: MonthlyIncomeForDtiScore;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MonthlyIncomeForDtiScoringColumnKey = {
    DTI: 'DTI',
    MONTHLY_INCOME: 'MONTHLY_INCOME'
} as const;

export type MonthlyIncomeForDtiScoringColumnKey = typeof MonthlyIncomeForDtiScoringColumnKey[keyof typeof MonthlyIncomeForDtiScoringColumnKey];


/**
 * 
 * @export
 * @interface MonthlyIncomeForDtiScoringColumnMetadata
 */
export interface MonthlyIncomeForDtiScoringColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof MonthlyIncomeForDtiScoringColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {MonthlyIncomeForDtiScoringColumnKey}
     * @memberof MonthlyIncomeForDtiScoringColumnMetadata
     */
    'key'?: MonthlyIncomeForDtiScoringColumnKey;
    /**
     * 
     * @type {FromToPairBigDecimal}
     * @memberof MonthlyIncomeForDtiScoringColumnMetadata
     */
    'payload'?: FromToPairBigDecimal;
}


/**
 * 
 * @export
 * @interface MonthlyIncomeForDtiScoringRow
 */
export interface MonthlyIncomeForDtiScoringRow {
    /**
     * 
     * @type {FromToPairBigDecimal}
     * @memberof MonthlyIncomeForDtiScoringRow
     */
    'dti'?: FromToPairBigDecimal;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonthlyIncomeForDtiScoringRow
     */
    'scores'?: Array<number>;
}
/**
 * 
 * @export
 * @interface MonthlyIncomeForDtiScoringSettingDto
 */
export interface MonthlyIncomeForDtiScoringSettingDto extends Settings {
    /**
     * 
     * @type {Array<MonthlyIncomeForDtiScoringColumnMetadata>}
     * @memberof MonthlyIncomeForDtiScoringSettingDto
     */
    'columns'?: Array<MonthlyIncomeForDtiScoringColumnMetadata>;
    /**
     * 
     * @type {Array<MonthlyIncomeForDtiScoringRow>}
     * @memberof MonthlyIncomeForDtiScoringSettingDto
     */
    'rows'?: Array<MonthlyIncomeForDtiScoringRow>;
}
/**
 * 
 * @export
 * @interface MonthlyIncomeResponse
 */
export interface MonthlyIncomeResponse {
    /**
     * 
     * @type {number}
     * @memberof MonthlyIncomeResponse
     */
    'monthlyIncomeValue'?: number;
}
/**
 * 
 * @export
 * @interface N110Task
 */
export interface N110Task extends Task {
    /**
     * 
     * @type {string}
     * @memberof N110Task
     */
    'accountantComment'?: string;
    /**
     * 
     * @type {number}
     * @memberof N110Task
     */
    'amount': number;
    /**
     * 
     * @type {Array<TaskDecisionType>}
     * @memberof N110Task
     */
    'availableDecisionTypes': Array<TaskDecisionType>;
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N110Task
     */
    'collaterals': Array<IdEntry>;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof N110Task
     */
    'creditCommitteeConditions'?: PledgedLoanApplicationConditions;
    /**
     * 
     * @type {number}
     * @memberof N110Task
     */
    'downpaymentAmount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof N110Task
     */
    'downpaymentPaid'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof N110Task
     */
    'downpaymentPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof N110Task
     */
    'estimatedCoBorrowersDti': number;
    /**
     * 
     * @type {number}
     * @memberof N110Task
     */
    'estimatedFirstRepaymentAmount': number;
    /**
     * 
     * @type {number}
     * @memberof N110Task
     */
    'estimatedIndividualDti': number;
    /**
     * 
     * @type {string}
     * @memberof N110Task
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof N110Task
     */
    'invoiceAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof N110Task
     */
    'invoicePreviousAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof N110Task
     */
    'laatLeaderComment'?: string;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof N110Task
     */
    'laatUserConditions': PledgedLoanApplicationConditions;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N110Task
     */
    'laatUserProduct': IdNameEntry;
    /**
     * 
     * @type {IdEntry}
     * @memberof N110Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof N110Task
     */
    'monthlyInterestRate': number;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof N110Task
     */
    'pledgedProducts': Array<IdNameEntry>;
    /**
     * 
     * @type {number}
     * @memberof N110Task
     */
    'productId': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof N110Task
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof N110Task
     */
    'requestedConditions': PledgedLoanApplicationConditions;
    /**
     * 
     * @type {string}
     * @memberof N110Task
     */
    'requestedLoanPurpose': string;
    /**
     * 
     * @type {string}
     * @memberof N110Task
     */
    'secondRepaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof N110Task
     */
    'term': number;
}


/**
 * 
 * @export
 * @interface N110TaskDecisionAddLoanConditions
 */
export interface N110TaskDecisionAddLoanConditions extends TaskDecision {
    /**
     * 
     * @type {number}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'commission': number;
    /**
     * 
     * @type {number}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'downpaymentAmount': number;
    /**
     * 
     * @type {boolean}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'downpaymentPaid': boolean;
    /**
     * 
     * @type {number}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'downpaymentPercentage': number;
    /**
     * 
     * @type {string}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'monthlyInterestRate': number;
    /**
     * 
     * @type {number}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'productId': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {number}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'requestedTerm': number;
    /**
     * 
     * @type {string}
     * @memberof N110TaskDecisionAddLoanConditions
     */
    'secondRepaymentDate'?: string;
}


/**
 * 
 * @export
 * @interface N110TaskDecisionDecline
 */
export interface N110TaskDecisionDecline extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N110TaskDecisionDecline
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N110TaskDecisionRequestInvoiceChange
 */
export interface N110TaskDecisionRequestInvoiceChange extends TaskDecision {
    /**
     * 
     * @type {number}
     * @memberof N110TaskDecisionRequestInvoiceChange
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface N111CoBorrower
 */
export interface N111CoBorrower {
    /**
     * 
     * @type {number}
     * @memberof N111CoBorrower
     */
    'coBorrowerId': number;
    /**
     * 
     * @type {string}
     * @memberof N111CoBorrower
     */
    'firstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof N111CoBorrower
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof N111CoBorrower
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof N111CoBorrower
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N111CoBorrower
     */
    'relationshipManager'?: IdNameEntry;
    /**
     * 
     * @type {CustomerVerificationStatus}
     * @memberof N111CoBorrower
     */
    'verificationStatus': CustomerVerificationStatus;
}


/**
 * 
 * @export
 * @interface N111Task
 */
export interface N111Task extends Task {
    /**
     * 
     * @type {Array<N111CoBorrower>}
     * @memberof N111Task
     */
    'coBorrowers': Array<N111CoBorrower>;
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N111Task
     */
    'collaterals': Array<IdEntry>;
    /**
     * 
     * @type {number}
     * @memberof N111Task
     */
    'commission': number;
    /**
     * 
     * @type {IdEntry}
     * @memberof N111Task
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof N111Task
     */
    'invoiceAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof N111Task
     */
    'invoicePreviousAmount'?: number;
    /**
     * 
     * @type {IdEntry}
     * @memberof N111Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N111Task
     */
    'product': IdNameEntry;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof N111Task
     */
    'relationshipManagerConditions': PledgedLoanApplicationConditions;
}
/**
 * 
 * @export
 * @interface N111TaskDecisionApprove
 */
export interface N111TaskDecisionApprove extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N111TaskDecisionApprove
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface N111TaskDecisionDecline
 */
export interface N111TaskDecisionDecline extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N111TaskDecisionDecline
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N111TaskDecisionRequestAdjustments
 */
export interface N111TaskDecisionRequestAdjustments extends TaskDecision {
}
/**
 * 
 * @export
 * @interface N112Task
 */
export interface N112Task extends Task {
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N112Task
     */
    'collaterals': Array<IdEntry>;
    /**
     * 
     * @type {IdEntry}
     * @memberof N112Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {Array<BoUserAssigneeEntry>}
     * @memberof N112Task
     */
    'users': Array<BoUserAssigneeEntry>;
}
/**
 * 
 * @export
 * @interface N112TaskDecisionChooseUser
 */
export interface N112TaskDecisionChooseUser extends TaskDecision {
    /**
     * 
     * @type {number}
     * @memberof N112TaskDecisionChooseUser
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface N113Task
 */
export interface N113Task extends Task {
    /**
     * 
     * @type {string}
     * @memberof N113Task
     */
    'bankAccountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof N113Task
     */
    'bankAccountNumber'?: string;
    /**
     * 
     * @type {BankInformationDto}
     * @memberof N113Task
     */
    'bankInformation'?: BankInformationDto;
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N113Task
     */
    'collaterals': Array<IdEntry>;
    /**
     * 
     * @type {number}
     * @memberof N113Task
     */
    'commission': number;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof N113Task
     */
    'creditCommitteeConditions'?: PledgedLoanApplicationConditions;
    /**
     * 
     * @type {IdEntry}
     * @memberof N113Task
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N113Task
     */
    'individual': IdNameEntry;
    /**
     * 
     * @type {IdEntry}
     * @memberof N113Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N113Task
     */
    'product': IdNameEntry;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof N113Task
     */
    'relationshipManagerConditions': PledgedLoanApplicationConditions;
}
/**
 * 
 * @export
 * @interface N113TaskDecisionApprove
 */
export interface N113TaskDecisionApprove extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N113TaskDecisionApprove
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface N113TaskDecisionRequestAdjustments
 */
export interface N113TaskDecisionRequestAdjustments extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N113TaskDecisionRequestAdjustments
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N114TaskDecisionCancel
 */
export interface N114TaskDecisionCancel extends TaskDecision {
}
/**
 * 
 * @export
 * @interface N11Collateral
 */
export interface N11Collateral {
    /**
     * 
     * @type {number}
     * @memberof N11Collateral
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof N11Collateral
     */
    'marketValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof N11Collateral
     */
    'mutuallyAgreedValuation'?: number;
    /**
     * 
     * @type {number}
     * @memberof N11Collateral
     */
    'rmValue'?: number;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof N11Collateral
     */
    'subType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof N11Collateral
     */
    'type': CollateralType;
}


/**
 * 
 * @export
 * @interface N11CollateralDecision
 */
export interface N11CollateralDecision {
    /**
     * 
     * @type {number}
     * @memberof N11CollateralDecision
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof N11CollateralDecision
     */
    'marketValue': number;
    /**
     * 
     * @type {number}
     * @memberof N11CollateralDecision
     */
    'mutuallyAgreedValuation': number;
    /**
     * 
     * @type {number}
     * @memberof N11CollateralDecision
     */
    'rmValue': number;
}
/**
 * 
 * @export
 * @interface N11Task
 */
export interface N11Task extends Task {
    /**
     * 
     * @type {Array<N11Collateral>}
     * @memberof N11Task
     */
    'collaterals': Array<N11Collateral>;
    /**
     * 
     * @type {IdEntry}
     * @memberof N11Task
     */
    'loanApplication': IdEntry;
}
/**
 * 
 * @export
 * @interface N11TaskDecisionApprove
 */
export interface N11TaskDecisionApprove extends TaskDecision {
    /**
     * 
     * @type {Array<N11CollateralDecision>}
     * @memberof N11TaskDecisionApprove
     */
    'collaterals': Array<N11CollateralDecision>;
}
/**
 * 
 * @export
 * @interface N11TaskDecisionDecline
 */
export interface N11TaskDecisionDecline extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N11TaskDecisionDecline
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N12Task
 */
export interface N12Task extends Task {
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N12Task
     */
    'collaterals': Array<IdEntry>;
    /**
     * 
     * @type {IdEntry}
     * @memberof N12Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {Array<BoUserAssigneeEntry>}
     * @memberof N12Task
     */
    'users': Array<BoUserAssigneeEntry>;
}
/**
 * 
 * @export
 * @interface N12TaskDecisionChooseUser
 */
export interface N12TaskDecisionChooseUser extends TaskDecision {
    /**
     * 
     * @type {number}
     * @memberof N12TaskDecisionChooseUser
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface N13Collateral
 */
export interface N13Collateral {
    /**
     * 
     * @type {IdEntry}
     * @memberof N13Collateral
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof N13Collateral
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof N13Collateral
     */
    'importedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof N13Collateral
     */
    'marketValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof N13Collateral
     */
    'mutuallyAgreedValuation'?: number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N13Collateral
     */
    'owner': IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof N13Collateral
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof N13Collateral
     */
    'rmValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof N13Collateral
     */
    'sizeMark'?: string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof N13Collateral
     */
    'subType': CollateralSubType;
}


/**
 * 
 * @export
 * @interface N13IndividualCoBorrower
 */
export interface N13IndividualCoBorrower {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof N13IndividualCoBorrower
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof N13IndividualCoBorrower
     */
    'coBorrowerId': number;
    /**
     * 
     * @type {string}
     * @memberof N13IndividualCoBorrower
     */
    'firstName': string;
    /**
     * 
     * @type {number}
     * @memberof N13IndividualCoBorrower
     */
    'id': number;
    /**
     * 
     * @type {CustomerKycLevel}
     * @memberof N13IndividualCoBorrower
     */
    'kycLevel': CustomerKycLevel;
    /**
     * 
     * @type {string}
     * @memberof N13IndividualCoBorrower
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof N13IndividualCoBorrower
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof N13IndividualCoBorrower
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof N13IndividualCoBorrower
     */
    'relationshipType': string;
    /**
     * 
     * @type {CoBorrowerStatus}
     * @memberof N13IndividualCoBorrower
     */
    'status': CoBorrowerStatus;
}


/**
 * 
 * @export
 * @interface N13LegalCoBorrower
 */
export interface N13LegalCoBorrower {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof N13LegalCoBorrower
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N13LegalCoBorrower
     */
    'ceo'?: IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof N13LegalCoBorrower
     */
    'coBorrowerId': number;
    /**
     * 
     * @type {number}
     * @memberof N13LegalCoBorrower
     */
    'id': number;
    /**
     * 
     * @type {IdLocalizationEntry}
     * @memberof N13LegalCoBorrower
     */
    'industry': IdLocalizationEntry;
    /**
     * 
     * @type {string}
     * @memberof N13LegalCoBorrower
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof N13LegalCoBorrower
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof N13LegalCoBorrower
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof N13LegalCoBorrower
     */
    'relationshipType'?: string;
    /**
     * 
     * @type {CoBorrowerStatus}
     * @memberof N13LegalCoBorrower
     */
    'status': CoBorrowerStatus;
}


/**
 * 
 * @export
 * @interface N13Task
 */
export interface N13Task extends Task {
    /**
     * 
     * @type {number}
     * @memberof N13Task
     */
    'amount': number;
    /**
     * 
     * @type {Array<N13Collateral>}
     * @memberof N13Task
     */
    'collaterals': Array<N13Collateral>;
    /**
     * 
     * @type {string}
     * @memberof N13Task
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof N13Task
     */
    'csComment'?: string;
    /**
     * 
     * @type {IdEntry}
     * @memberof N13Task
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof N13Task
     */
    'estimatedCoBorrowersDti'?: number;
    /**
     * 
     * @type {number}
     * @memberof N13Task
     */
    'estimatedFirstRepaymentAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof N13Task
     */
    'estimatedIndividualDti'?: number;
    /**
     * 
     * @type {string}
     * @memberof N13Task
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {Array<N13IndividualCoBorrower>}
     * @memberof N13Task
     */
    'individualCoBorrowers': Array<N13IndividualCoBorrower>;
    /**
     * 
     * @type {string}
     * @memberof N13Task
     */
    'laatLeaderComment'?: string;
    /**
     * 
     * @type {Array<N13LegalCoBorrower>}
     * @memberof N13Task
     */
    'legalCoBorrowers': Array<N13LegalCoBorrower>;
    /**
     * 
     * @type {IdEntry}
     * @memberof N13Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof N13Task
     */
    'loanPurposeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof N13Task
     */
    'loanSubPurposeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof N13Task
     */
    'monthlyInterestRate': number;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof N13Task
     */
    'pledgedProducts': Array<IdNameEntry>;
    /**
     * 
     * @type {number}
     * @memberof N13Task
     */
    'productId'?: number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof N13Task
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof N13Task
     */
    'requestedConditions': PledgedLoanApplicationConditions;
    /**
     * 
     * @type {string}
     * @memberof N13Task
     */
    'requestedLoanPurpose': string;
    /**
     * 
     * @type {string}
     * @memberof N13Task
     */
    'secondRepaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof N13Task
     */
    'term': number;
}


/**
 * 
 * @export
 * @interface N13TaskDecisionAddLoanConditions
 */
export interface N13TaskDecisionAddLoanConditions extends TaskDecision {
    /**
     * 
     * @type {number}
     * @memberof N13TaskDecisionAddLoanConditions
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof N13TaskDecisionAddLoanConditions
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof N13TaskDecisionAddLoanConditions
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof N13TaskDecisionAddLoanConditions
     */
    'loanSubPurposeId': number;
    /**
     * 
     * @type {number}
     * @memberof N13TaskDecisionAddLoanConditions
     */
    'monthlyInterestRate': number;
    /**
     * 
     * @type {number}
     * @memberof N13TaskDecisionAddLoanConditions
     */
    'productId': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof N13TaskDecisionAddLoanConditions
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {number}
     * @memberof N13TaskDecisionAddLoanConditions
     */
    'requestedTerm': number;
    /**
     * 
     * @type {string}
     * @memberof N13TaskDecisionAddLoanConditions
     */
    'secondRepaymentDate'?: string;
}


/**
 * 
 * @export
 * @interface N14Task
 */
export interface N14Task extends Task {
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N14Task
     */
    'collaterals': Array<IdEntry>;
    /**
     * 
     * @type {IdEntry}
     * @memberof N14Task
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof N14Task
     */
    'estimatedCoBorrowersDti': number;
    /**
     * 
     * @type {number}
     * @memberof N14Task
     */
    'estimatedFirstRepaymentAmount': number;
    /**
     * 
     * @type {number}
     * @memberof N14Task
     */
    'estimatedIndividualDti': number;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof N14Task
     */
    'laatUserConditions': PledgedLoanApplicationConditions;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N14Task
     */
    'laatUserProduct': IdNameEntry;
    /**
     * 
     * @type {IdEntry}
     * @memberof N14Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof N14Task
     */
    'requestedConditions': PledgedLoanApplicationConditions;
    /**
     * 
     * @type {string}
     * @memberof N14Task
     */
    'requestedLoanPurpose': string;
}
/**
 * 
 * @export
 * @interface N14TaskDecisionApprove
 */
export interface N14TaskDecisionApprove extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N14TaskDecisionApprove
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface N14TaskDecisionDecline
 */
export interface N14TaskDecisionDecline extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N14TaskDecisionDecline
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N14TaskDecisionRequestAdjustments
 */
export interface N14TaskDecisionRequestAdjustments extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N14TaskDecisionRequestAdjustments
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N15Task
 */
export interface N15Task extends Task {
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N15Task
     */
    'collaterals': Array<IdEntry>;
    /**
     * 
     * @type {IdEntry}
     * @memberof N15Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {Array<BoUserAssigneeEntry>}
     * @memberof N15Task
     */
    'users': Array<BoUserAssigneeEntry>;
}
/**
 * 
 * @export
 * @interface N15TaskDecisionChooseUser
 */
export interface N15TaskDecisionChooseUser extends TaskDecision {
    /**
     * 
     * @type {Array<number>}
     * @memberof N15TaskDecisionChooseUser
     */
    'userIds': Array<number>;
}
/**
 * 
 * @export
 * @interface N16Task
 */
export interface N16Task extends Task {
    /**
     * 
     * @type {number}
     * @memberof N16Task
     */
    'amount': number;
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N16Task
     */
    'collaterals': Array<IdEntry>;
    /**
     * 
     * @type {string}
     * @memberof N16Task
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof N16Task
     */
    'laatLeaderComment'?: string;
    /**
     * 
     * @type {string}
     * @memberof N16Task
     */
    'laatUserComment'?: string;
    /**
     * 
     * @type {IdEntry}
     * @memberof N16Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof N16Task
     */
    'monthlyInterestRate': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof N16Task
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {string}
     * @memberof N16Task
     */
    'secondRepaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof N16Task
     */
    'term': number;
}


/**
 * 
 * @export
 * @interface N16TaskDecisionAddLoanConditions
 */
export interface N16TaskDecisionAddLoanConditions extends TaskDecision {
    /**
     * 
     * @type {number}
     * @memberof N16TaskDecisionAddLoanConditions
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof N16TaskDecisionAddLoanConditions
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof N16TaskDecisionAddLoanConditions
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof N16TaskDecisionAddLoanConditions
     */
    'monthlyInterestRate': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof N16TaskDecisionAddLoanConditions
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {number}
     * @memberof N16TaskDecisionAddLoanConditions
     */
    'requestedTerm': number;
    /**
     * 
     * @type {string}
     * @memberof N16TaskDecisionAddLoanConditions
     */
    'secondRepaymentDate'?: string;
}


/**
 * 
 * @export
 * @interface N16TaskDecisionDecline
 */
export interface N16TaskDecisionDecline extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N16TaskDecisionDecline
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N17Task
 */
export interface N17Task extends Task {
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N17Task
     */
    'collaterals': Array<IdEntry>;
    /**
     * 
     * @type {IdEntry}
     * @memberof N17Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {Array<BoUserAssigneeEntry>}
     * @memberof N17Task
     */
    'users': Array<BoUserAssigneeEntry>;
}
/**
 * 
 * @export
 * @interface N17TaskDecisionCancel
 */
export interface N17TaskDecisionCancel extends TaskDecision {
}
/**
 * 
 * @export
 * @interface N17TaskDecisionChooseUser
 */
export interface N17TaskDecisionChooseUser extends TaskDecision {
    /**
     * 
     * @type {number}
     * @memberof N17TaskDecisionChooseUser
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface N18Task
 */
export interface N18Task extends Task {
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N18Task
     */
    'collaterals': Array<IdEntry>;
    /**
     * 
     * @type {Array<CreditCommitteeDecisionListEntry>}
     * @memberof N18Task
     */
    'dmtUserConditions': Array<CreditCommitteeDecisionListEntry>;
    /**
     * 
     * @type {number}
     * @memberof N18Task
     */
    'estimatedCoBorrowersDti': number;
    /**
     * 
     * @type {number}
     * @memberof N18Task
     */
    'estimatedFirstRepaymentAmount': number;
    /**
     * 
     * @type {number}
     * @memberof N18Task
     */
    'estimatedIndividualDti': number;
    /**
     * 
     * @type {string}
     * @memberof N18Task
     */
    'laatLeaderComment'?: string;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof N18Task
     */
    'laatUserConditions': PledgedLoanApplicationConditions;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N18Task
     */
    'laatUserProduct': IdNameEntry;
    /**
     * 
     * @type {IdEntry}
     * @memberof N18Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof N18Task
     */
    'requestedConditions': PledgedLoanApplicationConditions;
    /**
     * 
     * @type {string}
     * @memberof N18Task
     */
    'requestedLoanPurpose': string;
}
/**
 * 
 * @export
 * @interface N18TaskDecisionAddLoanConditions
 */
export interface N18TaskDecisionAddLoanConditions extends TaskDecision {
    /**
     * 
     * @type {number}
     * @memberof N18TaskDecisionAddLoanConditions
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof N18TaskDecisionAddLoanConditions
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof N18TaskDecisionAddLoanConditions
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof N18TaskDecisionAddLoanConditions
     */
    'monthlyInterestRate': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof N18TaskDecisionAddLoanConditions
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {number}
     * @memberof N18TaskDecisionAddLoanConditions
     */
    'requestedTerm': number;
    /**
     * 
     * @type {string}
     * @memberof N18TaskDecisionAddLoanConditions
     */
    'secondRepaymentDate'?: string;
}


/**
 * 
 * @export
 * @interface N18TaskDecisionDecline
 */
export interface N18TaskDecisionDecline extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N18TaskDecisionDecline
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N18TaskDecisionRequestAdjustments
 */
export interface N18TaskDecisionRequestAdjustments extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N18TaskDecisionRequestAdjustments
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N19Task
 */
export interface N19Task extends Task {
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N19Task
     */
    'collaterals': Array<IdEntry>;
    /**
     * 
     * @type {IdEntry}
     * @memberof N19Task
     */
    'loanApplication': IdEntry;
    /**
     * 
     * @type {Array<BoUserAssigneeEntry>}
     * @memberof N19Task
     */
    'users': Array<BoUserAssigneeEntry>;
}
/**
 * 
 * @export
 * @interface N19TaskDecisionChooseUser
 */
export interface N19TaskDecisionChooseUser extends TaskDecision {
    /**
     * 
     * @type {number}
     * @memberof N19TaskDecisionChooseUser
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface N21Task
 */
export interface N21Task extends Task {
    /**
     * 
     * @type {IdEntry}
     * @memberof N21Task
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof N21Task
     */
    'feeAmount': number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N21Task
     */
    'iatSenior'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof N21Task
     */
    'iatSeniorComment'?: string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N21Task
     */
    'iatUser'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof N21Task
     */
    'iatUserComment'?: string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N21Task
     */
    'individual': IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof N21Task
     */
    'registrationNumber': string;
}
/**
 * 
 * @export
 * @interface N21TaskDecisionApprove
 */
export interface N21TaskDecisionApprove extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N21TaskDecisionApprove
     */
    'bankStatementQualification': string;
    /**
     * 
     * @type {string}
     * @memberof N21TaskDecisionApprove
     */
    'bankStatementStatus': string;
    /**
     * 
     * @type {string}
     * @memberof N21TaskDecisionApprove
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof N21TaskDecisionApprove
     */
    'located': string;
}
/**
 * 
 * @export
 * @interface N21TaskDecisionDecline
 */
export interface N21TaskDecisionDecline extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N21TaskDecisionDecline
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N22Task
 */
export interface N22Task extends Task {
    /**
     * 
     * @type {string}
     * @memberof N22Task
     */
    'bankStatementQualification': string;
    /**
     * 
     * @type {string}
     * @memberof N22Task
     */
    'bankStatementStatus': string;
    /**
     * 
     * @type {IdEntry}
     * @memberof N22Task
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof N22Task
     */
    'feeAmount': number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N22Task
     */
    'iatSenior'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof N22Task
     */
    'iatSeniorComment'?: string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N22Task
     */
    'individual': IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof N22Task
     */
    'located': string;
    /**
     * 
     * @type {string}
     * @memberof N22Task
     */
    'registrationNumber': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N22Task
     */
    'socUser': IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof N22Task
     */
    'socUserComment'?: string;
}
/**
 * 
 * @export
 * @interface N22TaskDecisionApprove
 */
export interface N22TaskDecisionApprove extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N22TaskDecisionApprove
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface N22TaskDecisionRequestAdjustments
 */
export interface N22TaskDecisionRequestAdjustments extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N22TaskDecisionRequestAdjustments
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N23Task
 */
export interface N23Task extends Task {
    /**
     * 
     * @type {string}
     * @memberof N23Task
     */
    'bankStatementQualification': string;
    /**
     * 
     * @type {string}
     * @memberof N23Task
     */
    'bankStatementStatus': string;
    /**
     * 
     * @type {number}
     * @memberof N23Task
     */
    'feeAmount': number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N23Task
     */
    'iatUser': IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof N23Task
     */
    'iatUserComment'?: string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N23Task
     */
    'individual': IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof N23Task
     */
    'located': string;
    /**
     * 
     * @type {string}
     * @memberof N23Task
     */
    'registrationNumber': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N23Task
     */
    'socUser': IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof N23Task
     */
    'socUserComment'?: string;
}
/**
 * 
 * @export
 * @interface N23TaskDecisionApprove
 */
export interface N23TaskDecisionApprove extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N23TaskDecisionApprove
     */
    'bankAccount': string;
    /**
     * 
     * @type {string}
     * @memberof N23TaskDecisionApprove
     */
    'bankCode': string;
    /**
     * 
     * @type {string}
     * @memberof N23TaskDecisionApprove
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof N23TaskDecisionApprove
     */
    'continuousEmploymentInMonths': number;
    /**
     * 
     * @type {Currency}
     * @memberof N23TaskDecisionApprove
     */
    'currency': Currency;
    /**
     * 
     * @type {boolean}
     * @memberof N23TaskDecisionApprove
     */
    'dropAvgLast2Months': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof N23TaskDecisionApprove
     */
    'incomeAmountEnough': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof N23TaskDecisionApprove
     */
    'incomeAmountEnoughForLast2Months': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof N23TaskDecisionApprove
     */
    'incomeAmountStable': boolean;
    /**
     * 
     * @type {number}
     * @memberof N23TaskDecisionApprove
     */
    'monthlyIncome': number;
    /**
     * 
     * @type {number}
     * @memberof N23TaskDecisionApprove
     */
    'workingMonthsForLast2Years': number;
}


/**
 * 
 * @export
 * @interface N23TaskDecisionDecline
 */
export interface N23TaskDecisionDecline extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N23TaskDecisionDecline
     */
    'bankAccount': string;
    /**
     * 
     * @type {string}
     * @memberof N23TaskDecisionDecline
     */
    'bankCode': string;
    /**
     * 
     * @type {string}
     * @memberof N23TaskDecisionDecline
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof N23TaskDecisionDecline
     */
    'continuousEmploymentInMonths': number;
    /**
     * 
     * @type {Currency}
     * @memberof N23TaskDecisionDecline
     */
    'currency': Currency;
    /**
     * 
     * @type {boolean}
     * @memberof N23TaskDecisionDecline
     */
    'dropAvgLast2Months': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof N23TaskDecisionDecline
     */
    'incomeAmountEnough': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof N23TaskDecisionDecline
     */
    'incomeAmountEnoughForLast2Months': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof N23TaskDecisionDecline
     */
    'incomeAmountStable': boolean;
    /**
     * 
     * @type {number}
     * @memberof N23TaskDecisionDecline
     */
    'monthlyIncome': number;
    /**
     * 
     * @type {number}
     * @memberof N23TaskDecisionDecline
     */
    'workingMonthsForLast2Years': number;
}


/**
 * 
 * @export
 * @interface N23TaskDecisionRequestAdjustments
 */
export interface N23TaskDecisionRequestAdjustments extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N23TaskDecisionRequestAdjustments
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface N24Task
 */
export interface N24Task extends Task {
    /**
     * 
     * @type {IdEntry}
     * @memberof N24Task
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof N24Task
     */
    'feeAmount': number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N24Task
     */
    'iatSenior'?: IdNameEntry;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N24Task
     */
    'iatUser'?: IdNameEntry;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N24Task
     */
    'individual': IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof N24Task
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof N24Task
     */
    'registrationNumber': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof N24Task
     */
    'socUser'?: IdNameEntry;
}
/**
 * 
 * @export
 * @interface N24TaskDecisionApprove
 */
export interface N24TaskDecisionApprove extends TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof N24TaskDecisionApprove
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof N24TaskDecisionApprove
     */
    'refundDate': string;
}
/**
 * 
 * @export
 * @interface N31TaskDecisionCancel
 */
export interface N31TaskDecisionCancel extends TaskDecision {
}
/**
 * 
 * @export
 * @interface N31TaskDecisionSign
 */
export interface N31TaskDecisionSign extends TaskDecision {
}
/**
 * 
 * @export
 * @interface N41Task
 */
export interface N41Task extends Task {
    /**
     * 
     * @type {Array<IdEntry>}
     * @memberof N41Task
     */
    'collaterals'?: Array<IdEntry>;
    /**
     * 
     * @type {IdEntry}
     * @memberof N41Task
     */
    'customer': IdEntry;
    /**
     * 
     * @type {IdEntry}
     * @memberof N41Task
     */
    'loanApplication'?: IdEntry;
    /**
     * 
     * @type {TaskName}
     * @memberof N41Task
     */
    'targetTaskName': TaskName;
    /**
     * 
     * @type {Array<BoUserAssigneeEntry>}
     * @memberof N41Task
     */
    'users': Array<BoUserAssigneeEntry>;
}


/**
 * 
 * @export
 * @interface N41TaskDecisionChooseUser
 */
export interface N41TaskDecisionChooseUser extends TaskDecision {
    /**
     * 
     * @type {number}
     * @memberof N41TaskDecisionChooseUser
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface N51TaskDecisionCancel
 */
export interface N51TaskDecisionCancel extends TaskDecision {
}
/**
 * 
 * @export
 * @interface N51TaskDecisionSign
 */
export interface N51TaskDecisionSign extends TaskDecision {
}
/**
 * 
 * @export
 * @interface N52TaskDecisionCoBorrowerCancel
 */
export interface N52TaskDecisionCoBorrowerCancel extends TaskDecision {
}
/**
 * 
 * @export
 * @interface N52TaskDecisionCoBorrowerSign
 */
export interface N52TaskDecisionCoBorrowerSign extends TaskDecision {
}
/**
 * 
 * @export
 * @interface NewAdditionalLoanIssuingProposal
 */
export interface NewAdditionalLoanIssuingProposal {
    /**
     * 
     * @type {number}
     * @memberof NewAdditionalLoanIssuingProposal
     */
    'additionalPrincipalDebtAmount': number;
    /**
     * 
     * @type {string}
     * @memberof NewAdditionalLoanIssuingProposal
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof NewAdditionalLoanIssuingProposal
     */
    'repaymentsQuantity': number;
    /**
     * 
     * @type {string}
     * @memberof NewAdditionalLoanIssuingProposal
     */
    'secondRepaymentDate'?: string;
}
/**
 * 
 * @export
 * @interface NewApplicationCoBorrower
 */
export interface NewApplicationCoBorrower extends NewCoBorrower {
    /**
     * 
     * @type {number}
     * @memberof NewApplicationCoBorrower
     */
    'applicationId': number;
    /**
     * 
     * @type {number}
     * @memberof NewApplicationCoBorrower
     */
    'coBorrowerId': number;
}
/**
 * 
 * @export
 * @interface NewAutoAssignDebtCollectionProcess
 */
export interface NewAutoAssignDebtCollectionProcess extends NewProcess {
}
/**
 * 
 * @export
 * @interface NewBnplProduct
 */
export interface NewBnplProduct extends NewProduct {
    /**
     * 
     * @type {boolean}
     * @memberof NewBnplProduct
     */
    'excludedFromOffer': boolean;
    /**
     * 
     * @type {number}
     * @memberof NewBnplProduct
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof NewBnplProduct
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof NewBnplProduct
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof NewBnplProduct
     */
    'reOpeningProductLineId': number;
    /**
     * 
     * @type {number}
     * @memberof NewBnplProduct
     */
    'reOpeningRepaymentsQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof NewBnplProduct
     */
    'transferringFee': number;
}
/**
 * 
 * @export
 * @interface NewBnplReopenProcess
 */
export interface NewBnplReopenProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewBnplReopenProcess
     */
    'loanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof NewBnplReopenProcess
     */
    'reopenedLoanId': number;
}
/**
 * 
 * @export
 * @interface NewBoUserDeactivationProcess
 */
export interface NewBoUserDeactivationProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewBoUserDeactivationProcess
     */
    'boUserId': number;
}
/**
 * 
 * @export
 * @interface NewBusinessIncomeAssessmentProcess
 */
export interface NewBusinessIncomeAssessmentProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewBusinessIncomeAssessmentProcess
     */
    'businessIncomeFeeId': number;
    /**
     * 
     * @type {number}
     * @memberof NewBusinessIncomeAssessmentProcess
     */
    'individualId': number;
}
/**
 * 
 * @export
 * @interface NewCancelMerchantInvoiceProcess
 */
export interface NewCancelMerchantInvoiceProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewCancelMerchantInvoiceProcess
     */
    'invoiceId': number;
}
/**
 * 
 * @export
 * @interface NewCarCollateral
 */
export interface NewCarCollateral extends NewCollateral {
    /**
     * 
     * @type {string}
     * @memberof NewCarCollateral
     */
    'carBrand'?: string;
    /**
     * 
     * @type {CarCollateralCategory}
     * @memberof NewCarCollateral
     */
    'category'?: CarCollateralCategory;
    /**
     * 
     * @type {string}
     * @memberof NewCarCollateral
     */
    'chassisNumber'?: string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof NewCarCollateral
     */
    'collateralSubType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof NewCarCollateral
     */
    'collateralType': CollateralType;
    /**
     * 
     * @type {string}
     * @memberof NewCarCollateral
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewCarCollateral
     */
    'dateOfManufacture'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewCarCollateral
     */
    'engineCapacity'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewCarCollateral
     */
    'engineType'?: string;
    /**
     * 
     * @type {NewEvaluation}
     * @memberof NewCarCollateral
     */
    'evaluation': NewEvaluation;
    /**
     * 
     * @type {string}
     * @memberof NewCarCollateral
     */
    'importedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewCarCollateral
     */
    'mileage'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewCarCollateral
     */
    'model'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCarCollateral
     */
    'overallGrade'?: string;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof NewCarCollateral
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof NewCarCollateral
     */
    'registrationNumber': string;
    /**
     * 
     * @type {SteeringWheel}
     * @memberof NewCarCollateral
     */
    'steeringWheel'?: SteeringWheel;
}


/**
 * 
 * @export
 * @interface NewChildBranch
 */
export interface NewChildBranch {
    /**
     * 
     * @type {string}
     * @memberof NewChildBranch
     */
    'activityAreas': string;
    /**
     * 
     * @type {AddressUpdate}
     * @memberof NewChildBranch
     */
    'address': AddressUpdate;
    /**
     * 
     * @type {string}
     * @memberof NewChildBranch
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof NewChildBranch
     */
    'logoFileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewChildBranch
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof NewChildBranch
     */
    'parentBranchId': number;
    /**
     * 
     * @type {string}
     * @memberof NewChildBranch
     */
    'phone': string;
    /**
     * 
     * @type {WorkSchedule}
     * @memberof NewChildBranch
     */
    'workSchedule': WorkSchedule;
}
/**
 * 
 * @export
 * @interface NewCibLoanReportProcess
 */
export interface NewCibLoanReportProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewCibLoanReportProcess
     */
    'coBorrowerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewCibLoanReportProcess
     */
    'collateralId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewCibLoanReportProcess
     */
    'loanId': number;
    /**
     * 
     * @type {number}
     * @memberof NewCibLoanReportProcess
     */
    'operationId'?: number;
    /**
     * 
     * @type {CreditBureauTriggerType}
     * @memberof NewCibLoanReportProcess
     */
    'triggerType': CreditBureauTriggerType;
}


/**
 * 
 * @export
 * @interface NewCibStatusDecreasedProcess
 */
export interface NewCibStatusDecreasedProcess extends NewProcess {
    /**
     * 
     * @type {CreditBureauStatus}
     * @memberof NewCibStatusDecreasedProcess
     */
    'cibStatus': CreditBureauStatus;
    /**
     * 
     * @type {number}
     * @memberof NewCibStatusDecreasedProcess
     */
    'loanId': number;
}


/**
 * 
 * @export
 * @interface NewCibStatusWillDecreaseProcess
 */
export interface NewCibStatusWillDecreaseProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewCibStatusWillDecreaseProcess
     */
    'loanId': number;
}
/**
 * 
 * @export
 * @interface NewCivilServant
 */
export interface NewCivilServant {
    /**
     * 
     * @type {string}
     * @memberof NewCivilServant
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NewCivilServant
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof NewCivilServant
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof NewCivilServant
     */
    'position': string;
    /**
     * 
     * @type {string}
     * @memberof NewCivilServant
     */
    'workAddress': string;
    /**
     * 
     * @type {string}
     * @memberof NewCivilServant
     */
    'workPhoneNumber': string;
}
/**
 * 
 * @export
 * @interface NewCoBorrower
 */
export interface NewCoBorrower {
    /**
     * 
     * @type {string}
     * @memberof NewCoBorrower
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface NewCollateral
 */
export interface NewCollateral {
    /**
     * 
     * @type {string}
     * @memberof NewCollateral
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface NewCollateralCoOwner
 */
export interface NewCollateralCoOwner {
    /**
     * 
     * @type {string}
     * @memberof NewCollateralCoOwner
     */
    'civilId': string;
    /**
     * 
     * @type {string}
     * @memberof NewCollateralCoOwner
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof NewCollateralCoOwner
     */
    'fullAddress': string;
    /**
     * 
     * @type {string}
     * @memberof NewCollateralCoOwner
     */
    'lastName': string;
    /**
     * 
     * @type {OwnerType}
     * @memberof NewCollateralCoOwner
     */
    'ownerType': OwnerType;
    /**
     * 
     * @type {string}
     * @memberof NewCollateralCoOwner
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof NewCollateralCoOwner
     */
    'registrationNumber': string;
    /**
     * 
     * @type {ReferenceRelationship}
     * @memberof NewCollateralCoOwner
     */
    'relationship': ReferenceRelationship;
}


/**
 * 
 * @export
 * @interface NewComment
 */
export interface NewComment {
    /**
     * 
     * @type {string}
     * @memberof NewComment
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface NewCustomerGroup
 */
export interface NewCustomerGroup {
    /**
     * 
     * @type {string}
     * @memberof NewCustomerGroup
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof NewCustomerGroup
     */
    'priority': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewCustomerGroup
     */
    'productLineIds': Array<number>;
}
/**
 * 
 * @export
 * @interface NewCustomerGroupCompany
 */
export interface NewCustomerGroupCompany {
    /**
     * 
     * @type {string}
     * @memberof NewCustomerGroupCompany
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface NewDebtCollectionActivity
 */
export interface NewDebtCollectionActivity {
    /**
     * 
     * @type {Array<NewCivilServant>}
     * @memberof NewDebtCollectionActivity
     */
    'civilServants'?: Array<NewCivilServant>;
    /**
     * 
     * @type {CollectionLevel}
     * @memberof NewDebtCollectionActivity
     */
    'collectionLevel': CollectionLevel;
    /**
     * 
     * @type {string}
     * @memberof NewDebtCollectionActivity
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewDebtCollectionActivity
     */
    'commitmentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewDebtCollectionActivity
     */
    'commitmentDate'?: string;
    /**
     * 
     * @type {CollectionActivityContactedPerson}
     * @memberof NewDebtCollectionActivity
     */
    'contactedPerson': CollectionActivityContactedPerson;
    /**
     * 
     * @type {CollectionActivityGeneralType}
     * @memberof NewDebtCollectionActivity
     */
    'generalType': CollectionActivityGeneralType;
    /**
     * 
     * @type {CollectionActivityNextAction}
     * @memberof NewDebtCollectionActivity
     */
    'nextAction': CollectionActivityNextAction;
    /**
     * 
     * @type {string}
     * @memberof NewDebtCollectionActivity
     */
    'nextActionDate': string;
    /**
     * 
     * @type {CollectionActivityOverdueReason}
     * @memberof NewDebtCollectionActivity
     */
    'overdueReason'?: CollectionActivityOverdueReason;
    /**
     * 
     * @type {CollectionActivitySubType}
     * @memberof NewDebtCollectionActivity
     */
    'subType'?: CollectionActivitySubType;
    /**
     * 
     * @type {CollectionActivityType}
     * @memberof NewDebtCollectionActivity
     */
    'type': CollectionActivityType;
}


/**
 * 
 * @export
 * @interface NewDocument
 */
export interface NewDocument {
    /**
     * 
     * @type {DocumentSource}
     * @memberof NewDocument
     */
    'source': DocumentSource;
    /**
     * 
     * @type {DocumentType}
     * @memberof NewDocument
     */
    'type': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof NewDocument
     */
    'uploadedFileId': string;
}


/**
 * 
 * @export
 * @interface NewDocumentTemplate
 */
export interface NewDocumentTemplate {
    /**
     * 
     * @type {DocumentTemplateType}
     * @memberof NewDocumentTemplate
     */
    'type': DocumentTemplateType;
    /**
     * 
     * @type {string}
     * @memberof NewDocumentTemplate
     */
    'uploadedFileId': string;
}


/**
 * 
 * @export
 * @interface NewEquipmentCollateral
 */
export interface NewEquipmentCollateral extends NewCollateral {
    /**
     * 
     * @type {string}
     * @memberof NewEquipmentCollateral
     */
    'brandName'?: string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof NewEquipmentCollateral
     */
    'collateralSubType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof NewEquipmentCollateral
     */
    'collateralType': CollateralType;
    /**
     * 
     * @type {string}
     * @memberof NewEquipmentCollateral
     */
    'country'?: string;
    /**
     * 
     * @type {EquipmentType}
     * @memberof NewEquipmentCollateral
     */
    'equipmentType'?: EquipmentType;
    /**
     * 
     * @type {NewEvaluation}
     * @memberof NewEquipmentCollateral
     */
    'evaluation': NewEvaluation;
    /**
     * 
     * @type {string}
     * @memberof NewEquipmentCollateral
     */
    'importedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEquipmentCollateral
     */
    'manufacturedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEquipmentCollateral
     */
    'model'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEquipmentCollateral
     */
    'name'?: string;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof NewEquipmentCollateral
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof NewEquipmentCollateral
     */
    'serialNumber': string;
}


/**
 * 
 * @export
 * @interface NewEvaluation
 */
export interface NewEvaluation {
    /**
     * 
     * @type {number}
     * @memberof NewEvaluation
     */
    'marketValue': number;
    /**
     * 
     * @type {number}
     * @memberof NewEvaluation
     */
    'mutuallyAgreedValuation': number;
    /**
     * 
     * @type {number}
     * @memberof NewEvaluation
     */
    'rmValue': number;
}
/**
 * 
 * @export
 * @interface NewFileRequest
 */
export interface NewFileRequest {
    /**
     * 
     * @type {string}
     * @memberof NewFileRequest
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface NewGeneratePledgedApplicationAgreementsProcess
 */
export interface NewGeneratePledgedApplicationAgreementsProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewGeneratePledgedApplicationAgreementsProcess
     */
    'loanApplicationId': number;
    /**
     * 
     * @type {number}
     * @memberof NewGeneratePledgedApplicationAgreementsProcess
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface NewIndividualLegalRepresentative
 */
export interface NewIndividualLegalRepresentative extends NewLegalRepresentative {
    /**
     * 
     * @type {number}
     * @memberof NewIndividualLegalRepresentative
     */
    'individualId': number;
}
/**
 * 
 * @export
 * @interface NewIndividualsBlacklistingProcess
 */
export interface NewIndividualsBlacklistingProcess extends NewProcess {
    /**
     * 
     * @type {Array<number>}
     * @memberof NewIndividualsBlacklistingProcess
     */
    'individualIds': Array<number>;
}
/**
 * 
 * @export
 * @interface NewInstallmentProduct
 */
export interface NewInstallmentProduct extends NewProduct {
    /**
     * 
     * @type {number}
     * @memberof NewInstallmentProduct
     */
    'dti': number;
    /**
     * 
     * @type {boolean}
     * @memberof NewInstallmentProduct
     */
    'excludedFromOffer': boolean;
    /**
     * 
     * @type {number}
     * @memberof NewInstallmentProduct
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof NewInstallmentProduct
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof NewInstallmentProduct
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof NewInstallmentProduct
     */
    'name': string;
    /**
     * 
     * @type {PenaltyInterestAccrualMethod}
     * @memberof NewInstallmentProduct
     */
    'penaltyInterestAccrualMethod': PenaltyInterestAccrualMethod;
    /**
     * 
     * @type {number}
     * @memberof NewInstallmentProduct
     */
    'penaltyInterestAccrualStartDay': number;
    /**
     * 
     * @type {number}
     * @memberof NewInstallmentProduct
     */
    'penaltyInterestMultiplier': number;
    /**
     * 
     * @type {RepaymentAllocationRule}
     * @memberof NewInstallmentProduct
     */
    'repaymentAllocationRule': RepaymentAllocationRule;
    /**
     * 
     * @type {RepaymentCalculationMethod}
     * @memberof NewInstallmentProduct
     */
    'repaymentCalculationMethod': RepaymentCalculationMethod;
}


/**
 * 
 * @export
 * @interface NewInsurance
 */
export interface NewInsurance {
    /**
     * 
     * @type {number}
     * @memberof NewInsurance
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof NewInsurance
     */
    'brokerage'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInsurance
     */
    'expireDate': string;
    /**
     * 
     * @type {number}
     * @memberof NewInsurance
     */
    'insuranceCompanyId': number;
    /**
     * 
     * @type {number}
     * @memberof NewInsurance
     */
    'insuranceProductId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewInsurance
     */
    'registeredDate': string;
}
/**
 * 
 * @export
 * @interface NewIntangibleAssetCollateral
 */
export interface NewIntangibleAssetCollateral extends NewCollateral {
    /**
     * 
     * @type {string}
     * @memberof NewIntangibleAssetCollateral
     */
    'additionalField1': string;
    /**
     * 
     * @type {string}
     * @memberof NewIntangibleAssetCollateral
     */
    'additionalField2'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewIntangibleAssetCollateral
     */
    'availableAmount'?: number;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof NewIntangibleAssetCollateral
     */
    'collateralSubType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof NewIntangibleAssetCollateral
     */
    'collateralType': CollateralType;
    /**
     * 
     * @type {NewEvaluation}
     * @memberof NewIntangibleAssetCollateral
     */
    'evaluation': NewEvaluation;
    /**
     * 
     * @type {string}
     * @memberof NewIntangibleAssetCollateral
     */
    'expiresAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewIntangibleAssetCollateral
     */
    'futureIncomeAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewIntangibleAssetCollateral
     */
    'issuedOrganization'?: string;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof NewIntangibleAssetCollateral
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof NewIntangibleAssetCollateral
     */
    'startsAt'?: string;
}


/**
 * 
 * @export
 * @interface NewLeasingCollateral
 */
export interface NewLeasingCollateral extends NewCollateral {
    /**
     * 
     * @type {string}
     * @memberof NewLeasingCollateral
     */
    'assetNumber': string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof NewLeasingCollateral
     */
    'collateralSubType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof NewLeasingCollateral
     */
    'collateralType': CollateralType;
    /**
     * 
     * @type {NewEvaluation}
     * @memberof NewLeasingCollateral
     */
    'evaluation': NewEvaluation;
    /**
     * 
     * @type {boolean}
     * @memberof NewLeasingCollateral
     */
    'hasEcoStatus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewLeasingCollateral
     */
    'itemName'?: string;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof NewLeasingCollateral
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {number}
     * @memberof NewLeasingCollateral
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewLeasingCollateral
     */
    'specifications'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewLeasingCollateral
     */
    'unitPrice'?: number;
}


/**
 * 
 * @export
 * @interface NewLegal
 */
export interface NewLegal {
    /**
     * 
     * @type {number}
     * @memberof NewLegal
     */
    'activityId': number;
    /**
     * 
     * @type {EnterpriseType}
     * @memberof NewLegal
     */
    'enterpriseType': EnterpriseType;
    /**
     * 
     * @type {string}
     * @memberof NewLegal
     */
    'establishedDate': string;
    /**
     * 
     * @type {IncomeSource}
     * @memberof NewLegal
     */
    'incomeSource': IncomeSource;
    /**
     * 
     * @type {InvestmentType}
     * @memberof NewLegal
     */
    'investmentType'?: InvestmentType;
    /**
     * 
     * @type {string}
     * @memberof NewLegal
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof NewLegal
     */
    'numberOfBranches'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewLegal
     */
    'numberOfEmployees'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewLegal
     */
    'rating'?: string;
    /**
     * 
     * @type {RatingOrganisation}
     * @memberof NewLegal
     */
    'ratingOrganisation'?: RatingOrganisation;
    /**
     * 
     * @type {string}
     * @memberof NewLegal
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof NewLegal
     */
    'stateRegistrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof NewLegal
     */
    'taxNumber': string;
}


/**
 * 
 * @export
 * @interface NewLegalRepresentative
 */
export interface NewLegalRepresentative {
    /**
     * 
     * @type {string}
     * @memberof NewLegalRepresentative
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface NewLivestockCollateral
 */
export interface NewLivestockCollateral extends NewCollateral {
    /**
     * 
     * @type {string}
     * @memberof NewLivestockCollateral
     */
    'additionalField': string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof NewLivestockCollateral
     */
    'collateralSubType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof NewLivestockCollateral
     */
    'collateralType': CollateralType;
    /**
     * 
     * @type {NewEvaluation}
     * @memberof NewLivestockCollateral
     */
    'evaluation': NewEvaluation;
    /**
     * 
     * @type {HerderInformation}
     * @memberof NewLivestockCollateral
     */
    'herderInformation': HerderInformation;
    /**
     * 
     * @type {number}
     * @memberof NewLivestockCollateral
     */
    'herdingNumber': number;
    /**
     * 
     * @type {number}
     * @memberof NewLivestockCollateral
     */
    'livestockTotalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof NewLivestockCollateral
     */
    'livestockTotalCount': number;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof NewLivestockCollateral
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {number}
     * @memberof NewLivestockCollateral
     */
    'weightedNumberIntoSheep': number;
}


/**
 * 
 * @export
 * @interface NewLoanApplicationProcess
 */
export interface NewLoanApplicationProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewLoanApplicationProcess
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewLoanApplicationProcess
     */
    'loanApplicationId': number;
}
/**
 * 
 * @export
 * @interface NewLoanCoBorrower
 */
export interface NewLoanCoBorrower extends NewCoBorrower {
    /**
     * 
     * @type {number}
     * @memberof NewLoanCoBorrower
     */
    'coBorrowerId': number;
    /**
     * 
     * @type {number}
     * @memberof NewLoanCoBorrower
     */
    'loanId': number;
}
/**
 * 
 * @export
 * @interface NewLoanFeeProcess
 */
export interface NewLoanFeeProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewLoanFeeProcess
     */
    'loanFeeId': number;
}
/**
 * 
 * @export
 * @interface NewMasterAgreementRequest
 */
export interface NewMasterAgreementRequest extends NewFileRequest {
    /**
     * 
     * @type {number}
     * @memberof NewMasterAgreementRequest
     */
    'individualId': number;
}
/**
 * 
 * @export
 * @interface NewNonExistingIndividualLegalRepresentative
 */
export interface NewNonExistingIndividualLegalRepresentative extends NewLegalRepresentative {
    /**
     * 
     * @type {Citizenship}
     * @memberof NewNonExistingIndividualLegalRepresentative
     */
    'citizenship': Citizenship;
    /**
     * 
     * @type {string}
     * @memberof NewNonExistingIndividualLegalRepresentative
     */
    'civilId': string;
    /**
     * 
     * @type {string}
     * @memberof NewNonExistingIndividualLegalRepresentative
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof NewNonExistingIndividualLegalRepresentative
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof NewNonExistingIndividualLegalRepresentative
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof NewNonExistingIndividualLegalRepresentative
     */
    'pep'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NewNonExistingIndividualLegalRepresentative
     */
    'percentageOfShare'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewNonExistingIndividualLegalRepresentative
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof NewNonExistingIndividualLegalRepresentative
     */
    'registrationNumber': string;
    /**
     * 
     * @type {ReferenceRelationship}
     * @memberof NewNonExistingIndividualLegalRepresentative
     */
    'relationship': ReferenceRelationship;
}


/**
 * 
 * @export
 * @interface NewPayonProduct
 */
export interface NewPayonProduct extends NewProduct {
    /**
     * 
     * @type {number}
     * @memberof NewPayonProduct
     */
    'dti': number;
    /**
     * 
     * @type {boolean}
     * @memberof NewPayonProduct
     */
    'excludedFromOffer': boolean;
    /**
     * 
     * @type {number}
     * @memberof NewPayonProduct
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof NewPayonProduct
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof NewPayonProduct
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof NewPayonProduct
     */
    'name': string;
    /**
     * 
     * @type {PenaltyInterestAccrualMethod}
     * @memberof NewPayonProduct
     */
    'penaltyInterestAccrualMethod': PenaltyInterestAccrualMethod;
    /**
     * 
     * @type {number}
     * @memberof NewPayonProduct
     */
    'penaltyInterestAccrualStartDay': number;
    /**
     * 
     * @type {number}
     * @memberof NewPayonProduct
     */
    'penaltyInterestMultiplier': number;
    /**
     * 
     * @type {RepaymentAllocationRule}
     * @memberof NewPayonProduct
     */
    'repaymentAllocationRule': RepaymentAllocationRule;
    /**
     * 
     * @type {RepaymentCalculationMethod}
     * @memberof NewPayonProduct
     */
    'repaymentCalculationMethod': RepaymentCalculationMethod;
}


/**
 * 
 * @export
 * @interface NewPledgedApplicationAgreementRequest
 */
export interface NewPledgedApplicationAgreementRequest extends NewFileRequest {
    /**
     * 
     * @type {number}
     * @memberof NewPledgedApplicationAgreementRequest
     */
    'loanApplicationId': number;
}
/**
 * 
 * @export
 * @interface NewPledgedLoanApplicationProcess
 */
export interface NewPledgedLoanApplicationProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewPledgedLoanApplicationProcess
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewPledgedLoanApplicationProcess
     */
    'loanApplicationId': number;
}
/**
 * 
 * @export
 * @interface NewPledgedProduct
 */
export interface NewPledgedProduct extends NewProduct {
    /**
     * 
     * @type {number}
     * @memberof NewPledgedProduct
     */
    'disbursementFee': number;
    /**
     * 
     * @type {number}
     * @memberof NewPledgedProduct
     */
    'dti': number;
    /**
     * 
     * @type {boolean}
     * @memberof NewPledgedProduct
     */
    'excludedFromOffer': boolean;
    /**
     * 
     * @type {number}
     * @memberof NewPledgedProduct
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof NewPledgedProduct
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof NewPledgedProduct
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof NewPledgedProduct
     */
    'name': string;
    /**
     * 
     * @type {PenaltyInterestAccrualMethod}
     * @memberof NewPledgedProduct
     */
    'penaltyInterestAccrualMethod': PenaltyInterestAccrualMethod;
    /**
     * 
     * @type {number}
     * @memberof NewPledgedProduct
     */
    'penaltyInterestAccrualStartDay': number;
    /**
     * 
     * @type {number}
     * @memberof NewPledgedProduct
     */
    'penaltyInterestMultiplier': number;
    /**
     * 
     * @type {RepaymentAllocationRule}
     * @memberof NewPledgedProduct
     */
    'repaymentAllocationRule': RepaymentAllocationRule;
    /**
     * 
     * @type {RepaymentCalculationMethod}
     * @memberof NewPledgedProduct
     */
    'repaymentCalculationMethod': RepaymentCalculationMethod;
    /**
     * 
     * @type {ProductSubtype}
     * @memberof NewPledgedProduct
     */
    'subtype': ProductSubtype;
}


/**
 * 
 * @export
 * @interface NewProcess
 */
export interface NewProcess {
    /**
     * 
     * @type {string}
     * @memberof NewProcess
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface NewProduct
 */
export interface NewProduct {
    /**
     * 
     * @type {string}
     * @memberof NewProduct
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface NewRealEstateCollateral
 */
export interface NewRealEstateCollateral extends NewCollateral {
    /**
     * 
     * @type {AddressUpdate}
     * @memberof NewRealEstateCollateral
     */
    'address'?: AddressUpdate;
    /**
     * 
     * @type {number}
     * @memberof NewRealEstateCollateral
     */
    'areaSquare'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewRealEstateCollateral
     */
    'certificateNumber': string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof NewRealEstateCollateral
     */
    'collateralSubType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof NewRealEstateCollateral
     */
    'collateralType': CollateralType;
    /**
     * 
     * @type {string}
     * @memberof NewRealEstateCollateral
     */
    'establishedDate'?: string;
    /**
     * 
     * @type {EstatePurpose}
     * @memberof NewRealEstateCollateral
     */
    'estatePurpose'?: EstatePurpose;
    /**
     * 
     * @type {NewEvaluation}
     * @memberof NewRealEstateCollateral
     */
    'evaluation': NewEvaluation;
    /**
     * 
     * @type {string}
     * @memberof NewRealEstateCollateral
     */
    'governanceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRealEstateCollateral
     */
    'governmentCertificateNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewRealEstateCollateral
     */
    'isPledged'?: boolean;
    /**
     * 
     * @type {NewRealEstateLand}
     * @memberof NewRealEstateCollateral
     */
    'land'?: NewRealEstateLand;
    /**
     * 
     * @type {NumberOfRooms}
     * @memberof NewRealEstateCollateral
     */
    'numberOfRooms'?: NumberOfRooms;
    /**
     * 
     * @type {string}
     * @memberof NewRealEstateCollateral
     */
    'registeredDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRealEstateCollateral
     */
    'registrationNumber'?: string;
}


/**
 * 
 * @export
 * @interface NewRealEstateLand
 */
export interface NewRealEstateLand {
    /**
     * 
     * @type {string}
     * @memberof NewRealEstateLand
     */
    'cadastralNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRealEstateLand
     */
    'certificateEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRealEstateLand
     */
    'certificateNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRealEstateLand
     */
    'certificateStartDate'?: string;
    /**
     * 
     * @type {CoOwnerCount}
     * @memberof NewRealEstateLand
     */
    'coOwnerCount'?: CoOwnerCount;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof NewRealEstateLand
     */
    'collateralOwnershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {number}
     * @memberof NewRealEstateLand
     */
    'landSquare'?: number;
    /**
     * 
     * @type {LandOwnershipType}
     * @memberof NewRealEstateLand
     */
    'ownershipType'?: LandOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof NewRealEstateLand
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {UseOfLand}
     * @memberof NewRealEstateLand
     */
    'useOfLand'?: UseOfLand;
}


/**
 * 
 * @export
 * @interface NewReassignLoanApplicationProcess
 */
export interface NewReassignLoanApplicationProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewReassignLoanApplicationProcess
     */
    'loanApplicationId': number;
    /**
     * 
     * @type {number}
     * @memberof NewReassignLoanApplicationProcess
     */
    'newRelationshipManagerId': number;
}
/**
 * 
 * @export
 * @interface NewRecalculateOfferedBnplProductLimitsProcess
 */
export interface NewRecalculateOfferedBnplProductLimitsProcess extends NewProcess {
}
/**
 * 
 * @export
 * @interface NewTrustPledgedLoanApplicationProcess
 */
export interface NewTrustPledgedLoanApplicationProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewTrustPledgedLoanApplicationProcess
     */
    'loanApplicationId': number;
}
/**
 * 
 * @export
 * @interface NewTrustProduct
 */
export interface NewTrustProduct extends NewProduct {
    /**
     * 
     * @type {boolean}
     * @memberof NewTrustProduct
     */
    'excludedFromOffer': boolean;
    /**
     * 
     * @type {number}
     * @memberof NewTrustProduct
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof NewTrustProduct
     */
    'maxLoanAmountPct': number;
    /**
     * 
     * @type {number}
     * @memberof NewTrustProduct
     */
    'minLoanAmount': number;
    /**
     * 
     * @type {string}
     * @memberof NewTrustProduct
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface NewUnassignBoUsersTasksProcess
 */
export interface NewUnassignBoUsersTasksProcess extends NewProcess {
    /**
     * 
     * @type {Array<number>}
     * @memberof NewUnassignBoUsersTasksProcess
     */
    'boUserIds': Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewUnassignBoUsersTasksProcess
     */
    'lostPermissions': Array<string>;
}
/**
 * 
 * @export
 * @interface NewUpdateMerchantInvoiceProcess
 */
export interface NewUpdateMerchantInvoiceProcess extends NewProcess {
    /**
     * 
     * @type {number}
     * @memberof NewUpdateMerchantInvoiceProcess
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof NewUpdateMerchantInvoiceProcess
     */
    'invoiceId': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NumberOfRooms = {
    N1: 'N1',
    N2: 'N2',
    N3: 'N3',
    N4: 'N4',
    N5: 'N5',
    N6: 'N6',
    MORE_THAN_SIX: 'MORE_THAN_SIX'
} as const;

export type NumberOfRooms = typeof NumberOfRooms[keyof typeof NumberOfRooms];


/**
 * 
 * @export
 * @interface OfferExclusionUpdate
 */
export interface OfferExclusionUpdate extends ProductUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof OfferExclusionUpdate
     */
    'excludedFromOffer': boolean;
}
/**
 * 
 * @export
 * @interface OtherInformationRequest
 */
export interface OtherInformationRequest {
    /**
     * 
     * @type {boolean}
     * @memberof OtherInformationRequest
     */
    'pep': boolean;
    /**
     * 
     * @type {RelationToNbfi}
     * @memberof OtherInformationRequest
     */
    'relationToNbfi': RelationToNbfi;
}


/**
 * 
 * @export
 * @interface OtherInformationTabView
 */
export interface OtherInformationTabView {
    /**
     * 
     * @type {Array<DebtCollectionActionsInner>}
     * @memberof OtherInformationTabView
     */
    'actions': Array<DebtCollectionActionsInner>;
    /**
     * 
     * @type {string}
     * @memberof OtherInformationTabView
     */
    'branchCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherInformationTabView
     */
    'branchManager'?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherInformationTabView
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherInformationTabView
     */
    'createdUser'?: string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof OtherInformationTabView
     */
    'customerGroup'?: IdNameEntry;
    /**
     * 
     * @type {CustomerSegment}
     * @memberof OtherInformationTabView
     */
    'customerSegment'?: CustomerSegment;
    /**
     * 
     * @type {string}
     * @memberof OtherInformationTabView
     */
    'debtCollector'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OtherInformationTabView
     */
    'hasMasterAgreement': boolean;
    /**
     * 
     * @type {CustomerKycLevel}
     * @memberof OtherInformationTabView
     */
    'kycLevel': CustomerKycLevel;
    /**
     * 
     * @type {string}
     * @memberof OtherInformationTabView
     */
    'lastUpdateAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherInformationTabView
     */
    'lastUpdatedUser'?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherInformationTabView
     */
    'maSignedUser'?: string;
    /**
     * 
     * @type {Array<OwnedEstate>}
     * @memberof OtherInformationTabView
     */
    'ownedEstates': Array<OwnedEstate>;
    /**
     * 
     * @type {boolean}
     * @memberof OtherInformationTabView
     */
    'pep': boolean;
    /**
     * 
     * @type {RelationToNbfi}
     * @memberof OtherInformationTabView
     */
    'relationToNbfi': RelationToNbfi;
    /**
     * 
     * @type {string}
     * @memberof OtherInformationTabView
     */
    'relationshipManager'?: string;
    /**
     * 
     * @type {RestrictionListType}
     * @memberof OtherInformationTabView
     */
    'restrictionListType'?: RestrictionListType;
    /**
     * 
     * @type {string}
     * @memberof OtherInformationTabView
     */
    'vatCode'?: string;
}


/**
 * 
 * @export
 * @interface OverdueDaysSettingForm
 */
export interface OverdueDaysSettingForm {
    /**
     * 
     * @type {{ [key: string]: StatusInterval; }}
     * @memberof OverdueDaysSettingForm
     */
    'setting'?: { [key: string]: StatusInterval; };
}
/**
 * 
 * @export
 * @interface OwnedCarEstateScore
 */
export interface OwnedCarEstateScore {
    /**
     * 
     * @type {number}
     * @memberof OwnedCarEstateScore
     */
    'score'?: number;
    /**
     * 
     * @type {FromToPairInteger}
     * @memberof OwnedCarEstateScore
     */
    'yearsUsed'?: FromToPairInteger;
}
/**
 * 
 * @export
 * @interface OwnedCarEstateScoreCustomerResult
 */
export interface OwnedCarEstateScoreCustomerResult {
    /**
     * 
     * @type {string}
     * @memberof OwnedCarEstateScoreCustomerResult
     */
    'customerCarYearsUsed'?: string;
    /**
     * 
     * @type {OwnedCarEstateScore}
     * @memberof OwnedCarEstateScoreCustomerResult
     */
    'ownedCarEstateScoreSetting'?: OwnedCarEstateScore;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OwnedCarEstateScoringColumnKey = {
    YEARS_USED: 'YEARS_USED',
    SCORES: 'SCORES'
} as const;

export type OwnedCarEstateScoringColumnKey = typeof OwnedCarEstateScoringColumnKey[keyof typeof OwnedCarEstateScoringColumnKey];


/**
 * 
 * @export
 * @interface OwnedCarEstateScoringColumnMetadata
 */
export interface OwnedCarEstateScoringColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof OwnedCarEstateScoringColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {OwnedCarEstateScoringColumnKey}
     * @memberof OwnedCarEstateScoringColumnMetadata
     */
    'key'?: OwnedCarEstateScoringColumnKey;
    /**
     * 
     * @type {object}
     * @memberof OwnedCarEstateScoringColumnMetadata
     */
    'payload'?: object;
}


/**
 * 
 * @export
 * @interface OwnedCarEstateScoringRow
 */
export interface OwnedCarEstateScoringRow {
    /**
     * 
     * @type {Array<number>}
     * @memberof OwnedCarEstateScoringRow
     */
    'scores'?: Array<number>;
    /**
     * 
     * @type {FromToPairInteger}
     * @memberof OwnedCarEstateScoringRow
     */
    'yearsUsed'?: FromToPairInteger;
}
/**
 * 
 * @export
 * @interface OwnedCarEstateScoringSettingDto
 */
export interface OwnedCarEstateScoringSettingDto extends Settings {
    /**
     * 
     * @type {Array<OwnedCarEstateScoringColumnMetadata>}
     * @memberof OwnedCarEstateScoringSettingDto
     */
    'columns'?: Array<OwnedCarEstateScoringColumnMetadata>;
    /**
     * 
     * @type {Array<OwnedCarEstateScoringRow>}
     * @memberof OwnedCarEstateScoringSettingDto
     */
    'rows'?: Array<OwnedCarEstateScoringRow>;
}
/**
 * 
 * @export
 * @interface OwnedEstate
 */
export interface OwnedEstate {
    /**
     * 
     * @type {OwnedEstateAssetSubtype}
     * @memberof OwnedEstate
     */
    'ownedEstateSubType'?: OwnedEstateAssetSubtype;
    /**
     * 
     * @type {OwnedEstateAssetType}
     * @memberof OwnedEstate
     */
    'ownedEstateType'?: OwnedEstateAssetType;
    /**
     * 
     * @type {string}
     * @memberof OwnedEstate
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof OwnedEstate
     */
    'size'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const OwnedEstateAssetSubtype = {
    OTHER: 'OTHER',
    LAND: 'LAND',
    LAND_AND_HOUSE: 'LAND_AND_HOUSE',
    RESIDENCE: 'RESIDENCE'
} as const;

export type OwnedEstateAssetSubtype = typeof OwnedEstateAssetSubtype[keyof typeof OwnedEstateAssetSubtype];


/**
 * 
 * @export
 * @enum {string}
 */

export const OwnedEstateAssetType = {
    REAL_ESTATE: 'REAL_ESTATE',
    MOVABLE_PROPERTY: 'MOVABLE_PROPERTY'
} as const;

export type OwnedEstateAssetType = typeof OwnedEstateAssetType[keyof typeof OwnedEstateAssetType];


/**
 * 
 * @export
 * @enum {string}
 */

export const OwnedRealEstate = {
    NO_ESTATE: 'NO_ESTATE',
    OTHER: 'OTHER',
    LAND: 'LAND',
    LAND_AND_HOUSE: 'LAND_AND_HOUSE',
    RESIDENCE: 'RESIDENCE'
} as const;

export type OwnedRealEstate = typeof OwnedRealEstate[keyof typeof OwnedRealEstate];


/**
 * 
 * @export
 * @interface OwnedRealEstateScore
 */
export interface OwnedRealEstateScore {
    /**
     * 
     * @type {OwnedRealEstate}
     * @memberof OwnedRealEstateScore
     */
    'ownedRealEstate'?: OwnedRealEstate;
    /**
     * 
     * @type {number}
     * @memberof OwnedRealEstateScore
     */
    'score'?: number;
}


/**
 * 
 * @export
 * @interface OwnedRealEstateScoreCustomerResult
 */
export interface OwnedRealEstateScoreCustomerResult {
    /**
     * 
     * @type {OwnedRealEstate}
     * @memberof OwnedRealEstateScoreCustomerResult
     */
    'customerOwnedRealEstate'?: OwnedRealEstate;
    /**
     * 
     * @type {OwnedRealEstateScore}
     * @memberof OwnedRealEstateScoreCustomerResult
     */
    'ownedRealEstateScoreSetting'?: OwnedRealEstateScore;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const OwnedRealEstateScoringColumnKey = {
    SCORES: 'SCORES',
    OWNED_REAL_ESTATE: 'OWNED_REAL_ESTATE'
} as const;

export type OwnedRealEstateScoringColumnKey = typeof OwnedRealEstateScoringColumnKey[keyof typeof OwnedRealEstateScoringColumnKey];


/**
 * 
 * @export
 * @interface OwnedRealEstateScoringColumnMetadata
 */
export interface OwnedRealEstateScoringColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof OwnedRealEstateScoringColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {OwnedRealEstateScoringColumnKey}
     * @memberof OwnedRealEstateScoringColumnMetadata
     */
    'key'?: OwnedRealEstateScoringColumnKey;
    /**
     * 
     * @type {object}
     * @memberof OwnedRealEstateScoringColumnMetadata
     */
    'payload'?: object;
}


/**
 * 
 * @export
 * @interface OwnedRealEstateScoringRow
 */
export interface OwnedRealEstateScoringRow {
    /**
     * 
     * @type {OwnedRealEstate}
     * @memberof OwnedRealEstateScoringRow
     */
    'ownedRealEstate'?: OwnedRealEstate;
    /**
     * 
     * @type {Array<number>}
     * @memberof OwnedRealEstateScoringRow
     */
    'scores'?: Array<number>;
}


/**
 * 
 * @export
 * @interface OwnedRealEstateScoringSettingDto
 */
export interface OwnedRealEstateScoringSettingDto extends Settings {
    /**
     * 
     * @type {Array<OwnedRealEstateScoringColumnMetadata>}
     * @memberof OwnedRealEstateScoringSettingDto
     */
    'columns'?: Array<OwnedRealEstateScoringColumnMetadata>;
    /**
     * 
     * @type {Array<OwnedRealEstateScoringRow>}
     * @memberof OwnedRealEstateScoringSettingDto
     */
    'rows'?: Array<OwnedRealEstateScoringRow>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OwnerType = {
    CO_OWNER: 'CO_OWNER',
    OWNER: 'OWNER'
} as const;

export type OwnerType = typeof OwnerType[keyof typeof OwnerType];


/**
 * 
 * @export
 * @interface PageApplicationCollateralListEntry
 */
export interface PageApplicationCollateralListEntry {
    /**
     * 
     * @type {Array<ApplicationCollateralListEntry>}
     * @memberof PageApplicationCollateralListEntry
     */
    'content'?: Array<ApplicationCollateralListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApplicationCollateralListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApplicationCollateralListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApplicationCollateralListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApplicationCollateralListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApplicationCollateralListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageApplicationCollateralListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageApplicationCollateralListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageApplicationCollateralListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageApplicationCollateralListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApplicationCollateralListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageBankStatementListItem
 */
export interface PageBankStatementListItem {
    /**
     * 
     * @type {Array<BankStatementListItem>}
     * @memberof PageBankStatementListItem
     */
    'content'?: Array<BankStatementListItem>;
    /**
     * 
     * @type {boolean}
     * @memberof PageBankStatementListItem
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBankStatementListItem
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBankStatementListItem
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageBankStatementListItem
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBankStatementListItem
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageBankStatementListItem
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageBankStatementListItem
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageBankStatementListItem
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageBankStatementListItem
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBankStatementListItem
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageBnplProductListEntry
 */
export interface PageBnplProductListEntry {
    /**
     * 
     * @type {Array<BnplProductListEntry>}
     * @memberof PageBnplProductListEntry
     */
    'content'?: Array<BnplProductListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageBnplProductListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBnplProductListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBnplProductListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageBnplProductListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBnplProductListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageBnplProductListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageBnplProductListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageBnplProductListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageBnplProductListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBnplProductListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageBusinessIncomeListEntry
 */
export interface PageBusinessIncomeListEntry {
    /**
     * 
     * @type {Array<BusinessIncomeListEntry>}
     * @memberof PageBusinessIncomeListEntry
     */
    'content'?: Array<BusinessIncomeListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageBusinessIncomeListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBusinessIncomeListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBusinessIncomeListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageBusinessIncomeListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBusinessIncomeListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageBusinessIncomeListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageBusinessIncomeListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageBusinessIncomeListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageBusinessIncomeListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBusinessIncomeListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageChildBranchListEntry
 */
export interface PageChildBranchListEntry {
    /**
     * 
     * @type {Array<ChildBranchListEntry>}
     * @memberof PageChildBranchListEntry
     */
    'content'?: Array<ChildBranchListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageChildBranchListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageChildBranchListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageChildBranchListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageChildBranchListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageChildBranchListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageChildBranchListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageChildBranchListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageChildBranchListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageChildBranchListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageChildBranchListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageCollateralListEntry
 */
export interface PageCollateralListEntry {
    /**
     * 
     * @type {Array<CollateralListEntry>}
     * @memberof PageCollateralListEntry
     */
    'content'?: Array<CollateralListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollateralListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollateralListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollateralListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCollateralListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCollateralListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCollateralListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageCollateralListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageCollateralListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageCollateralListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCollateralListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageComment
 */
export interface PageComment {
    /**
     * 
     * @type {Array<Comment>}
     * @memberof PageComment
     */
    'content'?: Array<Comment>;
    /**
     * 
     * @type {boolean}
     * @memberof PageComment
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageComment
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageComment
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageComment
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageComment
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageComment
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageComment
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageComment
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageComment
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageComment
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageCustomerGroup
 */
export interface PageCustomerGroup {
    /**
     * 
     * @type {Array<CustomerGroup>}
     * @memberof PageCustomerGroup
     */
    'content'?: Array<CustomerGroup>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCustomerGroup
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCustomerGroup
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCustomerGroup
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCustomerGroup
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCustomerGroup
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCustomerGroup
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageCustomerGroup
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageCustomerGroup
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageCustomerGroup
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCustomerGroup
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageCustomerGroupCompany
 */
export interface PageCustomerGroupCompany {
    /**
     * 
     * @type {Array<CustomerGroupCompany>}
     * @memberof PageCustomerGroupCompany
     */
    'content'?: Array<CustomerGroupCompany>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCustomerGroupCompany
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCustomerGroupCompany
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCustomerGroupCompany
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCustomerGroupCompany
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCustomerGroupCompany
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCustomerGroupCompany
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageCustomerGroupCompany
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageCustomerGroupCompany
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageCustomerGroupCompany
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCustomerGroupCompany
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageDebtCollectionActivityListEntry
 */
export interface PageDebtCollectionActivityListEntry {
    /**
     * 
     * @type {Array<DebtCollectionActivityListEntry>}
     * @memberof PageDebtCollectionActivityListEntry
     */
    'content'?: Array<DebtCollectionActivityListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageDebtCollectionActivityListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDebtCollectionActivityListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDebtCollectionActivityListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageDebtCollectionActivityListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDebtCollectionActivityListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageDebtCollectionActivityListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageDebtCollectionActivityListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageDebtCollectionActivityListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageDebtCollectionActivityListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDebtCollectionActivityListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageDebtCollectionListEntry
 */
export interface PageDebtCollectionListEntry {
    /**
     * 
     * @type {Array<DebtCollectionListEntry>}
     * @memberof PageDebtCollectionListEntry
     */
    'content'?: Array<DebtCollectionListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageDebtCollectionListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDebtCollectionListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDebtCollectionListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageDebtCollectionListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDebtCollectionListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageDebtCollectionListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageDebtCollectionListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageDebtCollectionListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageDebtCollectionListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDebtCollectionListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageIndividualListEntry
 */
export interface PageIndividualListEntry {
    /**
     * 
     * @type {Array<IndividualListEntry>}
     * @memberof PageIndividualListEntry
     */
    'content'?: Array<IndividualListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageIndividualListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageIndividualListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageIndividualListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageIndividualListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIndividualListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageIndividualListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageIndividualListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageIndividualListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageIndividualListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIndividualListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLegalListEntry
 */
export interface PageLegalListEntry {
    /**
     * 
     * @type {Array<LegalListEntry>}
     * @memberof PageLegalListEntry
     */
    'content'?: Array<LegalListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLegalListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLegalListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLegalListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLegalListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLegalListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLegalListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLegalListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageLegalListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageLegalListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLegalListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLegalLoanListEntry
 */
export interface PageLegalLoanListEntry {
    /**
     * 
     * @type {Array<LegalLoanListEntry>}
     * @memberof PageLegalLoanListEntry
     */
    'content'?: Array<LegalLoanListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLegalLoanListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLegalLoanListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLegalLoanListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLegalLoanListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLegalLoanListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLegalLoanListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLegalLoanListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageLegalLoanListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageLegalLoanListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLegalLoanListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLoanApplicationListEntry
 */
export interface PageLoanApplicationListEntry {
    /**
     * 
     * @type {Array<LoanApplicationListEntry>}
     * @memberof PageLoanApplicationListEntry
     */
    'content'?: Array<LoanApplicationListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLoanApplicationListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageLoanApplicationListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLoanDetails
 */
export interface PageLoanDetails {
    /**
     * 
     * @type {Array<LoanDetails>}
     * @memberof PageLoanDetails
     */
    'content'?: Array<LoanDetails>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanDetails
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanDetails
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanDetails
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanDetails
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanDetails
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLoanDetails
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanDetails
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageLoanDetails
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanDetails
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanDetails
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLoanListEntry
 */
export interface PageLoanListEntry {
    /**
     * 
     * @type {Array<LoanListEntry>}
     * @memberof PageLoanListEntry
     */
    'content'?: Array<LoanListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLoanListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageLoanListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLoanOperationAuditEventListEntry
 */
export interface PageLoanOperationAuditEventListEntry {
    /**
     * 
     * @type {Array<LoanOperationAuditEventListEntry>}
     * @memberof PageLoanOperationAuditEventListEntry
     */
    'content'?: Array<LoanOperationAuditEventListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperationAuditEventListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperationAuditEventListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperationAuditEventListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperationAuditEventListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperationAuditEventListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLoanOperationAuditEventListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperationAuditEventListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageLoanOperationAuditEventListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperationAuditEventListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperationAuditEventListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageManagersListEntry
 */
export interface PageManagersListEntry {
    /**
     * 
     * @type {Array<ManagersListEntry>}
     * @memberof PageManagersListEntry
     */
    'content'?: Array<ManagersListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageManagersListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageManagersListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageManagersListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageManagersListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageManagersListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageManagersListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageManagersListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageManagersListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageManagersListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageManagersListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageMerchantListItem
 */
export interface PageMerchantListItem {
    /**
     * 
     * @type {Array<MerchantListItem>}
     * @memberof PageMerchantListItem
     */
    'content'?: Array<MerchantListItem>;
    /**
     * 
     * @type {boolean}
     * @memberof PageMerchantListItem
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMerchantListItem
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMerchantListItem
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageMerchantListItem
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMerchantListItem
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageMerchantListItem
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageMerchantListItem
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageMerchantListItem
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageMerchantListItem
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMerchantListItem
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageProductListEntry
 */
export interface PageProductListEntry {
    /**
     * 
     * @type {Array<ProductListEntry>}
     * @memberof PageProductListEntry
     */
    'content'?: Array<ProductListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageProductListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProductListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageProductListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageProductListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageProductListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageProductListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProductListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageRestrictionListEntry
 */
export interface PageRestrictionListEntry {
    /**
     * 
     * @type {Array<RestrictionListEntry>}
     * @memberof PageRestrictionListEntry
     */
    'content'?: Array<RestrictionListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRestrictionListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRestrictionListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRestrictionListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRestrictionListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRestrictionListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRestrictionListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRestrictionListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageRestrictionListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageRestrictionListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRestrictionListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageRoleListItem
 */
export interface PageRoleListItem {
    /**
     * 
     * @type {Array<RoleListItem>}
     * @memberof PageRoleListItem
     */
    'content'?: Array<RoleListItem>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRoleListItem
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRoleListItem
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRoleListItem
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRoleListItem
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRoleListItem
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRoleListItem
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRoleListItem
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageRoleListItem
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageRoleListItem
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRoleListItem
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageTaskMetadataListEntry
 */
export interface PageTaskMetadataListEntry {
    /**
     * 
     * @type {Array<TaskMetadataListEntry>}
     * @memberof PageTaskMetadataListEntry
     */
    'content'?: Array<TaskMetadataListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskMetadataListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskMetadataListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskMetadataListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTaskMetadataListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTaskMetadataListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTaskMetadataListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageTaskMetadataListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageTaskMetadataListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageTaskMetadataListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTaskMetadataListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageTrustProductListEntry
 */
export interface PageTrustProductListEntry {
    /**
     * 
     * @type {Array<TrustProductListEntry>}
     * @memberof PageTrustProductListEntry
     */
    'content'?: Array<TrustProductListEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrustProductListEntry
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrustProductListEntry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrustProductListEntry
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTrustProductListEntry
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTrustProductListEntry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTrustProductListEntry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageTrustProductListEntry
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageTrustProductListEntry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageTrustProductListEntry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTrustProductListEntry
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageUserListItem
 */
export interface PageUserListItem {
    /**
     * 
     * @type {Array<UserListItem>}
     * @memberof PageUserListItem
     */
    'content'?: Array<UserListItem>;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserListItem
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserListItem
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserListItem
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageUserListItem
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserListItem
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageUserListItem
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageUserListItem
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageUserListItem
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageUserListItem
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserListItem
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface ParentBranch
 */
export interface ParentBranch {
    /**
     * 
     * @type {string}
     * @memberof ParentBranch
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof ParentBranch
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ParentBranch
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ParentBranchOption
 */
export interface ParentBranchOption {
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof ParentBranchOption
     */
    'childBranchOptions'?: Array<IdNameEntry>;
    /**
     * 
     * @type {number}
     * @memberof ParentBranchOption
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ParentBranchOption
     */
    'name': string;
}
/**
 * @type PatchCollateralRequest
 * @export
 */
export type PatchCollateralRequest = CarCollateralPatch | CollateralEvaluationPatch | EquipmentCollateralPatch | IntangibleAssetCollateralPatch | LeasingCollateralPatch | LivestockCollateralPatch | RealEstateCollateralPatch | RealEstateLandCollateralPatch;

/**
 * @type PatchLegalRequest
 * @export
 */
export type PatchLegalRequest = LegalCeoUpdate | LegalContactDetailsUpdate | LegalGeneralInformationUpdate | LegalPaymentAccountUpdate;

/**
 * @type PatchLoanRequest
 * @export
 */
export type PatchLoanRequest = LoanChangeRelationshipManager | LoanSetCibStatusNormal;

/**
 * 
 * @export
 * @interface PaymentScheduleEntry
 */
export interface PaymentScheduleEntry {
    /**
     * 
     * @type {Array<LoanSchedulePeriodEntry>}
     * @memberof PaymentScheduleEntry
     */
    'schedulePeriods'?: Array<LoanSchedulePeriodEntry>;
    /**
     * 
     * @type {number}
     * @memberof PaymentScheduleEntry
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentScheduleEntry
     */
    'totalInterest'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentScheduleEntry
     */
    'totalPenalty'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentScheduleEntry
     */
    'totalPrincipal'?: number;
}
/**
 * 
 * @export
 * @interface PayonLoan
 */
export interface PayonLoan extends Loan {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof PayonLoan
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'applicationId': number;
    /**
     * 
     * @type {string}
     * @memberof PayonLoan
     */
    'branchCode': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PayonLoan
     */
    'branchManager'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof PayonLoan
     */
    'branchName': string;
    /**
     * 
     * @type {string}
     * @memberof PayonLoan
     */
    'cibSentDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PayonLoan
     */
    'cibSentStatus': boolean;
    /**
     * 
     * @type {Array<LoanCollateralListEntry>}
     * @memberof PayonLoan
     */
    'collaterals': Array<LoanCollateralListEntry>;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'commentRoomId': number;
    /**
     * 
     * @type {CreditBureauStatus}
     * @memberof PayonLoan
     */
    'creditBureauStatus': CreditBureauStatus;
    /**
     * 
     * @type {Customer}
     * @memberof PayonLoan
     */
    'customer': Customer;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'daysOverdue': number;
    /**
     * 
     * @type {string}
     * @memberof PayonLoan
     */
    'disbursementDate': string;
    /**
     * 
     * @type {IdEntry}
     * @memberof PayonLoan
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'downPayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'exceedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayonLoan
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayonLoan
     */
    'firstRepaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'incomeAmount'?: number;
    /**
     * 
     * @type {Array<IndividualCoBorrowerListEntry>}
     * @memberof PayonLoan
     */
    'individualCoBorrowers': Array<IndividualCoBorrowerListEntry>;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'interestRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'lastPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayonLoan
     */
    'lastPaymentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayonLoan
     */
    'loanPurpose'?: string;
    /**
     * 
     * @type {LoanStatus}
     * @memberof PayonLoan
     */
    'loanStatus': LoanStatus;
    /**
     * 
     * @type {string}
     * @memberof PayonLoan
     */
    'maturityDate': string;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'nextPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayonLoan
     */
    'nextPaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'overdueAdvancePayment': number;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'principalBalance': number;
    /**
     * 
     * @type {ProductLoanInfo}
     * @memberof PayonLoan
     */
    'product': ProductLoanInfo;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PayonLoan
     */
    'relationshipManager': IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'repaymentAmount': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof PayonLoan
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {string}
     * @memberof PayonLoan
     */
    'secondRepaymentDate'?: string;
    /**
     * 
     * @type {ApplicationSource}
     * @memberof PayonLoan
     */
    'source': ApplicationSource;
    /**
     * 
     * @type {number}
     * @memberof PayonLoan
     */
    'term': number;
}


/**
 * 
 * @export
 * @interface PayonLoanApplication
 */
export interface PayonLoanApplication extends LoanApplication {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof PayonLoanApplication
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof PayonLoanApplication
     */
    'averageIncome': number;
    /**
     * 
     * @type {string}
     * @memberof PayonLoanApplication
     */
    'bankAccountNumber': string;
    /**
     * 
     * @type {BankInformationDto}
     * @memberof PayonLoanApplication
     */
    'bankInformation': BankInformationDto;
    /**
     * 
     * @type {number}
     * @memberof PayonLoanApplication
     */
    'commentRoomId': number;
    /**
     * 
     * @type {number}
     * @memberof PayonLoanApplication
     */
    'creditScore': number;
    /**
     * 
     * @type {number}
     * @memberof PayonLoanApplication
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof PayonLoanApplication
     */
    'disbursementDate'?: string;
    /**
     * 
     * @type {IdEntry}
     * @memberof PayonLoanApplication
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {string}
     * @memberof PayonLoanApplication
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof PayonLoanApplication
     */
    'firstPaymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof PayonLoanApplication
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof PayonLoanApplication
     */
    'id': number;
    /**
     * 
     * @type {Array<CustomerIncomeType>}
     * @memberof PayonLoanApplication
     */
    'incomeTypes': Array<CustomerIncomeType>;
    /**
     * 
     * @type {number}
     * @memberof PayonLoanApplication
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof PayonLoanApplication
     */
    'limitOffer': number;
    /**
     * 
     * @type {number}
     * @memberof PayonLoanApplication
     */
    'loanId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayonLoanApplication
     */
    'loanPurpose': string;
    /**
     * 
     * @type {string}
     * @memberof PayonLoanApplication
     */
    'maturityDate': string;
    /**
     * 
     * @type {ProductBrief}
     * @memberof PayonLoanApplication
     */
    'product': ProductBrief;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PayonLoanApplication
     */
    'relationshipManager': IdNameEntry;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof PayonLoanApplication
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {number}
     * @memberof PayonLoanApplication
     */
    'requestedAmount': number;
    /**
     * 
     * @type {string}
     * @memberof PayonLoanApplication
     */
    'requestedAt': string;
    /**
     * 
     * @type {number}
     * @memberof PayonLoanApplication
     */
    'requestedTerm': number;
    /**
     * 
     * @type {ApplicationSource}
     * @memberof PayonLoanApplication
     */
    'source': ApplicationSource;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof PayonLoanApplication
     */
    'status': LoanApplicationStatus;
}


/**
 * 
 * @export
 * @interface PayonProduct
 */
export interface PayonProduct extends Product {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof PayonProduct
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof PayonProduct
     */
    'dti': number;
    /**
     * 
     * @type {boolean}
     * @memberof PayonProduct
     */
    'excludedFromOffer': boolean;
    /**
     * 
     * @type {number}
     * @memberof PayonProduct
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PayonProduct
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof PayonProduct
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof PayonProduct
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof PayonProduct
     */
    'name': string;
    /**
     * 
     * @type {PenaltyInterestAccrualMethod}
     * @memberof PayonProduct
     */
    'penaltyInterestAccrualMethod': PenaltyInterestAccrualMethod;
    /**
     * 
     * @type {number}
     * @memberof PayonProduct
     */
    'penaltyInterestAccrualStartDay': number;
    /**
     * 
     * @type {number}
     * @memberof PayonProduct
     */
    'penaltyInterestMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof PayonProduct
     */
    'productLineId': number;
    /**
     * 
     * @type {RepaymentAllocationRule}
     * @memberof PayonProduct
     */
    'repaymentAllocationRule': RepaymentAllocationRule;
    /**
     * 
     * @type {RepaymentCalculationMethod}
     * @memberof PayonProduct
     */
    'repaymentCalculationMethod': RepaymentCalculationMethod;
    /**
     * 
     * @type {ProductStatus}
     * @memberof PayonProduct
     */
    'status': ProductStatus;
}


/**
 * 
 * @export
 * @interface PayonProductUpdate
 */
export interface PayonProductUpdate extends ProductUpdate {
    /**
     * 
     * @type {number}
     * @memberof PayonProductUpdate
     */
    'dti': number;
    /**
     * 
     * @type {number}
     * @memberof PayonProductUpdate
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof PayonProductUpdate
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof PayonProductUpdate
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof PayonProductUpdate
     */
    'name': string;
    /**
     * 
     * @type {PenaltyInterestAccrualMethod}
     * @memberof PayonProductUpdate
     */
    'penaltyInterestAccrualMethod': PenaltyInterestAccrualMethod;
    /**
     * 
     * @type {number}
     * @memberof PayonProductUpdate
     */
    'penaltyInterestAccrualStartDay': number;
    /**
     * 
     * @type {number}
     * @memberof PayonProductUpdate
     */
    'penaltyInterestMultiplier': number;
    /**
     * 
     * @type {RepaymentAllocationRule}
     * @memberof PayonProductUpdate
     */
    'repaymentAllocationRule': RepaymentAllocationRule;
    /**
     * 
     * @type {RepaymentCalculationMethod}
     * @memberof PayonProductUpdate
     */
    'repaymentCalculationMethod': RepaymentCalculationMethod;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PenaltyInterestAccrualMethod = {
    ACTUAL_OVERDUE_PRINCIPAL_DEBT: 'ACTUAL_OVERDUE_PRINCIPAL_DEBT',
    PLANNED_PRINCIPAL_DEBT_REPAYMENT: 'PLANNED_PRINCIPAL_DEBT_REPAYMENT'
} as const;

export type PenaltyInterestAccrualMethod = typeof PenaltyInterestAccrualMethod[keyof typeof PenaltyInterestAccrualMethod];


/**
 * 
 * @export
 * @interface PledgedApplicationDecisionListEntry
 */
export interface PledgedApplicationDecisionListEntry {
    /**
     * 
     * @type {PledgedLoanApplicationDecisionActorType}
     * @memberof PledgedApplicationDecisionListEntry
     */
    'actorType': PledgedLoanApplicationDecisionActorType;
    /**
     * 
     * @type {number}
     * @memberof PledgedApplicationDecisionListEntry
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PledgedApplicationDecisionListEntry
     */
    'comment'?: string;
    /**
     * 
     * @type {PledgedLoanApplicationTaskDecisionType}
     * @memberof PledgedApplicationDecisionListEntry
     */
    'decision'?: PledgedLoanApplicationTaskDecisionType;
    /**
     * 
     * @type {number}
     * @memberof PledgedApplicationDecisionListEntry
     */
    'monthlyInterestRate'?: number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof PledgedApplicationDecisionListEntry
     */
    'repaymentFrequency'?: RepaymentFrequency;
    /**
     * 
     * @type {string}
     * @memberof PledgedApplicationDecisionListEntry
     */
    'taskCompletedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PledgedApplicationDecisionListEntry
     */
    'taskCreatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof PledgedApplicationDecisionListEntry
     */
    'term'?: number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PledgedApplicationDecisionListEntry
     */
    'user': IdNameEntry;
}


/**
 * 
 * @export
 * @interface PledgedLoan
 */
export interface PledgedLoan extends Loan {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof PledgedLoan
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'applicationId': number;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoan
     */
    'branchCode': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PledgedLoan
     */
    'branchManager'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoan
     */
    'branchName': string;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoan
     */
    'cibSentDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PledgedLoan
     */
    'cibSentStatus': boolean;
    /**
     * 
     * @type {Array<LoanCollateralListEntry>}
     * @memberof PledgedLoan
     */
    'collaterals': Array<LoanCollateralListEntry>;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'commentRoomId': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'commission'?: number;
    /**
     * 
     * @type {CreditBureauStatus}
     * @memberof PledgedLoan
     */
    'creditBureauStatus': CreditBureauStatus;
    /**
     * 
     * @type {Customer}
     * @memberof PledgedLoan
     */
    'customer': Customer;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'daysOverdue': number;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoan
     */
    'disbursementDate': string;
    /**
     * 
     * @type {IdEntry}
     * @memberof PledgedLoan
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'downpayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'exceedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoan
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoan
     */
    'firstRepaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'incomeAmount'?: number;
    /**
     * 
     * @type {Array<IndividualCoBorrowerListEntry>}
     * @memberof PledgedLoan
     */
    'individualCoBorrowers': Array<IndividualCoBorrowerListEntry>;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'interestRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'lastPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoan
     */
    'lastPaymentDate'?: string;
    /**
     * 
     * @type {Array<LegalCoBorrowerListEntry>}
     * @memberof PledgedLoan
     */
    'legalCoBorrowers': Array<LegalCoBorrowerListEntry>;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoan
     */
    'loanPurpose'?: string;
    /**
     * 
     * @type {LoanStatus}
     * @memberof PledgedLoan
     */
    'loanStatus': LoanStatus;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoan
     */
    'maturityDate': string;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'nextPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoan
     */
    'nextPaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'overdueAdvancePayment': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'principalBalance': number;
    /**
     * 
     * @type {ProductLoanInfo}
     * @memberof PledgedLoan
     */
    'product': ProductLoanInfo;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PledgedLoan
     */
    'relationshipManager': IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'repaymentAmount': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof PledgedLoan
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoan
     */
    'secondRepaymentDate'?: string;
    /**
     * 
     * @type {ApplicationSource}
     * @memberof PledgedLoan
     */
    'source': ApplicationSource;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoan
     */
    'term': number;
}


/**
 * 
 * @export
 * @interface PledgedLoanApplication
 */
export interface PledgedLoanApplication extends LoanApplication {
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PledgedLoanApplication
     */
    'accountant'?: IdNameEntry;
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof PledgedLoanApplication
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof PledgedLoanApplication
     */
    'approvedConditions'?: PledgedLoanApplicationConditions;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplication
     */
    'averageIncome': number;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoanApplication
     */
    'bankAccountNumber'?: string;
    /**
     * 
     * @type {BankInformationDto}
     * @memberof PledgedLoanApplication
     */
    'bankInformation'?: BankInformationDto;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PledgedLoanApplication
     */
    'branch': IdNameEntry;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PledgedLoanApplication
     */
    'branchManager'?: IdNameEntry;
    /**
     * 
     * @type {Array<PledgedApplicationDecisionListEntry>}
     * @memberof PledgedLoanApplication
     */
    'ccDecisions'?: Array<PledgedApplicationDecisionListEntry>;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PledgedLoanApplication
     */
    'ccSecretary'?: IdNameEntry;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PledgedLoanApplication
     */
    'ciaEmployee'?: IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplication
     */
    'commentRoomId': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplication
     */
    'commission'?: number;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof PledgedLoanApplication
     */
    'currentAssignees': Array<IdNameEntry>;
    /**
     * 
     * @type {Array<PledgedApplicationDecisionListEntry>}
     * @memberof PledgedLoanApplication
     */
    'decisions'?: Array<PledgedApplicationDecisionListEntry>;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoanApplication
     */
    'disbursedAt'?: string;
    /**
     * 
     * @type {IdEntry}
     * @memberof PledgedLoanApplication
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplication
     */
    'downpaymentAmount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PledgedLoanApplication
     */
    'downpaymentPaid'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplication
     */
    'downpaymentPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoanApplication
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplication
     */
    'feeAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplication
     */
    'id': number;
    /**
     * 
     * @type {Array<CustomerIncomeType>}
     * @memberof PledgedLoanApplication
     */
    'incomeTypes': Array<CustomerIncomeType>;
    /**
     * 
     * @type {IndividualDetails}
     * @memberof PledgedLoanApplication
     */
    'individual': IndividualDetails;
    /**
     * 
     * @type {Array<IndividualCoBorrowerListEntry>}
     * @memberof PledgedLoanApplication
     */
    'individualCoBorrowers'?: Array<IndividualCoBorrowerListEntry>;
    /**
     * 
     * @type {Invoice}
     * @memberof PledgedLoanApplication
     */
    'invoice'?: Invoice;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PledgedLoanApplication
     */
    'laaEmployee'?: IdNameEntry;
    /**
     * 
     * @type {IdLocalizationEntry}
     * @memberof PledgedLoanApplication
     */
    'laaLoanPurpose'?: IdLocalizationEntry;
    /**
     * 
     * @type {IdLocalizationEntry}
     * @memberof PledgedLoanApplication
     */
    'laaLoanSubPurpose'?: IdLocalizationEntry;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof PledgedLoanApplication
     */
    'laatConditions'?: PledgedLoanApplicationConditions;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplication
     */
    'laatEstimatedDti'?: number;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplication
     */
    'laatEstimatedDtiWithCoBorrower'?: number;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplication
     */
    'laatEstimatedFirstPaymentAmount'?: number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PledgedLoanApplication
     */
    'laatLeader'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoanApplication
     */
    'laatLeaderComment'?: string;
    /**
     * 
     * @type {Array<LegalCoBorrowerListEntry>}
     * @memberof PledgedLoanApplication
     */
    'legalCoBorrowers'?: Array<LegalCoBorrowerListEntry>;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplication
     */
    'loanId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoanApplication
     */
    'loanPurpose': string;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoanApplication
     */
    'maturityDate'?: string;
    /**
     * 
     * @type {ProductOfferResponseEntry}
     * @memberof PledgedLoanApplication
     */
    'offer'?: ProductOfferResponseEntry;
    /**
     * 
     * @type {ProductBrief}
     * @memberof PledgedLoanApplication
     */
    'product'?: ProductBrief;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof PledgedLoanApplication
     */
    'relationshipManager'?: IdNameEntry;
    /**
     * 
     * @type {PledgedLoanApplicationConditions}
     * @memberof PledgedLoanApplication
     */
    'requestedConditions': PledgedLoanApplicationConditions;
    /**
     * 
     * @type {ApplicationSource}
     * @memberof PledgedLoanApplication
     */
    'source': ApplicationSource;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof PledgedLoanApplication
     */
    'status': LoanApplicationStatus;
}


/**
 * 
 * @export
 * @interface PledgedLoanApplicationConditions
 */
export interface PledgedLoanApplicationConditions {
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplicationConditions
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoanApplicationConditions
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoanApplicationConditions
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoanApplicationConditions
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplicationConditions
     */
    'monthlyInterestRate'?: number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof PledgedLoanApplicationConditions
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {string}
     * @memberof PledgedLoanApplicationConditions
     */
    'secondRepaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PledgedLoanApplicationConditions
     */
    'term': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PledgedLoanApplicationDecisionActorType = {
    ACCOUNTANT: 'ACCOUNTANT',
    BM: 'BM',
    CC: 'CC',
    CIAT: 'CIAT',
    DMT_USER: 'DMT_USER',
    INDIVIDUAL: 'INDIVIDUAL',
    LAAT_LEADER: 'LAAT_LEADER',
    LAAT_USER: 'LAAT_USER',
    RM: 'RM'
} as const;

export type PledgedLoanApplicationDecisionActorType = typeof PledgedLoanApplicationDecisionActorType[keyof typeof PledgedLoanApplicationDecisionActorType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PledgedLoanApplicationTaskDecisionType = {
    APPROVED: 'APPROVED',
    DECLINED: 'DECLINED',
    NEED_ADJUSTMENT: 'NEED_ADJUSTMENT'
} as const;

export type PledgedLoanApplicationTaskDecisionType = typeof PledgedLoanApplicationTaskDecisionType[keyof typeof PledgedLoanApplicationTaskDecisionType];


/**
 * 
 * @export
 * @interface PledgedProduct
 */
export interface PledgedProduct extends Product {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof PledgedProduct
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof PledgedProduct
     */
    'disbursementFee': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedProduct
     */
    'dti': number;
    /**
     * 
     * @type {boolean}
     * @memberof PledgedProduct
     */
    'excludedFromOffer': boolean;
    /**
     * 
     * @type {number}
     * @memberof PledgedProduct
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedProduct
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedProduct
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedProduct
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof PledgedProduct
     */
    'name': string;
    /**
     * 
     * @type {PenaltyInterestAccrualMethod}
     * @memberof PledgedProduct
     */
    'penaltyInterestAccrualMethod': PenaltyInterestAccrualMethod;
    /**
     * 
     * @type {number}
     * @memberof PledgedProduct
     */
    'penaltyInterestAccrualStartDay': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedProduct
     */
    'penaltyInterestMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedProduct
     */
    'productLineId': number;
    /**
     * 
     * @type {RepaymentAllocationRule}
     * @memberof PledgedProduct
     */
    'repaymentAllocationRule': RepaymentAllocationRule;
    /**
     * 
     * @type {RepaymentCalculationMethod}
     * @memberof PledgedProduct
     */
    'repaymentCalculationMethod': RepaymentCalculationMethod;
    /**
     * 
     * @type {ProductStatus}
     * @memberof PledgedProduct
     */
    'status': ProductStatus;
    /**
     * 
     * @type {ProductSubtype}
     * @memberof PledgedProduct
     */
    'subtype': ProductSubtype;
}


/**
 * 
 * @export
 * @interface PledgedProductUpdate
 */
export interface PledgedProductUpdate extends ProductUpdate {
    /**
     * 
     * @type {number}
     * @memberof PledgedProductUpdate
     */
    'disbursementFee': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedProductUpdate
     */
    'dti': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedProductUpdate
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedProductUpdate
     */
    'maxLoanAmount': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedProductUpdate
     */
    'maxTenor': number;
    /**
     * 
     * @type {string}
     * @memberof PledgedProductUpdate
     */
    'name': string;
    /**
     * 
     * @type {PenaltyInterestAccrualMethod}
     * @memberof PledgedProductUpdate
     */
    'penaltyInterestAccrualMethod': PenaltyInterestAccrualMethod;
    /**
     * 
     * @type {number}
     * @memberof PledgedProductUpdate
     */
    'penaltyInterestAccrualStartDay': number;
    /**
     * 
     * @type {number}
     * @memberof PledgedProductUpdate
     */
    'penaltyInterestMultiplier': number;
    /**
     * 
     * @type {RepaymentAllocationRule}
     * @memberof PledgedProductUpdate
     */
    'repaymentAllocationRule': RepaymentAllocationRule;
    /**
     * 
     * @type {RepaymentCalculationMethod}
     * @memberof PledgedProductUpdate
     */
    'repaymentCalculationMethod': RepaymentCalculationMethod;
}


/**
 * 
 * @export
 * @interface Process
 */
export interface Process {
    /**
     * 
     * @type {string}
     * @memberof Process
     */
    'id': string;
    /**
     * 
     * @type {ProcessName}
     * @memberof Process
     */
    'name': ProcessName;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ProcessName = {
    AUTO_ASSIGN_DEBT_COLLECTION: 'AUTO_ASSIGN_DEBT_COLLECTION',
    BNPL_REOPEN: 'BNPL_REOPEN',
    BO_USER_DEACTIVATION: 'BO_USER_DEACTIVATION',
    BUSINESS_INCOME_ASSESSMENT: 'BUSINESS_INCOME_ASSESSMENT',
    CANCEL_MERCHANT_INVOICE: 'CANCEL_MERCHANT_INVOICE',
    CIB_LOAN_REPORT: 'CIB_LOAN_REPORT',
    CIB_STATUS_DECREASED: 'CIB_STATUS_DECREASED',
    CIB_STATUS_WILL_DECREASE: 'CIB_STATUS_WILL_DECREASE',
    GENERATE_PLEDGED_APPLICATION_AGREEMENTS: 'GENERATE_PLEDGED_APPLICATION_AGREEMENTS',
    INDIVIDUALS_BLACKLISTING: 'INDIVIDUALS_BLACKLISTING',
    LOAN_APPLICATION: 'LOAN_APPLICATION',
    LOAN_DISBURSEMENT: 'LOAN_DISBURSEMENT',
    LOAN_FEE: 'LOAN_FEE',
    PLEDGED_LOAN_APPLICATION: 'PLEDGED_LOAN_APPLICATION',
    REASSIGN_LOAN_APPLICATION: 'REASSIGN_LOAN_APPLICATION',
    RECALCULATE_OFFERED_BNPL_PRODUCT_LIMITS: 'RECALCULATE_OFFERED_BNPL_PRODUCT_LIMITS',
    SELECT_TASK_CANDIDATE: 'SELECT_TASK_CANDIDATE',
    TRUST_PLEDGED_LOAN_APPLICATION: 'TRUST_PLEDGED_LOAN_APPLICATION',
    UNASSIGN_BO_USERS_TASKS: 'UNASSIGN_BO_USERS_TASKS',
    UPDATE_MERCHANT_INVOICE: 'UPDATE_MERCHANT_INVOICE'
} as const;

export type ProcessName = typeof ProcessName[keyof typeof ProcessName];


/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ProductBrief
 */
export interface ProductBrief {
    /**
     * 
     * @type {number}
     * @memberof ProductBrief
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductBrief
     */
    'name': string;
    /**
     * 
     * @type {ProductLine}
     * @memberof ProductBrief
     */
    'productLine': ProductLine;
}
/**
 * 
 * @export
 * @interface ProductLine
 */
export interface ProductLine {
    /**
     * 
     * @type {number}
     * @memberof ProductLine
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductLine
     */
    'name': string;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductLine
     */
    'type': ProductType;
}


/**
 * 
 * @export
 * @interface ProductListEntry
 */
export interface ProductListEntry {
    /**
     * 
     * @type {number}
     * @memberof ProductListEntry
     */
    'externalId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductListEntry
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductListEntry
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductListEntry
     */
    'penaltyInterestAccrualStartDay': number;
    /**
     * 
     * @type {RepaymentAllocationRule}
     * @memberof ProductListEntry
     */
    'repaymentAllocationRule': RepaymentAllocationRule;
    /**
     * 
     * @type {RepaymentCalculationMethod}
     * @memberof ProductListEntry
     */
    'repaymentCalculationMethod': RepaymentCalculationMethod;
    /**
     * 
     * @type {ProductStatus}
     * @memberof ProductListEntry
     */
    'status': ProductStatus;
    /**
     * 
     * @type {ProductSubtype}
     * @memberof ProductListEntry
     */
    'subtype'?: ProductSubtype;
    /**
     * 
     * @type {number}
     * @memberof ProductListEntry
     */
    'version': number;
}


/**
 * 
 * @export
 * @interface ProductLoanInfo
 */
export interface ProductLoanInfo {
    /**
     * 
     * @type {number}
     * @memberof ProductLoanInfo
     */
    'externalId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductLoanInfo
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductLoanInfo
     */
    'nameWithVersion': string;
    /**
     * 
     * @type {number}
     * @memberof ProductLoanInfo
     */
    'penaltyInterestMultiplier': number;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductLoanInfo
     */
    'type': ProductType;
}


/**
 * 
 * @export
 * @interface ProductOfferListEntry
 */
export interface ProductOfferListEntry {
    /**
     * 
     * @type {number}
     * @memberof ProductOfferListEntry
     */
    'availableAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductOfferListEntry
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductOfferListEntry
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof ProductOfferListEntry
     */
    'issuedAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductOfferListEntry
     */
    'limitAmount': number;
    /**
     * 
     * @type {ProductBrief}
     * @memberof ProductOfferListEntry
     */
    'product': ProductBrief;
    /**
     * 
     * @type {number}
     * @memberof ProductOfferListEntry
     */
    'term': number;
}
/**
 * 
 * @export
 * @interface ProductOfferResponseEntry
 */
export interface ProductOfferResponseEntry {
    /**
     * 
     * @type {string}
     * @memberof ProductOfferResponseEntry
     */
    'expiredAt'?: string;
    /**
     * 
     * @type {Array<ProductOfferListEntry>}
     * @memberof ProductOfferResponseEntry
     */
    'productOfferList'?: Array<ProductOfferListEntry>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProductStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;

export type ProductStatus = typeof ProductStatus[keyof typeof ProductStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ProductSubtype = {
    CAR: 'CAR',
    COLLATERALIZED: 'COLLATERALIZED',
    MORTGAGE: 'MORTGAGE'
} as const;

export type ProductSubtype = typeof ProductSubtype[keyof typeof ProductSubtype];


/**
 * 
 * @export
 * @enum {string}
 */

export const ProductType = {
    BNPL: 'BNPL',
    INSTALLMENT: 'INSTALLMENT',
    PAYON: 'PAYON',
    PLEDGED: 'PLEDGED',
    TRUST: 'TRUST'
} as const;

export type ProductType = typeof ProductType[keyof typeof ProductType];


/**
 * 
 * @export
 * @interface ProductTypeWithSubtype
 */
export interface ProductTypeWithSubtype {
    /**
     * 
     * @type {ProductSubtype}
     * @memberof ProductTypeWithSubtype
     */
    'productSubtype'?: ProductSubtype;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductTypeWithSubtype
     */
    'productType'?: ProductType;
}


/**
 * 
 * @export
 * @interface ProductUpdate
 */
export interface ProductUpdate {
    /**
     * 
     * @type {string}
     * @memberof ProductUpdate
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ProposedGracePeriod
 */
export interface ProposedGracePeriod {
    /**
     * 
     * @type {GracePeriod}
     * @memberof ProposedGracePeriod
     */
    'gracePeriod': GracePeriod;
    /**
     * 
     * @type {number}
     * @memberof ProposedGracePeriod
     */
    'operationId': number;
}
/**
 * 
 * @export
 * @interface ProposedInterestRateChanging
 */
export interface ProposedInterestRateChanging {
    /**
     * 
     * @type {number}
     * @memberof ProposedInterestRateChanging
     */
    'currentInterestRate': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedInterestRateChanging
     */
    'operationId': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedInterestRateChanging
     */
    'proposedInterestRate': number;
}
/**
 * 
 * @export
 * @interface ProposedLoanInterestAccrual
 */
export interface ProposedLoanInterestAccrual {
    /**
     * 
     * @type {boolean}
     * @memberof ProposedLoanInterestAccrual
     */
    'interestAccrualStop': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanInterestAccrual
     */
    'operationId': number;
}
/**
 * 
 * @export
 * @interface ProposedLoanInterestAdjustment
 */
export interface ProposedLoanInterestAdjustment {
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanInterestAdjustment
     */
    'currentTotalInterest': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanInterestAdjustment
     */
    'operationId': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanInterestAdjustment
     */
    'proposedTotalInterest': number;
}
/**
 * 
 * @export
 * @interface ProposedLoanPastDatePayment
 */
export interface ProposedLoanPastDatePayment {
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanPastDatePayment
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof ProposedLoanPastDatePayment
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanPastDatePayment
     */
    'operationId': number;
    /**
     * 
     * @type {string}
     * @memberof ProposedLoanPastDatePayment
     */
    'paymentDate': string;
}
/**
 * 
 * @export
 * @interface ProposedLoanPaymentAmountChanging
 */
export interface ProposedLoanPaymentAmountChanging {
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanPaymentAmountChanging
     */
    'currentPrincipal': number;
    /**
     * 
     * @type {Array<LoanSchedulePrincipal>}
     * @memberof ProposedLoanPaymentAmountChanging
     */
    'loanSchedulePrincipals': Array<LoanSchedulePrincipal>;
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanPaymentAmountChanging
     */
    'operationId': number;
}
/**
 * 
 * @export
 * @interface ProposedLoanPaymentCancellation
 */
export interface ProposedLoanPaymentCancellation {
    /**
     * 
     * @type {string}
     * @memberof ProposedLoanPaymentCancellation
     */
    'comment'?: string;
    /**
     * 
     * @type {Array<LoanPayment>}
     * @memberof ProposedLoanPaymentCancellation
     */
    'loanPayments': Array<LoanPayment>;
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanPaymentCancellation
     */
    'operationId': number;
}
/**
 * 
 * @export
 * @interface ProposedLoanPenaltyAdjustment
 */
export interface ProposedLoanPenaltyAdjustment {
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanPenaltyAdjustment
     */
    'currentPenalty': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanPenaltyAdjustment
     */
    'operationId': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedLoanPenaltyAdjustment
     */
    'proposedPenalty': number;
}
/**
 * 
 * @export
 * @interface ProposedMaturityDateExtension
 */
export interface ProposedMaturityDateExtension {
    /**
     * 
     * @type {number}
     * @memberof ProposedMaturityDateExtension
     */
    'additionalPaymentsCount': number;
    /**
     * 
     * @type {string}
     * @memberof ProposedMaturityDateExtension
     */
    'maturityDate': string;
    /**
     * 
     * @type {number}
     * @memberof ProposedMaturityDateExtension
     */
    'operationId': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof ProposedMaturityDateExtension
     */
    'repaymentFrequency': RepaymentFrequency;
}


/**
 * 
 * @export
 * @interface ProposedNewGracePeriodAllowing
 */
export interface ProposedNewGracePeriodAllowing {
    /**
     * 
     * @type {number}
     * @memberof ProposedNewGracePeriodAllowing
     */
    'operationId': number;
}
/**
 * 
 * @export
 * @interface ProposedPaymentDateChanging
 */
export interface ProposedPaymentDateChanging {
    /**
     * 
     * @type {number}
     * @memberof ProposedPaymentDateChanging
     */
    'firstPaymentDay': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedPaymentDateChanging
     */
    'operationId': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedPaymentDateChanging
     */
    'secondPaymentDay'?: number;
}
/**
 * 
 * @export
 * @interface ProposedRefinancing
 */
export interface ProposedRefinancing {
    /**
     * 
     * @type {string}
     * @memberof ProposedRefinancing
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof ProposedRefinancing
     */
    'operationId': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedRefinancing
     */
    'refinancingPrincipalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedRefinancing
     */
    'repaymentsQuantity': number;
    /**
     * 
     * @type {string}
     * @memberof ProposedRefinancing
     */
    'secondRepaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProposedRefinancing
     */
    'totalAmount': number;
}
/**
 * 
 * @export
 * @interface ProposedWriteOff
 */
export interface ProposedWriteOff {
    /**
     * 
     * @type {number}
     * @memberof ProposedWriteOff
     */
    'operationId': number;
}
/**
 * 
 * @export
 * @interface ProvinceDto
 */
export interface ProvinceDto {
    /**
     * 
     * @type {Array<DistrictDto>}
     * @memberof ProvinceDto
     */
    'districtDtoList': Array<DistrictDto>;
    /**
     * 
     * @type {Localization}
     * @memberof ProvinceDto
     */
    'l10n': Localization;
    /**
     * 
     * @type {string}
     * @memberof ProvinceDto
     */
    'provinceCode': string;
}
/**
 * 
 * @export
 * @interface ProvisionRatesSettingForm
 */
export interface ProvisionRatesSettingForm {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ProvisionRatesSettingForm
     */
    'setting': { [key: string]: number; };
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RatingOrganisation = {
    FITCH_RATING: 'FITCH_RATING',
    MOODYS_RATING: 'MOODYS_RATING',
    NO_RATING: 'NO_RATING',
    SP_RATING: 'SP_RATING'
} as const;

export type RatingOrganisation = typeof RatingOrganisation[keyof typeof RatingOrganisation];


/**
 * 
 * @export
 * @interface RealEstateCollateral
 */
export interface RealEstateCollateral extends Collateral {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof RealEstateCollateral
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {Address}
     * @memberof RealEstateCollateral
     */
    'address'?: Address;
    /**
     * 
     * @type {number}
     * @memberof RealEstateCollateral
     */
    'areaSquare'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateral
     */
    'certificateNumber': string;
    /**
     * 
     * @type {Array<CollateralCoOwner>}
     * @memberof RealEstateCollateral
     */
    'coOwners': Array<CollateralCoOwner>;
    /**
     * 
     * @type {number}
     * @memberof RealEstateCollateral
     */
    'commentRoomId': number;
    /**
     * 
     * @type {IdEntry}
     * @memberof RealEstateCollateral
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateral
     */
    'establishedDate'?: string;
    /**
     * 
     * @type {EstatePurpose}
     * @memberof RealEstateCollateral
     */
    'estatePurpose'?: EstatePurpose;
    /**
     * 
     * @type {Evaluation}
     * @memberof RealEstateCollateral
     */
    'evaluation'?: Evaluation;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateral
     */
    'governanceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateral
     */
    'governmentCertificateNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealEstateCollateral
     */
    'id': number;
    /**
     * 
     * @type {Array<Insurance>}
     * @memberof RealEstateCollateral
     */
    'insurances': Array<Insurance>;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateCollateral
     */
    'isPledged'?: boolean;
    /**
     * 
     * @type {LandInformation}
     * @memberof RealEstateCollateral
     */
    'landInformation'?: LandInformation;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof RealEstateCollateral
     */
    'loans': Array<IdNameEntry>;
    /**
     * 
     * @type {NumberOfRooms}
     * @memberof RealEstateCollateral
     */
    'numberOfRooms'?: NumberOfRooms;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof RealEstateCollateral
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateral
     */
    'registeredDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateral
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof RealEstateCollateral
     */
    'subType': CollateralSubType;
    /**
     * 
     * @type {CollateralType}
     * @memberof RealEstateCollateral
     */
    'type': CollateralType;
}


/**
 * 
 * @export
 * @interface RealEstateCollateralPatch
 */
export interface RealEstateCollateralPatch extends CollateralPatch {
    /**
     * 
     * @type {AddressUpdate}
     * @memberof RealEstateCollateralPatch
     */
    'address': AddressUpdate;
    /**
     * 
     * @type {number}
     * @memberof RealEstateCollateralPatch
     */
    'areaSquare': number;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateralPatch
     */
    'certificateNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateralPatch
     */
    'establishedDate': string;
    /**
     * 
     * @type {EstatePurpose}
     * @memberof RealEstateCollateralPatch
     */
    'estatePurpose': EstatePurpose;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateralPatch
     */
    'governanceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateralPatch
     */
    'governmentCertificateNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateCollateralPatch
     */
    'isPledged'?: boolean;
    /**
     * 
     * @type {NumberOfRooms}
     * @memberof RealEstateCollateralPatch
     */
    'numberOfRooms': NumberOfRooms;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof RealEstateCollateralPatch
     */
    'ownershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateralPatch
     */
    'registeredDate': string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateCollateralPatch
     */
    'registrationNumber': string;
}


/**
 * 
 * @export
 * @interface RealEstateLandCollateralPatch
 */
export interface RealEstateLandCollateralPatch extends CollateralPatch {
    /**
     * 
     * @type {string}
     * @memberof RealEstateLandCollateralPatch
     */
    'cadastralNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateLandCollateralPatch
     */
    'certificateEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateLandCollateralPatch
     */
    'certificateNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateLandCollateralPatch
     */
    'certificateStartDate'?: string;
    /**
     * 
     * @type {CoOwnerCount}
     * @memberof RealEstateLandCollateralPatch
     */
    'coOwnerCount'?: CoOwnerCount;
    /**
     * 
     * @type {CollateralOwnershipType}
     * @memberof RealEstateLandCollateralPatch
     */
    'collateralOwnershipType'?: CollateralOwnershipType;
    /**
     * 
     * @type {number}
     * @memberof RealEstateLandCollateralPatch
     */
    'landSquare'?: number;
    /**
     * 
     * @type {LandOwnershipType}
     * @memberof RealEstateLandCollateralPatch
     */
    'ownershipType'?: LandOwnershipType;
    /**
     * 
     * @type {string}
     * @memberof RealEstateLandCollateralPatch
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {UseOfLand}
     * @memberof RealEstateLandCollateralPatch
     */
    'useOfLand'?: UseOfLand;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ReferenceRelationship = {
    ACCOUNTANT: 'ACCOUNTANT',
    BOARD_MEMBER: 'BOARD_MEMBER',
    BROTHER: 'BROTHER',
    CEO: 'CEO',
    COLLEAGUE: 'COLLEAGUE',
    COMMON_LAW_PARTNER: 'COMMON_LAW_PARTNER',
    COUSIN: 'COUSIN',
    DAUGHTER: 'DAUGHTER',
    DAUGHTER_IN_LAW: 'DAUGHTER_IN_LAW',
    FATHER: 'FATHER',
    FATHER_IN_LAW: 'FATHER_IN_LAW',
    FINAL_OWNER: 'FINAL_OWNER',
    FINANCIAL_AUTHORIZED_SIGNER: 'FINANCIAL_AUTHORIZED_SIGNER',
    FRIEND: 'FRIEND',
    GRANDCHILD: 'GRANDCHILD',
    GRANDFATHER: 'GRANDFATHER',
    GRANDMOTHER: 'GRANDMOTHER',
    HUMAN_RESOURCE: 'HUMAN_RESOURCE',
    HUSBAND: 'HUSBAND',
    LEGAL_ENTITY: 'LEGAL_ENTITY',
    MOTHER: 'MOTHER',
    MOTHER_IN_LAW: 'MOTHER_IN_LAW',
    OTHER_EMPLOYEES: 'OTHER_EMPLOYEES',
    RELATED_PERSON: 'RELATED_PERSON',
    SHAREHOLDER: 'SHAREHOLDER',
    SIBLING: 'SIBLING',
    SISTER: 'SISTER',
    SON: 'SON',
    SON_IN_LAW: 'SON_IN_LAW',
    WIFE: 'WIFE'
} as const;

export type ReferenceRelationship = typeof ReferenceRelationship[keyof typeof ReferenceRelationship];


/**
 * 
 * @export
 * @enum {string}
 */

export const RelationToNbfi = {
    NOT_RELATED: 'NOT_RELATED',
    OTHER: 'OTHER',
    RELATED_TO_BOARD_MEMBER: 'RELATED_TO_BOARD_MEMBER',
    RELATED_TO_OTHER_AUTHORIZED_OFFICIAL: 'RELATED_TO_OTHER_AUTHORIZED_OFFICIAL',
    RELATED_TO_OTHER_EMPLOYEE: 'RELATED_TO_OTHER_EMPLOYEE',
    RELATED_TO_STAKEHOLDER: 'RELATED_TO_STAKEHOLDER'
} as const;

export type RelationToNbfi = typeof RelationToNbfi[keyof typeof RelationToNbfi];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepaymentAllocationRule = {
    BANK: 'BANK',
    GENERAL: 'GENERAL'
} as const;

export type RepaymentAllocationRule = typeof RepaymentAllocationRule[keyof typeof RepaymentAllocationRule];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepaymentCalculationMethod = {
    ANNUITY: 'ANNUITY',
    NOT_ANNUITY: 'NOT_ANNUITY'
} as const;

export type RepaymentCalculationMethod = typeof RepaymentCalculationMethod[keyof typeof RepaymentCalculationMethod];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepaymentFrequency = {
    BI_WEEKLY: 'BI_WEEKLY',
    MONTHLY: 'MONTHLY',
    ONCE_PER_SEASON: 'ONCE_PER_SEASON',
    ONE_TIME: 'ONE_TIME',
    WEEKLY: 'WEEKLY'
} as const;

export type RepaymentFrequency = typeof RepaymentFrequency[keyof typeof RepaymentFrequency];


/**
 * @type RequestFileRequest
 * @export
 */
export type RequestFileRequest = NewMasterAgreementRequest | NewPledgedApplicationAgreementRequest;

/**
 * 
 * @export
 * @enum {string}
 */

export const RestrictionCause = {
    PEP: 'PEP',
    OTHER: 'OTHER'
} as const;

export type RestrictionCause = typeof RestrictionCause[keyof typeof RestrictionCause];


/**
 * 
 * @export
 * @interface RestrictionListEntry
 */
export interface RestrictionListEntry {
    /**
     * 
     * @type {RestrictionCause}
     * @memberof RestrictionListEntry
     */
    'cause': RestrictionCause;
    /**
     * 
     * @type {string}
     * @memberof RestrictionListEntry
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestrictionListEntry
     */
    'createdAt'?: string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof RestrictionListEntry
     */
    'createdBy'?: IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof RestrictionListEntry
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof RestrictionListEntry
     */
    'firstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof RestrictionListEntry
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RestrictionListEntry
     */
    'lastName'?: string;
    /**
     * 
     * @type {RestrictionListType}
     * @memberof RestrictionListEntry
     */
    'listType': RestrictionListType;
    /**
     * 
     * @type {string}
     * @memberof RestrictionListEntry
     */
    'registrationNumber': string;
    /**
     * 
     * @type {RestrictionType}
     * @memberof RestrictionListEntry
     */
    'type': RestrictionType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RestrictionListType = {
    GREY: 'GREY',
    BLACK: 'BLACK'
} as const;

export type RestrictionListType = typeof RestrictionListType[keyof typeof RestrictionListType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RestrictionType = {
    MANUAL: 'MANUAL',
    AUTOMATED: 'AUTOMATED'
} as const;

export type RestrictionType = typeof RestrictionType[keyof typeof RestrictionType];


/**
 * 
 * @export
 * @interface RestructuringReviewRequest
 */
export interface RestructuringReviewRequest {
    /**
     * 
     * @type {boolean}
     * @memberof RestructuringReviewRequest
     */
    'approved': boolean;
    /**
     * 
     * @type {number}
     * @memberof RestructuringReviewRequest
     */
    'operationId': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RiskPriceColumnKey = {
    SCORE: 'SCORE',
    TENOR: 'TENOR'
} as const;

export type RiskPriceColumnKey = typeof RiskPriceColumnKey[keyof typeof RiskPriceColumnKey];


/**
 * 
 * @export
 * @interface RiskPriceColumnMetadata
 */
export interface RiskPriceColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof RiskPriceColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {RiskPriceColumnKey}
     * @memberof RiskPriceColumnMetadata
     */
    'key'?: RiskPriceColumnKey;
    /**
     * 
     * @type {FromToPairBigDecimal}
     * @memberof RiskPriceColumnMetadata
     */
    'payload'?: FromToPairBigDecimal;
}


/**
 * 
 * @export
 * @interface RiskPriceDto
 */
export interface RiskPriceDto extends Settings {
    /**
     * 
     * @type {Array<RiskPriceColumnMetadata>}
     * @memberof RiskPriceDto
     */
    'columns'?: Array<RiskPriceColumnMetadata>;
    /**
     * 
     * @type {Array<RiskPriceRow>}
     * @memberof RiskPriceDto
     */
    'rows'?: Array<RiskPriceRow>;
}
/**
 * 
 * @export
 * @interface RiskPriceRow
 */
export interface RiskPriceRow {
    /**
     * 
     * @type {FromToPairBigDecimal}
     * @memberof RiskPriceRow
     */
    'score'?: FromToPairBigDecimal;
    /**
     * 
     * @type {Array<number>}
     * @memberof RiskPriceRow
     */
    'values'?: Array<number>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RiskyGroup = {
    UNDER_22_YEARS_OLD: 'UNDER_22_YEARS_OLD',
    HAS_BUSINESS_INCOME: 'HAS_BUSINESS_INCOME',
    HAS_FINANCIAL_AND_INSURANCE_ACTIVITIES: 'HAS_FINANCIAL_AND_INSURANCE_ACTIVITIES',
    IS_MALE: 'IS_MALE'
} as const;

export type RiskyGroup = typeof RiskyGroup[keyof typeof RiskyGroup];


/**
 * 
 * @export
 * @enum {string}
 */

export const RiskyGroupColumnKey = {
    RISKY_GROUP: 'RISKY_GROUP',
    PRODUCT_TYPE: 'PRODUCT_TYPE'
} as const;

export type RiskyGroupColumnKey = typeof RiskyGroupColumnKey[keyof typeof RiskyGroupColumnKey];


/**
 * 
 * @export
 * @interface RiskyGroupColumnMetadata
 */
export interface RiskyGroupColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof RiskyGroupColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {RiskyGroupColumnKey}
     * @memberof RiskyGroupColumnMetadata
     */
    'key'?: RiskyGroupColumnKey;
    /**
     * 
     * @type {ProductTypeWithSubtype}
     * @memberof RiskyGroupColumnMetadata
     */
    'payload'?: ProductTypeWithSubtype;
}


/**
 * 
 * @export
 * @interface RiskyGroupDto
 */
export interface RiskyGroupDto extends Settings {
    /**
     * 
     * @type {Array<RiskyGroupColumnMetadata>}
     * @memberof RiskyGroupDto
     */
    'columns'?: Array<RiskyGroupColumnMetadata>;
    /**
     * 
     * @type {Array<RiskyGroupRow>}
     * @memberof RiskyGroupDto
     */
    'rows'?: Array<RiskyGroupRow>;
}
/**
 * 
 * @export
 * @interface RiskyGroupRow
 */
export interface RiskyGroupRow {
    /**
     * 
     * @type {RiskyGroup}
     * @memberof RiskyGroupRow
     */
    'riskyGroup'?: RiskyGroup;
    /**
     * 
     * @type {Array<number>}
     * @memberof RiskyGroupRow
     */
    'values'?: Array<number>;
}


/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {Array<string>}
     * @memberof Role
     */
    'authorities': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 
     * @type {StartPage}
     * @memberof Role
     */
    'startPage': StartPage;
}


/**
 * 
 * @export
 * @interface RoleCreated
 */
export interface RoleCreated {
    /**
     * 
     * @type {number}
     * @memberof RoleCreated
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface RoleListItem
 */
export interface RoleListItem {
    /**
     * 
     * @type {string}
     * @memberof RoleListItem
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoleListItem
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RoleListItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleListItem
     */
    'startPage'?: string;
}
/**
 * 
 * @export
 * @interface RoleOption
 */
export interface RoleOption {
    /**
     * 
     * @type {number}
     * @memberof RoleOption
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RoleOption
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SalaryIncomeColumnKey = {
    RULES: 'RULES',
    NUMBER_OF_MONTHS: 'NUMBER_OF_MONTHS',
    RESTRICTION_AMOUNT: 'RESTRICTION_AMOUNT'
} as const;

export type SalaryIncomeColumnKey = typeof SalaryIncomeColumnKey[keyof typeof SalaryIncomeColumnKey];


/**
 * 
 * @export
 * @interface SalaryIncomeColumnMetadata
 */
export interface SalaryIncomeColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof SalaryIncomeColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {SalaryIncomeColumnKey}
     * @memberof SalaryIncomeColumnMetadata
     */
    'key'?: SalaryIncomeColumnKey;
    /**
     * 
     * @type {object}
     * @memberof SalaryIncomeColumnMetadata
     */
    'payload'?: object;
}


/**
 * 
 * @export
 * @interface SalaryIncomeDetailsResponse
 */
export interface SalaryIncomeDetailsResponse {
    /**
     * 
     * @type {Array<CustomerDecision>}
     * @memberof SalaryIncomeDetailsResponse
     */
    'decisions': Array<CustomerDecision>;
    /**
     * 
     * @type {Array<SalaryIncomeListEntry>}
     * @memberof SalaryIncomeDetailsResponse
     */
    'incomeData'?: Array<SalaryIncomeListEntry>;
    /**
     * 
     * @type {number}
     * @memberof SalaryIncomeDetailsResponse
     */
    'monthlyIncome'?: number;
    /**
     * 
     * @type {IncomeCheckStatus}
     * @memberof SalaryIncomeDetailsResponse
     */
    'status'?: IncomeCheckStatus;
}


/**
 * 
 * @export
 * @interface SalaryIncomeDto
 */
export interface SalaryIncomeDto extends Settings {
    /**
     * 
     * @type {Array<SalaryIncomeColumnMetadata>}
     * @memberof SalaryIncomeDto
     */
    'columns'?: Array<SalaryIncomeColumnMetadata>;
    /**
     * 
     * @type {Array<SalaryIncomeRow>}
     * @memberof SalaryIncomeDto
     */
    'rows'?: Array<SalaryIncomeRow>;
}
/**
 * 
 * @export
 * @interface SalaryIncomeListEntry
 */
export interface SalaryIncomeListEntry {
    /**
     * 
     * @type {string}
     * @memberof SalaryIncomeListEntry
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SalaryIncomeListEntry
     */
    'district'?: string;
    /**
     * 
     * @type {string}
     * @memberof SalaryIncomeListEntry
     */
    'entity'?: string;
    /**
     * 
     * @type {number}
     * @memberof SalaryIncomeListEntry
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SalaryIncomeListEntry
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof SalaryIncomeListEntry
     */
    'salary'?: number;
    /**
     * 
     * @type {number}
     * @memberof SalaryIncomeListEntry
     */
    'tax'?: number;
    /**
     * 
     * @type {number}
     * @memberof SalaryIncomeListEntry
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @interface SalaryIncomeRow
 */
export interface SalaryIncomeRow {
    /**
     * 
     * @type {number}
     * @memberof SalaryIncomeRow
     */
    'numberOfMonths'?: number;
    /**
     * 
     * @type {number}
     * @memberof SalaryIncomeRow
     */
    'restrictionAmount'?: number;
    /**
     * 
     * @type {SalaryIncomeSettingRule}
     * @memberof SalaryIncomeRow
     */
    'salaryIncomeSettingRule': SalaryIncomeSettingRule;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SalaryIncomeSettingRule = {
    SALARY_INCOME_MUST_CONTAIN_DATA_FOR_AT_LEAST_X_LAST_MONTHS_CURRENT_MONTH_IS_NOT_INCLUDED: 'SALARY_INCOME_MUST_CONTAIN_DATA_FOR_AT_LEAST_X_LAST_MONTHS_CURRENT_MONTH_IS_NOT_INCLUDED',
    SALARY_FOR_THE_LAST_X_MONTHS_CURRENT_MONTH_IS_NOT_INCLUDED_MUST_BE_REPAID_BY_THE_SAME_ENTITY_ENTITIES_OF_THE_SAME_CUSTOMER_GROUP: 'SALARY_FOR_THE_LAST_X_MONTHS_CURRENT_MONTH_IS_NOT_INCLUDED_MUST_BE_REPAID_BY_THE_SAME_ENTITY_ENTITIES_OF_THE_SAME_CUSTOMER_GROUP',
    THERE_ARE_NO_MISSED_MONTHS_FOR_LAST_X_MONTHS: 'THERE_ARE_NO_MISSED_MONTHS_FOR_LAST_X_MONTHS',
    THE_AVERAGE_INCOME_FOR_THE_LAST_X_MONTHS_MUST_BE_GREATER_THEN_MNT: 'THE_AVERAGE_INCOME_FOR_THE_LAST_X_MONTHS_MUST_BE_GREATER_THEN_MNT',
    THE_AVERAGE_INCOME_FOR_THE_LAST_2_MONTHS_MUST_BE_GREATER_THEN_X_MNT: 'THE_AVERAGE_INCOME_FOR_THE_LAST_2_MONTHS_MUST_BE_GREATER_THEN_X_MNT',
    THERE_ARE_NO_MONTHS_THAT_WERE_REPAID_VOLUNTARILY_FOR_THE_LAST_X_MONTHS: 'THERE_ARE_NO_MONTHS_THAT_WERE_REPAID_VOLUNTARILY_FOR_THE_LAST_X_MONTHS'
} as const;

export type SalaryIncomeSettingRule = typeof SalaryIncomeSettingRule[keyof typeof SalaryIncomeSettingRule];


/**
 * 
 * @export
 * @enum {string}
 */

export const SchedulePeriodStatus = {
    NOT_OVERDUE: 'NOT_OVERDUE',
    REPAID: 'REPAID',
    OVERDUE: 'OVERDUE',
    WRITTEN_OFF: 'WRITTEN_OFF'
} as const;

export type SchedulePeriodStatus = typeof SchedulePeriodStatus[keyof typeof SchedulePeriodStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const SettingType = {
    SALARY_INCOME: 'SALARY_INCOME',
    CIB: 'CIB',
    AGE: 'AGE',
    CREDIT_SCORING: 'CREDIT_SCORING',
    SEX_AGE: 'SEX_AGE',
    EDUCATION: 'EDUCATION',
    MONTHLY_INCOME_FOR_DTI: 'MONTHLY_INCOME_FOR_DTI',
    WORKING_STABILITY: 'WORKING_STABILITY',
    LOAN_HISTORY: 'LOAN_HISTORY',
    CURRENT_LOANS: 'CURRENT_LOANS',
    REAL_ESTATE: 'REAL_ESTATE',
    CAR_ESTATE: 'CAR_ESTATE',
    RISK_PRICE: 'RISK_PRICE',
    RISKY_GROUP: 'RISKY_GROUP',
    TRUST_DEFAULT_PRODUCT: 'TRUST_DEFAULT_PRODUCT',
    BNPL_RISK_COEFFICIENT: 'BNPL_RISK_COEFFICIENT',
    BNPL_INCOME_AMOUNT_RANGE: 'BNPL_INCOME_AMOUNT_RANGE',
    COLLECTION_ASSIGNMENT: 'COLLECTION_ASSIGNMENT'
} as const;

export type SettingType = typeof SettingType[keyof typeof SettingType];


/**
 * 
 * @export
 * @interface Settings
 */
export interface Settings {
    /**
     * 
     * @type {string}
     * @memberof Settings
     */
    'type': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Sex = {
    MALE: 'MALE',
    FEMALE: 'FEMALE'
} as const;

export type Sex = typeof Sex[keyof typeof Sex];


/**
 * 
 * @export
 * @interface SexAgeScore
 */
export interface SexAgeScore {
    /**
     * 
     * @type {FromToPairInteger}
     * @memberof SexAgeScore
     */
    'age'?: FromToPairInteger;
    /**
     * 
     * @type {number}
     * @memberof SexAgeScore
     */
    'score'?: number;
    /**
     * 
     * @type {Sex}
     * @memberof SexAgeScore
     */
    'sex'?: Sex;
}


/**
 * 
 * @export
 * @interface SexAgeScoreCustomerResult
 */
export interface SexAgeScoreCustomerResult {
    /**
     * 
     * @type {number}
     * @memberof SexAgeScoreCustomerResult
     */
    'customerAge'?: number;
    /**
     * 
     * @type {Sex}
     * @memberof SexAgeScoreCustomerResult
     */
    'customerSex'?: Sex;
    /**
     * 
     * @type {SexAgeScore}
     * @memberof SexAgeScoreCustomerResult
     */
    'sexAgeScoreSetting'?: SexAgeScore;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SexAgeScoringColumnKey = {
    SEX: 'SEX',
    AGE: 'AGE'
} as const;

export type SexAgeScoringColumnKey = typeof SexAgeScoringColumnKey[keyof typeof SexAgeScoringColumnKey];


/**
 * 
 * @export
 * @interface SexAgeScoringColumnMetadata
 */
export interface SexAgeScoringColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof SexAgeScoringColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {SexAgeScoringColumnKey}
     * @memberof SexAgeScoringColumnMetadata
     */
    'key'?: SexAgeScoringColumnKey;
    /**
     * 
     * @type {FromToPairInteger}
     * @memberof SexAgeScoringColumnMetadata
     */
    'payload'?: FromToPairInteger;
}


/**
 * 
 * @export
 * @interface SexAgeScoringRow
 */
export interface SexAgeScoringRow {
    /**
     * 
     * @type {Array<number>}
     * @memberof SexAgeScoringRow
     */
    'scores'?: Array<number>;
    /**
     * 
     * @type {Sex}
     * @memberof SexAgeScoringRow
     */
    'sex'?: Sex;
}


/**
 * 
 * @export
 * @interface SexAgeScoringSettingsDto
 */
export interface SexAgeScoringSettingsDto extends Settings {
    /**
     * 
     * @type {Array<SexAgeScoringColumnMetadata>}
     * @memberof SexAgeScoringSettingsDto
     */
    'columns'?: Array<SexAgeScoringColumnMetadata>;
    /**
     * 
     * @type {Array<SexAgeScoringRow>}
     * @memberof SexAgeScoringSettingsDto
     */
    'rows'?: Array<SexAgeScoringRow>;
}
/**
 * 
 * @export
 * @interface SocialMediaLink
 */
export interface SocialMediaLink {
    /**
     * 
     * @type {string}
     * @memberof SocialMediaLink
     */
    'platformName': string;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaLink
     */
    'profileLink': string;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaLink
     */
    'profileName': string;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StartPage = {
    APPLICATIONS: 'APPLICATIONS',
    BANK_STATEMENTS: 'BANK_STATEMENTS',
    BRANCHES: 'BRANCHES',
    INDIVIDUALS: 'INDIVIDUALS',
    LOANS: 'LOANS',
    PRODUCTS: 'PRODUCTS',
    ROLES: 'ROLES',
    TASKS: 'TASKS',
    USERS: 'USERS'
} as const;

export type StartPage = typeof StartPage[keyof typeof StartPage];


/**
 * @type StartProcessRequest
 * @export
 */
export type StartProcessRequest = NewAutoAssignDebtCollectionProcess | NewBnplReopenProcess | NewBoUserDeactivationProcess | NewBusinessIncomeAssessmentProcess | NewCancelMerchantInvoiceProcess | NewCibLoanReportProcess | NewCibStatusDecreasedProcess | NewCibStatusWillDecreaseProcess | NewGeneratePledgedApplicationAgreementsProcess | NewIndividualsBlacklistingProcess | NewLoanApplicationProcess | NewLoanFeeProcess | NewPledgedLoanApplicationProcess | NewReassignLoanApplicationProcess | NewRecalculateOfferedBnplProductLimitsProcess | NewTrustPledgedLoanApplicationProcess | NewUnassignBoUsersTasksProcess | NewUpdateMerchantInvoiceProcess;

/**
 * 
 * @export
 * @interface StartProcessUserAction
 */
export interface StartProcessUserAction extends UserAction {
    /**
     * 
     * @type {ProcessName}
     * @memberof StartProcessUserAction
     */
    'actionType': ProcessName;
}


/**
 * 
 * @export
 * @interface StatusInterval
 */
export interface StatusInterval {
    /**
     * 
     * @type {number}
     * @memberof StatusInterval
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof StatusInterval
     */
    'to': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SteeringWheel = {
    RIGHT: 'RIGHT',
    LEFT: 'LEFT'
} as const;

export type SteeringWheel = typeof SteeringWheel[keyof typeof SteeringWheel];


/**
 * @type SubmitTaskDecisionRequest
 * @export
 */
export type SubmitTaskDecisionRequest = N110TaskDecisionAddLoanConditions | N110TaskDecisionDecline | N110TaskDecisionRequestInvoiceChange | N111TaskDecisionApprove | N111TaskDecisionDecline | N111TaskDecisionRequestAdjustments | N112TaskDecisionChooseUser | N113TaskDecisionApprove | N113TaskDecisionRequestAdjustments | N114TaskDecisionCancel | N11TaskDecisionApprove | N11TaskDecisionDecline | N12TaskDecisionChooseUser | N13TaskDecisionAddLoanConditions | N14TaskDecisionApprove | N14TaskDecisionDecline | N14TaskDecisionRequestAdjustments | N15TaskDecisionChooseUser | N16TaskDecisionAddLoanConditions | N16TaskDecisionDecline | N17TaskDecisionCancel | N17TaskDecisionChooseUser | N18TaskDecisionAddLoanConditions | N18TaskDecisionDecline | N18TaskDecisionRequestAdjustments | N19TaskDecisionChooseUser | N21TaskDecisionApprove | N21TaskDecisionDecline | N22TaskDecisionApprove | N22TaskDecisionRequestAdjustments | N23TaskDecisionApprove | N23TaskDecisionDecline | N23TaskDecisionRequestAdjustments | N24TaskDecisionApprove | N31TaskDecisionCancel | N31TaskDecisionSign | N41TaskDecisionChooseUser | N51TaskDecisionCancel | N51TaskDecisionSign | N52TaskDecisionCoBorrowerCancel | N52TaskDecisionCoBorrowerSign;

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TaskActionType = {
    APPROVE: 'APPROVE',
    DECLINE: 'DECLINE',
    DECLINE_PLEDGED_LOAN_APPLICATION: 'DECLINE_PLEDGED_LOAN_APPLICATION'
} as const;

export type TaskActionType = typeof TaskActionType[keyof typeof TaskActionType];


/**
 * 
 * @export
 * @interface TaskCandidateBoUser
 */
export interface TaskCandidateBoUser {
    /**
     * 
     * @type {UserActivity}
     * @memberof TaskCandidateBoUser
     */
    'activity': UserActivity;
    /**
     * 
     * @type {string}
     * @memberof TaskCandidateBoUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof TaskCandidateBoUser
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof TaskCandidateBoUser
     */
    'roleName': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof TaskCandidateBoUser
     */
    'user': IdNameEntry;
}


/**
 * 
 * @export
 * @interface TaskDecision
 */
export interface TaskDecision {
    /**
     * 
     * @type {string}
     * @memberof TaskDecision
     */
    'type': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TaskDecisionType = {
    N1_1_APPROVE: 'N1_1_APPROVE',
    N1_1_DECLINE: 'N1_1_DECLINE',
    N1_2_CHOOSE_LAAT_USER: 'N1_2_CHOOSE_LAAT_USER',
    N1_3_ADD_LOAN_CONDITIONS: 'N1_3_ADD_LOAN_CONDITIONS',
    N1_4_APPROVE: 'N1_4_APPROVE',
    N1_4_DECLINE: 'N1_4_DECLINE',
    N1_4_REQUEST_ADJUSTMENTS: 'N1_4_REQUEST_ADJUSTMENTS',
    N1_5_CHOOSE_DMT_USERS: 'N1_5_CHOOSE_DMT_USERS',
    N1_6_ADD_LOAN_CONDITIONS: 'N1_6_ADD_LOAN_CONDITIONS',
    N1_6_DECLINE: 'N1_6_DECLINE',
    N1_7_CANCEL: 'N1_7_CANCEL',
    N1_7_CHOOSE_DMT_USER: 'N1_7_CHOOSE_DMT_USER',
    N1_8_ADD_LOAN_CONDITIONS: 'N1_8_ADD_LOAN_CONDITIONS',
    N1_8_DECLINE: 'N1_8_DECLINE',
    N1_8_REQUEST_ADJUSTMENTS: 'N1_8_REQUEST_ADJUSTMENTS',
    N1_9_CHOOSE_RM: 'N1_9_CHOOSE_RM',
    N1_10_ADD_LOAN_CONDITIONS: 'N1_10_ADD_LOAN_CONDITIONS',
    N1_10_DECLINE: 'N1_10_DECLINE',
    N1_10_REQUEST_INVOICE_CHANGE: 'N1_10_REQUEST_INVOICE_CHANGE',
    N1_11_APPROVE: 'N1_11_APPROVE',
    N1_11_DECLINE: 'N1_11_DECLINE',
    N1_11_REQUEST_ADJUSTMENTS: 'N1_11_REQUEST_ADJUSTMENTS',
    N1_12_CHOOSE_ACCOUNTANT: 'N1_12_CHOOSE_ACCOUNTANT',
    N1_13_APPROVE: 'N1_13_APPROVE',
    N1_13_REQUEST_ADJUSTMENTS: 'N1_13_REQUEST_ADJUSTMENTS',
    N1_14_CANCEL: 'N1_14_CANCEL',
    N2_1_APPROVE: 'N2_1_APPROVE',
    N2_1_DECLINE: 'N2_1_DECLINE',
    N2_2_APPROVE: 'N2_2_APPROVE',
    N2_2_REQUEST_ADJUSTMENTS: 'N2_2_REQUEST_ADJUSTMENTS',
    N2_3_APPROVE: 'N2_3_APPROVE',
    N2_3_DECLINE: 'N2_3_DECLINE',
    N2_3_REQUEST_ADJUSTMENTS: 'N2_3_REQUEST_ADJUSTMENTS',
    N2_4_APPROVE: 'N2_4_APPROVE',
    N3_1_CANCEL: 'N3_1_CANCEL',
    N3_1_SIGN: 'N3_1_SIGN',
    N4_1_CHOOSE_ASSIGNEE: 'N4_1_CHOOSE_ASSIGNEE',
    N5_1_CANCEL: 'N5_1_CANCEL',
    N5_1_SIGN: 'N5_1_SIGN',
    N5_2_CO_BORROWER_CANCEL: 'N5_2_CO_BORROWER_CANCEL',
    N5_2_CO_BORROWER_SIGN: 'N5_2_CO_BORROWER_SIGN'
} as const;

export type TaskDecisionType = typeof TaskDecisionType[keyof typeof TaskDecisionType];


/**
 * 
 * @export
 * @interface TaskMetadata
 */
export interface TaskMetadata {
    /**
     * 
     * @type {Array<DebtCollectionActionsInner>}
     * @memberof TaskMetadata
     */
    'actions': Array<DebtCollectionActionsInner>;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof TaskMetadata
     */
    'assignee'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof TaskMetadata
     */
    'completedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskMetadata
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMetadata
     */
    'id': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof TaskMetadata
     */
    'individual'?: IdNameEntry;
    /**
     * 
     * @type {TaskName}
     * @memberof TaskMetadata
     */
    'name': TaskName;
    /**
     * 
     * @type {Process}
     * @memberof TaskMetadata
     */
    'process': Process;
    /**
     * 
     * @type {TaskStatus}
     * @memberof TaskMetadata
     */
    'status': TaskStatus;
}


/**
 * 
 * @export
 * @interface TaskMetadataListEntry
 */
export interface TaskMetadataListEntry {
    /**
     * 
     * @type {Array<DebtCollectionActionsInner>}
     * @memberof TaskMetadataListEntry
     */
    'actions': Array<DebtCollectionActionsInner>;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof TaskMetadataListEntry
     */
    'assignee'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof TaskMetadataListEntry
     */
    'completedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskMetadataListEntry
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMetadataListEntry
     */
    'id': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof TaskMetadataListEntry
     */
    'individual'?: IdNameEntry;
    /**
     * 
     * @type {TaskName}
     * @memberof TaskMetadataListEntry
     */
    'name': TaskName;
    /**
     * 
     * @type {Process}
     * @memberof TaskMetadataListEntry
     */
    'process': Process;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof TaskMetadataListEntry
     */
    'product'?: IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof TaskMetadataListEntry
     */
    'requestedAmount'?: number;
    /**
     * 
     * @type {TaskStatus}
     * @memberof TaskMetadataListEntry
     */
    'status': TaskStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TaskName = {
    N1_1_ADD_COLLATERAL_DATA: 'N1_1_ADD_COLLATERAL_DATA',
    N1_2_CHOOSE_LAAT_USER: 'N1_2_CHOOSE_LAAT_USER',
    N1_3_LAAT_USER_ADD_ESTIMATED_CONDITIONS: 'N1_3_LAAT_USER_ADD_ESTIMATED_CONDITIONS',
    N1_4_MAKE_FINAL_DECISION: 'N1_4_MAKE_FINAL_DECISION',
    N1_5_CHOOSE_DMT_USERS: 'N1_5_CHOOSE_DMT_USERS',
    N1_6_DMT_USER_ADD_LOAN_CONDITIONS: 'N1_6_DMT_USER_ADD_LOAN_CONDITIONS',
    N1_7_CHOOSE_DMT_USER: 'N1_7_CHOOSE_DMT_USER',
    N1_8_CS_MAKE_FINAL_DECISION: 'N1_8_CS_MAKE_FINAL_DECISION',
    N1_9_CHOOSE_RM: 'N1_9_CHOOSE_RM',
    N1_10_RM_ADD_LOAN_CONDITIONS: 'N1_10_RM_ADD_LOAN_CONDITIONS',
    N1_11_RM_UPLOAD_DOCUMENTS: 'N1_11_RM_UPLOAD_DOCUMENTS',
    N1_12_CHOOSE_ACCOUNTANT: 'N1_12_CHOOSE_ACCOUNTANT',
    N1_13_ACCOUNTANT_DISBURSEMENT_DECISION: 'N1_13_ACCOUNTANT_DISBURSEMENT_DECISION',
    N1_14_INDIVIDUAL_CANCEL: 'N1_14_INDIVIDUAL_CANCEL',
    N2_1_ADD_BANK_STATEMENT_DATA: 'N2_1_ADD_BANK_STATEMENT_DATA',
    N2_2_IAT_USER_REVIEW: 'N2_2_IAT_USER_REVIEW',
    N2_3_ADD_BUSINESS_INCOME: 'N2_3_ADD_BUSINESS_INCOME',
    N2_4_FEE_REFUND: 'N2_4_FEE_REFUND',
    N3_1_INDIVIDUAL_SIGN_AGREEMENT: 'N3_1_INDIVIDUAL_SIGN_AGREEMENT',
    N4_1_CHOOSE_ASSIGNEE: 'N4_1_CHOOSE_ASSIGNEE',
    N5_1_SIGN_AGREEMENT: 'N5_1_SIGN_AGREEMENT',
    N5_2_CO_BORROWER_SIGN_AGREEMENT: 'N5_2_CO_BORROWER_SIGN_AGREEMENT'
} as const;

export type TaskName = typeof TaskName[keyof typeof TaskName];


/**
 * 
 * @export
 * @enum {string}
 */

export const TaskStatus = {
    ACTIVE: 'ACTIVE',
    APPROVED: 'APPROVED',
    COMPLETED: 'COMPLETED',
    DECLINED: 'DECLINED',
    DELETED: 'DELETED',
    REQUEST_ADJUSTMENTS: 'REQUEST_ADJUSTMENTS'
} as const;

export type TaskStatus = typeof TaskStatus[keyof typeof TaskStatus];


/**
 * 
 * @export
 * @interface TaskUserAction
 */
export interface TaskUserAction extends UserAction {
    /**
     * 
     * @type {TaskActionType}
     * @memberof TaskUserAction
     */
    'actionType': TaskActionType;
    /**
     * 
     * @type {string}
     * @memberof TaskUserAction
     */
    'processId': string;
    /**
     * 
     * @type {ProcessName}
     * @memberof TaskUserAction
     */
    'processName': ProcessName;
    /**
     * 
     * @type {string}
     * @memberof TaskUserAction
     */
    'taskId': string;
    /**
     * 
     * @type {TaskName}
     * @memberof TaskUserAction
     */
    'taskName': TaskName;
}


/**
 * 
 * @export
 * @interface TrustAccount
 */
export interface TrustAccount {
    /**
     * 
     * @type {number}
     * @memberof TrustAccount
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof TrustAccount
     */
    'interestRate': number;
}
/**
 * 
 * @export
 * @interface TrustAccountCollateral
 */
export interface TrustAccountCollateral extends Collateral {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof TrustAccountCollateral
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof TrustAccountCollateral
     */
    'amount': number;
    /**
     * 
     * @type {Array<CollateralCoOwner>}
     * @memberof TrustAccountCollateral
     */
    'coOwners': Array<CollateralCoOwner>;
    /**
     * 
     * @type {number}
     * @memberof TrustAccountCollateral
     */
    'commentRoomId': number;
    /**
     * 
     * @type {IdEntry}
     * @memberof TrustAccountCollateral
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof TrustAccountCollateral
     */
    'id': number;
    /**
     * 
     * @type {Array<Insurance>}
     * @memberof TrustAccountCollateral
     */
    'insurances': Array<Insurance>;
    /**
     * 
     * @type {number}
     * @memberof TrustAccountCollateral
     */
    'interestRate': number;
    /**
     * 
     * @type {Array<IdNameEntry>}
     * @memberof TrustAccountCollateral
     */
    'loans': Array<IdNameEntry>;
    /**
     * 
     * @type {string}
     * @memberof TrustAccountCollateral
     */
    'maturityDate': string;
    /**
     * 
     * @type {string}
     * @memberof TrustAccountCollateral
     */
    'openingDate': string;
    /**
     * 
     * @type {string}
     * @memberof TrustAccountCollateral
     */
    'purpose': string;
    /**
     * 
     * @type {CollateralSubType}
     * @memberof TrustAccountCollateral
     */
    'subType': CollateralSubType;
    /**
     * 
     * @type {number}
     * @memberof TrustAccountCollateral
     */
    'trustId': number;
    /**
     * 
     * @type {CollateralType}
     * @memberof TrustAccountCollateral
     */
    'type': CollateralType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TrustDefaultProductColumnKey = {
    TRUST_DEFAULT_PRODUCT_SETTING: 'TRUST_DEFAULT_PRODUCT_SETTING',
    PRODUCT_LINE_ID: 'PRODUCT_LINE_ID'
} as const;

export type TrustDefaultProductColumnKey = typeof TrustDefaultProductColumnKey[keyof typeof TrustDefaultProductColumnKey];


/**
 * 
 * @export
 * @interface TrustDefaultProductColumnMetadata
 */
export interface TrustDefaultProductColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof TrustDefaultProductColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {TrustDefaultProductColumnKey}
     * @memberof TrustDefaultProductColumnMetadata
     */
    'key'?: TrustDefaultProductColumnKey;
    /**
     * 
     * @type {object}
     * @memberof TrustDefaultProductColumnMetadata
     */
    'payload'?: object;
}


/**
 * 
 * @export
 * @interface TrustDefaultProductDto
 */
export interface TrustDefaultProductDto extends Settings {
    /**
     * 
     * @type {Array<TrustDefaultProductColumnMetadata>}
     * @memberof TrustDefaultProductDto
     */
    'columns'?: Array<TrustDefaultProductColumnMetadata>;
    /**
     * 
     * @type {Array<TrustDefaultProductRow>}
     * @memberof TrustDefaultProductDto
     */
    'rows'?: Array<TrustDefaultProductRow>;
}
/**
 * 
 * @export
 * @interface TrustDefaultProductRow
 */
export interface TrustDefaultProductRow {
    /**
     * 
     * @type {number}
     * @memberof TrustDefaultProductRow
     */
    'productLineId': number;
    /**
     * 
     * @type {TrustDefaultProductSetting}
     * @memberof TrustDefaultProductRow
     */
    'trustDefaultProductSetting': TrustDefaultProductSetting;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TrustDefaultProductSetting = {
    TRUST_DEFAULT_PRODUCT_LINE: 'TRUST_DEFAULT_PRODUCT_LINE'
} as const;

export type TrustDefaultProductSetting = typeof TrustDefaultProductSetting[keyof typeof TrustDefaultProductSetting];


/**
 * 
 * @export
 * @interface TrustPledgedLoan
 */
export interface TrustPledgedLoan extends Loan {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof TrustPledgedLoan
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'applicationId': number;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoan
     */
    'branchCode'?: string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof TrustPledgedLoan
     */
    'branchManager'?: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoan
     */
    'branchName': string;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoan
     */
    'cibSentDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrustPledgedLoan
     */
    'cibSentStatus': boolean;
    /**
     * 
     * @type {Array<LoanCollateralListEntry>}
     * @memberof TrustPledgedLoan
     */
    'collaterals': Array<LoanCollateralListEntry>;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'commentRoomId': number;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'commission'?: number;
    /**
     * 
     * @type {CreditBureauStatus}
     * @memberof TrustPledgedLoan
     */
    'creditBureauStatus': CreditBureauStatus;
    /**
     * 
     * @type {Customer}
     * @memberof TrustPledgedLoan
     */
    'customer': Customer;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'daysOverdue': number;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoan
     */
    'disbursementDate': string;
    /**
     * 
     * @type {IdEntry}
     * @memberof TrustPledgedLoan
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'downpayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'exceedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoan
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoan
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'id': number;
    /**
     * 
     * @type {Array<IndividualCoBorrowerListEntry>}
     * @memberof TrustPledgedLoan
     */
    'individualCoBorrowers': Array<IndividualCoBorrowerListEntry>;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'lastPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoan
     */
    'lastPaymentDate'?: string;
    /**
     * 
     * @type {Array<LegalCoBorrowerListEntry>}
     * @memberof TrustPledgedLoan
     */
    'legalCoBorrowers': Array<LegalCoBorrowerListEntry>;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoan
     */
    'loanPurpose'?: string;
    /**
     * 
     * @type {LoanStatus}
     * @memberof TrustPledgedLoan
     */
    'loanStatus': LoanStatus;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoan
     */
    'maturityDate': string;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'nextPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoan
     */
    'nextPaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'overdueAdvancePayment': number;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'principalBalance': number;
    /**
     * 
     * @type {ProductLoanInfo}
     * @memberof TrustPledgedLoan
     */
    'product': ProductLoanInfo;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof TrustPledgedLoan
     */
    'relationshipManager'?: IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'repaymentAmount': number;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof TrustPledgedLoan
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {ApplicationSource}
     * @memberof TrustPledgedLoan
     */
    'source': ApplicationSource;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoan
     */
    'term': number;
}


/**
 * 
 * @export
 * @interface TrustPledgedLoanApplication
 */
export interface TrustPledgedLoanApplication extends LoanApplication {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof TrustPledgedLoanApplication
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoanApplication
     */
    'averageIncome': number;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoanApplication
     */
    'bankAccountNumber': string;
    /**
     * 
     * @type {BankInformationDto}
     * @memberof TrustPledgedLoanApplication
     */
    'bankInformation': BankInformationDto;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoanApplication
     */
    'commentRoomId': number;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoanApplication
     */
    'creditScore': number;
    /**
     * 
     * @type {Customer}
     * @memberof TrustPledgedLoanApplication
     */
    'customer': Customer;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoanApplication
     */
    'disbursementDate'?: string;
    /**
     * 
     * @type {IdEntry}
     * @memberof TrustPledgedLoanApplication
     */
    'documentRoom': IdEntry;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoanApplication
     */
    'externalId': string;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoanApplication
     */
    'firstPaymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoanApplication
     */
    'firstRepaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoanApplication
     */
    'id': number;
    /**
     * 
     * @type {Array<CustomerIncomeType>}
     * @memberof TrustPledgedLoanApplication
     */
    'incomeTypes': Array<CustomerIncomeType>;
    /**
     * 
     * @type {Array<IndividualCoBorrowerListEntry>}
     * @memberof TrustPledgedLoanApplication
     */
    'individualCoBorrowers': Array<IndividualCoBorrowerListEntry>;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoanApplication
     */
    'interestRate': number;
    /**
     * 
     * @type {Array<LegalCoBorrowerListEntry>}
     * @memberof TrustPledgedLoanApplication
     */
    'legalCoBorrowers': Array<LegalCoBorrowerListEntry>;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoanApplication
     */
    'limitOffer': number;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoanApplication
     */
    'loanId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoanApplication
     */
    'loanPurpose': string;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoanApplication
     */
    'maturityDate': string;
    /**
     * 
     * @type {ProductBrief}
     * @memberof TrustPledgedLoanApplication
     */
    'product'?: ProductBrief;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof TrustPledgedLoanApplication
     */
    'relationshipManager': IdNameEntry;
    /**
     * 
     * @type {RepaymentFrequency}
     * @memberof TrustPledgedLoanApplication
     */
    'repaymentFrequency': RepaymentFrequency;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoanApplication
     */
    'requestedAmount': number;
    /**
     * 
     * @type {string}
     * @memberof TrustPledgedLoanApplication
     */
    'requestedAt': string;
    /**
     * 
     * @type {number}
     * @memberof TrustPledgedLoanApplication
     */
    'requestedTerm': number;
    /**
     * 
     * @type {ApplicationSource}
     * @memberof TrustPledgedLoanApplication
     */
    'source': ApplicationSource;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof TrustPledgedLoanApplication
     */
    'status': LoanApplicationStatus;
    /**
     * 
     * @type {TrustAccount}
     * @memberof TrustPledgedLoanApplication
     */
    'trustAccount': TrustAccount;
}


/**
 * 
 * @export
 * @interface TrustProduct
 */
export interface TrustProduct extends Product {
    /**
     * 
     * @type {Array<UserAction>}
     * @memberof TrustProduct
     */
    'actions': Array<UserAction>;
    /**
     * 
     * @type {boolean}
     * @memberof TrustProduct
     */
    'excludedFromOffer': boolean;
    /**
     * 
     * @type {number}
     * @memberof TrustProduct
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TrustProduct
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof TrustProduct
     */
    'maxLoanAmountPct': number;
    /**
     * 
     * @type {number}
     * @memberof TrustProduct
     */
    'minLoanAmount': number;
    /**
     * 
     * @type {string}
     * @memberof TrustProduct
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof TrustProduct
     */
    'productLineId': number;
    /**
     * 
     * @type {ProductStatus}
     * @memberof TrustProduct
     */
    'status': ProductStatus;
}


/**
 * 
 * @export
 * @interface TrustProductListEntry
 */
export interface TrustProductListEntry {
    /**
     * 
     * @type {number}
     * @memberof TrustProductListEntry
     */
    'externalId': number;
    /**
     * 
     * @type {number}
     * @memberof TrustProductListEntry
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TrustProductListEntry
     */
    'interestRate': number;
    /**
     * 
     * @type {string}
     * @memberof TrustProductListEntry
     */
    'name': string;
    /**
     * 
     * @type {ProductStatus}
     * @memberof TrustProductListEntry
     */
    'status': ProductStatus;
    /**
     * 
     * @type {number}
     * @memberof TrustProductListEntry
     */
    'version': number;
}


/**
 * 
 * @export
 * @interface TrustProductUpdate
 */
export interface TrustProductUpdate extends ProductUpdate {
    /**
     * 
     * @type {number}
     * @memberof TrustProductUpdate
     */
    'interestRate': number;
    /**
     * 
     * @type {number}
     * @memberof TrustProductUpdate
     */
    'maxLoanAmountPct': number;
    /**
     * 
     * @type {number}
     * @memberof TrustProductUpdate
     */
    'minLoanAmount': number;
    /**
     * 
     * @type {string}
     * @memberof TrustProductUpdate
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TypedUserAction
 */
export interface TypedUserAction extends UserAction {
    /**
     * 
     * @type {ActionType}
     * @memberof TypedUserAction
     */
    'actionType': ActionType;
    /**
     * 
     * @type {boolean}
     * @memberof TypedUserAction
     */
    'primary': boolean;
}


/**
 * 
 * @export
 * @interface UiSettings
 */
export interface UiSettings {
    /**
     * 
     * @type {UiSettingsAuthProperties}
     * @memberof UiSettings
     */
    'authentication': UiSettingsAuthProperties;
    /**
     * 
     * @type {string}
     * @memberof UiSettings
     */
    'backofficeVersion': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UiSettings
     */
    'locales': Array<string>;
    /**
     * 
     * @type {Array<MenuLink>}
     * @memberof UiSettings
     */
    'menuLinks': Array<MenuLink>;
}
/**
 * 
 * @export
 * @interface UiSettingsAuthProperties
 */
export interface UiSettingsAuthProperties {
    /**
     * 
     * @type {string}
     * @memberof UiSettingsAuthProperties
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof UiSettingsAuthProperties
     */
    'idleTimeout': string;
    /**
     * 
     * @type {string}
     * @memberof UiSettingsAuthProperties
     */
    'realm': string;
    /**
     * 
     * @type {string}
     * @memberof UiSettingsAuthProperties
     */
    'url': string;
}
/**
 * @type UpdateCoBorrowerRequest
 * @export
 */
export type UpdateCoBorrowerRequest = CoBorrowerRemoveFromLoan | CoBorrowerSetStatus | CoBorrowerUpdate;

/**
 * @type UpdateDebtCollectionRequest
 * @export
 */
export type UpdateDebtCollectionRequest = DebtCollectionLevelUpdate;

/**
 * 
 * @export
 * @interface UpdateIndividualGeneralInfoRequest
 */
export interface UpdateIndividualGeneralInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualGeneralInfoRequest
     */
    'birthDate'?: string;
    /**
     * 
     * @type {Citizenship}
     * @memberof UpdateIndividualGeneralInfoRequest
     */
    'citizenship'?: Citizenship;
    /**
     * 
     * @type {CustomerSegment}
     * @memberof UpdateIndividualGeneralInfoRequest
     */
    'customerSegment'?: CustomerSegment;
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualGeneralInfoRequest
     */
    'familyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualGeneralInfoRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualGeneralInfoRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof UpdateIndividualGeneralInfoRequest
     */
    'maritalStatus'?: MaritalStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualGeneralInfoRequest
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {Sex}
     * @memberof UpdateIndividualGeneralInfoRequest
     */
    'sex'?: Sex;
}


/**
 * 
 * @export
 * @interface UpdateIndividualLegalRepresentative
 */
export interface UpdateIndividualLegalRepresentative extends UpdateLegalRepresentative {
    /**
     * 
     * @type {number}
     * @memberof UpdateIndividualLegalRepresentative
     */
    'percentageOfShare'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualLegalRepresentative
     */
    'phoneNumber': string;
    /**
     * 
     * @type {ReferenceRelationship}
     * @memberof UpdateIndividualLegalRepresentative
     */
    'relationship': ReferenceRelationship;
}


/**
 * 
 * @export
 * @interface UpdateIndividualReferenceRequest
 */
export interface UpdateIndividualReferenceRequest {
    /**
     * 
     * @type {Array<CustomerReference>}
     * @memberof UpdateIndividualReferenceRequest
     */
    'customerReferences': Array<CustomerReference>;
}
/**
 * @type UpdateIndividualRequest
 * @export
 */
export type UpdateIndividualRequest = IndividualChangeRelationshipManager;

/**
 * 
 * @export
 * @interface UpdateLegalRepresentative
 */
export interface UpdateLegalRepresentative {
    /**
     * 
     * @type {string}
     * @memberof UpdateLegalRepresentative
     */
    'type': string;
}
/**
 * @type UpdateLegalRepresentativeRequest
 * @export
 */
export type UpdateLegalRepresentativeRequest = UpdateIndividualLegalRepresentative | UpdateNonExistingIndividualLegalRepresentative;

/**
 * @type UpdateLoanApplicationRequest
 * @export
 */
export type UpdateLoanApplicationRequest = LoanApplicationChangeRelationshipManager;

/**
 * 
 * @export
 * @interface UpdateNonExistingIndividualLegalRepresentative
 */
export interface UpdateNonExistingIndividualLegalRepresentative extends UpdateLegalRepresentative {
    /**
     * 
     * @type {Citizenship}
     * @memberof UpdateNonExistingIndividualLegalRepresentative
     */
    'citizenship': Citizenship;
    /**
     * 
     * @type {string}
     * @memberof UpdateNonExistingIndividualLegalRepresentative
     */
    'civilId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNonExistingIndividualLegalRepresentative
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNonExistingIndividualLegalRepresentative
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNonExistingIndividualLegalRepresentative
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNonExistingIndividualLegalRepresentative
     */
    'pep'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateNonExistingIndividualLegalRepresentative
     */
    'percentageOfShare'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateNonExistingIndividualLegalRepresentative
     */
    'phoneNumber': string;
    /**
     * 
     * @type {ReferenceRelationship}
     * @memberof UpdateNonExistingIndividualLegalRepresentative
     */
    'relationship': ReferenceRelationship;
}


/**
 * @type UpdateProductRequest
 * @export
 */
export type UpdateProductRequest = BnplProductUpdate | InstallmentProductUpdate | OfferExclusionUpdate | PayonProductUpdate | PledgedProductUpdate | TrustProductUpdate;

/**
 * 
 * @export
 * @interface UpdateRoleForm
 */
export interface UpdateRoleForm {
    /**
     * 
     * @type {Array<AuthorityHierarchy>}
     * @memberof UpdateRoleForm
     */
    'authorities': Array<AuthorityHierarchy>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleForm
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleForm
     */
    'name': string;
    /**
     * 
     * @type {StartPage}
     * @memberof UpdateRoleForm
     */
    'startPage': StartPage;
}


/**
 * 
 * @export
 * @interface UploadedFile
 */
export interface UploadedFile {
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    'contentType': string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    'presignedUrl': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UseOfLand = {
    FAMILY: 'FAMILY',
    INDUSTRIAL: 'INDUSTRIAL',
    SERVICE: 'SERVICE',
    TOURISM: 'TOURISM'
} as const;

export type UseOfLand = typeof UseOfLand[keyof typeof UseOfLand];


/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {Array<DebtCollectionActionsInner>}
     * @memberof User
     */
    'actions': Array<DebtCollectionActionsInner>;
    /**
     * 
     * @type {UserActivity}
     * @memberof User
     */
    'activity': UserActivity;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'branchManager': boolean;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof User
     */
    'childBranch': IdNameEntry;
    /**
     * 
     * @type {BoUserDepartment}
     * @memberof User
     */
    'department': BoUserDepartment;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof User
     */
    'parentBranch': IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'position': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'registrationCompleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'relationshipManager': boolean;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof User
     */
    'role': IdNameEntry;
    /**
     * 
     * @type {StartPage}
     * @memberof User
     */
    'startPage': StartPage;
    /**
     * 
     * @type {UserStatus}
     * @memberof User
     */
    'status': UserStatus;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'surname': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'teamsProfile': string;
}


/**
 * 
 * @export
 * @interface UserAction
 */
export interface UserAction {
    /**
     * 
     * @type {string}
     * @memberof UserAction
     */
    'type': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserActivity = {
    OFFLINE: 'OFFLINE',
    ONLINE: 'ONLINE'
} as const;

export type UserActivity = typeof UserActivity[keyof typeof UserActivity];


/**
 * 
 * @export
 * @interface UserActivityUpdate
 */
export interface UserActivityUpdate {
    /**
     * 
     * @type {UserActivity}
     * @memberof UserActivityUpdate
     */
    'activity': UserActivity;
}


/**
 * 
 * @export
 * @interface UserBrief
 */
export interface UserBrief {
    /**
     * 
     * @type {number}
     * @memberof UserBrief
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserBrief
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserListItem
 */
export interface UserListItem {
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof UserListItem
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    'phone': string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof UserListItem
     */
    'role': IdNameEntry;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserListItem
     */
    'status': UserStatus;
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    'surname': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    BLOCKED: 'BLOCKED'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const VerificationCheckDecision = {
    APPROVED: 'APPROVED',
    DECLINED: 'DECLINED',
    ERROR: 'ERROR'
} as const;

export type VerificationCheckDecision = typeof VerificationCheckDecision[keyof typeof VerificationCheckDecision];


/**
 * 
 * @export
 * @enum {string}
 */

export const VerificationCheckDecisionType = {
    MANUALLY: 'MANUALLY',
    AUTOMATICALLY: 'AUTOMATICALLY'
} as const;

export type VerificationCheckDecisionType = typeof VerificationCheckDecisionType[keyof typeof VerificationCheckDecisionType];


/**
 * 
 * @export
 * @interface WorkSchedule
 */
export interface WorkSchedule {
    /**
     * 
     * @type {WorkingDays}
     * @memberof WorkSchedule
     */
    'workingDays': WorkingDays;
    /**
     * 
     * @type {WorkingHours}
     * @memberof WorkSchedule
     */
    'workingHours': WorkingHours;
}
/**
 * 
 * @export
 * @interface WorkingDays
 */
export interface WorkingDays {
    /**
     * 
     * @type {DayOfWeek}
     * @memberof WorkingDays
     */
    'from': DayOfWeek;
    /**
     * 
     * @type {DayOfWeek}
     * @memberof WorkingDays
     */
    'to': DayOfWeek;
}


/**
 * 
 * @export
 * @interface WorkingHours
 */
export interface WorkingHours {
    /**
     * 
     * @type {HoursAndMinutes}
     * @memberof WorkingHours
     */
    'from': HoursAndMinutes;
    /**
     * 
     * @type {HoursAndMinutes}
     * @memberof WorkingHours
     */
    'to': HoursAndMinutes;
}
/**
 * 
 * @export
 * @interface WorkingStabilityScore
 */
export interface WorkingStabilityScore {
    /**
     * 
     * @type {boolean}
     * @memberof WorkingStabilityScore
     */
    'decreased'?: boolean;
    /**
     * 
     * @type {IncomeType}
     * @memberof WorkingStabilityScore
     */
    'incomeType'?: IncomeType;
    /**
     * 
     * @type {number}
     * @memberof WorkingStabilityScore
     */
    'score'?: number;
    /**
     * 
     * @type {FromToPairInteger}
     * @memberof WorkingStabilityScore
     */
    'workingMonth'?: FromToPairInteger;
    /**
     * 
     * @type {FromToPairInteger}
     * @memberof WorkingStabilityScore
     */
    'workingMonthContinuously'?: FromToPairInteger;
}


/**
 * 
 * @export
 * @interface WorkingStabilityScoreCustomerResult
 */
export interface WorkingStabilityScoreCustomerResult {
    /**
     * 
     * @type {boolean}
     * @memberof WorkingStabilityScoreCustomerResult
     */
    'customerIncomeDecreased'?: boolean;
    /**
     * 
     * @type {IncomeType}
     * @memberof WorkingStabilityScoreCustomerResult
     */
    'customerIncomeType'?: IncomeType;
    /**
     * 
     * @type {number}
     * @memberof WorkingStabilityScoreCustomerResult
     */
    'customerWorkingMonth'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingStabilityScoreCustomerResult
     */
    'customerWorkingMonthContinuously'?: number;
    /**
     * 
     * @type {WorkingStabilityScore}
     * @memberof WorkingStabilityScoreCustomerResult
     */
    'workingStabilityScoreSetting'?: WorkingStabilityScore;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const WorkingStabilityScoringColumnKey = {
    INCOME_TYPE: 'INCOME_TYPE',
    DECREASED: 'DECREASED',
    WORKING_MONTH: 'WORKING_MONTH',
    WORKING_MONTH_CONTINUOUSLY: 'WORKING_MONTH_CONTINUOUSLY'
} as const;

export type WorkingStabilityScoringColumnKey = typeof WorkingStabilityScoringColumnKey[keyof typeof WorkingStabilityScoringColumnKey];


/**
 * 
 * @export
 * @interface WorkingStabilityScoringColumnMetadata
 */
export interface WorkingStabilityScoringColumnMetadata {
    /**
     * 
     * @type {string}
     * @memberof WorkingStabilityScoringColumnMetadata
     */
    'dataIndex'?: string;
    /**
     * 
     * @type {WorkingStabilityScoringColumnKey}
     * @memberof WorkingStabilityScoringColumnMetadata
     */
    'key'?: WorkingStabilityScoringColumnKey;
    /**
     * 
     * @type {FromToPairInteger}
     * @memberof WorkingStabilityScoringColumnMetadata
     */
    'payload'?: FromToPairInteger;
}


/**
 * 
 * @export
 * @interface WorkingStabilityScoringRow
 */
export interface WorkingStabilityScoringRow {
    /**
     * 
     * @type {boolean}
     * @memberof WorkingStabilityScoringRow
     */
    'decreased'?: boolean;
    /**
     * 
     * @type {IncomeType}
     * @memberof WorkingStabilityScoringRow
     */
    'incomeType'?: IncomeType;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkingStabilityScoringRow
     */
    'scores'?: Array<number>;
    /**
     * 
     * @type {FromToPairInteger}
     * @memberof WorkingStabilityScoringRow
     */
    'workingMonth'?: FromToPairInteger;
}


/**
 * 
 * @export
 * @interface WorkingStabilityScoringSettingDto
 */
export interface WorkingStabilityScoringSettingDto extends Settings {
    /**
     * 
     * @type {Array<WorkingStabilityScoringColumnMetadata>}
     * @memberof WorkingStabilityScoringSettingDto
     */
    'columns'?: Array<WorkingStabilityScoringColumnMetadata>;
    /**
     * 
     * @type {Array<WorkingStabilityScoringRow>}
     * @memberof WorkingStabilityScoringSettingDto
     */
    'rows'?: Array<WorkingStabilityScoringRow>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WorstClassification = {
    PERFORMING: 'PERFORMING',
    SPECIAL_MENTION: 'SPECIAL_MENTION',
    SUBSTANDARD: 'SUBSTANDARD',
    DOUBTFUL: 'DOUBTFUL',
    BAD: 'BAD'
} as const;

export type WorstClassification = typeof WorstClassification[keyof typeof WorstClassification];



/**
 * AccountControllerApi - axios parameter creator
 * @export
 */
export const AccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profile(options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.profile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public profile(options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).profile(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuditEventControllerApi - axios parameter creator
 * @export
 */
export const AuditEventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} loanId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [effectiveAtFrom] 
         * @param {string} [effectiveAtTo] 
         * @param {number} [amountFrom] 
         * @param {number} [amountTo] 
         * @param {number} [principalFrom] 
         * @param {number} [principalTo] 
         * @param {Array<LoanOperationAuditEventOperationType>} [operationTypes] 
         * @param {Array<CreditBureauStatus>} [creditBureauStatuses] 
         * @param {Array<LoanStatus>} [loanStatuses] 
         * @param {string} [boUserName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanOperationAuditEvents: async (loanId: number, page?: number, size?: number, sort?: Array<string>, effectiveAtFrom?: string, effectiveAtTo?: string, amountFrom?: number, amountTo?: number, principalFrom?: number, principalTo?: number, operationTypes?: Array<LoanOperationAuditEventOperationType>, creditBureauStatuses?: Array<CreditBureauStatus>, loanStatuses?: Array<LoanStatus>, boUserName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('getLoanOperationAuditEvents', 'loanId', loanId)
            const localVarPath = `/api/audit-events/loan-operations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (loanId !== undefined) {
                localVarQueryParameter['loanId'] = loanId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (effectiveAtFrom !== undefined) {
                localVarQueryParameter['effectiveAtFrom'] = (effectiveAtFrom as any instanceof Date) ?
                    (effectiveAtFrom as any).toISOString().substring(0,10) :
                    effectiveAtFrom;
            }

            if (effectiveAtTo !== undefined) {
                localVarQueryParameter['effectiveAtTo'] = (effectiveAtTo as any instanceof Date) ?
                    (effectiveAtTo as any).toISOString().substring(0,10) :
                    effectiveAtTo;
            }

            if (amountFrom !== undefined) {
                localVarQueryParameter['amountFrom'] = amountFrom;
            }

            if (amountTo !== undefined) {
                localVarQueryParameter['amountTo'] = amountTo;
            }

            if (principalFrom !== undefined) {
                localVarQueryParameter['principalFrom'] = principalFrom;
            }

            if (principalTo !== undefined) {
                localVarQueryParameter['principalTo'] = principalTo;
            }

            if (operationTypes) {
                localVarQueryParameter['operationTypes'] = operationTypes;
            }

            if (creditBureauStatuses) {
                localVarQueryParameter['creditBureauStatuses'] = creditBureauStatuses;
            }

            if (loanStatuses) {
                localVarQueryParameter['loanStatuses'] = loanStatuses;
            }

            if (boUserName !== undefined) {
                localVarQueryParameter['boUserName'] = boUserName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditEventControllerApi - functional programming interface
 * @export
 */
export const AuditEventControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditEventControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} loanId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [effectiveAtFrom] 
         * @param {string} [effectiveAtTo] 
         * @param {number} [amountFrom] 
         * @param {number} [amountTo] 
         * @param {number} [principalFrom] 
         * @param {number} [principalTo] 
         * @param {Array<LoanOperationAuditEventOperationType>} [operationTypes] 
         * @param {Array<CreditBureauStatus>} [creditBureauStatuses] 
         * @param {Array<LoanStatus>} [loanStatuses] 
         * @param {string} [boUserName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanOperationAuditEvents(loanId: number, page?: number, size?: number, sort?: Array<string>, effectiveAtFrom?: string, effectiveAtTo?: string, amountFrom?: number, amountTo?: number, principalFrom?: number, principalTo?: number, operationTypes?: Array<LoanOperationAuditEventOperationType>, creditBureauStatuses?: Array<CreditBureauStatus>, loanStatuses?: Array<LoanStatus>, boUserName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanOperationAuditEventListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanOperationAuditEvents(loanId, page, size, sort, effectiveAtFrom, effectiveAtTo, amountFrom, amountTo, principalFrom, principalTo, operationTypes, creditBureauStatuses, loanStatuses, boUserName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditEventControllerApi - factory interface
 * @export
 */
export const AuditEventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditEventControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AuditEventControllerApiGetLoanOperationAuditEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanOperationAuditEvents(requestParameters: AuditEventControllerApiGetLoanOperationAuditEventsRequest, options?: AxiosRequestConfig): AxiosPromise<PageLoanOperationAuditEventListEntry> {
            return localVarFp.getLoanOperationAuditEvents(requestParameters.loanId, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.effectiveAtFrom, requestParameters.effectiveAtTo, requestParameters.amountFrom, requestParameters.amountTo, requestParameters.principalFrom, requestParameters.principalTo, requestParameters.operationTypes, requestParameters.creditBureauStatuses, requestParameters.loanStatuses, requestParameters.boUserName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getLoanOperationAuditEvents operation in AuditEventControllerApi.
 * @export
 * @interface AuditEventControllerApiGetLoanOperationAuditEventsRequest
 */
export interface AuditEventControllerApiGetLoanOperationAuditEventsRequest {
    /**
     * 
     * @type {number}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly loanId: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly effectiveAtFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly effectiveAtTo?: string

    /**
     * 
     * @type {number}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly amountFrom?: number

    /**
     * 
     * @type {number}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly amountTo?: number

    /**
     * 
     * @type {number}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly principalFrom?: number

    /**
     * 
     * @type {number}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly principalTo?: number

    /**
     * 
     * @type {Array<LoanOperationAuditEventOperationType>}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly operationTypes?: Array<LoanOperationAuditEventOperationType>

    /**
     * 
     * @type {Array<CreditBureauStatus>}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly creditBureauStatuses?: Array<CreditBureauStatus>

    /**
     * 
     * @type {Array<LoanStatus>}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly loanStatuses?: Array<LoanStatus>

    /**
     * 
     * @type {string}
     * @memberof AuditEventControllerApiGetLoanOperationAuditEvents
     */
    readonly boUserName?: string
}

/**
 * AuditEventControllerApi - object-oriented interface
 * @export
 * @class AuditEventControllerApi
 * @extends {BaseAPI}
 */
export class AuditEventControllerApi extends BaseAPI {
    /**
     * 
     * @param {AuditEventControllerApiGetLoanOperationAuditEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditEventControllerApi
     */
    public getLoanOperationAuditEvents(requestParameters: AuditEventControllerApiGetLoanOperationAuditEventsRequest, options?: AxiosRequestConfig) {
        return AuditEventControllerApiFp(this.configuration).getLoanOperationAuditEvents(requestParameters.loanId, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.effectiveAtFrom, requestParameters.effectiveAtTo, requestParameters.amountFrom, requestParameters.amountTo, requestParameters.principalFrom, requestParameters.principalTo, requestParameters.operationTypes, requestParameters.creditBureauStatuses, requestParameters.loanStatuses, requestParameters.boUserName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BranchControllerApi - axios parameter creator
 * @export
 */
export const BranchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/branches/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        childBranchOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/branches/children/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewChildBranch} newChildBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChildBranch: async (newChildBranch: NewChildBranch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newChildBranch' is not null or undefined
            assertParamExists('createChildBranch', 'newChildBranch', newChildBranch)
            const localVarPath = `/api/branches/child`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newChildBranch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParentBranch} parentBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParentBranch: async (parentBranch: ParentBranch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentBranch' is not null or undefined
            assertParamExists('createParentBranch', 'parentBranch', parentBranch)
            const localVarPath = `/api/branches/parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parentBranch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [parentBranchId] 
         * @param {ChildBranchStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllChildBranches: async (page?: number, size?: number, sort?: Array<string>, parentBranchId?: number, status?: ChildBranchStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/branches/child`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (parentBranchId !== undefined) {
                localVarQueryParameter['parentBranchId'] = parentBranchId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParentBranches: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/branches/parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildBranch: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getChildBranch', 'id', id)
            const localVarPath = `/api/branches/child/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentBranch: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getParentBranch', 'id', id)
            const localVarPath = `/api/branches/parent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChildBranchUpdate} childBranchUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildBranch: async (childBranchUpdate: ChildBranchUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childBranchUpdate' is not null or undefined
            assertParamExists('updateChildBranch', 'childBranchUpdate', childBranchUpdate)
            const localVarPath = `/api/branches/child`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(childBranchUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParentBranch} parentBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParentBranch: async (parentBranch: ParentBranch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentBranch' is not null or undefined
            assertParamExists('updateParentBranch', 'parentBranch', parentBranch)
            const localVarPath = `/api/branches/parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parentBranch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BranchControllerApi - functional programming interface
 * @export
 */
export const BranchControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BranchControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async branchOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParentBranchOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.branchOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async childBranchOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChildBranchOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.childBranchOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewChildBranch} newChildBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChildBranch(newChildBranch: NewChildBranch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChildBranch(newChildBranch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ParentBranch} parentBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createParentBranch(parentBranch: ParentBranch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createParentBranch(parentBranch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [parentBranchId] 
         * @param {ChildBranchStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllChildBranches(page?: number, size?: number, sort?: Array<string>, parentBranchId?: number, status?: ChildBranchStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageChildBranchListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllChildBranches(page, size, sort, parentBranchId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllParentBranches(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParentBranch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllParentBranches(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildBranch(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildBranch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildBranch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentBranch(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentBranch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentBranch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChildBranchUpdate} childBranchUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChildBranch(childBranchUpdate: ChildBranchUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChildBranch(childBranchUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ParentBranch} parentBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateParentBranch(parentBranch: ParentBranch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateParentBranch(parentBranch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BranchControllerApi - factory interface
 * @export
 */
export const BranchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BranchControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchOptions(options?: AxiosRequestConfig): AxiosPromise<Array<ParentBranchOption>> {
            return localVarFp.branchOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        childBranchOptions(options?: AxiosRequestConfig): AxiosPromise<Array<ChildBranchOption>> {
            return localVarFp.childBranchOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BranchControllerApiCreateChildBranchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChildBranch(requestParameters: BranchControllerApiCreateChildBranchRequest, options?: AxiosRequestConfig): AxiosPromise<IdEntry> {
            return localVarFp.createChildBranch(requestParameters.newChildBranch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BranchControllerApiCreateParentBranchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParentBranch(requestParameters: BranchControllerApiCreateParentBranchRequest, options?: AxiosRequestConfig): AxiosPromise<IdEntry> {
            return localVarFp.createParentBranch(requestParameters.parentBranch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BranchControllerApiFindAllChildBranchesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllChildBranches(requestParameters: BranchControllerApiFindAllChildBranchesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageChildBranchListEntry> {
            return localVarFp.findAllChildBranches(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.parentBranchId, requestParameters.status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParentBranches(options?: AxiosRequestConfig): AxiosPromise<Array<ParentBranch>> {
            return localVarFp.getAllParentBranches(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BranchControllerApiGetChildBranchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildBranch(requestParameters: BranchControllerApiGetChildBranchRequest, options?: AxiosRequestConfig): AxiosPromise<ChildBranch> {
            return localVarFp.getChildBranch(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BranchControllerApiGetParentBranchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentBranch(requestParameters: BranchControllerApiGetParentBranchRequest, options?: AxiosRequestConfig): AxiosPromise<ParentBranch> {
            return localVarFp.getParentBranch(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BranchControllerApiUpdateChildBranchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildBranch(requestParameters: BranchControllerApiUpdateChildBranchRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateChildBranch(requestParameters.childBranchUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BranchControllerApiUpdateParentBranchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParentBranch(requestParameters: BranchControllerApiUpdateParentBranchRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateParentBranch(requestParameters.parentBranch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createChildBranch operation in BranchControllerApi.
 * @export
 * @interface BranchControllerApiCreateChildBranchRequest
 */
export interface BranchControllerApiCreateChildBranchRequest {
    /**
     * 
     * @type {NewChildBranch}
     * @memberof BranchControllerApiCreateChildBranch
     */
    readonly newChildBranch: NewChildBranch
}

/**
 * Request parameters for createParentBranch operation in BranchControllerApi.
 * @export
 * @interface BranchControllerApiCreateParentBranchRequest
 */
export interface BranchControllerApiCreateParentBranchRequest {
    /**
     * 
     * @type {ParentBranch}
     * @memberof BranchControllerApiCreateParentBranch
     */
    readonly parentBranch: ParentBranch
}

/**
 * Request parameters for findAllChildBranches operation in BranchControllerApi.
 * @export
 * @interface BranchControllerApiFindAllChildBranchesRequest
 */
export interface BranchControllerApiFindAllChildBranchesRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof BranchControllerApiFindAllChildBranches
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof BranchControllerApiFindAllChildBranches
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof BranchControllerApiFindAllChildBranches
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof BranchControllerApiFindAllChildBranches
     */
    readonly parentBranchId?: number

    /**
     * 
     * @type {ChildBranchStatus}
     * @memberof BranchControllerApiFindAllChildBranches
     */
    readonly status?: ChildBranchStatus
}

/**
 * Request parameters for getChildBranch operation in BranchControllerApi.
 * @export
 * @interface BranchControllerApiGetChildBranchRequest
 */
export interface BranchControllerApiGetChildBranchRequest {
    /**
     * 
     * @type {number}
     * @memberof BranchControllerApiGetChildBranch
     */
    readonly id: number
}

/**
 * Request parameters for getParentBranch operation in BranchControllerApi.
 * @export
 * @interface BranchControllerApiGetParentBranchRequest
 */
export interface BranchControllerApiGetParentBranchRequest {
    /**
     * 
     * @type {number}
     * @memberof BranchControllerApiGetParentBranch
     */
    readonly id: number
}

/**
 * Request parameters for updateChildBranch operation in BranchControllerApi.
 * @export
 * @interface BranchControllerApiUpdateChildBranchRequest
 */
export interface BranchControllerApiUpdateChildBranchRequest {
    /**
     * 
     * @type {ChildBranchUpdate}
     * @memberof BranchControllerApiUpdateChildBranch
     */
    readonly childBranchUpdate: ChildBranchUpdate
}

/**
 * Request parameters for updateParentBranch operation in BranchControllerApi.
 * @export
 * @interface BranchControllerApiUpdateParentBranchRequest
 */
export interface BranchControllerApiUpdateParentBranchRequest {
    /**
     * 
     * @type {ParentBranch}
     * @memberof BranchControllerApiUpdateParentBranch
     */
    readonly parentBranch: ParentBranch
}

/**
 * BranchControllerApi - object-oriented interface
 * @export
 * @class BranchControllerApi
 * @extends {BaseAPI}
 */
export class BranchControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchControllerApi
     */
    public branchOptions(options?: AxiosRequestConfig) {
        return BranchControllerApiFp(this.configuration).branchOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchControllerApi
     */
    public childBranchOptions(options?: AxiosRequestConfig) {
        return BranchControllerApiFp(this.configuration).childBranchOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BranchControllerApiCreateChildBranchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchControllerApi
     */
    public createChildBranch(requestParameters: BranchControllerApiCreateChildBranchRequest, options?: AxiosRequestConfig) {
        return BranchControllerApiFp(this.configuration).createChildBranch(requestParameters.newChildBranch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BranchControllerApiCreateParentBranchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchControllerApi
     */
    public createParentBranch(requestParameters: BranchControllerApiCreateParentBranchRequest, options?: AxiosRequestConfig) {
        return BranchControllerApiFp(this.configuration).createParentBranch(requestParameters.parentBranch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BranchControllerApiFindAllChildBranchesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchControllerApi
     */
    public findAllChildBranches(requestParameters: BranchControllerApiFindAllChildBranchesRequest = {}, options?: AxiosRequestConfig) {
        return BranchControllerApiFp(this.configuration).findAllChildBranches(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.parentBranchId, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchControllerApi
     */
    public getAllParentBranches(options?: AxiosRequestConfig) {
        return BranchControllerApiFp(this.configuration).getAllParentBranches(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BranchControllerApiGetChildBranchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchControllerApi
     */
    public getChildBranch(requestParameters: BranchControllerApiGetChildBranchRequest, options?: AxiosRequestConfig) {
        return BranchControllerApiFp(this.configuration).getChildBranch(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BranchControllerApiGetParentBranchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchControllerApi
     */
    public getParentBranch(requestParameters: BranchControllerApiGetParentBranchRequest, options?: AxiosRequestConfig) {
        return BranchControllerApiFp(this.configuration).getParentBranch(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BranchControllerApiUpdateChildBranchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchControllerApi
     */
    public updateChildBranch(requestParameters: BranchControllerApiUpdateChildBranchRequest, options?: AxiosRequestConfig) {
        return BranchControllerApiFp(this.configuration).updateChildBranch(requestParameters.childBranchUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BranchControllerApiUpdateParentBranchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchControllerApi
     */
    public updateParentBranch(requestParameters: BranchControllerApiUpdateParentBranchRequest, options?: AxiosRequestConfig) {
        return BranchControllerApiFp(this.configuration).updateParentBranch(requestParameters.parentBranch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CoBorrowerControllerApi - axios parameter creator
 * @export
 */
export const CoBorrowerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCoBorrowerRequest} createCoBorrowerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoBorrower: async (createCoBorrowerRequest: CreateCoBorrowerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCoBorrowerRequest' is not null or undefined
            assertParamExists('createCoBorrower', 'createCoBorrowerRequest', createCoBorrowerRequest)
            const localVarPath = `/api/co-borrowers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCoBorrowerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCoBorrowerRequest} updateCoBorrowerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCoBorrower: async (id: number, updateCoBorrowerRequest: UpdateCoBorrowerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCoBorrower', 'id', id)
            // verify required parameter 'updateCoBorrowerRequest' is not null or undefined
            assertParamExists('updateCoBorrower', 'updateCoBorrowerRequest', updateCoBorrowerRequest)
            const localVarPath = `/api/co-borrowers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCoBorrowerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoBorrowerControllerApi - functional programming interface
 * @export
 */
export const CoBorrowerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoBorrowerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCoBorrowerRequest} createCoBorrowerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCoBorrower(createCoBorrowerRequest: CreateCoBorrowerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCoBorrower(createCoBorrowerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCoBorrowerRequest} updateCoBorrowerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCoBorrower(id: number, updateCoBorrowerRequest: UpdateCoBorrowerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCoBorrower(id, updateCoBorrowerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoBorrowerControllerApi - factory interface
 * @export
 */
export const CoBorrowerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoBorrowerControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CoBorrowerControllerApiCreateCoBorrowerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoBorrower(requestParameters: CoBorrowerControllerApiCreateCoBorrowerRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createCoBorrower(requestParameters.createCoBorrowerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoBorrowerControllerApiUpdateCoBorrowerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCoBorrower(requestParameters: CoBorrowerControllerApiUpdateCoBorrowerRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateCoBorrower(requestParameters.id, requestParameters.updateCoBorrowerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCoBorrower operation in CoBorrowerControllerApi.
 * @export
 * @interface CoBorrowerControllerApiCreateCoBorrowerRequest
 */
export interface CoBorrowerControllerApiCreateCoBorrowerRequest {
    /**
     * 
     * @type {CreateCoBorrowerRequest}
     * @memberof CoBorrowerControllerApiCreateCoBorrower
     */
    readonly createCoBorrowerRequest: CreateCoBorrowerRequest
}

/**
 * Request parameters for updateCoBorrower operation in CoBorrowerControllerApi.
 * @export
 * @interface CoBorrowerControllerApiUpdateCoBorrowerRequest
 */
export interface CoBorrowerControllerApiUpdateCoBorrowerRequest {
    /**
     * 
     * @type {number}
     * @memberof CoBorrowerControllerApiUpdateCoBorrower
     */
    readonly id: number

    /**
     * 
     * @type {UpdateCoBorrowerRequest}
     * @memberof CoBorrowerControllerApiUpdateCoBorrower
     */
    readonly updateCoBorrowerRequest: UpdateCoBorrowerRequest
}

/**
 * CoBorrowerControllerApi - object-oriented interface
 * @export
 * @class CoBorrowerControllerApi
 * @extends {BaseAPI}
 */
export class CoBorrowerControllerApi extends BaseAPI {
    /**
     * 
     * @param {CoBorrowerControllerApiCreateCoBorrowerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoBorrowerControllerApi
     */
    public createCoBorrower(requestParameters: CoBorrowerControllerApiCreateCoBorrowerRequest, options?: AxiosRequestConfig) {
        return CoBorrowerControllerApiFp(this.configuration).createCoBorrower(requestParameters.createCoBorrowerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoBorrowerControllerApiUpdateCoBorrowerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoBorrowerControllerApi
     */
    public updateCoBorrower(requestParameters: CoBorrowerControllerApiUpdateCoBorrowerRequest, options?: AxiosRequestConfig) {
        return CoBorrowerControllerApiFp(this.configuration).updateCoBorrower(requestParameters.id, requestParameters.updateCoBorrowerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CollateralCoOwnerControllerApi - axios parameter creator
 * @export
 */
export const CollateralCoOwnerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} collateralId 
         * @param {NewCollateralCoOwner} newCollateralCoOwner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCollateralCoOwner: async (collateralId: number, newCollateralCoOwner: NewCollateralCoOwner, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collateralId' is not null or undefined
            assertParamExists('createCollateralCoOwner', 'collateralId', collateralId)
            // verify required parameter 'newCollateralCoOwner' is not null or undefined
            assertParamExists('createCollateralCoOwner', 'newCollateralCoOwner', newCollateralCoOwner)
            const localVarPath = `/api/collaterals/{collateralId}/co-owners`
                .replace(`{${"collateralId"}}`, encodeURIComponent(String(collateralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCollateralCoOwner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollateralCoOwner: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCollateralCoOwner', 'id', id)
            const localVarPath = `/api/collateral-co-owners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CollateralCoOwnerUpdate} collateralCoOwnerUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCollateralCoOwner: async (id: number, collateralCoOwnerUpdate: CollateralCoOwnerUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCollateralCoOwner', 'id', id)
            // verify required parameter 'collateralCoOwnerUpdate' is not null or undefined
            assertParamExists('updateCollateralCoOwner', 'collateralCoOwnerUpdate', collateralCoOwnerUpdate)
            const localVarPath = `/api/collateral-co-owners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(collateralCoOwnerUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollateralCoOwnerControllerApi - functional programming interface
 * @export
 */
export const CollateralCoOwnerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollateralCoOwnerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} collateralId 
         * @param {NewCollateralCoOwner} newCollateralCoOwner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCollateralCoOwner(collateralId: number, newCollateralCoOwner: NewCollateralCoOwner, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollateralCoOwner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCollateralCoOwner(collateralId, newCollateralCoOwner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollateralCoOwner(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollateralCoOwner(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CollateralCoOwnerUpdate} collateralCoOwnerUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCollateralCoOwner(id: number, collateralCoOwnerUpdate: CollateralCoOwnerUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCollateralCoOwner(id, collateralCoOwnerUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CollateralCoOwnerControllerApi - factory interface
 * @export
 */
export const CollateralCoOwnerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollateralCoOwnerControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CollateralCoOwnerControllerApiCreateCollateralCoOwnerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCollateralCoOwner(requestParameters: CollateralCoOwnerControllerApiCreateCollateralCoOwnerRequest, options?: AxiosRequestConfig): AxiosPromise<CollateralCoOwner> {
            return localVarFp.createCollateralCoOwner(requestParameters.collateralId, requestParameters.newCollateralCoOwner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CollateralCoOwnerControllerApiDeleteCollateralCoOwnerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollateralCoOwner(requestParameters: CollateralCoOwnerControllerApiDeleteCollateralCoOwnerRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCollateralCoOwner(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CollateralCoOwnerControllerApiUpdateCollateralCoOwnerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCollateralCoOwner(requestParameters: CollateralCoOwnerControllerApiUpdateCollateralCoOwnerRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateCollateralCoOwner(requestParameters.id, requestParameters.collateralCoOwnerUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCollateralCoOwner operation in CollateralCoOwnerControllerApi.
 * @export
 * @interface CollateralCoOwnerControllerApiCreateCollateralCoOwnerRequest
 */
export interface CollateralCoOwnerControllerApiCreateCollateralCoOwnerRequest {
    /**
     * 
     * @type {number}
     * @memberof CollateralCoOwnerControllerApiCreateCollateralCoOwner
     */
    readonly collateralId: number

    /**
     * 
     * @type {NewCollateralCoOwner}
     * @memberof CollateralCoOwnerControllerApiCreateCollateralCoOwner
     */
    readonly newCollateralCoOwner: NewCollateralCoOwner
}

/**
 * Request parameters for deleteCollateralCoOwner operation in CollateralCoOwnerControllerApi.
 * @export
 * @interface CollateralCoOwnerControllerApiDeleteCollateralCoOwnerRequest
 */
export interface CollateralCoOwnerControllerApiDeleteCollateralCoOwnerRequest {
    /**
     * 
     * @type {number}
     * @memberof CollateralCoOwnerControllerApiDeleteCollateralCoOwner
     */
    readonly id: number
}

/**
 * Request parameters for updateCollateralCoOwner operation in CollateralCoOwnerControllerApi.
 * @export
 * @interface CollateralCoOwnerControllerApiUpdateCollateralCoOwnerRequest
 */
export interface CollateralCoOwnerControllerApiUpdateCollateralCoOwnerRequest {
    /**
     * 
     * @type {number}
     * @memberof CollateralCoOwnerControllerApiUpdateCollateralCoOwner
     */
    readonly id: number

    /**
     * 
     * @type {CollateralCoOwnerUpdate}
     * @memberof CollateralCoOwnerControllerApiUpdateCollateralCoOwner
     */
    readonly collateralCoOwnerUpdate: CollateralCoOwnerUpdate
}

/**
 * CollateralCoOwnerControllerApi - object-oriented interface
 * @export
 * @class CollateralCoOwnerControllerApi
 * @extends {BaseAPI}
 */
export class CollateralCoOwnerControllerApi extends BaseAPI {
    /**
     * 
     * @param {CollateralCoOwnerControllerApiCreateCollateralCoOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollateralCoOwnerControllerApi
     */
    public createCollateralCoOwner(requestParameters: CollateralCoOwnerControllerApiCreateCollateralCoOwnerRequest, options?: AxiosRequestConfig) {
        return CollateralCoOwnerControllerApiFp(this.configuration).createCollateralCoOwner(requestParameters.collateralId, requestParameters.newCollateralCoOwner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollateralCoOwnerControllerApiDeleteCollateralCoOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollateralCoOwnerControllerApi
     */
    public deleteCollateralCoOwner(requestParameters: CollateralCoOwnerControllerApiDeleteCollateralCoOwnerRequest, options?: AxiosRequestConfig) {
        return CollateralCoOwnerControllerApiFp(this.configuration).deleteCollateralCoOwner(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollateralCoOwnerControllerApiUpdateCollateralCoOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollateralCoOwnerControllerApi
     */
    public updateCollateralCoOwner(requestParameters: CollateralCoOwnerControllerApiUpdateCollateralCoOwnerRequest, options?: AxiosRequestConfig) {
        return CollateralCoOwnerControllerApiFp(this.configuration).updateCollateralCoOwner(requestParameters.id, requestParameters.collateralCoOwnerUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CollateralControllerApi - axios parameter creator
 * @export
 */
export const CollateralControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCollateralRequest} createCollateralRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCollateral: async (createCollateralRequest: CreateCollateralRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCollateralRequest' is not null or undefined
            assertParamExists('createCollateral', 'createCollateralRequest', createCollateralRequest)
            const localVarPath = `/api/collaterals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCollateralRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [loanExternalId] 
         * @param {number} [collateralId] 
         * @param {string} [ownerName] 
         * @param {string} [ownerRegistrationNumber] 
         * @param {Array<CollateralType>} [types] 
         * @param {Array<CollateralSubType>} [subTypes] 
         * @param {string} [chassisNumber] 
         * @param {string} [collateralRegistrationNumber] 
         * @param {string} [establishedDate] 
         * @param {number} [dateOfManufacture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCollaterals: async (page?: number, size?: number, sort?: Array<string>, loanExternalId?: string, collateralId?: number, ownerName?: string, ownerRegistrationNumber?: string, types?: Array<CollateralType>, subTypes?: Array<CollateralSubType>, chassisNumber?: string, collateralRegistrationNumber?: string, establishedDate?: string, dateOfManufacture?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/collaterals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (loanExternalId !== undefined) {
                localVarQueryParameter['loanExternalId'] = loanExternalId;
            }

            if (collateralId !== undefined) {
                localVarQueryParameter['collateralId'] = collateralId;
            }

            if (ownerName !== undefined) {
                localVarQueryParameter['ownerName'] = ownerName;
            }

            if (ownerRegistrationNumber !== undefined) {
                localVarQueryParameter['ownerRegistrationNumber'] = ownerRegistrationNumber;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (subTypes) {
                localVarQueryParameter['subTypes'] = subTypes;
            }

            if (chassisNumber !== undefined) {
                localVarQueryParameter['chassisNumber'] = chassisNumber;
            }

            if (collateralRegistrationNumber !== undefined) {
                localVarQueryParameter['collateralRegistrationNumber'] = collateralRegistrationNumber;
            }

            if (establishedDate !== undefined) {
                localVarQueryParameter['establishedDate'] = (establishedDate as any instanceof Date) ?
                    (establishedDate as any).toISOString().substring(0,10) :
                    establishedDate;
            }

            if (dateOfManufacture !== undefined) {
                localVarQueryParameter['dateOfManufacture'] = dateOfManufacture;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollateral: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCollateral', 'id', id)
            const localVarPath = `/api/collaterals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchCollateralRequest} patchCollateralRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCollateral: async (id: number, patchCollateralRequest: PatchCollateralRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCollateral', 'id', id)
            // verify required parameter 'patchCollateralRequest' is not null or undefined
            assertParamExists('patchCollateral', 'patchCollateralRequest', patchCollateralRequest)
            const localVarPath = `/api/collaterals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCollateralRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollateralControllerApi - functional programming interface
 * @export
 */
export const CollateralControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollateralControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCollateralRequest} createCollateralRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCollateral(createCollateralRequest: CreateCollateralRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCollateral200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCollateral(createCollateralRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [loanExternalId] 
         * @param {number} [collateralId] 
         * @param {string} [ownerName] 
         * @param {string} [ownerRegistrationNumber] 
         * @param {Array<CollateralType>} [types] 
         * @param {Array<CollateralSubType>} [subTypes] 
         * @param {string} [chassisNumber] 
         * @param {string} [collateralRegistrationNumber] 
         * @param {string} [establishedDate] 
         * @param {number} [dateOfManufacture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCollaterals(page?: number, size?: number, sort?: Array<string>, loanExternalId?: string, collateralId?: number, ownerName?: string, ownerRegistrationNumber?: string, types?: Array<CollateralType>, subTypes?: Array<CollateralSubType>, chassisNumber?: string, collateralRegistrationNumber?: string, establishedDate?: string, dateOfManufacture?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCollateralListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCollaterals(page, size, sort, loanExternalId, collateralId, ownerName, ownerRegistrationNumber, types, subTypes, chassisNumber, collateralRegistrationNumber, establishedDate, dateOfManufacture, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollateral(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCollateral200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollateral(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchCollateralRequest} patchCollateralRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCollateral(id: number, patchCollateralRequest: PatchCollateralRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCollateral(id, patchCollateralRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CollateralControllerApi - factory interface
 * @export
 */
export const CollateralControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollateralControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CollateralControllerApiCreateCollateralRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCollateral(requestParameters: CollateralControllerApiCreateCollateralRequest, options?: AxiosRequestConfig): AxiosPromise<CreateCollateral200Response> {
            return localVarFp.createCollateral(requestParameters.createCollateralRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CollateralControllerApiGetAllCollateralsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCollaterals(requestParameters: CollateralControllerApiGetAllCollateralsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageCollateralListEntry> {
            return localVarFp.getAllCollaterals(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.loanExternalId, requestParameters.collateralId, requestParameters.ownerName, requestParameters.ownerRegistrationNumber, requestParameters.types, requestParameters.subTypes, requestParameters.chassisNumber, requestParameters.collateralRegistrationNumber, requestParameters.establishedDate, requestParameters.dateOfManufacture, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CollateralControllerApiGetCollateralRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollateral(requestParameters: CollateralControllerApiGetCollateralRequest, options?: AxiosRequestConfig): AxiosPromise<CreateCollateral200Response> {
            return localVarFp.getCollateral(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CollateralControllerApiPatchCollateralRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCollateral(requestParameters: CollateralControllerApiPatchCollateralRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchCollateral(requestParameters.id, requestParameters.patchCollateralRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCollateral operation in CollateralControllerApi.
 * @export
 * @interface CollateralControllerApiCreateCollateralRequest
 */
export interface CollateralControllerApiCreateCollateralRequest {
    /**
     * 
     * @type {CreateCollateralRequest}
     * @memberof CollateralControllerApiCreateCollateral
     */
    readonly createCollateralRequest: CreateCollateralRequest
}

/**
 * Request parameters for getAllCollaterals operation in CollateralControllerApi.
 * @export
 * @interface CollateralControllerApiGetAllCollateralsRequest
 */
export interface CollateralControllerApiGetAllCollateralsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly loanExternalId?: string

    /**
     * 
     * @type {number}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly collateralId?: number

    /**
     * 
     * @type {string}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly ownerName?: string

    /**
     * 
     * @type {string}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly ownerRegistrationNumber?: string

    /**
     * 
     * @type {Array<CollateralType>}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly types?: Array<CollateralType>

    /**
     * 
     * @type {Array<CollateralSubType>}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly subTypes?: Array<CollateralSubType>

    /**
     * 
     * @type {string}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly chassisNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly collateralRegistrationNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly establishedDate?: string

    /**
     * 
     * @type {number}
     * @memberof CollateralControllerApiGetAllCollaterals
     */
    readonly dateOfManufacture?: number
}

/**
 * Request parameters for getCollateral operation in CollateralControllerApi.
 * @export
 * @interface CollateralControllerApiGetCollateralRequest
 */
export interface CollateralControllerApiGetCollateralRequest {
    /**
     * 
     * @type {number}
     * @memberof CollateralControllerApiGetCollateral
     */
    readonly id: number
}

/**
 * Request parameters for patchCollateral operation in CollateralControllerApi.
 * @export
 * @interface CollateralControllerApiPatchCollateralRequest
 */
export interface CollateralControllerApiPatchCollateralRequest {
    /**
     * 
     * @type {number}
     * @memberof CollateralControllerApiPatchCollateral
     */
    readonly id: number

    /**
     * 
     * @type {PatchCollateralRequest}
     * @memberof CollateralControllerApiPatchCollateral
     */
    readonly patchCollateralRequest: PatchCollateralRequest
}

/**
 * CollateralControllerApi - object-oriented interface
 * @export
 * @class CollateralControllerApi
 * @extends {BaseAPI}
 */
export class CollateralControllerApi extends BaseAPI {
    /**
     * 
     * @param {CollateralControllerApiCreateCollateralRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollateralControllerApi
     */
    public createCollateral(requestParameters: CollateralControllerApiCreateCollateralRequest, options?: AxiosRequestConfig) {
        return CollateralControllerApiFp(this.configuration).createCollateral(requestParameters.createCollateralRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollateralControllerApiGetAllCollateralsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollateralControllerApi
     */
    public getAllCollaterals(requestParameters: CollateralControllerApiGetAllCollateralsRequest = {}, options?: AxiosRequestConfig) {
        return CollateralControllerApiFp(this.configuration).getAllCollaterals(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.loanExternalId, requestParameters.collateralId, requestParameters.ownerName, requestParameters.ownerRegistrationNumber, requestParameters.types, requestParameters.subTypes, requestParameters.chassisNumber, requestParameters.collateralRegistrationNumber, requestParameters.establishedDate, requestParameters.dateOfManufacture, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollateralControllerApiGetCollateralRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollateralControllerApi
     */
    public getCollateral(requestParameters: CollateralControllerApiGetCollateralRequest, options?: AxiosRequestConfig) {
        return CollateralControllerApiFp(this.configuration).getCollateral(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollateralControllerApiPatchCollateralRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollateralControllerApi
     */
    public patchCollateral(requestParameters: CollateralControllerApiPatchCollateralRequest, options?: AxiosRequestConfig) {
        return CollateralControllerApiFp(this.configuration).patchCollateral(requestParameters.id, requestParameters.patchCollateralRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommentControllerApi - axios parameter creator
 * @export
 */
export const CommentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewComment} newComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentInRoom: async (id: number, newComment: NewComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createCommentInRoom', 'id', id)
            // verify required parameter 'newComment' is not null or undefined
            assertParamExists('createCommentInRoom', 'newComment', newComment)
            const localVarPath = `/api/rooms/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByRoomId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCommentsByRoomId', 'id', id)
            const localVarPath = `/api/rooms/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentControllerApi - functional programming interface
 * @export
 */
export const CommentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewComment} newComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentInRoom(id: number, newComment: NewComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentInRoom(id, newComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByRoomId(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByRoomId(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentControllerApi - factory interface
 * @export
 */
export const CommentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CommentControllerApiCreateCommentInRoomRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentInRoom(requestParameters: CommentControllerApiCreateCommentInRoomRequest, options?: AxiosRequestConfig): AxiosPromise<Comment> {
            return localVarFp.createCommentInRoom(requestParameters.id, requestParameters.newComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CommentControllerApiGetCommentsByRoomIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByRoomId(requestParameters: CommentControllerApiGetCommentsByRoomIdRequest, options?: AxiosRequestConfig): AxiosPromise<PageComment> {
            return localVarFp.getCommentsByRoomId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCommentInRoom operation in CommentControllerApi.
 * @export
 * @interface CommentControllerApiCreateCommentInRoomRequest
 */
export interface CommentControllerApiCreateCommentInRoomRequest {
    /**
     * 
     * @type {number}
     * @memberof CommentControllerApiCreateCommentInRoom
     */
    readonly id: number

    /**
     * 
     * @type {NewComment}
     * @memberof CommentControllerApiCreateCommentInRoom
     */
    readonly newComment: NewComment
}

/**
 * Request parameters for getCommentsByRoomId operation in CommentControllerApi.
 * @export
 * @interface CommentControllerApiGetCommentsByRoomIdRequest
 */
export interface CommentControllerApiGetCommentsByRoomIdRequest {
    /**
     * 
     * @type {number}
     * @memberof CommentControllerApiGetCommentsByRoomId
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof CommentControllerApiGetCommentsByRoomId
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof CommentControllerApiGetCommentsByRoomId
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof CommentControllerApiGetCommentsByRoomId
     */
    readonly sort?: Array<string>
}

/**
 * CommentControllerApi - object-oriented interface
 * @export
 * @class CommentControllerApi
 * @extends {BaseAPI}
 */
export class CommentControllerApi extends BaseAPI {
    /**
     * 
     * @param {CommentControllerApiCreateCommentInRoomRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public createCommentInRoom(requestParameters: CommentControllerApiCreateCommentInRoomRequest, options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).createCommentInRoom(requestParameters.id, requestParameters.newComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CommentControllerApiGetCommentsByRoomIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public getCommentsByRoomId(requestParameters: CommentControllerApiGetCommentsByRoomIdRequest, options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).getCommentsByRoomId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerGroupControllerApi - axios parameter creator
 * @export
 */
export const CustomerGroupControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewCustomerGroupCompany} newCustomerGroupCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyToCustomerGroup: async (id: number, newCustomerGroupCompany: NewCustomerGroupCompany, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addCompanyToCustomerGroup', 'id', id)
            // verify required parameter 'newCustomerGroupCompany' is not null or undefined
            assertParamExists('addCompanyToCustomerGroup', 'newCustomerGroupCompany', newCustomerGroupCompany)
            const localVarPath = `/api/customer-groups/{id}/companies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCustomerGroupCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewCustomerGroup} newCustomerGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerGroup: async (newCustomerGroup: NewCustomerGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newCustomerGroup' is not null or undefined
            assertParamExists('createCustomerGroup', 'newCustomerGroup', newCustomerGroup)
            const localVarPath = `/api/customer-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCustomerGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerGroup: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerGroup', 'id', id)
            const localVarPath = `/api/customer-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerGroupCompanies: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerGroupCompanies', 'id', id)
            const localVarPath = `/api/customer-groups/{id}/companies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerGroups: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCustomerGroup: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeCustomerGroup', 'id', id)
            const localVarPath = `/api/customer-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCustomerGroupCompany: async (id: number, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeCustomerGroupCompany', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('removeCustomerGroupCompany', 'name', name)
            const localVarPath = `/api/customer-groups/{id}/companies/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CustomerGroupUpdate} customerGroupUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerGroup: async (id: number, customerGroupUpdate: CustomerGroupUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomerGroup', 'id', id)
            // verify required parameter 'customerGroupUpdate' is not null or undefined
            assertParamExists('updateCustomerGroup', 'customerGroupUpdate', customerGroupUpdate)
            const localVarPath = `/api/customer-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerGroupUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerGroupControllerApi - functional programming interface
 * @export
 */
export const CustomerGroupControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerGroupControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewCustomerGroupCompany} newCustomerGroupCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCompanyToCustomerGroup(id: number, newCustomerGroupCompany: NewCustomerGroupCompany, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerGroupCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCompanyToCustomerGroup(id, newCustomerGroupCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewCustomerGroup} newCustomerGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerGroup(newCustomerGroup: NewCustomerGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerGroup(newCustomerGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerGroup(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerGroupCompanies(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCustomerGroupCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerGroupCompanies(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerGroups(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCustomerGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerGroups(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCustomerGroup(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCustomerGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCustomerGroupCompany(id: number, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCustomerGroupCompany(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CustomerGroupUpdate} customerGroupUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerGroup(id: number, customerGroupUpdate: CustomerGroupUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerGroup(id, customerGroupUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerGroupControllerApi - factory interface
 * @export
 */
export const CustomerGroupControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerGroupControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CustomerGroupControllerApiAddCompanyToCustomerGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyToCustomerGroup(requestParameters: CustomerGroupControllerApiAddCompanyToCustomerGroupRequest, options?: AxiosRequestConfig): AxiosPromise<CustomerGroupCompany> {
            return localVarFp.addCompanyToCustomerGroup(requestParameters.id, requestParameters.newCustomerGroupCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerGroupControllerApiCreateCustomerGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerGroup(requestParameters: CustomerGroupControllerApiCreateCustomerGroupRequest, options?: AxiosRequestConfig): AxiosPromise<CustomerGroup> {
            return localVarFp.createCustomerGroup(requestParameters.newCustomerGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerGroupControllerApiGetCustomerGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerGroup(requestParameters: CustomerGroupControllerApiGetCustomerGroupRequest, options?: AxiosRequestConfig): AxiosPromise<CustomerGroup> {
            return localVarFp.getCustomerGroup(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerGroupControllerApiGetCustomerGroupCompaniesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerGroupCompanies(requestParameters: CustomerGroupControllerApiGetCustomerGroupCompaniesRequest, options?: AxiosRequestConfig): AxiosPromise<PageCustomerGroupCompany> {
            return localVarFp.getCustomerGroupCompanies(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerGroupControllerApiGetCustomerGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerGroups(requestParameters: CustomerGroupControllerApiGetCustomerGroupsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageCustomerGroup> {
            return localVarFp.getCustomerGroups(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerGroupControllerApiRemoveCustomerGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCustomerGroup(requestParameters: CustomerGroupControllerApiRemoveCustomerGroupRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeCustomerGroup(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerGroupControllerApiRemoveCustomerGroupCompanyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCustomerGroupCompany(requestParameters: CustomerGroupControllerApiRemoveCustomerGroupCompanyRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeCustomerGroupCompany(requestParameters.id, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerGroupControllerApiUpdateCustomerGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerGroup(requestParameters: CustomerGroupControllerApiUpdateCustomerGroupRequest, options?: AxiosRequestConfig): AxiosPromise<CustomerGroup> {
            return localVarFp.updateCustomerGroup(requestParameters.id, requestParameters.customerGroupUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addCompanyToCustomerGroup operation in CustomerGroupControllerApi.
 * @export
 * @interface CustomerGroupControllerApiAddCompanyToCustomerGroupRequest
 */
export interface CustomerGroupControllerApiAddCompanyToCustomerGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerGroupControllerApiAddCompanyToCustomerGroup
     */
    readonly id: number

    /**
     * 
     * @type {NewCustomerGroupCompany}
     * @memberof CustomerGroupControllerApiAddCompanyToCustomerGroup
     */
    readonly newCustomerGroupCompany: NewCustomerGroupCompany
}

/**
 * Request parameters for createCustomerGroup operation in CustomerGroupControllerApi.
 * @export
 * @interface CustomerGroupControllerApiCreateCustomerGroupRequest
 */
export interface CustomerGroupControllerApiCreateCustomerGroupRequest {
    /**
     * 
     * @type {NewCustomerGroup}
     * @memberof CustomerGroupControllerApiCreateCustomerGroup
     */
    readonly newCustomerGroup: NewCustomerGroup
}

/**
 * Request parameters for getCustomerGroup operation in CustomerGroupControllerApi.
 * @export
 * @interface CustomerGroupControllerApiGetCustomerGroupRequest
 */
export interface CustomerGroupControllerApiGetCustomerGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerGroupControllerApiGetCustomerGroup
     */
    readonly id: number
}

/**
 * Request parameters for getCustomerGroupCompanies operation in CustomerGroupControllerApi.
 * @export
 * @interface CustomerGroupControllerApiGetCustomerGroupCompaniesRequest
 */
export interface CustomerGroupControllerApiGetCustomerGroupCompaniesRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerGroupControllerApiGetCustomerGroupCompanies
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof CustomerGroupControllerApiGetCustomerGroupCompanies
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof CustomerGroupControllerApiGetCustomerGroupCompanies
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof CustomerGroupControllerApiGetCustomerGroupCompanies
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getCustomerGroups operation in CustomerGroupControllerApi.
 * @export
 * @interface CustomerGroupControllerApiGetCustomerGroupsRequest
 */
export interface CustomerGroupControllerApiGetCustomerGroupsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof CustomerGroupControllerApiGetCustomerGroups
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof CustomerGroupControllerApiGetCustomerGroups
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof CustomerGroupControllerApiGetCustomerGroups
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for removeCustomerGroup operation in CustomerGroupControllerApi.
 * @export
 * @interface CustomerGroupControllerApiRemoveCustomerGroupRequest
 */
export interface CustomerGroupControllerApiRemoveCustomerGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerGroupControllerApiRemoveCustomerGroup
     */
    readonly id: number
}

/**
 * Request parameters for removeCustomerGroupCompany operation in CustomerGroupControllerApi.
 * @export
 * @interface CustomerGroupControllerApiRemoveCustomerGroupCompanyRequest
 */
export interface CustomerGroupControllerApiRemoveCustomerGroupCompanyRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerGroupControllerApiRemoveCustomerGroupCompany
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof CustomerGroupControllerApiRemoveCustomerGroupCompany
     */
    readonly name: string
}

/**
 * Request parameters for updateCustomerGroup operation in CustomerGroupControllerApi.
 * @export
 * @interface CustomerGroupControllerApiUpdateCustomerGroupRequest
 */
export interface CustomerGroupControllerApiUpdateCustomerGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerGroupControllerApiUpdateCustomerGroup
     */
    readonly id: number

    /**
     * 
     * @type {CustomerGroupUpdate}
     * @memberof CustomerGroupControllerApiUpdateCustomerGroup
     */
    readonly customerGroupUpdate: CustomerGroupUpdate
}

/**
 * CustomerGroupControllerApi - object-oriented interface
 * @export
 * @class CustomerGroupControllerApi
 * @extends {BaseAPI}
 */
export class CustomerGroupControllerApi extends BaseAPI {
    /**
     * 
     * @param {CustomerGroupControllerApiAddCompanyToCustomerGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupControllerApi
     */
    public addCompanyToCustomerGroup(requestParameters: CustomerGroupControllerApiAddCompanyToCustomerGroupRequest, options?: AxiosRequestConfig) {
        return CustomerGroupControllerApiFp(this.configuration).addCompanyToCustomerGroup(requestParameters.id, requestParameters.newCustomerGroupCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerGroupControllerApiCreateCustomerGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupControllerApi
     */
    public createCustomerGroup(requestParameters: CustomerGroupControllerApiCreateCustomerGroupRequest, options?: AxiosRequestConfig) {
        return CustomerGroupControllerApiFp(this.configuration).createCustomerGroup(requestParameters.newCustomerGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerGroupControllerApiGetCustomerGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupControllerApi
     */
    public getCustomerGroup(requestParameters: CustomerGroupControllerApiGetCustomerGroupRequest, options?: AxiosRequestConfig) {
        return CustomerGroupControllerApiFp(this.configuration).getCustomerGroup(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerGroupControllerApiGetCustomerGroupCompaniesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupControllerApi
     */
    public getCustomerGroupCompanies(requestParameters: CustomerGroupControllerApiGetCustomerGroupCompaniesRequest, options?: AxiosRequestConfig) {
        return CustomerGroupControllerApiFp(this.configuration).getCustomerGroupCompanies(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerGroupControllerApiGetCustomerGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupControllerApi
     */
    public getCustomerGroups(requestParameters: CustomerGroupControllerApiGetCustomerGroupsRequest = {}, options?: AxiosRequestConfig) {
        return CustomerGroupControllerApiFp(this.configuration).getCustomerGroups(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerGroupControllerApiRemoveCustomerGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupControllerApi
     */
    public removeCustomerGroup(requestParameters: CustomerGroupControllerApiRemoveCustomerGroupRequest, options?: AxiosRequestConfig) {
        return CustomerGroupControllerApiFp(this.configuration).removeCustomerGroup(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerGroupControllerApiRemoveCustomerGroupCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupControllerApi
     */
    public removeCustomerGroupCompany(requestParameters: CustomerGroupControllerApiRemoveCustomerGroupCompanyRequest, options?: AxiosRequestConfig) {
        return CustomerGroupControllerApiFp(this.configuration).removeCustomerGroupCompany(requestParameters.id, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerGroupControllerApiUpdateCustomerGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupControllerApi
     */
    public updateCustomerGroup(requestParameters: CustomerGroupControllerApiUpdateCustomerGroupRequest, options?: AxiosRequestConfig) {
        return CustomerGroupControllerApiFp(this.configuration).updateCustomerGroup(requestParameters.id, requestParameters.customerGroupUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DebtCollectionControllerApi - axios parameter creator
 * @export
 */
export const DebtCollectionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewDebtCollectionActivity} newDebtCollectionActivity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDebtCollectionActivity: async (id: number, newDebtCollectionActivity: NewDebtCollectionActivity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createDebtCollectionActivity', 'id', id)
            // verify required parameter 'newDebtCollectionActivity' is not null or undefined
            assertParamExists('createDebtCollectionActivity', 'newDebtCollectionActivity', newDebtCollectionActivity)
            const localVarPath = `/api/debt-collections/{id}/activities`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newDebtCollectionActivity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DebtCollectionReAssign} debtCollectionReAssign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debtCollectionsReAssign: async (debtCollectionReAssign: DebtCollectionReAssign, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'debtCollectionReAssign' is not null or undefined
            assertParamExists('debtCollectionsReAssign', 'debtCollectionReAssign', debtCollectionReAssign)
            const localVarPath = `/api/debt-collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(debtCollectionReAssign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [customerId] 
         * @param {string} [customerName] 
         * @param {string} [phoneNumber] 
         * @param {string} [registrationNumber] 
         * @param {number} [debtCollectorId] 
         * @param {Array<CollectionLevel>} [levels] 
         * @param {Array<CollectionStatus>} [statuses] 
         * @param {Array<CollectionActivityGeneralType>} [lastActivityGeneralTypes] 
         * @param {string} [lastActivityDate] 
         * @param {Array<CollectionActivityNextAction>} [nextActions] 
         * @param {string} [nextActionDate] 
         * @param {number} [principalAmountFrom] 
         * @param {number} [principalAmountTo] 
         * @param {number} [maxDaysOverdueFrom] 
         * @param {number} [maxDaysOverdueTo] 
         * @param {Array<LoanStatus>} [loanStatuses] 
         * @param {Array<CreditBureauStatus>} [creditBureauStatuses] 
         * @param {number} [childBranchId] 
         * @param {Array<ProductType>} [productTypes] 
         * @param {string} [provinceCode] 
         * @param {string} [districtCode] 
         * @param {boolean} [isCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCollections: async (page?: number, size?: number, sort?: Array<string>, customerId?: number, customerName?: string, phoneNumber?: string, registrationNumber?: string, debtCollectorId?: number, levels?: Array<CollectionLevel>, statuses?: Array<CollectionStatus>, lastActivityGeneralTypes?: Array<CollectionActivityGeneralType>, lastActivityDate?: string, nextActions?: Array<CollectionActivityNextAction>, nextActionDate?: string, principalAmountFrom?: number, principalAmountTo?: number, maxDaysOverdueFrom?: number, maxDaysOverdueTo?: number, loanStatuses?: Array<LoanStatus>, creditBureauStatuses?: Array<CreditBureauStatus>, childBranchId?: number, productTypes?: Array<ProductType>, provinceCode?: string, districtCode?: string, isCompleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/debt-collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (customerName !== undefined) {
                localVarQueryParameter['customerName'] = customerName;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (registrationNumber !== undefined) {
                localVarQueryParameter['registrationNumber'] = registrationNumber;
            }

            if (debtCollectorId !== undefined) {
                localVarQueryParameter['debtCollectorId'] = debtCollectorId;
            }

            if (levels) {
                localVarQueryParameter['levels'] = levels;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (lastActivityGeneralTypes) {
                localVarQueryParameter['lastActivityGeneralTypes'] = lastActivityGeneralTypes;
            }

            if (lastActivityDate !== undefined) {
                localVarQueryParameter['lastActivityDate'] = (lastActivityDate as any instanceof Date) ?
                    (lastActivityDate as any).toISOString().substring(0,10) :
                    lastActivityDate;
            }

            if (nextActions) {
                localVarQueryParameter['nextActions'] = nextActions;
            }

            if (nextActionDate !== undefined) {
                localVarQueryParameter['nextActionDate'] = (nextActionDate as any instanceof Date) ?
                    (nextActionDate as any).toISOString().substring(0,10) :
                    nextActionDate;
            }

            if (principalAmountFrom !== undefined) {
                localVarQueryParameter['principalAmountFrom'] = principalAmountFrom;
            }

            if (principalAmountTo !== undefined) {
                localVarQueryParameter['principalAmountTo'] = principalAmountTo;
            }

            if (maxDaysOverdueFrom !== undefined) {
                localVarQueryParameter['maxDaysOverdueFrom'] = maxDaysOverdueFrom;
            }

            if (maxDaysOverdueTo !== undefined) {
                localVarQueryParameter['maxDaysOverdueTo'] = maxDaysOverdueTo;
            }

            if (loanStatuses) {
                localVarQueryParameter['loanStatuses'] = loanStatuses;
            }

            if (creditBureauStatuses) {
                localVarQueryParameter['creditBureauStatuses'] = creditBureauStatuses;
            }

            if (childBranchId !== undefined) {
                localVarQueryParameter['childBranchId'] = childBranchId;
            }

            if (productTypes) {
                localVarQueryParameter['productTypes'] = productTypes;
            }

            if (provinceCode !== undefined) {
                localVarQueryParameter['provinceCode'] = provinceCode;
            }

            if (districtCode !== undefined) {
                localVarQueryParameter['districtCode'] = districtCode;
            }

            if (isCompleted !== undefined) {
                localVarQueryParameter['isCompleted'] = isCompleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {string} [customerName] 
         * @param {string} [phoneNumber] 
         * @param {string} [registrationNumber] 
         * @param {number} [debtCollectorId] 
         * @param {Array<CollectionLevel>} [levels] 
         * @param {Array<CollectionStatus>} [statuses] 
         * @param {Array<CollectionActivityGeneralType>} [lastActivityGeneralTypes] 
         * @param {string} [lastActivityDate] 
         * @param {Array<CollectionActivityNextAction>} [nextActions] 
         * @param {string} [nextActionDate] 
         * @param {number} [principalAmountFrom] 
         * @param {number} [principalAmountTo] 
         * @param {number} [maxDaysOverdueFrom] 
         * @param {number} [maxDaysOverdueTo] 
         * @param {Array<LoanStatus>} [loanStatuses] 
         * @param {Array<CreditBureauStatus>} [creditBureauStatuses] 
         * @param {number} [childBranchId] 
         * @param {Array<ProductType>} [productTypes] 
         * @param {string} [provinceCode] 
         * @param {string} [districtCode] 
         * @param {boolean} [isCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionSummary: async (customerId?: number, customerName?: string, phoneNumber?: string, registrationNumber?: string, debtCollectorId?: number, levels?: Array<CollectionLevel>, statuses?: Array<CollectionStatus>, lastActivityGeneralTypes?: Array<CollectionActivityGeneralType>, lastActivityDate?: string, nextActions?: Array<CollectionActivityNextAction>, nextActionDate?: string, principalAmountFrom?: number, principalAmountTo?: number, maxDaysOverdueFrom?: number, maxDaysOverdueTo?: number, loanStatuses?: Array<LoanStatus>, creditBureauStatuses?: Array<CreditBureauStatus>, childBranchId?: number, productTypes?: Array<ProductType>, provinceCode?: string, districtCode?: string, isCompleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/debt-collections/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (customerName !== undefined) {
                localVarQueryParameter['customerName'] = customerName;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (registrationNumber !== undefined) {
                localVarQueryParameter['registrationNumber'] = registrationNumber;
            }

            if (debtCollectorId !== undefined) {
                localVarQueryParameter['debtCollectorId'] = debtCollectorId;
            }

            if (levels) {
                localVarQueryParameter['levels'] = levels;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (lastActivityGeneralTypes) {
                localVarQueryParameter['lastActivityGeneralTypes'] = lastActivityGeneralTypes;
            }

            if (lastActivityDate !== undefined) {
                localVarQueryParameter['lastActivityDate'] = (lastActivityDate as any instanceof Date) ?
                    (lastActivityDate as any).toISOString().substring(0,10) :
                    lastActivityDate;
            }

            if (nextActions) {
                localVarQueryParameter['nextActions'] = nextActions;
            }

            if (nextActionDate !== undefined) {
                localVarQueryParameter['nextActionDate'] = (nextActionDate as any instanceof Date) ?
                    (nextActionDate as any).toISOString().substring(0,10) :
                    nextActionDate;
            }

            if (principalAmountFrom !== undefined) {
                localVarQueryParameter['principalAmountFrom'] = principalAmountFrom;
            }

            if (principalAmountTo !== undefined) {
                localVarQueryParameter['principalAmountTo'] = principalAmountTo;
            }

            if (maxDaysOverdueFrom !== undefined) {
                localVarQueryParameter['maxDaysOverdueFrom'] = maxDaysOverdueFrom;
            }

            if (maxDaysOverdueTo !== undefined) {
                localVarQueryParameter['maxDaysOverdueTo'] = maxDaysOverdueTo;
            }

            if (loanStatuses) {
                localVarQueryParameter['loanStatuses'] = loanStatuses;
            }

            if (creditBureauStatuses) {
                localVarQueryParameter['creditBureauStatuses'] = creditBureauStatuses;
            }

            if (childBranchId !== undefined) {
                localVarQueryParameter['childBranchId'] = childBranchId;
            }

            if (productTypes) {
                localVarQueryParameter['productTypes'] = productTypes;
            }

            if (provinceCode !== undefined) {
                localVarQueryParameter['provinceCode'] = provinceCode;
            }

            if (districtCode !== undefined) {
                localVarQueryParameter['districtCode'] = districtCode;
            }

            if (isCompleted !== undefined) {
                localVarQueryParameter['isCompleted'] = isCompleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtCollection: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDebtCollection', 'id', id)
            const localVarPath = `/api/debt-collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [createdAtFrom] 
         * @param {string} [createdAtTo] 
         * @param {Array<CollectionActivityGeneralType>} [generalTypes] 
         * @param {Array<CollectionActivityStatus>} [statuses] 
         * @param {boolean} [civilServantInvolved] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtCollectionActivities: async (id: number, createdAtFrom?: string, createdAtTo?: string, generalTypes?: Array<CollectionActivityGeneralType>, statuses?: Array<CollectionActivityStatus>, civilServantInvolved?: boolean, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDebtCollectionActivities', 'id', id)
            const localVarPath = `/api/debt-collections/{id}/activities`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = (createdAtFrom as any instanceof Date) ?
                    (createdAtFrom as any).toISOString().substring(0,10) :
                    createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = (createdAtTo as any instanceof Date) ?
                    (createdAtTo as any).toISOString().substring(0,10) :
                    createdAtTo;
            }

            if (generalTypes) {
                localVarQueryParameter['generalTypes'] = generalTypes;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (civilServantInvolved !== undefined) {
                localVarQueryParameter['civilServantInvolved'] = civilServantInvolved;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtCollectionActivity: async (id: number, activityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDebtCollectionActivity', 'id', id)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getDebtCollectionActivity', 'activityId', activityId)
            const localVarPath = `/api/debt-collections/{id}/activities/{activityId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtCollectionCustomerActiveLoans: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDebtCollectionCustomerActiveLoans', 'id', id)
            const localVarPath = `/api/debt-collections/{id}/loans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtCollectorCandidates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/debt-collections/collectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateDebtCollectionRequest} updateDebtCollectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDebtCollection: async (id: number, updateDebtCollectionRequest: UpdateDebtCollectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDebtCollection', 'id', id)
            // verify required parameter 'updateDebtCollectionRequest' is not null or undefined
            assertParamExists('updateDebtCollection', 'updateDebtCollectionRequest', updateDebtCollectionRequest)
            const localVarPath = `/api/debt-collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDebtCollectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DebtCollectionControllerApi - functional programming interface
 * @export
 */
export const DebtCollectionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DebtCollectionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewDebtCollectionActivity} newDebtCollectionActivity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDebtCollectionActivity(id: number, newDebtCollectionActivity: NewDebtCollectionActivity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDebtCollectionActivity(id, newDebtCollectionActivity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DebtCollectionReAssign} debtCollectionReAssign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async debtCollectionsReAssign(debtCollectionReAssign: DebtCollectionReAssign, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.debtCollectionsReAssign(debtCollectionReAssign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [customerId] 
         * @param {string} [customerName] 
         * @param {string} [phoneNumber] 
         * @param {string} [registrationNumber] 
         * @param {number} [debtCollectorId] 
         * @param {Array<CollectionLevel>} [levels] 
         * @param {Array<CollectionStatus>} [statuses] 
         * @param {Array<CollectionActivityGeneralType>} [lastActivityGeneralTypes] 
         * @param {string} [lastActivityDate] 
         * @param {Array<CollectionActivityNextAction>} [nextActions] 
         * @param {string} [nextActionDate] 
         * @param {number} [principalAmountFrom] 
         * @param {number} [principalAmountTo] 
         * @param {number} [maxDaysOverdueFrom] 
         * @param {number} [maxDaysOverdueTo] 
         * @param {Array<LoanStatus>} [loanStatuses] 
         * @param {Array<CreditBureauStatus>} [creditBureauStatuses] 
         * @param {number} [childBranchId] 
         * @param {Array<ProductType>} [productTypes] 
         * @param {string} [provinceCode] 
         * @param {string} [districtCode] 
         * @param {boolean} [isCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCollections(page?: number, size?: number, sort?: Array<string>, customerId?: number, customerName?: string, phoneNumber?: string, registrationNumber?: string, debtCollectorId?: number, levels?: Array<CollectionLevel>, statuses?: Array<CollectionStatus>, lastActivityGeneralTypes?: Array<CollectionActivityGeneralType>, lastActivityDate?: string, nextActions?: Array<CollectionActivityNextAction>, nextActionDate?: string, principalAmountFrom?: number, principalAmountTo?: number, maxDaysOverdueFrom?: number, maxDaysOverdueTo?: number, loanStatuses?: Array<LoanStatus>, creditBureauStatuses?: Array<CreditBureauStatus>, childBranchId?: number, productTypes?: Array<ProductType>, provinceCode?: string, districtCode?: string, isCompleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDebtCollectionListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCollections(page, size, sort, customerId, customerName, phoneNumber, registrationNumber, debtCollectorId, levels, statuses, lastActivityGeneralTypes, lastActivityDate, nextActions, nextActionDate, principalAmountFrom, principalAmountTo, maxDaysOverdueFrom, maxDaysOverdueTo, loanStatuses, creditBureauStatuses, childBranchId, productTypes, provinceCode, districtCode, isCompleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {string} [customerName] 
         * @param {string} [phoneNumber] 
         * @param {string} [registrationNumber] 
         * @param {number} [debtCollectorId] 
         * @param {Array<CollectionLevel>} [levels] 
         * @param {Array<CollectionStatus>} [statuses] 
         * @param {Array<CollectionActivityGeneralType>} [lastActivityGeneralTypes] 
         * @param {string} [lastActivityDate] 
         * @param {Array<CollectionActivityNextAction>} [nextActions] 
         * @param {string} [nextActionDate] 
         * @param {number} [principalAmountFrom] 
         * @param {number} [principalAmountTo] 
         * @param {number} [maxDaysOverdueFrom] 
         * @param {number} [maxDaysOverdueTo] 
         * @param {Array<LoanStatus>} [loanStatuses] 
         * @param {Array<CreditBureauStatus>} [creditBureauStatuses] 
         * @param {number} [childBranchId] 
         * @param {Array<ProductType>} [productTypes] 
         * @param {string} [provinceCode] 
         * @param {string} [districtCode] 
         * @param {boolean} [isCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionSummary(customerId?: number, customerName?: string, phoneNumber?: string, registrationNumber?: string, debtCollectorId?: number, levels?: Array<CollectionLevel>, statuses?: Array<CollectionStatus>, lastActivityGeneralTypes?: Array<CollectionActivityGeneralType>, lastActivityDate?: string, nextActions?: Array<CollectionActivityNextAction>, nextActionDate?: string, principalAmountFrom?: number, principalAmountTo?: number, maxDaysOverdueFrom?: number, maxDaysOverdueTo?: number, loanStatuses?: Array<LoanStatus>, creditBureauStatuses?: Array<CreditBureauStatus>, childBranchId?: number, productTypes?: Array<ProductType>, provinceCode?: string, districtCode?: string, isCompleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DebtCollectionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionSummary(customerId, customerName, phoneNumber, registrationNumber, debtCollectorId, levels, statuses, lastActivityGeneralTypes, lastActivityDate, nextActions, nextActionDate, principalAmountFrom, principalAmountTo, maxDaysOverdueFrom, maxDaysOverdueTo, loanStatuses, creditBureauStatuses, childBranchId, productTypes, provinceCode, districtCode, isCompleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDebtCollection(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DebtCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDebtCollection(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [createdAtFrom] 
         * @param {string} [createdAtTo] 
         * @param {Array<CollectionActivityGeneralType>} [generalTypes] 
         * @param {Array<CollectionActivityStatus>} [statuses] 
         * @param {boolean} [civilServantInvolved] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDebtCollectionActivities(id: number, createdAtFrom?: string, createdAtTo?: string, generalTypes?: Array<CollectionActivityGeneralType>, statuses?: Array<CollectionActivityStatus>, civilServantInvolved?: boolean, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDebtCollectionActivityListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDebtCollectionActivities(id, createdAtFrom, createdAtTo, generalTypes, statuses, civilServantInvolved, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDebtCollectionActivity(id: number, activityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DebtCollectionActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDebtCollectionActivity(id, activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDebtCollectionCustomerActiveLoans(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DebtCollectionLoanListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDebtCollectionCustomerActiveLoans(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDebtCollectorCandidates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DebtCollector>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDebtCollectorCandidates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateDebtCollectionRequest} updateDebtCollectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDebtCollection(id: number, updateDebtCollectionRequest: UpdateDebtCollectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDebtCollection(id, updateDebtCollectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DebtCollectionControllerApi - factory interface
 * @export
 */
export const DebtCollectionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DebtCollectionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {DebtCollectionControllerApiCreateDebtCollectionActivityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDebtCollectionActivity(requestParameters: DebtCollectionControllerApiCreateDebtCollectionActivityRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createDebtCollectionActivity(requestParameters.id, requestParameters.newDebtCollectionActivity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DebtCollectionControllerApiDebtCollectionsReAssignRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debtCollectionsReAssign(requestParameters: DebtCollectionControllerApiDebtCollectionsReAssignRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.debtCollectionsReAssign(requestParameters.debtCollectionReAssign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DebtCollectionControllerApiGetAllCollectionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCollections(requestParameters: DebtCollectionControllerApiGetAllCollectionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageDebtCollectionListEntry> {
            return localVarFp.getAllCollections(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.customerId, requestParameters.customerName, requestParameters.phoneNumber, requestParameters.registrationNumber, requestParameters.debtCollectorId, requestParameters.levels, requestParameters.statuses, requestParameters.lastActivityGeneralTypes, requestParameters.lastActivityDate, requestParameters.nextActions, requestParameters.nextActionDate, requestParameters.principalAmountFrom, requestParameters.principalAmountTo, requestParameters.maxDaysOverdueFrom, requestParameters.maxDaysOverdueTo, requestParameters.loanStatuses, requestParameters.creditBureauStatuses, requestParameters.childBranchId, requestParameters.productTypes, requestParameters.provinceCode, requestParameters.districtCode, requestParameters.isCompleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DebtCollectionControllerApiGetCollectionSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionSummary(requestParameters: DebtCollectionControllerApiGetCollectionSummaryRequest = {}, options?: AxiosRequestConfig): AxiosPromise<DebtCollectionSummary> {
            return localVarFp.getCollectionSummary(requestParameters.customerId, requestParameters.customerName, requestParameters.phoneNumber, requestParameters.registrationNumber, requestParameters.debtCollectorId, requestParameters.levels, requestParameters.statuses, requestParameters.lastActivityGeneralTypes, requestParameters.lastActivityDate, requestParameters.nextActions, requestParameters.nextActionDate, requestParameters.principalAmountFrom, requestParameters.principalAmountTo, requestParameters.maxDaysOverdueFrom, requestParameters.maxDaysOverdueTo, requestParameters.loanStatuses, requestParameters.creditBureauStatuses, requestParameters.childBranchId, requestParameters.productTypes, requestParameters.provinceCode, requestParameters.districtCode, requestParameters.isCompleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DebtCollectionControllerApiGetDebtCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtCollection(requestParameters: DebtCollectionControllerApiGetDebtCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<DebtCollection> {
            return localVarFp.getDebtCollection(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DebtCollectionControllerApiGetDebtCollectionActivitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtCollectionActivities(requestParameters: DebtCollectionControllerApiGetDebtCollectionActivitiesRequest, options?: AxiosRequestConfig): AxiosPromise<PageDebtCollectionActivityListEntry> {
            return localVarFp.getDebtCollectionActivities(requestParameters.id, requestParameters.createdAtFrom, requestParameters.createdAtTo, requestParameters.generalTypes, requestParameters.statuses, requestParameters.civilServantInvolved, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DebtCollectionControllerApiGetDebtCollectionActivityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtCollectionActivity(requestParameters: DebtCollectionControllerApiGetDebtCollectionActivityRequest, options?: AxiosRequestConfig): AxiosPromise<DebtCollectionActivity> {
            return localVarFp.getDebtCollectionActivity(requestParameters.id, requestParameters.activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DebtCollectionControllerApiGetDebtCollectionCustomerActiveLoansRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtCollectionCustomerActiveLoans(requestParameters: DebtCollectionControllerApiGetDebtCollectionCustomerActiveLoansRequest, options?: AxiosRequestConfig): AxiosPromise<Array<DebtCollectionLoanListEntry>> {
            return localVarFp.getDebtCollectionCustomerActiveLoans(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtCollectorCandidates(options?: AxiosRequestConfig): AxiosPromise<Array<DebtCollector>> {
            return localVarFp.getDebtCollectorCandidates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DebtCollectionControllerApiUpdateDebtCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDebtCollection(requestParameters: DebtCollectionControllerApiUpdateDebtCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateDebtCollection(requestParameters.id, requestParameters.updateDebtCollectionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDebtCollectionActivity operation in DebtCollectionControllerApi.
 * @export
 * @interface DebtCollectionControllerApiCreateDebtCollectionActivityRequest
 */
export interface DebtCollectionControllerApiCreateDebtCollectionActivityRequest {
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiCreateDebtCollectionActivity
     */
    readonly id: number

    /**
     * 
     * @type {NewDebtCollectionActivity}
     * @memberof DebtCollectionControllerApiCreateDebtCollectionActivity
     */
    readonly newDebtCollectionActivity: NewDebtCollectionActivity
}

/**
 * Request parameters for debtCollectionsReAssign operation in DebtCollectionControllerApi.
 * @export
 * @interface DebtCollectionControllerApiDebtCollectionsReAssignRequest
 */
export interface DebtCollectionControllerApiDebtCollectionsReAssignRequest {
    /**
     * 
     * @type {DebtCollectionReAssign}
     * @memberof DebtCollectionControllerApiDebtCollectionsReAssign
     */
    readonly debtCollectionReAssign: DebtCollectionReAssign
}

/**
 * Request parameters for getAllCollections operation in DebtCollectionControllerApi.
 * @export
 * @interface DebtCollectionControllerApiGetAllCollectionsRequest
 */
export interface DebtCollectionControllerApiGetAllCollectionsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly customerName?: string

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly phoneNumber?: string

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly registrationNumber?: string

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly debtCollectorId?: number

    /**
     * 
     * @type {Array<CollectionLevel>}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly levels?: Array<CollectionLevel>

    /**
     * 
     * @type {Array<CollectionStatus>}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly statuses?: Array<CollectionStatus>

    /**
     * 
     * @type {Array<CollectionActivityGeneralType>}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly lastActivityGeneralTypes?: Array<CollectionActivityGeneralType>

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly lastActivityDate?: string

    /**
     * 
     * @type {Array<CollectionActivityNextAction>}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly nextActions?: Array<CollectionActivityNextAction>

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly nextActionDate?: string

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly principalAmountFrom?: number

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly principalAmountTo?: number

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly maxDaysOverdueFrom?: number

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly maxDaysOverdueTo?: number

    /**
     * 
     * @type {Array<LoanStatus>}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly loanStatuses?: Array<LoanStatus>

    /**
     * 
     * @type {Array<CreditBureauStatus>}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly creditBureauStatuses?: Array<CreditBureauStatus>

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly childBranchId?: number

    /**
     * 
     * @type {Array<ProductType>}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly productTypes?: Array<ProductType>

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly provinceCode?: string

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly districtCode?: string

    /**
     * 
     * @type {boolean}
     * @memberof DebtCollectionControllerApiGetAllCollections
     */
    readonly isCompleted?: boolean
}

/**
 * Request parameters for getCollectionSummary operation in DebtCollectionControllerApi.
 * @export
 * @interface DebtCollectionControllerApiGetCollectionSummaryRequest
 */
export interface DebtCollectionControllerApiGetCollectionSummaryRequest {
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly customerName?: string

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly phoneNumber?: string

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly registrationNumber?: string

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly debtCollectorId?: number

    /**
     * 
     * @type {Array<CollectionLevel>}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly levels?: Array<CollectionLevel>

    /**
     * 
     * @type {Array<CollectionStatus>}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly statuses?: Array<CollectionStatus>

    /**
     * 
     * @type {Array<CollectionActivityGeneralType>}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly lastActivityGeneralTypes?: Array<CollectionActivityGeneralType>

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly lastActivityDate?: string

    /**
     * 
     * @type {Array<CollectionActivityNextAction>}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly nextActions?: Array<CollectionActivityNextAction>

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly nextActionDate?: string

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly principalAmountFrom?: number

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly principalAmountTo?: number

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly maxDaysOverdueFrom?: number

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly maxDaysOverdueTo?: number

    /**
     * 
     * @type {Array<LoanStatus>}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly loanStatuses?: Array<LoanStatus>

    /**
     * 
     * @type {Array<CreditBureauStatus>}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly creditBureauStatuses?: Array<CreditBureauStatus>

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly childBranchId?: number

    /**
     * 
     * @type {Array<ProductType>}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly productTypes?: Array<ProductType>

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly provinceCode?: string

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly districtCode?: string

    /**
     * 
     * @type {boolean}
     * @memberof DebtCollectionControllerApiGetCollectionSummary
     */
    readonly isCompleted?: boolean
}

/**
 * Request parameters for getDebtCollection operation in DebtCollectionControllerApi.
 * @export
 * @interface DebtCollectionControllerApiGetDebtCollectionRequest
 */
export interface DebtCollectionControllerApiGetDebtCollectionRequest {
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetDebtCollection
     */
    readonly id: number
}

/**
 * Request parameters for getDebtCollectionActivities operation in DebtCollectionControllerApi.
 * @export
 * @interface DebtCollectionControllerApiGetDebtCollectionActivitiesRequest
 */
export interface DebtCollectionControllerApiGetDebtCollectionActivitiesRequest {
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetDebtCollectionActivities
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetDebtCollectionActivities
     */
    readonly createdAtFrom?: string

    /**
     * 
     * @type {string}
     * @memberof DebtCollectionControllerApiGetDebtCollectionActivities
     */
    readonly createdAtTo?: string

    /**
     * 
     * @type {Array<CollectionActivityGeneralType>}
     * @memberof DebtCollectionControllerApiGetDebtCollectionActivities
     */
    readonly generalTypes?: Array<CollectionActivityGeneralType>

    /**
     * 
     * @type {Array<CollectionActivityStatus>}
     * @memberof DebtCollectionControllerApiGetDebtCollectionActivities
     */
    readonly statuses?: Array<CollectionActivityStatus>

    /**
     * 
     * @type {boolean}
     * @memberof DebtCollectionControllerApiGetDebtCollectionActivities
     */
    readonly civilServantInvolved?: boolean

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof DebtCollectionControllerApiGetDebtCollectionActivities
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof DebtCollectionControllerApiGetDebtCollectionActivities
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof DebtCollectionControllerApiGetDebtCollectionActivities
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getDebtCollectionActivity operation in DebtCollectionControllerApi.
 * @export
 * @interface DebtCollectionControllerApiGetDebtCollectionActivityRequest
 */
export interface DebtCollectionControllerApiGetDebtCollectionActivityRequest {
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetDebtCollectionActivity
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetDebtCollectionActivity
     */
    readonly activityId: number
}

/**
 * Request parameters for getDebtCollectionCustomerActiveLoans operation in DebtCollectionControllerApi.
 * @export
 * @interface DebtCollectionControllerApiGetDebtCollectionCustomerActiveLoansRequest
 */
export interface DebtCollectionControllerApiGetDebtCollectionCustomerActiveLoansRequest {
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiGetDebtCollectionCustomerActiveLoans
     */
    readonly id: number
}

/**
 * Request parameters for updateDebtCollection operation in DebtCollectionControllerApi.
 * @export
 * @interface DebtCollectionControllerApiUpdateDebtCollectionRequest
 */
export interface DebtCollectionControllerApiUpdateDebtCollectionRequest {
    /**
     * 
     * @type {number}
     * @memberof DebtCollectionControllerApiUpdateDebtCollection
     */
    readonly id: number

    /**
     * 
     * @type {UpdateDebtCollectionRequest}
     * @memberof DebtCollectionControllerApiUpdateDebtCollection
     */
    readonly updateDebtCollectionRequest: UpdateDebtCollectionRequest
}

/**
 * DebtCollectionControllerApi - object-oriented interface
 * @export
 * @class DebtCollectionControllerApi
 * @extends {BaseAPI}
 */
export class DebtCollectionControllerApi extends BaseAPI {
    /**
     * 
     * @param {DebtCollectionControllerApiCreateDebtCollectionActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtCollectionControllerApi
     */
    public createDebtCollectionActivity(requestParameters: DebtCollectionControllerApiCreateDebtCollectionActivityRequest, options?: AxiosRequestConfig) {
        return DebtCollectionControllerApiFp(this.configuration).createDebtCollectionActivity(requestParameters.id, requestParameters.newDebtCollectionActivity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DebtCollectionControllerApiDebtCollectionsReAssignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtCollectionControllerApi
     */
    public debtCollectionsReAssign(requestParameters: DebtCollectionControllerApiDebtCollectionsReAssignRequest, options?: AxiosRequestConfig) {
        return DebtCollectionControllerApiFp(this.configuration).debtCollectionsReAssign(requestParameters.debtCollectionReAssign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DebtCollectionControllerApiGetAllCollectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtCollectionControllerApi
     */
    public getAllCollections(requestParameters: DebtCollectionControllerApiGetAllCollectionsRequest = {}, options?: AxiosRequestConfig) {
        return DebtCollectionControllerApiFp(this.configuration).getAllCollections(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.customerId, requestParameters.customerName, requestParameters.phoneNumber, requestParameters.registrationNumber, requestParameters.debtCollectorId, requestParameters.levels, requestParameters.statuses, requestParameters.lastActivityGeneralTypes, requestParameters.lastActivityDate, requestParameters.nextActions, requestParameters.nextActionDate, requestParameters.principalAmountFrom, requestParameters.principalAmountTo, requestParameters.maxDaysOverdueFrom, requestParameters.maxDaysOverdueTo, requestParameters.loanStatuses, requestParameters.creditBureauStatuses, requestParameters.childBranchId, requestParameters.productTypes, requestParameters.provinceCode, requestParameters.districtCode, requestParameters.isCompleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DebtCollectionControllerApiGetCollectionSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtCollectionControllerApi
     */
    public getCollectionSummary(requestParameters: DebtCollectionControllerApiGetCollectionSummaryRequest = {}, options?: AxiosRequestConfig) {
        return DebtCollectionControllerApiFp(this.configuration).getCollectionSummary(requestParameters.customerId, requestParameters.customerName, requestParameters.phoneNumber, requestParameters.registrationNumber, requestParameters.debtCollectorId, requestParameters.levels, requestParameters.statuses, requestParameters.lastActivityGeneralTypes, requestParameters.lastActivityDate, requestParameters.nextActions, requestParameters.nextActionDate, requestParameters.principalAmountFrom, requestParameters.principalAmountTo, requestParameters.maxDaysOverdueFrom, requestParameters.maxDaysOverdueTo, requestParameters.loanStatuses, requestParameters.creditBureauStatuses, requestParameters.childBranchId, requestParameters.productTypes, requestParameters.provinceCode, requestParameters.districtCode, requestParameters.isCompleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DebtCollectionControllerApiGetDebtCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtCollectionControllerApi
     */
    public getDebtCollection(requestParameters: DebtCollectionControllerApiGetDebtCollectionRequest, options?: AxiosRequestConfig) {
        return DebtCollectionControllerApiFp(this.configuration).getDebtCollection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DebtCollectionControllerApiGetDebtCollectionActivitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtCollectionControllerApi
     */
    public getDebtCollectionActivities(requestParameters: DebtCollectionControllerApiGetDebtCollectionActivitiesRequest, options?: AxiosRequestConfig) {
        return DebtCollectionControllerApiFp(this.configuration).getDebtCollectionActivities(requestParameters.id, requestParameters.createdAtFrom, requestParameters.createdAtTo, requestParameters.generalTypes, requestParameters.statuses, requestParameters.civilServantInvolved, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DebtCollectionControllerApiGetDebtCollectionActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtCollectionControllerApi
     */
    public getDebtCollectionActivity(requestParameters: DebtCollectionControllerApiGetDebtCollectionActivityRequest, options?: AxiosRequestConfig) {
        return DebtCollectionControllerApiFp(this.configuration).getDebtCollectionActivity(requestParameters.id, requestParameters.activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DebtCollectionControllerApiGetDebtCollectionCustomerActiveLoansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtCollectionControllerApi
     */
    public getDebtCollectionCustomerActiveLoans(requestParameters: DebtCollectionControllerApiGetDebtCollectionCustomerActiveLoansRequest, options?: AxiosRequestConfig) {
        return DebtCollectionControllerApiFp(this.configuration).getDebtCollectionCustomerActiveLoans(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtCollectionControllerApi
     */
    public getDebtCollectorCandidates(options?: AxiosRequestConfig) {
        return DebtCollectionControllerApiFp(this.configuration).getDebtCollectorCandidates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DebtCollectionControllerApiUpdateDebtCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtCollectionControllerApi
     */
    public updateDebtCollection(requestParameters: DebtCollectionControllerApiUpdateDebtCollectionRequest, options?: AxiosRequestConfig) {
        return DebtCollectionControllerApiFp(this.configuration).updateDebtCollection(requestParameters.id, requestParameters.updateDebtCollectionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DictionaryControllerApi - axios parameter creator
 * @export
 */
export const DictionaryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionActivityTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dictionaries/collection-activity-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalIndustries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dictionaries/legal-industries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanPurposes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dictionaries/loan-purposes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictionaryControllerApi - functional programming interface
 * @export
 */
export const DictionaryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictionaryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionActivityTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CollectionActivityGeneralTypeDictionaryItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionActivityTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalIndustries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LegalIndustryItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalIndustries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanPurposes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoanPurposeDictionaryItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanPurposes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictionaryControllerApi - factory interface
 * @export
 */
export const DictionaryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictionaryControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionActivityTypes(options?: AxiosRequestConfig): AxiosPromise<Array<CollectionActivityGeneralTypeDictionaryItem>> {
            return localVarFp.getCollectionActivityTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalIndustries(options?: AxiosRequestConfig): AxiosPromise<Array<LegalIndustryItem>> {
            return localVarFp.getLegalIndustries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanPurposes(options?: AxiosRequestConfig): AxiosPromise<Array<LoanPurposeDictionaryItem>> {
            return localVarFp.getLoanPurposes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictionaryControllerApi - object-oriented interface
 * @export
 * @class DictionaryControllerApi
 * @extends {BaseAPI}
 */
export class DictionaryControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryControllerApi
     */
    public getCollectionActivityTypes(options?: AxiosRequestConfig) {
        return DictionaryControllerApiFp(this.configuration).getCollectionActivityTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryControllerApi
     */
    public getLegalIndustries(options?: AxiosRequestConfig) {
        return DictionaryControllerApiFp(this.configuration).getLegalIndustries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryControllerApi
     */
    public getLoanPurposes(options?: AxiosRequestConfig) {
        return DictionaryControllerApiFp(this.configuration).getLoanPurposes(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DocumentControllerApi - axios parameter creator
 * @export
 */
export const DocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} roomId 
         * @param {NewDocument} newDocument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument: async (roomId: number, newDocument: NewDocument, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('createDocument', 'roomId', roomId)
            // verify required parameter 'newDocument' is not null or undefined
            assertParamExists('createDocument', 'newDocument', newDocument)
            const localVarPath = `/api/rooms/{roomId}/documents`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newDocument, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roomId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (roomId: number, documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('deleteDocument', 'roomId', roomId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteDocument', 'documentId', documentId)
            const localVarPath = `/api/rooms/{roomId}/documents/{documentId}`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roomId 
         * @param {Array<DocumentType>} [documentTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsByRoomId: async (roomId: number, documentTypes?: Array<DocumentType>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('getDocumentsByRoomId', 'roomId', roomId)
            const localVarPath = `/api/rooms/{roomId}/documents`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (documentTypes) {
                localVarQueryParameter['documentTypes'] = documentTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentControllerApi - functional programming interface
 * @export
 */
export const DocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} roomId 
         * @param {NewDocument} newDocument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocument(roomId: number, newDocument: NewDocument, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocument(roomId, newDocument, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roomId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(roomId: number, documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(roomId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roomId 
         * @param {Array<DocumentType>} [documentTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentsByRoomId(roomId: number, documentTypes?: Array<DocumentType>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentsByRoomId(roomId, documentTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentControllerApi - factory interface
 * @export
 */
export const DocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {DocumentControllerApiCreateDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument(requestParameters: DocumentControllerApiCreateDocumentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createDocument(requestParameters.roomId, requestParameters.newDocument, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentControllerApiDeleteDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(requestParameters: DocumentControllerApiDeleteDocumentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteDocument(requestParameters.roomId, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentControllerApiGetDocumentsByRoomIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsByRoomId(requestParameters: DocumentControllerApiGetDocumentsByRoomIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Document>> {
            return localVarFp.getDocumentsByRoomId(requestParameters.roomId, requestParameters.documentTypes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDocument operation in DocumentControllerApi.
 * @export
 * @interface DocumentControllerApiCreateDocumentRequest
 */
export interface DocumentControllerApiCreateDocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentControllerApiCreateDocument
     */
    readonly roomId: number

    /**
     * 
     * @type {NewDocument}
     * @memberof DocumentControllerApiCreateDocument
     */
    readonly newDocument: NewDocument
}

/**
 * Request parameters for deleteDocument operation in DocumentControllerApi.
 * @export
 * @interface DocumentControllerApiDeleteDocumentRequest
 */
export interface DocumentControllerApiDeleteDocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentControllerApiDeleteDocument
     */
    readonly roomId: number

    /**
     * 
     * @type {number}
     * @memberof DocumentControllerApiDeleteDocument
     */
    readonly documentId: number
}

/**
 * Request parameters for getDocumentsByRoomId operation in DocumentControllerApi.
 * @export
 * @interface DocumentControllerApiGetDocumentsByRoomIdRequest
 */
export interface DocumentControllerApiGetDocumentsByRoomIdRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentControllerApiGetDocumentsByRoomId
     */
    readonly roomId: number

    /**
     * 
     * @type {Array<DocumentType>}
     * @memberof DocumentControllerApiGetDocumentsByRoomId
     */
    readonly documentTypes?: Array<DocumentType>
}

/**
 * DocumentControllerApi - object-oriented interface
 * @export
 * @class DocumentControllerApi
 * @extends {BaseAPI}
 */
export class DocumentControllerApi extends BaseAPI {
    /**
     * 
     * @param {DocumentControllerApiCreateDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public createDocument(requestParameters: DocumentControllerApiCreateDocumentRequest, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).createDocument(requestParameters.roomId, requestParameters.newDocument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentControllerApiDeleteDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public deleteDocument(requestParameters: DocumentControllerApiDeleteDocumentRequest, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).deleteDocument(requestParameters.roomId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentControllerApiGetDocumentsByRoomIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getDocumentsByRoomId(requestParameters: DocumentControllerApiGetDocumentsByRoomIdRequest, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getDocumentsByRoomId(requestParameters.roomId, requestParameters.documentTypes, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DocumentRoomControllerApi - axios parameter creator
 * @export
 */
export const DocumentRoomControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentRoom: async (roomId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('getDocumentRoom', 'roomId', roomId)
            const localVarPath = `/api/document-rooms/{roomId}`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentRoomControllerApi - functional programming interface
 * @export
 */
export const DocumentRoomControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentRoomControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentRoom(roomId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentRoom>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentRoom(roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentRoomControllerApi - factory interface
 * @export
 */
export const DocumentRoomControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentRoomControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {DocumentRoomControllerApiGetDocumentRoomRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentRoom(requestParameters: DocumentRoomControllerApiGetDocumentRoomRequest, options?: AxiosRequestConfig): AxiosPromise<DocumentRoom> {
            return localVarFp.getDocumentRoom(requestParameters.roomId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDocumentRoom operation in DocumentRoomControllerApi.
 * @export
 * @interface DocumentRoomControllerApiGetDocumentRoomRequest
 */
export interface DocumentRoomControllerApiGetDocumentRoomRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentRoomControllerApiGetDocumentRoom
     */
    readonly roomId: number
}

/**
 * DocumentRoomControllerApi - object-oriented interface
 * @export
 * @class DocumentRoomControllerApi
 * @extends {BaseAPI}
 */
export class DocumentRoomControllerApi extends BaseAPI {
    /**
     * 
     * @param {DocumentRoomControllerApiGetDocumentRoomRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentRoomControllerApi
     */
    public getDocumentRoom(requestParameters: DocumentRoomControllerApiGetDocumentRoomRequest, options?: AxiosRequestConfig) {
        return DocumentRoomControllerApiFp(this.configuration).getDocumentRoom(requestParameters.roomId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DocumentTemplateControllerApi - axios parameter creator
 * @export
 */
export const DocumentTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {NewDocumentTemplate} newDocumentTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentTemplate: async (newDocumentTemplate: NewDocumentTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newDocumentTemplate' is not null or undefined
            assertParamExists('createDocumentTemplate', 'newDocumentTemplate', newDocumentTemplate)
            const localVarPath = `/api/document-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newDocumentTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/document-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentTemplateType} templateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplate: async (templateType: DocumentTemplateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('getDocumentTemplate', 'templateType', templateType)
            const localVarPath = `/api/document-templates/{templateType}`
                .replace(`{${"templateType"}}`, encodeURIComponent(String(templateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTemplateControllerApi - functional programming interface
 * @export
 */
export const DocumentTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {NewDocumentTemplate} newDocumentTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentTemplate(newDocumentTemplate: NewDocumentTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentTemplate(newDocumentTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDocumentTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentTemplateListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDocumentTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DocumentTemplateType} templateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTemplate(templateType: DocumentTemplateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTemplate(templateType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentTemplateControllerApi - factory interface
 * @export
 */
export const DocumentTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentTemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {DocumentTemplateControllerApiCreateDocumentTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentTemplate(requestParameters: DocumentTemplateControllerApiCreateDocumentTemplateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createDocumentTemplate(requestParameters.newDocumentTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentTemplates(options?: AxiosRequestConfig): AxiosPromise<Array<DocumentTemplateListEntry>> {
            return localVarFp.getAllDocumentTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentTemplateControllerApiGetDocumentTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplate(requestParameters: DocumentTemplateControllerApiGetDocumentTemplateRequest, options?: AxiosRequestConfig): AxiosPromise<DocumentTemplateListEntry> {
            return localVarFp.getDocumentTemplate(requestParameters.templateType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDocumentTemplate operation in DocumentTemplateControllerApi.
 * @export
 * @interface DocumentTemplateControllerApiCreateDocumentTemplateRequest
 */
export interface DocumentTemplateControllerApiCreateDocumentTemplateRequest {
    /**
     * 
     * @type {NewDocumentTemplate}
     * @memberof DocumentTemplateControllerApiCreateDocumentTemplate
     */
    readonly newDocumentTemplate: NewDocumentTemplate
}

/**
 * Request parameters for getDocumentTemplate operation in DocumentTemplateControllerApi.
 * @export
 * @interface DocumentTemplateControllerApiGetDocumentTemplateRequest
 */
export interface DocumentTemplateControllerApiGetDocumentTemplateRequest {
    /**
     * 
     * @type {DocumentTemplateType}
     * @memberof DocumentTemplateControllerApiGetDocumentTemplate
     */
    readonly templateType: DocumentTemplateType
}

/**
 * DocumentTemplateControllerApi - object-oriented interface
 * @export
 * @class DocumentTemplateControllerApi
 * @extends {BaseAPI}
 */
export class DocumentTemplateControllerApi extends BaseAPI {
    /**
     * 
     * @param {DocumentTemplateControllerApiCreateDocumentTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public createDocumentTemplate(requestParameters: DocumentTemplateControllerApiCreateDocumentTemplateRequest, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).createDocumentTemplate(requestParameters.newDocumentTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public getAllDocumentTemplates(options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).getAllDocumentTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentTemplateControllerApiGetDocumentTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public getDocumentTemplate(requestParameters: DocumentTemplateControllerApiGetDocumentTemplateRequest, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).getDocumentTemplate(requestParameters.templateType, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileControllerApi - axios parameter creator
 * @export
 */
export const FileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFile', 'file', file)
            const localVarPath = `/api/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileControllerApi - functional programming interface
 * @export
 */
export const FileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileControllerApi - factory interface
 * @export
 */
export const FileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {FileControllerApiUploadFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(requestParameters: FileControllerApiUploadFileRequest, options?: AxiosRequestConfig): AxiosPromise<UploadedFile> {
            return localVarFp.uploadFile(requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for uploadFile operation in FileControllerApi.
 * @export
 * @interface FileControllerApiUploadFileRequest
 */
export interface FileControllerApiUploadFileRequest {
    /**
     * 
     * @type {File}
     * @memberof FileControllerApiUploadFile
     */
    readonly file: File
}

/**
 * FileControllerApi - object-oriented interface
 * @export
 * @class FileControllerApi
 * @extends {BaseAPI}
 */
export class FileControllerApi extends BaseAPI {
    /**
     * 
     * @param {FileControllerApiUploadFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public uploadFile(requestParameters: FileControllerApiUploadFileRequest, options?: AxiosRequestConfig) {
        return FileControllerApiFp(this.configuration).uploadFile(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileRequestControllerApi - axios parameter creator
 * @export
 */
export const FileRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileRequest: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFileRequest', 'id', id)
            const localVarPath = `/api/file-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestFileRequest} requestFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestFile: async (requestFileRequest: RequestFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestFileRequest' is not null or undefined
            assertParamExists('requestFile', 'requestFileRequest', requestFileRequest)
            const localVarPath = `/api/file-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileRequestControllerApi - functional programming interface
 * @export
 */
export const FileRequestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileRequestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileRequest(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestFileRequest} requestFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestFile(requestFileRequest: RequestFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestFile(requestFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileRequestControllerApi - factory interface
 * @export
 */
export const FileRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileRequestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {FileRequestControllerApiGetFileRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileRequest(requestParameters: FileRequestControllerApiGetFileRequestRequest, options?: AxiosRequestConfig): AxiosPromise<FileRequest> {
            return localVarFp.getFileRequest(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileRequestControllerApiRequestFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestFile(requestParameters: FileRequestControllerApiRequestFileRequest, options?: AxiosRequestConfig): AxiosPromise<FileRequest> {
            return localVarFp.requestFile(requestParameters.requestFileRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getFileRequest operation in FileRequestControllerApi.
 * @export
 * @interface FileRequestControllerApiGetFileRequestRequest
 */
export interface FileRequestControllerApiGetFileRequestRequest {
    /**
     * 
     * @type {number}
     * @memberof FileRequestControllerApiGetFileRequest
     */
    readonly id: number
}

/**
 * Request parameters for requestFile operation in FileRequestControllerApi.
 * @export
 * @interface FileRequestControllerApiRequestFileRequest
 */
export interface FileRequestControllerApiRequestFileRequest {
    /**
     * 
     * @type {RequestFileRequest}
     * @memberof FileRequestControllerApiRequestFile
     */
    readonly requestFileRequest: RequestFileRequest
}

/**
 * FileRequestControllerApi - object-oriented interface
 * @export
 * @class FileRequestControllerApi
 * @extends {BaseAPI}
 */
export class FileRequestControllerApi extends BaseAPI {
    /**
     * 
     * @param {FileRequestControllerApiGetFileRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileRequestControllerApi
     */
    public getFileRequest(requestParameters: FileRequestControllerApiGetFileRequestRequest, options?: AxiosRequestConfig) {
        return FileRequestControllerApiFp(this.configuration).getFileRequest(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileRequestControllerApiRequestFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileRequestControllerApi
     */
    public requestFile(requestParameters: FileRequestControllerApiRequestFileRequest, options?: AxiosRequestConfig) {
        return FileRequestControllerApiFp(this.configuration).requestFile(requestParameters.requestFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IndividualControllerApi - axios parameter creator
 * @export
 */
export const IndividualControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLivestockIncomeType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addLivestockIncomeType', 'id', id)
            const localVarPath = `/api/individuals/{id}/livestockIncome`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSalaryIncomeType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addSalaryIncomeType', 'id', id)
            const localVarPath = `/api/individuals/{id}/salaryIncome`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateIndividualRequest} createIndividualRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndividual: async (createIndividualRequest: CreateIndividualRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createIndividualRequest' is not null or undefined
            assertParamExists('createIndividual', 'createIndividualRequest', createIndividualRequest)
            const localVarPath = `/api/individuals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIndividualRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {string} [customerName] 
         * @param {string} [registrationNumber] 
         * @param {string} [phone] 
         * @param {number} [relationshipManagerId] 
         * @param {string} [branchCode] 
         * @param {Array<CustomerVerificationStatus>} [verificationStatuses] 
         * @param {string} [civilId] 
         * @param {Array<CustomerKycLevel>} [kycLevel] 
         * @param {Citizenship} [citizenship] 
         * @param {string} [birthDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllIndividuals: async (page?: number, size?: number, sort?: Array<string>, id?: number, customerName?: string, registrationNumber?: string, phone?: string, relationshipManagerId?: number, branchCode?: string, verificationStatuses?: Array<CustomerVerificationStatus>, civilId?: string, kycLevel?: Array<CustomerKycLevel>, citizenship?: Citizenship, birthDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/individuals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (customerName !== undefined) {
                localVarQueryParameter['customerName'] = customerName;
            }

            if (registrationNumber !== undefined) {
                localVarQueryParameter['registrationNumber'] = registrationNumber;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (relationshipManagerId !== undefined) {
                localVarQueryParameter['relationshipManagerId'] = relationshipManagerId;
            }

            if (branchCode !== undefined) {
                localVarQueryParameter['branchCode'] = branchCode;
            }

            if (verificationStatuses) {
                localVarQueryParameter['verificationStatuses'] = verificationStatuses;
            }

            if (civilId !== undefined) {
                localVarQueryParameter['civilId'] = civilId;
            }

            if (kycLevel) {
                localVarQueryParameter['kycLevel'] = kycLevel;
            }

            if (citizenship !== undefined) {
                localVarQueryParameter['citizenship'] = citizenship;
            }

            if (birthDate !== undefined) {
                localVarQueryParameter['birthDate'] = (birthDate as any instanceof Date) ?
                    (birthDate as any).toISOString().substring(0,10) :
                    birthDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of provinces with districts and khoroos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAddresses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/individuals/getAllAddresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductOffer: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllProductOffer', 'id', id)
            const localVarPath = `/api/individuals/{id}/productOffer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationsSummaryByIndividualId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplicationsSummaryByIndividualId', 'id', id)
            const localVarPath = `/api/individuals/{id}/applications-summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableBankList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/individuals/bankList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessIncome: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBusinessIncome', 'id', id)
            const localVarPath = `/api/individuals/{id}/business-income`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessIncomes: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBusinessIncomes', 'id', id)
            const localVarPath = `/api/individuals/{id}/business-incomes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCibLoanData: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCibLoanData', 'id', id)
            const localVarPath = `/api/individuals/{id}/getCibLoanData`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditScoringResult: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCreditScoringResult', 'id', id)
            const localVarPath = `/api/individuals/{id}/scoring`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsHistory: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCsHistory', 'id', id)
            const localVarPath = `/api/individuals/{id}/csHistory`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsValue: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCsValue', 'id', id)
            const localVarPath = `/api/individuals/{id}/csValue`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerInformationTab: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerInformationTab', 'id', id)
            const localVarPath = `/api/individuals/{id}/customerInformationTab`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndividualIncomes: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIndividualIncomes', 'id', id)
            const localVarPath = `/api/individuals/{id}/incomes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndividualVerificationDecisions: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIndividualVerificationDecisions', 'id', id)
            const localVarPath = `/api/individuals/{id}/getIndividualVerificationDecisions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLivestockIncomeCheckDetails: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLivestockIncomeCheckDetails', 'id', id)
            const localVarPath = `/api/individuals/{id}/getLivestockIncomeCheckDetails`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoansSummaryByIndividualId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLoansSummaryByIndividualId', 'id', id)
            const localVarPath = `/api/individuals/{id}/loans-summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyIncome: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMonthlyIncome', 'id', id)
            const localVarPath = `/api/individuals/{id}/monthlyIncome`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOne', 'id', id)
            const localVarPath = `/api/individuals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtherInformationTab: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOtherInformationTab', 'id', id)
            const localVarPath = `/api/individuals/{id}/otherInformation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalaryIncomeCheckDetails: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSalaryIncomeCheckDetails', 'id', id)
            const localVarPath = `/api/individuals/{id}/getSalaryIncomeCheckDetails`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalaryIncomeData: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSalaryIncomeData', 'id', id)
            const localVarPath = `/api/individuals/{id}/getSalaryIncomeData`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IndividualContactDetailsUpdate} individualContactDetailsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactDetails: async (id: number, individualContactDetailsUpdate: IndividualContactDetailsUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContactDetails', 'id', id)
            // verify required parameter 'individualContactDetailsUpdate' is not null or undefined
            assertParamExists('updateContactDetails', 'individualContactDetailsUpdate', individualContactDetailsUpdate)
            const localVarPath = `/api/individuals/{id}/contactDetails`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(individualContactDetailsUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateIndividualGeneralInfoRequest} updateIndividualGeneralInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralInfo: async (id: number, updateIndividualGeneralInfoRequest: UpdateIndividualGeneralInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGeneralInfo', 'id', id)
            // verify required parameter 'updateIndividualGeneralInfoRequest' is not null or undefined
            assertParamExists('updateGeneralInfo', 'updateIndividualGeneralInfoRequest', updateIndividualGeneralInfoRequest)
            const localVarPath = `/api/individuals/{id}/generalInfo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIndividualGeneralInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateIndividualRequest} updateIndividualRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndividual: async (id: number, updateIndividualRequest: UpdateIndividualRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIndividual', 'id', id)
            // verify required parameter 'updateIndividualRequest' is not null or undefined
            assertParamExists('updateIndividual', 'updateIndividualRequest', updateIndividualRequest)
            const localVarPath = `/api/individuals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIndividualRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CustomerJobInformationRequest} customerJobInformationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobInformation: async (id: number, customerJobInformationRequest: CustomerJobInformationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateJobInformation', 'id', id)
            // verify required parameter 'customerJobInformationRequest' is not null or undefined
            assertParamExists('updateJobInformation', 'customerJobInformationRequest', customerJobInformationRequest)
            const localVarPath = `/api/individuals/{id}/jobInformation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerJobInformationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OtherInformationRequest} otherInformationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOtherInformation: async (id: number, otherInformationRequest: OtherInformationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOtherInformation', 'id', id)
            // verify required parameter 'otherInformationRequest' is not null or undefined
            assertParamExists('updateOtherInformation', 'otherInformationRequest', otherInformationRequest)
            const localVarPath = `/api/individuals/{id}/otherInformation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otherInformationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IndividualPaymentAccountUpdate} individualPaymentAccountUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentAccounts: async (id: number, individualPaymentAccountUpdate: IndividualPaymentAccountUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePaymentAccounts', 'id', id)
            // verify required parameter 'individualPaymentAccountUpdate' is not null or undefined
            assertParamExists('updatePaymentAccounts', 'individualPaymentAccountUpdate', individualPaymentAccountUpdate)
            const localVarPath = `/api/individuals/{id}/paymentAccount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(individualPaymentAccountUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateIndividualReferenceRequest} updateIndividualReferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferences: async (id: number, updateIndividualReferenceRequest: UpdateIndividualReferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReferences', 'id', id)
            // verify required parameter 'updateIndividualReferenceRequest' is not null or undefined
            assertParamExists('updateReferences', 'updateIndividualReferenceRequest', updateIndividualReferenceRequest)
            const localVarPath = `/api/individuals/{id}/reference`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIndividualReferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndividualControllerApi - functional programming interface
 * @export
 */
export const IndividualControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndividualControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLivestockIncomeType(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLivestockIncomeType(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSalaryIncomeType(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSalaryIncomeType(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateIndividualRequest} createIndividualRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIndividual(createIndividualRequest: CreateIndividualRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIndividual(createIndividualRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {string} [customerName] 
         * @param {string} [registrationNumber] 
         * @param {string} [phone] 
         * @param {number} [relationshipManagerId] 
         * @param {string} [branchCode] 
         * @param {Array<CustomerVerificationStatus>} [verificationStatuses] 
         * @param {string} [civilId] 
         * @param {Array<CustomerKycLevel>} [kycLevel] 
         * @param {Citizenship} [citizenship] 
         * @param {string} [birthDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllIndividuals(page?: number, size?: number, sort?: Array<string>, id?: number, customerName?: string, registrationNumber?: string, phone?: string, relationshipManagerId?: number, branchCode?: string, verificationStatuses?: Array<CustomerVerificationStatus>, civilId?: string, kycLevel?: Array<CustomerKycLevel>, citizenship?: Citizenship, birthDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageIndividualListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllIndividuals(page, size, sort, id, customerName, registrationNumber, phone, relationshipManagerId, branchCode, verificationStatuses, civilId, kycLevel, citizenship, birthDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of provinces with districts and khoroos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAddresses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProvinceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAddresses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProductOffer(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductOfferResponseEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProductOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationsSummaryByIndividualId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationsSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationsSummaryByIndividualId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableBankList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BankInformationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableBankList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessIncome(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndividualBusinessIncome>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessIncome(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessIncomes(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageBusinessIncomeListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessIncomes(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCibLoanData(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CibLoanListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCibLoanData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreditScoringResult(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditScoringResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreditScoringResult(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCsHistory(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreditScoringListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCsHistory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCsValue(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsValueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCsValue(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerInformationTab(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndividualInformationTabView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerInformationTab(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndividualIncomes(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndividualIncomes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndividualIncomes(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndividualVerificationDecisions(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerDecision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndividualVerificationDecisions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLivestockIncomeCheckDetails(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LivestockIncomeDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLivestockIncomeCheckDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoansSummaryByIndividualId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoansSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoansSummaryByIndividualId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyIncome(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlyIncomeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyIncome(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndividualBriefDetailsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOtherInformationTab(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OtherInformationTabView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOtherInformationTab(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalaryIncomeCheckDetails(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalaryIncomeDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalaryIncomeCheckDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalaryIncomeData(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SalaryIncomeListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalaryIncomeData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IndividualContactDetailsUpdate} individualContactDetailsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactDetails(id: number, individualContactDetailsUpdate: IndividualContactDetailsUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactDetails(id, individualContactDetailsUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateIndividualGeneralInfoRequest} updateIndividualGeneralInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGeneralInfo(id: number, updateIndividualGeneralInfoRequest: UpdateIndividualGeneralInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGeneralInfo(id, updateIndividualGeneralInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateIndividualRequest} updateIndividualRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIndividual(id: number, updateIndividualRequest: UpdateIndividualRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIndividual(id, updateIndividualRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CustomerJobInformationRequest} customerJobInformationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJobInformation(id: number, customerJobInformationRequest: CustomerJobInformationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJobInformation(id, customerJobInformationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OtherInformationRequest} otherInformationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOtherInformation(id: number, otherInformationRequest: OtherInformationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOtherInformation(id, otherInformationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IndividualPaymentAccountUpdate} individualPaymentAccountUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentAccounts(id: number, individualPaymentAccountUpdate: IndividualPaymentAccountUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentAccounts(id, individualPaymentAccountUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateIndividualReferenceRequest} updateIndividualReferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReferences(id: number, updateIndividualReferenceRequest: UpdateIndividualReferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReferences(id, updateIndividualReferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndividualControllerApi - factory interface
 * @export
 */
export const IndividualControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndividualControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {IndividualControllerApiAddLivestockIncomeTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLivestockIncomeType(requestParameters: IndividualControllerApiAddLivestockIncomeTypeRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.addLivestockIncomeType(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiAddSalaryIncomeTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSalaryIncomeType(requestParameters: IndividualControllerApiAddSalaryIncomeTypeRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.addSalaryIncomeType(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiCreateIndividualRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndividual(requestParameters: IndividualControllerApiCreateIndividualRequest, options?: AxiosRequestConfig): AxiosPromise<IdEntry> {
            return localVarFp.createIndividual(requestParameters.createIndividualRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiFindAllIndividualsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllIndividuals(requestParameters: IndividualControllerApiFindAllIndividualsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageIndividualListEntry> {
            return localVarFp.findAllIndividuals(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.customerName, requestParameters.registrationNumber, requestParameters.phone, requestParameters.relationshipManagerId, requestParameters.branchCode, requestParameters.verificationStatuses, requestParameters.civilId, requestParameters.kycLevel, requestParameters.citizenship, requestParameters.birthDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of provinces with districts and khoroos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAddresses(options?: AxiosRequestConfig): AxiosPromise<Array<ProvinceDto>> {
            return localVarFp.getAllAddresses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetAllProductOfferRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductOffer(requestParameters: IndividualControllerApiGetAllProductOfferRequest, options?: AxiosRequestConfig): AxiosPromise<ProductOfferResponseEntry> {
            return localVarFp.getAllProductOffer(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetApplicationsSummaryByIndividualIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationsSummaryByIndividualId(requestParameters: IndividualControllerApiGetApplicationsSummaryByIndividualIdRequest, options?: AxiosRequestConfig): AxiosPromise<ApplicationsSummary> {
            return localVarFp.getApplicationsSummaryByIndividualId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableBankList(options?: AxiosRequestConfig): AxiosPromise<Array<BankInformationDto>> {
            return localVarFp.getAvailableBankList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetBusinessIncomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessIncome(requestParameters: IndividualControllerApiGetBusinessIncomeRequest, options?: AxiosRequestConfig): AxiosPromise<IndividualBusinessIncome> {
            return localVarFp.getBusinessIncome(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetBusinessIncomesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessIncomes(requestParameters: IndividualControllerApiGetBusinessIncomesRequest, options?: AxiosRequestConfig): AxiosPromise<PageBusinessIncomeListEntry> {
            return localVarFp.getBusinessIncomes(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetCibLoanDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCibLoanData(requestParameters: IndividualControllerApiGetCibLoanDataRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CibLoanListEntry>> {
            return localVarFp.getCibLoanData(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetCreditScoringResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditScoringResult(requestParameters: IndividualControllerApiGetCreditScoringResultRequest, options?: AxiosRequestConfig): AxiosPromise<CreditScoringResult> {
            return localVarFp.getCreditScoringResult(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetCsHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsHistory(requestParameters: IndividualControllerApiGetCsHistoryRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CreditScoringListEntry>> {
            return localVarFp.getCsHistory(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetCsValueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsValue(requestParameters: IndividualControllerApiGetCsValueRequest, options?: AxiosRequestConfig): AxiosPromise<CsValueResponse> {
            return localVarFp.getCsValue(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetCustomerInformationTabRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerInformationTab(requestParameters: IndividualControllerApiGetCustomerInformationTabRequest, options?: AxiosRequestConfig): AxiosPromise<IndividualInformationTabView> {
            return localVarFp.getCustomerInformationTab(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetIndividualIncomesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndividualIncomes(requestParameters: IndividualControllerApiGetIndividualIncomesRequest, options?: AxiosRequestConfig): AxiosPromise<IndividualIncomes> {
            return localVarFp.getIndividualIncomes(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetIndividualVerificationDecisionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndividualVerificationDecisions(requestParameters: IndividualControllerApiGetIndividualVerificationDecisionsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CustomerDecision>> {
            return localVarFp.getIndividualVerificationDecisions(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetLivestockIncomeCheckDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLivestockIncomeCheckDetails(requestParameters: IndividualControllerApiGetLivestockIncomeCheckDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<LivestockIncomeDetailsResponse> {
            return localVarFp.getLivestockIncomeCheckDetails(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetLoansSummaryByIndividualIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoansSummaryByIndividualId(requestParameters: IndividualControllerApiGetLoansSummaryByIndividualIdRequest, options?: AxiosRequestConfig): AxiosPromise<LoansSummary> {
            return localVarFp.getLoansSummaryByIndividualId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetMonthlyIncomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyIncome(requestParameters: IndividualControllerApiGetMonthlyIncomeRequest, options?: AxiosRequestConfig): AxiosPromise<MonthlyIncomeResponse> {
            return localVarFp.getMonthlyIncome(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(requestParameters: IndividualControllerApiGetOneRequest, options?: AxiosRequestConfig): AxiosPromise<IndividualBriefDetailsView> {
            return localVarFp.getOne(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetOtherInformationTabRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtherInformationTab(requestParameters: IndividualControllerApiGetOtherInformationTabRequest, options?: AxiosRequestConfig): AxiosPromise<OtherInformationTabView> {
            return localVarFp.getOtherInformationTab(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetSalaryIncomeCheckDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalaryIncomeCheckDetails(requestParameters: IndividualControllerApiGetSalaryIncomeCheckDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<SalaryIncomeDetailsResponse> {
            return localVarFp.getSalaryIncomeCheckDetails(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiGetSalaryIncomeDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalaryIncomeData(requestParameters: IndividualControllerApiGetSalaryIncomeDataRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SalaryIncomeListEntry>> {
            return localVarFp.getSalaryIncomeData(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiUpdateContactDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactDetails(requestParameters: IndividualControllerApiUpdateContactDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateContactDetails(requestParameters.id, requestParameters.individualContactDetailsUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiUpdateGeneralInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralInfo(requestParameters: IndividualControllerApiUpdateGeneralInfoRequest, options?: AxiosRequestConfig): AxiosPromise<IdEntry> {
            return localVarFp.updateGeneralInfo(requestParameters.id, requestParameters.updateIndividualGeneralInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiUpdateIndividualRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndividual(requestParameters: IndividualControllerApiUpdateIndividualRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateIndividual(requestParameters.id, requestParameters.updateIndividualRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiUpdateJobInformationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobInformation(requestParameters: IndividualControllerApiUpdateJobInformationRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateJobInformation(requestParameters.id, requestParameters.customerJobInformationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiUpdateOtherInformationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOtherInformation(requestParameters: IndividualControllerApiUpdateOtherInformationRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateOtherInformation(requestParameters.id, requestParameters.otherInformationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiUpdatePaymentAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentAccounts(requestParameters: IndividualControllerApiUpdatePaymentAccountsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updatePaymentAccounts(requestParameters.id, requestParameters.individualPaymentAccountUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndividualControllerApiUpdateReferencesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferences(requestParameters: IndividualControllerApiUpdateReferencesRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateReferences(requestParameters.id, requestParameters.updateIndividualReferenceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addLivestockIncomeType operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiAddLivestockIncomeTypeRequest
 */
export interface IndividualControllerApiAddLivestockIncomeTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiAddLivestockIncomeType
     */
    readonly id: number
}

/**
 * Request parameters for addSalaryIncomeType operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiAddSalaryIncomeTypeRequest
 */
export interface IndividualControllerApiAddSalaryIncomeTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiAddSalaryIncomeType
     */
    readonly id: number
}

/**
 * Request parameters for createIndividual operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiCreateIndividualRequest
 */
export interface IndividualControllerApiCreateIndividualRequest {
    /**
     * 
     * @type {CreateIndividualRequest}
     * @memberof IndividualControllerApiCreateIndividual
     */
    readonly createIndividualRequest: CreateIndividualRequest
}

/**
 * Request parameters for findAllIndividuals operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiFindAllIndividualsRequest
 */
export interface IndividualControllerApiFindAllIndividualsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly customerName?: string

    /**
     * 
     * @type {string}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly registrationNumber?: string

    /**
     * 
     * @type {string}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly phone?: string

    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly relationshipManagerId?: number

    /**
     * 
     * @type {string}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly branchCode?: string

    /**
     * 
     * @type {Array<CustomerVerificationStatus>}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly verificationStatuses?: Array<CustomerVerificationStatus>

    /**
     * 
     * @type {string}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly civilId?: string

    /**
     * 
     * @type {Array<CustomerKycLevel>}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly kycLevel?: Array<CustomerKycLevel>

    /**
     * 
     * @type {Citizenship}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly citizenship?: Citizenship

    /**
     * 
     * @type {string}
     * @memberof IndividualControllerApiFindAllIndividuals
     */
    readonly birthDate?: string
}

/**
 * Request parameters for getAllProductOffer operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetAllProductOfferRequest
 */
export interface IndividualControllerApiGetAllProductOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetAllProductOffer
     */
    readonly id: number
}

/**
 * Request parameters for getApplicationsSummaryByIndividualId operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetApplicationsSummaryByIndividualIdRequest
 */
export interface IndividualControllerApiGetApplicationsSummaryByIndividualIdRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetApplicationsSummaryByIndividualId
     */
    readonly id: number
}

/**
 * Request parameters for getBusinessIncome operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetBusinessIncomeRequest
 */
export interface IndividualControllerApiGetBusinessIncomeRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetBusinessIncome
     */
    readonly id: number
}

/**
 * Request parameters for getBusinessIncomes operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetBusinessIncomesRequest
 */
export interface IndividualControllerApiGetBusinessIncomesRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetBusinessIncomes
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof IndividualControllerApiGetBusinessIncomes
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof IndividualControllerApiGetBusinessIncomes
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof IndividualControllerApiGetBusinessIncomes
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getCibLoanData operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetCibLoanDataRequest
 */
export interface IndividualControllerApiGetCibLoanDataRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetCibLoanData
     */
    readonly id: number
}

/**
 * Request parameters for getCreditScoringResult operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetCreditScoringResultRequest
 */
export interface IndividualControllerApiGetCreditScoringResultRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetCreditScoringResult
     */
    readonly id: number
}

/**
 * Request parameters for getCsHistory operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetCsHistoryRequest
 */
export interface IndividualControllerApiGetCsHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetCsHistory
     */
    readonly id: number
}

/**
 * Request parameters for getCsValue operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetCsValueRequest
 */
export interface IndividualControllerApiGetCsValueRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetCsValue
     */
    readonly id: number
}

/**
 * Request parameters for getCustomerInformationTab operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetCustomerInformationTabRequest
 */
export interface IndividualControllerApiGetCustomerInformationTabRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetCustomerInformationTab
     */
    readonly id: number
}

/**
 * Request parameters for getIndividualIncomes operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetIndividualIncomesRequest
 */
export interface IndividualControllerApiGetIndividualIncomesRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetIndividualIncomes
     */
    readonly id: number
}

/**
 * Request parameters for getIndividualVerificationDecisions operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetIndividualVerificationDecisionsRequest
 */
export interface IndividualControllerApiGetIndividualVerificationDecisionsRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetIndividualVerificationDecisions
     */
    readonly id: number
}

/**
 * Request parameters for getLivestockIncomeCheckDetails operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetLivestockIncomeCheckDetailsRequest
 */
export interface IndividualControllerApiGetLivestockIncomeCheckDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetLivestockIncomeCheckDetails
     */
    readonly id: number
}

/**
 * Request parameters for getLoansSummaryByIndividualId operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetLoansSummaryByIndividualIdRequest
 */
export interface IndividualControllerApiGetLoansSummaryByIndividualIdRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetLoansSummaryByIndividualId
     */
    readonly id: number
}

/**
 * Request parameters for getMonthlyIncome operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetMonthlyIncomeRequest
 */
export interface IndividualControllerApiGetMonthlyIncomeRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetMonthlyIncome
     */
    readonly id: number
}

/**
 * Request parameters for getOne operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetOneRequest
 */
export interface IndividualControllerApiGetOneRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetOne
     */
    readonly id: number
}

/**
 * Request parameters for getOtherInformationTab operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetOtherInformationTabRequest
 */
export interface IndividualControllerApiGetOtherInformationTabRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetOtherInformationTab
     */
    readonly id: number
}

/**
 * Request parameters for getSalaryIncomeCheckDetails operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetSalaryIncomeCheckDetailsRequest
 */
export interface IndividualControllerApiGetSalaryIncomeCheckDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetSalaryIncomeCheckDetails
     */
    readonly id: number
}

/**
 * Request parameters for getSalaryIncomeData operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiGetSalaryIncomeDataRequest
 */
export interface IndividualControllerApiGetSalaryIncomeDataRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiGetSalaryIncomeData
     */
    readonly id: number
}

/**
 * Request parameters for updateContactDetails operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiUpdateContactDetailsRequest
 */
export interface IndividualControllerApiUpdateContactDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiUpdateContactDetails
     */
    readonly id: number

    /**
     * 
     * @type {IndividualContactDetailsUpdate}
     * @memberof IndividualControllerApiUpdateContactDetails
     */
    readonly individualContactDetailsUpdate: IndividualContactDetailsUpdate
}

/**
 * Request parameters for updateGeneralInfo operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiUpdateGeneralInfoRequest
 */
export interface IndividualControllerApiUpdateGeneralInfoRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiUpdateGeneralInfo
     */
    readonly id: number

    /**
     * 
     * @type {UpdateIndividualGeneralInfoRequest}
     * @memberof IndividualControllerApiUpdateGeneralInfo
     */
    readonly updateIndividualGeneralInfoRequest: UpdateIndividualGeneralInfoRequest
}

/**
 * Request parameters for updateIndividual operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiUpdateIndividualRequest
 */
export interface IndividualControllerApiUpdateIndividualRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiUpdateIndividual
     */
    readonly id: number

    /**
     * 
     * @type {UpdateIndividualRequest}
     * @memberof IndividualControllerApiUpdateIndividual
     */
    readonly updateIndividualRequest: UpdateIndividualRequest
}

/**
 * Request parameters for updateJobInformation operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiUpdateJobInformationRequest
 */
export interface IndividualControllerApiUpdateJobInformationRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiUpdateJobInformation
     */
    readonly id: number

    /**
     * 
     * @type {CustomerJobInformationRequest}
     * @memberof IndividualControllerApiUpdateJobInformation
     */
    readonly customerJobInformationRequest: CustomerJobInformationRequest
}

/**
 * Request parameters for updateOtherInformation operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiUpdateOtherInformationRequest
 */
export interface IndividualControllerApiUpdateOtherInformationRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiUpdateOtherInformation
     */
    readonly id: number

    /**
     * 
     * @type {OtherInformationRequest}
     * @memberof IndividualControllerApiUpdateOtherInformation
     */
    readonly otherInformationRequest: OtherInformationRequest
}

/**
 * Request parameters for updatePaymentAccounts operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiUpdatePaymentAccountsRequest
 */
export interface IndividualControllerApiUpdatePaymentAccountsRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiUpdatePaymentAccounts
     */
    readonly id: number

    /**
     * 
     * @type {IndividualPaymentAccountUpdate}
     * @memberof IndividualControllerApiUpdatePaymentAccounts
     */
    readonly individualPaymentAccountUpdate: IndividualPaymentAccountUpdate
}

/**
 * Request parameters for updateReferences operation in IndividualControllerApi.
 * @export
 * @interface IndividualControllerApiUpdateReferencesRequest
 */
export interface IndividualControllerApiUpdateReferencesRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualControllerApiUpdateReferences
     */
    readonly id: number

    /**
     * 
     * @type {UpdateIndividualReferenceRequest}
     * @memberof IndividualControllerApiUpdateReferences
     */
    readonly updateIndividualReferenceRequest: UpdateIndividualReferenceRequest
}

/**
 * IndividualControllerApi - object-oriented interface
 * @export
 * @class IndividualControllerApi
 * @extends {BaseAPI}
 */
export class IndividualControllerApi extends BaseAPI {
    /**
     * 
     * @param {IndividualControllerApiAddLivestockIncomeTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public addLivestockIncomeType(requestParameters: IndividualControllerApiAddLivestockIncomeTypeRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).addLivestockIncomeType(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiAddSalaryIncomeTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public addSalaryIncomeType(requestParameters: IndividualControllerApiAddSalaryIncomeTypeRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).addSalaryIncomeType(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiCreateIndividualRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public createIndividual(requestParameters: IndividualControllerApiCreateIndividualRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).createIndividual(requestParameters.createIndividualRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiFindAllIndividualsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public findAllIndividuals(requestParameters: IndividualControllerApiFindAllIndividualsRequest = {}, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).findAllIndividuals(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.customerName, requestParameters.registrationNumber, requestParameters.phone, requestParameters.relationshipManagerId, requestParameters.branchCode, requestParameters.verificationStatuses, requestParameters.civilId, requestParameters.kycLevel, requestParameters.citizenship, requestParameters.birthDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of provinces with districts and khoroos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getAllAddresses(options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getAllAddresses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetAllProductOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getAllProductOffer(requestParameters: IndividualControllerApiGetAllProductOfferRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getAllProductOffer(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetApplicationsSummaryByIndividualIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getApplicationsSummaryByIndividualId(requestParameters: IndividualControllerApiGetApplicationsSummaryByIndividualIdRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getApplicationsSummaryByIndividualId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getAvailableBankList(options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getAvailableBankList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetBusinessIncomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getBusinessIncome(requestParameters: IndividualControllerApiGetBusinessIncomeRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getBusinessIncome(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetBusinessIncomesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getBusinessIncomes(requestParameters: IndividualControllerApiGetBusinessIncomesRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getBusinessIncomes(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetCibLoanDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getCibLoanData(requestParameters: IndividualControllerApiGetCibLoanDataRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getCibLoanData(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetCreditScoringResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getCreditScoringResult(requestParameters: IndividualControllerApiGetCreditScoringResultRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getCreditScoringResult(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetCsHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getCsHistory(requestParameters: IndividualControllerApiGetCsHistoryRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getCsHistory(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetCsValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getCsValue(requestParameters: IndividualControllerApiGetCsValueRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getCsValue(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetCustomerInformationTabRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getCustomerInformationTab(requestParameters: IndividualControllerApiGetCustomerInformationTabRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getCustomerInformationTab(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetIndividualIncomesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getIndividualIncomes(requestParameters: IndividualControllerApiGetIndividualIncomesRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getIndividualIncomes(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetIndividualVerificationDecisionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getIndividualVerificationDecisions(requestParameters: IndividualControllerApiGetIndividualVerificationDecisionsRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getIndividualVerificationDecisions(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetLivestockIncomeCheckDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getLivestockIncomeCheckDetails(requestParameters: IndividualControllerApiGetLivestockIncomeCheckDetailsRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getLivestockIncomeCheckDetails(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetLoansSummaryByIndividualIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getLoansSummaryByIndividualId(requestParameters: IndividualControllerApiGetLoansSummaryByIndividualIdRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getLoansSummaryByIndividualId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetMonthlyIncomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getMonthlyIncome(requestParameters: IndividualControllerApiGetMonthlyIncomeRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getMonthlyIncome(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getOne(requestParameters: IndividualControllerApiGetOneRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetOtherInformationTabRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getOtherInformationTab(requestParameters: IndividualControllerApiGetOtherInformationTabRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getOtherInformationTab(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetSalaryIncomeCheckDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getSalaryIncomeCheckDetails(requestParameters: IndividualControllerApiGetSalaryIncomeCheckDetailsRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getSalaryIncomeCheckDetails(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiGetSalaryIncomeDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public getSalaryIncomeData(requestParameters: IndividualControllerApiGetSalaryIncomeDataRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).getSalaryIncomeData(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiUpdateContactDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public updateContactDetails(requestParameters: IndividualControllerApiUpdateContactDetailsRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).updateContactDetails(requestParameters.id, requestParameters.individualContactDetailsUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiUpdateGeneralInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public updateGeneralInfo(requestParameters: IndividualControllerApiUpdateGeneralInfoRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).updateGeneralInfo(requestParameters.id, requestParameters.updateIndividualGeneralInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiUpdateIndividualRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public updateIndividual(requestParameters: IndividualControllerApiUpdateIndividualRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).updateIndividual(requestParameters.id, requestParameters.updateIndividualRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiUpdateJobInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public updateJobInformation(requestParameters: IndividualControllerApiUpdateJobInformationRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).updateJobInformation(requestParameters.id, requestParameters.customerJobInformationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiUpdateOtherInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public updateOtherInformation(requestParameters: IndividualControllerApiUpdateOtherInformationRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).updateOtherInformation(requestParameters.id, requestParameters.otherInformationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiUpdatePaymentAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public updatePaymentAccounts(requestParameters: IndividualControllerApiUpdatePaymentAccountsRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).updatePaymentAccounts(requestParameters.id, requestParameters.individualPaymentAccountUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndividualControllerApiUpdateReferencesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualControllerApi
     */
    public updateReferences(requestParameters: IndividualControllerApiUpdateReferencesRequest, options?: AxiosRequestConfig) {
        return IndividualControllerApiFp(this.configuration).updateReferences(requestParameters.id, requestParameters.updateIndividualReferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InsuranceControllerApi - axios parameter creator
 * @export
 */
export const InsuranceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} collateralId 
         * @param {NewInsurance} newInsurance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsurance: async (collateralId: number, newInsurance: NewInsurance, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collateralId' is not null or undefined
            assertParamExists('createInsurance', 'collateralId', collateralId)
            // verify required parameter 'newInsurance' is not null or undefined
            assertParamExists('createInsurance', 'newInsurance', newInsurance)
            const localVarPath = `/api/collaterals/{collateralId}/insurances`
                .replace(`{${"collateralId"}}`, encodeURIComponent(String(collateralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newInsurance, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInsurance: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInsurance', 'id', id)
            const localVarPath = `/api/insurances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuranceCompanyOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/insurance-companies/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuranceProductOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/insurance-products/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InsuranceUpdate} insuranceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsurance: async (id: number, insuranceUpdate: InsuranceUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInsurance', 'id', id)
            // verify required parameter 'insuranceUpdate' is not null or undefined
            assertParamExists('updateInsurance', 'insuranceUpdate', insuranceUpdate)
            const localVarPath = `/api/insurances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insuranceUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceControllerApi - functional programming interface
 * @export
 */
export const InsuranceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} collateralId 
         * @param {NewInsurance} newInsurance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInsurance(collateralId: number, newInsurance: NewInsurance, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Insurance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInsurance(collateralId, newInsurance, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInsurance(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInsurance(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsuranceCompanyOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdNameEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsuranceCompanyOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsuranceProductOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdNameEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsuranceProductOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InsuranceUpdate} insuranceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInsurance(id: number, insuranceUpdate: InsuranceUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInsurance(id, insuranceUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsuranceControllerApi - factory interface
 * @export
 */
export const InsuranceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {InsuranceControllerApiCreateInsuranceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsurance(requestParameters: InsuranceControllerApiCreateInsuranceRequest, options?: AxiosRequestConfig): AxiosPromise<Insurance> {
            return localVarFp.createInsurance(requestParameters.collateralId, requestParameters.newInsurance, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsuranceControllerApiDeleteInsuranceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInsurance(requestParameters: InsuranceControllerApiDeleteInsuranceRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteInsurance(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuranceCompanyOptions(options?: AxiosRequestConfig): AxiosPromise<Array<IdNameEntry>> {
            return localVarFp.getInsuranceCompanyOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuranceProductOptions(options?: AxiosRequestConfig): AxiosPromise<Array<IdNameEntry>> {
            return localVarFp.getInsuranceProductOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsuranceControllerApiUpdateInsuranceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsurance(requestParameters: InsuranceControllerApiUpdateInsuranceRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateInsurance(requestParameters.id, requestParameters.insuranceUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInsurance operation in InsuranceControllerApi.
 * @export
 * @interface InsuranceControllerApiCreateInsuranceRequest
 */
export interface InsuranceControllerApiCreateInsuranceRequest {
    /**
     * 
     * @type {number}
     * @memberof InsuranceControllerApiCreateInsurance
     */
    readonly collateralId: number

    /**
     * 
     * @type {NewInsurance}
     * @memberof InsuranceControllerApiCreateInsurance
     */
    readonly newInsurance: NewInsurance
}

/**
 * Request parameters for deleteInsurance operation in InsuranceControllerApi.
 * @export
 * @interface InsuranceControllerApiDeleteInsuranceRequest
 */
export interface InsuranceControllerApiDeleteInsuranceRequest {
    /**
     * 
     * @type {number}
     * @memberof InsuranceControllerApiDeleteInsurance
     */
    readonly id: number
}

/**
 * Request parameters for updateInsurance operation in InsuranceControllerApi.
 * @export
 * @interface InsuranceControllerApiUpdateInsuranceRequest
 */
export interface InsuranceControllerApiUpdateInsuranceRequest {
    /**
     * 
     * @type {number}
     * @memberof InsuranceControllerApiUpdateInsurance
     */
    readonly id: number

    /**
     * 
     * @type {InsuranceUpdate}
     * @memberof InsuranceControllerApiUpdateInsurance
     */
    readonly insuranceUpdate: InsuranceUpdate
}

/**
 * InsuranceControllerApi - object-oriented interface
 * @export
 * @class InsuranceControllerApi
 * @extends {BaseAPI}
 */
export class InsuranceControllerApi extends BaseAPI {
    /**
     * 
     * @param {InsuranceControllerApiCreateInsuranceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceControllerApi
     */
    public createInsurance(requestParameters: InsuranceControllerApiCreateInsuranceRequest, options?: AxiosRequestConfig) {
        return InsuranceControllerApiFp(this.configuration).createInsurance(requestParameters.collateralId, requestParameters.newInsurance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsuranceControllerApiDeleteInsuranceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceControllerApi
     */
    public deleteInsurance(requestParameters: InsuranceControllerApiDeleteInsuranceRequest, options?: AxiosRequestConfig) {
        return InsuranceControllerApiFp(this.configuration).deleteInsurance(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceControllerApi
     */
    public getInsuranceCompanyOptions(options?: AxiosRequestConfig) {
        return InsuranceControllerApiFp(this.configuration).getInsuranceCompanyOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceControllerApi
     */
    public getInsuranceProductOptions(options?: AxiosRequestConfig) {
        return InsuranceControllerApiFp(this.configuration).getInsuranceProductOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsuranceControllerApiUpdateInsuranceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceControllerApi
     */
    public updateInsurance(requestParameters: InsuranceControllerApiUpdateInsuranceRequest, options?: AxiosRequestConfig) {
        return InsuranceControllerApiFp(this.configuration).updateInsurance(requestParameters.id, requestParameters.insuranceUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LegalControllerApi - axios parameter creator
 * @export
 */
export const LegalControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {NewLegal} newLegal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegal: async (newLegal: NewLegal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newLegal' is not null or undefined
            assertParamExists('createLegal', 'newLegal', newLegal)
            const localVarPath = `/api/legals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newLegal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CreateLegalRepresentativeRequest} createLegalRepresentativeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalRepresentative: async (id: number, createLegalRepresentativeRequest: CreateLegalRepresentativeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createLegalRepresentative', 'id', id)
            // verify required parameter 'createLegalRepresentativeRequest' is not null or undefined
            assertParamExists('createLegalRepresentative', 'createLegalRepresentativeRequest', createLegalRepresentativeRequest)
            const localVarPath = `/api/legals/{id}/representative`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLegalRepresentativeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} representativeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLegalRepresentative: async (id: number, representativeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLegalRepresentative', 'id', id)
            // verify required parameter 'representativeId' is not null or undefined
            assertParamExists('deleteLegalRepresentative', 'representativeId', representativeId)
            const localVarPath = `/api/legals/{id}/representative/{representativeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"representativeId"}}`, encodeURIComponent(String(representativeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {string} [companyName] 
         * @param {Array<number>} [industryIds] 
         * @param {string} [registrationNumber] 
         * @param {string} [ceoName] 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLegals: async (page?: number, size?: number, sort?: Array<string>, id?: number, companyName?: string, industryIds?: Array<number>, registrationNumber?: string, ceoName?: string, phoneNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (industryIds) {
                localVarQueryParameter['industryIds'] = industryIds;
            }

            if (registrationNumber !== undefined) {
                localVarQueryParameter['registrationNumber'] = registrationNumber;
            }

            if (ceoName !== undefined) {
                localVarQueryParameter['ceoName'] = ceoName;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLegalLoans: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findLegalLoans', 'id', id)
            const localVarPath = `/api/legals/{id}/loans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegal: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLegal', 'id', id)
            const localVarPath = `/api/legals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalLoanSummary: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLegalLoanSummary', 'id', id)
            const localVarPath = `/api/legals/{id}/loans-summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalOtherInformation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLegalOtherInformation', 'id', id)
            const localVarPath = `/api/legals/{id}/other-information`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchLegalRequest} patchLegalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLegal: async (id: number, patchLegalRequest: PatchLegalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchLegal', 'id', id)
            // verify required parameter 'patchLegalRequest' is not null or undefined
            assertParamExists('patchLegal', 'patchLegalRequest', patchLegalRequest)
            const localVarPath = `/api/legals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchLegalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} representativeId 
         * @param {UpdateLegalRepresentativeRequest} updateLegalRepresentativeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalRepresentative: async (id: number, representativeId: number, updateLegalRepresentativeRequest: UpdateLegalRepresentativeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLegalRepresentative', 'id', id)
            // verify required parameter 'representativeId' is not null or undefined
            assertParamExists('updateLegalRepresentative', 'representativeId', representativeId)
            // verify required parameter 'updateLegalRepresentativeRequest' is not null or undefined
            assertParamExists('updateLegalRepresentative', 'updateLegalRepresentativeRequest', updateLegalRepresentativeRequest)
            const localVarPath = `/api/legals/{id}/representative/{representativeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"representativeId"}}`, encodeURIComponent(String(representativeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLegalRepresentativeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LegalControllerApi - functional programming interface
 * @export
 */
export const LegalControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LegalControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {NewLegal} newLegal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLegal(newLegal: NewLegal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLegal(newLegal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CreateLegalRepresentativeRequest} createLegalRepresentativeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLegalRepresentative(id: number, createLegalRepresentativeRequest: CreateLegalRepresentativeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLegalRepresentative(id, createLegalRepresentativeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} representativeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLegalRepresentative(id: number, representativeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLegalRepresentative(id, representativeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {string} [companyName] 
         * @param {Array<number>} [industryIds] 
         * @param {string} [registrationNumber] 
         * @param {string} [ceoName] 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllLegals(page?: number, size?: number, sort?: Array<string>, id?: number, companyName?: string, industryIds?: Array<number>, registrationNumber?: string, ceoName?: string, phoneNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLegalListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllLegals(page, size, sort, id, companyName, industryIds, registrationNumber, ceoName, phoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLegalLoans(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLegalLoanListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLegalLoans(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegal(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Legal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalLoanSummary(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoansSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalLoanSummary(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalOtherInformation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalOtherInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalOtherInformation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchLegalRequest} patchLegalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchLegal(id: number, patchLegalRequest: PatchLegalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchLegal(id, patchLegalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} representativeId 
         * @param {UpdateLegalRepresentativeRequest} updateLegalRepresentativeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLegalRepresentative(id: number, representativeId: number, updateLegalRepresentativeRequest: UpdateLegalRepresentativeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLegalRepresentative(id, representativeId, updateLegalRepresentativeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LegalControllerApi - factory interface
 * @export
 */
export const LegalControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LegalControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {LegalControllerApiCreateLegalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegal(requestParameters: LegalControllerApiCreateLegalRequest, options?: AxiosRequestConfig): AxiosPromise<IdEntry> {
            return localVarFp.createLegal(requestParameters.newLegal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegalControllerApiCreateLegalRepresentativeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalRepresentative(requestParameters: LegalControllerApiCreateLegalRepresentativeRequest, options?: AxiosRequestConfig): AxiosPromise<IdEntry> {
            return localVarFp.createLegalRepresentative(requestParameters.id, requestParameters.createLegalRepresentativeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegalControllerApiDeleteLegalRepresentativeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLegalRepresentative(requestParameters: LegalControllerApiDeleteLegalRepresentativeRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteLegalRepresentative(requestParameters.id, requestParameters.representativeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegalControllerApiFindAllLegalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLegals(requestParameters: LegalControllerApiFindAllLegalsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageLegalListEntry> {
            return localVarFp.findAllLegals(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.companyName, requestParameters.industryIds, requestParameters.registrationNumber, requestParameters.ceoName, requestParameters.phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegalControllerApiFindLegalLoansRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLegalLoans(requestParameters: LegalControllerApiFindLegalLoansRequest, options?: AxiosRequestConfig): AxiosPromise<PageLegalLoanListEntry> {
            return localVarFp.findLegalLoans(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegalControllerApiGetLegalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegal(requestParameters: LegalControllerApiGetLegalRequest, options?: AxiosRequestConfig): AxiosPromise<Legal> {
            return localVarFp.getLegal(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegalControllerApiGetLegalLoanSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalLoanSummary(requestParameters: LegalControllerApiGetLegalLoanSummaryRequest, options?: AxiosRequestConfig): AxiosPromise<LoansSummary> {
            return localVarFp.getLegalLoanSummary(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegalControllerApiGetLegalOtherInformationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalOtherInformation(requestParameters: LegalControllerApiGetLegalOtherInformationRequest, options?: AxiosRequestConfig): AxiosPromise<LegalOtherInformation> {
            return localVarFp.getLegalOtherInformation(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegalControllerApiPatchLegalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLegal(requestParameters: LegalControllerApiPatchLegalRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchLegal(requestParameters.id, requestParameters.patchLegalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegalControllerApiUpdateLegalRepresentativeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalRepresentative(requestParameters: LegalControllerApiUpdateLegalRepresentativeRequest, options?: AxiosRequestConfig): AxiosPromise<IdEntry> {
            return localVarFp.updateLegalRepresentative(requestParameters.id, requestParameters.representativeId, requestParameters.updateLegalRepresentativeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createLegal operation in LegalControllerApi.
 * @export
 * @interface LegalControllerApiCreateLegalRequest
 */
export interface LegalControllerApiCreateLegalRequest {
    /**
     * 
     * @type {NewLegal}
     * @memberof LegalControllerApiCreateLegal
     */
    readonly newLegal: NewLegal
}

/**
 * Request parameters for createLegalRepresentative operation in LegalControllerApi.
 * @export
 * @interface LegalControllerApiCreateLegalRepresentativeRequest
 */
export interface LegalControllerApiCreateLegalRepresentativeRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalControllerApiCreateLegalRepresentative
     */
    readonly id: number

    /**
     * 
     * @type {CreateLegalRepresentativeRequest}
     * @memberof LegalControllerApiCreateLegalRepresentative
     */
    readonly createLegalRepresentativeRequest: CreateLegalRepresentativeRequest
}

/**
 * Request parameters for deleteLegalRepresentative operation in LegalControllerApi.
 * @export
 * @interface LegalControllerApiDeleteLegalRepresentativeRequest
 */
export interface LegalControllerApiDeleteLegalRepresentativeRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalControllerApiDeleteLegalRepresentative
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof LegalControllerApiDeleteLegalRepresentative
     */
    readonly representativeId: number
}

/**
 * Request parameters for findAllLegals operation in LegalControllerApi.
 * @export
 * @interface LegalControllerApiFindAllLegalsRequest
 */
export interface LegalControllerApiFindAllLegalsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LegalControllerApiFindAllLegals
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LegalControllerApiFindAllLegals
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LegalControllerApiFindAllLegals
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof LegalControllerApiFindAllLegals
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof LegalControllerApiFindAllLegals
     */
    readonly companyName?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof LegalControllerApiFindAllLegals
     */
    readonly industryIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof LegalControllerApiFindAllLegals
     */
    readonly registrationNumber?: string

    /**
     * 
     * @type {string}
     * @memberof LegalControllerApiFindAllLegals
     */
    readonly ceoName?: string

    /**
     * 
     * @type {string}
     * @memberof LegalControllerApiFindAllLegals
     */
    readonly phoneNumber?: string
}

/**
 * Request parameters for findLegalLoans operation in LegalControllerApi.
 * @export
 * @interface LegalControllerApiFindLegalLoansRequest
 */
export interface LegalControllerApiFindLegalLoansRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalControllerApiFindLegalLoans
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LegalControllerApiFindLegalLoans
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LegalControllerApiFindLegalLoans
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LegalControllerApiFindLegalLoans
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getLegal operation in LegalControllerApi.
 * @export
 * @interface LegalControllerApiGetLegalRequest
 */
export interface LegalControllerApiGetLegalRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalControllerApiGetLegal
     */
    readonly id: number
}

/**
 * Request parameters for getLegalLoanSummary operation in LegalControllerApi.
 * @export
 * @interface LegalControllerApiGetLegalLoanSummaryRequest
 */
export interface LegalControllerApiGetLegalLoanSummaryRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalControllerApiGetLegalLoanSummary
     */
    readonly id: number
}

/**
 * Request parameters for getLegalOtherInformation operation in LegalControllerApi.
 * @export
 * @interface LegalControllerApiGetLegalOtherInformationRequest
 */
export interface LegalControllerApiGetLegalOtherInformationRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalControllerApiGetLegalOtherInformation
     */
    readonly id: number
}

/**
 * Request parameters for patchLegal operation in LegalControllerApi.
 * @export
 * @interface LegalControllerApiPatchLegalRequest
 */
export interface LegalControllerApiPatchLegalRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalControllerApiPatchLegal
     */
    readonly id: number

    /**
     * 
     * @type {PatchLegalRequest}
     * @memberof LegalControllerApiPatchLegal
     */
    readonly patchLegalRequest: PatchLegalRequest
}

/**
 * Request parameters for updateLegalRepresentative operation in LegalControllerApi.
 * @export
 * @interface LegalControllerApiUpdateLegalRepresentativeRequest
 */
export interface LegalControllerApiUpdateLegalRepresentativeRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalControllerApiUpdateLegalRepresentative
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof LegalControllerApiUpdateLegalRepresentative
     */
    readonly representativeId: number

    /**
     * 
     * @type {UpdateLegalRepresentativeRequest}
     * @memberof LegalControllerApiUpdateLegalRepresentative
     */
    readonly updateLegalRepresentativeRequest: UpdateLegalRepresentativeRequest
}

/**
 * LegalControllerApi - object-oriented interface
 * @export
 * @class LegalControllerApi
 * @extends {BaseAPI}
 */
export class LegalControllerApi extends BaseAPI {
    /**
     * 
     * @param {LegalControllerApiCreateLegalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalControllerApi
     */
    public createLegal(requestParameters: LegalControllerApiCreateLegalRequest, options?: AxiosRequestConfig) {
        return LegalControllerApiFp(this.configuration).createLegal(requestParameters.newLegal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegalControllerApiCreateLegalRepresentativeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalControllerApi
     */
    public createLegalRepresentative(requestParameters: LegalControllerApiCreateLegalRepresentativeRequest, options?: AxiosRequestConfig) {
        return LegalControllerApiFp(this.configuration).createLegalRepresentative(requestParameters.id, requestParameters.createLegalRepresentativeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegalControllerApiDeleteLegalRepresentativeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalControllerApi
     */
    public deleteLegalRepresentative(requestParameters: LegalControllerApiDeleteLegalRepresentativeRequest, options?: AxiosRequestConfig) {
        return LegalControllerApiFp(this.configuration).deleteLegalRepresentative(requestParameters.id, requestParameters.representativeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegalControllerApiFindAllLegalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalControllerApi
     */
    public findAllLegals(requestParameters: LegalControllerApiFindAllLegalsRequest = {}, options?: AxiosRequestConfig) {
        return LegalControllerApiFp(this.configuration).findAllLegals(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.companyName, requestParameters.industryIds, requestParameters.registrationNumber, requestParameters.ceoName, requestParameters.phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegalControllerApiFindLegalLoansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalControllerApi
     */
    public findLegalLoans(requestParameters: LegalControllerApiFindLegalLoansRequest, options?: AxiosRequestConfig) {
        return LegalControllerApiFp(this.configuration).findLegalLoans(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegalControllerApiGetLegalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalControllerApi
     */
    public getLegal(requestParameters: LegalControllerApiGetLegalRequest, options?: AxiosRequestConfig) {
        return LegalControllerApiFp(this.configuration).getLegal(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegalControllerApiGetLegalLoanSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalControllerApi
     */
    public getLegalLoanSummary(requestParameters: LegalControllerApiGetLegalLoanSummaryRequest, options?: AxiosRequestConfig) {
        return LegalControllerApiFp(this.configuration).getLegalLoanSummary(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegalControllerApiGetLegalOtherInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalControllerApi
     */
    public getLegalOtherInformation(requestParameters: LegalControllerApiGetLegalOtherInformationRequest, options?: AxiosRequestConfig) {
        return LegalControllerApiFp(this.configuration).getLegalOtherInformation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegalControllerApiPatchLegalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalControllerApi
     */
    public patchLegal(requestParameters: LegalControllerApiPatchLegalRequest, options?: AxiosRequestConfig) {
        return LegalControllerApiFp(this.configuration).patchLegal(requestParameters.id, requestParameters.patchLegalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegalControllerApiUpdateLegalRepresentativeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalControllerApi
     */
    public updateLegalRepresentative(requestParameters: LegalControllerApiUpdateLegalRepresentativeRequest, options?: AxiosRequestConfig) {
        return LegalControllerApiFp(this.configuration).updateLegalRepresentative(requestParameters.id, requestParameters.representativeId, requestParameters.updateLegalRepresentativeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoanApplicationControllerApi - axios parameter creator
 * @export
 */
export const LoanApplicationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} applicationId 
         * @param {number} collateralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationCollateralEvaluation: async (applicationId: number, collateralId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('createApplicationCollateralEvaluation', 'applicationId', applicationId)
            // verify required parameter 'collateralId' is not null or undefined
            assertParamExists('createApplicationCollateralEvaluation', 'collateralId', collateralId)
            const localVarPath = `/api/loan-applications/{applicationId}/collaterals/{collateralId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"collateralId"}}`, encodeURIComponent(String(collateralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {number} collateralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationCollateralEvaluation: async (applicationId: number, collateralId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('deleteApplicationCollateralEvaluation', 'applicationId', applicationId)
            // verify required parameter 'collateralId' is not null or undefined
            assertParamExists('deleteApplicationCollateralEvaluation', 'collateralId', collateralId)
            const localVarPath = `/api/loan-applications/{applicationId}/collaterals/{collateralId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"collateralId"}}`, encodeURIComponent(String(collateralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [relationshipManagerId] 
         * @param {string} [branchCode] 
         * @param {number} [customerId] 
         * @param {Array<LoanApplicationStatus>} [applicationStatuses] 
         * @param {Array<ProductType>} [productTypes] 
         * @param {string} [customerName] 
         * @param {string} [registrationNumber] 
         * @param {string} [createdDateFrom] 
         * @param {string} [createdDateTo] 
         * @param {string} [externalId] 
         * @param {string} [phone] 
         * @param {string} [civilId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLoanApplications: async (page?: number, size?: number, sort?: Array<string>, id?: number, relationshipManagerId?: number, branchCode?: string, customerId?: number, applicationStatuses?: Array<LoanApplicationStatus>, productTypes?: Array<ProductType>, customerName?: string, registrationNumber?: string, createdDateFrom?: string, createdDateTo?: string, externalId?: string, phone?: string, civilId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (relationshipManagerId !== undefined) {
                localVarQueryParameter['relationshipManagerId'] = relationshipManagerId;
            }

            if (branchCode !== undefined) {
                localVarQueryParameter['branchCode'] = branchCode;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (applicationStatuses) {
                localVarQueryParameter['applicationStatuses'] = applicationStatuses;
            }

            if (productTypes) {
                localVarQueryParameter['productTypes'] = productTypes;
            }

            if (customerName !== undefined) {
                localVarQueryParameter['customerName'] = customerName;
            }

            if (registrationNumber !== undefined) {
                localVarQueryParameter['registrationNumber'] = registrationNumber;
            }

            if (createdDateFrom !== undefined) {
                localVarQueryParameter['createdDateFrom'] = (createdDateFrom as any instanceof Date) ?
                    (createdDateFrom as any).toISOString().substring(0,10) :
                    createdDateFrom;
            }

            if (createdDateTo !== undefined) {
                localVarQueryParameter['createdDateTo'] = (createdDateTo as any instanceof Date) ?
                    (createdDateTo as any).toISOString().substring(0,10) :
                    createdDateTo;
            }

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (civilId !== undefined) {
                localVarQueryParameter['civilId'] = civilId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollaterals: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCollaterals', 'id', id)
            const localVarPath = `/api/loan-applications/{id}/collaterals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanApplication: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLoanApplication', 'id', id)
            const localVarPath = `/api/loan-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentSchedule1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPaymentSchedule1', 'id', id)
            const localVarPath = `/api/loan-applications/{id}/getPaymentSchedule`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateLoanApplicationRequest} updateLoanApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanApplication: async (id: number, updateLoanApplicationRequest: UpdateLoanApplicationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLoanApplication', 'id', id)
            // verify required parameter 'updateLoanApplicationRequest' is not null or undefined
            assertParamExists('updateLoanApplication', 'updateLoanApplicationRequest', updateLoanApplicationRequest)
            const localVarPath = `/api/loan-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLoanApplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanApplicationControllerApi - functional programming interface
 * @export
 */
export const LoanApplicationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoanApplicationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} applicationId 
         * @param {number} collateralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplicationCollateralEvaluation(applicationId: number, collateralId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApplicationCollateralEvaluation(applicationId, collateralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {number} collateralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationCollateralEvaluation(applicationId: number, collateralId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicationCollateralEvaluation(applicationId, collateralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [relationshipManagerId] 
         * @param {string} [branchCode] 
         * @param {number} [customerId] 
         * @param {Array<LoanApplicationStatus>} [applicationStatuses] 
         * @param {Array<ProductType>} [productTypes] 
         * @param {string} [customerName] 
         * @param {string} [registrationNumber] 
         * @param {string} [createdDateFrom] 
         * @param {string} [createdDateTo] 
         * @param {string} [externalId] 
         * @param {string} [phone] 
         * @param {string} [civilId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllLoanApplications(page?: number, size?: number, sort?: Array<string>, id?: number, relationshipManagerId?: number, branchCode?: string, customerId?: number, applicationStatuses?: Array<LoanApplicationStatus>, productTypes?: Array<ProductType>, customerName?: string, registrationNumber?: string, createdDateFrom?: string, createdDateTo?: string, externalId?: string, phone?: string, civilId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllLoanApplications(page, size, sort, id, relationshipManagerId, branchCode, customerId, applicationStatuses, productTypes, customerName, registrationNumber, createdDateFrom, createdDateTo, externalId, phone, civilId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollaterals(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageApplicationCollateralListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollaterals(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanApplication(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLoanApplication200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentSchedule1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationPaymentScheduleEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentSchedule1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateLoanApplicationRequest} updateLoanApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLoanApplication(id: number, updateLoanApplicationRequest: UpdateLoanApplicationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLoanApplication(id, updateLoanApplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoanApplicationControllerApi - factory interface
 * @export
 */
export const LoanApplicationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoanApplicationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {LoanApplicationControllerApiCreateApplicationCollateralEvaluationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationCollateralEvaluation(requestParameters: LoanApplicationControllerApiCreateApplicationCollateralEvaluationRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createApplicationCollateralEvaluation(requestParameters.applicationId, requestParameters.collateralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanApplicationControllerApiDeleteApplicationCollateralEvaluationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationCollateralEvaluation(requestParameters: LoanApplicationControllerApiDeleteApplicationCollateralEvaluationRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApplicationCollateralEvaluation(requestParameters.applicationId, requestParameters.collateralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanApplicationControllerApiFindAllLoanApplicationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLoanApplications(requestParameters: LoanApplicationControllerApiFindAllLoanApplicationsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageLoanApplicationListEntry> {
            return localVarFp.findAllLoanApplications(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.relationshipManagerId, requestParameters.branchCode, requestParameters.customerId, requestParameters.applicationStatuses, requestParameters.productTypes, requestParameters.customerName, requestParameters.registrationNumber, requestParameters.createdDateFrom, requestParameters.createdDateTo, requestParameters.externalId, requestParameters.phone, requestParameters.civilId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanApplicationControllerApiGetCollateralsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollaterals(requestParameters: LoanApplicationControllerApiGetCollateralsRequest, options?: AxiosRequestConfig): AxiosPromise<PageApplicationCollateralListEntry> {
            return localVarFp.getCollaterals(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanApplicationControllerApiGetLoanApplicationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanApplication(requestParameters: LoanApplicationControllerApiGetLoanApplicationRequest, options?: AxiosRequestConfig): AxiosPromise<GetLoanApplication200Response> {
            return localVarFp.getLoanApplication(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanApplicationControllerApiGetPaymentSchedule1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentSchedule1(requestParameters: LoanApplicationControllerApiGetPaymentSchedule1Request, options?: AxiosRequestConfig): AxiosPromise<ApplicationPaymentScheduleEntry> {
            return localVarFp.getPaymentSchedule1(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanApplicationControllerApiUpdateLoanApplicationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanApplication(requestParameters: LoanApplicationControllerApiUpdateLoanApplicationRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateLoanApplication(requestParameters.id, requestParameters.updateLoanApplicationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createApplicationCollateralEvaluation operation in LoanApplicationControllerApi.
 * @export
 * @interface LoanApplicationControllerApiCreateApplicationCollateralEvaluationRequest
 */
export interface LoanApplicationControllerApiCreateApplicationCollateralEvaluationRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationControllerApiCreateApplicationCollateralEvaluation
     */
    readonly applicationId: number

    /**
     * 
     * @type {number}
     * @memberof LoanApplicationControllerApiCreateApplicationCollateralEvaluation
     */
    readonly collateralId: number
}

/**
 * Request parameters for deleteApplicationCollateralEvaluation operation in LoanApplicationControllerApi.
 * @export
 * @interface LoanApplicationControllerApiDeleteApplicationCollateralEvaluationRequest
 */
export interface LoanApplicationControllerApiDeleteApplicationCollateralEvaluationRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationControllerApiDeleteApplicationCollateralEvaluation
     */
    readonly applicationId: number

    /**
     * 
     * @type {number}
     * @memberof LoanApplicationControllerApiDeleteApplicationCollateralEvaluation
     */
    readonly collateralId: number
}

/**
 * Request parameters for findAllLoanApplications operation in LoanApplicationControllerApi.
 * @export
 * @interface LoanApplicationControllerApiFindAllLoanApplicationsRequest
 */
export interface LoanApplicationControllerApiFindAllLoanApplicationsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly relationshipManagerId?: number

    /**
     * 
     * @type {string}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly branchCode?: string

    /**
     * 
     * @type {number}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly customerId?: number

    /**
     * 
     * @type {Array<LoanApplicationStatus>}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly applicationStatuses?: Array<LoanApplicationStatus>

    /**
     * 
     * @type {Array<ProductType>}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly productTypes?: Array<ProductType>

    /**
     * 
     * @type {string}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly customerName?: string

    /**
     * 
     * @type {string}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly registrationNumber?: string

    /**
     * 
     * @type {string}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly createdDateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly createdDateTo?: string

    /**
     * 
     * @type {string}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly externalId?: string

    /**
     * 
     * @type {string}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly phone?: string

    /**
     * 
     * @type {string}
     * @memberof LoanApplicationControllerApiFindAllLoanApplications
     */
    readonly civilId?: string
}

/**
 * Request parameters for getCollaterals operation in LoanApplicationControllerApi.
 * @export
 * @interface LoanApplicationControllerApiGetCollateralsRequest
 */
export interface LoanApplicationControllerApiGetCollateralsRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationControllerApiGetCollaterals
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanApplicationControllerApiGetCollaterals
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanApplicationControllerApiGetCollaterals
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanApplicationControllerApiGetCollaterals
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getLoanApplication operation in LoanApplicationControllerApi.
 * @export
 * @interface LoanApplicationControllerApiGetLoanApplicationRequest
 */
export interface LoanApplicationControllerApiGetLoanApplicationRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationControllerApiGetLoanApplication
     */
    readonly id: number
}

/**
 * Request parameters for getPaymentSchedule1 operation in LoanApplicationControllerApi.
 * @export
 * @interface LoanApplicationControllerApiGetPaymentSchedule1Request
 */
export interface LoanApplicationControllerApiGetPaymentSchedule1Request {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationControllerApiGetPaymentSchedule1
     */
    readonly id: number
}

/**
 * Request parameters for updateLoanApplication operation in LoanApplicationControllerApi.
 * @export
 * @interface LoanApplicationControllerApiUpdateLoanApplicationRequest
 */
export interface LoanApplicationControllerApiUpdateLoanApplicationRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationControllerApiUpdateLoanApplication
     */
    readonly id: number

    /**
     * 
     * @type {UpdateLoanApplicationRequest}
     * @memberof LoanApplicationControllerApiUpdateLoanApplication
     */
    readonly updateLoanApplicationRequest: UpdateLoanApplicationRequest
}

/**
 * LoanApplicationControllerApi - object-oriented interface
 * @export
 * @class LoanApplicationControllerApi
 * @extends {BaseAPI}
 */
export class LoanApplicationControllerApi extends BaseAPI {
    /**
     * 
     * @param {LoanApplicationControllerApiCreateApplicationCollateralEvaluationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationControllerApi
     */
    public createApplicationCollateralEvaluation(requestParameters: LoanApplicationControllerApiCreateApplicationCollateralEvaluationRequest, options?: AxiosRequestConfig) {
        return LoanApplicationControllerApiFp(this.configuration).createApplicationCollateralEvaluation(requestParameters.applicationId, requestParameters.collateralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanApplicationControllerApiDeleteApplicationCollateralEvaluationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationControllerApi
     */
    public deleteApplicationCollateralEvaluation(requestParameters: LoanApplicationControllerApiDeleteApplicationCollateralEvaluationRequest, options?: AxiosRequestConfig) {
        return LoanApplicationControllerApiFp(this.configuration).deleteApplicationCollateralEvaluation(requestParameters.applicationId, requestParameters.collateralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanApplicationControllerApiFindAllLoanApplicationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationControllerApi
     */
    public findAllLoanApplications(requestParameters: LoanApplicationControllerApiFindAllLoanApplicationsRequest = {}, options?: AxiosRequestConfig) {
        return LoanApplicationControllerApiFp(this.configuration).findAllLoanApplications(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.relationshipManagerId, requestParameters.branchCode, requestParameters.customerId, requestParameters.applicationStatuses, requestParameters.productTypes, requestParameters.customerName, requestParameters.registrationNumber, requestParameters.createdDateFrom, requestParameters.createdDateTo, requestParameters.externalId, requestParameters.phone, requestParameters.civilId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanApplicationControllerApiGetCollateralsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationControllerApi
     */
    public getCollaterals(requestParameters: LoanApplicationControllerApiGetCollateralsRequest, options?: AxiosRequestConfig) {
        return LoanApplicationControllerApiFp(this.configuration).getCollaterals(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanApplicationControllerApiGetLoanApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationControllerApi
     */
    public getLoanApplication(requestParameters: LoanApplicationControllerApiGetLoanApplicationRequest, options?: AxiosRequestConfig) {
        return LoanApplicationControllerApiFp(this.configuration).getLoanApplication(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanApplicationControllerApiGetPaymentSchedule1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationControllerApi
     */
    public getPaymentSchedule1(requestParameters: LoanApplicationControllerApiGetPaymentSchedule1Request, options?: AxiosRequestConfig) {
        return LoanApplicationControllerApiFp(this.configuration).getPaymentSchedule1(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanApplicationControllerApiUpdateLoanApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationControllerApi
     */
    public updateLoanApplication(requestParameters: LoanApplicationControllerApiUpdateLoanApplicationRequest, options?: AxiosRequestConfig) {
        return LoanApplicationControllerApiFp(this.configuration).updateLoanApplication(requestParameters.id, requestParameters.updateLoanApplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoanControllerApi - axios parameter creator
 * @export
 */
export const LoanControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {CalculateLoanStateRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateLoanState: async (id: number, request: CalculateLoanStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calculateLoanState', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('calculateLoanState', 'request', request)
            const localVarPath = `/api/loans/{id}/calculateLoanState`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (request !== undefined) {
                localVarQueryParameter['request'] = request;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableBorrowerDeathStatus: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('disableBorrowerDeathStatus', 'id', id)
            const localVarPath = `/api/loans/{id}/disableBorrowerDeathStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableLawsuitStatus: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('disableLawsuitStatus', 'id', id)
            const localVarPath = `/api/loans/{id}/disableLawsuitStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableManualLoanStatus: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('disableManualLoanStatus', 'id', id)
            const localVarPath = `/api/loans/{id}/disableManualLoanStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableBorrowerDeathStatus: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enableBorrowerDeathStatus', 'id', id)
            const localVarPath = `/api/loans/{id}/enableBorrowerDeathStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableLawsuitStatus: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enableLawsuitStatus', 'id', id)
            const localVarPath = `/api/loans/{id}/enableLawsuitStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ManualLoanStatusRequest} manualLoanStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableManualLoanStatus: async (id: number, manualLoanStatusRequest: ManualLoanStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enableManualLoanStatus', 'id', id)
            // verify required parameter 'manualLoanStatusRequest' is not null or undefined
            assertParamExists('enableManualLoanStatus', 'manualLoanStatusRequest', manualLoanStatusRequest)
            const localVarPath = `/api/loans/{id}/enableManualLoanStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manualLoanStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [externalId] 
         * @param {Array<ProductType>} [productTypes] 
         * @param {string} [customerName] 
         * @param {number} [relationshipManagerId] 
         * @param {string} [branchCode] 
         * @param {Array<LoanStatus>} [loanStatuses] 
         * @param {string} [disbursementDateFrom] 
         * @param {string} [disbursementDateTo] 
         * @param {string} [customerRegistrationNumber] 
         * @param {string} [phone] 
         * @param {string} [disbursementPaymentAccountNumber] 
         * @param {string} [civilId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLoans: async (page?: number, size?: number, sort?: Array<string>, externalId?: string, productTypes?: Array<ProductType>, customerName?: string, relationshipManagerId?: number, branchCode?: string, loanStatuses?: Array<LoanStatus>, disbursementDateFrom?: string, disbursementDateTo?: string, customerRegistrationNumber?: string, phone?: string, disbursementPaymentAccountNumber?: string, civilId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (productTypes) {
                localVarQueryParameter['productTypes'] = productTypes;
            }

            if (customerName !== undefined) {
                localVarQueryParameter['customerName'] = customerName;
            }

            if (relationshipManagerId !== undefined) {
                localVarQueryParameter['relationshipManagerId'] = relationshipManagerId;
            }

            if (branchCode !== undefined) {
                localVarQueryParameter['branchCode'] = branchCode;
            }

            if (loanStatuses) {
                localVarQueryParameter['loanStatuses'] = loanStatuses;
            }

            if (disbursementDateFrom !== undefined) {
                localVarQueryParameter['disbursementDateFrom'] = (disbursementDateFrom as any instanceof Date) ?
                    (disbursementDateFrom as any).toISOString().substring(0,10) :
                    disbursementDateFrom;
            }

            if (disbursementDateTo !== undefined) {
                localVarQueryParameter['disbursementDateTo'] = (disbursementDateTo as any instanceof Date) ?
                    (disbursementDateTo as any).toISOString().substring(0,10) :
                    disbursementDateTo;
            }

            if (customerRegistrationNumber !== undefined) {
                localVarQueryParameter['customerRegistrationNumber'] = customerRegistrationNumber;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (disbursementPaymentAccountNumber !== undefined) {
                localVarQueryParameter['disbursementPaymentAccountNumber'] = disbursementPaymentAccountNumber;
            }

            if (civilId !== undefined) {
                localVarQueryParameter['civilId'] = civilId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalLoanIssuingPrecondition: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdditionalLoanIssuingPrecondition', 'id', id)
            const localVarPath = `/api/loans/{id}/additional-loan-issuing-precondition`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalLoanIssuingProposal: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdditionalLoanIssuingProposal', 'id', id)
            const localVarPath = `/api/loans/{id}/additional-loan-issuing-proposal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGracePeriod: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGracePeriod', 'id', id)
            const localVarPath = `/api/loans/{id}/proposeGracePeriod`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestAdjustmentPrecondition: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInterestAdjustmentPrecondition', 'id', id)
            const localVarPath = `/api/loans/{id}/interest-adjustment-precondition`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestAdjustmentProposal: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInterestAdjustmentProposal', 'id', id)
            const localVarPath = `/api/loans/{id}/interest-adjustment-proposal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestRateChanging: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInterestRateChanging', 'id', id)
            const localVarPath = `/api/loans/{id}/proposeInterestRateChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoan: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLoan', 'id', id)
            const localVarPath = `/api/loans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManualLoanStatuses: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getManualLoanStatuses', 'id', id)
            const localVarPath = `/api/loans/{id}/manualLoanStatuses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaturityDateExtension: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMaturityDateExtension', 'id', id)
            const localVarPath = `/api/loans/{id}/proposeMaturityDateExtension`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPastDatePayment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPastDatePayment', 'id', id)
            const localVarPath = `/api/loans/{id}/proposePastDatePayment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentAmountChanging: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPaymentAmountChanging', 'id', id)
            const localVarPath = `/api/loans/{id}/proposePaymentAmountChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentDateChanging: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPaymentDateChanging', 'id', id)
            const localVarPath = `/api/loans/{id}/proposePaymentDateChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentSchedule: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPaymentSchedule', 'id', id)
            const localVarPath = `/api/loans/{id}/payment-schedule`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPenaltyAdjustmentPrecondition: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPenaltyAdjustmentPrecondition', 'id', id)
            const localVarPath = `/api/loans/{id}/penalty-adjustment-precondition`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPenaltyAdjustmentProposal: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPenaltyAdjustmentProposal', 'id', id)
            const localVarPath = `/api/loans/{id}/penalty-adjustment-proposal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposeInterestAccrualStopRenew: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProposeInterestAccrualStopRenew', 'id', id)
            const localVarPath = `/api/loans/{id}/proposeInterestAccrualStopRenew`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposePaymentCancellation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProposePaymentCancellation', 'id', id)
            const localVarPath = `/api/loans/{id}/proposePaymentCancellation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefinancing: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRefinancing', 'id', id)
            const localVarPath = `/api/loans/{id}/proposeRefinancing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewGracePeriod: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewGracePeriod', 'id', id)
            const localVarPath = `/api/loans/{id}/reviewGracePeriod`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewInterestAccrualStopRenew: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewInterestAccrualStopRenew', 'id', id)
            const localVarPath = `/api/loans/{id}/reviewInterestAccrualStopRenew`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewInterestRateChanging: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewInterestRateChanging', 'id', id)
            const localVarPath = `/api/loans/{id}/reviewInterestRateChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewMaturityDateExtension: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewMaturityDateExtension', 'id', id)
            const localVarPath = `/api/loans/{id}/reviewMaturityDateExtension`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewNewGracePeriodAllowing: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewNewGracePeriodAllowing', 'id', id)
            const localVarPath = `/api/loans/{id}/reviewNewGracePeriodAllowing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewPastDatePayment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewPastDatePayment', 'id', id)
            const localVarPath = `/api/loans/{id}/reviewPastDatePayment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewPaymentCancellation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewPaymentCancellation', 'id', id)
            const localVarPath = `/api/loans/{id}/reviewPaymentCancellation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewRefinancing: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewRefinancing', 'id', id)
            const localVarPath = `/api/loans/{id}/reviewRefinancing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewRepaymentAmountChanging: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewRepaymentAmountChanging', 'id', id)
            const localVarPath = `/api/loans/{id}/reviewPaymentAmountChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewRepaymentDateChanging: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewRepaymentDateChanging', 'id', id)
            const localVarPath = `/api/loans/{id}/reviewPaymentDateChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewWriteOff: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewWriteOff', 'id', id)
            const localVarPath = `/api/loans/{id}/reviewWriteOff`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchLoanRequest} patchLoanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLoan: async (id: number, patchLoanRequest: PatchLoanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchLoan', 'id', id)
            // verify required parameter 'patchLoanRequest' is not null or undefined
            assertParamExists('patchLoan', 'patchLoanRequest', patchLoanRequest)
            const localVarPath = `/api/loans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchLoanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {NewAdditionalLoanIssuingProposal} newAdditionalLoanIssuingProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeAdditionalLoanIssuing: async (id: number, newAdditionalLoanIssuingProposal: NewAdditionalLoanIssuingProposal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposeAdditionalLoanIssuing', 'id', id)
            // verify required parameter 'newAdditionalLoanIssuingProposal' is not null or undefined
            assertParamExists('proposeAdditionalLoanIssuing', 'newAdditionalLoanIssuingProposal', newAdditionalLoanIssuingProposal)
            const localVarPath = `/api/loans/{id}/additional-loan-issuing-proposal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAdditionalLoanIssuingProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanGracePeriodProposal} loanGracePeriodProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeGracePeriod: async (id: number, loanGracePeriodProposal: LoanGracePeriodProposal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposeGracePeriod', 'id', id)
            // verify required parameter 'loanGracePeriodProposal' is not null or undefined
            assertParamExists('proposeGracePeriod', 'loanGracePeriodProposal', loanGracePeriodProposal)
            const localVarPath = `/api/loans/{id}/proposeGracePeriod`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanGracePeriodProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeInterestAccrualStopRenew: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposeInterestAccrualStopRenew', 'id', id)
            const localVarPath = `/api/loans/{id}/proposeInterestAccrualStopRenew`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanInterestAdjustment} loanInterestAdjustment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeInterestAdjustment: async (id: number, loanInterestAdjustment: LoanInterestAdjustment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposeInterestAdjustment', 'id', id)
            // verify required parameter 'loanInterestAdjustment' is not null or undefined
            assertParamExists('proposeInterestAdjustment', 'loanInterestAdjustment', loanInterestAdjustment)
            const localVarPath = `/api/loans/{id}/interest-adjustment-proposal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanInterestAdjustment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanInterestRateChangingProposal} loanInterestRateChangingProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeInterestRateChanging: async (id: number, loanInterestRateChangingProposal: LoanInterestRateChangingProposal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposeInterestRateChanging', 'id', id)
            // verify required parameter 'loanInterestRateChangingProposal' is not null or undefined
            assertParamExists('proposeInterestRateChanging', 'loanInterestRateChangingProposal', loanInterestRateChangingProposal)
            const localVarPath = `/api/loans/{id}/proposeInterestRateChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanInterestRateChangingProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanMaturityDateExtensionProposal} loanMaturityDateExtensionProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeMaturityDateExtension: async (id: number, loanMaturityDateExtensionProposal: LoanMaturityDateExtensionProposal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposeMaturityDateExtension', 'id', id)
            // verify required parameter 'loanMaturityDateExtensionProposal' is not null or undefined
            assertParamExists('proposeMaturityDateExtension', 'loanMaturityDateExtensionProposal', loanMaturityDateExtensionProposal)
            const localVarPath = `/api/loans/{id}/proposeMaturityDateExtension`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanMaturityDateExtensionProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeNewGracePeriodAllowing: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposeNewGracePeriodAllowing', 'id', id)
            const localVarPath = `/api/loans/{id}/proposeNewGracePeriodAllowing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanPastDatePaymentProposal} loanPastDatePaymentProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposePastDatePayment: async (id: number, loanPastDatePaymentProposal: LoanPastDatePaymentProposal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposePastDatePayment', 'id', id)
            // verify required parameter 'loanPastDatePaymentProposal' is not null or undefined
            assertParamExists('proposePastDatePayment', 'loanPastDatePaymentProposal', loanPastDatePaymentProposal)
            const localVarPath = `/api/loans/{id}/proposePastDatePayment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanPastDatePaymentProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanPaymentAmountChangingProposal} loanPaymentAmountChangingProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposePaymentAmountChanging: async (id: number, loanPaymentAmountChangingProposal: LoanPaymentAmountChangingProposal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposePaymentAmountChanging', 'id', id)
            // verify required parameter 'loanPaymentAmountChangingProposal' is not null or undefined
            assertParamExists('proposePaymentAmountChanging', 'loanPaymentAmountChangingProposal', loanPaymentAmountChangingProposal)
            const localVarPath = `/api/loans/{id}/proposePaymentAmountChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanPaymentAmountChangingProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanPaymentCancellationProposal} loanPaymentCancellationProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposePaymentCancellation: async (id: number, loanPaymentCancellationProposal: LoanPaymentCancellationProposal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposePaymentCancellation', 'id', id)
            // verify required parameter 'loanPaymentCancellationProposal' is not null or undefined
            assertParamExists('proposePaymentCancellation', 'loanPaymentCancellationProposal', loanPaymentCancellationProposal)
            const localVarPath = `/api/loans/{id}/proposePaymentCancellation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanPaymentCancellationProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanPaymentDateChangingProposal} loanPaymentDateChangingProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposePaymentDateChanging: async (id: number, loanPaymentDateChangingProposal: LoanPaymentDateChangingProposal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposePaymentDateChanging', 'id', id)
            // verify required parameter 'loanPaymentDateChangingProposal' is not null or undefined
            assertParamExists('proposePaymentDateChanging', 'loanPaymentDateChangingProposal', loanPaymentDateChangingProposal)
            const localVarPath = `/api/loans/{id}/proposePaymentDateChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanPaymentDateChangingProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanPenaltyAdjustment} loanPenaltyAdjustment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposePenaltyAdjustment: async (id: number, loanPenaltyAdjustment: LoanPenaltyAdjustment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposePenaltyAdjustment', 'id', id)
            // verify required parameter 'loanPenaltyAdjustment' is not null or undefined
            assertParamExists('proposePenaltyAdjustment', 'loanPenaltyAdjustment', loanPenaltyAdjustment)
            const localVarPath = `/api/loans/{id}/penalty-adjustment-proposal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanPenaltyAdjustment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanRefinancingProposal} loanRefinancingProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeRefinancing: async (id: number, loanRefinancingProposal: LoanRefinancingProposal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposeRefinancing', 'id', id)
            // verify required parameter 'loanRefinancingProposal' is not null or undefined
            assertParamExists('proposeRefinancing', 'loanRefinancingProposal', loanRefinancingProposal)
            const localVarPath = `/api/loans/{id}/proposeRefinancing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanRefinancingProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeWriteOff: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposeWriteOff', 'id', id)
            const localVarPath = `/api/loans/{id}/proposeWriteOff`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewAdditionalLoanIssuingProposal: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewAdditionalLoanIssuingProposal', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewAdditionalLoanIssuingProposal', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/additional-loan-issuing-proposal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewGracePeriod: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewGracePeriod', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewGracePeriod', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/reviewGracePeriod`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewInterestAccrualStopRenew: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewInterestAccrualStopRenew', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewInterestAccrualStopRenew', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/reviewInterestAccrualStopRenew`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewInterestAdjustmentProposal: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewInterestAdjustmentProposal', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewInterestAdjustmentProposal', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/interest-adjustment-proposal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewInterestRateChanging: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewInterestRateChanging', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewInterestRateChanging', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/reviewInterestRateChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewMaturityDateExtension: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewMaturityDateExtension', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewMaturityDateExtension', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/reviewMaturityDateExtension`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewNewGracePeriodAllowing: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewNewGracePeriodAllowing', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewNewGracePeriodAllowing', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/reviewNewGracePeriodAllowing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewPastDatePayment: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewPastDatePayment', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewPastDatePayment', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/reviewPastDatePayment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewPaymentAmountChanging: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewPaymentAmountChanging', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewPaymentAmountChanging', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/reviewPaymentAmountChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewPaymentCancellation: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewPaymentCancellation', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewPaymentCancellation', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/reviewPaymentCancellation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewPaymentDateChanging: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewPaymentDateChanging', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewPaymentDateChanging', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/reviewPaymentDateChanging`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewPenaltyAdjustmentProposal: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewPenaltyAdjustmentProposal', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewPenaltyAdjustmentProposal', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/penalty-adjustment-proposal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRefinancing: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewRefinancing', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewRefinancing', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/reviewRefinancing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewWriteOff: async (id: number, restructuringReviewRequest: RestructuringReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewWriteOff', 'id', id)
            // verify required parameter 'restructuringReviewRequest' is not null or undefined
            assertParamExists('reviewWriteOff', 'restructuringReviewRequest', restructuringReviewRequest)
            const localVarPath = `/api/loans/{id}/reviewWriteOff`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restructuringReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanControllerApi - functional programming interface
 * @export
 */
export const LoanControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoanControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {CalculateLoanStateRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateLoanState(id: number, request: CalculateLoanStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateLoanState(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableBorrowerDeathStatus(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableBorrowerDeathStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableLawsuitStatus(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableLawsuitStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableManualLoanStatus(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableManualLoanStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableBorrowerDeathStatus(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableBorrowerDeathStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableLawsuitStatus(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableLawsuitStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ManualLoanStatusRequest} manualLoanStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableManualLoanStatus(id: number, manualLoanStatusRequest: ManualLoanStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableManualLoanStatus(id, manualLoanStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [externalId] 
         * @param {Array<ProductType>} [productTypes] 
         * @param {string} [customerName] 
         * @param {number} [relationshipManagerId] 
         * @param {string} [branchCode] 
         * @param {Array<LoanStatus>} [loanStatuses] 
         * @param {string} [disbursementDateFrom] 
         * @param {string} [disbursementDateTo] 
         * @param {string} [customerRegistrationNumber] 
         * @param {string} [phone] 
         * @param {string} [disbursementPaymentAccountNumber] 
         * @param {string} [civilId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLoans(page?: number, size?: number, sort?: Array<string>, externalId?: string, productTypes?: Array<ProductType>, customerName?: string, relationshipManagerId?: number, branchCode?: string, loanStatuses?: Array<LoanStatus>, disbursementDateFrom?: string, disbursementDateTo?: string, customerRegistrationNumber?: string, phone?: string, disbursementPaymentAccountNumber?: string, civilId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLoans(page, size, sort, externalId, productTypes, customerName, relationshipManagerId, branchCode, loanStatuses, disbursementDateFrom, disbursementDateTo, customerRegistrationNumber, phone, disbursementPaymentAccountNumber, civilId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdditionalLoanIssuingPrecondition(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalLoanIssuingPrecondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdditionalLoanIssuingPrecondition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdditionalLoanIssuingProposal(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalLoanIssuingProposal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdditionalLoanIssuingProposal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGracePeriod(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanGracePeriodPrecondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGracePeriod(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterestAdjustmentPrecondition(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanInterestAdjustment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterestAdjustmentPrecondition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterestAdjustmentProposal(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedLoanInterestAdjustment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterestAdjustmentProposal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterestRateChanging(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanInterestRateChangingPrecondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterestRateChanging(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoan(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLoan200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManualLoanStatuses(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManualLoanStatusPrecondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManualLoanStatuses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaturityDateExtension(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanMaturityDateExtensionPrecondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaturityDateExtension(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPastDatePayment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanPastDatePaymentPrecondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPastDatePayment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentAmountChanging(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanPaymentAmountChangingPrecondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentAmountChanging(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentDateChanging(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanPaymentDateChangingPrecondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentDateChanging(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentSchedule(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentScheduleEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentSchedule(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPenaltyAdjustmentPrecondition(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanPenaltyAdjustment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPenaltyAdjustmentPrecondition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPenaltyAdjustmentProposal(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedLoanPenaltyAdjustment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPenaltyAdjustmentProposal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposeInterestAccrualStopRenew(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanInterestAccrualPrecondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposeInterestAccrualStopRenew(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposePaymentCancellation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanPaymentCancellationPrecondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposePaymentCancellation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRefinancing(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanRefinancingPrecondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRefinancing(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewGracePeriod(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedGracePeriod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewGracePeriod(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewInterestAccrualStopRenew(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedLoanInterestAccrual>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewInterestAccrualStopRenew(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewInterestRateChanging(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedInterestRateChanging>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewInterestRateChanging(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewMaturityDateExtension(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedMaturityDateExtension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewMaturityDateExtension(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewNewGracePeriodAllowing(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedNewGracePeriodAllowing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewNewGracePeriodAllowing(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewPastDatePayment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedLoanPastDatePayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewPastDatePayment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewPaymentCancellation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedLoanPaymentCancellation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewPaymentCancellation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewRefinancing(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedRefinancing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewRefinancing(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewRepaymentAmountChanging(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedLoanPaymentAmountChanging>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewRepaymentAmountChanging(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewRepaymentDateChanging(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedPaymentDateChanging>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewRepaymentDateChanging(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewWriteOff(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedWriteOff>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewWriteOff(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchLoanRequest} patchLoanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchLoan(id: number, patchLoanRequest: PatchLoanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchLoan(id, patchLoanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {NewAdditionalLoanIssuingProposal} newAdditionalLoanIssuingProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeAdditionalLoanIssuing(id: number, newAdditionalLoanIssuingProposal: NewAdditionalLoanIssuingProposal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeAdditionalLoanIssuing(id, newAdditionalLoanIssuingProposal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanGracePeriodProposal} loanGracePeriodProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeGracePeriod(id: number, loanGracePeriodProposal: LoanGracePeriodProposal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeGracePeriod(id, loanGracePeriodProposal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeInterestAccrualStopRenew(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeInterestAccrualStopRenew(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanInterestAdjustment} loanInterestAdjustment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeInterestAdjustment(id: number, loanInterestAdjustment: LoanInterestAdjustment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeInterestAdjustment(id, loanInterestAdjustment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanInterestRateChangingProposal} loanInterestRateChangingProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeInterestRateChanging(id: number, loanInterestRateChangingProposal: LoanInterestRateChangingProposal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeInterestRateChanging(id, loanInterestRateChangingProposal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanMaturityDateExtensionProposal} loanMaturityDateExtensionProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeMaturityDateExtension(id: number, loanMaturityDateExtensionProposal: LoanMaturityDateExtensionProposal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeMaturityDateExtension(id, loanMaturityDateExtensionProposal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeNewGracePeriodAllowing(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeNewGracePeriodAllowing(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanPastDatePaymentProposal} loanPastDatePaymentProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposePastDatePayment(id: number, loanPastDatePaymentProposal: LoanPastDatePaymentProposal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposePastDatePayment(id, loanPastDatePaymentProposal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanPaymentAmountChangingProposal} loanPaymentAmountChangingProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposePaymentAmountChanging(id: number, loanPaymentAmountChangingProposal: LoanPaymentAmountChangingProposal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposePaymentAmountChanging(id, loanPaymentAmountChangingProposal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanPaymentCancellationProposal} loanPaymentCancellationProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposePaymentCancellation(id: number, loanPaymentCancellationProposal: LoanPaymentCancellationProposal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposePaymentCancellation(id, loanPaymentCancellationProposal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanPaymentDateChangingProposal} loanPaymentDateChangingProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposePaymentDateChanging(id: number, loanPaymentDateChangingProposal: LoanPaymentDateChangingProposal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposePaymentDateChanging(id, loanPaymentDateChangingProposal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanPenaltyAdjustment} loanPenaltyAdjustment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposePenaltyAdjustment(id: number, loanPenaltyAdjustment: LoanPenaltyAdjustment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposePenaltyAdjustment(id, loanPenaltyAdjustment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LoanRefinancingProposal} loanRefinancingProposal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeRefinancing(id: number, loanRefinancingProposal: LoanRefinancingProposal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeRefinancing(id, loanRefinancingProposal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeWriteOff(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeWriteOff(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewAdditionalLoanIssuingProposal(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewAdditionalLoanIssuingProposal(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewGracePeriod(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewGracePeriod(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewInterestAccrualStopRenew(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewInterestAccrualStopRenew(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewInterestAdjustmentProposal(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewInterestAdjustmentProposal(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewInterestRateChanging(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewInterestRateChanging(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewMaturityDateExtension(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewMaturityDateExtension(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewNewGracePeriodAllowing(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewNewGracePeriodAllowing(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewPastDatePayment(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewPastDatePayment(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewPaymentAmountChanging(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewPaymentAmountChanging(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewPaymentCancellation(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewPaymentCancellation(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewPaymentDateChanging(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewPaymentDateChanging(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewPenaltyAdjustmentProposal(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewPenaltyAdjustmentProposal(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewRefinancing(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewRefinancing(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RestructuringReviewRequest} restructuringReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewWriteOff(id: number, restructuringReviewRequest: RestructuringReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewWriteOff(id, restructuringReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoanControllerApi - factory interface
 * @export
 */
export const LoanControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoanControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {LoanControllerApiCalculateLoanStateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateLoanState(requestParameters: LoanControllerApiCalculateLoanStateRequest, options?: AxiosRequestConfig): AxiosPromise<LoanState> {
            return localVarFp.calculateLoanState(requestParameters.id, requestParameters.request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiDisableBorrowerDeathStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableBorrowerDeathStatus(requestParameters: LoanControllerApiDisableBorrowerDeathStatusRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.disableBorrowerDeathStatus(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiDisableLawsuitStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableLawsuitStatus(requestParameters: LoanControllerApiDisableLawsuitStatusRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.disableLawsuitStatus(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiDisableManualLoanStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableManualLoanStatus(requestParameters: LoanControllerApiDisableManualLoanStatusRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.disableManualLoanStatus(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiEnableBorrowerDeathStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableBorrowerDeathStatus(requestParameters: LoanControllerApiEnableBorrowerDeathStatusRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.enableBorrowerDeathStatus(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiEnableLawsuitStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableLawsuitStatus(requestParameters: LoanControllerApiEnableLawsuitStatusRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.enableLawsuitStatus(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiEnableManualLoanStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableManualLoanStatus(requestParameters: LoanControllerApiEnableManualLoanStatusRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.enableManualLoanStatus(requestParameters.id, requestParameters.manualLoanStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiFindLoansRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLoans(requestParameters: LoanControllerApiFindLoansRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageLoanListEntry> {
            return localVarFp.findLoans(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.externalId, requestParameters.productTypes, requestParameters.customerName, requestParameters.relationshipManagerId, requestParameters.branchCode, requestParameters.loanStatuses, requestParameters.disbursementDateFrom, requestParameters.disbursementDateTo, requestParameters.customerRegistrationNumber, requestParameters.phone, requestParameters.disbursementPaymentAccountNumber, requestParameters.civilId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetAdditionalLoanIssuingPreconditionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalLoanIssuingPrecondition(requestParameters: LoanControllerApiGetAdditionalLoanIssuingPreconditionRequest, options?: AxiosRequestConfig): AxiosPromise<AdditionalLoanIssuingPrecondition> {
            return localVarFp.getAdditionalLoanIssuingPrecondition(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetAdditionalLoanIssuingProposalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalLoanIssuingProposal(requestParameters: LoanControllerApiGetAdditionalLoanIssuingProposalRequest, options?: AxiosRequestConfig): AxiosPromise<AdditionalLoanIssuingProposal> {
            return localVarFp.getAdditionalLoanIssuingProposal(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetGracePeriodRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGracePeriod(requestParameters: LoanControllerApiGetGracePeriodRequest, options?: AxiosRequestConfig): AxiosPromise<LoanGracePeriodPrecondition> {
            return localVarFp.getGracePeriod(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetInterestAdjustmentPreconditionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestAdjustmentPrecondition(requestParameters: LoanControllerApiGetInterestAdjustmentPreconditionRequest, options?: AxiosRequestConfig): AxiosPromise<LoanInterestAdjustment> {
            return localVarFp.getInterestAdjustmentPrecondition(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetInterestAdjustmentProposalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestAdjustmentProposal(requestParameters: LoanControllerApiGetInterestAdjustmentProposalRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedLoanInterestAdjustment> {
            return localVarFp.getInterestAdjustmentProposal(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetInterestRateChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestRateChanging(requestParameters: LoanControllerApiGetInterestRateChangingRequest, options?: AxiosRequestConfig): AxiosPromise<LoanInterestRateChangingPrecondition> {
            return localVarFp.getInterestRateChanging(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetLoanRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoan(requestParameters: LoanControllerApiGetLoanRequest, options?: AxiosRequestConfig): AxiosPromise<GetLoan200Response> {
            return localVarFp.getLoan(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetManualLoanStatusesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManualLoanStatuses(requestParameters: LoanControllerApiGetManualLoanStatusesRequest, options?: AxiosRequestConfig): AxiosPromise<ManualLoanStatusPrecondition> {
            return localVarFp.getManualLoanStatuses(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetMaturityDateExtensionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaturityDateExtension(requestParameters: LoanControllerApiGetMaturityDateExtensionRequest, options?: AxiosRequestConfig): AxiosPromise<LoanMaturityDateExtensionPrecondition> {
            return localVarFp.getMaturityDateExtension(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetPastDatePaymentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPastDatePayment(requestParameters: LoanControllerApiGetPastDatePaymentRequest, options?: AxiosRequestConfig): AxiosPromise<LoanPastDatePaymentPrecondition> {
            return localVarFp.getPastDatePayment(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetPaymentAmountChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentAmountChanging(requestParameters: LoanControllerApiGetPaymentAmountChangingRequest, options?: AxiosRequestConfig): AxiosPromise<LoanPaymentAmountChangingPrecondition> {
            return localVarFp.getPaymentAmountChanging(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetPaymentDateChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentDateChanging(requestParameters: LoanControllerApiGetPaymentDateChangingRequest, options?: AxiosRequestConfig): AxiosPromise<LoanPaymentDateChangingPrecondition> {
            return localVarFp.getPaymentDateChanging(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetPaymentScheduleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentSchedule(requestParameters: LoanControllerApiGetPaymentScheduleRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentScheduleEntry> {
            return localVarFp.getPaymentSchedule(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetPenaltyAdjustmentPreconditionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPenaltyAdjustmentPrecondition(requestParameters: LoanControllerApiGetPenaltyAdjustmentPreconditionRequest, options?: AxiosRequestConfig): AxiosPromise<LoanPenaltyAdjustment> {
            return localVarFp.getPenaltyAdjustmentPrecondition(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetPenaltyAdjustmentProposalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPenaltyAdjustmentProposal(requestParameters: LoanControllerApiGetPenaltyAdjustmentProposalRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedLoanPenaltyAdjustment> {
            return localVarFp.getPenaltyAdjustmentProposal(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetProposeInterestAccrualStopRenewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposeInterestAccrualStopRenew(requestParameters: LoanControllerApiGetProposeInterestAccrualStopRenewRequest, options?: AxiosRequestConfig): AxiosPromise<LoanInterestAccrualPrecondition> {
            return localVarFp.getProposeInterestAccrualStopRenew(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetProposePaymentCancellationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposePaymentCancellation(requestParameters: LoanControllerApiGetProposePaymentCancellationRequest, options?: AxiosRequestConfig): AxiosPromise<LoanPaymentCancellationPrecondition> {
            return localVarFp.getProposePaymentCancellation(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetRefinancingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefinancing(requestParameters: LoanControllerApiGetRefinancingRequest, options?: AxiosRequestConfig): AxiosPromise<LoanRefinancingPrecondition> {
            return localVarFp.getRefinancing(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetReviewGracePeriodRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewGracePeriod(requestParameters: LoanControllerApiGetReviewGracePeriodRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedGracePeriod> {
            return localVarFp.getReviewGracePeriod(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetReviewInterestAccrualStopRenewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewInterestAccrualStopRenew(requestParameters: LoanControllerApiGetReviewInterestAccrualStopRenewRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedLoanInterestAccrual> {
            return localVarFp.getReviewInterestAccrualStopRenew(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetReviewInterestRateChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewInterestRateChanging(requestParameters: LoanControllerApiGetReviewInterestRateChangingRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedInterestRateChanging> {
            return localVarFp.getReviewInterestRateChanging(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetReviewMaturityDateExtensionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewMaturityDateExtension(requestParameters: LoanControllerApiGetReviewMaturityDateExtensionRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedMaturityDateExtension> {
            return localVarFp.getReviewMaturityDateExtension(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetReviewNewGracePeriodAllowingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewNewGracePeriodAllowing(requestParameters: LoanControllerApiGetReviewNewGracePeriodAllowingRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedNewGracePeriodAllowing> {
            return localVarFp.getReviewNewGracePeriodAllowing(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetReviewPastDatePaymentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewPastDatePayment(requestParameters: LoanControllerApiGetReviewPastDatePaymentRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedLoanPastDatePayment> {
            return localVarFp.getReviewPastDatePayment(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetReviewPaymentCancellationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewPaymentCancellation(requestParameters: LoanControllerApiGetReviewPaymentCancellationRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedLoanPaymentCancellation> {
            return localVarFp.getReviewPaymentCancellation(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetReviewRefinancingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewRefinancing(requestParameters: LoanControllerApiGetReviewRefinancingRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedRefinancing> {
            return localVarFp.getReviewRefinancing(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetReviewRepaymentAmountChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewRepaymentAmountChanging(requestParameters: LoanControllerApiGetReviewRepaymentAmountChangingRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedLoanPaymentAmountChanging> {
            return localVarFp.getReviewRepaymentAmountChanging(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetReviewRepaymentDateChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewRepaymentDateChanging(requestParameters: LoanControllerApiGetReviewRepaymentDateChangingRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedPaymentDateChanging> {
            return localVarFp.getReviewRepaymentDateChanging(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiGetReviewWriteOffRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewWriteOff(requestParameters: LoanControllerApiGetReviewWriteOffRequest, options?: AxiosRequestConfig): AxiosPromise<ProposedWriteOff> {
            return localVarFp.getReviewWriteOff(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiPatchLoanRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLoan(requestParameters: LoanControllerApiPatchLoanRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchLoan(requestParameters.id, requestParameters.patchLoanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposeAdditionalLoanIssuingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeAdditionalLoanIssuing(requestParameters: LoanControllerApiProposeAdditionalLoanIssuingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposeAdditionalLoanIssuing(requestParameters.id, requestParameters.newAdditionalLoanIssuingProposal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposeGracePeriodRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeGracePeriod(requestParameters: LoanControllerApiProposeGracePeriodRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposeGracePeriod(requestParameters.id, requestParameters.loanGracePeriodProposal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposeInterestAccrualStopRenewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeInterestAccrualStopRenew(requestParameters: LoanControllerApiProposeInterestAccrualStopRenewRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposeInterestAccrualStopRenew(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposeInterestAdjustmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeInterestAdjustment(requestParameters: LoanControllerApiProposeInterestAdjustmentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposeInterestAdjustment(requestParameters.id, requestParameters.loanInterestAdjustment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposeInterestRateChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeInterestRateChanging(requestParameters: LoanControllerApiProposeInterestRateChangingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposeInterestRateChanging(requestParameters.id, requestParameters.loanInterestRateChangingProposal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposeMaturityDateExtensionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeMaturityDateExtension(requestParameters: LoanControllerApiProposeMaturityDateExtensionRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposeMaturityDateExtension(requestParameters.id, requestParameters.loanMaturityDateExtensionProposal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposeNewGracePeriodAllowingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeNewGracePeriodAllowing(requestParameters: LoanControllerApiProposeNewGracePeriodAllowingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposeNewGracePeriodAllowing(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposePastDatePaymentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposePastDatePayment(requestParameters: LoanControllerApiProposePastDatePaymentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposePastDatePayment(requestParameters.id, requestParameters.loanPastDatePaymentProposal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposePaymentAmountChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposePaymentAmountChanging(requestParameters: LoanControllerApiProposePaymentAmountChangingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposePaymentAmountChanging(requestParameters.id, requestParameters.loanPaymentAmountChangingProposal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposePaymentCancellationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposePaymentCancellation(requestParameters: LoanControllerApiProposePaymentCancellationRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposePaymentCancellation(requestParameters.id, requestParameters.loanPaymentCancellationProposal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposePaymentDateChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposePaymentDateChanging(requestParameters: LoanControllerApiProposePaymentDateChangingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposePaymentDateChanging(requestParameters.id, requestParameters.loanPaymentDateChangingProposal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposePenaltyAdjustmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposePenaltyAdjustment(requestParameters: LoanControllerApiProposePenaltyAdjustmentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposePenaltyAdjustment(requestParameters.id, requestParameters.loanPenaltyAdjustment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposeRefinancingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeRefinancing(requestParameters: LoanControllerApiProposeRefinancingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposeRefinancing(requestParameters.id, requestParameters.loanRefinancingProposal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiProposeWriteOffRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeWriteOff(requestParameters: LoanControllerApiProposeWriteOffRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.proposeWriteOff(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewAdditionalLoanIssuingProposalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewAdditionalLoanIssuingProposal(requestParameters: LoanControllerApiReviewAdditionalLoanIssuingProposalRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewAdditionalLoanIssuingProposal(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewGracePeriodRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewGracePeriod(requestParameters: LoanControllerApiReviewGracePeriodRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewGracePeriod(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewInterestAccrualStopRenewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewInterestAccrualStopRenew(requestParameters: LoanControllerApiReviewInterestAccrualStopRenewRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewInterestAccrualStopRenew(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewInterestAdjustmentProposalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewInterestAdjustmentProposal(requestParameters: LoanControllerApiReviewInterestAdjustmentProposalRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewInterestAdjustmentProposal(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewInterestRateChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewInterestRateChanging(requestParameters: LoanControllerApiReviewInterestRateChangingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewInterestRateChanging(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewMaturityDateExtensionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewMaturityDateExtension(requestParameters: LoanControllerApiReviewMaturityDateExtensionRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewMaturityDateExtension(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewNewGracePeriodAllowingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewNewGracePeriodAllowing(requestParameters: LoanControllerApiReviewNewGracePeriodAllowingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewNewGracePeriodAllowing(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewPastDatePaymentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewPastDatePayment(requestParameters: LoanControllerApiReviewPastDatePaymentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewPastDatePayment(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewPaymentAmountChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewPaymentAmountChanging(requestParameters: LoanControllerApiReviewPaymentAmountChangingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewPaymentAmountChanging(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewPaymentCancellationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewPaymentCancellation(requestParameters: LoanControllerApiReviewPaymentCancellationRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewPaymentCancellation(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewPaymentDateChangingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewPaymentDateChanging(requestParameters: LoanControllerApiReviewPaymentDateChangingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewPaymentDateChanging(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewPenaltyAdjustmentProposalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewPenaltyAdjustmentProposal(requestParameters: LoanControllerApiReviewPenaltyAdjustmentProposalRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewPenaltyAdjustmentProposal(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewRefinancingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRefinancing(requestParameters: LoanControllerApiReviewRefinancingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewRefinancing(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoanControllerApiReviewWriteOffRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewWriteOff(requestParameters: LoanControllerApiReviewWriteOffRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewWriteOff(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for calculateLoanState operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiCalculateLoanStateRequest
 */
export interface LoanControllerApiCalculateLoanStateRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiCalculateLoanState
     */
    readonly id: number

    /**
     * 
     * @type {CalculateLoanStateRequest}
     * @memberof LoanControllerApiCalculateLoanState
     */
    readonly request: CalculateLoanStateRequest
}

/**
 * Request parameters for disableBorrowerDeathStatus operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiDisableBorrowerDeathStatusRequest
 */
export interface LoanControllerApiDisableBorrowerDeathStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiDisableBorrowerDeathStatus
     */
    readonly id: number
}

/**
 * Request parameters for disableLawsuitStatus operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiDisableLawsuitStatusRequest
 */
export interface LoanControllerApiDisableLawsuitStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiDisableLawsuitStatus
     */
    readonly id: number
}

/**
 * Request parameters for disableManualLoanStatus operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiDisableManualLoanStatusRequest
 */
export interface LoanControllerApiDisableManualLoanStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiDisableManualLoanStatus
     */
    readonly id: number
}

/**
 * Request parameters for enableBorrowerDeathStatus operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiEnableBorrowerDeathStatusRequest
 */
export interface LoanControllerApiEnableBorrowerDeathStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiEnableBorrowerDeathStatus
     */
    readonly id: number
}

/**
 * Request parameters for enableLawsuitStatus operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiEnableLawsuitStatusRequest
 */
export interface LoanControllerApiEnableLawsuitStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiEnableLawsuitStatus
     */
    readonly id: number
}

/**
 * Request parameters for enableManualLoanStatus operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiEnableManualLoanStatusRequest
 */
export interface LoanControllerApiEnableManualLoanStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiEnableManualLoanStatus
     */
    readonly id: number

    /**
     * 
     * @type {ManualLoanStatusRequest}
     * @memberof LoanControllerApiEnableManualLoanStatus
     */
    readonly manualLoanStatusRequest: ManualLoanStatusRequest
}

/**
 * Request parameters for findLoans operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiFindLoansRequest
 */
export interface LoanControllerApiFindLoansRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanControllerApiFindLoans
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanControllerApiFindLoans
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanControllerApiFindLoans
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiFindLoans
     */
    readonly externalId?: string

    /**
     * 
     * @type {Array<ProductType>}
     * @memberof LoanControllerApiFindLoans
     */
    readonly productTypes?: Array<ProductType>

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiFindLoans
     */
    readonly customerName?: string

    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiFindLoans
     */
    readonly relationshipManagerId?: number

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiFindLoans
     */
    readonly branchCode?: string

    /**
     * 
     * @type {Array<LoanStatus>}
     * @memberof LoanControllerApiFindLoans
     */
    readonly loanStatuses?: Array<LoanStatus>

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiFindLoans
     */
    readonly disbursementDateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiFindLoans
     */
    readonly disbursementDateTo?: string

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiFindLoans
     */
    readonly customerRegistrationNumber?: string

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiFindLoans
     */
    readonly phone?: string

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiFindLoans
     */
    readonly disbursementPaymentAccountNumber?: string

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiFindLoans
     */
    readonly civilId?: string
}

/**
 * Request parameters for getAdditionalLoanIssuingPrecondition operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetAdditionalLoanIssuingPreconditionRequest
 */
export interface LoanControllerApiGetAdditionalLoanIssuingPreconditionRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetAdditionalLoanIssuingPrecondition
     */
    readonly id: number
}

/**
 * Request parameters for getAdditionalLoanIssuingProposal operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetAdditionalLoanIssuingProposalRequest
 */
export interface LoanControllerApiGetAdditionalLoanIssuingProposalRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetAdditionalLoanIssuingProposal
     */
    readonly id: number
}

/**
 * Request parameters for getGracePeriod operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetGracePeriodRequest
 */
export interface LoanControllerApiGetGracePeriodRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetGracePeriod
     */
    readonly id: number
}

/**
 * Request parameters for getInterestAdjustmentPrecondition operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetInterestAdjustmentPreconditionRequest
 */
export interface LoanControllerApiGetInterestAdjustmentPreconditionRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetInterestAdjustmentPrecondition
     */
    readonly id: number
}

/**
 * Request parameters for getInterestAdjustmentProposal operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetInterestAdjustmentProposalRequest
 */
export interface LoanControllerApiGetInterestAdjustmentProposalRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetInterestAdjustmentProposal
     */
    readonly id: number
}

/**
 * Request parameters for getInterestRateChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetInterestRateChangingRequest
 */
export interface LoanControllerApiGetInterestRateChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetInterestRateChanging
     */
    readonly id: number
}

/**
 * Request parameters for getLoan operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetLoanRequest
 */
export interface LoanControllerApiGetLoanRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetLoan
     */
    readonly id: number
}

/**
 * Request parameters for getManualLoanStatuses operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetManualLoanStatusesRequest
 */
export interface LoanControllerApiGetManualLoanStatusesRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetManualLoanStatuses
     */
    readonly id: number
}

/**
 * Request parameters for getMaturityDateExtension operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetMaturityDateExtensionRequest
 */
export interface LoanControllerApiGetMaturityDateExtensionRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetMaturityDateExtension
     */
    readonly id: number
}

/**
 * Request parameters for getPastDatePayment operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetPastDatePaymentRequest
 */
export interface LoanControllerApiGetPastDatePaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetPastDatePayment
     */
    readonly id: number
}

/**
 * Request parameters for getPaymentAmountChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetPaymentAmountChangingRequest
 */
export interface LoanControllerApiGetPaymentAmountChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetPaymentAmountChanging
     */
    readonly id: number
}

/**
 * Request parameters for getPaymentDateChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetPaymentDateChangingRequest
 */
export interface LoanControllerApiGetPaymentDateChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetPaymentDateChanging
     */
    readonly id: number
}

/**
 * Request parameters for getPaymentSchedule operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetPaymentScheduleRequest
 */
export interface LoanControllerApiGetPaymentScheduleRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetPaymentSchedule
     */
    readonly id: number
}

/**
 * Request parameters for getPenaltyAdjustmentPrecondition operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetPenaltyAdjustmentPreconditionRequest
 */
export interface LoanControllerApiGetPenaltyAdjustmentPreconditionRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetPenaltyAdjustmentPrecondition
     */
    readonly id: number
}

/**
 * Request parameters for getPenaltyAdjustmentProposal operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetPenaltyAdjustmentProposalRequest
 */
export interface LoanControllerApiGetPenaltyAdjustmentProposalRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetPenaltyAdjustmentProposal
     */
    readonly id: number
}

/**
 * Request parameters for getProposeInterestAccrualStopRenew operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetProposeInterestAccrualStopRenewRequest
 */
export interface LoanControllerApiGetProposeInterestAccrualStopRenewRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetProposeInterestAccrualStopRenew
     */
    readonly id: number
}

/**
 * Request parameters for getProposePaymentCancellation operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetProposePaymentCancellationRequest
 */
export interface LoanControllerApiGetProposePaymentCancellationRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetProposePaymentCancellation
     */
    readonly id: number
}

/**
 * Request parameters for getRefinancing operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetRefinancingRequest
 */
export interface LoanControllerApiGetRefinancingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetRefinancing
     */
    readonly id: number
}

/**
 * Request parameters for getReviewGracePeriod operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetReviewGracePeriodRequest
 */
export interface LoanControllerApiGetReviewGracePeriodRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetReviewGracePeriod
     */
    readonly id: number
}

/**
 * Request parameters for getReviewInterestAccrualStopRenew operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetReviewInterestAccrualStopRenewRequest
 */
export interface LoanControllerApiGetReviewInterestAccrualStopRenewRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetReviewInterestAccrualStopRenew
     */
    readonly id: number
}

/**
 * Request parameters for getReviewInterestRateChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetReviewInterestRateChangingRequest
 */
export interface LoanControllerApiGetReviewInterestRateChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetReviewInterestRateChanging
     */
    readonly id: number
}

/**
 * Request parameters for getReviewMaturityDateExtension operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetReviewMaturityDateExtensionRequest
 */
export interface LoanControllerApiGetReviewMaturityDateExtensionRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetReviewMaturityDateExtension
     */
    readonly id: number
}

/**
 * Request parameters for getReviewNewGracePeriodAllowing operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetReviewNewGracePeriodAllowingRequest
 */
export interface LoanControllerApiGetReviewNewGracePeriodAllowingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetReviewNewGracePeriodAllowing
     */
    readonly id: number
}

/**
 * Request parameters for getReviewPastDatePayment operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetReviewPastDatePaymentRequest
 */
export interface LoanControllerApiGetReviewPastDatePaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetReviewPastDatePayment
     */
    readonly id: number
}

/**
 * Request parameters for getReviewPaymentCancellation operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetReviewPaymentCancellationRequest
 */
export interface LoanControllerApiGetReviewPaymentCancellationRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetReviewPaymentCancellation
     */
    readonly id: number
}

/**
 * Request parameters for getReviewRefinancing operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetReviewRefinancingRequest
 */
export interface LoanControllerApiGetReviewRefinancingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetReviewRefinancing
     */
    readonly id: number
}

/**
 * Request parameters for getReviewRepaymentAmountChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetReviewRepaymentAmountChangingRequest
 */
export interface LoanControllerApiGetReviewRepaymentAmountChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetReviewRepaymentAmountChanging
     */
    readonly id: number
}

/**
 * Request parameters for getReviewRepaymentDateChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetReviewRepaymentDateChangingRequest
 */
export interface LoanControllerApiGetReviewRepaymentDateChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetReviewRepaymentDateChanging
     */
    readonly id: number
}

/**
 * Request parameters for getReviewWriteOff operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetReviewWriteOffRequest
 */
export interface LoanControllerApiGetReviewWriteOffRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetReviewWriteOff
     */
    readonly id: number
}

/**
 * Request parameters for patchLoan operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiPatchLoanRequest
 */
export interface LoanControllerApiPatchLoanRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiPatchLoan
     */
    readonly id: number

    /**
     * 
     * @type {PatchLoanRequest}
     * @memberof LoanControllerApiPatchLoan
     */
    readonly patchLoanRequest: PatchLoanRequest
}

/**
 * Request parameters for proposeAdditionalLoanIssuing operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposeAdditionalLoanIssuingRequest
 */
export interface LoanControllerApiProposeAdditionalLoanIssuingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposeAdditionalLoanIssuing
     */
    readonly id: number

    /**
     * 
     * @type {NewAdditionalLoanIssuingProposal}
     * @memberof LoanControllerApiProposeAdditionalLoanIssuing
     */
    readonly newAdditionalLoanIssuingProposal: NewAdditionalLoanIssuingProposal
}

/**
 * Request parameters for proposeGracePeriod operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposeGracePeriodRequest
 */
export interface LoanControllerApiProposeGracePeriodRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposeGracePeriod
     */
    readonly id: number

    /**
     * 
     * @type {LoanGracePeriodProposal}
     * @memberof LoanControllerApiProposeGracePeriod
     */
    readonly loanGracePeriodProposal: LoanGracePeriodProposal
}

/**
 * Request parameters for proposeInterestAccrualStopRenew operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposeInterestAccrualStopRenewRequest
 */
export interface LoanControllerApiProposeInterestAccrualStopRenewRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposeInterestAccrualStopRenew
     */
    readonly id: number
}

/**
 * Request parameters for proposeInterestAdjustment operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposeInterestAdjustmentRequest
 */
export interface LoanControllerApiProposeInterestAdjustmentRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposeInterestAdjustment
     */
    readonly id: number

    /**
     * 
     * @type {LoanInterestAdjustment}
     * @memberof LoanControllerApiProposeInterestAdjustment
     */
    readonly loanInterestAdjustment: LoanInterestAdjustment
}

/**
 * Request parameters for proposeInterestRateChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposeInterestRateChangingRequest
 */
export interface LoanControllerApiProposeInterestRateChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposeInterestRateChanging
     */
    readonly id: number

    /**
     * 
     * @type {LoanInterestRateChangingProposal}
     * @memberof LoanControllerApiProposeInterestRateChanging
     */
    readonly loanInterestRateChangingProposal: LoanInterestRateChangingProposal
}

/**
 * Request parameters for proposeMaturityDateExtension operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposeMaturityDateExtensionRequest
 */
export interface LoanControllerApiProposeMaturityDateExtensionRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposeMaturityDateExtension
     */
    readonly id: number

    /**
     * 
     * @type {LoanMaturityDateExtensionProposal}
     * @memberof LoanControllerApiProposeMaturityDateExtension
     */
    readonly loanMaturityDateExtensionProposal: LoanMaturityDateExtensionProposal
}

/**
 * Request parameters for proposeNewGracePeriodAllowing operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposeNewGracePeriodAllowingRequest
 */
export interface LoanControllerApiProposeNewGracePeriodAllowingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposeNewGracePeriodAllowing
     */
    readonly id: number
}

/**
 * Request parameters for proposePastDatePayment operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposePastDatePaymentRequest
 */
export interface LoanControllerApiProposePastDatePaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposePastDatePayment
     */
    readonly id: number

    /**
     * 
     * @type {LoanPastDatePaymentProposal}
     * @memberof LoanControllerApiProposePastDatePayment
     */
    readonly loanPastDatePaymentProposal: LoanPastDatePaymentProposal
}

/**
 * Request parameters for proposePaymentAmountChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposePaymentAmountChangingRequest
 */
export interface LoanControllerApiProposePaymentAmountChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposePaymentAmountChanging
     */
    readonly id: number

    /**
     * 
     * @type {LoanPaymentAmountChangingProposal}
     * @memberof LoanControllerApiProposePaymentAmountChanging
     */
    readonly loanPaymentAmountChangingProposal: LoanPaymentAmountChangingProposal
}

/**
 * Request parameters for proposePaymentCancellation operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposePaymentCancellationRequest
 */
export interface LoanControllerApiProposePaymentCancellationRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposePaymentCancellation
     */
    readonly id: number

    /**
     * 
     * @type {LoanPaymentCancellationProposal}
     * @memberof LoanControllerApiProposePaymentCancellation
     */
    readonly loanPaymentCancellationProposal: LoanPaymentCancellationProposal
}

/**
 * Request parameters for proposePaymentDateChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposePaymentDateChangingRequest
 */
export interface LoanControllerApiProposePaymentDateChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposePaymentDateChanging
     */
    readonly id: number

    /**
     * 
     * @type {LoanPaymentDateChangingProposal}
     * @memberof LoanControllerApiProposePaymentDateChanging
     */
    readonly loanPaymentDateChangingProposal: LoanPaymentDateChangingProposal
}

/**
 * Request parameters for proposePenaltyAdjustment operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposePenaltyAdjustmentRequest
 */
export interface LoanControllerApiProposePenaltyAdjustmentRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposePenaltyAdjustment
     */
    readonly id: number

    /**
     * 
     * @type {LoanPenaltyAdjustment}
     * @memberof LoanControllerApiProposePenaltyAdjustment
     */
    readonly loanPenaltyAdjustment: LoanPenaltyAdjustment
}

/**
 * Request parameters for proposeRefinancing operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposeRefinancingRequest
 */
export interface LoanControllerApiProposeRefinancingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposeRefinancing
     */
    readonly id: number

    /**
     * 
     * @type {LoanRefinancingProposal}
     * @memberof LoanControllerApiProposeRefinancing
     */
    readonly loanRefinancingProposal: LoanRefinancingProposal
}

/**
 * Request parameters for proposeWriteOff operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiProposeWriteOffRequest
 */
export interface LoanControllerApiProposeWriteOffRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiProposeWriteOff
     */
    readonly id: number
}

/**
 * Request parameters for reviewAdditionalLoanIssuingProposal operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewAdditionalLoanIssuingProposalRequest
 */
export interface LoanControllerApiReviewAdditionalLoanIssuingProposalRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewAdditionalLoanIssuingProposal
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewAdditionalLoanIssuingProposal
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewGracePeriod operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewGracePeriodRequest
 */
export interface LoanControllerApiReviewGracePeriodRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewGracePeriod
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewGracePeriod
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewInterestAccrualStopRenew operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewInterestAccrualStopRenewRequest
 */
export interface LoanControllerApiReviewInterestAccrualStopRenewRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewInterestAccrualStopRenew
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewInterestAccrualStopRenew
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewInterestAdjustmentProposal operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewInterestAdjustmentProposalRequest
 */
export interface LoanControllerApiReviewInterestAdjustmentProposalRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewInterestAdjustmentProposal
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewInterestAdjustmentProposal
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewInterestRateChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewInterestRateChangingRequest
 */
export interface LoanControllerApiReviewInterestRateChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewInterestRateChanging
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewInterestRateChanging
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewMaturityDateExtension operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewMaturityDateExtensionRequest
 */
export interface LoanControllerApiReviewMaturityDateExtensionRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewMaturityDateExtension
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewMaturityDateExtension
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewNewGracePeriodAllowing operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewNewGracePeriodAllowingRequest
 */
export interface LoanControllerApiReviewNewGracePeriodAllowingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewNewGracePeriodAllowing
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewNewGracePeriodAllowing
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewPastDatePayment operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewPastDatePaymentRequest
 */
export interface LoanControllerApiReviewPastDatePaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewPastDatePayment
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewPastDatePayment
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewPaymentAmountChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewPaymentAmountChangingRequest
 */
export interface LoanControllerApiReviewPaymentAmountChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewPaymentAmountChanging
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewPaymentAmountChanging
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewPaymentCancellation operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewPaymentCancellationRequest
 */
export interface LoanControllerApiReviewPaymentCancellationRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewPaymentCancellation
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewPaymentCancellation
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewPaymentDateChanging operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewPaymentDateChangingRequest
 */
export interface LoanControllerApiReviewPaymentDateChangingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewPaymentDateChanging
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewPaymentDateChanging
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewPenaltyAdjustmentProposal operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewPenaltyAdjustmentProposalRequest
 */
export interface LoanControllerApiReviewPenaltyAdjustmentProposalRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewPenaltyAdjustmentProposal
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewPenaltyAdjustmentProposal
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewRefinancing operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewRefinancingRequest
 */
export interface LoanControllerApiReviewRefinancingRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewRefinancing
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewRefinancing
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * Request parameters for reviewWriteOff operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiReviewWriteOffRequest
 */
export interface LoanControllerApiReviewWriteOffRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiReviewWriteOff
     */
    readonly id: number

    /**
     * 
     * @type {RestructuringReviewRequest}
     * @memberof LoanControllerApiReviewWriteOff
     */
    readonly restructuringReviewRequest: RestructuringReviewRequest
}

/**
 * LoanControllerApi - object-oriented interface
 * @export
 * @class LoanControllerApi
 * @extends {BaseAPI}
 */
export class LoanControllerApi extends BaseAPI {
    /**
     * 
     * @param {LoanControllerApiCalculateLoanStateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public calculateLoanState(requestParameters: LoanControllerApiCalculateLoanStateRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).calculateLoanState(requestParameters.id, requestParameters.request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiDisableBorrowerDeathStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public disableBorrowerDeathStatus(requestParameters: LoanControllerApiDisableBorrowerDeathStatusRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).disableBorrowerDeathStatus(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiDisableLawsuitStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public disableLawsuitStatus(requestParameters: LoanControllerApiDisableLawsuitStatusRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).disableLawsuitStatus(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiDisableManualLoanStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public disableManualLoanStatus(requestParameters: LoanControllerApiDisableManualLoanStatusRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).disableManualLoanStatus(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiEnableBorrowerDeathStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public enableBorrowerDeathStatus(requestParameters: LoanControllerApiEnableBorrowerDeathStatusRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).enableBorrowerDeathStatus(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiEnableLawsuitStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public enableLawsuitStatus(requestParameters: LoanControllerApiEnableLawsuitStatusRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).enableLawsuitStatus(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiEnableManualLoanStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public enableManualLoanStatus(requestParameters: LoanControllerApiEnableManualLoanStatusRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).enableManualLoanStatus(requestParameters.id, requestParameters.manualLoanStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiFindLoansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public findLoans(requestParameters: LoanControllerApiFindLoansRequest = {}, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).findLoans(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.externalId, requestParameters.productTypes, requestParameters.customerName, requestParameters.relationshipManagerId, requestParameters.branchCode, requestParameters.loanStatuses, requestParameters.disbursementDateFrom, requestParameters.disbursementDateTo, requestParameters.customerRegistrationNumber, requestParameters.phone, requestParameters.disbursementPaymentAccountNumber, requestParameters.civilId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetAdditionalLoanIssuingPreconditionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getAdditionalLoanIssuingPrecondition(requestParameters: LoanControllerApiGetAdditionalLoanIssuingPreconditionRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getAdditionalLoanIssuingPrecondition(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetAdditionalLoanIssuingProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getAdditionalLoanIssuingProposal(requestParameters: LoanControllerApiGetAdditionalLoanIssuingProposalRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getAdditionalLoanIssuingProposal(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetGracePeriodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getGracePeriod(requestParameters: LoanControllerApiGetGracePeriodRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getGracePeriod(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetInterestAdjustmentPreconditionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getInterestAdjustmentPrecondition(requestParameters: LoanControllerApiGetInterestAdjustmentPreconditionRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getInterestAdjustmentPrecondition(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetInterestAdjustmentProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getInterestAdjustmentProposal(requestParameters: LoanControllerApiGetInterestAdjustmentProposalRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getInterestAdjustmentProposal(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetInterestRateChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getInterestRateChanging(requestParameters: LoanControllerApiGetInterestRateChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getInterestRateChanging(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetLoanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getLoan(requestParameters: LoanControllerApiGetLoanRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getLoan(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetManualLoanStatusesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getManualLoanStatuses(requestParameters: LoanControllerApiGetManualLoanStatusesRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getManualLoanStatuses(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetMaturityDateExtensionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getMaturityDateExtension(requestParameters: LoanControllerApiGetMaturityDateExtensionRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getMaturityDateExtension(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetPastDatePaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getPastDatePayment(requestParameters: LoanControllerApiGetPastDatePaymentRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getPastDatePayment(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetPaymentAmountChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getPaymentAmountChanging(requestParameters: LoanControllerApiGetPaymentAmountChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getPaymentAmountChanging(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetPaymentDateChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getPaymentDateChanging(requestParameters: LoanControllerApiGetPaymentDateChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getPaymentDateChanging(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetPaymentScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getPaymentSchedule(requestParameters: LoanControllerApiGetPaymentScheduleRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getPaymentSchedule(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetPenaltyAdjustmentPreconditionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getPenaltyAdjustmentPrecondition(requestParameters: LoanControllerApiGetPenaltyAdjustmentPreconditionRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getPenaltyAdjustmentPrecondition(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetPenaltyAdjustmentProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getPenaltyAdjustmentProposal(requestParameters: LoanControllerApiGetPenaltyAdjustmentProposalRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getPenaltyAdjustmentProposal(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetProposeInterestAccrualStopRenewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getProposeInterestAccrualStopRenew(requestParameters: LoanControllerApiGetProposeInterestAccrualStopRenewRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getProposeInterestAccrualStopRenew(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetProposePaymentCancellationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getProposePaymentCancellation(requestParameters: LoanControllerApiGetProposePaymentCancellationRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getProposePaymentCancellation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetRefinancingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getRefinancing(requestParameters: LoanControllerApiGetRefinancingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getRefinancing(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetReviewGracePeriodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getReviewGracePeriod(requestParameters: LoanControllerApiGetReviewGracePeriodRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getReviewGracePeriod(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetReviewInterestAccrualStopRenewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getReviewInterestAccrualStopRenew(requestParameters: LoanControllerApiGetReviewInterestAccrualStopRenewRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getReviewInterestAccrualStopRenew(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetReviewInterestRateChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getReviewInterestRateChanging(requestParameters: LoanControllerApiGetReviewInterestRateChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getReviewInterestRateChanging(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetReviewMaturityDateExtensionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getReviewMaturityDateExtension(requestParameters: LoanControllerApiGetReviewMaturityDateExtensionRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getReviewMaturityDateExtension(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetReviewNewGracePeriodAllowingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getReviewNewGracePeriodAllowing(requestParameters: LoanControllerApiGetReviewNewGracePeriodAllowingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getReviewNewGracePeriodAllowing(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetReviewPastDatePaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getReviewPastDatePayment(requestParameters: LoanControllerApiGetReviewPastDatePaymentRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getReviewPastDatePayment(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetReviewPaymentCancellationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getReviewPaymentCancellation(requestParameters: LoanControllerApiGetReviewPaymentCancellationRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getReviewPaymentCancellation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetReviewRefinancingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getReviewRefinancing(requestParameters: LoanControllerApiGetReviewRefinancingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getReviewRefinancing(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetReviewRepaymentAmountChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getReviewRepaymentAmountChanging(requestParameters: LoanControllerApiGetReviewRepaymentAmountChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getReviewRepaymentAmountChanging(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetReviewRepaymentDateChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getReviewRepaymentDateChanging(requestParameters: LoanControllerApiGetReviewRepaymentDateChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getReviewRepaymentDateChanging(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetReviewWriteOffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getReviewWriteOff(requestParameters: LoanControllerApiGetReviewWriteOffRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).getReviewWriteOff(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiPatchLoanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public patchLoan(requestParameters: LoanControllerApiPatchLoanRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).patchLoan(requestParameters.id, requestParameters.patchLoanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposeAdditionalLoanIssuingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposeAdditionalLoanIssuing(requestParameters: LoanControllerApiProposeAdditionalLoanIssuingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposeAdditionalLoanIssuing(requestParameters.id, requestParameters.newAdditionalLoanIssuingProposal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposeGracePeriodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposeGracePeriod(requestParameters: LoanControllerApiProposeGracePeriodRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposeGracePeriod(requestParameters.id, requestParameters.loanGracePeriodProposal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposeInterestAccrualStopRenewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposeInterestAccrualStopRenew(requestParameters: LoanControllerApiProposeInterestAccrualStopRenewRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposeInterestAccrualStopRenew(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposeInterestAdjustmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposeInterestAdjustment(requestParameters: LoanControllerApiProposeInterestAdjustmentRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposeInterestAdjustment(requestParameters.id, requestParameters.loanInterestAdjustment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposeInterestRateChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposeInterestRateChanging(requestParameters: LoanControllerApiProposeInterestRateChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposeInterestRateChanging(requestParameters.id, requestParameters.loanInterestRateChangingProposal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposeMaturityDateExtensionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposeMaturityDateExtension(requestParameters: LoanControllerApiProposeMaturityDateExtensionRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposeMaturityDateExtension(requestParameters.id, requestParameters.loanMaturityDateExtensionProposal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposeNewGracePeriodAllowingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposeNewGracePeriodAllowing(requestParameters: LoanControllerApiProposeNewGracePeriodAllowingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposeNewGracePeriodAllowing(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposePastDatePaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposePastDatePayment(requestParameters: LoanControllerApiProposePastDatePaymentRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposePastDatePayment(requestParameters.id, requestParameters.loanPastDatePaymentProposal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposePaymentAmountChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposePaymentAmountChanging(requestParameters: LoanControllerApiProposePaymentAmountChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposePaymentAmountChanging(requestParameters.id, requestParameters.loanPaymentAmountChangingProposal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposePaymentCancellationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposePaymentCancellation(requestParameters: LoanControllerApiProposePaymentCancellationRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposePaymentCancellation(requestParameters.id, requestParameters.loanPaymentCancellationProposal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposePaymentDateChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposePaymentDateChanging(requestParameters: LoanControllerApiProposePaymentDateChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposePaymentDateChanging(requestParameters.id, requestParameters.loanPaymentDateChangingProposal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposePenaltyAdjustmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposePenaltyAdjustment(requestParameters: LoanControllerApiProposePenaltyAdjustmentRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposePenaltyAdjustment(requestParameters.id, requestParameters.loanPenaltyAdjustment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposeRefinancingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposeRefinancing(requestParameters: LoanControllerApiProposeRefinancingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposeRefinancing(requestParameters.id, requestParameters.loanRefinancingProposal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiProposeWriteOffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public proposeWriteOff(requestParameters: LoanControllerApiProposeWriteOffRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).proposeWriteOff(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewAdditionalLoanIssuingProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewAdditionalLoanIssuingProposal(requestParameters: LoanControllerApiReviewAdditionalLoanIssuingProposalRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewAdditionalLoanIssuingProposal(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewGracePeriodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewGracePeriod(requestParameters: LoanControllerApiReviewGracePeriodRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewGracePeriod(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewInterestAccrualStopRenewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewInterestAccrualStopRenew(requestParameters: LoanControllerApiReviewInterestAccrualStopRenewRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewInterestAccrualStopRenew(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewInterestAdjustmentProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewInterestAdjustmentProposal(requestParameters: LoanControllerApiReviewInterestAdjustmentProposalRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewInterestAdjustmentProposal(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewInterestRateChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewInterestRateChanging(requestParameters: LoanControllerApiReviewInterestRateChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewInterestRateChanging(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewMaturityDateExtensionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewMaturityDateExtension(requestParameters: LoanControllerApiReviewMaturityDateExtensionRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewMaturityDateExtension(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewNewGracePeriodAllowingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewNewGracePeriodAllowing(requestParameters: LoanControllerApiReviewNewGracePeriodAllowingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewNewGracePeriodAllowing(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewPastDatePaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewPastDatePayment(requestParameters: LoanControllerApiReviewPastDatePaymentRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewPastDatePayment(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewPaymentAmountChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewPaymentAmountChanging(requestParameters: LoanControllerApiReviewPaymentAmountChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewPaymentAmountChanging(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewPaymentCancellationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewPaymentCancellation(requestParameters: LoanControllerApiReviewPaymentCancellationRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewPaymentCancellation(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewPaymentDateChangingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewPaymentDateChanging(requestParameters: LoanControllerApiReviewPaymentDateChangingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewPaymentDateChanging(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewPenaltyAdjustmentProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewPenaltyAdjustmentProposal(requestParameters: LoanControllerApiReviewPenaltyAdjustmentProposalRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewPenaltyAdjustmentProposal(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewRefinancingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewRefinancing(requestParameters: LoanControllerApiReviewRefinancingRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewRefinancing(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiReviewWriteOffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public reviewWriteOff(requestParameters: LoanControllerApiReviewWriteOffRequest, options?: AxiosRequestConfig) {
        return LoanControllerApiFp(this.configuration).reviewWriteOff(requestParameters.id, requestParameters.restructuringReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ManagersControllerApi - axios parameter creator
 * @export
 */
export const ManagersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndividualsByManagerId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIndividualsByManagerId', 'id', id)
            const localVarPath = `/api/managers/{id}/individuals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanApplicationsByManagerId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLoanApplicationsByManagerId', 'id', id)
            const localVarPath = `/api/managers/{id}/loan-applications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoansByManagerId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLoansByManagerId', 'id', id)
            const localVarPath = `/api/managers/{id}/loans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {string} [lastName] 
         * @param {string} [branchCode] 
         * @param {string} [phone] 
         * @param {number} [loanId] 
         * @param {number} [loanApplicationId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagers: async (page?: number, size?: number, sort?: Array<string>, id?: number, lastName?: string, branchCode?: string, phone?: string, loanId?: number, loanApplicationId?: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (branchCode !== undefined) {
                localVarQueryParameter['branchCode'] = branchCode;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (loanId !== undefined) {
                localVarQueryParameter['loanId'] = loanId;
            }

            if (loanApplicationId !== undefined) {
                localVarQueryParameter['loanApplicationId'] = loanApplicationId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagersControllerApi - functional programming interface
 * @export
 */
export const ManagersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndividualsByManagerId(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageIndividualListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndividualsByManagerId(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanApplicationsByManagerId(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanApplicationsByManagerId(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoansByManagerId(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoansByManagerId(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {string} [lastName] 
         * @param {string} [branchCode] 
         * @param {string} [phone] 
         * @param {number} [loanId] 
         * @param {number} [loanApplicationId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManagers(page?: number, size?: number, sort?: Array<string>, id?: number, lastName?: string, branchCode?: string, phone?: string, loanId?: number, loanApplicationId?: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageManagersListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManagers(page, size, sort, id, lastName, branchCode, phone, loanId, loanApplicationId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManagersControllerApi - factory interface
 * @export
 */
export const ManagersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ManagersControllerApiGetIndividualsByManagerIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndividualsByManagerId(requestParameters: ManagersControllerApiGetIndividualsByManagerIdRequest, options?: AxiosRequestConfig): AxiosPromise<PageIndividualListEntry> {
            return localVarFp.getIndividualsByManagerId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManagersControllerApiGetLoanApplicationsByManagerIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanApplicationsByManagerId(requestParameters: ManagersControllerApiGetLoanApplicationsByManagerIdRequest, options?: AxiosRequestConfig): AxiosPromise<PageLoanApplicationListEntry> {
            return localVarFp.getLoanApplicationsByManagerId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManagersControllerApiGetLoansByManagerIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoansByManagerId(requestParameters: ManagersControllerApiGetLoansByManagerIdRequest, options?: AxiosRequestConfig): AxiosPromise<PageLoanListEntry> {
            return localVarFp.getLoansByManagerId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManagersControllerApiGetManagersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagers(requestParameters: ManagersControllerApiGetManagersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageManagersListEntry> {
            return localVarFp.getManagers(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.lastName, requestParameters.branchCode, requestParameters.phone, requestParameters.loanId, requestParameters.loanApplicationId, requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getIndividualsByManagerId operation in ManagersControllerApi.
 * @export
 * @interface ManagersControllerApiGetIndividualsByManagerIdRequest
 */
export interface ManagersControllerApiGetIndividualsByManagerIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ManagersControllerApiGetIndividualsByManagerId
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ManagersControllerApiGetIndividualsByManagerId
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ManagersControllerApiGetIndividualsByManagerId
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ManagersControllerApiGetIndividualsByManagerId
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getLoanApplicationsByManagerId operation in ManagersControllerApi.
 * @export
 * @interface ManagersControllerApiGetLoanApplicationsByManagerIdRequest
 */
export interface ManagersControllerApiGetLoanApplicationsByManagerIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ManagersControllerApiGetLoanApplicationsByManagerId
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ManagersControllerApiGetLoanApplicationsByManagerId
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ManagersControllerApiGetLoanApplicationsByManagerId
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ManagersControllerApiGetLoanApplicationsByManagerId
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getLoansByManagerId operation in ManagersControllerApi.
 * @export
 * @interface ManagersControllerApiGetLoansByManagerIdRequest
 */
export interface ManagersControllerApiGetLoansByManagerIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ManagersControllerApiGetLoansByManagerId
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ManagersControllerApiGetLoansByManagerId
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ManagersControllerApiGetLoansByManagerId
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ManagersControllerApiGetLoansByManagerId
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getManagers operation in ManagersControllerApi.
 * @export
 * @interface ManagersControllerApiGetManagersRequest
 */
export interface ManagersControllerApiGetManagersRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ManagersControllerApiGetManagers
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ManagersControllerApiGetManagers
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ManagersControllerApiGetManagers
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof ManagersControllerApiGetManagers
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof ManagersControllerApiGetManagers
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof ManagersControllerApiGetManagers
     */
    readonly branchCode?: string

    /**
     * 
     * @type {string}
     * @memberof ManagersControllerApiGetManagers
     */
    readonly phone?: string

    /**
     * 
     * @type {number}
     * @memberof ManagersControllerApiGetManagers
     */
    readonly loanId?: number

    /**
     * 
     * @type {number}
     * @memberof ManagersControllerApiGetManagers
     */
    readonly loanApplicationId?: number

    /**
     * 
     * @type {number}
     * @memberof ManagersControllerApiGetManagers
     */
    readonly customerId?: number
}

/**
 * ManagersControllerApi - object-oriented interface
 * @export
 * @class ManagersControllerApi
 * @extends {BaseAPI}
 */
export class ManagersControllerApi extends BaseAPI {
    /**
     * 
     * @param {ManagersControllerApiGetIndividualsByManagerIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersControllerApi
     */
    public getIndividualsByManagerId(requestParameters: ManagersControllerApiGetIndividualsByManagerIdRequest, options?: AxiosRequestConfig) {
        return ManagersControllerApiFp(this.configuration).getIndividualsByManagerId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManagersControllerApiGetLoanApplicationsByManagerIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersControllerApi
     */
    public getLoanApplicationsByManagerId(requestParameters: ManagersControllerApiGetLoanApplicationsByManagerIdRequest, options?: AxiosRequestConfig) {
        return ManagersControllerApiFp(this.configuration).getLoanApplicationsByManagerId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManagersControllerApiGetLoansByManagerIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersControllerApi
     */
    public getLoansByManagerId(requestParameters: ManagersControllerApiGetLoansByManagerIdRequest, options?: AxiosRequestConfig) {
        return ManagersControllerApiFp(this.configuration).getLoansByManagerId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManagersControllerApiGetManagersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersControllerApi
     */
    public getManagers(requestParameters: ManagersControllerApiGetManagersRequest = {}, options?: AxiosRequestConfig) {
        return ManagersControllerApiFp(this.configuration).getManagers(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.lastName, requestParameters.branchCode, requestParameters.phone, requestParameters.loanId, requestParameters.loanApplicationId, requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MerchantControllerApi - axios parameter creator
 * @export
 */
export const MerchantControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateMerchantForm} createMerchantForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMerchant: async (createMerchantForm: CreateMerchantForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMerchantForm' is not null or undefined
            assertParamExists('createMerchant', 'createMerchantForm', createMerchantForm)
            const localVarPath = `/api/merchants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMerchantForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EditMerchantForm} editMerchantForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editMerchant: async (id: number, editMerchantForm: EditMerchantForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editMerchant', 'id', id)
            // verify required parameter 'editMerchantForm' is not null or undefined
            assertParamExists('editMerchant', 'editMerchantForm', editMerchantForm)
            const localVarPath = `/api/merchants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editMerchantForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchant: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMerchant', 'id', id)
            const localVarPath = `/api/merchants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [surname] 
         * @param {string} [phone] 
         * @param {string} [email] 
         * @param {Array<MerchantCompany>} [companies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchants: async (page?: number, size?: number, sort?: Array<string>, surname?: string, phone?: string, email?: string, companies?: Array<MerchantCompany>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/merchants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (surname !== undefined) {
                localVarQueryParameter['surname'] = surname;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantControllerApi - functional programming interface
 * @export
 */
export const MerchantControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MerchantControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateMerchantForm} createMerchantForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMerchant(createMerchantForm: CreateMerchantForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMerchant(createMerchantForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EditMerchantForm} editMerchantForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editMerchant(id: number, editMerchantForm: EditMerchantForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editMerchant(id, editMerchantForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMerchant(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Merchant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMerchant(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [surname] 
         * @param {string} [phone] 
         * @param {string} [email] 
         * @param {Array<MerchantCompany>} [companies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMerchants(page?: number, size?: number, sort?: Array<string>, surname?: string, phone?: string, email?: string, companies?: Array<MerchantCompany>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMerchantListItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMerchants(page, size, sort, surname, phone, email, companies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MerchantControllerApi - factory interface
 * @export
 */
export const MerchantControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MerchantControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MerchantControllerApiCreateMerchantRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMerchant(requestParameters: MerchantControllerApiCreateMerchantRequest, options?: AxiosRequestConfig): AxiosPromise<IdEntry> {
            return localVarFp.createMerchant(requestParameters.createMerchantForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MerchantControllerApiEditMerchantRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editMerchant(requestParameters: MerchantControllerApiEditMerchantRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.editMerchant(requestParameters.id, requestParameters.editMerchantForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MerchantControllerApiGetMerchantRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchant(requestParameters: MerchantControllerApiGetMerchantRequest, options?: AxiosRequestConfig): AxiosPromise<Merchant> {
            return localVarFp.getMerchant(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MerchantControllerApiGetMerchantsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchants(requestParameters: MerchantControllerApiGetMerchantsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageMerchantListItem> {
            return localVarFp.getMerchants(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.surname, requestParameters.phone, requestParameters.email, requestParameters.companies, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createMerchant operation in MerchantControllerApi.
 * @export
 * @interface MerchantControllerApiCreateMerchantRequest
 */
export interface MerchantControllerApiCreateMerchantRequest {
    /**
     * 
     * @type {CreateMerchantForm}
     * @memberof MerchantControllerApiCreateMerchant
     */
    readonly createMerchantForm: CreateMerchantForm
}

/**
 * Request parameters for editMerchant operation in MerchantControllerApi.
 * @export
 * @interface MerchantControllerApiEditMerchantRequest
 */
export interface MerchantControllerApiEditMerchantRequest {
    /**
     * 
     * @type {number}
     * @memberof MerchantControllerApiEditMerchant
     */
    readonly id: number

    /**
     * 
     * @type {EditMerchantForm}
     * @memberof MerchantControllerApiEditMerchant
     */
    readonly editMerchantForm: EditMerchantForm
}

/**
 * Request parameters for getMerchant operation in MerchantControllerApi.
 * @export
 * @interface MerchantControllerApiGetMerchantRequest
 */
export interface MerchantControllerApiGetMerchantRequest {
    /**
     * 
     * @type {number}
     * @memberof MerchantControllerApiGetMerchant
     */
    readonly id: number
}

/**
 * Request parameters for getMerchants operation in MerchantControllerApi.
 * @export
 * @interface MerchantControllerApiGetMerchantsRequest
 */
export interface MerchantControllerApiGetMerchantsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof MerchantControllerApiGetMerchants
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof MerchantControllerApiGetMerchants
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof MerchantControllerApiGetMerchants
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof MerchantControllerApiGetMerchants
     */
    readonly surname?: string

    /**
     * 
     * @type {string}
     * @memberof MerchantControllerApiGetMerchants
     */
    readonly phone?: string

    /**
     * 
     * @type {string}
     * @memberof MerchantControllerApiGetMerchants
     */
    readonly email?: string

    /**
     * 
     * @type {Array<MerchantCompany>}
     * @memberof MerchantControllerApiGetMerchants
     */
    readonly companies?: Array<MerchantCompany>
}

/**
 * MerchantControllerApi - object-oriented interface
 * @export
 * @class MerchantControllerApi
 * @extends {BaseAPI}
 */
export class MerchantControllerApi extends BaseAPI {
    /**
     * 
     * @param {MerchantControllerApiCreateMerchantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantControllerApi
     */
    public createMerchant(requestParameters: MerchantControllerApiCreateMerchantRequest, options?: AxiosRequestConfig) {
        return MerchantControllerApiFp(this.configuration).createMerchant(requestParameters.createMerchantForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MerchantControllerApiEditMerchantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantControllerApi
     */
    public editMerchant(requestParameters: MerchantControllerApiEditMerchantRequest, options?: AxiosRequestConfig) {
        return MerchantControllerApiFp(this.configuration).editMerchant(requestParameters.id, requestParameters.editMerchantForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MerchantControllerApiGetMerchantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantControllerApi
     */
    public getMerchant(requestParameters: MerchantControllerApiGetMerchantRequest, options?: AxiosRequestConfig) {
        return MerchantControllerApiFp(this.configuration).getMerchant(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MerchantControllerApiGetMerchantsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantControllerApi
     */
    public getMerchants(requestParameters: MerchantControllerApiGetMerchantsRequest = {}, options?: AxiosRequestConfig) {
        return MerchantControllerApiFp(this.configuration).getMerchants(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.surname, requestParameters.phone, requestParameters.email, requestParameters.companies, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProcessControllerApi - axios parameter creator
 * @export
 */
export const ProcessControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProcessName} processName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewProcessVariables: async (processName: ProcessName, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processName' is not null or undefined
            assertParamExists('getNewProcessVariables', 'processName', processName)
            const localVarPath = `/api/processes/{processName}/variables`
                .replace(`{${"processName"}}`, encodeURIComponent(String(processName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartProcessRequest} startProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startProcess: async (startProcessRequest: StartProcessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startProcessRequest' is not null or undefined
            assertParamExists('startProcess', 'startProcessRequest', startProcessRequest)
            const localVarPath = `/api/processes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startProcessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessControllerApi - functional programming interface
 * @export
 */
export const ProcessControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcessControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProcessName} processName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewProcessVariables(processName: ProcessName, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewProcessVariables(processName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartProcessRequest} startProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startProcess(startProcessRequest: StartProcessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Process>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startProcess(startProcessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProcessControllerApi - factory interface
 * @export
 */
export const ProcessControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcessControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ProcessControllerApiGetNewProcessVariablesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewProcessVariables(requestParameters: ProcessControllerApiGetNewProcessVariablesRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getNewProcessVariables(requestParameters.processName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessControllerApiStartProcessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startProcess(requestParameters: ProcessControllerApiStartProcessRequest, options?: AxiosRequestConfig): AxiosPromise<Process> {
            return localVarFp.startProcess(requestParameters.startProcessRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getNewProcessVariables operation in ProcessControllerApi.
 * @export
 * @interface ProcessControllerApiGetNewProcessVariablesRequest
 */
export interface ProcessControllerApiGetNewProcessVariablesRequest {
    /**
     * 
     * @type {ProcessName}
     * @memberof ProcessControllerApiGetNewProcessVariables
     */
    readonly processName: ProcessName
}

/**
 * Request parameters for startProcess operation in ProcessControllerApi.
 * @export
 * @interface ProcessControllerApiStartProcessRequest
 */
export interface ProcessControllerApiStartProcessRequest {
    /**
     * 
     * @type {StartProcessRequest}
     * @memberof ProcessControllerApiStartProcess
     */
    readonly startProcessRequest: StartProcessRequest
}

/**
 * ProcessControllerApi - object-oriented interface
 * @export
 * @class ProcessControllerApi
 * @extends {BaseAPI}
 */
export class ProcessControllerApi extends BaseAPI {
    /**
     * 
     * @param {ProcessControllerApiGetNewProcessVariablesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessControllerApi
     */
    public getNewProcessVariables(requestParameters: ProcessControllerApiGetNewProcessVariablesRequest, options?: AxiosRequestConfig) {
        return ProcessControllerApiFp(this.configuration).getNewProcessVariables(requestParameters.processName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessControllerApiStartProcessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessControllerApi
     */
    public startProcess(requestParameters: ProcessControllerApiStartProcessRequest, options?: AxiosRequestConfig) {
        return ProcessControllerApiFp(this.configuration).startProcess(requestParameters.startProcessRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductControllerApi - axios parameter creator
 * @export
 */
export const ProductControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProductRequest} createProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (createProductRequest: CreateProductRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductRequest' is not null or undefined
            assertParamExists('createProduct', 'createProductRequest', createProductRequest)
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [externalId] 
         * @param {string} [name] 
         * @param {number} [transferringFeeFrom] 
         * @param {number} [transferringFeeTo] 
         * @param {Array<ProductStatus>} [statuses] 
         * @param {boolean} [excludedFromOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBnplProducts: async (page?: number, size?: number, sort?: Array<string>, id?: number, externalId?: number, name?: string, transferringFeeFrom?: number, transferringFeeTo?: number, statuses?: Array<ProductStatus>, excludedFromOffer?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/bnpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (transferringFeeFrom !== undefined) {
                localVarQueryParameter['transferringFeeFrom'] = transferringFeeFrom;
            }

            if (transferringFeeTo !== undefined) {
                localVarQueryParameter['transferringFeeTo'] = transferringFeeTo;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (excludedFromOffer !== undefined) {
                localVarQueryParameter['excludedFromOffer'] = excludedFromOffer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [externalId] 
         * @param {string} [name] 
         * @param {Array<RepaymentCalculationMethod>} [repaymentCalculationMethods] 
         * @param {number} [penaltyInterestMultiplierFrom] 
         * @param {number} [penaltyInterestMultiplierTo] 
         * @param {number} [penaltyInterestAccrualStartDay] 
         * @param {Array<RepaymentAllocationRule>} [repaymentAllocationRules] 
         * @param {Array<ProductStatus>} [statuses] 
         * @param {number} [interestRate] 
         * @param {Array<ProductSubtype>} [subtypes] 
         * @param {boolean} [excludedFromOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallmentProducts: async (page?: number, size?: number, sort?: Array<string>, id?: number, externalId?: number, name?: string, repaymentCalculationMethods?: Array<RepaymentCalculationMethod>, penaltyInterestMultiplierFrom?: number, penaltyInterestMultiplierTo?: number, penaltyInterestAccrualStartDay?: number, repaymentAllocationRules?: Array<RepaymentAllocationRule>, statuses?: Array<ProductStatus>, interestRate?: number, subtypes?: Array<ProductSubtype>, excludedFromOffer?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/installment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (repaymentCalculationMethods) {
                localVarQueryParameter['repaymentCalculationMethods'] = repaymentCalculationMethods;
            }

            if (penaltyInterestMultiplierFrom !== undefined) {
                localVarQueryParameter['penaltyInterestMultiplierFrom'] = penaltyInterestMultiplierFrom;
            }

            if (penaltyInterestMultiplierTo !== undefined) {
                localVarQueryParameter['penaltyInterestMultiplierTo'] = penaltyInterestMultiplierTo;
            }

            if (penaltyInterestAccrualStartDay !== undefined) {
                localVarQueryParameter['penaltyInterestAccrualStartDay'] = penaltyInterestAccrualStartDay;
            }

            if (repaymentAllocationRules) {
                localVarQueryParameter['repaymentAllocationRules'] = repaymentAllocationRules;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (interestRate !== undefined) {
                localVarQueryParameter['interestRate'] = interestRate;
            }

            if (subtypes) {
                localVarQueryParameter['subtypes'] = subtypes;
            }

            if (excludedFromOffer !== undefined) {
                localVarQueryParameter['excludedFromOffer'] = excludedFromOffer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [externalId] 
         * @param {string} [name] 
         * @param {Array<RepaymentCalculationMethod>} [repaymentCalculationMethods] 
         * @param {number} [penaltyInterestMultiplierFrom] 
         * @param {number} [penaltyInterestMultiplierTo] 
         * @param {number} [penaltyInterestAccrualStartDay] 
         * @param {Array<RepaymentAllocationRule>} [repaymentAllocationRules] 
         * @param {Array<ProductStatus>} [statuses] 
         * @param {boolean} [excludedFromOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayonProducts: async (page?: number, size?: number, sort?: Array<string>, id?: number, externalId?: number, name?: string, repaymentCalculationMethods?: Array<RepaymentCalculationMethod>, penaltyInterestMultiplierFrom?: number, penaltyInterestMultiplierTo?: number, penaltyInterestAccrualStartDay?: number, repaymentAllocationRules?: Array<RepaymentAllocationRule>, statuses?: Array<ProductStatus>, excludedFromOffer?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/payon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (repaymentCalculationMethods) {
                localVarQueryParameter['repaymentCalculationMethods'] = repaymentCalculationMethods;
            }

            if (penaltyInterestMultiplierFrom !== undefined) {
                localVarQueryParameter['penaltyInterestMultiplierFrom'] = penaltyInterestMultiplierFrom;
            }

            if (penaltyInterestMultiplierTo !== undefined) {
                localVarQueryParameter['penaltyInterestMultiplierTo'] = penaltyInterestMultiplierTo;
            }

            if (penaltyInterestAccrualStartDay !== undefined) {
                localVarQueryParameter['penaltyInterestAccrualStartDay'] = penaltyInterestAccrualStartDay;
            }

            if (repaymentAllocationRules) {
                localVarQueryParameter['repaymentAllocationRules'] = repaymentAllocationRules;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (excludedFromOffer !== undefined) {
                localVarQueryParameter['excludedFromOffer'] = excludedFromOffer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [externalId] 
         * @param {string} [name] 
         * @param {Array<RepaymentCalculationMethod>} [repaymentCalculationMethods] 
         * @param {number} [penaltyInterestMultiplierFrom] 
         * @param {number} [penaltyInterestMultiplierTo] 
         * @param {number} [penaltyInterestAccrualStartDay] 
         * @param {Array<RepaymentAllocationRule>} [repaymentAllocationRules] 
         * @param {Array<ProductStatus>} [statuses] 
         * @param {number} [interestRate] 
         * @param {Array<ProductSubtype>} [subtypes] 
         * @param {boolean} [excludedFromOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPledgedProducts: async (page?: number, size?: number, sort?: Array<string>, id?: number, externalId?: number, name?: string, repaymentCalculationMethods?: Array<RepaymentCalculationMethod>, penaltyInterestMultiplierFrom?: number, penaltyInterestMultiplierTo?: number, penaltyInterestAccrualStartDay?: number, repaymentAllocationRules?: Array<RepaymentAllocationRule>, statuses?: Array<ProductStatus>, interestRate?: number, subtypes?: Array<ProductSubtype>, excludedFromOffer?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/pledged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (repaymentCalculationMethods) {
                localVarQueryParameter['repaymentCalculationMethods'] = repaymentCalculationMethods;
            }

            if (penaltyInterestMultiplierFrom !== undefined) {
                localVarQueryParameter['penaltyInterestMultiplierFrom'] = penaltyInterestMultiplierFrom;
            }

            if (penaltyInterestMultiplierTo !== undefined) {
                localVarQueryParameter['penaltyInterestMultiplierTo'] = penaltyInterestMultiplierTo;
            }

            if (penaltyInterestAccrualStartDay !== undefined) {
                localVarQueryParameter['penaltyInterestAccrualStartDay'] = penaltyInterestAccrualStartDay;
            }

            if (repaymentAllocationRules) {
                localVarQueryParameter['repaymentAllocationRules'] = repaymentAllocationRules;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (interestRate !== undefined) {
                localVarQueryParameter['interestRate'] = interestRate;
            }

            if (subtypes) {
                localVarQueryParameter['subtypes'] = subtypes;
            }

            if (excludedFromOffer !== undefined) {
                localVarQueryParameter['excludedFromOffer'] = excludedFromOffer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProduct', 'id', id)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [externalId] 
         * @param {string} [name] 
         * @param {number} [interestRate] 
         * @param {Array<ProductStatus>} [statuses] 
         * @param {boolean} [excludedFromOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrustProducts: async (page?: number, size?: number, sort?: Array<string>, id?: number, externalId?: number, name?: string, interestRate?: number, statuses?: Array<ProductStatus>, excludedFromOffer?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/trust`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (interestRate !== undefined) {
                localVarQueryParameter['interestRate'] = interestRate;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (excludedFromOffer !== undefined) {
                localVarQueryParameter['excludedFromOffer'] = excludedFromOffer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProductRequest} updateProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (id: number, updateProductRequest: UpdateProductRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProduct', 'id', id)
            // verify required parameter 'updateProductRequest' is not null or undefined
            assertParamExists('updateProduct', 'updateProductRequest', updateProductRequest)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductControllerApi - functional programming interface
 * @export
 */
export const ProductControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProductRequest} createProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(createProductRequest: CreateProductRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(createProductRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [externalId] 
         * @param {string} [name] 
         * @param {number} [transferringFeeFrom] 
         * @param {number} [transferringFeeTo] 
         * @param {Array<ProductStatus>} [statuses] 
         * @param {boolean} [excludedFromOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBnplProducts(page?: number, size?: number, sort?: Array<string>, id?: number, externalId?: number, name?: string, transferringFeeFrom?: number, transferringFeeTo?: number, statuses?: Array<ProductStatus>, excludedFromOffer?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageBnplProductListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBnplProducts(page, size, sort, id, externalId, name, transferringFeeFrom, transferringFeeTo, statuses, excludedFromOffer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [externalId] 
         * @param {string} [name] 
         * @param {Array<RepaymentCalculationMethod>} [repaymentCalculationMethods] 
         * @param {number} [penaltyInterestMultiplierFrom] 
         * @param {number} [penaltyInterestMultiplierTo] 
         * @param {number} [penaltyInterestAccrualStartDay] 
         * @param {Array<RepaymentAllocationRule>} [repaymentAllocationRules] 
         * @param {Array<ProductStatus>} [statuses] 
         * @param {number} [interestRate] 
         * @param {Array<ProductSubtype>} [subtypes] 
         * @param {boolean} [excludedFromOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallmentProducts(page?: number, size?: number, sort?: Array<string>, id?: number, externalId?: number, name?: string, repaymentCalculationMethods?: Array<RepaymentCalculationMethod>, penaltyInterestMultiplierFrom?: number, penaltyInterestMultiplierTo?: number, penaltyInterestAccrualStartDay?: number, repaymentAllocationRules?: Array<RepaymentAllocationRule>, statuses?: Array<ProductStatus>, interestRate?: number, subtypes?: Array<ProductSubtype>, excludedFromOffer?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProductListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallmentProducts(page, size, sort, id, externalId, name, repaymentCalculationMethods, penaltyInterestMultiplierFrom, penaltyInterestMultiplierTo, penaltyInterestAccrualStartDay, repaymentAllocationRules, statuses, interestRate, subtypes, excludedFromOffer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [externalId] 
         * @param {string} [name] 
         * @param {Array<RepaymentCalculationMethod>} [repaymentCalculationMethods] 
         * @param {number} [penaltyInterestMultiplierFrom] 
         * @param {number} [penaltyInterestMultiplierTo] 
         * @param {number} [penaltyInterestAccrualStartDay] 
         * @param {Array<RepaymentAllocationRule>} [repaymentAllocationRules] 
         * @param {Array<ProductStatus>} [statuses] 
         * @param {boolean} [excludedFromOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayonProducts(page?: number, size?: number, sort?: Array<string>, id?: number, externalId?: number, name?: string, repaymentCalculationMethods?: Array<RepaymentCalculationMethod>, penaltyInterestMultiplierFrom?: number, penaltyInterestMultiplierTo?: number, penaltyInterestAccrualStartDay?: number, repaymentAllocationRules?: Array<RepaymentAllocationRule>, statuses?: Array<ProductStatus>, excludedFromOffer?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProductListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayonProducts(page, size, sort, id, externalId, name, repaymentCalculationMethods, penaltyInterestMultiplierFrom, penaltyInterestMultiplierTo, penaltyInterestAccrualStartDay, repaymentAllocationRules, statuses, excludedFromOffer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [externalId] 
         * @param {string} [name] 
         * @param {Array<RepaymentCalculationMethod>} [repaymentCalculationMethods] 
         * @param {number} [penaltyInterestMultiplierFrom] 
         * @param {number} [penaltyInterestMultiplierTo] 
         * @param {number} [penaltyInterestAccrualStartDay] 
         * @param {Array<RepaymentAllocationRule>} [repaymentAllocationRules] 
         * @param {Array<ProductStatus>} [statuses] 
         * @param {number} [interestRate] 
         * @param {Array<ProductSubtype>} [subtypes] 
         * @param {boolean} [excludedFromOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPledgedProducts(page?: number, size?: number, sort?: Array<string>, id?: number, externalId?: number, name?: string, repaymentCalculationMethods?: Array<RepaymentCalculationMethod>, penaltyInterestMultiplierFrom?: number, penaltyInterestMultiplierTo?: number, penaltyInterestAccrualStartDay?: number, repaymentAllocationRules?: Array<RepaymentAllocationRule>, statuses?: Array<ProductStatus>, interestRate?: number, subtypes?: Array<ProductSubtype>, excludedFromOffer?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProductListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPledgedProducts(page, size, sort, id, externalId, name, repaymentCalculationMethods, penaltyInterestMultiplierFrom, penaltyInterestMultiplierTo, penaltyInterestAccrualStartDay, repaymentAllocationRules, statuses, interestRate, subtypes, excludedFromOffer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProduct200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [id] 
         * @param {number} [externalId] 
         * @param {string} [name] 
         * @param {number} [interestRate] 
         * @param {Array<ProductStatus>} [statuses] 
         * @param {boolean} [excludedFromOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrustProducts(page?: number, size?: number, sort?: Array<string>, id?: number, externalId?: number, name?: string, interestRate?: number, statuses?: Array<ProductStatus>, excludedFromOffer?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTrustProductListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrustProducts(page, size, sort, id, externalId, name, interestRate, statuses, excludedFromOffer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProductRequest} updateProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(id: number, updateProductRequest: UpdateProductRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(id, updateProductRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductControllerApi - factory interface
 * @export
 */
export const ProductControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductControllerApiCreateProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(requestParameters: ProductControllerApiCreateProductRequest, options?: AxiosRequestConfig): AxiosPromise<IdEntry> {
            return localVarFp.createProduct(requestParameters.createProductRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductControllerApiGetBnplProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBnplProducts(requestParameters: ProductControllerApiGetBnplProductsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageBnplProductListEntry> {
            return localVarFp.getBnplProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.externalId, requestParameters.name, requestParameters.transferringFeeFrom, requestParameters.transferringFeeTo, requestParameters.statuses, requestParameters.excludedFromOffer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductControllerApiGetInstallmentProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallmentProducts(requestParameters: ProductControllerApiGetInstallmentProductsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageProductListEntry> {
            return localVarFp.getInstallmentProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.externalId, requestParameters.name, requestParameters.repaymentCalculationMethods, requestParameters.penaltyInterestMultiplierFrom, requestParameters.penaltyInterestMultiplierTo, requestParameters.penaltyInterestAccrualStartDay, requestParameters.repaymentAllocationRules, requestParameters.statuses, requestParameters.interestRate, requestParameters.subtypes, requestParameters.excludedFromOffer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductControllerApiGetPayonProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayonProducts(requestParameters: ProductControllerApiGetPayonProductsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageProductListEntry> {
            return localVarFp.getPayonProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.externalId, requestParameters.name, requestParameters.repaymentCalculationMethods, requestParameters.penaltyInterestMultiplierFrom, requestParameters.penaltyInterestMultiplierTo, requestParameters.penaltyInterestAccrualStartDay, requestParameters.repaymentAllocationRules, requestParameters.statuses, requestParameters.excludedFromOffer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductControllerApiGetPledgedProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPledgedProducts(requestParameters: ProductControllerApiGetPledgedProductsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageProductListEntry> {
            return localVarFp.getPledgedProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.externalId, requestParameters.name, requestParameters.repaymentCalculationMethods, requestParameters.penaltyInterestMultiplierFrom, requestParameters.penaltyInterestMultiplierTo, requestParameters.penaltyInterestAccrualStartDay, requestParameters.repaymentAllocationRules, requestParameters.statuses, requestParameters.interestRate, requestParameters.subtypes, requestParameters.excludedFromOffer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductControllerApiGetProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(requestParameters: ProductControllerApiGetProductRequest, options?: AxiosRequestConfig): AxiosPromise<GetProduct200Response> {
            return localVarFp.getProduct(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductControllerApiGetTrustProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrustProducts(requestParameters: ProductControllerApiGetTrustProductsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageTrustProductListEntry> {
            return localVarFp.getTrustProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.externalId, requestParameters.name, requestParameters.interestRate, requestParameters.statuses, requestParameters.excludedFromOffer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductControllerApiUpdateProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(requestParameters: ProductControllerApiUpdateProductRequest, options?: AxiosRequestConfig): AxiosPromise<IdEntry> {
            return localVarFp.updateProduct(requestParameters.id, requestParameters.updateProductRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createProduct operation in ProductControllerApi.
 * @export
 * @interface ProductControllerApiCreateProductRequest
 */
export interface ProductControllerApiCreateProductRequest {
    /**
     * 
     * @type {CreateProductRequest}
     * @memberof ProductControllerApiCreateProduct
     */
    readonly createProductRequest: CreateProductRequest
}

/**
 * Request parameters for getBnplProducts operation in ProductControllerApi.
 * @export
 * @interface ProductControllerApiGetBnplProductsRequest
 */
export interface ProductControllerApiGetBnplProductsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ProductControllerApiGetBnplProducts
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ProductControllerApiGetBnplProducts
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ProductControllerApiGetBnplProducts
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetBnplProducts
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetBnplProducts
     */
    readonly externalId?: number

    /**
     * 
     * @type {string}
     * @memberof ProductControllerApiGetBnplProducts
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetBnplProducts
     */
    readonly transferringFeeFrom?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetBnplProducts
     */
    readonly transferringFeeTo?: number

    /**
     * 
     * @type {Array<ProductStatus>}
     * @memberof ProductControllerApiGetBnplProducts
     */
    readonly statuses?: Array<ProductStatus>

    /**
     * 
     * @type {boolean}
     * @memberof ProductControllerApiGetBnplProducts
     */
    readonly excludedFromOffer?: boolean
}

/**
 * Request parameters for getInstallmentProducts operation in ProductControllerApi.
 * @export
 * @interface ProductControllerApiGetInstallmentProductsRequest
 */
export interface ProductControllerApiGetInstallmentProductsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly externalId?: number

    /**
     * 
     * @type {string}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly name?: string

    /**
     * 
     * @type {Array<RepaymentCalculationMethod>}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly repaymentCalculationMethods?: Array<RepaymentCalculationMethod>

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly penaltyInterestMultiplierFrom?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly penaltyInterestMultiplierTo?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly penaltyInterestAccrualStartDay?: number

    /**
     * 
     * @type {Array<RepaymentAllocationRule>}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly repaymentAllocationRules?: Array<RepaymentAllocationRule>

    /**
     * 
     * @type {Array<ProductStatus>}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly statuses?: Array<ProductStatus>

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly interestRate?: number

    /**
     * 
     * @type {Array<ProductSubtype>}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly subtypes?: Array<ProductSubtype>

    /**
     * 
     * @type {boolean}
     * @memberof ProductControllerApiGetInstallmentProducts
     */
    readonly excludedFromOffer?: boolean
}

/**
 * Request parameters for getPayonProducts operation in ProductControllerApi.
 * @export
 * @interface ProductControllerApiGetPayonProductsRequest
 */
export interface ProductControllerApiGetPayonProductsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly externalId?: number

    /**
     * 
     * @type {string}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly name?: string

    /**
     * 
     * @type {Array<RepaymentCalculationMethod>}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly repaymentCalculationMethods?: Array<RepaymentCalculationMethod>

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly penaltyInterestMultiplierFrom?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly penaltyInterestMultiplierTo?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly penaltyInterestAccrualStartDay?: number

    /**
     * 
     * @type {Array<RepaymentAllocationRule>}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly repaymentAllocationRules?: Array<RepaymentAllocationRule>

    /**
     * 
     * @type {Array<ProductStatus>}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly statuses?: Array<ProductStatus>

    /**
     * 
     * @type {boolean}
     * @memberof ProductControllerApiGetPayonProducts
     */
    readonly excludedFromOffer?: boolean
}

/**
 * Request parameters for getPledgedProducts operation in ProductControllerApi.
 * @export
 * @interface ProductControllerApiGetPledgedProductsRequest
 */
export interface ProductControllerApiGetPledgedProductsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly externalId?: number

    /**
     * 
     * @type {string}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly name?: string

    /**
     * 
     * @type {Array<RepaymentCalculationMethod>}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly repaymentCalculationMethods?: Array<RepaymentCalculationMethod>

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly penaltyInterestMultiplierFrom?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly penaltyInterestMultiplierTo?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly penaltyInterestAccrualStartDay?: number

    /**
     * 
     * @type {Array<RepaymentAllocationRule>}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly repaymentAllocationRules?: Array<RepaymentAllocationRule>

    /**
     * 
     * @type {Array<ProductStatus>}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly statuses?: Array<ProductStatus>

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly interestRate?: number

    /**
     * 
     * @type {Array<ProductSubtype>}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly subtypes?: Array<ProductSubtype>

    /**
     * 
     * @type {boolean}
     * @memberof ProductControllerApiGetPledgedProducts
     */
    readonly excludedFromOffer?: boolean
}

/**
 * Request parameters for getProduct operation in ProductControllerApi.
 * @export
 * @interface ProductControllerApiGetProductRequest
 */
export interface ProductControllerApiGetProductRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetProduct
     */
    readonly id: number
}

/**
 * Request parameters for getTrustProducts operation in ProductControllerApi.
 * @export
 * @interface ProductControllerApiGetTrustProductsRequest
 */
export interface ProductControllerApiGetTrustProductsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ProductControllerApiGetTrustProducts
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ProductControllerApiGetTrustProducts
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ProductControllerApiGetTrustProducts
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetTrustProducts
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetTrustProducts
     */
    readonly externalId?: number

    /**
     * 
     * @type {string}
     * @memberof ProductControllerApiGetTrustProducts
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiGetTrustProducts
     */
    readonly interestRate?: number

    /**
     * 
     * @type {Array<ProductStatus>}
     * @memberof ProductControllerApiGetTrustProducts
     */
    readonly statuses?: Array<ProductStatus>

    /**
     * 
     * @type {boolean}
     * @memberof ProductControllerApiGetTrustProducts
     */
    readonly excludedFromOffer?: boolean
}

/**
 * Request parameters for updateProduct operation in ProductControllerApi.
 * @export
 * @interface ProductControllerApiUpdateProductRequest
 */
export interface ProductControllerApiUpdateProductRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductControllerApiUpdateProduct
     */
    readonly id: number

    /**
     * 
     * @type {UpdateProductRequest}
     * @memberof ProductControllerApiUpdateProduct
     */
    readonly updateProductRequest: UpdateProductRequest
}

/**
 * ProductControllerApi - object-oriented interface
 * @export
 * @class ProductControllerApi
 * @extends {BaseAPI}
 */
export class ProductControllerApi extends BaseAPI {
    /**
     * 
     * @param {ProductControllerApiCreateProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public createProduct(requestParameters: ProductControllerApiCreateProductRequest, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).createProduct(requestParameters.createProductRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductControllerApiGetBnplProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getBnplProducts(requestParameters: ProductControllerApiGetBnplProductsRequest = {}, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getBnplProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.externalId, requestParameters.name, requestParameters.transferringFeeFrom, requestParameters.transferringFeeTo, requestParameters.statuses, requestParameters.excludedFromOffer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductControllerApiGetInstallmentProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getInstallmentProducts(requestParameters: ProductControllerApiGetInstallmentProductsRequest = {}, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getInstallmentProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.externalId, requestParameters.name, requestParameters.repaymentCalculationMethods, requestParameters.penaltyInterestMultiplierFrom, requestParameters.penaltyInterestMultiplierTo, requestParameters.penaltyInterestAccrualStartDay, requestParameters.repaymentAllocationRules, requestParameters.statuses, requestParameters.interestRate, requestParameters.subtypes, requestParameters.excludedFromOffer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductControllerApiGetPayonProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getPayonProducts(requestParameters: ProductControllerApiGetPayonProductsRequest = {}, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getPayonProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.externalId, requestParameters.name, requestParameters.repaymentCalculationMethods, requestParameters.penaltyInterestMultiplierFrom, requestParameters.penaltyInterestMultiplierTo, requestParameters.penaltyInterestAccrualStartDay, requestParameters.repaymentAllocationRules, requestParameters.statuses, requestParameters.excludedFromOffer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductControllerApiGetPledgedProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getPledgedProducts(requestParameters: ProductControllerApiGetPledgedProductsRequest = {}, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getPledgedProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.externalId, requestParameters.name, requestParameters.repaymentCalculationMethods, requestParameters.penaltyInterestMultiplierFrom, requestParameters.penaltyInterestMultiplierTo, requestParameters.penaltyInterestAccrualStartDay, requestParameters.repaymentAllocationRules, requestParameters.statuses, requestParameters.interestRate, requestParameters.subtypes, requestParameters.excludedFromOffer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductControllerApiGetProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getProduct(requestParameters: ProductControllerApiGetProductRequest, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getProduct(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductControllerApiGetTrustProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getTrustProducts(requestParameters: ProductControllerApiGetTrustProductsRequest = {}, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getTrustProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.id, requestParameters.externalId, requestParameters.name, requestParameters.interestRate, requestParameters.statuses, requestParameters.excludedFromOffer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductControllerApiUpdateProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public updateProduct(requestParameters: ProductControllerApiUpdateProductRequest, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).updateProduct(requestParameters.id, requestParameters.updateProductRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductLineControllerApi - axios parameter creator
 * @export
 */
export const ProductLineControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<ProductType>} [productTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductLines: async (productTypes?: Array<ProductType>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/product-lines/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (productTypes) {
                localVarQueryParameter['productTypes'] = productTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductLineControllerApi - functional programming interface
 * @export
 */
export const ProductLineControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductLineControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<ProductType>} [productTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductLines(productTypes?: Array<ProductType>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductLine>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductLines(productTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductLineControllerApi - factory interface
 * @export
 */
export const ProductLineControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductLineControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductLineControllerApiGetProductLinesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductLines(requestParameters: ProductLineControllerApiGetProductLinesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ProductLine>> {
            return localVarFp.getProductLines(requestParameters.productTypes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getProductLines operation in ProductLineControllerApi.
 * @export
 * @interface ProductLineControllerApiGetProductLinesRequest
 */
export interface ProductLineControllerApiGetProductLinesRequest {
    /**
     * 
     * @type {Array<ProductType>}
     * @memberof ProductLineControllerApiGetProductLines
     */
    readonly productTypes?: Array<ProductType>
}

/**
 * ProductLineControllerApi - object-oriented interface
 * @export
 * @class ProductLineControllerApi
 * @extends {BaseAPI}
 */
export class ProductLineControllerApi extends BaseAPI {
    /**
     * 
     * @param {ProductLineControllerApiGetProductLinesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLineControllerApi
     */
    public getProductLines(requestParameters: ProductLineControllerApiGetProductLinesRequest = {}, options?: AxiosRequestConfig) {
        return ProductLineControllerApiFp(this.configuration).getProductLines(requestParameters.productTypes, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * QaCalculationControllerApi - axios parameter creator
 * @export
 */
export const QaCalculationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} loanId 
         * @param {string} [calculateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculate: async (loanId: number, calculateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('calculate', 'loanId', loanId)
            const localVarPath = `/api/test/calculation/{loanId}/calculate`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (calculateTo !== undefined) {
                localVarQueryParameter['calculateTo'] = (calculateTo as any instanceof Date) ?
                    (calculateTo as any).toISOString().substring(0,10) :
                    calculateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} loanId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateLoanOperation: async (loanId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('deactivateLoanOperation', 'loanId', loanId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateLoanOperation', 'id', id)
            const localVarPath = `/api/test/calculation/{loanId}/operation/{id}/deactivate`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} loanId 
         * @param {LoanTestPaymentModel} loanTestPaymentModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payment: async (loanId: number, loanTestPaymentModel: LoanTestPaymentModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('payment', 'loanId', loanId)
            // verify required parameter 'loanTestPaymentModel' is not null or undefined
            assertParamExists('payment', 'loanTestPaymentModel', loanTestPaymentModel)
            const localVarPath = `/api/test/calculation/{loanId}/payment`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanTestPaymentModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateAuditLogForLoan: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('recreateAuditLogForLoan', 'id', id)
            const localVarPath = `/api/test/calculation/audit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCalculationJob: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/test/calculation/calculationJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startNotificationJob: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/test/calculation/runNotificationJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QaCalculationControllerApi - functional programming interface
 * @export
 */
export const QaCalculationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QaCalculationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} loanId 
         * @param {string} [calculateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculate(loanId: number, calculateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculate(loanId, calculateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} loanId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateLoanOperation(loanId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateLoanOperation(loanId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} loanId 
         * @param {LoanTestPaymentModel} loanTestPaymentModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payment(loanId: number, loanTestPaymentModel: LoanTestPaymentModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payment(loanId, loanTestPaymentModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recreateAuditLogForLoan(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recreateAuditLogForLoan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startCalculationJob(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startCalculationJob(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startNotificationJob(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startNotificationJob(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QaCalculationControllerApi - factory interface
 * @export
 */
export const QaCalculationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QaCalculationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {QaCalculationControllerApiCalculateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculate(requestParameters: QaCalculationControllerApiCalculateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.calculate(requestParameters.loanId, requestParameters.calculateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QaCalculationControllerApiDeactivateLoanOperationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateLoanOperation(requestParameters: QaCalculationControllerApiDeactivateLoanOperationRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deactivateLoanOperation(requestParameters.loanId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QaCalculationControllerApiPaymentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payment(requestParameters: QaCalculationControllerApiPaymentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.payment(requestParameters.loanId, requestParameters.loanTestPaymentModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QaCalculationControllerApiRecreateAuditLogForLoanRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateAuditLogForLoan(requestParameters: QaCalculationControllerApiRecreateAuditLogForLoanRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.recreateAuditLogForLoan(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCalculationJob(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.startCalculationJob(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startNotificationJob(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.startNotificationJob(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for calculate operation in QaCalculationControllerApi.
 * @export
 * @interface QaCalculationControllerApiCalculateRequest
 */
export interface QaCalculationControllerApiCalculateRequest {
    /**
     * 
     * @type {number}
     * @memberof QaCalculationControllerApiCalculate
     */
    readonly loanId: number

    /**
     * 
     * @type {string}
     * @memberof QaCalculationControllerApiCalculate
     */
    readonly calculateTo?: string
}

/**
 * Request parameters for deactivateLoanOperation operation in QaCalculationControllerApi.
 * @export
 * @interface QaCalculationControllerApiDeactivateLoanOperationRequest
 */
export interface QaCalculationControllerApiDeactivateLoanOperationRequest {
    /**
     * 
     * @type {number}
     * @memberof QaCalculationControllerApiDeactivateLoanOperation
     */
    readonly loanId: number

    /**
     * 
     * @type {number}
     * @memberof QaCalculationControllerApiDeactivateLoanOperation
     */
    readonly id: number
}

/**
 * Request parameters for payment operation in QaCalculationControllerApi.
 * @export
 * @interface QaCalculationControllerApiPaymentRequest
 */
export interface QaCalculationControllerApiPaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof QaCalculationControllerApiPayment
     */
    readonly loanId: number

    /**
     * 
     * @type {LoanTestPaymentModel}
     * @memberof QaCalculationControllerApiPayment
     */
    readonly loanTestPaymentModel: LoanTestPaymentModel
}

/**
 * Request parameters for recreateAuditLogForLoan operation in QaCalculationControllerApi.
 * @export
 * @interface QaCalculationControllerApiRecreateAuditLogForLoanRequest
 */
export interface QaCalculationControllerApiRecreateAuditLogForLoanRequest {
    /**
     * 
     * @type {number}
     * @memberof QaCalculationControllerApiRecreateAuditLogForLoan
     */
    readonly id: number
}

/**
 * QaCalculationControllerApi - object-oriented interface
 * @export
 * @class QaCalculationControllerApi
 * @extends {BaseAPI}
 */
export class QaCalculationControllerApi extends BaseAPI {
    /**
     * 
     * @param {QaCalculationControllerApiCalculateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaCalculationControllerApi
     */
    public calculate(requestParameters: QaCalculationControllerApiCalculateRequest, options?: AxiosRequestConfig) {
        return QaCalculationControllerApiFp(this.configuration).calculate(requestParameters.loanId, requestParameters.calculateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QaCalculationControllerApiDeactivateLoanOperationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaCalculationControllerApi
     */
    public deactivateLoanOperation(requestParameters: QaCalculationControllerApiDeactivateLoanOperationRequest, options?: AxiosRequestConfig) {
        return QaCalculationControllerApiFp(this.configuration).deactivateLoanOperation(requestParameters.loanId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QaCalculationControllerApiPaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaCalculationControllerApi
     */
    public payment(requestParameters: QaCalculationControllerApiPaymentRequest, options?: AxiosRequestConfig) {
        return QaCalculationControllerApiFp(this.configuration).payment(requestParameters.loanId, requestParameters.loanTestPaymentModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QaCalculationControllerApiRecreateAuditLogForLoanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaCalculationControllerApi
     */
    public recreateAuditLogForLoan(requestParameters: QaCalculationControllerApiRecreateAuditLogForLoanRequest, options?: AxiosRequestConfig) {
        return QaCalculationControllerApiFp(this.configuration).recreateAuditLogForLoan(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaCalculationControllerApi
     */
    public startCalculationJob(options?: AxiosRequestConfig) {
        return QaCalculationControllerApiFp(this.configuration).startCalculationJob(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaCalculationControllerApi
     */
    public startNotificationJob(options?: AxiosRequestConfig) {
        return QaCalculationControllerApiFp(this.configuration).startNotificationJob(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ResourceControllerApi - axios parameter creator
 * @export
 */
export const ResourceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LocalizedEnumTypes} localizedEnum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnums: async (localizedEnum: LocalizedEnumTypes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localizedEnum' is not null or undefined
            assertParamExists('getEnums', 'localizedEnum', localizedEnum)
            const localVarPath = `/api/resource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (localizedEnum !== undefined) {
                localVarQueryParameter['localizedEnum'] = localizedEnum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceControllerApi - functional programming interface
 * @export
 */
export const ResourceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResourceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LocalizedEnumTypes} localizedEnum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnums(localizedEnum: LocalizedEnumTypes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocalizedEnum>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnums(localizedEnum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResourceControllerApi - factory interface
 * @export
 */
export const ResourceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResourceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ResourceControllerApiGetEnumsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnums(requestParameters: ResourceControllerApiGetEnumsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<LocalizedEnum>> {
            return localVarFp.getEnums(requestParameters.localizedEnum, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getEnums operation in ResourceControllerApi.
 * @export
 * @interface ResourceControllerApiGetEnumsRequest
 */
export interface ResourceControllerApiGetEnumsRequest {
    /**
     * 
     * @type {LocalizedEnumTypes}
     * @memberof ResourceControllerApiGetEnums
     */
    readonly localizedEnum: LocalizedEnumTypes
}

/**
 * ResourceControllerApi - object-oriented interface
 * @export
 * @class ResourceControllerApi
 * @extends {BaseAPI}
 */
export class ResourceControllerApi extends BaseAPI {
    /**
     * 
     * @param {ResourceControllerApiGetEnumsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public getEnums(requestParameters: ResourceControllerApiGetEnumsRequest, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).getEnums(requestParameters.localizedEnum, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RestrictionListControllerApi - axios parameter creator
 * @export
 */
export const RestrictionListControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRestrictionListEntryRequest} createRestrictionListEntryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add: async (createRestrictionListEntryRequest: CreateRestrictionListEntryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRestrictionListEntryRequest' is not null or undefined
            assertParamExists('add', 'createRestrictionListEntryRequest', createRestrictionListEntryRequest)
            const localVarPath = `/api/restriction-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRestrictionListEntryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [companyName] 
         * @param {Array<RestrictionListType>} [listType] 
         * @param {Array<RestrictionCause>} [cause] 
         * @param {string} [registrationNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList: async (page?: number, size?: number, sort?: Array<string>, firstName?: string, lastName?: string, companyName?: string, listType?: Array<RestrictionListType>, cause?: Array<RestrictionCause>, registrationNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/restriction-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (listType) {
                localVarQueryParameter['listType'] = listType;
            }

            if (cause) {
                localVarQueryParameter['cause'] = cause;
            }

            if (registrationNumber !== undefined) {
                localVarQueryParameter['registrationNumber'] = registrationNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCsv: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('importCsv', 'file', file)
            const localVarPath = `/api/restriction-list/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('remove', 'customerId', customerId)
            const localVarPath = `/api/restriction-list/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RestrictionListControllerApi - functional programming interface
 * @export
 */
export const RestrictionListControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RestrictionListControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRestrictionListEntryRequest} createRestrictionListEntryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async add(createRestrictionListEntryRequest: CreateRestrictionListEntryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.add(createRestrictionListEntryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [companyName] 
         * @param {Array<RestrictionListType>} [listType] 
         * @param {Array<RestrictionCause>} [cause] 
         * @param {string} [registrationNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getList(page?: number, size?: number, sort?: Array<string>, firstName?: string, lastName?: string, companyName?: string, listType?: Array<RestrictionListType>, cause?: Array<RestrictionCause>, registrationNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRestrictionListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getList(page, size, sort, firstName, lastName, companyName, listType, cause, registrationNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importCsv(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importCsv(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RestrictionListControllerApi - factory interface
 * @export
 */
export const RestrictionListControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RestrictionListControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {RestrictionListControllerApiAddRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add(requestParameters: RestrictionListControllerApiAddRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.add(requestParameters.createRestrictionListEntryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RestrictionListControllerApiGetListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(requestParameters: RestrictionListControllerApiGetListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageRestrictionListEntry> {
            return localVarFp.getList(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.firstName, requestParameters.lastName, requestParameters.companyName, requestParameters.listType, requestParameters.cause, requestParameters.registrationNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RestrictionListControllerApiImportCsvRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCsv(requestParameters: RestrictionListControllerApiImportCsvRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.importCsv(requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RestrictionListControllerApiRemoveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(requestParameters: RestrictionListControllerApiRemoveRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.remove(requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for add operation in RestrictionListControllerApi.
 * @export
 * @interface RestrictionListControllerApiAddRequest
 */
export interface RestrictionListControllerApiAddRequest {
    /**
     * 
     * @type {CreateRestrictionListEntryRequest}
     * @memberof RestrictionListControllerApiAdd
     */
    readonly createRestrictionListEntryRequest: CreateRestrictionListEntryRequest
}

/**
 * Request parameters for getList operation in RestrictionListControllerApi.
 * @export
 * @interface RestrictionListControllerApiGetListRequest
 */
export interface RestrictionListControllerApiGetListRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof RestrictionListControllerApiGetList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof RestrictionListControllerApiGetList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof RestrictionListControllerApiGetList
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof RestrictionListControllerApiGetList
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof RestrictionListControllerApiGetList
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof RestrictionListControllerApiGetList
     */
    readonly companyName?: string

    /**
     * 
     * @type {Array<RestrictionListType>}
     * @memberof RestrictionListControllerApiGetList
     */
    readonly listType?: Array<RestrictionListType>

    /**
     * 
     * @type {Array<RestrictionCause>}
     * @memberof RestrictionListControllerApiGetList
     */
    readonly cause?: Array<RestrictionCause>

    /**
     * 
     * @type {string}
     * @memberof RestrictionListControllerApiGetList
     */
    readonly registrationNumber?: string
}

/**
 * Request parameters for importCsv operation in RestrictionListControllerApi.
 * @export
 * @interface RestrictionListControllerApiImportCsvRequest
 */
export interface RestrictionListControllerApiImportCsvRequest {
    /**
     * 
     * @type {File}
     * @memberof RestrictionListControllerApiImportCsv
     */
    readonly file: File
}

/**
 * Request parameters for remove operation in RestrictionListControllerApi.
 * @export
 * @interface RestrictionListControllerApiRemoveRequest
 */
export interface RestrictionListControllerApiRemoveRequest {
    /**
     * 
     * @type {number}
     * @memberof RestrictionListControllerApiRemove
     */
    readonly customerId: number
}

/**
 * RestrictionListControllerApi - object-oriented interface
 * @export
 * @class RestrictionListControllerApi
 * @extends {BaseAPI}
 */
export class RestrictionListControllerApi extends BaseAPI {
    /**
     * 
     * @param {RestrictionListControllerApiAddRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionListControllerApi
     */
    public add(requestParameters: RestrictionListControllerApiAddRequest, options?: AxiosRequestConfig) {
        return RestrictionListControllerApiFp(this.configuration).add(requestParameters.createRestrictionListEntryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestrictionListControllerApiGetListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionListControllerApi
     */
    public getList(requestParameters: RestrictionListControllerApiGetListRequest = {}, options?: AxiosRequestConfig) {
        return RestrictionListControllerApiFp(this.configuration).getList(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.firstName, requestParameters.lastName, requestParameters.companyName, requestParameters.listType, requestParameters.cause, requestParameters.registrationNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestrictionListControllerApiImportCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionListControllerApi
     */
    public importCsv(requestParameters: RestrictionListControllerApiImportCsvRequest, options?: AxiosRequestConfig) {
        return RestrictionListControllerApiFp(this.configuration).importCsv(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestrictionListControllerApiRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionListControllerApi
     */
    public remove(requestParameters: RestrictionListControllerApiRemoveRequest, options?: AxiosRequestConfig) {
        return RestrictionListControllerApiFp(this.configuration).remove(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoleControllerApi - axios parameter creator
 * @export
 */
export const RoleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAuthorities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles/authorities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRoleForm} createRoleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (createRoleForm: CreateRoleForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleForm' is not null or undefined
            assertParamExists('createRole', 'createRoleForm', createRoleForm)
            const localVarPath = `/api/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        role: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('role', 'id', id)
            const localVarPath = `/api/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roles: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRoleForm} updateRoleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (id: number, updateRoleForm: UpdateRoleForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRole', 'id', id)
            // verify required parameter 'updateRoleForm' is not null or undefined
            assertParamExists('updateRole', 'updateRoleForm', updateRoleForm)
            const localVarPath = `/api/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleControllerApi - functional programming interface
 * @export
 */
export const RoleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAuthorities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthorityListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAuthorities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateRoleForm} createRoleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(createRoleForm: CreateRoleForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(createRoleForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async role(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.role(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roles(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRoleListItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roles(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRoleForm} updateRoleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(id: number, updateRoleForm: UpdateRoleForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRole(id, updateRoleForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleControllerApi - factory interface
 * @export
 */
export const RoleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAuthorities(options?: AxiosRequestConfig): AxiosPromise<Array<AuthorityListEntry>> {
            return localVarFp.allAuthorities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoleControllerApiCreateRoleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(requestParameters: RoleControllerApiCreateRoleRequest, options?: AxiosRequestConfig): AxiosPromise<RoleCreated> {
            return localVarFp.createRole(requestParameters.createRoleForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoleControllerApiRoleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        role(requestParameters: RoleControllerApiRoleRequest, options?: AxiosRequestConfig): AxiosPromise<Role> {
            return localVarFp.role(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleOptions(options?: AxiosRequestConfig): AxiosPromise<Array<RoleOption>> {
            return localVarFp.roleOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoleControllerApiRolesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roles(requestParameters: RoleControllerApiRolesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageRoleListItem> {
            return localVarFp.roles(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoleControllerApiUpdateRoleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(requestParameters: RoleControllerApiUpdateRoleRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateRole(requestParameters.id, requestParameters.updateRoleForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRole operation in RoleControllerApi.
 * @export
 * @interface RoleControllerApiCreateRoleRequest
 */
export interface RoleControllerApiCreateRoleRequest {
    /**
     * 
     * @type {CreateRoleForm}
     * @memberof RoleControllerApiCreateRole
     */
    readonly createRoleForm: CreateRoleForm
}

/**
 * Request parameters for role operation in RoleControllerApi.
 * @export
 * @interface RoleControllerApiRoleRequest
 */
export interface RoleControllerApiRoleRequest {
    /**
     * 
     * @type {number}
     * @memberof RoleControllerApiRole
     */
    readonly id: number
}

/**
 * Request parameters for roles operation in RoleControllerApi.
 * @export
 * @interface RoleControllerApiRolesRequest
 */
export interface RoleControllerApiRolesRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof RoleControllerApiRoles
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof RoleControllerApiRoles
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof RoleControllerApiRoles
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for updateRole operation in RoleControllerApi.
 * @export
 * @interface RoleControllerApiUpdateRoleRequest
 */
export interface RoleControllerApiUpdateRoleRequest {
    /**
     * 
     * @type {number}
     * @memberof RoleControllerApiUpdateRole
     */
    readonly id: number

    /**
     * 
     * @type {UpdateRoleForm}
     * @memberof RoleControllerApiUpdateRole
     */
    readonly updateRoleForm: UpdateRoleForm
}

/**
 * RoleControllerApi - object-oriented interface
 * @export
 * @class RoleControllerApi
 * @extends {BaseAPI}
 */
export class RoleControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public allAuthorities(options?: AxiosRequestConfig) {
        return RoleControllerApiFp(this.configuration).allAuthorities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoleControllerApiCreateRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public createRole(requestParameters: RoleControllerApiCreateRoleRequest, options?: AxiosRequestConfig) {
        return RoleControllerApiFp(this.configuration).createRole(requestParameters.createRoleForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoleControllerApiRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public role(requestParameters: RoleControllerApiRoleRequest, options?: AxiosRequestConfig) {
        return RoleControllerApiFp(this.configuration).role(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public roleOptions(options?: AxiosRequestConfig) {
        return RoleControllerApiFp(this.configuration).roleOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoleControllerApiRolesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public roles(requestParameters: RoleControllerApiRolesRequest = {}, options?: AxiosRequestConfig) {
        return RoleControllerApiFp(this.configuration).roles(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoleControllerApiUpdateRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public updateRole(requestParameters: RoleControllerApiUpdateRoleRequest, options?: AxiosRequestConfig) {
        return RoleControllerApiFp(this.configuration).updateRole(requestParameters.id, requestParameters.updateRoleForm, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SettingsControllerApi - axios parameter creator
 * @export
 */
export const SettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OverdueDaysSettingForm} overdueDaysSettingForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOverdueDaysSetting: async (overdueDaysSettingForm: OverdueDaysSettingForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'overdueDaysSettingForm' is not null or undefined
            assertParamExists('editOverdueDaysSetting', 'overdueDaysSettingForm', overdueDaysSettingForm)
            const localVarPath = `/api/settings/overdueDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(overdueDaysSettingForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProvisionRatesSettingForm} provisionRatesSettingForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProvisionRatesSetting: async (provisionRatesSettingForm: ProvisionRatesSettingForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provisionRatesSettingForm' is not null or undefined
            assertParamExists('editProvisionRatesSetting', 'provisionRatesSettingForm', provisionRatesSettingForm)
            const localVarPath = `/api/settings/provisionRates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(provisionRatesSettingForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverdueDaysSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings/overdueDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvisionRatesSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings/provisionRates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SettingType} settingType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSetting: async (settingType: SettingType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingType' is not null or undefined
            assertParamExists('getSetting', 'settingType', settingType)
            const localVarPath = `/api/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (settingType !== undefined) {
                localVarQueryParameter['settingType'] = settingType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetSetting200Response} getSetting200Response 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (getSetting200Response: GetSetting200Response, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSetting200Response' is not null or undefined
            assertParamExists('updateSettings', 'getSetting200Response', getSetting200Response)
            const localVarPath = `/api/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSetting200Response, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsControllerApi - functional programming interface
 * @export
 */
export const SettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OverdueDaysSettingForm} overdueDaysSettingForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editOverdueDaysSetting(overdueDaysSettingForm: OverdueDaysSettingForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editOverdueDaysSetting(overdueDaysSettingForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProvisionRatesSettingForm} provisionRatesSettingForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editProvisionRatesSetting(provisionRatesSettingForm: ProvisionRatesSettingForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editProvisionRatesSetting(provisionRatesSettingForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverdueDaysSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: StatusInterval; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverdueDaysSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvisionRatesSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvisionRatesSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SettingType} settingType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSetting(settingType: SettingType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSetting200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSetting(settingType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetSetting200Response} getSetting200Response 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(getSetting200Response: GetSetting200Response, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(getSetting200Response, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsControllerApi - factory interface
 * @export
 */
export const SettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {SettingsControllerApiEditOverdueDaysSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOverdueDaysSetting(requestParameters: SettingsControllerApiEditOverdueDaysSettingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.editOverdueDaysSetting(requestParameters.overdueDaysSettingForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettingsControllerApiEditProvisionRatesSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProvisionRatesSetting(requestParameters: SettingsControllerApiEditProvisionRatesSettingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.editProvisionRatesSetting(requestParameters.provisionRatesSettingForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverdueDaysSetting(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: StatusInterval; }> {
            return localVarFp.getOverdueDaysSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvisionRatesSetting(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.getProvisionRatesSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettingsControllerApiGetSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSetting(requestParameters: SettingsControllerApiGetSettingRequest, options?: AxiosRequestConfig): AxiosPromise<GetSetting200Response> {
            return localVarFp.getSetting(requestParameters.settingType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettingsControllerApiUpdateSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(requestParameters: SettingsControllerApiUpdateSettingsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateSettings(requestParameters.getSetting200Response, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for editOverdueDaysSetting operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiEditOverdueDaysSettingRequest
 */
export interface SettingsControllerApiEditOverdueDaysSettingRequest {
    /**
     * 
     * @type {OverdueDaysSettingForm}
     * @memberof SettingsControllerApiEditOverdueDaysSetting
     */
    readonly overdueDaysSettingForm: OverdueDaysSettingForm
}

/**
 * Request parameters for editProvisionRatesSetting operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiEditProvisionRatesSettingRequest
 */
export interface SettingsControllerApiEditProvisionRatesSettingRequest {
    /**
     * 
     * @type {ProvisionRatesSettingForm}
     * @memberof SettingsControllerApiEditProvisionRatesSetting
     */
    readonly provisionRatesSettingForm: ProvisionRatesSettingForm
}

/**
 * Request parameters for getSetting operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiGetSettingRequest
 */
export interface SettingsControllerApiGetSettingRequest {
    /**
     * 
     * @type {SettingType}
     * @memberof SettingsControllerApiGetSetting
     */
    readonly settingType: SettingType
}

/**
 * Request parameters for updateSettings operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateSettingsRequest
 */
export interface SettingsControllerApiUpdateSettingsRequest {
    /**
     * 
     * @type {GetSetting200Response}
     * @memberof SettingsControllerApiUpdateSettings
     */
    readonly getSetting200Response: GetSetting200Response
}

/**
 * SettingsControllerApi - object-oriented interface
 * @export
 * @class SettingsControllerApi
 * @extends {BaseAPI}
 */
export class SettingsControllerApi extends BaseAPI {
    /**
     * 
     * @param {SettingsControllerApiEditOverdueDaysSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public editOverdueDaysSetting(requestParameters: SettingsControllerApiEditOverdueDaysSettingRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).editOverdueDaysSetting(requestParameters.overdueDaysSettingForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettingsControllerApiEditProvisionRatesSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public editProvisionRatesSetting(requestParameters: SettingsControllerApiEditProvisionRatesSettingRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).editProvisionRatesSetting(requestParameters.provisionRatesSettingForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getOverdueDaysSetting(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getOverdueDaysSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getProvisionRatesSetting(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getProvisionRatesSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettingsControllerApiGetSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getSetting(requestParameters: SettingsControllerApiGetSettingRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getSetting(requestParameters.settingType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettingsControllerApiUpdateSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateSettings(requestParameters: SettingsControllerApiUpdateSettingsRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateSettings(requestParameters.getSetting200Response, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TaskControllerApi - axios parameter creator
 * @export
 */
export const TaskControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {Assignee} assignee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignTask: async (id: string, assignee: Assignee, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignTask', 'id', id)
            // verify required parameter 'assignee' is not null or undefined
            assertParamExists('assignTask', 'assignee', assignee)
            const localVarPath = `/api/tasks/{id}/assignee`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignee, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('claimTask', 'id', id)
            const localVarPath = `/api/tasks/{id}/claim`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetadataTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMetadataTask', 'id', id)
            const localVarPath = `/api/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTask', 'id', id)
            const localVarPath = `/api/tasks/{id}/variables`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [roleName] 
         * @param {string} [userName] 
         * @param {UserActivity} [userActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskCandidateUsers: async (id: string, roleName?: string, userName?: string, userActivity?: UserActivity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaskCandidateUsers', 'id', id)
            const localVarPath = `/api/tasks/{id}/candidate-users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (roleName !== undefined) {
                localVarQueryParameter['roleName'] = roleName;
            }

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (userActivity !== undefined) {
                localVarQueryParameter['userActivity'] = userActivity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} assignedToMe 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<TaskName>} [taskNames] 
         * @param {Array<ProcessName>} [processNames] 
         * @param {Array<TaskStatus>} [taskStatuses] 
         * @param {number} [loanApplicationId] 
         * @param {string} [assigneeName] 
         * @param {string} [individualName] 
         * @param {number} [requestedAmount] 
         * @param {number} [productLineId] 
         * @param {string} [createdAt] 
         * @param {string} [completedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks: async (assignedToMe: boolean, page?: number, size?: number, sort?: Array<string>, taskNames?: Array<TaskName>, processNames?: Array<ProcessName>, taskStatuses?: Array<TaskStatus>, loanApplicationId?: number, assigneeName?: string, individualName?: string, requestedAmount?: number, productLineId?: number, createdAt?: string, completedAt?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignedToMe' is not null or undefined
            assertParamExists('getTasks', 'assignedToMe', assignedToMe)
            const localVarPath = `/api/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (assignedToMe !== undefined) {
                localVarQueryParameter['assignedToMe'] = assignedToMe;
            }

            if (taskNames) {
                localVarQueryParameter['taskNames'] = taskNames;
            }

            if (processNames) {
                localVarQueryParameter['processNames'] = processNames;
            }

            if (taskStatuses) {
                localVarQueryParameter['taskStatuses'] = taskStatuses;
            }

            if (loanApplicationId !== undefined) {
                localVarQueryParameter['loanApplicationId'] = loanApplicationId;
            }

            if (assigneeName !== undefined) {
                localVarQueryParameter['assigneeName'] = assigneeName;
            }

            if (individualName !== undefined) {
                localVarQueryParameter['individualName'] = individualName;
            }

            if (requestedAmount !== undefined) {
                localVarQueryParameter['requestedAmount'] = requestedAmount;
            }

            if (productLineId !== undefined) {
                localVarQueryParameter['productLineId'] = productLineId;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['createdAt'] = (createdAt as any instanceof Date) ?
                    (createdAt as any).toISOString().substring(0,10) :
                    createdAt;
            }

            if (completedAt !== undefined) {
                localVarQueryParameter['completedAt'] = (completedAt as any instanceof Date) ?
                    (completedAt as any).toISOString().substring(0,10) :
                    completedAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SubmitTaskDecisionRequest} submitTaskDecisionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTaskDecision: async (id: string, submitTaskDecisionRequest: SubmitTaskDecisionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submitTaskDecision', 'id', id)
            // verify required parameter 'submitTaskDecisionRequest' is not null or undefined
            assertParamExists('submitTaskDecision', 'submitTaskDecisionRequest', submitTaskDecisionRequest)
            const localVarPath = `/api/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitTaskDecisionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unclaimTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unclaimTask', 'id', id)
            const localVarPath = `/api/tasks/{id}/unclaim`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskControllerApi - functional programming interface
 * @export
 */
export const TaskControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {Assignee} assignee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignTask(id: string, assignee: Assignee, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignTask(id, assignee, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetadataTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskMetadata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetadataTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTask200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [roleName] 
         * @param {string} [userName] 
         * @param {UserActivity} [userActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskCandidateUsers(id: string, roleName?: string, userName?: string, userActivity?: UserActivity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskCandidateBoUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskCandidateUsers(id, roleName, userName, userActivity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} assignedToMe 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<TaskName>} [taskNames] 
         * @param {Array<ProcessName>} [processNames] 
         * @param {Array<TaskStatus>} [taskStatuses] 
         * @param {number} [loanApplicationId] 
         * @param {string} [assigneeName] 
         * @param {string} [individualName] 
         * @param {number} [requestedAmount] 
         * @param {number} [productLineId] 
         * @param {string} [createdAt] 
         * @param {string} [completedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTasks(assignedToMe: boolean, page?: number, size?: number, sort?: Array<string>, taskNames?: Array<TaskName>, processNames?: Array<ProcessName>, taskStatuses?: Array<TaskStatus>, loanApplicationId?: number, assigneeName?: string, individualName?: string, requestedAmount?: number, productLineId?: number, createdAt?: string, completedAt?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTaskMetadataListEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTasks(assignedToMe, page, size, sort, taskNames, processNames, taskStatuses, loanApplicationId, assigneeName, individualName, requestedAmount, productLineId, createdAt, completedAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SubmitTaskDecisionRequest} submitTaskDecisionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitTaskDecision(id: string, submitTaskDecisionRequest: SubmitTaskDecisionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitTaskDecision(id, submitTaskDecisionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unclaimTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unclaimTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskControllerApi - factory interface
 * @export
 */
export const TaskControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {TaskControllerApiAssignTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignTask(requestParameters: TaskControllerApiAssignTaskRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.assignTask(requestParameters.id, requestParameters.assignee, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaskControllerApiClaimTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimTask(requestParameters: TaskControllerApiClaimTaskRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.claimTask(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaskControllerApiGetMetadataTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetadataTask(requestParameters: TaskControllerApiGetMetadataTaskRequest, options?: AxiosRequestConfig): AxiosPromise<TaskMetadata> {
            return localVarFp.getMetadataTask(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaskControllerApiGetTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask(requestParameters: TaskControllerApiGetTaskRequest, options?: AxiosRequestConfig): AxiosPromise<GetTask200Response> {
            return localVarFp.getTask(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaskControllerApiGetTaskCandidateUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskCandidateUsers(requestParameters: TaskControllerApiGetTaskCandidateUsersRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TaskCandidateBoUser>> {
            return localVarFp.getTaskCandidateUsers(requestParameters.id, requestParameters.roleName, requestParameters.userName, requestParameters.userActivity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaskControllerApiGetTasksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(requestParameters: TaskControllerApiGetTasksRequest, options?: AxiosRequestConfig): AxiosPromise<PageTaskMetadataListEntry> {
            return localVarFp.getTasks(requestParameters.assignedToMe, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.taskNames, requestParameters.processNames, requestParameters.taskStatuses, requestParameters.loanApplicationId, requestParameters.assigneeName, requestParameters.individualName, requestParameters.requestedAmount, requestParameters.productLineId, requestParameters.createdAt, requestParameters.completedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaskControllerApiSubmitTaskDecisionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTaskDecision(requestParameters: TaskControllerApiSubmitTaskDecisionRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.submitTaskDecision(requestParameters.id, requestParameters.submitTaskDecisionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaskControllerApiUnclaimTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unclaimTask(requestParameters: TaskControllerApiUnclaimTaskRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.unclaimTask(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignTask operation in TaskControllerApi.
 * @export
 * @interface TaskControllerApiAssignTaskRequest
 */
export interface TaskControllerApiAssignTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiAssignTask
     */
    readonly id: string

    /**
     * 
     * @type {Assignee}
     * @memberof TaskControllerApiAssignTask
     */
    readonly assignee: Assignee
}

/**
 * Request parameters for claimTask operation in TaskControllerApi.
 * @export
 * @interface TaskControllerApiClaimTaskRequest
 */
export interface TaskControllerApiClaimTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiClaimTask
     */
    readonly id: string
}

/**
 * Request parameters for getMetadataTask operation in TaskControllerApi.
 * @export
 * @interface TaskControllerApiGetMetadataTaskRequest
 */
export interface TaskControllerApiGetMetadataTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiGetMetadataTask
     */
    readonly id: string
}

/**
 * Request parameters for getTask operation in TaskControllerApi.
 * @export
 * @interface TaskControllerApiGetTaskRequest
 */
export interface TaskControllerApiGetTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiGetTask
     */
    readonly id: string
}

/**
 * Request parameters for getTaskCandidateUsers operation in TaskControllerApi.
 * @export
 * @interface TaskControllerApiGetTaskCandidateUsersRequest
 */
export interface TaskControllerApiGetTaskCandidateUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiGetTaskCandidateUsers
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiGetTaskCandidateUsers
     */
    readonly roleName?: string

    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiGetTaskCandidateUsers
     */
    readonly userName?: string

    /**
     * 
     * @type {UserActivity}
     * @memberof TaskControllerApiGetTaskCandidateUsers
     */
    readonly userActivity?: UserActivity
}

/**
 * Request parameters for getTasks operation in TaskControllerApi.
 * @export
 * @interface TaskControllerApiGetTasksRequest
 */
export interface TaskControllerApiGetTasksRequest {
    /**
     * 
     * @type {boolean}
     * @memberof TaskControllerApiGetTasks
     */
    readonly assignedToMe: boolean

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof TaskControllerApiGetTasks
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof TaskControllerApiGetTasks
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof TaskControllerApiGetTasks
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {Array<TaskName>}
     * @memberof TaskControllerApiGetTasks
     */
    readonly taskNames?: Array<TaskName>

    /**
     * 
     * @type {Array<ProcessName>}
     * @memberof TaskControllerApiGetTasks
     */
    readonly processNames?: Array<ProcessName>

    /**
     * 
     * @type {Array<TaskStatus>}
     * @memberof TaskControllerApiGetTasks
     */
    readonly taskStatuses?: Array<TaskStatus>

    /**
     * 
     * @type {number}
     * @memberof TaskControllerApiGetTasks
     */
    readonly loanApplicationId?: number

    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiGetTasks
     */
    readonly assigneeName?: string

    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiGetTasks
     */
    readonly individualName?: string

    /**
     * 
     * @type {number}
     * @memberof TaskControllerApiGetTasks
     */
    readonly requestedAmount?: number

    /**
     * 
     * @type {number}
     * @memberof TaskControllerApiGetTasks
     */
    readonly productLineId?: number

    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiGetTasks
     */
    readonly createdAt?: string

    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiGetTasks
     */
    readonly completedAt?: string
}

/**
 * Request parameters for submitTaskDecision operation in TaskControllerApi.
 * @export
 * @interface TaskControllerApiSubmitTaskDecisionRequest
 */
export interface TaskControllerApiSubmitTaskDecisionRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiSubmitTaskDecision
     */
    readonly id: string

    /**
     * 
     * @type {SubmitTaskDecisionRequest}
     * @memberof TaskControllerApiSubmitTaskDecision
     */
    readonly submitTaskDecisionRequest: SubmitTaskDecisionRequest
}

/**
 * Request parameters for unclaimTask operation in TaskControllerApi.
 * @export
 * @interface TaskControllerApiUnclaimTaskRequest
 */
export interface TaskControllerApiUnclaimTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskControllerApiUnclaimTask
     */
    readonly id: string
}

/**
 * TaskControllerApi - object-oriented interface
 * @export
 * @class TaskControllerApi
 * @extends {BaseAPI}
 */
export class TaskControllerApi extends BaseAPI {
    /**
     * 
     * @param {TaskControllerApiAssignTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public assignTask(requestParameters: TaskControllerApiAssignTaskRequest, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).assignTask(requestParameters.id, requestParameters.assignee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskControllerApiClaimTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public claimTask(requestParameters: TaskControllerApiClaimTaskRequest, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).claimTask(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskControllerApiGetMetadataTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getMetadataTask(requestParameters: TaskControllerApiGetMetadataTaskRequest, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getMetadataTask(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskControllerApiGetTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getTask(requestParameters: TaskControllerApiGetTaskRequest, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getTask(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskControllerApiGetTaskCandidateUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getTaskCandidateUsers(requestParameters: TaskControllerApiGetTaskCandidateUsersRequest, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getTaskCandidateUsers(requestParameters.id, requestParameters.roleName, requestParameters.userName, requestParameters.userActivity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskControllerApiGetTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getTasks(requestParameters: TaskControllerApiGetTasksRequest, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getTasks(requestParameters.assignedToMe, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.taskNames, requestParameters.processNames, requestParameters.taskStatuses, requestParameters.loanApplicationId, requestParameters.assigneeName, requestParameters.individualName, requestParameters.requestedAmount, requestParameters.productLineId, requestParameters.createdAt, requestParameters.completedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskControllerApiSubmitTaskDecisionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public submitTaskDecision(requestParameters: TaskControllerApiSubmitTaskDecisionRequest, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).submitTaskDecision(requestParameters.id, requestParameters.submitTaskDecisionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskControllerApiUnclaimTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public unclaimTask(requestParameters: TaskControllerApiUnclaimTaskRequest, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).unclaimTask(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionControllerApi - axios parameter creator
 * @export
 */
export const TransactionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {AssignLoanRequest} assignLoanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignToLoan: async (id: number, assignLoanRequest: AssignLoanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignToLoan', 'id', id)
            // verify required parameter 'assignLoanRequest' is not null or undefined
            assertParamExists('assignToLoan', 'assignLoanRequest', assignLoanRequest)
            const localVarPath = `/api/transactions/{id}/assignToLoan`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignLoanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ChangeBankStatementStatusForm} changeBankStatementStatusForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatus: async (id: number, changeBankStatementStatusForm: ChangeBankStatementStatusForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeStatus', 'id', id)
            // verify required parameter 'changeBankStatementStatusForm' is not null or undefined
            assertParamExists('changeStatus', 'changeBankStatementStatusForm', changeBankStatementStatusForm)
            const localVarPath = `/api/transactions/{id}/changeStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeBankStatementStatusForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [selfAccounts] 
         * @param {string} [transactionDateFrom] 
         * @param {string} [transactionDateTo] 
         * @param {string} [accountNumber] 
         * @param {Array<BankStatementOperationType>} [operationTypes] 
         * @param {Array<BankStatementStatus>} [bankStatementStatuses] 
         * @param {string} [journalNo] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllBankStatements: async (page?: number, size?: number, sort?: Array<string>, selfAccounts?: Array<string>, transactionDateFrom?: string, transactionDateTo?: string, accountNumber?: string, operationTypes?: Array<BankStatementOperationType>, bankStatementStatuses?: Array<BankStatementStatus>, journalNo?: string, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (selfAccounts) {
                localVarQueryParameter['selfAccounts'] = selfAccounts;
            }

            if (transactionDateFrom !== undefined) {
                localVarQueryParameter['transactionDateFrom'] = (transactionDateFrom as any instanceof Date) ?
                    (transactionDateFrom as any).toISOString().substring(0,10) :
                    transactionDateFrom;
            }

            if (transactionDateTo !== undefined) {
                localVarQueryParameter['transactionDateTo'] = (transactionDateTo as any instanceof Date) ?
                    (transactionDateTo as any).toISOString().substring(0,10) :
                    transactionDateTo;
            }

            if (accountNumber !== undefined) {
                localVarQueryParameter['accountNumber'] = accountNumber;
            }

            if (operationTypes) {
                localVarQueryParameter['operationTypes'] = operationTypes;
            }

            if (bankStatementStatuses) {
                localVarQueryParameter['bankStatementStatuses'] = bankStatementStatuses;
            }

            if (journalNo !== undefined) {
                localVarQueryParameter['journalNo'] = journalNo;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [customerName] 
         * @param {string} [registrationNumber] 
         * @param {string} [phone] 
         * @param {string} [contractId] 
         * @param {string} [accountNumber] 
         * @param {number} [repaymentAmount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTransactionLoans: async (id: number, page?: number, size?: number, sort?: Array<string>, customerName?: string, registrationNumber?: string, phone?: string, contractId?: string, accountNumber?: string, repaymentAmount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findTransactionLoans', 'id', id)
            const localVarPath = `/api/transactions/{id}/loans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (customerName !== undefined) {
                localVarQueryParameter['customerName'] = customerName;
            }

            if (registrationNumber !== undefined) {
                localVarQueryParameter['registrationNumber'] = registrationNumber;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (accountNumber !== undefined) {
                localVarQueryParameter['accountNumber'] = accountNumber;
            }

            if (repaymentAmount !== undefined) {
                localVarQueryParameter['repaymentAmount'] = repaymentAmount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSelfAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/transactions/getAllAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/transactions/getAvailableStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankStatement: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBankStatement', 'id', id)
            const localVarPath = `/api/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionControllerApi - functional programming interface
 * @export
 */
export const TransactionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {AssignLoanRequest} assignLoanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignToLoan(id: number, assignLoanRequest: AssignLoanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignToLoan(id, assignLoanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ChangeBankStatementStatusForm} changeBankStatementStatusForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStatus(id: number, changeBankStatementStatusForm: ChangeBankStatementStatusForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStatus(id, changeBankStatementStatusForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [selfAccounts] 
         * @param {string} [transactionDateFrom] 
         * @param {string} [transactionDateTo] 
         * @param {string} [accountNumber] 
         * @param {Array<BankStatementOperationType>} [operationTypes] 
         * @param {Array<BankStatementStatus>} [bankStatementStatuses] 
         * @param {string} [journalNo] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllBankStatements(page?: number, size?: number, sort?: Array<string>, selfAccounts?: Array<string>, transactionDateFrom?: string, transactionDateTo?: string, accountNumber?: string, operationTypes?: Array<BankStatementOperationType>, bankStatementStatuses?: Array<BankStatementStatus>, journalNo?: string, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageBankStatementListItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllBankStatements(page, size, sort, selfAccounts, transactionDateFrom, transactionDateTo, accountNumber, operationTypes, bankStatementStatuses, journalNo, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [customerName] 
         * @param {string} [registrationNumber] 
         * @param {string} [phone] 
         * @param {string} [contractId] 
         * @param {string} [accountNumber] 
         * @param {number} [repaymentAmount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTransactionLoans(id: number, page?: number, size?: number, sort?: Array<string>, customerName?: string, registrationNumber?: string, phone?: string, contractId?: string, accountNumber?: string, repaymentAmount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTransactionLoans(id, page, size, sort, customerName, registrationNumber, phone, contractId, accountNumber, repaymentAmount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSelfAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSelfAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableStatuses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BankStatementStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableStatuses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankStatement(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankStatementListItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankStatement(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionControllerApi - factory interface
 * @export
 */
export const TransactionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {TransactionControllerApiAssignToLoanRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignToLoan(requestParameters: TransactionControllerApiAssignToLoanRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.assignToLoan(requestParameters.id, requestParameters.assignLoanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TransactionControllerApiChangeStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatus(requestParameters: TransactionControllerApiChangeStatusRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.changeStatus(requestParameters.id, requestParameters.changeBankStatementStatusForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TransactionControllerApiFindAllBankStatementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllBankStatements(requestParameters: TransactionControllerApiFindAllBankStatementsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageBankStatementListItem> {
            return localVarFp.findAllBankStatements(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.selfAccounts, requestParameters.transactionDateFrom, requestParameters.transactionDateTo, requestParameters.accountNumber, requestParameters.operationTypes, requestParameters.bankStatementStatuses, requestParameters.journalNo, requestParameters.description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TransactionControllerApiFindTransactionLoansRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTransactionLoans(requestParameters: TransactionControllerApiFindTransactionLoansRequest, options?: AxiosRequestConfig): AxiosPromise<PageLoanDetails> {
            return localVarFp.findTransactionLoans(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.customerName, requestParameters.registrationNumber, requestParameters.phone, requestParameters.contractId, requestParameters.accountNumber, requestParameters.repaymentAmount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSelfAccounts(options?: AxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getAllSelfAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableStatuses(options?: AxiosRequestConfig): AxiosPromise<Array<BankStatementStatus>> {
            return localVarFp.getAvailableStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TransactionControllerApiGetBankStatementRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankStatement(requestParameters: TransactionControllerApiGetBankStatementRequest, options?: AxiosRequestConfig): AxiosPromise<BankStatementListItem> {
            return localVarFp.getBankStatement(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignToLoan operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiAssignToLoanRequest
 */
export interface TransactionControllerApiAssignToLoanRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionControllerApiAssignToLoan
     */
    readonly id: number

    /**
     * 
     * @type {AssignLoanRequest}
     * @memberof TransactionControllerApiAssignToLoan
     */
    readonly assignLoanRequest: AssignLoanRequest
}

/**
 * Request parameters for changeStatus operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiChangeStatusRequest
 */
export interface TransactionControllerApiChangeStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionControllerApiChangeStatus
     */
    readonly id: number

    /**
     * 
     * @type {ChangeBankStatementStatusForm}
     * @memberof TransactionControllerApiChangeStatus
     */
    readonly changeBankStatementStatusForm: ChangeBankStatementStatusForm
}

/**
 * Request parameters for findAllBankStatements operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiFindAllBankStatementsRequest
 */
export interface TransactionControllerApiFindAllBankStatementsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof TransactionControllerApiFindAllBankStatements
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof TransactionControllerApiFindAllBankStatements
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof TransactionControllerApiFindAllBankStatements
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionControllerApiFindAllBankStatements
     */
    readonly selfAccounts?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiFindAllBankStatements
     */
    readonly transactionDateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiFindAllBankStatements
     */
    readonly transactionDateTo?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiFindAllBankStatements
     */
    readonly accountNumber?: string

    /**
     * 
     * @type {Array<BankStatementOperationType>}
     * @memberof TransactionControllerApiFindAllBankStatements
     */
    readonly operationTypes?: Array<BankStatementOperationType>

    /**
     * 
     * @type {Array<BankStatementStatus>}
     * @memberof TransactionControllerApiFindAllBankStatements
     */
    readonly bankStatementStatuses?: Array<BankStatementStatus>

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiFindAllBankStatements
     */
    readonly journalNo?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiFindAllBankStatements
     */
    readonly description?: string
}

/**
 * Request parameters for findTransactionLoans operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiFindTransactionLoansRequest
 */
export interface TransactionControllerApiFindTransactionLoansRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionControllerApiFindTransactionLoans
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof TransactionControllerApiFindTransactionLoans
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof TransactionControllerApiFindTransactionLoans
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof TransactionControllerApiFindTransactionLoans
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiFindTransactionLoans
     */
    readonly customerName?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiFindTransactionLoans
     */
    readonly registrationNumber?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiFindTransactionLoans
     */
    readonly phone?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiFindTransactionLoans
     */
    readonly contractId?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiFindTransactionLoans
     */
    readonly accountNumber?: string

    /**
     * 
     * @type {number}
     * @memberof TransactionControllerApiFindTransactionLoans
     */
    readonly repaymentAmount?: number
}

/**
 * Request parameters for getBankStatement operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiGetBankStatementRequest
 */
export interface TransactionControllerApiGetBankStatementRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionControllerApiGetBankStatement
     */
    readonly id: number
}

/**
 * TransactionControllerApi - object-oriented interface
 * @export
 * @class TransactionControllerApi
 * @extends {BaseAPI}
 */
export class TransactionControllerApi extends BaseAPI {
    /**
     * 
     * @param {TransactionControllerApiAssignToLoanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public assignToLoan(requestParameters: TransactionControllerApiAssignToLoanRequest, options?: AxiosRequestConfig) {
        return TransactionControllerApiFp(this.configuration).assignToLoan(requestParameters.id, requestParameters.assignLoanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransactionControllerApiChangeStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public changeStatus(requestParameters: TransactionControllerApiChangeStatusRequest, options?: AxiosRequestConfig) {
        return TransactionControllerApiFp(this.configuration).changeStatus(requestParameters.id, requestParameters.changeBankStatementStatusForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransactionControllerApiFindAllBankStatementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public findAllBankStatements(requestParameters: TransactionControllerApiFindAllBankStatementsRequest = {}, options?: AxiosRequestConfig) {
        return TransactionControllerApiFp(this.configuration).findAllBankStatements(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.selfAccounts, requestParameters.transactionDateFrom, requestParameters.transactionDateTo, requestParameters.accountNumber, requestParameters.operationTypes, requestParameters.bankStatementStatuses, requestParameters.journalNo, requestParameters.description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransactionControllerApiFindTransactionLoansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public findTransactionLoans(requestParameters: TransactionControllerApiFindTransactionLoansRequest, options?: AxiosRequestConfig) {
        return TransactionControllerApiFp(this.configuration).findTransactionLoans(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.customerName, requestParameters.registrationNumber, requestParameters.phone, requestParameters.contractId, requestParameters.accountNumber, requestParameters.repaymentAmount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public getAllSelfAccounts(options?: AxiosRequestConfig) {
        return TransactionControllerApiFp(this.configuration).getAllSelfAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public getAvailableStatuses(options?: AxiosRequestConfig) {
        return TransactionControllerApiFp(this.configuration).getAvailableStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransactionControllerApiGetBankStatementRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public getBankStatement(requestParameters: TransactionControllerApiGetBankStatementRequest, options?: AxiosRequestConfig) {
        return TransactionControllerApiFp(this.configuration).getBankStatement(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UiSettingsControllerApi - axios parameter creator
 * @export
 */
export const UiSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUiSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ui-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UiSettingsControllerApi - functional programming interface
 * @export
 */
export const UiSettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UiSettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUiSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUiSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UiSettingsControllerApi - factory interface
 * @export
 */
export const UiSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UiSettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUiSettings(options?: AxiosRequestConfig): AxiosPromise<UiSettings> {
            return localVarFp.getUiSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UiSettingsControllerApi - object-oriented interface
 * @export
 * @class UiSettingsControllerApi
 * @extends {BaseAPI}
 */
export class UiSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UiSettingsControllerApi
     */
    public getUiSettings(options?: AxiosRequestConfig) {
        return UiSettingsControllerApiFp(this.configuration).getUiSettings(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activateUser', 'id', id)
            const localVarPath = `/api/users/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserForm} createUserForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserForm: CreateUserForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserForm' is not null or undefined
            assertParamExists('createUser', 'createUserForm', createUserForm)
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateUser', 'id', id)
            const localVarPath = `/api/users/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendRegistrationLink: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resendRegistrationLink', 'id', id)
            const localVarPath = `/api/users/{id}/resendRegistrationLink`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UserActivityUpdate} userActivityUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleActivityUser: async (id: number, userActivityUpdate: UserActivityUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('toggleActivityUser', 'id', id)
            // verify required parameter 'userActivityUpdate' is not null or undefined
            assertParamExists('toggleActivityUser', 'userActivityUpdate', userActivityUpdate)
            const localVarPath = `/api/users/{id}/activity`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userActivityUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EditUserForm} editUserForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: number, editUserForm: EditUserForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'editUserForm' is not null or undefined
            assertParamExists('updateUser', 'editUserForm', editUserForm)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUserForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('user', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [name] 
         * @param {string} [phone] 
         * @param {Array<UserStatus>} [statuses] 
         * @param {boolean} [registrationCompleted] 
         * @param {Array<number>} [roleIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        users: async (page?: number, size?: number, sort?: Array<string>, name?: string, phone?: string, statuses?: Array<UserStatus>, registrationCompleted?: boolean, roleIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (registrationCompleted !== undefined) {
                localVarQueryParameter['registrationCompleted'] = registrationCompleted;
            }

            if (roleIds) {
                localVarQueryParameter['roleIds'] = roleIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserForm} createUserForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserForm: CreateUserForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendRegistrationLink(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendRegistrationLink(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UserActivityUpdate} userActivityUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleActivityUser(id: number, userActivityUpdate: UserActivityUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleActivityUser(id, userActivityUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EditUserForm} editUserForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: number, editUserForm: EditUserForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, editUserForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async user(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.user(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [name] 
         * @param {string} [phone] 
         * @param {Array<UserStatus>} [statuses] 
         * @param {boolean} [registrationCompleted] 
         * @param {Array<number>} [roleIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async users(page?: number, size?: number, sort?: Array<string>, name?: string, phone?: string, statuses?: Array<UserStatus>, registrationCompleted?: boolean, roleIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserListItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.users(page, size, sort, name, phone, statuses, registrationCompleted, roleIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {UserControllerApiActivateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser(requestParameters: UserControllerApiActivateUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.activateUser(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserControllerApiCreateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(requestParameters: UserControllerApiCreateUserRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createUser(requestParameters.createUserForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserControllerApiDeactivateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUser(requestParameters: UserControllerApiDeactivateUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deactivateUser(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserControllerApiResendRegistrationLinkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendRegistrationLink(requestParameters: UserControllerApiResendRegistrationLinkRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resendRegistrationLink(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserControllerApiToggleActivityUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleActivityUser(requestParameters: UserControllerApiToggleActivityUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.toggleActivityUser(requestParameters.id, requestParameters.userActivityUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserControllerApiUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(requestParameters: UserControllerApiUpdateUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateUser(requestParameters.id, requestParameters.editUserForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserControllerApiUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user(requestParameters: UserControllerApiUserRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.user(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserControllerApiUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        users(requestParameters: UserControllerApiUsersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageUserListItem> {
            return localVarFp.users(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.name, requestParameters.phone, requestParameters.statuses, requestParameters.registrationCompleted, requestParameters.roleIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activateUser operation in UserControllerApi.
 * @export
 * @interface UserControllerApiActivateUserRequest
 */
export interface UserControllerApiActivateUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserControllerApiActivateUser
     */
    readonly id: number
}

/**
 * Request parameters for createUser operation in UserControllerApi.
 * @export
 * @interface UserControllerApiCreateUserRequest
 */
export interface UserControllerApiCreateUserRequest {
    /**
     * 
     * @type {CreateUserForm}
     * @memberof UserControllerApiCreateUser
     */
    readonly createUserForm: CreateUserForm
}

/**
 * Request parameters for deactivateUser operation in UserControllerApi.
 * @export
 * @interface UserControllerApiDeactivateUserRequest
 */
export interface UserControllerApiDeactivateUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserControllerApiDeactivateUser
     */
    readonly id: number
}

/**
 * Request parameters for resendRegistrationLink operation in UserControllerApi.
 * @export
 * @interface UserControllerApiResendRegistrationLinkRequest
 */
export interface UserControllerApiResendRegistrationLinkRequest {
    /**
     * 
     * @type {number}
     * @memberof UserControllerApiResendRegistrationLink
     */
    readonly id: number
}

/**
 * Request parameters for toggleActivityUser operation in UserControllerApi.
 * @export
 * @interface UserControllerApiToggleActivityUserRequest
 */
export interface UserControllerApiToggleActivityUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserControllerApiToggleActivityUser
     */
    readonly id: number

    /**
     * 
     * @type {UserActivityUpdate}
     * @memberof UserControllerApiToggleActivityUser
     */
    readonly userActivityUpdate: UserActivityUpdate
}

/**
 * Request parameters for updateUser operation in UserControllerApi.
 * @export
 * @interface UserControllerApiUpdateUserRequest
 */
export interface UserControllerApiUpdateUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserControllerApiUpdateUser
     */
    readonly id: number

    /**
     * 
     * @type {EditUserForm}
     * @memberof UserControllerApiUpdateUser
     */
    readonly editUserForm: EditUserForm
}

/**
 * Request parameters for user operation in UserControllerApi.
 * @export
 * @interface UserControllerApiUserRequest
 */
export interface UserControllerApiUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserControllerApiUser
     */
    readonly id: number
}

/**
 * Request parameters for users operation in UserControllerApi.
 * @export
 * @interface UserControllerApiUsersRequest
 */
export interface UserControllerApiUsersRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof UserControllerApiUsers
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof UserControllerApiUsers
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof UserControllerApiUsers
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof UserControllerApiUsers
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof UserControllerApiUsers
     */
    readonly phone?: string

    /**
     * 
     * @type {Array<UserStatus>}
     * @memberof UserControllerApiUsers
     */
    readonly statuses?: Array<UserStatus>

    /**
     * 
     * @type {boolean}
     * @memberof UserControllerApiUsers
     */
    readonly registrationCompleted?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof UserControllerApiUsers
     */
    readonly roleIds?: Array<number>
}

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @param {UserControllerApiActivateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public activateUser(requestParameters: UserControllerApiActivateUserRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).activateUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserControllerApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public createUser(requestParameters: UserControllerApiCreateUserRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).createUser(requestParameters.createUserForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserControllerApiDeactivateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deactivateUser(requestParameters: UserControllerApiDeactivateUserRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).deactivateUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserControllerApiResendRegistrationLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public resendRegistrationLink(requestParameters: UserControllerApiResendRegistrationLinkRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).resendRegistrationLink(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserControllerApiToggleActivityUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public toggleActivityUser(requestParameters: UserControllerApiToggleActivityUserRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).toggleActivityUser(requestParameters.id, requestParameters.userActivityUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserControllerApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateUser(requestParameters: UserControllerApiUpdateUserRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateUser(requestParameters.id, requestParameters.editUserForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserControllerApiUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public user(requestParameters: UserControllerApiUserRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).user(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserControllerApiUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public users(requestParameters: UserControllerApiUsersRequest = {}, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).users(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.name, requestParameters.phone, requestParameters.statuses, requestParameters.registrationCompleted, requestParameters.roleIds, options).then((request) => request(this.axios, this.basePath));
    }
}



