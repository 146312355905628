import { observer } from 'mobx-react-lite';
import { Modal } from 'src/components/Modal/Modal';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { SubmitTaskDecisionRequest } from 'src/generated-api-client';
import { TaskViewModelClass } from 'src/pages/TaskViewPage/TaskViewModel';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type SubmitTaskDialogFnProps = ModalRendererProps<{
    viewModel: TaskViewModelClass;
    title?: React.ReactNode;
    children?: React.ReactNode;
    submitValues?: SubmitTaskDecisionRequest;
}>;

export const SubmitTaskDialogFn = observer(
    ({
        title,
        viewModel,
        children,
        submitValues,
        ...props
    }: SubmitTaskDialogFnProps) => {
        return (
            <Modal
                title={title}
                isLoading={viewModel.loadingInfo.isLoading}
                onFinish={async (values) => {
                    await viewModel.submitTask(submitValues || values);
                    if (viewModel.submitTaskLoaderHasError) {
                        return Promise.reject();
                    }
                    if (!viewModel.submitTaskLoaderHasError) {
                        Router.navigate(RoutePaths.tasks);
                    }
                }}
                initialValues={viewModel.currentTask}
                formProps={{ fields: viewModel?.submitTaskErrorFields }}
                width={640}
                {...props}
            >
                {children}
            </Modal>
        );
    },
);

export const SubmitTaskDialog = addModalRenderer(SubmitTaskDialogFn);
