import { Button } from 'antd';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { NotFound } from 'src/components/NotFound/NotFound';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { RealEstateCollateral } from 'src/generated-api-client';
import { useCollateralId } from 'src/hooks/useCollateralId';
import { CollateralDetailsBrief } from 'src/pages/CollateralDetailsPage/CollateralDetailsBrief';
import { CarCollateralGeneralInfoTab } from 'src/pages/CollateralDetailsPage/Tabs/CarCollateralTab/CarCollateralGeneralInfoTab';
import { CollateralCommentsTab } from 'src/pages/CollateralDetailsPage/Tabs/CollateralCommentsTab';
import { CollateralDocumentsTab } from 'src/pages/CollateralDetailsPage/Tabs/CollateralDocumentsTab';
import { EquipmentCollateralGeneralInfoTab } from 'src/pages/CollateralDetailsPage/Tabs/EquipmentCollateralTab/EquipmentCollateralGeneralInfoTab';
import { IntangibleAssetCollateralGeneralInfoTab } from 'src/pages/CollateralDetailsPage/Tabs/IntangibleAssetCollateralTab/IntangibleAssetCollateralGeneralInfoTab';
import { LeasingCollateralGeneralInfoTab } from 'src/pages/CollateralDetailsPage/Tabs/LeasingCollateralTab/LeasingCollateralGeneralInfoTab';
import { LivestockCollateralGeneralInfoTab } from 'src/pages/CollateralDetailsPage/Tabs/LivestockCollateralTab/LivestockCollateralGeneralInfoTab';
import { RealEstateCollateralLandInfoTab } from 'src/pages/CollateralDetailsPage/Tabs/RealEstateCollateralTab/CollateralLandInfo/RealEstateCollateralLandInfoTab';
import { RealEstateCollateralGeneralInfoTab } from 'src/pages/CollateralDetailsPage/Tabs/RealEstateCollateralTab/RealEstateCollateralGeneralInfoTab';
import { TrustAccountCollateralGeneralInfoTab } from 'src/pages/CollateralDetailsPage/Tabs/TrustAccountCollateralTab/TrustAccountCollateralGeneralInfoTab';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';

const store = CollateralsStore;

export const CollateralDetailsPage = observer(() => {
    const { collateralId } = useCollateralId();
    const generalInfoTabTitle = t('Page.CollateralDetails.Tabs.GeneralInfo');
    const collateral = store.currentItem;

    useEffect(() => {
        store.loadItem(collateralId);

        return () => {
            store.itemLoader.reset();
        };
    }, [collateralId]);

    const error = useMemo(() => {
        const showNotFound =
            !_.isInteger(Number(collateralId)) ||
            store.itemLoader.errorData?.status === 404;
        if (showNotFound) {
            return (
                <NotFound
                    title={t('Page.CollateralDetails.Error.NotFound.Title')}
                    description={t(
                        'Page.CollateralDetails.Error.NotFound.Description',
                        { collateralId },
                    )}
                >
                    <Button
                        onClick={() => {
                            Router.navigate(RoutePaths.collaterals);
                        }}
                    >
                        {t('Page.CollateralDetails.GoToList')}
                    </Button>
                </NotFound>
            );
        }

        return null;
    }, [collateralId, store.itemLoader.errorData]);

    const tabs: TabDefinition[] = [
        {
            title: generalInfoTabTitle,
            tabKey: 'General-information',
            Component: (
                <RealEstateCollateralGeneralInfoTab collateral={collateral} />
            ),
            visible: store.isRealEstateCollateral,
        },
        {
            title: t('Page.CollateralDetails.Tabs.LandInfo'),
            tabKey: 'real-estate-land-info',
            Component: (
                <RealEstateCollateralLandInfoTab
                    collateral={collateral as RealEstateCollateral}
                />
            ),
            visible: store.isRealEstateCollateral,
        },
        {
            title: generalInfoTabTitle,
            tabKey: 'livestock-general-info',
            Component: (
                <LivestockCollateralGeneralInfoTab collateral={collateral} />
            ),
            visible: store.isLivestockCollateral,
        },
        {
            title: generalInfoTabTitle,
            tabKey: 'car-general-info',
            Component: <CarCollateralGeneralInfoTab collateral={collateral} />,
            visible: store.isCarCollateral,
        },
        {
            title: generalInfoTabTitle,
            tabKey: 'leasing-general-info',
            Component: (
                <LeasingCollateralGeneralInfoTab collateral={collateral} />
            ),
            visible: store.isLeasingCollateral,
        },
        {
            title: generalInfoTabTitle,
            tabKey: 'equipment-general-info',
            Component: (
                <EquipmentCollateralGeneralInfoTab collateral={collateral} />
            ),
            visible: store.isEquipmentCollateral,
        },
        {
            title: generalInfoTabTitle,
            tabKey: 'intangible-asset-general-info',
            Component: (
                <IntangibleAssetCollateralGeneralInfoTab
                    collateral={collateral}
                />
            ),
            visible: store.isIntangibleAssetCollateral,
        },
        {
            title: generalInfoTabTitle,
            tabKey: 'trust-account-general-info',
            Component: (
                <TrustAccountCollateralGeneralInfoTab collateral={collateral} />
            ),
            visible: store.IsTrustAccountCollateral,
        },
        {
            title: t('Page.CollateralDetails.Tabs.Documents'),
            tabKey: 'documents',
            Component: (
                <CollateralDocumentsTab
                    documentRoom={store.currentItem?.documentRoom!}
                />
            ),
        },
        {
            title: t('Page.CollateralDetails.Tabs.Comments'),
            tabKey: 'comment',
            Component: (
                <CollateralCommentsTab
                    roomId={store.currentItem?.commentRoomId!}
                />
            ),
        },
    ];

    return (
        <BasePage
            header={<CollateralDetailsBrief />}
            error={error}
            isLoading={store.itemLoader.isLoading}
            showOnlyLoading={!store.currentItem}
        >
            <Tabs tabs={tabs} />
        </BasePage>
    );
});
