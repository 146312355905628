/* eslint-disable max-lines */
import { notification } from 'antd';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { StartPage } from 'src/generated-api-client';
import { IAuthStore } from 'src/stores/AuthStore/AuthStore.types';
import { AuthErrorType, isAuthError } from 'src/stores/LocalAuth/AuthErrors';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { ErrorDataHelper } from 'src/utils/ErrorDataHelper';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { UrlHelper } from 'src/utils/UrlHelper';

const LOCAL_STORE_TOKEN_KEY = 'auth';
const DIFF_BETWEEN_REAL_EXPIRATION_TOKEN_AND_VIRTUAL_MINUTES = 3;
const TIME_TO_CHECK_TOKEN = 1000 * 10; // 1000ms * n sec

const publicPaths = [
    RoutePaths.authForgotPassword,
    RoutePaths.authRecoveryPassword,
    RoutePaths.authRegistrationSetPassword,
    RoutePaths.authLogin,
    RoutePaths.authRecoveryExpiredPassword,
    RoutePaths.authRequestToRecoveryExpiredPassword,
];

export class LocalAuthStoreClass implements IAuthStore {
    @observable private authInfo?: any;
    @observable private counter = 1;
    private intervalId = 0;
    @observable isLoading?: boolean = false;

    @action init() {
        return Promise.resolve();
    }

    @action startAutoRefreshToken() {
        this.stopAutoRefreshToken();
        this.intervalId = setTimeout(() => {
            this.refreshTokenIfNeed?.();
        }, TIME_TO_CHECK_TOKEN) as any;

        return Promise.resolve();
    }

    @action stopAutoRefreshToken() {
        if (this.intervalId) {
            clearTimeout(this.intervalId);
        }

        return Promise.resolve();
    }

    resetPassword(): Promise<void> {
        throw new Error('Method not implemented.');
    }

    @action async authenticate(): Promise<void> {
        const requestedUrl = UrlHelper.getUrlWithoutHost(window.location.href);
        const { redirect } = UrlHelper.parseSearchParams(
            window.location.search,
        );

        if (this.isPublicUrl(window.location.pathname)) {
            return Router.navigate(requestedUrl);
        }

        await this.loadAuthInfoFromLocalStorage();

        if (!this.authenticated) {
            await this.tryRefreshToken();
        }

        if (this.authenticated) {
            const targetUrl =
                (redirect as string) || requestedUrl || this.getStartPageUrl();

            await this.startAutoRefreshToken();

            return Router.navigate(targetUrl);
        }

        await this.stopAutoRefreshToken();

        const newSearch = UrlHelper.stringifySearchParams({
            redirect: requestedUrl,
        });
        const targetUrl = `${RoutePaths.authLogin}?${newSearch}`;

        return Router.navigate(targetUrl);
    }

    logoutLoader = new AsyncOperationWithStatus(() => {
        console.warn('Methode "logout" not implemented');

        return Promise.reject();
    });

    @action async logout(): Promise<void> {
        this.isLoading = true;
        await this.stopAutoRefreshToken();

        await this.logoutLoader.call();
        if (ErrorDataHelper.isBaseError(this.logoutLoader.errorData)) {
            notification.error({
                message: this.logoutLoader.errorData.detail,
            });
        }
        await this.logoutLoader.reset();

        await this.setAuthInfoToLocalStorage({} as any);
        await this.loadAuthInfoFromLocalStorage();

        this.isLoading = false;
        window.location.href = RoutePaths.authLogin;
    }

    getStartPageUrl() {
        switch (this.authInfo?.startPage) {
            case StartPage.APPLICATIONS: {
                return RoutePaths.applications;
            }
            case StartPage.BANK_STATEMENTS: {
                return RoutePaths.bankStatements;
            }
            case StartPage.BRANCHES: {
                return RoutePaths.branches;
            }
            case StartPage.INDIVIDUALS: {
                return RoutePaths.individuals;
            }
            case StartPage.LOANS: {
                return RoutePaths.loans;
            }
            case StartPage.PRODUCTS: {
                return RoutePaths.products;
            }
            case StartPage.ROLES: {
                return RoutePaths.roles;
            }
            case StartPage.TASKS: {
                return RoutePaths.tasks;
            }
            case StartPage.USERS: {
                return RoutePaths.users;
            }
            default: {
                return RoutePaths.index;
            }
        }
    }

    async changePassword(): Promise<void> {
        await Router.navigate(RoutePaths.authResetPassword);
    }

    @computed get tokenExpireAt() {
        return moment(this.authInfo?.expireAt);
    }

    isValidToken() {
        if (
            this.tokenExpireAt.isAfter(
                moment().add(
                    DIFF_BETWEEN_REAL_EXPIRATION_TOKEN_AND_VIRTUAL_MINUTES,
                    'm',
                ),
            )
        ) {
            return true;
        }

        return false;
    }

    @computed get tokenRefreshExpireAt() {
        return moment(this.authInfo?.refreshExpireAt);
    }

    isValidRefreshToken() {
        if (this.tokenRefreshExpireAt.isAfter(moment()) && this.counter) {
            return true;
        }

        return false;
    }

    tokenByLoginPasswordLoader = new AsyncOperationWithStatus(
        (_login: string, _password: string) => {
            console.warn('Methode "tokenByLogin" not implemented');

            return Promise.reject();
        },
        {
            notifyError500: true,
        },
    );

    tokenRefreshTokenLoader = new AsyncOperationWithStatus(
        (_refreshToken: string) => {
            console.warn('Methode "tokenRefresh" not implemented');

            return Promise.reject();
        },
    );

    @action async getTokenByLoginAndPassword(login: string, password: string) {
        const authInfo = await this.tokenByLoginPasswordLoader.call(
            login,
            password,
        );

        if (authInfo) {
            const { redirect } = UrlHelper.parseSearchParams(
                window.location.search,
            );
            this.setAuthInfoToLocalStorage(authInfo);
            this.authInfo = authInfo;
            const targetUrl = (redirect as string) || this.getStartPageUrl();
            this.startAutoRefreshToken();
            await ProfileStore.loadProfile();

            return Router.navigate(targetUrl);
        }
    }

    @action async getTokenByRefresh(refreshToken: string) {
        const authInfo = await this.tokenRefreshTokenLoader.call(refreshToken);
        const { errorData, hasError } = this.tokenRefreshTokenLoader;

        if (authInfo) {
            this.setAuthInfoToLocalStorage(authInfo);
        }

        if (ErrorDataHelper.isBaseError(errorData) && hasError) {
            console.error(errorData.detail);
        }
    }

    @action loadAuthInfoFromLocalStorage() {
        this.authInfo = LocalStoreHelper.getItem(LOCAL_STORE_TOKEN_KEY);
    }

    setAuthInfoToLocalStorage(authInfo: any) {
        LocalStoreHelper.setItem(LOCAL_STORE_TOKEN_KEY, authInfo);
    }

    async tryRefreshToken() {
        if (!this.isValidRefreshToken || !this.authInfo?.refreshToken) {
            return;
        }

        const authInfo = await this.tokenRefreshTokenLoader.call(
            this.authInfo?.refreshToken,
        );

        if (authInfo) {
            this.setAuthInfoToLocalStorage(authInfo);
            this.authInfo = authInfo;
        }

        if (this.tokenRefreshTokenLoader.hasError) {
            await this.stopAutoRefreshToken();
            await this.logout();
        }
    }

    @computed get permissions() {
        return (this.authInfo?.authorities || []) as string[];
    }

    @computed get token() {
        return this.authInfo?.idToken;
    }

    @computed get authenticated() {
        return Boolean(this.token) && this.isValidToken();
    }

    @action async refreshTokenIfNeed() {
        await this.stopAutoRefreshToken();
        if (!this.isValidToken() && this.isValidRefreshToken()) {
            await this.tryRefreshToken();
        }
        await this.startAutoRefreshToken();
    }

    isPublicUrl(path: string) {
        return publicPaths.includes(path);
    }

    @computed get isLoginProcessing() {
        return this.tokenByLoginPasswordLoader.isLoading;
    }

    @computed get isPasswordExpired() {
        return (
            isAuthError(this.tokenByLoginPasswordLoader.errorData) &&
            this.tokenByLoginPasswordLoader.errorData.type ===
                AuthErrorType.PasswordExpired
        );
    }
}
