import { Table, TableProps } from 'antd';
import React from 'react';
import { Empty } from 'src/components/Empty/Empty';
import { IndividualDetailsScoringStoreClass } from 'src/stores/IndividualDetailsScoringStore/IndividualDetailsScoringStore';
import styled from 'styled-components';

export type CreditScoringTableProps = TableProps<GroupedItem> & {
    store?: IndividualDetailsScoringStoreClass;
};

export type GroupedItem = {
    index: number;
    title: React.ReactNode;
    ranges: React.ReactNode;
    values: React.ReactNode;
    points: number | undefined;
    groupLength: number;
};

export const getRows = (groupedData: any) => {
    const result: GroupedItem[] = [];

    groupedData.forEach((group: any) => {
        group.items.forEach((item: GroupedItem, index: number) => {
            result.push({
                ...item,
                index,
                groupLength: group.items.length,
            });
        });
    });

    return result;
};

export const CreditScoringTable = styled(
    ({ ...props }: CreditScoringTableProps) => (
        <Table
            pagination={false}
            rowClassName={(record: GroupedItem) => {
                return record.index === record.groupLength - 1
                    ? 'border-bottom'
                    : '';
            }}
            locale={{
                emptyText: <Empty />,
            }}
            {...props}
        />
    ),
)`
    margin-bottom: 70px;

    .row-span {
        border-left: 1px solid #f0f0f0;
        border-bottom: 1px solid #bfbfbf;
    }

    .border-bottom > td {
        border-bottom: 1px solid #bfbfbf;
    }
`;
