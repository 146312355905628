import { notification } from 'antd';
import { t } from 'i18next';
import { action, computed } from 'mobx';
import { ManualLoanStatusRequest } from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export class StatusActionStoreClass {
    constructor(private loanId: number) {}

    enableLawSuitStatusLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(
            loansApi.enableLawsuitStatus({ id: this.loanId }),
        ),
    );

    @action async enableLawSuitStatus() {
        await this.enableLawSuitStatusLoader.call();
        if (!this.enableLawSuitStatusLoader.hasError) {
            notification.success({
                message: t('Loan.Actions.ENABLE_LAWSUIT_STATUS.success'),
            });

            LoansStore.loadItem(this.loanId);
        }
    }

    disableLawSuitStatusLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(
            loansApi.disableLawsuitStatus({ id: this.loanId }),
        ),
    );

    @action async disableLawSuitStatus() {
        await this.disableLawSuitStatusLoader.call();
        if (!this.disableLawSuitStatusLoader.hasError) {
            notification.success({
                message: t('Loan.Actions.DISABLE_LAWSUIT_STATUS.success'),
            });

            LoansStore.loadItem(this.loanId);
        }
    }

    enableBorrowerDeathLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(
            loansApi.enableBorrowerDeathStatus({ id: this.loanId }),
        ),
    );

    @action async enableBorrowerDeathStatus() {
        await this.enableBorrowerDeathLoader.call();
        if (!this.enableBorrowerDeathLoader.hasError) {
            notification.success({
                message: t('Loan.Actions.ENABLE_BORROWER_DEATH_STATUS.success'),
            });

            LoansStore.loadItem(this.loanId);
        }
    }

    disableBorrowerDeathLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(
            loansApi.disableBorrowerDeathStatus({ id: this.loanId }),
        ),
    );

    @action async disableBorrowerDeathStatus() {
        await this.disableBorrowerDeathLoader.call();
        if (!this.disableBorrowerDeathLoader.hasError) {
            notification.success({
                message: t(
                    'Loan.Actions.DISABLE_BORROWER_DEATH_STATUS.success',
                ),
            });

            LoansStore.loadItem(this.loanId);
        }
    }

    availableManualStatusesLoader = new AsyncOperationWithStatus(
        () =>
            RequestHelper.unwrapFromAxiosPromise(
                loansApi.getManualLoanStatuses({ id: this.loanId }),
            ),
        {
            defaultIsLoading: true,
        },
    );

    @action async loadAvailableManualStatuses() {
        await this.availableManualStatusesLoader.call();
        if (!this.availableManualStatusesLoader.hasError) {
            this.reloadLoan();
        }
    }

    @computed get availableManualStatuses() {
        return this.availableManualStatusesLoader.data?.manualLoanStatuses;
    }

    enableManualStatusLoader = new AsyncOperationWithStatus(
        (data: ManualLoanStatusRequest) =>
            RequestHelper.unwrapFromAxiosPromise(
                loansApi.enableManualLoanStatus({
                    id: this.loanId,
                    manualLoanStatusRequest: data,
                }),
            ),
    );

    @action async enableManualStatus(data: ManualLoanStatusRequest) {
        await this.enableManualStatusLoader.call(data);
        if (!this.enableManualStatusLoader.hasError) {
            notification.success({
                message: t(
                    'Loan.Actions.ENABLE_MANUAL_LOAN_STATUS.success',
                    'Loan status was changed successfully',
                ),
            });
            this.reloadLoan();
        }
    }

    disableManualStatusLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(
            loansApi.disableManualLoanStatus({ id: this.loanId }),
        ),
    );

    @action async disableManualStatus() {
        await this.disableManualStatusLoader.call();
        if (!this.disableManualStatusLoader.hasError) {
            notification.success({
                message: t(
                    'Loan.Actions.DISABLE_MANUAL_LOAN_STATUS.success',
                    'Loan status was changed successfully',
                ),
            });
            this.reloadLoan();
        }
    }

    sibStatusLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(
            loansApi.patchLoan({
                id: this.loanId,
                patchLoanRequest: {
                    type: 'LoanSetCibStatusNormal',
                },
            }),
        ),
    );

    @action async setCibStatusNormal() {
        await this.sibStatusLoader.call();
        if (!this.sibStatusLoader.hasError) {
            notification.success({
                message: t(
                    'Loan.Actions.SET_CIB_STATUS_NORMAL.success',
                    'Loan CIB status was changed successfully',
                ),
            });
            this.reloadLoan();
        }
    }

    @action async reloadLoan() {
        LoansStore.itemLoader.turnOnSilentMode();
        await LoansStore.loadItem(this.loanId);
        LoansStore.itemLoader.turnOffSilentMode();
    }
}

export const StatusActionStore = new EntityProvider(StatusActionStoreClass);
