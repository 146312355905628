import { notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { FileField } from 'src/components/Form/FileField';
import {
    FileFormats,
    FileSizeUnit,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { DocumentTemplateType } from 'src/generated-api-client';
import { DocumentTemplatesStore } from 'src/stores/DocumentTemplatesStore/DocumentTemplatesStore';
import { RestrictionsStore } from 'src/stores/RestrictionsStore/RestrictionsStore';
import { ErrorDataHelper } from 'src/utils/ErrorDataHelper';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const store = RestrictionsStore;
const documentTemplatesStore = DocumentTemplatesStore;

export const ImportCsvFn = observer(({ ...props }: ModalRendererProps) => {
    useEffect(() => {
        documentTemplatesStore.loadItem(
            DocumentTemplateType.RESTRICTIONS_LIST_SAMPLE,
        );

        return () => {
            store.importCsvLoader.reset();
            documentTemplatesStore.itemLoader.reset();
        };
    }, []);

    const fields = FormHelper.getFieldDataItems(
        store.importCsvLoader.errorData,
    );

    const sampleFileUrl = documentTemplatesStore.currentItem?.file.presignedUrl;

    return (
        <Modal
            title={t('Dialog.RestrictionsListImport.Title')}
            isLoading={store.importCsvLoader.isLoading}
            onFinish={async (values) => {
                await store.importCsv(values);

                if (!store.importCsvLoader.hasError) {
                    notification.success({
                        message: t(
                            'Dialog.RestrictionsListImport.Message.Success',
                        ),
                    });
                }

                if (store.importCsvLoader.hasError) {
                    if (
                        ErrorDataHelper.isBaseError(
                            store.importCsvLoader.errorData,
                        )
                    ) {
                        notification.error({
                            message: store.importCsvLoader.errorData.detail,
                        });
                    }
                    throw new Error(store.importCsvLoader.errorData);
                }
            }}
            {...props}
            formProps={{ fields }}
        >
            <FileField
                name="file"
                label={
                    <StyledWrapper>
                        <DataView
                            hideLabel
                            value={t(
                                'Dialog.RestrictionsListImport.UploadFile',
                            )}
                        />
                        <DataView
                            hideLabel
                            value={t(
                                'Dialog.RestrictionsListImport.UploadFile.SampleFile',
                            )}
                            format={sampleFileUrl ? 'link-external' : undefined}
                            formatterProps={
                                sampleFileUrl
                                    ? {
                                          to: sampleFileUrl,
                                      }
                                    : undefined
                            }
                        />
                        <DataView
                            hideLabel
                            value={t(
                                'Dialog.RestrictionsListImport.UploadFile.Format',
                            )}
                        />
                    </StyledWrapper>
                }
                accept={FileFormats.csv}
                rules={[
                    ValidationRules.required,
                    ValidationRules.maxFileSize(10, FileSizeUnit.Mb),
                    ValidationRules.fileFormats([FileFormats.csv]),
                ]}
            />
        </Modal>
    );
});

export const ImportCsv = addModalRenderer(ImportCsvFn);

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    gap: 4px;
`;
