import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { LivestockCollateral } from 'src/generated-api-client';
import { getLivestockCollateralGeneralInfoActions } from 'src/pages/CollateralDetailsPage/Tabs/LivestockCollateralTab/LivestockCollateralGeneralInfoActions.config';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';

export const LivestockCollateralGeneralInfo = observer(() => {
    const collateral = CollateralsStore.currentItem as LivestockCollateral;
    const menuActions = getLivestockCollateralGeneralInfoActions(collateral);

    return (
        <Card
            title={t('Page.CollateralDetails.Tabs.Livestock.GeneralInfo.Title')}
            menuActions={menuActions}
        >
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral?.herderInformation}
                        label={t(
                            'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.HerderInformation',
                        )}
                        format={(value) => (
                            <EnumView
                                enumName="HERDER_INFORMATION"
                                value={value}
                            />
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.herdingNumber}
                        label={t(
                            'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.HerdingNumber',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.livestockTotalCount}
                        label={t(
                            'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.TotalCount',
                        )}
                        format="string"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.weightedNumberIntoSheep}
                        label={t(
                            'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.WeightedNumberIntoSheep',
                        )}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral?.livestockTotalAmount}
                        label={t(
                            'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.LivestockTotalAmount',
                        )}
                        format="money"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.additionalField}
                        label={t(
                            'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.AdditionalField',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.ownershipType}
                        label={t(
                            'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.OwnershipType',
                        )}
                        format={(value) => (
                            <EnumView
                                enumName="COLLATERAL_OWNERSHIP_TYPE"
                                value={value}
                            />
                        )}
                    />
                </Col>
            </Row>
        </Card>
    );
});
