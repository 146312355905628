import {
    CustomerGroupCompany,
    NewCustomerGroupCompany,
} from 'src/generated-api-client';
import { customerGroupsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export class CustomerGroupCompaniesStoreClass extends BasicStore<
    CustomerGroupCompany | any
> {
    constructor(private groupId: number) {
        super();
    }

    api: BasicStoreApi<CustomerGroupCompany, any, NewCustomerGroupCompany> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                customerGroupsApi.getCustomerGroupCompanies({
                    ...this.listParams,
                    id: this.groupId,
                }),
            );
        },
        createItem: (data): any => {
            return RequestHelper.unwrapFromAxiosPromise(
                customerGroupsApi.addCompanyToCustomerGroup({
                    id: this.groupId,
                    newCustomerGroupCompany: data,
                }),
            );
        },

        removeItem: (name) => {
            return RequestHelper.unwrapFromAxiosPromise(
                customerGroupsApi.removeCustomerGroupCompany({
                    id: this.groupId,
                    name: name as string,
                }),
            );
        },
    };

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<CustomerGroupCompany>(
        'addedAt',
        'descend',
    );
}

export const CustomerGroupCompaniesStore = new EntityProvider(
    CustomerGroupCompaniesStoreClass,
);
