import {
    CreateCoBorrowerRequest,
    UpdateCoBorrowerRequest,
} from 'src/generated-api-client';
import { coBorrowersApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export class CoBorrowersStoreClass extends BasicStore<
    any,
    any,
    UpdateCoBorrowerRequest,
    CreateCoBorrowerRequest
> {
    api: BasicStoreApi<any, UpdateCoBorrowerRequest, CreateCoBorrowerRequest> =
        {
            createItem: (data) => {
                return RequestHelper.unwrapFromAxiosPromise(
                    coBorrowersApi.createCoBorrower({
                        createCoBorrowerRequest: data,
                    }),
                );
            },

            updateItem: async (itemId, data) => {
                await RequestHelper.unwrapFromAxiosPromise(
                    coBorrowersApi.updateCoBorrower({
                        id: Number(itemId),
                        updateCoBorrowerRequest: data,
                    }),
                );
            },
        };

    pager?: Pager | undefined = new Pager();
}

export const CoBorrowersStore = new CoBorrowersStoreClass();
