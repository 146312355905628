import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { HiddenField } from 'src/components/Form/HiddenField';
import { N12Task, TaskDecisionType } from 'src/generated-api-client';
import { TaskAssigneeSelect } from 'src/pages/TaskDetailsPage/Components/TaskAssigneeSelect';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { TaskVariablesProps } from 'src/pages/TaskViewPage/TaskTypes';
import styled from 'styled-components';

export const N12ChooseLaatUserTaskApproveVariables = observer(
    ({ task }: TaskVariablesProps<N12Task>) => {
        const { loanApplication, collaterals } = task;

        return (
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <StyledText>
                        <Trans i18nKey="Page.N12ChooseLaatUserTask.Descriptions" />
                    </StyledText>
                </Col>
                <Col xs={24}>
                    <TaskLinkView
                        loanApplication={loanApplication}
                        collaterals={collaterals}
                    />
                </Col>

                <Col span={24}>
                    <Card>
                        <HiddenField
                            name="type"
                            initialValue={
                                TaskDecisionType.N1_2_CHOOSE_LAAT_USER
                            }
                        />

                        <Col span={12}>
                            <TaskAssigneeSelect
                                label={t('Task.Form.N12Task.AvailableMakers')}
                                assignees={task?.users}
                            />
                        </Col>
                    </Card>
                </Col>
            </Row>
        );
    },
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
