import { Button, Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DocumentsViewModelClass } from 'src/components/DocumentsList/DocumentsViewModel';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FileField } from 'src/components/Form/FileField';
import { SelectField } from 'src/components/Form/SelectField';
import {
    FileSizeUnit,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { CirclePlusIcon } from 'src/components/Icons/Icons';
import { Modal } from 'src/components/Modal/Modal';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type UploadFileDialogFnProps = ModalRendererProps<{
    viewModel: DocumentsViewModelClass;
    onUploadSuccess?: () => void;
}>;

export const UploadFileDialogFn = observer(
    ({ viewModel, onUploadSuccess, ...props }: UploadFileDialogFnProps) => {
        const documentTypesUploadOptions =
            viewModel?.documentTypesUploadOptions || [];

        useEffect(() => {
            viewModel.resetUploadLoadersToInitialState();

            return () => {
                viewModel.resetUploadLoadersToInitialState();
            };
        }, []);

        return (
            <Modal
                title={t('Dialog.UploadFile.Title')}
                isLoading={viewModel?.createDocumentLoadingInfo.isLoading}
                onFinish={async (values) => {
                    await viewModel?.createDocument(values);

                    if (!viewModel?.createDocumentLoaderHasError) {
                        onUploadSuccess?.();
                    }
                }}
                width={640}
                formProps={{ fields: viewModel?.createDocumentErrorField }}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={12}>
                        <SelectField
                            name="type"
                            options={documentTypesUploadOptions}
                            label={t(
                                'Dialog.UploadFile.DocumentType',
                                'Document Type',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={12}>
                        <EnumSelectField
                            name="source"
                            label={t(
                                'Dialog.UploadFile.DocumentSource',
                                'Source',
                            )}
                            enumName="DOCUMENT_SOURCE"
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={24}>
                        <FileField
                            name="file"
                            rules={[
                                ValidationRules.required,
                                ValidationRules.maxFileSize(
                                    10,
                                    FileSizeUnit.Mb,
                                ),
                            ]}
                        >
                            <Button icon={<CirclePlusIcon />} type="dashed">
                                {t('Dialog.UploadFile.File', 'Upload File')}
                            </Button>
                        </FileField>
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const UploadFileDialog = addModalRenderer(UploadFileDialogFn);
