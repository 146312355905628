import { ProvisionRatesSettingForm } from 'src/generated-api-client';
import { settingsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ManageProvisionRatesStoreClass extends BasicStore<
    { [p: string]: number },
    any,
    ProvisionRatesSettingForm,
    any,
    any
> {
    api: BasicStoreApi<
        { [p: string]: number },
        ProvisionRatesSettingForm,
        any,
        any
    > = {
        loadItem: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                settingsApi.getProvisionRatesSetting(),
            );
        },

        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                settingsApi.editProvisionRatesSetting({
                    provisionRatesSettingForm: data,
                }),
            );
        },
    };
}

export const ManageProvisionRatesStore = new ManageProvisionRatesStoreClass();
