import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ProposeActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ProposeActionDialogComponent';
import { ProposeNewGracePeriodActionStore } from 'src/stores/LoanActionsStores/ProposeNewGracePeriodActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

const { Text } = Typography;

export const ProposeNewGracePeriodDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ProposeNewGracePeriodActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ProposeNewGracePeriod.Title');

        return (
            <ProposeActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <StyledText>
                    {t('Dialog.ProposeNewGracePeriod.Text')}
                </StyledText>
            </ProposeActionDialogComponent>
        );
    },
);

export const ProposeNewGracePeriodDialog = addModalRenderer(
    ProposeNewGracePeriodDialogFn,
);

const StyledText = styled(Text)`
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #4d6082;
`;
