import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { FormHelper } from 'src/utils/FormHelper';

export const LeasingCollateralGeneralInfoUpdateVariables = observer(() => {
    return (
        <Row gutter={16}>
            <HiddenField name="type" />
            <Col xs={6}>
                <TextField
                    name="itemName"
                    label={t(
                        'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.ApproveConditions.ItemName',
                    )}
                />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="quantity"
                    label={t(
                        'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.ApproveConditions.Quantity',
                    )}
                    rules={[ValidationRules.greaterThanZero]}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="assetNumber"
                    label={t(
                        'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.ApproveConditions.AssetNumber',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="unitPrice"
                    label={t(
                        'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.ApproveConditions.UnitPrice',
                    )}
                    thousandDivider
                    rules={[ValidationRules.greaterThanZero]}
                />
            </Col>
            <Col xs={6}>
                <SelectField
                    name="hasEcoStatus"
                    label={t(
                        'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.ApproveConditions.HasEcoStatus',
                    )}
                    options={FormHelper.objAsOptions(
                        {
                            true: true,
                            false: false,
                        },
                        'Primitive.Boolean.YesNo.',
                    )}
                />
            </Col>
            <Col xs={6}>
                <EnumSelectField
                    name="ownershipType"
                    enumName="COLLATERAL_OWNERSHIP_TYPE"
                    label={t(
                        'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.ApproveConditions.OwnershipType',
                    )}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="specifications"
                    label={t(
                        'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.ApproveConditions.Specifications',
                    )}
                />
            </Col>
        </Row>
    );
});
