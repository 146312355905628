import { observer } from 'mobx-react-lite';
import { CreateCollateralEvaluationVariables } from 'src/pages/CollateralCreatePage/Components/CreateCollateralEvaluationVariables';
import { CreateRealEstateCollateralGeneralInfoVariables } from 'src/pages/CollateralCreatePage/RealEstateCollateral/CreateRealEstateCollateralGeneralInfoVariables';
import { CreateRealEstateCollateralLandInfoVariables } from 'src/pages/CollateralCreatePage/RealEstateCollateral/CreateRealEstateCollateralLandInfoVariables';

export const CreateRealEstateCollateral = observer(() => {
    return (
        <>
            <CreateRealEstateCollateralGeneralInfoVariables />
            <CreateCollateralEvaluationVariables />
            <CreateRealEstateCollateralLandInfoVariables />
        </>
    );
});
