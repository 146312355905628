import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListPage } from 'src/components/ListPage/ListPage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { LoanApplicationListEntry } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { ApplicationsHeaderPanel } from 'src/pages/ApplicationsPage/ApplicationsHeaderPanel';
import { ApplicationsStatus } from 'src/pages/ApplicationsPage/ApplicationsStatus';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const enumsToLoad: EnumName[] = ['PRODUCT_TYPE', 'LOAN_APPLICATION_STATUS'];

export const ApplicationsPage = observer(() => {
    const isLoading = useEnumsLoader(enumsToLoad);

    return (
        <ListPage
            store={ApplicationsStore}
            columns={columns}
            isLoading={isLoading}
            header={
                <ApplicationsHeaderPanel
                    title={t('Page.ApplicationsPage.Header')}
                />
            }
        />
    );
});

export const columns: ColumnsType<LoanApplicationListEntry> = [
    {
        key: 'externalId',
        dataIndex: 'externalId',
        title: <Trans i18nKey="Page.ApplicationsPage.List.Id" />,
        render(_, record) {
            return (
                <Link
                    to={UrlHelper.getTargetUrl(RoutePaths.applicationDetails, {
                        applicationId: record.id,
                    })}
                >
                    {record.externalId}
                </Link>
            );
        },
    },
    {
        key: 'productType',
        dataIndex: 'productType',
        title: <Trans i18nKey="Page.ApplicationsPage.List.Product" />,
        render(value) {
            return <EnumView enumName="PRODUCT_TYPE" value={value} />;
        },
    },
    {
        key: 'civilId',
        dataIndex: 'civilId',
        title: <Trans i18nKey="Page.ApplicationsPage.List.CivilId" />,
    },
    {
        key: 'registrationNumber',
        dataIndex: 'registrationNumber',
        title: (
            <Trans i18nKey="Page.ApplicationsPage.List.RegistrationNumber" />
        ),
    },
    {
        key: 'customerName',
        dataIndex: 'customerName',
        title: <Trans i18nKey="Page.ApplicationsPage.List.CustomerName" />,
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: <Trans i18nKey="Page.ApplicationsPage.List.Amount" />,
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'relationshipManagerId',
        dataIndex: 'relationshipManagerId',
        title: (
            <Trans i18nKey="Page.ApplicationsPage.List.RelationshipManagerId" />
        ),
    },
    {
        key: 'branchCode',
        dataIndex: 'branchCode',
        title: <Trans i18nKey="Page.ApplicationsPage.List.BranchCode" />,
    },
    {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: <Trans i18nKey="Page.ApplicationsPage.List.CreatedAt" />,
        sorter: true,
        render: (value) => {
            return (
                <DataView value={value} format="date-time-seconds" hideLabel />
            );
        },
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Page.ApplicationsPage.List.Status" />,
        render(value) {
            return <ApplicationsStatus status={value} />;
        },
    },
];
