import { NamePath } from 'antd/lib/form/interface';
import { useContext } from 'react';
import { FormItemGroupNameContext } from 'src/components/Form/FormItemGroup/FormItemGroupNameContext';

function pathToArray(path: NamePath) {
    return Array.isArray(path) ? path : [path];
}
export function useFormGroup() {
    const groupName = useContext(FormItemGroupNameContext);

    /**
     * Get form item name with form group name prefix
     * @param  {NamePath} fieldName
     * @returns {NamePath} Returns form item name with group name prefix
     */
    function getFormGroupItemName(fieldName: NamePath) {
        return [
            ...pathToArray(groupName),
            ...pathToArray(fieldName),
        ] as NamePath;
    }

    return {
        name: groupName,
        getFormGroupItemName,
    };
}
