import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { N110Task } from 'src/generated-api-client';
import { ConditionCardProps } from 'src/pages/TaskViewPage/TaskTypes';

export const RequestedCondition = observer(
    ({ task }: ConditionCardProps<N110Task>) => {
        const { requestedConditions } = task;

        return (
            <Card
                title={t(
                    'Task.Form.N110TaskApproveConditions.RequestedConditions.Title',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.createdAt}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.RequestedConditions.CreatedAt',
                            )}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.amount}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.RequestedConditions.Amount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.monthlyInterestRate}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.RequestedConditions.MonthlyInterestRate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.term}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.RequestedConditions.Term',
                            )}
                            format="number-int"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.firstRepaymentDate}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.RequestedConditions.FirstRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.secondRepaymentDate}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.RequestedConditions.SecondRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.repaymentFrequency}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.RequestedConditions.RepaymentFrequency',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="REPAYMENT_FREQUENCY"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={task?.requestedLoanPurpose}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.RequestedConditions.RequestedLoanPurpose',
                                'Requested loan purpose',
                            )}
                            format="string"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={task?.invoicePreviousAmount}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.RequestedConditions.OldInvoiceAmount',
                                'Old invoice amount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={task?.invoiceAmount}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.RequestedConditions.InvoiceAmount',
                                'Invoice amount',
                            )}
                            format="money"
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
