import { computed } from 'mobx';
import { SalaryIncomeDetailsResponse } from 'src/generated-api-client';
import { individualsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class IndividualSalaryIncomeStoreClass extends BasicStore<any> {
    api: BasicStoreApi<SalaryIncomeDetailsResponse> = {
        loadItem: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getSalaryIncomeCheckDetails({
                    id: this.individualId,
                }),
            );
        },
    };

    constructor(private individualId: number) {
        super();
    }

    qrLoader = new AsyncOperationWithStatus(() => {
        return RequestHelper.unwrapFromAxiosPromise(
            individualsApi.addSalaryIncomeType({
                id: this.individualId,
            }),
        );
    });

    @computed get incomeDetails() {
        return this.currentItem;
    }
}

export const IndividualSalaryIncomeStore = new EntityProvider(
    IndividualSalaryIncomeStoreClass,
);
