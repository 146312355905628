import { Typography } from 'antd';
import { t } from 'i18next';
import { Modal } from 'src/components/Modal/Modal';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { InsuranceStore } from 'src/stores/InsuranceStore/InsuranceStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type RemoveCollateralInsuranceDialogFnProps = ModalRendererProps<{
    collateralId: number;
    insuranceId: number;
}>;

const RemoveCollateralInsuranceDialogFn = ({
    collateralId,
    insuranceId,
    ...props
}: RemoveCollateralInsuranceDialogFnProps) => {
    const store = InsuranceStore.getInstance(Number(collateralId));

    return (
        <Modal
            title={t('Dialog.RemoveCollateralInsurance.Title')}
            isLoading={store.updateItemLoader.isLoading}
            onFinish={async () => {
                await store.remove(insuranceId);

                if (store.removeItemLoader.hasError) {
                    return Promise.reject();
                }

                if (!store.removeItemLoader.hasError) {
                    CollateralsStore.loadItem(collateralId);
                }
            }}
            {...props}
        >
            <StyledText>
                {t('Dialog.RemoveCollateralInsurance.Text')}
            </StyledText>
        </Modal>
    );
};

export const RemoveCollateralInsuranceDialog = addModalRenderer(
    RemoveCollateralInsuranceDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 16px;
    color: #4d6082;
`;
