import { Col, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { HiddenField } from 'src/components/Form/HiddenField';
import { N17Task, TaskDecisionType } from 'src/generated-api-client';
import { TaskAssigneeSelect } from 'src/pages/TaskDetailsPage/Components/TaskAssigneeSelect';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import styled from 'styled-components';

type TaskPropsVariables = {
    task: N17Task;
};

export const N17ChooseDmtAssigneeTaskApproveVariables = observer(
    ({ task }: TaskPropsVariables) => {
        const { loanApplication, collaterals } = task;

        return (
            <>
                <Col xs={24}>
                    <StyledText>
                        <Trans i18nKey="Page.N17ChooseDmtAssigneeTask.Descriptions" />
                    </StyledText>
                </Col>
                <Col xs={24}>
                    <TaskLinkView
                        loanApplication={loanApplication}
                        collaterals={collaterals}
                    />
                </Col>
                <Col span={24}>
                    <Card>
                        <Col xs={24}>
                            <HiddenField
                                name="type"
                                initialValue={
                                    TaskDecisionType.N1_7_CHOOSE_DMT_USER
                                }
                            />
                        </Col>
                        <Col xs={12}>
                            <TaskAssigneeSelect
                                label={t(
                                    'Task.Form.N17ChooseDmtAssigneeTask.AvailableMakers',
                                )}
                                assignees={task?.users}
                            />
                        </Col>
                    </Card>
                </Col>
            </>
        );
    },
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
