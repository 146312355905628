import {
    CreditBureauStatus,
    LoanOperationAuditEventListEntry,
    LoanOperationAuditEventOperationType,
    LoanStatus,
} from 'src/generated-api-client';
import { eventsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type FinancialOperationsActionsFilter = {
    effectiveAtFrom?: string;
    effectiveAtTo?: string;
    amountFrom?: number;
    amountTo?: number;
    principalFrom?: number;
    principalTo?: number;
    operationTypes?: LoanOperationAuditEventOperationType[];
    creditBureauStatuses?: CreditBureauStatus[];
    boUserName?: string;
    loanStatuses?: LoanStatus[];
};

const FinancialOperationsActionsFilterKeys: Array<
    keyof FinancialOperationsActionsFilter
> = [
    'effectiveAtFrom',
    'effectiveAtTo',
    'amountFrom',
    'amountTo',
    'principalFrom',
    'principalTo',
    'operationTypes',
    'creditBureauStatuses',
    'boUserName',
    'loanStatuses',
];

export class LoanFinancialOperationsStoreClass extends BasicStore<
    LoanOperationAuditEventListEntry | any
> {
    constructor(private loanId: number) {
        super();
    }

    api: BasicStoreApi<LoanOperationAuditEventListEntry> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                eventsApi.getLoanOperationAuditEvents({
                    ...this.listParams,
                    loanId: this.loanId,
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<FinancialOperationsActionsFilter>(
        FinancialOperationsActionsFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined =
        new Sorter<LoanOperationAuditEventListEntry>();
}

export const LoanFinancialOperationsStore = new EntityProvider(
    LoanFinancialOperationsStoreClass,
);
