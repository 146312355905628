import { TaskCandidateBoUser, UserActivity } from 'src/generated-api-client';
import { tasksApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { RequestHelper } from 'src/utils/RequestHelper';

export type TasksAssigneesFilter = {
    id: string;
    roleName?: string;
    userName?: string;
    userActivity?: UserActivity;
};

const TasksAssigneesFilterKeys: Array<keyof TasksAssigneesFilter> = [
    'id',
    'roleName',
    'userName',
    'userActivity',
];

export class TasksAssigneesStoreClass extends BasicStore<
    any,
    TasksAssigneesFilter
> {
    constructor(private taskId: string) {
        super();
    }

    api: BasicStoreApi<TaskCandidateBoUser> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                tasksApi.getTaskCandidateUsers({
                    id: this.taskId,
                    roleName: this.filterCriteria.filter.roleName,
                    userName: this.filterCriteria.filter.userName,
                    userActivity: this.filterCriteria.filter.userActivity,
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<TasksAssigneesFilter>(
        TasksAssigneesFilterKeys,
    );
}

export const TasksAssigneesStore = new EntityProvider(TasksAssigneesStoreClass);
