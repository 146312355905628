import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { CollateralDetailsType } from 'src/generated-api-client';

export const CreateCarCollateralGeneralInfoVariables = observer(() => {
    return (
        <Card
            title={t(
                'Component.CreateCarCollateral.GeneralInfoVariables.Title',
            )}
        >
            <Row gutter={16}>
                <HiddenField name="type" value={CollateralDetailsType.CAR} />
                <Col xs={6}>
                    <EnumSelectField
                        name="steeringWheel"
                        enumName="STEERING_WHEEL"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.SteeringWheel',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <EnumSelectField
                        name="category"
                        enumName="CAR_COLLATERAL_CATEGORY"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.Category',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="registrationNumber"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.RegistrationNumber',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="chassisNumber"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.ChassisNumber',
                        )}
                    />
                </Col>

                <Col xs={6}>
                    <TextField
                        name="carBrand"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.CarBrand',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="model"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.Model',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="color"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.Color',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="engineType"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.EngineType',
                        )}
                    />
                </Col>

                <Col xs={6}>
                    <NumberField
                        name="engineCapacity"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.EngineCapacity',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DatePickerField
                        name="dateOfManufacture"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.DateOfManufacture',
                        )}
                        picker="year"
                        format="YYYY"
                    />
                </Col>
                <Col xs={6}>
                    <DatePickerField
                        name="importedDate"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.ImportedDate',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <NumberField
                        name="mileage"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.Mileage',
                        )}
                    />
                </Col>

                <Col xs={6}>
                    <NumberField
                        name="overallGrade"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.OverallGrade',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <EnumSelectField
                        name="ownershipType"
                        enumName="COLLATERAL_OWNERSHIP_TYPE"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.OwnershipType',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="owner"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.Owner',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="ownerRegistrationNumber"
                        label={t(
                            'Component.CreateCarCollateral.GeneralInfoVariables.OwnerRegistrationNumber',
                        )}
                    />
                </Col>
            </Row>
        </Card>
    );
});
