import { Button, Col, Form as AntForm, Row, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { ComponentProvider } from 'src/components/ComponentProvider/ComponentProvider';
import { Form } from 'src/components/Form/Form';
import {
    AcceptSquareRoundedIcon,
    CloseSquareRoundedIcon,
} from 'src/components/Icons/Icons';
import { PromptDialog } from 'src/components/PromptDialog/PromptDialog';
import { Env } from 'src/core/Environment';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    CollateralSubType,
    CreateCollateralRequest,
} from 'src/generated-api-client';
import {
    CollateralCreateMap,
    UNCREATED_COLLATERAL_LOCAL_DATA,
} from 'src/pages/CollateralCreatePage/CollateralCreate.config';
import { CreateCollateralTypeVariables } from 'src/pages/CollateralCreatePage/Components/CreateCollateralTypeVariables';
import { UnknownCreateCollateralForm } from 'src/pages/CollateralCreatePage/UnknownCreateCollateralForm/UnknownCreateCollateralForm';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { datePattern } from 'src/utils/DataFormatterProvider';
import { FormHelper } from 'src/utils/FormHelper';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';
import styled from 'styled-components';

const store = CollateralsStore;

export const CollateralCreatePage = observer(() => {
    const [form] = AntForm.useForm();
    const collateralSubType: CollateralSubType = AntForm.useWatch(
        'collateralSubType',
        form,
    );
    const [shouldPrompt, setShouldPrompt] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            store.createItemLoader.reset();
        };
    }, []);

    const fields = FormHelper.getFieldDataItems(
        store.createItemLoader.errorData,
    );

    const hasCreateCollateralForm = useMemo(() => {
        const hasComponent = Boolean(
            CollateralCreateMap[collateralSubType]?.Component,
        );
        hasComponent && setShouldPrompt(true);

        return hasComponent;
    }, [collateralSubType]);

    const onSaveLocalStorageFormData = () => {
        LocalStoreHelper.setItemWithWithEncryption(
            UNCREATED_COLLATERAL_LOCAL_DATA,
            {
                uiVersion: Env.appVersion,
                createdAt: moment().toISOString(),
                data: form.getFieldsValue(),
            },
        );
    };

    return (
        <BasePage
            title={t('Page.CollateralCreatePage.Title')}
            isLoading={store.createItemLoader.isLoading}
        >
            <Form
                form={form}
                fields={fields}
                initialValues={getLocalStorageData()}
                onValuesChange={() => {
                    onSaveLocalStorageFormData();
                }}
                onFinish={async (values) => {
                    setShouldPrompt(false);

                    LocalStoreHelper.removeItem(
                        UNCREATED_COLLATERAL_LOCAL_DATA,
                    );

                    const convertedValues =
                        convertFormValuesToSubmitValues(values);

                    const collateral = await store.create(
                        convertedValues as CreateCollateralRequest,
                    );

                    if (!store.createItemLoader.hasError) {
                        Router.navigate(
                            EntityLinks.collaterals.itemDetails(collateral?.id),
                        );
                    }
                }}
            >
                <CreateCollateralTypeVariables />
                <StyledWrapper>
                    <ComponentProvider
                        componentsMap={CollateralCreateMap}
                        currentComponentKey={collateralSubType}
                        FallbackComponent={UnknownCreateCollateralForm}
                    />
                </StyledWrapper>
                <Row gutter={[25, 24]}>
                    <Col xs={24}>
                        {hasCreateCollateralForm && (
                            <Space>
                                <Button
                                    htmlType="button"
                                    icon={<CloseSquareRoundedIcon />}
                                    onClick={() => {
                                        Router.navigate(RoutePaths.collaterals);
                                    }}
                                >
                                    {t('Page.CollateralCreatePage.Btn.Cancel')}
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    icon={<AcceptSquareRoundedIcon />}
                                >
                                    {t('Page.CollateralCreatePage.Btn.Create')}
                                </Button>
                            </Space>
                        )}
                    </Col>
                </Row>
            </Form>
            <PromptDialog
                when={shouldPrompt}
                title={t('Dialog.CreateCollateralExitWarning.Title')}
                cancelText={t('Dialog.CreateCollateralExitWarning.Cancel')}
                okText={t('Dialog.CreateCollateralExitWarning.Exit', 'Exit')}
                onOk={() => {
                    setShouldPrompt(false);
                    LocalStoreHelper.removeItem(
                        UNCREATED_COLLATERAL_LOCAL_DATA,
                    );
                }}
            >
                {t('Dialog.CreateCollateralExitWarning.Message')}
            </PromptDialog>
        </BasePage>
    );
});

const convertFormValuesToSubmitValues = (values: CreateCollateralRequest) => {
    const convertFormValuesFn =
        CollateralCreateMap[values.collateralSubType]
            ?.convertFormValuesToSubmitValues;

    return (convertFormValuesFn && convertFormValuesFn(values)) || values;
};

const findObjectDateConvertToFormat = (value: any) => {
    const object = JSON.parse(JSON.stringify(value));
    Object.entries(object).map(([key, value]) => {
        if (typeof value === 'string' && datePattern.test(value)) {
            object[key] = moment(value);
        } else if (typeof value === 'object') {
            object[key] = findObjectDateConvertToFormat(value);
        }

        return [key, value];
    });

    return object;
};

const getLocalStorageData = () => {
    const localStorageValues = LocalStoreHelper.getItemWithEncryption(
        UNCREATED_COLLATERAL_LOCAL_DATA,
    );

    if (localStorageValues?.data) {
        return findObjectDateConvertToFormat(localStorageValues.data);
    }

    return null;
};

const StyledWrapper = styled.div`
    padding-bottom: 24px;
`;
