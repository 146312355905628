import { useEffect } from 'react';
import { ViewModel } from 'src/utils/mobx/ViewModel';

export function useViewModel(viewModel: ViewModel, dependencies: any[] = []) {
    useEffect(() => {
        viewModel.load();

        return () => {
            viewModel.reset();
        };
    }, dependencies);
}
