import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { RealEstateCollateral } from 'src/generated-api-client';
import { getLandInfoActions } from 'src/pages/CollateralDetailsPage/Tabs/RealEstateCollateralTab/CollateralLandInfo/RealEstateCollateralLandInfoActions.config';

type RealEstateCollateralLandInfoProps = {
    collateral?: RealEstateCollateral;
};
export const RealEstateCollateralLandInfoTab = observer(
    ({ collateral }: RealEstateCollateralLandInfoProps) => {
        const menuActions = getLandInfoActions(collateral);

        return (
            <Card
                title={t(
                    'Page.CollateralDetails.Tabs.RealEstate.LandInfo.Title',
                )}
                menuActions={menuActions}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={
                                collateral?.landInformation?.registrationNumber
                            }
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.LandInfo.RegistrationNumber',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                collateral?.landInformation?.certificateNumber
                            }
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.LandInfo.CertificateNumber',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral?.landInformation?.ownershipType}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.LandInfo.OwnershipType',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="LAND_OWNERSHIP_TYPE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                collateral?.landInformation
                                    ?.certificateStartDate
                            }
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.LandInfo.CertificateStartDate',
                            )}
                            format="date"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={
                                collateral?.landInformation?.certificateEndDate
                            }
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.LandInfo.CertificateEndDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.LandInfo.UseOfLand',
                            )}
                            value={collateral?.landInformation?.useOfLand}
                            format={(value) => (
                                <EnumView
                                    enumName="USE_OF_LAND"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.LandInfo.LandSquare',
                            )}
                            value={collateral?.landInformation?.landSquare}
                            format="number"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.LandInfo.CadastralNumber',
                            )}
                            value={collateral?.landInformation?.cadastralNumber}
                            format="string"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={
                                collateral?.landInformation
                                    ?.collateralOwnershipType
                            }
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.LandInfo.CollateralOwnershipType',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLATERAL_OWNERSHIP_TYPE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral?.landInformation?.coOwnerCount}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.LandInfo.CoOwnerCount',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="CO_OWNER_COUNT"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
