import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { QrCodeDialogComponent } from 'src/pages/IndividualDetailsPage/Components/QrCodeDialogComponent';
import { IndividualIncomeTypesViewModel } from 'src/pages/IndividualDetailsPage/Tabs/IndividualIncomeTypesViewModel';
import { IndividualLivestockIncomeStore } from 'src/stores/IndividualLivestockIncomeStore/IndividualLivestockIncomeStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

export type LiveStockIncomeQrCodeDialogFnProps = ModalRendererProps<{
    individualId?: number;
}>;

export const LiveStockIncomeQrCodeDialogFn = observer(
    ({ individualId, ...props }: LiveStockIncomeQrCodeDialogFnProps) => {
        const title = t('Dialog.LiveStockIncomeTypeQrCode.Title');
        const store = IndividualLivestockIncomeStore.getInstance(
            Number(individualId),
        );
        const viewModel = IndividualIncomeTypesViewModel.getInstance(
            Number(individualId),
        );

        return (
            <QrCodeDialogComponent
                title={title}
                qrLoader={store.qrLoader}
                {...props}
                onCancel={() => {
                    viewModel.reloadAfterCloseQrModal();
                    props.onCancel();
                }}
            />
        );
    },
);

export const LiveStockIncomeQrCodeDialog = addModalRenderer(
    LiveStockIncomeQrCodeDialogFn,
);
