import { notification } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { CustomerGroupCompaniesStore } from 'src/stores/CustomerGroupCompaniesStore/CustomerGroupCompaniesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type CreateCompanyDialogFnProps = ModalRendererProps<{
    groupId: string;
}>;

export const CreateCompanyDialogFn = observer(
    ({ groupId, ...props }: CreateCompanyDialogFnProps) => {
        const { t } = useTranslation();
        const store = CustomerGroupCompaniesStore.getInstance(Number(groupId));

        useEffect(() => {
            return () => {
                store.createItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.createItemLoader.errorData,
        );

        const loadingInfo = useMemo(() => {
            return new CombinedLoadingStatus([
                store.createItemLoader,
                store.listLoader,
            ]);
        }, []);

        return (
            <Modal
                title={t('Dialog.CreateCompany.Title')}
                isLoading={loadingInfo.isLoading}
                onFinish={async (values) => {
                    await store.create(values);

                    if (store?.createItemLoader.hasError) {
                        return Promise.reject();
                    }
                    if (!store.createItemLoader.hasError) {
                        await store.loadList();
                        notification.success({
                            message: t('Dialog.CreateCompany.Message.Success'),
                        });
                    }
                }}
                formProps={{ fields }}
                {...props}
            >
                <TextField
                    name="name"
                    label={t('Dialog.CreateCompany.CompanyName')}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.maxFieldLength(100),
                    ]}
                />
            </Modal>
        );
    },
);

export const CreateCompanyDialog = addModalRenderer(CreateCompanyDialogFn);
