import {
    BnplLoanApplication,
    GetLoanApplication200Response,
    InstallmentLoanApplication,
    LoanApplicationListEntry,
    LoanApplicationStatus,
    PayonLoanApplication,
    PledgedLoanApplication,
    ProductType,
    TrustPledgedLoanApplication,
    UpdateLoanApplicationRequest,
} from 'src/generated-api-client';
import { applicationsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LoanApplicationType =
    | BnplLoanApplication
    | InstallmentLoanApplication
    | PayonLoanApplication
    | PledgedLoanApplication
    | TrustPledgedLoanApplication;

export type ApplicationsFilter = {
    id?: number;
    relationshipManagerId?: number;
    branchCode?: string;
    customerId?: number;
    applicationStatuses?: LoanApplicationStatus[];
    productTypes?: ProductType[];
    customerName?: string;
    civilId?: string;
    registrationNumber?: string;
    createdDateFrom?: string;
    createdDateTo?: string;
    externalId?: string;
    phone: string;
};

const ApplicationsFilterKeys: Array<keyof ApplicationsFilter> = [
    'id',
    'relationshipManagerId',
    'branchCode',
    'customerId',
    'applicationStatuses',
    'productTypes',
    'customerName',
    'civilId',
    'registrationNumber',
    'createdDateFrom',
    'createdDateTo',
    'externalId',
    'phone',
];

export class ApplicationsStoreClass extends BasicStore<
    LoanApplicationListEntry,
    ApplicationsFilter,
    UpdateLoanApplicationRequest,
    any,
    GetLoanApplication200Response
> {
    api: BasicStoreApi<
        LoanApplicationListEntry,
        UpdateLoanApplicationRequest,
        any,
        GetLoanApplication200Response
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.findAllLoanApplications(this.listParams),
            );
        },

        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.getLoanApplication({ id }),
            );
        },

        updateItem: async (itemId, data) => {
            await RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.updateLoanApplication({
                    id: Number(itemId),
                    updateLoanApplicationRequest: data,
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<ApplicationsFilter>(
        ApplicationsFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<LoanApplicationListEntry>(
        'id',
        'descend',
    );
}

export const ApplicationsStore = new ApplicationsStoreClass();
