import { t } from 'i18next';
import {
    ActionType,
    CollateralCoOwner,
    CreateCollateral200Response,
} from 'src/generated-api-client';
import { EditCollateralOwnershipInfoDialog } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/Dialogs/EditCollateralOwnershipInfoDialog';
import { RemoveCollateralOwnershipInfoDialog } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/Dialogs/RemoveCollateralOwnershipInfoDialog';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getCollateralOwnershipInfoListActions = (
    collateral?: CreateCollateral200Response,
    coOwner?: CollateralCoOwner,
) => {
    return [...getUIActions(), ...getServerActions(collateral, coOwner)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    collateral?: CreateCollateral200Response,
    coOwner?: CollateralCoOwner,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(collateral, coOwner),
        coOwner?.actions,
    );
};

const getRegisteredServerActions = (
    collateral?: CreateCollateral200Response,
    coOwner?: CollateralCoOwner,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.CO_OWNER_UPDATE,
            label: t('CollateralCoOwner.Actions.Edit'),
            onClick: () => {
                EditCollateralOwnershipInfoDialog.render({
                    collateralId: collateral?.id,
                    coOwner,
                });
            },
        },
        {
            key: ActionType.CO_OWNER_DELETE,
            label: t('CollateralCoOwner.Actions.Remove'),
            onClick: () => {
                RemoveCollateralOwnershipInfoDialog.render({
                    collateralId: collateral?.id,
                    insuranceId: coOwner?.id,
                });
            },
        },
    ];
};
