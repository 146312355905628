import { ModalFuncProps, notification } from 'antd';
import { t } from 'i18next';
import { ModalConfirm } from 'src/components/ModalConfirm/ModalConfirmDialog';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { CustomerGroupsStore } from 'src/stores/CustomerGroupsStore/CustomerGroupsStore';

const store = CustomerGroupsStore;

type DeleteGroupCompanyDialogProps = ModalFuncProps & {
    groupId: string;
};

export const DeleteCustomerGroupDialog = ({
    groupId,
    ...dialogProps
}: DeleteGroupCompanyDialogProps) => {
    ModalConfirm({
        ...dialogProps,
        title: t('Dialog.DeleteCustomerGroup.Title'),
        width: 640,
        onOk: async () => {
            await store.remove(groupId);
            if (!store.removeItemLoader.hasError) {
                notification.success({
                    message: t('Dialog.DeleteCustomerGroup.Message.Success'),
                });

                Router.navigate(RoutePaths.settingsCustomerSegmentation);
            }
        },
    });
};
