/* eslint-disable max-lines */
import { notification } from 'antd';
import { ColumnType } from 'antd/es/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataView } from 'src/components/DataView/DataView';
import { ErrorField } from 'src/components/Form/ErrorField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { ListSelectField } from 'src/components/Form/ListSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { IndividualListEntry } from 'src/generated-api-client';
import { CreateExistingIndividualRepresentativeFilterForm } from 'src/pages/LegalEntityDetailsPage/Dialogs/LegalEntityInformation/CreateExistingIndividualRepresentativeFilterForm';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type CreateExistingIndividualRepresentativeDialogProps = ModalRendererProps<{
    viewModel: LegalEntityDetailsViewModelClass;
}>;

const store = IndividualsStore;

const CreateExistingIndividualRepresentativeDialogFn = observer(
    ({
        viewModel,
        ...props
    }: CreateExistingIndividualRepresentativeDialogProps) => {
        useEffect(() => {
            return () => {
                store.listLoader.reset();
                store.filterCriteria?.resetFilter();
                store.pager?.reset();
            };
        }, []);

        const { isLoading } =
            store.listLoader || viewModel?.isCreateRepresentativeLoading;

        const fields = FormHelper.getFieldDataItems(
            viewModel.createRepresentativeLoader.errorData,
        );

        return (
            <Modal
                isLoading={isLoading}
                title={t('Dialog.CreateRepresentative.Title')}
                onFinish={async (values) => {
                    await viewModel.createRepresentative(values);

                    if (viewModel.createRepresentativeLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel.createRepresentativeLoader.hasError) {
                        viewModel?.load();
                        notification.success({
                            message: t(
                                'Dialog.CreateRepresentative.Message.Success',
                            ),
                        });

                        props.onCancel();
                    }
                }}
                headerExtra={
                    <CreateExistingIndividualRepresentativeFilterForm
                        store={store}
                        viewModel={viewModel}
                        cancelCurrentModal={props?.onCancel}
                    />
                }
                formProps={{ fields }}
                width={1200}
                {...props}
            >
                <HiddenField
                    name="type"
                    initialValue="NewIndividualLegalRepresentative"
                />

                <StyledListSelectField
                    name="individualId"
                    store={store}
                    columns={getColumns(props)}
                    rules={[ValidationRules.required]}
                    listSelectProps={{
                        rowKey: (record: IndividualListEntry) => record?.id,
                    }}
                />

                <StyledError
                    name={[
                        'createLegalRepresentative',
                        'individualId',
                        'otherErrors',
                    ]}
                />
            </Modal>
        );
    },
);

export const CreateExistingIndividualRepresentativeDialog = addModalRenderer(
    CreateExistingIndividualRepresentativeDialogFn,
);

const StyledListSelectField = styled(ListSelectField)`
    .ant-table-wrapper {
        padding: 10px 0;
    }
`;

const StyledError = styled(ErrorField)`
    margin-bottom: 10px;
`;

const getColumns = (
    props: ModalRendererProps,
): ColumnType<IndividualListEntry>[] => {
    return [
        {
            key: 'id',
            dataIndex: 'id',
            title: <Trans i18nKey="Page.Individuals.List.Id" />,
            render: (id: string) => {
                return (
                    <Link
                        to={EntityLinks.individuals.itemDetails(id)}
                        onClick={() => {
                            Router.navigate(
                                EntityLinks.individuals.itemDetails(id),
                            );

                            props.onCancel();
                        }}
                    >
                        {id}
                    </Link>
                );
            },
        },
        {
            key: 'customerName',
            dataIndex: 'customerName',
            title: <Trans i18nKey="Dialog.CreateRepresentative.List.Name" />,
        },
        {
            key: 'idDocumentRegistrationNumber',
            dataIndex: 'idDocumentRegistrationNumber',
            title: (
                <Trans i18nKey="Dialog.CreateRepresentative.List.RegistrationNumber" />
            ),
        },
        {
            key: 'civilId',
            dataIndex: 'civilId',
            title: <Trans i18nKey="Dialog.CreateRepresentative.List.CivilId" />,
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: <Trans i18nKey="Dialog.CreateRepresentative.List.Phone" />,
        },
        {
            key: 'pep',
            dataIndex: 'pep',
            title: <Trans i18nKey="Dialog.CreateRepresentative.List.Pep" />,
            render: (value: boolean) => {
                return <DataView value={value} hideLabel format="yes-no" />;
            },
        },
    ];
};
