import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Card } from 'src/components/Card/Card';
import { DocumentTemplateUploadDialog } from 'src/pages/DocumentTemplatesPage/DocumentTeplateUploadDialog';
import { DocumentView } from 'src/pages/DocumentTemplatesPage/DocumentView';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { DocumentTemplatesStore } from 'src/stores/DocumentTemplatesStore/DocumentTemplatesStore';

export const DocumentTemplatesPage = observer(() => {
    const canUploadDocument = AppUser.hasPermissions(
        UserPermission.DocumentTemplateEdit,
    );
    useEffect(() => {
        DocumentTemplatesStore.loadList();

        return () => {
            DocumentTemplatesStore.listLoader.reset();
        };
    }, []);

    return (
        <BasePage
            title={t('Page.DocumentTemplates.Title')}
            isLoading={DocumentTemplatesStore.listLoader.isLoading}
            showOnlyLoading={DocumentTemplatesStore.listLoader.countLoads === 0}
            header={
                canUploadDocument && (
                    <Space direction="horizontal" align="end">
                        <Button
                            type="primary"
                            icon={<CloudUploadOutlined />}
                            onClick={() => {
                                DocumentTemplateUploadDialog.render();
                            }}
                        >
                            {t('Page.DocumentTemplates.Header.Upload')}
                        </Button>
                    </Space>
                )
            }
        >
            <Card>
                {DocumentTemplatesStore.list.map((document) => {
                    return (
                        <DocumentView
                            document={document}
                            canUploadDocument={canUploadDocument}
                            key={document.id}
                        />
                    );
                })}
            </Card>
        </BasePage>
    );
});
