import { action } from 'mobx';
import {
    BnplLoan,
    InstallmentLoan,
    LoanListEntry,
    LoanStatus,
    PatchLoanRequest,
    PayonLoan,
    PledgedLoan,
    ProductType,
    TrustPledgedLoan,
} from 'src/generated-api-client';
import { applicationsApi, loansApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LoanType =
    | BnplLoan
    | InstallmentLoan
    | PayonLoan
    | PledgedLoan
    | TrustPledgedLoan;

export type LoansFilter = {
    externalId?: string;
    productTypes?: ProductType[];
    customerName?: string;
    civilId?: string;
    relationshipManagerId?: number;
    branchCode?: string;
    loanStatuses?: LoanStatus[];
    disbursementDateFrom?: string;
    disbursementDateTo?: string;
    customerRegistrationNumber?: string;
    phone?: string;
    disbursementPaymentAccountNumber?: string;
};

const LoansFilterKeys: Array<keyof LoansFilter> = [
    'externalId',
    'productTypes',
    'customerName',
    'civilId',
    'relationshipManagerId',
    'branchCode',
    'loanStatuses',
    'disbursementDateFrom',
    'disbursementDateTo',
    'customerRegistrationNumber',
    'phone',
    'disbursementPaymentAccountNumber',
];

export class LoansStoreClass extends BasicStore<
    LoanListEntry,
    LoansFilter,
    PatchLoanRequest,
    any,
    LoanType
> {
    api: BasicStoreApi<LoanListEntry, PatchLoanRequest, any, LoanType> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.findLoans(this.listParams),
            );
        },

        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getLoan({ id }),
            );
        },

        updateItem: async (id, data) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.patchLoan({ id: Number(id), patchLoanRequest: data }),
            );
        },
    };

    filterCriteria = new FilterCriteria<LoansFilter>(LoansFilterKeys);

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<LoanListEntry>('id', 'descend');

    addCollateralLoader = new AsyncOperationWithStatus(
        (applicationId: string | number, collateralId: string | number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.createApplicationCollateralEvaluation({
                    applicationId: Number(applicationId),
                    collateralId: Number(collateralId),
                }),
            );
        },
    );

    @action
    async addCollateral(
        applicationId: string | number,
        collateralId: string | number,
    ) {
        await this.addCollateralLoader.call(applicationId, collateralId);
    }

    removeCollateralLoader = new AsyncOperationWithStatus(
        (applicationId: string | number, collateralId: string | number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.deleteApplicationCollateralEvaluation({
                    applicationId: Number(applicationId),
                    collateralId: Number(collateralId),
                }),
            );
        },
    );

    @action
    async removeCollateral(
        applicationId: string | number,
        collateralId: string | number,
    ) {
        await this.removeCollateralLoader.call(applicationId, collateralId);
    }
}

export const LoansStore = new LoansStoreClass();
