import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { PledgedProduct } from 'src/generated-api-client';
import { ProductsStatus } from 'src/pages/LoanProducts/ProductsStatus';

export type PledgedProductDetailsBriefProps = {
    productPledged?: PledgedProduct;
};
export const PledgedProductDetailsBrief = observer(
    ({ productPledged }: PledgedProductDetailsBriefProps) => {
        const { t } = useTranslation();

        return (
            <Brief>
                <Row gutter={16}>
                    <Col span={6}>
                        <DataView
                            value={productPledged?.name}
                            label={t('Page.PledgedProductDetails.Name')}
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productPledged?.maxLoanAmount}
                            label={t(
                                'Page.PledgedProductDetails.MaxLoanAmount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productPledged?.interestRate}
                            label={t('Page.PledgedProductDetails.InterestRate')}
                            format="percent"
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productPledged?.status}
                            label={t('Page.PledgedProductDetails.Status')}
                            format={(value) => (
                                <ProductsStatus status={value} />
                            )}
                        />
                    </Col>
                </Row>
            </Brief>
        );
    },
);
