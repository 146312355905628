import { observer } from 'mobx-react-lite';
import { SelectField, SelectFieldProps } from 'src/components/Form/SelectField';
import {
    convertEnumToOptions,
    EnumName,
    EnumStore,
} from 'src/stores/Resources/EnumStore/EnumStore';

export type EnumSelectFieldProms = SelectFieldProps & {
    enumName: EnumName;
};
export const EnumSelectField = observer(
    ({ enumName, ...props }: EnumSelectFieldProms) => {
        const enumStore = EnumStore.getInstance(enumName);

        return (
            <SelectField
                {...props}
                convertListItemToOption={(item) => convertEnumToOptions(item)}
                store={enumStore}
            />
        );
    },
);
