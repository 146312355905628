/* eslint-disable max-lines */
import { Col, Form, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Card } from 'src/components/Card/Card';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    N13Task,
    RepaymentFrequency,
    TaskDecisionType,
} from 'src/generated-api-client';
import { repaymentFrequencyOptions } from 'src/pages/TaskDetailsPage/Components/repaymentFrequencyOptions.config';
import { TaskVariablesProps } from 'src/pages/TaskViewPage/TaskTypes';
import { LoanPurposesOptionsStore } from 'src/stores/LoanPurposesOptionsStore/LoanPurposesOptionsStore';

export const N13AddEstimatedConditionsTaskApproveVariables = observer(
    ({ task }: TaskVariablesProps<N13Task>) => {
        const form = Form.useFormInstance();
        const repaymentFrequency = Form.useWatch('repaymentFrequency');
        const isPurposesOptionsLoading =
            LoanPurposesOptionsStore.listLoader.isLoading;
        const loanPurposeId = Form.useWatch('loanPurposeId');
        const isBiWeeklyRepaymentFrequency =
            repaymentFrequency === RepaymentFrequency.BI_WEEKLY;
        const { pledgedProducts } = task;
        const pledgedProductsOptions = pledgedProducts?.map(asOption);

        useEffect(() => {
            LoanPurposesOptionsStore.loadList();

            return () => {
                LoanPurposesOptionsStore.listLoader.reset();
            };
        }, []);

        const purposeOptions = LoanPurposesOptionsStore.loanPurposesOptions;
        const subPurposeOptions =
            LoanPurposesOptionsStore.loanSubPurposesOptions(loanPurposeId);

        useEffect(() => {
            const isTouchedLoanPurposeId = form.isFieldsTouched([
                'loanPurposeId',
            ]);
            if (isTouchedLoanPurposeId) {
                form.setFields([
                    { name: 'loanSubPurposeId', value: undefined },
                ]);
            }
        }, [loanPurposeId]);

        useEffect(() => {
            if (repaymentFrequency && !isBiWeeklyRepaymentFrequency) {
                form.setFields([
                    {
                        name: 'secondRepaymentDate',
                        value: undefined,
                    },
                ]);
            } else if (repaymentFrequency && isBiWeeklyRepaymentFrequency) {
                form.setFields([
                    { name: 'repaymentFrequency', value: undefined },
                ]);
            }
        }, [isBiWeeklyRepaymentFrequency]);

        const isRequiredSecondRepaymentDate = isBiWeeklyRepaymentFrequency
            ? ValidationRules.required
            : {};

        return (
            <Card title={t('Task.Form.N13AddEstimatedConditions.Title')}>
                <Row gutter={16}>
                    <Col xs={24}>
                        <HiddenField
                            name="type"
                            initialValue={
                                TaskDecisionType.N1_3_ADD_LOAN_CONDITIONS
                            }
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="amount"
                            label={t(
                                'Task.Form.N13AddEstimatedConditions.EstimatedLoanAmount',
                            )}
                            thousandDivider
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectField
                            name="repaymentFrequency"
                            label={t(
                                'Task.Form.N13AddEstimatedConditions.EstimatedRepaymentFrequency',
                            )}
                            options={repaymentFrequencyOptions}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <DatePickerField
                            name="firstRepaymentDate"
                            label={t(
                                'Task.Form.N13AddEstimatedConditions.EstimatedFirstRepaymentDate',
                            )}
                            rules={[ValidationRules.required]}
                            disabledDate={(date) => {
                                return date.isBefore(moment().endOf('day'));
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <DatePickerField
                            name="secondRepaymentDate"
                            label={t(
                                'Task.Form.N13AddEstimatedConditions.EstimatedSecondRepaymentDate',
                            )}
                            rules={[isRequiredSecondRepaymentDate]}
                            disabled={!isBiWeeklyRepaymentFrequency}
                            disabledDate={(date) => {
                                return date.isBefore(moment().endOf('day'));
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="monthlyInterestRate"
                            label={t(
                                'Task.Form.N13AddEstimatedConditions.EstimatedInterestRate',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 100),
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="requestedTerm"
                            label={t(
                                'Task.Form.N13AddEstimatedConditions.EstimatedTerm',
                            )}
                            onlyInteger
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 180),
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectField
                            name="loanPurposeId"
                            label={t(
                                'Task.Form.N13AddEstimatedConditions.LaaLoanPurpose',
                            )}
                            options={purposeOptions}
                            loading={isPurposesOptionsLoading}
                            disabled={isPurposesOptionsLoading}
                            showSearch
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectField
                            name="loanSubPurposeId"
                            label={t(
                                'Task.Form.N13AddEstimatedConditions.LaaLoanSubPurpose',
                            )}
                            options={subPurposeOptions}
                            loading={isPurposesOptionsLoading}
                            disabled={isPurposesOptionsLoading}
                            showSearch
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={12}>
                        <SelectField
                            name="productId"
                            label={t(
                                'Task.Form.N13AddEstimatedConditions.ProductId',
                                'Pledged Loan Product',
                            )}
                            options={pledgedProductsOptions}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={24}>
                        <TextAreaField
                            name="comment"
                            label={t(
                                'Task.Form.N13AddEstimatedConditions.Comment',
                            )}
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);

const asOption = (value: any) => ({
    value: value.id,
    label: value.name,
    'data-test': value.name,
});
