/* eslint-disable max-lines */
import { Button, Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView, LONG_DASH } from 'src/components/DataView/DataView';
import { PledgedLoanApplication } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { CcDecisionDetailsDialog } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Dialogs/CcDecisionDetailsDialog';
import { EnumName, EnumStore } from 'src/stores/Resources/EnumStore/EnumStore';

const enumsToLoad: EnumName[] = ['REPAYMENT_FREQUENCY', 'CUSTOMER_INCOME_TYPE'];

export type PledgedApplicationConditionTabProps = {
    application?: PledgedLoanApplication;
};

export const ConditionTab = observer(
    ({ application }: PledgedApplicationConditionTabProps) => {
        useEnumsLoader(enumsToLoad);

        const requestedConditions = application?.requestedConditions;
        const approvedConditions = application?.approvedConditions;
        const laatConditions = application?.laatConditions;

        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.Tab.Condition.RequestedCondition.Title',
                            'Requested Loan Condition',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.RequestedCondition.RequestedDate',
                                        'Requested Date',
                                    )}
                                    value={requestedConditions?.createdAt}
                                    format="date-time-seconds"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.RequestedCondition.Amount',
                                        'Requested Amount',
                                    )}
                                    value={requestedConditions?.amount}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.RequestedCondition.Term',
                                        'Requested Term',
                                    )}
                                    value={requestedConditions?.term}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.RequestedCondition.FirstRepaymentDate',
                                        'Requested First Repayment Date',
                                    )}
                                    value={
                                        requestedConditions?.firstRepaymentDate
                                    }
                                    format="date"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.RequestedCondition.SecondRepaymentDate',
                                        'Requested Second Repayment Date',
                                    )}
                                    value={
                                        requestedConditions?.secondRepaymentDate
                                    }
                                    format="date"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.incomeTypes}
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.RequestedCondition.IncomeTypes',
                                    )}
                                    format={(value) => {
                                        return value?.length
                                            ? value
                                                  ?.map((income: string) =>
                                                      EnumStore.getInstance(
                                                          'CUSTOMER_INCOME_TYPE',
                                                      ).getLocalizedEnum(
                                                          income,
                                                      ),
                                                  )
                                                  .join(', ')
                                            : LONG_DASH;
                                    }}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.averageIncome}
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.RequestedCondition.AverageIncome',
                                    )}
                                    format="money"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.Title',
                            'LAA Loan Condition',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.RequestedDate',
                                        'Estimated Date',
                                    )}
                                    value={laatConditions?.createdAt}
                                    format="date-time-seconds"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.EstimatedAmount',
                                        'Estimated Loan Amount',
                                    )}
                                    value={
                                        laatConditions
                                            ? laatConditions?.amount
                                            : requestedConditions?.amount
                                    }
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.RepaymentFrequency',
                                        'Estimated Repayment Frequency',
                                    )}
                                    value={
                                        laatConditions
                                            ? laatConditions?.repaymentFrequency
                                            : requestedConditions?.repaymentFrequency
                                    }
                                    format={(value) =>
                                        repaymentFrequencyI18nTranslation(value)
                                    }
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.FirstPaymentAmount',
                                        'Estimated First Payment Amount',
                                    )}
                                    value={
                                        application?.laatEstimatedFirstPaymentAmount
                                    }
                                    format="money"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.FirstRepaymentDate',
                                        'Estimated First Repayment Date',
                                    )}
                                    value={
                                        laatConditions
                                            ? laatConditions?.firstRepaymentDate
                                            : requestedConditions?.firstRepaymentDate
                                    }
                                    format="date"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.SecondRepaymentDate',
                                        'Estimated Second Repayment Date',
                                    )}
                                    value={
                                        laatConditions
                                            ? laatConditions?.secondRepaymentDate
                                            : requestedConditions?.secondRepaymentDate
                                    }
                                    format="date"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.EstimatedInterestRate',
                                        'Estimated Interest Rate',
                                    )}
                                    value={
                                        laatConditions
                                            ? laatConditions?.monthlyInterestRate
                                            : requestedConditions?.monthlyInterestRate
                                    }
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.Term',
                                        'Estimated Term',
                                    )}
                                    value={
                                        laatConditions
                                            ? laatConditions?.term
                                            : requestedConditions?.term
                                    }
                                    format="string"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.EstimatedDti',
                                        'Estimated DTI',
                                    )}
                                    value={application?.laatEstimatedDti}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.EstimatedDtiWithCoBorrower',
                                        'Estimated DTI With Co-borrower',
                                    )}
                                    value={
                                        application?.laatEstimatedDtiWithCoBorrower
                                    }
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.LaaCondition.LaatLeaderComment',
                                        'LAAT Leader Comment',
                                    )}
                                    value={application?.laatLeaderComment}
                                    format="string"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.Tab.Condition.ApprovedCondition.Title',
                            'Approved Condition',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.ApprovedCondition.Amount',
                                        'Approved Loan Amount',
                                    )}
                                    value={approvedConditions?.amount}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.ApprovedCondition.FirstRepaymentDate',
                                        'Approved First Repayment Date',
                                    )}
                                    value={
                                        approvedConditions?.firstRepaymentDate
                                    }
                                    format="date"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.ApprovedCondition.MaturityDate',
                                        'Maturity Date',
                                    )}
                                    value={application?.maturityDate}
                                    format="date"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.ApprovedCondition.RepaymentFrequency',
                                        'Approved Repayment Frequency',
                                    )}
                                    value={
                                        approvedConditions?.repaymentFrequency
                                    }
                                    format={(value) =>
                                        repaymentFrequencyI18nTranslation(value)
                                    }
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.ApprovedCondition.SecondRepaymentDate',
                                        'Approved Second Repayment Date',
                                    )}
                                    value={
                                        approvedConditions?.secondRepaymentDate
                                    }
                                    format="date"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.ApprovedCondition.InterestRate',
                                        'Approved Interest Rate',
                                    )}
                                    value={
                                        approvedConditions?.monthlyInterestRate
                                    }
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.ApprovedCondition.Term',
                                        'Approved Term',
                                    )}
                                    value={approvedConditions?.term}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.ApprovedCondition.Comment',
                                        'RM Comment',
                                    )}
                                    value={approvedConditions?.comment}
                                    format="string"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        CcDecisionDetailsDialog.render({
                                            application,
                                        });
                                    }}
                                >
                                    {t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Condition.ApprovedCondition.Btn.ViewCcDecision',
                                        'View CC Decision Detail',
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    },
);

const repaymentFrequencyI18nTranslation = (value: string) =>
    EnumStore.getInstance('REPAYMENT_FREQUENCY').getLocalizedEnum(value);
