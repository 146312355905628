import { observer } from 'mobx-react-lite';
import { CreateCollateralEvaluationVariables } from 'src/pages/CollateralCreatePage/Components/CreateCollateralEvaluationVariables';
import { CreateLeasingCollateralGeneralInfoVariables } from 'src/pages/CollateralCreatePage/LeasingCollateral/CreateLeasingCollateralGeneralInfoVariables';

export const CreateLeasingCollateral = observer(() => {
    return (
        <>
            <CreateLeasingCollateralGeneralInfoVariables />
            <CreateCollateralEvaluationVariables />
        </>
    );
});
