import { Button, Col, Row } from 'antd';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { NotFound } from 'src/components/NotFound/NotFound';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useCollectionId } from 'src/hooks/useCollectionId';
import { CollectionDetailsBrief } from 'src/pages/CollectionDetailsPage/Components/CollectionDetailsBrief';
import { CollectionsExternalActions } from 'src/pages/CollectionDetailsPage/Components/CollectionsExternalActions';
import { ActivityTab } from 'src/pages/CollectionDetailsPage/Tabs/ActivityTab';
import { GeneralInfoTab } from 'src/pages/CollectionDetailsPage/Tabs/GeneralInfoTab';
import { LoanTab } from 'src/pages/CollectionDetailsPage/Tabs/LoanTab';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { DebtCollectionsStore } from 'src/stores/DebtCollectionsStore/DebtCollectionsStore';

const store = DebtCollectionsStore;

export const CollectionDetails = observer(() => {
    const { collectionId } = useCollectionId();

    useEffect(() => {
        store.loadItem(collectionId);

        return () => {
            store.itemLoader.reset();
        };
    }, [collectionId]);

    const collection = store?.currentItem;
    const hasCollection = Boolean(collection);

    const error = useMemo(() => {
        const showNotFound =
            !_.isInteger(Number(collectionId)) ||
            store.itemLoader.errorData?.status === 404;
        if (showNotFound) {
            return (
                <NotFound
                    title={t('Page.CollectionDetails.Error.NotFound.Title')}
                    description={t(
                        'Page.CollectionDetails.Error.NotFound.Description',
                        { collectionId },
                    )}
                >
                    <Button
                        onClick={() => {
                            Router.navigate(RoutePaths.collections);
                        }}
                    >
                        {t('Page.CollectionDetails.GoToList')}
                    </Button>
                </NotFound>
            );
        }

        return null;
    }, [collectionId, store.itemLoader.errorData]);

    const tabs: TabDefinition[] = hasCollection
        ? [
              {
                  title: t('Page.CollectionDetails.Tabs.GeneralInformation'),
                  tabKey: 'details',
                  Component: <GeneralInfoTab collection={collection} />,
              },
              {
                  title: t('Page.CollectionDetails.Tabs.Loans'),
                  tabKey: 'loan',
                  Component: <LoanTab />,
              },
              {
                  title: t('Page.CollectionDetails.Tabs.Activity'),
                  tabKey: 'activity',
                  Component: <ActivityTab />,
                  visible: AppUser.hasPermissions(
                      UserPermission.CollectionReadActivityList,
                  ),
              },
          ]
        : [];

    return (
        <BasePage
            isLoading={store.itemLoader.isLoading}
            header={<CollectionDetailsBrief collection={collection} />}
            showOnlyLoading={!collection}
            error={error}
            title={
                <Row gutter={16}>
                    <Col flex={1}>
                        {t('Page.CollectionDetailsPage.Title', {
                            collectionId,
                        })}
                    </Col>
                    <Col>
                        <CollectionsExternalActions
                            collection={collection}
                            collectionId={Number(collectionId)}
                        />
                    </Col>
                </Row>
            }
        >
            <Tabs tabs={tabs} />
        </BasePage>
    );
});
