import { IndividualInformationTabView } from 'src/generated-api-client';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';

export type IndividualDetailKey = keyof IndividualInformationTabView;

export const hasData = (
    targetFields: IndividualDetailKey[],
    targetObject?: IndividualDetailsViewModelClass,
) => {
    return targetFields.some((key) => Boolean(targetObject?.item?.[key]));
};
