import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FormItemGroup } from 'src/components/Form/FormItemGroup/FormItemGroup';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { AddressFields } from 'src/pages/IndividualDetailsPage/Components/AddressFields';
import { FormHelper } from 'src/utils/FormHelper';

export const RealEstateCollateralGeneralInfoUpdateVariables = observer(() => {
    return (
        <Row gutter={16}>
            <HiddenField name="type" />
            <Col xs={8}>
                <SelectField
                    name="isPledged"
                    label={t(
                        'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.ApproveConditions.IsPledged',
                    )}
                    options={FormHelper.objAsOptions(
                        {
                            true: true,
                            false: false,
                        },
                        'Primitive.Boolean.',
                    )}
                />
            </Col>
            <Col xs={8}>
                <TextField
                    name="governmentCertificateNumber"
                    label={t(
                        'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.ApproveConditions.GovernmentCertificateNumber',
                    )}
                />
            </Col>
            <Col xs={8}>
                <TextField
                    name="registrationNumber"
                    label={t(
                        'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.ApproveConditions.RegistrationNumber',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={8}>
                <TextField
                    name="certificateNumber"
                    label={t(
                        'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.ApproveConditions.CertificateNumber',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={8}>
                <TextField
                    name="governanceName"
                    label={t(
                        'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.ApproveConditions.GovernanceName',
                    )}
                />
            </Col>
            <Col xs={8}>
                <DatePickerField
                    name="registeredDate"
                    label={t(
                        'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.ApproveConditions.RegisteredDate',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={8}>
                <DatePickerField
                    name="establishedDate"
                    label={t(
                        'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.ApproveConditions.EstablishedDate',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={24}>
                <FormItemGroup name="address">
                    <AddressFields hideLatitudeLongitude />
                </FormItemGroup>
            </Col>
            <Col xs={8}>
                <NumberField
                    name="areaSquare"
                    label={t(
                        'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.ApproveConditions.AreaSquare',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={8}>
                <EnumSelectField
                    name="estatePurpose"
                    enumName="ESTATE_PURPOSE"
                    label={t(
                        'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.ApproveConditions.EstatePurpose',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={8}>
                <EnumSelectField
                    name="numberOfRooms"
                    enumName="NUMBER_OF_ROOMS"
                    label={t(
                        'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.ApproveConditions.NumberOfRooms',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <EnumSelectField
                    name="ownershipType"
                    enumName="COLLATERAL_OWNERSHIP_TYPE"
                    label={t(
                        'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.ApproveConditions.OwnershipType',
                    )}
                />
            </Col>
        </Row>
    );
});
