import { t } from 'i18next';
import { ActionType, LoanCollateralListEntry } from 'src/generated-api-client';
import { RemoveCollateralDialog } from 'src/pages/LoanDetailsPage/Dialogs/Collaterals/RemoveCollateralDialog';
import { LoanType } from 'src/stores/LoansStore/LoansStore';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getCollateralsActions = (
    loan?: LoanType,
    collateral?: LoanCollateralListEntry,
) => {
    return [...getUIActions(), ...getServerActions(loan, collateral)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    loan?: LoanType,
    collateral?: LoanCollateralListEntry,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(loan, collateral),
        loan?.actions,
    );
};

const getRegisteredServerActions = (
    loan?: LoanType,
    collateral?: LoanCollateralListEntry,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.COLLATERAL_DELETE,
            label: t('Loan.Collaterals.Actions.COLLATERAL_DELETE'),
            onClick: () => {
                RemoveCollateralDialog.render({
                    loan,
                    collateralId: collateral?.id,
                });
            },
        },
    ];
};
