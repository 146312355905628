import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { N21Task } from 'src/generated-api-client';
import { ConditionCardProps } from 'src/pages/TaskViewPage/TaskTypes';

export const CustomerInformationCard = observer(
    ({ task }: ConditionCardProps<N21Task>) => {
        const {
            individual,
            registrationNumber,
            feeAmount,
            iatSenior,
            iatUser,
        } = task;

        return (
            <Card
                title={t(
                    'Task.Form.N21AddBankStatementTask.CustomerInformation.Title',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={individual?.name}
                            label={t(
                                'Task.Form.N21AddBankStatementTask.CustomerInformation.CustomerName',
                                'Customer name',
                            )}
                            format="link-internal"
                            formatterProps={{
                                to: EntityLinks.individuals.itemDetails(
                                    individual?.id,
                                ),
                            }}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={registrationNumber}
                            label={t(
                                'Task.Form.N21AddBankStatementTask.CustomerInformation.RegistrationNumber',
                                'Registration number',
                            )}
                            format="string"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={feeAmount}
                            label={t(
                                'Task.Form.N21AddBankStatementTask.CustomerInformation.FeeAmount',
                                'Fee amount',
                            )}
                            format="string"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={iatUser?.name}
                            label={t(
                                'Task.Form.N23AddBusinessIncomeTask.CustomerInformation.IatUser',
                                'IAT user',
                            )}
                            format="link-internal"
                            formatterProps={{
                                to: EntityLinks.users.itemDetails(iatUser?.id),
                            }}
                        />
                    </Col>

                    <Col xs={6}>
                        <DataView
                            value={iatSenior?.name}
                            label={t(
                                'Task.Form.N23AddBusinessIncomeTask.CustomerInformation.IatSenior',
                                'IAT senior',
                            )}
                            format="link-internal"
                            formatterProps={{
                                to: EntityLinks.users.itemDetails(
                                    iatSenior?.id,
                                ),
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
