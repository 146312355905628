/* eslint-disable max-lines */
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListPage } from 'src/components/ListPage/ListPage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    CollectionActivityGeneralType,
    CollectionActivityNextAction,
    CollectionActivityType,
    CollectionLevel,
    DebtCollectionActivityListEntry,
    IdNameEntry,
} from 'src/generated-api-client';
import { useCollectionId } from 'src/hooks/useCollectionId';
import { CollectionActivityStatus } from 'src/pages/CollectionDetailsPage/Components/CollectionActivityStatus';
import { CollectionActivityDetailsDialog } from 'src/pages/CollectionDetailsPage/Dialogs/CollectionActivityDetailsDialog';
import { CollectionActivitiesHeaderPanel } from 'src/pages/CollectionDetailsPage/RecordActivityPage/Components/CollectionActivitiesHeaderPanel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import {
    DebtCollectionActivitesStore,
    DebtCollectionActivitiesStoreClass,
} from 'src/stores/DebtCollectionsActivityStore/DebtCollectionsActivityStore';
import { UrlHelper } from 'src/utils/UrlHelper';

export const ActivityTab = observer(() => {
    const { collectionId } = useCollectionId();

    const store = DebtCollectionActivitesStore.getInstance(collectionId);

    return (
        <ListPage
            store={store}
            columns={getColumns(store)}
            header={
                <CollectionActivitiesHeaderPanel
                    title={t('Page.CollectionDetailsPage.Activity.Title')}
                    store={store}
                />
            }
        />
    );
});

export const getColumns = (
    store: DebtCollectionActivitiesStoreClass,
): ColumnsType<DebtCollectionActivityListEntry> => {
    return [
        {
            key: 'ordinalNumber',
            dataIndex: 'ordinalNumber',
            title: (
                <Trans i18nKey="Page.CollectionDetailsPage.Activity.List.OrdinalNumber" />
            ),
            render: (
                value: number,
                record: DebtCollectionActivityListEntry,
            ) => {
                const hasPermissions = AppUser.hasPermissions(
                    UserPermission.CollectionReadActivityDetails,
                );

                if (hasPermissions) {
                    return (
                        <Button
                            type="link"
                            onClick={() => {
                                CollectionActivityDetailsDialog.render({
                                    store,
                                    activityId: record?.id,
                                    title: t(
                                        'Dialog.CollectionActivityDetailsDialog.Title',
                                        { ordinalNumber: value },
                                    ),
                                });
                            }}
                        >
                            {value}
                        </Button>
                    );
                }

                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'debtCollector',
            dataIndex: 'debtCollector',
            title: (
                <Trans i18nKey="Page.CollectionDetailsPage.Activity.List.DebtCollector" />
            ),
            render: (value: IdNameEntry) => {
                const canUserReadDetails = AppUser.hasPermissions(
                    UserPermission.UserReadDetails,
                );

                const userDetailsUrl = UrlHelper.getTargetUrl(
                    RoutePaths.userDetails,
                    {
                        userId: value?.id,
                    },
                );

                if (canUserReadDetails) {
                    return (
                        <DataView
                            value={value?.name}
                            hideLabel
                            format="link-internal"
                            formatterProps={{ to: userDetailsUrl }}
                        />
                    );
                }

                return value?.id;
            },
        },
        {
            key: 'createdAt',
            dataIndex: 'createdAt',
            title: (
                <Trans i18nKey="Page.CollectionDetailsPage.Activity.List.CreatedAt" />
            ),
            render: (value: string) => {
                return <DataView value={value} format="date" hideLabel />;
            },
        },
        {
            key: 'generalType',
            dataIndex: 'generalType',
            title: (
                <Trans i18nKey="Page.CollectionDetailsPage.Activity.List.GeneralType" />
            ),
            render: (value: CollectionActivityGeneralType) => {
                return (
                    <DataView
                        value={value}
                        format={(value) => (
                            <EnumView
                                enumName="COLLECTION_ACTIVITY_GENERAL_TYPE"
                                value={value}
                            />
                        )}
                        hideLabel
                    />
                );
            },
        },
        {
            key: 'type',
            dataIndex: 'type',
            title: (
                <Trans i18nKey="Page.CollectionDetailsPage.Activity.List.Type" />
            ),
            render: (value: CollectionActivityType) => {
                return (
                    <DataView
                        value={value}
                        format={(value) => (
                            <EnumView
                                enumName="COLLECTION_ACTIVITY_TYPE"
                                value={value}
                            />
                        )}
                        hideLabel
                    />
                );
            },
        },
        {
            key: 'level',
            dataIndex: 'level',
            title: (
                <Trans i18nKey="Page.CollectionDetailsPage.Activity.List.Level" />
            ),
            render: (value: CollectionLevel) => {
                return (
                    <DataView
                        value={value}
                        format={(value) => (
                            <EnumView
                                enumName="COLLECTION_LEVEL"
                                value={value}
                            />
                        )}
                        hideLabel
                    />
                );
            },
        },
        {
            key: 'nextActionDate',
            dataIndex: 'nextActionDate',
            title: (
                <Trans i18nKey="Page.CollectionDetailsPage.Activity.List.NextActionDate" />
            ),
            render: (value: string) => {
                return <DataView value={value} format="date" hideLabel />;
            },
        },
        {
            key: 'nextAction',
            dataIndex: 'nextAction',
            title: (
                <Trans i18nKey="Page.CollectionDetailsPage.Activity.List.NextAction" />
            ),
            render: (value: CollectionActivityNextAction) => {
                return (
                    <DataView
                        value={value}
                        format={(value) => (
                            <EnumView
                                enumName="COLLECTION_ACTIVITY_NEXT_ACTION"
                                value={value}
                            />
                        )}
                        hideLabel
                    />
                );
            },
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: (
                <Trans i18nKey="Page.CollectionDetailsPage.Activity.List.Status" />
            ),
            render(value) {
                return <CollectionActivityStatus status={value} />;
            },
        },
    ];
};
