import { Col, Row, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { Role } from 'src/generated-api-client';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';
import styled from 'styled-components';

export type RoleAuthoritiesViewProps = { role?: Role };
export const RoleAuthoritiesView = observer(
    ({ role }: RoleAuthoritiesViewProps) => {
        return (
            <StyledSpace size={16} direction="vertical">
                {Object.entries(RolesStore.authoritiesGroupedByEntity).map(
                    ([entity, authorities]) => {
                        return (
                            <StyledCard
                                title={t(`Role.AuthorityGroup.${entity}`)}
                                key={entity}
                            >
                                <Row gutter={16}>
                                    {authorities.map((authority) => {
                                        return (
                                            <Col xs={6} key={authority.value}>
                                                <DataView
                                                    value={role?.authorities.includes(
                                                        authority.value || '',
                                                    )}
                                                    format="yes-no"
                                                    label={authority.value}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </StyledCard>
                        );
                    },
                )}
            </StyledSpace>
        );
    },
);

export const StyledSpace = styled(Space)`
    width: 100%;
`;

const StyledCard = styled(Card)`
    .value-yes {
        color: #0dbd7e;
    }
`;
