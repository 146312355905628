import { Button, Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N24Task, TaskDecisionType } from 'src/generated-api-client';
import { CustomerInformationCard } from 'src/pages/TaskDetailsPage/N24FeeRefundTask/Components/CustomerInformationCard';
import { N24FeeRefundTaskApproveVariables } from 'src/pages/TaskDetailsPage/N24FeeRefundTask/N24FeeRefundTaskApproveVariables';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';
import styled from 'styled-components';

export const N24FeeRefundTask = observer(
    ({ viewModel }: TaskProps<N24Task>) => {
        const task = viewModel.currentTask || {};
        const { documentRoom } = task;

        return (
            <>
                <Row gutter={[16, 24]}>
                    <Col xs={24}>
                        <StyledText>
                            <Trans i18nKey="Task.Form.N24FeeRefundTask.Descriptions" />
                        </StyledText>
                    </Col>
                    <Col xs={24}>
                        <CustomerInformationCard task={task} />
                    </Col>
                </Row>

                <TaskForm
                    fields={viewModel.submitTaskErrorFields}
                    initialValues={getInitialValues(task)}
                    onFinish={async (values) => {
                        await viewModel.submitTask(values);
                        if (!viewModel.submitTaskLoaderHasError) {
                            Router.navigate(RoutePaths.tasks);
                        }
                    }}
                >
                    <DocumentsList
                        documentRoom={documentRoom}
                        canUpload={viewModel.isCurrentTaskEqualWorkingTask}
                    />

                    <Card title={t('Task.Form.N24FeeRefundTask.Title')}>
                        <Row gutter={[16, 24]}>
                            <Col xs={24}>
                                <N24FeeRefundTaskApproveVariables />
                            </Col>
                            <Col xs={24}>
                                <Button
                                    type={
                                        viewModel.isCurrentTaskEqualWorkingTask
                                            ? 'primary'
                                            : 'default'
                                    }
                                    htmlType="submit"
                                >
                                    {t('Task.Form.Btn.Approve')}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </TaskForm>
            </>
        );
    },
);

const getInitialValues = (data?: N24Task) => {
    return {
        ...data,
        type: TaskDecisionType.N2_4_APPROVE,
    };
};

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
