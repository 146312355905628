import { notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { ComponentProvider } from 'src/components/ComponentProvider/ComponentProvider';
import { DocumentsViewModel } from 'src/components/DocumentsList/DocumentsViewModel';
import { Modal } from 'src/components/Modal/Modal';
import { CollateralListEntry } from 'src/generated-api-client';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { TasksStore } from 'src/stores/TasksStore/TasksStore';
import { CollateralsHelper } from 'src/utils/CollateralsHelper';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type EditCollateralDialogProps = ModalRendererProps<{
    collateral: CollateralListEntry;
    viewModel: N13TaskViewModelClass;
}>;

const EditCollateralDialogFn = observer(
    ({ collateral, viewModel, ...props }: EditCollateralDialogProps) => {
        const store = CollateralsStore;
        const isLoading =
            store.itemLoader.isLoading || store.updateItemLoader.isLoading;
        const collateralSubType = collateral?.subType;
        const item = store.currentItem;
        const initialValues = CollateralsHelper.getEditInitialValues(item);
        const convertFormValuesToSubmitValues =
            CollateralsHelper.getConverterEditFormValuesToSubmitValues(item);

        useEffect(() => {
            store.loadItem(collateral.id);

            return () => {
                store.itemLoader.reset();
                store.updateItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );

        return (
            <Modal
                isLoading={isLoading}
                showOnlyLoading={!item}
                title={t('Dialog.EditCollateral.Title')}
                onFinish={async (values) => {
                    const submitValues =
                        (convertFormValuesToSubmitValues &&
                            convertFormValuesToSubmitValues(values)) ||
                        values;
                    await store.update(collateral.id, submitValues);

                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.updateItemLoader.hasError) {
                        notification.success({
                            message: t('Dialog.EditCollateral.Message.Success'),
                        });
                        DocumentsViewModel.getInstance(
                            viewModel.currentTask?.documentRoom?.id,
                        ).load();
                        await TasksStore.itemLoader.reset();
                        viewModel.load();
                    }
                }}
                initialValues={initialValues}
                formProps={{ fields }}
                width={1200}
                {...props}
            >
                <ComponentProvider
                    componentsMap={CollateralsHelper.editVariablesMap}
                    currentComponentKey={collateralSubType}
                />
            </Modal>
        );
    },
);

export const EditCollateralDialog = addModalRenderer(EditCollateralDialogFn);
