/* eslint-disable max-lines */
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { DataView, LONG_DASH } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    DebtCollectionLoanListEntry,
    IdNameEntry,
} from 'src/generated-api-client';
import { useCollectionId } from 'src/hooks/useCollectionId';
import { CreditsBureauStatus } from 'src/pages/LoanDetailsPage/Components/CreditsBureauStatus';
import { LoansStatus } from 'src/pages/LoanDetailsPage/Components/LoansStatus';
import { DebtCollectionsStore } from 'src/stores/DebtCollectionsStore/DebtCollectionsStore';
import { NumberHelper } from 'src/utils/NumberHelper';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

export const LoanTab = observer(() => {
    const { collectionId } = useCollectionId();
    const store = DebtCollectionsStore;

    useEffect(() => {
        store.getActiveLoanList(Number(collectionId));

        return () => {
            store.activeLoanLoader.reset();
        };
    }, [collectionId]);

    return (
        <Card>
            <ListView
                dataSource={store.activeLoanLoader?.data}
                columns={columns}
                loading={store.activeLoanLoader.isLoading}
            />
        </Card>
    );
});

export const columns: ColumnsType<DebtCollectionLoanListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.Id" />,
        render: (loanId: string, loan: DebtCollectionLoanListEntry) => {
            const targetUrl = UrlHelper.getTargetUrl(RoutePaths.loanDetails, {
                loanId,
            });

            return <Link to={targetUrl}>{loan.externalId}</Link>;
        },
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.Amount" />,
        render(value) {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'daysOverdue',
        dataIndex: 'daysOverdue',
        title: (
            <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.DaysOverdue" />
        ),
    },
    {
        key: 'paymentAmount',
        dataIndex: 'paymentAmount',
        title: (
            <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.PaymentAmount" />
        ),
        render(value, record) {
            return getAuditBalanceView(value, record);
        },
    },
    {
        key: 'nextPaymentDate',
        dataIndex: 'nextPaymentDate',
        title: (
            <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.NextPaymentDate" />
        ),
        render(value) {
            return <DataView value={value} format="date" hideLabel />;
        },
    },
    {
        key: 'nextPaymentAmount',
        dataIndex: 'nextPaymentAmount',
        title: (
            <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.NextPaymentAmount" />
        ),
        render(value) {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'totalPaidAmount',
        dataIndex: 'totalPaidAmount',
        title: (
            <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.TotalPaidAmount" />
        ),
        render: (value) => {
            return <ColoredDataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'maturityDate',
        dataIndex: 'maturityDate',
        title: (
            <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.MaturityDate" />
        ),
        render(value) {
            return <DataView value={value} format="date" hideLabel />;
        },
    },

    {
        key: 'collateralIds',
        dataIndex: 'collateralIds',
        title: (
            <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.CollateralIds" />
        ),
        render: (value: Array<number>) => (
            <DataView
                value={value}
                hideLabel
                format={(collateral) => {
                    return Array.isArray(collateral) && collateral.length
                        ? collateral?.map((item: number) => {
                              return (
                                  <>
                                      <Link
                                          to={EntityLinks.collaterals.itemDetails(
                                              item,
                                          )}
                                      >
                                          {collateral}
                                      </Link>
                                      <br />
                                  </>
                              );
                          })
                        : LONG_DASH;
                }}
            />
        ),
    },
    {
        key: 'coBorrowers',
        dataIndex: 'coBorrowers',
        title: (
            <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.CoBorrowers" />
        ),
        render: (value: Array<IdNameEntry>) => (
            <DataView
                value={value}
                hideLabel
                format={(coBorrower) => {
                    return Array.isArray(coBorrower) && coBorrower.length
                        ? coBorrower?.map((item: IdNameEntry) => {
                              return (
                                  <>
                                      <Link
                                          to={EntityLinks.individuals.itemDetails(
                                              item?.id,
                                          )}
                                      >
                                          {item?.name}
                                      </Link>
                                      <br />
                                  </>
                              );
                          })
                        : LONG_DASH;
                }}
            />
        ),
    },
    {
        key: 'loanStatus',
        dataIndex: 'loanStatus',
        title: (
            <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.LoanStatus" />
        ),
        render(value) {
            return <LoansStatus status={value} />;
        },
    },
    {
        key: 'creditBureauStatus',
        dataIndex: 'creditBureauStatus',
        title: (
            <Trans i18nKey="Page.CollectionDetailsPage.Loan.List.CreditBureauStatus" />
        ),
        render(value) {
            return <CreditsBureauStatus status={value} />;
        },
    },
];

const getAuditBalanceView = (
    value?: number,
    record?: DebtCollectionLoanListEntry,
) => {
    return (
        <Space direction="vertical" align="end">
            {value ? (
                <OrangeDataView value={value} format="money" hideLabel />
            ) : (
                <DataView value={value} format="money" hideLabel />
            )}
            <StyledColumnValue
                value={record?.principalAmount}
                hideLabel
                format={(value) => `
                ${t(
                    'Page.CollectionDetailsPage.Loan.List.PaymentAmount.PD',
                )}:  ${NumberHelper.formatToMNT(value)}`}
            />

            <StyledColumnValue
                value={record?.interestAmount}
                hideLabel
                format={(value) => `
                ${t(
                    'Page.CollectionDetailsPage.Loan.List.PaymentAmount.AI',
                )}:  ${NumberHelper.formatToMNT(value)}`}
            />

            <StyledColumnValue
                value={record?.penaltyAmount}
                hideLabel
                format={(value) => `
                ${t(
                    'Page.CollectionDetailsPage.Loan.List.PaymentAmount.P',
                )}:  ${NumberHelper.formatToMNT(value)}`}
            />
        </Space>
    );
};

const StyledColumnValue = styled(DataView)`
    .app-data-view__value {
        font-size: 11px !important;
        padding: 0 4px;
    }
`;

const ColoredDataView = styled(DataView)`
    .app-data-view__value {
        font-weight: 700;
        background: linear-gradient(
            90deg,
            #0d73bd -25.12%,
            #0562a5 -25.09%,
            #17c48d 215.89%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
`;

const OrangeDataView = styled(DataView)`
    .app-data-view__value {
        font-weight: 700;
        color: #f75725;
    }
`;
