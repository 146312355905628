import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { CustomerKycLevel } from 'src/generated-api-client';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export type IndividualDocumentsListCardProps = {
    viewModel: IndividualDetailsViewModelClass;
};

export const IndividualDocumentsListCard = observer(
    ({ viewModel }: IndividualDocumentsListCardProps) => {
        const actions = getDocumentsActions(viewModel).filter(
            (action) => !action.disabled,
        );

        return (
            <DocumentsList
                documentRoom={viewModel?.item?.documentRoom}
                extraOptions={actions}
                isLoading={viewModel.isGenerateMasterAgreementLoading}
                canUpload={AppUser.hasPermissions(
                    UserPermission.DocumentCustomerUpload,
                )}
            />
        );
    },
);

const getDocumentsActions = (viewModel: IndividualDetailsViewModelClass) => {
    const canGenerateMasterAgreement =
        Boolean(viewModel.otherInformation?.kycLevel) &&
        viewModel.otherInformation?.kycLevel !== CustomerKycLevel.KYC1;

    return [
        {
            label: t('IndividualOtherInformationTab.Documents.GenerateMA'),
            key: 'generateMasterAgreement',
            onClick: async () => {
                await viewModel.generateMasterAgreement();
            },
            disabled: !canGenerateMasterAgreement,
        },
    ];
};
