import { NamePath } from 'antd/lib/form/interface';
import React, { useContext, useMemo } from 'react';
import { FormItemGroupNameContext } from 'src/components/Form/FormItemGroup/FormItemGroupNameContext';
import { FormHelper } from 'src/utils/FormHelper';

export type FormItemGroupProps = {
    name: NamePath;
};
export const FormItemGroup: React.FC<FormItemGroupProps> = ({
    name,
    children,
}) => {
    const prefixPath = useContext(FormItemGroupNameContext);
    const fullPath = useMemo(() => {
        return [
            ...FormHelper.namePathToArray(prefixPath),
            ...FormHelper.namePathToArray(name),
        ];
    }, [prefixPath, name]);

    return (
        <FormItemGroupNameContext.Provider value={fullPath}>
            {children}
        </FormItemGroupNameContext.Provider>
    );
};
