import { Col, Row, Segmented } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Empty } from 'src/components/Empty/Empty';
import { ChildBranchListEntry } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { useViewModel } from 'src/hooks/useViewModel';
import { BranchesViewModel } from 'src/pages/BranchesPage/BranchesViewModel';
import { BranchActions } from 'src/pages/BranchesPage/Components/BranchActions';
import { ImageBranchView } from 'src/pages/BranchesPage/Components/ImageBranchView';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';

const viewModel = BranchesViewModel;

const enumsToLoad: EnumName[] = ['DAY_OF_WEEK'];

export const BranchesPage = observer(() => {
    useViewModel(viewModel, []);
    const enumsIsLoading = useEnumsLoader(enumsToLoad);
    const isLoading = viewModel.loadingInfo.isLoading || enumsIsLoading;

    const { childBranches } = viewModel;
    const hasChildBranches = Boolean(childBranches?.length);
    const { options } = viewModel;

    return (
        <BasePage
            title={
                <Row gutter={16}>
                    <Col flex={1}>{t('Page.Branches.Title')}</Col>
                    <Col>
                        <BranchActions />
                    </Col>
                </Row>
            }
            header={
                <Segmented
                    options={options}
                    value={viewModel.activeParentBranchId}
                    onChange={(value) => {
                        viewModel.loadChildBranches(Number(value));
                    }}
                />
            }
            isLoading={isLoading}
        >
            <Row gutter={16}>
                {!hasChildBranches && (
                    <Col xs={24}>
                        <Empty notFound />
                    </Col>
                )}
                {childBranches?.map((branch: ChildBranchListEntry) => {
                    return (
                        <Col xs={8}>
                            <ImageBranchView branch={branch} />
                        </Col>
                    );
                })}
            </Row>
        </BasePage>
    );
});
