/* eslint-disable max-lines */
import {
    Button,
    Col,
    Form as AntForm,
    notification,
    Row,
    Typography,
} from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { ErrorField } from 'src/components/Form/ErrorField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { PhoneField } from 'src/components/Form/PhoneField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { CirclePlusIcon, ModalCloseIcon } from 'src/components/Icons/Icons';
import { Modal } from 'src/components/Modal/Modal';
import {
    CustomerReference as ApiCustomerReference,
    IndividualInformationTabView,
    UpdateIndividualReferenceRequest,
} from 'src/generated-api-client';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

export type UpdateCustomerReferenceDialogFnProps = ModalRendererProps<{
    viewModel?: IndividualDetailsViewModelClass;
}>;

export const UpdateCustomerReferenceDialogFn = observer(
    ({ viewModel, ...props }: UpdateCustomerReferenceDialogFnProps) => {
        useEffect(() => {
            return () => {
                viewModel?.updateCustomerReferencesLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            viewModel?.updateCustomerReferencesLoader.errorData,
        );

        return (
            <Modal
                title={t('Dialog.UpdateCustomerReference.Title')}
                width={900}
                onFinish={async (values) => {
                    await viewModel?.updateCustomerReferences(values);

                    if (viewModel?.updateCustomerReferencesLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel?.updateCustomerReferencesLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.UpdateCustomerReference.Message.Success',
                            ),
                        });
                    }
                }}
                initialValues={getInitialValues(viewModel?.item)}
                isLoading={viewModel?.isUpdateCustomerReferencesLoading}
                formProps={{ fields }}
                {...props}
            >
                <StyledError name="updateIndividualReferenceRequest" />

                <AntForm.List name="customerReferences">
                    {(fields, { add, remove }) => {
                        return (
                            <>
                                {fields.map((field, index) => {
                                    return (
                                        <CustomerReferenceItem
                                            key={field.key}
                                            parentPath={[field.name]}
                                            index={index}
                                            onRemove={() => {
                                                remove(field.name);
                                            }}
                                        />
                                    );
                                })}
                                <Button
                                    type="dashed"
                                    icon={<CirclePlusIcon />}
                                    onClick={() => {
                                        add?.({});
                                    }}
                                >
                                    {t('Form.CustomerReference.AddItem')}
                                </Button>
                            </>
                        );
                    }}
                </AntForm.List>
            </Modal>
        );
    },
);

const getInitialValues = (
    item?: IndividualInformationTabView,
): UpdateIndividualReferenceRequest => {
    return {
        customerReferences: item?.customerReferences || [
            {} as ApiCustomerReference,
        ],
    };
};

export const UpdateCustomerReferenceDialog = addModalRenderer(
    UpdateCustomerReferenceDialogFn,
);

type CustomerReferenceItemProps = {
    parentPath: Array<any>;
    index?: number;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onRemove?: () => void;
};
const CustomerReferenceItem = observer(
    ({ parentPath, index, onRemove }: CustomerReferenceItemProps) => {
        return (
            <CustomerReference>
                <CustomerReferenceHeader>
                    <StyledTitle level={4}>
                        {t('Form.CustomerReferenceEdit.Reference', {
                            index: (index || 0) + 1,
                        })}
                    </StyledTitle>
                    <StyledRemoveButton
                        type="text"
                        icon={<ModalCloseIcon />}
                        onClick={() => {
                            onRemove?.();
                        }}
                        title={t('Form.CustomerReference.RemoteItem')}
                    />
                </CustomerReferenceHeader>
                <CustomerReferenceBody>
                    <HiddenField name={[...parentPath, 'id']} />
                    <Row gutter={16}>
                        <Col xs={8}>
                            <EnumSelectField
                                name={[...parentPath, 'relationship']}
                                label={t(
                                    'Form.CustomerReferenceEdit.Relationship',
                                    'Relationship',
                                )}
                                required
                                rules={[ValidationRules.required]}
                                enumName="REFERENCE_RELATIONSHIP"
                            />
                        </Col>
                        <Col xs={8}>
                            <TextField
                                name={[...parentPath, 'lastName']}
                                label={t(
                                    'Form.CustomerReferenceEdit.LastName',
                                    'Last Name',
                                )}
                                required
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col xs={8}>
                            <TextField
                                name={[...parentPath, 'firstName']}
                                label={t(
                                    'Form.CustomerReferenceEdit.FirstName',
                                    'First Name',
                                )}
                                required
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col xs={8}>
                            <TextField
                                name={[...parentPath, 'registrationNumber']}
                                label={t(
                                    'Form.CustomerReferenceEdit.RegistrationNumber',
                                    'Registration Number',
                                )}
                                required
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col xs={8}>
                            <PhoneField
                                name={[...parentPath, 'phoneNumber']}
                                label={t(
                                    'Form.CustomerReferenceEdit.PhoneNumber',
                                    'Phone Number',
                                )}
                                required
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                    </Row>
                </CustomerReferenceBody>
            </CustomerReference>
        );
    },
);

const CustomerReferenceHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;

const CustomerReference = styled.section``;

const CustomerReferenceBody = styled.section`
    padding: 0px 0 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
`;

const StyledRemoveButton = styled(Button)`
    &:hover {
        svg {
            fill: #51688b;
        }
    }
`;

const StyledTitle = styled(Typography.Title)`
    &.ant-typography {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #4d6082;
    }
`;

const StyledError = styled(ErrorField)`
    margin-bottom: 10px;
`;
