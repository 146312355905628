import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { N110Task } from 'src/generated-api-client';
import { ConditionCardProps } from 'src/pages/TaskViewPage/TaskTypes';
import { UrlHelper } from 'src/utils/UrlHelper';

export const LaatUserConditions = observer(
    ({ task }: ConditionCardProps<N110Task>) => {
        const { laatUserConditions, laatUserProduct } = task;

        const productDetailsUrl = UrlHelper.getTargetUrl(
            RoutePaths.productPledgedDetails,
            { productId: laatUserProduct?.id },
        );

        return (
            <Card
                title={t(
                    'Task.Form.N110TaskApproveConditions.LaatUserConditions.Title',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.createdAt}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.EstimatedDate',
                            )}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.amount}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.Amount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.repaymentFrequency}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.RepaymentFrequency',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="REPAYMENT_FREQUENCY"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.firstRepaymentDate}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.FirstRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.secondRepaymentDate}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.SecondRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.monthlyInterestRate}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.MonthlyInterestRate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.term}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.EstimatedTerm',
                            )}
                            format="number-int"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.FirstPaymentAmount',
                                'Estimated First Payment Amount',
                            )}
                            value={task?.estimatedFirstRepaymentAmount}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.EstimatedDTI',
                                'Estimated DTI',
                            )}
                            value={task?.estimatedIndividualDti}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.EstimatedDtiCoBorrower',
                                'Estimated DTI With Co-Borrower',
                            )}
                            value={task?.estimatedCoBorrowersDti}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserProduct?.name}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.PledgedLoanProduct',
                                'Pledged Loan Product',
                            )}
                            format="link-internal"
                            formatterProps={{
                                to: productDetailsUrl,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
