import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { InsuranceStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: InsuranceStatus;
};

export const CollateralInsuranceStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: InsuranceStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: <EnumView enumName="INSURANCE_STATUS" value={status} />,
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [InsuranceStatus.ACTIVE]: {
        title: (
            <EnumView
                enumName="INSURANCE_STATUS"
                value={InsuranceStatus.ACTIVE}
            />
        ),
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },

    [InsuranceStatus.INACTIVE]: {
        title: (
            <EnumView
                enumName="INSURANCE_STATUS"
                value={InsuranceStatus.INACTIVE}
            />
        ),
        color: '#fa656c',
        backgroundColor: '#FFE4E4',
    },
};
