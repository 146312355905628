import { notification } from 'antd';
import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import { N111Task } from 'src/generated-api-client';
import { TaskViewModelClass } from 'src/pages/TaskViewPage/TaskViewModel';
import { FilesStore } from 'src/stores/FilesStore/FilesStore';
import { EntityProvider } from 'src/utils/EntityProvider';
import { UrlHelper } from 'src/utils/UrlHelper';

export class N111TaskViewModelClass extends TaskViewModelClass<N111Task> {
    constructor(private id: string) {
        super(id);
    }

    @observable isLoading?: boolean = false;

    @action async generateApplicationAgreement() {
        try {
            this.isLoading = true;

            const fileRequest = await FilesStore.requestFile({
                loanApplicationId: this.currentTask.loanApplication.id,
                type: 'NewPledgedApplicationAgreementRequest',
            });
            const files = fileRequest?.files;

            if (Array.isArray(files)) {
                files.forEach((file) => {
                    UrlHelper.downloadFile(
                        file.presignedUrl!,
                        file.fileName || 'Application Agreement',
                    );
                });
            }
        } catch (error) {
            notification.error({
                message: t(
                    'Task.Form.N111TaskUploadSignedContract.GenerateApplicationAgreementError',
                ),
            });
        } finally {
            this.isLoading = false;
        }
    }

    @computed get isGenerateApplicationAgreementLoading() {
        return this.isLoading;
    }
}

export const N111TaskViewModel = new EntityProvider(N111TaskViewModelClass);
