import {
    CreateUserForm,
    EditUserForm,
    User,
    UserListItem,
    UserStatus,
} from 'src/generated-api-client';
import { usersApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type RoleUsersFilter = {
    name?: string;
    phone?: string;
    statuses?: UserStatus[];
    registrationCompleted?: boolean;
    roleIds?: number[] | string[];
};

const RoleUsersFilterKeys: Array<keyof RoleUsersFilter> = [
    'name',
    'phone',
    'statuses',
    'registrationCompleted',
];

export class RoleUsersStoreClass extends BasicStore<
    UserListItem,
    RoleUsersFilter,
    EditUserForm,
    CreateUserForm,
    User
> {
    constructor(private roleId?: number) {
        super();
    }

    api: BasicStoreApi<UserListItem, EditUserForm, CreateUserForm, User> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                usersApi.users(this.listParams),
            );
        },
    };

    filterCriteria = new FilterCriteria<RoleUsersFilter>(
        RoleUsersFilterKeys,
        { applyToSearchParams: true },
        { roleIds: this.roleId ? [this.roleId] : undefined },
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<UserListItem>('id', 'descend');
}

export const RoleUsersStore = new EntityProvider(RoleUsersStoreClass);
