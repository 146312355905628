import { Col, Row, Select as AntdSelect } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { SelectField } from 'src/components/Form/SelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { BoUserAssigneeEntry } from 'src/generated-api-client';
import styled from 'styled-components';

const { Option } = AntdSelect;

type TaskAssigneeSelectProps = {
    label: string;
    assignees: BoUserAssigneeEntry[];
    name?: string | string[];
};

export const TaskAssigneeSelect = observer(
    ({ label, assignees, name }: TaskAssigneeSelectProps) => {
        return (
            <SelectField
                name={name ?? 'userId'}
                label={label}
                rules={[ValidationRules.required]}
                showSearch
            >
                {getOptions(assignees)?.map((t) => (
                    <Option key={t?.value} value={t?.value} label={t?.label}>
                        <Row gutter={16}>
                            <Col xs={18}>
                                <DataView
                                    hideLabel
                                    value={t?.label}
                                    className="demo-option-label-item"
                                />
                            </Col>
                            <StyledCol xs={6}>
                                <DataView
                                    hideLabel
                                    value={t?.activity}
                                    format={(value) => (
                                        <EnumView
                                            enumName="USER_ACTIVITY"
                                            value={value}
                                        />
                                    )}
                                />
                            </StyledCol>
                        </Row>
                    </Option>
                ))}
            </SelectField>
        );
    },
);

const StyledCol = styled(Col)`
    text-align: right;
`;

const asOption = (assignee?: BoUserAssigneeEntry) => ({
    value: assignee?.id,
    label: assignee?.name,
    activity: assignee?.activity,
});

const getOptions = (assignees?: BoUserAssigneeEntry[]) => {
    return assignees?.map(asOption);
};
