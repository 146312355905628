import { notification } from 'antd';
import i18next from 'i18next';
import { action, computed } from 'mobx';
import {
    DocumentTemplateListEntry,
    DocumentTemplateType,
    NewDocumentTemplate,
} from 'src/generated-api-client';
import { documentTemplatesApi } from 'src/services/apiServices';
import { FilesStore } from 'src/stores/FilesStore/FilesStore';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export class DocumentTemplatesStoreClass extends BasicStore<
    DocumentTemplateListEntry,
    any,
    any,
    NewDocumentTemplate
> {
    api: BasicStoreApi<DocumentTemplateListEntry, any, NewDocumentTemplate> = {
        loadList() {
            return RequestHelper.unwrapFromAxiosPromise(
                documentTemplatesApi.getAllDocumentTemplates(),
            );
        },

        createItem(data) {
            return RequestHelper.unwrapFromAxiosPromise(
                documentTemplatesApi.createDocumentTemplate({
                    newDocumentTemplate: data,
                }),
            );
        },
        loadItem(templateType: DocumentTemplateType) {
            return RequestHelper.unwrapFromAxiosPromise(
                documentTemplatesApi.getDocumentTemplate({ templateType }),
            );
        },
    };

    @action async upload(
        documentTemplateType: DocumentTemplateType,
        file: any,
    ) {
        const uploadedFile = await FilesStore.upload(file);

        if (uploadedFile) {
            await this.create({
                type: documentTemplateType,
                uploadedFileId: String(uploadedFile?.id),
            });
        }

        if (this.createItemLoader.hasError) {
            notification.error({
                message: i18next.t('Errors.DocumentTemplateUpload'),
            });
        }

        this.createItemLoader.reset();
    }

    @computed get loadingInfo() {
        return new CombinedLoadingStatus([
            this.createItemLoader,
            FilesStore.uploadFileLoader,
        ]);
    }
}

export const DocumentTemplatesStore = new DocumentTemplatesStoreClass();
