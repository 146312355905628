import moment from 'moment';
import {
    BankStatementListItem,
    BankStatementOperationType,
    BankStatementStatus,
} from 'src/generated-api-client';
import { transactionsApi } from 'src/services/apiServices';
import { DateHelper } from 'src/utils/DateHelper';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type BankStatementsFilter = {
    selfAccounts?: string[];
    transactionDateFrom?: string;
    transactionDateTo?: string;
    accountNumber?: string;
    operationTypes?: BankStatementOperationType[];
    bankStatementStatuses?: BankStatementStatus[];
    description?: string;
};

const BankStatementsFilterKeys: Array<keyof BankStatementsFilter> = [
    'selfAccounts',
    'transactionDateFrom',
    'transactionDateTo',
    'accountNumber',
    'operationTypes',
    'bankStatementStatuses',
    'description',
];
export const DEFAULT_SELF_ACCOUNTS = ['5038097377'];

export class BankStatementsStoreClass extends BasicStore<
    BankStatementListItem,
    BankStatementsFilter
> {
    api: BasicStoreApi<BankStatementListItem> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.findAllBankStatements(this.listParams),
            );
        },

        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.getBankStatement({ id }),
            );
        },
    };

    filterCriteria = new FilterCriteria<BankStatementsFilter>(
        BankStatementsFilterKeys,
        { applyToSearchParams: true },
        {
            selfAccounts: DEFAULT_SELF_ACCOUNTS,
            transactionDateFrom: DateHelper.formatISODate(
                moment().startOf('day').subtract(1, 'months'),
            ),
            transactionDateTo: DateHelper.formatISODate(moment().endOf('day')),
            bankStatementStatuses: [BankStatementStatus.PENDING],
        },
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<BankStatementListItem>(
        'id',
        'descend',
    );
}

export const BankStatementsStore = new BankStatementsStoreClass();
