/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView, LONG_DASH } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    Localization,
    TrustPledgedLoanApplication,
} from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { EnumName, EnumStore } from 'src/stores/Resources/EnumStore/EnumStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const enumsToLoad: EnumName[] = ['CUSTOMER_INCOME_TYPE'];

export type ApplicationDetailsGeneralInfoProps = {
    application?: TrustPledgedLoanApplication;
};

export const GeneralInfoTab = observer(
    ({ application }: ApplicationDetailsGeneralInfoProps) => {
        useEnumsLoader(enumsToLoad);

        const bankInformationName =
            application?.bankInformation?.l10n?.[
                i18next.language as keyof Localization
            ];

        const userDetailsUrl = UrlHelper.getTargetUrl(RoutePaths.userDetails, {
            userId: application?.relationshipManager?.id,
        });

        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.TrustLoanApplicationDetails.RequestedInformation.Title',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={application?.requestedAmount}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.RequestedAmount',
                                    )}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.firstPaymentAmount}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.FirstPaymentAmount',
                                    )}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.firstRepaymentDate}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.FirstRepaymentDate',
                                    )}
                                    format="date"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.maturityDate}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.MaturityDate',
                                    )}
                                    format="date"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={application?.requestedTerm}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.RequestedTerm',
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.repaymentFrequency}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.RepaymentFrequency',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="REPAYMENT_FREQUENCY"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.interestRate}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.AnnuallyRate',
                                    )}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.loanPurpose}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.LoanPurpose',
                                    )}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.TrustLoanApplicationDetails.Origination.Title',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={application?.limitOffer}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.LimitOffer',
                                    )}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.source}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.Source',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="APPLICATION_SOURCE"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.requestedAt}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.CreatedDate',
                                    )}
                                    format="date-time-seconds"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        application?.relationshipManager?.name
                                    }
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.RelationshipManager',
                                    )}
                                    format="link-internal"
                                    formatterProps={{ to: userDetailsUrl }}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.incomeTypes}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.IncomeTypes',
                                    )}
                                    format={(value) => {
                                        return value?.length
                                            ? value
                                                  ?.map((income: string) =>
                                                      EnumStore.getInstance(
                                                          'CUSTOMER_INCOME_TYPE',
                                                      ).getLocalizedEnum(
                                                          income,
                                                      ),
                                                  )
                                                  .join(', ')
                                            : LONG_DASH;
                                    }}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={application?.trustAccount?.amount}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.TrustAccountAmount',
                                    )}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        application?.trustAccount?.interestRate
                                    }
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.TrustAccountInterestRate',
                                    )}
                                    format="percent"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.TrustLoanApplicationDetails.DisbursementInformation.Title',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={application?.disbursementDate}
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.DisbursementDate',
                                    )}
                                    format="date-time-seconds"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        bankInformationName &&
                                        application?.bankAccountNumber &&
                                        `${bankInformationName} ${application?.bankAccountNumber}`
                                    }
                                    label={t(
                                        'Page.TrustLoanApplicationDetails.DisbursementBank',
                                    )}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    },
);
