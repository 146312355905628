import { t } from 'i18next';
import { CreateIndividualCoBorrowerDialog } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Dialogs/CreateIndividualCoBorrowerDialog';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import { ActionDefinition } from 'src/utils/UserActionsHelper';

export const getCoBorrowerExternalActions = (
    viewModel?: N13TaskViewModelClass,
): ActionDefinition[] => {
    return [...getUIActions(viewModel), ...getServerActions()];
};

const getUIActions = (
    viewModel?: N13TaskViewModelClass,
): ActionDefinition[] => {
    return [
        {
            key: 'add-co-borrower',
            label: t('LoanApplication.Actions.CO_BORROWER_ADD'),
            onClick: () => {
                CreateIndividualCoBorrowerDialog.render({
                    viewModel,
                });
            },
            primary: true,
        },
    ];
};

const getServerActions = (): ActionDefinition[] => {
    return [...getRegisteredServerActions()];
};

const getRegisteredServerActions = (): ActionDefinition[] => {
    return [];
};
