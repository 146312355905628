import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { AddressDetails } from 'src/components/AddressView/AddressDetails';
import { AddressViewDialog } from 'src/components/AddressView/AddressViewDialog';
import { Address, CustomerAddress } from 'src/generated-api-client';
import styled from 'styled-components';

const LONG_DASH = '—';

export type AddressViewProps = {
    address: Address | CustomerAddress;
    short?: boolean;
    detailedDialogTitle?: React.ReactNode;
};

export const AddressView = observer(
    ({
        address: passedAddress,
        short,
        detailedDialogTitle,
    }: AddressViewProps) => {
        const address = isCustomerAddress(passedAddress)
            ? passedAddress.address
            : passedAddress;

        if (short) {
            const hasFullAddress = Boolean(address.fullAddress);

            return (
                <StyledAddress>
                    {hasFullAddress ? (
                        <>
                            <StyledFullAddress>
                                {address.fullAddress}
                            </StyledFullAddress>
                            <Button
                                type="link"
                                onClick={() => {
                                    AddressViewDialog.render({
                                        address: passedAddress,
                                        title: detailedDialogTitle,
                                    });
                                }}
                            >
                                {t('Component.AddressView.More', 'more')}
                            </Button>
                        </>
                    ) : (
                        LONG_DASH
                    )}
                </StyledAddress>
            );
        }

        return <AddressDetails address={passedAddress} />;
    },
);

function isCustomerAddress(value: any): value is CustomerAddress {
    return Boolean(value.addressType);
}

const StyledAddress = styled.address`
    position: relative;
    display: flex;
    flex-direction: row;
    align-item: center;
`;

const StyledFullAddress = styled.div`
    flex: 1;
    padding-right: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
