import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';

export type IndividualOwnedEstateCardProps = {
    viewModel?: IndividualDetailsViewModelClass;
};

export const IndividualOwnedEstateCard = observer(
    ({ viewModel }: IndividualOwnedEstateCardProps) => {
        const otherInfo = viewModel?.otherInformation;

        return (
            <Card
                title={t(
                    'Individual.OtherInformation.Origination.OwnedEstateInformation',
                    'Owned estate information',
                )}
                isLoading={viewModel?.otherInformationLoader.isLoading}
            >
                {(otherInfo?.ownedEstates || [{}]).map((ownedEstate) => {
                    return (
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={ownedEstate?.ownedEstateType}
                                    label={t(
                                        'Individual.OtherInformation.OwnedStateInformation.AssetType',
                                        'Asset type',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="OWNED_ESTATE_ASSET_TYPE"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={ownedEstate?.ownedEstateSubType}
                                    label={t(
                                        'Individual.OtherInformation.OwnedStateInformation.SubType',
                                        'Sub type',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="OWNED_ESTATE_ASSET_SUBTYPE"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={ownedEstate?.registrationNumber}
                                    label={t(
                                        'Individual.OtherInformation.OwnedStateInformation.AssetNumber',
                                        'Asset number',
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={ownedEstate?.size}
                                    format="number"
                                    label={t(
                                        'Individual.OtherInformation.OwnedStateInformation.SizeMark',
                                        'Size/Mark',
                                    )}
                                />
                            </Col>
                        </Row>
                    );
                })}
            </Card>
        );
    },
);
