import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { UserAvatar } from 'src/components/UserAvatar';
import { Comment } from 'src/generated-api-client';
import styled from 'styled-components';

type CommentViewProps = {
    comment?: Comment;
};

const DATE_FORMAT = 'MMMM D, YYYY / HH:mma';

export const CommentView = observer(({ comment }: CommentViewProps) => {
    return (
        <StyledRow gutter={16} wrap={false}>
            <Col>
                <StyledAvatar user={comment?.author} size={50} />
            </Col>
            <Col flex={1}>
                <Row>
                    <Col xs={24}>
                        <StyledName>{comment?.author?.name}</StyledName>
                    </Col>
                    <StyledCol xs={24}>
                        <StyledDate>
                            {moment(comment?.createdAt).format(DATE_FORMAT)}
                        </StyledDate>
                    </StyledCol>
                    <Col xs={24}>
                        <StyledText>{comment?.commentText}</StyledText>
                    </Col>
                </Row>
            </Col>
        </StyledRow>
    );
});

const StyledDate = styled(Typography.Text)`
    font-weight: 600;
    font-size: 13px !important;
    line-height: 20px;
    color: #82869b;
`;

const StyledName = styled(Typography.Text)`
    font-weight: 700;
    font-size: 14px !important;
    line-height: 20px;
    color: #2e3e5c;
`;

const StyledText = styled(Typography.Text)`
    font-weight: 400;
    font-size: 16px !important;
    line-height: 25px;
    color: #4d6082;
    word-break: break-word;
    white-space: pre-wrap;
`;

const StyledRow = styled(Row)`
    padding-top: 6px;
`;

const StyledCol = styled(Col)`
    margin-bottom: 20px;
`;

const StyledAvatar = styled(UserAvatar)`
    border: 2px solid #d7d7d7;
    span {
        font-size: 18px;
    }
`;
