import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { TrustAccountCollateral } from 'src/generated-api-client';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';

export const TrustAccountCollateralGeneralInfo = observer(() => {
    const collateral = CollateralsStore.currentItem as TrustAccountCollateral;

    return (
        <Card
            title={t(
                'Page.CollateralDetails.Tabs.TrustAccount.GeneralInfo.Title',
            )}
        >
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral.trustId}
                        label={t(
                            'Page.CollateralDetails.Tabs.TrustAccount.GeneralInfo.TrustId',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.amount}
                        label={t(
                            'Page.CollateralDetails.Tabs.TrustAccount.GeneralInfo.Amount',
                        )}
                        format="money"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.interestRate}
                        label={t(
                            'Page.CollateralDetails.Tabs.TrustAccount.GeneralInfo.InterestRate',
                        )}
                        format="percent"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.openingDate}
                        label={t(
                            'Page.CollateralDetails.Tabs.TrustAccount.GeneralInfo.OpeningDate',
                        )}
                        format="date"
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral.maturityDate}
                        label={t(
                            'Page.CollateralDetails.Tabs.TrustAccount.GeneralInfo.MaturityDate',
                        )}
                        format="date"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.purpose}
                        label={t(
                            'Page.CollateralDetails.Tabs.TrustAccount.GeneralInfo.Purpose',
                        )}
                    />
                </Col>
            </Row>
        </Card>
    );
});
