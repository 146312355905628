import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DotsHorizontalIcon } from 'src/components/Icons/Icons';
import { LegalRepresentativeListEntry } from 'src/generated-api-client';
import { getRepresentativeActions } from 'src/pages/LegalEntityDetailsPage/Components/RepresentativeActions.config';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import styled from 'styled-components';

type RepresentativeActionsProps = {
    representative: LegalRepresentativeListEntry;
    viewModel: LegalEntityDetailsViewModelClass;
};

export const RepresentativeActions = observer(
    ({ representative, viewModel }: RepresentativeActionsProps) => {
        const representativeActions = getRepresentativeActions(
            representative,
            viewModel,
        );

        return (
            <Dropdown
                items={representativeActions}
                disabled={!representativeActions.length}
            >
                <StyledIconButton icon={<DotsHorizontalIcon />} />
            </Dropdown>
        );
    },
);

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;
