import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N11Task } from 'src/generated-api-client';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { SubmitTaskDialog } from 'src/pages/TaskDetailsPage/Dialogs/SubmitTaskDialog';
import { N11TaskCIATUserApproveVariables } from 'src/pages/TaskDetailsPage/N11TaskCIATUser/N11TaskCIATUserApproveVariables';
import { N11TaskCIATUserDeclineVariables } from 'src/pages/TaskDetailsPage/N11TaskCIATUser/N11TaskCIATUserDeclineVariables';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';
import styled from 'styled-components';

export const N11TaskCIATUser = observer(({ viewModel }: TaskProps<N11Task>) => {
    const task = viewModel.currentTask || {};
    const { loanApplication } = task;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <StyledText>
                    <Trans i18nKey="Task.Form.N11TaskСIATUser.Descriptions" />
                </StyledText>
            </Col>
            <Col xs={24}>
                <TaskLinkView
                    loanApplication={loanApplication}
                    collaterals={task.collaterals}
                />
            </Col>
            <Col xs={24}>
                <TaskForm
                    initialValues={task}
                    fields={viewModel.submitTaskErrorFields}
                    onFinish={async (values) => {
                        await viewModel.submitTask(values);
                        if (!viewModel.submitTaskLoaderHasError) {
                            Router.navigate(RoutePaths.tasks);
                        }
                    }}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <N11TaskCIATUserApproveVariables task={task} />
                        </Col>

                        <Col span={24}>
                            <Space size={20}>
                                <Button
                                    type={
                                        viewModel.isCurrentTaskEqualWorkingTask
                                            ? 'primary'
                                            : 'default'
                                    }
                                    htmlType="submit"
                                >
                                    {t('Task.Form.Btn.Approve')}
                                </Button>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        SubmitTaskDialog.render({
                                            viewModel,
                                            title: t(
                                                'Dialog.Reject.N11Task.Title',
                                            ),
                                            children: (
                                                <N11TaskCIATUserDeclineVariables />
                                            ),
                                        });
                                    }}
                                >
                                    {t('Task.Form.Btn.Reject')}
                                </Button>
                                <Button
                                    onClick={() => {
                                        Router.navigate(RoutePaths.tasks);
                                    }}
                                >
                                    {t('Task.Form.Btn.Back')}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </TaskForm>
            </Col>
        </Row>
    );
});

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
