import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    CreditScoringTable,
    CreditScoringTableProps,
    getRows,
    GroupedItem,
} from 'src/pages/IndividualDetailsPage/Tabs/CreditScoringResultTables/CreditScoringTableTypes';
import { NumberHelper } from 'src/utils/NumberHelper';

const columns = [
    {
        title: (
            <Trans i18nKey="IndividualScoringResultTab.DtiScoringTable.Title" />
        ),
        dataIndex: 'title',
        key: 'title',
        width: 400,
    },
    {
        title: (
            <Trans i18nKey="IndividualScoringResultTab.DtiScoringTable.Ranges" />
        ),
        dataIndex: 'ranges',
        key: 'ranges',
    },
    {
        title: (
            <Trans i18nKey="IndividualScoringResultTab.DtiScoringTable.Values" />
        ),
        dataIndex: 'values',
        key: 'values',
    },
    {
        title: (
            <Trans i18nKey="IndividualScoringResultTab.DtiScoringTable.Points" />
        ),
        dataIndex: 'points',
        key: 'points',
        align: 'center' as any,
        width: 150,
        onCell: (record: GroupedItem) => {
            if (record.groupLength === 1) {
                return {};
            }

            if (record.index === 0) {
                return {
                    rowSpan: record.groupLength,
                    className: 'row-span',
                };
            }

            return { rowSpan: 0 };
        },
    },
];

export const DtiScoringTable = observer(
    ({ store, ...props }: CreditScoringTableProps) => {
        const { t } = useTranslation();
        const data = store?.creditScoringResultLoader.data;
        const dti = data?.monthlyIncomeForDtiScoreCustomerResult;

        const groupedData = [
            {
                groupName: 'DTI',
                items: [
                    {
                        title: t(
                            'IndividualScoringResultTab.DtiScoringTable.Title.MonthlyIncome',
                        ),
                        ranges: `${NumberHelper.formatToMNT(
                            dti?.monthlyIncomeForDtiScoreSetting?.monthlyIncome
                                ?.from!,
                        )} - ${NumberHelper.formatToMNT(
                            dti?.monthlyIncomeForDtiScoreSetting?.monthlyIncome
                                ?.to!,
                        )}`,
                        values: NumberHelper.formatToMNT(
                            dti?.customerMonthlyIncome!,
                        ),
                        points: dti?.monthlyIncomeForDtiScoreSetting?.score,
                    },
                    {
                        title: t(
                            'IndividualScoringResultTab.DtiScoringTable.Title.Dti',
                        ),
                        ranges: `${dti?.monthlyIncomeForDtiScoreSetting?.dti?.from}% - ${dti?.monthlyIncomeForDtiScoreSetting?.dti?.to}%`,
                        values: `${dti?.customerDti}%`,
                        points: dti?.monthlyIncomeForDtiScoreSetting?.score,
                    },
                ],
            },
        ];

        const rows = useMemo(() => {
            return getRows(groupedData);
        }, [store?.creditScoringResultLoader.data]);

        const targetRows =
            !store?.creditScoringResultLoader.isLoading && data ? rows : [];

        return (
            <CreditScoringTable
                dataSource={targetRows}
                columns={columns}
                loading={store?.creditScoringResultLoader.isLoading}
                {...props}
            />
        );
    },
);
