import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { AddressView } from 'src/components/AddressView/AddressView';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { DataInformationCard } from 'src/pages/IndividualDetailsPage/Components/DataInformationCard';
import { UpdateJobInformationDialog } from 'src/pages/IndividualDetailsPage/Dialogs/IndividualInformation/UpdateJobInformationDialog';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export type IndividualJobInformationCardProps = {
    viewModel?: IndividualDetailsViewModelClass;
};

export const IndividualJobInformationCard = observer(
    ({ viewModel }: IndividualJobInformationCardProps) => {
        const { customerJobInformationResponse: jobInformation } =
            viewModel?.item || {};

        return (
            <DataInformationCard
                title={t('Page.IndividualDetails.JobInformation.Title')}
                isLoading={viewModel?.itemLoader.isLoading}
                hasData={Boolean(
                    viewModel?.item?.customerJobInformationResponse,
                )}
                canUpdate={AppUser.hasPermissions(
                    UserPermission.IndividualUpdateJobInfo,
                )}
                onAdd={() => {
                    UpdateJobInformationDialog.render({
                        viewModel,
                    });
                }}
            >
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={jobInformation?.education}
                            label={t(
                                'Page.IndividualDetails.JobInformation.Education',
                                'Education',
                            )}
                            format={(value) => (
                                <EnumView enumName="EDUCATION" value={value} />
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={jobInformation?.companyName}
                            label={t(
                                'Page.IndividualDetails.JobInformation.CompanyOrganization',
                                'Company/Organization',
                            )}
                        />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={jobInformation?.industry}
                            label={t(
                                'Page.IndividualDetails.JobInformation.Industry',
                                'Industry',
                            )}
                            format={(value) => (
                                <EnumView enumName="INDUSTRY" value={value} />
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={jobInformation?.employment}
                            label={t(
                                'Page.IndividualDetails.JobInformation.Employment',
                                'Employment',
                            )}
                            format={(value) => (
                                <EnumView enumName="EMPLOYMENT" value={value} />
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={jobInformation?.position}
                            label={t(
                                'Page.IndividualDetails.JobInformation.Position',
                                'Position',
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={jobInformation?.workPhoneNumber}
                            label={t(
                                'Page.IndividualDetails.JobInformation.WorkPhoneNumber',
                                'Work Phone Number',
                            )}
                            format="tel"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24}>
                        <DataView
                            value={jobInformation?.jobAddress}
                            label={t(
                                'Page.IndividualDetails.JobInformation.FullWorkplaceAddress',
                                'Full Workplace Address',
                            )}
                            format={(address) => (
                                <AddressView
                                    address={address}
                                    short
                                    detailedDialogTitle={t(
                                        'Page.IndividualDetails.JobInformation.DetailedAddressViewTitle',
                                        'Job address',
                                    )}
                                />
                            )}
                        />
                    </Col>
                </Row>
            </DataInformationCard>
        );
    },
);
