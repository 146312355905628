/* eslint-disable max-lines */
import { Button, Col, notification, Row, Space } from 'antd';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { Form } from 'src/components/Form/Form';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import {
    FieldMaxLength,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { NotFound } from 'src/components/NotFound/NotFound';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useProductId } from 'src/hooks/useProductId';
import { InstallmentProductsStore } from 'src/stores/InstallmentProductsStore/InstallmentProductsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = InstallmentProductsStore;

export const InstallmentProductEditPage = observer(() => {
    const { productId } = useProductId();

    useEffect(() => {
        store.loadItem(productId);

        return () => {
            store.itemLoader.reset();
            store.updateItemLoader.reset();
        };
    }, [productId]);

    const fields = FormHelper.getFieldDataItems(
        store.updateItemLoader.errorData,
    );

    const loadingInfo = useMemo(() => {
        return new CombinedLoadingStatus([
            store.itemLoader,
            store.updateItemLoader,
        ]);
    }, []);

    const error = useMemo(() => {
        const showNotFound =
            !_.isInteger(Number(productId)) ||
            store.itemLoader.errorData?.status === 404;
        if (showNotFound) {
            return (
                <NotFound
                    title={t(
                        'Page.InstallmentProductEdit.Error.NotFound.Title',
                    )}
                    description={t(
                        'Page.InstallmentProductEdit.Error.NotFound.Description',
                        { productId },
                    )}
                >
                    <Button
                        onClick={() => {
                            Router.navigate(RoutePaths.productsInstallment);
                        }}
                    >
                        {t('Page.InstallmentProductEdit.GoToList')}
                    </Button>
                </NotFound>
            );
        }

        return null;
    }, [productId, store.itemLoader.errorData]);

    return (
        <BasePage
            title={t('Page.InstallmentProductEdit.Title')}
            isLoading={loadingInfo.isLoading}
            showOnlyLoading={!store.currentItem}
            error={error}
        >
            <Form
                onFinish={async (values) => {
                    const product = await store.update(productId, values);
                    if (!store.updateItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Page.InstallmentProductEdit.Message.Success',
                            ),
                        });
                        const targetUrl = UrlHelper.getTargetUrl(
                            RoutePaths.productInstallmentDetails,
                            { productId: product?.id },
                        );

                        Router.navigate(targetUrl);
                    }
                }}
                onReset={() => {
                    const targetUrl = UrlHelper.getTargetUrl(
                        RoutePaths.productInstallmentDetails,
                        { productId },
                    );

                    Router.navigate(targetUrl);
                }}
                fields={fields}
                initialValues={store.currentItem}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            name="name"
                            label={t('Page.InstallmentProductCreate.Name')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.maxFieldLength(
                                    FieldMaxLength.LoanProductName,
                                ),
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="penaltyInterestMultiplier"
                            label={t(
                                'Page.InstallmentProductCreate.PenaltyInterestMultiplier',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberMaxValue(100),
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="interestRate"
                            label={t(
                                'Page.InstallmentProductCreate.InterestRate',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 100),
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="penaltyInterestAccrualStartDay"
                            label={t(
                                'Page.InstallmentProductCreate.PenaltyInterestAccrualStartDay',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.greaterThanZero,
                            ]}
                            onlyInteger
                        />
                    </Col>
                    <Col span={12}>
                        <EnumSelectField
                            name="repaymentAllocationRule"
                            label={t(
                                'Page.InstallmentProductCreate.RepaymentAllocationRule',
                            )}
                            rules={[ValidationRules.required]}
                            enumName="REPAYMENT_ALLOCATION_RULE"
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="dti"
                            label={t('Page.InstallmentProductCreate.Dti')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberMaxValue(100),
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <EnumSelectField
                            name="repaymentCalculationMethod"
                            label={t(
                                'Page.InstallmentProductCreate.RepaymentCalculationMethod',
                            )}
                            rules={[ValidationRules.required]}
                            enumName="REPAYMENT_CALCULATION_METHOD"
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="maxLoanAmount"
                            label={t(
                                'Page.InstallmentProductCreate.MaxLoanAmount',
                            )}
                            thousandDivider
                            rules={[
                                ValidationRules.required,
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <EnumSelectField
                            name="penaltyInterestAccrualMethod"
                            label={t(
                                'Page.InstallmentProductCreate.PenaltyInterestAccrualMethod',
                            )}
                            rules={[ValidationRules.required]}
                            enumName="PENALTY_INTEREST_ACCRUAL_METHOD"
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="maxTenor"
                            label={t('Page.InstallmentProductCreate.MaxTenor')}
                            onlyInteger
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberMaxValue(100),
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col span={24}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                {t('Page.InstallmentProductEdit.Btn.Save')}
                            </Button>
                            <Button type="default" htmlType="reset">
                                {t('Page.InstallmentProductEdit.Btn.Reset')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </BasePage>
    );
});
