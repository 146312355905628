/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { Localization } from 'src/generated-api-client';
import { DataInformationCard } from 'src/pages/LegalEntityDetailsPage/Components/DataInformationCard';
import { UpdateGeneralInformationDialog } from 'src/pages/LegalEntityDetailsPage/Dialogs/LegalEntityInformation/UpdateGeneralInformationDialog';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import {
    hasData,
    LegalEntityDetailKey,
} from 'src/pages/LegalEntityDetailsPage/Types/LegalEntityDetailsTypes';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export type LegalEntityGeneralInfoCardProps = {
    viewModel?: LegalEntityDetailsViewModelClass;
};

const generalInfoFieldKeys: Array<LegalEntityDetailKey> = [
    'name',
    'stateRegistrationNumber',
    'registrationNumber',
    'taxNumber',
    'enterpriseType',
    'establishedDate',
    'investmentType',
    'incomeSource',
    'industry',
    'activity',
    'numberOfBranches',
    'numberOfEmployees',
    'ratingOrganisation',
    'rating',
];

export const LegalEntityGeneralInfoCard = observer(
    ({ viewModel }: LegalEntityGeneralInfoCardProps) => {
        return (
            <DataInformationCard
                title={t('Page.LegalEntityDetails.GeneralInfo.Title')}
                isLoading={viewModel?.itemLoader.isLoading}
                hasData={hasData(generalInfoFieldKeys, viewModel)}
                canUpdate={AppUser.hasPermissions(UserPermission.LegalEdit)}
                onAdd={() => {
                    UpdateGeneralInformationDialog.render({
                        viewModel,
                    });
                }}
            >
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.name}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.CompanyName',
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.stateRegistrationNumber}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.StateRegistrationNumber',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.registrationNumber}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.RegistrationNumber',
                            )}
                        />
                    </Col>

                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.taxNumber}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.TaxPayerNumber',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.enterpriseType}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.EnterpriseType',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="ENTERPRISE_TYPE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>

                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.establishedDate}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.EstablishedDate',
                            )}
                            format="date"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.investmentType}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.InvestmentType',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="INVESTMENT_TYPE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>

                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.incomeSource}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.IncomeSource',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="INCOME_SOURCE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={
                                viewModel?.item?.industry?.l10n?.[
                                    i18next.language as keyof Localization
                                ]
                            }
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.Industry',
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={
                                viewModel?.item?.activity?.l10n?.[
                                    i18next.language as keyof Localization
                                ]
                            }
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.ActivityType',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.numberOfBranches}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.NumberOfBranches',
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.numberOfEmployees}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.NumberOfEmployees',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.ratingOrganisation}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.RatingOrganisation',
                            )}
                            format={(value) =>
                                t(`LegalEntity.RatingOrganisation.${value}`)
                            }
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.rating}
                            label={t(
                                'Page.LegalEntityDetails.GeneralInfo.Rating',
                            )}
                        />
                    </Col>
                </Row>
            </DataInformationCard>
        );
    },
);
