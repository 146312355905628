import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { ReviewActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ReviewActionDialogComponent';
import { ReviewInterestRateChangingActionStore } from 'src/stores/LoanActionsStores/ReviewInterestRateChangingActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId: number;
}>;

export const ReviewInterestRateChangingDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ReviewInterestRateChangingActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ReviewInterestRateChanging.Title');

        return (
            <ReviewActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={store.initialValues?.currentInterestRate}
                            label={t(
                                'Dialog.ReviewInterestRateChanging.CurrentRate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={store.initialValues?.proposedInterestRate}
                            label={t(
                                'Dialog.ReviewInterestRateChanging.NewRate',
                            )}
                            format="percent"
                        />
                    </Col>
                </Row>
            </ReviewActionDialogComponent>
        );
    },
);

export const ReviewInterestRateChangingDialog = addModalRenderer(
    ReviewInterestRateChangingDialogFn,
);
