import { Form, FormItemProps } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Key, useEffect, useState } from 'react';
import { ListView, ListViewProps } from 'src/components/ListView/ListView';

export type ListSelectFieldProps = FormItemProps & {
    mode?: ListSelectProps['mode'];
    store?: ListSelectProps['store'];
    columns?: ListSelectProps['columns'];
    dataSource?: ListSelectProps['dataSource'];
    listSelectProps?: Omit<
        ListSelectProps,
        'mode' | 'store' | 'columns' | 'dataSource' | 'onChange'
    >;
};

export const ListSelectField = observer(
    ({
        name,
        label,
        mode,
        store,
        columns,
        dataSource,
        listSelectProps,
        ...props
    }: ListSelectFieldProps) => {
        return (
            <Form.Item {...props} name={name} label={label}>
                <ListSelect
                    mode={mode}
                    store={store}
                    columns={columns}
                    dataSource={dataSource}
                    {...listSelectProps}
                />
            </Form.Item>
        );
    },
);

type ListSelectProps = ListViewProps<any> & {
    mode?: 'multiple';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange?: (value: any) => void;
};

const ListSelect = observer(
    ({ onChange, mode, store, ...props }: ListSelectProps) => {
        const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();

        useEffect(() => {
            store?.loadList();

            return () => {
                store?.listLoader.reset();
            };
        }, []);

        return (
            <ListView
                {...props}
                store={store}
                rowSelection={{
                    type: mode === 'multiple' ? 'checkbox' : 'radio',
                    selectedRowKeys,
                    onChange: (selectedRowKeys) => {
                        setSelectedRowKeys(selectedRowKeys);

                        if (mode === 'multiple') {
                            onChange?.(selectedRowKeys);
                        } else {
                            onChange?.(selectedRowKeys[0]);
                        }
                    },
                }}
            />
        );
    },
);
