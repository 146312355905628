import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Modal } from 'src/components/Modal/Modal';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { ModalRendererProps } from 'src/utils/ModalRenderer';

export type ReviewActionDialogComponentProps = ModalRendererProps<{
    loanId?: number;
    store?: BasicActionStore<any>;
    title?: React.ReactNode;
    children?: React.ReactNode;
}>;

export const ReviewActionDialogComponent: React.FC<ReviewActionDialogComponentProps> =
    observer(
        ({
            loanId,
            store,
            title,
            children,
            ...props
        }: ReviewActionDialogComponentProps) => {
            const operationId = store?.initialValues?.operationId;

            useEffect(() => {
                store?.load?.();

                return () => {
                    store?.initialValuesLoader.reset();
                };
            }, [loanId]);

            return (
                <Modal
                    title={title}
                    isLoading={store?.isLoading}
                    showOnlyLoading={
                        store?.initialValuesLoader.countLoads === 0
                    }
                    closable={false}
                    maskClosable={false}
                    footer={[
                        <Button
                            key="cancel"
                            disabled={store?.isLoading}
                            onClick={async () => {
                                await store?.cancel(operationId);
                                LoansStore.loadItem(loanId);
                                props.onCancel();
                            }}
                        >
                            {t('Dialog.Restructuring.DeclineButton')}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            disabled={store?.isLoading}
                            onClick={async () => {
                                await store?.ok(operationId);
                                LoansStore.loadItem(loanId);
                                props.onCancel();
                            }}
                        >
                            {t('Dialog.Restructuring.ApproveButton')}
                        </Button>,
                    ]}
                    {...props}
                >
                    {children}
                </Modal>
            );
        },
    );
