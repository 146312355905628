import { t } from 'i18next';
import { computed } from 'mobx';
import React from 'react';
import { TaskName } from 'src/generated-api-client';
import { N11TaskCIATUser } from 'src/pages/TaskDetailsPage/N11TaskCIATUser/N11TaskCIATUser';
import { N12ChooseLaatUserTask } from 'src/pages/TaskDetailsPage/N12ChooseLaatUserTask/N12ChooseLaatUserTask';
import { N13AddEstimatedConditionsTask } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13AddEstimatedConditionsTask';
import { N14TaskLAATLeader } from 'src/pages/TaskDetailsPage/N14TaskLAATLeader/N14TaskLAATLeader';
import { N15ChooseDmtUsersTask } from 'src/pages/TaskDetailsPage/N15ChooseDmtUsersTask/N15ChooseDmtUsersTask';
import { N16TaskAddCCConditions } from 'src/pages/TaskDetailsPage/N16TaskAddССConditions/N16TaskAddССConditions';
import { N17ChooseDmtAssigneeTask } from 'src/pages/TaskDetailsPage/N17ChooseDmtAssigneeTask/N17ChooseDmtAssigneeTask';
import { N18CsDecisionTask } from 'src/pages/TaskDetailsPage/N18CsDecisionTask/N18CsDecisionTask';
import { N19ChooseRmUsersTask } from 'src/pages/TaskDetailsPage/N19ChooseRmUserTask/N19ChooseRmUserTask';
import { N21AddBankStatementTask } from 'src/pages/TaskDetailsPage/N21AddBankStatementTask/N21AddBankStatementTask';
import { N22EnterIATUserDecision } from 'src/pages/TaskDetailsPage/N22EnterIATUserDecision/N22EnterIATUserDecision';
import { N23AddBusinessIncomeTask } from 'src/pages/TaskDetailsPage/N23AddBusinessIncome/N23AddBusinessIncome';
import { N24FeeRefundTask } from 'src/pages/TaskDetailsPage/N24FeeRefundTask/N24FeeRefundTask';
import { N41ManualReAssignmentTask } from 'src/pages/TaskDetailsPage/N41ManualReAssignmentTask/N41ManualReAssignmentTask';
import { N110TaskApproveConditions } from 'src/pages/TaskDetailsPage/N110TaskApproveConditions/N110TaskApproveConditions';
import { N111TaskUploadSignedContract } from 'src/pages/TaskDetailsPage/N111TaskUploadSignedContract/N111TaskUploadSignedContract';
import { N112TaskChooseAccountantUser } from 'src/pages/TaskDetailsPage/N112TaskСhooseAccountantUser/N112TaskСhooseAccountantUser';
import { N113ApproveDisbursementTask } from 'src/pages/TaskDetailsPage/N113ApproveDisbursmentTask/N113ApproveDisbursementTask';
import { UnknownTaskPresentation } from 'src/pages/TaskDetailsPage/UnknownTaskPresentation/UnknownTaskPresentation';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type AnyComponent = (...props: any[]) => JSX.Element;
export type TaskDefinition = {
    key: TaskName;
    Component: AnyComponent;
    title?: React.ReactNode;
};

export class TasksRegisterStoreClass {
    private taskDefinitionsMap = new Map<TaskName, TaskDefinition>(
        this.registeredTasks?.map((task) => [task.key, task]),
    );

    getTaskDefinition(formKey: TaskName): TaskDefinition {
        return (
            this.taskDefinitionsMap.get(formKey) ||
            ({
                key: 'unknown' as any,
                Component: UnknownTaskPresentation,
                title: t('Page.UnknownTaskPresentation.Error.NotFound.Title'),
            } as TaskDefinition)
        );
    }

    @computed private get registeredTasks(): TaskDefinition[] {
        return [
            {
                key: TaskName.N1_1_ADD_COLLATERAL_DATA,
                Component: N11TaskCIATUser,
            },
            {
                key: TaskName.N1_2_CHOOSE_LAAT_USER,
                Component: N12ChooseLaatUserTask,
            },
            {
                key: TaskName.N1_3_LAAT_USER_ADD_ESTIMATED_CONDITIONS,
                Component: N13AddEstimatedConditionsTask,
            },
            {
                key: TaskName.N1_4_MAKE_FINAL_DECISION,
                Component: N14TaskLAATLeader,
            },
            {
                key: TaskName.N1_5_CHOOSE_DMT_USERS,
                Component: N15ChooseDmtUsersTask,
            },
            {
                key: TaskName.N1_6_DMT_USER_ADD_LOAN_CONDITIONS,
                Component: N16TaskAddCCConditions,
            },
            {
                key: TaskName.N1_7_CHOOSE_DMT_USER,
                Component: N17ChooseDmtAssigneeTask,
            },
            {
                key: TaskName.N1_8_CS_MAKE_FINAL_DECISION,
                Component: N18CsDecisionTask,
            },
            {
                key: TaskName.N1_9_CHOOSE_RM,
                Component: N19ChooseRmUsersTask,
            },
            {
                key: TaskName.N1_10_RM_ADD_LOAN_CONDITIONS,
                Component: N110TaskApproveConditions,
            },
            {
                key: TaskName.N1_11_RM_UPLOAD_DOCUMENTS,
                Component: N111TaskUploadSignedContract,
            },
            {
                key: TaskName.N1_12_CHOOSE_ACCOUNTANT,
                Component: N112TaskChooseAccountantUser,
            },
            {
                key: TaskName.N1_13_ACCOUNTANT_DISBURSEMENT_DECISION,
                Component: N113ApproveDisbursementTask,
            },
            {
                key: TaskName.N2_1_ADD_BANK_STATEMENT_DATA,
                Component: N21AddBankStatementTask,
            },
            {
                key: TaskName.N2_2_IAT_USER_REVIEW,
                Component: N22EnterIATUserDecision,
            },
            {
                key: TaskName.N2_3_ADD_BUSINESS_INCOME,
                Component: N23AddBusinessIncomeTask,
            },
            {
                key: TaskName.N2_4_FEE_REFUND,
                Component: N24FeeRefundTask,
            },
            {
                key: TaskName.N4_1_CHOOSE_ASSIGNEE,
                Component: N41ManualReAssignmentTask,
            },
        ];
    }
}

export const TasksRegisterStore = new TasksRegisterStoreClass();
