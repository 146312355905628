import { BasePage, BasePageProps } from 'src/components/BasePage/BasePage';
import styled from 'styled-components';

export const AuthPage = styled((props: BasePageProps) => (
    <BasePage {...props} />
))`
    max-width: 400px;
    margin-top: 50px;
    border-radius: 8px;
    background-color: white;
    padding: 32px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);

    .page-spin {
        width: 100vw;
        left: 0;
    }
`;
