/* eslint-disable max-lines */
import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N14Task } from 'src/generated-api-client';
import { TaskCommentsView } from 'src/pages/TaskDetailsPage/Components/TaskCommentsView';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { SubmitTaskDialog } from 'src/pages/TaskDetailsPage/Dialogs/SubmitTaskDialog';
import { LaaLoanConditionCard } from 'src/pages/TaskDetailsPage/N14TaskLAATLeader/Components/LaaLoanConditionCard';
import { RequestedLoanConditionCard } from 'src/pages/TaskDetailsPage/N14TaskLAATLeader/Components/RequestedLoanConditionCard';
import { N14TaskLAATLeaderApproveVariables } from 'src/pages/TaskDetailsPage/N14TaskLAATLeader/N14TaskLAATLeaderApproveVariables';
import { N14TaskLAATLeaderDeclineVariables } from 'src/pages/TaskDetailsPage/N14TaskLAATLeader/N14TaskLAATLeaderDeclineVariables';
import { N14TaskLAATLeaderReturnVariables } from 'src/pages/TaskDetailsPage/N14TaskLAATLeader/N14TaskLAATLeaderReturnVariables';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';
import styled from 'styled-components';

export const N14TaskLAATLeader = observer(
    ({ viewModel }: TaskProps<N14Task>) => {
        const task = viewModel.currentTask || {};
        const {
            requestedConditions,
            laatUserConditions,
            loanApplication,
            collaterals,
        } = task;
        const showRequestedConditions = Boolean(requestedConditions);
        const showLaatUserConditions = Boolean(laatUserConditions);

        const comments = [
            {
                label: t('Component.TaskCommentsView.LAATUserComment'),
                value: laatUserConditions?.comment,
            },
        ];

        return (
            <TaskForm
                initialValues={getInitialValues(task)}
                fields={viewModel.submitTaskErrorFields}
                onFinish={async (values) => {
                    await viewModel.submitTask(values);
                    if (!viewModel.submitTaskLoaderHasError) {
                        Router.navigate(RoutePaths.tasks);
                    }
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <StyledText>
                            <Trans i18nKey="Task.Form.N14TaskLAATLeader.Descriptions" />
                        </StyledText>
                    </Col>
                    <Col xs={24}>
                        <TaskLinkView
                            loanApplication={loanApplication}
                            collaterals={collaterals}
                        />
                    </Col>
                </Row>

                {showRequestedConditions && (
                    <RequestedLoanConditionCard task={task} />
                )}

                {showLaatUserConditions && <LaaLoanConditionCard task={task} />}

                <TaskCommentsView comments={comments} />

                <DocumentsList
                    documentRoom={task?.documentRoom}
                    canUpload={false}
                />

                <Row gutter={16}>
                    <Col xs={24}>
                        <N14TaskLAATLeaderApproveVariables />
                    </Col>

                    <Col xs={24}>
                        <Space size={20}>
                            <Button
                                type={
                                    viewModel.isCurrentTaskEqualWorkingTask
                                        ? 'primary'
                                        : 'default'
                                }
                                htmlType="submit"
                            >
                                {t('Task.Form.Btn.Approve')}
                            </Button>
                            <Button
                                onClick={() => {
                                    SubmitTaskDialog.render({
                                        viewModel,
                                        title: t(
                                            'Dialog.Reject.N14TaskLAATLeader.Title',
                                        ),
                                        children: (
                                            <N14TaskLAATLeaderDeclineVariables />
                                        ),
                                    });
                                }}
                            >
                                {t('Task.Form.Btn.Reject')}
                            </Button>
                            <Button
                                onClick={() => {
                                    SubmitTaskDialog.render({
                                        viewModel,
                                        title: t(
                                            'Dialog.Return.N14TaskLAATLeader.Title',
                                        ),
                                        children: (
                                            <N14TaskLAATLeaderReturnVariables />
                                        ),
                                    });
                                }}
                            >
                                {t('Task.Form.Btn.Return')}
                            </Button>
                            <Button
                                onClick={() => {
                                    Router.navigate(RoutePaths.tasks);
                                }}
                            >
                                {t('Task.Form.Btn.Back')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </TaskForm>
        );
    },
);

const getInitialValues = (data?: N14Task) => {
    return {
        ...data,
        adjustmentRequired: false,
    };
};

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
