import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DotsHorizontalIcon } from 'src/components/Icons/Icons';
import { TaskMetadata } from 'src/generated-api-client';
import { getTaskActions } from 'src/pages/TasksPage/Components/TaskActions.config';
import styled from 'styled-components';

type TaskActionsProps = {
    task?: TaskMetadata;
};

export const TaskActions = observer(({ task }: TaskActionsProps) => {
    const taskActions = getTaskActions(task);

    return (
        <Dropdown items={taskActions} disabled={!taskActions.length}>
            <StyledIconButton icon={<DotsHorizontalIcon />} />
        </Dropdown>
    );
});

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;
