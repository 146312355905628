import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';

export const LoansFilterForm = observer(() => {
    return (
        <>
            <TextField
                name="externalId"
                label={t('Page.Loans.Filter.ExternalId')}
            />
            <EnumSelectField
                mode="multiple"
                name="productTypes"
                label={t('Page.Loans.Filter.ProductType')}
                enumName="PRODUCT_TYPE"
            />
            <TextField name="civilId" label={t('Page.Loans.Filter.CivilId')} />
            <TextField
                name="customerName"
                label={t('Page.Loans.Filter.CustomerName')}
            />
            <TextField
                name="phone"
                label={t('Page.Loans.Filter.PhoneNumber')}
            />
            <TextField
                name="customerRegistrationNumber"
                label={t('Page.Loans.Filter.CustomerRegistrationNumber')}
            />
            <NumberField
                name="relationshipManagerId"
                label={t('Page.Loans.Filter.RelationshipManagerId')}
                onlyInteger
            />
            <TextField
                name="branchCode"
                label={t('Page.Loans.Filter.BranchCode')}
            />
            <TextField
                name="disbursementPaymentAccountNumber"
                label={t('Page.Loans.Filter.DisbursementPaymentAccountNumber')}
            />
            <EnumSelectField
                mode="multiple"
                name="loanStatuses"
                label={t('Page.Loans.Filter.LoanStatus')}
                enumName="LOAN_STATUS"
            />
            <DatePickerField
                name="disbursementDateFrom"
                label={t('Page.Loans.Filter.DisbursementDateFrom')}
            />
            <DatePickerField
                name="disbursementDateTo"
                label={t('Page.Loans.Filter.DisbursementDateTo')}
            />
        </>
    );
});
