import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { ProposeActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ProposeActionDialogComponent';
import { ProposeInterestRateChangingActionStore } from 'src/stores/LoanActionsStores/ProposeInterestRateChangingActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

export const ProposeInterestRateChangingDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ProposeInterestRateChangingActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ProposeInterestRateChanging.Title');

        return (
            <ProposeActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <NumberField
                    name="currentInterestRate"
                    label={t('Dialog.ProposeInterestRateChanging.CurrentRate')}
                    disabled
                />
                <NumberField
                    name="interestRate"
                    label={t('Dialog.ProposeInterestRateChanging.NewRate')}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.numberRange(0, 100),
                    ]}
                />
            </ProposeActionDialogComponent>
        );
    },
);

export const ProposeInterestRateChangingDialog = addModalRenderer(
    ProposeInterestRateChangingDialogFn,
);
