import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { ReviewActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ReviewActionDialogComponent';
import { ReviewPastDateRepaymentActionStore } from 'src/stores/LoanActionsStores/ReviewPastDateRepaymentActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId: number;
}>;

export const ReviewPastDateRepaymentDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ReviewPastDateRepaymentActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ReviewPastDateRepayment.Title');

        return (
            <ReviewActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={store.initialValues?.paymentDate}
                            label={t(
                                'Dialog.ReviewPastDateRepayment.PaymentDate',
                            )}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={store.initialValues?.amount}
                            label={t('Dialog.ReviewPastDateRepayment.Amount')}
                            format="money"
                        />
                    </Col>
                    <Col xs={24}>
                        <DataView
                            value={store.initialValues?.comment}
                            label={t('Dialog.ReviewPastDateRepayment.Comment')}
                            format="string"
                        />
                    </Col>
                </Row>
            </ReviewActionDialogComponent>
        );
    },
);

export const ReviewPastDateRepaymentDialog = addModalRenderer(
    ReviewPastDateRepaymentDialogFn,
);
