import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { FilterForm } from 'src/components/Form/FilterForm';
import { PlusIcon } from 'src/components/Icons/Icons';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { InstallmentProductsFilterForm } from 'src/pages/LoanProducts/InstallmentProductsPage/InstallmentProductsFilterForm';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { InstallmentProductsStore } from 'src/stores/InstallmentProductsStore/InstallmentProductsStore';

type InstallmentProductsHeaderPanelProps = {
    title: React.ReactNode;
};

const store = InstallmentProductsStore;

export const InstallmentProductsHeaderPanel = observer(
    ({ title }: InstallmentProductsHeaderPanelProps) => {
        const canCreate = AppUser.hasPermissions(UserPermission.ProductCreate);

        return (
            <div>
                <FilterForm
                    store={store}
                    title={title}
                    panelExtra={
                        canCreate && (
                            <Button
                                icon={<PlusIcon />}
                                type="primary"
                                onClick={() =>
                                    Router.navigate(
                                        RoutePaths.productInstallmentCreate,
                                    )
                                }
                            >
                                {t(
                                    'Page.InstallmentProducts.Button.CreateProduct',
                                )}
                            </Button>
                        )
                    }
                >
                    <InstallmentProductsFilterForm />
                </FilterForm>
            </div>
        );
    },
);
