/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { N14Task } from 'src/generated-api-client';
import { ConditionCardProps } from 'src/pages/TaskViewPage/TaskTypes';

export const RequestedLoanConditionCard = observer(
    ({ task }: ConditionCardProps<N14Task>) => {
        const { requestedConditions } = task;

        return (
            <Card
                title={t(
                    'Task.Form.N14TaskLAATLeader.RequestedConditions.Title',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.createdAt}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.RequestedConditions.RequestedDate',
                            )}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.amount}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.RequestedConditions.Amount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.monthlyInterestRate}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.RequestedConditions.SystemInterestRate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.term}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.RequestedConditions.Term',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.firstRepaymentDate}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.RequestedConditions.FirstRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={requestedConditions?.secondRepaymentDate}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.RequestedConditions.SecondRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={task?.requestedLoanPurpose}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.RequestedConditions.RequestedLoanPurpose',
                                'Requested Loan Purpose',
                            )}
                            format="string"
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
