import {
    Form,
    FormItemProps,
    Space,
    Typography,
    Upload,
    UploadProps,
} from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import classNames from 'classnames';
import { t } from 'i18next';
import React, { SetStateAction, useEffect, useState } from 'react';
import { Spinner } from 'src/components/Spinner/Spinner';
import styled from 'styled-components';

const { Text } = Typography;

export type UploadImageProps = Omit<UploadProps, 'onChange'> & {
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    value?: string | File;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,react/require-default-props
    onChange?: (fileList: UploadFile[]) => void;
    // eslint-disable-next-line react/require-default-props
    isInitialValueLoading?: boolean;
};

export const UploadImage: React.FC<UploadImageProps> = ({
    value,
    onChange,
    label,
    accept,
    disabled,
    isInitialValueLoading,
    children,
    ...props
}: UploadImageProps) => {
    const [imgURL, setImgURL] = useState<SetStateAction<any> | undefined>();
    const [isImageLoading, setIsImageLoading] = useState<boolean>();
    const isLoading = isImageLoading || isInitialValueLoading;

    const getBase64 = (img: any) => {
        const reader = new FileReader();
        reader.onloadstart = () => setIsImageLoading(true);
        reader.onloadend = () => {
            setIsImageLoading(false);
            setImgURL(reader.result);
        };
        reader.readAsDataURL(img);
    };

    useEffect(() => {
        if (value instanceof File) {
            getBase64(value);
        }
        if (value && typeof value === 'string') {
            setImgURL(value);
        }
    }, [value]);

    const handleChange = (info: UploadChangeParam) => {
        getBase64(info.file);
        onChange?.([...info.fileList]);
    };

    return (
        <Space size={12} direction="vertical" align="center">
            <StyledImage
                className={classNames('image-view', {
                    empty: !imgURL,
                })}
                url={imgURL}
            >
                {isLoading && <Spinner />}
            </StyledImage>
            <StyledSpace size={10}>
                {Boolean(label) && <StyledText>{label}</StyledText>}
                {!disabled && (
                    <StyledUpload
                        className="image-uploader"
                        onChange={handleChange}
                        beforeUpload={() => false}
                        accept={accept}
                        showUploadList={false}
                        maxCount={1}
                        {...props}
                    >
                        {t('Component.UploadImage.UploadImage')}
                    </StyledUpload>
                )}
            </StyledSpace>
        </Space>
    );
};

export type ImageFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    isInitialValueLoading?: boolean;
    // eslint-disable-next-line react/require-default-props,@typescript-eslint/no-unused-vars
    onChange?: (fileList: UploadFile[]) => void;
    // eslint-disable-next-line react/require-default-props
    accept?: UploadProps['accept'];
    // eslint-disable-next-line react/require-default-props
    uploadProps?: Omit<UploadProps, 'accept' | 'onChange'>;
};

export const ImageField: React.FC<ImageFieldProps> = ({
    name,
    label,
    disabled,
    onChange,
    accept,
    isInitialValueLoading,
    uploadProps,
    children,
    ...props
}) => {
    return (
        <Form.Item {...props} name={name}>
            <UploadImage
                label={label}
                accept={accept}
                onChange={onChange}
                disabled={disabled}
                isInitialValueLoading={isInitialValueLoading}
                {...uploadProps}
            />
        </Form.Item>
    );
};

const StyledImage = styled.div<{ url?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 135px;
    height: 135px;
    border: 2px solid #d7d7d7;
    border-radius: 50%;

    background-image: url(${(props) => props?.url});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &.empty {
        border: 2px dashed #82869b;
    }
`;

const StyledText = styled(Text)`
    font-size: 14px;
    line-height: 25px;
    color: #82869b;
    text-align: center;
`;

const StyledSpace = styled(Space)`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 32px;
`;

const StyledUpload = styled(Upload)`
    margin: 0;

    span.ant-upload {
        cursor: pointer;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;

        background: linear-gradient(
            90deg,
            #0d73bd -25.12%,
            #0562a5 -25.09%,
            #17c48d 215.89%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
    }
`;
