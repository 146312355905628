import { Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { Router } from 'src/core/router/Router';
import { RouterStore } from 'src/stores/RouterStore/RouterStore';
import {
    isRouteGroup,
    RouteDefinition,
} from 'src/stores/RouterStore/RouterStore.types';
import styled from 'styled-components';

export const AppMenu = observer(() => {
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState<string[]>();

    useEffect(() => {
        const keys = RouterStore.routes
            .filter((route) => {
                return matchPath(location.pathname, {
                    exact: false,
                    path: route.path,
                });
            })
            .map((route) => route?.key);

        setSelectedKeys(keys);
    }, [location.pathname]);

    return (
        <StyledMenu
            theme="dark"
            openKeys={selectedKeys}
            selectedKeys={selectedKeys}
            mode="inline"
            items={RouterStore.menuItems.map((route) => getMenuItem(route))}
        >
            {/* {RouterStore.menuItems.map((route) => {
                if (isRouteGroup(route)) {
                    return (
                        <Menu.SubMenu title={route.label?.()} key={route.key}>
                            {route.routes?.map((innerRoute) => {
                                return <MenuItem route={innerRoute} />;
                            })}
                        </Menu.SubMenu>
                    );
                }

                return <MenuItem route={route} />;
            })} */}
        </StyledMenu>
    );
});

const getMenuItem = (route: RouteDefinition): ItemType => {
    const isGroup = isRouteGroup(route);
    const hasPath = Boolean(route.path);
    const label =
        hasPath && !isGroup ? (
            <NavLink to={route.path}>{route.label}</NavLink>
        ) : (
            route.label
        );

    const children = isRouteGroup(route)
        ? route.children
              ?.filter((r) => Boolean(r.label))
              .map((r) => getMenuItem(r))
        : undefined;

    if (children?.length === 0) {
        return null;
    }

    return {
        key: route.key,
        icon: route.icon,
        label,
        children,
        onTitleClick: isGroup
            ? () => {
                  Router.navigate(route.path);
              }
            : undefined,
    };
};

const StyledMenu = styled(Menu)`
    margin-top: 36px;

    .ant-menu-title-content {
        margin-left: 16px;
    }

    .ant-menu-item {
        svg {
            transition: color 0.3s;
        }
    }
`;
