import { Col, notification, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { UsersStore } from 'src/stores/UsersStore/UsersStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

export type ChangeActivityStatusDialogProps = ModalRendererProps<{
    userId: number;
}>;

const store = UsersStore;

export const ChangeActivityDialogStatusFn = observer(
    ({ userId, ...props }: ChangeActivityStatusDialogProps) => {
        useEffect(() => {
            return () => {
                store.changeActivityLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.changeActivityLoader.errorData,
        );

        return (
            <Modal
                title={t('Dialog.UserChangeActivityStatus.Title')}
                isLoading={store.changeActivityLoader.isLoading}
                onFinish={async (values) => {
                    await store.changeActivity(userId, values);

                    if (!store.changeActivityLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.UserChangeActivityStatus.Message.Success',
                            ),
                        });

                        await store.reloadUserId(userId);
                    }

                    if (store.changeActivityLoader.hasError) {
                        throw new Error(store.changeActivityLoader.errorData);
                    }
                }}
                formProps={{ fields }}
                {...props}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <EnumSelectField
                            name="activity"
                            label={t(
                                'Dialog.UserChangeActivityStatus.Activity',
                            )}
                            rules={[ValidationRules.required]}
                            enumName="USER_ACTIVITY"
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const ChangeActivityStatusDialog = addModalRenderer(
    ChangeActivityDialogStatusFn,
);
