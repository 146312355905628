import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { action, computed, observable } from 'mobx';
import { IAuthStore } from 'src/stores/AuthStore/AuthStore.types';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { TokenHelper } from 'src/utils/TokenHelper';

export const UPDATE_TOKEN_MIN_VALIDITY = 30;

export class AuthStoreKeycloakClass implements IAuthStore {
    @observable keycloak?: Keycloak;

    @observable authenticated = false;
    @observable token?: string;

    @action setupKeycloak() {
        if (!SettingsStore.settings?.authentication) {
            console.error('Keycloack settings is not provided');

            return;
        }
        this.keycloak = new Keycloak(
            SettingsStore.settings?.authentication as KeycloakConfig,
        );
        this.keycloak.onTokenExpired = () => {
            this.refreshTokenIfNeed();
        };
        this.keycloak.onAuthRefreshError = () => {
            this.authenticated = false;
            this.logout();
        };
    }

    @action async init() {
        await this.setupKeycloak();
    }

    @action async refreshTokenIfNeed() {
        try {
            await this.keycloak?.updateToken(UPDATE_TOKEN_MIN_VALIDITY);
        } catch (error) {
            console.warn(`Application can't update token using refreshToken`);
        }

        this.token = this.keycloak?.token;
    }

    @action async authenticate() {
        try {
            const authenticated =
                (await this.keycloak?.init({
                    onLoad: 'check-sso',
                    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
                    pkceMethod: 'S256',
                })) || false;

            if (!authenticated) {
                await this.keycloak?.login();
            }

            this.authenticated = authenticated;
            this.token = this.keycloak?.token;
        } catch (error) {
            this.authenticated = false;
            this.token = undefined;
        }
    }

    // eslint-disable-next-line require-await
    @action async logout(_redirectUrl?: string) {
        if (this.isRefreshTokenExpired()) {
            window.location.reload();
        } else {
            await this.keycloak?.logout();
        }
    }

    isRefreshTokenExpired() {
        return (
            (this.keycloak?.refreshTokenParsed?.exp || 0) -
                Math.ceil(new Date().getTime() / 1000) <
            0
        );
    }

    @action async changePassword() {
        await this.keycloak?.login({ action: 'UPDATE_PASSWORD' });
    }

    @computed get permissions() {
        if (!this.token) {
            return [];
        }

        const parsedToken = TokenHelper.decode(this.token) as any;

        return parsedToken?.resource_access?.backoffice?.roles || [];
    }

    resetPassword(): Promise<void> {
        throw new Error('Method not implemented.');
    }
}
