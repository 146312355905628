import { t } from 'i18next';
import { ActionType } from 'src/generated-api-client';
import { CreateIndividualCoBorrowerDialog } from 'src/pages/LoanDetailsPage/Dialogs/IndividualCoBorrowers/CreateIndividualCoBorrowerDialog';
import { LoanType } from 'src/stores/LoansStore/LoansStore';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getCoBorrowerExternalActions = (
    loan?: LoanType,
): ActionDefinition[] => {
    return [...getUIActions(), ...getServerActions(loan)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (loan?: LoanType): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(loan),
        loan?.actions,
    );
};

const getRegisteredServerActions = (loan?: LoanType): ActionDefinition[] => {
    return [
        {
            key: ActionType.CO_BORROWER_ADD,
            label: t('Loan.Actions.CO_BORROWER_ADD'),
            onClick: () => {
                CreateIndividualCoBorrowerDialog.render({
                    loan,
                });
            },
            primary: true,
        },
    ];
};
