import { Form, FormItemProps, Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import React from 'react';

export type TextAreaFieldProps = FormItemProps & {
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    readOnly?: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    maxLength?: number;
    // eslint-disable-next-line react/require-default-props
    rows?: number;
    // eslint-disable-next-line react/require-default-props
    textAreaProps?: TextAreaProps;
};

const DEFAULT_ROWS = 7;

export const TextAreaField = React.forwardRef<TextAreaRef, TextAreaFieldProps>(
    (
        {
            // eslint-disable-next-line react/prop-types
            name,
            label,
            readOnly,
            disabled,
            maxLength,
            rows = DEFAULT_ROWS,
            textAreaProps,
            ...props
        }: TextAreaFieldProps,
        ref,
    ) => {
        return (
            <Form.Item {...props} name={name} label={label}>
                <Input.TextArea
                    maxLength={maxLength}
                    readOnly={readOnly}
                    disabled={disabled}
                    rows={rows}
                    {...textAreaProps}
                    ref={ref}
                />
            </Form.Item>
        );
    },
);
