import i18next from 'i18next';
import { computed } from 'mobx';
import { BankInformationDto, Localization } from 'src/generated-api-client';
import { individualsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class BanksStoreClass extends BasicStore<BankInformationDto> {
    api: BasicStoreApi<BankInformationDto> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getAvailableBankList(),
            );
        },
    };

    @computed get bankOptions() {
        return this.list.map((item: BankInformationDto) => {
            return {
                label: item.l10n?.[i18next.language as keyof Localization],
                value: item.id,
            };
        });
    }
}

export const BanksStore = new BanksStoreClass();
