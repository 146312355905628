import { observer } from 'mobx-react-lite';
import { Form, FormProps } from 'src/components/Form/Form';
import { useTaskId } from 'src/hooks/useTaskId';
import { TaskViewModel } from 'src/pages/TaskViewPage/TaskViewModel';

export const TaskForm = observer((props: FormProps) => {
    const { taskId } = useTaskId();
    const viewModel = TaskViewModel.getInstance(taskId);

    const { isCurrentTaskEqualWorkingTask } = viewModel;

    return <Form {...props} disabled={!isCurrentTaskEqualWorkingTask} />;
});
