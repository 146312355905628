import { Env } from 'src/core/Environment';

export const RoutePaths = {
    index: `${Env.basePath}/`,

    profile: `${Env.basePath}/profile`,

    merchants: `${Env.basePath}/merchants`,
    merchantCreate: `${Env.basePath}/merchants/create`,
    merchantDetails: `${Env.basePath}/merchants/:merchantId`,
    merchantEdit: `${Env.basePath}/merchants/:merchantId/edit`,

    legalEntities: `${Env.basePath}/legals`,
    legalCreate: `${Env.basePath}/legals/create`,
    legalDetails: `${Env.basePath}/legals/:legalId`,
    legalEdit: `${Env.basePath}/legals/:legalId/edit`,

    loans: `${Env.basePath}/loans`,
    loanDetails: `${Env.basePath}/loans/:loanId`,

    applications: `${Env.basePath}/loan-applications`,
    applicationDetails: `${Env.basePath}/loan-applications/:applicationId`,

    individuals: `${Env.basePath}/individuals`,
    individualDetails: `${Env.basePath}/individuals/:individualId`,

    products: `${Env.basePath}/products`,

    productsBNPL: `${Env.basePath}/products/bnpl`,
    productBNPLCreate: `${Env.basePath}/products/bnpl/create`,
    productBNPLDetails: `${Env.basePath}/products/bnpl/:productId`,
    productBNPLEdit: `${Env.basePath}/products/bnpl/:productId/edit`,

    productsInstallment: `${Env.basePath}/products/installment`,
    productInstallmentCreate: `${Env.basePath}/products/installment/create`,
    productInstallmentDetails: `${Env.basePath}/products/installment/:productId`,
    productInstallmentEdit: `${Env.basePath}/products/installment/:productId/edit`,

    productsPledged: `${Env.basePath}/products/pledged`,
    productPledgedCreate: `${Env.basePath}/products/pledged/create`,
    productPledgedDetails: `${Env.basePath}/products/pledged/:productId`,
    productPledgedEdit: `${Env.basePath}/products/pledged/:productId/edit`,

    productsTrustPledged: `${Env.basePath}/products/trust-pledged`,
    productTrustPledgedCreate: `${Env.basePath}/products/trust-pledged/create`,
    productTrustPledgedDetails: `${Env.basePath}/products/trust-pledged/:productId`,
    productTrustPledgedEdit: `${Env.basePath}/products/trust-pledged/:productId/edit`,

    productsPayon: `${Env.basePath}/products/payon`,
    productsPayonCreate: `${Env.basePath}/products/payon/create`,
    productsPayonDetails: `${Env.basePath}/products/payon/:productId`,
    productsPayonEdit: `${Env.basePath}/products/payon/:productId/edit`,

    restrictions: `${Env.basePath}/restrictions`,

    settings: `${Env.basePath}/settings`,
    settingsOverdueDays: `${Env.basePath}/settings/overdue-days`,
    settingsProvisionRates: `${Env.basePath}/settings/provision-rates`,
    settingsTemplates: `${Env.basePath}/settings/templates`,
    settingsScoring: `${Env.basePath}/settings/scoring`,
    settingsProductLimit: `${Env.basePath}/settings/product-limit`,
    settingsRiskyGroup: `${Env.basePath}/settings/risky-group`,
    settingsCustomerSegmentation: `${Env.basePath}/settings/customer-segmentation`,
    settingsCustomerVerification: `${Env.basePath}/settings/customer-verification`,
    settingsGroupDetails: `${Env.basePath}/settings/customer-segmentation/group/:entityId`,
    settingsProduct: `${Env.basePath}/settings/product`,
    settingsCollectionAssignment: `${Env.basePath}/settings/collection-assignment`,

    authLogin: `${Env.basePath}/auth/login`,
    authForgotPassword: `${Env.basePath}/auth/forgot-password`,
    authRecoveryPassword: `${Env.basePath}/auth/recovery-password`,
    authRecoveryExpiredPassword: `${Env.basePath}/auth/recovery-expired-password`,
    authRequestToRecoveryExpiredPassword: `${Env.basePath}/auth/request-to-recovery-expired-password`,
    authRegistrationSetPassword: `${Env.basePath}/auth/confirm-registration`,
    authResetPassword: `${Env.basePath}/profile/reset-password`,

    security: `${Env.basePath}/security`,

    users: `${Env.basePath}/security/users`,
    userCreate: `${Env.basePath}/security/users/create`,
    userDetails: `${Env.basePath}/security/users/:userId`,
    userEdit: `${Env.basePath}/security/users/:userId/edit`,

    roles: `${Env.basePath}/security/roles`,
    roleDetails: `${Env.basePath}/security/roles/:roleId`,
    roleCreate: `${Env.basePath}/security/roles/create`,
    roleEdit: `${Env.basePath}/security/roles/:roleId/edit`,

    bankStatements: `${Env.basePath}/bank-statements`,

    branches: `${Env.basePath}/branches`,

    tasks: `${Env.basePath}/tasks`,
    taskDetails: `${Env.basePath}/tasks/:taskId`,

    collaterals: `${Env.basePath}/collaterals`,
    collateralCreate: `${Env.basePath}/collaterals/create`,
    collateralDetails: `${Env.basePath}/collaterals/:collateralId`,

    collections: `${Env.basePath}/collections`,
    collectionDetails: `${Env.basePath}/collections/:collectionId`,
    collectionRecordActivity: `${Env.basePath}/collections/:collectionId/record-activity`,
};
