/* eslint-disable max-lines */
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { FilterForm } from 'src/components/Form/FilterForm';
import { ListPage } from 'src/components/ListPage/ListPage';
import {
    AuditBalance,
    LoanOperationAuditEventListEntry,
    LoanOperationAuditEventOperationType,
    PayonLoan,
} from 'src/generated-api-client';
import { CreditsBureauStatus } from 'src/pages/LoanDetailsPage/Components/CreditsBureauStatus';
import { LoanFinancialOperationsFilterForm } from 'src/pages/LoanDetailsPage/Components/LoanFinancialOperationsFilterForm';
import { LoansStatus } from 'src/pages/LoanDetailsPage/Components/LoansStatus';
import { LoanFinancialOperationsStore } from 'src/stores/LoanFinancialOperationsStore/LoanFinancialOperationsStore';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

export type FinancialOperationsTabProps = { loan?: PayonLoan };

export const FinancialOperationsTab = observer(
    ({ loan }: FinancialOperationsTabProps) => {
        const store = LoanFinancialOperationsStore.getInstance(
            loan?.id as number,
        );

        return (
            <ListPage
                store={store}
                columns={columns}
                header={
                    <FilterForm
                        store={store}
                        title={t(
                            'Page.PayonLoanDetails.Tab.FinancialOperations.Header',
                        )}
                        convertFilterCriteriaToFormValues={
                            convertFilterCriteriaToFormValues
                        }
                        convertFormValuesToFilterCriteria={
                            convertFormValuesToFilterCriteria
                        }
                    >
                        <LoanFinancialOperationsFilterForm />
                    </FilterForm>
                }
            />
        );
    },
);

const convertFilterCriteriaToFormValues = (values: any) => {
    return {
        ...values,
        effectiveAtFrom:
            values.effectiveAtFrom && moment(values.effectiveAtFrom),
        effectiveAtTo: values.effectiveAtTo && moment(values.effectiveAtTo),
    };
};
const convertFormValuesToFilterCriteria = (values: any) => {
    return {
        ...values,
        effectiveAtFrom: DateHelper.formatISODate(values.effectiveAtFrom),
        effectiveAtTo: DateHelper.formatISODate(values.effectiveAtTo),
    };
};

const columns: ColumnsType<LoanOperationAuditEventListEntry> = [
    {
        key: 'effectiveAt',
        dataIndex: 'effectiveAt',
        title: (
            <Trans i18nKey="Page.PayonLoanDetails.Tab.FinancialOperations.List.EffectiveAt" />
        ),
        render: (value) => {
            return <StyledColumnValue value={value} hideLabel format="date" />;
        },
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: (
            <Trans i18nKey="Page.PayonLoanDetails.Tab.FinancialOperations.List.Amount" />
        ),
        align: 'right',
        render: (value, record) => {
            return getBalanceView(value, record);
        },
    },
    {
        key: 'principal',
        dataIndex: 'principal',
        title: (
            <Trans i18nKey="Page.PayonLoanDetails.Tab.FinancialOperations.List.Principal" />
        ),
        align: 'right',
        render: (value, record) => {
            return getAuditBalanceView(value, record);
        },
    },
    {
        key: 'principalOverdue',
        dataIndex: 'principalOverdue',
        title: (
            <Trans i18nKey="Page.PayonLoanDetails.Tab.FinancialOperations.List.PrincipalOverdue" />
        ),
        align: 'right',
        render: (value, record) => {
            return getAuditBalanceView(value, record);
        },
    },
    {
        key: 'interest',
        dataIndex: 'interest',
        title: (
            <Trans i18nKey="Page.PayonLoanDetails.Tab.FinancialOperations.List.Interest" />
        ),
        align: 'right',
        render: (value, record) => {
            return getAuditBalanceView(value, record);
        },
    },
    {
        key: 'interestOverdue',
        dataIndex: 'interestOverdue',
        title: (
            <Trans i18nKey="Page.PayonLoanDetails.Tab.FinancialOperations.List.InterestOverdue" />
        ),
        align: 'right',
        render: (value, record) => {
            return getAuditBalanceView(value, record);
        },
    },
    {
        key: 'penalty',
        dataIndex: 'penalty',
        title: (
            <Trans i18nKey="Page.PayonLoanDetails.Tab.FinancialOperations.List.Penalty" />
        ),
        align: 'right',
        render: (value, record) => {
            return getAuditBalanceView(value, record);
        },
    },
    {
        key: 'loanStatus',
        dataIndex: 'loanStatus',
        title: (
            <Trans i18nKey="Page.PayonLoanDetails.Tab.FinancialOperations.List.LoanStatus" />
        ),
        render: (value) => {
            return <LoansStatus status={value} />;
        },
    },
    {
        key: 'creditBureauStatus',
        dataIndex: 'creditBureauStatus',
        title: (
            <Trans i18nKey="Page.PayonLoanDetails.Tab.FinancialOperations.List.CreditBureauStatus" />
        ),
        render: (value) => {
            return <CreditsBureauStatus status={value} />;
        },
    },
    {
        key: 'operationType',
        dataIndex: 'operationType',
        title: (
            <Trans i18nKey="Page.PayonLoanDetails.Tab.FinancialOperations.List.OperationType" />
        ),
        render: (value) => {
            return (
                <StyledColumnValue
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView
                            enumName="LOAN_OPERATION_AUDIT_EVENT_OPERATION_TYPE"
                            value={value}
                        />
                    )}
                />
            );
        },
    },
    {
        key: 'createdBy',
        dataIndex: 'createdBy',
        title: (
            <Trans i18nKey="Page.PayonLoanDetails.Tab.FinancialOperations.List.CreatedBy" />
        ),
        render: (value) => {
            return <StyledColumnValue value={value?.name} hideLabel />;
        },
    },
];

const getBalanceView = (
    value?: AuditBalance,
    record?: LoanOperationAuditEventListEntry,
) => {
    const isRepaymentOperationType =
        record?.operationType ===
        LoanOperationAuditEventOperationType?.REPAYMENT;

    if (isRepaymentOperationType) {
        return (
            <StyledHighlightedColumnValue
                value={value}
                hideLabel
                format="money"
            />
        );
    }

    return <StyledColumnValue value={value} hideLabel format="money" />;
};

const getAuditBalanceView = (
    value?: AuditBalance,
    record?: LoanOperationAuditEventListEntry,
) => {
    const isRepaymentOperationType =
        record?.operationType ===
        LoanOperationAuditEventOperationType?.REPAYMENT;

    return (
        <Space direction="vertical" align="end">
            <StyledColumnValue
                value={value?.newAmount}
                hideLabel
                format="money"
            />

            {isRepaymentOperationType && (
                <StyledHighlightedDiff
                    value={value?.diff}
                    hideLabel
                    format="money"
                />
            )}

            {isRepaymentOperationType && (
                <StyledColumnValue
                    value={value?.oldAmount}
                    hideLabel
                    format="money"
                />
            )}
        </Space>
    );
};

const StyledHighlightedDiff = styled(DataView)`
    .app-data-view__value {
        font-size: 10px !important;
        padding: 0 4px;
        border-radius: 100px;
        background-color: #d3f3bb;
    }
`;

const StyledColumnValue = styled(DataView)`
    .app-data-view__value {
        font-size: 11px !important;
        padding: 0 4px;
    }
`;

const StyledHighlightedColumnValue = styled(DataView)`
    .app-data-view__value {
        font-size: 11px !important;
        padding: 0 4px;
        border-radius: 100px;
        background-color: #d3f3bb;
    }
`;
