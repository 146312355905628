import { Button, Col, Form as AntForm, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Form } from 'src/components/Form/Form';
import { PasswordField } from 'src/components/Form/PssswordField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { PasswordPolicy, passwordRule } from 'src/pages/Auth/PasswordPolicy';
import { ResetPasswordStore } from 'src/stores/LocalAuth/ResetPasswordStore';

export const ResetPasswordPage = observer(() => {
    const [form] = AntForm.useForm();
    const newPassword = AntForm.useWatch('newPassword', form);

    useEffect(() => {
        return () => {
            ResetPasswordStore.resetPasswordLoader.reset();
        };
    }, []);

    const showForm = !ResetPasswordStore.isPasswordReset;
    const showErrorMessage = ResetPasswordStore.resetPasswordLoader.hasError;

    return (
        <BasePage
            title={t('Page.Auth.ResetPassword.Title')}
            isLoading={ResetPasswordStore.isPasswordResetting}
        >
            {showForm && (
                <Form
                    form={form}
                    onFinish={async (values) => {
                        await ResetPasswordStore.resetPassword(values);
                    }}
                >
                    <Row gutter={16}>
                        <Col xs={12}>
                            <PasswordField
                                name="oldPassword"
                                label={t('Page.Auth.ResetPassword.OldPassword')}
                                rules={[ValidationRules.requiredPassword]}
                            />
                            <PasswordField
                                name="newPassword"
                                label={t('Page.Auth.ResetPassword.NewPassword')}
                                rules={[
                                    ValidationRules.requiredPassword,
                                    passwordRule,
                                ]}
                            />
                            <PasswordField
                                name="confirmNewPassword"
                                label={t(
                                    'Page.Auth.ResetPassword.ConfirmNewPassword',
                                )}
                                rules={[
                                    ValidationRules.requiredPassword,
                                    ValidationRules.isEqualPasswords(
                                        newPassword,
                                    ),
                                ]}
                                dependencies={['newPassword']}
                            />
                        </Col>
                        <Col xs={12}>
                            <PasswordPolicy passwordFieldName="newPassword" />
                        </Col>
                    </Row>
                    <Button type="primary" htmlType="submit">
                        {t('Page.Auth.ResetPassword.Submit')}
                    </Button>
                </Form>
            )}

            {showErrorMessage && (
                <>{t('Page.Auth.ResetPassword.PasswordHasNotBeenChanged')}</>
            )}
        </BasePage>
    );
});
