import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { Router } from 'src/core/router/Router';
import { RouteItem } from 'src/stores/RouterStore/RouterStore.types';
import styled from 'styled-components';

export type GroupItemProps = { routeDefinition: RouteItem };
export const GroupItem = observer(({ routeDefinition }: GroupItemProps) => {
    return (
        <Wrapper
            onClick={() => {
                Router.navigate(routeDefinition.path);
            }}
        >
            <Typography.Title level={3}>
                {routeDefinition.label}
            </Typography.Title>
            <StyledDescription>{routeDefinition.description}</StyledDescription>

            {/* <Button
                icon={<PlusOutlined />}
                type="dashed"
                onClick={(e) => {
                    e.preventDefault();
                    routeDefinition.linkToCreate &&
                        Router.navigate(routeDefinition.linkToCreate);
                }}
            >
                {t('Page.GroupPage.AddNew', 'Create new')}
            </Button> */}
        </Wrapper>
    );
});

const StyledDescription = styled(Typography.Paragraph)`
    color: #9fa2b4;
`;

const Wrapper = styled.div`
    position: relative;
    padding: 16px;
    background: white;
    width: 250px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 8px;
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
        box-shadow: 0 5px 10px rgb(12 100 158 / 30%);
    }
`;
