import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FilterForm } from 'src/components/Form/FilterForm';
import { PlusIcon } from 'src/components/Icons/Icons';
import { IndividualCreateDialog } from 'src/pages/IndividualCreatePage/IndividualCreateDialog';
import { IndividualsFilterForm } from 'src/pages/IndividualsPage/IndividualsFilterForm';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';

type IndividualsHeaderPanelProps = {
    title: React.ReactNode;
};

export const IndividualsHeaderPanel = observer(
    ({ title }: IndividualsHeaderPanelProps) => {
        const { t } = useTranslation();
        const canCreate = AppUser.hasPermissions(
            UserPermission.IndividualCreate,
        );

        return (
            <div>
                <FilterForm
                    store={IndividualsStore}
                    title={title}
                    panelExtra={
                        canCreate && (
                            <Button
                                icon={<PlusIcon />}
                                type="primary"
                                onClick={() => {
                                    IndividualCreateDialog.render();
                                }}
                            >
                                {t('Page.Individuals.Button.CreateIndividual')}
                            </Button>
                        )
                    }
                >
                    <IndividualsFilterForm />
                </FilterForm>
            </div>
        );
    },
);
