import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { CustomerType, PledgedLoan } from 'src/generated-api-client';
import { CreditsBureauStatus } from 'src/pages/LoanDetailsPage/Components/CreditsBureauStatus';
import { LoansStatus } from 'src/pages/LoanDetailsPage/Components/LoansStatus';

type LoanDetailsBriefProps = { loan?: PledgedLoan };

export const PledgedLoanDetailsBrief = observer(
    ({ loan }: LoanDetailsBriefProps) => {
        const { id: customerId, type: customerType } = loan?.customer ?? {};
        const linkType =
            customerType === CustomerType.INDIVIDUAL ? 'individuals' : 'legals';
        const customerLink = EntityLinks[linkType]?.itemDetails(customerId);

        return (
            <Brief>
                <DataView
                    value={customerId}
                    label={t('Page.PledgedLoanDetails.Brief.CustomerId')}
                    format="link-internal"
                    formatterProps={{
                        to: customerLink,
                    }}
                />
                <DataView
                    value={customerType}
                    label={t('Page.PledgedLoanDetails.Brief.CustomerType')}
                    format={(value) => (
                        <EnumView enumName="CUSTOMER_TYPE" value={value} />
                    )}
                />
                <DataView
                    value={loan?.product.nameWithVersion}
                    label={t('Page.PledgedLoanDetails.Brief.Product')}
                />
                <DataView
                    value={loan?.product.externalId}
                    label={t('Page.PledgedLoanDetails.Brief.ProductId')}
                    format="link-internal"
                    formatterProps={{
                        to: EntityLinks.productsPledged.itemDetails(
                            loan?.product?.id,
                        ),
                    }}
                />
                <DataView
                    value={loan?.principalBalance}
                    label={t('Page.PledgedLoanDetails.Brief.PrincipalBalance')}
                    format="money"
                />
                <DataView
                    value={loan?.loanStatus}
                    label={t('Page.PledgedLoanDetails.Brief.LoanStatus')}
                    format={(value) => <LoansStatus status={value} />}
                />
                <DataView
                    value={loan?.creditBureauStatus}
                    label={t('Page.PledgedLoanDetails.Brief.CibStatus')}
                    format={(value) => <CreditsBureauStatus status={value} />}
                />
            </Brief>
        );
    },
);
