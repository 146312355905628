import { Form, FormItemProps } from 'antd';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

type ErrorFieldProps = FormItemProps;
export const ErrorField = observer((props: ErrorFieldProps) => {
    return (
        <StyledFormItem {...props}>
            <div />
        </StyledFormItem>
    );
});

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;

    .ant-form-item-control-input {
        height: 0;
        min-height: 0;
    }

    .ant-form-item-explain {
        margin-bottom: 0;
    }
`;
