import { notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import {
    N13IndividualCoBorrower,
    N13LegalCoBorrower,
} from 'src/generated-api-client';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type UpdateCoBorrowerDialogFnProps = ModalRendererProps<{
    coBorrower: N13IndividualCoBorrower | N13LegalCoBorrower;
    viewModel: N13TaskViewModelClass;
}>;

const UpdateCoBorrowerDialogFn = observer(
    ({ coBorrower, viewModel, ...props }: UpdateCoBorrowerDialogFnProps) => {
        useEffect(() => {
            return () => {
                viewModel.updateCoBorrowerLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            viewModel.updateCoBorrowerLoader.errorData,
        );

        return (
            <Modal
                isLoading={viewModel.updateCoBorrowerLoader.isLoading}
                title={t('Dialog.UpdateCoBorrower.Title')}
                initialValues={coBorrower}
                onFinish={async (values) => {
                    await viewModel.updateCoBorrower(
                        Number(coBorrower?.id),
                        values,
                    );

                    if (viewModel.updateCoBorrowerLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel.updateCoBorrowerLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.UpdateCoBorrower.Message.Success',
                            ),
                        });
                        viewModel.load();
                    }
                }}
                formProps={{ fields }}
                {...props}
            >
                <HiddenField name="type" initialValue="CoBorrowerUpdate" />
                <TextField
                    name="relationshipType"
                    label={t('Dialog.UpdateCoBorrower.RelationshipType')}
                    rules={[ValidationRules.required]}
                />
            </Modal>
        );
    },
);

export const UpdateCoBorrowerDialog = addModalRenderer(
    UpdateCoBorrowerDialogFn,
);
