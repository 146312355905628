import { notification, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { HiddenField } from 'src/components/Form/HiddenField';
import { Modal } from 'src/components/Modal/Modal';
import { IndividualCoBorrowerListEntry } from 'src/generated-api-client';
import { CoBorrowersStore } from 'src/stores/CoBorrowersStore/CoBorrowersStore';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const store = CoBorrowersStore;

type RemoveCoBorrowerDialogProps = ModalRendererProps<{
    coBorrower?: IndividualCoBorrowerListEntry;
    loanId?: number;
}>;

const RemoveCoBorrowerDialogFn = observer(
    ({ coBorrower, loanId, ...props }: RemoveCoBorrowerDialogProps) => {
        useEffect(() => {
            return () => {
                store.updateItemLoader.reset();
            };
        }, []);

        return (
            <Modal
                isLoading={store.updateItemLoader.isLoading}
                title={t('Dialog.Loan.RemoveCoBorrower.Title')}
                onFinish={async (values) => {
                    await store.update(Number(coBorrower?.id), values);

                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.updateItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.Loan.RemoveCoBorrower.Message.Success',
                            ),
                        });
                        LoansStore.loadItem(loanId);
                    }
                }}
                {...props}
            >
                <StyledText>
                    {t('Dialog.Loan.RemoveCoBorrower.Text')}
                </StyledText>

                <HiddenField
                    name="type"
                    initialValue="CoBorrowerRemoveFromLoan"
                />
            </Modal>
        );
    },
);

export const RemoveCoBorrowerDialog = addModalRenderer(
    RemoveCoBorrowerDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    height: 70px;
    font-size: 18px;
    color: #4d6082;
`;
