import { notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { NewCustomerGroup } from 'src/generated-api-client';
import { CustomerGroupsStore } from 'src/stores/CustomerGroupsStore/CustomerGroupsStore';
import { ProductLinesStore } from 'src/stores/ProductLinesStore/ProductLinesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const store = CustomerGroupsStore;

type UpdateCustomerGroupDialogFnProps = ModalRendererProps<{
    groupId: number;
}>;

export const UpdateCustomerGroupDialogFn = observer(
    ({ groupId, ...props }: UpdateCustomerGroupDialogFnProps) => {
        useEffect(() => {
            ProductLinesStore.loadList();

            return () => {
                store.updateItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );

        const loadingInfo = useMemo(() => {
            return new CombinedLoadingStatus([
                store.updateItemLoader,
                ProductLinesStore.listLoader,
            ]);
        }, []);

        return (
            <Modal
                title={t('Dialog.UpdateCustomerGroup.Title')}
                isLoading={loadingInfo.isLoading}
                showOnlyLoading={loadingInfo.isLoading}
                onFinish={async (values) => {
                    await onFinish(groupId, values);
                }}
                initialValues={getInitialValues()}
                formProps={{ fields }}
                {...props}
            >
                <TextField
                    name="name"
                    label={t('Dialog.UpdateCustomerGroup.GroupName')}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.maxFieldLength(100),
                    ]}
                />
                <NumberField
                    name="priority"
                    label={t('Dialog.UpdateCustomerGroup.Priority')}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.greaterThanZero,
                        ValidationRules.numberMaxValue(9999),
                    ]}
                />
                <SelectField
                    name="productLineIds"
                    label={t('Dialog.CreateCustomerGroup.Products')}
                    rules={[ValidationRules.required]}
                    mode="multiple"
                    options={ProductLinesStore.productLinesOptions}
                />
            </Modal>
        );
    },
);

const getInitialValues = () => ({
    ...store.currentItem,
    productLineIds: store.currentItem?.productLines?.map((item) => item?.id),
});

const onFinish = async (groupId: number, values: NewCustomerGroup) => {
    await store.update(groupId, values);
    if (store?.updateItemLoader.hasError) {
        return Promise.reject();
    }
    if (!store.updateItemLoader.hasError) {
        store.loadItem(groupId);

        notification.success({
            message: t('Dialog.UpdateCustomerGroup.Message.Success'),
        });
    }
};

export const UpdateCustomerGroupDialog = addModalRenderer(
    UpdateCustomerGroupDialogFn,
);
