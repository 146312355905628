import { observer } from 'mobx-react-lite';
import { Env } from 'src/core/Environment';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import styled from 'styled-components';

// eslint-disable-next-line react/require-default-props
export type AppInfoProps = { className?: string };

export const AppInfo = observer((props: AppInfoProps) => {
    return (
        <Container {...props}>
            <div>UI: {Env.appVersion}</div>
            <div>Back-end: {SettingsStore.backEndServerVersion}</div>
            <div>Api-client: {SettingsStore.apiClientVersion}</div>
        </Container>
    );
});

const Container = styled.div`
    font-size: 10px;
    color: gray;
    padding: 10px;
    overflow-wrap: break-word;
    div {
        font-size: 10px;
    }
`;
