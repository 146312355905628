/* eslint-disable max-lines */
import { Button, Col, Row, Space } from 'antd';
import classNames from 'classnames';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ReactComponent as DownloadIcon } from 'src/assets/download-icon.svg';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { DeleteFileDialog } from 'src/components/DocumentsList/DeleteFileDialog';
import { DocumentsViewModel } from 'src/components/DocumentsList/DocumentsViewModel';
import { UploadFileDialog } from 'src/components/DocumentsList/UploadFileDialog';
import { EnumView } from 'src/components/EnumView/EnumView';
import { Form } from 'src/components/Form/Form';
import { SelectField } from 'src/components/Form/SelectField';
import { RejectIcon } from 'src/components/Icons/Icons';
import { EntityLinks } from 'src/core/router/EntityLinks';
import {
    ActionType,
    Actor,
    ActorType,
    Document,
    IdEntry,
} from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { useViewModel } from 'src/hooks/useViewModel';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import { ActionDefinition } from 'src/utils/UserActionsHelper';
import styled from 'styled-components';

const DEFAULT_FILE_NAME = 'document';
const enumsToLoad: EnumName[] = ['DOCUMENT_TYPE'];

type DocumentsListProps = {
    documentRoom: IdEntry;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    title?: React.ReactNode | ((countDocument: number) => React.ReactNode);
    extraOptions?: ActionDefinition[];
    isLoading?: boolean;
    canUpload?: boolean;
    onUploadSuccess?: () => void;
    onDeleteSuccess?: () => void;
};

const defaultDocumentsListTitle = (countDocuments: number) =>
    t('Component.DocumentsList.Title', {
        count: countDocuments,
    });

export const DocumentsList = observer(
    ({
        documentRoom,
        extraOptions,
        isLoading = false,
        canUpload = true,
        onUploadSuccess,
        onDeleteSuccess,
        title = defaultDocumentsListTitle,
    }: DocumentsListProps) => {
        const viewModel = DocumentsViewModel.getInstance(documentRoom?.id);
        const documentTypesFilterOptions =
            viewModel?.documentTypesFilterOptions || [];
        const countDocuments = viewModel.documents.length || 0;
        const targetTitle =
            typeof title === 'function' ? title?.(countDocuments) : title;

        useViewModel(viewModel, []);
        useEnumsLoader(enumsToLoad);

        const actions = [
            {
                key: 'upload',
                label: t('Component.DocumentsList.Actions.Upload'),
                onClick: () => {
                    UploadFileDialog.render({
                        viewModel,
                        onUploadSuccess,
                    });
                },
                disabled: !canUpload,
            },
            ...(extraOptions || []),
        ];

        const menuActions = actions.filter((action) => !action.disabled);

        return (
            <Card
                title={<TitleWrapper>{targetTitle}</TitleWrapper>}
                isLoading={viewModel.loadingInfo.isLoading || isLoading}
                menuActions={menuActions}
                extra={
                    <Form
                        onValuesChange={(changedValues) => {
                            viewModel.documentsStore.filterCriteria.setFilter(
                                changedValues,
                            );
                            viewModel.documentsStore.loadList();
                        }}
                    >
                        <StyledSelectField
                            name="documentTypes"
                            label={t(
                                'Component.DocumentsList.Filter.DocumentType',
                                'Document Type :',
                            )}
                            options={documentTypesFilterOptions}
                            mode="multiple"
                        />
                    </Form>
                }
            >
                {viewModel.documents?.map((document: Document) => {
                    const isDeletableType = document?.actions?.some(
                        (t) => t?.actionType === ActionType.DOCUMENT_DELETE,
                    );

                    return (
                        <Row gutter={16} wrap={false}>
                            <Col xs={4}>
                                <DataView
                                    label={t(
                                        'Component.DocumentsList.DocumentType',
                                    )}
                                    value={document?.type}
                                    format={(value) => (
                                        <EnumView
                                            enumName="DOCUMENT_TYPE"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Component.DocumentsList.FileName',
                                    )}
                                    value={document?.file?.fileName}
                                />
                            </Col>
                            <Col xs={4}>
                                <DataView
                                    label={t(
                                        'Component.DocumentsList.FileUploadedDate',
                                    )}
                                    value={document?.file?.createdAt}
                                />
                            </Col>
                            <Col xs={4}>
                                <DataView
                                    label={t(
                                        'Component.DocumentsList.FileUploadedRespondent',
                                    )}
                                    value={document?.file?.createdBy?.name}
                                    format="link-internal"
                                    formatterProps={{
                                        to: getActorDetailsUrl(
                                            document?.file?.createdBy,
                                        ),
                                    }}
                                />
                            </Col>
                            <Col xs={3}>
                                <DataView
                                    value={document?.source}
                                    label={t('Component.DocumentsList.Source')}
                                    format={(value) => (
                                        <EnumView
                                            enumName="DOCUMENT_SOURCE"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={1}>
                                <StyledSpace
                                    size={10}
                                    direction="horizontal"
                                    align="end"
                                >
                                    <StyledButton
                                        title={t(
                                            'Component.DocumentsList.Actions.Download',
                                        )}
                                        onClick={() => {
                                            UrlHelper.downloadFile(
                                                String(
                                                    document?.file
                                                        ?.presignedUrl,
                                                ),
                                                document?.file?.fileName ||
                                                    DEFAULT_FILE_NAME,
                                            );
                                        }}
                                    >
                                        <DownloadIcon />
                                    </StyledButton>
                                </StyledSpace>
                            </Col>
                            <Col xs={1}>
                                <StyledButton
                                    disabled={!isDeletableType}
                                    title={t(
                                        'Component.DocumentsList.Actions.Delete',
                                    )}
                                    onClick={() => {
                                        DeleteFileDialog.render({
                                            documentId: document.id,
                                            viewModel,
                                            onDeleteSuccess,
                                        });
                                    }}
                                >
                                    <RejectIcon />
                                </StyledButton>
                            </Col>
                        </Row>
                    );
                })}
            </Card>
        );
    },
);

const getActorDetailsUrl = (actor?: Actor) => {
    if (actor?.type === ActorType.INDIVIDUAL) {
        return EntityLinks.individuals.itemDetails(actor?.id);
    }
    if (actor?.type === ActorType.BO_USER) {
        return EntityLinks.users.itemDetails(actor?.id);
    }
    if (actor?.type === ActorType.SYSTEM) {
        return undefined;
    }
};

const StyledButton = styled(Button)`
    background: #f7f8fc;
    border: transparent;

    min-width: 80px;
    padding-left: 20px;
    padding-right: 20px;

    &:active,
    &:hover {
        border: transparent;
        background: #f7f8fc;
    }
`;

const StyledSpace = styled(Space)`
    width: 100%;
    justify-content: end;
`;

const StyledSelectField = styled(SelectField)`
    margin-bottom: 0;
    width: 350px;
    flex-direction: row !important;

    .ant-form-item-label > label {
        height: 40px;
    }

    .ant-form-item-control {
        max-width: 244px;
    }

    .ant-form-item-label {
        padding: 0;
        margin-right: 12px;
    }
`;

const TitleWrapper = styled.div.attrs((props) => ({
    className: classNames(props.className, 'documents-list-title'),
}))`
    font-size: inherit;
`;
