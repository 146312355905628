import { CustomerDecision } from 'src/generated-api-client';
import { individualsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class IndividualDecisionsStoreClass extends BasicStore<
    CustomerDecision,
    any,
    any,
    any,
    any
> {
    api: BasicStoreApi<CustomerDecision, any, any, any> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getIndividualVerificationDecisions({
                    id: this.individualId,
                }),
            );
        },
    };

    constructor(private individualId: number) {
        super();
    }
}

export const IndividualDecisionsStore = new EntityProvider(
    IndividualDecisionsStoreClass,
);
