/* eslint-disable max-lines */
import { Button, Form, notification, Space } from 'antd';
import Table, { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { CheckIcon, EditIcon, RejectIcon } from 'src/components/Icons/Icons';
import { Spinner } from 'src/components/Spinner/Spinner';
import {
    TableData,
    TableSettingsStoreClass,
} from 'src/stores/Settings/TableSettingsStoreClass';
import { FormHelper } from 'src/utils/FormHelper';
import styled from 'styled-components';

export type ColumnSettings<T = any> = Omit<ColumnType<T>, 'title'> & {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    title?: (colDef: any) => React.ReactNode;
    editable?: boolean;
    renderEdit?: (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        valuePath: string | number | (string | number)[],
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        row: T,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        rowIndex: number,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        column: ColumnType<T>,
    ) => React.ReactNode;
};

export type SettingsTableProps = {
    tableStore: TableSettingsStoreClass;
    columnSettings?: ColumnSettings[];
    hasPermission?: boolean | (() => boolean);
};

export function getColumns<T = any>(
    table?: TableData<T>,
    columnSettings?: ColumnSettings<T>[],
) {
    if (!table || !table.columns) {
        return [];
    }

    const cols = Array.from(
        Object.values(_.groupBy(table.columns, 'key')),
    ).flatMap((arr) => {
        if (arr.length > 1) {
            return arr.map((col, idx) => ({
                ...col,
                dataIndex: [col.dataIndex, idx],
            })) as ColumnType<T>[];
        }

        return arr as ColumnType<T>[];
    });

    return cols.map((colDef, index) => {
        const settings = columnSettings?.find((col) => col.key === colDef.key);

        return {
            key: index,
            dataIndex: colDef.dataIndex,

            ...settings,
            title: settings?.title ? settings.title?.(colDef) : colDef.key,
        };
    });
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    row: any;
    column: any;
    title: string;
    rowIndex: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    row,
    column,
    title,
    rowIndex,
    children,
    ...props
}) => {
    const dataIndex = Array.isArray(column.dataIndex)
        ? column.dataIndex
        : [column.dataIndex];
    let path = [rowIndex, ...dataIndex];

    if (
        typeof column.dataIndex === 'string' &&
        Array.isArray(row[column.dataIndex])
    ) {
        path = [rowIndex, column.dataIndex, 0];
    }

    return (
        <td {...props}>
            {editing
                ? column.renderEdit?.(path, row, rowIndex, column)
                : children}
        </td>
    );
};

export const SettingsTable = observer(
    ({
        tableStore,
        hasPermission,
        columnSettings: columns,
    }: // eslint-disable-next-line sonarjs/cognitive-complexity
    SettingsTableProps) => {
        const [editingRowIndex, setEditingRowIndex] = useState<
            number | undefined
        >();
        const [formKey, setFormKey] = useState(0);

        useEffect(() => {
            tableStore.load();

            return () => {
                tableStore.reset();
            };
        }, []);

        useEffect(() => {
            setFormKey(formKey + 1);
        }, [tableStore.table?.rows]);

        const canEditTable = () => {
            if (hasPermission === undefined || hasPermission === true) {
                return editingRowIndex === undefined;
            }

            if (typeof hasPermission === 'function') {
                const fn = hasPermission();

                if (fn) {
                    return editingRowIndex === undefined;
                }

                return fn;
            }

            return hasPermission;
        };

        const canEdit = canEditTable();

        const cols = [
            ...getColumns(tableStore.table, columns),
            {
                title: '',
                key: 'action',
                dataIndex: 'action',
                editable: false,
                width: 100,
                render: (value: any, row: any, rowIndex: number) => {
                    if (rowIndex === editingRowIndex) {
                        return (
                            <Space>
                                <Button
                                    htmlType="submit"
                                    icon={<CheckIcon />}
                                    type="text"
                                />
                                <Button
                                    htmlType="reset"
                                    icon={<RejectIcon />}
                                    type="text"
                                />
                            </Space>
                        );
                    }

                    return (
                        <Button
                            icon={<EditIcon />}
                            type="text"
                            disabled={!canEdit}
                            onClick={() => {
                                setEditingRowIndex(rowIndex);
                            }}
                        />
                    );
                },
            },
        ];

        const mergedColumns = cols.map((col) => {
            return {
                ...col,
                onCell: (record: any, rowIndex: number) => ({
                    row: record,
                    column: col,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    rowIndex,
                    editing: rowIndex === editingRowIndex && col.editable,
                }),
            };
        });

        const fields = FormHelper.getFieldDataItems(
            tableStore?.updateTableLoader.errorData,
        ).map(({ name, ...field }) => ({
            ...field,
            name: (name as [])?.splice(1),
        }));

        if (!tableStore.table?.rows) {
            return (
                <StyledWrapper>
                    <Spinner />
                </StyledWrapper>
            );
        }

        return (
            <Form
                key={formKey}
                fields={fields}
                onFinish={async (values) => {
                    const rows = tableStore.table?.rows?.map((row, index) => {
                        if (values[index]) {
                            return { ...row, ...values[index] };
                        }

                        return row;
                    });

                    await tableStore.update({
                        columns: tableStore.table?.columns,
                        rows,
                    });

                    if (!tableStore.updateTableLoader.hasError) {
                        setEditingRowIndex(undefined);
                        notification.success({
                            message: (
                                <Trans i18nKey="Component.SettingsTable.Message.Success" />
                            ),
                        });
                    }
                }}
                onReset={() => {
                    tableStore.updateTableLoader.reset();
                    setEditingRowIndex(undefined);
                }}
                initialValues={tableStore.table?.rows}
            >
                <Table
                    loading={{
                        spinning:
                            tableStore.loadTableLoader.isLoading ||
                            tableStore.updateTableLoader.isLoading,
                        indicator: <Spinner />,
                    }}
                    pagination={false}
                    dataSource={tableStore.table?.rows}
                    columns={mergedColumns as any}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    scroll={{ x: true }}
                />
            </Form>
        );
    },
);

const StyledWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
`;
