import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ListPage } from 'src/components/ListPage/ListPage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { IndividualListEntry } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { IndividualsStatus } from 'src/pages/IndividualDetailsPage/Components/IndividualsStatus';
import { IndividualsHeaderPanel } from 'src/pages/IndividualsPage/IndividualsHeaderPanel';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const enumsToLoad: EnumName[] = [
    'CUSTOMER_VERIFICATION_STATUS',
    'REFERENCE_RELATIONSHIP',
    'CURRENCY',
    'ADDRESS_TYPE',
    'ADDRESS_OWNER',
];

export const IndividualsPage = observer(() => {
    const isLoading = useEnumsLoader(enumsToLoad);

    return (
        <ListPage
            store={IndividualsStore}
            columns={columns}
            isLoading={isLoading}
            header={
                <IndividualsHeaderPanel title={t('Page.Individuals.Header')} />
            }
        />
    );
});

export const columns: ColumnsType<IndividualListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.Individuals.List.Id" />,
        render: (individualId: string) => {
            const targetUrl = UrlHelper.getTargetUrl(
                RoutePaths.individualDetails,
                {
                    individualId,
                },
            );

            return <Link to={targetUrl}>{individualId}</Link>;
        },
    },
    {
        key: 'customerName',
        dataIndex: 'customerName',
        title: <Trans i18nKey="Page.Individuals.List.IndividualName" />,
    },
    {
        key: 'idDocumentRegistrationNumber',
        dataIndex: 'idDocumentRegistrationNumber',
        title: (
            <Trans i18nKey="Page.Individuals.List.IdDocumentRegistrationNumber" />
        ),
    },
    {
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
        title: <Trans i18nKey="Page.Individuals.List.PhoneNumber" />,
    },
    {
        key: 'relationshipManagerId',
        dataIndex: 'relationshipManagerId',
        title: <Trans i18nKey="Page.Individuals.List.RelationshipManagerId" />,
    },
    {
        key: 'branchCode',
        dataIndex: 'branchCode',
        title: <Trans i18nKey="Page.Individuals.List.BranchCode" />,
    },
    {
        key: 'verificationStatus',
        dataIndex: 'verificationStatus',
        title: <Trans i18nKey="Page.Individuals.List.VerificationStatus" />,
        render(value) {
            return <IndividualsStatus status={value} />;
        },
    },
    {
        key: 'civilId',
        dataIndex: 'civilId',
        title: <Trans i18nKey="Page.Individuals.List.CivilId" />,
    },
];
