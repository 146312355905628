import { notification } from 'antd';
import { action, computed } from 'mobx';
import { individualsApi } from 'src/services/apiServices';
import {
    IndividualBusinessIncomeStore,
    IndividualBusinessIncomeStoreClass,
} from 'src/stores/IndividualBusinessIncomeStore/IndividualBusinessIncomeStore';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { ViewModel } from 'src/utils/mobx/ViewModel';
import { RequestHelper } from 'src/utils/RequestHelper';

export class IndividualIncomeTypesViewModelClass extends ViewModel {
    constructor(public individualId: number) {
        super();
        this.businessIncomeStore = IndividualBusinessIncomeStore.getInstance(
            Number(individualId),
        );
    }

    businessIncomeStore: IndividualBusinessIncomeStoreClass;

    incomeTypesBriefLoader = new AsyncOperationWithStatus(async () => {
        const data = await RequestHelper.unwrapFromAxiosPromise(
            individualsApi.getIndividualIncomes({ id: this.individualId }),
        );

        await this.businessIncomeStore.loadList();

        IndividualsStore.itemLoader.turnOnSilentMode();
        await IndividualsStore.itemLoader.call(this.individualId);
        IndividualsStore.itemLoader.turnOffSilentMode();

        IndividualsStore.customerCreditScoringLoader.turnOnSilentMode();
        await IndividualsStore.customerCreditScoringLoader.call(
            this.individualId,
        );
        IndividualsStore.customerCreditScoringLoader.turnOffSilentMode();

        IndividualsStore.customerMonthlyIncomeLoader.turnOnSilentMode();
        await IndividualsStore.customerMonthlyIncomeLoader.call(
            this.individualId,
        );
        IndividualsStore.customerMonthlyIncomeLoader.turnOffSilentMode();

        if (IndividualsStore.itemLoader.hasError) {
            notification.error({
                message: IndividualsStore.itemLoader.errorData.detail,
            });
        }

        return data;
    });

    @action
    async load() {
        await this.incomeTypesBriefLoader.call();
    }

    @action
    async reset() {
        await this.incomeTypesBriefLoader.reset();
        await this.businessIncomeStore.listLoader.reset();
    }

    @action
    async reloadAfterCloseQrModal() {
        await IndividualsStore.loadItem(this.individualId);
        await this.incomeTypesBriefLoader.call();
    }

    @computed get salaryIncomeBrief() {
        return this.incomeTypesBriefLoader.data?.salary;
    }

    @computed get salaryIncomeActions() {
        return this.incomeTypesBriefLoader.data?.salaryActions;
    }

    @computed get businessIncomeBrief() {
        return this.incomeTypesBriefLoader.data?.business;
    }

    @computed get businessIncomeActions() {
        return this.incomeTypesBriefLoader.data?.businessActions;
    }

    @computed get businessIncomeHistory() {
        return this.businessIncomeStore.list;
    }

    @computed get livestockIncomeBrief() {
        return this.incomeTypesBriefLoader.data?.livestock;
    }

    @computed get livestockIncomeActions() {
        return this.incomeTypesBriefLoader.data?.livestockActions;
    }

    @computed get feeRequired() {
        return this.incomeTypesBriefLoader.data?.feeRequired || false;
    }
}

export const IndividualIncomeTypesViewModel = new EntityProvider(
    IndividualIncomeTypesViewModelClass,
);
