/* eslint-disable max-lines */
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataView, LONG_DASH } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListPage } from 'src/components/ListPage/ListPage';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { CollateralListEntry, IdNameEntry } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { CollateralsHeaderPanel } from 'src/pages/CollateralsPage/CollateralsHeaderPanel';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

const enumsToLoad: EnumName[] = ['COLLATERAL_TYPE', 'COLLATERAL_SUB_TYPE'];

export const CollateralsPage = observer(() => {
    const isLoading = useEnumsLoader(enumsToLoad);

    return (
        <ListPage
            store={CollateralsStore}
            columns={columns}
            isLoading={isLoading}
            header={
                <CollateralsHeaderPanel
                    title={t('Page.CollateralsPage.Header')}
                />
            }
        />
    );
});

export const columns: ColumnsType<CollateralListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.CollateralsPage.List.Id" />,
        render(_, record) {
            return (
                <Link
                    to={UrlHelper.getTargetUrl(RoutePaths.collateralDetails, {
                        collateralId: record.id,
                    })}
                >
                    {record.id}
                </Link>
            );
        },
    },
    {
        key: 'type',
        dataIndex: 'type',
        title: <Trans i18nKey="Page.CollateralsPage.List.Type" />,
        render: (value) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView enumName="COLLATERAL_TYPE" value={value} />
                    )}
                />
            );
        },
    },
    {
        key: 'subType',
        dataIndex: 'subType',
        title: <Trans i18nKey="Page.CollateralsPage.List.SubType" />,
        render: (value) => {
            return (
                <DataView
                    value={value}
                    format={(value) => (
                        <EnumView
                            enumName="COLLATERAL_SUB_TYPE"
                            value={value}
                        />
                    )}
                    hideLabel
                />
            );
        },
    },
    {
        key: 'registrationNumber',
        dataIndex: 'registrationNumber',
        title: <Trans i18nKey="Page.CollateralsPage.List.RegistrationNumber" />,
        render: (value) => {
            return <StyledRegistrationNumber>{value}</StyledRegistrationNumber>;
        },
    },
    {
        key: 'establishDateOrDateOfManufacture',
        title: (
            <Trans i18nKey="Page.CollateralsPage.List.EstablishDateOrDateOfManufacture" />
        ),
        render: (_, record) => getDate(record),
    },
    {
        key: 'owners',
        dataIndex: 'owners',
        title: <Trans i18nKey="Page.CollateralsPage.List.Owners" />,
        render: (value: [IdNameEntry]) => (
            <DataView
                value={value}
                hideLabel
                format={(users) => {
                    return Array.isArray(users) && users.length
                        ? users?.map((item: IdNameEntry) => {
                              return (
                                  <>
                                      <Link
                                          to={EntityLinks.individuals.itemDetails(
                                              item?.id,
                                          )}
                                      >
                                          {item?.name}
                                      </Link>
                                      <br />
                                  </>
                              );
                          })
                        : LONG_DASH;
                }}
            />
        ),
    },
    {
        key: 'loans',
        dataIndex: 'loans',
        title: <Trans i18nKey="Page.CollateralsPage.List.Loans" />,
        render: (value: [IdNameEntry]) => (
            <DataView
                value={value}
                hideLabel
                format={(loans) => {
                    return Array.isArray(loans) && loans.length
                        ? loans?.map((item: IdNameEntry) => {
                              return (
                                  <>
                                      <Link
                                          to={EntityLinks.loans.itemDetails(
                                              item?.id,
                                          )}
                                      >
                                          {item?.name}
                                      </Link>
                                      <br />
                                  </>
                              );
                          })
                        : LONG_DASH;
                }}
            />
        ),
    },
    {
        key: 'createdBy',
        dataIndex: 'createdBy',
        title: <Trans i18nKey="Page.CollateralsPage.List.CreatedBy" />,
        render: (value: IdNameEntry) => {
            return (
                <DataView
                    value={value?.name}
                    format="link-internal"
                    hideLabel
                    formatterProps={{
                        to: UrlHelper.getTargetUrl(RoutePaths.userDetails, {
                            userId: value?.id,
                        }),
                    }}
                />
            );
        },
    },
    {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: <Trans i18nKey="Page.CollateralsPage.List.CreatedAt" />,
        render: (value) => {
            return (
                <DataView value={value} format="date-time-seconds" hideLabel />
            );
        },
    },
];

const getDate = (item?: CollateralListEntry) => {
    if (item?.establishedDate) {
        return (
            <DataView value={item.establishedDate} format="date" hideLabel />
        );
    }

    if (item?.dateOfManufacture) {
        return (
            <DataView
                value={item.dateOfManufacture}
                format="string"
                hideLabel
            />
        );
    }

    return <DataView value={undefined} hideLabel />;
};

const StyledRegistrationNumber = styled.span`
    word-break: break-all;
    word-wrap: break-word;
`;
