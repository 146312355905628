import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';

export const BnplProductsFilterForm = observer(() => {
    return (
        <>
            <NumberField
                name="externalId"
                label={t('Page.BNPLProducts.Filter.ExternalId')}
                onlyInteger
            />
            <TextField name="name" label={t('Page.BNPLProducts.Filter.Name')} />
            <EnumSelectField
                mode="multiple"
                name="statuses"
                label={t('Page.BNPLProducts.Filter.Status')}
                enumName="PRODUCT_STATUS"
            />
            <NumberField
                name="transferringFeeFrom"
                label={t('Page.BNPLProducts.Filter.TransferringFeeFrom')}
            />
            <NumberField
                name="transferringFeeTo"
                label={t('Page.BNPLProducts.Filter.TransferringFeeTo')}
            />
        </>
    );
});
