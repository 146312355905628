import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    ColumnSettings,
    SettingsTable,
} from 'src/components/SettingsTable/SettingsTable';
import { RiskPriceRow, SettingType } from 'src/generated-api-client';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { TableSettingsStoreClass } from 'src/stores/Settings/TableSettingsStoreClass';
import styled from 'styled-components';

const tableStore = new TableSettingsStoreClass<RiskPriceRow>(
    SettingType.RISK_PRICE,
);

const renderNumberInput = (valuePath: any) => {
    return (
        <StyledNumberField
            name={valuePath}
            rules={[
                ValidationRules.required,
                ValidationRules.numberMinValue(0),
            ]}
        />
    );
};

const columnSettings: ColumnSettings[] = [
    {
        key: 'SCORE',
        title: (colDef) => {
            return t(
                'Page.SettingsProductLimit.Tabs.RiskPrice.Table.Score',
                colDef.payload,
            );
        },
        render: (data) => {
            return t(
                'Page.SettingsProductLimit.Tabs.RiskPrice.Table.Row.Score',
                {
                    ...data,
                },
            );
        },
    },
    {
        key: 'TENOR',
        title: (colDef) => {
            return t('Page.SettingsProductLimit.Tabs.RiskPrice.Table.Tenor', {
                ...colDef.payload,
            });
        },
        renderEdit: (valuePath) => renderNumberInput(valuePath),
        editable: true,
        width: 150,
    },
];

export const SettingsProductLimitRiskPriceTab = observer(() => {
    return (
        <SettingsTable
            tableStore={tableStore}
            columnSettings={columnSettings}
            hasPermission={AppUser.hasPermissions(
                UserPermission.LoanSettingsProductLimitRiskPriceEdit,
            )}
        />
    );
});

const StyledNumberField = styled(NumberField)`
    margin: 0;
    min-width: 60px;
`;
