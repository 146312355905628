import { Empty as AntEmpty } from 'antd';
import { useTranslation } from 'react-i18next';
import { NoResultFoundIcon } from 'src/components/Icons/Icons';
import styled from 'styled-components';

export type EmptyProps = {
    // eslint-disable-next-line react/require-default-props
    notFound?: boolean;
};
export const Empty = ({ notFound }: EmptyProps) => {
    const { t } = useTranslation();

    return (
        <StyledEmpty
            description={
                notFound ? (
                    <div>
                        <div>
                            <NoResultFoundIcon />
                        </div>
                        {t('Component.Empty.DescriptionNotFound')}
                    </div>
                ) : (
                    t('Component.Empty.Description')
                )
            }
            image={null}
        />
    );
};

const StyledEmpty = styled(AntEmpty)`
    .ant-empty-image {
        display: none;
    }
`;
