import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { CollateralDetailsType } from 'src/generated-api-client';

export const CreateLivestockCollateralGeneralInfoVariables = observer(() => {
    return (
        <Card
            title={t('Page.CollateralCreatePage.Livestock.GeneralInfo.Title')}
        >
            <Row gutter={16}>
                <HiddenField
                    name="type"
                    value={CollateralDetailsType.LIVESTOCK}
                />
                <Col xs={6}>
                    <EnumSelectField
                        name="herderInformation"
                        enumName="HERDER_INFORMATION"
                        label={t(
                            'Page.CollateralCreatePage.Livestock.GeneralInfo.Title.HerderInformation',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={6}>
                    <NumberField
                        name="herdingNumber"
                        label={t(
                            'Page.CollateralCreatePage.Livestock.GeneralInfo.Title.HerdingNumber',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={6}>
                    <NumberField
                        name="livestockTotalCount"
                        label={t(
                            'Page.CollateralCreatePage.Livestock.GeneralInfo.Title.LivestockTotalCount',
                        )}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.numberMinValue(0),
                        ]}
                    />
                </Col>
                <Col xs={6}>
                    <NumberField
                        name="weightedNumberIntoSheep"
                        label={t(
                            'Page.CollateralCreatePage.Livestock.GeneralInfo.Title.WeightedNumberIntoSheep',
                        )}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.numberMinValue(0),
                        ]}
                    />
                </Col>
                <Col xs={6}>
                    <NumberField
                        name="livestockTotalAmount"
                        label={t(
                            'Page.CollateralCreatePage.Livestock.GeneralInfo.Title.LivestockTotalAmount',
                        )}
                        thousandDivider
                        rules={[
                            ValidationRules.required,
                            ValidationRules.numberMinValue(0),
                        ]}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="additionalField"
                        label={t(
                            'Page.CollateralCreatePage.Livestock.GeneralInfo.Title.AdditionalField',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={6}>
                    <EnumSelectField
                        name="ownershipType"
                        enumName="COLLATERAL_OWNERSHIP_TYPE"
                        label={t(
                            'Page.CollateralCreatePage.Livestock.GeneralInfo.OwnershipType',
                        )}
                    />
                </Col>
            </Row>
        </Card>
    );
});
