import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { AutoSessionCompleteViewModel } from 'src/components/AutoSessionComplete/AutoSessionCompleteViewModel';
import { CompleteSessionDialog } from 'src/components/AutoSessionComplete/CompleteSessionDialog';

const viewModel = AutoSessionCompleteViewModel;

export const AutoSessionComplete = observer(() => {
    useEffect(() => {
        viewModel.init();

        return () => {
            viewModel.reset();
        };
    }, []);

    useEffect(() => {
        if (viewModel.isShowDialog) {
            viewModel.pause();
            CompleteSessionDialog.render();
        }
    }, [viewModel.isShowDialog]);

    useEffect(() => {
        const id = setInterval(() => {
            if (!viewModel.isShowDialog && !viewModel.getLastSessionTime()) {
                clearInterval(id);
                setTimeout(() => window.location.reload(), 500);
            }
        }, 500);

        return () => {
            clearInterval(id);
        };
    }, [viewModel.isShowDialog]);

    return null;
});
