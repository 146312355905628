import { notification } from 'antd';
import { t } from 'i18next';
import { action, computed } from 'mobx';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { AuthErrorType, isAuthError } from 'src/stores/LocalAuth/AuthErrors';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';

export type RecoveryExpiredPasswordForm = {
    code: string;
    email: string;
};

export class RecoveryExpiredPasswordStoreClass {
    public verifyCodeLoader = new AsyncOperationWithStatus(
        (_code: string, _email: string) => {
            console.warn('Methode "verifyCode" not implemented');

            return Promise.reject();
        },
    );

    public recoveryExpiredPasswordLoader = new AsyncOperationWithStatus(
        ({ code, email, ..._values }: RecoveryExpiredPasswordForm) => {
            console.warn('Methode "recoveryExpiredPassword" not implemented');

            return Promise.reject();
        },
    );

    public requestToRecoveryExpiredPasswordLoader =
        new AsyncOperationWithStatus((_values) => {
            console.warn('Methode "requestToRecovery" not implemented');

            return Promise.reject();
        });

    @action async verifyCode(code: string, email: string) {
        await this.verifyCodeLoader.call(code, email);
    }

    @computed get isValidCode() {
        return !this.verifyCodeLoader.hasError && this.isCodeValidated;
    }

    @computed get isCodeValidated() {
        return this.verifyCodeLoader.countLoads > 0;
    }

    @computed get isCodeValidating() {
        return this.verifyCodeLoader.isLoading;
    }

    @action async recoveryExpiredPassword(values: RecoveryExpiredPasswordForm) {
        await this.recoveryExpiredPasswordLoader.call(values);

        if (!this.recoveryExpiredPasswordLoader.hasError) {
            notification.success({
                message: t(
                    'Page.RecoveryExpiredPassword.Messages.Success',
                    'Password was set successfully',
                ),
            });
            Router.navigate(RoutePaths.authLogin);
        }
    }

    @computed get isExpiredPasswordRecovering() {
        return this.recoveryExpiredPasswordLoader.isLoading;
    }

    @computed get isRequestResetExpiredPasswordLoading() {
        return this.requestToRecoveryExpiredPasswordLoader.isLoading;
    }

    @action async requestToRecoveryExpiredPassword(values: any) {
        await this.requestToRecoveryExpiredPasswordLoader.call(values);
    }

    @computed get isRequestToRecoverySent() {
        return (
            !this.requestToRecoveryExpiredPasswordLoader.hasError &&
            this.requestToRecoveryExpiredPasswordLoader.countLoads > 0 &&
            !this.requestToRecoveryExpiredPasswordLoader.isLoading
        );
    }

    @computed get isAccessDenied() {
        return (
            this.requestToRecoveryExpiredPasswordLoader.errorData?.detail?.toLowerCase() ===
            'access is forbidden - contact your system administrator'
        );
    }

    @action reset() {
        this.verifyCodeLoader.reset();
        this.recoveryExpiredPasswordLoader.reset();
        this.requestToRecoveryExpiredPasswordLoader.reset();
    }

    @computed get isNewPasswordSet() {
        return (
            this.recoveryExpiredPasswordLoader.countLoads > 0 &&
            !this.recoveryExpiredPasswordLoader.hasError
        );
    }

    @computed get isInvalidLink() {
        return (
            this.verifyCodeLoader.hasError &&
            isAuthError(this.verifyCodeLoader.errorData) &&
            this.verifyCodeLoader.errorData.type === AuthErrorType.InvalidCode
        );
    }
}

export const RecoveryExpiredPasswordStore =
    new RecoveryExpiredPasswordStoreClass();
