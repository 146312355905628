import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView, LONG_DASH } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { CarCollateral, CollateralOwner } from 'src/generated-api-client';
import { getCarCollateralGeneralInfoActions } from 'src/pages/CollateralDetailsPage/Tabs/CarCollateralTab/CarCollateralGeneralInfoActions.config';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';

export const CarCollateralGeneralInfo = observer(() => {
    const collateral = CollateralsStore.currentItem as CarCollateral;
    const menuActions = getCarCollateralGeneralInfoActions(collateral);

    return (
        <Card
            title={t('Page.CollateralDetails.Tabs.Car.GeneralInfo.Title')}
            menuActions={menuActions}
        >
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral?.steeringWheel}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.SteeringWheel',
                        )}
                        format={(value) => (
                            <EnumView enumName="STEERING_WHEEL" value={value} />
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.category}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.Category',
                        )}
                        format={(value) => (
                            <EnumView
                                enumName="CAR_COLLATERAL_CATEGORY"
                                value={value}
                            />
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.registrationNumber}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.RegistrationNumber',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.chassisNumber}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.ChassisNumber',
                        )}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral.carBrand}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.CarBrand',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.model}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.Model',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.color}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.Color',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.engineType}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.EngineType',
                        )}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral.engineCapacity}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.EngineCapacity',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.dateOfManufacture}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.DateOfManufacture',
                        )}
                        format="string"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.importedDate}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.ImportedDate',
                        )}
                        format="date"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.mileage}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.Mileage',
                        )}
                        format="number"
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral?.overallGrade}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.OverallGrade',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.ownershipType}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.OwnershipType',
                        )}
                        format={(value) => (
                            <EnumView
                                enumName="COLLATERAL_OWNERSHIP_TYPE"
                                value={value}
                            />
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.owners}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.Owner',
                        )}
                        format={(owners) => {
                            return Array.isArray(owners) && owners.length
                                ? owners.map((owner: CollateralOwner) => {
                                      return (
                                          <>
                                              {owner.name}
                                              <br />
                                          </>
                                      );
                                  })
                                : LONG_DASH;
                        }}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.owners}
                        label={t(
                            'Page.CollateralDetails.Tabs.Car.GeneralInfo.OwnerRegistrationNumber',
                        )}
                        format={(owners) => {
                            return Array.isArray(owners) && owners?.length
                                ? owners.map((owner: CollateralOwner) => {
                                      return (
                                          <>
                                              {owner.registrationNumber}
                                              <br />
                                          </>
                                      );
                                  })
                                : LONG_DASH;
                        }}
                    />
                </Col>
            </Row>
        </Card>
    );
});
