/* eslint-disable max-lines */
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { DeleteIcon, EditIcon, PlusIcon } from 'src/components/Icons/Icons';
import { ListView } from 'src/components/ListView/ListView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { IdNameEntry, N13Collateral } from 'src/generated-api-client';
import { AddCollateralDialog } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/Collaterals/AddCollateralDialog';
import { EditCollateralDialog } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/Collaterals/EditCollateralDialog';
import { RemoveCollateralDialog } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/Collaterals/RemoveCollateralDialog';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import styled from 'styled-components';

type CollateralsListCardProps = {
    viewModel: N13TaskViewModelClass;
    collaterals: N13Collateral[];
};

export const CollateralsListCard = observer(
    ({ viewModel, collaterals }: CollateralsListCardProps) => {
        return (
            <Card
                title={t(
                    'Page.N13AddEstimatedConditionsTask.Collaterals.Title',
                )}
                extra={
                    <Button
                        icon={<PlusIcon />}
                        type="primary"
                        onClick={() => {
                            AddCollateralDialog.render({
                                viewModel,
                            });
                        }}
                    >
                        {t(
                            'Page.N13AddEstimatedConditionsTask.Collaterals.AddCollateral',
                        )}
                    </Button>
                }
            >
                <StyledListView
                    dataSource={collaterals}
                    columns={getColumns(viewModel)}
                />
            </Card>
        );
    },
);

const getColumns = (
    viewModel: N13TaskViewModelClass,
): ColumnsType<N13Collateral> => {
    return [
        {
            key: 'id',
            dataIndex: 'id',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.Collaterals.List.Id" />
            ),
            render(_, record) {
                return (
                    <Link to={EntityLinks.collaterals.itemDetails(record?.id)}>
                        {record?.id}
                    </Link>
                );
            },
        },
        {
            key: 'subType',
            dataIndex: 'subType',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.Collaterals.List.SubType" />
            ),
            render: (value) => {
                return (
                    <DataView
                        value={value}
                        hideLabel
                        format={(value) => (
                            <EnumView
                                enumName="COLLATERAL_SUB_TYPE"
                                value={value}
                            />
                        )}
                    />
                );
            },
        },
        {
            key: 'sizeMark',
            dataIndex: 'sizeMark',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.Collaterals.List.SizeMark" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'registrationNumber',
            dataIndex: 'registrationNumber',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.Collaterals.List.RegistrationNumber" />
            ),
            render: (value) => {
                return (
                    <StyledRegistrationNumber>{value}</StyledRegistrationNumber>
                );
            },
        },
        {
            key: 'marketValue',
            dataIndex: 'marketValue',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.Collaterals.List.MarketValue" />
            ),
            render: (value) => {
                return <DataView value={value} format="money" hideLabel />;
            },
        },
        {
            key: 'rmValue',
            dataIndex: 'rmValue',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.Collaterals.List.CiaValue" />
            ),
            render: (value) => {
                return <DataView value={value} format="money" hideLabel />;
            },
        },
        {
            key: 'importedDate',
            dataIndex: 'importedDate',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.Collaterals.List.ImportedDate" />
            ),
            render: (value) => {
                return <DataView value={value} format="date" hideLabel />;
            },
        },
        {
            key: 'owner',
            dataIndex: 'owner',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.Collaterals.List.Owner" />
            ),
            render: (value: IdNameEntry) => {
                return (
                    <DataView
                        value={value?.name}
                        format="link-internal"
                        hideLabel
                        formatterProps={{
                            to: EntityLinks.individuals.itemDetails(value?.id),
                        }}
                    />
                );
            },
        },
        {
            key: 'editCollateral',
            dataIndex: 'editCollateral',
            title: '',
            render: (_, record) => {
                return (
                    <Button
                        type="text"
                        icon={<EditIcon />}
                        onClick={() => {
                            EditCollateralDialog.render({
                                viewModel,
                                collateral: record,
                            });
                        }}
                    />
                );
            },
        },
        {
            key: 'removeCollateral',
            dataIndex: 'removeCollateral',
            title: '',
            render: (_, record) => {
                return (
                    <Button
                        type="text"
                        icon={<DeleteIcon />}
                        onClick={() => {
                            RemoveCollateralDialog.render({
                                viewModel,
                                collateralId: record?.id,
                            });
                        }}
                    />
                );
            },
        },
    ];
};

const StyledRegistrationNumber = styled.span`
    word-break: break-all;
    word-wrap: break-word;
`;

const StyledListView = styled(ListView)`
    padding: 0 0 10px;
`;
