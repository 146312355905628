import { WarningOutlined } from '@ant-design/icons';
import { Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BusinessIncomeCard } from 'src/pages/IndividualDetailsPage/Components/IncomeCards/BusinessIncomeCard';
import { LiveStockIncomeCard } from 'src/pages/IndividualDetailsPage/Components/IncomeCards/LiveStockIncomeCard';
import { SalaryIncomeCard } from 'src/pages/IndividualDetailsPage/Components/IncomeCards/SalaryIncomeCard';
import { IndividualIncomeTypesViewModel } from 'src/pages/IndividualDetailsPage/Tabs/IndividualIncomeTypesViewModel';
import styled from 'styled-components';

export type IndividualIncomeTypesProps = { individualId?: number };
export const IndividualIncomeTypes = observer(
    ({ individualId }: IndividualIncomeTypesProps) => {
        const incomeTypesViewModel = IndividualIncomeTypesViewModel.getInstance(
            individualId as number,
        );
        const showFeeRequiredMessage = incomeTypesViewModel.feeRequired;

        useEffect(() => {
            incomeTypesViewModel.load();

            return () => {
                incomeTypesViewModel.reset();
            };
        }, [individualId]);

        return (
            <Row gutter={16}>
                {showFeeRequiredMessage && (
                    <Col xs={24}>
                        <StyledMessage type="warning">
                            <Space size={5}>
                                <WarningOutlined />
                                {t(
                                    'Page.LoanDetails.Tab.LoanLimit.FeeRequiredMessage',
                                    "New income can't be added until the Customer will repay a recalculation fee. Please try again when the fee will be repaid",
                                )}
                            </Space>
                        </StyledMessage>
                    </Col>
                )}
                <Col xs={8}>
                    <SalaryIncomeCard individualId={individualId} />
                </Col>
                <Col xs={8}>
                    <BusinessIncomeCard individualId={individualId} />
                </Col>
                <Col xs={8}>
                    <LiveStockIncomeCard individualId={individualId} />
                </Col>
            </Row>
        );
    },
);

const StyledMessage = styled(Typography.Paragraph)`
    padding: 20px;
    border-radius: 8px;
    background: white;
`;
