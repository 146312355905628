import { Col, notification, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { RestrictionsStore } from 'src/stores/RestrictionsStore/RestrictionsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const store = RestrictionsStore;

export const AddCustomerFn = observer(({ ...props }: ModalRendererProps) => {
    useEffect(() => {
        return () => {
            store.addCustomerLoader.reset();
        };
    }, []);

    const fields = FormHelper.getFieldDataItems(
        store.addCustomerLoader.errorData,
    );

    return (
        <Modal
            title={t('Dialog.RestrictionsListAdd.Title')}
            isLoading={store.addCustomerLoader.isLoading}
            onFinish={async (values) => {
                await store.add(values);

                if (!store.addCustomerLoader.hasError) {
                    notification.success({
                        message: t(
                            'Dialog.RestrictionsListAdd.Message.Success',
                        ),
                    });
                }

                if (store.addCustomerLoader.hasError) {
                    throw new Error(store.addCustomerLoader.errorData);
                }
            }}
            formProps={{ fields }}
            {...props}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <EnumSelectField
                        enumName="RESTRICTION_LIST_TYPE"
                        name="listType"
                        label={t('Dialog.RestrictionsListAdd.ListType')}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col span={24}>
                    <EnumSelectField
                        enumName="RESTRICTION_CAUSE"
                        name="cause"
                        label={t('Dialog.RestrictionsListAdd.RestrictedCause')}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col span={24}>
                    <NumberField
                        name="customerId"
                        label={t('Dialog.RestrictionsListAdd.CustomerId')}
                        rules={[ValidationRules.required]}
                        onlyInteger
                    />
                </Col>
            </Row>
        </Modal>
    );
});

export const AddCustomer = addModalRenderer(AddCustomerFn);
