import { notification } from 'antd';
import i18next from 'i18next';
import { action } from 'mobx';
import {
    FileRequest,
    FileRequestStatus,
    RequestFileRequest,
} from 'src/generated-api-client';
import { fileRequestsApi, filesApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

const REQUEST_FILE_UPDATE_INTERVAL = 5000;
export class FilesStoreClass {
    uploadFileLoader = new AsyncOperationWithStatus((file: any) => {
        return RequestHelper.unwrapFromAxiosPromise(
            filesApi.uploadFile({ file }),
        );
    });

    requestNewFileLoader = new AsyncOperationWithStatus(
        (data: RequestFileRequest) => {
            return RequestHelper.unwrapFromAxiosPromise(
                fileRequestsApi.requestFile({ requestFileRequest: data }),
            );
        },
    );

    requestFileInfoLoader = new AsyncOperationWithStatus((id: number) => {
        return RequestHelper.unwrapFromAxiosPromise(
            fileRequestsApi.getFileRequest({ id }),
        );
    });

    @action async upload(file: any) {
        const documentId = await this.uploadFileLoader.call(file);

        if (this.uploadFileLoader.hasError) {
            notification.error({
                message: i18next.t('Errors.DocumentUpload'),
            });
        }

        return documentId;
    }

    @action async requestFile(requestFileRequest: RequestFileRequest) {
        let requestedFileInfo = await this.requestNewFile(requestFileRequest);

        const requestId = requestedFileInfo?.requestId as number;

        if (requestedFileInfo?.requestStatus !== FileRequestStatus.COMPLETED) {
            // eslint-disable-next-line no-await-in-loop
            requestedFileInfo = await this.getRequestFileInfoWhileNotCompleted(
                requestId,
            );
        }

        return requestedFileInfo;
    }

    @action async requestNewFile(requestFileRequest: RequestFileRequest) {
        const fileRequest = await this.requestNewFileLoader.call(
            requestFileRequest,
        );

        if (this.requestNewFileLoader.hasError) {
            notification.error({
                message: this.requestNewFileLoader.errorData?.detail,
            });

            return;
        }
        this.requestNewFileLoader.reset();

        return fileRequest;
    }

    @action async getRequestFileInfo(requestId: number) {
        const fileRequest = await this.requestFileInfoLoader.call(requestId);

        this.requestFileInfoLoader.reset();

        return fileRequest;
    }

    @action getRequestFileInfoWhileNotCompleted(requestId: number) {
        return new Promise<FileRequest>((resolve, reject) => {
            setTimeout(async () => {
                const fileRequest = await this.getRequestFileInfo(requestId);
                if (!fileRequest) {
                    reject(new Error(`File can't be downloaded`));

                    return;
                }
                if (
                    fileRequest?.requestStatus === FileRequestStatus.COMPLETED
                ) {
                    resolve(fileRequest);
                } else {
                    resolve(
                        this.getRequestFileInfoWhileNotCompleted(requestId),
                    );
                }
            }, REQUEST_FILE_UPDATE_INTERVAL);
        });
    }
}

export const FilesStore = new FilesStoreClass();
