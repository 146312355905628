import { t } from 'i18next';
import {
    ActionType,
    IndividualCoBorrowerListEntry,
} from 'src/generated-api-client';
import { RemoveCoBorrowerDialog } from 'src/pages/LoanDetailsPage/Dialogs/IndividualCoBorrowers/RemoveIndividualCoBorrowerDialog';
import { UpdateCoBorrowerDialog } from 'src/pages/LoanDetailsPage/Dialogs/IndividualCoBorrowers/UpdateIndividualCoBorrowerDialog';
import { LoanType } from 'src/stores/LoansStore/LoansStore';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getCoBorrowerActions = (
    coBorrower?: IndividualCoBorrowerListEntry,
    loan?: LoanType,
) => {
    return [...getUIActions(), ...getServerActions(coBorrower, loan)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    coBorrower?: IndividualCoBorrowerListEntry,
    loan?: LoanType,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(loan, coBorrower),
        loan?.actions,
    );
};

const getRegisteredServerActions = (
    loan?: LoanType,
    coBorrower?: IndividualCoBorrowerListEntry,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.CO_BORROWER_UPDATE,
            label: t('Loan.Actions.CO_BORROWER_UPDATE'),
            onClick: () => {
                UpdateCoBorrowerDialog.render({ coBorrower, loanId: loan?.id });
            },
        },
        {
            key: ActionType.CO_BORROWER_DELETE,
            label: t('Loan.Actions.CO_BORROWER_DELETE'),
            onClick: () => {
                RemoveCoBorrowerDialog.render({ coBorrower, loanId: loan?.id });
            },
        },
    ];
};
