/* eslint-disable max-lines */
import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { ErrorField } from 'src/components/Form/ErrorField';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    N13Task,
    N13TaskDecisionAddLoanConditions,
} from 'src/generated-api-client';
import { TaskCommentsView } from 'src/pages/TaskDetailsPage/Components/TaskCommentsView';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { AdditionalInfoCard } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/AdditionalInfoCard';
import { CollateralsListCard } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/Collaterals/CollateralsListCard';
import { CollateralsCard } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/CollateralsCard';
import { IndividualCoBorrowersCard } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/IndividualCoBorrowersCard';
import { LegalEntityCoBorrowersCard } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/LegalEntityCoBorrowersCard';
import { RequestedConditionCard } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/RequestedConditionCard';
import { N13AddEstimatedConditionsTaskApproveVariables } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13AddEstimatedConditionsTaskApproveVariables';
import { N13TaskTaskViewModel } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

export const N13AddEstimatedConditionsTask = observer(
    ({ viewModel }: TaskProps<N13Task>) => {
        const n13TaskTaskViewModel = N13TaskTaskViewModel.getInstance(
            String(viewModel.currentTaskId),
        );
        const task = n13TaskTaskViewModel.currentTask || ({} as N13Task);
        const {
            loanApplication,
            requestedConditions,
            collaterals,
            csComment,
            laatLeaderComment,
        } = task;
        const showRequestedConditions = Boolean(requestedConditions);

        const comments = [
            {
                label: t('Component.TaskCommentsView.CSComment'),
                value: csComment,
            },
            {
                label: t('Component.TaskCommentsView.LAATLeaderComment'),
                value: laatLeaderComment,
            },
        ];

        return (
            <TaskForm
                initialValues={getInitialValues(task)}
                fields={n13TaskTaskViewModel.submitTaskErrorFields}
                onFinish={async (values) => {
                    const submitValues =
                        convertFormValuesToSubmitValues(values);
                    await n13TaskTaskViewModel.submitTask(submitValues);
                    if (!n13TaskTaskViewModel.submitTaskLoaderHasError) {
                        Router.navigate(RoutePaths.tasks);
                    }
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col xs={24}>
                        <StyledText>
                            <Trans i18nKey="Page.N13AddEstimatedConditionsTask.Descriptions" />
                        </StyledText>
                    </Col>
                    <Col xs={24}>
                        <TaskLinkView loanApplication={loanApplication} />
                    </Col>
                    <Col xs={24}>
                        <CollateralsListCard
                            viewModel={n13TaskTaskViewModel}
                            collaterals={collaterals}
                        />
                    </Col>
                    <Col xs={24}>
                        <CollateralsCard task={task} />
                    </Col>
                    <Col xs={24}>
                        <DocumentsList
                            documentRoom={task?.documentRoom}
                            title={(countDocuments) =>
                                t(
                                    'Page.N13AddEstimatedConditionsTask.AllDocuments',
                                    {
                                        count: countDocuments,
                                    },
                                )
                            }
                        />
                    </Col>

                    <Col xs={24}>
                        <AdditionalInfoCard task={task} />
                    </Col>
                    <Col xs={24}>
                        <TaskCommentsView comments={comments} />
                    </Col>
                    <Col xs={24}>
                        {showRequestedConditions && (
                            <RequestedConditionCard task={task} />
                        )}
                    </Col>
                    <Col xs={24}>
                        <N13AddEstimatedConditionsTaskApproveVariables
                            task={task}
                        />
                    </Col>
                    <Col xs={24}>
                        <IndividualCoBorrowersCard
                            viewModel={n13TaskTaskViewModel}
                        />
                    </Col>
                    <Col xs={24}>
                        <LegalEntityCoBorrowersCard
                            viewModel={n13TaskTaskViewModel}
                        />
                    </Col>
                    <Col xs={24}>
                        <ErrorField name={['coBorrowers', 'otherErrors']} />
                    </Col>
                    <Col xs={24}>
                        <ErrorField name={['collaterals', 'otherErrors']} />
                    </Col>

                    <Col xs={24}>
                        <Space size={20}>
                            <Button
                                type={
                                    viewModel.isCurrentTaskEqualWorkingTask
                                        ? 'primary'
                                        : 'default'
                                }
                                htmlType="submit"
                            >
                                {t('Task.Form.N13Task.Btn.Approve')}
                            </Button>
                            <Button
                                onClick={() => {
                                    Router.navigate(RoutePaths.tasks);
                                }}
                            >
                                {t('Task.Form.Btn.Back')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </TaskForm>
        );
    },
);

const getInitialValues = (data?: N13Task) => {
    return {
        ...data,
        firstRepaymentDate:
            data?.firstRepaymentDate && moment(data?.firstRepaymentDate),
        secondRepaymentDate:
            data?.secondRepaymentDate && moment(data?.secondRepaymentDate),
        productId: data?.productId,
        requestedTerm: data?.requestedConditions?.term,
    };
};

const convertFormValuesToSubmitValues = (
    data?: N13TaskDecisionAddLoanConditions,
) => {
    return {
        ...data,
        firstRepaymentDate:
            data?.firstRepaymentDate &&
            DateHelper.formatISODate(data?.firstRepaymentDate),
        secondRepaymentDate:
            data?.secondRepaymentDate &&
            DateHelper.formatISODate(data?.secondRepaymentDate),
    } as N13TaskDecisionAddLoanConditions;
};

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
