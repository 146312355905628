import { Button, Col, Row } from 'antd';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { NotFound } from 'src/components/NotFound/NotFound';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useEntityId } from 'src/hooks/useEntityId';
import { GroupCompaniesActions } from 'src/pages/Settings/CustomerGroups/CustomerGroupCompaniesPage/Components/GroupCompaniesActions';
import { GroupCompaniesList } from 'src/pages/Settings/CustomerGroups/CustomerGroupCompaniesPage/Components/GroupCompaniesList';
import { CustomerGroupsStore } from 'src/stores/CustomerGroupsStore/CustomerGroupsStore';

const store = CustomerGroupsStore;

export const CustomerGroupCompaniesPage = observer(() => {
    const { entityId } = useEntityId();

    useEffect(() => {
        store.loadItem(Number(entityId));

        return () => {
            store.itemLoader.reset();
        };
    }, [entityId]);

    const item = store.currentItem;

    const error = useMemo(() => {
        const showNotFound =
            !_.isInteger(Number(entityId)) ||
            store.itemLoader.errorData?.status === 404;
        if (showNotFound) {
            return (
                <NotFound
                    title={t('Page.GroupCompanies.Error.NotFound.Title')}
                    description={t(
                        'Page.GroupCompanies.Error.NotFound.Description',
                        { entityId },
                    )}
                >
                    <Button
                        onClick={() => {
                            Router.navigate(
                                RoutePaths.settingsCustomerSegmentation,
                            );
                        }}
                    >
                        {t('Page.GroupCompanies.GoToList')}
                    </Button>
                </NotFound>
            );
        }

        return null;
    }, [entityId, store.itemLoader.errorData]);

    return (
        <BasePage
            title={
                <Row gutter={16}>
                    <Col flex={1}>{item?.name}</Col>
                    <Col>
                        <GroupCompaniesActions />
                    </Col>
                </Row>
            }
            isLoading={store.itemLoader.isLoading}
            showOnlyLoading={!item}
            error={error}
        >
            <GroupCompaniesList groupId={item?.id} />
        </BasePage>
    );
});
