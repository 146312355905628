/* eslint-disable complexity, max-lines */
import { Col, Form, notification, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { ErrorField } from 'src/components/Form/ErrorField';
import { ImageField } from 'src/components/Form/ImageField';
import { TextField } from 'src/components/Form/TextField';
import {
    FileFormats,
    FileSizeUnit,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { DocumentSource, DocumentType } from 'src/generated-api-client';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { DocumentsStore } from 'src/stores/DocumentsStore/DocumentsStore';
import { FilesStore } from 'src/stores/FilesStore/FilesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type UpdateGeneralInformationDialogFnProps = ModalRendererProps<{
    viewModel?: IndividualDetailsViewModelClass;
}>;

const supportedFormats = [FileFormats.png, FileFormats.jpeg].join(',');

export const UpdateGeneralInformationDialogFn = observer(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    ({ viewModel, ...props }: UpdateGeneralInformationDialogFnProps) => {
        const [form] = Form.useForm();
        const documentsStore = DocumentsStore.getInstance(
            Number(viewModel?.item?.documentRoom?.id),
        );

        useEffect(() => {
            return () => {
                viewModel?.updateGeneralInfoLoader.reset();
                documentsStore.createItemLoader.reset();
            };
        }, []);

        const loadingInfo = useMemo(() => {
            return new CombinedLoadingStatus([
                viewModel?.updateGeneralInfoLoader!,
                documentsStore.createItemLoader,
                FilesStore.uploadFileLoader,
            ]);
        }, []);

        const documentsFields = FormHelper.getFieldDataItems(
            documentsStore.createItemLoader.errorData,
        ).map((item) => ({ ...item, name: 'documents' }));

        const generalInfoFields = FormHelper.getFieldDataItems(
            viewModel?.updateGeneralInfoLoader.errorData,
        );

        const fields = [...generalInfoFields, ...documentsFields];

        const isSetCustomerList = AppUser.hasPermissions(
            UserPermission.DocumentCustomerUpload,
        )
            ? Boolean(viewModel?.item?.restrictionListType)
            : true;

        return (
            <Modal
                title={t('Dialog.UpdateGeneralInformation.Title')}
                isLoading={loadingInfo.isLoading}
                onFinish={async (values) => {
                    const selfieImage = values.selfieImage?.[0]?.originFileObj;
                    const signatureImage =
                        values.signatureImage?.[0]?.originFileObj;

                    if (selfieImage) {
                        const selfieImageMetaData = await FilesStore.upload(
                            selfieImage,
                        );

                        await documentsStore.create({
                            type: DocumentType.SELFIE_IMAGE,
                            source: DocumentSource.HES,
                            uploadedFileId: String(selfieImageMetaData?.id),
                        });
                    }

                    if (documentsStore.createItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (signatureImage) {
                        const signatureImageMetaData = await FilesStore.upload(
                            signatureImage,
                        );
                        await documentsStore.create({
                            type: DocumentType.SIGNATURE,
                            source: DocumentSource.HES,
                            uploadedFileId: String(signatureImageMetaData?.id),
                        });
                    }

                    if (documentsStore.createItemLoader.hasError) {
                        return Promise.reject();
                    }

                    await viewModel?.updateGeneralInfo(values);

                    if (viewModel?.updateGeneralInfoLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel?.updateGeneralInfoLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.UpdateGeneralInformation.Message.Success',
                            ),
                        });
                    }
                }}
                initialValues={getInitialValues(viewModel)}
                formProps={{ fields }}
                form={form}
                width={900}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={24}>
                        <StyledError name={['documents']} />
                    </Col>
                    <StyledCol sm={8}>
                        <ImageField
                            name="idCardImage"
                            label={t('Dialog.UpdateGeneralInformation.IdCard')}
                            disabled
                        />
                    </StyledCol>
                    <StyledCol sm={8}>
                        <ImageField
                            name="selfieImage"
                            label={t('Dialog.UpdateGeneralInformation.Selfie')}
                            accept={supportedFormats}
                            rules={[
                                ValidationRules.fileFormats([
                                    FileFormats.png,
                                    FileFormats.jpeg,
                                ]),
                                ValidationRules.maxFileSize(
                                    10,
                                    FileSizeUnit.Mb,
                                ),
                            ]}
                            onChange={() =>
                                documentsStore.createItemLoader.reset()
                            }
                            disabled={isSetCustomerList}
                        />
                    </StyledCol>
                    <StyledCol sm={8}>
                        <ImageField
                            name="signatureImage"
                            label={t(
                                'Dialog.UpdateGeneralInformation.Signature',
                            )}
                            accept={FileFormats.png}
                            rules={[
                                ValidationRules.fileFormats([FileFormats.png]),
                                ValidationRules.maxFileSize(
                                    10,
                                    FileSizeUnit.Mb,
                                ),
                            ]}
                            onChange={() =>
                                documentsStore.createItemLoader.reset()
                            }
                            disabled={isSetCustomerList}
                        />
                    </StyledCol>
                </Row>
                <Row gutter={16}>
                    <Col sm={8}>
                        <TextField
                            name="id"
                            label={t('Dialog.UpdateGeneralInformation.Id')}
                            disabled
                        />
                    </Col>
                    <Col sm={8}>
                        <TextField
                            name="registrationNumber"
                            label={t(
                                'Dialog.UpdateGeneralInformation.RegistrationNumber',
                            )}
                            disabled={
                                Boolean(
                                    viewModel?.item
                                        ?.idDocumentRegistrationNumber,
                                ) || isSetCustomerList
                            }
                        />
                    </Col>
                    <Col sm={8}>
                        <TextField
                            name="firstName"
                            label={t(
                                'Dialog.UpdateGeneralInformation.FirstName',
                            )}
                            disabled={
                                Boolean(viewModel?.item?.firstName) ||
                                isSetCustomerList
                            }
                        />
                    </Col>
                    <Col sm={8}>
                        <TextField
                            name="lastName"
                            label={t(
                                'Dialog.UpdateGeneralInformation.LastName',
                            )}
                            disabled={
                                Boolean(viewModel?.item?.lastName) ||
                                isSetCustomerList
                            }
                        />
                    </Col>
                    <Col sm={8}>
                        <TextField
                            name="familyName"
                            label={t(
                                'Dialog.UpdateGeneralInformation.FamilyName',
                            )}
                            disabled={
                                Boolean(viewModel?.item?.familyName) ||
                                isSetCustomerList
                            }
                        />
                    </Col>
                    <Col sm={8}>
                        <DatePickerField
                            name="birthDate"
                            label={t(
                                'Dialog.UpdateGeneralInformation.BirthDate',
                            )}
                            disabled={
                                Boolean(viewModel?.item?.birthDate) ||
                                isSetCustomerList
                            }
                            disabledDate={(current) =>
                                current.isAfter(moment())
                            }
                        />
                    </Col>
                    <Col sm={8}>
                        <TextField
                            name="civilId"
                            label={t('Dialog.UpdateGeneralInformation.CivilId')}
                            disabled
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={8}>
                        <EnumSelectField
                            name="sex"
                            label={t('Dialog.UpdateGeneralInformation.Sex')}
                            disabled={
                                Boolean(viewModel?.item?.sex) ||
                                isSetCustomerList
                            }
                            enumName="SEX"
                        />
                    </Col>
                    <Col sm={8}>
                        <EnumSelectField
                            name="maritalStatus"
                            label={t(
                                'Dialog.UpdateGeneralInformation.MaritalStatus',
                            )}
                            disabled={
                                Boolean(viewModel?.item?.maritalStatus) ||
                                isSetCustomerList
                            }
                            enumName="MARITAL_STATUS"
                        />
                    </Col>
                    <Col sm={8}>
                        <EnumSelectField
                            name="citizenship"
                            label={t(
                                'Dialog.UpdateGeneralInformation.Citizenship',
                            )}
                            disabled={
                                Boolean(viewModel?.item?.citizenship) ||
                                isSetCustomerList
                            }
                            enumName="CITIZENSHIP"
                        />
                    </Col>
                    <Col sm={8}>
                        <EnumSelectField
                            name="customerSegment"
                            label={t(
                                'Dialog.UpdateGeneralInformation.CustomerSegment',
                            )}
                            disabled={
                                Boolean(viewModel?.item?.customerSegment) ||
                                isSetCustomerList
                            }
                            enumName="CUSTOMER_SEGMENT"
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const UpdateGeneralInformationDialog = addModalRenderer(
    UpdateGeneralInformationDialogFn,
);

const getInitialValues = (viewModel?: IndividualDetailsViewModelClass) => {
    const initialValues = viewModel?.item || {};

    return {
        ...initialValues,
        idCardImage: initialValues.idCardImage?.presignedUrl,
        selfieImage: initialValues.selfieImage?.presignedUrl,
        signatureImage: initialValues.signatureImage?.presignedUrl,
        registrationNumber: initialValues.idDocumentRegistrationNumber,
        birthDate: initialValues.birthDate && moment(initialValues.birthDate),
    };
};

const StyledCol = styled(Col)`
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
`;

const StyledError = styled(ErrorField)`
    margin-bottom: 10px;
`;
