import { Button, Col, Row, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N15Task } from 'src/generated-api-client';
import { N15ChooseDmtUsersTaskApproveVariables } from 'src/pages/TaskDetailsPage/N15ChooseDmtUsersTask/N15ChooseDmtUsersTaskApproveVariables';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';

export const N15ChooseDmtUsersTask = observer(
    ({ viewModel }: TaskProps<N15Task>) => {
        const task = viewModel.currentTask || {};

        return (
            <TaskForm
                fields={viewModel.submitTaskErrorFields}
                onFinish={async (values) => {
                    await viewModel.submitTask(values);
                    if (!viewModel.submitTaskLoaderHasError) {
                        Router.navigate(RoutePaths.tasks);
                    }
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <N15ChooseDmtUsersTaskApproveVariables task={task} />
                    </Col>

                    <Col span={24}>
                        <Space size={20}>
                            <Button
                                type={
                                    viewModel.isCurrentTaskEqualWorkingTask
                                        ? 'primary'
                                        : 'default'
                                }
                                htmlType="submit"
                            >
                                {t('Task.Form.N15Task.Btn.Approve')}
                            </Button>
                            <Button
                                onClick={() => {
                                    Router.navigate(RoutePaths.tasks);
                                }}
                            >
                                {t('Task.Form.Btn.Back')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </TaskForm>
        );
    },
);
