import { Spinner } from 'src/components/Spinner/Spinner';
import styled from 'styled-components';

export const SplashScreen = () => {
    return (
        <StyledAppSplashScreen>
            <StyledWrapper>
                <StyledLogo>Tavan Bogd</StyledLogo>{' '}
                <StyledTradeMark>®</StyledTradeMark>
            </StyledWrapper>
            <Spinner />
        </StyledAppSplashScreen>
    );
};

const StyledAppSplashScreen = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledLogo = styled.span`
    font-weight: bold;
    font-size: 32px;
`;

const StyledWrapper = styled.div`
    color: #0d0f1c;
    display: flex;
    margin-bottom: 30px;

    &:hover {
        text-decoration: none;
        color: #0d0f1c;
    }
`;
const StyledTradeMark = styled.span`
    transform: translate(3px, -3px);
`;
