/* eslint-disable no-underscore-dangle */
import { action, computed, observable } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { AutoSessionCompleteViewModel } from 'src/components/AutoSessionComplete/AutoSessionCompleteViewModel';
import { IAuthStore } from 'src/stores/AuthStore/AuthStore.types';
import { LocalAuthStoreClass } from 'src/stores/AuthStore/LocalAuthStore';

export class AppAuthStoreClass implements IAuthStore {
    @observable
    private _authStoreImplementation: IAuthStore;

    constructor() {
        this._authStoreImplementation = new LocalAuthStoreClass();
    }

    @action
    authenticate(): Promise<void> {
        return this._authStoreImplementation.authenticate();
    }

    @action
    logout(): Promise<void> {
        AutoSessionCompleteViewModel.reset();

        return this._authStoreImplementation.logout();
    }

    @action
    changePassword(): Promise<void> {
        return this._authStoreImplementation.changePassword();
    }

    @action
    resetPassword(): Promise<void> {
        return this._authStoreImplementation.resetPassword();
    }

    @action
    init?(): Promise<void> {
        return this._authStoreImplementation.init?.() || Promise.resolve();
    }

    @action
    refreshTokenIfNeed?(): Promise<void> {
        return (
            this._authStoreImplementation.refreshTokenIfNeed?.() ||
            Promise.resolve()
        );
    }

    @computed
    get authenticated(): boolean {
        return this._authStoreImplementation.authenticated;
    }

    @computed
    get permissions(): string[] {
        return this._authStoreImplementation.permissions;
    }

    @computed
    get token(): string | undefined {
        return this._authStoreImplementation.token;
    }

    @computed
    get isLoading(): boolean | undefined {
        return this._authStoreImplementation.isLoading;
    }

    @action
    async resetAuthStoreImplementation(authStore: IAuthStore) {
        this._authStoreImplementation = authStore;
        await this._authStoreImplementation.init?.();
    }

    @computed get authStoreImplementation() {
        return this._authStoreImplementation;
    }
}
