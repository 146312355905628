import { Typography } from 'antd';
import { t } from 'i18next';
import { Modal } from 'src/components/Modal/Modal';
import { CollateralCoOwnerStore } from 'src/stores/CollateralCoOwnerStore/CollateralCoOwnerStore';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type RemoveCollateralOwnershipInfoDialogFnProps = ModalRendererProps<{
    collateralId: number;
    insuranceId: number;
}>;

const RemoveCollateralOwnershipInfoDialogFn = ({
    collateralId,
    insuranceId,
    ...props
}: RemoveCollateralOwnershipInfoDialogFnProps) => {
    const store = CollateralCoOwnerStore.getInstance(Number(collateralId));

    return (
        <Modal
            title={t('Dialog.RemoveCollateralOwnershipInfo.Title')}
            isLoading={store.updateItemLoader.isLoading}
            onFinish={async () => {
                await store.remove(insuranceId);
                if (store.removeItemLoader.hasError) {
                    return Promise.reject();
                }
                if (!store.removeItemLoader.hasError) {
                    CollateralsStore.loadItem(collateralId);
                }
            }}
            {...props}
        >
            <StyledText>
                {t('Dialog.RemoveCollateralOwnershipInfo.Text')}
            </StyledText>
        </Modal>
    );
};

export const RemoveCollateralOwnershipInfoDialog = addModalRenderer(
    RemoveCollateralOwnershipInfoDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 16px;
    color: #4d6082;
`;
