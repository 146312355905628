import { action } from 'mobx';
import {
    CoBorrowerSetStatus,
    N13Task,
    NewApplicationCoBorrower,
} from 'src/generated-api-client';
import { TaskViewModelClass } from 'src/pages/TaskViewPage/TaskViewModel';
import { applicationsApi, coBorrowersApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export class N13TaskViewModelClass extends TaskViewModelClass<N13Task> {
    constructor(private id: string) {
        super(id);
    }

    createCoBorrowerLoader = new AsyncOperationWithStatus(
        (data: NewApplicationCoBorrower) => {
            return RequestHelper.unwrapFromAxiosPromise(
                coBorrowersApi.createCoBorrower({
                    createCoBorrowerRequest: data,
                }),
            );
        },
    );

    @action
    async createCoBorrower(data: NewApplicationCoBorrower) {
        await this.createCoBorrowerLoader.call(data);
    }

    updateCoBorrowerLoader = new AsyncOperationWithStatus(
        (id: string | number, data: CoBorrowerSetStatus) => {
            return RequestHelper.unwrapFromAxiosPromise(
                coBorrowersApi.updateCoBorrower({
                    id: Number(id),
                    updateCoBorrowerRequest: data,
                }),
            );
        },
    );

    @action
    async updateCoBorrower(id: string | number, data: CoBorrowerSetStatus) {
        await this.updateCoBorrowerLoader.call(id, data);
    }

    addCollateralLoader = new AsyncOperationWithStatus(
        (applicationId: string | number, collateralId: string | number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.createApplicationCollateralEvaluation({
                    applicationId: Number(applicationId),
                    collateralId: Number(collateralId),
                }),
            );
        },
    );

    @action
    async addCollateral(
        applicationId: string | number,
        collateralId: string | number,
    ) {
        await this.addCollateralLoader.call(applicationId, collateralId);
    }

    removeCollateralLoader = new AsyncOperationWithStatus(
        (applicationId: string | number, collateralId: string | number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.deleteApplicationCollateralEvaluation({
                    applicationId: Number(applicationId),
                    collateralId: Number(collateralId),
                }),
            );
        },
    );

    @action
    async removeCollateral(
        applicationId: string | number,
        collateralId: string | number,
    ) {
        await this.removeCollateralLoader.call(applicationId, collateralId);
    }
}

export const N13TaskTaskViewModel = new EntityProvider(N13TaskViewModelClass);
