/* eslint-disable max-lines */
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    IndividualCoBorrowerListEntry,
    PayonLoan,
} from 'src/generated-api-client';
import { CoBorrowersStatus } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/CoBorrowersStatus';
import { CoBorrowerActions } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/IndividualCoBorrowerActions';
import { IndividualCoBorrowerExternalActions } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/IndividualCoBorrowerExternalActions';
import { UrlHelper } from 'src/utils/UrlHelper';

export type CoBorrowersTabProps = { loan?: PayonLoan };

export const CoBorrowersTab = observer(({ loan }: CoBorrowersTabProps) => {
    const coBorrowers = loan?.individualCoBorrowers;

    return (
        <Card
            title={t('Page.PayonLoanDetails.Tab.CoBorrowers.Title')}
            extra={<IndividualCoBorrowerExternalActions loan={loan} />}
        >
            <ListView dataSource={coBorrowers} columns={getColumns(loan)} />
        </Card>
    );
});

const getColumns = (
    loan?: PayonLoan,
): ColumnsType<IndividualCoBorrowerListEntry> => {
    return [
        {
            key: 'coBorrowerId',
            dataIndex: 'coBorrowerId',
            title: (
                <Trans i18nKey="Page.PayonLoanDetails.Tab.CoBorrowers.List.Id" />
            ),
            render(value) {
                return (
                    <Link
                        to={UrlHelper.getTargetUrl(
                            RoutePaths.individualDetails,
                            {
                                individualId: value,
                            },
                        )}
                    >
                        {value}
                    </Link>
                );
            },
        },
        {
            key: 'lastName',
            dataIndex: 'lastName',
            title: (
                <Trans i18nKey="Page.PayonLoanDetails.Tab.CoBorrowers.List.LastName" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'firstName',
            dataIndex: 'firstName',
            title: (
                <Trans i18nKey="Page.PayonLoanDetails.Tab.CoBorrowers.List.FirstName" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'registrationNumber',
            dataIndex: 'registrationNumber',
            title: (
                <Trans i18nKey="Page.PayonLoanDetails.Tab.CoBorrowers.List.RegistrationNumber" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'relationshipType',
            dataIndex: 'relationshipType',
            title: (
                <Trans i18nKey="Page.PayonLoanDetails.Tab.CoBorrowers.List.RelationshipType" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: (
                <Trans i18nKey="Page.PayonLoanDetails.Tab.CoBorrowers.List.PhoneNumber" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'kycLevel',
            dataIndex: 'kycLevel',
            title: (
                <Trans i18nKey="Page.PayonLoanDetails.Tab.CoBorrowers.List.kycLevel" />
            ),
            render: (value) => {
                return (
                    <DataView
                        value={value}
                        hideLabel
                        format={(value) => (
                            <EnumView
                                enumName="CUSTOMER_KYC_LEVEL"
                                value={value}
                            />
                        )}
                    />
                );
            },
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: (
                <Trans i18nKey="Page.PayonLoanDetails.Tab.CoBorrowers.List.Status" />
            ),
            render(value) {
                return <CoBorrowersStatus status={value} />;
            },
        },
        {
            key: 'actions',
            render: (_, record) => {
                return <CoBorrowerActions coBorrower={record} loan={loan} />;
            },
        },
    ];
};
