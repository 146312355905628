import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { CurrentWorkingTaskMiniWindow } from 'src/components/CurrentWorkingTaskMiniWindow/CurrentWorkingTaskMiniWindow';
import { LanguageSwitcher } from 'src/components/LanguageSwitcher/LanguageSwitcher';
import { UserMenu } from 'src/components/UserMenu';
import { CssVariables } from 'src/styles/CssVariables';
import styled from 'styled-components';

export const Header = observer(() => {
    useTranslation();

    return (
        <StyledHeader>
            <StyledAppHeader>
                <Spacer>
                    <CurrentWorkingTaskMiniWindow />
                </Spacer>
                <LanguageSwitcher />
                <UserMenu />
            </StyledAppHeader>
        </StyledHeader>
    );
});

const Spacer = styled.div`
    flex: 1;
`;

const StyledAppHeader = styled.div`
    display: flex;
    align-items: center;
`;

const StyledHeader = styled(Layout.Header)`
    padding: 10px 20px;
    height: fit-content;
    background-color: #f7f8fc;
    line-height: ${CssVariables.headerLineHeight};
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    &:after {
        content: ' ';
        display: block;
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 5px;
        width: 100%;
        background: linear-gradient(180deg, #f7f8fc, transparent);
    }
`;
