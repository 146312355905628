/* eslint-disable max-lines, import/no-cycle */

import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { N111Task } from 'src/generated-api-client';
import { TaskViewModelClass } from 'src/pages/TaskViewPage/TaskViewModel';

type RelationshipManagerConditionsCardProps = {
    viewModel: TaskViewModelClass;
};

export const RelationshipManagerConditionsCard = observer(
    ({ viewModel }: RelationshipManagerConditionsCardProps) => {
        const task = (viewModel.currentTask as N111Task) || {};
        const { relationshipManagerConditions, commission, product } = task;

        return (
            <Card
                title={t(
                    'Task.Form.N111TaskUploadSignedContract.ApprovedConditions',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={relationshipManagerConditions?.createdAt}
                            label={t(
                                'Task.Form.N111TaskUploadSignedContract.ApprovedDate',
                            )}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={relationshipManagerConditions?.amount}
                            label={t(
                                'Task.Form.N111TaskUploadSignedContract.Amount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                relationshipManagerConditions?.monthlyInterestRate
                            }
                            label={t(
                                'Task.Form.N111TaskUploadSignedContract.InterestRate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={relationshipManagerConditions?.term}
                            label={t(
                                'Task.Form.N111TaskUploadSignedContract.Term',
                            )}
                            format="number-int"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={
                                relationshipManagerConditions?.firstRepaymentDate
                            }
                            label={t(
                                'Task.Form.N111TaskUploadSignedContract.FirstRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                relationshipManagerConditions?.secondRepaymentDate
                            }
                            label={t(
                                'Task.Form.N111TaskUploadSignedContract.SecondRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                relationshipManagerConditions?.repaymentFrequency
                            }
                            label={t(
                                'Task.Form.N111TaskUploadSignedContract.RepaymentFrequency',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="REPAYMENT_FREQUENCY"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={commission}
                            label={t(
                                'Task.Form.N111TaskUploadSignedContract.Commission',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={product?.name}
                            label={t(
                                'Task.Form.N111TaskUploadSignedContract.ProductName',
                            )}
                            format="link-internal"
                            formatterProps={{
                                to: EntityLinks.productsPledged.itemDetails(
                                    product?.id,
                                ),
                            }}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={task?.invoicePreviousAmount}
                            label={t(
                                'Task.Form.N111TaskUploadSignedContract.OldInvoiceAmount',
                                'Old invoice amount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={task?.invoiceAmount}
                            label={t(
                                'Task.Form.N111TaskUploadSignedContract.InvoiceAmount',
                                'Invoice amount',
                            )}
                            format="money"
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
