/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { PayonLoan } from 'src/generated-api-client';
import { LoanStateCalculator } from 'src/pages/LoanDetailsPage/Components/LoanStateCalculator';

export type GeneralInfoTabProps = { loan?: PayonLoan };

export const GeneralInfoTab = observer(({ loan }: GeneralInfoTabProps) => {
    const branchManagerDetailsUrl = EntityLinks.users.itemDetails(
        loan?.branchManager?.id,
    );

    const relationshipManagerDetailsUrl = EntityLinks.users.itemDetails(
        loan?.relationshipManager?.id,
    );

    return (
        <Row gutter={16}>
            <Col xs={24}>
                <Card
                    title={t(
                        'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.Title',
                    )}
                >
                    <Row gutter={16}>
                        <Col xs={6}>
                            <DataView
                                value={loan?.amount}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.Amount',
                                )}
                                format="money"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.interestRate}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.InterestRate',
                                )}
                                format="percent"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.term}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.Term',
                                )}
                                format="number-int"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.repaymentFrequency}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.RepaymentFrequency',
                                )}
                                format={(value) => (
                                    <EnumView
                                        enumName="REPAYMENT_FREQUENCY"
                                        value={value}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={6}>
                            <DataView
                                value={loan?.disbursementDate}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.DisbursementDate',
                                )}
                                format="date-time-seconds"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.firstRepaymentDate}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.FirstRepaymentDate',
                                )}
                                format="date"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.secondRepaymentDate}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.SecondRepaymentDate',
                                )}
                                format="date"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.repaymentAmount}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.RepaymentAmount',
                                )}
                                format="money"
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={6}>
                            <DataView
                                value={loan?.maturityDate}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.MaturityDate',
                                )}
                                format="date"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.fee}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.FeeCommission',
                                )}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.product.penaltyInterestMultiplier}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.PenaltyInterestRate',
                                )}
                                format="percent"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.loanPurpose}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.LoanPurpose',
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={6}>
                            <DataView
                                value={loan?.downPayment}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.DownPayment',
                                )}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.cibSentStatus}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.CibSentStatus',
                                )}
                                format="yes-no"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.cibSentDate}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.CibSentDate',
                                )}
                                format="date-time-seconds"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.exceedAmount}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.LoanInformation.ExceedAmount',
                                )}
                                format="money"
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col xs={24}>
                <Card
                    title={t(
                        'Page.PayonLoanDetails.Tab.GeneralInfo.Origination.Title',
                    )}
                >
                    <Row gutter={16}>
                        <Col xs={6}>
                            <DataView
                                value={loan?.branchName}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Origination.BranchName',
                                )}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.branchManager?.name}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Origination.BranchManager',
                                )}
                                format="link-internal"
                                formatterProps={{ to: branchManagerDetailsUrl }}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.relationshipManager?.name}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Origination.RelationshipManager',
                                )}
                                format="link-internal"
                                formatterProps={{
                                    to: relationshipManagerDetailsUrl,
                                }}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.source}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Origination.Source',
                                )}
                                format={(value) => (
                                    <EnumView
                                        enumName="APPLICATION_SOURCE"
                                        value={value}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={6}>
                            <DataView
                                value={loan?.branchCode}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Origination.BranchCode',
                                )}
                                format="string"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={loan?.incomeAmount}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Origination.IncomeAmount',
                                )}
                                format="money"
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col xs={12}>
                <Card
                    title={t(
                        'Page.PayonLoanDetails.Tab.GeneralInfo.Payment.Title',
                    )}
                >
                    <Row gutter={16}>
                        <Col xs={12}>
                            <DataView
                                value={loan?.lastPaymentDate}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Payment.LastPaymentDate',
                                )}
                                format="date"
                            />
                        </Col>
                        <Col xs={12}>
                            <DataView
                                value={loan?.lastPaymentAmount}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Payment.LastPaymentAmount',
                                )}
                                format="money"
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={12}>
                            <DataView
                                value={loan?.overdueAdvancePayment}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Payment.OverdueAdvancePayment',
                                )}
                                format="money"
                            />
                        </Col>
                        <Col xs={12}>
                            <DataView
                                value={loan?.daysOverdue}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Payment.OverdueDay',
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={12}>
                            <DataView
                                value={loan?.nextPaymentDate}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Payment.NextPaymentDate',
                                )}
                                format="date"
                            />
                        </Col>
                        <Col xs={12}>
                            <DataView
                                value={loan?.nextPaymentAmount}
                                label={t(
                                    'Page.PayonLoanDetails.Tab.GeneralInfo.Payment.NextPaymentAmount',
                                )}
                                format="money"
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col xs={12}>
                <LoanStateCalculator loanId={loan?.id} />
            </Col>
        </Row>
    );
});
