import { Button } from 'antd';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { NotFound } from 'src/components/NotFound/NotFound';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useIndividualId } from 'src/hooks/useIndividualId';
import { useViewModel } from 'src/hooks/useViewModel';
import { IndividualDetailsBrief } from 'src/pages/IndividualDetailsPage/IndividualDetailsBrief';
import { IndividualDetailsViewModel } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { IndividualCommentsTab } from 'src/pages/IndividualDetailsPage/Tabs/IndividualCommentsTab';
import { IndividualDecisionsTab } from 'src/pages/IndividualDetailsPage/Tabs/IndividualDecisionsTab';
import { IndividualDetailsTab } from 'src/pages/IndividualDetailsPage/Tabs/IndividualDetailsTab';
import { IndividualLoanLimitTab } from 'src/pages/IndividualDetailsPage/Tabs/IndividualLoanLimitTab';
import { IndividualScoringResultTab } from 'src/pages/IndividualDetailsPage/Tabs/IndividualScoringResultTab';
import { IndividualOtherInformationTab } from 'src/pages/IndividualDetailsPage/Tabs/OtherInformationTab';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';

const store = IndividualsStore;

export const IndividualDetailsPage = observer(() => {
    const { individualId } = useIndividualId();
    const viewModel = IndividualDetailsViewModel.getInstance(
        Number(individualId),
    );

    const individual = viewModel.item;

    useViewModel(viewModel, [individualId]);

    const hasData = Boolean(viewModel.item);

    const error = useMemo(() => {
        const showNotFound =
            !_.isInteger(Number(individualId)) ||
            store.itemLoader.errorData?.status === 404;
        if (showNotFound) {
            return (
                <NotFound
                    title={t('Page.IndividualDetails.Error.NotFound.Title')}
                    description={t(
                        'Page.IndividualDetails.Error.NotFound.Description',
                        { individualId },
                    )}
                >
                    <Button
                        onClick={() => {
                            Router.navigate(RoutePaths.individuals);
                        }}
                    >
                        {t('Page.IndividualDetails.GoToList')}
                    </Button>
                </NotFound>
            );
        }

        return null;
    }, [individualId, store.itemLoader.errorData]);

    const tabs: TabDefinition[] = hasData
        ? [
              {
                  title: t('Page.IndividualDetails.Tabs.Details'),
                  tabKey: 'details',
                  Component: <IndividualDetailsTab viewModel={viewModel} />,
                  visible: AppUser.hasPermissions(
                      UserPermission.IndividualReadGeneralDetails,
                  ),
              },
              {
                  title: t('Page.IndividualDetails.Tabs.Decisions'),
                  tabKey: 'decisions',
                  Component: <IndividualDecisionsTab />,
                  visible: AppUser.hasPermissions(
                      UserPermission.IndividualReadDecisions,
                  ),
              },
              {
                  title: t('Page.IndividualDetails.Tabs.LoanLimit'),
                  tabKey: 'loan-limit',
                  Component: <IndividualLoanLimitTab />,
              },
              {
                  title: t('Page.IndividualDetails.Tabs.ScoringResult'),
                  tabKey: 'scoring-result',
                  Component: (
                      <IndividualScoringResultTab individual={individual} />
                  ),
                  visible: AppUser.hasPermissions(
                      UserPermission.IndividualReadCreditScoring,
                  ),
              },
              {
                  title: t(
                      'Page.IndividualDetails.Tabs.OtherInformation',
                      'Other Information',
                  ),
                  tabKey: 'other-information',
                  visible: AppUser.hasPermissions(
                      UserPermission.IndividualReadOtherInfo,
                  ),
                  Component: (
                      <IndividualOtherInformationTab viewModel={viewModel} />
                  ),
              },

              {
                  title: t('Page.IndividualDetails.Tabs.Comments', 'Comments'),
                  tabKey: 'comments',
                  Component: (
                      <IndividualCommentsTab
                          roomId={viewModel?.item?.commentRoomId}
                      />
                  ),
              },
          ]
        : [];

    return (
        <BasePage
            isLoading={IndividualsStore.isItemLoading}
            header={<IndividualDetailsBrief viewModel={viewModel} />}
            showOnlyLoading
            error={error}
        >
            <Tabs tabs={tabs} />
        </BasePage>
    );
});
