import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import {
    IdNameEntry,
    LoanCollateralListEntry,
    PledgedLoan,
} from 'src/generated-api-client';
import { CollateralsActions } from 'src/pages/LoanDetailsPage/Components/Collaterals/CollateralsActions';
import { CollateralsExternalActions } from 'src/pages/LoanDetailsPage/Components/Collaterals/CollateralsExternalActions';

export type CollateralsTabProps = { loan?: PledgedLoan };

export const CollateralsTab = observer(({ loan }: CollateralsTabProps) => {
    const collaterals = loan?.collaterals;

    return (
        <Card
            title={t('Page.PledgedLoanDetails.Tab.Collaterals.Title')}
            extra={<CollateralsExternalActions loan={loan} />}
        >
            <ListView dataSource={collaterals} columns={getColumns(loan)} />
        </Card>
    );
});

export const getColumns = (
    loan?: PledgedLoan,
): ColumnsType<LoanCollateralListEntry> => {
    return [
        {
            key: 'id',
            dataIndex: 'id',
            title: (
                <Trans i18nKey="Page.PledgedLoanDetails.Tab.Collaterals.List.Id" />
            ),
            render(value) {
                return (
                    <DataView
                        hideLabel
                        value={value}
                        format="link-internal"
                        formatterProps={{
                            to: EntityLinks.collaterals.itemDetails(value),
                        }}
                    />
                );
            },
        },
        {
            key: 'subType',
            dataIndex: 'subType',
            title: (
                <Trans i18nKey="Page.PledgedLoanDetails.Tab.Collaterals.List.SubType" />
            ),
            render: (value) => {
                return (
                    <DataView
                        value={value}
                        hideLabel
                        format={(value) => (
                            <EnumView
                                enumName="COLLATERAL_SUB_TYPE"
                                value={value}
                            />
                        )}
                    />
                );
            },
        },
        {
            key: 'sizeMark',
            dataIndex: 'sizeMark',
            title: (
                <Trans i18nKey="Page.PledgedLoanDetails.Tab.Collaterals.List.SizeMark" />
            ),
            render(value) {
                return <DataView hideLabel value={value} format="string" />;
            },
        },
        {
            key: 'registrationNumber',
            dataIndex: 'registrationNumber',
            title: (
                <Trans i18nKey="Page.PledgedLoanDetails.Tab.Collaterals.List.RegistrationNumber" />
            ),
            render(value) {
                return <DataView hideLabel value={value} format="string" />;
            },
        },
        {
            key: 'marketValue',
            dataIndex: 'marketValue',
            title: (
                <Trans i18nKey="Page.PledgedLoanDetails.Tab.Collaterals.List.MarketValue" />
            ),
            render(value) {
                return <DataView hideLabel value={value} format="money" />;
            },
        },
        {
            key: 'ciaValue',
            dataIndex: 'ciaValue',
            title: (
                <Trans i18nKey="Page.PledgedLoanDetails.Tab.Collaterals.List.CiaValue" />
            ),
            render(value) {
                return <DataView hideLabel value={value} format="money" />;
            },
        },
        {
            key: 'importedDate',
            dataIndex: 'importedDate',
            title: (
                <Trans i18nKey="Page.PledgedLoanDetails.Tab.Collaterals.List.ImportedDate" />
            ),
            render: (value) => {
                return <DataView hideLabel value={value} format="date" />;
            },
        },
        {
            key: 'owner',
            dataIndex: 'owner',
            title: (
                <Trans i18nKey="Page.PledgedLoanDetails.Tab.Collaterals.List.Owner" />
            ),
            render: (value: IdNameEntry) => {
                return (
                    <DataView
                        hideLabel
                        value={value?.name}
                        format="link-internal"
                        formatterProps={{
                            to: EntityLinks.individuals.itemDetails(value?.id),
                        }}
                    />
                );
            },
        },
        {
            key: 'collateralAgreementType',
            dataIndex: 'collateralAgreementType',
            title: (
                <Trans i18nKey="Page.PledgedLoanDetails.Tab.Collaterals.List.CollateralAgreementType" />
            ),
            render: (value) => {
                return <DataView hideLabel value={value} format="string" />;
            },
        },
        {
            key: 'actions',
            render: (_, record) => {
                return <CollateralsActions loan={loan} collateral={record} />;
            },
        },
    ];
};
