import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N19Task, TaskName } from 'src/generated-api-client';
import { TaskAssigneeSelect } from 'src/pages/TaskDetailsPage/Components/TaskAssigneeSelect';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { TaskViewModelClass } from 'src/pages/TaskViewPage/TaskViewModel';
import styled from 'styled-components';

type TaskProps = {
    viewModel: TaskViewModelClass;
};

export const N19ChooseRmUsersTask = observer(({ viewModel }: TaskProps) => {
    const task = (viewModel.currentTask as N19Task) || {};
    const { loanApplication, collaterals } = task;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <StyledText>
                    <Trans i18nKey="Page.N19ChooseRmUsersTask.Descriptions" />
                </StyledText>
            </Col>
            <Col xs={24}>
                <TaskLinkView
                    loanApplication={loanApplication}
                    collaterals={collaterals}
                />
            </Col>

            <Col span={24}>
                <TaskForm
                    fields={viewModel.submitTaskErrorFields}
                    onFinish={async (values) => {
                        await viewModel.submitTask(values);
                        if (!viewModel.submitTaskLoaderHasError) {
                            Router.navigate(RoutePaths.tasks);
                        }
                    }}
                >
                    <HiddenField
                        name="type"
                        initialValue={TaskName.N1_9_CHOOSE_RM}
                    />
                    <Card>
                        <Row gutter={16}>
                            <Col span={12}>
                                <TaskAssigneeSelect
                                    label={t(
                                        'Task.Form.N19ChooseRmUsersTask.AvailableMakers',
                                    )}
                                    assignees={task?.users}
                                />
                            </Col>

                            <Col span={24}>
                                <Space size={20}>
                                    <Button
                                        type={
                                            viewModel.isCurrentTaskEqualWorkingTask
                                                ? 'primary'
                                                : 'default'
                                        }
                                        htmlType="submit"
                                    >
                                        {t('Task.Form.N19Task.Btn.Approve')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            Router.navigate(RoutePaths.tasks);
                                        }}
                                    >
                                        {t('Task.Form.Btn.Back')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                </TaskForm>
            </Col>
        </Row>
    );
});

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
