import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { BasePage } from 'src/components/BasePage/BasePage';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { SettingsCustomerVerificationAgeTab } from 'src/pages/Settings/CustomerVerificationPage/Tabs/SettingsCustomerVerificationAgeTab';
import { SettingsCustomerVerificationCibTab } from 'src/pages/Settings/CustomerVerificationPage/Tabs/SettingsCustomerVerificationCibTab';
import { SettingsCustomerVerificationCreditScoringTab } from 'src/pages/Settings/CustomerVerificationPage/Tabs/SettingsCustomerVerificationCreditScoringTab';
import { SettingsCustomerVerificationSalaryIncomeTab } from 'src/pages/Settings/CustomerVerificationPage/Tabs/SettingsCustomerVerificationSalaryIncomeTab';

export const SettingsCustomerVerificationPage = observer(() => {
    const tabs: TabDefinition[] = [
        {
            title: t(
                'Page.SettingsCustomerVerification.Tabs.SalaryIncome',
                'Salary income',
            ),
            tabKey: 'salary-income',
            Component: <SettingsCustomerVerificationSalaryIncomeTab />,
        },
        {
            title: t('Page.SettingsCustomerVerification.Tabs.CIB', 'CIB'),
            tabKey: 'cib',
            Component: <SettingsCustomerVerificationCibTab />,
        },
        {
            title: t('Page.SettingsCustomerVerification.Tabs.Age', 'Age'),
            tabKey: 'age',
            Component: <SettingsCustomerVerificationAgeTab />,
        },
        {
            title: t(
                'Page.SettingsCustomerVerification.Tabs.CreditScoring',
                'Credit scoring',
            ),
            tabKey: 'credit-scoring',
            Component: <SettingsCustomerVerificationCreditScoringTab />,
        },
    ];

    return (
        <BasePage
            title={t(
                'Page.SettingsCustomerVerification.Title',
                'Customer verification settings',
            )}
        >
            <Tabs tabs={tabs} />
        </BasePage>
    );
});
