/* eslint-disable max-lines */
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { LegalRepresentativeListEntry } from 'src/generated-api-client';
import { RepresentativeActions } from 'src/pages/LegalEntityDetailsPage/Components/RepresentativeActions';
import { RepresentativesExternalActions } from 'src/pages/LegalEntityDetailsPage/Components/RepresentativesExternalActions';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { UrlHelper } from 'src/utils/UrlHelper';

export type LegalEntityRepresentativesTabProps = {
    viewModel: LegalEntityDetailsViewModelClass;
};

export const LegalEntityRepresentativesTab = observer(
    ({ viewModel }: LegalEntityRepresentativesTabProps) => {
        return (
            <Card
                title={t('Page.LegalEntityDetails.Representatives.Title')}
                extra={<RepresentativesExternalActions viewModel={viewModel} />}
            >
                <ListView
                    dataSource={viewModel?.item?.representatives}
                    columns={getColumns(viewModel)}
                />
            </Card>
        );
    },
);

const getColumns = (
    viewModel: LegalEntityDetailsViewModelClass,
): ColumnsType<LegalRepresentativeListEntry> => {
    return [
        {
            key: 'individualId',
            dataIndex: 'individualId',
            title: (
                <Trans i18nKey="Page.LegalEntityDetails.Representatives.List.IndividualId" />
            ),
            render: (individualId: string) => {
                const targetUrl = UrlHelper.getTargetUrl(
                    RoutePaths.individualDetails,
                    {
                        individualId,
                    },
                );

                return <Link to={targetUrl}>{individualId}</Link>;
            },
        },
        {
            key: 'relationship',
            dataIndex: 'relationship',
            title: (
                <Trans i18nKey="Page.LegalEntityDetails.Representatives.List.ReferenceType" />
            ),
            render: (value) => {
                return (
                    <DataView
                        value={value}
                        format={(value) => (
                            <EnumView
                                enumName="REFERENCE_RELATIONSHIP"
                                value={value}
                            />
                        )}
                        hideLabel
                    />
                );
            },
        },
        {
            key: 'lastName',
            dataIndex: 'lastName',
            title: (
                <Trans i18nKey="Page.LegalEntityDetails.Representatives.List.LastName" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'firstName',
            dataIndex: 'firstName',
            title: (
                <Trans i18nKey="Page.LegalEntityDetails.Representatives.List.FirstName" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'familyName',
            dataIndex: 'familyName',
            title: (
                <Trans i18nKey="Page.LegalEntityDetails.Representatives.List.FamilyName" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'registrationNumber',
            dataIndex: 'registrationNumber',
            title: (
                <Trans i18nKey="Page.LegalEntityDetails.Representatives.List.RegistrationNumber" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'civilId',
            dataIndex: 'civilId',
            title: (
                <Trans i18nKey="Page.LegalEntityDetails.Representatives.List.CivilId" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'percentageOfShare',
            dataIndex: 'percentageOfShare',
            title: (
                <Trans i18nKey="Page.LegalEntityDetails.Representatives.List.PercentageOfShare" />
            ),
            render: (value) => {
                return <DataView value={value} format="percent" hideLabel />;
            },
        },

        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: (
                <Trans i18nKey="Page.LegalEntityDetails.Representatives.List.PhoneNumber" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'pep',
            dataIndex: 'pep',
            title: (
                <Trans i18nKey="Page.LegalEntityDetails.Representatives.List.Pep" />
            ),
            render(value) {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'citizenship',
            dataIndex: 'citizenship',
            title: (
                <Trans i18nKey="Page.LegalEntityDetails.Representatives.List.Citizenship" />
            ),
            render: (value) => {
                return (
                    <DataView
                        value={value}
                        hideLabel
                        format={(value) => (
                            <EnumView enumName="CITIZENSHIP" value={value} />
                        )}
                    />
                );
            },
        },
        {
            key: 'actions',
            render: (_, record) => {
                return (
                    <RepresentativeActions
                        representative={record}
                        viewModel={viewModel}
                    />
                );
            },
        },
    ];
};
