import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { ProductType } from 'src/generated-api-client';
import { processNameOptions } from 'src/pages/TasksPage/Components/processNameOptions.config';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { ProductLinesStore } from 'src/stores/ProductLinesStore/ProductLinesStore';
import { FormHelper } from 'src/utils/FormHelper';

export const TasksFilterForm = observer(() => {
    const canSeeUnassignedTasks = AppUser.hasPermissions(
        UserPermission.TaskReadUnassignedList,
    );

    useEffect(() => {
        ProductLinesStore.filterCriteria.setFilter({
            productTypes: [ProductType.PLEDGED],
        });
        ProductLinesStore.loadList();

        return () => {
            ProductLinesStore.filterCriteria.resetFilter();
            ProductLinesStore.listLoader.reset();
        };
    }, []);

    return (
        <>
            {canSeeUnassignedTasks && (
                <SelectField
                    name="assignedToMe"
                    label={t('Page.Tasks.Filter.AssignedToMe')}
                    options={FormHelper.objAsOptions(
                        {
                            true: true,
                            false: false,
                        },
                        'Primitive.Boolean.',
                    )}
                    rules={[ValidationRules.required]}
                />
            )}
            <TextField
                name="loanApplicationId"
                label={t('Page.Tasks.Filter.LoanApplicationId')}
            />
            <SelectField
                mode="multiple"
                name="processNames"
                options={processNameOptions()}
                label={t('Page.Tasks.Filter.processName')}
            />
            <TextField
                name="individualName"
                label={t('Page.Tasks.Filter.CustomerName')}
            />
            <EnumSelectField
                mode="multiple"
                name="taskStatuses"
                label={t('Page.Tasks.Filter.Status')}
                enumName="TASK_STATUS"
            />
            <TextField
                name="assigneeName"
                label={t('Page.Tasks.Filter.ClaimedBy')}
            />
            <DatePickerField
                name="createdAt"
                label={t('Page.Tasks.Filter.CreatedAt')}
            />
            <DatePickerField
                name="completedAt"
                label={t('Page.Tasks.Filter.CompletedAt')}
            />
            <NumberField
                name="requestedAmount"
                label={t('Page.Tasks.Filter.RequestedAmount')}
            />
            <SelectField
                name="productLineId"
                label={t('Page.Tasks.Filter.Product')}
                options={ProductLinesStore.currentListAsOptions}
            />
        </>
    );
});
