import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { InstallmentProduct } from 'src/generated-api-client';

export type InstallmentProductDetailsTab = {
    productInstallment?: InstallmentProduct;
};
export const InstallmentProductDetailsTab = observer(
    ({ productInstallment }: InstallmentProductDetailsTab) => {
        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.InstallmentProductDetails.Details.Title',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={productInstallment?.productLineId}
                                    label={t(
                                        'Page.InstallmentProductDetails.ExternalId',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productInstallment?.name}
                                    label={t(
                                        'Page.InstallmentProductDetails.Name',
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productInstallment?.status}
                                    label={t(
                                        'Page.InstallmentProductDetails.Status',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="PRODUCT_STATUS"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        productInstallment?.repaymentAllocationRule
                                    }
                                    label={t(
                                        'Page.InstallmentProductDetails.RepaymentAllocationRule',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="REPAYMENT_ALLOCATION_RULE"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={productInstallment?.interestRate}
                                    label={t(
                                        'Page.InstallmentProductDetails.InterestRate',
                                    )}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        productInstallment?.penaltyInterestMultiplier
                                    }
                                    label={t(
                                        'Page.InstallmentProductDetails.PenaltyInterestMultiplier',
                                    )}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        productInstallment?.penaltyInterestAccrualStartDay
                                    }
                                    label={t(
                                        'Page.InstallmentProductDetails.PenaltyInterestAccrualStartDay',
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        productInstallment?.penaltyInterestAccrualMethod
                                    }
                                    label={t(
                                        'Page.InstallmentProductDetails.PenaltyInterestAccrualMethod',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="PENALTY_INTEREST_ACCRUAL_METHOD"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={productInstallment?.dti}
                                    label={t(
                                        'Page.InstallmentProductDetails.Dti',
                                    )}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productInstallment?.maxLoanAmount}
                                    label={t(
                                        'Page.InstallmentProductDetails.MaxLoanAmount',
                                    )}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productInstallment?.maxTenor}
                                    label={t(
                                        'Page.InstallmentProductDetails.MaxTenor',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        productInstallment?.repaymentCalculationMethod
                                    }
                                    label={t(
                                        'Page.InstallmentProductDetails.RepaymentCalculationMethod',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="REPAYMENT_CALCULATION_METHOD"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <DataView
                                    value={
                                        productInstallment?.excludedFromOffer
                                    }
                                    label={t(
                                        'Page.InstallmentProductDetails.ExcludedFromOffer',
                                    )}
                                    format="yes-no"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    },
);
