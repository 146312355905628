import { Button, Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from 'src/components/Icons/Icons';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export const RolesHeaderPanel = observer(() => {
    const { t } = useTranslation();
    const canCreate = AppUser.hasPermissions(UserPermission.RoleCreate);

    return (
        <Row gutter={16}>
            <Col flex={1}>
                <Typography.Title>
                    {t('Page.Roles.HeaderTitle', 'Roles')}
                </Typography.Title>
            </Col>
            <Col>
                {canCreate && (
                    <Button
                        icon={<PlusIcon />}
                        type="primary"
                        onClick={() => Router.navigate(RoutePaths.roleCreate)}
                    >
                        {t('Page.Roles.Button.CreateRole', 'Create role')}
                    </Button>
                )}
            </Col>
        </Row>
    );
});
