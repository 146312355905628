import { notification, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DocumentsViewModel } from 'src/components/DocumentsList/DocumentsViewModel';
import { Modal } from 'src/components/Modal/Modal';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import { TasksStore } from 'src/stores/TasksStore/TasksStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type RemoveCollateralDialogProps = ModalRendererProps<{
    collateralId: number;
    viewModel: N13TaskViewModelClass;
}>;

const RemoveCollateralDialogFn = observer(
    ({ viewModel, collateralId, ...props }: RemoveCollateralDialogProps) => {
        const applicationId = viewModel?.currentTask?.loanApplication?.id;

        useEffect(() => {
            return () => {
                viewModel.removeCollateralLoader.reset();
            };
        }, []);

        return (
            <Modal
                isLoading={viewModel.removeCollateralLoader.isLoading}
                title={t('Dialog.RemoveCollateral.Title')}
                onFinish={async () => {
                    await viewModel.removeCollateral(
                        applicationId,
                        collateralId,
                    );

                    if (viewModel.removeCollateralLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel.removeCollateralLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.RemoveCollateral.Message.Success',
                            ),
                        });
                        DocumentsViewModel.getInstance(
                            viewModel.currentTask?.documentRoom?.id,
                        ).load();
                        await TasksStore.itemLoader.reset();
                        viewModel.load();
                    }
                }}
                okText={t('Dialog.RemoveCollateral.OkText', 'Approve')}
                {...props}
            >
                <StyledText>
                    <Trans i18nKey="Dialog.RemoveCollateral.Descriptions" />
                </StyledText>
            </Modal>
        );
    },
);

export const RemoveCollateralDialog = addModalRenderer(
    RemoveCollateralDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
    height: 100px;
`;
