import { notification, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Modal } from 'src/components/Modal/Modal';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type RemoveRepresentativeDialogProps = ModalRendererProps<{
    viewModel: LegalEntityDetailsViewModelClass;
    representativeId: number;
}>;

const RemoveRepresentativeDialogFn = observer(
    ({
        viewModel,
        representativeId,
        ...props
    }: RemoveRepresentativeDialogProps) => {
        useEffect(() => {
            return () => {
                viewModel.removeRepresentativeLoader.reset();
            };
        }, []);

        return (
            <Modal
                isLoading={viewModel.removeRepresentativeLoader.isLoading}
                title={t('Dialog.RemoveRepresentative.Title')}
                onFinish={async () => {
                    await viewModel.removeRepresentative(representativeId);
                    if (viewModel.removeRepresentativeLoader.hasError) {
                        return Promise.reject();
                    }
                    if (!viewModel.removeRepresentativeLoader.hasError) {
                        viewModel?.load();

                        notification.success({
                            message: t(
                                'Dialog.RemoveRepresentative.Message.Success',
                            ),
                        });
                    }
                }}
                okText={t('Dialog.RemoveRepresentative.OkText', 'Approve')}
                {...props}
            >
                <StyledText>
                    <Trans i18nKey="Dialog.RemoveRepresentative.Descriptions" />
                </StyledText>
            </Modal>
        );
    },
);

export const RemoveRepresentativeDialog = addModalRenderer(
    RemoveRepresentativeDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
    height: 100px;
`;
