import { Col, Form, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { N11Task, TaskDecisionType } from 'src/generated-api-client';
import { TaskVariablesProps } from 'src/pages/TaskViewPage/TaskTypes';

export const N11TaskCIATUserApproveVariables = observer(
    ({ task }: TaskVariablesProps<N11Task>) => {
        const collaterals = task?.collaterals || [];

        return (
            <>
                <HiddenField
                    name="type"
                    initialValue={TaskDecisionType.N1_1_APPROVE}
                />

                <Form.List name="collaterals">
                    {(fields) => (
                        <div>
                            {fields?.map((field, index) => {
                                const collateral = collaterals?.[index];
                                const collateralId = collateral?.id;

                                return (
                                    <Card
                                        title={
                                            <Link
                                                to={EntityLinks.collaterals.itemDetails(
                                                    collateralId,
                                                )}
                                            >
                                                {t(
                                                    'Task.Form.N11TaskСIATUser.CollateralId',
                                                    {
                                                        collateralId,
                                                    },
                                                )}
                                            </Link>
                                        }
                                    >
                                        <Row key={field.key} gutter={16}>
                                            <Col span={6}>
                                                <DataView
                                                    value={collateral?.type}
                                                    label={t(
                                                        'Task.Form.N11TaskСIATUser.CollateralType',
                                                    )}
                                                    format={(value) => (
                                                        <EnumView
                                                            enumName="COLLATERAL_TYPE"
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col span={6}>
                                                <DataView
                                                    value={collateral?.subType}
                                                    label={t(
                                                        'Task.Form.N11TaskСIATUser.CollateralSubType',
                                                    )}
                                                    format={(value) => (
                                                        <EnumView
                                                            enumName="COLLATERAL_SUB_TYPE"
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        </Row>

                                        <Row key={field.key} gutter={16}>
                                            <HiddenField
                                                name={[field.name, 'id']}
                                            />

                                            <Col span={12}>
                                                <NumberField
                                                    name={[
                                                        field.name,
                                                        'rmValue',
                                                    ]}
                                                    label={t(
                                                        'Task.Form.N11TaskСIATUser.CIATValue',
                                                    )}
                                                    thousandDivider
                                                    rules={[
                                                        ValidationRules.required,
                                                    ]}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <NumberField
                                                    name={[
                                                        field.name,
                                                        'marketValue',
                                                    ]}
                                                    label={t(
                                                        'Task.Form.N11TaskСIATUser.MarketValue',
                                                    )}
                                                    thousandDivider
                                                    rules={[
                                                        ValidationRules.required,
                                                    ]}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <NumberField
                                                    name={[
                                                        field.name,
                                                        'mutuallyAgreedValuation',
                                                    ]}
                                                    label={t(
                                                        'Task.Form.N11TaskСIATUser.MutuallyAgreedValuation',
                                                    )}
                                                    rules={[
                                                        ValidationRules.required,
                                                    ]}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                );
                            })}
                        </div>
                    )}
                </Form.List>
            </>
        );
    },
);
