/* eslint-disable max-lines */
import { notification } from 'antd';
import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import {
    CustomerJobInformationRequest,
    IndividualContactDetailsUpdate,
    IndividualPaymentAccountUpdate,
    OtherInformationRequest,
    UpdateIndividualGeneralInfoRequest,
    UpdateIndividualReferenceRequest,
} from 'src/generated-api-client';
import { individualsApi } from 'src/services/apiServices';
import { FilesStore } from 'src/stores/FilesStore/FilesStore';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { ViewModel } from 'src/utils/mobx/ViewModel';
import { RequestHelper } from 'src/utils/RequestHelper';
import { UrlHelper } from 'src/utils/UrlHelper';

export class IndividualDetailsViewModelClass extends ViewModel {
    constructor(private individualId: number) {
        super();
    }

    itemLoader = IndividualsStore.itemLoader;

    @computed get item() {
        return IndividualsStore.currentItem;
    }

    @action
    async load() {
        await IndividualsStore.loadItem(this.individualId);
    }

    @action
    async reset() {
        await IndividualsStore.itemLoader.reset();
        await IndividualsStore.customerInformationLoader.reset();
        await IndividualsStore.customerCreditScoringLoader.reset();
        await IndividualsStore.customerMonthlyIncomeLoader.reset();
    }

    updateGeneralInfoLoader = new AsyncOperationWithStatus(
        (data: UpdateIndividualGeneralInfoRequest) => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.updateGeneralInfo({
                    id: this.individualId,
                    updateIndividualGeneralInfoRequest: data,
                }),
            );
        },
    );

    @action
    async updateGeneralInfo(data: UpdateIndividualGeneralInfoRequest) {
        await this.updateGeneralInfoLoader.call(data);
        IndividualsStore.loadItem(this.individualId);
    }

    updateCustomerReferencesLoader = new AsyncOperationWithStatus(
        (data: UpdateIndividualReferenceRequest) => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.updateReferences({
                    id: this.individualId,
                    updateIndividualReferenceRequest: data,
                }),
            );
        },
    );

    @action async updateCustomerReferences(
        data: UpdateIndividualReferenceRequest,
    ) {
        await this.updateCustomerReferencesLoader.call(data);
        IndividualsStore.loadItem(this.individualId);
    }

    @computed get isUpdateCustomerReferencesLoading() {
        return this.updateCustomerReferencesLoader.isLoading;
    }

    updateBankInformationLoader = new AsyncOperationWithStatus(
        (data: IndividualPaymentAccountUpdate) => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.updatePaymentAccounts({
                    id: this.individualId,
                    individualPaymentAccountUpdate: data,
                }),
            );
        },
    );

    @action async updateBankInformation(data: IndividualPaymentAccountUpdate) {
        await this.updateBankInformationLoader.call(data);
        IndividualsStore.loadItem(this.individualId);
    }

    @computed get isUpdateBankInformationLoading() {
        return this.updateBankInformationLoader.isLoading;
    }

    updateJobInformationLoader = new AsyncOperationWithStatus(
        (data: CustomerJobInformationRequest) => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.updateJobInformation({
                    id: this.individualId,
                    customerJobInformationRequest: data,
                }),
            );
        },
    );

    @action async updateJobInformation(data: CustomerJobInformationRequest) {
        await this.updateJobInformationLoader.call(data);
        IndividualsStore.loadItem(this.individualId);
    }

    @computed get isUpdateJobInformationLoading() {
        return this.updateJobInformationLoader.isLoading;
    }

    otherInformationLoader = new AsyncOperationWithStatus(
        () => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getOtherInformationTab({
                    id: this.individualId,
                }),
            );
        },
        { defaultIsLoading: true },
    );

    @action async loadOtherInformation() {
        await this.otherInformationLoader.call();
    }

    @computed get otherInformation() {
        return this.otherInformationLoader.data;
    }

    updateContactInformationLoader = new AsyncOperationWithStatus(
        (data: IndividualContactDetailsUpdate) => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.updateContactDetails({
                    id: this.individualId,
                    individualContactDetailsUpdate: data,
                }),
            );
        },
    );

    @action async updateContactInformation(
        data: IndividualContactDetailsUpdate,
    ) {
        await this.updateContactInformationLoader.call(data);
        IndividualsStore.loadItem(this.individualId);
    }

    @computed get isUpdateContactInformationLoading() {
        return this.updateContactInformationLoader.isLoading;
    }

    updateOtherInformationLoader = new AsyncOperationWithStatus(
        (data: OtherInformationRequest) => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.updateOtherInformation({
                    id: this.individualId,
                    otherInformationRequest: data,
                }),
            );
        },
    );

    @action async updateOtherInformation(data: OtherInformationRequest) {
        await this.updateOtherInformationLoader.call(data);
        this.otherInformationLoader.call();
    }

    @computed get isUpdateOtherInformationLoading() {
        return this.updateOtherInformationLoader.isLoading;
    }

    @observable isGenerateMasterAgreementLoadingVariable?: boolean = false;

    @action async generateMasterAgreement() {
        try {
            this.isGenerateMasterAgreementLoadingVariable = true;

            const fileRequest = await FilesStore.requestFile({
                individualId: this.individualId,
                type: 'NewMasterAgreementRequest',
            });
            const files = fileRequest?.files;

            if (Array.isArray(files)) {
                files.forEach((file) => {
                    UrlHelper.downloadFile(
                        file.presignedUrl!,
                        file.fileName || 'Master Agreement',
                    );
                });
            }
        } catch (error) {
            notification.error({
                message: t(
                    'Page.IndividualDetails.GenerateMasterAgreementError',
                ),
            });
        } finally {
            this.isGenerateMasterAgreementLoadingVariable = false;
        }
    }

    @computed get isGenerateMasterAgreementLoading() {
        return this.isGenerateMasterAgreementLoadingVariable;
    }
}

export const IndividualDetailsViewModel = new EntityProvider(
    IndividualDetailsViewModelClass,
);
