import { action } from 'mobx';
import {
    CreateRestrictionListEntryRequest,
    RestrictionCause,
    RestrictionListEntry,
    RestrictionListType,
} from 'src/generated-api-client';
import { restrictionsApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type RestrictionsFilter = {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    listType?: RestrictionListType[];
    cause?: RestrictionCause[];
    registrationNumber?: string;
};

const RestrictionsFilterKeys: Array<keyof RestrictionsFilter> = [
    'firstName',
    'lastName',
    'companyName',
    'listType',
    'cause',
    'registrationNumber',
];

export class RestrictionsStoreClass extends BasicStore<
    RestrictionListEntry,
    RestrictionsFilter,
    any,
    any,
    any
> {
    api: BasicStoreApi<RestrictionListEntry, any, any, any> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                restrictionsApi.getList(this.listParams),
            );
        },
    };

    addCustomerLoader = new AsyncOperationWithStatus(
        (data: CreateRestrictionListEntryRequest) =>
            RequestHelper.unwrapFromAxiosPromise(
                restrictionsApi.add({
                    createRestrictionListEntryRequest: data,
                }),
            ),
    );

    @action async add(item: CreateRestrictionListEntryRequest) {
        await this.addCustomerLoader.call(item);
        this.loadList();
    }

    importCsvLoader = new AsyncOperationWithStatus((file: File) =>
        RequestHelper.unwrapFromAxiosPromise(
            restrictionsApi.importCsv(
                {
                    file,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            ),
        ),
    );

    @action async importCsv(values: any) {
        await this.importCsvLoader.call(values.file[0].originFileObj);
        if (!this.importCsvLoader.hasError) {
            this.loadList();
        }
    }

    removeCustomerLoader = new AsyncOperationWithStatus((customerId: number) =>
        RequestHelper.unwrapFromAxiosPromise(
            restrictionsApi.remove({ customerId }),
        ),
    );

    @action async remove(customerId: number) {
        await this.removeCustomerLoader.call(customerId);
        this.loadList();
    }

    filterCriteria = new FilterCriteria<RestrictionsFilter>(
        RestrictionsFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<RestrictionListEntry>();
}

export const RestrictionsStore = new RestrictionsStoreClass();
