import { observer } from 'mobx-react-lite';
import { CreateCollateralEvaluationVariables } from 'src/pages/CollateralCreatePage/Components/CreateCollateralEvaluationVariables';
import { CreateEquipmentCollateralGeneralInfoVariables } from 'src/pages/CollateralCreatePage/EquipmentCollateral/CreateEquipmentCollateralGeneralInfoVariables';

export const CreateEquipmentCollateral = observer(() => {
    return (
        <>
            <CreateEquipmentCollateralGeneralInfoVariables />
            <CreateCollateralEvaluationVariables />
        </>
    );
});
