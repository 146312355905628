/* eslint-disable max-lines */
import moment from 'moment';
import { ComponentsMap } from 'src/components/ComponentProvider/ComponentProvider';
import {
    CarCollateral,
    CarCollateralPatch,
    CollateralDetailsType,
    CollateralSubType,
    EquipmentCollateral,
    EquipmentCollateralPatch,
    IntangibleAssetCollateral,
    IntangibleAssetCollateralPatch,
    LeasingCollateral,
    LeasingCollateralPatch,
    LivestockCollateral,
    LivestockCollateralPatch,
    RealEstateCollateral,
    RealEstateCollateralPatch,
} from 'src/generated-api-client';
import { CarCollateralGeneralInfoUpdateVariables } from 'src/pages/CollateralDetailsPage/Tabs/CarCollateralTab/CarCollateralGeneralInfoUpdateVariables';
import { EquipmentCollateralGeneralInfoUpdateVariables } from 'src/pages/CollateralDetailsPage/Tabs/EquipmentCollateralTab/EquipmentCollateralGeneralInfoUpdateVariables';
import { IntangibleAssetCollateralGeneralInfoUpdateVariables } from 'src/pages/CollateralDetailsPage/Tabs/IntangibleAssetCollateralTab/IntangibleAssetCollateralGeneralInfoUpdateVariables';
import { LeasingCollateralGeneralInfoUpdateVariables } from 'src/pages/CollateralDetailsPage/Tabs/LeasingCollateralTab/LeasingCollateralGeneralInfoUpdateVariables';
import { LivestockCollateralGeneralInfoUpdateVariables } from 'src/pages/CollateralDetailsPage/Tabs/LivestockCollateralTab/LivestockCollateralGeneralInfoUpdateVariables';
import { RealEstateCollateralGeneralInfoUpdateVariables } from 'src/pages/CollateralDetailsPage/Tabs/RealEstateCollateralTab/RealEstateCollateralGeneralInfoUpdateVariables';
import { DateHelper } from 'src/utils/DateHelper';

export type Collateral =
    | CarCollateral
    | EquipmentCollateral
    | IntangibleAssetCollateral
    | LeasingCollateral
    | LivestockCollateral
    | RealEstateCollateral;

const editVariablesMap: ComponentsMap = {
    [CollateralSubType.LEASING_PRODUCT]: {
        Component: LeasingCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.LIVESTOCK]: {
        Component: LivestockCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.ELECTRON_PRODUCT]: {
        Component: EquipmentCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.MANUAL_EQUIPMENT]: {
        Component: EquipmentCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.FACTORY_EQUIPMENT]: {
        Component: EquipmentCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.OTHER_PRODUCT]: {
        Component: EquipmentCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.FACTORY_BUILDINGS]: {
        Component: RealEstateCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.APARTMENTS]: {
        Component: RealEstateCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.OFFICE]: {
        Component: RealEstateCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.HOUSE_AND_LAND]: {
        Component: RealEstateCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.LAND]: {
        Component: RealEstateCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.CAR_PARK]: {
        Component: RealEstateCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.NEW_CAR]: {
        Component: CarCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.IMPORTED_CAR]: {
        Component: CarCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.USED_CAR]: {
        Component: CarCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.BOND]: {
        Component: IntangibleAssetCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.STOCK]: {
        Component: IntangibleAssetCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.LICENSE]: {
        Component: IntangibleAssetCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.PHONE_NUMBER]: {
        Component: IntangibleAssetCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.TRUST_ACCOUNT]: {
        Component: IntangibleAssetCollateralGeneralInfoUpdateVariables,
    },
    [CollateralSubType.AGREEMENT_FOR_GOODS_AND_SERVICES]: {
        Component: IntangibleAssetCollateralGeneralInfoUpdateVariables,
    },
};

// eslint-disable-next-line sonarjs/cognitive-complexity,complexity
const getEditInitialValues = (collateral?: Collateral) => {
    const collateralSubType = collateral?.subType as any;

    if (carCollateralTypes.includes(collateralSubType)) {
        const values = collateral as CarCollateral;

        return {
            ...values,
            dateOfManufacture:
                values?.dateOfManufacture &&
                moment(values?.dateOfManufacture, 'YYYY'),
            importedDate: values?.importedDate && moment(values?.importedDate),
            type: CollateralDetailsType.CAR,
        };
    }

    if (realEstateCollateralTypes.includes(collateralSubType)) {
        const values = collateral as RealEstateCollateral;

        return {
            ...values,
            type: CollateralDetailsType.REAL_ESTATE,
            registeredDate:
                values?.registeredDate && moment(values?.registeredDate),
            establishedDate:
                values?.establishedDate && moment(values?.establishedDate),
            districtCode: values?.address?.district.code,
            address: {
                ...values?.address,
                provinceCode: values?.address?.province.code,
                districtCode: values?.address?.district.code,
                khorooCode: values?.address?.khoroo.code,
            },
        };
    }

    if (equipmentCollateralTypes.includes(collateralSubType)) {
        const values = collateral as EquipmentCollateral;

        return {
            ...values,
            manufacturedDate:
                values?.manufacturedDate && moment(values?.manufacturedDate),
            importedDate: values?.importedDate && moment(values?.importedDate),
            type: CollateralDetailsType.EQUIPMENT,
        };
    }

    if (livestockCollateralTypes.includes(collateralSubType)) {
        const values = collateral as LivestockCollateral;

        return {
            ...values,
            type: CollateralDetailsType.LIVESTOCK,
        };
    }

    if (leasingCollateralTypes.includes(collateralSubType)) {
        const values = collateral as LeasingCollateral;

        return {
            ...values,
            type: CollateralDetailsType.LEASING,
        };
    }

    if (intangibleAssetCollateralTypes.includes(collateralSubType)) {
        const values = collateral as IntangibleAssetCollateral;

        return {
            ...values,
            expiresAt: moment(values?.expiresAt),
            startsAt: moment(values?.startsAt),
            type: CollateralDetailsType.INTANGIBLE_ASSET,
        };
    }
};

const getConverterEditFormValuesToSubmitValues = (collateral?: Collateral) => {
    const collateralSubType = collateral?.subType as any;

    if (carCollateralTypes.includes(collateralSubType)) {
        return (values?: CarCollateralPatch) => {
            return {
                ...values,
                dateOfManufacture: moment(values?.dateOfManufacture).year(),
                importedDate: DateHelper.formatISODate(values?.importedDate),
            } as CarCollateralPatch;
        };
    }

    if (realEstateCollateralTypes.includes(collateralSubType)) {
        return (values?: RealEstateCollateralPatch) => {
            return {
                ...values,
                establishedDate: DateHelper.formatISODate(
                    values?.establishedDate,
                ),
                registeredDate: DateHelper.formatISODate(
                    values?.registeredDate,
                ),
            } as RealEstateCollateralPatch;
        };
    }

    if (equipmentCollateralTypes.includes(collateralSubType)) {
        return (values?: EquipmentCollateralPatch) => {
            return {
                ...values,
                importedDate: DateHelper.formatISODate(values?.importedDate),
                manufacturedDate: DateHelper.formatISODate(
                    values?.manufacturedDate,
                ),
            } as EquipmentCollateralPatch;
        };
    }

    if (livestockCollateralTypes.includes(collateralSubType)) {
        return (values?: LivestockCollateralPatch) => {
            return {
                ...values,
            } as LivestockCollateralPatch;
        };
    }

    if (leasingCollateralTypes.includes(collateralSubType)) {
        return (values?: LeasingCollateralPatch) => {
            return {
                ...values,
            } as LeasingCollateralPatch;
        };
    }

    if (intangibleAssetCollateralTypes.includes(collateralSubType)) {
        return (values: IntangibleAssetCollateralPatch) => {
            return {
                ...values,
                expiresAt: DateHelper.formatISODate(values?.expiresAt),
                startsAt: DateHelper.formatISODate(values?.startsAt),
            } as IntangibleAssetCollateralPatch;
        };
    }
};

export const leasingCollateralTypes = [CollateralSubType.LEASING_PRODUCT];
export const livestockCollateralTypes = [CollateralSubType.LIVESTOCK];
export const equipmentCollateralTypes = [
    CollateralSubType.ELECTRON_PRODUCT,
    CollateralSubType.MANUAL_EQUIPMENT,
    CollateralSubType.FACTORY_EQUIPMENT,
    CollateralSubType.OTHER_PRODUCT,
];
export const realEstateCollateralTypes = [
    CollateralSubType.FACTORY_BUILDINGS,
    CollateralSubType.APARTMENTS,
    CollateralSubType.OFFICE,
    CollateralSubType.HOUSE_AND_LAND,
    CollateralSubType.LAND,
    CollateralSubType.CAR_PARK,
];
export const carCollateralTypes = [
    CollateralSubType.NEW_CAR,
    CollateralSubType.IMPORTED_CAR,
    CollateralSubType.USED_CAR,
];
export const intangibleAssetCollateralTypes = [
    CollateralSubType.BOND,
    CollateralSubType.STOCK,
    CollateralSubType.LICENSE,
    CollateralSubType.PHONE_NUMBER,
    CollateralSubType.AGREEMENT_FOR_GOODS_AND_SERVICES,
];

export const trustAccountCollateralTypes = [CollateralSubType.TRUST_ACCOUNT];

export const CollateralsHelper = {
    getEditInitialValues,
    getConverterEditFormValuesToSubmitValues,
    editVariablesMap,
};
