import { notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { SelectField } from 'src/components/Form/SelectField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { BankStatementStatus } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { BankStatementActionsStore } from 'src/stores/BankStatementActionsStore/BankStatementActionsStore';
import { BankStatementsStore } from 'src/stores/BankStatementsStore/BankStatementsStore';
import { BankStatementStatusesStore } from 'src/stores/Resources/BankStatementStatusesStore/BankStatementStatusesStore';
import { EnumName, EnumStore } from 'src/stores/Resources/EnumStore/EnumStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const enumsToLoad: EnumName[] = ['BANK_STATEMENT_STATUS'];

type BankStatementChangeStatusDialogFnProps = ModalRendererProps<{
    bankStatementId?: number;
}>;

export const BankStatementChangeStatusDialogFn = observer(
    ({ bankStatementId, ...props }: BankStatementChangeStatusDialogFnProps) => {
        useEnumsLoader(enumsToLoad);

        const store = BankStatementActionsStore.getInstance(
            Number(bankStatementId),
        );
        const isLoading =
            store.changeStatusLoader.isLoading ||
            BankStatementStatusesStore.listLoader.isLoading;
        const fields = FormHelper.getFieldDataItems(
            store?.changeStatusLoader.errorData,
        );

        useEffect(() => {
            return () => {
                store?.changeStatusLoader.reset();
                BankStatementStatusesStore.listLoader.reset();
            };
        }, []);

        return (
            <Modal
                title={t('Dialog.BankStatementChangeStatus.Title')}
                isLoading={isLoading}
                onFinish={async (values) => {
                    await store.changeStatus(values);

                    if (store.changeStatusLoader.hasError) {
                        if (store.changeStatusLoader.errorData.status === 409) {
                            notification.error({
                                message:
                                    store.changeStatusLoader.errorData.detail ||
                                    t(
                                        'Dialog.BankStatementChangeStatus.Message.409Conflict',
                                    ),
                            });
                        }

                        return Promise.reject();
                    }

                    if (!store.changeStatusLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.BankStatementChangeStatus.Message.Success',
                            ),
                        });
                    }

                    BankStatementsStore.loadList();
                }}
                formProps={{ fields }}
                width={640}
                {...props}
            >
                <SelectField
                    name="bankStatementStatus"
                    label={t(
                        'Dialog.BankStatementChangeStatus.BankStatementStatus',
                    )}
                    store={BankStatementStatusesStore}
                    convertListItemToOption={(item: BankStatementStatus) => {
                        return {
                            label: EnumStore.getInstance(
                                'BANK_STATEMENT_STATUS',
                            ).getLocalizedEnum(item),
                            value: item,
                        };
                    }}
                    rules={[ValidationRules.required]}
                />
                <TextAreaField
                    name="comment"
                    label={t('Dialog.BankStatementChangeStatus.Comment')}
                    maxLength={255}
                />
            </Modal>
        );
    },
);

export const BankStatementChangeStatusDialog = addModalRenderer(
    BankStatementChangeStatusDialogFn,
);
