import { action, computed, observable } from 'mobx';
import moment from 'moment-timezone';
import { UiSettings as BaseUiSettings } from 'src/generated-api-client';
import apiClientInfo from 'src/generated-api-client/package.json';
import i18next, { initInternationalization } from 'src/i18n';
import { uiSettingsApi } from 'src/services/apiServices';
import { DateHelper } from 'src/utils/DateHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export const UPDATE_TOKEN_MIN_VALIDITY = 30;
export const SUPPORTED_LOCALES = ['en'];
const DEFAULT_TIME_ZONE = 'Asia/Ulan_Bator';

export type UISettings = BaseUiSettings;

export class SettingsStoreClass {
    private settingsLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(uiSettingsApi.getUiSettings()),
    );

    @observable currentLocale?: string;

    @action async load() {
        await this.settingsLoader.call();
    }

    @computed get settings() {
        return this.settingsLoader.data as UISettings;
    }

    @computed get hasSettings() {
        return this.settingsLoader.hasData;
    }

    @action async setupInternationalization() {
        await initInternationalization((this.settings || {}) as any);
    }

    @action async init() {
        await this.load();
        await this.setupInternationalization();
        await this.setupTimeZone();
    }

    @action async setupTimeZone() {
        await moment.tz.setDefault(DEFAULT_TIME_ZONE);
    }

    @computed get supportedLocales() {
        return this.settings?.locales || SUPPORTED_LOCALES;
    }

    @computed get hasFewLocales() {
        return this.supportedLocales.length > 1;
    }

    @action async setLocale(locale: string) {
        await i18next.changeLanguage(locale);
    }

    @computed get backEndServerVersion() {
        return this.settings.backofficeVersion;
    }

    get apiClientVersion() {
        return apiClientInfo.version;
    }

    @computed get menuLinks() {
        return this.settings.menuLinks;
    }

    @computed get sessionLifeSpanMs() {
        return DateHelper.convertPTtoMs(
            this.settings.authentication.idleTimeout,
        );
    }
}

export const SettingsStore = new SettingsStoreClass();
