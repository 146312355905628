import { Button, Col, Form as AntForm, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N23Task, TaskDecisionType } from 'src/generated-api-client';
import { TaskCommentsView } from 'src/pages/TaskDetailsPage/Components/TaskCommentsView';
import { SubmitTaskDialog } from 'src/pages/TaskDetailsPage/Dialogs/SubmitTaskDialog';
import { BankStatementInformationCard } from 'src/pages/TaskDetailsPage/N23AddBusinessIncome/Components/BankStatementInformationCard';
import { CustomerInformationCard } from 'src/pages/TaskDetailsPage/N23AddBusinessIncome/Components/CustomerInfomationCard';
import { N23AddBusinessIncomeTaskVariables } from 'src/pages/TaskDetailsPage/N23AddBusinessIncome/N23AddBusinessIncomeTaskVariables';
import { N23TaskReturnVariables } from 'src/pages/TaskDetailsPage/N23AddBusinessIncome/N23TaskReturnVariables';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

export const N23AddBusinessIncomeTask = observer(
    ({ viewModel }: TaskProps<N23Task>) => {
        const task = viewModel.currentTask || {};
        const { socUserComment, iatUserComment } = task;

        const comments = [
            {
                label: t('Component.TaskCommentsView.SOCUserComment'),
                value: socUserComment,
            },
            {
                label: t('Component.TaskCommentsView.IATUserComment'),
                value: iatUserComment,
            },
        ];

        const [form] = AntForm.useForm();
        const type = AntForm.useWatch('type', form);

        return (
            <>
                <Row gutter={[16, 24]}>
                    <Col xs={24}>
                        <StyledText>
                            <Trans i18nKey="Task.Form.N23AddBusinessIncomeTask.Descriptions" />
                        </StyledText>
                    </Col>
                    <Col xs={24}>
                        <CustomerInformationCard task={task} />
                    </Col>
                    <Col xs={24}>
                        <BankStatementInformationCard task={task} />
                    </Col>

                    <Col xs={24}>
                        <TaskCommentsView comments={comments} />
                    </Col>
                </Row>

                <TaskForm
                    form={form}
                    fields={viewModel.submitTaskErrorFields}
                    initialValues={getInitialValues(task)}
                    onFinish={async (values) => {
                        await viewModel.submitTask(values);

                        if (!viewModel.submitTaskLoaderHasError) {
                            const targetUrl = UrlHelper.getTargetUrl(
                                RoutePaths.individualDetails,
                                {
                                    individualId: task?.individual.id,
                                },
                            );

                            const targetUrlWithTab =
                                UrlHelper.getUrlWithoutHost(
                                    UrlHelper.getUrlWithQueryParams(
                                        { tab: 'loan-limit' },
                                        targetUrl,
                                    ).toString(),
                                );

                            Router.replace(targetUrlWithTab);
                        }
                    }}
                >
                    <Card title={t('Task.Form.N23AddBusinessIncomeTask.Title')}>
                        <Row gutter={[16, 24]}>
                            <Col xs={24}>
                                <N23AddBusinessIncomeTaskVariables
                                    type={type}
                                />
                            </Col>
                            <Col xs={24}>
                                <Space size={20}>
                                    <Button
                                        type={
                                            viewModel.isCurrentTaskEqualWorkingTask
                                                ? 'primary'
                                                : 'default'
                                        }
                                        htmlType="submit"
                                    >
                                        {t('Task.Form.Btn.Save')}
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            SubmitTaskDialog.render({
                                                viewModel,
                                                title: t(
                                                    'Dialog.Return.N23AddBusinessIncomeTask.Title',
                                                ),
                                                children: (
                                                    <N23TaskReturnVariables />
                                                ),
                                            });
                                        }}
                                    >
                                        {t('Task.Form.Btn.Return')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                </TaskForm>
            </>
        );
    },
);

const getInitialValues = (data?: N23Task) => {
    return {
        ...data,
        type: TaskDecisionType.N2_3_APPROVE,
    };
};

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
