/* eslint-disable max-lines */
import { Col, Form as AntForm, Form, Row } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { FieldData } from 'rc-field-form/es/interface';
import { useEffect, useState } from 'react';
import { Card } from 'src/components/Card/Card';
import { EnumView } from 'src/components/EnumView/EnumView';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { SelectField } from 'src/components/Form/SelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { CollateralSubType, CollateralType } from 'src/generated-api-client';

export const CreateCollateralTypeVariables = observer(() => {
    const [collateralTypeIsTouchedOnce, setCollateralTypeIsTouchedOnce] =
        useState(false);

    const form = Form.useFormInstance();

    const collateralType: CollateralType = AntForm.useWatch('collateralType');
    const collateralSubType: CollateralSubType =
        AntForm.useWatch('collateralSubType');

    const collateralSubTypeIsTouched = form.isFieldTouched('collateralSubType');

    useEffect(() => {
        if (!collateralTypeIsTouchedOnce && collateralSubTypeIsTouched) {
            setCollateralTypeIsTouchedOnce(collateralSubTypeIsTouched);
        }
    }, [collateralSubTypeIsTouched]);

    useEffect(() => {
        const touched = form.isFieldsTouched();
        if (touched) {
            const fields = form.getFieldsValue(true, (meta) => {
                return !_.isEqual(meta.name, ['collateralType']);
            });

            form.setFieldsValue(setAllFieldsToUndefined(fields));

            collateralTypeIsTouchedOnce && form.validateFields();
        }
    }, [collateralType]);

    useEffect(() => {
        const touched = form.isFieldsTouched();
        if (touched) {
            const fields = form.getFieldsValue(true, (meta) => {
                return (
                    !_.isEqual(meta.name, ['collateralType']) &&
                    !_.isEqual(meta.name, ['collateralSubType'])
                );
            });
            form.setFieldsValue(setAllFieldsToUndefined(fields));
        }
    }, [collateralSubType]);

    const collateralSybTypeOptions =
        getCollateralSubTypeOptions(collateralType);

    return (
        <Card title={t('Component.CreateCollateralTypeVariables.Title')}>
            <Row gutter={[16, 16]}>
                <Col xs={8}>
                    <EnumSelectField
                        name="collateralType"
                        label={t(
                            'Component.CreateCollateralTypeVariables.CollateralType',
                        )}
                        enumName="COLLATERAL_TYPE"
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={8}>
                    <SelectField
                        name="collateralSubType"
                        label={t(
                            'Component.CreateCollateralTypeVariables.CollateralSubType',
                        )}
                        options={collateralSybTypeOptions}
                        rules={[ValidationRules.required]}
                    />
                </Col>
            </Row>
        </Card>
    );
});

function getCollateralSubTypeOptions(type: CollateralType) {
    return CollateralSybTypeOptions[type] || [];
}

function setAllFieldsToUndefined(fields: FieldData) {
    return Object.fromEntries(
        Object.entries(fields).map(([key]) => [key, undefined]),
    );
}

const RealEstateSybTypeOptions: DefaultOptionType[] = [
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.FACTORY_BUILDINGS}
            />
        ),
        value: CollateralSubType.FACTORY_BUILDINGS,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.APARTMENTS}
            />
        ),
        value: CollateralSubType.APARTMENTS,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.OFFICE}
            />
        ),
        value: CollateralSubType.OFFICE,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.HOUSE_AND_LAND}
            />
        ),
        value: CollateralSubType.HOUSE_AND_LAND,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.LAND}
            />
        ),
        value: CollateralSubType.LAND,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.CAR_PARK}
            />
        ),
        value: CollateralSubType.CAR_PARK,
    },
];

const MovablePropertySybTypeOptions: DefaultOptionType[] = [
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.NEW_CAR}
            />
        ),
        value: CollateralSubType.NEW_CAR,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.IMPORTED_CAR}
            />
        ),
        value: CollateralSubType.IMPORTED_CAR,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.USED_CAR}
            />
        ),
        value: CollateralSubType.USED_CAR,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.ELECTRON_PRODUCT}
            />
        ),
        value: CollateralSubType.ELECTRON_PRODUCT,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.MANUAL_EQUIPMENT}
            />
        ),
        value: CollateralSubType.MANUAL_EQUIPMENT,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.FACTORY_EQUIPMENT}
            />
        ),
        value: CollateralSubType.FACTORY_EQUIPMENT,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.OTHER_PRODUCT}
            />
        ),
        value: CollateralSubType.OTHER_PRODUCT,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.LEASING_PRODUCT}
            />
        ),
        value: CollateralSubType.LEASING_PRODUCT,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.LIVESTOCK}
            />
        ),
        value: CollateralSubType.LIVESTOCK,
    },
];

const IntangibleAssetSybTypeOptions: DefaultOptionType[] = [
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.BOND}
            />
        ),
        value: CollateralSubType.BOND,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.STOCK}
            />
        ),
        value: CollateralSubType.STOCK,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.LICENSE}
            />
        ),
        value: CollateralSubType.LICENSE,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.PHONE_NUMBER}
            />
        ),
        value: CollateralSubType.PHONE_NUMBER,
    },
    {
        label: (
            <EnumView
                enumName="COLLATERAL_SUB_TYPE"
                value={CollateralSubType.AGREEMENT_FOR_GOODS_AND_SERVICES}
            />
        ),
        value: CollateralSubType.AGREEMENT_FOR_GOODS_AND_SERVICES,
    },
];

const CollateralSybTypeOptions = {
    [CollateralType.REAL_ESTATE]: RealEstateSybTypeOptions,
    [CollateralType.MOVABLE_PROPERTY]: MovablePropertySybTypeOptions,
    [CollateralType.INTANGIBLE_ASSETS]: IntangibleAssetSybTypeOptions,
};
