import { t } from 'i18next';
import { ActionType } from 'src/generated-api-client';
import { CreateExistingIndividualRepresentativeDialog } from 'src/pages/LegalEntityDetailsPage/Dialogs/LegalEntityInformation/CreateExistingIndividualRepresentativeDialog';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getRepresentativesExternalActions = (
    viewModel?: LegalEntityDetailsViewModelClass,
): ActionDefinition[] => {
    return [...getUIActions(), ...getServerActions(viewModel)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    viewModel?: LegalEntityDetailsViewModelClass,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(viewModel),
        viewModel?.item?.actions,
    );
};

const getRegisteredServerActions = (
    viewModel?: LegalEntityDetailsViewModelClass,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.LEGAL_REPRESENTATIVE_CREATE,
            label: t(
                'Legal.Representatives.Actions.LEGAL_REPRESENTATIVE_CREATE',
            ),
            onClick: () => {
                CreateExistingIndividualRepresentativeDialog.render({
                    viewModel,
                });
            },
            primary: true,
        },
    ];
};
