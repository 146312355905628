import { Button, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'src/components/Form/Form';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { AuthPage } from 'src/pages/Auth/AuthPage';
import { AuthPageWithMessage } from 'src/pages/Auth/AuthPageWithMessage';
import { RecoveryExpiredPasswordStore } from 'src/stores/LocalAuth/RecoveryExpiredPasswordStore';
import { FormHelper } from 'src/utils/FormHelper';
import styled from 'styled-components';

export const RequestToRecoveryExpiredPasswordPage = observer(() => {
    const pageTitle = t('Page.Auth.RequestToRecoveryExpiredPassword.Title');

    useEffect(() => {
        return () => {
            RecoveryExpiredPasswordStore.reset();
        };
    }, []);

    const fields = FormHelper.getFieldDataItems(
        RecoveryExpiredPasswordStore.requestToRecoveryExpiredPasswordLoader
            .errorData,
    );

    if (RecoveryExpiredPasswordStore.isRequestToRecoverySent) {
        return (
            <AuthPageWithMessage
                title={pageTitle}
                message={t(
                    'Page.Auth.RequestToRecoveryExpiredPassword.EmailHasBeenSent',
                )}
                messageType="success"
            />
        );
    }

    return (
        <AuthPage
            title={pageTitle}
            isLoading={
                RecoveryExpiredPasswordStore.isRequestResetExpiredPasswordLoading
            }
        >
            {!RecoveryExpiredPasswordStore.isRequestToRecoverySent && (
                <Form
                    onFinish={async (values) => {
                        await RecoveryExpiredPasswordStore.requestToRecoveryExpiredPassword(
                            values,
                        );
                    }}
                    fields={fields}
                >
                    <TextField
                        name="login"
                        label={t(
                            'Page.Auth.RequestToRecoveryExpiredPassword.Login',
                        )}
                        rules={[ValidationRules.requiredEmail]}
                    />

                    <StyledSpace direction="vertical" size={16}>
                        <Button htmlType="submit" type="primary">
                            {t(
                                'Page.Auth.RequestToRecoveryExpiredPassword.Submit',
                            )}
                        </Button>
                        <Link to={RoutePaths.authLogin}>
                            {t(
                                'Page.Auth.RequestToRecoveryExpiredPassword.BackToLoginPage',
                            )}
                        </Link>
                    </StyledSpace>
                </Form>
            )}
        </AuthPage>
    );
});

const StyledSpace = styled(Space)`
    width: 100%;
`;
