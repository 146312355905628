import { Button } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { NotFound } from 'src/components/NotFound/NotFound';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useLegalEntityId } from 'src/hooks/useLegalEntityId';
import { useViewModel } from 'src/hooks/useViewModel';
import { LegalEntityDetailsBrief } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsBrief';
import { LegalEntityDetailsViewModel } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { LegalEntityCommentsTab } from 'src/pages/LegalEntityDetailsPage/Tabs/LegalEntityCommentsTab';
import { LegalEntityDetailsTab } from 'src/pages/LegalEntityDetailsPage/Tabs/LegalEntityDetailsTab';
import { LegalEntityLoanInformationTab } from 'src/pages/LegalEntityDetailsPage/Tabs/LegalEntityLoanInformationTab';
import { LegalEntityOtherInformationTab } from 'src/pages/LegalEntityDetailsPage/Tabs/LegalEntityOtherInformationTab';
import { LegalEntityRepresentativesTab } from 'src/pages/LegalEntityDetailsPage/Tabs/LegalEntityRepresentativesTab';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { LegalEntitiesStore } from 'src/stores/LegalEntitiesStore/LegalEntitiesStore';

const store = LegalEntitiesStore;

export const LegalEntityDetailsPage = observer(() => {
    const { t } = useTranslation();

    const { legalId } = useLegalEntityId();
    const viewModel = LegalEntityDetailsViewModel.getInstance(Number(legalId));

    useViewModel(viewModel, [legalId]);

    const hasItem = Boolean(viewModel.item);

    const error = useMemo(() => {
        const showNotFound =
            !_.isInteger(Number(legalId)) ||
            store.itemLoader.errorData?.status === 404;
        if (showNotFound) {
            return (
                <NotFound
                    title={t('Page.LegalEntityDetails.Error.NotFound.Title')}
                    description={t(
                        'Page.LegalEntityDetails.Error.NotFound.Description',
                        { legalId },
                    )}
                >
                    <Button
                        onClick={() => {
                            Router.navigate(RoutePaths.legalEntities);
                        }}
                    >
                        {t('Page.LegalEntityDetails.GoToList')}
                    </Button>
                </NotFound>
            );
        }

        return null;
    }, [legalId, store.itemLoader.errorData]);

    useEffect(() => {
        return () => {
            LegalEntitiesStore.itemLoader.reset();
        };
    }, [legalId]);

    const tabs: TabDefinition[] = hasItem
        ? [
              {
                  title: t('Page.LegalEntityDetails.Tabs.CustomerInformation'),
                  tabKey: 'details',
                  Component: <LegalEntityDetailsTab viewModel={viewModel} />,
                  visible: AppUser.hasPermissions(
                      UserPermission.LegalReadDetails,
                  ),
              },
              {
                  title: t('Page.LegalEntityDetails.Tabs.Representatives'),
                  tabKey: 'representatives',
                  Component: (
                      <LegalEntityRepresentativesTab viewModel={viewModel} />
                  ),
                  visible: AppUser.hasPermissions(
                      UserPermission.LegalReadDetails,
                  ),
              },
              {
                  title: t('Page.LegalEntityDetails.Tabs.LoanInformation'),
                  tabKey: 'loans',
                  Component: (
                      <LegalEntityLoanInformationTab viewModel={viewModel} />
                  ),
                  visible: AppUser.hasPermissions(
                      UserPermission.LegalReadLoanList,
                  ),
              },
              {
                  title: t('Page.LegalEntityDetails.Tabs.OtherInformation'),
                  tabKey: 'other',
                  Component: (
                      <LegalEntityOtherInformationTab viewModel={viewModel} />
                  ),
                  visible: AppUser.hasPermissions(
                      UserPermission.LegalReadDetails,
                  ),
              },
              {
                  title: t('Page.LegalEntityDetails.Tabs.Comments', 'Comments'),
                  tabKey: 'comments',
                  Component: (
                      <LegalEntityCommentsTab
                          roomId={Number(viewModel?.item?.commentRoom?.id)}
                      />
                  ),
              },
          ]
        : [];

    return (
        <BasePage
            isLoading={store?.isItemLoading}
            header={<LegalEntityDetailsBrief viewModel={viewModel} />}
            showOnlyLoading
            error={error}
        >
            <Tabs tabs={tabs} />
        </BasePage>
    );
});
