import { Button, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'src/components/Form/Form';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { AuthPage } from 'src/pages/Auth/AuthPage';
import { AuthPageWithMessage } from 'src/pages/Auth/AuthPageWithMessage';
import { ForgotPasswordPageErrorMessage } from 'src/pages/Auth/ForgotPasswordPageErrorMessage';
import { RecoveryPasswordStore } from 'src/stores/LocalAuth/RecoveryPasswordStore';
import { FormHelper } from 'src/utils/FormHelper';
import styled from 'styled-components';

export const ForgotPasswordPage = observer(() => {
    const pageTitle = t('Page.Auth.ForgotPasswordPage.Title');

    useEffect(() => {
        return () => {
            RecoveryPasswordStore.reset();
        };
    }, []);

    const fields = FormHelper.getFieldDataItems(
        RecoveryPasswordStore.requestRecoveryPasswordLoader.errorData,
    );

    if (RecoveryPasswordStore.isRequestToRecoverySent) {
        return (
            <AuthPageWithMessage
                title={pageTitle}
                message={t('Page.Auth.ForgotPasswordPage.EmailHasBeenSent')}
                messageType="success"
            />
        );
    }

    return (
        <AuthPage
            title={pageTitle}
            isLoading={RecoveryPasswordStore.isRecoveryRequestLoading}
        >
            {!RecoveryPasswordStore.isRequestToRecoverySent && (
                <Form
                    onFinish={async (values) => {
                        await RecoveryPasswordStore.requestRecoveryPassword(
                            values,
                        );
                    }}
                    fields={fields}
                >
                    <TextField
                        name="login"
                        label={t('Page.Auth.ForgotPasswordPage.Login')}
                        rules={[ValidationRules.requiredEmail]}
                    />
                    <ForgotPasswordPageErrorMessage />
                    <StyledSpace direction="vertical" size={16}>
                        <Button htmlType="submit" type="primary">
                            {t('Page.Auth.ForgotPasswordPage.Submit')}
                        </Button>
                        <Link to={RoutePaths.authLogin}>
                            {t('Page.Auth.ForgotPasswordPage.BackToLoginPage')}
                        </Link>
                    </StyledSpace>
                </Form>
            )}
        </AuthPage>
    );
});

const StyledSpace = styled(Space)`
    width: 100%;
`;
