import { action, computed } from 'mobx';
import {
    Citizenship,
    CreateIndividualRequest,
    CustomerKycLevel,
    CustomerVerificationStatus,
    IndividualListEntry,
    UpdateIndividualRequest,
} from 'src/generated-api-client';
import { individualsApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type IndividualsFilter = {
    id?: number;
    customerName?: string;
    registrationNumber?: string;
    phone?: string;
    relationshipManagerId?: number;
    branchCode?: string;
    verificationStatuses?: CustomerVerificationStatus[];
    kycLevel?: CustomerKycLevel[];
    civilId?: string;
    citizenship?: Citizenship;
    birthDate?: string;
};

const IndividualsFilterKeys: Array<keyof IndividualsFilter> = [
    'id',
    'customerName',
    'registrationNumber',
    'phone',
    'relationshipManagerId',
    'branchCode',
    'verificationStatuses',
    'kycLevel',
    'civilId',
    'citizenship',
    'birthDate',
];

export class IndividualsStoreClass extends BasicStore<
    IndividualListEntry,
    IndividualsFilter,
    UpdateIndividualRequest,
    CreateIndividualRequest,
    any
> {
    api: BasicStoreApi<
        IndividualListEntry,
        UpdateIndividualRequest,
        CreateIndividualRequest,
        any
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.findAllIndividuals(this.listParams),
            );
        },

        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getOne({ id }),
            );
        },

        createItem: (data: CreateIndividualRequest) => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.createIndividual({
                    createIndividualRequest: data,
                }),
            );
        },

        updateItem: (itemId, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.updateIndividual({
                    id: Number(itemId),
                    updateIndividualRequest: data,
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<IndividualsFilter>(
        IndividualsFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<IndividualListEntry>(
        'id',
        'descend',
    );

    customerInformationLoader = new AsyncOperationWithStatus((id: number) => {
        return RequestHelper.unwrapFromAxiosPromise(
            individualsApi.getCustomerInformationTab({ id }),
        );
    });

    customerCreditScoringLoader = new AsyncOperationWithStatus((id: number) => {
        return RequestHelper.unwrapFromAxiosPromise(
            individualsApi.getCsValue({ id }),
        );
    });

    customerMonthlyIncomeLoader = new AsyncOperationWithStatus((id: number) => {
        return RequestHelper.unwrapFromAxiosPromise(
            individualsApi.getMonthlyIncome({ id }),
        );
    });

    @action async loadItem(id: number) {
        await super.loadItem(id);
        await this.customerInformationLoader.call(id);
        await this.customerCreditScoringLoader.call(id);
        await this.customerMonthlyIncomeLoader.call(id);
    }

    @computed get currentItem() {
        return {
            ...this.itemLoader.data,
            ...this.customerInformationLoader.data,
            ...this.customerCreditScoringLoader.data,
            ...this.customerMonthlyIncomeLoader.data,
        };
    }

    @computed get isItemLoading() {
        return (
            this.itemLoader.isLoading ||
            this.customerInformationLoader.isLoading ||
            this.customerCreditScoringLoader.isLoading ||
            this.customerMonthlyIncomeLoader.isLoading
        );
    }
}

export const IndividualsStore = new IndividualsStoreClass();
