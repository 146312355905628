import { ColumnType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { Modal } from 'src/components/Modal/Modal';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import {
    IdNameEntry,
    PledgedApplicationDecisionListEntry,
    PledgedLoanApplication,
} from 'src/generated-api-client';
import { PledgedApplicationDecision } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Components/PledgedApplicationDecision';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type CcDecisionDetailsDialogProps = ModalRendererProps<{
    application?: PledgedLoanApplication;
}>;

export const CcDecisionDetailsDialogFn = observer(
    ({ application, ...props }: CcDecisionDetailsDialogProps) => {
        const decisionColumns = getDecisionColumns(props);

        return (
            <Modal
                title={t(
                    'Dialog.CcDecisionDetails.Title',
                    'Credit Committee Decision',
                )}
                width={1200}
                footer={false}
                maskClosable={false}
                {...props}
            >
                <ListView
                    dataSource={application?.ccDecisions}
                    columns={decisionColumns}
                />
            </Modal>
        );
    },
);

export const CcDecisionDetailsDialog = addModalRenderer(
    CcDecisionDetailsDialogFn,
);

const getDecisionColumns = (
    props: ModalRendererProps,
): ColumnType<PledgedApplicationDecisionListEntry>[] => {
    return [
        {
            key: 'user',
            dataIndex: 'user',
            title: <Trans i18nKey="Dialog.CcDecisionDetails.List.Member" />,
            render(value: IdNameEntry) {
                return (
                    <StyledLink
                        onClick={() => {
                            Router.navigate(
                                EntityLinks.users.itemDetails(value?.id),
                            );
                            props.onCancel();
                        }}
                    >
                        {value?.name}
                    </StyledLink>
                );
            },
        },
        {
            key: 'actorType',
            dataIndex: 'actorType',
            title: <Trans i18nKey="Dialog.CcDecisionDetails.List.ActorType" />,
            render: (value) => {
                return (
                    <DataView
                        value={value}
                        hideLabel
                        format={(value) => t(`Application.ActorType.${value}`)}
                    />
                );
            },
        },
        {
            key: 'decision',
            dataIndex: 'decision',
            title: <Trans i18nKey="Dialog.CcDecisionDetails.List.Decision" />,
            render: (value) => {
                return <PledgedApplicationDecision decision={value} />;
            },
        },
        {
            key: 'amount',
            dataIndex: 'amount',
            title: <Trans i18nKey="Dialog.CcDecisionDetails.List.Amount" />,
            render: (value) => {
                return <DataView value={value} format="money" hideLabel />;
            },
        },
        {
            key: 'term',
            dataIndex: 'term',
            title: <Trans i18nKey="Dialog.CcDecisionDetails.List.Term" />,
            render: (value) => {
                return <DataView value={value} hideLabel format="string" />;
            },
        },
        {
            key: 'monthlyInterestRate ',
            dataIndex: 'monthlyInterestRate',
            title: (
                <Trans i18nKey="Dialog.CcDecisionDetails.List.InterestRate" />
            ),
            render: (value) => {
                return <DataView value={value} format="percent" hideLabel />;
            },
        },
        {
            key: 'repaymentFrequency',
            dataIndex: 'repaymentFrequency',
            title: (
                <Trans i18nKey="Dialog.CcDecisionDetails.List.RepaymentFrequency" />
            ),
            render: (value) => {
                return (
                    <DataView
                        value={value}
                        hideLabel
                        format={(value) => (
                            <EnumView
                                enumName="REPAYMENT_FREQUENCY"
                                value={value}
                            />
                        )}
                    />
                );
            },
        },
        {
            key: 'comment',
            dataIndex: 'comment',
            title: <Trans i18nKey="Dialog.CcDecisionDetails.List.Comment" />,
            render: (value) => {
                return <DataView value={value} hideLabel format="string" />;
            },
        },
    ];
};

const StyledLink = styled.a`
    cursor: pointer;
`;
