import { notification } from 'antd';
import { t } from 'i18next';
import { action } from 'mobx';
import {
    CreateUserForm,
    EditUserForm,
    User,
    UserActivityUpdate,
    UserListItem,
    UserStatus,
} from 'src/generated-api-client';
import { usersApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type UsersFilter = {
    name?: string;
    phone?: string;
    statuses?: UserStatus[];
    registrationCompleted?: boolean;
    roleIds?: number[] | string[];
};

const UsersFilterKeys: Array<keyof UsersFilter> = [
    'name',
    'phone',
    'statuses',
    'registrationCompleted',
    'roleIds',
];

export class UsersStoreClass extends BasicStore<
    UserListItem,
    UsersFilter,
    EditUserForm,
    CreateUserForm,
    User
> {
    api: BasicStoreApi<UserListItem, EditUserForm, CreateUserForm, User> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                usersApi.users(this.listParams),
            );
        },

        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(usersApi.user({ id }));
        },

        createItem: async (data) => {
            await RequestHelper.unwrapFromAxiosPromise(
                usersApi.createUser({ createUserForm: data }),
            );
        },

        updateItem: async (id, data) => {
            await RequestHelper.unwrapFromAxiosPromise(
                usersApi.updateUser({
                    id: Number(id),
                    editUserForm: data,
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<UsersFilter>(UsersFilterKeys);

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<UserListItem>('id', 'descend');

    changeActivityLoader = new AsyncOperationWithStatus(
        (id: number, userActivityUpdate: UserActivityUpdate) => {
            return RequestHelper.unwrapFromAxiosPromise(
                usersApi.toggleActivityUser({
                    id,
                    userActivityUpdate,
                }),
            );
        },
    );

    deactivateUserLoader = new AsyncOperationWithStatus((id: number) => {
        return RequestHelper.unwrapFromAxiosPromise(
            usersApi.deactivateUser({ id }),
        );
    });

    activateUserLoader = new AsyncOperationWithStatus((id: number) => {
        return RequestHelper.unwrapFromAxiosPromise(
            usersApi.activateUser({ id }),
        );
    });

    resendRegistrationLinkLoader = new AsyncOperationWithStatus(
        (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                usersApi.resendRegistrationLink({ id }),
            );
        },
    );

    @action async activateUser(id: number) {
        await this.activateUserLoader.call(id);
        if (!this.activateUserLoader.hasError) {
            notification.success({
                message: t('Actions.Users.Activate.Success'),
            });
        }

        this.reloadUserId(id);
    }

    @action async reloadUserId(id: number) {
        await this.itemLoader.turnOnSilentMode();
        await this.loadItem(id);
        await this.itemLoader.turnOffSilentMode();
    }

    @action async deactivateUser(id: number) {
        await this.deactivateUserLoader.call(id);
        if (!this.deactivateUserLoader.hasError) {
            notification.success({
                message: t('Actions.Users.Deactivate.Success'),
            });
        }
        this.reloadUserId(id);
    }

    @action async resendRegistrationLink(id: number) {
        await this.resendRegistrationLinkLoader.call(id);
        if (!this.resendRegistrationLinkLoader.hasError) {
            notification.success({
                message: t('Actions.Users.ResendRegistrationLink.Success'),
            });
        }
    }

    @action async changeActivity(
        id: number,
        userActivityUpdate: UserActivityUpdate,
    ) {
        await this.changeActivityLoader.call(id, userActivityUpdate);
    }
}

export const UsersStore = new UsersStoreClass();
