import { observer } from 'mobx-react-lite';
import { RecoveryPasswordStore } from 'src/stores/LocalAuth/RecoveryPasswordStore';
import { ErrorDataHelper } from 'src/utils/ErrorDataHelper';
import styled from 'styled-components';

export const ForgotPasswordPageErrorMessage = observer(() => {
    const { errorData } = RecoveryPasswordStore.requestRecoveryPasswordLoader;

    if (ErrorDataHelper.isBaseError(errorData)) {
        return <StyledError>{errorData.detail}</StyledError>;
    }

    return null;
});

const StyledError = styled.p`
    margin-top: 10px;
    color: #ff4d4f;
`;
