import { useEffect, useRef } from 'react';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';

export type AutoRefreshSettings = {
    timeout: number;
    useSilentMode?: boolean;
};

export const useAutoRefresh = (
    loader?: AsyncOperationWithStatus<any>,
    settings: AutoRefreshSettings = { timeout: 5000, useSilentMode: true },
) => {
    const intervalRef = useRef(0);

    const startAutoRefresh = () => {
        if (intervalRef.current) {
            stopAutoRefresh();
        }

        intervalRef.current = setInterval(() => {
            if (intervalRef.current) {
                settings.useSilentMode && loader?.turnOnSilentMode();
                loader?.call();
                settings.useSilentMode && loader?.turnOffSilentMode();
            }
        }, settings?.timeout) as unknown as number;
    };

    const stopAutoRefresh = () => {
        clearInterval(intervalRef.current);
    };

    useEffect(() => {
        return () => {
            stopAutoRefresh();
        };
    }, []);

    return { startAutoRefresh, stopAutoRefresh };
};
