import {
    NewPayonProduct,
    PayonProduct,
    PayonProductUpdate,
    ProductListEntry,
    ProductStatus,
    RepaymentAllocationRule,
    RepaymentCalculationMethod,
} from 'src/generated-api-client';
import { productsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type PayonProductsFilter = {
    id?: number;
    externalId?: number;
    name?: string;
    repaymentCalculationMethod?: RepaymentCalculationMethod[];
    penaltyInterestMultiplierFrom?: number;
    penaltyInterestMultiplierTo?: number;
    penaltyInterestAccrualStartDay?: number;
    repaymentAllocationRule?: RepaymentAllocationRule[];
    status?: ProductStatus[];
};

const PayonProductsFilterKeys: Array<keyof PayonProductsFilter> = [
    'id',
    'externalId',
    'name',
    'repaymentCalculationMethod',
    'penaltyInterestMultiplierFrom',
    'penaltyInterestMultiplierTo',
    'penaltyInterestAccrualStartDay',
    'repaymentAllocationRule',
    'status',
];

export class PayonProductsStoreClass extends BasicStore<
    ProductListEntry,
    PayonProductsFilter,
    PayonProductUpdate,
    NewPayonProduct,
    PayonProduct
> {
    api: BasicStoreApi<
        ProductListEntry,
        PayonProductUpdate,
        NewPayonProduct,
        PayonProduct
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.getPayonProducts(this.listParams),
            );
        },

        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.getProduct({ id }),
            ) as Promise<PayonProduct>;
        },

        createItem: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.createProduct({
                    createProductRequest: {
                        ...data,
                        type: 'NewPayonProduct',
                    } as NewPayonProduct,
                }),
            );
        },

        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.updateProduct({
                    id: Number(id),
                    updateProductRequest: {
                        ...data,
                        type: 'PayonProductUpdate',
                    } as PayonProductUpdate,
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<PayonProductsFilter>(
        PayonProductsFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<ProductListEntry>('name');
}

export const PayonProductsStore = new PayonProductsStoreClass();
