import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Router } from 'src/core/router/Router';
import { SearchParamsOptions, UrlHelper } from 'src/utils/UrlHelper';

const setSearchParams = (params: Record<string, any>) => {
    Router.setSearchParams(params);
};

const applySearchParams = (
    params: Record<string, any>,
    searchParamsOptions?: SearchParamsOptions,
) => {
    Router.applySearchParams(params, searchParamsOptions);
};

const resetSearchParams = () => {
    Router.setSearchParams({});
};

export function useSearchParams(searchParamsOptions?: SearchParamsOptions) {
    const location = useLocation();
    const searchParams = useMemo(() => {
        return UrlHelper.parseSearchParams(
            location.search,
            searchParamsOptions,
        );
    }, [location.search]);

    return {
        params: searchParams,
        setSearchParams,
        applySearchParams,
        resetSearchParams,
    };
}
