import { CreditScoringListEntry } from 'src/generated-api-client';
import { individualsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class IndividualCreditScoringHistoryStoreClass extends BasicStore<
    CreditScoringListEntry,
    any,
    any,
    any,
    any
> {
    constructor(private individualId: number) {
        super();
    }

    api: BasicStoreApi<CreditScoringListEntry, any, any, any> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getCsHistory({
                    id: this.individualId,
                }),
            );

            return result || [];
        },
    };
}

export const IndividualCreditScoringHistoryStore = new EntityProvider(
    IndividualCreditScoringHistoryStoreClass,
);
