import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

export const UnknownCreateCollateralForm = observer(() => {
    return (
        <StyledWrapper>
            <StyledText>
                {t(
                    'Component.UnknownCreateCollateralForm.Text',
                    'Sorry, the Form was not found.',
                )}
            </StyledText>
        </StyledWrapper>
    );
});

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
const StyledWrapper = styled.div`
    position: relative;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
`;
