import { observer } from 'mobx-react-lite';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { useEntityId } from 'src/hooks/useEntityId';
import { getGroupCompaniesActions } from 'src/pages/Settings/CustomerGroups/CustomerGroupCompaniesPage/Components/GroupCompaniesActions.config';

export const GroupCompaniesActions = observer(() => {
    const { entityId } = useEntityId();

    const actions = getGroupCompaniesActions(entityId).filter(
        (action) => !action.disabled,
    );
    const hideActions = actions.length === 0;

    if (hideActions) {
        return null;
    }

    return <Dropdown items={actions} />;
});
