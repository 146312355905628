import { action, computed } from 'mobx';
import {
    IdNameEntry,
    InsuranceUpdate,
    NewInsurance,
} from 'src/generated-api-client';
import { insuranceApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class InsuranceStoreClass extends BasicStore<
    any,
    any,
    any,
    NewInsurance
> {
    api: BasicStoreApi<any, InsuranceUpdate, NewInsurance> = {
        createItem: (data): any => {
            return RequestHelper.unwrapFromAxiosPromise(
                insuranceApi.createInsurance({
                    collateralId: Number(this.collateralId),
                    newInsurance: data,
                }),
            );
        },
        updateItem: (insuranceId, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                insuranceApi.updateInsurance({
                    id: Number(insuranceId),
                    insuranceUpdate: data,
                }),
            );
        },
        removeItem: (insuranceId) => {
            return RequestHelper.unwrapFromAxiosPromise(
                insuranceApi.deleteInsurance({ id: Number(insuranceId) }),
            );
        },
    };

    insuranceCompanyOptionsLoader = new AsyncOperationWithStatus(() => {
        return RequestHelper.unwrapFromAxiosPromise(
            insuranceApi.getInsuranceCompanyOptions(),
        );
    });

    @action
    async getInsuranceCompanyOptions() {
        await this.insuranceCompanyOptionsLoader.call();
    }

    insuranceProductOptionsLoader = new AsyncOperationWithStatus(() => {
        return RequestHelper.unwrapFromAxiosPromise(
            insuranceApi.getInsuranceProductOptions(),
        );
    });

    @action
    async getInsuranceProductOptions() {
        await this.insuranceProductOptionsLoader.call();
    }

    @computed get insuranceProductOptions() {
        return this.insuranceProductOptionsLoader.data?.map(asOption);
    }

    @computed get insuranceCompanyOptions() {
        return this.insuranceCompanyOptionsLoader.data?.map(asOption);
    }

    constructor(private collateralId: number) {
        super();
    }
}

export const InsuranceStore = new EntityProvider(InsuranceStoreClass);

const asOption = (data: IdNameEntry) => ({
    label: data.name,
    value: data.id,
});
