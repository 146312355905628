import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { TaskDecisionType } from 'src/generated-api-client';

export const N22EnterIATUserDecisionApproveVariables = observer(() => {
    return (
        <Row gutter={16}>
            <HiddenField
                name="type"
                initialValue={TaskDecisionType.N2_2_APPROVE}
            />
            <Col xs={24}>
                <TextAreaField
                    name="comment"
                    label={t('Task.Form.N22EnterIATUserDecision.Comment')}
                />
            </Col>
        </Row>
    );
});
