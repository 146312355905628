import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { FilterForm } from 'src/components/Form/FilterForm';
import { PlusIcon } from 'src/components/Icons/Icons';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { PledgedProductsFilterForm } from 'src/pages/LoanProducts/PledgedProductsPage/PledgedProductsFilterForm';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { PledgedProductsStore } from 'src/stores/PledgedProductsStore/PledgedProductsStore';

type PledgedProductsHeaderPanelProps = {
    title: React.ReactNode;
};

const store = PledgedProductsStore;

export const PledgedProductsHeaderPanel = observer(
    ({ title }: PledgedProductsHeaderPanelProps) => {
        const canCreate = AppUser.hasPermissions(UserPermission.ProductCreate);

        return (
            <div>
                <FilterForm
                    store={store}
                    title={title}
                    panelExtra={
                        canCreate && (
                            <Button
                                icon={<PlusIcon />}
                                type="primary"
                                onClick={() =>
                                    Router.navigate(
                                        RoutePaths.productPledgedCreate,
                                    )
                                }
                            >
                                {t('Page.PledgedProducts.Button.CreateProduct')}
                            </Button>
                        )
                    }
                >
                    <PledgedProductsFilterForm />
                </FilterForm>
            </div>
        );
    },
);
