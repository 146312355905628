import { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListPage } from 'src/components/ListPage/ListPage';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { UserListItem } from 'src/generated-api-client';
import { RolesHeaderPanel } from 'src/pages/RolesPage/RolesHeaderPanel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';

export const RolesPage = observer(() => {
    return (
        <ListPage
            store={RolesStore}
            columns={columns}
            header={<RolesHeaderPanel />}
        />
    );
});

export const columns: ColumnsType<UserListItem> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.Roles.List.Id" />,
        render: (roleId: string) => {
            const canReadDetails = AppUser.hasPermissions(
                UserPermission.RoleReadDetails,
            );

            if (canReadDetails) {
                return (
                    <Link to={EntityLinks.roles.itemDetails(roleId)}>
                        {roleId}
                    </Link>
                );
            }

            return roleId;
        },
        sorter: true,
        width: 30,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: <Trans i18nKey="Page.Roles.List.Name" />,
    },
    {
        key: 'description',
        dataIndex: 'description',
        title: <Trans i18nKey="Page.Roles.List.Description" />,
    },
    {
        key: 'startPage',
        dataIndex: 'startPage',
        title: <Trans i18nKey="Page.Roles.List.StartPage" />,
        sorter: true,
        render(startPage) {
            return <EnumView enumName="START_PAGE" value={startPage} />;
        },
    },
];
