import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';

export const CreateCollateralEvaluationVariables = observer(() => {
    return (
        <Card title={t('Component.CreateCollateralEvaluationVariables.Title')}>
            <Row gutter={16}>
                <Col xs={6}>
                    <NumberField
                        name={['evaluation', 'rmValue']}
                        label={t(
                            'Component.CreateCollateralEvaluationVariables.CIAValue',
                        )}
                        thousandDivider
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={6}>
                    <NumberField
                        name={['evaluation', 'marketValue']}
                        label={t(
                            'Component.CreateCollateralEvaluationVariables.MarketValue',
                        )}
                        thousandDivider
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={6}>
                    <NumberField
                        name={['evaluation', 'mutuallyAgreedValuation']}
                        label={t(
                            'Component.CreateCollateralEvaluationVariables.MutuallyAgreedValuation',
                        )}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.numberRange(0, 100),
                        ]}
                    />
                </Col>
            </Row>
        </Card>
    );
});
