import { Col, Form as AntForm, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { Insurance, InsuranceUpdate } from 'src/generated-api-client';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { InsuranceStore } from 'src/stores/InsuranceStore/InsuranceStore';
import { DateHelper } from 'src/utils/DateHelper';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type EditCollateralInsuranceDialogFnProps = ModalRendererProps<{
    collateralId: number;
    insurance: Insurance;
}>;

const EditCollateralInsuranceDialogFn = observer(
    ({
        collateralId,
        insurance,
        ...props
    }: EditCollateralInsuranceDialogFnProps) => {
        const [form] = AntForm.useForm();
        const registeredDate = AntForm.useWatch('registeredDate', form);
        const initialValues = getInitialValues(insurance);
        const store = InsuranceStore.getInstance(Number(collateralId));

        useEffect(() => {
            Promise.all([
                store.getInsuranceCompanyOptions(),
                store.getInsuranceProductOptions(),
            ]);

            return () => {
                store.updateItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );

        return (
            <Modal
                isLoading={store.updateItemLoader.isLoading}
                form={form}
                title={t('Dialog.EditCollateralInsurance.Title')}
                cancelText={t('Component.Modal.Btn.Back')}
                initialValues={initialValues}
                onFinish={async (values) => {
                    const convertedValues =
                        convertFormValuesToSubmitValues(values);

                    await store.update(insurance.id, convertedValues);
                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }
                    if (!store.updateItemLoader.hasError) {
                        CollateralsStore.loadItem(collateralId);
                    }
                }}
                width={1000}
                formProps={{ fields }}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={8}>
                        <SelectField
                            name="insuranceProductId"
                            label={t(
                                'Dialog.EditCollateralInsuranceD.InsuranceProductId',
                            )}
                            options={store.insuranceProductOptions}
                            loading={
                                store.insuranceProductOptionsLoader.isLoading
                            }
                        />
                    </Col>
                    <Col xs={8}>
                        <SelectField
                            name="insuranceCompanyId"
                            label={t(
                                'Dialog.EditCollateralInsuranceD.InsuranceCompanyId',
                            )}
                            options={store.insuranceCompanyOptions}
                            loading={
                                store.insuranceCompanyOptionsLoader.isLoading
                            }
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="brokerage"
                            label={t(
                                'Dialog.EditCollateralInsuranceD.Brokerage',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <NumberField
                            name="amount"
                            label={t('Dialog.EditCollateralInsuranceD.Amount')}
                            thousandDivider
                            rules={[
                                ValidationRules.required,
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col xs={8}>
                        <DatePickerField
                            name="registeredDate"
                            label={t(
                                'Dialog.EditCollateralInsuranceD.RegisteredDate',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <DatePickerField
                            name="expireDate"
                            label={t(
                                'Dialog.EditCollateralInsuranceD.ExpireDate',
                            )}
                            dependencies={['registeredDate']}
                            disabledDate={(date) => {
                                return date.isBefore(
                                    moment(registeredDate).startOf('day'),
                                );
                            }}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.dateIsAfter(
                                    moment(registeredDate).startOf('day'),
                                ),
                            ]}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const EditCollateralInsuranceDialog = addModalRenderer(
    EditCollateralInsuranceDialogFn,
);

const getInitialValues = (values?: Insurance) => {
    return {
        ...values,
        expireDate: values?.expireDate && moment(values.expireDate),
        registeredDate: values?.registeredDate && moment(values.registeredDate),
        insuranceCompanyId: values?.insuranceCompany.id,
        insuranceProductId: values?.insuranceProduct?.id,
    } as InsuranceUpdate;
};

const convertFormValuesToSubmitValues = (values: InsuranceUpdate) => {
    return {
        ...values,
        registeredDate: DateHelper.formatISODate(values.registeredDate) || '',
        expireDate: DateHelper.formatISODate(values.expireDate),
    } as InsuranceUpdate;
};
