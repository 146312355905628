import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { LegalLoanListEntry } from 'src/generated-api-client';
import { useLegalEntityId } from 'src/hooks/useLegalEntityId';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { LoansStatus } from 'src/pages/LoanDetailsPage/Components/LoansStatus';
import { LegalEntityLoanInformationStore } from 'src/stores/LegalEntityLoanInformationStore/LegalEntityLoanInformationStore';
import { UrlHelper } from 'src/utils/UrlHelper';

export type LegalEntityLoanInformationTabProps = {
    viewModel: LegalEntityDetailsViewModelClass;
};

export const LegalEntityLoanInformationTab = observer(
    ({ viewModel }: LegalEntityLoanInformationTabProps) => {
        const { legalId } = useLegalEntityId();
        const store = LegalEntityLoanInformationStore.getInstance(
            Number(legalId),
        );

        useEffect(() => {
            store.loadList();
            viewModel?.loadLoanSummary();

            return () => {
                store.listLoader.reset();
                viewModel?.getLoanSummaryLoader.reset();
            };
        }, [legalId]);

        return (
            <>
                <Card
                    title={t('Page.LegalEntityDetails.LoanInfo.Summary.Title')}
                >
                    <Row gutter={16}>
                        <Col xs={6}>
                            <DataView
                                value={viewModel.loanSummary?.totalLoans}
                                label={t(
                                    'Page.LegalEntityDetails.LoanInfo.Summary.TotalLoans',
                                )}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={
                                    viewModel.loanSummary?.totalLoanApplications
                                }
                                label={t(
                                    'Page.LegalEntityDetails.LoanInfo.Summary.TotalLoanApplications',
                                )}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={viewModel.loanSummary?.overdueLoansCount}
                                label={t(
                                    'Page.LegalEntityDetails.LoanInfo.Summary.OverdueLoansCount',
                                )}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={viewModel.loanSummary?.normalLoansCount}
                                label={t(
                                    'Page.LegalEntityDetails.LoanInfo.Summary.NormalLoansCount',
                                )}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={viewModel.loanSummary?.activeLoansCount}
                                label={t(
                                    'Page.LegalEntityDetails.LoanInfo.Summary.ActiveLoansCount',
                                )}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={viewModel.loanSummary?.closedLoansCount}
                                label={t(
                                    'Page.LegalEntityDetails.LoanInfo.Summary.ClosedLoansCount',
                                )}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={
                                    viewModel.loanSummary?.activeLoansBalance
                                }
                                label={t(
                                    'Page.LegalEntityDetails.LoanInfo.Summary.ActiveLoansBalance',
                                )}
                                format="money"
                            />
                        </Col>
                    </Row>
                </Card>

                <Card title={t('Page.LegalEntityDetails.LoanInfo.List.Title')}>
                    <ListView
                        store={store}
                        columns={columns}
                        loading={store.listLoader.isLoading}
                    />
                </Card>
            </>
        );
    },
);

export const columns: ColumnsType<LegalLoanListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.LegalEntityDetails.LoanInfo.List.Id" />,
        render: (loanId: string, loan: LegalLoanListEntry) => {
            const targetUrl = UrlHelper.getTargetUrl(RoutePaths.loanDetails, {
                loanId,
            });

            return <Link to={targetUrl}>{loan.id}</Link>;
        },
    },
    {
        key: 'productType',
        dataIndex: 'productType',
        title: (
            <Trans i18nKey="Page.LegalEntityDetails.LoanInfo.List.ProductType" />
        ),
        render(value) {
            return <EnumView enumName="PRODUCT_TYPE" value={value} />;
        },
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: <Trans i18nKey="Page.LegalEntityDetails.LoanInfo.List.Amount" />,
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'disbursementDate',
        dataIndex: 'disbursementDate',
        title: (
            <Trans i18nKey="Page.LegalEntityDetails.LoanInfo.List.DisbursementDate" />
        ),
        render(value) {
            return (
                <DataView value={value} format="date-time-seconds" hideLabel />
            );
        },
    },
    {
        key: 'maxDaysOverdue',
        dataIndex: 'maxDaysOverdue',
        title: (
            <Trans i18nKey="Page.LegalEntityDetails.LoanInfo.List.MaxDaysOverdue" />
        ),
    },
    {
        key: 'daysOverdue',
        dataIndex: 'daysOverdue',
        title: (
            <Trans i18nKey="Page.LegalEntityDetails.LoanInfo.List.DaysOverdue" />
        ),
    },
    {
        key: 'loanStatus',
        dataIndex: 'loanStatus',
        title: (
            <Trans i18nKey="Page.LegalEntityDetails.LoanInfo.List.LoanStatus" />
        ),
        render(value) {
            return <LoansStatus status={value} />;
        },
    },
];
