export enum UserPermission {
    ProductRead = 'product:read',
    ProductEdit = 'product:edit',
    ProductCreate = 'product:create',

    LoanReadList = 'loan:readList',
    LoanReadDetails = 'loan:readDetails',
    LoanReadPaymentSchedule = 'loan:readPaymentSchedule',
    LoanReadOperationAuditEvents = 'loan:readOperationAuditEvents',
    LoanManageLoanStatus = 'loan:manageLoanStatus',
    LoanManageLawsuitStatus = 'loan:manageLawsuitStatus',
    LoanManageBorrowerDeathStatus = 'loan:manageBorrowerDeathStatus',

    LoanSettingsDaysOverdueRead = 'loanSettings:daysOverdueRead',
    LoanSettingsProvisionRatesRead = 'loanSettings:provisionRatesRead',
    LoanSettingsScoringRead = 'loanSettings:scoringRead',
    LoanSettingsProductLimitRead = 'loanSettings:productLimitRead',
    LoanSettingsProductLimitRiskPriceRead = 'loanSettings:productLimitRiskPriceRead',
    LoanSettingsRiskyGroupRead = 'loanSettings:riskyGroupRead',
    LoanSettingsDaysOverdueEdit = 'loanSettings:daysOverdueEdit',
    LoanSettingsProvisionRatesEdit = 'loanSettings:provisionRatesEdit',
    LoanSettingsScoringEdit = 'loanSettings:scoringEdit',
    LoanSettingsProductLimitRiskPriceEdit = 'loanSettings:productLimitRiskPriceEdit',
    LoanSettingsRiskyGroupEdit = 'loanSettings:riskyGroupEdit',
    LoanSettingsVerificationRead = 'loanSettings:verificationRead',
    LoanSettingsVerificationEdit = 'loanSettings:verificationEdit',
    LoanSettingsTrustDefaultProductRead = 'loanSettings:trustDefaultProductRead',
    LoanSettingsTrustDefaultProductEdit = 'loanSettings:trustDefaultProductEdit',
    LoanSettingsBnplRiskCoefficientEdit = 'loanSettings:bnplRiskCoefficientEdit',
    LoanSettingsBnplIncomeAmountRangeEdit = 'loanSettings:bnplIncomeAmountRangeEdit',
    LoanSettingsBnplRiskCoefficientRead = 'loanSettings:bnplRiskCoefficientRead',
    LoanSettingsBnplIncomeAmountRangeRead = 'loanSettings:bnplIncomeAmountRangeRead',

    RestrictionListRead = 'restrictionList:read',
    RestrictionListWrite = 'restrictionList:write',
    RestrictionListRemove = 'restrictionList:remove',

    ApplicationReadList = 'application:readList',
    ApplicationReadGeneralInformation = 'application:readGeneralInformation',
    ApplicationReadRepaymentSchedule = 'application:readRepaymentSchedule',

    IndividualRead = 'individual:read',
    IndividualReadBriefDetails = 'individual:readBriefDetails',
    IndividualReadGeneralDetails = 'individual:readGeneralDetails',
    IndividualReadParticularDetails = 'individual:readParticularDetails',
    IndividualReadProductOffer = 'individual:readProductOffer',
    IndividualReadLoanInformationDetails = 'individual:readLoanInformationDetails',
    IndividualReadCs = 'individual:readCs',
    IndividualReadCibLoanData = 'individual:readCibLoanData',
    IndividualReadIncomePreview = 'individual:readIncomePreview',
    IndividualReadSalaryIncome = 'individual:readSalaryIncome',
    IndividualReadBusinessIncome = 'individual:readBusinessIncome',
    IndividualReadLivestockIncome = 'individual:readLivestockIncome',
    IndividualReadMonthlyIncome = 'individual:readMonthlyIncome',
    IndividualReadDecisions = 'individual:readDecisions',
    IndividualReadCreditScoring = 'individual:readCreditScoring',
    IndividualReadOtherInfo = 'individual:readOtherInfo',
    IndividualCreate = 'individual:create',
    IndividualUpdateGeneralInfo = 'individual:updateGeneralInfo',
    IndividualUpdateReferences = 'individual:updateReferences',
    IndividualUpdateJobInfo = 'individual:updateJobInfo',
    IndividualUpdateContactDetails = 'individual:updateContactDetails',
    IndividualUpdateBankAccounts = 'individual:updateBankAccounts',
    IndividualUpdateOtherInfo = 'individual:updateOtherInfo',
    IndividualAddIncome = 'individual:addIncome',

    DocumentCustomerRead = 'document:customerRead',
    DocumentCustomerUpload = 'document:customerUpload',

    DocumentLegalEntityRead = 'document:legalEntityRead',
    DocumentLegalEntityUpload = 'document:legalEntityUpload',
    DocumentLegalEntityDelete = 'document:legalEntityDelete',

    DocumentLoanApplicationRead = 'document:loanApplicationRead',
    DocumentLoanApplicationUpload = 'document:loanApplicationUpload',

    DocumentLoanRead = 'document:loanRead',
    DocumentLoanUpload = 'document:loanUpload',

    DocumentTemplateRead = 'documentTemplate:read',
    DocumentTemplateEdit = 'documentTemplate:edit',

    LegalReadList = 'legal:readList',
    LegalReadLoanList = 'legal:readLoanList',
    LegalReadDetails = 'legal:readDetails',
    LegalCreate = 'legal:create',
    LegalEdit = 'legal:edit',
    LegalCreateRepresentative = 'legal:representativeCreate',
    LegalDeleteRepresentative = 'legal:representativeDelete',
    LegalUpdateRepresentative = 'legal:representativeUpdate',

    MerchantReadList = 'legal:create',
    MerchantReadDetails = 'merchant:readDetails',
    MerchantCreate = 'merchant:create',
    MerchantEdit = 'merchant:edit',

    RoleReadList = 'role:readList',
    RoleReadDetails = 'role:readDetails',
    RoleCreate = 'role:create',
    RoleEdit = 'role:edit',

    UserReadList = 'user:readList',
    UserReadDetails = 'user:readDetails',
    UserCreate = 'user:create',
    UserEdit = 'user:edit',
    UserActivate = 'user:activate',
    UserDeactivate = 'user:deactivate',
    UserResendRegistrationLink = 'user:resendRegistrationLink',
    UserUpdateActivity = 'user:updateActivity',

    BankStatementReadList = 'bankStatement:readList',
    BankStatementReadDetails = 'bankStatement:readDetails',
    BankStatementAssignToLoan = 'bankStatement:assignToLoan',
    BankStatementChangeStatus = 'bankStatement:changeStatus',

    LoanReviewMaturityDateExtension = 'loan:reviewMaturityDateExtension',
    LoanReviewNewGracePeriodAllowing = 'loan:reviewNewGracePeriodAllowing',
    LoanReviewPaymentDateChanging = 'loan:reviewPaymentDateChanging',
    LoanReviewInterestRateChanging = 'loan:reviewInterestRateChanging',
    LoanReviewPaymentAmountChanging = 'loan:reviewPaymentAmountChanging',
    LoanReviewRefinancing = 'loan:reviewRefinancing',
    LoanReviewGracePeriod = 'loan:reviewGracePeriod',
    LoanReviewPaymentCancellation = 'loan:reviewPaymentCancellation',
    LoanReviewPastDatePayment = 'loan:reviewPastDatePayment',
    LoanReviewWriteOff = 'loan:reviewWriteOff',
    LoanReviewInterestAccrualStopRenew = 'loan:reviewInterestAccrualStopRenew',
    LoanProposeMaturityDateExtension = 'loan:proposeMaturityDateExtension',
    LoanProposeNewGracePeriodAllowing = 'loan:proposeNewGracePeriodAllowing',
    LoanProposePaymentDateChanging = 'loan:proposePaymentDateChanging',
    LoanProposeInterestRateChanging = 'loan:proposeInterestRateChanging',
    LoanProposePaymentAmountChanging = 'loan:proposePaymentAmountChanging',
    LoanProposeRefinancing = 'loan:proposeRefinancing',
    LoanProposeGracePeriod = 'loan:proposeGracePeriod',
    LoanProposePaymentCancellation = 'loan:proposePaymentCancellation',
    LoanProposePastDatePayment = 'loan:proposePastDatePayment',
    LoanProposeWriteOff = 'loan:proposeWriteOff',
    LoanProposeInterestAccrualStopRenew = 'loan:proposeInterestAccrualStopRenew',

    BranchReadList = 'branch:readList',
    BranchReadDetails = 'branch:readDetails',
    BranchEdit = 'branch:edit',
    BranchCreate = 'branch:create',

    CustomerSettingsSegmentationEdit = 'customerSettings:segmentationEdit',
    CustomerSettingsSegmentationRead = 'customerSettings:segmentationRead',

    CollateralReadList = 'collateral:readList',
    CollateralReadDetails = 'collateral:readDetails',
    CollateralCreate = 'collateral:create',

    TaskReadUnassignedList = 'task:readUnassignedList',

    CollectionReadList = 'collection:readList',
    CollectionReadDetails = 'collection:readDetails',
    CollectionReassign = 'collection:reassignment',
    CollectionAssignmentSettings = 'collection:assignmentSettings',
    CollectionRecordActivity = 'collection:recordActivity',
    CollectionReadOwnList = 'collection:readOwnList',
    CollectionLitigationSpecialist = 'collection:litigationSpecialist',
    CollectionDebtCollector = 'collection:debtCollector',
    CollectionPartTimeWorker = 'collection:partTimeWorker',
    CollectionEditLevel = 'collection:editCollectionLevel',
    CollectionReadActivityList = 'collection:readActivityList',
    CollectionReadActivityDetails = 'collection:readActivityDetails',
}
