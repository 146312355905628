/* eslint-disable max-lines */
import { Button, Col, Form as AntdForm, Row, Space } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ColumnsType } from 'antd/lib/table';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Card } from 'src/components/Card/Card';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { Form } from 'src/components/Form/Form';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { CirclePlusIcon } from 'src/components/Icons/Icons';
import { ListView } from 'src/components/ListView/ListView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    Localization,
    NewCivilServant,
    NewDebtCollectionActivity,
} from 'src/generated-api-client';
import { useCollectionId } from 'src/hooks/useCollectionId';
import { RecordActivityCivilServantsListActions } from 'src/pages/CollectionDetailsPage/RecordActivityPage/Components/RecordActivityCivilServantsListActions';
import { CheckActivityResultDialog } from 'src/pages/CollectionDetailsPage/RecordActivityPage/Dialogs/CheckActivityResultDialog';
import { CreateCivilServantDialog } from 'src/pages/CollectionDetailsPage/RecordActivityPage/Dialogs/CreateCivilServantDialog';
import {
    CollectionActivityGeneralOptionStore,
    TypeDictionaryUnitWithId,
} from 'src/stores/CollectionActivityGeneralOptionStore/CollectionActivityGeneralOptionStore';
import { DebtCollectionActivitesStore } from 'src/stores/DebtCollectionsActivityStore/DebtCollectionsActivityStore';
import { DebtCollectionsStore } from 'src/stores/DebtCollectionsStore/DebtCollectionsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { UrlHelper } from 'src/utils/UrlHelper';

export const RecordActivityPage = observer(() => {
    const { collectionId } = useCollectionId();
    const collection = DebtCollectionsStore?.currentItem;

    const store = DebtCollectionActivitesStore.getInstance(collectionId);

    const [form] = AntdForm.useForm();

    const [civilServantsList, setCivilServantsList] = useState<any[]>([]);

    const fields = FormHelper.getFieldDataItems(
        store.createDebtCollectionActivityLoader.errorData,
    );

    useEffect(() => {
        DebtCollectionsStore.loadItem(collectionId);
        CollectionActivityGeneralOptionStore.listLoader.call();

        return () => {
            store.itemLoader.reset();
            CollectionActivityGeneralOptionStore.listLoader.reset();
            store.createDebtCollectionActivityLoader.reset();
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            collectionLevel: collection?.level,
            overdueReason: collection?.overdueReason,
        });
    }, [collection]);

    const generalTypeValue = AntdForm.useWatch('generalType', form);
    const typeValue = AntdForm.useWatch('type', form);

    const generalTypeOptions = useMemo(() => {
        return CollectionActivityGeneralOptionStore.getGeneralTypes().map(
            asOption,
        );
    }, [CollectionActivityGeneralOptionStore.list]);

    const typeOptions = useMemo(() => {
        return generalTypeValue
            ? CollectionActivityGeneralOptionStore.getTypes(
                  generalTypeValue,
              )?.map(asOption)
            : [];
    }, [CollectionActivityGeneralOptionStore.list, generalTypeValue]);

    const subTypeOptions = useMemo(() => {
        return generalTypeValue && typeValue
            ? CollectionActivityGeneralOptionStore.getSubTypes(
                  generalTypeValue,
                  typeValue,
              )?.map(asOption)
            : [];
    }, [
        CollectionActivityGeneralOptionStore.list,
        generalTypeValue,
        typeValue,
    ]);

    useEffect(() => {
        const isTouchedGeneralType = form.isFieldTouched('generalType');
        if (isTouchedGeneralType) {
            form.setFields([
                {
                    name: 'type',
                    value: undefined,
                },
                {
                    name: 'subType',
                    value: undefined,
                },
            ]);
        }
    }, [generalTypeValue]);

    useEffect(() => {
        const isTouchedType = form.isFieldTouched('type');
        if (isTouchedType) {
            form.setFields([
                {
                    name: 'subType',
                    value: undefined,
                },
            ]);
        }
    }, [typeValue]);

    return (
        <BasePage
            title={t('Page.CollectionRecordActivity.Title')}
            isLoading={
                store.createDebtCollectionActivityLoader.isLoading ||
                DebtCollectionsStore.itemLoader?.isLoading
            }
        >
            <Form
                form={form}
                onFinish={(values: NewDebtCollectionActivity) => {
                    const newActivityData = {
                        ...values,
                        civilServants: civilServantsList,
                    };

                    CheckActivityResultDialog.render({
                        store,
                        collectionId,
                        newActivityData,
                    });
                }}
                onReset={() => {
                    setCivilServantsList([]);

                    const targetUrl = UrlHelper.getTargetUrl(
                        RoutePaths.collectionRecordActivity,
                        {
                            collectionId,
                        },
                    );

                    Router.navigate(targetUrl);
                }}
                fields={fields}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Card
                            title={t(
                                'Page.CollectionRecordActivity.GeneralInfo.Title',
                            )}
                        >
                            <Row gutter={16}>
                                <Col span={8}>
                                    <SelectField
                                        name="generalType"
                                        label={t(
                                            'Page.CollectionRecordActivity.GeneralInfo.GeneralType',
                                        )}
                                        rules={[ValidationRules.required]}
                                        options={generalTypeOptions}
                                        showSearch
                                    />
                                </Col>
                                <Col span={8}>
                                    <SelectField
                                        name="type"
                                        label={t(
                                            'Page.CollectionRecordActivity.GeneralInfo.Type',
                                        )}
                                        rules={[ValidationRules.required]}
                                        options={typeOptions}
                                        showSearch
                                    />
                                </Col>
                                <Col span={8}>
                                    <SelectField
                                        name="subType"
                                        label={t(
                                            'Page.CollectionRecordActivity.GeneralInfo.SubType',
                                        )}
                                        options={subTypeOptions}
                                        showSearch
                                    />
                                </Col>
                                <Col span={8}>
                                    <EnumSelectField
                                        name="contactedPerson"
                                        label={t(
                                            'Page.CollectionRecordActivity.GeneralInfo.ContactedPerson',
                                        )}
                                        rules={[ValidationRules.required]}
                                        enumName="COLLECTION_ACTIVITY_CONTACTED_PERSON"
                                        showSearch
                                    />
                                </Col>

                                <Col span={8}>
                                    <EnumSelectField
                                        name="collectionLevel"
                                        label={t(
                                            'Page.CollectionRecordActivity.GeneralInfo.CollectionLevel',
                                        )}
                                        rules={[ValidationRules.required]}
                                        enumName="COLLECTION_LEVEL"
                                        showSearch
                                    />
                                </Col>

                                <Col span={8}>
                                    <EnumSelectField
                                        name="overdueReason"
                                        label={t(
                                            'Page.CollectionRecordActivity.GeneralInfo.OverdueReason',
                                        )}
                                        enumName="COLLECTION_ACTIVITY_OVERDUE_REASON"
                                        showSearch
                                    />
                                </Col>

                                <Col span={8}>
                                    <EnumSelectField
                                        name="nextAction"
                                        label={t(
                                            'Page.CollectionRecordActivity.GeneralInfo.NextAction',
                                        )}
                                        enumName="COLLECTION_ACTIVITY_NEXT_ACTION"
                                        rules={[ValidationRules.required]}
                                        showSearch
                                    />
                                </Col>

                                <Col span={8}>
                                    <DatePickerField
                                        name="nextActionDate"
                                        label={t(
                                            'Page.CollectionRecordActivity.GeneralInfo.NextActionDate',
                                        )}
                                        rules={[ValidationRules.required]}
                                        disabledDate={(date) => {
                                            return date.isBefore(
                                                moment().startOf('day'),
                                            );
                                        }}
                                    />
                                </Col>

                                <Col span={8}>
                                    <DatePickerField
                                        name="commitmentDate"
                                        label={t(
                                            'Page.CollectionRecordActivity.GeneralInfo.CommitmentDate',
                                        )}
                                    />
                                </Col>

                                <Col span={8}>
                                    <NumberField
                                        name="commitmentAmount"
                                        label={t(
                                            'Page.CollectionRecordActivity.GeneralInfo.CommitmentAmount',
                                        )}
                                        thousandDivider
                                        min={0}
                                        rules={[
                                            ValidationRules.greaterThanZero,
                                        ]}
                                    />
                                </Col>

                                <Col span={16}>
                                    <TextAreaField
                                        rows={1}
                                        name="comment"
                                        label={t(
                                            'Page.CollectionRecordActivity.GeneralInfo.Comment',
                                        )}
                                        maxLength={1000}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col span={24}>
                        <Card
                            title={t(
                                'Page.CollectionRecordActivity.CivilServant.Title',
                            )}
                            extra={
                                <Button
                                    type="dashed"
                                    icon={<CirclePlusIcon />}
                                    onClick={() => {
                                        CreateCivilServantDialog.render({
                                            onSave: (values: any) => {
                                                setCivilServantsList((r) => [
                                                    ...r,
                                                    values,
                                                ]);
                                            },
                                        });
                                    }}
                                >
                                    {t(
                                        'Page.CollectionRecordActivity.CivilServant.Add',
                                    )}
                                </Button>
                            }
                        >
                            <ListView
                                columns={civilServantsColumns(
                                    setCivilServantsList,
                                )}
                                dataSource={civilServantsList}
                            />
                        </Card>
                    </Col>

                    <Col span={24}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                {t('Page.CollectionRecordActivity.Btn.Save')}
                            </Button>
                            <Button type="default" htmlType="reset">
                                {t('Page.CollectionRecordActivity.Btn.Reset')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </BasePage>
    );
});

const civilServantsColumns = (
    onSave: Dispatch<SetStateAction<any[]>>,
): ColumnsType<NewCivilServant> => {
    return [
        {
            key: 'id',
            dataIndex: 'id',
            width: 100,
            title: (
                <Trans i18nKey="Page.CollectionRecordActivity.CivilServant.List.Id" />
            ),
            render: (_, _record, index) => index + 1,
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: (
                <Trans i18nKey="Page.CollectionRecordActivity.CivilServant.List.Name" />
            ),
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: (
                <Trans i18nKey="Page.CollectionRecordActivity.CivilServant.List.PhoneNumber" />
            ),
        },
        {
            key: 'workPhoneNumber',
            dataIndex: 'workPhoneNumber',
            title: (
                <Trans i18nKey="Page.CollectionRecordActivity.CivilServant.List.WorkPhoneNumber" />
            ),
        },
        {
            key: 'position',
            dataIndex: 'position',
            title: (
                <Trans i18nKey="Page.CollectionRecordActivity.CivilServant.List.Position" />
            ),
        },
        {
            key: 'organization',
            dataIndex: 'organization',
            title: (
                <Trans i18nKey="Page.CollectionRecordActivity.CivilServant.List.Organization" />
            ),
        },
        {
            key: 'workAddress',
            dataIndex: 'workAddress',
            title: (
                <Trans i18nKey="Page.CollectionRecordActivity.CivilServant.List.WorkAddress" />
            ),
        },
        {
            key: 'actions',
            render: (_, record, index) => {
                return (
                    <RecordActivityCivilServantsListActions
                        civilServant={record as NewCivilServant}
                        civilServantIndex={index}
                        onSave={onSave}
                    />
                );
            },
        },
    ];
};

const asOption = (value: TypeDictionaryUnitWithId) => {
    const language = i18next.language as keyof Localization;

    return {
        value: value.key,
        label: value.l10n?.[language],
    } as DefaultOptionType;
};
