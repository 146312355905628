import { action, computed, observable } from 'mobx';
import { GetSetting200Response, SettingType } from 'src/generated-api-client';
import { settingsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export type TableDataColumnDefinition = {
    key?: string;
    dataIndex?: string;
    payload?: any;
};

export type TableData<Data = any> = {
    type?: string;
    columns?: TableDataColumnDefinition[];
    rows?: Data[];
};
export type BaseTableSettingsStoreApi<Data> = {
    load?: () => Promise<TableData<Data>>;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    update?: (data: TableData<Data>) => Promise<void>;
};

export class TableSettingsStoreClass<Data = any> {
    constructor(private settingsType: SettingType) {}

    api: BaseTableSettingsStoreApi<Data> = {
        load: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                settingsApi.getSetting({ settingType: this.settingsType }),
            ) as Promise<TableData<Data>>;
        },

        update: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                settingsApi.updateSettings({
                    getSetting200Response: {
                        ...data,
                        type: this.settingsType,
                    } as GetSetting200Response,
                }),
            );
        },
    };

    @observable newTable?: TableData<Data>;

    loadTableLoader = new AsyncOperationWithStatus(() => this.api.load?.());
    updateTableLoader = new AsyncOperationWithStatus((table: TableData<Data>) =>
        this.api.update?.(table),
    );

    @action load() {
        return this.loadTableLoader.call();
    }

    @action async update(table: TableData<Data>) {
        await this.updateTableLoader.call(table);
        if (!this.updateTableLoader.hasError) {
            this.newTable = table;
            this.updateTableLoader.reset();
            await this.load();
        }
    }

    @action async reset() {
        await this.loadTableLoader.reset();
        await this.updateTableLoader.reset();
    }

    @computed get table() {
        return this.newTable || this.loadTableLoader.data;
    }
}

export const TableSettingsStore = new EntityProvider<
    SettingType,
    TableSettingsStoreClass
>(TableSettingsStoreClass);
