import { Col, Row } from 'antd';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { CustomerPaymentAccount, Localization } from 'src/generated-api-client';
import { DataInformationCard } from 'src/pages/LegalEntityDetailsPage/Components/DataInformationCard';
import { UpdateBankAccountDialog } from 'src/pages/LegalEntityDetailsPage/Dialogs/LegalEntityInformation/UpdateBankAccountDialog';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export type LegalEntityBankAccountCardProps = {
    viewModel?: LegalEntityDetailsViewModelClass;
};

export const LegalEntityBankAccountCard = observer(
    ({ viewModel }: LegalEntityBankAccountCardProps) => {
        const paymentAccounts = viewModel?.item?.paymentAccounts || [
            {} as CustomerPaymentAccount,
        ];

        return (
            <DataInformationCard
                title={t('Page.LegalEntityDetails.BankAccount.Title')}
                isLoading={viewModel?.itemLoader.isLoading}
                hasData={(viewModel?.item?.paymentAccounts?.length || 0) > 0}
                canUpdate={AppUser.hasPermissions(UserPermission.LegalEdit)}
                onAdd={() => {
                    UpdateBankAccountDialog.render({
                        viewModel,
                    });
                }}
            >
                {paymentAccounts.map(
                    (paymentAccount?: CustomerPaymentAccount) => {
                        return (
                            <Row gutter={16} key={paymentAccount?.id}>
                                <Col xs={6}>
                                    <DataView
                                        value={
                                            paymentAccount?.bankInformation
                                                ?.l10n?.[
                                                i18next.language as keyof Localization
                                            ]
                                        }
                                        label={t(
                                            'Page.LegalEntityDetails.BankAccount.Bank',
                                            'Bank',
                                        )}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <DataView
                                        value={paymentAccount?.accountNumber}
                                        label={t(
                                            'Page.LegalEntityDetails.BankAccount.AccountNumber',
                                            'Bank account number',
                                        )}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <DataView
                                        value={paymentAccount?.accountName}
                                        label={t(
                                            'Page.LegalEntityDetails.BankAccount.AccountName',
                                            'Account name',
                                        )}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <DataView
                                        value={paymentAccount?.mainAccount}
                                        label={t(
                                            'Page.LegalEntityDetails.BankAccount.MainAccount',
                                            'Main account',
                                        )}
                                        format="yes-no"
                                    />
                                </Col>
                            </Row>
                        );
                    },
                )}
            </DataInformationCard>
        );
    },
);
