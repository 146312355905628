import { Button, Col, Row, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N17Task } from 'src/generated-api-client';
import { SubmitTaskDialog } from 'src/pages/TaskDetailsPage/Dialogs/SubmitTaskDialog';
import { N17ChooseDmtAssigneeTaskApproveVariables } from 'src/pages/TaskDetailsPage/N17ChooseDmtAssigneeTask/N17ChooseDmtAssigneeTaskApproveVariables';
import { N17ChooseDmtAssigneeTaskCancelVariables } from 'src/pages/TaskDetailsPage/N17ChooseDmtAssigneeTask/N17ChooseDmtAssigneeTaskCancelVariables';
import { TaskViewModelClass } from 'src/pages/TaskViewPage/TaskViewModel';

type TaskProps = {
    viewModel: TaskViewModelClass;
};

export const N17ChooseDmtAssigneeTask = observer(({ viewModel }: TaskProps) => {
    const task = (viewModel.currentTask as N17Task) || {};

    return (
        <TaskForm
            fields={viewModel.submitTaskErrorFields}
            onFinish={async (values) => {
                await viewModel.submitTask(values);
                if (!viewModel.submitTaskLoaderHasError) {
                    Router.navigate(RoutePaths.tasks);
                }
            }}
        >
            <Row gutter={[16, 16]}>
                <N17ChooseDmtAssigneeTaskApproveVariables task={task} />

                <Col span={24}>
                    <Space size={20}>
                        <Button
                            type={
                                viewModel.isCurrentTaskEqualWorkingTask
                                    ? 'primary'
                                    : 'default'
                            }
                            htmlType="submit"
                        >
                            {t('Task.Form.N17Task.Btn.Approve')}
                        </Button>
                        <Button
                            onClick={() => {
                                SubmitTaskDialog.render({
                                    viewModel,
                                    title: t('Dialog.N17TaskCancel.Title'),
                                    children: (
                                        <N17ChooseDmtAssigneeTaskCancelVariables />
                                    ),
                                });
                            }}
                        >
                            {t('Task.Form.N17Task.Btn.Cancel')}
                        </Button>
                        <Button
                            onClick={() => {
                                Router.navigate(RoutePaths.tasks);
                            }}
                        >
                            {t('Task.Form.Btn.Back')}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </TaskForm>
    );
});
