import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ReviewActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ReviewActionDialogComponent';
import { ReviewWriteOffActionStore } from 'src/stores/LoanActionsStores/ReviewWriteOffActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const { Text } = Typography;

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

export const ReviewWriteOffDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ReviewWriteOffActionStore.getInstance(Number(loanId));
        const title = t('Dialog.ReviewWriteOff.Title');

        return (
            <ReviewActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <StyledText>{t('Dialog.ReviewWriteOff.Notice')}</StyledText>
            </ReviewActionDialogComponent>
        );
    },
);

export const ReviewWriteOffDialog = addModalRenderer(ReviewWriteOffDialogFn);

const StyledText = styled(Text)`
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #4d6082;
`;
