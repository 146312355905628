import { notification, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { HiddenField } from 'src/components/Form/HiddenField';
import { Modal } from 'src/components/Modal/Modal';
import {
    CoBorrowerStatus,
    N13IndividualCoBorrower,
} from 'src/generated-api-client';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type ApproveCoBorrowerDialogFnProps = ModalRendererProps<{
    coBorrower: N13IndividualCoBorrower;
    viewModel: N13TaskViewModelClass;
}>;

const ApproveCoBorrowerDialogFn = observer(
    ({ coBorrower, viewModel, ...props }: ApproveCoBorrowerDialogFnProps) => {
        useEffect(() => {
            return () => {
                viewModel.updateCoBorrowerLoader.reset();
            };
        }, []);

        return (
            <Modal
                isLoading={viewModel.updateCoBorrowerLoader.isLoading}
                title={t('Dialog.ApproveCoBorrower.Title')}
                onFinish={async (values) => {
                    await viewModel.updateCoBorrower(coBorrower?.id, values);

                    if (viewModel.updateCoBorrowerLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel.updateCoBorrowerLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.ApproveCoBorrower.Message.Success',
                            ),
                        });
                        viewModel.load();
                    }
                }}
                {...props}
            >
                <StyledText>{t('Dialog.ApproveCoBorrower.Text')}</StyledText>

                <HiddenField name="type" initialValue="CoBorrowerSetStatus" />
                <HiddenField
                    name="status"
                    initialValue={CoBorrowerStatus.APPROVED}
                />
            </Modal>
        );
    },
);

export const ApproveCoBorrowerDialog = addModalRenderer(
    ApproveCoBorrowerDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 16px;
    color: #4d6082;
    height: 100px;
`;
