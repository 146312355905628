import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';

export const TrustPledgedProductsFilterForm = observer(() => {
    return (
        <>
            <NumberField
                name="externalId"
                label={t('Page.TrustPledgedProducts.Filter.ExternalId')}
                onlyInteger
            />
            <TextField
                name="name"
                label={t('Page.TrustPledgedProducts.Filter.Name')}
            />
            <NumberField
                name="interestRate"
                label={t('Page.TrustPledgedProducts.Filter.InterestRate')}
            />
            <EnumSelectField
                mode="multiple"
                name="statuses"
                label={t('Page.TrustPledgedProducts.Filter.Status')}
                enumName="PRODUCT_STATUS"
            />
        </>
    );
});
