import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { ProposeActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ProposeActionDialogComponent';
import { ProposePastDateRepaymentActionStore } from 'src/stores/LoanActionsStores/ProposePastDateRepaymentActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

export const ProposePastDateRepaymentDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ProposePastDateRepaymentActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ProposePastDateRepayment.Title');

        return (
            <ProposeActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <StyledText>
                    {t('Dialog.ProposePastDateRepayment.Text')}
                </StyledText>
                <DatePickerField
                    name="paymentDate"
                    label={t('Dialog.ProposePastDateRepayment.PaymentDate')}
                    disabledDate={(date) => {
                        return !date.isBetween(
                            moment(
                                store.initialValues?.leastPossiblePaymentDate,
                            ).startOf('day'),
                            moment().endOf('day'),
                        );
                    }}
                    rules={[ValidationRules.required]}
                />
                <NumberField
                    name="amount"
                    label={t('Dialog.ProposePastDateRepayment.Amount')}
                    thousandDivider
                    rules={[
                        ValidationRules.required,
                        ValidationRules.greaterThanZero,
                    ]}
                />
                <TextAreaField
                    name="comment"
                    label={t('Dialog.ProposePastDateRepayment.Comment')}
                    maxLength={255}
                />
            </ProposeActionDialogComponent>
        );
    },
);

export const ProposePastDateRepaymentDialog = addModalRenderer(
    ProposePastDateRepaymentDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #4d6082;
`;
