import { Button, Col, Row, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import {
    ComponentProvider,
    ComponentsMap,
} from 'src/components/ComponentProvider/ComponentProvider';
import { CircleArrowRightIcon } from 'src/components/Icons/Icons';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useLoanId } from 'src/hooks/useLoanId';
import { BnplLoanDetails } from 'src/pages/LoanDetailsPage/BnplLoan/BnplLoanDetails';
import { LoanActions } from 'src/pages/LoanDetailsPage/Components/LoanActions';
import { InstallmentLoanDetails } from 'src/pages/LoanDetailsPage/InstallmentLoan/InstallmentLoanDetails';
import { PayonLoanDetails } from 'src/pages/LoanDetailsPage/PayonLoan/PayonLoanDetails';
import { PledgedLoanDetails } from 'src/pages/LoanDetailsPage/PledgedLoan/PledgedLoanDetails';
import { TrustLoanDetails } from 'src/pages/LoanDetailsPage/TrustLoan/TrustLoanDetails';
import { UnknownLoan } from 'src/pages/LoanDetailsPage/UnknownLoan/UnknownLoan';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = LoansStore;

export const LoanDetailsPage = observer(() => {
    const { loanId } = useLoanId();

    useEffect(() => {
        store.loadItem(loanId);

        return () => {
            store.itemLoader.reset();
        };
    }, [loanId]);

    const loan = store.currentItem;
    const showApplicationInformationLink = AppUser.hasPermissions(
        UserPermission.ApplicationReadGeneralInformation,
    );

    return (
        <BasePage
            title={
                loan && (
                    <Row gutter={16}>
                        <Col flex={1}>
                            {t('Page.LoanDetails.Title', {
                                loanId: loan?.externalId,
                            })}
                        </Col>
                        <Col>
                            <Space size={16}>
                                {showApplicationInformationLink && (
                                    <Button
                                        icon={<CircleArrowRightIcon />}
                                        type="primary"
                                        onClick={() => {
                                            const targetUrl =
                                                UrlHelper.getTargetUrl(
                                                    RoutePaths.applicationDetails,
                                                    {
                                                        applicationId:
                                                            loan?.applicationId,
                                                    },
                                                );

                                            Router.navigate(targetUrl);
                                        }}
                                    >
                                        {t(
                                            'Page.LoanDetails.Button.ApplicationInformation',
                                        )}
                                    </Button>
                                )}

                                <LoanActions loan={loan} />
                            </Space>
                        </Col>
                    </Row>
                )
            }
            isLoading={store.itemLoader.isLoading}
            showOnlyLoading={!loan}
        >
            <ComponentProvider
                componentsMap={loanMap}
                currentComponentKey={String(loan?.type)}
                FallbackComponent={UnknownLoan}
            />
        </BasePage>
    );
});

export enum LoanPageType {
    Bnpl = 'BnplLoan',
    Installment = 'InstallmentLoan',
    Payon = 'PayonLoan',
    Pledged = 'PledgedLoan',
    Trust = 'TrustPledgedLoan',
}

const loanMap: ComponentsMap = {
    [LoanPageType.Bnpl]: {
        Component: BnplLoanDetails,
    },
    [LoanPageType.Installment]: {
        Component: InstallmentLoanDetails,
    },
    [LoanPageType.Payon]: {
        Component: PayonLoanDetails,
    },
    [LoanPageType.Pledged]: {
        Component: PledgedLoanDetails,
    },
    [LoanPageType.Trust]: {
        Component: TrustLoanDetails,
    },
};
