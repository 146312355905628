import { Col, notification, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { TextField } from 'src/components/Form/TextField';
import { CircleCheck } from 'src/components/Icons/Icons';
import { Modal } from 'src/components/Modal/Modal';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { NewDebtCollectionActivity } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { DebtCollectionActivitiesStoreClass } from 'src/stores/DebtCollectionsActivityStore/DebtCollectionsActivityStore';
import { EnumName, EnumStore } from 'src/stores/Resources/EnumStore/EnumStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

const enumsToLoad: EnumName[] = ['BO_USER_DEPARTMENT'];

type CheckActivityResultDialogFnProps = ModalRendererProps<{
    store: DebtCollectionActivitiesStoreClass;
    newActivityData: NewDebtCollectionActivity;
    collectionId: string;
}>;

const initialValues = () => {
    const currentUserDepartment = EnumStore.getInstance(
        'BO_USER_DEPARTMENT',
    ).getLocalizedEnum(AppUser.userProfile?.department ?? '');

    return {
        recordedDate: moment(),
        department: currentUserDepartment,
        debtCollector: AppUser.userProfile?.name,
    };
};

const CheckActivityResultDialogFn = observer(
    ({
        store,
        newActivityData,
        collectionId,
        ...props
    }: CheckActivityResultDialogFnProps) => {
        const isEnumsLoading = useEnumsLoader(enumsToLoad);

        const fields = FormHelper.getFieldDataItems(
            store.createDebtCollectionActivityLoader.errorData,
        );

        return (
            <Modal
                isLoading={
                    store.createDebtCollectionActivityLoader.isLoading ||
                    isEnumsLoading
                }
                cancelText={t('Component.Modal.Btn.Back')}
                initialValues={initialValues()}
                formProps={{
                    layout: 'vertical',
                    fields,
                }}
                onFinish={async () => {
                    await store.createDebtCollectionActivity(newActivityData);
                    if (!store.createDebtCollectionActivityLoader.hasError) {
                        notification.success({
                            message: t(
                                'Page.CollectionRecordActivity.Message.Success',
                            ),
                        });

                        const targetUrl = UrlHelper.getTargetUrl(
                            RoutePaths.collectionDetails,
                            {
                                collectionId,
                            },
                        );

                        const targetUrlWithTab = UrlHelper.getUrlWithoutHost(
                            UrlHelper.getUrlWithQueryParams(
                                { tab: 'activity' },
                                targetUrl,
                            ).toString(),
                        );

                        Router.navigate(targetUrlWithTab);
                    }

                    if (store.createDebtCollectionActivityLoader.hasError) {
                        props.onCancel();

                        return Promise.reject();
                    }
                }}
                width={640}
                {...props}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <StyledDataView
                            align="horizontal"
                            value={t('Dialog.CheckActivityResultDialog.Title')}
                            hideLabel
                            format={(value) => {
                                return (
                                    <>
                                        <CircleCheck />
                                        <StyledTitle>{value}</StyledTitle>
                                    </>
                                );
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <DataView
                            align="horizontal"
                            value={newActivityData?.type}
                            label={t('Dialog.CheckActivityResultDialog.Type')}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLECTION_ACTIVITY_TYPE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col span={24}>
                        <DataView
                            align="horizontal"
                            value={newActivityData?.subType}
                            label={t(
                                'Dialog.CheckActivityResultDialog.SubType',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLECTION_ACTIVITY_SUB_TYPE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col span={24}>
                        <DatePickerField
                            disabled
                            name="recordedDate"
                            label={t(
                                'Dialog.CheckActivityResultDialog.RecordedDate',
                            )}
                        />
                    </Col>
                    <Col span={24}>
                        <TextField
                            disabled
                            name="department"
                            label={t(
                                'Dialog.CheckActivityResultDialog.Department',
                            )}
                        />
                    </Col>
                    <Col span={24}>
                        <TextField
                            disabled
                            name="debtCollector"
                            label={t(
                                'Dialog.CheckActivityResultDialog.DebtCollector',
                            )}
                        />
                    </Col>
                    <Col xs={24}>
                        <DataView
                            hideLabel
                            value={t(
                                'Dialog.CheckActivityResultDialog.Warning',
                            )}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const CheckActivityResultDialog = addModalRenderer(
    CheckActivityResultDialogFn,
);

const StyledDataView = styled(DataView)`
    display: flex !important;
    justify-content: center;
    align-items: center;

    .app-data-view__value {
        display: flex !important;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        gap: 8px;
    }

    .ant-typography {
        margin-bottom: 0 !important;
    }
`;

const StyledTitle = styled(Typography.Title)`
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 32px !important;
    color: #2e3e5c !important;
`;
