/* eslint-disable complexity, max-lines */
import { Col, Form, notification, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { Legal } from 'src/generated-api-client';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { LegalIndustryOptionsStore } from 'src/stores/LegalIndustryOptionsStore/LegalIndustryOptionsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type UpdateGeneralInformationDialogFnProps = ModalRendererProps<{
    viewModel?: LegalEntityDetailsViewModelClass;
}>;

export const UpdateGeneralInformationDialogFn = observer(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    ({ viewModel, ...props }: UpdateGeneralInformationDialogFnProps) => {
        const [form] = Form.useForm();

        useEffect(() => {
            LegalIndustryOptionsStore.loadList();

            return () => {
                LegalIndustryOptionsStore.listLoader.reset();

                viewModel?.updateGeneralInformationLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            viewModel?.updateGeneralInformationLoader?.errorData,
        );

        const industry = Form.useWatch('industry', form);
        const isIndustryActivityTypesOptionsLoading =
            LegalIndustryOptionsStore.listLoader.isLoading;

        const { industryOptions } = LegalIndustryOptionsStore;
        const industryActivityTypes =
            LegalIndustryOptionsStore.industryActivityTypesOptions(industry);

        useEffect(() => {
            const isTouchedIndustry = form.isFieldsTouched(['industry']);
            if (isTouchedIndustry) {
                form.setFields([{ name: 'activityId', value: undefined }]);
            }
        }, [industry]);

        return (
            <Modal
                title={t('Dialog.UpdateGeneralInformation.Title')}
                isLoading={
                    isIndustryActivityTypesOptionsLoading ||
                    viewModel?.updateGeneralInformationLoader?.isLoading
                }
                onFinish={async (values) => {
                    await viewModel?.updateGeneralInformation(values);

                    if (viewModel?.updateGeneralInformationLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel?.updateGeneralInformationLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.UpdateGeneralInformation.Message.Success',
                            ),
                        });
                    }
                }}
                initialValues={getInitialValues(viewModel)}
                formProps={{ fields }}
                form={form}
                width={900}
                {...props}
            >
                <Row gutter={16}>
                    <Col sm={8}>
                        <TextField
                            name="name"
                            label={t('Page.LegalEntityCreate.CompanyName')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col sm={8}>
                        <EnumSelectField
                            enumName="ENTERPRISE_TYPE"
                            name="enterpriseType"
                            label={t('Page.LegalEntityCreate.EnterpriseType')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col sm={8}>
                        <DatePickerField
                            name="establishedDate"
                            label={t('Page.LegalEntityCreate.EstablishedDate')}
                            rules={[ValidationRules.required]}
                            disabledDate={(date) => {
                                return date.isAfter(moment().endOf('day'));
                            }}
                        />
                    </Col>
                    <Col sm={8}>
                        <TextField
                            name="stateRegistrationNumber"
                            label={t('Page.LegalEntityCreate.StateRegNum')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col sm={8}>
                        <TextField
                            name="registrationNumber"
                            label={t('Page.LegalEntityCreate.RegNum')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.minRegNumLength,
                            ]}
                        />
                    </Col>
                    <Col sm={8}>
                        <TextField
                            name="taxNumber"
                            label={t('Page.LegalEntityCreate.TaxNumber')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col sm={8}>
                        <SelectField
                            name="industry"
                            options={industryOptions}
                            label={t('Page.LegalEntityCreate.Industry')}
                            rules={[ValidationRules.required]}
                            showSearch
                        />
                    </Col>
                    <Col sm={8}>
                        <SelectField
                            name="activityId"
                            label={t('Page.LegalEntityCreate.ActivityType')}
                            options={industryActivityTypes}
                            loading={isIndustryActivityTypesOptionsLoading}
                            disabled={isIndustryActivityTypesOptionsLoading}
                            rules={[ValidationRules.required]}
                            showSearch
                        />
                    </Col>
                    <Col sm={8}>
                        <EnumSelectField
                            enumName="INCOME_SOURCE"
                            name="incomeSource"
                            label={t('Page.LegalEntityCreate.IncomeSource')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={8}>
                        <NumberField
                            name="numberOfEmployees"
                            label={t(
                                'Page.LegalEntityCreate.NumberOfEmployees',
                            )}
                            min={1}
                            onlyInteger
                            rules={[ValidationRules.greaterThanZero]}
                        />
                    </Col>

                    <Col sm={8}>
                        <NumberField
                            name="numberOfBranches"
                            label={t('Page.LegalEntityCreate.NumberOfBranches')}
                            min={1}
                            onlyInteger
                            rules={[ValidationRules.greaterThanZero]}
                        />
                    </Col>
                    <Col sm={8}>
                        <EnumSelectField
                            enumName="INVESTMENT_TYPE"
                            name="investmentType"
                            label={t('Page.LegalEntityCreate.InvestmentType')}
                        />
                    </Col>
                    <Col sm={8}>
                        <EnumSelectField
                            enumName="RATING_ORGANISATION"
                            name="ratingOrganisation"
                            label={t('Page.LegalEntityCreate.RatingOrg')}
                        />
                    </Col>
                    <Col sm={8}>
                        <TextField
                            name="rating"
                            label={t('Page.LegalEntityCreate.Rating')}
                        />
                    </Col>
                    <Col sm={8}>
                        <EnumSelectField
                            enumName="CUSTOMER_STATUS"
                            name="status"
                            label={t('Page.LegalEntityCreate.Status')}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const UpdateGeneralInformationDialog = addModalRenderer(
    UpdateGeneralInformationDialogFn,
);

const getInitialValues = (viewModel?: LegalEntityDetailsViewModelClass) => {
    const initialValues = viewModel?.item || ({} as Legal);

    return {
        ...initialValues,
        activityId: String(initialValues?.activity?.id),
        industry: String(initialValues?.industry?.id),
        establishedDate:
            initialValues?.establishedDate &&
            moment(initialValues?.establishedDate),
    };
};
