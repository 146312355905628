import { CustomerGroup, NewCustomerGroup } from 'src/generated-api-client';
import { customerGroupsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export class CustomerGroupsStoreClass extends BasicStore<
    CustomerGroup,
    CustomerGroup,
    NewCustomerGroup,
    NewCustomerGroup
> {
    api: BasicStoreApi<CustomerGroup, NewCustomerGroup, NewCustomerGroup> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                customerGroupsApi.getCustomerGroups(this.listParams),
            );
        },
        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                customerGroupsApi.getCustomerGroup({ id }),
            );
        },
        createItem: (item) => {
            return RequestHelper.unwrapFromAxiosPromise(
                customerGroupsApi.createCustomerGroup({
                    newCustomerGroup: {
                        ...item,
                        priority: Number(item.priority),
                    },
                }),
            );
        },
        updateItem: (id, item) => {
            return RequestHelper.unwrapFromAxiosPromise(
                customerGroupsApi.updateCustomerGroup({
                    id: Number(id),
                    customerGroupUpdate: {
                        ...item,
                        priority: Number(item.priority),
                    },
                }),
            );
        },

        removeItem: (id) => {
            return RequestHelper.unwrapFromAxiosPromise(
                customerGroupsApi.removeCustomerGroup({ id: Number(id) }),
            );
        },
    };

    pager?: Pager | undefined = new Pager();
}

export const CustomerGroupsStore = new CustomerGroupsStoreClass();
