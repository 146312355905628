import { t } from 'i18next';
import React from 'react';
import { ActionType, LeasingCollateral } from 'src/generated-api-client';
import { UpdateCollateralGeneralInfoDialog } from 'src/pages/CollateralDetailsPage/Dialogs/UpdateCollateralGeneralInfoDialog';
import { LeasingCollateralGeneralInfoUpdateVariables } from 'src/pages/CollateralDetailsPage/Tabs/LeasingCollateralTab/LeasingCollateralGeneralInfoUpdateVariables';
import { CollateralsHelper } from 'src/utils/CollateralsHelper';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getLeasingCollateralGeneralInfoActions = (
    collateral: LeasingCollateral,
) => {
    return [...getUIActions(), ...getServerActions(collateral)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    collateral: LeasingCollateral,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(collateral),
        collateral.actions,
    );
};

const getRegisteredServerActions = (
    collateral: LeasingCollateral,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.COLLATERAL_UPDATE,
            label: t('Collateral.Actions.Update'),
            onClick: () => {
                UpdateCollateralGeneralInfoDialog.render({
                    collateralId: collateral.id,
                    children: <LeasingCollateralGeneralInfoUpdateVariables />,
                    initialValues:
                        CollateralsHelper.getEditInitialValues(collateral),
                    covertFormValues:
                        CollateralsHelper.getConverterEditFormValuesToSubmitValues(
                            collateral,
                        ),
                });
            },
        },
    ];
};
