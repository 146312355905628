import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';

export type CommentItem = {
    label: string;
    value?: string;
};

export type TaskCommentsViewProps = {
    comments: CommentItem[];
};

export const TaskCommentsView = observer(
    ({ comments }: TaskCommentsViewProps) => {
        if (!comments) {
            return null;
        }

        return (
            <Card title={t('Component.TaskCommentsView.Title')}>
                <Row gutter={[16, 16]}>
                    {comments?.map((t) => (
                        <Col xs={12}>
                            <DataView value={t?.value} label={t?.label} />
                        </Col>
                    ))}
                </Row>
            </Card>
        );
    },
);
