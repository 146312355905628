import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { ReviewActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ReviewActionDialogComponent';
import { LoanPaymentsColumns } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposePaymentCancellationDialog';
import { ReviewPaymentCancellationActionStore } from 'src/stores/LoanActionsStores/ReviewPaymentCancellationActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

export const ReviewPaymentCancellationDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ReviewPaymentCancellationActionStore.getInstance(
            Number(loanId),
        );
        const columns = LoanPaymentsColumns;
        const title = t('Dialog.ReviewPaymentCancellation.Title');

        return (
            <ReviewActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <ListView
                    dataSource={store.initialValues?.loanPayments}
                    columns={columns}
                    rowKey="paymentId"
                />
                <StyledDataView
                    value={store.initialValues?.comment}
                    label={t('Dialog.ReviewPaymentCancellation.Comment')}
                    format="string"
                />
            </ReviewActionDialogComponent>
        );
    },
);

export const ReviewPaymentCancellationDialog = addModalRenderer(
    ReviewPaymentCancellationDialogFn,
);

const StyledDataView = styled(DataView)`
    margin: 30px 20px;
`;
