import { Button, Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { Modal } from 'src/components/Modal/Modal';
import { BankStatementsStatus } from 'src/pages/BankStatementsPage/Components/BankStatementsStatus';
import { BankStatementsStore } from 'src/stores/BankStatementsStore/BankStatementsStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type BankStatementDetailsDialogFnProps = ModalRendererProps<{
    bankStatementId?: number;
}>;

const store = BankStatementsStore;

export const BankStatementDetailsDialogFn = observer(
    ({ bankStatementId, ...props }: BankStatementDetailsDialogFnProps) => {
        useEffect(() => {
            store.loadItem(bankStatementId);

            return () => {
                store.itemLoader.reset();
            };
        }, []);

        const bankStatement = store.currentItem;

        return (
            <Modal
                title={t('Dialog.BankStatementDetails.Title', {
                    id: bankStatementId,
                })}
                isLoading={store.itemLoader.isLoading}
                showOnlyLoading={!bankStatement}
                width={1200}
                footer={[
                    <Button key="cancel" onClick={() => props.onCancel()}>
                        {t('Dialog.BankStatementDetails.Back')}
                    </Button>,
                ]}
                {...props}
            >
                <Row gutter={16}>
                    <Col sm={6}>
                        <DataView
                            label={t('Dialog.BankStatementDetails.ID')}
                            value={bankStatement?.id}
                            format="string"
                        />
                    </Col>
                    <Col sm={6}>
                        <DataView
                            label={t('Dialog.BankStatementDetails.AccountName')}
                            value={bankStatement?.accountName}
                            format="string"
                        />
                    </Col>
                    <Col sm={6}>
                        <DataView
                            label={t(
                                'Dialog.BankStatementDetails.AccountNumber',
                            )}
                            value={bankStatement?.accountNumber}
                            format="string"
                        />
                    </Col>
                    <Col sm={6}>
                        <DataView
                            label={t(
                                'Dialog.BankStatementDetails.TransactionDate',
                            )}
                            value={bankStatement?.transactionDate}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col sm={6}>
                        <DataView
                            label={t('Dialog.BankStatementDetails.Balance')}
                            value={bankStatement?.balance}
                            format="money"
                        />
                    </Col>
                    <Col sm={6}>
                        {bankStatement?.type === 1 ? (
                            <BlueColorDataView
                                label={t(
                                    'Dialog.BankStatementDetails.DebitAmount',
                                )}
                                value={bankStatement?.amount}
                                format="money"
                            />
                        ) : (
                            <OrangeColorDataView
                                label={t(
                                    'Dialog.BankStatementDetails.CreditAmount',
                                )}
                                value={bankStatement?.amount}
                                format="money"
                            />
                        )}
                    </Col>
                    <Col sm={6}>
                        <DataView
                            label={t(
                                'Dialog.BankStatementDetails.CurrencyCode',
                            )}
                            value={bankStatement?.currencyCode}
                            format={(value) => (
                                <EnumView enumName="CURRENCY" value={value} />
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <DataView
                            label={t('Dialog.BankStatementDetails.BankCode')}
                            value={bankStatement?.bankCode}
                            format="string"
                        />
                    </Col>
                    <Col sm={6}>
                        <DataView
                            label={t(
                                'Dialog.BankStatementDetails.SelfAccountNo',
                            )}
                            value={bankStatement?.selfAccountNo}
                            format="string"
                        />
                    </Col>
                    <Col sm={6}>
                        <DataView
                            label={t(
                                'Dialog.BankStatementDetails.OperationType',
                            )}
                            value={bankStatement?.operationType}
                            format={(value) => (
                                <EnumView
                                    enumName="BANK_STATEMENT_OPERATION_TYPE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <DataView
                            label={t('Dialog.BankStatementDetails.Status')}
                            value={bankStatement?.status}
                            format={(value) => (
                                <BankStatementsStatus status={value} />
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <DataView
                            label={t('Dialog.BankStatementDetails.Description')}
                            value={bankStatement?.description}
                            format="string"
                        />
                    </Col>
                    <Col sm={24}>
                        <DataView
                            label={t('Dialog.BankStatementDetails.Comment')}
                            value={bankStatement?.comment}
                            format="string"
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const BankStatementDetailsDialog = addModalRenderer(
    BankStatementDetailsDialogFn,
);

const BlueColorDataView = styled(DataView)`
    .app-data-view__value {
        background: linear-gradient(
            90deg,
            #0d73bd -25.12%,
            #0562a5 -25.09%,
            #17c48d 215.89%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
`;

const OrangeColorDataView = styled(DataView)`
    .app-data-view__value {
        color: #fc842b;
    }
`;
