/* eslint-disable max-lines */
import { Button, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { HistoryIcon } from 'src/components/Icons/Icons';
import { ListView } from 'src/components/ListView/ListView';
import { Spinner } from 'src/components/Spinner/Spinner';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    ProductBrief,
    ProductOfferListEntry,
    ProductType,
} from 'src/generated-api-client';
import { useIndividualId } from 'src/hooks/useIndividualId';
import { CreditScoringHistoryDialog } from 'src/pages/IndividualDetailsPage/Dialogs/CreditScoringHistoryDialog';
import { IndividualDetailsViewModel } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { IndividualCibLoadDataTab } from 'src/pages/IndividualDetailsPage/Tabs/IndividualCibLoadDataTab';
import { IndividualIncomeTypes } from 'src/pages/IndividualDetailsPage/Tabs/IndividualIncomeTypes';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { IndividualDetailsLoanLimitStore } from 'src/stores/IndividualDetailsLoanLimitStore/IndividualDetailsLoanLimitsStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

export const IndividualLoanLimitTab = observer(() => {
    const { t } = useTranslation();
    const { individualId } = useIndividualId();
    const loanLimitStore = IndividualDetailsLoanLimitStore.getInstance(
        Number(individualId),
    );
    const showIndividualIncome = AppUser.hasPermissions(
        UserPermission.IndividualReadIncomePreview,
    );
    const showCibLoadData = AppUser.hasPermissions(
        UserPermission.IndividualReadCibLoanData,
    );
    const showProductOffer = AppUser.hasPermissions(
        UserPermission.IndividualReadProductOffer,
    );

    useEffect(() => {
        loanLimitStore.loadList();

        return () => {
            loanLimitStore.listLoader.reset();
        };
    }, [individualId]);

    const viewModel = IndividualDetailsViewModel.getInstance(
        Number(individualId),
    );

    const individual = viewModel.item;

    if (loanLimitStore.listLoader.isLoading) {
        return (
            <StyledWrapper>
                <Spinner />
            </StyledWrapper>
        );
    }

    return (
        <>
            <StyledSpace>
                <IntervalDataView
                    value={loanLimitStore.expiredAt}
                    label={t(
                        'Page.IndividualDetails.LoanLimit.Title.ExpireDate',
                    )}
                    align="horizontal"
                    format="date-time"
                />
                {showProductOffer && (
                    <Button
                        icon={<HistoryIcon />}
                        type="link"
                        onClick={() => {
                            CreditScoringHistoryDialog.render({
                                individualId: individual?.id,
                            });
                        }}
                    >
                        {t(
                            'Page.IndividualDetails.Button.CreditScoringHistory',
                        )}
                    </Button>
                )}
            </StyledSpace>

            {showProductOffer && (
                <StyledListView store={loanLimitStore} columns={columns} />
            )}
            {showIndividualIncome && (
                <IndividualIncomeTypes individualId={individual?.id} />
            )}
            {showCibLoadData && (
                <>
                    <StyledTitle level={4}>
                        {t(
                            'Page.IndividualDetails.LoanLimit.CIBDetails',
                            'CIB Detail Information',
                        )}
                    </StyledTitle>
                    <IndividualCibLoadDataTab individualId={individual?.id} />
                </>
            )}
        </>
    );
});

export const columns: ColumnsType<ProductOfferListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.IndividualDetails.LoanLimit.Id" />,
    },
    {
        key: 'product',
        dataIndex: 'product',
        title: <Trans i18nKey="Page.IndividualDetails.LoanLimit.Name" />,
        render(product) {
            return (
                <DataView
                    value={product?.name}
                    hideLabel
                    format="link-internal"
                    formatterProps={{
                        to: getProductDetailsUrl(product),
                    }}
                />
            );
        },
    },
    {
        key: 'limitAmount',
        dataIndex: 'limitAmount',
        title: <Trans i18nKey="Page.IndividualDetails.LoanLimit.LimitAmount" />,
        render(value) {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'issuedAmount',
        dataIndex: 'issuedAmount',
        title: (
            <Trans i18nKey="Page.IndividualDetails.LoanLimit.IssuedAmount" />
        ),
        render(value) {
            return (
                <OrangeColorDataView value={value} format="money" hideLabel />
            );
        },
    },
    {
        key: 'availableAmount',
        dataIndex: 'availableAmount',
        title: (
            <Trans i18nKey="Page.IndividualDetails.LoanLimit.AvailableAmount" />
        ),
        render(value) {
            return (
                <GreenColorDataView value={value} format="money" hideLabel />
            );
        },
    },
    {
        key: 'term',
        dataIndex: 'term',
        title: <Trans i18nKey="Page.IndividualDetails.LoanLimit.Term" />,
    },
    {
        key: 'interestRate',
        dataIndex: 'interestRate',
        title: (
            <Trans i18nKey="Page.IndividualDetails.LoanLimit.InterestRate" />
        ),
        render(value) {
            return <DataView value={value} format="percent" hideLabel />;
        },
    },
];

const StyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const GreenColorDataView = styled(DataView)`
    .app-data-view__value {
        color: #0dbd7e;
    }
`;

const OrangeColorDataView = styled(DataView)`
    .app-data-view__value {
        color: #fc842b;
    }
`;

const IntervalDataView = styled(DataView)`
    .app-data-view__label {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: #4d6082;
    }

    .app-data-view__value {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: #2e3e5c;
    }
`;

const StyledListView = styled(ListView)`
    margin-bottom: 20px;
`;

const StyledTitle = styled(Typography.Title)`
    margin-top: 20px;
`;

const StyledSpace = styled(Space)`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const getProductDetailsUrl = (product?: ProductBrief) => {
    if (product) {
        const productId = product?.id;
        const productType = product?.productLine?.type;
        const routePath = ProductDetailsRoutePath[productType];

        return UrlHelper.getTargetUrl(routePath, { productId });
    }
};

export const ProductDetailsRoutePath = {
    [ProductType.BNPL]: RoutePaths.productBNPLDetails,
    [ProductType.INSTALLMENT]: RoutePaths.productInstallmentDetails,
    [ProductType.PLEDGED]: RoutePaths.productPledgedDetails,
    [ProductType.PAYON]: RoutePaths.productsPayonDetails,
    [ProductType.TRUST]: RoutePaths.productTrustPledgedDetails,
};
