import { t } from 'i18next';
import {
    BankStatementListItem,
    BankStatementOperationType,
    BankStatementStatus,
} from 'src/generated-api-client';
import { AssignToLoanDialog } from 'src/pages/BankStatementsPage/Dialogs/AssignToLoanDialog';
import { BankStatementChangeStatusDialog } from 'src/pages/BankStatementsPage/Dialogs/BankStatementChangeStatusDialog';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { ActionDefinition } from 'src/utils/UserActionsHelper';

export const getBankStatementActions = (
    bankStatement?: BankStatementListItem,
) => {
    return [...getUIActions(bankStatement), ...getServerActions()];
};

const getUIActions = (
    bankStatement?: BankStatementListItem,
): ActionDefinition[] => {
    const canAssignToLoan =
        (bankStatement?.status !== BankStatementStatus.PENDING &&
            bankStatement?.operationType ===
                BankStatementOperationType.LOAN_REPAYMENT) ||
        bankStatement?.operationType !==
            BankStatementOperationType.LOAN_REPAYMENT;
    const canChangeStatus =
        bankStatement?.operationType !==
            BankStatementOperationType.LOAN_REPAYMENT ||
        bankStatement?.status === BankStatementStatus.APPROVED;

    const isDisabledAssignToLoan = AppUser.hasPermissions(
        UserPermission.BankStatementAssignToLoan,
    )
        ? canAssignToLoan
        : true;

    const isDisabledChangeStatus = AppUser.hasPermissions(
        UserPermission.BankStatementChangeStatus,
    )
        ? canChangeStatus
        : true;

    return [
        {
            key: 'assign-to-loan',
            label: t('BankStatement.Actions.AssignToLoan'),
            onClick: () => {
                AssignToLoanDialog.render({
                    bankStatementId: bankStatement?.id,
                });
            },
            disabled: isDisabledAssignToLoan,
        },
        {
            key: 'change-status',
            label: t('BankStatement.Actions.ChangeStatus'),
            onClick: () => {
                BankStatementChangeStatusDialog.render({
                    bankStatementId: bankStatement?.id,
                });
            },
            disabled: isDisabledChangeStatus,
        },
    ];
};

const getServerActions = (): ActionDefinition[] => {
    return [...getRegisteredServerActions()];
};

const getRegisteredServerActions = (): ActionDefinition[] => {
    return [];
};
