import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ReviewActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ReviewActionDialogComponent';
import {
    ReviewInterestAccrueStopRenewActionStore,
    ReviewInterestAccrueStopRenewActionStoreClass,
} from 'src/stores/LoanActionsStores/ReviewInterestAccrueStopRenewActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const { Text } = Typography;

const getTitleContext = (
    store: ReviewInterestAccrueStopRenewActionStoreClass,
) => {
    if (store.initialValuesLoader.countLoads === 0) {
        return 'initial';
    }
    if (store.initialValues?.interestAccrualStop) {
        return 'stop';
    }

    return 'start';
};

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

export const ReviewInterestAccrueStopRenewDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ReviewInterestAccrueStopRenewActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ReviewInterestAccrualStopRenew.Title', {
            context: getTitleContext(store),
        });

        return (
            <ReviewActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                {!store.initialValues?.interestAccrualStop && (
                    <StyledText>
                        {t('Dialog.ReviewInterestAccrualStopRenew.Start')}
                    </StyledText>
                )}

                {store.initialValues?.interestAccrualStop && (
                    <StyledText>
                        {t('Dialog.ReviewInterestAccrualStopRenew.Stop')}
                    </StyledText>
                )}
            </ReviewActionDialogComponent>
        );
    },
);

export const ReviewInterestAccrueStopRenewDialog = addModalRenderer(
    ReviewInterestAccrueStopRenewDialogFn,
);

const StyledText = styled(Text)`
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #4d6082;
`;
