import { Form, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { ListView } from 'src/components/ListView/ListView';
import { LoanPayment } from 'src/generated-api-client';
import { ProposeActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ProposeActionDialogComponent';
import { ProposePaymentCancellationActionStore } from 'src/stores/LoanActionsStores/ProposePaymentCancellationActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

export const ProposePaymentCancellationDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const [form] = Form.useForm();
        const store = ProposePaymentCancellationActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ProposePaymentCancellation.Title');

        return (
            <ProposeActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                form={form}
                {...props}
            >
                <Form.Item
                    name="loanPayments"
                    initialValue={[]}
                    rules={[ValidationRules.requiredPaymentItem]}
                >
                    <StyledText>
                        {t('Dialog.ProposePaymentCancellation.Text')}
                    </StyledText>
                    <ListView
                        dataSource={store.initialValues?.loanPayments}
                        columns={LoanPaymentsColumns}
                        rowKey="paymentId"
                        rowSelection={{
                            type: 'checkbox',
                            onChange: (_, selectedRows) => {
                                form.setFieldsValue({
                                    loanPayments: selectedRows,
                                });
                            },
                        }}
                    />
                </Form.Item>
                <TextAreaField
                    name="comment"
                    label={t('Dialog.ProposePaymentCancellation.Comment')}
                    maxLength={255}
                />
            </ProposeActionDialogComponent>
        );
    },
);

export const ProposePaymentCancellationDialog = addModalRenderer(
    ProposePaymentCancellationDialogFn,
);

export const LoanPaymentsColumns: ColumnType<LoanPayment>[] = [
    {
        title: <Trans i18nKey="Dialog.PaymentCancellation.EffectiveAt" />,
        key: 'effectiveAt',
        dataIndex: 'effectiveAt',
        render: (value) => {
            return (
                <DataView value={value} format="date-time-seconds" hideLabel />
            );
        },
    },
    {
        title: <Trans i18nKey="Dialog.PaymentCancellation.Amount" />,
        key: 'amount',
        dataIndex: 'amount',
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
];

const StyledText = styled(Typography.Text)`
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #4d6082;
`;
