import { ReactComponent as AcceptSquareRoundedSvg } from 'src/assets/accept-square-rounded.svg';
import { ReactComponent as ArrowDownIconSvg } from 'src/assets/arrow-down.svg';
import { ReactComponent as ArrowUpIconSvg } from 'src/assets/arrow-up.svg';
import { ReactComponent as AssignIconSvg } from 'src/assets/assign.svg';
import { ReactComponent as CheckIconSvg } from 'src/assets/check-icon.svg';
import { ReactComponent as CircleArrowRightIconSvg } from 'src/assets/circle-arrow-right-icon.svg';
import { ReactComponent as CircleCheckIconSvg } from 'src/assets/circle-check.svg';
import { ReactComponent as ModalCloseSvg } from 'src/assets/circle-close-icon.svg';
import { ReactComponent as CirclePlusIconSvg } from 'src/assets/circle-plus-icon.svg';
import { ReactComponent as CloseSquareRoundedSvg } from 'src/assets/close-square-rounded.svg';
import { ReactComponent as DebtCollectionIconSvg } from 'src/assets/debt-collection.svg';
import { ReactComponent as DeleteIconSvg } from 'src/assets/delete-icon.svg';
import { ReactComponent as DotsHorizontalIconSvg } from 'src/assets/dots-horizontal-icon.svg';
import { ReactComponent as EditIconSvg } from 'src/assets/edit-icon.svg';
import { ReactComponent as ExternalLinkIconSvg } from 'src/assets/external-link-icon.svg';
import { ReactComponent as HistoryIconSvg } from 'src/assets/history-icon.svg';
import { ReactComponent as NoResultFoundSvg } from 'src/assets/list-no-found-result.svg';
import { ReactComponent as ApplicationsMenuIconSvg } from 'src/assets/menu-applications-icon.svg';
import { ReactComponent as BankStatementsMenuIconSvg } from 'src/assets/menu-bank-statements-icon.svg';
import { ReactComponent as BranchInformationMenuIconSvg } from 'src/assets/menu-branch-information.svg';
import { ReactComponent as CollateralsMenuIconSvg } from 'src/assets/menu-collaterals-icon.svg';
import { ReactComponent as IndividualsMenuIconSvg } from 'src/assets/menu-individuals-icon.svg';
import { ReactComponent as LegalEntitiesMenuIconSvg } from 'src/assets/menu-legal-entities-icon.svg';
import { ReactComponent as LoansMenuIconSvg } from 'src/assets/menu-loans-icon.svg';
import { ReactComponent as MerchantsMenuIconSvg } from 'src/assets/menu-merchants-icon.svg';
import { ReactComponent as RestrictionsMenuIconSvg } from 'src/assets/menu-restrictions-icon.svg';
import { ReactComponent as SecurityMenuIconSvg } from 'src/assets/menu-security-icon.svg';
import { ReactComponent as SettingsMenuIconSvg } from 'src/assets/menu-settings-icon.svg';
import { ReactComponent as PlusIconSvg } from 'src/assets/plus-icon.svg';
import { ReactComponent as RejectIconSvg } from 'src/assets/reject-icon.svg';
import { ReactComponent as WarningIconSvg } from 'src/assets/warning-icon.svg';

export const IndividualsMenuIcon = () => {
    return <IndividualsMenuIconSvg />;
};

export const LegalEntitiesMenuIcon = () => {
    return <LegalEntitiesMenuIconSvg />;
};

export const MerchantsMenuIcon = () => {
    return <MerchantsMenuIconSvg />;
};

export const ApplicationsMenuIcon = () => {
    return <ApplicationsMenuIconSvg />;
};

export const CollateralsMenuIcon = () => {
    return <CollateralsMenuIconSvg />;
};

export const LoansMenuIcon = () => {
    return <LoansMenuIconSvg />;
};

export const BankStatementsMenuIcon = () => {
    return <BankStatementsMenuIconSvg />;
};

export const BranchInformationMenuIcon = () => {
    return <BranchInformationMenuIconSvg />;
};

export const SecurityMenuIcon = () => {
    return <SecurityMenuIconSvg />;
};

export const SettingsMenuIcon = () => {
    return <SettingsMenuIconSvg />;
};

export const RestrictionsMenuIcon = () => {
    return <RestrictionsMenuIconSvg />;
};

export const WarningIcon = () => {
    return <WarningIconSvg />;
};

export const CheckIcon = () => {
    return <CheckIconSvg />;
};

export const RejectIcon = () => {
    return <RejectIconSvg />;
};

export const NoResultFoundIcon = () => {
    return <NoResultFoundSvg />;
};

export const CircleArrowRightIcon = () => {
    return <CircleArrowRightIconSvg />;
};

export const CirclePlusIcon = () => {
    return <CirclePlusIconSvg />;
};

export const ArrowUpIcon = () => {
    return <ArrowUpIconSvg />;
};

export const ArrowDownIcon = () => {
    return <ArrowDownIconSvg />;
};

export const DotsHorizontalIcon = () => {
    return <DotsHorizontalIconSvg />;
};

export const AcceptSquareRoundedIcon = () => {
    return <AcceptSquareRoundedSvg />;
};

export const CloseSquareRoundedIcon = () => {
    return <CloseSquareRoundedSvg />;
};

export const ModalCloseIcon = () => {
    return <ModalCloseSvg />;
};

export const HistoryIcon = () => {
    return <HistoryIconSvg />;
};

export const PlusIcon = () => {
    return <PlusIconSvg />;
};

export const EditIcon = () => {
    return <EditIconSvg />;
};

export const DeleteIcon = () => {
    return <DeleteIconSvg />;
};

export const ExternalLinkIcon = () => {
    return <ExternalLinkIconSvg />;
};

export const DebtCollectionMenuIcon = () => {
    return <DebtCollectionIconSvg />;
};

export const AssignIcon = () => {
    return <AssignIconSvg />;
};

export const CircleCheck = () => {
    return <CircleCheckIconSvg />;
};
