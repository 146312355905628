import { computed } from 'mobx';
import {
    BusinessIncomeListEntry,
    IndividualBusinessIncome,
} from 'src/generated-api-client';
import { individualsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export class IndividualBusinessIncomeStoreClass extends BasicStore<any> {
    api: BasicStoreApi<
        BusinessIncomeListEntry,
        any,
        any,
        IndividualBusinessIncome
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getBusinessIncomes({
                    ...this.listParams,
                    id: this.individualId,
                }),
            );
        },

        loadItem: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getBusinessIncome({
                    id: this.individualId,
                }),
            );
        },
    };

    constructor(private individualId: number) {
        super();
    }

    pager?: Pager | undefined = new Pager();

    @computed get incomeDetails() {
        return this.currentItem;
    }
}

export const IndividualBusinessIncomeStore = new EntityProvider(
    IndividualBusinessIncomeStoreClass,
);
