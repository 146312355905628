import { t } from 'i18next';
import { ActionType, Insurance } from 'src/generated-api-client';
import { EditCollateralInsuranceDialog } from 'src/pages/CollateralDetailsPage/Components/CollateralInsurance/Dialogs/EditCollateralInsuranceDialog';
import { RemoveCollateralInsuranceDialog } from 'src/pages/CollateralDetailsPage/Components/CollateralInsurance/Dialogs/RemoveCollateralInsuranceDialog';
import { Collateral } from 'src/utils/CollateralsHelper';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getCollateralInsuranceListActions = (
    collateral: Collateral,
    insurance?: Insurance,
) => {
    return [...getUIActions(), ...getServerActions(collateral, insurance)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    collateral: Collateral,
    insurance?: Insurance,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(collateral, insurance),
        insurance?.actions,
    );
};

const getRegisteredServerActions = (
    collateral: Collateral,
    insurance?: Insurance,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.INSURANCE_UPDATE,
            label: t('Insurance.Actions.Edit', 'Edit'),
            onClick: () => {
                EditCollateralInsuranceDialog.render({
                    collateralId: collateral.id,
                    insurance,
                });
            },
        },
        {
            key: ActionType.INSURANCE_DELETE,
            label: t('Insurance.Actions.Remove', 'Delete'),
            onClick: () => {
                RemoveCollateralInsuranceDialog.render({
                    collateralId: collateral.id,
                    insuranceId: insurance?.id,
                });
            },
        },
    ];
};
