import { Button, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { getLoanActions } from 'src/pages/LoanDetailsPage/Components/LoanActions.config';
import { LoanType } from 'src/stores/LoansStore/LoansStore';
import styled from 'styled-components';

type LoanActionsProps = {
    loan?: LoanType;
};

export const LoanActions = observer(({ loan }: LoanActionsProps) => {
    const actions = getLoanActions(loan);
    const primaryActions = actions.filter((action) => action.primary);
    const secondaryActions = actions.filter((action) => !action.primary);
    const showSecondaryActions =
        secondaryActions && secondaryActions.length > 0;

    return (
        <StyledSpace size={16}>
            {primaryActions.map(({ label: title, key: type, onClick }) => (
                <Button type="primary" key={type} onClick={onClick}>
                    {title}
                </Button>
            ))}
            {showSecondaryActions && <Dropdown items={secondaryActions} />}
        </StyledSpace>
    );
});

const StyledSpace = styled(Space)`
    display: flex;
`;
