import { observer } from 'mobx-react-lite';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { getApplicationActions } from 'src/pages/ApplicationDetailsPage/Components/ApplicationActions.config';
import { LoanApplicationType } from 'src/stores/ApplicationsStore/ApplicationsStore';

type ApplicationActionsProps = {
    application?: LoanApplicationType;
};

export const ApplicationActions = observer(
    ({ application }: ApplicationActionsProps) => {
        const actions = getApplicationActions(application);
        const hideActions = actions.length === 0;

        if (hideActions) {
            return null;
        }

        return <Dropdown items={actions} />;
    },
);
