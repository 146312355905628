import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { BnplProduct } from 'src/generated-api-client';

export type BNPLProductDetailsTab = { productBNPL?: BnplProduct };
export const BNPLProductDetailsTab = observer(
    ({ productBNPL }: BNPLProductDetailsTab) => {
        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card title={t('Page.BNPLProductDetails.Details.Title')}>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={productBNPL?.productLineId}
                                    label={t(
                                        'Page.BNPLProductDetails.ExternalId',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productBNPL?.name}
                                    label={t('Page.BNPLProductDetails.Name')}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productBNPL?.transferringFee}
                                    label={t(
                                        'Page.BNPLProductDetails.TransferringFee',
                                    )}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productBNPL?.status}
                                    label={t('Page.BNPLProductDetails.Status')}
                                    format={(value) => (
                                        <EnumView
                                            enumName="PRODUCT_STATUS"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <DataView
                                    value={productBNPL?.maxLoanAmount}
                                    label={t(
                                        'Page.BNPLProductDetails.MaxLoanAmount',
                                    )}
                                    format="money"
                                />
                            </Col>
                            <Col span={6}>
                                <DataView
                                    value={productBNPL?.maxTenor}
                                    label={t(
                                        'Page.BNPLProductDetails.MaxTenor',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col span={6}>
                                <DataView
                                    value={
                                        productBNPL?.reOpeningProductLine?.name
                                    }
                                    label={t(
                                        'Page.BNPLProductDetails.ReOpeningProductLine',
                                    )}
                                />
                            </Col>
                            <Col span={6}>
                                <DataView
                                    value={
                                        productBNPL?.reOpeningRepaymentsQuantity
                                    }
                                    label={t(
                                        'Page.BNPLProductDetails.ReOpeningRepaymentsQuantity',
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <DataView
                                    value={productBNPL?.excludedFromOffer}
                                    label={t(
                                        'Page.BNPLProductDetails.ExcludedFromOffer',
                                    )}
                                    format="yes-no"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    },
);
