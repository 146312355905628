import { Col, Row, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IndividualBankAccountCard } from 'src/pages/IndividualDetailsPage/Components/IndividualBankAccountCard';
import { IndividualContactInformationCard } from 'src/pages/IndividualDetailsPage/Components/IndividualContactInformationCard';
import { IndividualCustomerReferenceCard } from 'src/pages/IndividualDetailsPage/Components/IndividualCustomerReferenceCard';
import { IndividualGeneralInfoCard } from 'src/pages/IndividualDetailsPage/Components/IndividualGeneralInfoCard';
import { IndividualJobInformationCard } from 'src/pages/IndividualDetailsPage/Components/IndividualJobInformationCard';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import styled from 'styled-components';

export type IndividualDetailsTab = {
    viewModel?: IndividualDetailsViewModelClass;
};

export const IndividualDetailsTab = observer(
    ({ viewModel }: IndividualDetailsTab) => {
        return (
            <Row gutter={24}>
                <Col sm={12}>
                    <StyledSpace direction="vertical">
                        <IndividualGeneralInfoCard viewModel={viewModel} />
                        <IndividualCustomerReferenceCard
                            viewModel={viewModel}
                        />
                        <IndividualBankAccountCard viewModel={viewModel} />
                    </StyledSpace>
                </Col>

                <Col sm={12}>
                    <StyledSpace direction="vertical">
                        <IndividualContactInformationCard
                            viewModel={viewModel}
                        />
                        <IndividualJobInformationCard viewModel={viewModel} />
                    </StyledSpace>
                </Col>
            </Row>
        );
    },
);

const StyledSpace = styled(Space)`
    display: flex;
`;
