import { notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { Modal } from 'src/components/Modal/Modal';
import { NewChildBranch } from 'src/generated-api-client';
import { BranchesViewModel } from 'src/pages/BranchesPage/BranchesViewModel';
import { ChildBranchFields } from 'src/pages/BranchesPage/Components/ChildBranchFields';
import { BranchOptionsStore } from 'src/stores/BranchOptionsStore/BranchOptionsStore';
import { ChildBranchesStore } from 'src/stores/ChildBranchesStore/ChildBranchesStore';
import { FilesStore } from 'src/stores/FilesStore/FilesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const store = ChildBranchesStore;

export const CreateChildBranchDialogFn = observer(
    ({ ...props }: ModalRendererProps) => {
        const loadingInfo = useMemo(() => {
            return new CombinedLoadingStatus([
                BranchOptionsStore.listLoader,
                store.createItemLoader,
                FilesStore.uploadFileLoader,
            ]);
        }, []);

        useEffect(() => {
            BranchOptionsStore.loadList();

            return () => {
                store.createItemLoader.reset();
                BranchOptionsStore.listLoader.reset();
                FilesStore.uploadFileLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.createItemLoader.errorData,
        );

        return (
            <Modal
                title={t('Dialog.CreateChildBranch.Title')}
                isLoading={loadingInfo.isLoading}
                onFinish={async (values) => {
                    const { logoFile, ...formValues } = values;
                    const file = logoFile?.[0]?.originFileObj;
                    // eslint-disable-next-line init-declarations
                    let logoFileMetadata;

                    if (file) {
                        const result = await FilesStore.upload(file);
                        logoFileMetadata = { ...result };
                    }

                    const logoFileId = logoFileMetadata?.id
                        ? String(logoFileMetadata?.id)
                        : undefined;

                    const dataForCreate = convertValuesToChildBranchCreate(
                        formValues,
                        logoFileId,
                    );

                    await store.create(dataForCreate);

                    if (store.createItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.createItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.CreateChildBranch.Message.Success',
                            ),
                        });
                    }

                    BranchesViewModel.reloadChildBranches();
                }}
                formProps={{ fields }}
                width={1100}
                {...props}
            >
                <ChildBranchFields />
            </Modal>
        );
    },
);

export const CreateChildBranchDialog = addModalRenderer(
    CreateChildBranchDialogFn,
);

const convertValuesToChildBranchCreate = (values: any, logoFileId?: string) => {
    return convertValues(values, logoFileId) as NewChildBranch;
};

export function convertValues(values: any, logoFileId?: string) {
    return {
        ...values,
        logoFileId,
        workSchedule: {
            ...values.workSchedule,
            workingHours: {
                from: values.workSchedule?.workingHours?.from?.toObject(),
                to: values.workSchedule?.workingHours?.to?.toObject(),
            },
        },
    };
}
