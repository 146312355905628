import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ProposeActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ProposeActionDialogComponent';
import { ProposeWriteOffActionStore } from 'src/stores/LoanActionsStores/ProposeWriteOffActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const { Text } = Typography;

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

export const ProposeWriteOffDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ProposeWriteOffActionStore.getInstance(Number(loanId));
        const title = t('Dialog.ProposeWriteOff.Title');

        return (
            <ProposeActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <StyledText>{t('Dialog.ProposeWriteOff.Notice')}</StyledText>
            </ProposeActionDialogComponent>
        );
    },
);

export const ProposeWriteOffDialog = addModalRenderer(ProposeWriteOffDialogFn);

const StyledText = styled(Text)`
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #4d6082;
`;
