import { ColumnType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { Modal } from 'src/components/Modal/Modal';
import { CreditScoringListEntry } from 'src/generated-api-client';
import { CreditScoringDecision } from 'src/pages/IndividualDetailsPage/Components/CreditScoringDecision';
import { IndividualCreditScoringHistoryStore } from 'src/stores/IndividualCreditScoringHistoryStore/IndividualCreditScoringHistoryStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type CreditScoringHistoryDialogFnProps = ModalRendererProps<{
    individualId?: number;
}>;

export const CreditScoringHistoryDialogFn = observer(
    ({ individualId, ...props }: CreditScoringHistoryDialogFnProps) => {
        const store = IndividualCreditScoringHistoryStore.getInstance(
            Number(individualId),
        );
        const { isLoading } = store.listLoader;

        useEffect(() => {
            store.loadList();

            return () => {
                store.listLoader.reset();
            };
        }, [individualId]);

        return (
            <Modal
                footer={null}
                maskClosable={false}
                width={720}
                isLoading={isLoading}
                {...props}
            >
                <StyledListView
                    store={store}
                    columns={creditScoringHistoryColumns}
                />
            </Modal>
        );
    },
);

export const CreditScoringHistoryDialog = addModalRenderer(
    CreditScoringHistoryDialogFn,
);

const StyledListView = styled(ListView)`
    padding: 0;
`;

const creditScoringHistoryColumns: ColumnType<CreditScoringListEntry>[] = [
    {
        title: <Trans i18nKey="Dialog.CreditScoringHistory.List.CreatedAt" />,
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (value) => {
            return <DataView value={value} format="date" hideLabel />;
        },
    },
    {
        title: <Trans i18nKey="Dialog.CreditScoringHistory.List.CreditScore" />,
        key: 'creditScore',
        dataIndex: 'creditScore',
    },
    {
        title: <Trans i18nKey="Dialog.CreditScoringHistory.List.Dti" />,
        key: 'dti',
        dataIndex: 'dti',
        render: (value) => {
            return <DataView value={value} format="percent" hideLabel />;
        },
    },
    {
        title: <Trans i18nKey="Dialog.CreditScoringHistory.List.Fee" />,
        key: 'fee',
        dataIndex: 'fee',
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        title: <Trans i18nKey="Dialog.CreditScoringHistory.List.Decision" />,
        key: 'decision',
        dataIndex: 'decision',
        render: (value) => {
            return <CreditScoringDecision decision={value} />;
        },
    },
];
