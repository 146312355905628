import { Button, Col, notification, Row, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Form } from 'src/components/Form/Form';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import {
    FieldMaxLength,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { ProductType } from 'src/generated-api-client';
import { BnplProductsStore } from 'src/stores/BNPLProductsStore/BNPLProductsStore';
import { ProductLinesStore } from 'src/stores/ProductLinesStore/ProductLinesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = BnplProductsStore;

export const BnplProductCreatePage = observer(() => {
    const yesNoOptions = FormHelper.objAsOptions(
        {
            true: true,
            false: false,
        },
        'Primitive.Boolean.YesNo.',
    );

    useEffect(() => {
        ProductLinesStore.filterCriteria.setFilter({
            productTypes: [ProductType.INSTALLMENT],
        });
        ProductLinesStore.loadList();

        return () => {
            ProductLinesStore.filterCriteria.resetFilter();
            ProductLinesStore.listLoader.reset();
            store.createItemLoader.reset();
        };
    }, []);

    const fields = FormHelper.getFieldDataItems(
        store.createItemLoader.errorData,
    );

    return (
        <BasePage
            title={t('Page.BNPLProductCreate.Title')}
            isLoading={store.createItemLoader.isLoading}
        >
            <Form
                onFinish={async (values) => {
                    const item = await store.create(values);

                    if (!store.createItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Page.BNPLProductCreate.Message.Success',
                            ),
                        });

                        const targetUrl = UrlHelper.getTargetUrl(
                            RoutePaths.productBNPLDetails,
                            { productId: item?.id },
                        );

                        Router.navigate(targetUrl);
                    }
                }}
                onReset={() => {
                    Router.navigate(RoutePaths.productsBNPL);
                }}
                fields={fields}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            name="name"
                            label={t('Page.BNPLProductCreate.Name')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.maxFieldLength(
                                    FieldMaxLength.LoanProductName,
                                ),
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="transferringFee"
                            label={t('Page.BNPLProductCreate.TransferringFee')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 1000000),
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="maxLoanAmount"
                            label={t('Page.BNPLProductCreate.MaxLoanAmount')}
                            thousandDivider
                            rules={[
                                ValidationRules.required,
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="maxTenor"
                            label={t('Page.BNPLProductCreate.MaxTenor')}
                            onlyInteger
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberMaxValue(100),
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>

                    <Col span={12}>
                        <SelectField
                            name="reOpeningProductLineId"
                            label={t(
                                'Page.BNPLProductCreate.ReOpeningProductLine',
                            )}
                            options={ProductLinesStore.currentListAsOptions}
                            rules={[ValidationRules.required]}
                        />
                    </Col>

                    <Col span={12}>
                        <NumberField
                            name="reOpeningRepaymentsQuantity"
                            label={t(
                                'Page.BNPLProductCreate.ReOpeningRepaymentsQuantity',
                            )}
                            onlyInteger
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberMaxValue(100),
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>

                    <Col span={12}>
                        <SelectField
                            name="excludedFromOffer"
                            label={t(
                                'Page.BNPLProductCreate.ExcludedFromOffer',
                            )}
                            rules={[ValidationRules.required]}
                            options={yesNoOptions}
                        />
                    </Col>

                    <Col span={24}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                {t('Page.BNPLProductCreate.Btn.Create')}
                            </Button>
                            <Button type="default" htmlType="reset">
                                {t('Page.BNPLProductCreate.Btn.Reset')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </BasePage>
    );
});
