import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { TaskDecisionType } from 'src/generated-api-client';
import styled from 'styled-components';

export const N110TaskApproveInvoiceVariables = observer(() => {
    return (
        <>
            <StyledText>
                {t(
                    'Dialog.Approve.N110TaskApproveInvoice.Description',
                    'Please specify new invoice amount and confirm action to send request to Merchant',
                )}
            </StyledText>
            <HiddenField
                name="type"
                initialValue={TaskDecisionType.N1_10_REQUEST_INVOICE_CHANGE}
            />
            <NumberField
                name="amount"
                label={t(
                    'Dialog.Approve.N110TaskApproveInvoice.Amount',
                    'New invoice amount',
                )}
                rules={[ValidationRules.required]}
            />
        </>
    );
});

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 16px;
    color: #4d6082;
    margin-bottom: 20px;
`;
