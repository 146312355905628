/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import {
    Button,
    Col,
    Form,
    FormInstance,
    notification,
    Radio,
    Row,
    Typography,
} from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { ErrorField } from 'src/components/Form/ErrorField';
import { FormItemGroup } from 'src/components/Form/FormItemGroup/FormItemGroup';
import { PhoneField } from 'src/components/Form/PhoneField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { CirclePlusIcon, ModalCloseIcon } from 'src/components/Icons/Icons';
import { Modal } from 'src/components/Modal/Modal';
import {
    AddressUpdate,
    CustomerAddressUpdate,
    CustomerContactDetails,
    CustomerPhoneNumber,
    IndividualContactDetailsUpdate,
    IndividualInformationTabView,
    SocialMediaLink,
} from 'src/generated-api-client';
import { AddressFields } from 'src/pages/IndividualDetailsPage/Components/AddressFields';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

export type UpdateContactInformationDialogFnProps = ModalRendererProps<{
    viewModel?: IndividualDetailsViewModelClass;
}>;

export const UpdateContactInformationDialogFn = observer(
    ({ viewModel, ...props }: UpdateContactInformationDialogFnProps) => {
        const [form] = Form.useForm();
        const customerPhoneNumbers: CustomerPhoneNumber[] = Form.useWatch(
            'customerPhoneNumbers',
            form,
        );

        useEffect(() => {
            return () => {
                viewModel?.updateContactInformationLoader.reset();
            };
        }, []);

        useEffect(() => {
            const noMainPhone =
                customerPhoneNumbers?.length &&
                !customerPhoneNumbers?.some(
                    (phoneNumber) =>
                        phoneNumber.isMain || phoneNumber.isMain === undefined,
                );

            if (noMainPhone) {
                form.setFieldsValue({
                    customerPhoneNumbers: customerPhoneNumbers?.map(
                        (
                            customerPhoneNumber: CustomerPhoneNumber,
                            index: number,
                        ) => {
                            return {
                                ...customerPhoneNumber,
                                isMain: index === 0,
                            };
                        },
                    ),
                });
            }
        }, [customerPhoneNumbers]);

        const fields = FormHelper.getFieldDataItems(
            viewModel?.updateContactInformationLoader.errorData,
        );

        return (
            <Modal
                title={t('Dialog.UpdateContactInformation.Title')}
                form={form}
                width={900}
                onFinish={async (values) => {
                    await viewModel?.updateContactInformation(
                        Object.fromEntries(
                            Object.entries(values).filter(([_, value]) => {
                                return value !== null;
                            }),
                        ) as unknown as IndividualContactDetailsUpdate,
                    );

                    if (viewModel?.updateContactInformationLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel?.updateContactInformationLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.UpdateContactInformation.Message.Success',
                            ),
                        });
                    }
                }}
                initialValues={getInitialValues(viewModel?.item)}
                isLoading={viewModel?.isUpdateContactInformationLoading}
                formProps={{
                    fields,
                }}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={8}>
                        <TextField
                            name="email"
                            label={t('Dialog.UpdateContactInformation.Email')}
                            required
                            rules={[
                                ValidationRules.required,
                                ValidationRules.email,
                            ]}
                        />
                    </Col>
                    <Col xs={8}>
                        <SelectField
                            name="emailVerified"
                            label={t(
                                'Dialog.UpdateContactInformation.EmailVerified',
                            )}
                            disabled
                            options={FormHelper.objAsOptions(
                                {
                                    true: true,
                                    false: false,
                                },
                                'Primitive.Boolean.YesNo.',
                            )}
                        />
                    </Col>
                </Row>

                <StyledTitle>
                    {t('Dialog.UpdateContactInformation.CustomerPhones.Title')}
                </StyledTitle>

                <Form.List name="customerPhoneNumbers">
                    {(fields, { add, remove }) => {
                        return (
                            <>
                                {fields.map((field, index) => {
                                    return (
                                        <Row
                                            gutter={16}
                                            align="middle"
                                            key={field.key}
                                        >
                                            <Col xs={8}>
                                                <PhoneField
                                                    name={[
                                                        field.name,
                                                        'phoneNumber',
                                                    ]}
                                                    label={t(
                                                        'Dialog.UpdateContactInformation.CustomerPhoneNumbers.PhoneNumber',
                                                    )}
                                                    rules={[
                                                        ValidationRules.required,
                                                    ]}
                                                />
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Item
                                                    name={[
                                                        field.name,
                                                        'isMain',
                                                    ]}
                                                    label=" "
                                                    valuePropName="checked"
                                                >
                                                    <Radio
                                                        onChange={() =>
                                                            changeIsMainPhoneNumber(
                                                                index,
                                                                form,
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {fields.length > 1 && (
                                                <Col xs={2}>
                                                    <StyledRemoveButton
                                                        type="text"
                                                        icon={
                                                            <ModalCloseIcon />
                                                        }
                                                        onClick={() => {
                                                            remove(field.name);
                                                        }}
                                                        title={t(
                                                            'Form.RemoteItem',
                                                        )}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    );
                                })}

                                <Button
                                    type="dashed"
                                    icon={<CirclePlusIcon />}
                                    onClick={() => {
                                        add?.({
                                            isMain: false,
                                        } as CustomerPhoneNumber);
                                    }}
                                >
                                    {t(
                                        'Dialog.UpdateContactInformation.CustomerPhoneNumbers.AddPhoneNumber',
                                    )}
                                </Button>
                            </>
                        );
                    }}
                </Form.List>

                <StyledTitle>
                    {t('Dialog.UpdateContactInformation.CustomerAddress.Title')}
                </StyledTitle>
                <StyledErrorField name="customerAddressRequests" />
                <Form.List name="customerAddressRequests">
                    {(fields, { add, remove }) => {
                        return (
                            <>
                                {fields.map((field, index) => {
                                    return (
                                        <div key={field.key}>
                                            <Row gutter={16} align="middle">
                                                <Col flex={1}>
                                                    <StyledTitle>
                                                        {t(
                                                            'Dialog.UpdateContactInformation.CustomerAddress.Address',
                                                            {
                                                                index:
                                                                    index + 1,
                                                            },
                                                        )}
                                                    </StyledTitle>
                                                </Col>
                                                <Col>
                                                    <StyledRemoveButton
                                                        type="text"
                                                        icon={
                                                            <ModalCloseIcon />
                                                        }
                                                        onClick={() => {
                                                            remove(field.name);
                                                        }}
                                                        title={t(
                                                            'Form.RemoteItem',
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={16} align="middle">
                                                <StyledCol xs={8}>
                                                    <EnumSelectField
                                                        name={[
                                                            field.name,
                                                            'addressType',
                                                        ]}
                                                        label={t(
                                                            'Dialog.UpdateContactInformation.Addresses.AddressType',
                                                        )}
                                                        enumName="ADDRESS_TYPE"
                                                        rules={[
                                                            ValidationRules.required,
                                                        ]}
                                                    />
                                                </StyledCol>
                                                <StyledCol xs={8}>
                                                    <EnumSelectField
                                                        name={[
                                                            field.name,
                                                            'addressOwner',
                                                        ]}
                                                        label={t(
                                                            'Dialog.UpdateContactInformation.Addresses.AddressOwner',
                                                        )}
                                                        enumName="ADDRESS_OWNER"
                                                        rules={[
                                                            ValidationRules.required,
                                                        ]}
                                                    />
                                                </StyledCol>
                                            </Row>
                                            <FormItemGroup
                                                name={[field.name, 'address']}
                                            >
                                                <AddressFields watchNamePathPrefix="customerAddressRequests" />
                                            </FormItemGroup>
                                        </div>
                                    );
                                })}

                                <Button
                                    type="dashed"
                                    icon={<CirclePlusIcon />}
                                    onClick={() => {
                                        add?.({
                                            address: {},
                                        } as Partial<CustomerAddressUpdate>);
                                    }}
                                >
                                    {t(
                                        'Dialog.UpdateContactInformation.CustomerAddress.AddAddress',
                                    )}
                                </Button>
                            </>
                        );
                    }}
                </Form.List>

                <StyledTitle>
                    {t('Dialog.UpdateContactInformation.SocialAccounts')}
                </StyledTitle>
                <Form.List name="socialMediaLinks">
                    {(fields, { add, remove }) => {
                        return (
                            <>
                                {fields.map((field) => {
                                    return (
                                        <Row
                                            gutter={16}
                                            align="middle"
                                            key={field.key}
                                        >
                                            <StyledCol xs={4}>
                                                <SelectField
                                                    name={[
                                                        field.name,
                                                        'platformName',
                                                    ]}
                                                    label={t(
                                                        'Dialog.UpdateContactInformation.SocialAccounts.Platform',
                                                    )}
                                                    options={FormHelper.objAsOptions(
                                                        {
                                                            fb: 'FB',
                                                            inst: 'INSTAGRAM',
                                                        },
                                                        'Entity.SocialAccountPlatform.',
                                                    )}
                                                    required
                                                    rules={[
                                                        ValidationRules.required,
                                                    ]}
                                                />
                                            </StyledCol>
                                            <StyledCol xs={8}>
                                                <TextField
                                                    name={[
                                                        field.name,
                                                        'profileLink',
                                                    ]}
                                                    label={t(
                                                        'Dialog.UpdateContactInformation.SocialAccounts.Link',
                                                    )}
                                                    required
                                                    rules={[
                                                        ValidationRules.required,
                                                    ]}
                                                />
                                            </StyledCol>
                                            <StyledCol xs={8}>
                                                <TextField
                                                    name={[
                                                        field.name,
                                                        'profileName',
                                                    ]}
                                                    label={t(
                                                        'Dialog.UpdateContactInformation.SocialAccounts.ProfileName',
                                                    )}
                                                    required
                                                    rules={[
                                                        ValidationRules.required,
                                                    ]}
                                                />
                                            </StyledCol>
                                            <Col xs={4}>
                                                <StyledRemoveButton
                                                    type="text"
                                                    icon={<ModalCloseIcon />}
                                                    onClick={() => {
                                                        remove(field.name);
                                                    }}
                                                    title={t('Form.RemoteItem')}
                                                />
                                            </Col>
                                        </Row>
                                    );
                                })}

                                <Button
                                    type="dashed"
                                    icon={<CirclePlusIcon />}
                                    onClick={() => {
                                        add?.({} as AddressUpdate);
                                    }}
                                >
                                    {t(
                                        'Dialog.UpdateContactInformation.CustomerAddress.AddSocialLink',
                                    )}
                                </Button>
                            </>
                        );
                    }}
                </Form.List>
            </Modal>
        );
    },
);

type InitialValues = Omit<
    Partial<IndividualContactDetailsUpdate>,
    'customerAddressRequests'
> & { customerAddressRequests?: Array<CustomerAddressUpdate> };

const getInitialValues = (
    item?: IndividualInformationTabView,
): InitialValues => {
    const contactInformation =
        item?.customerContactDetails || ({} as CustomerContactDetails);

    return {
        ...contactInformation,
        customerAddressRequests:
            contactInformation.customerAddresses?.map((item) => {
                return {
                    addressOwner: item.addressOwner,
                    addressType: item.addressType,
                    address: {
                        ...item.address,
                        provinceCode: item.address.province.code,
                        districtCode: item.address.district.code,
                        khorooCode: item.address.khoroo.code,
                    },
                } as CustomerAddressUpdate;
            }) || ([{}] as Array<CustomerAddressUpdate>),
        socialMediaLinks:
            contactInformation.socialMediaLinks ||
            ([{}] as Array<SocialMediaLink>),
    };
};

export const UpdateContactInformationDialog = addModalRenderer(
    UpdateContactInformationDialogFn,
);

const StyledTitle = styled(Typography.Title)`
    margin-top: 20px;
    &.ant-typography {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #4d6082;
    }
`;

const StyledErrorField = styled(ErrorField)`
    margin-bottom: 10px;
`;

const changeIsMainPhoneNumber = (index: number, form: FormInstance) => {
    const customerPhoneNumbers = form.getFieldValue('customerPhoneNumbers');

    form.setFieldsValue({
        customerPhoneNumbers: customerPhoneNumbers.map(
            (
                customerPhoneNumber: CustomerPhoneNumber,
                phoneNumberIndex: number,
            ) => {
                return {
                    ...customerPhoneNumber,
                    isMain: phoneNumberIndex === index,
                };
            },
        ),
    });
};

const StyledRemoveButton = styled(Button)`
    &:hover {
        svg {
            fill: #51688b;
        }
    }
`;

const StyledCol = styled(Col)`
    min-height: 110px;
`;
