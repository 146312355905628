import { t } from 'i18next';
import { ProposedLoanInterestAdjustment } from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ReviewInterestAdjustmentActionStoreClass extends BasicActionStore<
    ProposedLoanInterestAdjustment,
    number,
    number
> {
    constructor(private loanId: number) {
        super();
    }

    okMessage = t('Dialog.Restructuring.Message.SuccessOk');
    cancelMessage = t('Dialog.Restructuring.Message.SuccessCancel');

    api: BasicActionStoreApi<ProposedLoanInterestAdjustment, number, number> = {
        load: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getInterestAdjustmentProposal({ id: this.loanId }),
            );
        },

        ok: async (operationId) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.reviewInterestAdjustmentProposal({
                    id: this.loanId,
                    restructuringReviewRequest: {
                        approved: true,
                        operationId: operationId as number,
                    },
                }),
            );
        },

        cancel: async (operationId) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.reviewInterestAdjustmentProposal({
                    id: this.loanId,
                    restructuringReviewRequest: {
                        approved: false,
                        operationId: operationId as number,
                    },
                }),
            );
        },
    };
}

export const ReviewInterestAdjustmentActionStore = new EntityProvider(
    ReviewInterestAdjustmentActionStoreClass,
);
