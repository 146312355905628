import { notification } from 'antd';
import { action, computed, observable } from 'mobx';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { SubmitTaskDecisionRequest } from 'src/generated-api-client';
import { CurrentWorkingTaskStore } from 'src/stores/CurrentWorkingTaskStore/CurrentWorkingTaskStore';
import { TasksStore } from 'src/stores/TasksStore/TasksStore';
import { EntityProvider } from 'src/utils/EntityProvider';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';

export class TaskViewModelClass<T = any> {
    @observable isLoaded = false;

    constructor(private taskId: string) {}

    @action
    async load() {
        await Promise.all([
            TasksStore.getTaskMetadata(this.taskId),
            TasksStore.loadItem(this.taskId),
        ]);
        this.isLoaded = true;
    }

    @action
    async reset() {
        await TasksStore.itemLoader.reset();
        await TasksStore.taskMetadataLoader.reset();
        await TasksStore.updateItemLoader.reset();
        this.isLoaded = false;
    }

    @action
    async loadTasksList() {
        await TasksStore.loadList();
    }

    @action
    async submitTask(data: SubmitTaskDecisionRequest) {
        await TasksStore.update(this.taskId, data);
        if (!this.submitTaskLoaderHasError) {
            await CurrentWorkingTaskStore.unsetCurrentWorkingTask();
        }

        if (
            this.submitTaskLoaderHasError &&
            this.submitTaskLoaderErrorData?.status === 404
        ) {
            notification.error({
                message: this.submitTaskLoaderErrorData?.detail,
            });
            await TasksStore.itemLoader.reset();
            await TasksStore.taskMetadataLoader.reset();
            this.isLoaded = true;
            Router.navigate(EntityLinks.tasks.itemDetails(this.taskId));
        }
    }

    @computed get currentTaskId() {
        return TasksStore.taskMetadata?.id;
    }

    @computed get taskName() {
        return TasksStore.taskMetadata?.name;
    }

    @computed get submitTaskLoaderHasError() {
        return TasksStore.updateItemLoader.hasError;
    }

    @computed get submitTaskLoaderErrorData() {
        return TasksStore.updateItemLoader.errorData;
    }

    @computed get currentTask() {
        return TasksStore.currentItem as unknown as T;
    }

    @computed get loadingInfo() {
        return new CombinedLoadingStatus([
            TasksStore.taskMetadataLoader,
            TasksStore.itemLoader,
            TasksStore.updateItemLoader,
        ]);
    }

    @computed get submitTaskErrorFields() {
        return FormHelper.getFieldDataItems(
            TasksStore.updateItemLoader.errorData,
        );
    }

    @action startWork() {
        CurrentWorkingTaskStore.setCurrentWorkingTask(TasksStore.taskMetadata);
    }

    @action stopWork() {
        CurrentWorkingTaskStore.unsetCurrentWorkingTask();
    }

    @computed get isCurrentTaskEqualWorkingTask() {
        return (
            TasksStore.taskMetadata?.id ===
            CurrentWorkingTaskStore.workingTaskMetadata?.id
        );
    }
}

export const TaskViewModel = new EntityProvider<
    string,
    TaskViewModelClass<any>
>(TaskViewModelClass);
