import { t } from 'i18next';
import { ProposedGracePeriod } from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ReviewGracePeriodActionStoreClass extends BasicActionStore<
    ProposedGracePeriod,
    number,
    number
> {
    constructor(private loanId: number) {
        super();
    }

    okMessage = t('Dialog.Restructuring.Message.SuccessOk');
    cancelMessage = t('Dialog.Restructuring.Message.SuccessCancel');

    api: BasicActionStoreApi<ProposedGracePeriod, number, number> = {
        load: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getReviewGracePeriod({ id: this.loanId }),
            );
        },

        ok: async (operationId) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.reviewGracePeriod({
                    id: this.loanId,
                    restructuringReviewRequest: {
                        approved: true,
                        operationId: operationId as number,
                    },
                }),
            );
        },

        cancel: async (operationId) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.reviewGracePeriod({
                    id: this.loanId,
                    restructuringReviewRequest: {
                        approved: false,
                        operationId: operationId as number,
                    },
                }),
            );
        },
    };
}

export const ReviewGracePeriodActionStore = new EntityProvider(
    ReviewGracePeriodActionStoreClass,
);
