/* eslint-disable max-lines */
import { t } from 'i18next';
import { ActionType } from 'src/generated-api-client';
import { ChangeLoanRelationshipManagerDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ChangeLoanRelationshipManagerDialog';
import { DisableBorrowersDeathLoanStatusDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/DisableBorrowersDeathLoanStatusDialog';
import { DisableLawsuitLoanStatusDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/DisableLawsuitLoanStatusDialog';
import { EnableBorrowersDeathLoanStatusDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/EnableBorrowersDeathLoanStatusDialog';
import { EnableLawsuitStatusDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/EnableLawsuitLoanStatusDialog';
import { EnableManualStatusDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/EnableManualLoanStatusFn';
import { ProposeGracePeriodDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposeGracePeriodDialog';
import { ProposeInterestAccrueStopRenewDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposeInterestAccrueStopRenewDialog';
import { ProposeInterestAdjustmentDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposeInterestAdjustmentDialog';
import { ProposeInterestRateChangingDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposeInterestRateChangingDialog';
import { ProposeMaturityDateExtensionDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposeMaturityDateExtensionDialog';
import { ProposeNewGracePeriodDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposeNewGracePeriodDialog';
import { ProposePastDateRepaymentDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposePastDateRepaymentDialog';
import { ProposePaymentAmountChangingDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposePaymentAmountChangingDialog';
import { ProposePaymentCancellationDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposePaymentCancellationDialog';
import { ProposePaymentDateChangingDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposePaymentDateChangingDialog';
import { ProposePenaltyAdjustmentDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposePenaltyAdjustmentDialog';
import { ProposeWriteOffDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ProposeWriteOffDialog';
import { ReviewGracePeriodDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewGracePeriodDialog';
import { ReviewInterestAccrueStopRenewDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewInterestAccrueStopRenewDialog';
import { ReviewInterestAdjustmentDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewInterestAdjustmentDialog';
import { ReviewInterestRateChangingDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewInterestRateChangingDialog';
import { ReviewMaturityDateExtensionDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewMaturityDateExtensionDialog';
import { ReviewNewGracePeriodDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewNewGracePeriodDialog';
import { ReviewPastDateRepaymentDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewPastDateRepaymentDialog';
import { ReviewPaymentAmountChangingDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewPaymentAmountChangingDialog';
import { ReviewPaymentCancellationDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewPaymentCancellationDialog';
import { ReviewPaymentDateChangingDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewPaymentDateChangingDialog';
import { ReviewPenaltyAdjustmentDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewPenaltyAdjustmentDialog';
import { ReviewWriteOffDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/ReviewWriteOffDialog';
import { SetSibStatusNormalDialog } from 'src/pages/LoanDetailsPage/Dialogs/LoanOperations/SetSibStatusNormalDialog';
import { StatusActionStore } from 'src/stores/LoanActionsStores/StatusActionStore';
import { LoanType } from 'src/stores/LoansStore/LoansStore';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getLoanActions = (loan?: LoanType): ActionDefinition[] => {
    return [...getUIActions(loan), ...getServerActions(loan)];
};

const getUIActions = (_loan?: LoanType): ActionDefinition[] => {
    return [];
};

const getServerActions = (loan?: LoanType): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(loan),
        loan?.actions,
    );
};
const getRegisteredServerActions = (loan?: LoanType): ActionDefinition[] => {
    const loanId = loan?.id;
    const { repaymentFrequency } = loan ?? {};

    return [
        {
            key: ActionType.PROPOSE_INTEREST_ACCRUAL_STOP_RENEW,
            label: t('Loan.Actions.PROPOSE_INTEREST_ACCRUAL_STOP_RENEW'),
            onClick: () => {
                ProposeInterestAccrueStopRenewDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.REVIEW_INTEREST_ACCRUAL_STOP_RENEW,
            label: t('Loan.Actions.REVIEW_INTEREST_ACCRUAL_STOP_RENEW'),
            onClick: () => {
                ReviewInterestAccrueStopRenewDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.PROPOSE_WRITE_OFF,
            label: t('Loan.Actions.PROPOSE_WRITE_OFF'),
            onClick: () => {
                ProposeWriteOffDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.REVIEW_WRITE_OFF,
            label: t('Loan.Actions.REVIEW_WRITE_OFF'),
            onClick: () => {
                ReviewWriteOffDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.PROPOSE_PAYMENT_CANCELLATION,
            label: t('Loan.Actions.PROPOSE_PAYMENT_CANCELLATION'),
            onClick: () => {
                ProposePaymentCancellationDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.REVIEW_PAYMENT_CANCELLATION,
            label: t('Loan.Actions.REVIEW_PAYMENT_CANCELLATION'),
            onClick: () => {
                ReviewPaymentCancellationDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.PROPOSE_PAST_DATE_PAYMENT,
            label: t('Loan.Actions.PROPOSE_PAST_DATE_PAYMENT'),
            onClick: () => {
                ProposePastDateRepaymentDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.REVIEW_PAST_DATE_PAYMENT,
            label: t('Loan.Actions.REVIEW_PAST_DATE_PAYMENT'),
            onClick: () => {
                ReviewPastDateRepaymentDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.PROPOSE_INTEREST_RATE_CHANGING,
            label: t('Loan.Actions.PROPOSE_INTEREST_RATE_CHANGING'),
            onClick: () => {
                ProposeInterestRateChangingDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.REVIEW_INTEREST_RATE_CHANGING,
            label: t('Loan.Actions.REVIEW_INTEREST_RATE_CHANGING'),
            onClick: () => {
                ReviewInterestRateChangingDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.PROPOSE_PAYMENT_DATE_CHANGING,
            label: t('Loan.Actions.PROPOSE_PAYMENT_DATE_CHANGING'),
            onClick: () => {
                ProposePaymentDateChangingDialog.render({
                    loanId,
                    repaymentFrequency,
                });
            },
        },
        {
            key: ActionType.REVIEW_PAYMENT_DATE_CHANGING,
            label: t('Loan.Actions.REVIEW_PAYMENT_DATE_CHANGING'),
            onClick: () => {
                ReviewPaymentDateChangingDialog.render({
                    loanId,
                    repaymentFrequency,
                });
            },
        },
        {
            key: ActionType.PROPOSE_GRACE_PERIOD,
            label: t('Loan.Actions.PROPOSE_GRACE_PERIOD'),
            onClick: () => {
                ProposeGracePeriodDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.REVIEW_GRACE_PERIOD,
            label: t('Loan.Actions.REVIEW_GRACE_PERIOD'),
            onClick: () => {
                ReviewGracePeriodDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.PROPOSE_NEW_GRACE_PERIOD_ALLOWING,
            label: t('Loan.Actions.PROPOSE_NEW_GRACE_PERIOD_ALLOWING'),
            onClick: () => {
                ProposeNewGracePeriodDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.REVIEW_NEW_GRACE_PERIOD_ALLOWING,
            label: t('Loan.Actions.REVIEW_NEW_GRACE_PERIOD_ALLOWING'),
            onClick: () => {
                ReviewNewGracePeriodDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.PROPOSE_PAYMENT_AMOUNT_CHANGING,
            label: t('Loan.Actions.PROPOSE_PAYMENT_AMOUNT_CHANGING'),
            onClick: () => {
                ProposePaymentAmountChangingDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.REVIEW_PAYMENT_AMOUNT_CHANGING,
            label: t('Loan.Actions.REVIEW_PAYMENT_AMOUNT_CHANGING'),
            onClick: () => {
                ReviewPaymentAmountChangingDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.PROPOSE_MATURITY_DATE_EXTENSION,
            label: t('Loan.Actions.PROPOSE_MATURITY_DATE_EXTENSION'),
            onClick: () => {
                ProposeMaturityDateExtensionDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.REVIEW_MATURITY_DATE_EXTENSION,
            label: t('Loan.Actions.REVIEW_MATURITY_DATE_EXTENSION'),
            onClick: () => {
                ReviewMaturityDateExtensionDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.PROPOSE_INTEREST_ADJUSTMENT,
            label: t('Loan.Actions.PROPOSE_INTEREST_ADJUSTMENT'),
            onClick: () => {
                ProposeInterestAdjustmentDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.REVIEW_INTEREST_ADJUSTMENT,
            label: t('Loan.Actions.REVIEW_INTEREST_ADJUSTMENT'),
            onClick: () => {
                ReviewInterestAdjustmentDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.PROPOSE_PENALTY_ADJUSTMENT,
            label: t('Loan.Actions.PROPOSE_PENALTY_ADJUSTMENT'),
            onClick: () => {
                ProposePenaltyAdjustmentDialog.render({
                    loanId,
                });
            },
        },
        {
            key: ActionType.REVIEW_PENALTY_ADJUSTMENT,
            label: t('Loan.Actions.REVIEW_PENALTY_ADJUSTMENT'),
            onClick: () => {
                ReviewPenaltyAdjustmentDialog.render({
                    loanId,
                });
            },
        },

        {
            key: ActionType.DISABLE_LAWSUIT_STATUS,
            label: t('Loan.Actions.DISABLE_LAWSUIT_STATUS'),
            onClick: () => {
                DisableLawsuitLoanStatusDialog.render({ loanId });
            },
        },

        {
            key: ActionType.ENABLE_LAWSUIT_STATUS,
            label: t('Loan.Actions.ENABLE_LAWSUIT_STATUS'),
            onClick: () => {
                EnableLawsuitStatusDialog.render({ loanId });
            },
        },

        {
            key: ActionType.ENABLE_BORROWER_DEATH_STATUS,
            label: t('Loan.Actions.ENABLE_BORROWER_DEATH_STATUS'),
            onClick: () => {
                EnableBorrowersDeathLoanStatusDialog.render({ loanId });
            },
        },

        {
            key: ActionType.DISABLE_BORROWER_DEATH_STATUS,
            label: t('Loan.Actions.DISABLE_BORROWER_DEATH_STATUS'),
            onClick: () => {
                DisableBorrowersDeathLoanStatusDialog.render({ loanId });
            },
        },

        {
            key: ActionType.ENABLE_MANUAL_LOAN_STATUS,
            label: t(
                'Loan.Actions.ENABLE_MANUAL_LOAN_STATUS',
                'Enable manual status',
            ),
            onClick: () => {
                EnableManualStatusDialog.render({ loanId });
            },
        },

        {
            key: ActionType.DISABLE_MANUAL_LOAN_STATUS,
            label: t(
                'Loan.Actions.DISABLE_MANUAL_LOAN_STATUS',
                'Disable manual status',
            ),
            onClick: async () => {
                await StatusActionStore.getInstance(
                    Number(loanId),
                ).disableManualStatus();
            },
        },

        {
            key: ActionType.SET_CIB_STATUS_NORMAL,
            label: t('Loan.Actions.SET_CIB_STATUS_NORMAL', 'Change CIB status'),
            onClick: () => {
                SetSibStatusNormalDialog.render({ loanId });
            },
        },
        {
            key: ActionType.UPDATE_RELATIONSHIP_MANAGER,
            label: t('Loan.Actions.UPDATE_RELATIONSHIP_MANAGER'),
            onClick: () => {
                ChangeLoanRelationshipManagerDialog.render({
                    loanId,
                });
            },
        },
    ];
};
