/* eslint-disable max-lines */
import { t } from 'i18next';
import { ActionType, GetProduct200Response } from 'src/generated-api-client';
import { ExcludeProductFromLimitOffersDialog } from 'src/pages/LoanProducts/Dialogs//ExcludeProductFromLimitOffersDialog';
import { AddProductToLimitOffersDialog } from 'src/pages/LoanProducts/Dialogs/AddProductToLimitOffersDialog';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getProductActions = (
    product?: GetProduct200Response,
    store?: BasicStore<any>,
): ActionDefinition[] => {
    return [...getUIActions(product), ...getServerActions(product, store)];
};

const getUIActions = (_product?: GetProduct200Response): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    product?: GetProduct200Response,
    store?: BasicStore<any>,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(product, store),
        product?.actions,
    );
};
const getRegisteredServerActions = (
    product?: GetProduct200Response,
    store?: BasicStore<any>,
): ActionDefinition[] => {
    const productId = product?.id;
    const isExcludedFromOffer = product?.excludedFromOffer;

    return [
        {
            key: ActionType.UPDATE_PRODUCT_OFFER_EXCLUSION,
            label: t('Product.Actions.UPDATE_PRODUCT_OFFER_EXCLUSION'),
            onClick: () => {
                isExcludedFromOffer
                    ? AddProductToLimitOffersDialog.render({
                          productId,
                          store,
                      })
                    : ExcludeProductFromLimitOffersDialog.render({
                          productId,
                          store,
                      });
            },
        },
    ];
};
