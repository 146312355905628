/* eslint-disable max-lines */
import { notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListSelectField } from 'src/components/Form/ListSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { CollateralListEntry, IdNameEntry } from 'src/generated-api-client';
import { AddCollateralFilterForm } from 'src/pages/LoanDetailsPage/Dialogs/Collaterals/AddCollateralFilterForm';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { LoansStore, LoanType } from 'src/stores/LoansStore/LoansStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type AddCollateralDialogProps = ModalRendererProps<{
    loan: LoanType;
}>;

const store = LoansStore;

const AddCollateralDialogFn = observer(
    ({ loan, ...props }: AddCollateralDialogProps) => {
        const isLoading =
            store.addCollateralLoader.isLoading ||
            CollateralsStore.listLoader.isLoading;

        useEffect(() => {
            return () => {
                CollateralsStore.listLoader.reset();
                CollateralsStore.filterCriteria?.resetFilter();
                CollateralsStore.pager?.reset();
                store.addCollateralLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.addCollateralLoader.errorData,
        );

        return (
            <Modal
                isLoading={isLoading}
                title={t('Dialog.AddCollateral.Title')}
                onFinish={async (values) => {
                    await store.addCollateral(
                        loan?.applicationId,
                        values.collateralId,
                    );

                    if (store.addCollateralLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.addCollateralLoader.hasError) {
                        notification.success({
                            message: t('Dialog.AddCollateral.Message.Success'),
                        });
                        store.loadItem(loan?.id);
                    }
                }}
                headerExtra={
                    <AddCollateralFilterForm store={CollateralsStore} />
                }
                formProps={{ fields }}
                width={1200}
                {...props}
            >
                <StyledListSelectField
                    name="collateralId"
                    store={CollateralsStore}
                    columns={collateralsListColumns}
                    rules={[ValidationRules.required]}
                />
            </Modal>
        );
    },
);

export const AddCollateralDialog = addModalRenderer(AddCollateralDialogFn);

const StyledListSelectField = styled(ListSelectField)`
    .ant-table-wrapper {
        padding: 10px 0;
    }
`;

const collateralsListColumns: ColumnsType<CollateralListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Dialog.AddCollateral.List.Id" />,
    },
    {
        key: 'type',
        dataIndex: 'type',
        title: <Trans i18nKey="Dialog.AddCollateral.List.Type" />,
        render: (value) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView enumName="COLLATERAL_TYPE" value={value} />
                    )}
                />
            );
        },
    },
    {
        key: 'subType',
        dataIndex: 'subType',
        title: <Trans i18nKey="Dialog.AddCollateral.List.SubType" />,
        render: (value) => {
            return (
                <DataView
                    value={value}
                    format={(value) => (
                        <EnumView
                            enumName="COLLATERAL_SUB_TYPE"
                            value={value}
                        />
                    )}
                    hideLabel
                />
            );
        },
    },
    {
        key: 'registrationNumber',
        dataIndex: 'registrationNumber',
        title: <Trans i18nKey="Dialog.AddCollateral.List.RegistrationNumber" />,
        render: (value) => {
            return <StyledRegistrationNumber>{value}</StyledRegistrationNumber>;
        },
    },
    {
        key: 'establishDateOrDateOfManufacture',
        title: (
            <Trans i18nKey="Dialog.AddCollateral.List.EstablishDateOrDateOfManufacture" />
        ),
        render: (_, record) => getDate(record),
    },
    {
        key: 'owner',
        dataIndex: 'owner',
        title: <Trans i18nKey="Dialog.AddCollateral.List.Owner" />,
        render: (value: IdNameEntry) => {
            return <DataView value={value?.name} format="string" hideLabel />;
        },
    },
    {
        key: 'loan',
        dataIndex: 'loan',
        title: <Trans i18nKey="Dialog.AddCollateral.List.Loan" />,
        render: (value: IdNameEntry) => {
            return <DataView value={value?.name} format="string" hideLabel />;
        },
    },
    {
        key: 'createdBy',
        dataIndex: 'createdBy',
        title: <Trans i18nKey="Dialog.AddCollateral.List.CreatedBy" />,
        render: (value: IdNameEntry) => {
            return <DataView value={value?.name} format="string" hideLabel />;
        },
    },
    {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: <Trans i18nKey="Dialog.AddCollateral.List.CreatedAt" />,
        render: (value) => {
            return (
                <DataView value={value} format="date-time-seconds" hideLabel />
            );
        },
    },
];

const getDate = (item?: CollateralListEntry) => {
    if (item?.establishedDate) {
        return (
            <DataView value={item.establishedDate} format="date" hideLabel />
        );
    }

    if (item?.dateOfManufacture) {
        return (
            <DataView
                value={item.dateOfManufacture}
                format="string"
                hideLabel
            />
        );
    }

    return <DataView value={undefined} hideLabel />;
};

const StyledRegistrationNumber = styled.span`
    word-break: break-all;
    word-wrap: break-word;
`;
