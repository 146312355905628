import { Form, notification } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ErrorField } from 'src/components/Form/ErrorField';
import { ListSelectField } from 'src/components/Form/ListSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { LoanDetails } from 'src/generated-api-client';
import { BankStatementLoansFilterForm } from 'src/pages/BankStatementsPage/Components/BankStatementLoansFilterForm';
import { LoansStatus } from 'src/pages/LoanDetailsPage/Components/LoansStatus';
import { BankStatementActionsStore } from 'src/stores/BankStatementActionsStore/BankStatementActionsStore';
import { BankStatementsStore } from 'src/stores/BankStatementsStore/BankStatementsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type AssignToLoanDialogFnProps = ModalRendererProps<{
    bankStatementId?: number;
}>;

export const AssignToLoanDialogFn = observer(
    ({ bankStatementId, ...props }: AssignToLoanDialogFnProps) => {
        const store = BankStatementActionsStore.getInstance(
            Number(bankStatementId),
        );
        const [form] = Form.useForm();

        const isLoading =
            store.listLoader.isLoading || store.assignToLoanLoader.isLoading;
        const fields = FormHelper.getFieldDataItems(
            store?.assignToLoanLoader.errorData,
        );

        useEffect(() => {
            return () => {
                store.listLoader.reset();
                store.filterCriteria?.resetFilter();
                store.pager?.reset();
                store.assignToLoanLoader.reset();
            };
        }, []);

        return (
            <Modal
                title={t('Dialog.AssignToLoan.Title')}
                isLoading={isLoading}
                onFinish={async (values) => {
                    await store.assignToLoan(values);

                    if (store.assignToLoanLoader.hasError) {
                        if (store.assignToLoanLoader.errorData.status === 409) {
                            notification.error({
                                message:
                                    store.assignToLoanLoader.errorData.detail ||
                                    t(
                                        'Dialog.AssignToLoan.Message.409Conflict',
                                    ),
                            });
                        }

                        if (store.assignToLoanLoader.errorData.status === 400) {
                            notification.error({
                                message:
                                    store.assignToLoanLoader.errorData
                                        .violations?.[0].message ||
                                    t(
                                        'Dialog.AssignToLoan.Message.400Violations',
                                    ),
                            });
                        }

                        return Promise.reject();
                    }

                    if (!store.assignToLoanLoader.hasError) {
                        notification.success({
                            message: t('Dialog.AssignToLoan.Message.Success'),
                        });
                    }

                    BankStatementsStore.loadList();
                }}
                form={form}
                formProps={{ fields }}
                width={1200}
                headerExtra={<BankStatementLoansFilterForm store={store} />}
                {...props}
            >
                <StyledListSelectField
                    name="loanId"
                    store={store}
                    columns={AssignToLoanColumns}
                    rules={[ValidationRules.required]}
                />
                <ErrorField name="amount" />
            </Modal>
        );
    },
);

export const AssignToLoanDialog = addModalRenderer(AssignToLoanDialogFn);

export const AssignToLoanColumns: ColumnType<LoanDetails>[] = [
    {
        key: 'customerName',
        dataIndex: 'customerName',
        title: <Trans i18nKey="Dialog.AssignToLoan.CustomerName" />,
    },
    {
        key: 'registrationNumber',
        dataIndex: 'registrationNumber',
        title: <Trans i18nKey="Dialog.AssignToLoan.RegistrationNumber" />,
    },
    {
        key: 'contractId',
        dataIndex: 'contractId',
        title: <Trans i18nKey="Dialog.AssignToLoan.ContractId" />,
    },
    {
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
        title: <Trans i18nKey="Dialog.AssignToLoan.PhoneNumber" />,
        render: (value) => {
            return <DataView value={value} format="tel" hideLabel />;
        },
    },
    {
        key: 'accountNumber',
        dataIndex: 'accountNumber',
        title: <Trans i18nKey="Dialog.AssignToLoan.AccountNumber" />,
        render: (value) => {
            return <DataView value={value} format="string" hideLabel />;
        },
    },
    {
        key: 'nextPaymentDate',
        dataIndex: 'nextPaymentDate',
        title: <Trans i18nKey="Dialog.AssignToLoan.NextPaymentDate" />,
        render: (value) => {
            return <DataView value={value} format="date" hideLabel />;
        },
    },
    {
        key: 'nextPaymentAmount',
        dataIndex: 'nextPaymentAmount',
        title: <Trans i18nKey="Dialog.AssignToLoan.NextPaymentAmount" />,
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'loanBalance',
        dataIndex: 'loanBalance',
        title: <Trans i18nKey="Dialog.AssignToLoan.LoanBalance" />,
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'loanAmount',
        dataIndex: 'loanAmount',
        title: <Trans i18nKey="Dialog.AssignToLoan.LoanAmount" />,
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'productType',
        dataIndex: 'productType',
        title: <Trans i18nKey="Dialog.AssignToLoan.LoanProduct" />,
        render: (value) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView enumName="PRODUCT_TYPE" value={value} />
                    )}
                />
            );
        },
    },
    {
        key: 'branch',
        dataIndex: 'branch',
        title: <Trans i18nKey="Dialog.AssignToLoan.Branch" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="string" />;
        },
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Dialog.AssignToLoan.Status" />,
        render: (value) => {
            return <LoansStatus status={value} />;
        },
    },
];

const StyledListSelectField = styled(ListSelectField)`
    .ant-table-wrapper {
        padding: 10px 0;
    }
`;
