export type BaseError = {
    title: string;
    status: number;
    detail: any;
};

export type ConstraintViolationError = {
    type: string;
    title: string;
    status: number;
    violations: Violation[];
};

export type Violation = { field: string; message: string };

const isConstraintViolationError = (
    error?: any,
): error is ConstraintViolationError => {
    if (error?.type && Array.isArray(error?.violations)) {
        return true;
    }

    return false;
};

const isBaseError = (error?: any): error is BaseError => {
    if (error?.detail && typeof error.detail === 'string' && error?.title) {
        return true;
    }

    return false;
};

export const ErrorDataHelper = {
    isConstraintViolationError,
    isBaseError,
};
