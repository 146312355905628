import { Button, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'src/components/Form/Form';
import { HiddenField } from 'src/components/Form/HiddenField';
import { PasswordField } from 'src/components/Form/PssswordField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { useSearchParams } from 'src/hooks/useSeachParams';
import { AuthPage } from 'src/pages/Auth/AuthPage';
import { AuthPageWithMessage } from 'src/pages/Auth/AuthPageWithMessage';
import { PasswordPolicy, passwordRule } from 'src/pages/Auth/PasswordPolicy';
import {
    RecoveryExpiredPasswordForm,
    RecoveryExpiredPasswordStore,
} from 'src/stores/LocalAuth/RecoveryExpiredPasswordStore';
import { FormHelper } from 'src/utils/FormHelper';
import styled from 'styled-components';

type SearchParams = {
    code?: string;
    email?: string;
};

export const RecoveryExpiredPasswordPage = observer(() => {
    const searchParams = useSearchParams();
    const { code, email } = searchParams.params as SearchParams;

    const pageTitle = t('Page.Auth.RecoveryExpiredPassword.Title');

    useEffect(() => {
        return () => {
            RecoveryExpiredPasswordStore.reset();
        };
    }, []);

    useEffect(() => {
        if (code && email) {
            RecoveryExpiredPasswordStore.verifyCode(code, email);
        }
    }, [code, email]);

    if (!code || !email || RecoveryExpiredPasswordStore.isInvalidLink) {
        return (
            <AuthPageWithMessage
                title={pageTitle}
                message={t(
                    'Page.Auth.RecoveryExpiredPassword.InvalidLinkToRecoveryExpiredPassword',
                )}
                messageType="danger"
            />
        );
    }

    const showError =
        RecoveryExpiredPasswordStore.isCodeValidated &&
        !RecoveryExpiredPasswordStore.isValidCode;

    if (showError) {
        return (
            <AuthPageWithMessage
                title={pageTitle}
                message={t('Page.Auth.RecoveryPassword.CodeIsNotValid')}
                messageType="danger"
            />
        );
    }

    const fields = FormHelper.getFieldDataItems(
        RecoveryExpiredPasswordStore.recoveryExpiredPasswordLoader.errorData,
    );

    return (
        <AuthPage
            title={pageTitle}
            isLoading={
                RecoveryExpiredPasswordStore.isCodeValidating ||
                RecoveryExpiredPasswordStore.isExpiredPasswordRecovering
            }
        >
            {RecoveryExpiredPasswordStore.isValidCode && (
                <Form
                    onFinish={async (values: RecoveryExpiredPasswordForm) => {
                        await RecoveryExpiredPasswordStore.recoveryExpiredPassword(
                            values,
                        );
                    }}
                    fields={fields}
                >
                    <HiddenField name="code" initialValue={code} />
                    <HiddenField name="email" initialValue={email} />
                    <PasswordField
                        label={t(
                            'Page.Auth.RecoveryExpiredPassword.SetPassword',
                        )}
                        name="password"
                        rules={[ValidationRules.requiredPassword, passwordRule]}
                    />
                    <PasswordPolicy />

                    <StyledSpace direction="vertical" size={16}>
                        <Button type="primary" htmlType="submit" block>
                            {t('Page.Auth.RecoveryPassword.Submit')}
                        </Button>
                        <Link to={RoutePaths.authLogin}>
                            {t('Page.Auth.GoToLoginPage')}
                        </Link>
                    </StyledSpace>
                </Form>
            )}
        </AuthPage>
    );
});

const StyledSpace = styled(Space)`
    width: 100%;
`;
