/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { N113Task } from 'src/generated-api-client';
import { ConditionCardProps } from 'src/pages/TaskViewPage/TaskTypes';

export const CcConditionCard = observer(
    ({ task }: ConditionCardProps<N113Task>) => {
        const { creditCommitteeConditions } = task;

        return (
            <Card
                title={t(
                    'Task.Form.N113ApproveDisbursementTask.CcCondition.Title',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={creditCommitteeConditions?.amount}
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.CcCondition.Amount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                creditCommitteeConditions?.firstRepaymentDate
                            }
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.CcCondition.FirstRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                creditCommitteeConditions?.repaymentFrequency
                            }
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.CcCondition.RepaymentFrequency',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="REPAYMENT_FREQUENCY"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                creditCommitteeConditions?.secondRepaymentDate
                            }
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.CcCondition.SecondRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={
                                creditCommitteeConditions?.monthlyInterestRate
                            }
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.CcCondition.InterestRate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={creditCommitteeConditions?.term}
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.CcCondition.Term',
                            )}
                            format="number-int"
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
