import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { SelectField } from 'src/components/Form/SelectField';
import { FormHelper } from 'src/utils/FormHelper';

export const CollectionActivitiesFilterForm = observer(() => {
    return (
        <>
            <DatePickerField
                name="createdAtFrom"
                label={t(
                    'Page.CollectionDetailsPage.Activity.Filter.CreatedAtFrom',
                )}
            />
            <DatePickerField
                name="createdAtTo"
                label={t(
                    'Page.CollectionDetailsPage.Activity.Filter.CreatedAtTo',
                )}
            />

            <EnumSelectField
                mode="multiple"
                name="generalTypes"
                label={t(
                    'Page.CollectionDetailsPage.Activity.Filter.GeneralType',
                )}
                enumName="COLLECTION_ACTIVITY_GENERAL_TYPE"
            />
            <EnumSelectField
                mode="multiple"
                name="statuses"
                label={t('Page.CollectionDetailsPage.Activity.Filter.Status')}
                enumName="COLLECTION_ACTIVITY_STATUS"
            />
            <SelectField
                name="civilServantInvolved"
                label={t(
                    'Page.CollectionDetailsPage.Activity.Filter.CivilServantInvolved',
                )}
                options={FormHelper.objAsOptions(
                    {
                        true: true,
                        false: false,
                    },
                    'Primitive.Boolean.',
                )}
            />
        </>
    );
});
