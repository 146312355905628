import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { PledgedLoanApplicationTaskDecisionType } from 'src/generated-api-client';

type N18TaskDecisionProps = {
    decision?: PledgedLoanApplicationTaskDecisionType;
};

export const N18TaskDecision = observer(
    ({ decision }: N18TaskDecisionProps) => {
        const decisionInfo = getDecisionInfo(decision);

        return <StatusView statusInfo={decisionInfo} />;
    },
);

const getDecisionInfo = (decision?: PledgedLoanApplicationTaskDecisionType) => {
    return (
        (decision && decisionInfoMap[decision]) || {
            title: t(`N18CsDecisionTask.Decision.${decision}`),
        }
    );
};

const decisionInfoMap: Record<string, StatusInfo> = {
    [PledgedLoanApplicationTaskDecisionType.APPROVED]: {
        title: <Trans i18nKey="N18CsDecisionTask.Decision.APPROVED" />,
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
    [PledgedLoanApplicationTaskDecisionType.DECLINED]: {
        title: <Trans i18nKey="N18CsDecisionTask.Decision.DECLINED" />,
        color: '#fa656c',
        backgroundColor: '#FFE4E4',
    },
    [PledgedLoanApplicationTaskDecisionType.NEED_ADJUSTMENT]: {
        title: <Trans i18nKey="N18CsDecisionTask.Decision.NEED_ADJUSTMENT" />,
        color: '#797064',
        backgroundColor: '#FBEED5',
    },
};
