import i18next from 'i18next';
import { computed } from 'mobx';
import { LegalIndustryItem, Localization } from 'src/generated-api-client';
import { dictionaryApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LegalIndustryOptionsStoreClass extends BasicStore<LegalIndustryItem> {
    api: BasicStoreApi<LegalIndustryItem> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                dictionaryApi.getLegalIndustries(),
            );

            return result || [];
        },
    };

    @computed get industryOptions() {
        return this.list.map(convertIndustryListItemToOption);
    }

    @computed get industriesMap() {
        return Object.fromEntries(
            this.list.map((industryItem) => {
                return [industryItem.id, industryItem];
            }),
        );
    }

    industryActivityTypesOptions(industry: number) {
        return this.industriesMap[industry]?.legalActivities?.map(
            convertIndustryListItemToOption,
        );
    }
}

export const LegalIndustryOptionsStore = new LegalIndustryOptionsStoreClass();

export const convertIndustryListItemToOption = (value: any) => ({
    value: String(value.id),
    label: value.l10n?.[i18next.language as keyof Localization],
    key: String(value.id),
});
