import { notification } from 'antd';
import { t } from 'i18next';
import { action, computed } from 'mobx';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';

export class ResetPasswordStoreClass {
    resetPasswordLoader = new AsyncOperationWithStatus((_values) => {
        console.warn('Methode "resetPassword" not implemented');

        return Promise.reject();
    });

    @action async resetPassword(values: any) {
        await this.resetPasswordLoader.call(values);
        if (!this.resetPasswordLoader.hasError) {
            notification.success({
                message: t('Page.Auth.ResetPassword.PasswordHasBeenChanged'),
            });
            Router.navigate(RoutePaths.profile);
        }
    }

    @computed get isPasswordReset() {
        return (
            this.resetPasswordLoader.countLoads > 0 &&
            !this.resetPasswordLoader.hasError
        );
    }

    @computed get isPasswordResetting() {
        return this.resetPasswordLoader.isLoading;
    }
}

export const ResetPasswordStore = new ResetPasswordStoreClass();
