/* eslint-disable max-lines */
import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    N18Task,
    N18TaskDecisionAddLoanConditions,
} from 'src/generated-api-client';
import { TaskCommentsView } from 'src/pages/TaskDetailsPage/Components/TaskCommentsView';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { SubmitTaskDialog } from 'src/pages/TaskDetailsPage/Dialogs/SubmitTaskDialog';
import { LaaLoanConditionCard } from 'src/pages/TaskDetailsPage/N18CsDecisionTask/Components/LaaLoanConditionCard';
import { RequestedLoanConditionCard } from 'src/pages/TaskDetailsPage/N18CsDecisionTask/Components/RequestedLoanConditionCard';
import { N18TaskDecisionDetailsDialog } from 'src/pages/TaskDetailsPage/N18CsDecisionTask/Dialogs/N18TaskDecisionDetailsDialog';
import { N18CsDecisionTaskApproveVariables } from 'src/pages/TaskDetailsPage/N18CsDecisionTask/N18CsDecisionTaskAproveVariables';
import { N18CsDecisionTaskDeclineVariables } from 'src/pages/TaskDetailsPage/N18CsDecisionTask/N18CsDecisionTaskDeclineVariables';
import { N18CsDecisionTaskReturnVariables } from 'src/pages/TaskDetailsPage/N18CsDecisionTask/N18CsDecisionTaskReturnVariables';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

export const N18CsDecisionTask = observer(
    ({ viewModel }: TaskProps<N18Task>) => {
        const task = viewModel.currentTask || {};
        const {
            dmtUserConditions,
            laatUserConditions,
            laatLeaderComment,
            requestedConditions,
            loanApplication,
            collaterals,
        } = task;
        const showRequestedConditions = Boolean(requestedConditions);
        const showLaatUserConditions = Boolean(laatUserConditions);

        const comments = [
            {
                label: t('Component.TaskCommentsView.LAATLeaderComment'),
                value: laatLeaderComment,
            },
            {
                label: t('Component.TaskCommentsView.LAATUserComment'),
                value: laatUserConditions?.comment,
            },
        ];

        return (
            <Row gutter={[16, 24]}>
                <Col xs={24}>
                    <StyledText>
                        <Trans i18nKey="Task.Form.N18CsDecisionTask.Descriptions" />
                    </StyledText>
                </Col>
                <Col xs={24}>
                    <TaskLinkView
                        loanApplication={loanApplication}
                        collaterals={collaterals}
                    />
                </Col>
                <Col xs={24}>
                    {showRequestedConditions && (
                        <RequestedLoanConditionCard task={task} />
                    )}
                </Col>
                <Col xs={24}>
                    {showLaatUserConditions && (
                        <LaaLoanConditionCard task={task} />
                    )}
                </Col>
                <Col xs={24}>
                    <TaskCommentsView comments={comments} />
                </Col>
                <Col xs={24}>
                    <Button
                        type="primary"
                        onClick={() => {
                            N18TaskDecisionDetailsDialog.render({
                                data: dmtUserConditions,
                            });
                        }}
                    >
                        {t(
                            'Task.Form.N18CsDecisionTask.Btn.ViewDecisionDetail',
                        )}
                    </Button>
                </Col>
                <Col xs={24}>
                    <TaskForm
                        fields={viewModel.submitTaskErrorFields}
                        initialValues={getInitialValues(task)}
                        onFinish={async (values) => {
                            const submitValues =
                                convertFormValuesToSubmitValues(values);
                            await viewModel.submitTask(submitValues);
                            if (!viewModel.submitTaskLoaderHasError) {
                                Router.navigate(RoutePaths.tasks);
                            }
                        }}
                    >
                        <Row gutter={16}>
                            <N18CsDecisionTaskApproveVariables />

                            <Col xs={24}>
                                <Space size={20}>
                                    <Button
                                        type={
                                            viewModel.isCurrentTaskEqualWorkingTask
                                                ? 'primary'
                                                : 'default'
                                        }
                                        htmlType="submit"
                                    >
                                        {t('Task.Form.Btn.Approve')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            SubmitTaskDialog.render({
                                                viewModel,
                                                title: t(
                                                    'Dialog.Reject.N18CsDecisionTask.Title',
                                                ),
                                                children: (
                                                    <N18CsDecisionTaskDeclineVariables />
                                                ),
                                            });
                                        }}
                                    >
                                        {t('Task.Form.Btn.Reject')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            SubmitTaskDialog.render({
                                                viewModel,
                                                title: t(
                                                    'Dialog.Return.N18CsDecisionTask.Title',
                                                ),
                                                children: (
                                                    <N18CsDecisionTaskReturnVariables />
                                                ),
                                            });
                                        }}
                                    >
                                        {t('Task.Form.Btn.Return')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            Router.navigate(RoutePaths.tasks);
                                        }}
                                    >
                                        {t('Task.Form.Btn.Back')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </TaskForm>
                </Col>
            </Row>
        );
    },
);

const getInitialValues = (data?: N18Task) => {
    return {
        ...data?.laatUserConditions,
        secondRepaymentDate:
            data?.laatUserConditions?.secondRepaymentDate &&
            moment(data?.laatUserConditions?.secondRepaymentDate),
        firstRepaymentDate:
            data?.laatUserConditions?.firstRepaymentDate &&
            moment(data?.laatUserConditions?.firstRepaymentDate),
        requestedTerm: data?.laatUserConditions?.term,
        comment: undefined,
    };
};

const convertFormValuesToSubmitValues = (
    data?: N18TaskDecisionAddLoanConditions,
) => {
    return {
        ...data,
        firstRepaymentDate:
            data?.firstRepaymentDate &&
            DateHelper.formatISODate(data?.firstRepaymentDate),
        secondRepaymentDate:
            data?.secondRepaymentDate &&
            DateHelper.formatISODate(data?.secondRepaymentDate),
    } as N18TaskDecisionAddLoanConditions;
};

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
