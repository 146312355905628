import { action, computed } from 'mobx';
import {
    CollectionActivityGeneralType,
    CollectionActivityNextAction,
    CollectionLevel,
    CollectionStatus,
    CreditBureauStatus,
    DebtCollection,
    DebtCollectionListEntry,
    DebtCollector,
    LoanStatus,
    ProductType,
} from 'src/generated-api-client';
import { debtCollectionApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type CollectionFilter = {
    customerId?: number;
    customerName?: string;
    phoneNumber?: string;
    registrationNumber?: string;
    debtCollectorId?: number;
    levels?: Array<CollectionLevel>;
    statuses?: Array<CollectionStatus>;
    lastActivityGeneralTypes?: Array<CollectionActivityGeneralType>;
    lastActivityDate?: string;
    nextActions?: Array<CollectionActivityNextAction>;
    nextActionDate?: string;
    principalAmountFrom?: number;
    principalAmountTo?: number;
    maxOverdueDayFrom?: number;
    maxOverdueDayTo?: number;
    loanStatuses?: Array<LoanStatus>;
    creditBureauStatuses?: Array<CreditBureauStatus>;
    childBranchId?: number;
    productTypes?: Array<ProductType>;
    provinceCode?: string;
    districtCode?: string;
    isCompleted?: boolean;
};

const CollectionsFilterKeys: Array<keyof CollectionFilter> = [
    'customerId',
    'customerName',
    'phoneNumber',
    'registrationNumber',
    'debtCollectorId',
    'levels',
    'statuses',
    'lastActivityGeneralTypes',
    'lastActivityDate',
    'nextActions',
    'nextActionDate',
    'principalAmountFrom',
    'principalAmountTo',
    'maxOverdueDayFrom',
    'maxOverdueDayTo',
    'loanStatuses',
    'creditBureauStatuses',
    'childBranchId',
    'productTypes',
    'provinceCode',
    'districtCode',
    'isCompleted',
];

export class DebtCollectionsStoreClass extends BasicStore<
    DebtCollectionListEntry,
    CollectionFilter,
    any,
    any,
    DebtCollection
> {
    api: BasicStoreApi<DebtCollectionListEntry, any, any, DebtCollection> = {
        loadList: () => {
            this.collectionSummaryLoader?.call();

            return RequestHelper.unwrapFromAxiosPromise(
                debtCollectionApi.getAllCollections(this.listParams),
            );
        },
        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                debtCollectionApi.getDebtCollection({ id }),
            );
        },
    };

    filterCriteria = new FilterCriteria<CollectionFilter>(
        CollectionsFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<DebtCollectionListEntry>(
        'id',
        'descend',
    );

    collectionSummaryLoader = new AsyncOperationWithStatus(() => {
        return RequestHelper.unwrapFromAxiosPromise(
            debtCollectionApi.getCollectionSummary(this.filterCriteria?.filter),
        );
    });

    @computed get summaryItem() {
        return this.collectionSummaryLoader.data;
    }

    debtCollectorsLoader = new AsyncOperationWithStatus(() => {
        return RequestHelper.unwrapFromAxiosPromise(
            debtCollectionApi.getDebtCollectorCandidates(),
        );
    });

    @action async getDebtCollectorsList() {
        await this.debtCollectorsLoader.call();
    }

    debtCollectionReAssignLoader = new AsyncOperationWithStatus(
        (
            debtCollectorId: number,
            collectionIds: Array<number>,
            isAuto: boolean,
        ) => {
            return RequestHelper.unwrapFromAxiosPromise(
                debtCollectionApi.debtCollectionsReAssign({
                    debtCollectionReAssign: {
                        debtCollectorId,
                        collectionIds,
                        isAuto,
                    },
                }),
            );
        },
    );

    @action async reAssignDebtCollections(
        debtCollectorId: number,
        collectionIds: Array<number>,
        isAuto: boolean,
    ) {
        await this.debtCollectionReAssignLoader.call(
            debtCollectorId,
            collectionIds,
            isAuto,
        );
    }

    @computed get debtCollectorsOptions() {
        const groupedDebtCollectors: { [key: string]: DebtCollector[] } = {};

        this.debtCollectorsLoader?.data?.forEach((debtCollector) => {
            if (!groupedDebtCollectors[debtCollector.roleName]) {
                groupedDebtCollectors[debtCollector.roleName] = [];
            }

            groupedDebtCollectors[debtCollector.roleName].push(debtCollector);
        });

        return Object.entries(groupedDebtCollectors).map(
            ([key, debtCollectors]) => ({
                label: key,
                options: debtCollectors.map(({ user }) => ({
                    label: user?.name,
                    value: user?.id,
                })),
            }),
        );
    }

    @computed get roleOptions() {
        const roles: string[] = [];

        this.debtCollectorsLoader?.data?.forEach((debtCollector) => {
            if (!roles.includes(debtCollector.roleName)) {
                roles.push(debtCollector.roleName);
            }
        });

        return roles.map((role) => {
            return { label: role, value: role };
        });
    }

    activeLoanLoader = new AsyncOperationWithStatus((id: number) => {
        return RequestHelper.unwrapFromAxiosPromise(
            debtCollectionApi.getDebtCollectionCustomerActiveLoans({ id }),
        );
    });

    @action async getActiveLoanList(id: number) {
        await this.activeLoanLoader.call(id);
    }

    @computed get activeLoanList() {
        return this.activeLoanLoader.data;
    }

    updateCollectionLevelLoader = new AsyncOperationWithStatus(
        (id: number, data: any) => {
            return RequestHelper.unwrapFromAxiosPromise(
                debtCollectionApi.updateDebtCollection({
                    id,
                    updateDebtCollectionRequest: {
                        ...data,
                        type: 'DebtCollectionLevelUpdate',
                    },
                }),
            );
        },
    );

    @action async updateCollectionLevel(id: number, data: any) {
        await this.updateCollectionLevelLoader.call(id, data);
    }
}

export const DebtCollectionsStore = new DebtCollectionsStoreClass();
