import {
    BnplProduct,
    BnplProductListEntry,
    BnplProductUpdate,
    NewBnplProduct,
    ProductStatus,
} from 'src/generated-api-client';
import { productsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type BnplProductsFilter = {
    id?: number;
    externalId?: number;
    name?: string;
    statuses?: ProductStatus[];
    transferringFeeFrom: number;
    transferringFeeTo: number;
};
const BnplProductsFilterKeys: Array<keyof BnplProductsFilter> = [
    'id',
    'externalId',
    'name',
    'statuses',
    'transferringFeeFrom',
    'transferringFeeTo',
];

export class BnplProductsStoreClass extends BasicStore<
    BnplProductListEntry,
    BnplProductsFilter,
    BnplProductUpdate,
    NewBnplProduct,
    BnplProduct
> {
    api: BasicStoreApi<
        BnplProductListEntry,
        BnplProductUpdate,
        NewBnplProduct,
        BnplProduct
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.getBnplProducts(this.listParams),
            );
        },

        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.getProduct({ id }),
            ) as Promise<BnplProduct>;
        },

        createItem: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.createProduct({
                    createProductRequest: {
                        ...data,
                        type: 'NewBnplProduct',
                    } as NewBnplProduct,
                }),
            );
        },

        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.updateProduct({
                    id: Number(id),
                    updateProductRequest: {
                        ...data,
                        type: 'BnplProductUpdate',
                    } as BnplProductUpdate,
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<BnplProductsFilter>(
        BnplProductsFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<BnplProductListEntry>('name');
}

export const BnplProductsStore = new BnplProductsStoreClass();
