/* eslint-disable max-lines, import/no-cycle */
import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { N113Task } from 'src/generated-api-client';
import { ConditionCardProps } from 'src/pages/TaskViewPage/TaskTypes';
import { UrlHelper } from 'src/utils/UrlHelper';

export const RmConditionCard = observer(
    ({ task }: ConditionCardProps<N113Task>) => {
        const { relationshipManagerConditions, commission, product } = task;

        const productDetailsUrl = UrlHelper.getTargetUrl(
            RoutePaths.productPledgedDetails,
            { productId: product?.id },
        );

        return (
            <Card
                title={t(
                    'Task.Form.N113ApproveDisbursementTask.RmCondition.Title',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={relationshipManagerConditions?.amount}
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.RmCondition.Amount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                relationshipManagerConditions?.firstRepaymentDate
                            }
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.RmCondition.FirstRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={commission}
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.RmCondition.CommissionPaidStatus',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                relationshipManagerConditions?.repaymentFrequency
                            }
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.RmCondition.RepaymentFrequency',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="REPAYMENT_FREQUENCY"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={
                                relationshipManagerConditions?.secondRepaymentDate
                            }
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.RmCondition.SecondRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={product?.name}
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.RmCondition.PledgedLoanProduct',
                            )}
                            format="link-internal"
                            formatterProps={{
                                to: productDetailsUrl,
                            }}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                relationshipManagerConditions?.monthlyInterestRate
                            }
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.RmCondition.InterestRate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={relationshipManagerConditions?.term}
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.RmCondition.Term',
                            )}
                            format="number-int"
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
