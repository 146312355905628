import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { IntangibleAssetCollateral } from 'src/generated-api-client';
import { getIntangibleAssetCollateralGeneralInfoActions } from 'src/pages/CollateralDetailsPage/Tabs/IntangibleAssetCollateralTab/IntangibleAssetCollateralGeneralInfoActions.config';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';

export const IntangibleAssetCollateralGeneralInfo = observer(() => {
    const collateral =
        CollateralsStore.currentItem as IntangibleAssetCollateral;
    const menuActions =
        getIntangibleAssetCollateralGeneralInfoActions(collateral);

    return (
        <Card
            title={t(
                'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.Title',
            )}
            menuActions={menuActions}
        >
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral.issuedOrganization}
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.RegistrationNumber',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.additionalField1}
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.AdditionalField1',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.additionalField2}
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.AdditionalField2',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.availableAmount}
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.AvailableAmount',
                        )}
                        format="money"
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral.expiresAt}
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.ExpiresAt',
                        )}
                        format="date"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.futureIncomeAmount}
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.FutureIncomeAmount',
                        )}
                        format="money"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.startsAt}
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.StartsAt',
                        )}
                        format="date"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.ownershipType}
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.OwnershipType',
                        )}
                        format={(value) => (
                            <EnumView
                                enumName="COLLATERAL_OWNERSHIP_TYPE"
                                value={value}
                            />
                        )}
                    />
                </Col>
            </Row>
        </Card>
    );
});
