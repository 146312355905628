import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Modal } from 'src/components/Modal/Modal';
import { StatusActionStore } from 'src/stores/LoanActionsStores/StatusActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;
export const DisableBorrowersDeathLoanStatusDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const statusStore = StatusActionStore.getInstance(Number(loanId));

        useEffect(() => {
            return () => {
                statusStore.disableBorrowerDeathLoader.reset();
            };
        }, []);

        const title = t(
            'Dialog.DisableBorrowersDeathLoanStatus.Title',
            'Confirm action',
        );

        return (
            <Modal
                {...props}
                title={title}
                isLoading={statusStore.disableBorrowerDeathLoader.isLoading}
                onFinish={async () => {
                    await statusStore.disableBorrowerDeathStatus();
                    if (statusStore.disableBorrowerDeathLoader.hasError) {
                        return Promise.reject();
                    }
                }}
            >
                <StyledText>
                    {t(
                        'Dialog.DisableBorrowersDeathLoanStatus.Notice',
                        'Please, confirm disabling borrower`s death status',
                    )}
                </StyledText>
            </Modal>
        );
    },
);

export const DisableBorrowersDeathLoanStatusDialog = addModalRenderer(
    DisableBorrowersDeathLoanStatusDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    padding: 10px 0;
    margin-bottom: 20px;
    font-size: 16px;
    color: #4d6082;
`;
