import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { CustomerReference } from 'src/generated-api-client';
import { DataInformationCard } from 'src/pages/CollectionDetailsPage/Components/DataInformationCard';

export type CollectionReferenceCardProps = {
    references?: CustomerReference[];
};
export const CollectionReferenceCard = observer(
    ({ references }: CollectionReferenceCardProps) => {
        return (
            <DataInformationCard
                title={t('Page.CollectionDetailsPage.References.Title')}
                hasData
                canUpdate={false}
            >
                {references?.map((reference: CustomerReference) => {
                    return (
                        <Row gutter={16} key={reference.id}>
                            <Col xs={6}>
                                <DataView
                                    value={reference?.relationship}
                                    label={t(
                                        'Page.CollectionDetailsPage.References.Relationship',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="REFERENCE_RELATIONSHIP"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={reference?.lastName}
                                    label={t(
                                        'Page.CollectionDetailsPage.References.LastName',
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={reference?.firstName}
                                    label={t(
                                        'Page.CollectionDetailsPage.References.FirstName',
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={reference?.registrationNumber}
                                    label={t(
                                        'Page.CollectionDetailsPage.References.RegistrationNumber',
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={reference?.phoneNumber}
                                    label={t(
                                        'Page.CollectionDetailsPage.References.PhoneNumber',
                                    )}
                                    format="tel"
                                />
                            </Col>
                        </Row>
                    );
                })}
            </DataInformationCard>
        );
    },
);
