import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Modal } from 'src/components/Modal/Modal';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type UpdateCollateralGeneralInfoDialogFnProps = ModalRendererProps<{
    collateralId: string;
    children?: React.ReactNode;
    covertFormValues?: (values: any) => any;
    initialValues?: any;
}>;

const store = CollateralsStore;

export const UpdateCollateralGeneralInfoDialogFn = observer(
    ({
        children,
        collateralId,
        covertFormValues,
        initialValues,
        ...props
    }: UpdateCollateralGeneralInfoDialogFnProps) => {
        useEffect(() => {
            return () => store.updateItemLoader.reset();
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );

        return (
            <Modal
                title={t('Dialog.UpdateCollateralGeneralInfo.Title')}
                isLoading={store.updateItemLoader.isLoading}
                onFinish={async (values) => {
                    const convertedValues =
                        (covertFormValues && covertFormValues(values)) ||
                        values;

                    await store.update(collateralId, convertedValues);
                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }
                    if (!store.updateItemLoader.hasError) {
                        store.loadItem(collateralId);
                    }
                }}
                cancelText={t('Dialog.UpdateCollateralGeneralInfo.Back')}
                initialValues={initialValues}
                formProps={{ fields }}
                width={1200}
                {...props}
            >
                {children}
            </Modal>
        );
    },
);

export const UpdateCollateralGeneralInfoDialog = addModalRenderer(
    UpdateCollateralGeneralInfoDialogFn,
);
