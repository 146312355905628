import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';

export const PledgedProductsFilterForm = observer(() => {
    return (
        <>
            <NumberField
                name="externalId"
                label={t('Page.PledgedProducts.Filter.ExternalId')}
                onlyInteger
            />
            <TextField
                name="name"
                label={t('Page.PledgedProducts.Filter.Name')}
            />
            <EnumSelectField
                mode="multiple"
                name="subtype"
                label={t('Page.PledgedProducts.Filter.Subtype')}
                enumName="PRODUCT_SUBTYPE"
            />
            <EnumSelectField
                mode="multiple"
                name="statuses"
                label={t('Page.PledgedProducts.Filter.Status')}
                enumName="PRODUCT_STATUS"
            />
            <EnumSelectField
                mode="multiple"
                name="repaymentCalculationMethods"
                label={t(
                    'Page.PledgedProducts.Filter.RepaymentCalculationMethods',
                )}
                enumName="REPAYMENT_CALCULATION_METHOD"
            />
            <NumberField
                name="interestRate"
                label={t('Page.PledgedProducts.Filter.InterestRate')}
            />
            <NumberField
                name="penaltyInterestMultiplierFrom"
                label={t(
                    'Page.PledgedProducts.Filter.PenaltyInterestMultiplierFrom',
                )}
            />
            <NumberField
                name="penaltyInterestMultiplierTo"
                label={t(
                    'Page.PledgedProducts.Filter.PenaltyInterestMultiplierTo',
                )}
            />
            <EnumSelectField
                mode="multiple"
                name="repaymentAllocationRules"
                label={t(
                    'Page.PledgedProducts.Filter.RepaymentAllocationRules',
                )}
                enumName="REPAYMENT_ALLOCATION_RULE"
            />
            <NumberField
                name="penaltyInterestAccrualStartDay"
                label={t(
                    'Page.PledgedProducts.Filter.PenaltyInterestAccrualStartDay',
                )}
                onlyInteger
            />
        </>
    );
});
