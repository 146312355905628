import { action, observable } from 'mobx';
import { BranchOptionsStore } from 'src/stores/BranchOptionsStore/BranchOptionsStore';
import { RoleStore } from 'src/stores/RoleStore/RoleStore';
import { UsersStore } from 'src/stores/UsersStore/UsersStore';

export class UserEditPageViewModelClass {
    @observable isLoaded = false;
    @observable isLoading = true;

    @action async load(userId: string) {
        this.isLoading = true;
        await UsersStore.loadItem(userId);
        await RoleStore.loadList();
        await BranchOptionsStore.loadList();
        this.isLoading = false;
        this.isLoaded = true;
    }

    @action reset() {
        UsersStore.updateItemLoader.reset();
        RoleStore.listLoader.reset();
        BranchOptionsStore.listLoader.reset();
        this.isLoaded = false;
        this.isLoading = true;
    }

    @action async save(userId: number | string, data: any) {
        this.isLoading = true;
        await UsersStore.update(userId, data);
        this.isLoading = false;
    }
}

export const UserEditPageViewModel = new UserEditPageViewModelClass();
