import { Col, Row } from 'antd';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { RealEstateCollateral } from 'src/generated-api-client';
import { getRealEstateCollateralGeneralInfoActions } from 'src/pages/CollateralDetailsPage/Tabs/RealEstateCollateralTab/RealEstateCollateralGeneralInfoActions.config';

type RealEstateCollateralGeneralInfoProps = {
    collateral: RealEstateCollateral;
};

export const RealEstateCollateralGeneralInfo = observer(
    ({ collateral }: RealEstateCollateralGeneralInfoProps) => {
        const menuActions =
            getRealEstateCollateralGeneralInfoActions(collateral);

        return (
            <Card
                title={t(
                    'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.Title',
                )}
                menuActions={menuActions}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={collateral.registrationNumber}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.RegistrationNumber',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral.certificateNumber}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.CertificateNumber',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral.governanceName}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.GovernanceName',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral.registeredDate}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.RegisteredDate',
                            )}
                            format="date"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={collateral.establishedDate}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.EstablishedDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral.areaSquare}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.AreaSquare',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral.estatePurpose}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.EstatePurpose',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="ESTATE_PURPOSE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral.numberOfRooms}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.NumberOfRooms',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="NUMBER_OF_ROOMS"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={
                                (collateral.address?.province.l10n as any)[
                                    i18next.language
                                ]
                            }
                            label={t('Entity.Address.CityProvince')}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                (collateral.address?.district.l10n as any)[
                                    i18next.language
                                ]
                            }
                            label={t('Entity.Address.DistrictSum')}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                (collateral.address?.khoroo.l10n as any)[
                                    i18next.language
                                ]
                            }
                            label={t('Entity.Address.Khoroo')}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral.address?.streetName}
                            label={t('Entity.Address.Street')}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={collateral.address?.townName}
                            label={t('Entity.Address.Town')}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral.address?.apartmentName}
                            label={t('Entity.Address.Apartment')}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral.address?.fullAddress}
                            label={t('Entity.Address.FullAddress')}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral.isPledged}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.IsPledged',
                            )}
                            format="yes-no"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={collateral.governmentCertificateNumber}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.GovernmentCertificateNumber',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={collateral.ownershipType}
                            label={t(
                                'Page.CollateralDetails.Tabs.RealEstate.GeneralInfo.OwnershipType',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLATERAL_OWNERSHIP_TYPE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
