import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { NotFound } from 'src/components/NotFound/NotFound';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useTaskId } from 'src/hooks/useTaskId';
import { CurrentWorkingTaskStore } from 'src/stores/CurrentWorkingTaskStore/CurrentWorkingTaskStore';

export const UnknownTaskPresentation = observer(() => {
    const { taskId } = useTaskId();
    const { workingTaskMetadata } = CurrentWorkingTaskStore;

    useEffect(() => {
        if (workingTaskMetadata?.id === taskId) {
            CurrentWorkingTaskStore.unsetCurrentWorkingTask();
        }
    }, [workingTaskMetadata?.id, taskId]);

    return (
        <NotFound
            title={t('Page.UnknownTaskPresentation.Error.NotFound.Title')}
            description={t(
                'Page.UnknownTaskPresentation.Error.NotFound.Description',
                { taskId },
            )}
        >
            <Button
                onClick={() => {
                    Router.navigate(RoutePaths.tasks);
                }}
            >
                {t('Page.UnknownTaskPresentation.GoToList')}
            </Button>
        </NotFound>
    );
});
