/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    IdLocalizationEntry,
    IdNameEntry,
    IndividualCoBorrowerListEntry,
    LegalCoBorrowerListEntry,
    PledgedLoanApplication,
} from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { CoBorrowersStatus } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/CoBorrowersStatus';
import { EnumName, EnumStore } from 'src/stores/Resources/EnumStore/EnumStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const enumsToLoad: EnumName[] = ['CUSTOMER_INCOME_TYPE'];

export type ApplicationCustomerInfoTabProps = {
    application?: PledgedLoanApplication;
};

export const CustomerInfoTab = observer(
    ({ application }: ApplicationCustomerInfoTabProps) => {
        useEnumsLoader(enumsToLoad);

        const individual = application?.individual;
        const individualCoBorrowers = application?.individualCoBorrowers;
        const legalCoBorrowers = application?.legalCoBorrowers;

        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.Title',
                            'Main Borrower',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.LastName',
                                        'Last Name',
                                    )}
                                    value={individual?.lastName}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.FirstName',
                                        'First Name',
                                    )}
                                    value={individual?.firstName}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.RegistrationNumber',
                                        'Registration Number',
                                    )}
                                    value={individual?.registrationNumber}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.PhoneNumber',
                                        'Phone Number',
                                    )}
                                    value={individual?.phoneNumber}
                                    format="tel"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.CompanyName',
                                        'Company Name',
                                    )}
                                    value={individual?.companyName}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.Position',
                                        'Position',
                                    )}
                                    value={individual?.position}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.IncomeTypes',
                                        'Income Types',
                                    )}
                                    value={individual?.incomeTypes}
                                    format={(value) => {
                                        return value
                                            ?.map((income: string) =>
                                                EnumStore.getInstance(
                                                    'CUSTOMER_INCOME_TYPE',
                                                ).getLocalizedEnum(income),
                                            )
                                            .join(', ');
                                    }}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.MonthlyIncome',
                                        'Overall Monthly Income',
                                    )}
                                    value={individual?.monthlyIncome}
                                    format="money"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.FullAddress',
                                        'Full Address',
                                    )}
                                    value={individual?.fullAddress}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.NumberOfFamilyMembers',
                                        'Number Of Family Members',
                                    )}
                                    value={individual?.numberOfFamilyMembers}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.Dti',
                                        'Current DTI',
                                    )}
                                    value={individual?.dti}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.MainBorrower.CreditScore',
                                        'CS Point',
                                    )}
                                    value={individual?.creditScore}
                                    format="string"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.IndividualTitle',
                        )}
                    >
                        <ListView
                            dataSource={individualCoBorrowers}
                            columns={individualsColumns}
                        />
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.LegalTitle',
                        )}
                    >
                        <ListView
                            dataSource={legalCoBorrowers}
                            columns={legalColumns}
                        />
                    </Card>
                </Col>
            </Row>
        );
    },
);

const individualsColumns: ColumnsType<IndividualCoBorrowerListEntry> = [
    {
        key: 'id',
        dataIndex: 'coBorrowerId',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.Id" />
        ),
        render(value) {
            return (
                <Link
                    to={UrlHelper.getTargetUrl(RoutePaths.individualDetails, {
                        individualId: value,
                    })}
                >
                    {value}
                </Link>
            );
        },
    },
    {
        key: 'relationshipType',
        dataIndex: 'relationshipType',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.RelationshipType" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'lastName',
        dataIndex: 'lastName',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.LastName" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'firstName',
        dataIndex: 'firstName',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.FirstName" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'registrationNumber',
        dataIndex: 'registrationNumber',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.RegistrationNumber" />
        ),
        render: (value) => {
            return <DataView value={value} format="string" hideLabel />;
        },
    },
    {
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.PhoneNumber" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'kycLevel',
        dataIndex: 'kycLevel',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.kycLevel" />
        ),
        render: (value) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView enumName="CUSTOMER_KYC_LEVEL" value={value} />
                    )}
                />
            );
        },
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.Status" />
        ),
        render(value) {
            return <CoBorrowersStatus status={value} />;
        },
    },
];

const legalColumns: ColumnsType<LegalCoBorrowerListEntry> = [
    {
        key: 'id',
        dataIndex: 'coBorrowerId',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.Legal.Id" />
        ),
        render(value) {
            return (
                <Link
                    to={UrlHelper.getTargetUrl(RoutePaths.legalDetails, {
                        legalId: value,
                    })}
                >
                    {value}
                </Link>
            );
        },
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.Legal.CompanyName" />
        ),
    },
    {
        key: 'industry',
        dataIndex: 'industry',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.Legal.Industry" />
        ),
        render: (value: IdLocalizationEntry) => {
            return (value?.l10n as any)?.[i18next.language];
        },
    },
    {
        key: 'registrationNumber',
        dataIndex: 'registrationNumber',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.Legal.RegistrationNumber" />
        ),
        render: (value) => {
            return <DataView value={value} format="string" hideLabel />;
        },
    },
    {
        key: 'ceo',
        dataIndex: 'ceo',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.Legal.CEO" />
        ),
        render: (value: IdNameEntry) => {
            return (
                <Link to={EntityLinks.individuals.itemDetails(value?.id)}>
                    {value?.name}
                </Link>
            );
        },
    },
    {
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.Legal.PhoneNumber" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CoBorrowerInformation.List.Legal.Status" />
        ),
        render(value) {
            return <CoBorrowersStatus status={value} />;
        },
    },
];
