import { Location } from 'history';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Prompt } from 'react-router-dom';
import { Modal, ModalProps } from 'src/components/Modal/Modal';

type PromptDialogProps = ModalProps & {
    when: boolean;
    children?: React.ReactNode;
};

export const PromptDialog: React.FC<PromptDialogProps> = ({
    when,
    children,
    ...props
}: PromptDialogProps) => {
    const history = useHistory();

    const [modalVisible, setModalVisible] = useState(false);
    const [currentPath, setCurrentPath] = useState<string>();

    const onHandleExit = useCallback(() => {
        currentPath && history.push(currentPath);
    }, [currentPath, history]);

    const onHandleCancel = useCallback(() => {
        setModalVisible(false);
    }, []);

    const onPromptMessage = (location: Location) => {
        if (when) {
            setCurrentPath(location?.pathname);
            setModalVisible(true);

            return false;
        }

        return true;
    };

    return (
        <>
            <Prompt when={when} message={onPromptMessage} />
            <Modal
                {...props}
                visible={modalVisible}
                onFinish={onHandleExit}
                onCancel={onHandleCancel}
                destroyOnClose
            >
                {children}
            </Modal>
        </>
    );
};
