import { Col, Radio, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { AddressView } from 'src/components/AddressView/AddressView';
import { DataView } from 'src/components/DataView/DataView';
import {
    CustomerAddress,
    CustomerPhoneNumber,
    Legal,
    SocialMediaLink,
} from 'src/generated-api-client';
import { DataInformationCard } from 'src/pages/LegalEntityDetailsPage/Components/DataInformationCard';
import { UpdateContactInformationDialog } from 'src/pages/LegalEntityDetailsPage/Dialogs/LegalEntityInformation/UpdateContactInformationDialog';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import styled from 'styled-components';

export type LegalEntityContactInformationCardProps = {
    viewModel?: LegalEntityDetailsViewModelClass;
};

export const LegalEntityContactInformationCard = observer(
    ({ viewModel }: LegalEntityContactInformationCardProps) => {
        const customerInfo = viewModel?.item || ({} as Legal);
        const email = customerInfo.contactDetails?.email || '';
        const phoneNumbers = customerInfo.contactDetails
            ?.customerPhoneNumbers || [{} as CustomerPhoneNumber];
        const addressList = customerInfo.contactDetails?.customerAddresses || [
            {} as CustomerAddress,
        ];
        const socialLinks = customerInfo.contactDetails?.socialMediaLinks || [
            {} as SocialMediaLink,
        ];
        const hasSocialAccounts = socialLinks.length > 0;

        return (
            <DataInformationCard
                title={t(
                    'Page.LegalEntityDetails.ContactInformation.Title',
                    'Contact Details',
                )}
                isLoading={viewModel?.itemLoader.isLoading}
                hasData
                canUpdate={AppUser.hasPermissions(UserPermission.LegalEdit)}
                onAdd={() => {
                    UpdateContactInformationDialog.render({
                        viewModel,
                    });
                }}
            >
                <Row gutter={16}>
                    {phoneNumbers?.map(
                        (phoneNumber: CustomerPhoneNumber, index: number) => {
                            return (
                                <>
                                    <Col xs={8} key={phoneNumber.id}>
                                        <DataView
                                            value={phoneNumber.phoneNumber}
                                            label={t(
                                                'Page.LegalEntityDetails.ContactInformation.PhoneNumber',
                                                {
                                                    index: index + 1,
                                                },
                                            )}
                                            format="tel"
                                        />
                                    </Col>
                                    <Col xs={4} key={phoneNumber.id}>
                                        <Radio
                                            defaultChecked={phoneNumber.isMain}
                                            disabled
                                        />
                                    </Col>
                                </>
                            );
                        },
                    )}
                    <Col xs={12}>
                        <DataView
                            value={email}
                            label={t(
                                'Page.LegalEntityDetails.ContactInformation.Email',
                                'Email',
                            )}
                            format="tel"
                        />
                    </Col>
                </Row>

                {addressList?.map((address: CustomerAddress) => {
                    return (
                        <Row gutter={16} key={address?.address?.id}>
                            <Col xs={24}>
                                <DataView
                                    value={address}
                                    label={t(
                                        'Page.LegalEntityDetails.ContactInformation.Address',
                                        'Address',
                                        {
                                            context: address.addressType,
                                        },
                                    )}
                                    format={() => (
                                        <AddressView address={address} short />
                                    )}
                                />
                            </Col>
                        </Row>
                    );
                })}

                {hasSocialAccounts && (
                    <>
                        <StyledTitle level={4}>
                            {t(
                                'Page.LegalEntityDetails.ContactInformation.SocialAccounts',
                                'Social Accounts',
                            )}
                        </StyledTitle>

                        {socialLinks.map((link: SocialMediaLink) => {
                            return (
                                <Row gutter={16}>
                                    <Col xs={6}>
                                        <DataView
                                            value={link.platformName}
                                            label={t(
                                                'Page.LegalEntityDetails.ContactInformation.SocialAccounts.Platform',
                                                'Platform',
                                            )}
                                        />
                                    </Col>
                                    <Col xs={18}>
                                        <DataView
                                            value={link.profileLink}
                                            label={t(
                                                'Page.LegalEntityDetails.ContactInformation.SocialAccounts.Link',
                                                'Link',
                                            )}
                                            format={(href: string) => {
                                                return (
                                                    <Link
                                                        to={{ pathname: href }}
                                                        target="_blank"
                                                    >
                                                        {link.profileName}
                                                    </Link>
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        })}
                    </>
                )}
            </DataInformationCard>
        );
    },
);

const StyledTitle = styled(Typography.Title)`
    margin-top: 20px;

    &.ant-typography {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #4d6082;
    }
`;
