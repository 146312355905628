import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { Role } from 'src/generated-api-client';

export type RoleDetailsBriefProps = { role?: Role };
export const RoleDetailsBrief = observer(({ role }: RoleDetailsBriefProps) => {
    const { t } = useTranslation();

    return (
        <Brief>
            <Row gutter={16}>
                <Col span={6}>
                    <DataView
                        value={role?.name}
                        label={t('Page.RoleDetails.Name')}
                    />
                </Col>
            </Row>
        </Brief>
    );
});
