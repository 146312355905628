import { transactionsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class BankStatementStatusesStoreClass extends BasicStore<any> {
    api: BasicStoreApi<string> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.getAvailableStatuses(),
            );
        },
    };
}

export const BankStatementStatusesStore = new BankStatementStatusesStoreClass();
