import { Col, notification, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { SelectField } from 'src/components/Form/SelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type UpdateOriginationDialogFnProps = ModalRendererProps<{
    viewModel?: IndividualDetailsViewModelClass;
}>;

export const UpdateOriginationDialogFn = observer(
    ({ viewModel, ...props }: UpdateOriginationDialogFnProps) => {
        useEffect(() => {
            return () => {
                viewModel?.updateOtherInformationLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            viewModel?.updateOtherInformationLoader.errorData,
        );

        return (
            <Modal
                title={t('Dialog.UpdateOrigination.Title')}
                isLoading={viewModel?.isUpdateOtherInformationLoading}
                onFinish={async (values) => {
                    await viewModel?.updateOtherInformation(values);

                    if (viewModel?.updateOtherInformationLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel?.updateOtherInformationLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.UpdateOrigination.Message.Success',
                            ),
                        });
                    }
                }}
                initialValues={viewModel?.otherInformation}
                formProps={{ fields }}
                width={900}
                {...props}
            >
                <Row gutter={16}>
                    <Col sm={12}>
                        <SelectField
                            name="pep"
                            label={t('Dialog.UpdateOrigination.Pep')}
                            options={FormHelper.objAsOptions(
                                {
                                    true: true,
                                    false: false,
                                },
                                'Primitive.Boolean.',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col sm={12}>
                        <EnumSelectField
                            name="relationToNbfi"
                            label={t('Dialog.UpdateOrigination.RelationToNBFI')}
                            enumName="RELATION_TO_NBFI"
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const UpdateOriginationDialog = addModalRenderer(
    UpdateOriginationDialogFn,
);
