import { useParams } from 'react-router-dom';

export function useIndividualId() {
    const params = useParams<{ individualId: string }>();

    return {
        individualId: params?.individualId,
        hasIndividualId: Boolean(params?.individualId),
    };
}
