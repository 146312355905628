/* eslint-disable max-lines */
// eslint-disable import/no-cycle
import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { EnumView } from 'src/components/EnumView/EnumView';
import { TaskForm } from 'src/components/Form/TaskForm';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N111Task } from 'src/generated-api-client';
import { TaskCommentsView } from 'src/pages/TaskDetailsPage/Components/TaskCommentsView';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { SubmitTaskDialog } from 'src/pages/TaskDetailsPage/Dialogs/SubmitTaskDialog';
import { RelationshipManagerConditionsCard } from 'src/pages/TaskDetailsPage/N111TaskUploadSignedContract/Components/RelationshipManagerConditionsCard';
import { N111TaskUploadSignedContractAdjustVariables } from 'src/pages/TaskDetailsPage/N111TaskUploadSignedContract/N111TaskUploadSignedContractAdjustVariables';
import { N111TaskUploadSignedContractApproveVariables } from 'src/pages/TaskDetailsPage/N111TaskUploadSignedContract/N111TaskUploadSignedContractApproveVariables ';
import { N111TaskUploadSignedContractDeclineVariables } from 'src/pages/TaskDetailsPage/N111TaskUploadSignedContract/N111TaskUploadSignedContractDeclineVariables';
import {
    N111TaskViewModel,
    N111TaskViewModelClass,
} from 'src/pages/TaskDetailsPage/N111TaskUploadSignedContract/N111TaskVievModel';
import { TaskViewModelClass } from 'src/pages/TaskViewPage/TaskViewModel';
import styled from 'styled-components';

type N16TaskAddCCConditionsProps = {
    viewModel: TaskViewModelClass;
};

export const N111TaskUploadSignedContract = observer(
    ({ viewModel }: N16TaskAddCCConditionsProps) => {
        const n111TaskViewModel = N111TaskViewModel.getInstance(
            String(viewModel.currentTaskId),
        );
        const task = (n111TaskViewModel.currentTask as N111Task) || {};
        const {
            loanApplication,
            coBorrowers,
            documentRoom,
            collaterals,
            relationshipManagerConditions,
        } = task;

        const comments = [
            {
                label: t('Component.TaskCommentsView.RMComment'),
                value: relationshipManagerConditions?.comment,
            },
        ];

        return (
            <TaskForm
                fields={n111TaskViewModel.submitTaskErrorFields}
                onFinish={async (values) => {
                    await n111TaskViewModel.submitTask(values);
                    if (!n111TaskViewModel.submitTaskLoaderHasError) {
                        Router.navigate(RoutePaths.tasks);
                    }
                }}
            >
                <StyledRow gutter={[16, 16]}>
                    <Col xs={24}>
                        <StyledText>
                            <Trans i18nKey="Page.N111TaskUploadSignedContract.Descriptions" />
                        </StyledText>
                    </Col>
                    <Col xs={24}>
                        <TaskLinkView
                            loanApplication={loanApplication}
                            collaterals={collaterals}
                        />
                    </Col>
                </StyledRow>
                <RelationshipManagerConditionsCard
                    viewModel={n111TaskViewModel}
                />
                <TaskCommentsView comments={comments} />
                <DocumentsList
                    documentRoom={documentRoom}
                    extraOptions={getApplicationDocumentsActions(
                        n111TaskViewModel,
                    )}
                    isLoading={
                        n111TaskViewModel.isGenerateApplicationAgreementLoading
                    }
                />
                <Card
                    title={t(
                        'Task.Form.N111TaskUploadSignedContract.CoBorrowers',
                        'Co-Borrowers',
                    )}
                >
                    {coBorrowers?.map((coBorrower) => {
                        return (
                            <Row gutter={16}>
                                <Col xs={6}>
                                    <DataView
                                        value={`${coBorrower.firstName} ${coBorrower.lastName}`}
                                        label={t(
                                            'Task.Form.N111TaskUploadSignedContract.CoBorrower.Name',
                                        )}
                                        format="link-internal"
                                        formatterProps={{
                                            to: EntityLinks.individuals.itemDetails(
                                                coBorrower?.coBorrowerId,
                                            ),
                                        }}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <DataView
                                        value={coBorrower?.verificationStatus}
                                        label={t(
                                            'Task.Form.N111TaskUploadSignedContract.CoBorrower.VerificationStatus',
                                        )}
                                        format={(value) => (
                                            <EnumView
                                                enumName="CUSTOMER_VERIFICATION_STATUS"
                                                value={value}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <DataView
                                        value={coBorrower?.registrationNumber}
                                        label={t(
                                            'Task.Form.N111TaskUploadSignedContract.CoBorrower.RegistrationNumber',
                                        )}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <DataView
                                        value={
                                            coBorrower?.relationshipManager
                                                ?.name
                                        }
                                        label={t(
                                            'Task.Form.N111TaskUploadSignedContract.CoBorrower.RelationshipManager',
                                        )}
                                        format="link-internal"
                                        formatterProps={{
                                            to: EntityLinks.users.itemDetails(
                                                coBorrower?.relationshipManager
                                                    ?.id,
                                            ),
                                        }}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </Card>
                <Row gutter={16}>
                    <Col xs={24}>
                        <N111TaskUploadSignedContractApproveVariables />
                    </Col>
                    <Col xs={24}>
                        <Space size={20}>
                            <Button
                                type={
                                    n111TaskViewModel.isCurrentTaskEqualWorkingTask
                                        ? 'primary'
                                        : 'default'
                                }
                                htmlType="submit"
                            >
                                {t('Task.Form.Btn.Approve')}
                            </Button>
                            <Button
                                onClick={() => {
                                    SubmitTaskDialog.render({
                                        viewModel,
                                        title: t(
                                            'Dialog.Reject.N111TaskUploadSignedContract.Title',
                                        ),
                                        children: (
                                            <N111TaskUploadSignedContractDeclineVariables />
                                        ),
                                    });
                                }}
                            >
                                {t('Task.Form.Btn.Decline')}
                            </Button>
                            <Button
                                onClick={() => {
                                    SubmitTaskDialog.render({
                                        viewModel,
                                        title: t(
                                            'Dialog.Adjust.N111TaskUploadSignedContract.Title',
                                        ),
                                        children: (
                                            <N111TaskUploadSignedContractAdjustVariables />
                                        ),
                                    });
                                }}
                            >
                                {t('Task.Form.Btn.Adjust')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </TaskForm>
        );
    },
);

const StyledRow = styled(Row)`
    margin-bottom: 20px;
`;

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;

const getApplicationDocumentsActions = (viewModel: N111TaskViewModelClass) => {
    return [
        {
            label: t(
                'Task.Form.N111TaskUploadSignedContract.ApplicationAgreementDocument.Actions',
            ),
            key: 'generateApplicationAgreement',
            onClick: async () => {
                await viewModel.generateApplicationAgreement();
            },
        },
    ];
};
