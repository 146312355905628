/* eslint-disable max-lines */
import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { DocumentsViewModel } from 'src/components/DocumentsList/DocumentsViewModel';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { N13Task } from 'src/generated-api-client';
import { ConditionCardProps } from 'src/pages/TaskViewPage/TaskTypes';
import styled from 'styled-components';

export const CollateralsCard = observer(
    ({ task }: ConditionCardProps<N13Task>) => {
        const { collaterals } = task;
        const applicationDocumentsViewModel = DocumentsViewModel.getInstance(
            task?.documentRoom?.id,
        );
        const isEmpty =
            !collaterals ||
            (Array.isArray(collaterals) && collaterals?.length === 0);

        if (isEmpty) {
            return null;
        }

        return (
            <Row gutter={[16, 4]}>
                <Col xs={24}>
                    <StyledTitle level={4}>
                        {t(
                            'Task.Form.N13AddEstimatedConditions.Collaterals.Title',
                            'Collaterals:',
                        )}
                    </StyledTitle>
                </Col>
                {collaterals?.map((collateral, index) => {
                    return (
                        <Col xs={24}>
                            <StyledCard
                                title={t(
                                    'Task.Form.N13AddEstimatedConditions.Collateral.Title',
                                    'Collateral #{{index}}',
                                    {
                                        index: index + 1,
                                    },
                                )}
                            >
                                <Row gutter={16}>
                                    <Col xs={24}>
                                        <DataView
                                            hideLabel
                                            value={t(
                                                'Task.Form.N13AddEstimatedConditions.Collateral.Link',
                                                'Collateral details',
                                            )}
                                            format="link-internal"
                                            formatterProps={{
                                                to: EntityLinks.collaterals.itemDetails(
                                                    collateral?.id,
                                                ),
                                            }}
                                        />
                                    </Col>
                                    <StyledCol xs={24}>
                                        <DocumentsList
                                            documentRoom={
                                                collateral?.documentRoom
                                            }
                                            title={(countDocuments) =>
                                                t(
                                                    'Page.Form.N13AddEstimatedConditionsTask.Collateral.Documents',
                                                    'Collateral documents ({{count}})',
                                                    {
                                                        count: countDocuments,
                                                    },
                                                )
                                            }
                                            onUploadSuccess={() => {
                                                applicationDocumentsViewModel.load();
                                            }}
                                        />
                                    </StyledCol>
                                </Row>
                            </StyledCard>
                        </Col>
                    );
                })}
            </Row>
        );
    },
);

const StyledTitle = styled(Typography.Title)`
    display: block;
    font-weight: bold !important;
    color: #4d6082 !important;
`;

const StyledCard = styled(Card)`
    .ant-card-head-title {
        padding: 0 8px 0 !important;
        font-size: 17px !important;
    }
`;

const StyledCol = styled(Col)`
    .ant-card-head,
    .ant-card-body {
        padding: 0;
    }

    .ant-card {
        margin: 0;
    }
    .ant-card-extra {
        padding-top: 0 !important;
    }

    .ant-card-head-title {
        padding: 0 0 16px !important;
    }

    .documents-list-title {
        font-size: 14px !important;
    }
`;
