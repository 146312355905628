import { computed } from 'mobx';
import { ProductLine, ProductType } from 'src/generated-api-client';
import { productLinesApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { RequestHelper } from 'src/utils/RequestHelper';

export type ProductLinesFilter = {
    productTypes: ProductType[];
};

const ProductLinesFilterKeys: Array<keyof ProductLinesFilter> = [
    'productTypes',
];

export class ProductLinesStoreClass extends BasicStore<
    ProductLine,
    ProductLinesFilter
> {
    api: BasicStoreApi<ProductLine> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                productLinesApi.getProductLines({
                    ...this.filterCriteria.filter,
                }),
            );
        },
    };

    @computed get productLinesOptions() {
        const groupedProducts: { [key: string]: ProductLine[] } = {};

        this.list.forEach((product) => {
            if (!groupedProducts[product.type]) {
                groupedProducts[product.type] = [];
            }

            groupedProducts[product.type].push(product);
        });

        return Object.entries(groupedProducts).map(([key, products]) => ({
            label: key,
            options: products.map(({ name, id }) => ({
                label: name,
                value: id,
            })),
        }));
    }

    @computed get currentListAsOptions() {
        return this.list.map((product) => ({
            label: product?.name,
            value: String(product?.id),
        }));
    }

    filterCriteria = new FilterCriteria<ProductLinesFilter>(
        ProductLinesFilterKeys,
    );
}

export const ProductLinesStore = new ProductLinesStoreClass();
