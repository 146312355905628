/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { YesNoView } from 'src/components/YesNoView/YesNoView';
import {
    CreditScoringTable,
    CreditScoringTableProps,
    getRows,
    GroupedItem,
} from 'src/pages/IndividualDetailsPage/Tabs/CreditScoringResultTables/CreditScoringTableTypes';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const columns = [
    {
        title: (
            <Trans i18nKey="IndividualScoringResultTab.GeneralScoringTable.Title" />
        ),
        dataIndex: 'title',
        key: 'title',
        width: 400,
    },
    {
        title: (
            <Trans i18nKey="IndividualScoringResultTab.GeneralScoringTable.Ranges" />
        ),
        dataIndex: 'ranges',
        key: 'ranges',
    },
    {
        title: (
            <Trans i18nKey="IndividualScoringResultTab.GeneralScoringTable.Values" />
        ),
        dataIndex: 'values',
        key: 'values',
    },
    {
        title: (
            <Trans i18nKey="IndividualScoringResultTab.GeneralScoringTable.Points" />
        ),
        dataIndex: 'points',
        key: 'points',
        align: 'center' as any,
        width: 150,
        onCell: (record: GroupedItem) => {
            if (record.groupLength === 1) {
                return {};
            }

            if (record.index === 0) {
                return {
                    rowSpan: record.groupLength,
                    className: 'row-span',
                };
            }

            return { rowSpan: 0 };
        },
    },
];

export const GeneralScoringTable = observer(
    // eslint-disable-next-line complexity
    ({ store, ...props }: CreditScoringTableProps) => {
        const { t } = useTranslation();
        const data = store?.creditScoringResultLoader.data;
        const i18nextMonthContinuouslyContext =
            data?.workingStabilityScoreCustomerResult
                ?.workingStabilityScoreSetting?.workingMonthContinuously
                ?.from ===
            data?.workingStabilityScoreCustomerResult
                ?.workingStabilityScoreSetting?.workingMonthContinuously?.to
                ? 'Equal'
                : '';

        const groupedData = [
            {
                groupName: 'AgeSexEvolution',
                items: [
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.Age',
                        ),
                        ranges: data?.sexAgeScoreCustomerResult
                            ?.sexAgeScoreSetting?.age
                            ? `${data?.sexAgeScoreCustomerResult?.sexAgeScoreSetting?.age?.from} - ${data?.sexAgeScoreCustomerResult?.sexAgeScoreSetting?.age?.to}`
                            : '—',
                        values: data?.sexAgeScoreCustomerResult?.customerAge,
                        points: data?.sexAgeScoreCustomerResult
                            ?.sexAgeScoreSetting?.score,
                    },
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.Sex',
                        ),
                        ranges: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Ranges.Sex.${data?.sexAgeScoreCustomerResult?.sexAgeScoreSetting?.sex}`,
                        ),
                        values: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Values.Sex.${data?.sexAgeScoreCustomerResult?.customerSex}`,
                        ),
                        points: data?.sexAgeScoreCustomerResult
                            ?.sexAgeScoreSetting?.score,
                    },
                ],
            },
            {
                groupName: 'EducationLevel',
                items: [
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.EducationLevel',
                        ),
                        ranges: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Ranges.EducationLevel.${data?.educationScoreCustomerResult?.educationScoreSetting?.education}`,
                        ),
                        values: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Values.EducationLevel.${data?.educationScoreCustomerResult?.customerEducation}`,
                        ),
                        points: data?.educationScoreCustomerResult
                            ?.educationScoreSetting?.score,
                    },
                ],
            },
            {
                groupName: 'LoanHistory',
                items: [
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.PaidAmount',
                        ),
                        ranges: `${NumberHelper.formatToMNT(
                            data?.loanHistoryScoreCustomerResult
                                ?.loanHistoryScoreSetting?.paidHistory?.from!,
                        )} - ${NumberHelper.formatToMNT(
                            data?.loanHistoryScoreCustomerResult
                                ?.loanHistoryScoreSetting?.paidHistory?.to!,
                        )}`,
                        values: NumberHelper.formatToMNT(
                            data?.loanHistoryScoreCustomerResult
                                ?.customerPaidHistory!,
                        ),
                        points: data?.loanHistoryScoreCustomerResult
                            ?.loanHistoryScoreSetting?.score,
                    },
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.WorstClassification',
                        ),
                        ranges: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Ranges.WorstClassification.${data?.loanHistoryScoreCustomerResult?.loanHistoryScoreSetting?.worstClassification}`,
                        ),
                        values: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Values.WorstClassification.${data?.loanHistoryScoreCustomerResult?.customerWorstClassification}`,
                        ),
                        points: data?.loanHistoryScoreCustomerResult
                            ?.loanHistoryScoreSetting?.score,
                    },
                ],
            },
            {
                groupName: 'CurrentLoan',
                items: [
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.CurrentLoan',
                        ),
                        ranges: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Ranges.CurrentLoan.${data?.currentLoansScoreCustomerResult?.currentLoansScoreSetting?.loanClassification}`,
                        ),
                        values: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Values.CurrentLoan.${data?.currentLoansScoreCustomerResult?.customerLoanClassification}`,
                        ),
                        points: data?.currentLoansScoreCustomerResult
                            ?.currentLoansScoreSetting?.score,
                    },
                ],
            },
            {
                groupName: 'OwnedRealEstate',
                items: [
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.RealEstate',
                        ),
                        ranges: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Ranges.RealEstate.${data?.ownedRealEstateScoreCustomerResult?.ownedRealEstateScoreSetting?.ownedRealEstate}`,
                        ),
                        values: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Values.RealEstate.${data?.ownedRealEstateScoreCustomerResult?.customerOwnedRealEstate}`,
                        ),
                        points: data?.ownedRealEstateScoreCustomerResult
                            ?.ownedRealEstateScoreSetting?.score,
                    },
                ],
            },
            {
                groupName: 'OwnedCar',
                items: [
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.CarEstate',
                        ),
                        ranges:
                            data?.ownedCarEstateScoreCustomerResult
                                ?.ownedCarEstateScoreSetting?.yearsUsed
                                ?.from === null
                                ? '—'
                                : t(
                                      'IndividualScoringResultTab.GeneralScoringTable.Ranges.CarEstate',
                                      {
                                          from: data
                                              ?.ownedCarEstateScoreCustomerResult
                                              ?.ownedCarEstateScoreSetting
                                              ?.yearsUsed?.from,
                                          to: data
                                              ?.ownedCarEstateScoreCustomerResult
                                              ?.ownedCarEstateScoreSetting
                                              ?.yearsUsed?.to,
                                      },
                                  ),
                        values:
                            data?.ownedCarEstateScoreCustomerResult
                                ?.customerCarYearsUsed === null ? (
                                '—'
                            ) : (
                                <StyledDataView
                                    value={
                                        data?.ownedCarEstateScoreCustomerResult
                                            ?.customerCarYearsUsed
                                    }
                                    format="interval"
                                    hideLabel
                                />
                            ),
                        points: data?.ownedCarEstateScoreCustomerResult
                            ?.ownedCarEstateScoreSetting?.score,
                    },
                ],
            },
            {
                groupName: 'WorkingStability',
                items: [
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.IncomeDecreased',
                        ),
                        ranges: (
                            <YesNoView
                                value={
                                    data?.workingStabilityScoreCustomerResult
                                        ?.workingStabilityScoreSetting
                                        ?.decreased
                                }
                            />
                        ),
                        values: (
                            <YesNoView
                                value={
                                    data?.workingStabilityScoreCustomerResult
                                        ?.customerIncomeDecreased
                                }
                            />
                        ),
                        points: data?.workingStabilityScoreCustomerResult
                            ?.workingStabilityScoreSetting?.score,
                    },
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.WorkingMonth',
                        ),
                        ranges:
                            data?.workingStabilityScoreCustomerResult
                                ?.workingStabilityScoreSetting?.workingMonth
                                ?.from === null
                                ? '—'
                                : t(
                                      'IndividualScoringResultTab.GeneralScoringTable.Ranges.WorkingMonth',
                                      {
                                          ...data
                                              ?.workingStabilityScoreCustomerResult
                                              ?.workingStabilityScoreSetting
                                              ?.workingMonth,
                                      },
                                  ),
                        values:
                            data?.workingStabilityScoreCustomerResult
                                ?.customerWorkingMonth === null
                                ? '—'
                                : t(
                                      'IndividualScoringResultTab.GeneralScoringTable.Values.WorkingMonth',
                                      {
                                          data: data
                                              ?.workingStabilityScoreCustomerResult
                                              ?.customerWorkingMonth,
                                      },
                                  ),
                        points: data?.workingStabilityScoreCustomerResult
                            ?.workingStabilityScoreSetting?.score,
                    },
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.MonthContinuously',
                        ),
                        ranges:
                            data?.workingStabilityScoreCustomerResult
                                ?.workingStabilityScoreSetting
                                ?.workingMonthContinuously?.from === null
                                ? '—'
                                : t(
                                      'IndividualScoringResultTab.GeneralScoringTable.Ranges.MonthContinuously',
                                      {
                                          context:
                                              i18nextMonthContinuouslyContext,
                                          ...data
                                              ?.workingStabilityScoreCustomerResult
                                              ?.workingStabilityScoreSetting
                                              ?.workingMonthContinuously,
                                      },
                                  ),
                        values:
                            data?.workingStabilityScoreCustomerResult
                                ?.customerWorkingMonthContinuously === null
                                ? '—'
                                : t(
                                      'IndividualScoringResultTab.GeneralScoringTable.Values.MonthContinuously',
                                      {
                                          data: data
                                              ?.workingStabilityScoreCustomerResult
                                              ?.customerWorkingMonthContinuously,
                                      },
                                  ),
                        points: data?.workingStabilityScoreCustomerResult
                            ?.workingStabilityScoreSetting?.score,
                    },
                    {
                        title: t(
                            'IndividualScoringResultTab.GeneralScoringTable.Title.IncomeType',
                        ),
                        ranges: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Ranges.IncomeType.${data?.workingStabilityScoreCustomerResult?.workingStabilityScoreSetting?.incomeType}`,
                        ),
                        values: t(
                            `IndividualScoringResultTab.GeneralScoringTable.Values.IncomeType.${data?.workingStabilityScoreCustomerResult?.customerIncomeType}`,
                        ),
                        points: data?.workingStabilityScoreCustomerResult
                            ?.workingStabilityScoreSetting?.score,
                    },
                ],
            },
        ];

        const rows = useMemo(() => {
            return getRows(groupedData);
        }, [store?.creditScoringResultLoader.data]);

        const targetRows =
            !store?.creditScoringResultLoader.isLoading && data ? rows : [];

        return (
            <CreditScoringTable
                dataSource={targetRows}
                columns={columns}
                loading={store?.creditScoringResultLoader.isLoading}
                {...props}
            />
        );
    },
);

const StyledDataView = styled(DataView)`
    margin-bottom: 0;

    .app-data-view__value {
        color: #4d6082;
    }
`;
