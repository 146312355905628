import { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ListPage } from 'src/components/ListPage/ListPage';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { IdNameEntry, UserListItem } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { UsersHeaderPanel } from 'src/pages/UsersPage/UsersHeaderPanel';
import { UsersStatus } from 'src/pages/UsersPage/UsersStatus';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';
import { UsersStore } from 'src/stores/UsersStore/UsersStore';

const enumsToLoad: EnumName[] = ['USER_STATUS'];

export const UsersPage = observer(() => {
    const { t } = useTranslation();
    const isLoading = useEnumsLoader(enumsToLoad);

    return (
        <ListPage
            store={UsersStore}
            columns={columns}
            isLoading={isLoading}
            header={<UsersHeaderPanel title={t('Page.Users.Header')} />}
        />
    );
});

export const columns: ColumnsType<UserListItem> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.Users.List.Id" />,
        render: (userId: string) => {
            const canReadDetails = AppUser.hasPermissions(
                UserPermission.UserReadDetails,
            );

            if (canReadDetails) {
                return (
                    <Link
                        to={EntityLinks.users.itemDetails(userId)}
                        data-test="user-details-link"
                    >
                        {userId}
                    </Link>
                );
            }

            return userId;
        },
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Page.Users.List.Status" />,
        render(value) {
            return <UsersStatus status={value} />;
        },
    },
    {
        key: 'surname',
        dataIndex: 'surname',
        title: <Trans i18nKey="Page.Users.List.Surname" />,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: <Trans i18nKey="Page.Users.List.Name" />,
    },
    {
        key: 'role',
        dataIndex: 'role',
        title: <Trans i18nKey="Page.Users.List.Role" />,
        render: ({ id, name }: IdNameEntry) => {
            const canReadDetails = AppUser.hasPermissions(
                UserPermission.RoleReadDetails,
            );

            if (canReadDetails) {
                return (
                    <Link to={EntityLinks.roles.itemDetails(id)}>{name}</Link>
                );
            }

            return name;
        },
    },
    {
        key: 'phone',
        dataIndex: 'phone',
        title: <Trans i18nKey="Page.Users.List.Phone" />,
    },
    {
        key: 'email',
        dataIndex: 'email',
        title: <Trans i18nKey="Page.Users.List.Email" />,
        sorter: true,
    },
];
