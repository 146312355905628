import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ReviewActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ReviewActionDialogComponent';
import { ReviewNewGracePeriodActionStore } from 'src/stores/LoanActionsStores/ReviewNewGracePeriodActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const { Text } = Typography;

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

export const ReviewNewGracePeriodDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ReviewNewGracePeriodActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ReviewNewGracePeriod.Title');

        return (
            <ReviewActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <StyledText>{t('Dialog.ReviewNewGracePeriod.Text')}</StyledText>
            </ReviewActionDialogComponent>
        );
    },
);

export const ReviewNewGracePeriodDialog = addModalRenderer(
    ReviewNewGracePeriodDialogFn,
);

const StyledText = styled(Text)`
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #4d6082;
`;
