import { Col, Row, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { LegalEntityBankAccountCard } from 'src/pages/LegalEntityDetailsPage/Components/LegalEntityBankAccountCard';
import { LegalEntityCEOInformationCard } from 'src/pages/LegalEntityDetailsPage/Components/LegalEntityCEOInformationCard';
import { LegalEntityContactInformationCard } from 'src/pages/LegalEntityDetailsPage/Components/LegalEntityContactInformationCard';
import { LegalEntityGeneralInfoCard } from 'src/pages/LegalEntityDetailsPage/Components/LegalEntityGeneralInfoCard';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import styled from 'styled-components';

export type LegalEntityDetailsTabProps = {
    viewModel?: LegalEntityDetailsViewModelClass;
};

export const LegalEntityDetailsTab = observer(
    ({ viewModel }: LegalEntityDetailsTabProps) => {
        return (
            <Row gutter={24}>
                <Col sm={12}>
                    <StyledSpace direction="vertical">
                        <LegalEntityGeneralInfoCard viewModel={viewModel} />
                        <LegalEntityBankAccountCard viewModel={viewModel} />
                    </StyledSpace>
                </Col>

                <Col sm={12}>
                    <StyledSpace direction="vertical">
                        <LegalEntityCEOInformationCard viewModel={viewModel} />

                        <LegalEntityContactInformationCard
                            viewModel={viewModel}
                        />
                    </StyledSpace>
                </Col>
            </Row>
        );
    },
);

const StyledSpace = styled(Space)`
    display: flex;
`;
