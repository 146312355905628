import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';

export const PayonProductsFilterForm = observer(() => {
    return (
        <>
            <NumberField
                name="externalId"
                label={t('Page.PayonProducts.Filter.ExternalId')}
                onlyInteger
            />
            <TextField
                name="name"
                label={t('Page.PayonProducts.Filter.Name')}
            />
            <EnumSelectField
                mode="multiple"
                name="status"
                label={t('Page.PayonProducts.Filter.Status')}
                enumName="PRODUCT_STATUS"
            />
            <EnumSelectField
                mode="multiple"
                name="repaymentCalculationMethod"
                label={t(
                    'Page.PayonProducts.Filter.RepaymentCalculationMethod',
                )}
                enumName="REPAYMENT_CALCULATION_METHOD"
            />
            <NumberField
                name="penaltyInterestMultiplierFrom"
                label={t(
                    'Page.PayonProducts.Filter.PenaltyInterestMultiplierFrom',
                )}
            />
            <NumberField
                name="penaltyInterestMultiplierTo"
                label={t(
                    'Page.PayonProducts.Filter.PenaltyInterestMultiplierTo',
                )}
            />
            <NumberField
                name="penaltyInterestAccrualStartDay"
                label={t(
                    'Page.PayonProducts.Filter.PenaltyInterestAccrualStartDay',
                )}
                onlyInteger
            />
            <EnumSelectField
                mode="multiple"
                name="repaymentAllocationRule"
                label={t('Page.PayonProducts.Filter.RepaymentAllocationRule')}
                enumName="REPAYMENT_ALLOCATION_RULE"
            />
        </>
    );
});
