import { Button, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DotsHorizontalIcon, PlusIcon } from 'src/components/Icons/Icons';
import { getRepresentativesExternalActions } from 'src/pages/LegalEntityDetailsPage/Components/RepresentativesExternalActions.config';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import styled from 'styled-components';

type RepresentativesExternalActionsProps = {
    viewModel?: LegalEntityDetailsViewModelClass;
};

export const RepresentativesExternalActions = observer(
    ({ viewModel }: RepresentativesExternalActionsProps) => {
        const actions = getRepresentativesExternalActions(viewModel);

        const hideActions = actions.length === 0;
        const primaryActions = actions.filter((action) => action?.primary);
        const secondaryActions = actions.filter((action) => !action?.primary);
        const showSecondaryActions =
            secondaryActions && secondaryActions.length > 0;

        if (hideActions) {
            return null;
        }

        return (
            <StyledSpace size={16}>
                {primaryActions.map(({ label: title, key: type, onClick }) => (
                    <Button
                        type="primary"
                        icon={<PlusIcon />}
                        key={type}
                        onClick={onClick}
                    >
                        {title}
                    </Button>
                ))}
                {showSecondaryActions && (
                    <Dropdown items={secondaryActions}>
                        <StyledIconButton icon={<DotsHorizontalIcon />} />
                    </Dropdown>
                )}
            </StyledSpace>
        );
    },
);

const StyledSpace = styled(Space)`
    display: flex;
`;

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    margin-right: -12px;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;
