import { LockOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import classNames from 'classnames';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import {
    DataFormatterFormats,
    DataFormatterProvider,
} from 'src/utils/DataFormatterProvider';
import styled from 'styled-components';

export type DataViewProps = {
    value: React.ReactNode | boolean;
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    hideLabel?: boolean;
    // eslint-disable-next-line react/require-default-props
    format?:
        | DataFormatterFormats
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        | ((value: any, options: any) => React.ReactNode);
    // eslint-disable-next-line react/require-default-props
    formatterProps?: any;
    // eslint-disable-next-line react/require-default-props
    className?: string;
    // eslint-disable-next-line react/require-default-props
    align?: 'vertical' | 'horizontal';
    // eslint-disable-next-line react/require-default-props
    hasPermission?: boolean | (() => boolean);
};

export const LONG_DASH = '—';

export const DataView = ({
    label,
    hideLabel,
    value,
    format,
    formatterProps,
    className,
    align = 'vertical',
    hasPermission,
    ...props
}: // eslint-disable-next-line sonarjs/cognitive-complexity
DataViewProps) => {
    const formatter = useMemo(() => {
        if (typeof format === 'function') {
            return (value: any, options: any) => format(value, options);
        }
        const targetFormat = format || DataFormatterProvider.guessFormat(value);

        return DataFormatterProvider.getFormatter(targetFormat);
    }, [format, value]);

    const hasValue =
        (value !== null && value !== undefined && value !== '') ||
        (typeof value === 'number' && Number.isFinite(Number(value)));

    const classes = {
        'app-data-view': true,
        'align-horizontal': align === 'horizontal',
    };

    const canView = () => {
        if (hasPermission === undefined) {
            return true;
        }

        if (typeof hasPermission === 'function') {
            return hasPermission();
        }

        return hasPermission;
    };

    const getLabel = () => {
        if (label) {
            if (!canView()) {
                return (
                    <Space size={5}>
                        {label}
                        <Tooltip
                            title={t(
                                'Component.DataView.Tooltip.HasNotPermission',
                            )}
                        >
                            <LockOutlined />
                        </Tooltip>
                    </Space>
                );
            }

            return label;
        }

        return <>&nbsp;</>;
    };

    const getValue = () => {
        if (canView() && hasValue) {
            return formatter(value, formatterProps);
        }

        return LONG_DASH;
    };

    return (
        <StyledDataViewWrapper className={classNames(className, classes)}>
            {!hideLabel && <StyledLabel>{getLabel()}</StyledLabel>}
            <StyledValue {...props}>{getValue()}</StyledValue>
        </StyledDataViewWrapper>
    );
};

const StyledDataViewWrapper = styled.div`
    position: relative;
    margin-bottom: 16px;

    label {
        word-break: break-word;
    }

    a {
        cursor: pointer;
    }

    &.align-horizontal {
        display: flex;

        label {
            margin: 0 10px 0 0;
            line-height: 150%;
        }

        div {
            width: fit-content;
        }
    }
`;

const StyledLabel = styled.label.attrs((props) => ({
    className: classNames(props.className, 'app-data-view__label'),
}))`
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #9fa2b4;
`;

const StyledValue = styled.div.attrs((props) => ({
    className: classNames(props.className, 'app-data-view__value'),
}))`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #4d6082;
    word-break: break-word;
    width: -webkit-fill-available;
`;
