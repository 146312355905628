import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N22Task, TaskDecisionType } from 'src/generated-api-client';
import { TaskCommentsView } from 'src/pages/TaskDetailsPage/Components/TaskCommentsView';
import { SubmitTaskDialog } from 'src/pages/TaskDetailsPage/Dialogs/SubmitTaskDialog';
import { BankStatementInformation } from 'src/pages/TaskDetailsPage/N22EnterIATUserDecision/Components/BankStatementInformation';
import { CustomerInformationCard } from 'src/pages/TaskDetailsPage/N22EnterIATUserDecision/Components/CustomerInformationCard';
import { N22EnterIATUserDecisionApproveVariables } from 'src/pages/TaskDetailsPage/N22EnterIATUserDecision/N22EnterIATUserDecisionApproveVariables';
import { N22EnterIATUserDecisionReturnVariables } from 'src/pages/TaskDetailsPage/N22EnterIATUserDecision/N22EnterIATUserDecisionReturnVariables';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';
import styled from 'styled-components';

export const N22EnterIATUserDecision = observer(
    ({ viewModel }: TaskProps<N22Task>) => {
        const task = viewModel.currentTask || {};
        const { documentRoom, socUserComment, iatSeniorComment } = task;

        const comments = [
            {
                label: t('Component.TaskCommentsView.SOCUserComment'),
                value: socUserComment,
            },
            {
                label: t('Component.TaskCommentsView.IATSeniorComment'),
                value: iatSeniorComment,
            },
        ];

        return (
            <>
                <Row gutter={[16, 24]}>
                    <Col xs={24}>
                        <StyledText>
                            <Trans i18nKey="Task.Form.N22EnterIATUserDecision.Descriptions" />
                        </StyledText>
                    </Col>
                    <Col xs={24}>
                        <CustomerInformationCard task={task} />
                    </Col>
                    <Col xs={24}>
                        <BankStatementInformation task={task} />
                    </Col>
                    <Col xs={24}>
                        <TaskCommentsView comments={comments} />
                    </Col>
                </Row>
                <TaskForm
                    fields={viewModel.submitTaskErrorFields}
                    initialValues={getInitialValues(task)}
                    onFinish={async (values) => {
                        await viewModel.submitTask(values);
                        if (!viewModel.submitTaskLoaderHasError) {
                            Router.navigate(RoutePaths.tasks);
                        }
                    }}
                >
                    <DocumentsList
                        documentRoom={documentRoom}
                        canUpload={viewModel.isCurrentTaskEqualWorkingTask}
                    />

                    <Card>
                        <Row gutter={[16, 24]}>
                            <Col xs={24}>
                                <N22EnterIATUserDecisionApproveVariables />
                            </Col>
                            <Col xs={24}>
                                <Space size={20}>
                                    <Button
                                        type={
                                            viewModel.isCurrentTaskEqualWorkingTask
                                                ? 'primary'
                                                : 'default'
                                        }
                                        htmlType="submit"
                                    >
                                        {t('Task.Form.Btn.Approve')}
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            SubmitTaskDialog.render({
                                                viewModel,
                                                title: t(
                                                    'Dialog.Return.N22EnterIATUserDecision.Title',
                                                ),
                                                children: (
                                                    <N22EnterIATUserDecisionReturnVariables />
                                                ),
                                            });
                                        }}
                                    >
                                        {t('Task.Form.Btn.Return')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                </TaskForm>
            </>
        );
    },
);

const getInitialValues = (data?: N22Task) => {
    return {
        ...data,
        type: TaskDecisionType.N2_2_APPROVE,
    };
};

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
