import { ModalFuncProps, notification } from 'antd';
import { t } from 'i18next';
import { ModalConfirm } from 'src/components/ModalConfirm/ModalConfirmDialog';
import { CustomerGroupCompaniesStore } from 'src/stores/CustomerGroupCompaniesStore/CustomerGroupCompaniesStore';

type RemoveCompanyDialogProps = ModalFuncProps & {
    name: string;
    groupId?: number;
};

export const RemoveCompanyDialog = ({
    groupId,
    name,
    ...dialogProps
}: RemoveCompanyDialogProps) => {
    const store = CustomerGroupCompaniesStore.getInstance(Number(groupId));

    ModalConfirm({
        ...dialogProps,
        title: t('Dialog.RemoveCompany.Title'),
        onOk: async () => {
            await store.remove(name);

            if (!store.removeItemLoader.hasError) {
                await store.loadList(true);
                notification.success({
                    message: t('Dialog.RemoveCompany.Remove.Success'),
                });
            }
        },
    });
};
