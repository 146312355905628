import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { AddressDetails } from 'src/components/AddressView/AddressDetails';
import { Modal } from 'src/components/Modal/Modal';
import { Address } from 'src/generated-api-client';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

export type AddressViewDialogProps = ModalRendererProps<{
    address: Address;
    title?: React.ReactNode;
}>;

export const AddressViewDialogFn = observer(
    ({ address, title, ...props }: AddressViewDialogProps) => {
        return (
            <StyledModal
                title={title || t('Dialogs.AddressView.Title', 'Address')}
                {...props}
                footer={false}
            >
                <AddressDetails address={address} />
            </StyledModal>
        );
    },
);

export const AddressViewDialog = addModalRenderer(AddressViewDialogFn);

const StyledModal = styled(Modal)`
    min-width: 70vw;

    .ant-row {
        + .ant-row {
            border-top: 1px solid #dfe0eb;
            padding-top: 15px;
        }
    }
`;
