import { useParams } from 'react-router-dom';

export function useProductId() {
    const params = useParams<{ productId: string }>();

    return {
        productId: params?.productId,
        hasProductId: Boolean(params?.productId),
    };
}
