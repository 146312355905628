import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    ColumnSettings,
    SettingsTable,
} from 'src/components/SettingsTable/SettingsTable';
import { BnplRiskCoefficientRow, SettingType } from 'src/generated-api-client';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { TableSettingsStoreClass } from 'src/stores/Settings/TableSettingsStoreClass';
import styled from 'styled-components';

const tableStore = new TableSettingsStoreClass<BnplRiskCoefficientRow>(
    SettingType.BNPL_RISK_COEFFICIENT,
);

const renderNumberInput = (valuePath: any) => {
    return (
        <StyledNumberField
            name={valuePath}
            min={1}
            max={100}
            onlyInteger
            rules={[ValidationRules.required]}
        />
    );
};

const columnSettings: ColumnSettings[] = [
    {
        key: 'BNPL_RISK_COEFFICIENT_SETTING',
        title: (colDef) => {
            return t(
                'Page.SettingsBnplRiskCoefficient.Table.BnplRiskCoefficientSetting',
                colDef.payload,
            );
        },
        render: (data) => {
            return t(
                `Page.SettingsBnplRiskCoefficient.Table.Row.BnplRiskCoefficientSetting.${data}`,
            );
        },
        width: 170,
    },
    {
        key: 'RISK_COEFFICIENT',
        title: () => {
            return t('Page.SettingsTrustDefaultProduct.Table.RiskCoefficient');
        },
        renderEdit: (valuePath) => renderNumberInput(valuePath),
        editable: true,
        width: 205,
    },
];

export const BnplRiskCoefficientTab = observer(() => {
    return (
        <SettingsTable
            tableStore={tableStore}
            columnSettings={columnSettings}
            hasPermission={AppUser.hasPermissions(
                UserPermission.LoanSettingsBnplRiskCoefficientEdit,
            )}
        />
    );
});

const StyledNumberField = styled(NumberField)`
    margin: 0;
    min-width: 60px;
`;
