import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FilterForm } from 'src/components/Form/FilterForm';
import { ApplicationsFilterForm } from 'src/pages/ApplicationsPage/ApplicationsFilterForm';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { DateHelper } from 'src/utils/DateHelper';

type ApplicationsHeaderPanelProps = {
    title: React.ReactNode;
};

export const ApplicationsHeaderPanel = observer(
    ({ title }: ApplicationsHeaderPanelProps) => {
        return (
            <div>
                <FilterForm
                    store={ApplicationsStore}
                    title={title}
                    convertFilterCriteriaToFormValues={
                        convertFilterCriteriaToFormValues
                    }
                    convertFormValuesToFilterCriteria={
                        convertFormValuesToFilterCriteria
                    }
                >
                    <ApplicationsFilterForm />
                </FilterForm>
            </div>
        );
    },
);
const convertFilterCriteriaToFormValues = (values: any) => {
    return {
        ...values,
        createdDateFrom:
            values.createdDateFrom && moment(values.createdDateFrom),
        createdDateTo: values.createdDateTo && moment(values.createdDateTo),
    };
};
const convertFormValuesToFilterCriteria = (values: any) => {
    return {
        ...values,
        createdDateFrom: DateHelper.formatISODate(values.createdDateFrom),
        createdDateTo: DateHelper.formatISODate(values.createdDateTo),
    };
};
