import { notification } from 'antd';
import { t } from 'i18next';
import { action, computed } from 'mobx';
import { convertDocumentTypesToSelectOptions } from 'src/components/DocumentsList/DocumentRoomHelper';
import { documentRoomsApi } from 'src/services/apiServices';
import { DocumentsStore } from 'src/stores/DocumentsStore/DocumentsStore';
import { FilesStore } from 'src/stores/FilesStore/FilesStore';
import { EntityProvider } from 'src/utils/EntityProvider';
import { FormHelper } from 'src/utils/FormHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { ViewModel } from 'src/utils/mobx/ViewModel';
import { RequestHelper } from 'src/utils/RequestHelper';

export class DocumentsViewModelClass extends ViewModel {
    constructor(private roomId: number) {
        super();
    }

    documentsStore = DocumentsStore.getInstance(this.roomId);

    @action
    async load() {
        this.reset();
        await Promise.all([
            this.documentsStore.loadList(),
            this.loadDocumentsRoom(),
        ]);
    }

    @action
    async reset() {
        await Promise.all([
            this.documentsStore.filterCriteria.resetFilter(),
            this.documentsStore.listLoader.reset(),
            FilesStore.uploadFileLoader.reset(),
            this.documentsRoomLoader.reset(),
        ]);
    }

    @action
    async resetUploadLoadersToInitialState() {
        await Promise.all([
            FilesStore.uploadFileLoader.reset(),
            this.documentsStore.createItemLoader.reset(),
        ]);
    }

    @action
    async resetDeleteLoadersToInitialState() {
        await this.documentsStore.removeItemLoader.reset();
    }

    @computed get documents() {
        return this.documentsStore.list;
    }

    @computed get loadingInfo() {
        return new CombinedLoadingStatus([
            this.documentsStore.listLoader,
            this.documentsRoomLoader,
        ]);
    }

    @action
    async createDocument(values: any) {
        const file = values.file[0].originFileObj;
        const fileStore = FilesStore;
        const uploadedFile = await fileStore.upload(file);

        if (fileStore.uploadFileLoader.hasError) {
            return Promise.reject();
        }

        await this.documentsStore.create({
            ...values,
            uploadedFileId: uploadedFile?.id,
        });

        if (this.documentsStore.createItemLoader.hasError) {
            notification.error({
                message: this.documentsStore.createItemLoader.errorData?.detail,
            });

            return Promise.reject();
        }

        if (!this.documentsStore.createItemLoader.hasError) {
            notification.success({
                message: t('Dialog.UploadFile.Message.Success'),
            });
        }

        this.documentsStore.loadList();
        this.loadDocumentsRoom();
        this.documentsStore.createItemLoader.reset();
        fileStore.uploadFileLoader.reset();
    }

    @action
    async deleteDocument(fileId: number) {
        await this.documentsStore.remove(fileId);

        if (this.documentsStore.removeItemLoader.hasError) {
            notification.error({
                message: this.documentsStore.removeItemLoader.errorData?.detail,
            });

            return Promise.reject();
        }

        if (!this.documentsStore.removeItemLoader.hasError) {
            notification.success({
                message: t('Dialog.DeleteFile.Message.Success'),
            });
        }

        this.documentsStore.loadList();
        this.loadDocumentsRoom();
        this.documentsStore.removeItemLoader.reset();
    }

    @computed get createDocumentLoadingInfo() {
        return new CombinedLoadingStatus([
            this.documentsStore.createItemLoader,
            FilesStore.uploadFileLoader,
        ]);
    }

    @computed get createDocumentLoaderHasError() {
        return this.documentsStore.createItemLoader.hasError;
    }

    @computed get createDocumentErrorField() {
        const fileUploadErrors = FormHelper.getFieldDataItems(
            FilesStore.uploadFileLoader.errorData,
        ).map((item) => ({ ...item, name: 'file' }));

        const documentUploadFieldErrors = FormHelper.getFieldDataItems(
            this.documentsStore.createItemLoader.errorData,
        ).map((item) => ({ ...item, name: 'file' }));

        return [...fileUploadErrors, ...documentUploadFieldErrors];
    }

    @computed get deleteDocumentLoadingInfo() {
        return this.documentsStore.removeItemLoader;
    }

    @computed get deleteDocumentLoaderHasError() {
        return this.documentsStore.removeItemLoader.hasError;
    }

    documentsRoomLoader = new AsyncOperationWithStatus(() => {
        return RequestHelper.unwrapFromAxiosPromise(
            documentRoomsApi.getDocumentRoom({ roomId: this.roomId }),
        );
    });

    @action
    async loadDocumentsRoom() {
        await this.documentsRoomLoader.call();
    }

    @computed get documentTypesFilterOptions() {
        return this.documentsRoomLoader.data?.filterableTypes?.map(
            convertDocumentTypesToSelectOptions,
        );
    }

    @computed get documentTypesUploadOptions() {
        return this.documentsRoomLoader.data?.uploadableTypes?.map(
            convertDocumentTypesToSelectOptions,
        );
    }
}

export const DocumentsViewModel = new EntityProvider(DocumentsViewModelClass);
