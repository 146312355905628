import { Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DebtCollection } from 'src/generated-api-client';
import { getCollectionsExternalActions } from 'src/pages/CollectionDetailsPage/Components/CollectionsExternalActions.config';
import styled from 'styled-components';

type CollectionsExternalActionsProps = {
    collectionId: number;
    collection?: DebtCollection;
};

export const CollectionsExternalActions = observer(
    ({ collectionId, collection }: CollectionsExternalActionsProps) => {
        const actions = getCollectionsExternalActions(collectionId, collection);

        const hideActions = actions.length === 0;

        if (hideActions) {
            return null;
        }

        return (
            <StyledSpace size={16}>
                <Dropdown items={actions} />
            </StyledSpace>
        );
    },
);

const StyledSpace = styled(Space)`
    display: flex;
`;
