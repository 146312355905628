import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    ColumnSettings,
    SettingsTable,
} from 'src/components/SettingsTable/SettingsTable';
import {
    MonthlyIncomeForDtiScoringRow,
    SettingType,
} from 'src/generated-api-client';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { TableSettingsStoreClass } from 'src/stores/Settings/TableSettingsStoreClass';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const tableStore = new TableSettingsStoreClass<MonthlyIncomeForDtiScoringRow>(
    SettingType.MONTHLY_INCOME_FOR_DTI,
);

const renderNumberInput = (valuePath: any) => {
    return (
        <StyledNumberField
            name={valuePath}
            rules={[ValidationRules.required]}
        />
    );
};

const columnSettings: ColumnSettings[] = [
    {
        key: 'DTI',
        title: (colDef) => {
            return t(
                'Page.SettingsScoring.Tabs.MonthlyIncomeDti.Table.DTI',
                colDef.payload,
            );
        },
        render: (data) => {
            return t(
                'Page.SettingsScoring.Tabs.MonthlyIncomeDti.Table.Row.DTI',
                data,
            );
        },
        width: 130,
    },
    {
        key: 'MONTHLY_INCOME',
        title: (colDef) => {
            return t(
                'Page.SettingsScoring.Tabs.MonthlyIncomeDti.Table.MONTHLY_INCOME',
                {
                    from: NumberHelper.formatToMNT(colDef.payload.from),
                    to: NumberHelper.formatToMNT(colDef.payload.to),
                },
            );
        },
        renderEdit: (valuePath) => renderNumberInput(valuePath),
        editable: true,
        width: 150,
    },
];

export const SettingsScoringMonthlyIncomeDtiEvaluationTab = observer(() => {
    return (
        <SettingsTable
            tableStore={tableStore}
            columnSettings={columnSettings}
            hasPermission={AppUser.hasPermissions(
                UserPermission.LoanSettingsScoringEdit,
            )}
        />
    );
});

const StyledNumberField = styled(NumberField)`
    margin: 0;
    min-width: 60px;
`;
