import { Typography } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { AutoSessionCompleteConfig } from 'src/components/AutoSessionComplete/AutoSessionComplete.config';
import { AutoSessionCompleteViewModel } from 'src/components/AutoSessionComplete/AutoSessionCompleteViewModel';
import { Modal } from 'src/components/Modal/Modal';
import { AppStore } from 'src/stores/AppStore/AppStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const viewModel = AutoSessionCompleteViewModel;

export type CompleteSessionDialogProps = ModalRendererProps;
export const CompleteSessionDialogFn = ({
    ...props
}: CompleteSessionDialogProps) => {
    const [timeToExpire, setTimeToExpire] = useState(
        AutoSessionCompleteConfig.timeToShowNotificationBeforeAutoLogoutSec,
    );
    const [isMounted, setIsMounted] = useState(true);

    useEffect(() => {
        return () => {
            setIsMounted(false);
        };
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            isMounted &&
                setTimeToExpire((prevTimeToExpire) => {
                    return prevTimeToExpire > 0
                        ? prevTimeToExpire - 1
                        : prevTimeToExpire;
                });
        }, 1000) as unknown as number;

        return () => {
            clearInterval(id);
        };
    }, [isMounted]);

    useEffect(() => {
        if (timeToExpire === 0 || viewModel.isAutoLogout) {
            if (viewModel.getLastSessionTime()) {
                AuthStore.logout();
            } else {
                window.location.reload();
            }
            // eslint-disable-next-line react/destructuring-assignment
            props?.onCancel();
        }
    }, [timeToExpire]);

    return (
        <Modal
            {...props}
            title={t('Dialog.CompleteSession.Title')}
            okText={t('Dialog.CompleteDialog.Logout')}
            onCancel={(e) => {
                if (e === (true as any)) {
                    viewModel.reset();
                } else {
                    viewModel.resume();
                }
                props.onCancel();
            }}
            onOk={async () => {
                AppStore.startLoading();
                await AuthStore.logout();
                AppStore.stopLoading();
            }}
            maskClosable={false}
            closable={false}
        >
            <Typography.Text>
                {t('Dialog.CompleteSession.YourSessionWillExpireIn', {
                    time: timeToExpire,
                })}
            </Typography.Text>
        </Modal>
    );
};

export const CompleteSessionDialog = addModalRenderer(CompleteSessionDialogFn);
