import { Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { useContext } from 'react';
import { FormItemGroupNameContext } from 'src/components/Form/FormItemGroup/FormItemGroupNameContext';
import { FormHelper } from 'src/utils/FormHelper';

export type UseFormGroupSettings = {
    watchNamePathPrefix?: NamePath;
};
export function useFormGroupValue(settings?: UseFormGroupSettings) {
    const groupName = useContext(FormItemGroupNameContext);

    return Form.useWatch([
        ...FormHelper.namePathToArray(settings?.watchNamePathPrefix),
        ...FormHelper.namePathToArray(groupName),
    ] as NamePath);
}
