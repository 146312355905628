import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { ProductStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: ProductStatus;
};

export const ProductsStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: ProductStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: <EnumView enumName="PRODUCT_STATUS" value={status} />,
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [ProductStatus.ACTIVE]: {
        title: (
            <EnumView enumName="PRODUCT_STATUS" value={ProductStatus.ACTIVE} />
        ),
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
    [ProductStatus.INACTIVE]: {
        title: (
            <EnumView
                enumName="PRODUCT_STATUS"
                value={ProductStatus.INACTIVE}
            />
        ),
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
};
