import { Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { EnumName, EnumStore } from 'src/stores/Resources/EnumStore/EnumStore';

export type EnumViewProps = {
    enumName: EnumName;
    value?: any;
};

export const EnumView = observer(({ enumName, value }: EnumViewProps) => {
    const enumStore = EnumStore.getInstance(enumName);
    useEffect(() => {
        if (enumStore.listLoader.countTries === 0) {
            enumStore.loadList(false);
        }
    }, [enumName]);
    if (enumStore.listLoader.isLoading) {
        return <Skeleton paragraph={{ rows: 0 }} active />;
    }

    return <> {enumStore.getLocalizedEnum(value)}</>;
});
