import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import {
    CollateralCoOwner,
    CreateCollateral200Response,
} from 'src/generated-api-client';
import { CollateralOwnershipInfoActions } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/CollateralOwnershipInfoActions';
import { CollateralOwnershipInfoListActions } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/CollateralOwnershipInfoListActions';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';

type CollateralOwnershipInfoProps = {
    collateral?: CreateCollateral200Response;
};
export const CollateralOwnershipInfo = observer(
    ({ collateral }: CollateralOwnershipInfoProps) => {
        const columns = getColumns(collateral);

        return (
            <Card
                title={t('Component.OwnershipInfo.Title', {
                    count: CollateralsStore.coOwnerList?.length || 0,
                })}
                extra={
                    <CollateralOwnershipInfoActions collateral={collateral} />
                }
            >
                <ListView
                    columns={columns}
                    dataSource={CollateralsStore.coOwnerList}
                />
            </Card>
        );
    },
);

const getColumns = (
    collateral?: CreateCollateral200Response,
): ColumnsType<CollateralCoOwner> => [
    {
        key: 'id',
        dataIndex: 'id',
        width: 100,
        title: <Trans i18nKey="Component.OwnershipInfo.Id" />,
    },
    {
        key: 'civilId',
        dataIndex: 'civilId',
        title: <Trans i18nKey="Component.OwnershipInfo.CivilId" />,
    },
    {
        key: 'firstName',
        dataIndex: 'firstName',
        title: <Trans i18nKey="Component.OwnershipInfo.FirstName" />,
    },
    {
        key: 'lastName',
        dataIndex: 'lastName',
        title: <Trans i18nKey="Component.OwnershipInfo.LastName" />,
    },
    {
        key: 'registrationNumber',
        dataIndex: 'registrationNumber',
        title: <Trans i18nKey="Component.OwnershipInfo.RegistrationNumber" />,
    },
    {
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
        title: <Trans i18nKey="Component.OwnershipInfo.PhoneNumber" />,
    },
    {
        key: 'fullAddress',
        dataIndex: 'fullAddress',
        title: <Trans i18nKey="Component.OwnershipInfo.FullAddress" />,
    },
    {
        key: 'relationship',
        dataIndex: 'relationship',
        title: <Trans i18nKey="Component.OwnershipInfo.Relationship" />,
        render: (value) => {
            return (
                <DataView
                    hideLabel
                    value={value}
                    format={(value) => (
                        <EnumView
                            enumName="REFERENCE_RELATIONSHIP"
                            value={value}
                        />
                    )}
                />
            );
        },
    },
    {
        key: 'ownerType',
        dataIndex: 'ownerType',
        title: <Trans i18nKey="Component.OwnershipInfo.CoOwnerType" />,
        render: (value) => {
            return (
                <DataView
                    hideLabel
                    value={value}
                    format={(value) => t(`CoOwner.Type.${value}`)}
                />
            );
        },
    },
    {
        key: 'actions',
        render: (_, record) => {
            return (
                <CollateralOwnershipInfoListActions
                    coOwner={record}
                    collateral={collateral}
                />
            );
        },
    },
];
