/* eslint-disable max-lines */
import { computed } from 'mobx';
import { Trans } from 'react-i18next';
// eslint-disable-next-line import/no-cycle
import { GroupPage } from 'src/components/GroupPage/GroupPage';
import {
    ApplicationsMenuIcon,
    BankStatementsMenuIcon,
    BranchInformationMenuIcon,
    CollateralsMenuIcon,
    DebtCollectionMenuIcon,
    IndividualsMenuIcon,
    LegalEntitiesMenuIcon,
    LoansMenuIcon,
    RestrictionsMenuIcon,
    SecurityMenuIcon,
    SettingsMenuIcon,
} from 'src/components/Icons/Icons';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { ApplicationDetailsPage } from 'src/pages/ApplicationDetailsPage/ApplicationDetailsPage';
import { ApplicationsPage } from 'src/pages/ApplicationsPage/ApplicationsPage';
import { ForgotPasswordPage } from 'src/pages/Auth/ForgotPasswordPage';
import { LoginPage } from 'src/pages/Auth/LoginPage';
import { RecoveryExpiredPasswordPage } from 'src/pages/Auth/RecoveryExpiredPasswordPage';
import { RecoveryPasswordPage } from 'src/pages/Auth/RecoveryPasswordPage';
import { RequestToRecoveryExpiredPasswordPage } from 'src/pages/Auth/RequestToRecoveryExpiredPasswordPage';
import { ResetPasswordPage } from 'src/pages/Auth/ResetPasswordPage';
import { SetPasswordPage } from 'src/pages/Auth/SetPasswordPage';
import { BankStatementsPage } from 'src/pages/BankStatementsPage/BankStatementsPage';
import { BranchesPage } from 'src/pages/BranchesPage/BranchesPage';
import { CollateralCreatePage } from 'src/pages/CollateralCreatePage/CollateralCreatePage';
import { CollateralDetailsPage } from 'src/pages/CollateralDetailsPage/CollateralDetailsPage';
import { CollateralsPage } from 'src/pages/CollateralsPage/CollateralsPage';
import { CollectionDetails } from 'src/pages/CollectionDetailsPage/CollectionDetailsPage';
import { RecordActivityPage } from 'src/pages/CollectionDetailsPage/RecordActivityPage/RecordActivityPage';
import { CollectionsPage } from 'src/pages/CollectionsPage/CollectionsPage';
import { DocumentTemplatesPage } from 'src/pages/DocumentTemplatesPage/DocumentTemplatesPage';
import { IndexPage } from 'src/pages/IndexPage/IndexPage';
import { IndividualDetailsPage } from 'src/pages/IndividualDetailsPage/IndividualDetailsPage';
import { IndividualsPage } from 'src/pages/IndividualsPage/IndividualsPage';
import { LegalEntitiesPage } from 'src/pages/LegalEntitiesPage/LegalEntitiesPage';
import { LegalEntityCreatePage } from 'src/pages/LegalEntityCreatePage/LegalEntityCreatePage';
import { LegalEntityDetailsPage } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsPage';
import { LoanDetailsPage } from 'src/pages/LoanDetailsPage/LoanDetailsPage';
import { BnplProductCreatePage } from 'src/pages/LoanProducts/BNPLProductCreatePage/BNPLProductCreatePage';
import { BNPLProductDetailsPage } from 'src/pages/LoanProducts/BNPLProductDetailsPage/BNPLProductDetailsPage';
import { BnplProductEditPage } from 'src/pages/LoanProducts/BNPLProductEditPage/BNPLProductEditPage';
import { BNPLProductsPage } from 'src/pages/LoanProducts/BNPLProductsPage/BNPLProductsPage';
import { InstallmentProductCreatePage } from 'src/pages/LoanProducts/InstallmentProductCreatePage/InstallmentProductCreatePage';
import { InstallmentProductDetailsPage } from 'src/pages/LoanProducts/InstallmentProductDetailsPage/InstallmentProductDetailsPage';
import { InstallmentProductEditPage } from 'src/pages/LoanProducts/InstallmentProductEditPage/InstallmentProductEditPage';
import { InstallmentProductsPage } from 'src/pages/LoanProducts/InstallmentProductsPage/InstallmentProductsPage';
import { PayonProductCreatePage } from 'src/pages/LoanProducts/PayonProductCreatePage/PayonProductCreatePage';
import { PayonProductDetailsPage } from 'src/pages/LoanProducts/PayonProductDetailsPage/PayonProductDetailsPage';
import { PayonProductEditPage } from 'src/pages/LoanProducts/PayonProductEditPage/PayonProductEditPage';
import { PayonProductsPage } from 'src/pages/LoanProducts/PayonProductsPage/PayonProductsPage';
import { PledgedProductCreatePage } from 'src/pages/LoanProducts/PledgedProductCreatePage/PledgedProductCreatePage';
import { PledgedProductDetailsPage } from 'src/pages/LoanProducts/PledgedProductDetailsPage/PledgedProductDetailsPage';
import { PledgedProductEditPage } from 'src/pages/LoanProducts/PledgedProductEditPage/PledgedProductEditPage';
import { PledgedProductsPage } from 'src/pages/LoanProducts/PledgedProductsPage/PledgedProductsPage';
import { TrustPledgedProductCreatePage } from 'src/pages/LoanProducts/TrustPledgedProductCreatePage/TrustPledgedProductCreatePage';
import { TrustPledgedProductDetailsPage } from 'src/pages/LoanProducts/TrustPledgedProductDetailsPage/TrustPledgedProductDetailsPage';
import { TrustPledgedProductEditPage } from 'src/pages/LoanProducts/TrustPledgedProductEditPage/TrustPledgedProductEditPage';
import { TrustPledgedProductsPage } from 'src/pages/LoanProducts/TrustPledgedProductsPage/TrustPledgedProductsPage';
import { LoansPage } from 'src/pages/LoansPage/LoansPage';
import { ProfilePage } from 'src/pages/ProfilePage/ProfilePage';
import { RestrictionsListPage } from 'src/pages/RestrictionsListPage/RestrictionsListPage';
import { RoleDetailsPage } from 'src/pages/RoleDetailsPage/RoleDetailsPage';
import { RoleCreatePage } from 'src/pages/RoleEditPage/RoleCreatePage';
import { RoleEditPage } from 'src/pages/RoleEditPage/RoleEditPage';
import { RolesPage } from 'src/pages/RolesPage/RolesPage';
import { SettingsCollectionAssignmentPage } from 'src/pages/Settings/CollectionAssignment/SettingsCollectionAssignmentPage';
import { CustomerGroupCompaniesPage } from 'src/pages/Settings/CustomerGroups/CustomerGroupCompaniesPage/CustomerGroupCompaniesPage';
import { CustomerGroupsPage } from 'src/pages/Settings/CustomerGroups/CustomerGroupsPage/CustomerGroupsPage';
import { SettingsCustomerVerificationPage } from 'src/pages/Settings/CustomerVerificationPage/SettingsCustomerVerificationPage';
import { LoanOverdueDaysGapsPage } from 'src/pages/Settings/LoanOverdueDaysGapsPage/LoanOverdueDaysGapsPage';
import { ManageProvisionRatesPage } from 'src/pages/Settings/ManageProvisionRatesPage/ManageProvisionRatesPage';
import { SettingsProductPage } from 'src/pages/Settings/Product/SettingsProductPage';
import { SettingsProductLimitPage } from 'src/pages/Settings/ProductLimit/SettingsProductLimitPage';
import { SettingsRiskyGroupPage } from 'src/pages/Settings/RiskyGroup/SettingsRiskyGroupPage';
import { SettingsScoringPage } from 'src/pages/Settings/Scoring/SettingsScoringPage';
import { TasksPage } from 'src/pages/TasksPage/TasksPage';
import { TaskViewPage } from 'src/pages/TaskViewPage/TaskViewPage';
import { UserCreatePage } from 'src/pages/UserCreatePage/UserCreatePage';
import { UserDetailsPage } from 'src/pages/UserDetailsPage/UserDetailsPage';
import { UserEditPage } from 'src/pages/UserEditPage/UserEditPage';
import { UsersPage } from 'src/pages/UsersPage/UsersPage';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import {
    isRouteGroup,
    RouteDefinition,
} from 'src/stores/RouterStore/RouterStore.types';

export class RouterStoreClass {
    // eslint-disable-next-line no-underscore-dangle
    @computed private get routeDefinitions(): RouteDefinition[] {
        if (!AuthStore.authenticated) {
            return [
                {
                    key: RoutePaths.authLogin,
                    path: RoutePaths.authLogin,
                    Component: LoginPage,
                },
                {
                    key: RoutePaths.authRegistrationSetPassword,
                    path: RoutePaths.authRegistrationSetPassword,
                    Component: SetPasswordPage,
                },
                {
                    key: RoutePaths.authForgotPassword,
                    path: RoutePaths.authForgotPassword,
                    Component: ForgotPasswordPage,
                },
                {
                    key: RoutePaths.authRecoveryPassword,
                    path: RoutePaths.authRecoveryPassword,
                    Component: RecoveryPasswordPage,
                },
                {
                    key: RoutePaths.authRecoveryExpiredPassword,
                    path: RoutePaths.authRecoveryExpiredPassword,
                    Component: RecoveryExpiredPasswordPage,
                },
                {
                    key: RoutePaths.authRequestToRecoveryExpiredPassword,
                    path: RoutePaths.authRequestToRecoveryExpiredPassword,
                    Component: RequestToRecoveryExpiredPasswordPage,
                },
            ];
        }

        return [
            {
                key: RoutePaths.index,
                path: RoutePaths.index,
                Component: IndexPage,
                exact: true,
            },

            {
                key: RoutePaths.profile,
                path: RoutePaths.profile,
                Component: ProfilePage,
                exact: true,
            },
            // {
            //     key: RoutePaths.merchants,
            //     path: RoutePaths.merchants,
            //     Component: MerchantsPage,
            //     exact: true,
            //     label: <Trans i18nKey="App.Menu.Links.Merchants" />,
            //     icon: <MerchantsMenuIcon />,
            // },
            // {
            //     key: RoutePaths.merchantCreate,
            //     path: RoutePaths.merchantCreate,
            //     Component: MerchantCreatePage,
            //     exact: true,
            // },
            // {
            //     key: RoutePaths.merchantDetails,
            //     path: RoutePaths.merchantDetails,
            //     Component: MerchantDetailsPage,
            //     exact: true,
            // },
            // {
            //     key: RoutePaths.merchantEdit,
            //     path: RoutePaths.merchantEdit,
            //     Component: MerchantEditPage,
            //     exact: true,
            // },
            {
                key: RoutePaths.applications,
                path: RoutePaths.applications,
                Component: ApplicationsPage,
                exact: true,
                label: <Trans i18nKey="App.Menu.Links.Applications" />,
                description: (
                    <Trans i18nKey="App.Menu.Links.Applications.Description" />
                ),
                icon: <ApplicationsMenuIcon />,
                available: AppUser.hasPermissions(
                    UserPermission.ApplicationReadList,
                ),
            },
            {
                key: RoutePaths.applicationDetails,
                path: RoutePaths.applicationDetails,
                Component: ApplicationDetailsPage,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.ApplicationReadGeneralInformation,
                    UserPermission.ApplicationReadRepaymentSchedule,
                ),
            },
            {
                key: RoutePaths.individuals,
                path: RoutePaths.individuals,
                Component: IndividualsPage,
                exact: true,
                label: <Trans i18nKey="App.Menu.Links.Individuals" />,
                description: (
                    <Trans i18nKey="App.Menu.Links.Individuals.Description" />
                ),
                icon: <IndividualsMenuIcon />,
                available: AppUser.hasPermissions(
                    UserPermission.IndividualRead,
                ),
            },
            {
                key: RoutePaths.individualDetails,
                path: RoutePaths.individualDetails,
                Component: IndividualDetailsPage,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.IndividualReadBriefDetails,
                ),
            },
            {
                key: RoutePaths.legalEntities,
                path: RoutePaths.legalEntities,
                Component: LegalEntitiesPage,
                exact: true,
                label: <Trans i18nKey="App.Menu.Links.LegalEntities" />,
                icon: <LegalEntitiesMenuIcon />,
                available: AppUser.hasPermissions(UserPermission.LegalReadList),
            },
            {
                key: RoutePaths.legalCreate,
                path: RoutePaths.legalCreate,
                Component: LegalEntityCreatePage,
                exact: true,
                available: AppUser.hasPermissions(UserPermission.LegalCreate),
            },
            {
                key: RoutePaths.legalDetails,
                path: RoutePaths.legalDetails,
                Component: LegalEntityDetailsPage,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.LegalReadDetails,
                ),
            },
            // {
            //     key: RoutePaths.legalEdit,
            //     path: RoutePaths.legalEdit,
            //     Component: LegalEntityEditPage,
            //     exact: true,
            // },
            {
                key: RoutePaths.loans,
                path: RoutePaths.loans,
                Component: LoansPage,
                exact: true,
                label: <Trans i18nKey="App.Menu.Links.Loans" />,
                icon: <LoansMenuIcon />,
                available: AppUser.hasPermissions(UserPermission.LoanReadList),
            },
            {
                key: RoutePaths.loanDetails,
                path: RoutePaths.loanDetails,
                Component: LoanDetailsPage,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.LoanReadDetails,
                ),
            },
            {
                key: RoutePaths.bankStatements,
                path: RoutePaths.bankStatements,
                Component: BankStatementsPage,
                exact: true,
                label: <Trans i18nKey="App.Menu.Links.BankStatements" />,
                description: (
                    <Trans i18nKey="App.Menu.Links.BankStatements.Description" />
                ),
                icon: <BankStatementsMenuIcon />,
                available: AppUser.hasPermissions(
                    UserPermission.BankStatementReadList,
                ),
            },
            {
                key: RoutePaths.products,
                path: RoutePaths.products,
                Component: () => {
                    return <GroupPage groupKey={RoutePaths.products} />;
                },
                label: <Trans i18nKey="App.Menu.Links.Products" />,
                description: (
                    <Trans i18nKey="App.Menu.Links.Products.Description" />
                ),
                exact: true,
                icon: <LoansMenuIcon />,
                children: [
                    {
                        key: RoutePaths.productsBNPL,
                        path: RoutePaths.productsBNPL,
                        Component: BNPLProductsPage,
                        label: <Trans i18nKey="App.Menu.Links.Products.BNPL" />,
                        description: (
                            <Trans i18nKey="App.Menu.Links.Products.BNPL.Description" />
                        ),
                        exact: true,
                        linkToCreate: RoutePaths.productBNPLCreate,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductRead,
                        ),
                    },
                    {
                        key: RoutePaths.productBNPLCreate,
                        path: RoutePaths.productBNPLCreate,
                        Component: BnplProductCreatePage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductCreate,
                        ),
                    },
                    {
                        key: RoutePaths.productBNPLEdit,
                        path: RoutePaths.productBNPLEdit,
                        Component: BnplProductEditPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductEdit,
                        ),
                    },
                    {
                        key: RoutePaths.productBNPLDetails,
                        path: RoutePaths.productBNPLDetails,
                        Component: BNPLProductDetailsPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductRead,
                        ),
                    },

                    {
                        key: RoutePaths.productsInstallment,
                        path: RoutePaths.productsInstallment,
                        Component: InstallmentProductsPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Products.Installment" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Products.Installment.Description" />
                        ),
                        exact: true,
                        linkToCreate: RoutePaths.productInstallmentCreate,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductRead,
                        ),
                    },
                    {
                        key: RoutePaths.productInstallmentCreate,
                        path: RoutePaths.productInstallmentCreate,
                        Component: InstallmentProductCreatePage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductCreate,
                        ),
                    },
                    {
                        key: RoutePaths.productInstallmentEdit,
                        path: RoutePaths.productInstallmentEdit,
                        Component: InstallmentProductEditPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductEdit,
                        ),
                    },
                    {
                        key: RoutePaths.productInstallmentDetails,
                        path: RoutePaths.productInstallmentDetails,
                        Component: InstallmentProductDetailsPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductRead,
                        ),
                    },

                    {
                        key: RoutePaths.productsPledged,
                        path: RoutePaths.productsPledged,
                        Component: PledgedProductsPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Products.Pledged" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Products.Pledged.Description" />
                        ),
                        exact: true,
                        linkToCreate: RoutePaths.productPledgedCreate,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductRead,
                        ),
                    },
                    {
                        key: RoutePaths.productPledgedCreate,
                        path: RoutePaths.productPledgedCreate,
                        Component: PledgedProductCreatePage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductCreate,
                        ),
                    },
                    {
                        key: RoutePaths.productPledgedEdit,
                        path: RoutePaths.productPledgedEdit,
                        Component: PledgedProductEditPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductEdit,
                        ),
                    },
                    {
                        key: RoutePaths.productPledgedDetails,
                        path: RoutePaths.productPledgedDetails,
                        Component: PledgedProductDetailsPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductRead,
                        ),
                    },
                    {
                        key: RoutePaths.productsPayon,
                        path: RoutePaths.productsPayon,
                        Component: PayonProductsPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Products.Payon" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Products.Payon.Description" />
                        ),
                        exact: true,
                        linkToCreate: RoutePaths.productsPayonCreate,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductRead,
                        ),
                    },
                    {
                        key: RoutePaths.productsPayonCreate,
                        path: RoutePaths.productsPayonCreate,
                        Component: PayonProductCreatePage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductCreate,
                        ),
                    },
                    {
                        key: RoutePaths.productsPayonDetails,
                        path: RoutePaths.productsPayonDetails,
                        Component: PayonProductDetailsPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductRead,
                        ),
                    },
                    {
                        key: RoutePaths.productsPayonEdit,
                        path: RoutePaths.productsPayonEdit,
                        Component: PayonProductEditPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductEdit,
                        ),
                    },
                    {
                        key: RoutePaths.productsTrustPledged,
                        path: RoutePaths.productsTrustPledged,
                        Component: TrustPledgedProductsPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Products.TrustPledged" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Products.TrustPledged.Description" />
                        ),
                        exact: true,
                        linkToCreate: RoutePaths.productTrustPledgedCreate,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductRead,
                        ),
                    },
                    {
                        key: RoutePaths.productTrustPledgedCreate,
                        path: RoutePaths.productTrustPledgedCreate,
                        Component: TrustPledgedProductCreatePage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductCreate,
                        ),
                    },
                    {
                        key: RoutePaths.productTrustPledgedEdit,
                        path: RoutePaths.productTrustPledgedEdit,
                        Component: TrustPledgedProductEditPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductEdit,
                        ),
                    },
                    {
                        key: RoutePaths.productTrustPledgedDetails,
                        path: RoutePaths.productTrustPledgedDetails,
                        Component: TrustPledgedProductDetailsPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.ProductRead,
                        ),
                    },
                ],
            },
            {
                key: RoutePaths.collaterals,
                path: RoutePaths.collaterals,
                Component: CollateralsPage,
                label: <Trans i18nKey="App.Menu.Links.Collaterals" />,
                description: (
                    <Trans i18nKey="App.Menu.Links.Collaterals.Description" />
                ),
                icon: <CollateralsMenuIcon />,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.CollateralReadList,
                ),
            },
            {
                key: RoutePaths.collateralCreate,
                path: RoutePaths.collateralCreate,
                Component: CollateralCreatePage,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.CollateralCreate,
                ),
            },
            {
                key: RoutePaths.collateralDetails,
                path: RoutePaths.collateralDetails,
                Component: CollateralDetailsPage,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.CollateralReadDetails,
                ),
            },
            {
                key: RoutePaths.collections,
                path: RoutePaths.collections,
                Component: CollectionsPage,
                label: <Trans i18nKey="App.Menu.Links.CollectionList" />,
                description: (
                    <Trans i18nKey="App.Menu.Links.CollectionList.Description" />
                ),
                icon: <DebtCollectionMenuIcon />,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.CollectionReadList,
                    UserPermission.CollectionReadOwnList,
                ),
            },
            {
                key: RoutePaths.collectionDetails,
                path: RoutePaths.collectionDetails,
                Component: CollectionDetails,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.CollectionReadDetails,
                ),
            },
            {
                key: RoutePaths.collectionRecordActivity,
                path: RoutePaths.collectionRecordActivity,
                Component: RecordActivityPage,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.CollectionRecordActivity,
                ),
            },
            {
                key: RoutePaths.restrictions,
                path: RoutePaths.restrictions,
                Component: RestrictionsListPage,
                label: <Trans i18nKey="App.Menu.Links.Restrictions" />,
                description: (
                    <Trans i18nKey="App.Menu.Links.Restrictions.Description" />
                ),
                icon: <RestrictionsMenuIcon />,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.RestrictionListRead,
                ),
            },
            {
                key: RoutePaths.branches,
                path: RoutePaths.branches,
                Component: BranchesPage,
                label: <Trans i18nKey="App.Menu.Links.Branches" />,
                icon: <BranchInformationMenuIcon />,
                description: (
                    <Trans i18nKey="App.Menu.Links.Branches.Description" />
                ),
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.BranchReadList,
                ),
            },
            {
                key: RoutePaths.security,
                path: RoutePaths.security,
                Component: () => {
                    return <GroupPage groupKey={RoutePaths.security} />;
                },
                label: <Trans i18nKey="App.Menu.Links.Security" />,
                description: (
                    <Trans i18nKey="App.Menu.Links.Security.Description" />
                ),
                icon: <SecurityMenuIcon />,
                exact: true,
                children: [
                    {
                        key: RoutePaths.users,
                        path: RoutePaths.users,
                        Component: UsersPage,
                        exact: true,
                        label: <Trans i18nKey="App.Menu.Links.Users" />,
                        description: (
                            <Trans i18nKey="App.Menu.Links.Users.Description" />
                        ),
                        available: AppUser.hasPermissions(
                            UserPermission.UserReadList,
                        ),
                    },
                    {
                        key: RoutePaths.userCreate,
                        path: RoutePaths.userCreate,
                        Component: UserCreatePage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.UserCreate,
                        ),
                    },
                    {
                        key: RoutePaths.userDetails,
                        path: RoutePaths.userDetails,
                        Component: UserDetailsPage,
                        available: AppUser.hasPermissions(
                            UserPermission.UserReadDetails,
                        ),
                    },
                    {
                        key: RoutePaths.userEdit,
                        path: RoutePaths.userEdit,
                        Component: UserEditPage,
                        available: AppUser.hasPermissions(
                            UserPermission.UserEdit,
                        ),
                    },
                    {
                        key: RoutePaths.roles,
                        path: RoutePaths.roles,
                        Component: RolesPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Security.Roles" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Security.Roles.Description" />
                        ),
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.RoleReadList,
                        ),
                    },
                    {
                        key: RoutePaths.roleCreate,
                        path: RoutePaths.roleCreate,
                        Component: RoleCreatePage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.RoleCreate,
                        ),
                    },
                    {
                        key: RoutePaths.roleDetails,
                        path: RoutePaths.roleDetails,
                        Component: RoleDetailsPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.RoleReadDetails,
                        ),
                    },
                    {
                        key: RoutePaths.roleEdit,
                        path: RoutePaths.roleEdit,
                        Component: RoleEditPage,
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.RoleEdit,
                        ),
                    },
                ],
            },
            {
                key: RoutePaths.settings,
                path: RoutePaths.settings,
                Component: () => {
                    return <GroupPage groupKey={RoutePaths.settings} />;
                },
                label: <Trans i18nKey="App.Menu.Links.Settings" />,
                description: (
                    <Trans i18nKey="App.Menu.Links.Settings.Description" />
                ),
                icon: <SettingsMenuIcon />,
                exact: true,
                children: [
                    {
                        key: RoutePaths.settingsOverdueDays,
                        path: RoutePaths.settingsOverdueDays,
                        Component: LoanOverdueDaysGapsPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Settings.LoanOverdue" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Settings.LoanOverdue.Description" />
                        ),
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.LoanSettingsDaysOverdueRead,
                        ),
                    },
                    {
                        key: RoutePaths.settingsProvisionRates,
                        path: RoutePaths.settingsProvisionRates,
                        Component: ManageProvisionRatesPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Settings.ProvisionRates" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Settings.ProvisionRates.Description" />
                        ),
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.LoanSettingsProvisionRatesRead,
                        ),
                    },
                    {
                        key: RoutePaths.settingsScoring,
                        path: RoutePaths.settingsScoring,
                        Component: SettingsScoringPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Settings.Scoring" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Settings.Scoring.Description" />
                        ),
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.LoanSettingsScoringRead,
                        ),
                    },
                    {
                        key: RoutePaths.settingsProductLimit,
                        path: RoutePaths.settingsProductLimit,
                        Component: SettingsProductLimitPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Settings.ProductLimit" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Settings.ProductLimit.Description" />
                        ),
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.LoanSettingsProductLimitRead,
                        ),
                    },
                    {
                        key: RoutePaths.settingsRiskyGroup,
                        path: RoutePaths.settingsRiskyGroup,
                        Component: SettingsRiskyGroupPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Settings.RiskyGroup" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Settings.RiskyGroup.Description" />
                        ),
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.LoanSettingsRiskyGroupRead,
                        ),
                    },
                    {
                        key: RoutePaths.settingsTemplates,
                        path: RoutePaths.settingsTemplates,
                        Component: DocumentTemplatesPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Settings.DocumentTemplates" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Settings.DocumentTemplates.Description" />
                        ),
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.DocumentTemplateRead,
                        ),
                    },
                    {
                        key: RoutePaths.settingsCustomerSegmentation,
                        path: RoutePaths.settingsCustomerSegmentation,
                        Component: CustomerGroupsPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Settings.CustomerSegmentation" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Settings.CustomerSegmentation.Description" />
                        ),
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.CustomerSettingsSegmentationRead,
                        ),
                    },
                    {
                        key: RoutePaths.settingsCustomerVerification,
                        path: RoutePaths.settingsCustomerVerification,
                        Component: SettingsCustomerVerificationPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Settings.CustomerVerification" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Settings.CustomerVerification.Description" />
                        ),
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.LoanSettingsVerificationRead,
                        ),
                    },
                    {
                        key: RoutePaths.settingsProduct,
                        path: RoutePaths.settingsProduct,
                        Component: SettingsProductPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Settings.Product" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Settings.Product.Description" />
                        ),
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.LoanSettingsTrustDefaultProductRead,
                        ),
                    },
                    {
                        key: RoutePaths.settingsCollectionAssignment,
                        path: RoutePaths.settingsCollectionAssignment,
                        Component: SettingsCollectionAssignmentPage,
                        label: (
                            <Trans i18nKey="App.Menu.Links.Settings.CollectionAssignment" />
                        ),
                        description: (
                            <Trans i18nKey="App.Menu.Links.Settings.CollectionAssignment.Description" />
                        ),
                        exact: true,
                        available: AppUser.hasPermissions(
                            UserPermission.CollectionAssignmentSettings,
                        ),
                    },
                ],
            },
            {
                key: RoutePaths.authResetPassword,
                path: RoutePaths.authResetPassword,
                Component: ResetPasswordPage,
                exact: true,
            },
            {
                key: RoutePaths.settingsGroupDetails,
                path: RoutePaths.settingsGroupDetails,
                Component: CustomerGroupCompaniesPage,
                exact: true,
                available: AppUser.hasPermissions(
                    UserPermission.CustomerSettingsSegmentationRead,
                ),
            },
            {
                key: RoutePaths.tasks,
                path: RoutePaths.tasks,
                Component: TasksPage,
                label: <Trans i18nKey="App.Menu.Links.Tasks" />,
                exact: true,
            },
            {
                key: RoutePaths.taskDetails,
                path: RoutePaths.taskDetails,
                Component: TaskViewPage,
                exact: true,
            },
        ];
    }

    @computed get routes() {
        return this.routeDefinitions
            .flatMap((route) => {
                if (isRouteGroup(route)) {
                    /* debugger;
                    const asa = route.children?.some((item) => item.available);

                    if (!asa) {
                        return { ...route, available: false };
                    }*/

                    return [route, ...(route.children || [])];
                }

                return route;
            })
            .filter(Boolean) as RouteDefinition[];
    }

    @computed get menuItems(): RouteDefinition[] {
        return this.routeDefinitions
            .filter((route) => route.label)
            .map((route) => {
                return {
                    ...route,
                    children: Array.isArray(route.children)
                        ? route.children.filter(
                              (item) => item.available !== false,
                          )
                        : route.children,
                };
            })
            .filter((route) =>
                route.available === undefined ? true : route.available,
            );
    }
}

export const RouterStore = new RouterStoreClass();
