import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListPage } from 'src/components/ListPage/ListPage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { ProductListEntry } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { PayonProductsHeaderPanel } from 'src/pages/LoanProducts/PayonProductsPage/PayonProductsHeaderPanel';
import { ProductsStatus } from 'src/pages/LoanProducts/ProductsStatus';
import { PayonProductsStore } from 'src/stores/PayonProductsStore/PayonProductsStore';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = PayonProductsStore;
const enumsToLoad: EnumName[] = [
    'PRODUCT_STATUS',
    'REPAYMENT_CALCULATION_METHOD',
    'REPAYMENT_ALLOCATION_RULE',
];

export const PayonProductsPage = observer(() => {
    const isLoading = useEnumsLoader(enumsToLoad);

    return (
        <ListPage
            store={store}
            columns={columns}
            isLoading={isLoading}
            header={
                <PayonProductsHeaderPanel
                    title={t('Page.PayonProducts.Header')}
                />
            }
        />
    );
});

export const columns: ColumnsType<ProductListEntry> = [
    {
        key: 'id',
        dataIndex: 'externalId',
        title: <Trans i18nKey="Page.PayonProducts.List.Id" />,
        render(_, record) {
            const target = UrlHelper.getTargetUrl(
                RoutePaths.productsPayonDetails,
                { productId: record.id },
            );

            return <Link to={target}>{record.externalId}</Link>;
        },
        sorter: true,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: <Trans i18nKey="Page.PayonProducts.List.Name" />,
        sorter: true,
    },
    {
        key: 'repaymentAllocationRule',
        dataIndex: 'repaymentAllocationRule',
        title: (
            <Trans i18nKey="Page.PayonProducts.List.RepaymentAllocationRule" />
        ),
        render(value) {
            return (
                <EnumView enumName="REPAYMENT_ALLOCATION_RULE" value={value} />
            );
        },
        sorter: true,
    },
    {
        key: 'repaymentCalculationMethod',
        dataIndex: 'repaymentCalculationMethod',
        title: (
            <Trans i18nKey="Page.PayonProducts.List.RepaymentCalculationMethod" />
        ),
        render(value) {
            return (
                <EnumView
                    enumName="REPAYMENT_CALCULATION_METHOD"
                    value={value}
                />
            );
        },
        sorter: true,
    },
    {
        key: 'penaltyInterestAccrualStartDay',
        dataIndex: 'penaltyInterestAccrualStartDay',
        title: (
            <Trans i18nKey="Page.PayonProducts.List.PenaltyInterestAccrualStartDay" />
        ),
        sorter: true,
    },
    {
        key: 'version',
        dataIndex: 'version',
        title: <Trans i18nKey="Page.PayonProducts.List.Version" />,
        sorter: true,
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Page.PayonProducts.List.Status" />,
        render(value) {
            return <ProductsStatus status={value} />;
        },
        sorter: true,
    },
];
