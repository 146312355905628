import { Form, FormItemProps, TimePicker } from 'antd';
import React from 'react';
import { DEFAULT_TIME_FORMAT } from 'src/utils/DateHelper';
import styled from 'styled-components';

export type TimePickerFieldProps = FormItemProps & {
    label: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    format?: string;
    // eslint-disable-next-line react/require-default-props
    minuteStep?: number;
};

export const TimePickerField = ({
    name,
    label,
    format = DEFAULT_TIME_FORMAT,
    minuteStep,
    ...props
}: TimePickerFieldProps) => {
    return (
        <Form.Item {...props} name={name} label={label}>
            <TimePickerStyled
                format={format}
                showNow={false}
                minuteStep={minuteStep}
                getPopupContainer={() =>
                    document.querySelector('.ant-modal-wrap') ||
                    document.querySelector('#layout-content') ||
                    document.body
                }
            />
        </Form.Item>
    );
};

const TimePickerStyled = styled(TimePicker)`
    width: 100%;
    z-index: auto;
`;
