import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { ProposeActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ProposeActionDialogComponent';
import {
    ProposePaymentAmountChangingActionStore,
    ProposePaymentAmountChangingActionStoreClass,
} from 'src/stores/LoanActionsStores/ProposePaymentAmountChangingActionStore';
import { DateHelper } from 'src/utils/DateHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const { Text } = Typography;

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

const formFieldsRender = (
    store?: ProposePaymentAmountChangingActionStoreClass,
) => {
    return store?.initialValues?.loanSchedulePrincipals.map((item, index) => {
        const getFieldRules = () => {
            if (
                index ===
                Number(store?.initialValues?.loanSchedulePrincipals.length) - 1
            ) {
                return [
                    ValidationRules.required,
                    ValidationRules.numberMinValue(1),
                ];
            }

            return [
                ValidationRules.required,
                ValidationRules.numberMinValue(0),
            ];
        };

        return (
            <>
                <NumberField
                    name={['loanSchedulePrincipals', index, 'principal']}
                    label={DateHelper.formatDate(item.paymentDate)}
                    rules={getFieldRules()}
                />
                <HiddenField
                    name={['loanSchedulePrincipals', index, 'paymentDate']}
                />
                <HiddenField
                    name={['loanSchedulePrincipals', index, 'periodId']}
                />
            </>
        );
    });
};

export const ProposePaymentAmountChangingDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ProposePaymentAmountChangingActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ProposePaymentAmountChanging.Title');

        return (
            <ProposeActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <StyledText>
                    {t('Dialog.ProposePaymentAmountChanging.Text')}
                </StyledText>
                <StyledDataView
                    value={store.initialValues?.currentPrincipal}
                    label={t('Dialog.ProposePaymentAmountChanging.CurrentDebt')}
                    format="money"
                    align="horizontal"
                />
                {formFieldsRender(store)}
            </ProposeActionDialogComponent>
        );
    },
);

export const ProposePaymentAmountChangingDialog = addModalRenderer(
    ProposePaymentAmountChangingDialogFn,
);

const StyledText = styled(Text)`
    display: block;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    color: #4d6082;
`;

const StyledDataView = styled(DataView)`
    .app-data-view__label {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #4d6082;
    }

    .app-data-view__value {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #2e3e5c;
    }
`;
