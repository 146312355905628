import { useParams } from 'react-router-dom';

export function useEntityId() {
    const params = useParams<{ entityId: string }>();

    return {
        entityId: params?.entityId,
        hasEntityId: Boolean(params?.entityId),
    };
}
