import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ProposeActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ProposeActionDialogComponent';
import { ProposeInterestAccrueStopRenewActionStore } from 'src/stores/LoanActionsStores/ProposeInterestAccrueStopRenewActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

const { Text } = Typography;

export const ProposeInterestAccrueStopRenewDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ProposeInterestAccrueStopRenewActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ProposeInterestAccrualStopRenew.Title');

        return (
            <ProposeActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                {store.initialValues?.interestAccrualStopped && (
                    <StyledText>
                        {t('Dialog.ProposeInterestAccrualStopRenew.Start')}
                    </StyledText>
                )}

                {!store.initialValues?.interestAccrualStopped && (
                    <StyledText>
                        {t('Dialog.ProposeInterestAccrualStopRenew.Stop')}
                    </StyledText>
                )}
            </ProposeActionDialogComponent>
        );
    },
);

export const ProposeInterestAccrueStopRenewDialog = addModalRenderer(
    ProposeInterestAccrueStopRenewDialogFn,
);

const StyledText = styled(Text)`
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #4d6082;
`;
