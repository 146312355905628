import { Button } from 'antd';
import classNames from 'classnames';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { CirclePlusIcon } from 'src/components/Icons/Icons';
import { Spinner } from 'src/components/Spinner/Spinner';
import { ActionDefinition } from 'src/utils/UserActionsHelper';
import styled from 'styled-components';

export type DataInformationCardProps = {
    title?: React.ReactNode;
    onAdd?: () => void;
    className?: string;
    isLoading?: boolean;
    hasData?: boolean;
    canUpdate?: boolean;
};

export const DataInformationCard: React.FC<DataInformationCardProps> = observer(
    ({ title, onAdd, className, isLoading, hasData, canUpdate, children }) => {
        const getIndividualActions = (): ActionDefinition[] => {
            return [
                {
                    key: 'update',
                    label: t('Individual.Actions.Update'),
                    disabled: canUpdate === false,
                    onClick: () => {
                        onAdd?.();
                    },
                },
            ];
        };

        const individualActions = getIndividualActions().filter(
            (action) => !action.disabled,
        );

        if (isLoading) {
            return (
                <StyledEmptyDataInformationCardWrapper>
                    <Spinner />
                </StyledEmptyDataInformationCardWrapper>
            );
        }

        if (hasData) {
            return (
                <div className={classNames('data-information-card', className)}>
                    <StyledCard title={title} menuActions={individualActions}>
                        {children}
                    </StyledCard>
                </div>
            );
        }

        return (
            <StyledEmptyDataInformationCardWrapper
                className={classNames(
                    'data-information-card',
                    'data-information-card_empty',
                    className,
                )}
            >
                <StyledButton
                    icon={<CirclePlusIcon />}
                    type="dashed"
                    onClick={() => onAdd?.()}
                    disabled={canUpdate === false}
                >
                    {title}
                </StyledButton>
            </StyledEmptyDataInformationCardWrapper>
        );
    },
);

const StyledCard = styled(Card)`
    .ant-card-body {
        padding: 30px 28px 20px;
    }

    .ant-card-extra {
        padding: 0;
    }
`;

const StyledEmptyDataInformationCardWrapper = styled.div`
    border: 1px dashed #82869b;
    border-radius: 8px;
    height: 220px;
    margin: 8px 0;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledButton = styled(Button)`
    height: 60px;
    padding: 15px 20px;
`;
