import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { PledgedProduct } from 'src/generated-api-client';

export type PledgedProductDetailsTab = {
    productPledged?: PledgedProduct;
};
export const PledgedProductDetailsTab = observer(
    ({ productPledged }: PledgedProductDetailsTab) => {
        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card title={t('Page.PledgedProductDetails.Details.Title')}>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={productPledged?.productLineId}
                                    label={t(
                                        'Page.PledgedProductDetails.ExternalId',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productPledged?.name}
                                    label={t('Page.PledgedProductDetails.Name')}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productPledged?.status}
                                    label={t(
                                        'Page.PledgedProductDetails.Status',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="PRODUCT_STATUS"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        productPledged?.repaymentAllocationRule
                                    }
                                    label={t(
                                        'Page.PledgedProductDetails.RepaymentAllocationRule',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="REPAYMENT_ALLOCATION_RULE"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={productPledged?.interestRate}
                                    label={t(
                                        'Page.PledgedProductDetails.InterestRate',
                                    )}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        productPledged?.penaltyInterestMultiplier
                                    }
                                    label={t(
                                        'Page.PledgedProductDetails.PenaltyInterestMultiplier',
                                    )}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        productPledged?.penaltyInterestAccrualStartDay
                                    }
                                    label={t(
                                        'Page.PledgedProductDetails.PenaltyInterestAccrualStartDay',
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        productPledged?.penaltyInterestAccrualMethod
                                    }
                                    label={t(
                                        'Page.PledgedProductDetails.PenaltyInterestAccrualMethod',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="PENALTY_INTEREST_ACCRUAL_METHOD"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={productPledged?.dti}
                                    label={t('Page.PledgedProductDetails.Dti')}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productPledged?.maxLoanAmount}
                                    label={t(
                                        'Page.PledgedProductDetails.MaxLoanAmount',
                                    )}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productPledged?.maxTenor}
                                    label={t(
                                        'Page.PledgedProductDetails.MaxTenor',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        productPledged?.repaymentCalculationMethod
                                    }
                                    label={t(
                                        'Page.PledgedProductDetails.RepaymentCalculationMethod',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="REPAYMENT_CALCULATION_METHOD"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={productPledged?.subtype}
                                    label={t(
                                        'Page.PledgedProductDetails.Subtype',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="PRODUCT_SUBTYPE"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productPledged?.disbursementFee}
                                    label={t(
                                        'Page.PledgedProductDetails.DisbursementFee',
                                    )}
                                    format="percent"
                                />
                            </Col>
                            <Col span={6}>
                                <DataView
                                    value={productPledged?.excludedFromOffer}
                                    label={t(
                                        'Page.PledgedProductDetails.ExcludedFromOffer',
                                    )}
                                    format="yes-no"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    },
);
