import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { NotFound } from 'src/components/NotFound/NotFound';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useLoanId } from 'src/hooks/useLoanId';

export const UnknownLoan = observer(() => {
    const { loanId } = useLoanId();

    return (
        <NotFound
            title={t('Page.UnknownLoan.Title')}
            description={t('Page.UnknownLoan.Description', {
                loanId,
            })}
        >
            <Button
                onClick={() => {
                    Router.navigate(RoutePaths.loans);
                }}
            >
                {t('Page.UnknownLoan.GoToList')}
            </Button>
        </NotFound>
    );
});
