import { DefaultOptionType } from 'antd/lib/select';
import { t } from 'i18next';
import { ProcessName } from 'src/generated-api-client';

export const processNameOptions = (): DefaultOptionType[] => {
    return [
        {
            label: t('ProcessName.BUSINESS_INCOME_ASSESSMENT'),
            value: ProcessName.BUSINESS_INCOME_ASSESSMENT,
        },
        {
            label: t('ProcessName.PLEDGED_LOAN_APPLICATION'),
            value: ProcessName.PLEDGED_LOAN_APPLICATION,
        },
        {
            label: t('ProcessName.SELECT_TASK_CANDIDATE'),
            value: ProcessName.SELECT_TASK_CANDIDATE,
        },
    ];
};
