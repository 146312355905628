/* eslint-disable max-lines */
import { Button, Col, Form as AntForm, notification, Row, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { CheckboxField } from 'src/components/Form/CheckboxField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { ErrorField } from 'src/components/Form/ErrorField';
import { Form } from 'src/components/Form/Form';
import { PhoneField } from 'src/components/Form/PhoneField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { CreateUserForm } from 'src/generated-api-client';
import { BranchOptionsStore } from 'src/stores/BranchOptionsStore/BranchOptionsStore';
import {
    convertRoleListItemToOption,
    RoleStore,
} from 'src/stores/RoleStore/RoleStore';
import { UsersStore } from 'src/stores/UsersStore/UsersStore';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import styled from 'styled-components';

export const UserCreatePage = observer(() => {
    const [form] = AntForm.useForm();
    const parentBranchId = AntForm.useWatch('parentBranchId', form);

    useEffect(() => {
        BranchOptionsStore.loadList();

        return () => {
            UsersStore.createItemLoader.reset();
            BranchOptionsStore.listLoader.reset();
            RoleStore.listLoader.reset();
        };
    }, []);

    const loadingInfo = useMemo(() => {
        return new CombinedLoadingStatus([
            UsersStore.createItemLoader,
            BranchOptionsStore.listLoader,
            RoleStore.listLoader,
        ]);
    }, []);

    const fields = FormHelper.getFieldDataItems(
        UsersStore.createItemLoader.errorData,
    );

    const { parentBranchOptions } = BranchOptionsStore;
    const childBranchOptions =
        BranchOptionsStore.childBranchOptions(parentBranchId);

    useEffect(() => {
        form.setFields([{ name: 'childBranchId', value: undefined }]);
    }, [parentBranchId]);

    return (
        <BasePage
            title={t('Page.UserCreate.Title')}
            isLoading={loadingInfo.isLoading}
        >
            <Form
                form={form}
                onFinish={async (values: CreateUserForm) => {
                    await UsersStore.create(values);

                    if (
                        UsersStore.createItemLoader.hasError &&
                        UsersStore.createItemLoader.errorData.detail
                    ) {
                        notification.error({
                            message:
                                UsersStore.createItemLoader.errorData.detail,
                        });
                    }

                    if (!UsersStore.createItemLoader.hasError) {
                        notification.success({
                            message: t('Page.UserCreate.Message.Success'),
                        });
                        Router.navigate(RoutePaths.users);
                    }
                }}
                onReset={() => {
                    Router.navigate(RoutePaths.users);
                }}
                fields={fields}
                initialValues={getInitialValues()}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            name="surname"
                            label={t('Page.UserCreate.Surname')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.name,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            name="name"
                            label={t('Page.UserCreate.Name')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.name,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            name="email"
                            label={t('Page.UserCreate.Email')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.email,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectField
                            store={RoleStore}
                            convertListItemToOption={
                                convertRoleListItemToOption
                            }
                            name="roleId"
                            label={t('Page.UserCreate.Role')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <EnumSelectField
                            name="department"
                            label={t('Page.UserCreate.Department')}
                            rules={[ValidationRules.required]}
                            enumName="BO_USER_DEPARTMENT"
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            name="position"
                            label={t('Page.UserCreate.Position')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectField
                            name="parentBranchId"
                            label={t('Page.UserCreate.ParentBranch')}
                            options={parentBranchOptions}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectField
                            name="childBranchId"
                            label={t('Page.UserCreate.ChildBranch')}
                            options={childBranchOptions}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <PhoneField
                            name="phone"
                            label={t('Page.UserCreate.Phone')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            name="teamsProfile"
                            label={t('Page.UserCreate.TeamsProfile')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <CheckboxField
                            valuePropName="checked"
                            name="isBranchManager"
                            label={t('Page.UserCreate.IsBranchManager')}
                        />
                    </Col>
                    <Col span={12}>
                        <CheckboxField
                            valuePropName="checked"
                            name="isRelationshipManager"
                            label={t('Page.UserCreate.IsRelationshipManager')}
                        />
                    </Col>
                    <Col span={24}>
                        <StyledErrorField name="createUserForm" />
                    </Col>
                    <Col span={24}>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                data-test="save"
                            >
                                {t('Page.UserCreate.Btn.Save')}
                            </Button>
                            <Button
                                type="default"
                                htmlType="reset"
                                data-test="cancel"
                            >
                                {t('Page.UserCreate.Btn.Reset')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </BasePage>
    );
});

const getInitialValues = () => {
    return {
        isBranchManager: false,
        isRelationshipManager: false,
    };
};

const StyledErrorField = styled(ErrorField)`
    padding-bottom: 12px;
`;
