import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';

export const LivestockCollateralGeneralInfoUpdateVariables = observer(() => {
    return (
        <Row gutter={16}>
            <HiddenField name="type" />
            <Col xs={6}>
                <EnumSelectField
                    name="herderInformation"
                    enumName="HERDER_INFORMATION"
                    label={t(
                        'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.ApproveConditions.HerderInformation',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="herdingNumber"
                    label={t(
                        'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.ApproveConditions.HerdingNumber',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="livestockTotalCount"
                    label={t(
                        'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.ApproveConditions.LivestockTotalCount',
                    )}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.numberMinValue(0),
                    ]}
                />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="weightedNumberIntoSheep"
                    label={t(
                        'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.ApproveConditions.WeightedNumberIntoSheep',
                    )}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.numberMinValue(0),
                    ]}
                />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="livestockTotalAmount"
                    label={t(
                        'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.ApproveConditions.LivestockTotalAmount',
                    )}
                    thousandDivider
                    rules={[
                        ValidationRules.required,
                        ValidationRules.numberMinValue(0),
                    ]}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="additionalField"
                    label={t(
                        'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.ApproveConditions.AdditionalField',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <EnumSelectField
                    name="ownershipType"
                    enumName="COLLATERAL_OWNERSHIP_TYPE"
                    label={t(
                        'Page.CollateralDetails.Tabs.Livestock.GeneralInfo.ApproveConditions.OwnershipType',
                    )}
                />
            </Col>
        </Row>
    );
});
