import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { BankStatementStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: BankStatementStatus;
};

export const BankStatementsStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: BankStatementStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: <EnumView enumName="BANK_STATEMENT_STATUS" value={status} />,
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [BankStatementStatus.APPROVED]: {
        title: (
            <EnumView
                enumName="BANK_STATEMENT_STATUS"
                value={BankStatementStatus.APPROVED}
            />
        ),
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
    [BankStatementStatus.PENDING]: {
        title: (
            <EnumView
                enumName="BANK_STATEMENT_STATUS"
                value={BankStatementStatus.PENDING}
            />
        ),
        color: '#8b939b',
        backgroundColor: '#F5F6F7',
    },
    [BankStatementStatus.CANCELLED]: {
        title: (
            <EnumView
                enumName="BANK_STATEMENT_STATUS"
                value={BankStatementStatus.CANCELLED}
            />
        ),
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
};
