import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FilterForm } from 'src/components/Form/FilterForm';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { DateHelper } from 'src/utils/DateHelper';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

type CollateralsListFilterFormProps = {
    store: BasicStore<any>;
};
export const AddCollateralFilterForm = observer(
    ({ store }: CollateralsListFilterFormProps) => {
        return (
            <FilterForm
                store={store}
                convertFilterCriteriaToFormValues={
                    convertFilterCriteriaToFormValues
                }
                convertFormValuesToFilterCriteria={
                    convertFormValuesToFilterCriteria
                }
            >
                <NumberField
                    name="loanId"
                    label={t('Page.CollateralsPage.Filter.LinkedLoanId')}
                    onlyInteger
                />
                <NumberField
                    name="collateralId"
                    label={t('Page.CollateralsPage.Filter.CollateralId')}
                    onlyInteger
                />
                <TextField
                    name="ownerName"
                    label={t('Page.CollateralsPage.Filter.OwnerName')}
                />
                <TextField
                    name="ownerRegistrationNumber"
                    label={t(
                        'Page.CollateralsPage.Filter.OwnerRegistrationNumber',
                    )}
                />
                <EnumSelectField
                    mode="multiple"
                    name="types"
                    label={t('Page.CollateralsPage.Filter.Type')}
                    enumName="COLLATERAL_TYPE"
                />
                <EnumSelectField
                    mode="multiple"
                    name="subTypes"
                    label={t('Page.CollateralsPage.Filter.SubType')}
                    enumName="COLLATERAL_SUB_TYPE"
                />
                <TextField
                    name="chassisNumber"
                    label={t('Page.CollateralsPage.Filter.ChassisNumber')}
                />
                <TextField
                    name="collateralRegistrationNumber"
                    label={t(
                        'Page.CollateralsPage.Filter.CollateralRegistrationNumber',
                    )}
                />
                <DatePickerField
                    name="establishedDate"
                    label={t('Page.CollateralsPage.Filter.EstablishedDate')}
                    disabledDate={(date) => {
                        return date.isAfter(moment().endOf('day'));
                    }}
                />
                <DatePickerField
                    name="dateOfManufacture"
                    label={t('Page.CollateralsPage.Filter.DateOfManufacture')}
                    disabledDate={(date) => {
                        return date.isAfter(moment().endOf('year'));
                    }}
                    format="YYYY"
                    picker="year"
                />
            </FilterForm>
        );
    },
);

const convertFilterCriteriaToFormValues = (values: any) => {
    return {
        ...values,
        establishedDate:
            values.establishedDate && moment(values.establishedDate),
        dateOfManufacture:
            values.dateOfManufacture &&
            moment(values.dateOfManufacture, 'YYYY'),
    };
};

const convertFormValuesToFilterCriteria = (values: any) => {
    return {
        ...values,
        establishedDate: DateHelper.formatISODate(values.establishedDate),
        dateOfManufacture: DateHelper.getYear(values.dateOfManufacture),
    };
};
