import { History } from 'history';
import { action, computed, observable, when } from 'mobx';
import { Router } from 'src/core/router/Router';
import { configureMobX } from 'src/mobXSettings';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { AuthStore, setupAuthStore } from 'src/stores/AuthStore/AuthStore';
import { CurrentWorkingTaskStore } from 'src/stores/CurrentWorkingTaskStore/CurrentWorkingTaskStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { AdministrativeUnitsStore } from 'src/stores/Resources/AdministrativeUnitsStore/AdministrativeUnitsStore';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { TasksStore } from 'src/stores/TasksStore/TasksStore';
import { UrlHelper } from 'src/utils/UrlHelper';

export class AppStoreClass {
    @observable isSetHistory = false;
    @observable isInit = false;
    @observable isLoading = false;

    constructor() {
        when(
            () => AuthStore.authenticated,
            async () => {
                await this.preloadData();

                this.navigateToStartPage();
            },
        );
    }

    @action
    async init() {
        configureMobX();

        await SettingsStore.init();
        await setupAuthStore(SettingsStore.settings);

        await AuthStore.init?.();
        await AuthStore.authenticate();

        this.isInit = true;
    }

    @action
    async preloadData() {
        this.isInit = false;
        await AppUser.loadUserInfo();
        await this.preloadResources();
        await this.restoreCurrentWorkingTask();
        this.isInit = true;
    }

    @computed get started() {
        return this.isSetHistory && this.isInit;
    }

    setHistory(history: History) {
        Router.setHistory(history);
        this.isSetHistory = true;
    }

    navigateToStartPage() {
        const { pathname, search } = window.location;
        const { redirect } = UrlHelper.parseSearchParams(search);

        const checkedPathname = !!pathname && pathname !== '/' ? pathname : '';

        const targetUrl =
            (redirect as string) ||
            checkedPathname ||
            ProfileStore.startPageUrl;

        Router.navigate(targetUrl ?? '');
    }

    @action startLoading() {
        this.isLoading = true;
    }

    @action stopLoading() {
        this.isLoading = false;
    }

    @action async preloadResources() {
        await AdministrativeUnitsStore.loadList();
    }

    @action async restoreCurrentWorkingTask() {
        const savedWorkingTask =
            await CurrentWorkingTaskStore.getSavedWorkingTask();
        if (savedWorkingTask) {
            const loadedTaskMetadata = await TasksStore.getTaskMetadata(
                savedWorkingTask.id,
            );

            await TasksStore.taskMetadataLoader.reset();

            if (loadedTaskMetadata) {
                CurrentWorkingTaskStore.setCurrentWorkingTask(
                    loadedTaskMetadata,
                );
            } else {
                CurrentWorkingTaskStore.unsetCurrentWorkingTask();
            }
        }
    }
}

export const AppStore = new AppStoreClass();
