import {
    OverdueDaysSettingForm,
    StatusInterval,
} from 'src/generated-api-client';
import { settingsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LoanOverdueDaysGapsStoreClass extends BasicStore<
    { [p: string]: StatusInterval },
    any,
    OverdueDaysSettingForm,
    any,
    any
> {
    api: BasicStoreApi<
        { [p: string]: StatusInterval },
        OverdueDaysSettingForm,
        any,
        any
    > = {
        loadItem: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                settingsApi.getOverdueDaysSetting(),
            );
        },

        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                settingsApi.editOverdueDaysSetting({
                    overdueDaysSettingForm: data,
                }),
            );
        },
    };
}

export const LoanOverdueDaysGapsStore = new LoanOverdueDaysGapsStoreClass();
