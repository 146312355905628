import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { IndividualDocumentsListCard } from 'src/pages/IndividualDetailsPage/Components/IndividualDocumentListCard';
import { IndividualOriginationCard } from 'src/pages/IndividualDetailsPage/Components/IndividualOriginationCard';
import { IndividualOwnedEstateCard } from 'src/pages/IndividualDetailsPage/Components/IndividualOwnedEstateCard';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export type IndividualOtherInformationTabProps = {
    viewModel: IndividualDetailsViewModelClass;
};

export const IndividualOtherInformationTab = observer(
    ({ viewModel }: IndividualOtherInformationTabProps) => {
        const showDocuments = AppUser.hasPermissions(
            UserPermission.DocumentCustomerRead,
        );
        useEffect(() => {
            viewModel.loadOtherInformation();

            return () => {
                viewModel.otherInformationLoader.reset();
            };
        }, []);

        return (
            <Row gutter={24}>
                <Col xs={24}>
                    <IndividualOriginationCard viewModel={viewModel} />
                </Col>
                <Col xs={24}>
                    <IndividualOwnedEstateCard viewModel={viewModel} />
                </Col>
                <Col xs={24}>
                    {showDocuments && (
                        <IndividualDocumentsListCard viewModel={viewModel} />
                    )}
                </Col>
            </Row>
        );
    },
);
