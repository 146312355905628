import { ColumnType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { CibLoanListEntry } from 'src/generated-api-client';
import { IndividualDetailsCibStore } from 'src/stores/IndividualDetailsCibStore/IndividualDetailsCibStore';
import { DateHelper } from 'src/utils/DateHelper';
import { NumberHelper } from 'src/utils/NumberHelper';

export type IndividualCibLoadDataTabProps = { individualId?: number };
export const IndividualCibLoadDataTab = observer(
    ({ individualId }: IndividualCibLoadDataTabProps) => {
        const store = IndividualDetailsCibStore.getInstance(
            individualId as number,
        );

        useEffect(() => {
            store.loadList();

            return () => {
                store.listLoader.reset();
            };
        }, [individualId]);

        const columns: ColumnType<CibLoanListEntry>[] = [
            {
                title: t('Page.IndividualDetails.Tabs.CibLoanData.ID'),
                key: 'id',
                dataIndex: 'id',
            },
            {
                title: t(
                    'Page.IndividualDetails.Tabs.CibLoanData.Classification',
                ),
                key: 'classification',
                dataIndex: 'classification',
            },
            {
                title: t('Page.IndividualDetails.Tabs.CibLoanData.Type'),
                key: 'type',
                dataIndex: 'type',
            },
            {
                title: t('Page.IndividualDetails.Tabs.CibLoanData.Currency'),
                key: 'currency',
                dataIndex: 'currency',
            },
            {
                title: t(
                    'Page.IndividualDetails.Tabs.CibLoanData.OriginatedAmount',
                ),
                key: 'originatedAmount',
                dataIndex: 'originatedAmount',
                render: (value) => {
                    return NumberHelper.formatToMNT(value);
                },
            },
            {
                title: t(
                    'Page.IndividualDetails.Tabs.CibLoanData.InterestRate',
                ),
                key: 'interestRate',
                dataIndex: 'interestRate',
            },
            {
                title: t(
                    'Page.IndividualDetails.Tabs.CibLoanData.OriginatedDate',
                ),
                key: 'originatedDate',
                dataIndex: 'originatedDate',
                render(value) {
                    return DateHelper.formatDate(value);
                },
            },
            {
                title: t(
                    'Page.IndividualDetails.Tabs.CibLoanData.MaturityDate',
                ),
                key: 'maturityDate',
                dataIndex: 'maturityDate',
                render(value) {
                    return DateHelper.formatDate(value);
                },
            },
            {
                title: t(
                    'Page.IndividualDetails.Tabs.CibLoanData.OutstandingBalance',
                ),
                key: 'outstandingBalance',
                dataIndex: 'outstandingBalance',
                render: (value) => {
                    return NumberHelper.formatToMNT(value);
                },
            },
            {
                title: t('Page.IndividualDetails.Tabs.CibLoanData.Status'),
                key: 'status',
                dataIndex: 'status',
            },
            {
                title: t(
                    'Page.IndividualDetails.Tabs.CibLoanData.CollateralSubType',
                ),
                key: 'collateralSubType',
                dataIndex: 'collateralSubType',
            },
            {
                title: t(
                    'Page.IndividualDetails.Tabs.CibLoanData.TotalMonthlyPayment',
                    'Calculated Monthly Payment',
                ),
                key: 'totalMonthlyPayment',
                dataIndex: 'totalMonthlyPayment',
                render: (value) => {
                    return <DataView value={value} format="money" hideLabel />;
                },
            },
        ];

        return (
            <ListView
                store={store}
                columns={columns}
                loading={store.listLoader.isLoading}
            />
        );
    },
);
