/* eslint-disable max-lines */
import { Col, Row, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { CustomerReference, DebtCollection } from 'src/generated-api-client';
import { CollectionContactInformationCard } from 'src/pages/CollectionDetailsPage/Components/CollectionContactInformationCard';
import { CollectionLoanSummaryInfoCard } from 'src/pages/CollectionDetailsPage/Components/CollectionLoanSummaryInfoCard';
import { CollectionReferenceCard } from 'src/pages/CollectionDetailsPage/Components/CollectionReferenceCard';
import styled from 'styled-components';

export type CollectionDetailsGeneralInfoProps = {
    collection?: DebtCollection;
};

export const GeneralInfoTab = observer(
    ({ collection }: CollectionDetailsGeneralInfoProps) => {
        const references = collection?.customer?.references || [
            {} as CustomerReference,
        ];

        return (
            <Row gutter={24}>
                <Col sm={12}>
                    <StyledSpace direction="vertical">
                        <CollectionLoanSummaryInfoCard
                            collection={collection}
                        />
                        <CollectionReferenceCard references={references} />
                    </StyledSpace>
                </Col>

                <Col sm={12}>
                    <StyledSpace direction="vertical">
                        <CollectionContactInformationCard
                            collection={collection}
                        />
                    </StyledSpace>
                </Col>
            </Row>
        );
    },
);

const StyledSpace = styled(Space)`
    display: flex;
`;
