import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { UrlHelper } from 'src/utils/UrlHelper';

export type LegalEntityOriginationCardProps = {
    viewModel: LegalEntityDetailsViewModelClass;
};

export const LegalEntityOriginationCard = observer(
    ({ viewModel }: LegalEntityOriginationCardProps) => {
        const { createdBy, updatedBy, createdAt, updatedAt } =
            viewModel?.otherInformation ?? {};

        const createdUserDetailsUrl = UrlHelper.getTargetUrl(
            RoutePaths.userDetails,
            {
                userId: createdBy?.id,
            },
        );

        const updatedUserDetailsUrl = UrlHelper.getTargetUrl(
            RoutePaths.userDetails,
            {
                userId: updatedBy?.id,
            },
        );

        return (
            <Card
                title={t(
                    'LegalEntity.OtherInformation.Origination.Title',
                    'Origination',
                )}
                isLoading={viewModel?.otherInformationLoader.isLoading}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={createdAt}
                            label={t(
                                'LegalEntity.OtherInformation.Origination.CreatedAt',
                                'Created Date',
                            )}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={createdBy?.name}
                            label={t(
                                'LegalEntity.OtherInformation.Origination.CreatedBy',
                                'Created User',
                            )}
                            format="link-internal"
                            formatterProps={{ to: createdUserDetailsUrl }}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={updatedAt}
                            label={t(
                                'LegalEntity.OtherInformation.Origination.UpdatedAt',
                                'Last Updated Date',
                            )}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={updatedBy?.name}
                            label={t(
                                'LegalEntity.OtherInformation.Origination.UpdatedBy',
                                'Last Updated User',
                            )}
                            format="link-internal"
                            formatterProps={{ to: updatedUserDetailsUrl }}
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
