import { Button, Form as AntForm, FormInstance } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';
import { FilterForm } from 'src/components/Form/FilterForm';
import { AssignIcon } from 'src/components/Icons/Icons';
import { CollectionsFilterForm } from 'src/pages/CollectionsPage/CollectionsFilterForm';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { DebtCollectionsStore } from 'src/stores/DebtCollectionsStore/DebtCollectionsStore';
import { DateHelper } from 'src/utils/DateHelper';

type HeaderPanelProps = {
    title: React.ReactNode;
    collectionListForm: FormInstance;
    isCollectionSelected: boolean;
};

export const CollectionsHeaderPanel = observer(
    ({ title, collectionListForm, isCollectionSelected }: HeaderPanelProps) => {
        const [form] = AntForm.useForm();

        const canReassign = AppUser.hasPermissions(
            UserPermission.CollectionReassign,
        );

        return (
            <div>
                <FilterForm
                    form={form}
                    store={DebtCollectionsStore}
                    title={title}
                    convertFilterCriteriaToFormValues={
                        convertFilterCriteriaToFormValues
                    }
                    convertFormValuesToFilterCriteria={
                        convertFormValuesToFilterCriteria
                    }
                    panelExtra={
                        canReassign && (
                            <Button
                                icon={<AssignIcon />}
                                type="primary"
                                disabled={!isCollectionSelected}
                                onClick={() => {
                                    collectionListForm.submit();
                                }}
                                data-test="reassign-collection"
                            >
                                {t('Page.CollectionsPage.Button.Assign')}
                            </Button>
                        )
                    }
                >
                    <CollectionsFilterForm form={form} />
                </FilterForm>
            </div>
        );
    },
);

const convertFilterCriteriaToFormValues = (values: any) => {
    return {
        ...values,
        nextActionDate: values.nextActionDate && moment(values.nextActionDate),
        lastActivityDate:
            values.lastActivityDate && moment(values.lastActivityDate),
    };
};
const convertFormValuesToFilterCriteria = (values: any) => {
    return {
        ...values,
        nextActionDate: DateHelper.formatISODate(values.nextActionDate),
        lastActivityDate: DateHelper.formatISODate(values.lastActivityDate),
    };
};
