import { Form, FormItemProps } from 'antd';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Input } from 'src/components/Inputs/Input';

export type TextFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    readOnly?: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    maxLength?: number;
    // eslint-disable-next-line react/require-default-props
    onlyNumbers?: boolean;
    // eslint-disable-next-line react/require-default-props
    inputProps?: any;
};

export const TextField = ({
    name,
    label,
    readOnly,
    disabled,
    maxLength,
    onlyNumbers,
    inputProps,
    rules,
    ...props
}: TextFieldProps) => {
    const itemRules = rules || [];

    return (
        <Form.Item
            {...props}
            name={name}
            label={label}
            rules={[ValidationRules.withoutStartEndSpaces, ...itemRules]}
        >
            <Input
                readOnly={readOnly}
                disabled={disabled}
                maxLength={maxLength}
                onlyNumbers={onlyNumbers}
                data-test={name}
                {...inputProps}
            />
        </Form.Item>
    );
};
