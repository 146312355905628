import { Form as AntForm, FormProps as AntFormProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

export type FormProps = AntFormProps;

export const Form = observer(({ form, ...props }: FormProps) => {
    const [innerForm] = AntForm.useForm();

    const targetForm = useMemo(() => {
        return form || innerForm;
    }, [form]);

    return <AntForm layout="vertical" form={targetForm} {...props} />;
});
