import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { HiddenField } from 'src/components/Form/HiddenField';
import { N15Task, TaskDecisionType } from 'src/generated-api-client';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { DmtUserItemList } from 'src/pages/TaskDetailsPage/N15ChooseDmtUsersTask/Components/DmtUserItemList';
import { TaskVariablesProps } from 'src/pages/TaskViewPage/TaskTypes';
import styled from 'styled-components';

export const N15ChooseDmtUsersTaskApproveVariables = observer(
    ({ task }: TaskVariablesProps<N15Task>) => {
        const { loanApplication, collaterals } = task;

        return (
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <StyledText>
                        <Trans i18nKey="Page.N15ChooseDmtUsersTask.Descriptions" />
                    </StyledText>
                </Col>
                <Col xs={24}>
                    <TaskLinkView
                        loanApplication={loanApplication}
                        collaterals={collaterals}
                    />
                </Col>

                <Col span={24}>
                    <Card>
                        <Col xs={24}>
                            <HiddenField
                                name="type"
                                initialValue={
                                    TaskDecisionType.N1_5_CHOOSE_DMT_USERS
                                }
                            />
                        </Col>

                        <Col span={12}>
                            <DmtUserItemList task={task} />
                        </Col>
                    </Card>
                </Col>
            </Row>
        );
    },
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
