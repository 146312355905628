import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { PayonProduct } from 'src/generated-api-client';
import { ProductsStatus } from 'src/pages/LoanProducts/ProductsStatus';

export type PayonDetailsBriefProps = { payon?: PayonProduct };

export const PayonProductDetailsBrief = observer(
    ({ payon }: PayonDetailsBriefProps) => {
        return (
            <Brief>
                <Row gutter={16}>
                    <Col span={6}>
                        <DataView
                            value={payon?.name}
                            label={t('Page.PayonProductDetails.Name')}
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={payon?.maxLoanAmount}
                            label={t('Page.PayonProductDetails.MaxLoanAmount')}
                            format="money"
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={payon?.penaltyInterestMultiplier}
                            label={t(
                                'Page.PayonProductDetails.PenaltyInterestMultiplier',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={payon?.status}
                            label={t('Page.PayonProductDetails.Status')}
                            format={(value) => (
                                <ProductsStatus status={value} />
                            )}
                        />
                    </Col>
                </Row>
            </Brief>
        );
    },
);
