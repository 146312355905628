import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { TaskDecisionType } from 'src/generated-api-client';
import { FormHelper } from 'src/utils/FormHelper';

export type N23AddBusinessIncomeTaskVariablesProps = {
    type?:
        | typeof TaskDecisionType.N2_3_APPROVE
        | typeof TaskDecisionType.N2_3_DECLINE
        | undefined;
};
export const N23AddBusinessIncomeTaskVariables = observer(
    ({ type }: N23AddBusinessIncomeTaskVariablesProps) => {
        const yesNoOptions = FormHelper.objAsOptions(
            {
                true: true,
                false: false,
            },
            'Primitive.Boolean.YesNo.',
        );

        const taskSubmitType = FormHelper.objAsOptions(
            {
                [TaskDecisionType.N2_3_APPROVE]: TaskDecisionType.N2_3_APPROVE,
                [TaskDecisionType.N2_3_DECLINE]: TaskDecisionType.N2_3_DECLINE,
            },
            'TaskDecisionType.',
        );

        return (
            <Row gutter={[16, 16]}>
                <Col xs={8}>
                    <TextField
                        name="bankCode"
                        label={t(
                            'Task.Form.N23AddBusinessIncomeTask.BankCode',
                            'Bank Code',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={8}>
                    <TextField
                        name="bankAccount"
                        label={t(
                            'Task.Form.N23AddBusinessIncomeTask.BankAccount',
                            'Bank Account',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={8}>
                    <EnumSelectField
                        name="currency"
                        label={t(
                            'Task.Form.N23AddBusinessIncomeTask.Currency',
                            'Currency',
                        )}
                        rules={[ValidationRules.required]}
                        enumName="CURRENCY"
                    />
                </Col>
                <Col xs={8}>
                    <NumberField
                        name="workingMonthsForLast2Years"
                        label={t(
                            'Task.Form.N23AddBusinessIncomeTask.WorkingMonths',
                            'Working Months For The Last 2 Years',
                        )}
                        onlyInteger
                        rules={[
                            ValidationRules.required,
                            ValidationRules.numberRange(1, 24),
                        ]}
                    />
                </Col>
                <Col xs={8}>
                    <NumberField
                        name="monthlyIncome"
                        label={t(
                            'Task.Form.N23AddBusinessIncomeTask.MonthlyIncome',
                            'Monthly Income',
                        )}
                        thousandDivider
                        rules={[
                            ValidationRules.required,
                            ValidationRules.greaterThanZero,
                        ]}
                    />
                </Col>
                <Col xs={8}>
                    <NumberField
                        name="continuousEmploymentInMonths"
                        label={t(
                            'Task.Form.N23AddBusinessIncomeTask.ContinuousEmploymentInMonths',
                            'Continuous Employment In Months',
                        )}
                        onlyInteger
                        rules={[
                            ValidationRules.required,
                            ValidationRules.greaterThanZero,
                        ]}
                    />
                </Col>
                <Col xs={8}>
                    <SelectField
                        name="incomeAmountEnough"
                        label={t(
                            'Task.Form.N23AddBusinessIncomeTask.IsIncomeAmountEnough',
                            'Is Income Amount Enough?',
                        )}
                        options={yesNoOptions}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={8}>
                    <SelectField
                        name="incomeAmountStable"
                        label={t(
                            'Task.Form.N23AddBusinessIncomeTask.IsIncomeAmountStable',
                            'Is Income Amount Stable?',
                        )}
                        options={yesNoOptions}
                        rules={[ValidationRules.required]}
                    />
                </Col>

                <Col xs={8}>
                    <SelectField
                        name="incomeAmountEnoughForLast2Months"
                        label={t(
                            'Task.Form.N23AddBusinessIncomeTask.IsIncomeAmountEnoughForLast2Months',
                            'Is Income Amount Enough For The Last 2 Months?',
                        )}
                        options={yesNoOptions}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={8}>
                    <SelectField
                        name="dropAvgLast2Months"
                        label={t(
                            'Task.Form.N23AddBusinessIncomeTask.IsDropAvgLast2Months',
                            'Is Drop Avg Last Two Months?',
                        )}
                        options={yesNoOptions}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={8}>
                    <SelectField
                        name="type"
                        label={t(
                            'Task.Form.N23AddBusinessIncomeTask.TaskActionSelect',
                        )}
                        options={taskSubmitType}
                        rules={[ValidationRules.required]}
                    />
                </Col>

                <Col xs={8}>
                    <TextAreaField
                        name="comment"
                        label={t(
                            'Dialog.Reject.N23AddBusinessIncomeTask.Comment',
                            'Comment',
                        )}
                        rules={
                            type === TaskDecisionType.N2_3_DECLINE
                                ? [ValidationRules.required]
                                : undefined
                        }
                    />
                </Col>
            </Row>
        );
    },
);
