import { notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { NewCustomerGroup } from 'src/generated-api-client';
import { CustomerGroupsStore } from 'src/stores/CustomerGroupsStore/CustomerGroupsStore';
import { ProductLinesStore } from 'src/stores/ProductLinesStore/ProductLinesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const store = CustomerGroupsStore;

export const CreateCustomerGroupDialogFn = observer(
    ({ ...props }: ModalRendererProps) => {
        useEffect(() => {
            ProductLinesStore.loadList();

            return () => {
                store.createItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.createItemLoader.errorData,
        );

        const loadingInfo = useMemo(() => {
            return new CombinedLoadingStatus([
                store.createItemLoader,
                ProductLinesStore.listLoader,
            ]);
        }, []);

        return (
            <Modal
                title={t('Dialog.CreateCustomerGroup.Title')}
                isLoading={loadingInfo.isLoading}
                onFinish={onFinish}
                formProps={{ fields }}
                {...props}
            >
                <TextField
                    name="name"
                    label={t('Dialog.CreateCustomerGroup.GroupName')}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.maxFieldLength(100),
                    ]}
                />
                <NumberField
                    name="priority"
                    label={t('Dialog.CreateCustomerGroup.Priority')}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.greaterThanZero,
                        ValidationRules.numberMaxValue(9999),
                    ]}
                />
                <SelectField
                    name="productLineIds"
                    label={t('Dialog.CreateCustomerGroup.Products')}
                    rules={[ValidationRules.required]}
                    mode="multiple"
                    options={ProductLinesStore.productLinesOptions}
                />
            </Modal>
        );
    },
);

const onFinish = async (values: NewCustomerGroup) => {
    await store.create(values);

    if (store?.createItemLoader.hasError) {
        return Promise.reject();
    }
    if (!store.createItemLoader.hasError) {
        store.loadList();
        notification.success({
            message: t('Dialog.CreateCustomerGroup.Message.Success'),
        });
    }
};

export const CreateCustomerGroupDialog = addModalRenderer(
    CreateCustomerGroupDialogFn,
);
