import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { Modal } from 'src/components/Modal/Modal';
import {
    LandInformation,
    RealEstateCollateral,
    RealEstateLandCollateralPatch,
} from 'src/generated-api-client';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { DateHelper } from 'src/utils/DateHelper';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type UpdateRealEstateCollateralLandInfoDialogFnProps = ModalRendererProps<{
    collateralId: string;
}>;

const store = CollateralsStore;

export const UpdateRealEstateCollateralLandInfoDialogFn = observer(
    ({
        collateralId,
        ...props
    }: UpdateRealEstateCollateralLandInfoDialogFnProps) => {
        useEffect(() => {
            return () => store.updateItemLoader.reset();
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );
        const initialValues = getInitialValues(
            (store.currentItem as RealEstateCollateral).landInformation,
        );

        return (
            <Modal
                title={t('Dialog.UpdateRealEstateCollateralLandInfo.Title')}
                isLoading={store.updateItemLoader.isLoading}
                onFinish={async (values) => {
                    const submitValues =
                        convertFormValuesToSubmitValues(values);

                    await store.update(collateralId, submitValues);
                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }
                    if (!store.updateItemLoader.hasError) {
                        store.loadItem(collateralId);
                    }
                }}
                cancelText={t('Dialog.UpdateRealEstateCollateralLandInfo.Back')}
                initialValues={initialValues}
                formProps={{ fields }}
                width={1200}
                {...props}
            >
                <Row gutter={16}>
                    <HiddenField name="type" initialValue="REAL_ESTATE_LAND" />
                    <Col xs={8}>
                        <TextField
                            name="registrationNumber"
                            label={t(
                                'Dialog.UpdateRealEstateCollateralLandInfo.RegistrationNumber',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="certificateNumber"
                            label={t(
                                'Dialog.UpdateRealEstateCollateralLandInfo.CertificateNumber',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <EnumSelectField
                            name="ownershipType"
                            enumName="LAND_OWNERSHIP_TYPE"
                            label={t(
                                'Dialog.UpdateRealEstateCollateralLandInfo.OwnershipType',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <DatePickerField
                            name="certificateStartDate"
                            label={t(
                                'Dialog.UpdateRealEstateCollateralLandInfo.CertificateStartDate',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <DatePickerField
                            name="certificateEndDate"
                            label={t(
                                'Dialog.UpdateRealEstateCollateralLandInfo.CertificateEndDate',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <EnumSelectField
                            name="useOfLand"
                            enumName="USE_OF_LAND"
                            label={t(
                                'Dialog.UpdateRealEstateCollateralLandInfo.UseOfLand',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <NumberField
                            name="landSquare"
                            label={t(
                                'Dialog.UpdateRealEstateCollateralLandInfo.LandSquare',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="cadastralNumber"
                            label={t(
                                'Dialog.UpdateRealEstateCollateralLandInfo.CadastralNumber',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <EnumSelectField
                            name="collateralOwnershipType"
                            enumName="COLLATERAL_OWNERSHIP_TYPE"
                            label={t(
                                'Dialog.UpdateRealEstateCollateralLandInfo.CollateralOwnershipType',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <EnumSelectField
                            name="coOwnerCount"
                            enumName="CO_OWNER_COUNT"
                            label={t(
                                'Dialog.UpdateRealEstateCollateralLandInfo.CoOwnerCount',
                            )}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const UpdateRealEstateCollateralLandInfoDialog = addModalRenderer(
    UpdateRealEstateCollateralLandInfoDialogFn,
);

const getInitialValues = (values?: LandInformation) => {
    return {
        ...values,
        certificateStartDate:
            values?.certificateStartDate && moment(values.certificateStartDate),
        certificateEndDate:
            values?.certificateEndDate && moment(values.certificateEndDate),
    };
};

const convertFormValuesToSubmitValues = (values?: LandInformation) => {
    return {
        ...values,
        certificateStartDate: DateHelper.formatISODate(
            values?.certificateStartDate,
        ),
        certificateEndDate: DateHelper.formatISODate(
            values?.certificateEndDate,
        ),
    } as RealEstateLandCollateralPatch;
};
