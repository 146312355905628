import { t } from 'i18next';
import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProposeNewGracePeriodActionStoreClass extends BasicActionStore<number> {
    constructor(private loanId: number) {
        super();
    }

    okMessage = t('Dialog.Restructuring.Message.SuccessCreate');

    api: BasicActionStoreApi<number> = {
        ok: async () => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.proposeNewGracePeriodAllowing({ id: this.loanId }),
            );
        },
    };
}

export const ProposeNewGracePeriodActionStore = new EntityProvider(
    ProposeNewGracePeriodActionStoreClass,
);
