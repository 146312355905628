import { observer } from 'mobx-react-lite';
import { Comments } from 'src/components/Comments/Comments';

export type IndividualComentsTabProps = {
    roomId: number;
};
export const IndividualCommentsTab = observer(
    ({ roomId }: IndividualComentsTabProps) => {
        return <Comments roomId={roomId} />;
    },
);
