import { action, observable } from 'mobx';
import { TaskMetadata } from 'src/generated-api-client';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';

const STORAGE_WORKING_TASK_DATA_KEY = 'CURRENT_WORKING_TASK';

export class CurrentWorkingTaskStoreClass {
    @observable workingTaskMetadata?: TaskMetadata;

    @action async setCurrentWorkingTask(taskMetadata: any) {
        await LocalStoreHelper.setItem(
            STORAGE_WORKING_TASK_DATA_KEY,
            taskMetadata,
        );
        this.workingTaskMetadata = await LocalStoreHelper.getItem(
            STORAGE_WORKING_TASK_DATA_KEY,
        );
    }

    @action async unsetCurrentWorkingTask() {
        await this.setCurrentWorkingTask(null);
    }

    @action getSavedWorkingTask() {
        return LocalStoreHelper.getItem(STORAGE_WORKING_TASK_DATA_KEY) as
            | TaskMetadata
            | undefined;
    }
}

export const CurrentWorkingTaskStore = new CurrentWorkingTaskStoreClass();
