import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { InstallmentLoan } from 'src/generated-api-client';
import { InstallmentLoanDetailsBrief } from 'src/pages/LoanDetailsPage/InstallmentLoan/Components/InstallmentLoanDetailsBrief';
import { CoBorrowersTab } from 'src/pages/LoanDetailsPage/InstallmentLoan/Tabs/CoBorrowersTab';
import { CollateralsTab } from 'src/pages/LoanDetailsPage/InstallmentLoan/Tabs/CollateralsTab';
import { FinancialOperationsTab } from 'src/pages/LoanDetailsPage/InstallmentLoan/Tabs/FinancialOperationsTab';
import { GeneralInfoTab } from 'src/pages/LoanDetailsPage/InstallmentLoan/Tabs/GeneralInfoTab';
import { PaymentScheduleTab } from 'src/pages/LoanDetailsPage/InstallmentLoan/Tabs/PaymentScheduleTab';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';

const store = LoansStore;

export const InstallmentLoanDetails = observer(() => {
    const loan = store.currentItem as InstallmentLoan;

    const tabs: TabDefinition[] = [
        {
            title: t('Page.InstallmentLoanDetails.Tabs.GeneralInfo'),
            tabKey: 'general-info',
            Component: <GeneralInfoTab loan={loan} />,
        },
        {
            title: t('Page.InstallmentLoanDetails.Tabs.CoBorrowers'),
            tabKey: 'co-borrowers',
            Component: <CoBorrowersTab loan={loan} />,
        },
        {
            title: t('Page.InstallmentLoanDetails.Tabs.Collaterals'),
            tabKey: 'collaterals',
            Component: <CollateralsTab loan={loan} />,
        },
        {
            title: t('Page.InstallmentLoanDetails.Tabs.PaymentSchedule'),
            tabKey: 'payment-schedule',
            Component: <PaymentScheduleTab loan={loan} />,
            visible: AppUser.hasPermissions(
                UserPermission.LoanReadPaymentSchedule,
            ),
        },
        {
            title: t('Page.InstallmentLoanDetails.Tabs.Documents'),
            tabKey: 'documents',
            Component: (
                <DocumentsList
                    documentRoom={loan?.documentRoom}
                    canUpload={AppUser.hasPermissions(
                        UserPermission.DocumentLoanUpload,
                    )}
                />
            ),
            visible: AppUser.hasPermissions(UserPermission.DocumentLoanRead),
        },
        {
            title: t('Page.InstallmentLoanDetails.Tabs.FinancialOperations'),
            tabKey: 'financial-operations',
            Component: <FinancialOperationsTab loan={loan} />,
            visible: AppUser.hasPermissions(
                UserPermission.LoanReadOperationAuditEvents,
            ),
        },
    ];

    return (
        <Row gutter={[16, 32]}>
            <Col xs={24}>
                <InstallmentLoanDetailsBrief loan={loan} />
            </Col>
            <Col xs={24}>
                <Tabs tabs={tabs} />
            </Col>
        </Row>
    );
});
