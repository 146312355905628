import { action, computed } from 'mobx';
import { CalculateLoanStateRequest } from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LoanStateStoreClass {
    constructor(private loanId: number) {}

    loanStateLoader = new AsyncOperationWithStatus(
        (data: CalculateLoanStateRequest) =>
            RequestHelper.unwrapFromAxiosPromise(
                loansApi.calculateLoanState({ id: this.loanId, request: data }),
            ),
    );

    @action async calculateLoanState(data: CalculateLoanStateRequest) {
        await this.loanStateLoader.call(data);
    }

    @computed get loanState() {
        return this.loanStateLoader.data;
    }
}

export const LoanStateStore = new EntityProvider(LoanStateStoreClass);
