import { observer } from 'mobx-react-lite';
import { MouseEvent, useEffect } from 'react';
import { BasePage, BasePageProps } from 'src/components/BasePage/BasePage';
import { ListView, ListViewProps } from 'src/components/ListView/ListView';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { Entity } from 'src/utils/mobx/BasicStore/BasicStore.types';

export type ListPageProps<T extends Entity> = {
    store: BasicStore<T>;
    columns: ListViewProps<T>['columns'];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onRowClick?: (e: MouseEvent, record: T) => void;
    listViewProps?: Omit<ListViewProps<T>, 'store'>;
    isLoading?: boolean;
} & BasePageProps;

export const ListPage = observer(
    ({
        store,
        columns,
        onRowClick,
        listViewProps,
        isLoading,
        ...props
    }: ListPageProps<any>) => {
        useEffect(() => {
            store.loadList();

            return () => {
                store.listLoader.reset();
                store.filterCriteria?.resetFilter();
                store.sorter?.reset();
            };
        }, []);

        return (
            <BasePage
                {...props}
                isLoading={isLoading || store.listLoader.isLoading}
            >
                <ListView
                    {...listViewProps}
                    store={store}
                    columns={columns}
                    onRowClick={onRowClick}
                />
            </BasePage>
        );
    },
);
