import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FilterForm } from 'src/components/Form/FilterForm';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

type IndividualFilterFormProps = {
    store: BasicStore<any>;
};
export const IndividualCoBorrowersListFilterForm = observer(
    ({ store }: IndividualFilterFormProps) => {
        return (
            <FilterForm store={store}>
                <NumberField
                    name="id"
                    label={t(
                        'Dialog.CreateCoBorrower.Individual.Filter.CoborrowerId',
                    )}
                    onlyInteger
                />
                <TextField
                    name="customerName"
                    label={t(
                        'Dialog.CreateCoBorrower.Individual.Filter.CoborrowerName',
                    )}
                />
                <TextField
                    name="registrationNumber"
                    label={t(
                        'Dialog.CreateCoBorrower.Individual.Filter.RegistrationNumber',
                    )}
                />
                <TextField
                    name="phone"
                    label={t(
                        'Dialog.CreateCoBorrower.Individual.Filter.PhoneNumber',
                    )}
                />
                <EnumSelectField
                    mode="multiple"
                    name="kycLevel"
                    label={t(
                        'Dialog.CreateCoBorrower.Individual.Filter.KycLevel',
                    )}
                    enumName="CUSTOMER_KYC_LEVEL"
                />
                <EnumSelectField
                    mode="multiple"
                    name="verificationStatuses"
                    label={t(
                        'Dialog.CreateCoBorrower.Individual.Filter.VerificationStatuses',
                    )}
                    enumName="CUSTOMER_VERIFICATION_STATUS"
                />
            </FilterForm>
        );
    },
);
