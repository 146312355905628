import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { AppAuthStoreClass } from 'src/stores/AppAuthStore/AppAuthStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { LocalAuthStoreClass } from 'src/stores/AuthStore/LocalAuthStore';
import { ErrorDataHelper } from 'src/utils/ErrorDataHelper';
import styled from 'styled-components';

const authStore = (AuthStore as AppAuthStoreClass)
    .authStoreImplementation as LocalAuthStoreClass;

export const LoginPageErrorMessage = observer(() => {
    const { hasError, errorData } = authStore.tokenByLoginPasswordLoader;

    if (!hasError) {
        return null;
    }

    if (ErrorDataHelper.isBaseError(errorData)) {
        return <StyledError>{errorData.detail}</StyledError>;
    }

    return (
        <StyledError>
            {t('Page.Auth.Login.ErrorMessage.GeneralErrorMessage')}
        </StyledError>
    );
});

const StyledError = styled.p`
    margin-top: 10px;
    color: #ff4d4f;
`;
