import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import i18next from 'i18next';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';

axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
    return {
        ...config,
        headers: {
            ...config.headers,
            ...(await getAuthHeader()),
            'Accept-Language': i18next.language,
        },
    };
});

axios.interceptors.response.use(async (response: AxiosResponse) => {
    if (response.status === 401) {
        await AuthStore.authenticate();

        return response;
    }

    return response;
});

// eslint-disable-next-line require-await
const getAuthHeader = async () => {
    // await AuthStore.refreshTokenIfNeed?.();

    if (AuthStore.token) {
        return {
            Authorization: `bearer ${AuthStore.token}`,
        };
    }

    return {};
};
