import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DotsHorizontalIcon } from 'src/components/Icons/Icons';
import {
    N13IndividualCoBorrower,
    N13LegalCoBorrower,
} from 'src/generated-api-client';
import { getCoBorrowerActions } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/CoBorrowerActions.config';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import styled from 'styled-components';

type CoBorrowerActionsProps = {
    coBorrower: N13IndividualCoBorrower | N13LegalCoBorrower;
    viewModel: N13TaskViewModelClass;
};

export const CoBorrowerActions = observer(
    ({ coBorrower, viewModel }: CoBorrowerActionsProps) => {
        const coBorrowerActions = getCoBorrowerActions(coBorrower, viewModel);

        return (
            <Dropdown
                items={coBorrowerActions}
                disabled={!coBorrowerActions.length}
            >
                <StyledIconButton icon={<DotsHorizontalIcon />} />
            </Dropdown>
        );
    },
);

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;
