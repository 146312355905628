import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FormItemGroup } from 'src/components/Form/FormItemGroup/FormItemGroup';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { CollateralDetailsType } from 'src/generated-api-client';
import { AddressFields } from 'src/pages/IndividualDetailsPage/Components/AddressFields';
import { FormHelper } from 'src/utils/FormHelper';

export const CreateRealEstateCollateralGeneralInfoVariables = observer(() => {
    return (
        <Card
            title={t('Page.CollateralCreatePage.RealEstate.GeneralInfo.Title')}
        >
            <Row gutter={16}>
                <HiddenField
                    name="type"
                    value={CollateralDetailsType.REAL_ESTATE}
                />
                <Col xs={8}>
                    <SelectField
                        name="isPledged"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.GeneralInfo.IsPledged',
                        )}
                        options={FormHelper.objAsOptions(
                            {
                                true: true,
                                false: false,
                            },
                            'Primitive.Boolean.',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <TextField
                        name="governmentCertificateNumber"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.GeneralInfo.GovernmentCertificateNumber',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <TextField
                        name="registrationNumber"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.GeneralInfo.RegistrationNumber',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={8}>
                    <TextField
                        name="certificateNumber"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.GeneralInfo.CertificateNumber',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={8}>
                    <TextField
                        name="governanceName"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.GeneralInfo.GovernanceName',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <DatePickerField
                        name="registeredDate"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.GeneralInfo.RegisteredDate',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <DatePickerField
                        name="establishedDate"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.GeneralInfo.EstablishedDate',
                        )}
                    />
                </Col>
                <Col xs={24}>
                    <FormItemGroup name="address">
                        <AddressFields hideLatitudeLongitude />
                    </FormItemGroup>
                </Col>
                <Col xs={8}>
                    <NumberField
                        name="areaSquare"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.GeneralInfo.AreaSquare',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <EnumSelectField
                        name="estatePurpose"
                        enumName="ESTATE_PURPOSE"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.GeneralInfo.EstatePurpose',
                        )}
                    />
                </Col>
                <Col xs={8}>
                    <EnumSelectField
                        name="numberOfRooms"
                        enumName="NUMBER_OF_ROOMS"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.GeneralInfo.NumberOfRooms',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <EnumSelectField
                        name="ownershipType"
                        enumName="COLLATERAL_OWNERSHIP_TYPE"
                        label={t(
                            'Page.CollateralCreatePage.RealEstate.GeneralInfo.OwnershipType',
                        )}
                    />
                </Col>
            </Row>
        </Card>
    );
});
