import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';

export const IndividualsFilterForm = observer(() => {
    return (
        <>
            <NumberField
                name="id"
                label={t('Page.Individuals.Filter.CustomerId')}
                onlyInteger
            />
            <TextField
                name="customerName"
                label={t('Page.Individuals.Filter.IndividualName')}
            />
            <TextField
                name="registrationNumber"
                label={t('Page.Individuals.Filter.RegistrationNumber')}
            />
            <TextField
                name="phone"
                label={t('Page.Individuals.Filter.PhoneNumber')}
            />
            <NumberField
                name="relationshipManagerId"
                label={t('Page.Individuals.Filter.RelationshipManagerId')}
                onlyInteger
            />
            <TextField
                name="branchCode"
                label={t('Page.Individuals.Filter.BranchCode')}
            />
            <EnumSelectField
                mode="multiple"
                name="verificationStatuses"
                label={t('Page.Individuals.Filter.VerificationStatuses')}
                enumName="CUSTOMER_VERIFICATION_STATUS"
            />
            <TextField
                name="civilId"
                label={t('Page.Individuals.Filter.CivilId')}
            />
        </>
    );
});
