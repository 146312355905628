import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ActionType } from 'src/generated-api-client';
import { IncomeCard } from 'src/pages/IndividualDetailsPage/Components/IncomeCards/IncomeCard';
import { BusinessIncomeDetailsDialog } from 'src/pages/IndividualDetailsPage/Dialogs/BusinessIncomeDetailsDialog';
import { BusinessIncomeHistoryDialog } from 'src/pages/IndividualDetailsPage/Dialogs/BusinessIncomeHistoryDialog';
import {
    IndividualIncomeTypesViewModel,
    IndividualIncomeTypesViewModelClass,
} from 'src/pages/IndividualDetailsPage/Tabs/IndividualIncomeTypesViewModel';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export type BusinessIncomeCardProps = {
    individualId?: number;
};

export const BusinessIncomeCard = observer(
    ({ individualId }: BusinessIncomeCardProps) => {
        const viewModel = IndividualIncomeTypesViewModel.getInstance(
            individualId as number,
        );

        const actions = getBusinessIncomeActiveActions(viewModel);

        return (
            <IncomeCard
                title={t(
                    'Page.IndividualDetails.Tabs.IncomeTypes.BusinessIncome.Title',
                )}
                incomeLoader={viewModel.incomeTypesBriefLoader}
                briefData={viewModel.businessIncomeBrief}
                menuActions={actions}
            />
        );
    },
);

const getBusinessIncomeActiveActions = (
    viewModel?: IndividualIncomeTypesViewModelClass,
) => {
    return getBusinessIncomeActions(viewModel).filter(
        (action) => !action.disabled,
    );
};

const getBusinessIncomeActions = (
    viewModel?: IndividualIncomeTypesViewModelClass,
) => {
    return [...getUIActions(), ...getServerActions(viewModel)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    viewModel?: IndividualIncomeTypesViewModelClass,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(viewModel),
        viewModel?.businessIncomeActions,
    );
};

const getRegisteredServerActions = (
    viewModel?: IndividualIncomeTypesViewModelClass,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.VIEW_HISTORY,
            label: t('IncomeTypes.BusinessIncome.Actions.History', 'History'),
            onClick: () => {
                BusinessIncomeHistoryDialog.render({
                    individualId: viewModel?.individualId,
                });
            },
        },
        {
            key: ActionType.VIEW_DETAILS,
            label: t('IncomeTypes.BusinessIncome.Actions.Details', 'Details'),
            onClick: () => {
                BusinessIncomeDetailsDialog.render({
                    individualId: viewModel?.individualId,
                });
            },
        },
    ];
};
