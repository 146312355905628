import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { TextField } from 'src/components/Form/TextField';

export const RestrictionsListFilterForm = observer(() => {
    return (
        <>
            <TextField
                name="firstName"
                label={t('Page.RestrictionsList.Filter.CustomerFirstName')}
            />
            <TextField
                name="lastName"
                label={t('Page.RestrictionsList.Filter.CustomerLastName')}
            />
            <TextField
                name="companyName"
                label={t('Page.RestrictionsList.Filter.CompanyName')}
            />
            <EnumSelectField
                mode="multiple"
                name="listType"
                label={t('Page.RestrictionsList.Filter.ListType')}
                enumName="RESTRICTION_LIST_TYPE"
            />
            <EnumSelectField
                mode="multiple"
                name="cause"
                label={t('Page.RestrictionsList.Filter.RestrictedCause')}
                enumName="RESTRICTION_CAUSE"
            />
            <TextField
                name="registrationNumber"
                label={t('Page.RestrictionsList.Filter.RegistrationNumber')}
            />
        </>
    );
});
