import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';

export type BriefProps = { extra?: React.ReactNode };
export const Brief: React.FC<BriefProps> = observer(({ children, extra }) => {
    return (
        <StyledBrief>
            <ContentWrapper>{children}</ContentWrapper>
            {extra ? <ExtraWrapper>{extra}</ExtraWrapper> : null}
        </StyledBrief>
    );
});

const ContentWrapper = styled.div.attrs((props) => ({
    ...props,
    className: classNames(props.className, 'brief-content'),
}))`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    > .ant-row {
        width: 100%;
    }
`;

const ExtraWrapper = styled.div.attrs((props) => ({
    ...props,
    className: classNames(props.className, 'brief-extra'),
}))`
    margin-left: 30px;
    position: relative;
`;

const StyledBrief = styled.div`
    border-bottom: 1px solid #dfe0eb;
    padding: 30px 0;
    display: flex;
    width: 100%;
    position: relative;

    .brief-content {
        .app-data-view {
            .app-data-view__label {
                font-weight: 500;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.5px;
                color: #82869b;
            }

            .app-data-view__value {
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                letter-spacing: 0.5px;
                color: #4d6082;

                a {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 32px;
                }
            }
        }
    }
`;
