import { t } from 'i18next';
import { ActionType } from 'src/generated-api-client';
import { EditCollateralEvaluationDialog } from 'src/pages/CollateralDetailsPage/Components/CollateralEvaluation/Dialogs/EditCollateralEvaluationDialog';
import { Collateral } from 'src/utils/CollateralsHelper';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getCollateralEvaluationActions = (collateral?: Collateral) => {
    return [...getUIActions(collateral), ...getServerActions(collateral)];
};

const getUIActions = (_collateral?: Collateral): ActionDefinition[] => {
    return [];
};

const getServerActions = (collateral?: Collateral): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(collateral),
        collateral?.evaluation?.actions,
    );
};

const getRegisteredServerActions = (
    collateral?: Collateral,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.EVALUATION_UPDATE,
            label: t('Collateral.Actions.EditEvaluation'),
            onClick: () => {
                EditCollateralEvaluationDialog.render({
                    collateral,
                });
            },
        },
    ];
};
