/* eslint-disable max-lines */
import { action, computed } from 'mobx';
import {
    CollectionActivityGeneralType,
    CollectionActivityStatus,
    DebtCollectionActivity,
    DebtCollectionActivityListEntry,
    NewDebtCollectionActivity,
} from 'src/generated-api-client';
import { debtCollectionApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type CollectionActivityFilter = {
    createdAtFrom?: string;
    createdAtTo?: string;
    generalTypes?: Array<CollectionActivityGeneralType>;
    statuses?: Array<CollectionActivityStatus>;
    civilServantInvolved?: boolean;
};

const CollectionActivitiesFilterKeys: Array<keyof CollectionActivityFilter> = [
    'createdAtFrom',
    'createdAtTo',
    'generalTypes',
    'statuses',
    'civilServantInvolved',
];

export class DebtCollectionActivitiesStoreClass extends BasicStore<
    any,
    CollectionActivityFilter,
    any,
    NewDebtCollectionActivity,
    DebtCollectionActivity
> {
    constructor(private collectionId: string) {
        super();
    }

    api: BasicStoreApi<
        DebtCollectionActivityListEntry,
        any,
        NewDebtCollectionActivity,
        DebtCollectionActivity
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                debtCollectionApi.getDebtCollectionActivities({
                    id: Number(this.collectionId),
                    ...this.listParams,
                }),
            );
        },
        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                debtCollectionApi.getDebtCollectionActivity({
                    id: Number(this.collectionId),
                    activityId: id,
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<CollectionActivityFilter>(
        CollectionActivitiesFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<DebtCollectionActivityListEntry>(
        'ordinalNumber',
        'descend',
    );

    createDebtCollectionActivityLoader = new AsyncOperationWithStatus(
        (id: number, newDebtCollectionActivity: NewDebtCollectionActivity) => {
            return RequestHelper.unwrapFromAxiosPromise(
                debtCollectionApi.createDebtCollectionActivity({
                    id,
                    newDebtCollectionActivity,
                }),
            );
        },
    );

    @action async createDebtCollectionActivity(
        newDebtCollectionActivity: NewDebtCollectionActivity,
    ) {
        await this.createDebtCollectionActivityLoader.call(
            Number(this.collectionId),
            newDebtCollectionActivity,
        );
    }

    @computed get createDebtCollectionActivityResult() {
        return this.createDebtCollectionActivityLoader.data;
    }

    @computed get instanceCollectionId() {
        return this.collectionId;
    }
}

export const DebtCollectionActivitesStore = new EntityProvider(
    DebtCollectionActivitiesStoreClass,
);
