/* eslint-disable max-lines */
import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    N110Task,
    N110TaskDecisionAddLoanConditions,
    TaskDecisionType,
} from 'src/generated-api-client';
import { TaskCommentsView } from 'src/pages/TaskDetailsPage/Components/TaskCommentsView';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { SubmitTaskDialog } from 'src/pages/TaskDetailsPage/Dialogs/SubmitTaskDialog';
import { CreditCommitteeConditions } from 'src/pages/TaskDetailsPage/N110TaskApproveConditions/Components/CreditCommitteeConditions';
import { LaatUserConditions } from 'src/pages/TaskDetailsPage/N110TaskApproveConditions/Components/LaatUserConditions';
import { RequestedCondition } from 'src/pages/TaskDetailsPage/N110TaskApproveConditions/Components/RequestedCondition';
import { N110TaskApproveConditionsApproveVariables } from 'src/pages/TaskDetailsPage/N110TaskApproveConditions/N110TaskApproveConditionsApproveVariables';
import { N110TaskApproveConditionsDeclineVariables } from 'src/pages/TaskDetailsPage/N110TaskApproveConditions/N110TaskApproveConditionsDeclineVariables';
import { N110TaskApproveInvoiceVariables } from 'src/pages/TaskDetailsPage/N110TaskApproveConditions/N110TaskApproveInvoiceVariables';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

export const N110TaskApproveConditions = observer(
    ({ viewModel }: TaskProps<N110Task>) => {
        const task = viewModel.currentTask || {};
        const {
            loanApplication,
            collaterals,
            creditCommitteeConditions,
            laatUserConditions,
            accountantComment,
            laatLeaderComment,
        } = task;
        const showRequestedCondition = Boolean(task?.requestedConditions);
        const showLaatUserConditions = Boolean(task?.laatUserConditions);
        const showCreditCommitteeConditions = Boolean(
            task?.creditCommitteeConditions,
        );
        const approveBtnDisabled = !task?.availableDecisionTypes?.includes(
            TaskDecisionType.N1_10_ADD_LOAN_CONDITIONS,
        );
        const declineBtnDisabled = !task?.availableDecisionTypes?.includes(
            TaskDecisionType.N1_10_DECLINE,
        );
        const approveInvoiceBtnDisabled =
            !task?.availableDecisionTypes?.includes(
                TaskDecisionType.N1_10_REQUEST_INVOICE_CHANGE,
            );

        const comments = [
            {
                label: t('Component.TaskCommentsView.CSComment'),
                value: creditCommitteeConditions?.comment,
            },
            {
                label: t('Component.TaskCommentsView.LAATLeaderComment'),
                value: laatLeaderComment,
            },
            {
                label: t('Component.TaskCommentsView.LAATUserComment'),
                value: laatUserConditions?.comment,
            },
            {
                label: t('Component.TaskCommentsView.AccountantComment'),
                value: accountantComment,
            },
        ];

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <StyledText>
                            <Trans i18nKey="Task.Form.N110TaskApproveConditions.Descriptions" />
                        </StyledText>
                    </Col>
                    <Col xs={24}>
                        <TaskLinkView
                            loanApplication={loanApplication}
                            collaterals={collaterals}
                        />
                    </Col>

                    <Col xs={24}>
                        {showRequestedCondition && (
                            <RequestedCondition task={task} />
                        )}
                    </Col>
                    <Col xs={24}>
                        {showLaatUserConditions && (
                            <LaatUserConditions task={task} />
                        )}
                    </Col>
                    <Col xs={24}>
                        {showCreditCommitteeConditions && (
                            <CreditCommitteeConditions currentTask={task} />
                        )}
                    </Col>

                    <Col xs={24}>
                        <TaskCommentsView comments={comments} />
                    </Col>
                </Row>

                <TaskForm
                    fields={viewModel.submitTaskErrorFields}
                    initialValues={getInitialValues(task)}
                    onFinish={async (values) => {
                        const result = covertFormValuesToSubmitValues(values);
                        await viewModel.submitTask(result);
                        if (!viewModel.submitTaskLoaderHasError) {
                            Router.navigate(RoutePaths.tasks);
                        }
                    }}
                >
                    <N110TaskApproveConditionsApproveVariables
                        currentTask={task}
                    />

                    <Space>
                        <Button
                            type={
                                viewModel.isCurrentTaskEqualWorkingTask
                                    ? 'primary'
                                    : 'default'
                            }
                            htmlType="submit"
                            disabled={approveBtnDisabled}
                        >
                            {t('Task.Form.Btn.Approve')}
                        </Button>
                        <Button
                            onClick={() => {
                                SubmitTaskDialog.render({
                                    viewModel,
                                    title: t(
                                        'Dialog.Reject.N110TaskApproveConditions.Title',
                                    ),
                                    children: (
                                        <N110TaskApproveConditionsDeclineVariables />
                                    ),
                                });
                            }}
                            disabled={declineBtnDisabled}
                        >
                            {t('Task.Form.Btn.Decline')}
                        </Button>
                        <Button
                            onClick={() => {
                                SubmitTaskDialog.render({
                                    viewModel,
                                    title: t(
                                        'Dialog.Approve.N110TaskApproveInvoice.Title',
                                        'Confirm action',
                                    ),
                                    children: (
                                        <N110TaskApproveInvoiceVariables />
                                    ),
                                });
                            }}
                            disabled={approveInvoiceBtnDisabled}
                        >
                            {t(
                                'Task.Form.N110Task.Btn.ApproveInvoice',
                                'Propose invoice adjustment',
                            )}
                        </Button>
                        <Button
                            onClick={() => {
                                Router.navigate(RoutePaths.tasks);
                            }}
                        >
                            {t('Task.Form.Btn.Back')}
                        </Button>
                    </Space>
                </TaskForm>
            </>
        );
    },
);

const getInitialValues = (data?: N110Task) => {
    return {
        ...data,
        firstRepaymentDate:
            data?.firstRepaymentDate && moment(data.firstRepaymentDate),
        secondRepaymentDate:
            data?.secondRepaymentDate && moment(data.secondRepaymentDate),
        type: TaskDecisionType.N1_10_ADD_LOAN_CONDITIONS,
        productId: data?.productId,
        requestedTerm: data?.term,
    };
};

const covertFormValuesToSubmitValues = (
    data?: N110TaskDecisionAddLoanConditions,
) => {
    return {
        ...data,
        firstRepaymentDate: DateHelper.formatISODate(data?.firstRepaymentDate),
        secondRepaymentDate:
            data?.secondRepaymentDate &&
            DateHelper.formatISODate(data?.secondRepaymentDate),
    } as N110TaskDecisionAddLoanConditions;
};

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
