import { observer } from 'mobx-react-lite';
import React from 'react';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { IdEntry } from 'src/generated-api-client';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export type LegalEntityDocumentsListCardProps = {
    viewModel: LegalEntityDetailsViewModelClass;
};

export const LegalEntityDocumentsListCard = observer(
    ({ viewModel }: LegalEntityDocumentsListCardProps) => {
        const { documentRoom } = viewModel?.item ?? {};

        return (
            <DocumentsList
                documentRoom={documentRoom ?? ({} as IdEntry)}
                canUpload={AppUser.hasPermissions(
                    UserPermission.DocumentCustomerUpload,
                )}
            />
        );
    },
);
