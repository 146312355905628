import {
    CollateralCoOwnerUpdate,
    NewCollateralCoOwner,
} from 'src/generated-api-client';
import { collateralCoOwnerApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class CollateralCoOwnerStoreClass extends BasicStore<
    any,
    any,
    CollateralCoOwnerUpdate,
    NewCollateralCoOwner
> {
    api: BasicStoreApi<any, CollateralCoOwnerUpdate, NewCollateralCoOwner> = {
        createItem: (data): any => {
            return RequestHelper.unwrapFromAxiosPromise(
                collateralCoOwnerApi.createCollateralCoOwner({
                    collateralId: this.collateralId,
                    newCollateralCoOwner: data,
                }),
            );
        },
        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                collateralCoOwnerApi.updateCollateralCoOwner({
                    id: Number(id),
                    collateralCoOwnerUpdate: data,
                }),
            );
        },
        removeItem: (id) => {
            return RequestHelper.unwrapFromAxiosPromise(
                collateralCoOwnerApi.deleteCollateralCoOwner({
                    id: Number(id),
                }),
            );
        },
    };

    constructor(private collateralId: number) {
        super();
    }
}

export const CollateralCoOwnerStore = new EntityProvider(
    CollateralCoOwnerStoreClass,
);
