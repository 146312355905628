import { t } from 'i18next';
import { ProposedLoanPaymentCancellation } from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ReviewPaymentCancellationActionStoreClass extends BasicActionStore<
    ProposedLoanPaymentCancellation,
    number,
    number
> {
    constructor(private loanId: number) {
        super();
    }

    okMessage = t('Dialog.Restructuring.Message.SuccessOk');
    cancelMessage = t('Dialog.Restructuring.Message.SuccessCancel');

    api: BasicActionStoreApi<ProposedLoanPaymentCancellation, number, number> =
        {
            load: () => {
                return RequestHelper.unwrapFromAxiosPromise(
                    loansApi.getReviewPaymentCancellation({ id: this.loanId }),
                );
            },

            ok: async (operationId) => {
                await RequestHelper.unwrapFromAxiosPromise(
                    loansApi.reviewPaymentCancellation({
                        id: this.loanId,
                        restructuringReviewRequest: {
                            approved: true,
                            operationId: operationId as number,
                        },
                    }),
                );
            },

            cancel: async (operationId) => {
                await RequestHelper.unwrapFromAxiosPromise(
                    loansApi.reviewPaymentCancellation({
                        id: this.loanId,
                        restructuringReviewRequest: {
                            approved: false,
                            operationId: operationId as number,
                        },
                    }),
                );
            },
        };
}

export const ReviewPaymentCancellationActionStore = new EntityProvider(
    ReviewPaymentCancellationActionStoreClass,
);
