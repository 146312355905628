import { Button, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CirclePlusIcon } from 'src/components/Icons/Icons';
import { getCollateralInsuranceActions } from 'src/pages/CollateralDetailsPage/Components/CollateralInsurance/CollateralInsuranceActions.config';
import { Collateral } from 'src/utils/CollateralsHelper';
import styled from 'styled-components';

type CollateralInsuranceActionsProps = {
    collateral: Collateral;
};

export const CollateralInsuranceActions = observer(
    ({ collateral }: CollateralInsuranceActionsProps) => {
        const actions = getCollateralInsuranceActions(collateral);
        const hideActions = actions.length === 0;

        if (hideActions) {
            return null;
        }

        return (
            <StyledSpace size={16}>
                {actions.map(({ label: title, key: type, onClick }) => (
                    <Button
                        type="dashed"
                        icon={<CirclePlusIcon />}
                        key={type}
                        onClick={onClick}
                    >
                        {title}
                    </Button>
                ))}
            </StyledSpace>
        );
    },
);

const StyledSpace = styled(Space)`
    display: flex;
`;
