import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FilterForm } from 'src/components/Form/FilterForm';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

export type BankStatementLoansFilterFormProps = {
    store: BasicStore<any>;
};

export const BankStatementLoansFilterForm = observer(
    ({ store }: BankStatementLoansFilterFormProps) => {
        return (
            <FilterForm store={store}>
                <TextField
                    name="customerName"
                    label={t('Dialog.AssignToLoan.Filter.CustomerName')}
                />
                <TextField
                    name="registrationNumber"
                    label={t('Dialog.AssignToLoan.Filter.RegistrationNumber')}
                />
                <TextField
                    name="phone"
                    label={t('Dialog.AssignToLoan.Filter.PhoneNumber')}
                />
                <TextField
                    name="contractId"
                    label={t('Dialog.AssignToLoan.Filter.ContractId')}
                />
                <TextField
                    name="accountNumber"
                    label={t('Dialog.AssignToLoan.Filter.AccountNumber')}
                />
                <NumberField
                    name="repaymentAmount"
                    label={t('Dialog.AssignToLoan.Filter.RepaymentAmount')}
                />
            </FilterForm>
        );
    },
);
