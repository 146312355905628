import { computed } from 'mobx';
import {
    CollateralListEntry,
    CollateralSubType,
    CollateralType,
    CreateCollateralRequest,
    PatchCollateralRequest,
    RealEstateCollateral,
} from 'src/generated-api-client';
import { collateralsApi } from 'src/services/apiServices';
import {
    carCollateralTypes,
    Collateral,
    equipmentCollateralTypes,
    intangibleAssetCollateralTypes,
    leasingCollateralTypes,
    livestockCollateralTypes,
    realEstateCollateralTypes,
    trustAccountCollateralTypes,
} from 'src/utils/CollateralsHelper';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type CollateralsFilter = {
    loanId?: number;
    collateralId?: number;
    ownerName?: string;
    ownerRegistrationNumber?: string;
    types?: CollateralType[];
    subTypes?: CollateralSubType[];
    chassisNumber?: string;
    collateralRegistrationNumber?: string;
    establishedDate?: string;
    dateOfManufacture?: string;
};

const CollateralsFilterKeys: Array<keyof CollateralsFilter> = [
    'loanId',
    'collateralId',
    'ownerName',
    'ownerRegistrationNumber',
    'types',
    'subTypes',
    'chassisNumber',
    'collateralRegistrationNumber',
    'establishedDate',
    'dateOfManufacture',
];

export class CollateralsStoreClass extends BasicStore<
    CollateralListEntry,
    CollateralsFilter,
    PatchCollateralRequest,
    CreateCollateralRequest,
    Collateral
> {
    api: BasicStoreApi<
        CollateralListEntry,
        PatchCollateralRequest,
        CreateCollateralRequest,
        Collateral
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                collateralsApi.getAllCollaterals(this.listParams),
            );
        },
        loadItem: (id: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                collateralsApi.getCollateral({ id: Number(id) }),
            );
        },
        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                collateralsApi.patchCollateral({
                    id: Number(id),
                    patchCollateralRequest: data,
                }),
            );
        },
        createItem: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                collateralsApi.createCollateral({
                    createCollateralRequest: data,
                }),
            );
        },
    };

    @computed get collateralSubType() {
        return this.currentItem?.subType as CollateralSubType as any;
    }

    @computed get coOwnerList() {
        return (this.currentItem as RealEstateCollateral).coOwners;
    }

    @computed get insuranceList() {
        return this.currentItem?.insurances;
    }

    @computed get isCarCollateral() {
        return carCollateralTypes.includes(this.collateralSubType);
    }

    @computed get isRealEstateCollateral() {
        return realEstateCollateralTypes.includes(this.collateralSubType);
    }

    @computed get isEquipmentCollateral() {
        return equipmentCollateralTypes.includes(this.collateralSubType);
    }

    @computed get isLivestockCollateral() {
        return livestockCollateralTypes.includes(this.collateralSubType);
    }

    @computed get isLeasingCollateral() {
        return leasingCollateralTypes.includes(this.collateralSubType);
    }

    @computed get isIntangibleAssetCollateral() {
        return intangibleAssetCollateralTypes.includes(this.collateralSubType);
    }

    @computed get IsTrustAccountCollateral() {
        return trustAccountCollateralTypes.includes(this.collateralSubType);
    }

    filterCriteria = new FilterCriteria<CollateralsFilter>(
        CollateralsFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<CollateralListEntry>(
        'id',
        'descend',
    );
}

export const CollateralsStore = new CollateralsStoreClass();
