import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { PayonProduct } from 'src/generated-api-client';

export type PayonProductDetailsViewProps = { payon?: PayonProduct };

export const PayonProductDetailsTab = observer(
    ({ payon }: PayonProductDetailsViewProps) => {
        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card title={t('Page.PayonProductDetails.Details.Title')}>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={payon?.productLineId}
                                    label={t(
                                        'Page.PayonProductDetails.ExternalId',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={payon?.name}
                                    label={t('Page.PayonProductDetails.Name')}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={payon?.status}
                                    label={t('Page.PayonProductDetails.Status')}
                                    format={(value) => (
                                        <EnumView
                                            enumName="PRODUCT_STATUS"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={payon?.repaymentAllocationRule}
                                    label={t(
                                        'Page.PayonProductDetails.RepaymentAllocationRule',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="REPAYMENT_ALLOCATION_RULE"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={payon?.interestRate}
                                    label={t(
                                        'Page.PayonProductDetails.MonthlyBaseInterestRates',
                                    )}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={payon?.penaltyInterestMultiplier}
                                    label={t(
                                        'Page.PayonProductDetails.PenaltyInterestMultiplier',
                                    )}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={
                                        payon?.penaltyInterestAccrualStartDay
                                    }
                                    label={t(
                                        'Page.PayonProductDetails.PenaltyInterestAccrualStartDay',
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={payon?.penaltyInterestAccrualMethod}
                                    label={t(
                                        'Page.PayonProductDetails.PenaltyInterestAccrualMethod',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="PENALTY_INTEREST_ACCRUAL_METHOD"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={payon?.dti}
                                    label={t('Page.PayonProductDetails.Dti')}
                                    format="percent"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={payon?.maxLoanAmount}
                                    label={t(
                                        'Page.PayonProductDetails.MaxLoanAmount',
                                    )}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={payon?.maxTenor}
                                    label={t(
                                        'Page.PayonProductDetails.MaxTenor',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={payon?.repaymentCalculationMethod}
                                    label={t(
                                        'Page.PayonProductDetails.RepaymentCalculationMethod',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="REPAYMENT_CALCULATION_METHOD"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <DataView
                                    value={payon?.excludedFromOffer}
                                    label={t(
                                        'Page.PayonProductDetails.ExcludedFromOffer',
                                    )}
                                    format="yes-no"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    },
);
