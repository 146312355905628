import { t } from 'i18next';
import {
    LoanInterestRateChangingPrecondition,
    LoanInterestRateChangingProposal,
} from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProposeInterestRateChangingActionStoreClass extends BasicActionStore<
    LoanInterestRateChangingPrecondition,
    LoanInterestRateChangingProposal
> {
    constructor(private loanId: number) {
        super();
    }

    okMessage = t('Dialog.Restructuring.Message.SuccessCreate');

    api: BasicActionStoreApi<
        LoanInterestRateChangingPrecondition,
        LoanInterestRateChangingProposal
    > = {
        load: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getInterestRateChanging({ id: this.loanId }),
            );
        },

        ok: async (data?: LoanInterestRateChangingProposal) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.proposeInterestRateChanging({
                    id: this.loanId,
                    loanInterestRateChangingProposal: data!,
                }),
            );
        },
    };
}

export const ProposeInterestRateChangingActionStore = new EntityProvider(
    ProposeInterestRateChangingActionStoreClass,
);
