export const CssVariables = {
    headerHeight: '64px',
    headerLineHeight: '52px',

    masterDetailsAsideWidth: '426px',
};

export const CssColors = {
    black: 'black',
};
