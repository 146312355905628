import { Avatar, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { LegalEntitiesStatus } from 'src/pages/LegalEntityDetailsPage/Components/LegalEntitiesStatus';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';

export type LegalEntityDetailsBrief = {
    viewModel?: LegalEntityDetailsViewModelClass;
};

export const LegalEntityDetailsBrief = observer(
    ({ viewModel }: LegalEntityDetailsBrief) => {
        const { t } = useTranslation();

        const logoUrl = viewModel?.item?.logo?.presignedUrl;

        return (
            <Brief>
                <Row gutter={16}>
                    <Col>
                        <Avatar size={135} src={logoUrl}>
                            {logoUrl || viewModel?.item?.name}
                        </Avatar>
                    </Col>
                    <Col span={20}>
                        <Row>
                            <DataView
                                value={viewModel?.item?.name}
                                label={t('Page.LegalEntityDetails.CompanyName')}
                                align="horizontal"
                            />
                        </Row>
                        <Row>
                            <DataView
                                value={viewModel?.item?.status}
                                label={t('Page.LegalEntityDetails.Status')}
                                align="horizontal"
                                format={(value) => (
                                    <LegalEntitiesStatus status={value} />
                                )}
                            />
                        </Row>
                        <Row>
                            <DataView
                                value={viewModel?.item?.createdAt}
                                label={t('Page.LegalEntityDetails.CreatedAt')}
                                format="date"
                                align="horizontal"
                            />
                        </Row>
                    </Col>
                </Row>
            </Brief>
        );
    },
);
