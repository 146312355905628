import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DotsHorizontalIcon } from 'src/components/Icons/Icons';
import { BankStatementListItem } from 'src/generated-api-client';
import { getBankStatementActions } from 'src/pages/BankStatementsPage/Components/BankStatementActions.config';
import styled from 'styled-components';

type BankStatementActionsProps = {
    bankStatement?: BankStatementListItem;
};

export const BankStatementActions = observer(
    ({ bankStatement }: BankStatementActionsProps) => {
        const bankStatementActions = getBankStatementActions(
            bankStatement,
        ).filter((action) => !action.disabled);

        return (
            <Dropdown
                items={bankStatementActions}
                disabled={!bankStatementActions.length}
            >
                <StyledIconButton icon={<DotsHorizontalIcon />} />
            </Dropdown>
        );
    },
);

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    box-shadow: none;

    &:disabled {
        path {
            fill: #c6c6c6;
        }
    }

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;
