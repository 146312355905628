import { t } from 'i18next';
import {
    LoanPastDatePaymentPrecondition,
    LoanPastDatePaymentProposal,
} from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { DateHelper } from 'src/utils/DateHelper';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProposePastDateRepaymentActionStoreClass extends BasicActionStore<
    LoanPastDatePaymentPrecondition,
    LoanPastDatePaymentProposal
> {
    constructor(private loanId: number) {
        super();
    }

    okMessage = t('Dialog.Restructuring.Message.SuccessCreate');

    api: BasicActionStoreApi<
        LoanPastDatePaymentPrecondition,
        LoanPastDatePaymentProposal
    > = {
        load: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getPastDatePayment({ id: this.loanId }),
            );
        },

        ok: async (data?: LoanPastDatePaymentProposal) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.proposePastDatePayment({
                    id: this.loanId,
                    loanPastDatePaymentProposal: {
                        ...data,
                        paymentDate: DateHelper.formatISODate(
                            data?.paymentDate,
                        ),
                    },
                }),
            );
        },
    };
}

export const ProposePastDateRepaymentActionStore = new EntityProvider(
    ProposePastDateRepaymentActionStoreClass,
);
