import { Form, FormInstance } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { SelectField } from 'src/components/Form/SelectField';
import { SwitchField } from 'src/components/Form/SwitchField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { DebtCollectionListEntry } from 'src/generated-api-client';
import { DebtCollectionsStore } from 'src/stores/DebtCollectionsStore/DebtCollectionsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type AssignToLoanDialogFnProps = ModalRendererProps<{
    collectionIds: number[];
    collectionsData: DebtCollectionListEntry[];
    collectionsListForm: FormInstance;
}>;

export const AssignDebtCollectionDialogFn = observer(
    ({
        collectionIds,
        collectionsData,
        collectionsListForm,
        ...props
    }: AssignToLoanDialogFnProps) => {
        const [form] = Form.useForm();
        const selectedRole = Form.useWatch('role', form);
        const store = DebtCollectionsStore;
        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );
        const debtCollectorsOptions =
            selectedRole &&
            store.debtCollectorsOptions.find(
                (debtCollector) => debtCollector.label === selectedRole,
            )?.options;

        const loanTotalAmount = collectionsData
            ?.map((t) => t?.principalAmount)
            ?.reduce((partialSum, t) => partialSum + t, 0);

        useEffect(() => {
            store.getDebtCollectorsList();

            return () => DebtCollectionsStore.debtCollectorsLoader.reset();
        }, []);

        useEffect(() => {
            form.setFields([
                {
                    name: 'debtCollectorId',
                    value: undefined,
                },
            ]);
        }, [selectedRole]);

        return (
            <Modal
                title={t('Dialog.AssignToDebtCollection.Title')}
                okText={t('Dialog.AssignToDebtCollection.Assign')}
                isLoading={store.debtCollectorsLoader.isLoading}
                formProps={{ fields }}
                form={form}
                width={600}
                initialValues={{
                    isAuto: true,
                }}
                onFinish={async ({
                    debtCollectorId,
                    isAuto,
                }: {
                    debtCollectorId: number;
                    isAuto: boolean;
                }) => {
                    await store.reAssignDebtCollections(
                        debtCollectorId,
                        collectionIds,
                        isAuto,
                    );
                    if (store.debtCollectionReAssignLoader.hasError) {
                        return Promise.reject();
                    }
                    collectionsListForm.resetFields();

                    store.loadList();
                }}
                okButtonProps={{
                    loading: store.debtCollectionReAssignLoader.isLoading,
                }}
                {...props}
            >
                <SelectField
                    name="role"
                    label={t('Dialog.AssignToDebtCollection.AssigneeRole')}
                    rules={[ValidationRules.required]}
                    options={store.roleOptions}
                    showSearch
                />
                <SelectField
                    name="debtCollectorId"
                    label={t('Dialog.AssignToDebtCollection.AssigneeName')}
                    rules={[ValidationRules.required]}
                    options={debtCollectorsOptions ?? []}
                    showSearch
                />
                <StyledSwitchField
                    name="isAuto"
                    label={t(
                        'Dialog.AssignToDebtCollection.IsAuto',
                        'Auto assign',
                    )}
                />
                <DataView
                    align="horizontal"
                    label={t(
                        'Dialog.AssignToDebtCollection.TotalNumber',
                        'Number of collections selected',
                    )}
                    value={collectionIds.length}
                />
                <DataView
                    align="horizontal"
                    label={t(
                        'Dialog.AssignToDebtCollection.TotalAmount',
                        'Total principal amount',
                    )}
                    value={loanTotalAmount}
                    format="money"
                />
            </Modal>
        );
    },
);

export const AssignDebtCollectionDialog = addModalRenderer(
    AssignDebtCollectionDialogFn,
);

const StyledSwitchField = styled(SwitchField)`
    flex-direction: column;
    display: flex;
    flex-direction: row !important;
    gap: 24px;
    align-items: baseline;
    justify-content: flex-end;
`;
