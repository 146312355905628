/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import {
    PledgedLoanApplication,
    ProductOfferListEntry,
} from 'src/generated-api-client';
import { getProductDetailsUrl } from 'src/pages/IndividualDetailsPage/Tabs/IndividualLoanLimitTab';
import styled from 'styled-components';

export type PledgedApplicationLimitOffersTabProps = {
    application?: PledgedLoanApplication;
};

export const LimitOffersTab = observer(
    ({ application }: PledgedApplicationLimitOffersTabProps) => {
        const offer = application?.offer;

        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.Tab.LimitOffers.Title',
                            'Limit Offers',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={24}>
                                <IntervalDataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.LimitOffers.ExpireDate',
                                        'Expire Date:',
                                    )}
                                    value={offer?.expiredAt}
                                    format="date-time"
                                    align="horizontal"
                                />
                            </Col>
                            <Col xs={24}>
                                <ListView
                                    dataSource={offer?.productOfferList}
                                    columns={columns}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    },
);

export const columns: ColumnsType<ProductOfferListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.LimitOffers.List.Id" />
        ),
    },
    {
        key: 'product',
        dataIndex: 'product',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.LimitOffers.List.Name" />
        ),
        render(product) {
            return (
                <DataView
                    hideLabel
                    value={product?.name}
                    format="link-internal"
                    formatterProps={{
                        to: getProductDetailsUrl(product),
                    }}
                />
            );
        },
    },
    {
        key: 'limitAmount',
        dataIndex: 'limitAmount',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.LimitOffers.List.LimitAmount" />
        ),
        render(value) {
            return <DataView hideLabel value={value} format="money" />;
        },
    },
    {
        key: 'issuedAmount',
        dataIndex: 'issuedAmount',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.LimitOffers.List.IssuedAmount" />
        ),
        render(value) {
            return (
                <OrangeColorDataView hideLabel value={value} format="money" />
            );
        },
    },
    {
        key: 'availableAmount',
        dataIndex: 'availableAmount',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.LimitOffers.List.AvailableAmount" />
        ),
        render(value) {
            return (
                <GreenColorDataView hideLabel value={value} format="money" />
            );
        },
    },
    {
        key: 'term',
        dataIndex: 'term',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.LimitOffers.List.Term" />
        ),
        render(value) {
            return <DataView hideLabel value={value} format="string" />;
        },
    },
    {
        key: 'interestRate',
        dataIndex: 'interestRate',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.LimitOffers.List.InterestRate" />
        ),
        render(value) {
            return <DataView hideLabel value={value} format="percent" />;
        },
    },
];

const IntervalDataView = styled(DataView)`
    .app-data-view__label {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: #4d6082;
    }

    .app-data-view__value {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: #2e3e5c;
    }
`;

const GreenColorDataView = styled(DataView)`
    .app-data-view__value {
        color: #0dbd7e;
    }
`;

const OrangeColorDataView = styled(DataView)`
    .app-data-view__value {
        color: #fc842b;
    }
`;
