import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';
import { ReactComponent as ResetIcon } from 'src/assets/reload-page-icon.svg';
import { FilterForm } from 'src/components/Form/FilterForm';
import { TasksFilterForm } from 'src/pages/TasksPage/TasksFilterForm';
import {
    TaskFilterInitialValues,
    TasksStore,
} from 'src/stores/TasksStore/TasksStore';
import { DateHelper } from 'src/utils/DateHelper';

export const TasksHeaderPanel = observer(() => {
    return (
        <FilterForm
            store={TasksStore}
            title={t('Page.Tasks.Header')}
            initialValues={TaskFilterInitialValues}
            convertFilterCriteriaToFormValues={
                convertFilterCriteriaToFormValues
            }
            convertFormValuesToFilterCriteria={
                convertFormValuesToFilterCriteria
            }
            panelExtra={
                <Button
                    icon={<ResetIcon />}
                    onClick={() => TasksStore.loadList()}
                    data-test="refresh-page"
                >
                    {t('Page.Tasks.Btn.Refresh')}
                </Button>
            }
        >
            <TasksFilterForm />
        </FilterForm>
    );
});

const convertFilterCriteriaToFormValues = (values: any) => {
    return {
        ...values,
        createdAt: values.createdAt && moment(values.createdAt),
        completedAt: values.completedAt && moment(values.completedAt),
    };
};
const convertFormValuesToFilterCriteria = (values: any) => {
    return {
        ...values,
        createdAt: DateHelper.formatISODate(values.createdAt),
        completedAt: DateHelper.formatISODate(values.completedAt),
    };
};
