import axios from 'axios';
import { Env } from 'src/core/Environment';
import {
    AccountControllerApiFactory,
    AuditEventControllerApiFactory,
    BranchControllerApiFactory,
    CoBorrowerControllerApiFactory,
    CollateralControllerApiFactory,
    CollateralCoOwnerControllerApiFactory,
    CommentControllerApiFactory,
    Configuration,
    CustomerGroupControllerApiFactory,
    DebtCollectionControllerApiFactory,
    DictionaryControllerApiFactory,
    DocumentControllerApiFactory,
    DocumentRoomControllerApiFactory,
    DocumentTemplateControllerApiFactory,
    FileControllerApiFactory,
    FileRequestControllerApiFactory,
    IndividualControllerApiFactory,
    InsuranceControllerApiFactory,
    LegalControllerApiFactory,
    LoanApplicationControllerApiFactory,
    LoanControllerApiFactory,
    MerchantControllerApiFactory,
    ProductControllerApiFactory,
    ProductLineControllerApiFactory,
    ResourceControllerApiFactory,
    RestrictionListControllerApiFactory,
    RoleControllerApiFactory,
    SettingsControllerApiFactory,
    TaskControllerApiFactory,
    TransactionControllerApiFactory,
    UiSettingsControllerApiFactory,
    UserControllerApiFactory,
} from 'src/generated-api-client';

axios.defaults.withCredentials = true;

const apiConfig: Configuration | undefined = undefined;

export const usersApi = UserControllerApiFactory(apiConfig, Env.basePath);
export const rolesApi = RoleControllerApiFactory(apiConfig, Env.basePath);
export const legalsApi = LegalControllerApiFactory(apiConfig, Env.basePath);
export const merchantsApi = MerchantControllerApiFactory(
    apiConfig,
    Env.basePath,
);
export const individualsApi = IndividualControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const productsApi = ProductControllerApiFactory(apiConfig, Env.basePath);
export const settingsApi = SettingsControllerApiFactory(
    apiConfig,
    Env.basePath,
);
export const restrictionsApi = RestrictionListControllerApiFactory(
    apiConfig,
    Env.basePath,
);
export const loansApi = LoanControllerApiFactory(apiConfig, Env.basePath);
export const filesApi = FileControllerApiFactory(apiConfig, Env.basePath);
export const fileRequestsApi = FileRequestControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const applicationsApi = LoanApplicationControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const documentTemplatesApi = DocumentTemplateControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const accountsApi = AccountControllerApiFactory(apiConfig, Env.basePath);
export const transactionsApi = TransactionControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const resourceApi = ResourceControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const brunchesApi = BranchControllerApiFactory(apiConfig, Env.basePath);
export const commentsApi = CommentControllerApiFactory(apiConfig, Env.basePath);
export const documentsApi = DocumentControllerApiFactory(
    apiConfig,
    Env.basePath,
);
export const uiSettingsApi = UiSettingsControllerApiFactory(
    apiConfig,
    Env.basePath,
);
export const eventsApi = AuditEventControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const customerGroupsApi = CustomerGroupControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const productLinesApi = ProductLineControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const coBorrowersApi = CoBorrowerControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const tasksApi = TaskControllerApiFactory(apiConfig, Env.basePath);

export const collateralsApi = CollateralControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const insuranceApi = InsuranceControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const collateralCoOwnerApi = CollateralCoOwnerControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const dictionaryApi = DictionaryControllerApiFactory(
    apiConfig,
    Env.basePath,
);
export const documentRoomsApi = DocumentRoomControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const debtCollectionApi = DebtCollectionControllerApiFactory(
    apiConfig,
    Env.basePath,
);
