import { t } from 'i18next';
import {
    ActionType,
    N13IndividualCoBorrower,
    N13LegalCoBorrower,
} from 'src/generated-api-client';
import { ApproveCoBorrowerDialog } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Dialogs/ApproveCoBorrowerDialog';
import { DeclineCoBorrowerDialog } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Dialogs/DeclineCoBorrowerDialog';
import { UpdateCoBorrowerDialog } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Dialogs/UpdateCoBorrowerDialog';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getCoBorrowerActions = (
    coBorrower: N13IndividualCoBorrower | N13LegalCoBorrower,
    viewModel: N13TaskViewModelClass,
) => {
    return [...getUIActions(), ...getServerActions(coBorrower, viewModel)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    coBorrower: N13IndividualCoBorrower | N13LegalCoBorrower,
    viewModel: N13TaskViewModelClass,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(coBorrower, viewModel),
        coBorrower?.actions,
    );
};

const getRegisteredServerActions = (
    coBorrower: N13IndividualCoBorrower | N13LegalCoBorrower,
    viewModel: N13TaskViewModelClass,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.CO_BORROWER_APPROVE,
            label: t('CoBorrower.Actions.Approve'),
            onClick: () => {
                ApproveCoBorrowerDialog.render({ coBorrower, viewModel });
            },
        },
        {
            key: ActionType.CO_BORROWER_DECLINE,
            label: t('CoBorrower.Actions.Decline'),
            onClick: () => {
                DeclineCoBorrowerDialog.render({ coBorrower, viewModel });
            },
        },
        {
            key: ActionType.CO_BORROWER_UPDATE,
            label: t('CoBorrower.Actions.Update'),
            onClick: () => {
                UpdateCoBorrowerDialog.render({ coBorrower, viewModel });
            },
        },
    ];
};
