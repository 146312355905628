import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LoanPaymentScheduleStoreClass extends BasicStore<
    any,
    any,
    any,
    any,
    any
> {
    api: BasicStoreApi<any, any, any, any> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                loansApi.getPaymentSchedule({ id: this.loanId }),
            );

            return result.schedulePeriods || [];
        },
    };

    constructor(private loanId: number) {
        super();
    }
}

export const LoanPaymentScheduleStore = new EntityProvider(
    LoanPaymentScheduleStoreClass,
);
