import { notification, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Modal } from 'src/components/Modal/Modal';
import { ChildBranch, ChildBranchUpdate } from 'src/generated-api-client';
import { BranchesViewModel } from 'src/pages/BranchesPage/BranchesViewModel';
import { ChildBranchesStatus } from 'src/pages/BranchesPage/Components/ChildBranchesStatus';
import { ChildBranchFields } from 'src/pages/BranchesPage/Components/ChildBranchFields';
import { convertValues } from 'src/pages/BranchesPage/Dialogs/CreateChildBranchDialog';
import { BranchOptionsStore } from 'src/stores/BranchOptionsStore/BranchOptionsStore';
import { ChildBranchesStore } from 'src/stores/ChildBranchesStore/ChildBranchesStore';
import { FilesStore } from 'src/stores/FilesStore/FilesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type EditChildBranchDialogFnProps = ModalRendererProps<{
    branchId?: number;
}>;

const store = ChildBranchesStore;

export const EditChildBranchDialogFn = observer(
    ({ branchId, ...props }: EditChildBranchDialogFnProps) => {
        const loadingInfo = useMemo(() => {
            return new CombinedLoadingStatus([
                store.itemLoader,
                store.updateItemLoader,
                BranchOptionsStore.listLoader,
                FilesStore.uploadFileLoader,
            ]);
        }, []);

        useEffect(() => {
            BranchOptionsStore.loadList();
            store.loadItem(branchId);

            return () => {
                store.itemLoader.reset();
                store.updateItemLoader.reset();
                BranchOptionsStore.listLoader.reset();
                FilesStore.uploadFileLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );

        const branch = store.currentItem;

        return (
            <Modal
                title={
                    <Space size={20}>
                        {branch?.name || t('Dialog.EditChildBranch.Title')}
                        {branch?.status && (
                            <ChildBranchesStatus status={branch?.status} />
                        )}
                    </Space>
                }
                isLoading={loadingInfo.isLoading}
                showOnlyLoading={!branch}
                onFinish={async (values) => {
                    const { logoFile, ...formValues } = values;
                    let logoFileMetadata;
                    if (logoFile?.[0]?.originFileObj) {
                        logoFileMetadata = await FilesStore.upload(
                            logoFile?.[0]?.originFileObj,
                        );
                    }

                    const dataForUpdate = convertValuesToChildBranchUpdate(
                        formValues,
                        logoFileMetadata?.id,
                    );

                    await store.update(0, dataForUpdate);

                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.updateItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.EditChildBranch.Message.Success',
                            ),
                        });
                    }

                    BranchesViewModel.reloadChildBranches();
                }}
                initialValues={getInitialValues(branch)}
                formProps={{ fields }}
                width={1100}
                {...props}
            >
                <ChildBranchFields />
            </Modal>
        );
    },
);

export const EditChildBranchDialog = addModalRenderer(EditChildBranchDialogFn);

const getInitialValues = (branch?: ChildBranch) => {
    const data = branch || ({} as ChildBranch);

    return {
        ...data,
        logoFile: branch?.logoFile?.presignedUrl,
        branchManager: branch?.branchManager?.name,
        workSchedule: {
            ...branch?.workSchedule,
            workingHours: {
                from: moment({ ...branch?.workSchedule?.workingHours?.from }),
                to: moment({ ...branch?.workSchedule?.workingHours?.to }),
            },
        },
        address: {
            ...data.address,
            provinceCode: data.address?.province?.code,
            districtCode: data.address?.district?.code,
            khorooCode: data.address?.khoroo?.code,
        },
    };
};

const convertValuesToChildBranchUpdate = (values: any, logoFileId?: string) => {
    return convertValues(values, logoFileId) as ChildBranchUpdate;
};
