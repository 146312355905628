import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    ColumnSettings,
    SettingsTable,
} from 'src/components/SettingsTable/SettingsTable';
import { EducationScoringRow, SettingType } from 'src/generated-api-client';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { TableSettingsStoreClass } from 'src/stores/Settings/TableSettingsStoreClass';
import styled from 'styled-components';

const tableStore = new TableSettingsStoreClass<EducationScoringRow>(
    SettingType.EDUCATION,
);

const renderNumberInput = (valuePath: any) => {
    return (
        <StyledNumberField
            name={valuePath}
            rules={[ValidationRules.required]}
        />
    );
};

const columnSettings: ColumnSettings[] = [
    {
        key: 'EDUCATION',
        title: (colDef) => {
            return t(
                'Page.SettingsScoring.Tabs.EducationLevel.Table.EDUCATION',
                colDef.payload,
            );
        },
        render: (data) => {
            return t(
                `Page.SettingsScoring.Tabs.EducationLevel.Table.Row.EDUCATION.${data}`,
            );
        },
        width: 250,
    },
    {
        key: 'SCORES',
        title: (colDef) => {
            return t(
                'Page.SettingsScoring.Tabs.EducationLevel.Table.SCORES',
                colDef.payload,
            );
        },
        renderEdit: (valuePath) => renderNumberInput(valuePath),
        editable: true,
        width: 200,
    },
];

export const SettingsScoringEducationLevelEvaluationTab = observer(() => {
    return (
        <SettingsTable
            tableStore={tableStore}
            columnSettings={columnSettings}
            hasPermission={AppUser.hasPermissions(
                UserPermission.LoanSettingsScoringEdit,
            )}
        />
    );
});

const StyledNumberField = styled(NumberField)`
    margin: 0;
    min-width: 60px;
`;
