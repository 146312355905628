import { Image, notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from 'src/components/Icons/Icons';
import { Modal } from 'src/components/Modal/Modal';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

export type QrCodeDialogComponentProps = ModalRendererProps<{
    title?: string;
    qrLoader?: AsyncOperationWithStatus<() => Promise<string>, string>;
    handleOnCancel?: () => void;
}>;

const getQrCode = async (
    qrLoader?: AsyncOperationWithStatus<() => Promise<string>, string>,
) => {
    const qrCode = await qrLoader?.call();
    if (!qrCode) {
        notification.error({
            message: t('Dialog.QR.NoQrCode'),
        });
    }
};

export const QrCodeDialogComponent = observer(
    ({
        title,
        qrLoader,
        handleOnCancel,
        ...props
    }: QrCodeDialogComponentProps) => {
        const { t } = useTranslation();
        const countLoads = qrLoader?.countLoads;

        useEffect(() => {
            getQrCode(qrLoader);

            return () => {
                qrLoader?.reset();
            };
        }, []);

        const qrContentRender = () => {
            if (
                Number(countLoads) > 0 &&
                (qrLoader?.hasError || !qrLoader?.hasData)
            ) {
                return (
                    <>
                        <WarningIcon />
                        <StyledText>{t('Dialog.QR.NoQrCode')}</StyledText>
                    </>
                );
            }

            return (
                <>
                    <StyledText>{t('Dialog.QR.ScanQR')}</StyledText>
                    <Image src={qrLoader?.data as any} preview={false} />
                </>
            );
        };

        return (
            <StyledModal
                title={title}
                maskClosable={false}
                footer={false}
                isLoading={qrLoader?.isLoading}
                {...props}
            >
                <QrCodeWrapper>{qrContentRender()}</QrCodeWrapper>
            </StyledModal>
        );
    },
);

const StyledModal = styled(Modal)`
    .ant-modal-title {
        text-align: center;
    }
`;

const QrCodeWrapper = styled.div`
    text-align: center;
    min-height: 150px;
`;

const StyledText = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #4d6082;
    margin: 0 0 15px;
`;
