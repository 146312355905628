import { observer } from 'mobx-react-lite';
import { FilterForm } from 'src/components/Form/FilterForm';
import { CollectionActivitiesFilterForm } from 'src/pages/CollectionDetailsPage/RecordActivityPage/Components/CollectionActivitiesFilterForm';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

type CollectionActivitiesHeaderPanelProps = {
    title: React.ReactNode;
    store: BasicStore<any>;
};

export const CollectionActivitiesHeaderPanel = observer(
    ({ title, store }: CollectionActivitiesHeaderPanelProps) => {
        return (
            <div>
                <FilterForm store={store} title={title}>
                    <CollectionActivitiesFilterForm />
                </FilterForm>
            </div>
        );
    },
);
