import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { DeleteIcon } from 'src/components/Icons/Icons';
import { ListView } from 'src/components/ListView/ListView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { CustomerGroupCompany, IdNameEntry } from 'src/generated-api-client';
import { RemoveCompanyDialog } from 'src/pages/Settings/CustomerGroups/CustomerGroupCompaniesPage/Dialogs/RemoveCompanyDialog';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { CustomerGroupCompaniesStore } from 'src/stores/CustomerGroupCompaniesStore/CustomerGroupCompaniesStore';
import { UrlHelper } from 'src/utils/UrlHelper';

export type GroupCompaniesListProps = {
    groupId?: number;
};

export const GroupCompaniesList = observer(
    ({ groupId }: GroupCompaniesListProps) => {
        const store = CustomerGroupCompaniesStore.getInstance(Number(groupId));
        const columns = getGroupCompaniesColumns(groupId);

        useEffect(() => {
            store.loadList();

            return () => {
                store.listLoader.reset();
            };
        }, [groupId]);

        return (
            <ListView
                store={store}
                columns={columns}
                loading={store.listLoader.isLoading}
            />
        );
    },
);

const getGroupCompaniesColumns = (
    groupId?: number,
): ColumnsType<CustomerGroupCompany> => [
    {
        key: 'name',
        dataIndex: 'name',
        title: <Trans i18nKey="Component.GroupCompaniesList.CompanyName" />,
    },
    {
        key: 'addedAt',
        dataIndex: 'addedAt',
        title: <Trans i18nKey="Component.GroupCompaniesList.AddedAt" />,
        render: (value) => {
            return (
                <DataView value={value} hideLabel format="date-time-seconds" />
            );
        },
    },
    {
        key: 'addedBy',
        dataIndex: 'addedBy',
        title: <Trans i18nKey="Component.GroupCompaniesList.AddedBy" />,
        render: (user: IdNameEntry) => {
            return (
                <DataView
                    value={user.name}
                    hideLabel
                    format="link-internal"
                    formatterProps={{
                        to: UrlHelper.getTargetUrl(RoutePaths.userDetails, {
                            userId: user.id,
                        }),
                    }}
                />
            );
        },
    },
    {
        key: 'delete',
        dataIndex: 'delete',
        title: <Trans i18nKey="Component.GroupCompaniesList.Actions" />,
        render: (_, { name }) => {
            return (
                <Button
                    type="text"
                    icon={<DeleteIcon />}
                    onClick={() => {
                        RemoveCompanyDialog({ groupId, name });
                    }}
                    disabled={
                        !AppUser.hasPermissions(
                            UserPermission.CustomerSettingsSegmentationEdit,
                        )
                    }
                />
            );
        },
    },
];
