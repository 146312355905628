import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';

export const LoanFinancialOperationsFilterForm = observer(() => {
    return (
        <>
            <DatePickerField
                name="effectiveAtFrom"
                label={t(
                    'Page.LoansDetails.FinancialOperations.Filter.DateFrom',
                )}
            />
            <DatePickerField
                name="effectiveAtTo"
                label={t('Page.LoansDetails.FinancialOperations.Filter.DateTo')}
            />
            <NumberField
                name="amountFrom"
                label={t(
                    'Page.LoansDetails.FinancialOperations.Filter.AmountFrom',
                )}
            />
            <NumberField
                name="amountTo"
                label={t(
                    'Page.LoansDetails.FinancialOperations.Filter.AmountTo',
                )}
            />
            <NumberField
                name="principalFrom"
                label={t(
                    'Page.LoansDetails.FinancialOperations.Filter.PrincipalFrom',
                )}
            />
            <NumberField
                name="principalTo"
                label={t(
                    'Page.LoansDetails.FinancialOperations.Filter.PrincipalTo',
                )}
            />
            <EnumSelectField
                mode="multiple"
                name="operationTypes"
                label={t(
                    'Page.LoansDetails.FinancialOperations.Filter.ProductType',
                )}
                enumName="LOAN_OPERATION_AUDIT_EVENT_OPERATION_TYPE"
            />
            <EnumSelectField
                mode="multiple"
                name="creditBureauStatuses"
                label={t(
                    'Page.LoansDetails.FinancialOperations.Filter.CreditBureauStatuses',
                )}
                enumName="CREDIT_BUREAU_STATUS"
            />
            <TextField
                name="boUserName"
                label={t(
                    'Page.LoansDetails.FinancialOperations.Filter.BoUserName',
                )}
            />
            <EnumSelectField
                mode="multiple"
                name="loanStatuses"
                label={t(
                    'Page.LoansDetails.FinancialOperations.Filter.LoanStatuses',
                )}
                enumName="LOAN_STATUS"
            />
        </>
    );
});
