import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { CollectionActivityStatus as CollectionActivityStatusEnum } from 'src/generated-api-client';
import { EnumStore } from 'src/stores/Resources/EnumStore/EnumStore';

type StatusProps = {
    status?: CollectionActivityStatusEnum;
};

export const CollectionActivityStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: CollectionActivityStatusEnum) => {
    return (
        (status && statusInfoMap[status]) || {
            title: EnumStore.getInstance(
                'COLLECTION_ACTIVITY_STATUS',
            ).getLocalizedEnum(status ?? ''),
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [CollectionActivityStatusEnum.SUCCESS]: {
        title: (
            <EnumView
                enumName="COLLECTION_ACTIVITY_STATUS"
                value={CollectionActivityStatusEnum.SUCCESS}
            />
        ),
        color: '#FFF',
        backgroundColor: '#23C165',
    },
    [CollectionActivityStatusEnum.FAILED]: {
        title: (
            <EnumView
                enumName="COLLECTION_ACTIVITY_STATUS"
                value={CollectionActivityStatusEnum.FAILED}
            />
        ),
        color: '#FFF',
        backgroundColor: '#F75725',
    },
};
