import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { CustomerVerificationStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: CustomerVerificationStatus;
};

export const IndividualsStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: CustomerVerificationStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: (
                <EnumView
                    enumName="CUSTOMER_VERIFICATION_STATUS"
                    value={status}
                />
            ),
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [CustomerVerificationStatus.NEW]: {
        title: (
            <EnumView
                enumName="CUSTOMER_VERIFICATION_STATUS"
                value={CustomerVerificationStatus.NEW}
            />
        ),
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
};
