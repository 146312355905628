import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { CeoDetails } from 'src/generated-api-client';
import { DataInformationCard } from 'src/pages/LegalEntityDetailsPage/Components/DataInformationCard';
import { UpdateCEOInformationDialog } from 'src/pages/LegalEntityDetailsPage/Dialogs/LegalEntityInformation/UpdateCEODialog';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { UrlHelper } from 'src/utils/UrlHelper';

export type LegalEntityCEOInformationCardProps = {
    viewModel?: LegalEntityDetailsViewModelClass;
};

export const LegalEntityCEOInformationCard = observer(
    ({ viewModel }: LegalEntityCEOInformationCardProps) => {
        const ceoInfo = viewModel?.item?.ceo || ({} as CeoDetails);

        const individualDetailsUrl = UrlHelper.getTargetUrl(
            RoutePaths.individualDetails,
            { individualId: ceoInfo?.id },
        );

        return (
            <DataInformationCard
                title={t('Page.LegalEntityDetails.CEO.Title')}
                isLoading={viewModel?.itemLoader?.isLoading}
                hasData
                canUpdate={AppUser.hasPermissions(UserPermission.LegalEdit)}
                onAdd={() => {
                    UpdateCEOInformationDialog.render({
                        viewModel,
                    });
                }}
            >
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={ceoInfo?.name}
                            label={t('Page.LegalEntityDetails.CEO.Name')}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={ceoInfo?.civilId}
                            label={t('Page.LegalEntityDetails.CEO.CivilId')}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={ceoInfo?.citizenship}
                            label={t('Page.LegalEntityDetails.CEO.Citizenship')}
                            format={(value) => (
                                <EnumView
                                    enumName="CITIZENSHIP"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={ceoInfo?.registrationNumber}
                            label={t(
                                'Page.LegalEntityDetails.CEO.RegistrationNumber',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={ceoInfo?.birthDate}
                            label={t('Page.LegalEntityDetails.CEO.BirthDate')}
                            format="date"
                        />
                    </Col>

                    <Col xs={12}>
                        <DataView
                            value={ceoInfo?.id}
                            label={t('Page.LegalEntityDetails.CEO.Id')}
                            format="link-internal"
                            formatterProps={{
                                to: individualDetailsUrl,
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={ceoInfo?.pep}
                            label={t('Page.LegalEntityDetails.CEO.PEP')}
                        />
                    </Col>
                </Row>
            </DataInformationCard>
        );
    },
);
