import { Button, Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import {
    ComponentProvider,
    ComponentsMap,
} from 'src/components/ComponentProvider/ComponentProvider';
import { CircleArrowRightIcon } from 'src/components/Icons/Icons';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { BnplLoanApplicationDetails } from 'src/pages/ApplicationDetailsPage/BnplLoanApplication/BnplLoanApplicationDetails';
import { ApplicationActions } from 'src/pages/ApplicationDetailsPage/Components/ApplicationActions';
import { InstallmentLoanApplicationDetails } from 'src/pages/ApplicationDetailsPage/InstallmentLoanApplication/InstallmentLoanApplicationDetails';
import { PayonLoanApplicationDetails } from 'src/pages/ApplicationDetailsPage/PayonLoanApplication/PayonLoanApplicationDetails';
import { PledgedLoanApplicationDetails } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/PledgedLoanApplicationDetails';
import { TrustLoanApplicationDetails } from 'src/pages/ApplicationDetailsPage/TrustLoanApplication/TrustLoanApplicationDetails';
import { UnknownLoanApplication } from 'src/pages/ApplicationDetailsPage/UnknownLoanApplication/UnknownLoanApplication';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = ApplicationsStore;

export const ApplicationDetailsPage = observer(() => {
    const { applicationId } = useApplicationId();

    useEffect(() => {
        store.loadItem(applicationId);

        return () => {
            store.itemLoader.reset();
        };
    }, [applicationId]);

    const application = store.currentItem;
    const showLoanInformationLink = AppUser.hasPermissions(
        UserPermission.LoanReadDetails,
    );

    return (
        <BasePage
            title={
                application && (
                    <Row gutter={16}>
                        <Col flex={1}>
                            {t('Page.ApplicationDetails.Title', {
                                applicationId: application?.externalId,
                            })}
                        </Col>
                        <Col>
                            {showLoanInformationLink && (
                                <Button
                                    icon={<CircleArrowRightIcon />}
                                    type="primary"
                                    onClick={() => {
                                        const targetUrl =
                                            UrlHelper.getTargetUrl(
                                                RoutePaths.loanDetails,
                                                { loanId: application?.loanId },
                                            );

                                        Router.navigate(targetUrl);
                                    }}
                                >
                                    {t(
                                        'Page.ApplicationDetails.Button.LoanInformation',
                                    )}
                                </Button>
                            )}
                        </Col>
                        <Col>
                            <ApplicationActions application={application} />
                        </Col>
                    </Row>
                )
            }
            isLoading={store.itemLoader.isLoading}
            showOnlyLoading={!application}
        >
            <ComponentProvider
                componentsMap={loanApplicationMap}
                currentComponentKey={String(application?.type)}
                FallbackComponent={UnknownLoanApplication}
            />
        </BasePage>
    );
});

export enum LoanApplicationPageType {
    Bnpl = 'BnplLoanApplication',
    Installment = 'InstallmentLoanApplication',
    Payon = 'PayonLoanApplication',
    Pledged = 'PledgedLoanApplication',
    Trust = 'TrustPledgedLoanApplication',
}

const loanApplicationMap: ComponentsMap = {
    [LoanApplicationPageType.Bnpl]: {
        Component: BnplLoanApplicationDetails,
    },
    [LoanApplicationPageType.Installment]: {
        Component: InstallmentLoanApplicationDetails,
    },
    [LoanApplicationPageType.Payon]: {
        Component: PayonLoanApplicationDetails,
    },
    [LoanApplicationPageType.Pledged]: {
        Component: PledgedLoanApplicationDetails,
    },
    [LoanApplicationPageType.Trust]: {
        Component: TrustLoanApplicationDetails,
    },
};
