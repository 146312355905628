import { Form as AntForm } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FilterForm } from 'src/components/Form/FilterForm';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { BankStatementStatus } from 'src/generated-api-client';
import {
    BankStatementsStore,
    DEFAULT_SELF_ACCOUNTS,
} from 'src/stores/BankStatementsStore/BankStatementsStore';
import { BankAccountsStore } from 'src/stores/Resources/BankAccountsStore/BankAccountsStore';
import { DateHelper } from 'src/utils/DateHelper';

type HeaderPanelProps = {
    title: React.ReactNode;
};

const getInitialValues = () => {
    return {
        selfAccounts: DEFAULT_SELF_ACCOUNTS,
        transactionDateFrom: moment().startOf('day').subtract(1, 'months'),
        transactionDateTo: moment().endOf('day'),
        bankStatementStatuses: [BankStatementStatus.PENDING],
    };
};

export const BankStatementsHeaderPanel = observer(
    ({ title }: HeaderPanelProps) => {
        const [form] = AntForm.useForm();
        const transactionDateFrom = AntForm.useWatch(
            'transactionDateFrom',
            form,
        );

        return (
            <div>
                <FilterForm
                    store={BankStatementsStore}
                    title={title}
                    form={form}
                    initialValues={getInitialValues()}
                    convertFilterCriteriaToFormValues={
                        convertFilterCriteriaToFormValues
                    }
                    convertFormValuesToFilterCriteria={
                        convertFormValuesToFilterCriteria
                    }
                >
                    <SelectField
                        name="selfAccounts"
                        mode="multiple"
                        label={t('Page.BankStatements.Filter.TbfBankAccount')}
                        store={BankAccountsStore}
                        convertListItemToOption={(item: string) => {
                            return {
                                label: item,
                                value: item,
                            };
                        }}
                    />
                    <DatePickerField
                        name="transactionDateFrom"
                        label={t(
                            'Page.BankStatements.Filter.TransactionDateFrom',
                        )}
                        disabledDate={(date) => {
                            return date.isAfter(moment().endOf('day'));
                        }}
                    />
                    <DatePickerField
                        name="transactionDateTo"
                        label={t(
                            'Page.BankStatements.Filter.TransactionDateTo',
                        )}
                        dependencies={['transactionDateFrom']}
                        disabledDate={(date) => {
                            return (
                                date.isBefore(
                                    moment(transactionDateFrom).startOf('day'),
                                ) ||
                                date.isAfter(
                                    moment(transactionDateFrom)
                                        .endOf('day')
                                        .add(3, 'month'),
                                ) ||
                                date.isAfter(moment().endOf('day'))
                            );
                        }}
                        rules={[
                            ValidationRules.dateIsAfter(
                                moment(transactionDateFrom).startOf('day'),
                            ),
                            ValidationRules.dateIsBefore(
                                moment(transactionDateFrom)
                                    .endOf('day')
                                    .add(3, 'month'),
                            ),
                        ]}
                    />
                    <TextField
                        name="accountNumber"
                        label={t(
                            'Page.BankStatements.Filter.ContactAccountNumber',
                        )}
                    />
                    <TextField
                        name="description"
                        label={t('Page.BankStatements.Filter.Description')}
                    />
                    <EnumSelectField
                        mode="multiple"
                        name="operationTypes"
                        label={t('Page.BankStatements.Filter.TransactionType')}
                        enumName="BANK_STATEMENT_OPERATION_TYPE"
                    />
                    <EnumSelectField
                        mode="multiple"
                        name="bankStatementStatuses"
                        label={t('Page.BankStatements.Filter.Status')}
                        enumName="BANK_STATEMENT_STATUS"
                    />
                </FilterForm>
            </div>
        );
    },
);

const convertFilterCriteriaToFormValues = (values: any) => {
    return {
        ...values,
        selfAccounts: convertSelfAccountsToStringArray(values?.selfAccounts),
        transactionDateFrom:
            values.transactionDateFrom && moment(values.transactionDateFrom),
        transactionDateTo:
            values.transactionDateTo && moment(values.transactionDateTo),
    };
};

const convertFormValuesToFilterCriteria = (values: any) => {
    return {
        ...values,
        transactionDateFrom: DateHelper.formatISODate(
            values.transactionDateFrom,
        ),
        transactionDateTo: DateHelper.formatISODate(values.transactionDateTo),
    };
};

const convertSelfAccountsToStringArray = (
    selfAccounts?: string[] | number[],
): string[] => {
    return Array.isArray(selfAccounts) && selfAccounts?.length
        ? selfAccounts.map((selfAccount) => String(selfAccount))
        : DEFAULT_SELF_ACCOUNTS;
};
