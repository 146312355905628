import { Space, Typography } from 'antd';
import { BaseType } from 'antd/es/typography/Base';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { AuthPage } from 'src/pages/Auth/AuthPage';
import styled from 'styled-components';

export type AuthPageWithMessageProps = {
    title?: React.ReactNode;
    message?: React.ReactNode;
    messageType?: BaseType;
};

export const AuthPageWithMessage = observer(
    ({ message, messageType, title }: AuthPageWithMessageProps) => {
        return (
            <AuthPage title={title}>
                <StyledSpace size={16} direction="vertical">
                    <Typography.Text type={messageType}>
                        {message}
                    </Typography.Text>
                    <Link to={RoutePaths.authLogin}>
                        {t('Component.AuthPageWithMessage.GoToLoginPage')}
                    </Link>
                </StyledSpace>
            </AuthPage>
        );
    },
);

const StyledSpace = styled(Space)`
    width: 100%;
`;
