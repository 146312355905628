import { Form as AntForm, FormInstance } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { Localization } from 'src/generated-api-client';
import { BranchOptionsStore } from 'src/stores/BranchOptionsStore/BranchOptionsStore';
import { DebtCollectionsStore } from 'src/stores/DebtCollectionsStore/DebtCollectionsStore';
import { AdministrativeUnitWithId } from 'src/stores/Resources/AdministrativeUnitsStore/AdministrativeUnits.types';
import { AdministrativeUnitsStore } from 'src/stores/Resources/AdministrativeUnitsStore/AdministrativeUnitsStore';
import { FormHelper } from 'src/utils/FormHelper';

type CollectionsFilterFormProps = {
    form: FormInstance;
};

const asOption = (value: AdministrativeUnitWithId) => {
    const language = i18next.language as keyof Localization;

    return {
        value: value.code,
        label: value.l10n?.[language],
    } as DefaultOptionType;
};

export const CollectionsFilterForm = observer(
    ({ form }: CollectionsFilterFormProps) => {
        const parentBranchId = AntForm.useWatch('parentBranchId', form);
        const provinceCode = AntForm.useWatch('provinceCode', form);

        const { parentBranchOptions } = BranchOptionsStore;
        const childBranchOptions =
            BranchOptionsStore.childBranchOptions(parentBranchId);

        useEffect(() => {
            BranchOptionsStore.loadList();

            DebtCollectionsStore.getDebtCollectorsList();

            if (AdministrativeUnitsStore.listLoader.countTries === 0) {
                AdministrativeUnitsStore.loadList(false);
            }

            return () => {
                BranchOptionsStore.listLoader.reset();
                DebtCollectionsStore.debtCollectorsLoader.reset();
            };
        }, []);

        useEffect(() => {
            form.setFieldsValue({
                districtCode: undefined,
            });
        }, [provinceCode]);

        useEffect(() => {
            form.setFields([{ name: 'childBranchId', value: undefined }]);
        }, [parentBranchId]);

        const provinceOptions = useMemo(() => {
            return AdministrativeUnitsStore.getProvinces().map(asOption);
        }, [AdministrativeUnitsStore.list]);

        const districtOptions = useMemo(() => {
            return provinceCode
                ? AdministrativeUnitsStore.getDistricts(provinceCode)?.map(
                      asOption,
                  )
                : [];
        }, [AdministrativeUnitsStore.list, provinceCode]);

        return (
            <>
                <NumberField
                    name="customerId"
                    label={t('Page.CollectionsPage.Filter.CustomerId')}
                    onlyInteger
                />
                <TextField
                    name="customerName"
                    label={t('Page.CollectionsPage.Filter.CustomerName')}
                />
                <TextField
                    name="phoneNumber"
                    label={t('Page.CollectionsPage.Filter.PhoneNumber')}
                />
                <TextField
                    name="registrationNumber"
                    label={t('Page.CollectionsPage.Filter.RegistrationNumber')}
                />
                <EnumSelectField
                    mode="multiple"
                    name="statuses"
                    label={t('Page.CollectionsPage.Filter.Statuses')}
                    enumName="COLLECTION_STATUS"
                />
                <NumberField
                    name="principalAmountFrom"
                    label={t('Page.CollectionsPage.Filter.PrincipalAmountFrom')}
                    thousandDivider
                />
                <NumberField
                    name="principalAmountTo"
                    label={t('Page.CollectionsPage.Filter.PrincipalAmountTo')}
                    thousandDivider
                />
                <EnumSelectField
                    mode="multiple"
                    name="levels"
                    label={t('Page.CollectionsPage.Filter.Levels')}
                    enumName="COLLECTION_LEVEL"
                />
                <EnumSelectField
                    mode="multiple"
                    name="lastActivityGeneralTypes"
                    label={t(
                        'Page.CollectionsPage.Filter.LastActivityGeneralTypes',
                    )}
                    enumName="COLLECTION_ACTIVITY_GENERAL_TYPE"
                />
                <DatePickerField
                    name="lastActivityDate"
                    label={t('Page.CollectionsPage.Filter.LastActivityDate')}
                />
                <NumberField
                    name="maxDaysOverdueFrom"
                    label={t('Page.CollectionsPage.Filter.MaxOverdueDayFrom')}
                    onlyInteger
                />
                <NumberField
                    name="maxDaysOverdueTo"
                    label={t('Page.CollectionsPage.Filter.MaxOverdueDayTo')}
                    onlyInteger
                />
                <SelectField
                    name="debtCollectorId"
                    label={t('Page.CollectionsPage.Filter.DebtCollectorId')}
                    options={DebtCollectionsStore?.debtCollectorsOptions}
                    showSearch
                />
                <EnumSelectField
                    mode="multiple"
                    name="nextActions"
                    label={t('Page.CollectionsPage.Filter.NextActions')}
                    enumName="COLLECTION_ACTIVITY_NEXT_ACTION"
                />
                <DatePickerField
                    name="nextActionDate"
                    label={t('Page.CollectionsPage.Filter.NextActionDate')}
                />
                <EnumSelectField
                    mode="multiple"
                    name="loanStatuses"
                    label={t('Page.CollectionsPage.Filter.LoanStatus')}
                    enumName="LOAN_STATUS"
                />
                <EnumSelectField
                    mode="multiple"
                    name="creditBureauStatuses"
                    label={t(
                        'Page.CollectionsPage.Filter.CreditBureauStatuses',
                    )}
                    enumName="CREDIT_BUREAU_STATUS"
                />
                <SelectField
                    name="parentBranchId"
                    label={t('Page.CollectionsPage.Filter.ParentBranchId')}
                    options={parentBranchOptions}
                    showSearch
                />
                <SelectField
                    name="childBranchId"
                    label={t('Page.CollectionsPage.Filter.ChildBranchId')}
                    options={childBranchOptions}
                    showSearch
                />
                <EnumSelectField
                    mode="multiple"
                    name="productTypes"
                    label={t('Page.CollectionsPage.Filter.ProductType')}
                    enumName="PRODUCT_TYPE"
                />
                <SelectField
                    name="provinceCode"
                    label={t('Page.CollectionsPage.Filter.CityProvince')}
                    options={provinceOptions}
                    showSearch
                />
                <SelectField
                    name="districtCode"
                    label={t('Page.CollectionsPage.Filter.DistrictSum')}
                    options={districtOptions}
                    showSearch
                />
                <SelectField
                    name="isCompleted"
                    label={t('Page.CollectionsPage.Filter.IsCompleted')}
                    options={FormHelper.objAsOptions(
                        {
                            true: true,
                            false: false,
                        },
                        'Page.CollectionsPage.Filter.IsCompleted.',
                    )}
                />
            </>
        );
    },
);
