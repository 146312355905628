import { Space, Typography } from 'antd';
import { t } from 'i18next';
import React from 'react';
import styled from 'styled-components';

export type NotFoundProps = {
    title?: React.ReactNode;
    description?: React.ReactNode;
};
export const NotFound: React.FC<NotFoundProps> = ({
    title,
    description,
    children,
}) => {
    return (
        <StyledNotFoundWrapper>
            <StyledTitle>{title || t('Component.NotFound.Title')}</StyledTitle>
            <Space direction="vertical" align="center">
                <StyledMessage>
                    {description || t('Component.NotFound.Description')}
                </StyledMessage>
                <div>{children}</div>
            </Space>
        </StyledNotFoundWrapper>
    );
};

const StyledNotFoundWrapper = styled.div`
    position: relative;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledTitle = styled(Typography.Title)`
    &.ant-typography {
        margin-bottom: 20px;
        font-size: 32px;
        color: #2e3e5c;
    }
`;

const StyledMessage = styled(Typography.Text)`
    &.ant-typography {
        font-size: 16px;
        color: #4d6082;
    }
`;
