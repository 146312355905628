import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { CreditBureauStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: CreditBureauStatus;
};

export const CreditsBureauStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: CreditBureauStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: <EnumView enumName="CREDIT_BUREAU_STATUS" value={status} />,
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [CreditBureauStatus.NORMAL]: {
        title: (
            <EnumView
                enumName="CREDIT_BUREAU_STATUS"
                value={CreditBureauStatus.NORMAL}
            />
        ),
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
    [CreditBureauStatus.STRESSED]: {
        title: (
            <EnumView
                enumName="CREDIT_BUREAU_STATUS"
                value={CreditBureauStatus.STRESSED}
            />
        ),
        color: '#fa656c',
        backgroundColor: '#FFE4E4',
    },
    [CreditBureauStatus.SUB_STANDARD]: {
        title: (
            <EnumView
                enumName="CREDIT_BUREAU_STATUS"
                value={CreditBureauStatus.SUB_STANDARD}
            />
        ),
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
    [CreditBureauStatus.DOUBTFUL]: {
        title: (
            <EnumView
                enumName="CREDIT_BUREAU_STATUS"
                value={CreditBureauStatus.DOUBTFUL}
            />
        ),
        color: '#0d0d0d',
        backgroundColor: '#f2f2f2',
    },
    [CreditBureauStatus.LOSS]: {
        title: (
            <EnumView
                enumName="CREDIT_BUREAU_STATUS"
                value={CreditBureauStatus.LOSS}
            />
        ),
        color: '#0d0d0d',
        backgroundColor: '#f2f2f2',
    },
};
