/* eslint-disable max-lines */
import { Col, notification, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FormItemGroup } from 'src/components/Form/FormItemGroup/FormItemGroup';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { WorkPhoneField } from 'src/components/Form/WorkPhoneField';
import { Modal } from 'src/components/Modal/Modal';
import {
    AddressUpdate,
    CustomerJobInformationRequest,
    CustomerJobInformationResponse,
    IndividualInformationTabView,
} from 'src/generated-api-client';
import { AddressFields } from 'src/pages/IndividualDetailsPage/Components/AddressFields';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

export type UpdateCustomerReferenceDialogFnProps = ModalRendererProps<{
    viewModel?: IndividualDetailsViewModelClass;
}>;

export const UpdateJobInformationDialogFn = observer(
    ({ viewModel, ...props }: UpdateCustomerReferenceDialogFnProps) => {
        useEffect(() => {
            return () => {
                viewModel?.updateJobInformationLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            viewModel?.updateJobInformationLoader.errorData,
        );

        return (
            <Modal
                title={t(
                    'Dialog.UpdateJobInformation.Title',
                    'Job Information',
                )}
                width={900}
                onFinish={async (values) => {
                    await viewModel?.updateJobInformation(values);

                    if (viewModel?.updateJobInformationLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel?.updateJobInformationLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.UpdateJobInformation.Message.Success',
                                'Job information was updated successfully',
                            ),
                        });
                    }
                }}
                initialValues={getInitialValues(viewModel?.item)}
                isLoading={viewModel?.isUpdateJobInformationLoading}
                formProps={{
                    fields,
                }}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={8}>
                        <EnumSelectField
                            name="education"
                            enumName="EDUCATION"
                            label={t(
                                'Dialog.UpdateJobInformation.Education',
                                'Education',
                            )}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <EnumSelectField
                            name="employment"
                            enumName="EMPLOYMENT"
                            label={t(
                                'Dialog.UpdateJobInformation.Employment',
                                'Employment',
                            )}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>

                    <Col xs={8}>
                        <EnumSelectField
                            name="industry"
                            enumName="INDUSTRY"
                            label={t(
                                'Dialog.UpdateJobInformation.Industry',
                                'Industry',
                            )}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={8}>
                        <TextField
                            name="companyName"
                            label={t(
                                'Dialog.UpdateJobInformation.CompanyName',
                                'Company/Organization',
                            )}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="position"
                            label={t(
                                'Dialog.UpdateJobInformation.Position',
                                'Position',
                            )}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>

                    <Col xs={8}>
                        <WorkPhoneField
                            name="workPhoneNumber"
                            label={t(
                                'Dialog.UpdateJobInformation.WorkPhoneNumber',
                                'Workplace Phone Number',
                            )}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                </Row>
                <StyledTitle>
                    {t(
                        'Dialog.UpdateJobInformation.JobAddress.Title',
                        'Job address',
                    )}
                </StyledTitle>

                <FormItemGroup name="jobAddress">
                    <AddressFields />
                </FormItemGroup>
            </Modal>
        );
    },
);

type JobAddress = Partial<AddressUpdate>;
type InitialValues = Omit<
    Partial<CustomerJobInformationRequest>,
    'jobAddress'
> & { jobAddress: JobAddress };

const getInitialValues = (
    item?: IndividualInformationTabView,
): InitialValues => {
    const jobInfo =
        item?.customerJobInformationResponse ||
        ({} as CustomerJobInformationResponse);

    return {
        ...jobInfo,
        jobAddress: {
            ...jobInfo.jobAddress,
            provinceCode: jobInfo.jobAddress?.province?.code,
            districtCode: jobInfo.jobAddress?.district?.code,
            khorooCode: jobInfo.jobAddress?.khoroo?.code,
        },
    };
};

export const UpdateJobInformationDialog = addModalRenderer(
    UpdateJobInformationDialogFn,
);

const StyledTitle = styled(Typography.Title)`
    margin-top: 20px;
    &.ant-typography {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #4d6082;
    }
`;
