import { Legal } from 'src/generated-api-client';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';

export type LegalEntityDetailKey = keyof Legal;

export const hasData = (
    targetFields: LegalEntityDetailKey[],
    targetObject?: LegalEntityDetailsViewModelClass,
) => {
    return targetFields.some((key) => Boolean(targetObject?.item?.[key]));
};
