import { Avatar, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { IndividualsStatus } from 'src/pages/IndividualDetailsPage/Components/IndividualsStatus';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import styled from 'styled-components';

export type IndividualDetailsBriefProps = {
    viewModel?: IndividualDetailsViewModelClass;
};

export const IndividualDetailsBrief = observer(
    ({ viewModel }: IndividualDetailsBriefProps) => {
        const individualInitials = [
            viewModel?.item?.firstName?.[0],
            viewModel?.item?.lastName?.[0],
        ]
            .join('')
            .toUpperCase();

        const avatarUrl = viewModel?.item?.idCardImage?.presignedUrl;

        return (
            <Brief
                extra={
                    <Space direction="horizontal">
                        <StyledDataView
                            value={viewModel?.item?.monthlyIncomeValue}
                            label={t(
                                'Page.IndividualDetails.OverallMonthlyIncome',
                            )}
                            format="money"
                        />
                        <StyledDataView
                            value={viewModel?.item?.csValue}
                            label={t('Page.IndividualDetails.CSPoint')}
                        />
                    </Space>
                }
            >
                <Space size={30} align="start">
                    <StyledAvatar size={135} src={avatarUrl}>
                        {avatarUrl || individualInitials}
                    </StyledAvatar>
                    <div>
                        <DataView
                            align="horizontal"
                            value={viewModel?.item?.id}
                            label={t('Page.IndividualDetails.Id')}
                            format="string"
                        />
                        <DataView
                            align="horizontal"
                            value={viewModel?.item?.lastName}
                            label={t('Page.IndividualDetails.LastName')}
                        />
                        <DataView
                            align="horizontal"
                            value={viewModel?.item?.firstName}
                            label={t('Page.IndividualDetails.FirstName')}
                        />
                        <DataView
                            align="horizontal"
                            value={viewModel?.item?.verificationStatus}
                            label={t(
                                'Page.IndividualDetails.VerificationStatus',
                            )}
                            format={(value) => (
                                <IndividualsStatus status={value} />
                            )}
                        />
                    </div>
                </Space>
            </Brief>
        );
    },
);

const StyledAvatar = styled(Avatar)`
    display: flex;
    align-items: center;

    border: 2px solid #d7d7d7;
    background: #363740;

    .ant-avatar-string {
        font-size: 50px;
    }
`;

const StyledDataView = styled(DataView)`
    min-width: 140px;
    height: 134px;

    padding: 20px;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .app-data-view__label {
        font-weight: 700;
        font-size: 15px !important;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.4px;

        color: #9fa2b4;
    }

    .app-data-view__value {
        font-weight: 700;
        font-size: 35px !important;
        line-height: 44px !important;

        text-align: center;
        letter-spacing: 1px;

        background: linear-gradient(
            90deg,
            #0d73bd -25.12%,
            #0562a5 -25.09%,
            #17c48d 215.89%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
`;
