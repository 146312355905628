import { notification, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Modal } from 'src/components/Modal/Modal';
import { OfferExclusionUpdate } from 'src/generated-api-client';
import { LoanProductOfferExclusionsStore } from 'src/stores/LoanProductOfferExclusionsStore/LoanProductOfferExclusionsStore';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { Entity } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type AddProductToLimitOffersDialogProps<T extends Entity> = ModalRendererProps<{
    store?: BasicStore<T>;
    productId: number;
}>;

const AddProductToLimitOffersDialogFn = observer(
    ({
        store,
        productId,
        ...props
    }: AddProductToLimitOffersDialogProps<any>) => {
        const loanProductOfferExclusionsStore = LoanProductOfferExclusionsStore;

        useEffect(() => {
            return () => {
                loanProductOfferExclusionsStore.updateItemLoader.reset();
            };
        }, []);

        return (
            <Modal
                isLoading={
                    loanProductOfferExclusionsStore.updateItemLoader.isLoading
                }
                title={t('Dialog.AddProductToLimitOffers.Title')}
                onFinish={async () => {
                    await loanProductOfferExclusionsStore.update(productId, {
                        excludedFromOffer: false,
                    } as OfferExclusionUpdate);
                    if (
                        !loanProductOfferExclusionsStore.updateItemLoader
                            .hasError
                    ) {
                        notification.success({
                            message: t(
                                'Page.AddProductToLimitOffers.Message.Success',
                            ),
                        });
                        props.onCancel();
                        store?.itemLoader?.call(productId);
                    }
                }}
                okText={t('Dialog.AddProductToLimitOffers.OkText', 'Confirm')}
                {...props}
            >
                <StyledText>
                    <Trans i18nKey="Dialog.AddProductToLimitOffers.Descriptions" />
                </StyledText>
            </Modal>
        );
    },
);

export const AddProductToLimitOffersDialog = addModalRenderer(
    AddProductToLimitOffersDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
    height: 100px;
`;
