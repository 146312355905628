import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { ErrorField } from 'src/components/Form/ErrorField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { TaskDecisionType } from 'src/generated-api-client';
import styled from 'styled-components';

export const N111TaskUploadSignedContractApproveVariables = observer(() => {
    return (
        <Card title={t('Task.Form.N111TaskUploadSignedContract.Comment')}>
            <Row gutter={16}>
                <HiddenField
                    name="type"
                    initialValue={TaskDecisionType.N1_11_APPROVE}
                />
                <Col xs={24}>
                    <StyledTextArea
                        name="comment"
                        label={t(
                            'Task.Form.N111TaskUploadSignedContract.Comment',
                        )}
                    />
                </Col>
                <Col xs={24}>
                    <StyledError name={['process', 'otherErrors']} />
                </Col>
            </Row>
        </Card>
    );
});

const StyledTextArea = styled(TextAreaField)`
    .ant-input {
        padding: 12px;
    }
`;

const StyledError = styled(ErrorField)`
    margin-bottom: 16px;
`;
