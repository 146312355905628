import { notification } from 'antd';
import { ColumnType } from 'antd/es/table';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HiddenField } from 'src/components/Form/HiddenField';
import { ListSelectField } from 'src/components/Form/ListSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import {
    IdLocalizationEntry,
    IdNameEntry,
    LegalListEntry,
} from 'src/generated-api-client';
import { LegalEntityCoBorrowersListFilterForm } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/LegalEntityCoBorrowersListFilterForm';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import { LegalEntitiesStore } from 'src/stores/LegalEntitiesStore/LegalEntitiesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const store = LegalEntitiesStore;

type CreateCoBorrowerDialogProps = ModalRendererProps<{
    viewModel: N13TaskViewModelClass;
}>;

const CreateLegalCoBorrowerDialogFn = observer(
    ({ viewModel, ...props }: CreateCoBorrowerDialogProps) => {
        const applicationId = viewModel?.currentTask?.loanApplication?.id;
        const isLoading =
            viewModel.createCoBorrowerLoader.isLoading ||
            store.listLoader.isLoading;
        useEffect(() => {
            return () => {
                store.listLoader.reset();
                store.filterCriteria?.resetFilter();
                store.pager?.reset();
                viewModel.createCoBorrowerLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            viewModel.createCoBorrowerLoader.errorData,
        );

        const columns = getColumns(props);

        return (
            <Modal
                isLoading={isLoading}
                title={t('Dialog.CreateCoBorrower.LegalTitle')}
                width={1200}
                onFinish={async (values) => {
                    await viewModel.createCoBorrower(values);

                    if (viewModel.createCoBorrowerLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel.createCoBorrowerLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.CreateCoBorrower.Message.Success',
                            ),
                        });
                        viewModel.load();
                    }
                }}
                headerExtra={
                    <LegalEntityCoBorrowersListFilterForm store={store} />
                }
                formProps={{ fields }}
                {...props}
            >
                <HiddenField
                    name="type"
                    initialValue="NewApplicationCoBorrower"
                />

                <HiddenField
                    name="applicationId"
                    initialValue={applicationId}
                />

                <StyledListSelectField
                    name="coBorrowerId"
                    store={store}
                    columns={columns}
                    rules={[ValidationRules.required]}
                />
            </Modal>
        );
    },
);

export const CreateLegalCoBorrowerDialog = addModalRenderer(
    CreateLegalCoBorrowerDialogFn,
);

const StyledListSelectField = styled(ListSelectField)`
    .ant-table-wrapper {
        padding: 10px 0;
    }
`;

const getColumns = (
    props: ModalRendererProps,
): ColumnType<LegalListEntry>[] => {
    return [
        {
            key: 'id',
            dataIndex: 'id',
            title: <Trans i18nKey="Dialog.CreateCoBorrower.List.Id" />,
            render: (legalId: string) => {
                return (
                    <Link
                        data-test="legal-details-link"
                        to={EntityLinks.legals.itemDetails(legalId)}
                        onClick={() => {
                            Router.navigate(
                                EntityLinks.legals.itemDetails(legalId),
                            );

                            props.onCancel();
                        }}
                    >
                        {legalId}
                    </Link>
                );
            },
        },
        {
            key: 'companyName',
            dataIndex: 'companyName',
            title: (
                <Trans i18nKey="Dialog.CreateCoBorrower.List.CoborrowerName" />
            ),
        },
        {
            key: 'industry',
            dataIndex: 'industry',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.LegalEntity.List.Industry" />
            ),

            render: (value: IdLocalizationEntry) => {
                return (value?.l10n as any)[i18next.language];
            },
        },
        {
            key: 'registrationNumber',
            dataIndex: 'registrationNumber',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.LegalEntity.List.RegistrationNumber" />
            ),
        },
        {
            key: 'ceo',
            dataIndex: 'ceo',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.LegalEntity.List.CEO" />
            ),
            render: (value: IdNameEntry) => {
                return (
                    <Link to={EntityLinks.individuals.itemDetails(value?.id)}>
                        {value?.name}
                    </Link>
                );
            },
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.LegalEntity.List.PhoneNumber" />
            ),
        },
    ];
};
