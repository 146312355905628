import { notification } from 'antd';
import { ColumnType } from 'antd/es/table';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HiddenField } from 'src/components/Form/HiddenField';
import { ListSelectField } from 'src/components/Form/ListSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import {
    IdLocalizationEntry,
    IdNameEntry,
    LegalListEntry,
} from 'src/generated-api-client';
import { LegalEntityCoBorrowersListFilterForm } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/LegalEntityCoBorrowersListFilterForm';
import { CoBorrowersStore } from 'src/stores/CoBorrowersStore/CoBorrowersStore';
import { LegalEntitiesStore } from 'src/stores/LegalEntitiesStore/LegalEntitiesStore';
import { LoansStore, LoanType } from 'src/stores/LoansStore/LoansStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const store = CoBorrowersStore;
const legalsStore = LegalEntitiesStore;

type CreateLegalCoBorrowerDialogProps = ModalRendererProps<{
    loan: LoanType;
}>;

const CreateLegalCoBorrowerDialogFn = observer(
    ({ loan, ...props }: CreateLegalCoBorrowerDialogProps) => {
        const isLoading =
            store.createItemLoader.isLoading ||
            legalsStore.listLoader.isLoading;
        useEffect(() => {
            return () => {
                legalsStore.listLoader.reset();
                legalsStore.filterCriteria?.resetFilter();
                legalsStore.pager?.reset();
                store.createItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.createItemLoader.errorData,
        );

        const columns = getLegalEntitiesColumns(props);

        return (
            <Modal
                isLoading={isLoading}
                title={t('Dialog.Loan.CreateCoBorrower.LegalEntity.Title')}
                width={1200}
                onFinish={async (values) => {
                    await store.create(values);

                    if (store.createItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.createItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.Loan.CreateCoBorrower.Message.Success',
                            ),
                        });
                        LoansStore.loadItem(loan?.id);
                    }
                }}
                headerExtra={
                    <LegalEntityCoBorrowersListFilterForm store={legalsStore} />
                }
                formProps={{ fields }}
                {...props}
            >
                <HiddenField name="type" initialValue="NewLoanCoBorrower" />

                <HiddenField name="loanId" initialValue={loan?.id} />

                <StyledListSelectField
                    name="coBorrowerId"
                    store={legalsStore}
                    columns={columns}
                    rules={[ValidationRules.required]}
                />
            </Modal>
        );
    },
);

export const CreateLegalCoBorrowerDialog = addModalRenderer(
    CreateLegalCoBorrowerDialogFn,
);

const StyledListSelectField = styled(ListSelectField)`
    .ant-table-wrapper {
        padding: 10px 0;
    }
`;

const getLegalEntitiesColumns = (
    props: ModalRendererProps,
): ColumnType<LegalListEntry>[] => {
    return [
        {
            key: 'id',
            dataIndex: 'id',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.LegalEntity.List.Id" />
            ),
            render: (legalId: string) => {
                return (
                    <Link
                        data-test="legal-details-link"
                        to={EntityLinks.legals.itemDetails(legalId)}
                        onClick={() => {
                            Router.navigate(
                                EntityLinks.legals.itemDetails(legalId),
                            );

                            props.onCancel();
                        }}
                    >
                        {legalId}
                    </Link>
                );
            },
        },
        {
            key: 'companyName',
            dataIndex: 'companyName',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.LegalEntity.List.CompanyName" />
            ),
        },
        {
            key: 'industry',
            dataIndex: 'industry',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.LegalEntity.List.Industry" />
            ),

            render: (value: IdLocalizationEntry) => {
                return (value?.l10n as any)[i18next.language];
            },
        },
        {
            key: 'registrationNumber',
            dataIndex: 'registrationNumber',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.LegalEntity.List.RegistrationNumber" />
            ),
        },
        {
            key: 'ceo',
            dataIndex: 'ceo',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.LegalEntity.List.CEO" />
            ),
            render: (value: IdNameEntry) => {
                return (
                    <Link to={EntityLinks.individuals.itemDetails(value?.id)}>
                        {value?.name}
                    </Link>
                );
            },
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.LegalEntity.List.PhoneNumber" />
            ),
        },
    ];
};
