import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { InstallmentProduct } from 'src/generated-api-client';
import { ProductsStatus } from 'src/pages/LoanProducts/ProductsStatus';

export type InstallmentProductDetailsBriefProps = {
    productInstallment?: InstallmentProduct;
};
export const InstallmentProductDetailsBrief = observer(
    ({ productInstallment }: InstallmentProductDetailsBriefProps) => {
        return (
            <Brief>
                <Row gutter={16}>
                    <Col span={6}>
                        <DataView
                            value={productInstallment?.name}
                            label={t('Page.InstallmentProductDetails.Name')}
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productInstallment?.maxLoanAmount}
                            label={t(
                                'Page.InstallmentProductDetails.MaxLoanAmount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productInstallment?.interestRate}
                            label={t(
                                'Page.InstallmentProductDetails.InterestRate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productInstallment?.status}
                            label={t('Page.InstallmentProductDetails.Status')}
                            format={(value) => (
                                <ProductsStatus status={value} />
                            )}
                        />
                    </Col>
                </Row>
            </Brief>
        );
    },
);
