import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { DataView } from 'src/components/DataView/DataView';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    ColumnSettings,
    SettingsTable,
} from 'src/components/SettingsTable/SettingsTable';
import {
    SalaryIncomeDto,
    SalaryIncomeRow,
    SalaryIncomeSettingRule,
    SettingType,
} from 'src/generated-api-client';
import { TableSettingsStoreClass } from 'src/stores/Settings/TableSettingsStoreClass';
import styled from 'styled-components';

const tableStore = new TableSettingsStoreClass<SalaryIncomeDto>(
    SettingType.SALARY_INCOME,
);

const columnSettings: ColumnSettings[] = [
    {
        key: 'RULES',
        title: (colDef) => {
            return t(
                'Page.SettingsCustomerVerification.Tabs.SalaryIncome.Table.Rules',
                colDef.payload,
            );
        },
        render: (value, record) => {
            return t(`Entity.Settings.SalaryIncome.${value}`, {
                numberOfMonths: record?.numberOfMonths,
                restrictionAmount: record?.restrictionAmount,
            });
        },
    },
    {
        key: 'NUMBER_OF_MONTHS',
        title: (colDef) => {
            return t(
                'Page.SettingsCustomerVerification.Tabs.SalaryIncome.Table.NumberOfMonths',
                colDef.payload,
            );
        },
        renderEdit: (valuePath, row: SalaryIncomeRow) => {
            if (
                NumberOfMonthsSettingRules.includes(row.salaryIncomeSettingRule)
            ) {
                return renderNumberOfMonthsNumberInput(valuePath);
            }

            return null;
        },
        render(value) {
            return <DataView value={value} hideLabel />;
        },
        editable: true,
        width: 205,
    },
    {
        key: 'RESTRICTION_AMOUNT',
        title: (colDef) => {
            return t(
                'Page.SettingsCustomerVerification.Tabs.SalaryIncome.Table.RestrictionAmount',
                colDef.payload,
            );
        },
        renderEdit: (valuePath, row: SalaryIncomeRow) => {
            if (
                RestrictionAmountSettingRules.includes(
                    row.salaryIncomeSettingRule,
                )
            ) {
                return renderRestrictionAmountNumberInput(valuePath);
            }

            return null;
        },
        render(value) {
            return <DataView value={value} hideLabel />;
        },
        editable: true,
        width: 205,
    },
];

export const SettingsCustomerVerificationSalaryIncomeTab = observer(() => {
    return (
        <SettingsTable
            tableStore={tableStore}
            columnSettings={columnSettings}
        />
    );
});

const NumberOfMonthsSettingRules: SalaryIncomeSettingRule[] = [
    SalaryIncomeSettingRule.SALARY_INCOME_MUST_CONTAIN_DATA_FOR_AT_LEAST_X_LAST_MONTHS_CURRENT_MONTH_IS_NOT_INCLUDED,
    SalaryIncomeSettingRule.SALARY_FOR_THE_LAST_X_MONTHS_CURRENT_MONTH_IS_NOT_INCLUDED_MUST_BE_REPAID_BY_THE_SAME_ENTITY_ENTITIES_OF_THE_SAME_CUSTOMER_GROUP,
    SalaryIncomeSettingRule.THERE_ARE_NO_MISSED_MONTHS_FOR_LAST_X_MONTHS,
    SalaryIncomeSettingRule.THE_AVERAGE_INCOME_FOR_THE_LAST_X_MONTHS_MUST_BE_GREATER_THEN_MNT,
    SalaryIncomeSettingRule.THERE_ARE_NO_MONTHS_THAT_WERE_REPAID_VOLUNTARILY_FOR_THE_LAST_X_MONTHS,
];

const RestrictionAmountSettingRules: SalaryIncomeSettingRule[] = [
    SalaryIncomeSettingRule.THE_AVERAGE_INCOME_FOR_THE_LAST_X_MONTHS_MUST_BE_GREATER_THEN_MNT,
    SalaryIncomeSettingRule.THE_AVERAGE_INCOME_FOR_THE_LAST_2_MONTHS_MUST_BE_GREATER_THEN_X_MNT,
];

const renderNumberOfMonthsNumberInput = (valuePath: any) => {
    return (
        <StyledNumberField
            name={valuePath}
            rules={[
                ValidationRules.required,
                ValidationRules.numberMinValue(0),
                ValidationRules.numberMaxValue(100),
            ]}
        />
    );
};

const renderRestrictionAmountNumberInput = (valuePath: any) => {
    return (
        <StyledNumberField
            name={valuePath}
            thousandDivider
            rules={[
                ValidationRules.required,
                ValidationRules.numberMinValue(0),
            ]}
        />
    );
};

const StyledNumberField = styled(NumberField)`
    margin: 0;
    min-width: 60px;
`;
