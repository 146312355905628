import _ from 'lodash';
import { action, computed } from 'mobx';
import {
    CreateRoleForm,
    Role,
    RoleListItem,
    UpdateRoleForm,
} from 'src/generated-api-client';
import { rolesApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export class RolesStoreClass extends BasicStore<
    RoleListItem,
    any,
    UpdateRoleForm,
    CreateRoleForm,
    Role
> {
    api: BasicStoreApi<RoleListItem, UpdateRoleForm, CreateRoleForm, Role> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                rolesApi.roles(this.listParams),
            );
        },

        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(rolesApi.role({ id }));
        },

        createItem: async (data) => {
            await RequestHelper.unwrapFromAxiosPromise(
                rolesApi.createRole({ createRoleForm: data }),
            );
        },

        updateItem: async (id, data) => {
            await RequestHelper.unwrapFromAxiosPromise(
                rolesApi.updateRole({
                    id: Number(id),
                    updateRoleForm: data,
                }),
            );
        },
    };

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<RoleListItem>('id', 'descend');

    authorityLoader = new AsyncOperationWithStatus(
        () => RequestHelper.unwrapFromAxiosPromise(rolesApi.allAuthorities()),
        {
            defaultIsLoading: true,
        },
    );

    @action async loadAuthorities() {
        await this.authorityLoader.call();
    }

    @computed get authorities() {
        return _.orderBy(
            this.authorityLoader.data?.map((x) => {
                const entity = x.value?.split(':')[0];

                return { entity, ...x };
            }),
            'entity',
            'asc',
        );
    }

    @action async loadItem(id?: string | number | undefined): Promise<void> {
        if (!this.authorityLoader.hasData) {
            await this.loadAuthorities();
        }
        await super.loadItem(id);
    }

    @computed get authoritiesGroupedByEntity() {
        return _.groupBy(this.authorities, 'entity');
    }

    @computed get authoritiesValueAuthorityMap() {
        return new Map(this.authorities.map((x) => [x.value, x.authority]));
    }
}

export const RolesStore = new RolesStoreClass();
