import { Col, Row } from 'antd';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { Address, CustomerAddress } from 'src/generated-api-client';

type AddressDetailsProps = { address: Address | CustomerAddress };
export const AddressDetails = observer(
    ({ address: passedAddress }: AddressDetailsProps) => {
        const showAdditionalFields = isCustomerAddress(passedAddress);
        const address = isCustomerAddress(passedAddress)
            ? passedAddress.address
            : passedAddress;

        return (
            <address>
                <Row gutter={16}>
                    <Col xs={8}>
                        <DataView
                            value={
                                (address.province.l10n as any)[i18next.language]
                            }
                            label={t(
                                'Entity.Address.CityProvince',
                                'City/Province',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={
                                (address.district.l10n as any)[i18next.language]
                            }
                            label={t(
                                'Entity.Address.DistrictSum',
                                'District/Sum',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={
                                (address.khoroo.l10n as any)[i18next.language]
                            }
                            label={t('Entity.Address.Khoroo', 'Khoroo/Bag')}
                        />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={8}>
                        <DataView
                            value={address.streetName}
                            label={t('Entity.Address.Street', 'Street')}
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={address.townName}
                            label={t('Entity.Address.Town', 'Town')}
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={address.apartmentName}
                            label={t('Entity.Address.Apartment', 'Apartment')}
                        />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={16}>
                        <DataView
                            value={address.fullAddress}
                            label={t(
                                'Entity.Address.FullAddress',
                                'Full Address',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={address.familiarAddress}
                            label={t(
                                'Entity.Address.FamiliarAddress',
                                'Familiar Address',
                            )}
                        />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={8}>
                        <DataView
                            value={address.latitudeLongitude}
                            label={t(
                                'Entity.Address.LatitudeLongitude',
                                'Google Location',
                            )}
                        />
                    </Col>

                    {showAdditionalFields && (
                        <>
                            <Col xs={8}>
                                <DataView
                                    value={passedAddress.addressType}
                                    label={t(
                                        'Entity.Address.AddressType',
                                        'Address Type',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="ADDRESS_TYPE"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={8}>
                                <DataView
                                    value={passedAddress.addressOwner}
                                    label={t(
                                        'Entity.Address.AddressOwner',
                                        'Address owner',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="ADDRESS_OWNER"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>{' '}
                        </>
                    )}
                </Row>
            </address>
        );
    },
);

function isCustomerAddress(value: any): value is CustomerAddress {
    return Boolean(value.addressType);
}
