import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField } from 'src/components/Form/SelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { RepaymentFrequency } from 'src/generated-api-client';
import { ProposeActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ProposeActionDialogComponent';
import { ProposePaymentDateChangingActionStore } from 'src/stores/LoanActionsStores/ProposePaymentDateChangingActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const asOption = (value: number) => ({
    value,
    label: value,
});

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
    repaymentFrequency?: RepaymentFrequency;
}>;

export const ProposePaymentDateChangingDialogFn = observer(
    ({ loanId, repaymentFrequency, ...props }: RestructuringsDialogFnProps) => {
        const store = ProposePaymentDateChangingActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ProposePaymentDateChanging.Title');
        const dayOption = Array.from(new Array(26).keys())
            .slice(1)
            .map(asOption);

        return (
            <ProposeActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <SelectField
                    options={dayOption}
                    name="firstPaymentDay"
                    label={
                        repaymentFrequency === RepaymentFrequency.MONTHLY
                            ? t(
                                  'Dialog.ProposePaymentDateChanging.FirstPaymentDayMonthly',
                              )
                            : t(
                                  'Dialog.ProposePaymentDateChanging.FirstPaymentDay',
                              )
                    }
                    rules={[ValidationRules.required]}
                />
                {store.initialValues?.hasSecondPaymentDay && (
                    <SelectField
                        options={dayOption}
                        name="secondPaymentDay"
                        label={t(
                            'Dialog.ProposePaymentDateChanging.SecondPaymentDay',
                        )}
                        rules={[ValidationRules.required]}
                    />
                )}
            </ProposeActionDialogComponent>
        );
    },
);

export const ProposePaymentDateChangingDialog = addModalRenderer(
    ProposePaymentDateChangingDialogFn,
);
