import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { IdNameEntry, Insurance } from 'src/generated-api-client';
import { CollateralInsuranceActions } from 'src/pages/CollateralDetailsPage/Components/CollateralInsurance/CollateralInsuranceActions';
import { CollateralInsuranceListActions } from 'src/pages/CollateralDetailsPage/Components/CollateralInsurance/CollateralInsuranceListActions';
import { CollateralInsuranceStatus } from 'src/pages/CollateralDetailsPage/Components/CollateralInsurance/Components/CollateralInsuranceStatus';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { Collateral } from 'src/utils/CollateralsHelper';

export const CollateralInsurance = observer(() => {
    const collateral = CollateralsStore.currentItem;
    const columns = getColumns(collateral!);

    return (
        <Card
            title={t('Component.CollateralInsurance.Title')}
            extra={<CollateralInsuranceActions collateral={collateral!} />}
        >
            <ListView
                columns={columns}
                dataSource={CollateralsStore.insuranceList}
            />
        </Card>
    );
});

const getColumns = (collateral: Collateral): ColumnsType<Insurance> => [
    {
        key: 'insuranceProduct',
        dataIndex: 'insuranceProduct',
        title: <Trans i18nKey="Component.CollateralInsurance.Product" />,
        render: (product: IdNameEntry) => {
            return <DataView value={product?.name} hideLabel />;
        },
    },
    {
        key: 'insuranceCompany',
        dataIndex: 'insuranceCompany',
        title: <Trans i18nKey="Component.CollateralInsurance.Company" />,
        render: (company: IdNameEntry) => {
            return <DataView value={company.name} hideLabel />;
        },
    },
    {
        key: 'brokerage',
        dataIndex: 'brokerage',
        title: <Trans i18nKey="Component.CollateralInsurance.Brokerage" />,
        render: (value) => {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: <Trans i18nKey="Component.CollateralInsurance.Amount" />,
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'registeredDate',
        dataIndex: 'registeredDate',
        title: <Trans i18nKey="Component.CollateralInsurance.RegisteredDate" />,
        render: (value) => {
            return <DataView value={value} format="date" hideLabel />;
        },
    },
    {
        key: 'expireDate',
        dataIndex: 'expireDate',
        title: <Trans i18nKey="Component.CollateralInsurance.ExpireDate" />,
        render: (value) => {
            return <DataView value={value} format="date" hideLabel />;
        },
    },
    {
        key: 'source',
        dataIndex: 'source',
        title: <Trans i18nKey="Component.CollateralInsurance.Source" />,
        render: (value) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView enumName="INSURANCE_SOURCE" value={value} />
                    )}
                />
            );
        },
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Component.CollateralInsurance.Status" />,
        render(value) {
            return <CollateralInsuranceStatus status={value} />;
        },
    },
    {
        key: 'actions',
        render: (_, record) => {
            return (
                <CollateralInsuranceListActions
                    insurance={record}
                    collateral={collateral}
                />
            );
        },
    },
];
