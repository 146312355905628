import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { CustomerGroupsTab } from 'src/pages/Settings/CustomerGroups/Tabs/CustomerGroupsTab';

export const CustomerGroupsPage = observer(() => {
    const { t } = useTranslation();

    const tabs: TabDefinition[] = [
        {
            title: t('Page.CustomerGroups.Tabs.CustomerGroup'),
            tabKey: 'customer-group',
            Component: <CustomerGroupsTab />,
        },
    ];

    return (
        <BasePage title={t('Page.CustomerGroups.Title')}>
            <Tabs tabs={tabs} />
        </BasePage>
    );
});
