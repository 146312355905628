import { Button, Col, Form as AntdForm, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'src/components/Form/Form';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { UserAvatar } from 'src/components/UserAvatar';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { CommentsStore } from 'src/stores/CommentsStore/CommentsStore';
import styled from 'styled-components';

export type AddCommentProps = {
    roomId: number;
};

export const AddComment = observer(({ roomId }: AddCommentProps) => {
    const store = CommentsStore.getInstance(roomId);
    const [form] = AntdForm.useForm();
    const { t } = useTranslation();
    const currentUserFullName = `${AppUser.userProfile?.name} ${AppUser.userProfile?.surname}`;

    useEffect(() => {
        return () => {
            store.createItemLoader.reset();
        };
    }, []);

    return (
        <Row gutter={16} wrap={false}>
            <Col xs={24}>
                <Form
                    form={form}
                    onFinish={async (values) => {
                        if (values?.text) {
                            await store.create(values);
                            await form.resetFields();
                            store.loadList(false);
                        }
                    }}
                    onKeyPress={(e) => {
                        if (e.ctrlKey && e.charCode === 13) {
                            form.submit();
                        }
                    }}
                >
                    <StyledWrapper>
                        <StyledTextArea
                            name="text"
                            rules={[ValidationRules.maxCommentLength]}
                            disabled={store.createItemLoader.isLoading}
                        />

                        <Row gutter={16}>
                            <StyledCol flex={1}>
                                <UserAvatar user={AppUser.userProfile} />
                                <StyledText type="secondary">
                                    {currentUserFullName}
                                </StyledText>
                            </StyledCol>

                            <StyledCol>
                                <StyledText type="secondary">
                                    {t('Component.Comments.AddPost.SubText')}
                                </StyledText>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={store.createItemLoader.isLoading}
                                >
                                    {t('Component.Comments.AddPost')}
                                </Button>
                            </StyledCol>
                        </Row>
                    </StyledWrapper>
                </Form>
            </Col>
        </Row>
    );
});

const StyledWrapper = styled.div``;

const StyledTextArea = styled(TextAreaField)`
    margin-bottom: 12px;
    .ant-input {
        padding: 12px;
    }
`;

const StyledCol = styled(Col)`
    display: flex;
    align-items: center;
`;

const StyledText = styled(Typography.Text)`
    margin: 0 12px;
    font-size: 13px !important;
`;
