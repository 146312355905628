import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FilterForm } from 'src/components/Form/FilterForm';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { CirclePlusIcon } from 'src/components/Icons/Icons';
import { CreateNonExistingIndividualRepresentativeDialog } from 'src/pages/LegalEntityDetailsPage/Dialogs/LegalEntityInformation/CreateNonExistingIndividualRepresentativeDialog';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

type CreateExistingIndividualRepresentativeFilterFormProps = {
    store: BasicStore<any>;
    viewModel: LegalEntityDetailsViewModelClass;
    cancelCurrentModal: () => void;
};
export const CreateExistingIndividualRepresentativeFilterForm = observer(
    ({
        store,
        viewModel,
        cancelCurrentModal,
    }: CreateExistingIndividualRepresentativeFilterFormProps) => {
        const canCreate = AppUser.hasPermissions(
            UserPermission.LegalCreateRepresentative,
        );

        return (
            <FilterForm
                store={store}
                panelExtra={
                    canCreate && (
                        <Button
                            icon={<CirclePlusIcon />}
                            onClick={() => {
                                cancelCurrentModal();

                                CreateNonExistingIndividualRepresentativeDialog.render(
                                    {
                                        viewModel,
                                    },
                                );
                            }}
                        >
                            {t(
                                'Dialog.CreateRepresentative.CreateNonExistingRepresentative',
                            )}
                        </Button>
                    )
                }
            >
                <NumberField
                    name="id"
                    label={t('Dialog.CreateRepresentative.List.Filter.Id')}
                    onlyInteger
                />
                <TextField
                    name="civilId"
                    label={t('Dialog.CreateRepresentative.List.Filter.CivilId')}
                />
                <TextField
                    name="customerName"
                    label={t('Dialog.CreateRepresentative.List.Filter.Name')}
                />
                <TextField
                    name="registrationNumber"
                    label={t(
                        'Dialog.CreateRepresentative.List.Filter.RegistrationNumber',
                    )}
                />
                <TextField
                    name="phone"
                    label={t(
                        'Dialog.CreateRepresentative.List.Filter.PhoneNumber',
                    )}
                />
            </FilterForm>
        );
    },
);
