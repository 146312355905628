import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';

export type UnknownComponentProps = {
    componentKey: string;
};

export const UnknownComponent = observer(
    ({ componentKey }: UnknownComponentProps) => {
        return (
            <StyledNotFoundWrapper>
                <StyledMessage>
                    {t('Component.UnknownComponent.Description', {
                        componentKey,
                    })}
                </StyledMessage>
            </StyledNotFoundWrapper>
        );
    },
);

const StyledNotFoundWrapper = styled.div`
    position: relative;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledMessage = styled(Typography.Text)`
    &.ant-typography {
        font-size: 16px;
        color: #4d6082;
    }
`;
