import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { EquipmentCollateral } from 'src/generated-api-client';
import { getEquipmentCollateralGeneralInfoActions } from 'src/pages/CollateralDetailsPage/Tabs/EquipmentCollateralTab/EquipmentCollateralGeneralInfoActions.config';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';

export const EquipmentCollateralGeneralInfo = observer(() => {
    const collateral = CollateralsStore.currentItem as EquipmentCollateral;
    const menuActions = getEquipmentCollateralGeneralInfoActions(collateral);

    return (
        <Card
            title={t('Page.CollateralDetails.Tabs.Equipment.GeneralInfo.Title')}
            menuActions={menuActions}
        >
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral?.equipmentType}
                        label={t(
                            'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.Type',
                        )}
                        format={(value) => (
                            <EnumView enumName="EQUIPMENT_TYPE" value={value} />
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.name}
                        label={t(
                            'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.Name',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.country}
                        label={t(
                            'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.Country',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.brandName}
                        label={t(
                            'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.BrandName',
                        )}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral?.model}
                        label={t(
                            'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.Model',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.serialNumber}
                        label={t(
                            'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.SerialNumber',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.manufacturedDate}
                        label={t(
                            'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.ManufacturedDate',
                        )}
                        format="date"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.importedDate}
                        label={t(
                            'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.ImportedDate',
                        )}
                        format="date"
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral.ownershipType}
                        label={t(
                            'Page.CollateralDetails.Tabs.Equipment.GeneralInfo.OwnershipType',
                        )}
                        format={(value) => (
                            <EnumView
                                enumName="COLLATERAL_OWNERSHIP_TYPE"
                                value={value}
                            />
                        )}
                    />
                </Col>
            </Row>
        </Card>
    );
});
