/* eslint-disable max-lines */
import { ColumnsType } from 'antd/lib/table';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    IdLocalizationEntry,
    IdNameEntry,
    IndividualCoBorrowerListEntry,
    LegalCoBorrowerListEntry,
    TrustPledgedLoan,
} from 'src/generated-api-client';
import { CoBorrowersStatus } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/CoBorrowersStatus';
import { CoBorrowerActions as IndividualCoBorrowerActions } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/IndividualCoBorrowerActions';
import { IndividualCoBorrowerExternalActions } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/IndividualCoBorrowerExternalActions';
import { CoBorrowerActions as LegalEntityCoBorrowerActions } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/LegalEntityCoBorrowerActions';
import { LegalEntityCoBorrowerExternalActions } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/LegalEntityCoBorrowerExternalActions';
import { UrlHelper } from 'src/utils/UrlHelper';

export type CoBorrowersTabProps = { loan?: TrustPledgedLoan };

export const CoBorrowersTab = observer(({ loan }: CoBorrowersTabProps) => {
    const individualCoBorrowers = loan?.individualCoBorrowers;
    const legalCoBorrowers = loan?.legalCoBorrowers;

    return (
        <>
            <Card
                title={t(
                    'Page.TrustLoanDetails.Tab.CoBorrowers.IndividualTitle',
                )}
                extra={<IndividualCoBorrowerExternalActions loan={loan} />}
            >
                <ListView
                    dataSource={individualCoBorrowers}
                    columns={getIndividualColumns(loan)}
                />
            </Card>

            <Card
                title={t('Page.TrustLoanDetails.Tab.CoBorrowers.LegalTitle')}
                extra={<LegalEntityCoBorrowerExternalActions loan={loan} />}
            >
                <ListView
                    dataSource={legalCoBorrowers}
                    columns={getLegalColumns(loan)}
                />
            </Card>
        </>
    );
});

const getIndividualColumns = (
    loan?: TrustPledgedLoan,
): ColumnsType<IndividualCoBorrowerListEntry> => {
    return [
        {
            key: 'coBorrowerId',
            dataIndex: 'coBorrowerId',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.Id" />
            ),
            render(value) {
                return (
                    <Link
                        to={UrlHelper.getTargetUrl(
                            RoutePaths.individualDetails,
                            {
                                individualId: value,
                            },
                        )}
                    >
                        {value}
                    </Link>
                );
            },
        },
        {
            key: 'lastName',
            dataIndex: 'lastName',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.LastName" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'firstName',
            dataIndex: 'firstName',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.FirstName" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'registrationNumber',
            dataIndex: 'registrationNumber',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.RegistrationNumber" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'relationshipType',
            dataIndex: 'relationshipType',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.RelationshipType" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.PhoneNumber" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'kycLevel',
            dataIndex: 'kycLevel',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.kycLevel" />
            ),
            render: (value) => {
                return (
                    <DataView
                        value={value}
                        hideLabel
                        format={(value) => (
                            <EnumView
                                enumName="CUSTOMER_KYC_LEVEL"
                                value={value}
                            />
                        )}
                    />
                );
            },
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.Status" />
            ),
            render(value) {
                return <CoBorrowersStatus status={value} />;
            },
        },
        {
            key: 'actions',
            render: (_, record) => {
                return (
                    <IndividualCoBorrowerActions
                        coBorrower={record}
                        loan={loan}
                    />
                );
            },
        },
    ];
};

const getLegalColumns = (
    loan?: TrustPledgedLoan,
): ColumnsType<LegalCoBorrowerListEntry> => {
    return [
        {
            key: 'coBorrowerId',
            dataIndex: 'coBorrowerId',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.Legal.Id" />
            ),
            render(value) {
                return (
                    <Link
                        to={UrlHelper.getTargetUrl(RoutePaths.legalDetails, {
                            legalId: value,
                        })}
                    >
                        {value}
                    </Link>
                );
            },
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.Legal.CompanyName" />
            ),
        },
        {
            key: 'industry',
            dataIndex: 'industry',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.Legal.Industry" />
            ),
            render: (value: IdLocalizationEntry) => {
                return (value?.l10n as any)?.[i18next.language];
            },
        },
        {
            key: 'registrationNumber',
            dataIndex: 'registrationNumber',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.Legal.RegistrationNumber" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'ceo',
            dataIndex: 'ceo',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.Legal.CEO" />
            ),
            render: (value: IdNameEntry) => {
                return (
                    <Link to={EntityLinks.individuals.itemDetails(value?.id)}>
                        {value?.name}
                    </Link>
                );
            },
        },
        {
            key: 'relationshipType',
            dataIndex: 'relationshipType',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.RelationshipType" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.Legal.PhoneNumber" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: (
                <Trans i18nKey="Page.TrustLoanDetails.Tab.CoBorrowers.List.Legal.Status" />
            ),
            render(value) {
                return <CoBorrowersStatus status={value} />;
            },
        },
        {
            key: 'actions',
            render: (_, record) => {
                return (
                    <LegalEntityCoBorrowerActions
                        coBorrower={record}
                        loan={loan}
                    />
                );
            },
        },
    ];
};
