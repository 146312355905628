import { notification, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HiddenField } from 'src/components/Form/HiddenField';
import { ListSelectField } from 'src/components/Form/ListSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { IndividualListEntry } from 'src/generated-api-client';
import { IndividualsStatus } from 'src/pages/IndividualDetailsPage/Components/IndividualsStatus';
import { IndividualCoBorrowersListFilterForm } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/IndividualCoBorrowersListFilterForm';
import { CoBorrowersStore } from 'src/stores/CoBorrowersStore/CoBorrowersStore';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';
import { LoansStore, LoanType } from 'src/stores/LoansStore/LoansStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const store = CoBorrowersStore;
const individualsStore = IndividualsStore;

type CreateIndividualCoBorrowerDialogProps = ModalRendererProps<{
    loan: LoanType;
}>;

const CreateIndividualCoBorrowerDialogFn = observer(
    ({ loan, ...props }: CreateIndividualCoBorrowerDialogProps) => {
        const isLoading =
            store.createItemLoader.isLoading ||
            individualsStore.listLoader.isLoading;
        useEffect(() => {
            return () => {
                individualsStore.listLoader.reset();
                individualsStore.filterCriteria?.resetFilter();
                individualsStore.pager?.reset();
                store.createItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.createItemLoader.errorData,
        );

        const columns = getIndividualsColumns(props);

        return (
            <Modal
                isLoading={isLoading}
                title={t('Dialog.Loan.CreateCoBorrower.Individual.Title')}
                width={1200}
                onFinish={async (values) => {
                    await store.create(values);

                    if (store.createItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.createItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.Loan.CreateCoBorrower.Message.Success',
                            ),
                        });
                        LoansStore.loadItem(loan?.id);
                    }
                }}
                headerExtra={
                    <IndividualCoBorrowersListFilterForm
                        store={individualsStore}
                    />
                }
                formProps={{ fields }}
                {...props}
            >
                <HiddenField name="type" initialValue="NewLoanCoBorrower" />

                <HiddenField name="loanId" initialValue={loan?.id} />

                <StyledListSelectField
                    name="coBorrowerId"
                    store={individualsStore}
                    columns={columns}
                    rules={[ValidationRules.required]}
                />

                <StyledText>
                    {t('Dialog.Loan.CreateCoBorrower.Individual.Text')}
                </StyledText>
            </Modal>
        );
    },
);

export const CreateIndividualCoBorrowerDialog = addModalRenderer(
    CreateIndividualCoBorrowerDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 14px;
    color: #4d6082;
`;

const StyledListSelectField = styled(ListSelectField)`
    .ant-table-wrapper {
        padding: 10px 0;
    }
`;

const getIndividualsColumns = (
    props: ModalRendererProps,
): ColumnType<IndividualListEntry>[] => {
    return [
        {
            key: 'id',
            dataIndex: 'id',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.Individual.List.Id" />
            ),
            render: (individualId: string) => {
                return (
                    <Link
                        data-test="individual-details-link"
                        to={EntityLinks.individuals.itemDetails(individualId)}
                        onClick={() => {
                            Router.navigate(
                                EntityLinks.individuals.itemDetails(
                                    individualId,
                                ),
                            );

                            props.onCancel();
                        }}
                    >
                        {individualId}
                    </Link>
                );
            },
        },
        {
            key: 'customerName',
            dataIndex: 'customerName',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.Individual.List.CoborrowerName" />
            ),
        },
        {
            key: 'idDocumentRegistrationNumber',
            dataIndex: 'idDocumentRegistrationNumber',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.Individual.List.IdDocumentRegistrationNumber" />
            ),
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.Individual.List.PhoneNumber" />
            ),
        },
        {
            key: 'kycLevel',
            dataIndex: 'kycLevel',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.Individual.List.KycLevel" />
            ),
        },
        {
            key: 'verificationStatus',
            dataIndex: 'verificationStatus',
            title: (
                <Trans i18nKey="Dialog.Loan.CreateCoBorrower.Individual.List.VerificationStatus" />
            ),
            render(value) {
                return <IndividualsStatus status={value} />;
            },
        },
    ];
};
