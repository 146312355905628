import { notification, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HiddenField } from 'src/components/Form/HiddenField';
import { ListSelectField } from 'src/components/Form/ListSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { IndividualListEntry } from 'src/generated-api-client';
import { IndividualsStatus } from 'src/pages/IndividualDetailsPage/Components/IndividualsStatus';
import { IndividualCoBorrowersListFilterForm } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/IndividualCoBorrowersListFilterForm';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const store = IndividualsStore;

type CreateCoBorrowerDialogProps = ModalRendererProps<{
    viewModel: N13TaskViewModelClass;
}>;

const CreateIndividualCoBorrowerDialogFn = observer(
    ({ viewModel, ...props }: CreateCoBorrowerDialogProps) => {
        const applicationId = viewModel?.currentTask?.loanApplication?.id;
        const isLoading =
            viewModel.createCoBorrowerLoader.isLoading ||
            store.listLoader.isLoading;
        useEffect(() => {
            return () => {
                store.listLoader.reset();
                store.filterCriteria?.resetFilter();
                store.pager?.reset();
                viewModel.createCoBorrowerLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            viewModel.createCoBorrowerLoader.errorData,
        );

        const columns = getIndividualsColumns(props);

        return (
            <Modal
                isLoading={isLoading}
                title={t('Dialog.CreateCoBorrower.IndividualTitle')}
                width={1200}
                onFinish={async (values) => {
                    await viewModel.createCoBorrower(values);

                    if (viewModel.createCoBorrowerLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel.createCoBorrowerLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.CreateCoBorrower.Message.Success',
                            ),
                        });
                        viewModel.load();
                    }
                }}
                headerExtra={
                    <IndividualCoBorrowersListFilterForm store={store} />
                }
                formProps={{ fields }}
                {...props}
            >
                <HiddenField
                    name="type"
                    initialValue="NewApplicationCoBorrower"
                />

                <HiddenField
                    name="applicationId"
                    initialValue={applicationId}
                />

                <StyledListSelectField
                    name="coBorrowerId"
                    store={store}
                    columns={columns}
                    rules={[ValidationRules.required]}
                />

                <StyledText>{t('Dialog.CreateCoBorrower.Text')}</StyledText>
            </Modal>
        );
    },
);

export const CreateIndividualCoBorrowerDialog = addModalRenderer(
    CreateIndividualCoBorrowerDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 14px;
    color: #4d6082;
`;

const StyledListSelectField = styled(ListSelectField)`
    .ant-table-wrapper {
        padding: 10px 0;
    }
`;

const getIndividualsColumns = (
    props: ModalRendererProps,
): ColumnType<IndividualListEntry>[] => {
    return [
        {
            key: 'id',
            dataIndex: 'id',
            title: <Trans i18nKey="Dialog.CreateCoBorrower.List.Id" />,
            render: (individualId: string) => {
                return (
                    <Link
                        data-test="individual-details-link"
                        to={EntityLinks.individuals.itemDetails(individualId)}
                        onClick={() => {
                            Router.navigate(
                                EntityLinks.individuals.itemDetails(
                                    individualId,
                                ),
                            );

                            props.onCancel();
                        }}
                    >
                        {individualId}
                    </Link>
                );
            },
        },
        {
            key: 'customerName',
            dataIndex: 'customerName',
            title: (
                <Trans i18nKey="Dialog.CreateCoBorrower.List.CoborrowerName" />
            ),
        },
        {
            key: 'idDocumentRegistrationNumber',
            dataIndex: 'idDocumentRegistrationNumber',
            title: (
                <Trans i18nKey="Dialog.CreateCoBorrower.List.IdDocumentRegistrationNumber" />
            ),
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: <Trans i18nKey="Dialog.CreateCoBorrower.List.PhoneNumber" />,
        },
        {
            key: 'kycLevel',
            dataIndex: 'kycLevel',
            title: <Trans i18nKey="Dialog.CreateCoBorrower.List.KycLevel" />,
        },
        {
            key: 'verificationStatus',
            dataIndex: 'verificationStatus',
            title: (
                <Trans i18nKey="Dialog.CreateCoBorrower.List.VerificationStatus" />
            ),
            render(value) {
                return <IndividualsStatus status={value} />;
            },
        },
    ];
};
