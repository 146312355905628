import { useParams } from 'react-router-dom';

export function useCollectionId() {
    const params = useParams<{ collectionId: string }>();

    return {
        collectionId: params?.collectionId,
        hasRoleId: Boolean(params?.collectionId),
    };
}
