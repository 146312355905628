import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { DebtCollection } from 'src/generated-api-client';
import { DataInformationCard } from 'src/pages/CollectionDetailsPage/Components/DataInformationCard';

export type CollectionLoanSummaryInfoCardProps = {
    collection?: DebtCollection;
};

export const CollectionLoanSummaryInfoCard = observer(
    ({ collection }: CollectionLoanSummaryInfoCardProps) => {
        return (
            <DataInformationCard
                title={t('Page.CollectionDetailsPage.LoansSummary.Title')}
                hasData
                canUpdate={false}
            >
                <Row gutter={16}>
                    <Col xs={8}>
                        <DataView
                            value={collection?.customerTotalLoansCount}
                            label={t(
                                'Page.CollectionDetailsPage.LoansSummary.TotalLoansCount',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={collection?.totalLoansCount}
                            label={t(
                                'Page.CollectionDetailsPage.LoansSummary.TotalOverdueLoansCount',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={collection?.maxDaysOverdue}
                            label={t(
                                'Page.CollectionDetailsPage.LoansSummary.MaxDaysOverdue',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={8}>
                        <DataView
                            value={collection?.principalAmount}
                            label={t(
                                'Page.CollectionDetailsPage.LoansSummary.TotalLoanPrincipalAmount',
                            )}
                            format="money"
                        />
                    </Col>

                    <Col xs={8}>
                        <DataView
                            value={collection?.paymentAmount}
                            label={t(
                                'Page.CollectionDetailsPage.LoansSummary.TotalPaymentAmount',
                            )}
                            format="money"
                        />
                    </Col>
                </Row>
            </DataInformationCard>
        );
    },
);
