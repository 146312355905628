import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { ReviewActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ReviewActionDialogComponent';
import { ReviewInterestAdjustmentActionStore } from 'src/stores/LoanActionsStores/ReviewInterestAdjustmentActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId: number;
}>;

export const ReviewInterestAdjustmentDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ReviewInterestAdjustmentActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ReviewInterestAdjustment.Title');

        return (
            <ReviewActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <DataView
                    value={store.initialValues?.currentTotalInterest}
                    label={t(
                        'Dialog.ReviewInterestAdjustment.CurrentTotalInterest',
                    )}
                    format="money"
                />
                <DataView
                    value={store.initialValues?.proposedTotalInterest}
                    label={t(
                        'Dialog.ReviewInterestAdjustment.NewTotalInterest',
                    )}
                    format="money"
                />
            </ReviewActionDialogComponent>
        );
    },
);

export const ReviewInterestAdjustmentDialog = addModalRenderer(
    ReviewInterestAdjustmentDialogFn,
);
