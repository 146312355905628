import { Button, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { DeleteIcon } from 'src/components/Icons/Icons';
import { ListPage } from 'src/components/ListPage/ListPage';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RestrictionListEntry } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { RestrictionsListHeaderPanel } from 'src/pages/RestrictionsListPage/RestrictionsListHeaderPanel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';
import { RestrictionsStore } from 'src/stores/RestrictionsStore/RestrictionsStore';

const store = RestrictionsStore;
const enumsToLoad: EnumName[] = ['RESTRICTION_LIST_TYPE', 'RESTRICTION_CAUSE'];

export const RestrictionsListPage = observer(() => {
    const isLoading = useEnumsLoader(enumsToLoad);

    return (
        <ListPage
            store={store}
            columns={columns}
            isLoading={isLoading}
            header={
                <RestrictionsListHeaderPanel
                    title={t('Page.RestrictionsList.Header')}
                />
            }
        />
    );
});

export const columns: ColumnsType<RestrictionListEntry> = [
    {
        key: 'firstName',
        dataIndex: 'firstName',
        title: <Trans i18nKey="Page.RestrictionsList.List.CustomerFirstName" />,
        render: (value, record) => {
            return (
                <Link
                    to={EntityLinks.individuals.itemDetails(record.customerId)}
                >
                    {value}
                </Link>
            );
        },
    },
    {
        key: 'lastName',
        dataIndex: 'lastName',
        title: <Trans i18nKey="Page.RestrictionsList.List.CustomerLastName" />,
    },
    {
        key: 'companyName',
        dataIndex: 'companyName',
        title: <Trans i18nKey="Page.RestrictionsList.List.CompanyName" />,
    },
    {
        key: 'registrationNumber',
        dataIndex: 'registrationNumber',
        title: (
            <Trans i18nKey="Page.RestrictionsList.List.RegistrationNumber" />
        ),
    },
    {
        key: 'cause',
        dataIndex: 'cause',
        title: <Trans i18nKey="Page.RestrictionsList.List.RestrictedCause" />,
        render: (value) => (
            <DataView
                value={value}
                hideLabel
                format={(value) => (
                    <EnumView enumName="RESTRICTION_CAUSE" value={value} />
                )}
            />
        ),
    },
    {
        key: 'listType',
        dataIndex: 'listType',
        title: <Trans i18nKey="Page.RestrictionsList.List.ListType" />,
        render: (value) => (
            <DataView
                value={value}
                hideLabel
                format={(value) => (
                    <EnumView enumName="RESTRICTION_LIST_TYPE" value={value} />
                )}
            />
        ),
    },
    {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: <Trans i18nKey="Page.RestrictionsList.List.CreatedAt" />,
        render: (value) => {
            return (
                <DataView value={value} format="date-time-seconds" hideLabel />
            );
        },
    },
    {
        key: 'createdBy',
        dataIndex: ['createdBy', 'name'],
        title: <Trans i18nKey="Page.RestrictionsList.List.CreatedBy" />,
    },
    {
        key: 'type',
        dataIndex: 'type',
        title: <Trans i18nKey="Page.RestrictionsList.List.RestrictionType" />,
        render: (value) => (
            <DataView
                value={value}
                hideLabel
                format={(value) => (
                    <EnumView enumName="RESTRICTION_TYPE" value={value} />
                )}
            />
        ),
    },
    {
        key: 'removeCustomer',
        dataIndex: 'customerId',
        title: '',
        render: (customerId: number) => {
            return (
                <Button
                    type="text"
                    icon={<DeleteIcon />}
                    disabled={
                        !AppUser.hasPermissions(
                            UserPermission.RestrictionListRemove,
                        )
                    }
                    onClick={async () => {
                        await store.remove(customerId);

                        if (!store.removeCustomerLoader.hasError) {
                            notification.success({
                                message: t(
                                    'Page.RestrictionsList.Remove.Message.Success',
                                ),
                            });
                        }
                    }}
                />
            );
        },
    },
];
