import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { UserAvatar } from 'src/components/UserAvatar';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { ActionDefinition } from 'src/utils/UserActionsHelper';
import styled from 'styled-components';

const menuActions: ActionDefinition[] = [
    {
        key: 'profile',
        label: <Trans i18nKey="App.Dropdown.Menu.Profile" />,
        onClick: () => {
            Router.navigate(RoutePaths.profile);
        },
    },
    {
        key: 'change-password',
        label: <Trans i18nKey="App.Dropdown.Menu.ChangePassword" />,
        onClick: () => {
            AuthStore.changePassword();
        },
    },
    {
        key: 'logout',
        label: <Trans i18nKey="App.Dropdown.Menu.Logout" />,
        onClick: () => {
            AuthStore.logout();
        },
    },
];

export const UserMenu = observer(() => {
    return (
        <Dropdown
            items={menuActions}
            trigger={['hover']}
            overlayStyle={{
                position: 'fixed',
                zIndex: 100,
            }}
            name="user-dropdown-menu"
        >
            <StyledUserName>
                <UserName>{AppUser.userProfile?.name}</UserName>
                <StyledAvatar user={AppUser.userProfile} />
            </StyledUserName>
        </Dropdown>
    );
});

const StyledUserName = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const UserName = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    text-align: right;

    color: #2e3e5c;
    margin-right: 8px;
`;

const StyledAvatar = styled(UserAvatar)`
    position: relative;
    display: inline-flex;
    height: 44px;
    width: 44px;
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2f333a;
    text-transform: uppercase;
    font-weight: 700;
    border: 2px solid #dfe0eb;
    color: #ffffff;
`;
