import { Checkbox, Col, Form, Row, Space } from 'antd';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Card } from 'src/components/Card/Card';
import { AuthorityHierarchy } from 'src/generated-api-client';
import { RoleAuthoritiesViewModel } from 'src/pages/RoleEditPage/RoleAuthoritiesViewModel';

const ViewModel = RoleAuthoritiesViewModel;

export const RoleAuthorities = observer(() => {
    const selectedAuthorities = Form.useWatch('authorities') || [];
    const form = Form.useFormInstance();

    useEffect(() => {
        if (ViewModel.currentAuthorities?.size) {
            const currentAuthoritiesDependency = _.difference(
                ViewModel.authoritiesKeys,
                selectedAuthorities,
            );

            form.setFieldsValue({
                authorities: [
                    ...selectedAuthorities,
                    ...currentAuthoritiesDependency,
                ],
            });
        }
        if (selectedAuthorities.length && !ViewModel.currentAuthorities?.size) {
            ViewModel.setInitialAuthorities(selectedAuthorities);
        }
    }, [ViewModel.currentAuthorities?.size, selectedAuthorities]);

    useEffect(() => {
        return () => {
            ViewModel.resetAuthorities();
        };
    }, []);

    return (
        <Form.Item name="authorities">
            <Checkbox.Group>
                {ViewModel.authoritiesGroupedByEntity.map(
                    // eslint-disable-next-line sonarjs/cognitive-complexity
                    ([entity, authorities]) => {
                        const selectedAsMap = Object.fromEntries(
                            (selectedAuthorities || []).map(
                                (authority: AuthorityHierarchy) => [
                                    authority,
                                    true,
                                ],
                            ),
                        );
                        const indeterminate =
                            !authorities.every(
                                (x) => selectedAsMap[x.authority || ''],
                            ) &&
                            authorities.some(
                                (x) => selectedAsMap[x.authority || ''],
                            );

                        const checkedAll = authorities.every(
                            (x) => selectedAsMap[x.authority || ''],
                        );

                        return (
                            <Card
                                title={
                                    <Space>
                                        {t(`Role.AuthorityGroup.${entity}`)}
                                        <Checkbox
                                            skipGroup
                                            indeterminate={indeterminate}
                                            onChange={(e) => {
                                                const groupAuthorities =
                                                    authorities.map(
                                                        (x) => x.authority,
                                                    );
                                                const authorityIntersection =
                                                    _.intersection(
                                                        selectedAuthorities ||
                                                            [],
                                                        groupAuthorities,
                                                    );

                                                if (e.target.checked) {
                                                    ViewModel.setGroupAuthorities(
                                                        authorities,
                                                    );

                                                    const unselectedAuthoritiesFromGroup =
                                                        _.difference(
                                                            groupAuthorities,
                                                            selectedAuthorities,
                                                        );

                                                    form.setFieldsValue({
                                                        authorities: [
                                                            ...selectedAuthorities,
                                                            ...unselectedAuthoritiesFromGroup,
                                                        ],
                                                    });
                                                } else {
                                                    ViewModel.unsetGroupAuthorities(
                                                        authorities,
                                                    );

                                                    form.setFieldsValue({
                                                        authorities:
                                                            _.difference(
                                                                selectedAuthorities,
                                                                authorityIntersection,
                                                            ),
                                                    });
                                                }
                                            }}
                                            value={entity}
                                            checked={checkedAll}
                                            disabled={
                                                checkedAll &&
                                                ViewModel.isDisabledGroupAuthorities(
                                                    entity,
                                                )
                                            }
                                        >
                                            {t(
                                                'Component.RoleAuthorities.SelectAll',
                                            )}
                                        </Checkbox>
                                    </Space>
                                }
                                key={entity}
                            >
                                <Row gutter={16}>
                                    {authorities.map((authority) => {
                                        return (
                                            <Col xs={8} key={authority.value}>
                                                <Checkbox
                                                    disabled={ViewModel.isDisabledAuthority(
                                                        authority,
                                                    )}
                                                    value={authority.authority}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            ViewModel.setAuthority(
                                                                e.target.value,
                                                            );
                                                        } else {
                                                            ViewModel.unsetAuthority(
                                                                e.target.value,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {authority.value}
                                                </Checkbox>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Card>
                        );
                    },
                )}
            </Checkbox.Group>
        </Form.Item>
    );
});
