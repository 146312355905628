/* eslint-disable max-lines */
import { Col, Form as AntForm, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Card } from 'src/components/Card/Card';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { ErrorField } from 'src/components/Form/ErrorField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { N110Task, RepaymentFrequency } from 'src/generated-api-client';
import { repaymentFrequencyOptions } from 'src/pages/TaskDetailsPage/Components/repaymentFrequencyOptions.config';
import { FormHelper } from 'src/utils/FormHelper';
import styled from 'styled-components';

type N110TaskApproveConditionsApproveVariablesProps = {
    currentTask?: N110Task;
};
export const N110TaskApproveConditionsApproveVariables = observer(
    ({ currentTask }: N110TaskApproveConditionsApproveVariablesProps) => {
        const { pledgedProducts } = (currentTask as N110Task) || {};

        const pledgedProductsOptions = pledgedProducts?.map(asOption);
        const form = AntForm.useFormInstance();
        const repaymentFrequency = AntForm.useWatch('repaymentFrequency');
        const isBiWeeklyRepaymentFrequency =
            repaymentFrequency === RepaymentFrequency.BI_WEEKLY;
        const options = FormHelper.objAsOptions(
            {
                true: true,
                false: false,
            },
            'DownPayment.Status.',
        );

        useEffect(() => {
            if (repaymentFrequency && !isBiWeeklyRepaymentFrequency) {
                form.setFields([
                    {
                        name: 'secondRepaymentDate',
                        value: undefined,
                    },
                ]);
            } else if (repaymentFrequency && isBiWeeklyRepaymentFrequency) {
                form.setFields([
                    { name: 'repaymentFrequency', value: undefined },
                ]);
            }
        }, [isBiWeeklyRepaymentFrequency]);

        const isRequiredSecondRepaymentDate = isBiWeeklyRepaymentFrequency
            ? ValidationRules.required
            : {};

        return (
            <Col span={24}>
                <Card
                    title={t(
                        'Task.Form.N110TaskApproveConditions.LoanConditionTitle',
                    )}
                >
                    <Row gutter={[16, 16]}>
                        <HiddenField name="type" />

                        <Col xs={6}>
                            <NumberField
                                name="amount"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.Amount',
                                )}
                                thousandDivider
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col xs={6}>
                            <DatePickerField
                                name="firstRepaymentDate"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.FirstRepaymentDate',
                                )}
                                rules={[ValidationRules.required]}
                                disabledDate={(date) => {
                                    return date.isBefore(moment().endOf('day'));
                                }}
                            />
                        </Col>
                        <Col xs={6}>
                            <NumberField
                                name="commission"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.CommissionPaid',
                                )}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.numberMinValue(0),
                                ]}
                            />
                        </Col>
                        <Col xs={6}>
                            <SelectField
                                name="repaymentFrequency"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.RepaymentFrequency',
                                )}
                                options={repaymentFrequencyOptions}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col xs={6}>
                            <DatePickerField
                                name="secondRepaymentDate"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.SecondRepaymentDate',
                                )}
                                rules={[isRequiredSecondRepaymentDate]}
                                disabled={!isBiWeeklyRepaymentFrequency}
                                disabledDate={(date) => {
                                    return date.isBefore(moment().endOf('day'));
                                }}
                            />
                        </Col>
                        <Col xs={6}>
                            <SelectField
                                name="productId"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.productId',
                                )}
                                options={pledgedProductsOptions}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col xs={6}>
                            <NumberField
                                name="monthlyInterestRate"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.MonthlyInterestRate',
                                )}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.numberMinValue(0),
                                    ValidationRules.numberMaxValue(100),
                                ]}
                            />
                        </Col>
                        <Col xs={6}>
                            <NumberField
                                name="requestedTerm"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.Term',
                                )}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.numberMaxValue(180),
                                    ValidationRules.greaterThanZero,
                                ]}
                            />
                        </Col>
                        <Col xs={6}>
                            <SelectField
                                name="downpaymentPaid"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.DownpaymentPaidStatus',
                                )}
                                options={options}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col xs={6}>
                            <NumberField
                                name="downpaymentPercentage"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.DownpaymentPercentage',
                                )}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col xs={6}>
                            <NumberField
                                name="downpaymentAmount"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.DownpaymentAmount',
                                )}
                                thousandDivider
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col xs={24}>
                            <TextAreaField
                                name="comment"
                                label={t(
                                    'Task.Form.N110TaskApproveConditions.Comment',
                                )}
                            />
                        </Col>
                        <Col xs={24}>
                            <StyledError name={['process', 'otherErrors']} />
                        </Col>
                    </Row>
                </Card>
            </Col>
        );
    },
);

const asOption = (value: any) => ({
    value: value.id,
    label: value.name,
    'data-test': value.name,
});

const StyledError = styled(ErrorField)`
    margin-bottom: 16px;
`;
