import { Button, Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage, BasePageProps } from 'src/components/BasePage/BasePage';
import { useTaskId } from 'src/hooks/useTaskId';
import { TaskViewModel } from 'src/pages/TaskViewPage/TaskViewModel';
import { CurrentWorkingTaskStore } from 'src/stores/CurrentWorkingTaskStore/CurrentWorkingTaskStore';
import { TasksRegisterStore } from 'src/stores/TasksRegisterStore/TasksRegisterStore';
import styled from 'styled-components';

export const TaskViewPage = observer(({ ...props }: BasePageProps) => {
    const { taskId } = useTaskId();
    const viewModel = TaskViewModel.getInstance(taskId);

    useEffect(() => {
        viewModel.load();

        return () => {
            viewModel.reset();
        };
    }, [taskId]);

    const { Component, title } = TasksRegisterStore.getTaskDefinition(
        viewModel.taskName!,
    );

    if (!viewModel.isLoaded) {
        return <BasePage isLoading showOnlyLoading />;
    }

    if (Component) {
        const showStopTaskBtn = viewModel.isCurrentTaskEqualWorkingTask;
        const showStartTaskBtn =
            !viewModel.isCurrentTaskEqualWorkingTask &&
            !CurrentWorkingTaskStore.workingTaskMetadata;
        const showHeader = Boolean(viewModel.currentTask);

        return (
            <BasePage
                {...props}
                isLoading={viewModel.loadingInfo.isLoading}
                showOnlyLoading={!viewModel.isLoaded}
            >
                {showHeader && (
                    <Row gutter={[16, 16]}>
                        <Col span={16}>
                            <Typography.Title level={2}>
                                {title || t(`Task.Name.${viewModel.taskName}`)}
                            </Typography.Title>
                        </Col>
                        <StyledCol span={8}>
                            <ButtonsContainer>
                                {showStopTaskBtn && (
                                    <Button
                                        type="default"
                                        onClick={() => {
                                            viewModel.stopWork();
                                        }}
                                    >
                                        {t('Page.TaskView.Actions.StopWork')}
                                    </Button>
                                )}
                                {showStartTaskBtn && (
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            viewModel.startWork();
                                        }}
                                    >
                                        {t('Page.TaskView.Actions.StartWork')}
                                    </Button>
                                )}
                            </ButtonsContainer>
                        </StyledCol>
                    </Row>
                )}
                <Component viewModel={viewModel} />
            </BasePage>
        );
    }

    return null;
});

const StyledCol = styled(Col)`
    position: relative;
    text-align: right;
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;
