import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { PledgedLoanApplication } from 'src/generated-api-client';
import { PledgedLoanApplicationBrief } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Components/PledgedLoanApplicationBrief';
import { CollateralsTab } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Tabs/CollateralsTab';
import { ConditionTab } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Tabs/ConditionTab';
import { CustomerInfoTab } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Tabs/CustomerInfoTab';
import { DecisionTab } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Tabs/DecisionTab';
import { GeneralInfoTab } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Tabs/GeneralInfoTab';
import { InvoiceTab } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Tabs/InvoiceTab';
import { LimitOffersTab } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Tabs/LimitOffersTab';
import { RepaymentScheduleTab } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Tabs/RepaymentScheduleTab';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

const store = ApplicationsStore;

export const PledgedLoanApplicationDetails = observer(() => {
    const application = store.currentItem as PledgedLoanApplication;

    const tabs: TabDefinition[] = [
        {
            title: t('Page.PledgedLoanApplicationDetails.Tabs.Details'),
            tabKey: 'details',
            Component: <GeneralInfoTab application={application} />,
            visible: AppUser.hasPermissions(
                UserPermission.ApplicationReadGeneralInformation,
            ),
        },
        {
            title: t('Page.PledgedLoanApplicationDetails.Tabs.CustomerInfo'),
            tabKey: 'customer-info',
            Component: <CustomerInfoTab application={application} />,
        },
        {
            title: t('Page.PledgedLoanApplicationDetails.Tabs.Collaterals'),
            tabKey: 'collaterals',
            Component: <CollateralsTab application={application} />,
        },
        {
            title: t('Page.PledgedLoanApplicationDetails.Tabs.Conditions'),
            tabKey: 'conditions',
            Component: <ConditionTab application={application} />,
        },
        {
            title: t('Page.PledgedLoanApplicationDetails.Tabs.Decision'),
            tabKey: 'decision',
            Component: <DecisionTab application={application} />,
        },
        {
            title: t('Page.PledgedLoanApplicationDetails.Tabs.Documents'),
            tabKey: 'documents',
            Component: (
                <DocumentsList
                    documentRoom={application?.documentRoom}
                    canUpload={AppUser.hasPermissions(
                        UserPermission.DocumentLoanApplicationUpload,
                    )}
                />
            ),
            visible: AppUser.hasPermissions(
                UserPermission.DocumentLoanApplicationRead,
            ),
        },
        {
            title: t('Page.PledgedLoanApplicationDetails.Tabs.Invoice'),
            tabKey: 'invoice',
            Component: <InvoiceTab application={application} />,
        },
        {
            title: t('Page.PledgedLoanApplicationDetails.Tabs.LimitOffers'),
            tabKey: 'limit-offers',
            Component: <LimitOffersTab application={application} />,
        },
        {
            title: t(
                'Page.PledgedLoanApplicationDetails.Tabs.RepaymentSchedule',
            ),
            tabKey: 'schedule',
            Component: <RepaymentScheduleTab applicationId={application?.id} />,
            visible: AppUser.hasPermissions(
                UserPermission.ApplicationReadRepaymentSchedule,
            ),
        },
    ];

    return (
        <Row gutter={[16, 32]}>
            <Col xs={24}>
                <PledgedLoanApplicationBrief application={application} />
            </Col>
            <Col xs={24}>
                <Tabs tabs={tabs} />
            </Col>
        </Row>
    );
});
