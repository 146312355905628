import { t } from 'i18next';
import {
    LoanGracePeriodPrecondition,
    LoanGracePeriodProposal,
} from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProposeGracePeriodActionStoreClass extends BasicActionStore<
    LoanGracePeriodPrecondition,
    LoanGracePeriodProposal
> {
    constructor(private loanId: number) {
        super();
    }

    okMessage = t('Dialog.Restructuring.Message.SuccessCreate');

    api: BasicActionStoreApi<
        LoanGracePeriodPrecondition,
        LoanGracePeriodProposal
    > = {
        load: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getGracePeriod({ id: this.loanId }),
            );
        },

        ok: async (data?: LoanGracePeriodProposal) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.proposeGracePeriod({
                    id: this.loanId,
                    loanGracePeriodProposal: data!,
                }),
            );
        },
    };
}

export const ProposeGracePeriodActionStore = new EntityProvider(
    ProposeGracePeriodActionStoreClass,
);
