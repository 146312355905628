import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DotsHorizontalIcon } from 'src/components/Icons/Icons';
import { NewCivilServant } from 'src/generated-api-client';
import { getRecordActivityCivilServantsListActions } from 'src/pages/CollectionDetailsPage/RecordActivityPage/Components/RecordActivityCivilServantsListActions.config';
import styled from 'styled-components';

type RecordActivityCivilServantsListActionsProps = {
    civilServant?: NewCivilServant;
    civilServantIndex?: number;
    onSave?: Dispatch<SetStateAction<any[]>>;
};

export const RecordActivityCivilServantsListActions = observer(
    ({
        civilServant,
        civilServantIndex,
        onSave,
    }: RecordActivityCivilServantsListActionsProps) => {
        const actions = getRecordActivityCivilServantsListActions(
            civilServant,
            civilServantIndex,
            onSave,
        ).filter((action) => !action.disabled);
        const hideActions = actions.length === 0;

        if (hideActions) {
            return null;
        }

        return (
            <Dropdown items={actions} disabled={!actions.length}>
                <StyledIconButton icon={<DotsHorizontalIcon />} />
            </Dropdown>
        );
    },
);

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;
