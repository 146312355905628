import { Input as AntInput, InputProps as AntInputProps } from 'antd';

type InputProps = AntInputProps & {
    // eslint-disable-next-line react/require-default-props
    value?: string;
    // eslint-disable-next-line react/require-default-props
    onChange?: (_value: string | undefined) => void;
    // eslint-disable-next-line react/require-default-props
    readOnly?: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    maxLength?: number;
    // eslint-disable-next-line react/require-default-props
    max?: number;
    // eslint-disable-next-line react/require-default-props
    onlyNumbers?: boolean;
    // eslint-disable-next-line react/require-default-props
    addonAfter?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    placeholder?: any;
};

const ONLY_NUMBERS_PATTERN = /^\d*$/;

export const Input = ({
    onChange,
    value,
    onlyNumbers,
    maxLength,
    max,
    ...props
}: InputProps) => {
    const validateNumbers = (v: string) => {
        if (ONLY_NUMBERS_PATTERN.test(v)) {
            if (max) {
                max >= Number(v)
                    ? onChange?.(v)
                    : onChange?.(value || undefined);
            } else {
                onChange?.(v);
            }
        } else {
            onChange?.(value || undefined);
        }
    };

    return (
        <AntInput
            {...props}
            value={value}
            maxLength={maxLength}
            onChange={(e) => {
                const v = e.target.value;
                if (onlyNumbers) {
                    validateNumbers(v);
                } else {
                    onChange?.(v || undefined);
                }
            }}
        />
    );
};
