/* eslint-disable max-lines */
import { Button } from 'antd';
import classNames from 'classnames';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DotsHorizontalIcon } from 'src/components/Icons/Icons';
import { Spinner } from 'src/components/Spinner/Spinner';
import {
    IncomeCheckStatus,
    IndividualIncomeState,
} from 'src/generated-api-client';
import { useAutoRefresh } from 'src/hooks/useAutoRefresh';
import { IncomeStatus } from 'src/pages/IndividualDetailsPage/Components/IncomeStatus';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { ActionDefinition } from 'src/utils/UserActionsHelper';
import styled from 'styled-components';

export type IncomeCardProps = {
    title?: React.ReactNode;
    className?: string;
    incomeLoader?: AsyncOperationWithStatus<any>;
    briefData?: IndividualIncomeState;
    menuActions: ActionDefinition[];
};

export const IncomeCard: React.FC<IncomeCardProps> = observer(
    ({ title, className, incomeLoader, briefData, menuActions }) => {
        const incomeAutoRefresh = useAutoRefresh(incomeLoader);
        const showActions = menuActions && menuActions.length > 0;

        useEffect(() => {
            if (briefData?.status === IncomeCheckStatus.PENDING) {
                incomeAutoRefresh.startAutoRefresh();
            }

            return () => {
                incomeAutoRefresh.stopAutoRefresh();
            };
        }, [briefData?.status]);

        if (incomeLoader?.isLoading) {
            return (
                <StyledIncomeCardWrapper>
                    <div className="income-card__title">{title}</div>
                    <Spinner />
                </StyledIncomeCardWrapper>
            );
        }

        if (!incomeLoader?.isLoading && briefData) {
            return (
                <StyledIncomeCardWrapper
                    className={classNames('income-card', className)}
                >
                    <StyledCardHead>
                        {showActions && (
                            <Dropdown items={menuActions}>
                                <StyledIconButton
                                    icon={<DotsHorizontalIcon />}
                                />
                            </Dropdown>
                        )}
                    </StyledCardHead>
                    <div className={classNames('income-card__body')}>
                        <div className="income-card__title">{title}</div>
                        {cardRender(briefData)}
                    </div>
                </StyledIncomeCardWrapper>
            );
        }

        return (
            <StyledEmptyIncomeCardWrapper
                className={classNames(
                    'income-card',
                    'income-card_empty',
                    className,
                )}
            >
                <StyledCardHead>
                    {showActions && (
                        <Dropdown items={menuActions}>
                            <StyledIconButton icon={<DotsHorizontalIcon />} />
                        </Dropdown>
                    )}
                </StyledCardHead>
                <StyledTitle>{title}</StyledTitle>
            </StyledEmptyIncomeCardWrapper>
        );
    },
);

const cardRender = (briefData: IndividualIncomeState) => {
    if (
        briefData?.status === IncomeCheckStatus.APPROVED ||
        briefData?.status === IncomeCheckStatus.PENDING
    ) {
        return (
            <>
                <DataView
                    align="horizontal"
                    value={briefData?.monthlyIncome}
                    format="money"
                    label={t('Component.IncomeBriefCard.MonthlyIncome')}
                />
                <DataView
                    align="horizontal"
                    value={briefData?.status}
                    label={t('Component.IncomeBriefCard.Status')}
                    format={(value) => <IncomeStatus status={value} />}
                />
            </>
        );
    }
    if (
        briefData?.status === IncomeCheckStatus.REJECT ||
        briefData?.status === IncomeCheckStatus.ERROR
    ) {
        return (
            <DataView
                align="horizontal"
                value={briefData?.status}
                label={t('Component.IncomeBriefCard.Status')}
                format={(value) => <IncomeStatus status={value} />}
            />
        );
    }
    if (briefData?.status === IncomeCheckStatus.EXPIRED) {
        return (
            <StyledExpireWrapper>
                {t('Component.IncomeBriefCard.ExpireMessage')}
            </StyledExpireWrapper>
        );
    }
};

const StyledIncomeCardWrapper = styled.div`
    background: #ffffff;
    height: 220px;

    border: 1px solid #d7d7d7;
    border-radius: 8px;

    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px 40px;

    transition: all 0.2s ease-out;

    .income-card__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;

        letter-spacing: 0.5px;

        color: #2e3e5c;
        margin-bottom: 32px;
        text-align: center;
    }

    .app-data-view__value,
    .app-data-view__label {
        font-weight: 500;
        font-size: 18px;
        color: #4d6082;
    }

    .app-data-view {
        justify-content: space-between;
        align-items: baseline;
    }
`;

const StyledEmptyIncomeCardWrapper = styled.div`
    border: 1px dashed #82869b;
    border-radius: 8px;
    height: 220px;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledExpireWrapper = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.5px;
    word-break: break-word;
    text-align: center;

    color: #f75725;
`;

const StyledTitle = styled.div`
    max-width: fit-content;
    height: 60px;
    padding: 15px 20px;
    border: 1px dashed #82869b;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    background: linear-gradient(
        90deg,
        #0d73bd -25.12%,
        #0562a5 -25.09%,
        #17c48d 215.89%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
`;

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    box-shadow: none;
    margin-right: 14px;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;

const StyledCardHead = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;
