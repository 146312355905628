/* eslint-disable max-lines */
import { ColumnsType } from 'antd/lib/table';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    IdLocalizationEntry,
    IdNameEntry,
    N13LegalCoBorrower,
} from 'src/generated-api-client';
import { CoBorrowersStatus } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/CoBorrowersStatus';
import { CoBorrowerActions } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/CoBorrowerActions';
import { LegalEntityCoBorrowerExternalActions } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/LegalEntityCoBorrowerExternalActions';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import { UrlHelper } from 'src/utils/UrlHelper';

export type CoBorrowersCardProps = {
    viewModel: N13TaskViewModelClass;
};

export const LegalEntityCoBorrowersCard = observer(
    ({ viewModel }: CoBorrowersCardProps) => {
        const task = viewModel.currentTask || {};
        const { legalCoBorrowers } = task;

        return (
            <Card
                title={t(
                    'Page.N13AddEstimatedConditionsTask.CoBorrowers.LegalTitle',
                )}
                extra={
                    <LegalEntityCoBorrowerExternalActions
                        viewModel={viewModel}
                    />
                }
            >
                <ListView
                    dataSource={legalCoBorrowers}
                    columns={getColumns(viewModel)}
                />
            </Card>
        );
    },
);

const getColumns = (
    viewModel: N13TaskViewModelClass,
): ColumnsType<N13LegalCoBorrower> => {
    return [
        {
            key: 'coBorrowerId',
            dataIndex: 'coBorrowerId',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.LegalEntity.List.Id" />
            ),
            render(value) {
                return (
                    <Link
                        to={UrlHelper.getTargetUrl(RoutePaths.legalDetails, {
                            legalId: value,
                        })}
                    >
                        {value}
                    </Link>
                );
            },
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.LegalEntity.List.CompanyName" />
            ),
        },
        {
            key: 'industry',
            dataIndex: 'industry',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.LegalEntity.List.Industry" />
            ),
            render: (value: IdLocalizationEntry) => {
                return (value?.l10n as any)?.[i18next.language];
            },
        },
        {
            key: 'registrationNumber',
            dataIndex: 'registrationNumber',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.LegalEntity.List.RegistrationNumber" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'ceo',
            dataIndex: 'ceo',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.LegalEntity.List.CEO" />
            ),
            render: (value: IdNameEntry) => {
                return (
                    <Link to={EntityLinks.individuals.itemDetails(value?.id)}>
                        {value?.name}
                    </Link>
                );
            },
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.LegalEntity.List.PhoneNumber" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'relationshipType',
            dataIndex: 'relationshipType',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.LegalEntity.List.RelationshipType" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.LegalEntity.List.Status" />
            ),
            render(value) {
                return <CoBorrowersStatus status={value} />;
            },
        },
        {
            key: 'actions',
            render: (_, record) => {
                return (
                    <CoBorrowerActions
                        coBorrower={record}
                        viewModel={viewModel}
                    />
                );
            },
        },
    ];
};
