import {
    Button,
    Dropdown as AntDropdown,
    DropdownProps as AntDropdownProps,
    Menu,
} from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ArrowDownIcon } from 'src/components/Icons/Icons';
import { ActionDefinition } from 'src/utils/UserActionsHelper';

export type DropdownProps = Omit<AntDropdownProps, 'overlay'> & {
    items: ActionDefinition[];
    name?: string;
};

export const Dropdown: React.FC<DropdownProps> = observer(
    ({ children, items, name, ...props }) => {
        const mergedItems = items.map((item) => {
            return { ...item, 'data-test': item.key };
        });

        return (
            <AntDropdown
                overlay={
                    <Menu
                        items={mergedItems}
                        data-test={name || 'dropdown-menu'}
                    />
                }
                placement="bottomRight"
                trigger={['click']}
                getPopupContainer={() =>
                    document.querySelector('.ant-modal-wrap') ||
                    document.querySelector('#layout-content') ||
                    document.body
                }
                {...props}
            >
                {children || (
                    <Button
                        type="primary"
                        icon={<ArrowDownIcon />}
                        data-test="dropdown-trigger"
                    >
                        {t('Component.Dropdown.Trigger')}
                    </Button>
                )}
            </AntDropdown>
        );
    },
);
