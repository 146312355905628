import { useParams } from 'react-router-dom';

export function useCollateralId() {
    const params = useParams<{ collateralId: string }>();

    return {
        collateralId: params?.collateralId,
        hasCollateralId: Boolean(params?.collateralId),
    };
}
