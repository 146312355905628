import { observer } from 'mobx-react-lite';
import { CreateCollateralEvaluationVariables } from 'src/pages/CollateralCreatePage/Components/CreateCollateralEvaluationVariables';
import { CreateIntangibleAssetCollateralGeneralInfoVariables } from 'src/pages/CollateralCreatePage/IntangibleAssetCollateral/CreateIntangibleAssetCollateralGeneralInfoVariables';

export const CreateIntangibleAssetCollateral = observer(() => {
    return (
        <>
            <CreateIntangibleAssetCollateralGeneralInfoVariables />
            <CreateCollateralEvaluationVariables />
        </>
    );
});
