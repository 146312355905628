import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { CollateralDetailsType } from 'src/generated-api-client';

export const CreateIntangibleAssetCollateralGeneralInfoVariables = observer(
    () => {
        return (
            <Card
                title={t(
                    'Component.CreateIntangibleAssetCollateral.GeneralInfoVariables.Title',
                )}
            >
                <Row gutter={16}>
                    <HiddenField
                        name="type"
                        value={CollateralDetailsType.INTANGIBLE_ASSET}
                    />
                    <Col xs={6}>
                        <TextField
                            name="issuedOrganization"
                            label={t(
                                'Component.CreateIntangibleAssetCollateral.GeneralInfoVariables.IssuedOrganization',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <TextField
                            name="additionalField1"
                            label={t(
                                'Component.CreateIntangibleAssetCollateral.GeneralInfoVariables.AdditionalField1',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={6}>
                        <TextField
                            name="additionalField2"
                            label={t(
                                'Component.CreateIntangibleAssetCollateral.GeneralInfoVariables.AdditionalField2',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <NumberField
                            name="availableAmount"
                            label={t(
                                'Component.CreateIntangibleAssetCollateral.GeneralInfoVariables.AvailableAmount',
                            )}
                            thousandDivider
                        />
                    </Col>

                    <Col xs={6}>
                        <DatePickerField
                            name="expiresAt"
                            label={t(
                                'Component.CreateIntangibleAssetCollateral.GeneralInfoVariables.ExpiresAt',
                            )}
                            disabledDate={(date) => {
                                return date.isSameOrBefore();
                            }}
                        />
                    </Col>
                    <Col xs={6}>
                        <NumberField
                            name="futureIncomeAmount"
                            label={t(
                                'Component.CreateIntangibleAssetCollateral.GeneralInfoVariables.FutureIncomeAmount',
                            )}
                            thousandDivider
                        />
                    </Col>
                    <Col xs={6}>
                        <DatePickerField
                            name="startsAt"
                            label={t(
                                'Component.CreateIntangibleAssetCollateral.GeneralInfoVariables.StartsAt',
                            )}
                            disabledDate={(date) => {
                                return date.isSameOrAfter(
                                    moment().startOf('day'),
                                );
                            }}
                        />
                    </Col>
                    <Col xs={6}>
                        <EnumSelectField
                            name="ownershipType"
                            enumName="COLLATERAL_OWNERSHIP_TYPE"
                            label={t(
                                'Component.CreateIntangibleAssetCollateral.GeneralInfoVariables.OwnershipType',
                            )}
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
