import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { ReviewActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ReviewActionDialogComponent';
import {
    ReviewGracePeriodActionStore,
    ReviewGracePeriodActionStoreClass,
} from 'src/stores/LoanActionsStores/ReviewGracePeriodActionStore';
import { DateHelper } from 'src/utils/DateHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const { Text } = Typography;

const dateIntervalFormat = (store: ReviewGracePeriodActionStoreClass) => {
    if (!store.initialValues?.gracePeriod) {
        return;
    }

    return `${DateHelper.formatDate(
        store.initialValues?.gracePeriod.from as string,
    )} - ${DateHelper.formatDate(
        store.initialValues?.gracePeriod.to as string,
    )}`;
};

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId: number;
}>;

export const ReviewGracePeriodDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ReviewGracePeriodActionStore.getInstance(Number(loanId));
        const title = t('Dialog.ReviewGracePeriod.Title');

        return (
            <ReviewActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={24}>
                        <StyledText>
                            {t('Dialog.ReviewGracePeriod.Text')}
                        </StyledText>
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={store}
                            label={t('Dialog.ReviewGracePeriod.IntervalDate')}
                            format={dateIntervalFormat}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={store.initialValues?.gracePeriod.count}
                            label={t('Dialog.ReviewGracePeriod.Count')}
                        />
                    </Col>
                </Row>
            </ReviewActionDialogComponent>
        );
    },
);

export const ReviewGracePeriodDialog = addModalRenderer(
    ReviewGracePeriodDialogFn,
);

const StyledText = styled(Text)`
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #4d6082;
`;
