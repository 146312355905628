import { ColumnType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { Modal } from 'src/components/Modal/Modal';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { BusinessIncomeListEntry, IdNameEntry } from 'src/generated-api-client';
import { IncomeStatus } from 'src/pages/IndividualDetailsPage/Components/IncomeStatus';
import { IndividualBusinessIncomeStore } from 'src/stores/IndividualBusinessIncomeStore/IndividualBusinessIncomeStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

type BusinessIncomeHistoryDialogFnProps = ModalRendererProps<{
    individualId?: number;
}>;

export const BusinessIncomeHistoryDialogFn = observer(
    ({ individualId, ...props }: BusinessIncomeHistoryDialogFnProps) => {
        const store = IndividualBusinessIncomeStore.getInstance(
            Number(individualId),
        );
        const { isLoading } = store.listLoader;
        const historyColumns = getBusinessIncomeHistoryColumns(props);

        useEffect(() => {
            store.loadList();
        }, []);

        return (
            <Modal
                title={t(
                    'Dialog.BusinessIncomeHistory.Title',
                    'History: Business Income',
                )}
                isLoading={isLoading}
                width={1000}
                footer={false}
                maskClosable={false}
                {...props}
            >
                <StyledListView store={store} columns={historyColumns} />
            </Modal>
        );
    },
);

export const BusinessIncomeHistoryDialog = addModalRenderer(
    BusinessIncomeHistoryDialogFn,
);

const StyledListView = styled(ListView)`
    padding: 10px 0;
`;

const getBusinessIncomeHistoryColumns = (
    props: ModalRendererProps,
): ColumnType<BusinessIncomeListEntry>[] => {
    return [
        {
            title: (
                <Trans i18nKey="Dialog.BusinessIncomeHistory.List.AddedAt" />
            ),
            key: 'createdAt',
            dataIndex: 'createdAt',
            render: (value) => {
                return (
                    <DataView
                        value={value}
                        format="date-time-seconds"
                        hideLabel
                    />
                );
            },
        },
        {
            title: (
                <Trans i18nKey="Dialog.BusinessIncomeHistory.List.AddedBy" />
            ),
            key: 'createdBy',
            dataIndex: 'createdBy',
            render: (value: IdNameEntry) => {
                if (!value) {
                    return (
                        <Trans i18nKey="Dialog.BusinessIncomeHistory.List.AddedBy.System" />
                    );
                }

                const targetUrl = UrlHelper.getTargetUrl(
                    RoutePaths.userDetails,
                    {
                        userId: value?.id,
                    },
                );

                return (
                    <StyledLink
                        onClick={() => {
                            Router.navigate(targetUrl);
                            props.onCancel();
                        }}
                    >
                        {value?.name}
                    </StyledLink>
                );
            },
        },
        {
            title: (
                <Trans i18nKey="Dialog.BusinessIncomeHistory.List.IncomeValue" />
            ),
            key: 'incomeAmount',
            dataIndex: 'incomeAmount',
            render: (value) => {
                return <DataView value={value} format="money" hideLabel />;
            },
        },
        {
            title: (
                <Trans i18nKey="Dialog.BusinessIncomeHistory.List.CheckStatus" />
            ),
            key: 'checkStatus',
            dataIndex: 'checkStatus',
            render: (value) => {
                return <IncomeStatus status={value} />;
            },
        },
    ];
};

const StyledLink = styled.a`
    cursor: pointer;
`;
