/* eslint-disable max-lines */
import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { LoanApplicationStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: LoanApplicationStatus;
};

export const ApplicationsStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: LoanApplicationStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: (
                <EnumView enumName="LOAN_APPLICATION_STATUS" value={status} />
            ),
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [LoanApplicationStatus.INITIAL]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.INITIAL}
            />
        ),
        color: '#8b939b',
        backgroundColor: '#F5F6F7',
    },
    [LoanApplicationStatus.CIAT_REVIEW]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.CIAT_REVIEW}
            />
        ),
        color: '#54595e',
        backgroundColor: '#e0f2ff',
    },
    [LoanApplicationStatus.LAAT_REVIEW]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.LAAT_REVIEW}
            />
        ),
        color: '#54595e',
        backgroundColor: '#e0f2ff',
    },
    [LoanApplicationStatus.CC_REVIEW]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.CC_REVIEW}
            />
        ),
        color: '#54595e',
        backgroundColor: '#e0f2ff',
    },
    [LoanApplicationStatus.RM_REVIEW]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.RM_REVIEW}
            />
        ),
        color: '#54595e',
        backgroundColor: '#e0f2ff',
    },
    [LoanApplicationStatus.ACCOUNTANT_REVIEW]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.ACCOUNTANT_REVIEW}
            />
        ),
        color: '#54595e',
        backgroundColor: '#e0f2ff',
    },
    [LoanApplicationStatus.WAITING_FOR_DISBURSEMENT]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.WAITING_FOR_DISBURSEMENT}
            />
        ),
        color: '#6e5b51',
        backgroundColor: '#fff4cc',
    },
    [LoanApplicationStatus.WAITING_FOR_SIGN_AGREEMENT]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.WAITING_FOR_SIGN_AGREEMENT}
            />
        ),
        color: '#6e5b51',
        backgroundColor: '#fff4cc',
    },

    [LoanApplicationStatus.WAITING_FOR_SIGN_AGREEMENT_BY_CO_BORROWERS]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={
                    LoanApplicationStatus.WAITING_FOR_SIGN_AGREEMENT_BY_CO_BORROWERS
                }
            />
        ),
        color: '#6e5b51',
        backgroundColor: '#fff4cc',
    },
    [LoanApplicationStatus.WAITING_FOR_INVOICE_AMOUNT_CHANGING]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={
                    LoanApplicationStatus.WAITING_FOR_INVOICE_AMOUNT_CHANGING
                }
            />
        ),
        color: '#6e5b51',
        backgroundColor: '#fff4cc',
    },
    [LoanApplicationStatus.PENDING]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.PENDING}
            />
        ),
        color: '#6e5b51',
        backgroundColor: '#fff4cc',
    },
    [LoanApplicationStatus.EXPIRED]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.EXPIRED}
            />
        ),
        color: '#6e5b51',
        backgroundColor: '#fff4cc',
    },
    [LoanApplicationStatus.AGREEMENT_SIGNED]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.AGREEMENT_SIGNED}
            />
        ),
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },

    [LoanApplicationStatus.AGREEMENT_SIGNED_BY_CO_BORROWERS]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.AGREEMENT_SIGNED_BY_CO_BORROWERS}
            />
        ),
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
    [LoanApplicationStatus.SUCCESSFUL_DISBURSEMENT]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.SUCCESSFUL_DISBURSEMENT}
            />
        ),
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
    [LoanApplicationStatus.ERROR_DISBURSEMENT]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.ERROR_DISBURSEMENT}
            />
        ),
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
    [LoanApplicationStatus.REJECTED]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.REJECTED}
            />
        ),
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
    [LoanApplicationStatus.CUSTOMER_CANCELED]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.CUSTOMER_CANCELED}
            />
        ),
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
    [LoanApplicationStatus.CO_BORROWER_CANCELED]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.CO_BORROWER_CANCELED}
            />
        ),
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
    [LoanApplicationStatus.REJECTED_BY_CIAT]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.REJECTED_BY_CIAT}
            />
        ),
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
    [LoanApplicationStatus.REJECTED_BY_LAAT]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.REJECTED_BY_LAAT}
            />
        ),
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
    [LoanApplicationStatus.REJECTED_BY_CC]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.REJECTED_BY_CC}
            />
        ),
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
    [LoanApplicationStatus.REJECTED_BY_RM]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.REJECTED_BY_RM}
            />
        ),
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
    [LoanApplicationStatus.REJECTED_BY_MERCHANT_PORTAL]: {
        title: (
            <EnumView
                enumName="LOAN_APPLICATION_STATUS"
                value={LoanApplicationStatus.REJECTED_BY_MERCHANT_PORTAL}
            />
        ),
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
};
