import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { TaskDecisionType } from 'src/generated-api-client';

export const N24FeeRefundTaskApproveVariables = observer(() => {
    return (
        <Row gutter={16}>
            <HiddenField
                name="type"
                initialValue={TaskDecisionType.N2_4_APPROVE}
            />
            <Col xs={6}>
                <DatePickerField
                    name="refundDate"
                    label={t('Task.Form.N24FeeRefundTask.RefundDate')}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={24}>
                <TextAreaField
                    name="comment"
                    label={t('Task.Form.N24FeeRefundTask.Comment')}
                />
            </Col>
        </Row>
    );
});
