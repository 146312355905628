const MIN_LENGTH = 8;
const MIN_COUNT_DIGITS = 1;
const MIN_COUNT_LETTERS = 1;
const MIN_COUNT_CAPITALIZED_LETTERS = 1;

export const getMinLengthValidator = (length: number) => (value: string) => {
    return value?.length >= length;
};
export const getMinCountOfDigitsValidator =
    (length: number) => (value: string) => {
        return (
            value?.split('').filter((symbol) => /\d/.test(symbol)).length >=
            length
        );
    };
export const getMinCountOfLettersValidator =
    (length: number) => (value: string) => {
        return (
            value?.split('').filter((symbol) => !/\d/.test(symbol)).length >=
            length
        );
    };
export const getMinCountOfCapitalizedLettersValidator =
    (length: number) => (value: string) => {
        return (
            value
                ?.split('')
                .filter((symbol) => !/\d/.test(symbol))
                .map((symbol) => symbol !== symbol.toLowerCase())
                .filter((value) => value).length >= length
        );
    };

export const PasswordValidators = {
    minLengthValidator: getMinLengthValidator(MIN_LENGTH),
    minCountOfDigitsValidator: getMinCountOfDigitsValidator(MIN_COUNT_DIGITS),
    minCountOfLettersValidator:
        getMinCountOfLettersValidator(MIN_COUNT_LETTERS),
    minCountOfCapitalizedLettersValidator:
        getMinCountOfCapitalizedLettersValidator(MIN_COUNT_CAPITALIZED_LETTERS),
};
