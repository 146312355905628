/* eslint-disable init-declarations */
import { DistrictDto, KhorooDto } from 'src/generated-api-client';
import { individualsApi } from 'src/services/apiServices';
import {
    AdministrativeUnitWithId,
    convertToAdministrativeUnitList,
} from 'src/stores/Resources/AdministrativeUnitsStore/AdministrativeUnits.types';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class AdministrativeUnitsStoreClass extends BasicStore<AdministrativeUnitWithId> {
    api: BasicStoreApi<AdministrativeUnitWithId> = {
        loadList: async () => {
            const items = await RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getAllAddresses(),
            );

            return convertToAdministrativeUnitList(items, 'districtDtoList');
        },
    };

    getProvinces() {
        return this.list;
    }

    getDistricts(provinceCode: string) {
        const districts = this.list.find((item) => item.code === provinceCode)
            ?.children as unknown as Array<DistrictDto>;

        if (districts) {
            return convertToAdministrativeUnitList(districts, 'khorooDtoList');
        }
    }

    getKhoroos(provinceCode: string, districtCode: string) {
        const khoroos = this.getDistricts(provinceCode)?.find(
            (item) => item.code === districtCode,
        )?.children as Array<KhorooDto>;

        if (khoroos) {
            return convertToAdministrativeUnitList(khoroos, 'khorooCode');
        }
    }

    getAdministrativeUnitsTreePath(
        provinceCode?: string,
        districtCode?: string,
        khorooCode?: string,
    ) {
        let province: AdministrativeUnitWithId | undefined;
        let district: AdministrativeUnitWithId | undefined;
        let khoroo: AdministrativeUnitWithId | undefined;

        if (provinceCode) {
            province = this.getProvinces().find(
                (unit) => unit.code === provinceCode,
            );
        }

        if (provinceCode && districtCode) {
            district = this.getDistricts(provinceCode)?.find(
                (unit) => unit.code === districtCode,
            );
        }

        if (provinceCode && districtCode && khorooCode) {
            khoroo = this.getKhoroos(provinceCode, districtCode)?.find(
                (unit) => unit.code === khorooCode,
            );
        }

        return [province, district, khoroo];
    }
}

export const AdministrativeUnitsStore = new AdministrativeUnitsStoreClass();
