import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { LeasingCollateral } from 'src/generated-api-client';
import { getLeasingCollateralGeneralInfoActions } from 'src/pages/CollateralDetailsPage/Tabs/LeasingCollateralTab/LeasingCollateralGeneralInfoActions.config';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';

export const LeasingCollateralGeneralInfo = observer(() => {
    const collateral = CollateralsStore.currentItem as LeasingCollateral;
    const menuActions = getLeasingCollateralGeneralInfoActions(collateral);

    return (
        <Card
            title={t('Page.CollateralDetails.Tabs.Leasing.GeneralInfo.Title')}
            menuActions={menuActions}
        >
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral?.itemName}
                        label={t(
                            'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.ItemName',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.quantity}
                        label={t(
                            'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.Quantity',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.assetNumber}
                        label={t(
                            'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.AssetNumber',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.unitPrice}
                        label={t(
                            'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.UnitPrice',
                        )}
                        format="money"
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral?.hasEcoStatus}
                        label={t(
                            'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.hasEcoStatus',
                        )}
                        format="yes-no"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.ownershipType}
                        label={t(
                            'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.OwnershipType',
                        )}
                        format={(value) => (
                            <EnumView
                                enumName="COLLATERAL_OWNERSHIP_TYPE"
                                value={value}
                            />
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral.specifications}
                        label={t(
                            'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.Specifications',
                        )}
                    />
                </Col>
            </Row>
        </Card>
    );
});
