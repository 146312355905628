import { t } from 'i18next';
import { action, computed } from 'mobx';
import { ChildBranchStatus } from 'src/generated-api-client';
import {
    ChildBranchesFilter,
    ChildBranchesStore,
} from 'src/stores/ChildBranchesStore/ChildBranchesStore';
import { ParentBranchesStore } from 'src/stores/ParentBranchesStore/ParentBranchesStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { ViewModel } from 'src/utils/mobx/ViewModel';

export enum LocalBranchId {
    AllBranches = -1,
    Inactive = -2,
}

export class BranchesViewModelClass extends ViewModel {
    activeParentBranchId?: number | LocalBranchId;

    @action
    async load() {
        await ParentBranchesStore.loadList();
        await this.loadChildBranches(LocalBranchId.AllBranches);
    }

    @action
    async reset() {
        await ParentBranchesStore.listLoader.reset();
        await ChildBranchesStore.listLoader.reset();
    }

    @action
    async loadChildBranches(parentBranchId: number | LocalBranchId) {
        this.activeParentBranchId = parentBranchId;

        await ChildBranchesStore.listLoader.reset();
        ChildBranchesStore.filterCriteria.resetFilter();

        const filter: ChildBranchesFilter = {
            parentBranchId,
            status: ChildBranchStatus.ACTIVE,
        };

        if (parentBranchId === LocalBranchId.AllBranches) {
            filter.parentBranchId = undefined;
        }
        if (parentBranchId === LocalBranchId.Inactive) {
            filter.parentBranchId = undefined;
            filter.status = ChildBranchStatus.INACTIVE;
        }

        ChildBranchesStore.filterCriteria.setFilter(filter);
        await ChildBranchesStore.loadList();
    }

    @action
    async reloadChildBranches() {
        await this.loadChildBranches(Number(this.activeParentBranchId));
    }

    @computed get parentBranches() {
        return (
            ParentBranchesStore.list?.map((branch) => {
                return {
                    label: branch.name,
                    value: branch.id,
                };
            }) || []
        );
    }

    @computed get childBranches() {
        return ChildBranchesStore.list;
    }

    @computed get options() {
        return [
            ...this.parentBranches,
            {
                label: t('Page.Branches.Segment.AllBranches'),
                value: LocalBranchId.AllBranches,
            },
            {
                label: t('Page.Branches.Segment.Inactive'),
                value: LocalBranchId.Inactive,
            },
        ];
    }

    @computed get loadingInfo() {
        return new CombinedLoadingStatus([
            ParentBranchesStore.listLoader,
            ChildBranchesStore.listLoader,
        ]);
    }
}

export const BranchesViewModel = new BranchesViewModelClass();
