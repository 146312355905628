import {
    Button,
    Card as AntCard,
    CardProps as AntCardProps,
    Space,
} from 'antd';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DotsHorizontalIcon } from 'src/components/Icons/Icons';
import { Spinner } from 'src/components/Spinner/Spinner';
import { ActionDefinition } from 'src/utils/UserActionsHelper';
import styled from 'styled-components';

type CardProps = AntCardProps & {
    menuActions?: ActionDefinition[];
    isLoading?: boolean;
};

export const Card = ({
    children,
    menuActions,
    extra,
    isLoading,
    ...props
}: CardProps) => {
    const showActions = menuActions && menuActions.length > 0;

    return (
        <StyledCard
            extra={
                <Space size={16}>
                    <div>{extra}</div>
                    <div>
                        {showActions && (
                            <Dropdown items={menuActions} disabled={isLoading}>
                                <StyledIconButton
                                    icon={<DotsHorizontalIcon />}
                                />
                            </Dropdown>
                        )}
                    </div>
                </Space>
            }
            {...props}
        >
            {children}
            {isLoading && <StyledSpinner />}
        </StyledCard>
    );
};

const StyledSpinner = styled(Spinner)`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
`;

const StyledCard = styled(AntCard)`
    border: none;
    border-radius: 8px;
    margin: 8px 0;

    .ant-card-head {
        border-bottom: none;

        .ant-card-head-title {
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;

            letter-spacing: 0.5px;

            color: #4d6082;

            margin-bottom: 0;
            padding-bottom: 16px;
        }
    }

    .ant-card-extra {
        padding: 16px 0;
    }

    .ant-card-body {
        padding: 16px 30px;
    }

    .ant-row + .ant-row {
        border-top: 1px solid #dfe0eb;
        padding-top: 15px;
    }

    + .ant-card {
        margin-top: 24px;
    }

    ${(props) => {
        if (!props?.title) {
            return `.ant-card-head { display: none; }`;
        }
    }}
`;

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    margin-right: -8px;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;
