import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { PlusIcon } from 'src/components/Icons/Icons';
import { ListView } from 'src/components/ListView/ListView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { CustomerGroup } from 'src/generated-api-client';
import { CreateCustomerGroupDialog } from 'src/pages/Settings/CustomerGroups/CustomerGroupsPage/Dialogs/CreateCustomerGroupDialog';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { CustomerGroupsStore } from 'src/stores/CustomerGroupsStore/CustomerGroupsStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

const store = CustomerGroupsStore;

const columns: ColumnsType<CustomerGroup> = [
    {
        key: 'name',
        dataIndex: 'name',
        title: <Trans i18nKey="Page.CustomerGroup.Tabs.Table.Name" />,
        render(name, { id }) {
            return (
                <DataView
                    value={name}
                    hideLabel
                    format="link-internal"
                    formatterProps={{
                        to: UrlHelper.getTargetUrl(
                            RoutePaths.settingsGroupDetails,
                            {
                                entityId: id,
                            },
                        ),
                    }}
                />
            );
        },
    },
    {
        key: 'priority',
        dataIndex: 'priority',
        title: <Trans i18nKey="Page.CustomerGroup.Tabs.Table.Priority" />,
    },
    {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: <Trans i18nKey="Page.CustomerGroup.Tabs.Table.CreatedAt" />,
        render: (value) => {
            return (
                <DataView value={value} hideLabel format="date-time-seconds" />
            );
        },
    },
    {
        key: 'createdBy',
        dataIndex: 'createdBy',
        title: <Trans i18nKey="Page.CustomerGroup.Tabs.Table.CreatedBy" />,
        render(createdBy: CustomerGroup['createdBy']) {
            return (
                <DataView
                    value={createdBy.name}
                    hideLabel
                    format="link-internal"
                    formatterProps={{
                        to: UrlHelper.getTargetUrl(RoutePaths.userDetails, {
                            userId: createdBy.id,
                        }),
                    }}
                />
            );
        },
    },
    {
        key: 'productLines',
        dataIndex: 'productLines',
        title: <Trans i18nKey="Page.CustomerGroup.Tabs.Table.ProductLines" />,
        render(productLines: CustomerGroup['productLines']) {
            return (
                <div>
                    {productLines?.map(({ name }) => (
                        <>
                            {name}
                            <br />
                        </>
                    ))}
                </div>
            );
        },
    },
];

export const CustomerGroupsTab = observer(() => {
    const { t } = useTranslation();
    const canCreate = AppUser.hasPermissions(
        UserPermission.CustomerSettingsSegmentationEdit,
    );

    useEffect(() => {
        store.loadList();

        return () => {
            store.listLoader.reset();
        };
    }, []);

    return (
        <>
            <StyledSpace>
                {canCreate && (
                    <Button
                        icon={<PlusIcon />}
                        type="primary"
                        onClick={() => {
                            CreateCustomerGroupDialog.render();
                        }}
                    >
                        {t('Page.CustomerGroup.Tabs.CreateCustomerGroup')}
                    </Button>
                )}
            </StyledSpace>

            <ListView
                store={store}
                columns={columns}
                loading={store.listLoader.isLoading}
            />
        </>
    );
});

const StyledSpace = styled(Space)`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
`;
