import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    N16Task,
    N16TaskDecisionAddLoanConditions,
    TaskDecisionType,
} from 'src/generated-api-client';
import { TaskCommentsView } from 'src/pages/TaskDetailsPage/Components/TaskCommentsView';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { SubmitTaskDialog } from 'src/pages/TaskDetailsPage/Dialogs/SubmitTaskDialog';
import { N16TaskAddCCConditionsApproveVariables } from 'src/pages/TaskDetailsPage/N16TaskAddССConditions/N16TaskAddССConditionsApproveVariables';
import { N16TaskAddCCConditionsDeclineVariables } from 'src/pages/TaskDetailsPage/N16TaskAddССConditions/N16TaskAddССConditionsDeclineVariables';
import { TaskViewModelClass } from 'src/pages/TaskViewPage/TaskViewModel';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

type N16TaskAddCCConditionsProps = {
    viewModel: TaskViewModelClass;
};

export const N16TaskAddCCConditions = observer(
    ({ viewModel }: N16TaskAddCCConditionsProps) => {
        const task = (viewModel.currentTask as N16Task) || {};
        const {
            loanApplication,
            laatLeaderComment,
            laatUserComment,
            collaterals,
        } = task;

        const comments = [
            {
                label: t('Component.TaskCommentsView.LAATLeaderComment'),
                value: laatLeaderComment,
            },
            {
                label: t('Component.TaskCommentsView.LAATUserComment'),
                value: laatUserComment,
            },
        ];

        return (
            <>
                <StyledRow gutter={[16, 16]}>
                    <Col xs={24}>
                        <StyledText>
                            <Trans i18nKey="Task.Form.N16TaskAddССConditions.Descriptions" />
                        </StyledText>
                    </Col>
                    <Col xs={24}>
                        <TaskLinkView
                            loanApplication={loanApplication}
                            collaterals={collaterals}
                        />
                    </Col>

                    <Col xs={24}>
                        <TaskCommentsView comments={comments} />
                    </Col>
                </StyledRow>

                <TaskForm
                    fields={viewModel.submitTaskErrorFields}
                    initialValues={getInitialValues(task)}
                    onFinish={async (values) => {
                        const result = covertFormValuesToSubmitValues(values);
                        await viewModel.submitTask(result);
                        if (!viewModel.submitTaskLoaderHasError) {
                            Router.navigate(RoutePaths.tasks);
                        }
                    }}
                >
                    <Card
                        title={t(
                            'Task.Form.N16TaskAddССConditions.AddCCConditions',
                        )}
                    >
                        <N16TaskAddCCConditionsApproveVariables />
                    </Card>

                    <Space>
                        <Button
                            type={
                                viewModel.isCurrentTaskEqualWorkingTask
                                    ? 'primary'
                                    : 'default'
                            }
                            htmlType="submit"
                        >
                            {t('Task.Form.Btn.Approve')}
                        </Button>
                        <Button
                            onClick={() => {
                                SubmitTaskDialog.render({
                                    viewModel,
                                    title: t(
                                        'Dialog.Reject.N16TaskAddССConditions.Title',
                                    ),
                                    children: (
                                        <N16TaskAddCCConditionsDeclineVariables />
                                    ),
                                });
                            }}
                        >
                            {t('Task.Form.Btn.Decline')}
                        </Button>
                        <Button
                            onClick={() => {
                                Router.navigate(RoutePaths.tasks);
                            }}
                        >
                            {t('Task.Form.Btn.Back')}
                        </Button>
                    </Space>
                </TaskForm>
            </>
        );
    },
);

const getInitialValues = (data?: N16Task) => {
    return {
        ...data,
        type: TaskDecisionType.N1_6_ADD_LOAN_CONDITIONS,
        secondRepaymentDate:
            data?.secondRepaymentDate && moment(data?.secondRepaymentDate),
        firstRepaymentDate:
            data?.firstRepaymentDate && moment(data?.firstRepaymentDate),
        requestedTerm: data?.term,
    };
};

const covertFormValuesToSubmitValues = (
    data?: N16TaskDecisionAddLoanConditions,
) => {
    return {
        ...data,
        firstRepaymentDate: DateHelper.formatISODate(data?.firstRepaymentDate),
        secondRepaymentDate:
            data?.secondRepaymentDate &&
            DateHelper.formatISODate(data.secondRepaymentDate),
    } as N16TaskDecisionAddLoanConditions;
};

const StyledRow = styled(Row)`
    margin-bottom: 20px;
`;

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
