import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { FilterForm } from 'src/components/Form/FilterForm';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { CirclePlusIcon } from 'src/components/Icons/Icons';
import { CreateCollateralOwnershipInfoDialog } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/Dialogs/CreateCollateralOwnershipInfoDialog';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

type CreateExistingIndividualCoOwnerFilterFormProps = {
    store: BasicStore<any>;
    collateralId: number;
    cancelCurrentModal: () => void;
};
export const CreateExistingIndividualCoOwnerFilterForm = observer(
    ({
        store,
        collateralId,
        cancelCurrentModal,
    }: CreateExistingIndividualCoOwnerFilterFormProps) => {
        return (
            <StyledFilterForm
                store={store}
                panelExtra={
                    <Button
                        icon={<CirclePlusIcon />}
                        onClick={() => {
                            cancelCurrentModal();

                            CreateCollateralOwnershipInfoDialog.render({
                                collateralId,
                            });
                        }}
                    >
                        {t(
                            'Dialog.CreateOwnershipInfo.CreateNonExistingCoOwner',
                        )}
                    </Button>
                }
            >
                <NumberField
                    name="id"
                    label={t(
                        'Dialog.CreateCollateralOwnershipInfo.List.Filter.IndividualId',
                    )}
                    onlyInteger
                />
                <TextField
                    name="civilId"
                    label={t(
                        'Dialog.CreateCollateralOwnershipInfo.List.Filter.CivilId',
                    )}
                />
                <TextField
                    name="customerName"
                    label={t(
                        'Dialog.CreateCollateralOwnershipInfo.List.Filter.Name',
                    )}
                />
                <TextField
                    name="registrationNumber"
                    label={t(
                        'Dialog.CreateCollateralOwnershipInfo.List.Filter.RegistrationNumber',
                    )}
                />
                <TextField
                    name="phone"
                    label={t(
                        'Dialog.CreateCollateralOwnershipInfo.List.Filter.PhoneNumber',
                    )}
                />
            </StyledFilterForm>
        );
    },
);
const StyledFilterForm = styled(FilterForm)`
    margin: 30px 0px;
`;
