import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ReviewActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ReviewActionDialogComponent';
import { ReviewMaturityDateExtensionActionStore } from 'src/stores/LoanActionsStores/ReviewMaturityDateExtensionActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId: number;
}>;

export const ReviewMaturityDateExtensionDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ReviewMaturityDateExtensionActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ReviewMaturityDateExtension.Title');

        return (
            <ReviewActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={store.initialValues?.maturityDate}
                            label={t(
                                'Dialog.ReviewMaturityDateExtension.MaturityDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={store.initialValues?.repaymentFrequency}
                            label={t(
                                'Dialog.ReviewMaturityDateExtension.RepaymentFrequency',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="REPAYMENT_FREQUENCY"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={store.initialValues?.additionalPaymentsCount}
                            label={t(
                                'Dialog.ReviewMaturityDateExtension.AdditionalPaymentsCount',
                            )}
                            format="number-int"
                        />
                    </Col>
                </Row>
            </ReviewActionDialogComponent>
        );
    },
);

export const ReviewMaturityDateExtensionDialog = addModalRenderer(
    ReviewMaturityDateExtensionDialogFn,
);
