/* eslint-disable max-lines */
import {
    Button,
    Col,
    Form as AntForm,
    notification,
    Row,
    Typography,
} from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { ErrorField } from 'src/components/Form/ErrorField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { CirclePlusIcon, ModalCloseIcon } from 'src/components/Icons/Icons';
import { Modal } from 'src/components/Modal/Modal';
import {
    CustomerPaymentAccountUpdate,
    IndividualInformationTabView,
    IndividualPaymentAccountUpdate,
} from 'src/generated-api-client';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { BanksStore } from 'src/stores/Resources/BanksStore/BanksStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

export type UpdateCustomerReferenceDialogFnProps = ModalRendererProps<{
    viewModel?: IndividualDetailsViewModelClass;
}>;

export const UpdateBankAccountDialogFn = observer(
    ({ viewModel, ...props }: UpdateCustomerReferenceDialogFnProps) => {
        useEffect(() => {
            BanksStore.loadList();

            return () => {
                viewModel?.updateBankInformationLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            viewModel?.updateBankInformationLoader.errorData,
        );

        return (
            <Modal
                title={t('Dialog.UpdateBankInformation.Title', 'Bank Account')}
                width={900}
                onFinish={async (values) => {
                    await viewModel?.updateBankInformation(values);

                    if (viewModel?.updateBankInformationLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel?.updateBankInformationLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.UpdateBankInformation.Message.Success',
                                'Banks accounts were updated successfully',
                            ),
                        });
                    }
                }}
                initialValues={getInitialValues(viewModel?.item)}
                isLoading={
                    viewModel?.isUpdateBankInformationLoading ||
                    BanksStore.listLoader.isLoading
                }
                formProps={{ fields }}
                {...props}
            >
                <StyledError name="paymentAccounts" />

                <AntForm.List name="paymentAccounts">
                    {(fields, { add, remove }) => {
                        return (
                            <>
                                {fields.map((field, index) => {
                                    return (
                                        <BankInformationItem
                                            key={field.key}
                                            parentPath={[field.name]}
                                            index={index}
                                            onRemove={() => {
                                                remove(field.name);
                                            }}
                                        />
                                    );
                                })}
                                <Button
                                    type="dashed"
                                    icon={<CirclePlusIcon />}
                                    onClick={() => {
                                        add?.({});
                                    }}
                                >
                                    {t(
                                        'Form.BankInformationEdit.AddItem',
                                        'Add bank account',
                                    )}
                                </Button>
                            </>
                        );
                    }}
                </AntForm.List>
            </Modal>
        );
    },
);

const getInitialValues = (
    item?: IndividualInformationTabView,
): IndividualPaymentAccountUpdate => {
    return {
        paymentAccounts: item?.paymentAccounts?.map((paymentAccount) => {
            return {
                ...paymentAccount,
                bankInformation: paymentAccount.bankInformation?.id,
            } as CustomerPaymentAccountUpdate;
        }) || [{} as CustomerPaymentAccountUpdate],
    };
};

export const UpdateBankAccountDialog = addModalRenderer(
    UpdateBankAccountDialogFn,
);

type BankInformationItemProps = {
    parentPath: Array<any>;
    index?: number;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onRemove?: () => void;
};
const BankInformationItem = observer(
    ({ parentPath, index, onRemove }: BankInformationItemProps) => {
        return (
            <BankInformation>
                <CustomerBankInformationHeader>
                    <StyledTitle level={4}>
                        {t('Form.BankInformationEdit.BankAccount', {
                            index: (index || 0) + 1,
                        })}
                    </StyledTitle>
                    <StyledRemoveButton
                        type="text"
                        icon={<ModalCloseIcon />}
                        onClick={() => {
                            onRemove?.();
                        }}
                        title={t(
                            'Form.BankInformationEdit.RemoteItem',
                            'Remove',
                        )}
                    />
                </CustomerBankInformationHeader>
                <BankInformationBody>
                    <HiddenField name={[...parentPath, 'id']} />
                    <Row gutter={16}>
                        <Col xs={8}>
                            <SelectField
                                name={[...parentPath, 'bankInformation']}
                                label={t(
                                    'Form.BankInformationEdit.BankName',
                                    'Bank Name',
                                )}
                                required
                                rules={[ValidationRules.required]}
                                options={BanksStore.bankOptions}
                            />
                        </Col>
                        <Col xs={8}>
                            <TextField
                                name={[...parentPath, 'accountNumber']}
                                label={t(
                                    'Form.BankInformationEdit.AccountNumber',
                                    'Account Number',
                                )}
                                required
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col xs={8}>
                            <TextField
                                name={[...parentPath, 'accountName']}
                                label={t(
                                    'Form.BankInformationEdit.AccountName',
                                    'Account Name',
                                )}
                                required
                                rules={[ValidationRules.required]}
                            />
                        </Col>

                        <Col xs={8}>
                            <EnumSelectField
                                name={[...parentPath, 'currency']}
                                label={t(
                                    'Form.BankInformationEdit.Currency',
                                    'Currency',
                                )}
                                required
                                rules={[ValidationRules.required]}
                                enumName="CURRENCY"
                            />
                        </Col>
                        <Col xs={8}>
                            <SelectField
                                name={[...parentPath, 'mainAccount']}
                                label={t(
                                    'Form.BankInformationEdit.MainAccount',
                                    'Main',
                                )}
                                required
                                options={FormHelper.objAsOptions(
                                    {
                                        true: true,
                                        false: false,
                                    },
                                    'Primitive.Boolean.',
                                )}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                    </Row>
                </BankInformationBody>
            </BankInformation>
        );
    },
);

const CustomerBankInformationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;

const BankInformation = styled.section``;

const BankInformationBody = styled.section`
    padding: 0 0 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
`;

const StyledRemoveButton = styled(Button)`
    &:hover {
        svg {
            fill: #51688b;
        }
    }
`;

const StyledTitle = styled(Typography.Title)`
    &.ant-typography {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #4d6082;
    }
`;

const StyledError = styled(ErrorField)`
    margin-bottom: 10px;
`;
