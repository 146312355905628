import { t } from 'i18next';
import { ActionType } from 'src/generated-api-client';
import { ChangeLoanApplicationRelationshipManagerDialog } from 'src/pages/ApplicationDetailsPage/Dialogs/ChangeLoanApplicationRelationshipManagerDialog';
import { LoanApplicationType } from 'src/stores/ApplicationsStore/ApplicationsStore';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getApplicationActions = (
    application?: LoanApplicationType,
): ActionDefinition[] => {
    return [...getUIActions(application), ...getServerActions(application)];
};

const getUIActions = (
    _application?: LoanApplicationType,
): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    application?: LoanApplicationType,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(application),
        application?.actions,
    );
};

const getRegisteredServerActions = (
    application?: LoanApplicationType,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.UPDATE_RELATIONSHIP_MANAGER,
            label: t('LoanApplication.Actions.UPDATE_RELATIONSHIP_MANAGER'),
            onClick: () => {
                ChangeLoanApplicationRelationshipManagerDialog.render({
                    applicationId: application?.id,
                });
            },
        },
    ];
};
