import { individualsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class IndividualDetailsLoanLimitStoreClass extends BasicStore<any> {
    api: BasicStoreApi<any, any, any, any> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getAllProductOffer({
                    id: this.individualId,
                }),
            );

            this.expiredAt = result.expiredAt;

            return result.productOfferList || [];
        },
    };

    expiredAt?: string;

    constructor(private individualId: number) {
        super();
    }
}

export const IndividualDetailsLoanLimitStore = new EntityProvider(
    IndividualDetailsLoanLimitStoreClass,
);
