import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';
import { FilterForm } from 'src/components/Form/FilterForm';
import { PlusIcon } from 'src/components/Icons/Icons';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { CollateralsFilterForm } from 'src/pages/CollateralsPage/CollateralsFilterForm';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { DateHelper } from 'src/utils/DateHelper';

type HeaderPanelProps = {
    title: React.ReactNode;
};

export const CollateralsHeaderPanel = observer(
    ({ title }: HeaderPanelProps) => {
        const canCreateCollateral = AppUser.hasPermissions(
            UserPermission.CollateralCreate,
        );

        return (
            <div>
                <FilterForm
                    store={CollateralsStore}
                    title={title}
                    convertFilterCriteriaToFormValues={
                        convertFilterCriteriaToFormValues
                    }
                    convertFormValuesToFilterCriteria={
                        convertFormValuesToFilterCriteria
                    }
                    panelExtra={
                        canCreateCollateral && (
                            <Button
                                icon={<PlusIcon />}
                                type="primary"
                                onClick={() =>
                                    Router.navigate(RoutePaths.collateralCreate)
                                }
                                data-test="create-collateral"
                            >
                                {t(
                                    'Page.CollateralsPage.Button.Create',
                                    'Create Collateral',
                                )}
                            </Button>
                        )
                    }
                >
                    <CollateralsFilterForm />
                </FilterForm>
            </div>
        );
    },
);

const convertFilterCriteriaToFormValues = (values: any) => {
    return {
        ...values,
        establishedDate:
            values.establishedDate && moment(values.establishedDate),
        dateOfManufacture:
            values.dateOfManufacture &&
            moment(values.dateOfManufacture, 'YYYY'),
    };
};
const convertFormValuesToFilterCriteria = (values: any) => {
    return {
        ...values,
        establishedDate: DateHelper.formatISODate(values.establishedDate),
        dateOfManufacture: DateHelper.getYear(values.dateOfManufacture),
    };
};
