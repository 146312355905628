import { Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TaskDecisionType } from 'src/generated-api-client';
import styled from 'styled-components';

export const N17ChooseDmtAssigneeTaskCancelVariables = observer(() => {
    return (
        <>
            <StyledText>
                {t('Dialog.Reject.N17ChooseDmtAssigneeTask.Description')}
            </StyledText>
            <HiddenField
                name="type"
                initialValue={TaskDecisionType.N1_7_CANCEL}
            />
        </>
    );
});

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 16px;
    color: #4d6082;
    margin-bottom: 20px;
`;
