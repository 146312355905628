import { ParentBranch } from 'src/generated-api-client';
import { brunchesApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ParentBranchesStoreClass extends BasicStore<ParentBranch> {
    api: BasicStoreApi<ParentBranch> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                brunchesApi.getAllParentBranches(),
            );

            return result || [];
        },

        createItem: async (data) => {
            await RequestHelper.unwrapFromAxiosPromise(
                brunchesApi.createParentBranch({ parentBranch: data }),
            );
        },
    };
}

export const ParentBranchesStore = new ParentBranchesStoreClass();
