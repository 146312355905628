import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListPage } from 'src/components/ListPage/ListPage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { LoanListEntry } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { LoansStatus } from 'src/pages/LoanDetailsPage/Components/LoansStatus';
import { LoansHeaderPanel } from 'src/pages/LoansPage/LoansHeaderPanel';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const enumsToLoad: EnumName[] = ['PRODUCT_TYPE', 'LOAN_STATUS'];

export const LoansPage = observer(() => {
    const isLoading = useEnumsLoader(enumsToLoad);

    return (
        <ListPage
            store={LoansStore}
            columns={columns}
            isLoading={isLoading}
            header={<LoansHeaderPanel title={t('Page.Loans.Header')} />}
        />
    );
});

export const columns: ColumnsType<LoanListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.Loans.List.Id" />,
        render: (loanId: string, loan: LoanListEntry) => {
            const targetUrl = UrlHelper.getTargetUrl(RoutePaths.loanDetails, {
                loanId,
            });

            return <Link to={targetUrl}>{loan.externalId}</Link>;
        },
    },
    {
        key: 'productType',
        dataIndex: 'productType',
        title: <Trans i18nKey="Page.Loans.List.ProductType" />,
        render(value) {
            return <EnumView enumName="PRODUCT_TYPE" value={value} />;
        },
    },
    {
        key: 'civilId',
        dataIndex: ['customer', 'civilId'],
        title: <Trans i18nKey="Page.Loans.List.CivilId" />,
    },
    {
        key: 'registrationNumber',
        dataIndex: ['customer', 'registrationNumber'],
        title: <Trans i18nKey="Page.Loans.List.RegistrationNumber" />,
    },
    {
        key: 'customer',
        dataIndex: ['customer', 'name'],
        title: <Trans i18nKey="Page.Loans.List.CustomerName" />,
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: <Trans i18nKey="Page.Loans.List.Amount" />,
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'principalBalance',
        dataIndex: 'principalBalance',
        title: <Trans i18nKey="Page.Loans.List.PrincipalBalance" />,
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'relationshipManagerId',
        dataIndex: ['customer', 'relationshipManagerId'],
        title: <Trans i18nKey="Page.Loans.List.RelationshipManagerId" />,
    },
    {
        key: 'branchCode',
        dataIndex: ['customer', 'branchCode'],
        title: <Trans i18nKey="Page.Loans.List.BranchCode" />,
    },
    {
        key: 'disbursementDate',
        dataIndex: 'disbursementDate',
        title: <Trans i18nKey="Page.Loans.List.DisbursementDate" />,
        render: (value) => {
            return (
                <DataView value={value} format="date-time-seconds" hideLabel />
            );
        },
    },
    {
        key: 'loanStatus',
        dataIndex: 'loanStatus',
        title: <Trans i18nKey="Page.Loans.List.LoanStatus" />,
        render(value) {
            return <LoansStatus status={value} />;
        },
    },
];
