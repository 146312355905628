import { action, computed } from 'mobx';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { StartPage } from 'src/generated-api-client';
import { accountsApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProfileStoreClass {
    profileLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(accountsApi.profile()),
    );

    @action async loadProfile() {
        await this.profileLoader.call();
    }

    @computed get profile() {
        return this.profileLoader.data;
    }

    @computed get startPageUrl() {
        switch (this.profileLoader.data?.startPage) {
            case StartPage.APPLICATIONS: {
                return RoutePaths.applications;
            }
            case StartPage.BANK_STATEMENTS: {
                return RoutePaths.bankStatements;
            }
            case StartPage.BRANCHES: {
                return RoutePaths.branches;
            }
            case StartPage.INDIVIDUALS: {
                return RoutePaths.individuals;
            }
            case StartPage.LOANS: {
                return RoutePaths.loans;
            }
            case StartPage.PRODUCTS: {
                return RoutePaths.products;
            }
            case StartPage.ROLES: {
                return RoutePaths.roles;
            }
            case StartPage.TASKS: {
                return RoutePaths.tasks;
            }
            case StartPage.USERS: {
                return RoutePaths.users;
            }
            default: {
                return RoutePaths.index;
            }
        }
    }
}

export const ProfileStore = new ProfileStoreClass();
