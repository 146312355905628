/* eslint-disable max-lines */
import { action, computed } from 'mobx';
import {
    CreateLegalRepresentativeRequest,
    LegalCeoUpdate,
    LegalContactDetailsUpdate,
    LegalGeneralInformationUpdate,
    LegalPaymentAccountUpdate,
    UpdateLegalRepresentativeRequest,
} from 'src/generated-api-client';
import { legalsApi } from 'src/services/apiServices';
import { LegalEntitiesStore } from 'src/stores/LegalEntitiesStore/LegalEntitiesStore';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { ViewModel } from 'src/utils/mobx/ViewModel';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LegalEntityDetailsViewModelClass extends ViewModel {
    constructor(private legalId: number) {
        super();
    }

    itemLoader = LegalEntitiesStore.itemLoader;

    @computed get item() {
        return LegalEntitiesStore.currentItem;
    }

    @action
    async load() {
        await LegalEntitiesStore.loadItem(this.legalId);
    }

    @action
    async reset() {
        await LegalEntitiesStore.itemLoader.reset();
    }

    getLoanSummaryLoader = new AsyncOperationWithStatus(() => {
        return RequestHelper.unwrapFromAxiosPromise(
            legalsApi.getLegalLoanSummary({
                id: this.legalId,
            }),
        );
    });

    @action async loadLoanSummary() {
        await this.getLoanSummaryLoader.call();
    }

    @computed get isGetLoanSummaryLoaderLoading() {
        return this.getLoanSummaryLoader.isLoading;
    }

    @computed get loanSummary() {
        return this.getLoanSummaryLoader.data;
    }

    otherInformationLoader = new AsyncOperationWithStatus(
        () => {
            return RequestHelper.unwrapFromAxiosPromise(
                legalsApi.getLegalOtherInformation({
                    id: this.legalId,
                }),
            );
        },
        { defaultIsLoading: true },
    );

    @action async loadOtherInformation() {
        await this.otherInformationLoader.call();
    }

    @computed get otherInformation() {
        return this.otherInformationLoader.data;
    }

    updateBankInformationLoader = new AsyncOperationWithStatus(
        (data: LegalPaymentAccountUpdate) => {
            return RequestHelper.unwrapFromAxiosPromise(
                legalsApi.patchLegal({
                    id: this.legalId,
                    patchLegalRequest: {
                        ...data,
                        type: 'LegalPaymentAccountUpdate',
                    },
                }),
            );
        },
    );

    @action async updateBankInformation(data: LegalPaymentAccountUpdate) {
        await this.updateBankInformationLoader.call(data);
        LegalEntitiesStore.loadItem(this.legalId);
    }

    @computed get isUpdateBankInformationLoading() {
        return this.updateBankInformationLoader.isLoading;
    }

    updateContactInformationLoader = new AsyncOperationWithStatus(
        (data: LegalContactDetailsUpdate) => {
            return RequestHelper.unwrapFromAxiosPromise(
                legalsApi.patchLegal({
                    id: this.legalId,
                    patchLegalRequest: {
                        ...data,
                        type: 'LegalContactDetailsUpdate',
                    },
                }),
            );
        },
    );

    @action async updateContactInformation(data: LegalContactDetailsUpdate) {
        await this.updateContactInformationLoader.call(data);
        LegalEntitiesStore.loadItem(this.legalId);
    }

    @computed get isUpdateContactInformationLoading() {
        return this.updateContactInformationLoader.isLoading;
    }

    updateCEOInformationLoader = new AsyncOperationWithStatus(
        (data: LegalCeoUpdate) => {
            return RequestHelper.unwrapFromAxiosPromise(
                legalsApi.patchLegal({
                    id: this.legalId,
                    patchLegalRequest: {
                        ...data,
                        type: 'LegalCeoUpdate',
                    },
                }),
            );
        },
    );

    @action async updateCEOInformation(data: LegalCeoUpdate) {
        await this.updateCEOInformationLoader.call(data);
        LegalEntitiesStore.loadItem(this.legalId);
    }

    @computed get isUpdateCEOInformationLoading() {
        return this.updateCEOInformationLoader.isLoading;
    }

    updateGeneralInformationLoader = new AsyncOperationWithStatus(
        (data: LegalGeneralInformationUpdate) => {
            return RequestHelper.unwrapFromAxiosPromise(
                legalsApi.patchLegal({
                    id: this.legalId,
                    patchLegalRequest: {
                        ...data,
                        type: 'LegalGeneralInformationUpdate',
                    },
                }),
            );
        },
    );

    @action async updateGeneralInformation(
        data: LegalGeneralInformationUpdate,
    ) {
        await this.updateGeneralInformationLoader.call(data);
        LegalEntitiesStore.loadItem(this.legalId);
    }

    @computed get isUpdateGeneralInformationLoading() {
        return this.updateGeneralInformationLoader.isLoading;
    }

    createRepresentativeLoader = new AsyncOperationWithStatus(
        (data: CreateLegalRepresentativeRequest) => {
            return RequestHelper.unwrapFromAxiosPromise(
                legalsApi.createLegalRepresentative({
                    id: this.legalId,
                    createLegalRepresentativeRequest: data,
                }),
            );
        },
    );

    @action async createRepresentative(data: CreateLegalRepresentativeRequest) {
        await this.createRepresentativeLoader.call(data);
    }

    @computed get isCreateRepresentativeLoading() {
        return this.updateRepresentativeLoader.isLoading;
    }

    updateRepresentativeLoader = new AsyncOperationWithStatus(
        (representativeId: number, data: UpdateLegalRepresentativeRequest) => {
            return RequestHelper.unwrapFromAxiosPromise(
                legalsApi.updateLegalRepresentative({
                    id: this.legalId,
                    representativeId,
                    updateLegalRepresentativeRequest: data,
                }),
            );
        },
    );

    @action async updateRepresentative(
        representativeId: number,
        data: UpdateLegalRepresentativeRequest,
    ) {
        await this.updateRepresentativeLoader.call(representativeId, data);
    }

    @computed get isUpdateRepresentativeLoading() {
        return this.updateRepresentativeLoader.isLoading;
    }

    removeRepresentativeLoader = new AsyncOperationWithStatus(
        (representativeId: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                legalsApi.deleteLegalRepresentative({
                    id: this.legalId,
                    representativeId,
                }),
            );
        },
    );

    @action async removeRepresentative(representativeId: number) {
        await this.removeRepresentativeLoader.call(representativeId);
    }

    @computed get isRemoveRepresentativeLoading() {
        return this.removeRepresentativeLoader.isLoading;
    }
}

export const LegalEntityDetailsViewModel = new EntityProvider(
    LegalEntityDetailsViewModelClass,
);
