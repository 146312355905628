/* eslint-disable max-lines */
import { Col, Row, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { Modal } from 'src/components/Modal/Modal';
import { NewCivilServant } from 'src/generated-api-client';
import { DebtCollectionActivitiesStoreClass } from 'src/stores/DebtCollectionsActivityStore/DebtCollectionsActivityStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

export type CollectionActivityDetailsDialogProps = ModalRendererProps<{
    store: DebtCollectionActivitiesStoreClass;
    activityId: number;
    title?: React.ReactNode;
}>;

export const CollectionActivityDetailsDialogFn = observer(
    ({
        store,
        activityId,
        title,
        ...props
    }: CollectionActivityDetailsDialogProps) => {
        useEffect(() => {
            store.loadItem(activityId);

            return () => {
                store.itemLoader.reset();
            };
        }, [activityId]);

        const item = store.currentItem;

        return (
            <StyledModal
                isLoading={store.itemLoader.isLoading}
                title={
                    title ||
                    t('Dialog.CollectionActivityDetailsDialog.Title', {
                        ordinalNumber: '',
                    })
                }
                {...props}
                footer={false}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={item?.createdAt}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.CreatedAt',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={item?.department}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.Department',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="BO_USER_DEPARTMENT"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={item?.debtCollector?.name}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.DebtCollector',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={item?.generalType}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLECTION_ACTIVITY_GENERAL_TYPE"
                                    value={value}
                                />
                            )}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.GeneralType',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={item?.status}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.Status',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLECTION_ACTIVITY_STATUS"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={item?.type}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.Type',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLECTION_ACTIVITY_TYPE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={item?.subType}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.SubType',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLECTION_ACTIVITY_SUB_TYPE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={item?.contactedPerson}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.ContactedPerson',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLECTION_ACTIVITY_CONTACTED_PERSON"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={item?.level}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.Level',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLECTION_LEVEL"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={item?.overdueReason}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.OverdueReason',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLECTION_ACTIVITY_OVERDUE_REASON"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={item?.nextActionDate}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.NextActionDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={item?.nextAction}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.NextAction',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="COLLECTION_ACTIVITY_NEXT_ACTION"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={item?.commitmentDate}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.CommitmentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={item?.commitmentAmount}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.CommitmentAmount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={item?.comment}
                            label={t(
                                'Dialog.CollectionActivityDetailsDialog.Comment',
                            )}
                        />
                    </Col>
                </Row>

                <StyledTitle>
                    {t(
                        'Dialog.CollectionActivityDetailsDialog.CivilServant.Title',
                    )}
                </StyledTitle>

                <StyledTable
                    dataSource={item?.civilServants}
                    columns={civilServantsColumns}
                />
            </StyledModal>
        );
    },
);

export const CollectionActivityDetailsDialog = addModalRenderer(
    CollectionActivityDetailsDialogFn,
);

const StyledModal = styled(Modal)`
    min-width: 70vw;

    .ant-row {
        + .ant-row {
            border-top: 1px solid #dfe0eb;
            padding-top: 15px;
        }
    }
`;

const StyledTitle = styled(Typography.Title)`
    margin-top: 20px;
    &.ant-typography {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #2e3e5c;
    }
`;

const StyledTable = styled(ListView)`
    padding: 0px !important;
`;

const civilServantsColumns: ColumnsType<NewCivilServant> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: (
            <Trans i18nKey="Dialog.CollectionActivityDetailsDialog.CivilServant.List.Id" />
        ),
        render: (_value, _record, index) => index + 1,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: (
            <Trans i18nKey="Dialog.CollectionActivityDetailsDialog.CivilServant.List.Name" />
        ),
    },
    {
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
        title: (
            <Trans i18nKey="Dialog.CollectionActivityDetailsDialog.CivilServant.List.PhoneNumber" />
        ),
    },
    {
        key: 'workPhoneNumber',
        dataIndex: 'workPhoneNumber',
        title: (
            <Trans i18nKey="Dialog.CollectionActivityDetailsDialog.CivilServant.List.WorkPhoneNumber" />
        ),
    },
    {
        key: 'position',
        dataIndex: 'position',
        title: (
            <Trans i18nKey="Dialog.CollectionActivityDetailsDialog.CivilServant.List.Position" />
        ),
    },
    {
        key: 'organization',
        dataIndex: 'organization',
        title: (
            <Trans i18nKey="Dialog.CollectionActivityDetailsDialog.CivilServant.List.Organization" />
        ),
    },
    {
        key: 'workAddress',
        dataIndex: 'workAddress',
        title: (
            <Trans i18nKey="Dialog.CollectionActivityDetailsDialog.CivilServant.List.WorkAddress" />
        ),
    },
];
