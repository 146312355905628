import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { ErrorField } from 'src/components/Form/ErrorField';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N113Task } from 'src/generated-api-client';
import { TaskCommentsView } from 'src/pages/TaskDetailsPage/Components/TaskCommentsView';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { SubmitTaskDialog } from 'src/pages/TaskDetailsPage/Dialogs/SubmitTaskDialog';
import { CcConditionCard } from 'src/pages/TaskDetailsPage/N113ApproveDisbursmentTask/Components/CcConditionCard';
import { CustomerInformationCard } from 'src/pages/TaskDetailsPage/N113ApproveDisbursmentTask/Components/CustomerInformationCard';
import { RmConditionCard } from 'src/pages/TaskDetailsPage/N113ApproveDisbursmentTask/Components/RmConditionCard';
import { N113ApproveDisbursementTaskApproveVariables } from 'src/pages/TaskDetailsPage/N113ApproveDisbursmentTask/N113ApproveDisbursementTaskApproveVariables';
import { N113ApproveDisbursementTaskReturnVariables } from 'src/pages/TaskDetailsPage/N113ApproveDisbursmentTask/N113ApproveDisbursementTaskReturnVariables';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';
import styled from 'styled-components';

export const N113ApproveDisbursementTask = observer(
    ({ viewModel }: TaskProps<N113Task>) => {
        const task = viewModel.currentTask || {};
        const {
            loanApplication,
            creditCommitteeConditions,
            relationshipManagerConditions,
            collaterals,
        } = task;
        const showCcConditions = Boolean(creditCommitteeConditions);
        const showRmConditions = Boolean(relationshipManagerConditions);

        const comments = [
            {
                label: t('Component.TaskCommentsView.RMComment'),
                value: relationshipManagerConditions?.comment,
            },
            {
                label: t('Component.TaskCommentsView.CSComment'),
                value: creditCommitteeConditions?.comment,
            },
        ];

        return (
            <Row gutter={[16, 24]}>
                <Col xs={24}>
                    <StyledText>
                        <Trans i18nKey="Page.N113ApproveDisbursementTask.Descriptions" />
                    </StyledText>
                </Col>
                <Col xs={24}>
                    <TaskLinkView
                        loanApplication={loanApplication}
                        collaterals={collaterals}
                    />
                </Col>
                <Col xs={24}>
                    <CustomerInformationCard task={task} />
                </Col>
                <Col xs={24}>
                    {showCcConditions && <CcConditionCard task={task} />}
                </Col>
                <Col xs={24}>
                    {showRmConditions && <RmConditionCard task={task} />}
                </Col>
                <Col span={24}>
                    <TaskCommentsView comments={comments} />
                </Col>
                <Col xs={24}>
                    <TaskForm
                        fields={viewModel.submitTaskErrorFields}
                        onFinish={(values) => {
                            SubmitTaskDialog.render({
                                viewModel,
                                submitValues: values,
                                title: t(
                                    'Dialog.Reject.N113ApproveDisbursementTask.Title',
                                ),
                                children: (
                                    <Space direction="vertical" size={16}>
                                        {t(
                                            'Dialog.Reject.N113ApproveDisbursementTask.Description',
                                        )}
                                        <ErrorField
                                            name={['process', 'otherErrors']}
                                        />
                                    </Space>
                                ),
                            });
                        }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <N113ApproveDisbursementTaskApproveVariables />
                            </Col>

                            <Col span={24}>
                                <Space size={20}>
                                    <Button
                                        type={
                                            viewModel.isCurrentTaskEqualWorkingTask
                                                ? 'primary'
                                                : 'default'
                                        }
                                        htmlType="submit"
                                    >
                                        {t('Task.Form.N113Task.Btn.Approve')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            SubmitTaskDialog.render({
                                                viewModel,
                                                title: t(
                                                    'Dialog.N113Task.Return.Title',
                                                ),
                                                children: (
                                                    <N113ApproveDisbursementTaskReturnVariables />
                                                ),
                                            });
                                        }}
                                    >
                                        {t('Task.Form.Btn.Return')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            Router.navigate(RoutePaths.tasks);
                                        }}
                                    >
                                        {t('Task.Form.Btn.Back')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </TaskForm>
                </Col>
            </Row>
        );
    },
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
