import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N41Task, TaskDecisionType } from 'src/generated-api-client';
import { TaskAssigneeSelect } from 'src/pages/TaskDetailsPage/Components/TaskAssigneeSelect';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';
import styled from 'styled-components';

export const N41ManualReAssignmentTask = observer(
    ({ viewModel }: TaskProps<N41Task>) => {
        const task = viewModel.currentTask || {};
        const { loanApplication, collaterals, customer } = task;

        return (
            <Row gutter={16}>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <StyledText>
                                <Trans i18nKey="Page.N41ManualReAssignmentTask.Descriptions" />
                            </StyledText>
                        </Col>
                        <Col xs={24}>
                            <TaskLinkView
                                loanApplication={loanApplication}
                                collaterals={collaterals}
                                individual={customer}
                            />
                        </Col>

                        <Col xs={24}>
                            <Card title={t(`Task.Name.${task.targetTaskName}`)}>
                                <TaskForm
                                    fields={viewModel.submitTaskErrorFields}
                                    onFinish={async (values) => {
                                        await viewModel.submitTask(values);
                                        if (
                                            !viewModel.submitTaskLoaderHasError
                                        ) {
                                            Router.navigate(RoutePaths.tasks);
                                        }
                                    }}
                                >
                                    <HiddenField
                                        name="type"
                                        initialValue={
                                            TaskDecisionType.N4_1_CHOOSE_ASSIGNEE
                                        }
                                    />

                                    <Col span={12}>
                                        <TaskAssigneeSelect
                                            label={t(
                                                'Task.Form.N41Task.AvailableMakers',
                                            )}
                                            assignees={task?.users}
                                        />
                                    </Col>

                                    <Col span={24}>
                                        <Space size={20}>
                                            <Button
                                                type={
                                                    viewModel.isCurrentTaskEqualWorkingTask
                                                        ? 'primary'
                                                        : 'default'
                                                }
                                                htmlType="submit"
                                            >
                                                {t(
                                                    'Task.Form.N41Task.Btn.Approve',
                                                )}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    Router.navigate(
                                                        RoutePaths.tasks,
                                                    );
                                                }}
                                            >
                                                {t('Task.Form.Btn.Back')}
                                            </Button>
                                        </Space>
                                    </Col>
                                </TaskForm>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    },
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
