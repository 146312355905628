import { DefaultOptionType } from 'antd/lib/select';
import { EnumView } from 'src/components/EnumView/EnumView';
import { RepaymentFrequency } from 'src/generated-api-client';

export const repaymentFrequencyOptions: DefaultOptionType[] = [
    {
        label: (
            <EnumView
                enumName="REPAYMENT_FREQUENCY"
                value={RepaymentFrequency.MONTHLY}
            />
        ),
        value: RepaymentFrequency.MONTHLY,
    },
    // {
    //     label: (
    //         <EnumView
    //             enumName="REPAYMENT_FREQUENCY"
    //             value={RepaymentFrequency.BI_WEEKLY}
    //         />
    //     ),
    //     value: RepaymentFrequency.BI_WEEKLY,
    // },
    {
        label: (
            <EnumView
                enumName="REPAYMENT_FREQUENCY"
                value={RepaymentFrequency.ONCE_PER_SEASON}
            />
        ),
        value: RepaymentFrequency.ONCE_PER_SEASON,
    },
];
