import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DotsHorizontalIcon } from 'src/components/Icons/Icons';
import { Insurance } from 'src/generated-api-client';
import { getCollateralInsuranceListActions } from 'src/pages/CollateralDetailsPage/Components/CollateralInsurance/CollateralInsuranceListActions.config';
import { Collateral } from 'src/utils/CollateralsHelper';
import styled from 'styled-components';

type CollateralInsuranceListActionsProps = {
    insurance: Insurance;
    collateral: Collateral;
};

export const CollateralInsuranceListActions = observer(
    ({ insurance, collateral }: CollateralInsuranceListActionsProps) => {
        const actions = getCollateralInsuranceListActions(
            collateral,
            insurance,
        ).filter((action) => !action.disabled);
        const hideActions = actions.length === 0;

        if (hideActions) {
            return null;
        }

        return (
            <Dropdown items={actions} disabled={!actions.length}>
                <StyledIconButton icon={<DotsHorizontalIcon />} />
            </Dropdown>
        );
    },
);

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;
