import { useEffect } from 'react';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';
import { EnumsLoadersClass } from 'src/utils/EnumHelper';

export function useEnumsLoader(enumsToLoad: EnumName[]) {
    const enumsLoaders = new EnumsLoadersClass(enumsToLoad);
    const { isLoading } = enumsLoaders.loadingInfo;

    useEffect(() => {
        enumsLoaders.loadEnums();
    }, []);

    return isLoading;
}
