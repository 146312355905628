const setItem = (key: string, data: any) => {
    try {
        const serializedData = JSON.stringify(data);
        localStorage.setItem(key, serializedData);
    } catch (error) {
        console.warn(error);
    }
};

const setItemWithWithEncryption = (key: string, data: any) => {
    try {
        const serializedData = JSON.stringify(data);
        const encodeData = Buffer.from(serializedData, 'utf8').toString(
            'base64',
        );
        localStorage.setItem(key, encodeData);
    } catch (error) {
        console.warn(error);
    }
};

const getItem = <T = any>(key: string) => {
    try {
        const data = localStorage.getItem(key);
        if (data) {
            return JSON.parse(data) as T;
        }
    } catch (error) {
        console.warn(error);
    }
};

const getItemWithEncryption = <T = any>(key: string) => {
    try {
        const data = localStorage.getItem(key);
        if (data) {
            return JSON.parse(
                Buffer.from(data, 'base64').toString('utf8'),
            ) as T;
        }
    } catch (error) {
        console.warn(error);
    }
};

const removeItem = (key: string) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.warn(error);
    }
};

export const LocalStoreHelper = {
    setItem,
    setItemWithWithEncryption,
    getItem,
    getItemWithEncryption,
    removeItem,
};
