import { notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { DebtCollection } from 'src/generated-api-client';
import { DebtCollectionsStore } from 'src/stores/DebtCollectionsStore/DebtCollectionsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const store = DebtCollectionsStore;

type UpdateCollectionLevelDialogProps = ModalRendererProps<{
    collectionId: number;
    collection?: DebtCollection;
}>;

export const UpdateCollectionLevelDialogFn = observer(
    ({
        collectionId,
        collection,
        ...props
    }: UpdateCollectionLevelDialogProps) => {
        const fields = FormHelper.getFieldDataItems(
            store.updateCollectionLevelLoader.errorData,
        );

        return (
            <Modal
                title={t('Dialog.UpdateCollectionLevel.Title')}
                isLoading={store.updateCollectionLevelLoader.isLoading}
                onFinish={async (values) => {
                    await store.updateCollectionLevel(collectionId, values);

                    if (store.updateCollectionLevelLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.updateCollectionLevelLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.UpdateCollectionLevel.Message.Success',
                            ),
                        });
                        store.loadItem(collectionId);
                    }
                }}
                formProps={{ fields }}
                initialValues={collection}
                {...props}
            >
                <EnumSelectField
                    enumName="COLLECTION_LEVEL"
                    name="level"
                    label={t('Dialog.UpdateCollectionLevelDialog.Level')}
                    rules={[ValidationRules.required]}
                    showSearch
                />
            </Modal>
        );
    },
);

export const UpdateCollectionLevelDialog = addModalRenderer(
    UpdateCollectionLevelDialogFn,
);
