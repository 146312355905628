import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { VerificationCheckDecision } from 'src/generated-api-client';
import { EnumStore } from 'src/stores/Resources/EnumStore/EnumStore';

type DecisionProps = {
    decision?: VerificationCheckDecision;
};

export const CreditScoringDecision = observer(({ decision }: DecisionProps) => {
    const decisionInfo = getDecisionInfo(decision);

    return <StatusView statusInfo={decisionInfo} />;
});

const getDecisionInfo = (decision?: VerificationCheckDecision) => {
    return (
        (decision && decisionInfoMap[decision]) || {
            title: EnumStore.getInstance(
                'VERIFICATION_CHECK_DECISION',
            ).getLocalizedEnum(decision ?? ''),
        }
    );
};

const decisionInfoMap: Record<string, StatusInfo> = {
    [VerificationCheckDecision.APPROVED]: {
        title: (
            <EnumView
                enumName="VERIFICATION_CHECK_DECISION"
                value={VerificationCheckDecision.APPROVED}
            />
        ),
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
    [VerificationCheckDecision.DECLINED]: {
        title: (
            <EnumView
                enumName="VERIFICATION_CHECK_DECISION"
                value={VerificationCheckDecision.DECLINED}
            />
        ),
        color: '#fa656c',
        backgroundColor: '#FFE4E4',
    },
    [VerificationCheckDecision.ERROR]: {
        title: (
            <EnumView
                enumName="VERIFICATION_CHECK_DECISION"
                value={VerificationCheckDecision.ERROR}
            />
        ),
        color: '#fa656c',
        backgroundColor: '#FFE4E4',
    },
};
