import { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FilterForm } from 'src/components/Form/FilterForm';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ListPage } from 'src/components/ListPage/ListPage';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { IdNameEntry, Role, UserListItem } from 'src/generated-api-client';
import { UsersStatus } from 'src/pages/UsersPage/UsersStatus';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { RoleUsersStore } from 'src/stores/RoleUsersStore/RoleUsersStore';
import { FormHelper } from 'src/utils/FormHelper';

export type RoleUsersViewProps = { role?: Role };
export const RoleUsersView = observer(({ role }: RoleUsersViewProps) => {
    const { t } = useTranslation();

    const store = RoleUsersStore.getInstance(role?.id);

    return (
        <ListPage
            store={store}
            columns={columns}
            isLoading={store.listLoader.isLoading}
            header={
                <FilterForm store={store} title={t('Page.RoleUsers.Details')}>
                    <TextField
                        name="name"
                        label={t('Page.Users.Filter.Name')}
                    />
                    <TextField
                        name="phone"
                        label={t('Page.Users.Filter.PhoneNumber')}
                    />
                    <EnumSelectField
                        mode="multiple"
                        name="statuses"
                        label={t('Page.Users.Filter.Status')}
                        enumName="USER_STATUS"
                    />
                    <SelectField
                        name="registrationCompleted"
                        label={t('Page.Users.Filter.RegistrationCompleted')}
                        options={FormHelper.objAsOptions(
                            {
                                true: true,
                                false: false,
                            },
                            'Primitive.Boolean.',
                        )}
                    />
                </FilterForm>
            }
        />
    );
});

export const columns: ColumnsType<UserListItem> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.RoleUsers.List.Id" />,
        render: (userId: string) => {
            const canReadDetails = AppUser.hasPermissions(
                UserPermission.UserReadDetails,
            );

            if (canReadDetails) {
                return (
                    <Link
                        to={EntityLinks.users.itemDetails(userId)}
                        data-test="user-details-link"
                    >
                        {userId}
                    </Link>
                );
            }

            return userId;
        },
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Page.RoleUsers.List.Status" />,
        render(value) {
            return <UsersStatus status={value} />;
        },
    },
    {
        key: 'surname',
        dataIndex: 'surname',
        title: <Trans i18nKey="Page.RoleUsers.List.Surname" />,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: <Trans i18nKey="Page.RoleUsers.List.Name" />,
    },
    {
        key: 'role',
        dataIndex: 'role',
        title: <Trans i18nKey="Page.RoleUsers.List.Role" />,
        render: ({ id, name }: IdNameEntry) => {
            const canReadDetails = AppUser.hasPermissions(
                UserPermission.RoleReadDetails,
            );

            if (canReadDetails) {
                return (
                    <Link to={EntityLinks.roles.itemDetails(id)}>{name}</Link>
                );
            }

            return name;
        },
    },
    {
        key: 'phone',
        dataIndex: 'phone',
        title: <Trans i18nKey="Page.RoleUsers.List.Phone" />,
    },
    {
        key: 'email',
        dataIndex: 'email',
        title: <Trans i18nKey="Page.RoleUsers.List.Email" />,
        sorter: true,
    },
];
