import { Button, Col, Row } from 'antd';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { EditIcon } from 'src/components/Icons/Icons';
import { NotFound } from 'src/components/NotFound/NotFound';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useRoleId } from 'src/hooks/useRoleId';
import { RoleDetailsBrief } from 'src/pages/RoleDetailsPage/RoleDetailsBrief';
import { RoleDetailsView } from 'src/pages/RoleDetailsPage/RoleDetailsView';
import { RoleAuthoritiesView } from 'src/pages/RoleDetailsPage/RolePermissionsView';
import { RoleUsersView } from 'src/pages/RoleDetailsPage/RoleUsersView';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';

const store = RolesStore;

export const RoleDetailsPage = observer(() => {
    const { roleId } = useRoleId();

    useEffect(() => {
        store.loadItem(roleId);

        return () => {
            store.itemLoader.reset();
        };
    }, [roleId]);

    const role = store.currentItem;
    const hasItem = Boolean(role);

    const canEdit = AppUser.hasPermissions(UserPermission.RoleEdit);

    const tabs: TabDefinition[] = hasItem
        ? [
              {
                  title: t('Page.RoleDetails.Tabs.Details'),
                  tabKey: 'details',
                  Component: <RoleDetailsView role={role} />,
              },
              {
                  title: t('Page.RoleDetails.Tabs.Authorities'),
                  tabKey: 'authorities',
                  Component: <RoleAuthoritiesView role={role} />,
              },
              {
                  title: t('Page.RoleDetails.Tabs.Users'),
                  tabKey: 'users',
                  Component: <RoleUsersView role={role} />,
              },
          ]
        : [];

    const error = useMemo(() => {
        const showNotFound =
            !_.isInteger(Number(roleId)) ||
            store.itemLoader.errorData?.status === 404;
        if (showNotFound) {
            return (
                <NotFound
                    title={t('Page.RoleDetails.Error.NotFound.Title')}
                    description={t(
                        'Page.RoleDetails.Error.NotFound.Description',
                        { roleId },
                    )}
                >
                    <Button
                        onClick={() => {
                            Router.navigate(RoutePaths.roles);
                        }}
                    >
                        {t('Page.RoleDetails.GoToList')}
                    </Button>
                </NotFound>
            );
        }

        return null;
    }, [roleId, store.itemLoader.errorData]);

    return (
        <BasePage
            title={
                <Row gutter={16}>
                    <Col flex={1}>
                        {t('Page.RoleDetails.Title', { roleId })}
                    </Col>
                    <Col>
                        {canEdit && (
                            <Button
                                icon={<EditIcon />}
                                type="primary"
                                onClick={() => {
                                    Router.navigate(
                                        EntityLinks.roles.itemEdit?.(roleId)!,
                                    );
                                }}
                            >
                                {t('Page.RoleDetails.Btn.Edit')}
                            </Button>
                        )}
                    </Col>
                </Row>
            }
            isLoading={store.itemLoader.isLoading}
            header={<RoleDetailsBrief role={role} />}
            showOnlyLoading={!role}
            error={error}
        >
            <Tabs tabs={tabs} />
        </BasePage>
    );
});
