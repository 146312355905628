import { Form, FormItemProps, Input, InputProps } from 'antd';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import styled from 'styled-components';

export type PhoneFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    readOnly?: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    inputProps?: any;
};

export const PhoneField = ({
    name,
    label,
    readOnly,
    disabled,
    inputProps,
    rules,
    ...props
}: PhoneFieldProps) => {
    const itemRules = rules || [];

    return (
        <Form.Item
            {...props}
            name={name}
            label={label}
            rules={[
                ValidationRules.withoutStartEndSpaces,
                ValidationRules.phone,
                ...itemRules,
            ]}
        >
            <PhoneInput
                readOnly={readOnly}
                disabled={disabled}
                data-test={name}
                {...inputProps}
            />
        </Form.Item>
    );
};

type PhoneInputProps = Omit<InputProps, 'onChange' | 'value'> & {
    onChange: (_value: string) => void;
    // eslint-disable-next-line react/require-default-props
    value?: string;
};

const PHONE_PREFIX = '+976';

const PhoneInput = ({
    readOnly,
    disabled,
    onChange,
    value,
    ...props
}: PhoneInputProps) => {
    return (
        <StyledInput
            readOnly={readOnly}
            disabled={disabled}
            {...props}
            prefix={PHONE_PREFIX}
            onChange={(e: any) => {
                const calculatedValue = `${PHONE_PREFIX}${
                    (e as any).target.value || ''
                }`;
                onChange?.(calculatedValue);
            }}
            value={value?.replace(PHONE_PREFIX, '')}
            maxLength={8}
            minLength={8}
        />
    );
};

const StyledInput = styled(Input)`
    .ant-input-prefix {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;

        color: #4d6082;
        margin-right: 0;
    }
`;
