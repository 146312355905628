import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FilterForm } from 'src/components/Form/FilterForm';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { RoleOption } from 'src/generated-api-client';
import { RoleStore } from 'src/stores/RoleStore/RoleStore';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

type AssigneeFilterFormProps = {
    store: BasicStore<any>;
};
export const ChangeAssigneeFilterForm = observer(
    ({ store }: AssigneeFilterFormProps) => {
        return (
            <FilterForm
                store={store}
                title={<p>{t('Dialog.ChangeAssignee.Label')}</p>}
            >
                <SelectField
                    store={RoleStore}
                    convertListItemToOption={(role: RoleOption) => ({
                        label: role.name,
                        value: role.name,
                        'data-test': role.name,
                    })}
                    name="roleName"
                    label={t('Dialog.ChangeAssignee.List.Filter.Role')}
                    showSearch
                />
                <TextField
                    name="userName"
                    label={t('Dialog.ChangeAssignee.List.Filter.Name')}
                />
                <EnumSelectField
                    name="userActivity"
                    label={t('Dialog.ChangeAssignee.List.Filter.Activity')}
                    enumName="USER_ACTIVITY"
                />
            </FilterForm>
        );
    },
);
