import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { CustomerStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: CustomerStatus;
};

export const LegalEntitiesStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: CustomerStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: <EnumView enumName="CUSTOMER_STATUS" value={status} />,
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [CustomerStatus.ACTIVE]: {
        title: (
            <EnumView
                enumName="CUSTOMER_STATUS"
                value={CustomerStatus.ACTIVE}
            />
        ),
        color: '#0C1604',
        backgroundColor: '#D3F3BB',
    },
    [CustomerStatus.CANCELLED_BY_CUSTOMER]: {
        title: (
            <EnumView
                enumName="CUSTOMER_STATUS"
                value={CustomerStatus.CANCELLED_BY_CUSTOMER}
            />
        ),
        color: '#8B939B',
        backgroundColor: '#F5F6F7',
    },
    [CustomerStatus.DISABLED]: {
        title: (
            <EnumView
                enumName="CUSTOMER_STATUS"
                value={CustomerStatus.DISABLED}
            />
        ),
        color: '#F54753',
        backgroundColor: '#FFE4E4',
    },
};
