import { observer } from 'mobx-react-lite';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { User, UserStatus } from 'src/generated-api-client';
import { getUserActions } from 'src/pages/UserDetailsPage/Components/UserActions.config';

type UserActionsProps = {
    user?: User;
};

export const UserActions = observer(({ user }: UserActionsProps) => {
    const userActions = getUserActions(user);

    const hideActions =
        userActions.length === 0 ||
        (user?.status === UserStatus.INACTIVE && user.registrationCompleted);

    if (hideActions) {
        return null;
    }

    return <Dropdown items={userActions} />;
});
