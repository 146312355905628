import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TaskDecisionType } from 'src/generated-api-client';

export const N113ApproveDisbursementTaskApproveVariables = observer(() => {
    return (
        <Row gutter={16}>
            <Col xs={24}>
                <HiddenField
                    name="type"
                    initialValue={TaskDecisionType.N1_13_APPROVE}
                />
            </Col>
        </Row>
    );
});
