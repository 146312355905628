import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { TrustProduct } from 'src/generated-api-client';
import { ProductsStatus } from 'src/pages/LoanProducts/ProductsStatus';

export type TrustPledgedProductDetailsBriefProps = {
    productTrustPledged?: TrustProduct;
};
export const TrustPledgedProductDetailsBrief = observer(
    ({ productTrustPledged }: TrustPledgedProductDetailsBriefProps) => {
        return (
            <Brief>
                <Row gutter={16}>
                    <Col span={6}>
                        <DataView
                            value={productTrustPledged?.name}
                            label={t('Page.TrustPledgedProductDetails.Name')}
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productTrustPledged?.interestRate}
                            label={t(
                                'Page.TrustPledgedProductDetails.InterestRate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productTrustPledged?.maxLoanAmountPct}
                            label={t(
                                'Page.TrustPledgedProductDetails.MaxLoanAmountPct',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productTrustPledged?.status}
                            label={t('Page.TrustPledgedProductDetails.Status')}
                            format={(value) => (
                                <ProductsStatus status={value} />
                            )}
                        />
                    </Col>
                </Row>
            </Brief>
        );
    },
);
