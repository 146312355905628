import { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import {
    InstallmentLoan,
    LoanSchedulePeriodEntry,
} from 'src/generated-api-client';
import { LoanPaymentScheduleStore } from 'src/stores/LoanPaymentScheduleStore/LoanPaymentScheduleStore';

export type PaymentScheduleTabProps = { loan?: InstallmentLoan };

export const PaymentScheduleTab = observer(
    ({ loan }: PaymentScheduleTabProps) => {
        const store = LoanPaymentScheduleStore.getInstance(loan?.id as number);

        useEffect(() => {
            store.loadList();

            return () => {
                store.listLoader.reset();
            };
        }, [loan?.id]);

        return (
            <ListView
                store={store}
                columns={columns}
                loading={store.listLoader.isLoading}
            />
        );
    },
);

const columns: ColumnsType<LoanSchedulePeriodEntry> = [
    {
        key: 'paymentDate',
        dataIndex: 'paymentDate',
        title: (
            <Trans i18nKey="Page.InstallmentLoanDetails.Tab.PaymentSchedule.List.PaymentDate" />
        ),
    },
    {
        key: 'totalPayment',
        dataIndex: 'totalPayment',
        title: (
            <Trans i18nKey="Page.InstallmentLoanDetails.Tab.PaymentSchedule.List.TotalPayment" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'principal',
        dataIndex: 'principal',
        title: (
            <Trans i18nKey="Page.InstallmentLoanDetails.Tab.PaymentSchedule.List.Principal" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'interest',
        dataIndex: 'interest',
        title: (
            <Trans i18nKey="Page.InstallmentLoanDetails.Tab.PaymentSchedule.List.Interest" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'principalOverdue',
        dataIndex: 'principalOverdue',
        title: (
            <Trans i18nKey="Page.InstallmentLoanDetails.Tab.PaymentSchedule.List.PrincipalOverdue" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'interestOverdue',
        dataIndex: 'interestOverdue',
        title: (
            <Trans i18nKey="Page.InstallmentLoanDetails.Tab.PaymentSchedule.List.InterestOverdue" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'penaltyDue',
        dataIndex: 'penaltyDue',
        title: (
            <Trans i18nKey="Page.InstallmentLoanDetails.Tab.PaymentSchedule.List.PenaltyDue" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'daysOverdue',
        dataIndex: 'daysOverdue',
        title: (
            <Trans i18nKey="Page.InstallmentLoanDetails.Tab.PaymentSchedule.List.DaysOverdue" />
        ),
    },
];
