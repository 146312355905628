import { computed } from 'mobx';
import { Comment, NewComment } from 'src/generated-api-client';
import { commentsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export class CommentsStoreClass extends BasicStore<
    Comment,
    any,
    any,
    NewComment
> {
    api: BasicStoreApi<Comment, any, NewComment> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                commentsApi.getCommentsByRoomId({
                    ...this.listParams,
                    id: this.roomId,
                }),
            );
        },

        createItem: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                commentsApi.createCommentInRoom({
                    id: this.roomId,
                    newComment: data,
                }),
            );
        },
    };

    @computed get totalComments() {
        return this.pager?.total;
    }

    constructor(private roomId: number) {
        super();
    }

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<Comment>('id', 'descend');
}

export const CommentsStore = new EntityProvider(CommentsStoreClass);
