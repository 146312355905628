import { notification } from 'antd';
import { t } from 'i18next';
import { action, computed } from 'mobx';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';

export type SetPasswordForm = {
    code: string;
};

export class SetPasswordStoreClass {
    public verifyCodeLoader = new AsyncOperationWithStatus((_code: string) => {
        console.warn('Methode "verifyCode" not implemented');

        return Promise.reject();
    });

    public setPasswordLoader = new AsyncOperationWithStatus(
        ({ code, ..._registrationForm }: SetPasswordForm) => {
            console.warn('Methode "setPassword" not implemented');

            return Promise.reject();
        },
    );

    @action async verifyCode(code: string) {
        await this.verifyCodeLoader.call(code);
    }

    @computed get isValidCode() {
        return !this.verifyCodeLoader.hasError && this.isCodeValidated;
    }

    @computed get isCodeValidated() {
        return this.verifyCodeLoader.countLoads > 0;
    }

    @computed get isCodeValidating() {
        return this.verifyCodeLoader.isLoading;
    }

    @action async setPassword(values: SetPasswordForm) {
        await this.setPasswordLoader.call(values);
        if (!this.setPasswordLoader.hasError) {
            notification.success({
                message: t(
                    'Page.SetPassword.Messages.Success',
                    'Password was set successfully',
                ),
            });
        }
        Router.navigate(RoutePaths.authLogin);
    }

    @computed get isPasswordSetting() {
        return this.setPasswordLoader.isLoading;
    }
}

export const SetPasswordStore = new SetPasswordStoreClass();
