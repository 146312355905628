import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Form,
    FormItemProps,
    Upload,
    UploadFile,
    UploadProps,
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type UploadInputProps = UploadProps & {
    // eslint-disable-next-line react/require-default-props,@typescript-eslint/no-unused-vars
    onChange?: (fileList: UploadFile[]) => void;
    // eslint-disable-next-line react/require-default-props,@typescript-eslint/no-unused-vars
    triggerIcon?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props,@typescript-eslint/no-unused-vars
    triggerLabel?: React.ReactNode;
};

const UploadInput: React.FC<UploadInputProps> = ({
    maxCount,
    multiple,
    onChange,
    children,
    triggerIcon,
    triggerLabel,
    ...props
}) => {
    const { t } = useTranslation();

    const count = maxCount || 1;
    const icon = triggerIcon || <UploadOutlined />;
    const label = triggerLabel || t('Component.FileField.TriggerLabel');

    return (
        <Upload
            maxCount={count}
            multiple={multiple}
            beforeUpload={() => false}
            onChange={(event) => {
                onChange?.([...event.fileList]);
            }}
            {...props}
        >
            {children || <Button icon={icon}>{label}</Button>}
        </Upload>
    );
};

export type FileFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    maxCount?: UploadProps['maxCount'];
    // eslint-disable-next-line react/require-default-props
    multiple?: UploadProps['multiple'];
    // eslint-disable-next-line react/require-default-props
    accept?: UploadProps['accept'];
    // eslint-disable-next-line react/require-default-props
    uploadProps?: Omit<
        UploadProps,
        'maxCount' | 'multiple' | 'onChange' | 'accept'
    >;
};

export const FileField: React.FC<FileFieldProps> = ({
    name,
    label,
    maxCount,
    multiple,
    accept,
    uploadProps,
    children,
    ...props
}) => {
    return (
        <Form.Item {...props} name={name} label={label}>
            <UploadInput
                maxCount={maxCount}
                multiple={multiple}
                accept={accept}
                {...uploadProps}
            >
                {children}
            </UploadInput>
        </Form.Item>
    );
};
