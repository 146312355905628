import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { SelectField } from 'src/components/Form/SelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { ProposeActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ProposeActionDialogComponent';
import { ProposeMaturityDateExtensionActionStore } from 'src/stores/LoanActionsStores/ProposeMaturityDateExtensionActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const asOption = (value: number) => ({
    value,
    label: value,
});

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

export const ProposeMaturityDateExtensionDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ProposeMaturityDateExtensionActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ProposeMaturityDateExtension.Title');
        const paymentCountOption = Array.from(
            new Array(
                store.initialValues?.allowedAdditionalPaymentsCount || 0,
            ).keys(),
            (item) => item + 1,
        ).map(asOption);

        return (
            <ProposeActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <Row gutter={16}>
                    <Col sm={12}>
                        <DataView
                            value={store.initialValues?.maturityDate}
                            label={t(
                                'Dialog.ProposeMaturityDateExtension.MaturityDate',
                            )}
                        />
                    </Col>
                    <Col sm={12}>
                        <DataView
                            value={store.initialValues?.repaymentFrequency}
                            label={t(
                                'Dialog.ProposeMaturityDateExtension.RepaymentFrequency',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="REPAYMENT_FREQUENCY"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col sm={24}>
                        <SelectField
                            options={paymentCountOption}
                            name="additionalPaymentsCount"
                            label={t(
                                'Dialog.ProposeMaturityDateExtension.AdditionalPaymentsCount',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                </Row>
            </ProposeActionDialogComponent>
        );
    },
);

export const ProposeMaturityDateExtensionDialog = addModalRenderer(
    ProposeMaturityDateExtensionDialogFn,
);
