import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { NotFound } from 'src/components/NotFound/NotFound';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useApplicationId } from 'src/hooks/useApplicationId';

export const UnknownLoanApplication = observer(() => {
    const { applicationId } = useApplicationId();

    return (
        <NotFound
            title={t('Page.UnknownLoanApplication.Title')}
            description={t('Page.UnknownLoanApplication.Description', {
                applicationId,
            })}
        >
            <Button
                onClick={() => {
                    Router.navigate(RoutePaths.applications);
                }}
            >
                {t('Page.UnknownLoanApplication.GoToList')}
            </Button>
        </NotFound>
    );
});
