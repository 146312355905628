import { RoutePaths } from 'src/core/router/RoutePaths';
import { UrlHelper } from 'src/utils/UrlHelper';

type Links = {
    list: () => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemDetails: (id?: string | number) => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemEdit?: (id?: string | number) => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    process?: (id?: string | number) => string;

    create?: () => string;
};

type EntityNames =
    | 'collaterals'
    | 'collections'
    | 'application'
    | 'users'
    | 'individuals'
    | 'legals'
    | 'productsPledged'
    | 'productsPayon'
    | 'productsInstallment'
    | 'productsBnpl'
    | 'productsTrustPledged'
    | 'loans'
    | 'roles'
    | 'tasks';

const generatePath = (path: string, params: Record<string, any>) => {
    return UrlHelper.getTargetUrl(path, params);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EntityLinks: { [key in EntityNames]: Links } = {
    collaterals: {
        list: () => RoutePaths.collaterals,
        itemDetails: (id) =>
            generatePath(RoutePaths.collateralDetails, {
                collateralId: id,
            }),
    },
    collections: {
        list: () => RoutePaths.collections,
        itemDetails: (id) =>
            generatePath(RoutePaths.collectionDetails, {
                collectionId: id,
            }),
    },
    application: {
        list: () => RoutePaths.applications,
        itemDetails: (id) =>
            generatePath(RoutePaths.applicationDetails, {
                applicationId: id,
            }),
    },
    users: {
        list: () => RoutePaths.users,
        itemDetails: (id) =>
            generatePath(RoutePaths.userDetails, {
                userId: id,
            }),

        itemEdit: (id) =>
            generatePath(RoutePaths.userEdit, {
                userId: id,
            }),
    },
    individuals: {
        list: () => RoutePaths.individuals,
        itemDetails: (id) =>
            generatePath(RoutePaths.individualDetails, {
                individualId: id,
            }),
    },
    legals: {
        list: () => RoutePaths.legalEntities,
        itemDetails: (id) =>
            generatePath(RoutePaths.legalDetails, {
                legalId: id,
            }),
    },
    productsPledged: {
        list: () => RoutePaths.productsPledged,
        itemDetails: (id) =>
            generatePath(RoutePaths.productPledgedDetails, {
                productId: id,
            }),
        itemEdit: (id) =>
            generatePath(RoutePaths.productPledgedEdit, {
                productId: id,
            }),
    },
    productsPayon: {
        list: () => RoutePaths.productsPayon,
        itemDetails: (id) =>
            generatePath(RoutePaths.productsPayonDetails, {
                productId: id,
            }),
        itemEdit: (id) =>
            generatePath(RoutePaths.productsPayonEdit, {
                productId: id,
            }),
    },
    productsInstallment: {
        list: () => RoutePaths.productsInstallment,
        itemDetails: (id) =>
            generatePath(RoutePaths.productInstallmentDetails, {
                productId: id,
            }),
        itemEdit: (id) =>
            generatePath(RoutePaths.productInstallmentEdit, {
                productId: id,
            }),
    },
    productsBnpl: {
        list: () => RoutePaths.productsBNPL,
        itemDetails: (id) =>
            generatePath(RoutePaths.productBNPLDetails, {
                productId: id,
            }),
        itemEdit: (id) =>
            generatePath(RoutePaths.productBNPLEdit, {
                productId: id,
            }),
        create: () => RoutePaths.productBNPLCreate,
    },
    productsTrustPledged: {
        list: () => RoutePaths.productsTrustPledged,
        itemDetails: (id) =>
            generatePath(RoutePaths.productTrustPledgedDetails, {
                productId: id,
            }),
        itemEdit: (id) =>
            generatePath(RoutePaths.productTrustPledgedEdit, {
                productId: id,
            }),
        create: () => RoutePaths.productTrustPledgedCreate,
    },
    loans: {
        list: () => RoutePaths.loans,
        itemDetails: (id) =>
            generatePath(RoutePaths.loanDetails, {
                loanId: id,
            }),
    },
    roles: {
        list: () => RoutePaths.roles,
        itemDetails: (id) =>
            generatePath(RoutePaths.roleDetails, {
                roleId: id,
            }),
        itemEdit: (id) =>
            generatePath(RoutePaths.roleEdit, {
                roleId: id,
            }),
    },
    tasks: {
        list: () => RoutePaths.tasks,
        itemDetails: (id) =>
            generatePath(RoutePaths.taskDetails, {
                taskId: id,
            }),
    },
};
