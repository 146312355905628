/* eslint-disable max-lines */
import { notification } from 'antd';
import { ColumnType } from 'antd/es/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataView, LONG_DASH } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ErrorField } from 'src/components/Form/ErrorField';
import { ListSelectField } from 'src/components/Form/ListSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import {
    IdNameEntry,
    TaskCandidateBoUser,
    UserListItem,
} from 'src/generated-api-client';
import { ChangeAssigneeFilterForm } from 'src/pages/TasksPage/Components/ChangeAssigneeFilterForm';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { TasksAssigneesStore } from 'src/stores/TasksAssigneesStore/TasksAssigneesStore';
import { TasksStore } from 'src/stores/TasksStore/TasksStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type ChangeAssigneeDialogProps = ModalRendererProps<{
    taskId: string;
}>;

const store = TasksStore;

const ChangeAssigneeDialogFn = observer(
    ({ taskId, ...props }: ChangeAssigneeDialogProps) => {
        const tasksAssigneesStore = TasksAssigneesStore.getInstance(taskId);

        const isLoading =
            store.assignTaskLoader.isLoading ||
            tasksAssigneesStore.listLoader.isLoading;

        const assigneeColumns = getAssigneeColumns(props);

        useEffect(() => {
            return () => {
                tasksAssigneesStore.listLoader.reset();
                tasksAssigneesStore.filterCriteria?.resetFilter();

                store.assignTaskLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.assignTaskLoader.errorData,
        );

        return (
            <Modal
                isLoading={isLoading}
                title={t('Dialog.ChangeAssignee.Title')}
                onFinish={async (values) => {
                    await store.assignTask(taskId, values.userId);

                    if (store.assignTaskLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.assignTaskLoader.hasError) {
                        notification.success({
                            message: t('Dialog.ChangeAssignee.Message.Success'),
                        });
                        store.loadList();
                    }
                }}
                headerExtra={
                    <ChangeAssigneeFilterForm store={tasksAssigneesStore} />
                }
                formProps={{ fields }}
                width={1200}
                {...props}
            >
                <StyledListSelectField
                    name="userId"
                    store={tasksAssigneesStore}
                    columns={assigneeColumns}
                    rules={[ValidationRules.required]}
                    listSelectProps={{
                        rowKey: (record: TaskCandidateBoUser) =>
                            record?.user?.id,
                    }}
                />
                <StyledError name="id" />
            </Modal>
        );
    },
);

export const ChangeAssigneeDialog = addModalRenderer(ChangeAssigneeDialogFn);

const StyledListSelectField = styled(ListSelectField)`
    .ant-table-wrapper {
        padding: 10px 0;
    }
`;

const StyledError = styled(ErrorField)`
    margin-bottom: 10px;
`;

const getAssigneeColumns = (
    props: ModalRendererProps,
): ColumnType<UserListItem>[] => {
    return [
        {
            key: 'name',
            dataIndex: 'user',
            title: <Trans i18nKey="Dialog.ChangeAssignee.List.Name" />,
            render: (value: IdNameEntry) => {
                const canReadDetails = AppUser.hasPermissions(
                    UserPermission.UserReadDetails,
                );

                if (canReadDetails) {
                    return (
                        <Link
                            data-test="user-details-link"
                            to={EntityLinks.users.itemDetails(value?.id)}
                            onClick={() => {
                                Router.navigate(
                                    EntityLinks.users.itemDetails(value?.id),
                                );

                                props.onCancel();
                            }}
                        >
                            {value?.name}
                        </Link>
                    );
                }

                return value?.name;
            },
        },
        {
            key: 'roleName',
            dataIndex: 'roleName',
            title: <Trans i18nKey="Dialog.ChangeAssignee.List.Role" />,
        },
        {
            key: 'activity',
            dataIndex: 'activity',
            title: <Trans i18nKey="Dialog.ChangeAssignee.List.Activity" />,
            render: (value) => {
                return value ? (
                    <DataView
                        value={value}
                        hideLabel
                        format={(value) => (
                            <EnumView enumName="USER_ACTIVITY" value={value} />
                        )}
                    />
                ) : (
                    LONG_DASH
                );
            },
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: <Trans i18nKey="Dialog.ChangeAssignee.List.Phone" />,
        },
        {
            key: 'email',
            dataIndex: 'email',
            title: <Trans i18nKey="Dialog.ChangeAssignee.List.Email" />,
        },
    ];
};
