import { notification, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Modal } from 'src/components/Modal/Modal';
import { OfferExclusionUpdate } from 'src/generated-api-client';
import { LoanProductOfferExclusionsStore } from 'src/stores/LoanProductOfferExclusionsStore/LoanProductOfferExclusionsStore';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { Entity } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type ExcludeProductFromLimitOffersDialogProps<T extends Entity> =
    ModalRendererProps<{
        store?: BasicStore<T>;
        productId: number;
    }>;

const ExcludeProductFromLimitOffersDialogFn = observer(
    ({
        store,
        productId,
        ...props
    }: ExcludeProductFromLimitOffersDialogProps<any>) => {
        const loanProductOfferExclusionsStore = LoanProductOfferExclusionsStore;

        useEffect(() => {
            return () => {
                loanProductOfferExclusionsStore.updateItemLoader.reset();
            };
        }, []);

        return (
            <Modal
                isLoading={
                    loanProductOfferExclusionsStore.updateItemLoader.isLoading
                }
                title={t('Dialog.ExcludeProductFromLimitOffers.Title')}
                onFinish={async () => {
                    await loanProductOfferExclusionsStore.update(productId, {
                        excludedFromOffer: true,
                    } as OfferExclusionUpdate);

                    if (
                        !loanProductOfferExclusionsStore.updateItemLoader
                            .hasError
                    ) {
                        notification.success({
                            message: t(
                                'Page.ExcludeProductFromLimitOffers.Message.Success',
                            ),
                        });

                        props.onCancel();
                        store?.itemLoader?.call(productId);
                    }
                }}
                okText={t(
                    'Dialog.ExcludeProductFromLimitOffers.OkText',
                    'Confirm',
                )}
                {...props}
            >
                <StyledText>
                    <Trans i18nKey="Dialog.ExcludeProductFromLimitOffers.Descriptions" />
                </StyledText>
            </Modal>
        );
    },
);

export const ExcludeProductFromLimitOffersDialog = addModalRenderer(
    ExcludeProductFromLimitOffersDialogFn,
);

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
    height: 100px;
`;
