import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N21Task, TaskDecisionType } from 'src/generated-api-client';
import { TaskCommentsView } from 'src/pages/TaskDetailsPage/Components/TaskCommentsView';
import { SubmitTaskDialog } from 'src/pages/TaskDetailsPage/Dialogs/SubmitTaskDialog';
import { CustomerInformationCard } from 'src/pages/TaskDetailsPage/N21AddBankStatementTask/Components/CustomerInfomationCard';
import { N21AddBankStatementTaskRejectVariables } from 'src/pages/TaskDetailsPage/N21AddBankStatementTask/N21AddBankStatementTaskRejectVariables';
import { N21AddBankStatementTaskVariables } from 'src/pages/TaskDetailsPage/N21AddBankStatementTask/N21AddBankStatementTaskVariables';
import { TaskProps } from 'src/pages/TaskViewPage/TaskTypes';
import styled from 'styled-components';

export const N21AddBankStatementTask = observer(
    ({ viewModel }: TaskProps<N21Task>) => {
        const task = (viewModel.currentTask as N21Task) || {};
        const { iatUserComment, iatSeniorComment } = task;

        const comments = [
            {
                label: t('Component.TaskCommentsView.IATUserComment'),
                value: iatUserComment,
            },
            {
                label: t('Component.TaskCommentsView.IATSeniorComment'),
                value: iatSeniorComment,
            },
        ];

        return (
            <>
                <Row gutter={[16, 24]}>
                    <Col xs={24}>
                        <StyledText>
                            <Trans i18nKey="Task.Form.N21AddBankStatementTask.Descriptions" />
                        </StyledText>
                    </Col>
                    <Col xs={24}>
                        <CustomerInformationCard task={task} />
                    </Col>
                    <Col xs={24}>
                        <TaskCommentsView comments={comments} />
                    </Col>
                </Row>
                <TaskForm
                    fields={viewModel.submitTaskErrorFields}
                    initialValues={getInitialValues(task)}
                    onFinish={async (values) => {
                        await viewModel.submitTask(values);
                        if (!viewModel.submitTaskLoaderHasError) {
                            Router.navigate(RoutePaths.tasks);
                        }
                    }}
                >
                    <DocumentsList
                        documentRoom={task?.documentRoom}
                        canUpload={viewModel.isCurrentTaskEqualWorkingTask}
                    />
                    <Card
                        title={t(
                            'Task.Form.N21AddBankStatementTask.SpecifyBankStatementData',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={24}>
                                <N21AddBankStatementTaskVariables />
                            </Col>
                            <Col xs={24}>
                                <Space size={20}>
                                    <Button
                                        type={
                                            viewModel.isCurrentTaskEqualWorkingTask
                                                ? 'primary'
                                                : 'default'
                                        }
                                        htmlType="submit"
                                    >
                                        {t('Task.Form.Btn.Approve')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            SubmitTaskDialog.render({
                                                viewModel,
                                                title: t(
                                                    'Dialog.Reject.N21TaskAddBankStatement.Title',
                                                    'Confirm action',
                                                ),
                                                children: (
                                                    <N21AddBankStatementTaskRejectVariables />
                                                ),
                                            });
                                        }}
                                    >
                                        {t('Task.Form.Btn.Reject')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                </TaskForm>
            </>
        );
    },
);

const getInitialValues = (data?: N21Task) => {
    return {
        ...data,
        type: TaskDecisionType.N2_1_APPROVE,
    };
};

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
