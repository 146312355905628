import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    ColumnSettings,
    SettingsTable,
} from 'src/components/SettingsTable/SettingsTable';
import { RiskyGroupRow, SettingType } from 'src/generated-api-client';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { TableSettingsStoreClass } from 'src/stores/Settings/TableSettingsStoreClass';
import styled from 'styled-components';

const tableStore = new TableSettingsStoreClass<RiskyGroupRow>(
    SettingType.RISKY_GROUP,
);

const renderNumberInput = (valuePath: any) => {
    return (
        <StyledNumberField
            name={valuePath}
            rules={[
                ValidationRules.required,
                ValidationRules.numberMinValue(0),
            ]}
        />
    );
};

const columnSettings: ColumnSettings[] = [
    {
        key: 'RISKY_GROUP',
        title: (colDef) => {
            return t(
                'Page.SettingsRiskyGroup.Table.RiskyGroup',
                colDef.payload,
            );
        },
        render: (data) => {
            return t(`Page.SettingsRiskyGroup.Table.Row.RiskyGroup.${data}`);
        },
        width: 170,
    },
    {
        key: 'PRODUCT_TYPE',
        title: (colDef) => {
            return t(`Loan.ProductType.${colDef.payload.productType}`, {
                context: colDef.payload.productSubtype,
            });
        },
        renderEdit: (valuePath) => renderNumberInput(valuePath),
        editable: true,
        width: 205,
    },
];

export const SettingsRiskyGroupPage = observer(() => {
    const { t } = useTranslation();

    return (
        <BasePage title={t('Page.SettingsRiskyGroup.Title')}>
            <SettingsTable
                tableStore={tableStore}
                columnSettings={columnSettings}
                hasPermission={AppUser.hasPermissions(
                    UserPermission.LoanSettingsRiskyGroupEdit,
                )}
            />
        </BasePage>
    );
});

const StyledNumberField = styled(NumberField)`
    margin: 0;
    min-width: 60px;
`;
