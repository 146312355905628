import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { CustomerReference } from 'src/generated-api-client';
import { DataInformationCard } from 'src/pages/IndividualDetailsPage/Components/DataInformationCard';
import { UpdateCustomerReferenceDialog } from 'src/pages/IndividualDetailsPage/Dialogs/IndividualInformation/UpdateCustomerReferenceDialog';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export type IndividualCustomerReferenceCardProps = {
    viewModel?: IndividualDetailsViewModelClass;
};
export const IndividualCustomerReferenceCard = observer(
    ({ viewModel }: IndividualCustomerReferenceCardProps) => {
        return (
            <DataInformationCard
                title={t('Page.IndividualDetails.CustomerReference.Title')}
                isLoading={viewModel?.itemLoader.isLoading}
                hasData={(viewModel?.item?.customerReferences?.length || 0) > 0}
                canUpdate={AppUser.hasPermissions(
                    UserPermission.IndividualUpdateReferences,
                )}
                onAdd={() => {
                    UpdateCustomerReferenceDialog.render({
                        viewModel,
                    });
                }}
            >
                {viewModel?.item?.customerReferences?.map(
                    (reference: CustomerReference) => {
                        return (
                            <Row gutter={16} key={reference.id}>
                                <Col xs={6}>
                                    <DataView
                                        value={reference.relationship}
                                        label={t(
                                            'Page.IndividualDetails.CustomerReference.Relationship',
                                        )}
                                        format={(value) => (
                                            <EnumView
                                                enumName="REFERENCE_RELATIONSHIP"
                                                value={value}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <DataView
                                        value={reference.firstName}
                                        label={t(
                                            'Page.IndividualDetails.CustomerReference.FirstName',
                                        )}
                                        format="string"
                                    />
                                </Col>
                                <Col xs={6}>
                                    <DataView
                                        value={reference.registrationNumber}
                                        label={t(
                                            'Page.IndividualDetails.CustomerReference.RegistrationNumber',
                                        )}
                                        format="string"
                                    />
                                </Col>
                                <Col xs={6}>
                                    <DataView
                                        value={reference.phoneNumber}
                                        label={t(
                                            'Page.IndividualDetails.CustomerReference.Phone',
                                        )}
                                        format="tel"
                                    />
                                </Col>
                            </Row>
                        );
                    },
                )}
            </DataInformationCard>
        );
    },
);
