/* eslint-disable max-lines */
import { Form as AntForm, Form, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListSelectField } from 'src/components/Form/ListSelectField';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    CollectionActivityGeneralType,
    CollectionLevel,
    Customer,
    CustomerType,
    DebtCollectionListEntry,
    IdNameEntry,
} from 'src/generated-api-client';
import { CollectionStatus } from 'src/pages/CollectionDetailsPage/Components/CollectionStatus';
import { CollectionsHeaderPanel } from 'src/pages/CollectionsPage/CollectionsHeaderPanel';
import { AssignDebtCollectionDialog } from 'src/pages/CollectionsPage/Dialogs/AssignToDebtCollectionDialog';
import { DebtCollectionsStore } from 'src/stores/DebtCollectionsStore/DebtCollectionsStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

export const CollectionsPage = observer(() => {
    const [form] = AntForm.useForm();
    const selectedCollectionIds: number[] = Form.useWatch(
        'collectionIds',
        form,
    );
    const isCollectionSelected = selectedCollectionIds?.length > 0;

    const [selectedRowData, setSelectedRowData] = useState<
        DebtCollectionListEntry[] | []
    >([]);

    useEffect(() => {
        setSelectedRowData([]);
    }, []);

    useEffect(() => {
        const newSelectedRowData: DebtCollectionListEntry[] | [] = [];

        const selectedRowIds = selectedRowData?.map((t) => t?.id);

        selectedCollectionIds?.forEach((t) => {
            if (selectedRowIds?.includes(t)) {
                newSelectedRowData?.push(
                    selectedRowData?.find(
                        (rowData) => rowData?.id === t,
                    ) as never,
                );
            } else {
                newSelectedRowData?.push(
                    DebtCollectionsStore?.list?.find(
                        (storeData) => storeData?.id === t,
                    ) as never,
                );
            }
        });

        setSelectedRowData(newSelectedRowData);
    }, [selectedCollectionIds]);

    return (
        <BasePage
            isLoading={DebtCollectionsStore.listLoader.isLoading}
            header={
                <CollectionsHeaderPanel
                    title={t('Page.CollectionsPage.Header')}
                    collectionListForm={form}
                    isCollectionSelected={isCollectionSelected}
                />
            }
        >
            <StyledSpace size={20}>
                <StyledSuccessfulDataView
                    hideLabel
                    value={DebtCollectionsStore.summaryItem?.successful}
                    format={(value) =>
                        `${t(
                            'Page.CollectionsPage.Summary.Successful',
                        )} (${value})`
                    }
                />
                <StyledFailedDataView
                    hideLabel
                    value={DebtCollectionsStore.summaryItem?.failed}
                    format={(value) =>
                        `${t('Page.CollectionsPage.Summary.Failed')} (${value})`
                    }
                />
                <StyledPendingDataView
                    hideLabel
                    value={DebtCollectionsStore.summaryItem?.pending}
                    format={(value) =>
                        `${t(
                            'Page.CollectionsPage.Summary.Pending',
                        )} (${value})`
                    }
                />
            </StyledSpace>

            <Form
                form={form}
                onFinish={({ collectionIds }) => {
                    AssignDebtCollectionDialog.render({
                        collectionIds,
                        collectionsData: selectedRowData,
                        collectionsListForm: form,
                    });
                }}
            >
                <ListSelectField
                    name="collectionIds"
                    mode="multiple"
                    store={DebtCollectionsStore}
                    columns={columns}
                />
            </Form>
        </BasePage>
    );
});

export const columns: ColumnsType<DebtCollectionListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.CollectionsPage.List.Id" />,
        render: (value: number) => {
            const collectionDetailsUrl = UrlHelper.getTargetUrl(
                RoutePaths.collectionDetails,
                { collectionId: value },
            );

            return (
                <DataView
                    value={value}
                    hideLabel
                    format="link-internal"
                    formatterProps={{ to: collectionDetailsUrl }}
                />
            );
        },
    },
    {
        key: 'customer',
        dataIndex: 'customer',
        title: <Trans i18nKey="Page.CollectionsPage.List.Name" />,
        render: (value: Customer) => {
            const isIndividual = value?.type === CustomerType.INDIVIDUAL;
            const detailsPageType = isIndividual
                ? RoutePaths.individualDetails
                : RoutePaths.legalDetails;

            const detailsPageParam = isIndividual
                ? {
                      individualId: value?.id,
                  }
                : {
                      legalId: value?.id,
                  };

            const userDetailsUrl = UrlHelper.getTargetUrl(
                detailsPageType,
                detailsPageParam,
            );

            return (
                <DataView
                    value={value?.name}
                    hideLabel
                    format="link-internal"
                    formatterProps={{ to: userDetailsUrl }}
                />
            );
        },
    },
    {
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
        title: <Trans i18nKey="Page.CollectionsPage.List.PhoneNumber" />,
    },
    {
        key: 'registrationNumber',
        dataIndex: 'registrationNumber',
        title: <Trans i18nKey="Page.CollectionsPage.List.RegistrationNumber" />,
    },
    {
        key: 'maxDaysOverdue',
        dataIndex: 'maxDaysOverdue',
        title: <Trans i18nKey="Page.CollectionsPage.List.MaxOverdueDay" />,
        sorter: true,
    },
    {
        key: 'principalAmount',
        dataIndex: 'principalAmount',
        title: <Trans i18nKey="Page.CollectionsPage.List.PrincipalAmount" />,
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
        sorter: true,
    },
    {
        key: 'debtCollector',
        dataIndex: 'debtCollector',
        title: <Trans i18nKey="Page.CollectionsPage.List.DebtCollector" />,
        render: (value: IdNameEntry) => {
            const userDetailsUrl = UrlHelper.getTargetUrl(
                RoutePaths.userDetails,
                {
                    userId: value?.id,
                },
            );

            return (
                <DataView
                    value={value?.name}
                    hideLabel
                    format="link-internal"
                    formatterProps={{ to: userDetailsUrl }}
                />
            );
        },
    },
    {
        key: 'level',
        dataIndex: 'level',
        title: <Trans i18nKey="Page.CollectionsPage.List.Level" />,
        render: (value: CollectionLevel) => {
            return (
                <DataView
                    value={value}
                    format={(value) => (
                        <EnumView enumName="COLLECTION_LEVEL" value={value} />
                    )}
                    hideLabel
                />
            );
        },
    },
    {
        key: 'lastActivityGeneralType',
        dataIndex: 'lastActivityGeneralType',
        title: (
            <Trans i18nKey="Page.CollectionsPage.List.LastActivityGeneralType" />
        ),
        render: (value: CollectionActivityGeneralType) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView
                            enumName="COLLECTION_ACTIVITY_GENERAL_TYPE"
                            value={value}
                        />
                    )}
                />
            );
        },
    },
    {
        key: 'lastActivityDate',
        dataIndex: 'lastActivityDate',
        title: <Trans i18nKey="Page.CollectionsPage.List.LastActivityDate" />,
        render: (value: string) => {
            return <DataView value={value} format="date" hideLabel />;
        },
        sorter: true,
    },
    {
        key: 'nextActionDate',
        dataIndex: 'nextActionDate',
        title: <Trans i18nKey="Page.CollectionsPage.List.NextActionDate" />,
        render: (value: string) => {
            return <DataView value={value} format="date" hideLabel />;
        },
        sorter: true,
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Page.CollectionsPage.List.Status" />,
        render(value) {
            return <CollectionStatus status={value} />;
        },
    },
];

const StyledSpace = styled(Space)`
    position: relative;
`;

const StyledDataView = styled(DataView)`
    padding: 10px 20px;
    margin-bottom: 20px;

    border-radius: 8px;
    background: #fff;

    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    .app-data-view {
    }

    .app-data-view__value {
        font-weight: 600;
        font-size: 14px !important;
        line-height: 20px !important;

        text-align: center;
        letter-spacing: 0.5px;
        color: #ffa500;
    }
`;

const StyledSuccessfulDataView = styled(StyledDataView)`
    .app-data-view__value {
        color: #23c165;
    }
`;

const StyledFailedDataView = styled(StyledDataView)`
    .app-data-view__value {
        color: #f75725;
    }
`;

const StyledPendingDataView = styled(StyledDataView)`
    .app-data-view__value {
        color: #ffa500;
    }
`;
