import { Button, Col, notification, Row, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { Form } from 'src/components/Form/Form';
import { TextField } from 'src/components/Form/TextField';
import {
    FieldMaxLength,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { RoleAuthorities } from 'src/pages/RoleEditPage/RoleAuthorities';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { UrlHelper } from 'src/utils/UrlHelper';

export const RoleCreatePage = observer(() => {
    const { t } = useTranslation();

    useEffect(() => {
        RolesStore.loadAuthorities();

        return () => {
            RolesStore.authorityLoader.reset();
            RolesStore.createItemLoader.reset();
        };
    }, []);

    const fields = FormHelper.getFieldDataItems(
        RolesStore.createItemLoader.errorData,
    );

    const loadingInfo = useMemo(() => {
        return new CombinedLoadingStatus([
            RolesStore.authorityLoader,
            RolesStore.createItemLoader,
        ]);
    }, []);

    const pageTitle = t('Page.RolesCreate.Title');

    return (
        <BasePage
            title={pageTitle}
            isLoading={loadingInfo.isLoading}
            showOnlyLoading={!RolesStore.authorities.length}
        >
            <Form
                onFinish={async (values: any) => {
                    const { id } = (await RolesStore.create(values)) || {};
                    if (!RolesStore.createItemLoader.hasError) {
                        notification.success({
                            message: t('Page.RolesCreate.Message.Success'),
                        });
                        Router.navigate(
                            UrlHelper.getTargetUrl(RoutePaths.roleDetails, {
                                roleId: id,
                            }),
                        );
                    }
                }}
                onReset={() => {
                    Router.navigate(RoutePaths.roles);
                }}
                fields={fields}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            name="name"
                            label={t('Page.RoleCreate.Name')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.maxFieldLength(
                                    FieldMaxLength.RoleName,
                                ),
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <EnumSelectField
                            name="startPage"
                            label={t('Page.RoleEdit.StartPage')}
                            rules={[ValidationRules.required]}
                            enumName="START_PAGE"
                        />
                    </Col>

                    <Col span={12}>
                        <TextField
                            name="description"
                            label={t('Page.RoleCreate.description')}
                            rules={[
                                ValidationRules.maxFieldLength(
                                    FieldMaxLength.RoleDescription,
                                ),
                            ]}
                        />
                    </Col>
                </Row>

                <RoleAuthorities />

                <Row>
                    <Col span={24}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                {t('Page.RoleCreate.Btn.Save')}
                            </Button>
                            <Button type="default" htmlType="reset">
                                {t('Page.RoleCreate.Btn.Reset')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </BasePage>
    );
});
