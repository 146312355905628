import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { PhoneField } from 'src/components/Form/PhoneField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { CollateralCoOwner } from 'src/generated-api-client';
import { CollateralCoOwnerStore } from 'src/stores/CollateralCoOwnerStore/CollateralCoOwnerStore';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type EditCollateralOwnershipInfoDialogFnProps = ModalRendererProps<{
    collateralId: number;
    coOwner: CollateralCoOwner;
}>;

const EditCollateralOwnershipInfoDialogFn = observer(
    ({
        collateralId,
        coOwner,
        ...props
    }: EditCollateralOwnershipInfoDialogFnProps) => {
        const store = CollateralCoOwnerStore.getInstance(collateralId);

        useEffect(() => {
            return () => {
                store.updateItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );

        return (
            <Modal
                isLoading={store.updateItemLoader.isLoading}
                title={t('Dialog.EditCollateralOwnershipInfo.Title')}
                cancelText={t('Component.Modal.Btn.Back')}
                initialValues={coOwner}
                onFinish={async (values) => {
                    await store.update(coOwner.id, values);
                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }
                    if (!store.updateItemLoader.hasError) {
                        CollateralsStore.loadItem(collateralId);
                    }
                }}
                width={1000}
                formProps={{ fields }}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={8}>
                        <TextField
                            name="civilId"
                            label={t(
                                'Dialog.EditCollateralOwnershipInfo.CivilId',
                                'Civil ID',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="firstName"
                            label={t(
                                'Dialog.EditCollateralOwnershipInfo.FirstName',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="lastName"
                            label={t(
                                'Dialog.EditCollateralOwnershipInfo.LastName',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="registrationNumber"
                            label={t(
                                'Dialog.EditCollateralOwnershipInfo.RegistrationNumber',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <PhoneField
                            name="phoneNumber"
                            label={t(
                                'Dialog.EditCollateralOwnershipInfo.PhoneNumber',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="fullAddress"
                            label={t(
                                'Dialog.EditCollateralOwnershipInfo.FullAddress',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <EnumSelectField
                            name="relationship"
                            enumName="REFERENCE_RELATIONSHIP"
                            label={t(
                                'Dialog.EditCollateralOwnershipInfo.Relationship',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <EnumSelectField
                            name="ownerType"
                            enumName="OWNER_TYPE"
                            label={t(
                                'Dialog.EditCollateralOwnershipInfo.CoOwnerType',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const EditCollateralOwnershipInfoDialog = addModalRenderer(
    EditCollateralOwnershipInfoDialogFn,
);
