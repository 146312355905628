import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { CollateralDetailsType } from 'src/generated-api-client';
import { FormHelper } from 'src/utils/FormHelper';

export const CreateLeasingCollateralGeneralInfoVariables = observer(() => {
    return (
        <Card title={t('Page.CollateralCreatePage.Leasing.GeneralInfo.Title')}>
            <Row gutter={16}>
                <HiddenField
                    name="type"
                    value={CollateralDetailsType.LEASING}
                />
                <Col xs={6}>
                    <TextField
                        name="itemName"
                        label={t(
                            'Page.CollateralCreatePage.Leasing.GeneralInfo.ItemName',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <NumberField
                        name="quantity"
                        label={t(
                            'Page.CollateralCreatePage.Leasing.GeneralInfo.Quantity',
                        )}
                        rules={[ValidationRules.greaterThanZero]}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="assetNumber"
                        label={t(
                            'Page.CollateralCreatePage.Leasing.GeneralInfo.AssetNumber',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={6}>
                    <NumberField
                        name="unitPrice"
                        label={t(
                            'Page.CollateralCreatePage.Leasing.GeneralInfo.UnitPrice',
                        )}
                        thousandDivider
                        rules={[ValidationRules.greaterThanZero]}
                    />
                </Col>
                <Col xs={6}>
                    <SelectField
                        name="hasEcoStatus"
                        label={t(
                            'Page.CollateralCreatePage.Leasing.GeneralInfo.HasEcoStatus',
                        )}
                        options={FormHelper.objAsOptions(
                            {
                                true: true,
                                false: false,
                            },
                            'Primitive.Boolean.YesNo.',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <EnumSelectField
                        name="ownershipType"
                        enumName="COLLATERAL_OWNERSHIP_TYPE"
                        label={t(
                            'Page.CollateralCreatePage.Leasing.GeneralInfo.OwnershipType',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="specifications"
                        label={t(
                            'Page.CollateralDetails.Tabs.Leasing.GeneralInfo.Specifications',
                        )}
                    />
                </Col>
            </Row>
        </Card>
    );
});
