import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';

export const ProfilePage = observer(() => {
    useEffect(() => {
        ProfileStore.loadProfile();
    }, []);

    const { profile } = ProfileStore;

    return (
        <BasePage
            title={t('Page.Profile.Title')}
            isLoading={ProfileStore.profileLoader.isLoading}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Card>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t('Page.Profile.Name')}
                                    value={profile?.name}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t('Page.Profile.Surname')}
                                    value={profile?.surname}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t('Page.Profile.Email')}
                                    value={profile?.email}
                                    format="mail"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t('Page.Profile.Phone')}
                                    value={profile?.phone}
                                    format="tel"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t('Page.Profile.ParentBranch')}
                                    value={profile?.parentBranch?.name}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t('Page.Profile.ChildBranch')}
                                    value={profile?.childBranch?.name}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t('Page.Profile.BranchManager')}
                                    value={profile?.branchManager}
                                    format="yes-no"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.Profile.RelationshipManager',
                                    )}
                                    value={profile?.relationshipManager}
                                    format="yes-no"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t('Page.Profile.Department')}
                                    value={profile?.department}
                                    format={(value) => (
                                        <EnumView
                                            enumName="BO_USER_DEPARTMENT"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t('Page.Profile.Position')}
                                    value={profile?.position}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t('Page.Profile.TeamsProfile')}
                                    value={profile?.teamsProfile}
                                    format="link-external"
                                    formatterProps={{
                                        to: profile?.teamsProfile,
                                    }}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={profile?.activity}
                                    label={t('Page.Profile.Activity')}
                                    format={(value) => (
                                        <EnumView
                                            enumName="USER_ACTIVITY"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </BasePage>
    );
});
