import { ColumnType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { Modal } from 'src/components/Modal/Modal';
import {
    CreditCommitteeDecisionListEntry,
    IdNameEntry,
} from 'src/generated-api-client';
import { N18TaskDecision } from 'src/pages/TaskDetailsPage/N18CsDecisionTask/Components/N18TaskDecision';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

export const N18TaskDecisionDetailsDialogFn = observer(
    ({ data, ...props }: ModalRendererProps) => {
        return (
            <Modal
                title={t('Dialog.N18TaskDecisionDetails.Title')}
                footer={null}
                maskClosable={false}
                width={1100}
                {...props}
            >
                <StyledListView dataSource={data} columns={columns} />
            </Modal>
        );
    },
);

export const N18TaskDecisionDetailsDialog = addModalRenderer(
    N18TaskDecisionDetailsDialogFn,
);

const StyledListView = styled(ListView)`
    padding: 0;
`;

const columns: ColumnType<CreditCommitteeDecisionListEntry>[] = [
    {
        title: <Trans i18nKey="Dialog.N18TaskDecisionDetails.List.Member" />,
        key: 'user',
        dataIndex: 'user',
        render: (value: IdNameEntry) => {
            return <DataView value={value?.name} format="string" hideLabel />;
        },
    },
    {
        title: <Trans i18nKey="Dialog.N18TaskDecisionDetails.List.Decision" />,
        key: 'decision',
        dataIndex: 'decision',
        render: (value) => {
            return <N18TaskDecision decision={value} />;
        },
    },
    {
        title: <Trans i18nKey="Dialog.N18TaskDecisionDetails.List.Amount" />,
        key: 'amount',
        dataIndex: 'amount',
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        title: <Trans i18nKey="Dialog.N18TaskDecisionDetails.List.Term" />,
        key: 'term',
        dataIndex: 'term',
    },
    {
        title: (
            <Trans i18nKey="Dialog.N18TaskDecisionDetails.List.InterestRate" />
        ),
        key: 'monthlyInterestRate',
        dataIndex: 'monthlyInterestRate',
        render: (value) => {
            return <DataView value={value} format="percent" hideLabel />;
        },
    },

    {
        title: <Trans i18nKey="Dialog.N18TaskDecisionDetails.List.Comment" />,
        key: 'comment',
        dataIndex: 'comment',
    },
];
