import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { CollectionStatus as DebtCollectionStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: DebtCollectionStatus;
};

export const CollectionStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: DebtCollectionStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: <EnumView enumName="COLLECTION_STATUS" value={status} />,
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [DebtCollectionStatus.SUCCESSFUL]: {
        title: (
            <EnumView
                enumName="COLLECTION_STATUS"
                value={DebtCollectionStatus.SUCCESSFUL}
            />
        ),
        color: '#FFF',
        backgroundColor: '#23C165',
    },
    [DebtCollectionStatus.PENDING]: {
        title: (
            <EnumView
                enumName="COLLECTION_STATUS"
                value={DebtCollectionStatus.PENDING}
            />
        ),
        color: '#FFF',
        backgroundColor: '#FFAD17',
    },
    [DebtCollectionStatus.FAILED]: {
        title: (
            <EnumView
                enumName="COLLECTION_STATUS"
                value={DebtCollectionStatus.FAILED}
            />
        ),
        color: '#FFF',
        backgroundColor: '#F75725',
    },
};
