import { Button, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CirclePlusIcon } from 'src/components/Icons/Icons';
import { CreateCollateral200Response } from 'src/generated-api-client';
import { getCollateralOwnershipInfoActions } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/CollateralOwnershipInfoActions.config';
import styled from 'styled-components';

type CollateralOwnershipInfoActionsProps = {
    collateral?: CreateCollateral200Response;
};

export const CollateralOwnershipInfoActions = observer(
    ({ collateral }: CollateralOwnershipInfoActionsProps) => {
        const actions = getCollateralOwnershipInfoActions(collateral);
        const hideActions = actions.length === 0;

        if (hideActions) {
            return null;
        }

        return (
            <StyledSpace size={16}>
                {actions.map(({ label: title, key: type, onClick }) => (
                    <Button
                        type="dashed"
                        icon={<CirclePlusIcon />}
                        key={type}
                        onClick={onClick}
                    >
                        {title}
                    </Button>
                ))}
            </StyledSpace>
        );
    },
);

const StyledSpace = styled(Space)`
    display: flex;
`;
