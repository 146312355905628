import { DefaultOptionType } from 'antd/lib/select';
import i18next from 'i18next';
import { computed } from 'mobx';
import { LocalizedEnum, LocalizedEnumTypes } from 'src/generated-api-client';
import { resourceApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import {
    BasicStoreApi,
    Entity,
} from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export type ResourceItem = Entity & LocalizedEnum;

export type EnumName = LocalizedEnumTypes;

export class EnumStoreClass extends BasicStore<ResourceItem> {
    api: BasicStoreApi<ResourceItem> = {
        loadList: async () => {
            const items = await RequestHelper.unwrapFromAxiosPromise(
                resourceApi.getEnums({ localizedEnum: this.enumName as any }),
            );

            return items.map((item) => ({ ...item, id: item.key }));
        },
    };

    constructor(private enumName: EnumName) {
        super();
    }

    @computed get options() {
        return this.list.map((item) =>
            convertEnumToOptions(item),
        ) as DefaultOptionType[];
    }

    static async loadEnums(enumNames: EnumName[]) {
        await Promise.allSettled(
            enumNames.map((enumName) =>
                EnumStore.getInstance(enumName).loadList(),
            ),
        );
    }

    getLocalizedEnum(key: string): string {
        const item = this.list.find((item) => item.key === key);

        return item ? (item.l10n as any)?.[i18next.language] : key;
    }
}

export const convertEnumToOptions = (item: ResourceItem) => ({
    label: (item.l10n as any)?.[i18next.language],
    value: item.key,
    'data-test': item.key,
});

export const EnumStore = new EntityProvider(EnumStoreClass);
