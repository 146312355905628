import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { DataInformationCard } from 'src/pages/IndividualDetailsPage/Components/DataInformationCard';
import { UpdateGeneralInformationDialog } from 'src/pages/IndividualDetailsPage/Dialogs/IndividualInformation/UpdateGeneralInformationDialog';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import {
    hasData,
    IndividualDetailKey,
} from 'src/pages/IndividualDetailsPage/Types/IndividualDetailsTypes';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export type IndividualGeneralInfoCardProps = {
    viewModel?: IndividualDetailsViewModelClass;
};

const generalInfoFieldKeys: Array<IndividualDetailKey> = [
    'idDocumentRegistrationNumber',
    'familyName',
    'citizenship',
    'hasMasterAgreement',
    'sex',
    'birthDate',
    'maritalStatus',
    'civilId',
];

export const IndividualGeneralInfoCard = observer(
    ({ viewModel }: IndividualGeneralInfoCardProps) => {
        return (
            <DataInformationCard
                title={t('Page.IndividualDetails.GeneralInfo.Title')}
                isLoading={viewModel?.itemLoader.isLoading}
                hasData={hasData(generalInfoFieldKeys, viewModel)}
                canUpdate={AppUser.hasPermissions(
                    UserPermission.IndividualUpdateGeneralInfo,
                )}
                onAdd={() => {
                    UpdateGeneralInformationDialog.render({
                        viewModel,
                    });
                }}
            >
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={
                                viewModel?.item?.idDocumentRegistrationNumber
                            }
                            label={t(
                                'Page.IndividualDetails.GeneralInfo.RegistrationNumber',
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.familyName}
                            label={t(
                                'Page.IndividualDetails.GeneralInfo.FamilyName',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.citizenship}
                            label={t(
                                'Page.IndividualDetails.GeneralInfo.Citizenship',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="CITIZENSHIP"
                                    value={value}
                                />
                            )}
                        />
                    </Col>

                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.hasMasterAgreement}
                            label={t(
                                'Page.IndividualDetails.GeneralInfo.MaConcluded',
                            )}
                            format="yes-no"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.sex}
                            label={t('Page.IndividualDetails.GeneralInfo.Sex')}
                            format={(value) => (
                                <EnumView enumName="SEX" value={value} />
                            )}
                        />
                    </Col>

                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.birthDate}
                            label={t(
                                'Page.IndividualDetails.GeneralInfo.BirthDate',
                            )}
                            format="date"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.maritalStatus}
                            label={t(
                                'Page.IndividualDetails.GeneralInfo.MaritalStatus',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="MARITAL_STATUS"
                                    value={value}
                                />
                            )}
                        />
                    </Col>

                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.civilId}
                            label={t(
                                'Page.IndividualDetails.GeneralInfo.CivilId',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={t(viewModel?.item?.taxNumber)}
                            label={t(
                                'Page.IndividualDetails.GeneralInfo.TaxNumber',
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={viewModel?.item?.numberOfFamilyMembers}
                            label={t(
                                'Page.IndividualDetails.GeneralInfo.NumberOfFamilyMembers',
                            )}
                        />
                    </Col>
                </Row>
            </DataInformationCard>
        );
    },
);
