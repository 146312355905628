import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { Spinner } from 'src/components/Spinner/Spinner';
import { ApplicationSchedulePeriodEntry } from 'src/generated-api-client';
import { ApplicationPaymentScheduleStore } from 'src/stores/ApplicationPaymentScheduleStore/ApplicationPaymentScheduleStore';
import styled from 'styled-components';

export type ApplicationRepaymentScheduleTabProps = {
    applicationId?: number;
};

export const RepaymentScheduleTab = observer(
    ({ applicationId }: ApplicationRepaymentScheduleTabProps) => {
        const store = ApplicationPaymentScheduleStore.getInstance(
            applicationId as number,
        );

        useEffect(() => {
            store.loadList();

            return () => {
                store.listLoader.reset();
            };
        }, [applicationId]);

        if (store.listLoader.isLoading) {
            return (
                <StyledWrapper>
                    <Spinner />
                </StyledWrapper>
            );
        }

        return (
            <>
                <StyledSpace size={20}>
                    <StyledDataView
                        value={store?.loanAmount}
                        label={t(
                            'Page.InstallmentLoanApplicationDetails.Tab.RepaymentSchedule.LoanAmount',
                        )}
                        format="money"
                    />
                    <StyledDataView
                        value={store?.termInMonths}
                        label={t(
                            'Page.InstallmentLoanApplicationDetails.Tab.RepaymentSchedule.Month',
                        )}
                    />
                </StyledSpace>

                <StyledListView dataSource={store.list} columns={columns} />
            </>
        );
    },
);

const columns: ColumnsType<ApplicationSchedulePeriodEntry> = [
    {
        key: 'indexNumber',
        title: (
            <Trans i18nKey="Page.InstallmentLoanApplicationDetails.Tab.RepaymentSchedule.IndexNumber" />
        ),
        render: (_, record, index) => index + 1,
    },
    {
        key: 'paymentAmount',
        dataIndex: 'paymentAmount',
        title: (
            <Trans i18nKey="Page.InstallmentLoanApplicationDetails.Tab.RepaymentSchedule.PaymentAmount" />
        ),
        render: (value) => {
            return <ColoredDataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'loanBalance',
        dataIndex: 'loanBalance',
        title: (
            <Trans i18nKey="Page.InstallmentLoanApplicationDetails.Tab.RepaymentSchedule.LoanBalance" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'principal',
        dataIndex: 'principal',
        title: (
            <Trans i18nKey="Page.InstallmentLoanApplicationDetails.Tab.RepaymentSchedule.Principal" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'interest',
        dataIndex: 'interest',
        title: (
            <Trans i18nKey="Page.InstallmentLoanApplicationDetails.Tab.RepaymentSchedule.Interest" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'paymentDate',
        dataIndex: 'paymentDate',
        title: (
            <Trans i18nKey="Page.InstallmentLoanApplicationDetails.Tab.RepaymentSchedule.PaymentDate" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="date" />;
        },
    },
];

const StyledSpace = styled(Space)`
    position: relative;
    margin-bottom: -8px;
`;

const StyledListView = styled(ListView)`
    position: relative;
`;

const StyledDataView = styled(DataView)`
    min-width: 224px;
    height: 80px;
    padding: 12px 24px;
    margin: 0;

    border-radius: 8px;
    background: #dfe0eb;

    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    .app-data-view {
    }

    .app-data-view__label {
        font-weight: 500;
        font-size: 12px !important;
        line-height: 22px;

        text-align: center;
        letter-spacing: 0.5px;
        color: #4d6082;
    }

    .app-data-view__value {
        font-weight: 700;
        font-size: 20px !important;
        line-height: 32px !important;

        text-align: center;
        letter-spacing: 0.5px;
        color: #4d6082;
    }
`;

const StyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ColoredDataView = styled(DataView)`
    .app-data-view__value {
        font-weight: 700;
        background: linear-gradient(
            90deg,
            #0d73bd -25.12%,
            #0562a5 -25.09%,
            #17c48d 215.89%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
`;
