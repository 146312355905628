/* eslint-disable max-lines */
import { Button, Col, Form as AntForm, notification, Row, Space } from 'antd';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { CheckboxField } from 'src/components/Form/CheckboxField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { Form } from 'src/components/Form/Form';
import { PhoneField } from 'src/components/Form/PhoneField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { NotFound } from 'src/components/NotFound/NotFound';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { User } from 'src/generated-api-client';
import { useUserId } from 'src/hooks/useUserId';
import { UserEditPageViewModel } from 'src/pages/UserEditPage/UserEditPageViewModel';
import { BranchOptionsStore } from 'src/stores/BranchOptionsStore/BranchOptionsStore';
import {
    convertRoleListItemToOption,
    RoleStore,
} from 'src/stores/RoleStore/RoleStore';
import { UsersStore } from 'src/stores/UsersStore/UsersStore';
import { FormHelper } from 'src/utils/FormHelper';
import { UrlHelper } from 'src/utils/UrlHelper';

export const UserEditPage = observer(() => {
    const { userId } = useUserId();
    const [form] = AntForm.useForm();
    const parentBranchId = AntForm.useWatch('parentBranchId', form);

    useEffect(() => {
        UserEditPageViewModel.load(userId);

        return () => {
            UserEditPageViewModel.reset();
        };
    }, [userId]);

    const fields = FormHelper.getFieldDataItems(
        UsersStore.updateItemLoader.errorData,
    );

    const user = UsersStore.currentItem;

    const { parentBranchOptions } = BranchOptionsStore;
    const childBranchOptions =
        BranchOptionsStore.childBranchOptions(parentBranchId);

    const error = useMemo(() => {
        const showNotFound =
            !_.isInteger(Number(userId)) ||
            UsersStore.itemLoader.errorData?.status === 404;
        if (showNotFound) {
            return (
                <NotFound
                    title={t('Page.UserEdit.Error.NotFound.Title')}
                    description={t('Page.UserEdit.Error.NotFound.Description', {
                        userId,
                    })}
                >
                    <Button
                        onClick={() => {
                            Router.navigate(RoutePaths.users);
                        }}
                    >
                        {t('Page.UserEdit.GoToList')}
                    </Button>
                </NotFound>
            );
        }

        return null;
    }, [userId, UsersStore.itemLoader.errorData]);

    return (
        <BasePage
            title={t('Page.UserEdit.Title', { userId })}
            isLoading={UserEditPageViewModel.isLoading}
            showOnlyLoading={!UserEditPageViewModel.isLoaded}
            error={error}
        >
            <Form
                form={form}
                onFinish={async (values: any) => {
                    await UserEditPageViewModel.save(userId, values);

                    if (
                        UsersStore.updateItemLoader.hasError &&
                        UsersStore.updateItemLoader.errorData.detail
                    ) {
                        notification.error({
                            message:
                                UsersStore.updateItemLoader.errorData.detail,
                        });
                    }

                    if (!UsersStore.updateItemLoader.hasError) {
                        notification.success({
                            message: t('Page.UserUpdate.Message.Success'),
                        });
                        Router.navigate(
                            UrlHelper.getTargetUrl(RoutePaths.userDetails, {
                                userId,
                            }),
                        );
                    }
                }}
                onReset={() => {
                    Router.navigate(
                        UrlHelper.getTargetUrl(RoutePaths.userDetails, {
                            userId,
                        }),
                    );
                }}
                fields={fields}
                initialValues={getInitialValues(user)}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            name="surname"
                            label={t('Page.UserCreate.Surname')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.name,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            name="name"
                            label={t('Page.UserCreate.Name')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.name,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            name="email"
                            label={t('Page.UserCreate.Email')}
                            disabled
                        />
                    </Col>
                    <Col span={12}>
                        <SelectField
                            store={RoleStore}
                            convertListItemToOption={
                                convertRoleListItemToOption
                            }
                            name="roleId"
                            label={t('Page.UserCreate.Role')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <EnumSelectField
                            name="department"
                            label={t('Page.UserCreate.Department')}
                            rules={[ValidationRules.required]}
                            enumName="BO_USER_DEPARTMENT"
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            name="position"
                            label={t('Page.UserCreate.Position')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectField
                            name="parentBranchId"
                            label={t('Page.UserCreate.ParentBranch')}
                            options={parentBranchOptions}
                            rules={[ValidationRules.required]}
                            onChange={() => {
                                form.setFields([
                                    { name: 'childBranchId', value: undefined },
                                ]);
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectField
                            name="childBranchId"
                            label={t('Page.UserCreate.ChildBranch')}
                            options={childBranchOptions}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <PhoneField
                            name="phone"
                            label={t('Page.UserCreate.Phone')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            name="teamsProfile"
                            label={t('Page.UserCreate.TeamsProfile')}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <CheckboxField
                            valuePropName="checked"
                            name="isBranchManager"
                            label={t('Page.UserCreate.IsBranchManager')}
                        />
                    </Col>
                    <Col span={12}>
                        <CheckboxField
                            valuePropName="checked"
                            name="isRelationshipManager"
                            label={t('Page.UserCreate.IsRelationshipManager')}
                        />
                    </Col>

                    <Col span={24}>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                data-test="save"
                            >
                                {t('Page.UserCreate.Btn.Save')}
                            </Button>
                            <Button
                                type="default"
                                htmlType="reset"
                                data-test="cancel"
                            >
                                {t('Page.UserCreate.Btn.Reset')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </BasePage>
    );
});

const getInitialValues = (user?: User) => {
    return {
        ...user,
        parentBranchId: String(user?.parentBranch?.id),
        childBranchId: String(user?.childBranch?.id),
        isBranchManager: user?.branchManager,
        isRelationshipManager: user?.relationshipManager,
        roleId: String(user?.role?.id),
    };
};
