import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';

export const IntangibleAssetCollateralGeneralInfoUpdateVariables = observer(
    () => {
        return (
            <Row gutter={16}>
                <HiddenField name="type" />
                <Col xs={6}>
                    <TextField
                        name="issuedOrganization"
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.ApproveConditions.IssuedOrganization',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="additionalField1"
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.ApproveConditions.AdditionalField1',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="additionalField2"
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.ApproveConditions.AdditionalField2',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <NumberField
                        name="availableAmount"
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.ApproveConditions.AvailableAmount',
                        )}
                        thousandDivider
                        rules={[
                            ValidationRules.required,
                            ValidationRules.greaterThanZero,
                        ]}
                    />
                </Col>
                <Col xs={6}>
                    <DatePickerField
                        name="expiresAt"
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.ApproveConditions.ExpiresAt',
                        )}
                        rules={[ValidationRules.required]}
                        disabledDate={(date) => {
                            return date.isSameOrBefore();
                        }}
                    />
                </Col>
                <Col xs={6}>
                    <NumberField
                        name="futureIncomeAmount"
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.ApproveConditions.FutureIncomeAmount',
                        )}
                        thousandDivider
                        rules={[
                            ValidationRules.required,
                            ValidationRules.greaterThanZero,
                        ]}
                    />
                </Col>
                <Col xs={6}>
                    <DatePickerField
                        name="startsAt"
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.ApproveConditions.StartsAt',
                        )}
                        rules={[ValidationRules.required]}
                        disabledDate={(date) => {
                            return date.isSameOrAfter(moment().startOf('day'));
                        }}
                    />
                </Col>
                <Col xs={6}>
                    <EnumSelectField
                        name="ownershipType"
                        enumName="COLLATERAL_OWNERSHIP_TYPE"
                        label={t(
                            'Page.CollateralDetails.Tabs.IntangibleAsset.GeneralInfo.ApproveConditions.OwnershipType',
                        )}
                    />
                </Col>
            </Row>
        );
    },
);
