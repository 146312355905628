import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { User } from 'src/generated-api-client';
import { UrlHelper } from 'src/utils/UrlHelper';

export type UserDetailsView = { user?: User };
export const UserDetailsView = observer(({ user }: UserDetailsView) => {
    const roleDetailsUrl = UrlHelper.getTargetUrl(RoutePaths.roleDetails, {
        roleId: user?.role?.id,
    });

    return (
        <Row gutter={16}>
            <Col xs={24}>
                <Card title={t('Page.UserDetails.UserInformation.Title')}>
                    <Row gutter={16}>
                        <Col xs={6}>
                            <DataView
                                value={user?.name}
                                label={t('Page.UserDetails.Name')}
                                data-test="name"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.surname}
                                label={t('Page.UserDetails.Surname')}
                                data-test="surname"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.email}
                                label={t('Page.UserDetails.Email')}
                                format="mail"
                                data-test="email"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.phone}
                                label={t('Page.UserDetails.Phone')}
                                data-test="phone"
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={6}>
                            <DataView
                                value={user?.id}
                                label={t('Page.UserDetails.Id')}
                                data-test="id"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.status}
                                label={t('Page.UserDetails.Status')}
                                data-test="status"
                                format={(value) => (
                                    <EnumView
                                        enumName="USER_STATUS"
                                        value={value}
                                    />
                                )}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.role?.name}
                                label={t('Page.UserDetails.Role')}
                                data-test="role"
                                format="link-internal"
                                formatterProps={{
                                    to: roleDetailsUrl,
                                }}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.registrationCompleted}
                                label={t(
                                    'Page.UserDetails.RegistrationCompleted',
                                )}
                                format="yes-no"
                                data-test="registrationCompleted"
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={6}>
                            <DataView
                                value={user?.parentBranch?.name}
                                label={t('Page.UserDetails.ParentBranch')}
                                data-test="parentBranch"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.childBranch?.name}
                                label={t('Page.UserDetails.ChildBranch')}
                                data-test="childBranch"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.branchManager}
                                label={t('Page.UserDetails.BranchManager')}
                                format="yes-no"
                                data-test="branchManager"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.relationshipManager}
                                label={t(
                                    'Page.UserDetails.RelationshipManager',
                                )}
                                format="yes-no"
                                data-test="relationshipManager"
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={6}>
                            <DataView
                                value={user?.department}
                                label={t('Page.UserDetails.Department')}
                                data-test="department"
                                format={(value) => (
                                    <EnumView
                                        enumName="BO_USER_DEPARTMENT"
                                        value={value}
                                    />
                                )}
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.position}
                                label={t('Page.UserDetails.Position')}
                                data-test="position"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.teamsProfile}
                                label={t('Page.UserDetails.TeamsProfile')}
                                format="link-external"
                                formatterProps={{
                                    to: user?.teamsProfile,
                                }}
                                data-test="teamsProfile"
                            />
                        </Col>
                        <Col xs={6}>
                            <DataView
                                value={user?.activity}
                                label={t('Page.UserDetails.Activity')}
                                data-test="activity"
                                format={(value) => (
                                    <EnumView
                                        enumName="USER_ACTIVITY"
                                        value={value}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
});
