/* eslint-disable max-lines */
import { Col, Form, Row } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { DefaultOptionType } from 'antd/lib/select';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Localization } from 'src/generated-api-client';
import { useFormGroup } from 'src/hooks/useFormGroup';
import { useFormGroupValue } from 'src/hooks/useFormGroupValue';
import { AdministrativeUnitWithId } from 'src/stores/Resources/AdministrativeUnitsStore/AdministrativeUnits.types';
import { AdministrativeUnitsStore } from 'src/stores/Resources/AdministrativeUnitsStore/AdministrativeUnitsStore';
import { FormHelper } from 'src/utils/FormHelper';

export type AddressFieldsProps = {
    hideFamiliarAddress?: boolean;
    hideLatitudeLongitude?: boolean;
    watchNamePathPrefix?: NamePath; // TODO: refactor this
};

const asOption = (value: AdministrativeUnitWithId) => {
    const language = i18next.language as keyof Localization;

    return {
        value: value.code,
        label: value.l10n?.[language],
    } as DefaultOptionType;
};

export const AddressFields = observer(
    ({
        hideFamiliarAddress,
        watchNamePathPrefix,
        hideLatitudeLongitude,
    }: AddressFieldsProps) => {
        const formGroup = useFormGroup();
        const address = useFormGroupValue({ watchNamePathPrefix });
        const nameFromFormRoot = (name: string) => {
            return [
                ...FormHelper.namePathToArray(watchNamePathPrefix),
                ...FormHelper.namePathToArray(
                    formGroup.getFormGroupItemName(name),
                ),
            ];
        };

        const form = Form.useFormInstance();

        const fullAddress = useMemo(() => {
            const administrativeUnitsPath =
                AdministrativeUnitsStore.getAdministrativeUnitsTreePath(
                    address?.provinceCode,
                    address?.districtCode,
                    address?.khorooCode,
                ) || [];
            const administrativeUnitNames = administrativeUnitsPath
                .map((unit) => unit?.l10n?.mn)
                .filter(Boolean);

            return [
                ...administrativeUnitNames,
                address?.streetName,
                address?.townName,
                address?.apartmentName,
            ]
                .filter((part) => Boolean(part))
                .join(' ');
        }, [address]);

        useEffect(() => {
            const namePath = nameFromFormRoot('fullAddress');
            form.setFields([
                {
                    name: namePath,
                    value: fullAddress,
                },
            ]);
            if (fullAddress) {
                form.validateFields([namePath]);
            }
        }, [fullAddress]);

        const provinceOptions = useMemo(() => {
            return AdministrativeUnitsStore.getProvinces().map(asOption);
        }, [AdministrativeUnitsStore.list]);

        const districtOptions = useMemo(() => {
            return address?.provinceCode
                ? AdministrativeUnitsStore.getDistricts(
                      address.provinceCode,
                  )?.map(asOption)
                : [];
        }, [AdministrativeUnitsStore.list, address?.provinceCode]);

        const khorooOptions = useMemo(() => {
            return address?.provinceCode && address?.districtCode
                ? AdministrativeUnitsStore.getKhoroos(
                      address.provinceCode,
                      address.districtCode,
                  )?.map(asOption)
                : [];
        }, [
            AdministrativeUnitsStore.list,
            address?.provinceCode,
            address?.districtCode,
        ]);

        useEffect(() => {
            const isTouchedProvince = form.isFieldTouched(
                nameFromFormRoot('provinceCode'),
                // formGroup.getFormGroupItemName(['provinceCode']),
            );
            if (isTouchedProvince) {
                form.setFields([
                    {
                        name: nameFromFormRoot('districtCode'),
                        value: undefined,
                    },
                    {
                        name: nameFromFormRoot('khorooCode'),
                        value: undefined,
                    },
                ]);
            }
        }, [address?.provinceCode]);

        useEffect(() => {
            const isTouchedDistrict = form.isFieldTouched(
                nameFromFormRoot('districtCode'),
            );
            if (isTouchedDistrict) {
                form.setFields([
                    {
                        name: nameFromFormRoot('khorooCode'),
                        value: undefined,
                    },
                ]);
            }
        }, [address?.districtCode]);

        return (
            <>
                <Row gutter={16}>
                    <Col xs={8}>
                        <SelectField
                            name={formGroup.getFormGroupItemName([
                                'provinceCode',
                            ])}
                            label={t('Entity.Address.CityProvince')}
                            required
                            rules={[ValidationRules.required]}
                            options={provinceOptions}
                            showSearch
                        />
                    </Col>

                    <Col xs={8}>
                        <SelectField
                            name={formGroup.getFormGroupItemName([
                                'districtCode',
                            ])}
                            label={t('Entity.Address.DistrictSum')}
                            required
                            rules={[ValidationRules.required]}
                            options={districtOptions}
                            showSearch
                        />
                    </Col>

                    <Col xs={8}>
                        <SelectField
                            name={formGroup.getFormGroupItemName([
                                'khorooCode',
                            ])}
                            label={t('Entity.Address.Khoroo')}
                            required
                            rules={[ValidationRules.required]}
                            options={khorooOptions}
                            showSearch
                        />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={8}>
                        <TextField
                            name={formGroup.getFormGroupItemName([
                                'streetName',
                            ])}
                            label={t('Entity.Address.Street')}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>

                    <Col xs={8}>
                        <TextField
                            name={formGroup.getFormGroupItemName(['townName'])}
                            label={t('Entity.Address.Town')}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>

                    <Col xs={8}>
                        <TextField
                            name={formGroup.getFormGroupItemName([
                                'apartmentName',
                            ])}
                            label={t('Entity.Address.Apartment')}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={16}>
                        <TextField
                            readOnly
                            name={formGroup.getFormGroupItemName([
                                'fullAddress',
                            ])}
                            label={t('Entity.Address.FullAddress')}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    {!hideFamiliarAddress && (
                        <Col xs={8}>
                            <TextField
                                name={formGroup.getFormGroupItemName([
                                    'familiarAddress',
                                ])}
                                label={t('Entity.Address.FamiliarAddress')}
                            />
                        </Col>
                    )}

                    {!hideLatitudeLongitude && (
                        <Col xs={8}>
                            <TextField
                                name={formGroup.getFormGroupItemName([
                                    'latitudeLongitude',
                                ])}
                                label={t('Entity.Address.LatitudeLongitude')}
                            />
                        </Col>
                    )}
                </Row>
            </>
        );
    },
);
