import { t } from 'i18next';
import { ActionType, TaskMetadata } from 'src/generated-api-client';
import { ChangeAssigneeDialog } from 'src/pages/TasksPage/Components/ChangeAssigneeDialog';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getTaskActions = (task?: TaskMetadata): ActionDefinition[] => {
    return [...getUIActions(task), ...getServerActions(task)];
};

const getUIActions = (_task?: TaskMetadata): ActionDefinition[] => {
    return [];
};

const getServerActions = (task?: TaskMetadata): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(task),
        task?.actions,
    );
};

const getRegisteredServerActions = (
    task?: TaskMetadata,
): ActionDefinition[] => {
    const taskId = task?.id;

    return [
        {
            key: ActionType.TASK_ASSIGN,
            label: t('Task.Actions.TASK_ASSIGN'),
            onClick: () => {
                ChangeAssigneeDialog.render({
                    taskId,
                });
            },
        },
    ];
};
