import { computed } from 'mobx';
import { ParentBranchOption } from 'src/generated-api-client';
import { brunchesApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class BranchOptionsStoreClass extends BasicStore<ParentBranchOption> {
    api: BasicStoreApi<ParentBranchOption> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                brunchesApi.branchOptions(),
            );

            return result || [];
        },
    };

    @computed get parentBranchOptions() {
        return this.list.map(asOption);
    }

    @computed get parentBranchMap() {
        return Object.fromEntries(
            this.list.map((branch) => {
                return [branch.id, branch];
            }),
        );
    }

    childBranchOptions(parentBranchId: number) {
        return this.parentBranchMap[parentBranchId]?.childBranchOptions?.map(
            asOption,
        );
    }
}

export const BranchOptionsStore = new BranchOptionsStoreClass();

const asOption = (value: any) => ({
    value: String(value.id),
    label: value.name,
    'data-test': value.name,
});
