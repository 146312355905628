import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { PhoneField } from 'src/components/Form/PhoneField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { WorkPhoneField } from 'src/components/Form/WorkPhoneField';
import { Modal } from 'src/components/Modal/Modal';
import { NewCivilServant } from 'src/generated-api-client';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type CreateCivilServantDialogFnProps = ModalRendererProps<{
    onSave: (values: NewCivilServant) => void;
}>;

const CreateCivilServantDialogFn = observer(
    ({ onSave, ...props }: CreateCivilServantDialogFnProps) => {
        return (
            <Modal
                title={t('Dialog.CreateCivilServantDialog.Title')}
                cancelText={t('Component.Modal.Btn.Back')}
                onFinish={(values) => {
                    onSave(values);
                }}
                width={1000}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={8}>
                        <TextField
                            name="name"
                            label={t('Dialog.CreateCivilServantDialog.Name')}
                            rules={[ValidationRules.required]}
                            maxLength={255}
                        />
                    </Col>
                    <Col xs={8}>
                        <PhoneField
                            name="phoneNumber"
                            label={t(
                                'Dialog.CreateCivilServantDialog.PhoneNumber',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <WorkPhoneField
                            name="workPhoneNumber"
                            label={t(
                                'Dialog.CreateCivilServantDialog.WorkPhoneNumber',
                            )}
                            rules={[ValidationRules.required]}
                            inputProps={{ maxLength: 32 }}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="position"
                            label={t(
                                'Dialog.CreateCivilServantDialog.Position',
                            )}
                            rules={[ValidationRules.required]}
                            maxLength={255}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="organization"
                            label={t(
                                'Dialog.CreateCivilServantDialog.Organization',
                            )}
                            rules={[ValidationRules.required]}
                            maxLength={255}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="workAddress"
                            label={t(
                                'Dialog.CreateCivilServantDialog.WorkAddress',
                            )}
                            rules={[ValidationRules.required]}
                            maxLength={1530}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const CreateCivilServantDialog = addModalRenderer(
    CreateCivilServantDialogFn,
);
