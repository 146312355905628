/* eslint-disable max-lines */
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListPage } from 'src/components/ListPage/ListPage';
import { BankStatementListItem } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { BankStatementsHeaderPanel } from 'src/pages/BankStatementsPage/BankStatementsHeaderPanel';
import { BankStatementActions } from 'src/pages/BankStatementsPage/Components/BankStatementActions';
import { BankStatementsStatus } from 'src/pages/BankStatementsPage/Components/BankStatementsStatus';
import { BankStatementDetailsDialog } from 'src/pages/BankStatementsPage/Dialogs/BankStatementDetailsDialog';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { BankStatementsStore } from 'src/stores/BankStatementsStore/BankStatementsStore';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';
import styled from 'styled-components';

const store = BankStatementsStore;
const enumsToLoad: EnumName[] = [
    'BANK_STATEMENT_OPERATION_TYPE',
    'BANK_STATEMENT_STATUS',
];

export const BankStatementsPage = observer(() => {
    const isLoading = useEnumsLoader(enumsToLoad);

    return (
        <ListPage
            store={store}
            columns={columns}
            isLoading={isLoading}
            header={
                <BankStatementsHeaderPanel
                    title={t('Page.BankStatements.Header')}
                />
            }
        />
    );
});

export const columns: ColumnsType<BankStatementListItem> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.BankStatements.List.Id" />,
        render: (value) => {
            const canReadDetails = AppUser.hasPermissions(
                UserPermission.BankStatementReadDetails,
            );
            if (canReadDetails) {
                return (
                    <StyledSpan
                        onClick={() =>
                            BankStatementDetailsDialog.render({
                                bankStatementId: value,
                            })
                        }
                    >
                        {value}
                    </StyledSpan>
                );
            }

            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'transactionDate',
        dataIndex: 'transactionDate',
        title: <Trans i18nKey="Page.BankStatements.List.Date" />,
        render: (value) => {
            return <DataView value={value} format="date" hideLabel />;
        },
    },
    {
        key: 'debitAmount',
        dataIndex: 'amount',
        title: <Trans i18nKey="Page.BankStatements.List.DebitAmount" />,
        render: (value, record) => {
            if (record.type === 1) {
                return (
                    <BlueColorDataView value={value} format="money" hideLabel />
                );
            }
        },
    },
    {
        key: 'creditAmount',
        dataIndex: 'amount',
        title: <Trans i18nKey="Page.BankStatements.List.CreditAmount" />,
        render: (value, record) => {
            if (record.type === 0) {
                return (
                    <OrangeColorDataView
                        value={value}
                        format="money"
                        hideLabel
                    />
                );
            }
        },
    },
    {
        key: 'balance',
        dataIndex: 'balance',
        title: <Trans i18nKey="Page.BankStatements.List.Balance" />,
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'currencyCode',
        dataIndex: 'currencyCode',
        title: <Trans i18nKey="Page.BankStatements.List.TransactionCurrency" />,
        render: (value) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView enumName="CURRENCY" value={value} />
                    )}
                />
            );
        },
    },
    {
        key: 'accountNumber',
        dataIndex: 'accountNumber',
        title: (
            <Trans i18nKey="Page.BankStatements.List.ContactAccountNumber" />
        ),
    },
    {
        key: 'accountName',
        dataIndex: 'accountName',
        title: <Trans i18nKey="Page.BankStatements.List.ContactAccountName" />,
    },
    {
        key: 'operationType',
        dataIndex: 'operationType',
        title: <Trans i18nKey="Page.BankStatements.List.TransactionType" />,
        render: (value) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView
                            enumName="BANK_STATEMENT_OPERATION_TYPE"
                            value={value}
                        />
                    )}
                />
            );
        },
    },
    {
        key: 'description',
        dataIndex: 'description',
        title: (
            <Trans i18nKey="Page.BankStatements.List.TransactionDescription" />
        ),
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Page.BankStatements.List.Status" />,
        render: (value) => {
            return <BankStatementsStatus status={value} />;
        },
    },
    {
        key: 'actions',
        render: (_, record) => {
            return <BankStatementActions bankStatement={record} />;
        },
    },
];

const BlueColorDataView = styled(DataView)`
    .app-data-view__value {
        background: linear-gradient(
            90deg,
            #0d73bd -25.12%,
            #0562a5 -25.09%,
            #17c48d 215.89%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
`;

const OrangeColorDataView = styled(DataView)`
    .app-data-view__value {
        color: #fc842b;
    }
`;

const StyledSpan = styled.span`
    color: #0562a5;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: #40a9ff;
    }
`;
