import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FilterForm } from 'src/components/Form/FilterForm';
import { LoansFilterForm } from 'src/pages/LoansPage/LoansFilterForm';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { DateHelper } from 'src/utils/DateHelper';

type LoansHeaderPanelProps = {
    title: React.ReactNode;
};

export const LoansHeaderPanel = observer(({ title }: LoansHeaderPanelProps) => {
    return (
        <FilterForm
            store={LoansStore}
            title={title}
            convertFilterCriteriaToFormValues={
                convertFilterCriteriaToFormValues
            }
            convertFormValuesToFilterCriteria={
                convertFormValuesToFilterCriteria
            }
        >
            <LoansFilterForm />
        </FilterForm>
    );
});

const convertFilterCriteriaToFormValues = (values: any) => {
    return {
        ...values,
        disbursementDateFrom:
            values.disbursementDateFrom && moment(values.disbursementDateFrom),
        disbursementDateTo:
            values.disbursementDateTo && moment(values.disbursementDateTo),
    };
};
const convertFormValuesToFilterCriteria = (values: any) => {
    return {
        ...values,
        disbursementDateFrom: DateHelper.formatISODate(
            values.disbursementDateFrom,
        ),
        disbursementDateTo: DateHelper.formatISODate(values.disbursementDateTo),
    };
};
