import { Form as AntForm, Typography } from 'antd';
import { Rule } from 'antd/lib/form';
import { t } from 'i18next';
import { PasswordValidators } from 'src/pages/Auth/PasswordValidators';

export type PasswordPolicyProps = {
    // eslint-disable-next-line react/require-default-props
    passwordFieldName?: string | string[];
};

export const PasswordPolicy = ({
    passwordFieldName = 'password',
}: PasswordPolicyProps) => {
    const password = AntForm.useWatch(passwordFieldName) || '';

    return (
        <>
            <Typography.Title level={3}>
                {t('Page.Auth.SetPassword.PasswordPolicy.Title')}
            </Typography.Title>
            <ul>
                <li>
                    <Typography.Text
                        type={
                            PasswordValidators.minLengthValidator(password)
                                ? 'success'
                                : undefined
                        }
                    >
                        {t('Page.Auth.SetPassword.PasswordPolicy.MinLength')}
                    </Typography.Text>
                </li>
                <li>
                    <Typography.Text
                        type={
                            PasswordValidators.minCountOfDigitsValidator(
                                password,
                            )
                                ? 'success'
                                : undefined
                        }
                    >
                        {t(
                            'Page.Auth.SetPassword.PasswordPolicy.MinCountOfDigits',
                        )}
                    </Typography.Text>
                </li>

                <li>
                    <Typography.Text
                        type={
                            PasswordValidators.minCountOfLettersValidator(
                                password,
                            )
                                ? 'success'
                                : undefined
                        }
                    >
                        {t(
                            'Page.Auth.SetPassword.PasswordPolicy.MinCountOfLetters',
                        )}
                    </Typography.Text>
                </li>

                <li>
                    <Typography.Text
                        type={
                            PasswordValidators.minCountOfCapitalizedLettersValidator(
                                password,
                            )
                                ? 'success'
                                : undefined
                        }
                    >
                        {t(
                            'Page.Auth.SetPassword.PasswordPolicy.MinCountOfCapitalizedLetters',
                        )}
                    </Typography.Text>
                </li>
            </ul>
        </>
    );
};

export const passwordRule: Rule = {
    type: 'string',
    validator: (rule: Rule, value: string) => {
        const isValid = [
            PasswordValidators.minLengthValidator,
            PasswordValidators.minCountOfDigitsValidator,
            PasswordValidators.minCountOfLettersValidator,
            PasswordValidators.minCountOfCapitalizedLettersValidator,
        ].every((validator) => validator(value));

        if (isValid) {
            return Promise.resolve(isValid);
        }

        return Promise.reject(
            t('Form.Rules.Validator.IncorrectPasswordPolicy'),
        );
    },
};
