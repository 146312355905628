import { observer } from 'mobx-react-lite';
import { BasePage } from 'src/components/BasePage/BasePage';
import { GroupItem } from 'src/components/GroupPage/GroupItem';
// eslint-disable-next-line import/no-cycle
import { RouterStore } from 'src/stores/RouterStore/RouterStore';
import { RouteGroup } from 'src/stores/RouterStore/RouterStore.types';
import styled from 'styled-components';

export type GroupPageProps = {
    groupKey: string;
};
export const GroupPage = observer(({ groupKey }: GroupPageProps) => {
    const group = RouterStore.routes.find(
        (route) => route.key === groupKey,
    ) as RouteGroup;

    return (
        <BasePage title={group?.label}>
            <GroupItems>
                {group.children
                    ?.filter((child) => child.label)
                    ?.filter((child) => child.available !== false)
                    .map((child) => (
                        <GroupItem key={child.key} routeDefinition={child} />
                    ))}
            </GroupItems>
        </BasePage>
    );
});

const GroupItems = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
`;
