import { notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { LegalCoBorrowerListEntry } from 'src/generated-api-client';
import { CoBorrowersStore } from 'src/stores/CoBorrowersStore/CoBorrowersStore';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const store = CoBorrowersStore;

type UpdateCoBorrowerDialogFnProps = ModalRendererProps<{
    coBorrower?: LegalCoBorrowerListEntry;
    loanId?: number;
}>;

const UpdateCoBorrowerDialogFn = observer(
    ({ coBorrower, loanId, ...props }: UpdateCoBorrowerDialogFnProps) => {
        useEffect(() => {
            return () => {
                store.updateItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );

        return (
            <Modal
                isLoading={store.updateItemLoader.isLoading}
                title={t('Dialog.Loan.UpdateCoBorrower.LegalEntity.Title')}
                initialValues={coBorrower}
                onFinish={async (values) => {
                    await store.update(Number(coBorrower?.id), values);

                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.updateItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.Loan.UpdateCoBorrower.Message.Success',
                            ),
                        });
                        LoansStore.loadItem(loanId);
                    }
                }}
                formProps={{ fields }}
                {...props}
            >
                <HiddenField name="type" initialValue="CoBorrowerUpdate" />
                <TextField
                    name="relationshipType"
                    label={t(
                        'Dialog.Loan.UpdateCoBorrower.Legal.RelationshipType',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Modal>
        );
    },
);

export const UpdateCoBorrowerDialog = addModalRenderer(
    UpdateCoBorrowerDialogFn,
);
