import { Button, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { GetProduct200Response } from 'src/generated-api-client';
import { getProductActions } from 'src/pages/LoanProducts/Components/ProductActions.config';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

type ProductActionsProps = {
    store?: BasicStore<any>;
    product?: GetProduct200Response;
};

export const ProductActions = observer(
    ({ product, store }: ProductActionsProps) => {
        const actions = getProductActions(product, store);
        const primaryActions = actions.filter((action) => action.primary);
        const secondaryActions = actions.filter((action) => !action.primary);
        const showSecondaryActions =
            secondaryActions && secondaryActions.length > 0;

        return (
            <StyledSpace size={16}>
                {primaryActions.map(({ label: title, key: type, onClick }) => (
                    <Button type="primary" key={type} onClick={onClick}>
                        {title}
                    </Button>
                ))}
                {showSecondaryActions && <Dropdown items={secondaryActions} />}
            </StyledSpace>
        );
    },
);

const StyledSpace = styled(Space)`
    display: flex;
`;
