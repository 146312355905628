import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FilterForm } from 'src/components/Form/FilterForm';
import { PlusIcon } from 'src/components/Icons/Icons';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { UsersFilterForm } from 'src/pages/UsersPage/UsersFilterForm';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { UsersStore } from 'src/stores/UsersStore/UsersStore';

type UsersHeaderPanelProps = {
    title: React.ReactNode;
};

export const UsersHeaderPanel = observer(({ title }: UsersHeaderPanelProps) => {
    const { t } = useTranslation();
    const canCreate = AppUser.hasPermissions(UserPermission.UserCreate);

    return (
        <div>
            <FilterForm
                store={UsersStore}
                title={title}
                panelExtra={
                    canCreate && (
                        <Button
                            icon={<PlusIcon />}
                            type="primary"
                            onClick={() =>
                                Router.navigate(RoutePaths.userCreate)
                            }
                            data-test="create-user"
                        >
                            {t('Page.Users.Button.CreateUser', 'Create user')}
                        </Button>
                    )
                }
            >
                <UsersFilterForm />
            </FilterForm>
        </div>
    );
});
