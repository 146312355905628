import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { IndividualInformationTabView } from 'src/generated-api-client';
import { DtiScoringTable } from 'src/pages/IndividualDetailsPage/Tabs/CreditScoringResultTables/DtiScoringTable';
import { GeneralScoringTable } from 'src/pages/IndividualDetailsPage/Tabs/CreditScoringResultTables/GeneralScoringTable';
import { IndividualDetailsScoringStore } from 'src/stores/IndividualDetailsScoringStore/IndividualDetailsScoringStore';

export type IndividualScoringResultTabProps = {
    individual?: IndividualInformationTabView;
};
export const IndividualScoringResultTab = observer(
    ({ individual }: IndividualScoringResultTabProps) => {
        const store = IndividualDetailsScoringStore.getInstance(
            individual?.id!,
        );

        useEffect(() => {
            store.loadCreditScoringResult();

            return () => {
                store.creditScoringResultLoader.reset();
            };
        }, [individual]);

        return (
            <>
                <DtiScoringTable store={store} />
                <GeneralScoringTable store={store} />
            </>
        );
    },
);
