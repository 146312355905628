import { t } from 'i18next';
import {
    ActionType,
    CreateCollateral200Response,
} from 'src/generated-api-client';
import { CreateExistingIndividualCoOwnerDialog } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/Dialogs/CreateExistingIndividualCoOwnerDialog';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getCollateralOwnershipInfoActions = (
    collateral?: CreateCollateral200Response,
) => {
    return [...getUIActions(), ...getServerActions(collateral)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    collateral?: CreateCollateral200Response,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(collateral),
        collateral?.actions,
    );
};

const getRegisteredServerActions = (
    collateral?: CreateCollateral200Response,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.CO_OWNER_ADD,
            label: t('CollateralCoOwner.Actions.Create'),
            onClick: () => {
                CreateExistingIndividualCoOwnerDialog.render({
                    collateralId: collateral?.id,
                });
            },
        },
    ];
};
