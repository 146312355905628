import {
    InstallmentProduct,
    InstallmentProductUpdate,
    NewInstallmentProduct,
    ProductListEntry,
    ProductStatus,
    RepaymentAllocationRule,
    RepaymentCalculationMethod,
} from 'src/generated-api-client';
import { productsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type InstallmentProductsFilter = {
    id?: number;
    externalId?: number;
    name?: string;
    interestRate?: number;
    statuses?: ProductStatus[];
    repaymentCalculationMethods?: RepaymentCalculationMethod[];
    penaltyInterestMultiplierFrom?: number;
    penaltyInterestMultiplierTo?: number;
    penaltyInterestAccrualStartDay?: number;
    repaymentAllocationRules?: RepaymentAllocationRule[];
};

const InstallmentProductsFilterKeys: Array<keyof InstallmentProductsFilter> = [
    'id',
    'externalId',
    'name',
    'interestRate',
    'statuses',
    'repaymentCalculationMethods',
    'penaltyInterestMultiplierFrom',
    'penaltyInterestMultiplierTo',
    'penaltyInterestAccrualStartDay',
    'repaymentAllocationRules',
];

export class InstallmentProductsStoreClass extends BasicStore<
    ProductListEntry,
    InstallmentProductsFilter,
    InstallmentProductUpdate,
    NewInstallmentProduct,
    InstallmentProduct
> {
    api: BasicStoreApi<
        ProductListEntry,
        InstallmentProductUpdate,
        NewInstallmentProduct,
        InstallmentProduct
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.getInstallmentProducts(this.listParams),
            );
        },

        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.getProduct({ id }),
            ) as Promise<InstallmentProduct>;
        },

        createItem: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.createProduct({
                    createProductRequest: {
                        ...data,
                        type: 'NewInstallmentProduct',
                    } as NewInstallmentProduct,
                }),
            );
        },

        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.updateProduct({
                    id: Number(id),
                    updateProductRequest: {
                        ...data,
                        type: 'InstallmentProductUpdate',
                    } as InstallmentProductUpdate,
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<InstallmentProductsFilter>(
        InstallmentProductsFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<ProductListEntry>('name');
}

export const InstallmentProductsStore = new InstallmentProductsStoreClass();
