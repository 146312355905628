/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { N14Task } from 'src/generated-api-client';
import { ConditionCardProps } from 'src/pages/TaskViewPage/TaskTypes';
import { UrlHelper } from 'src/utils/UrlHelper';

export const LaaLoanConditionCard = observer(
    ({ task }: ConditionCardProps<N14Task>) => {
        const { laatUserConditions, laatUserProduct } = task;

        const productDetailsUrl = UrlHelper.getTargetUrl(
            RoutePaths.productPledgedDetails,
            { productId: laatUserProduct?.id },
        );

        return (
            <Card
                title={t(
                    'Task.Form.N14TaskLAATLeader.LaatUserConditions.Title',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.createdAt}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.LaatUserConditions.EstimatedDate',
                            )}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.amount}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.LaatUserConditions.Amount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.repaymentFrequency}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.LaatUserConditions.RepaymentFrequency',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="REPAYMENT_FREQUENCY"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={task?.estimatedFirstRepaymentAmount}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.LaatUserConditions.FirstPaymentAmount',
                            )}
                            format="money"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.firstRepaymentDate}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.LaatUserConditions.FirstRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.secondRepaymentDate}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.LaatUserConditions.SecondRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.monthlyInterestRate}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.LaatUserConditions.MonthlyInterestRate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserConditions?.term}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.LaatUserConditions.EstimatedTerm',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={task?.estimatedIndividualDti}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.LaatUserConditions.EstimatedDTI',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={task?.estimatedCoBorrowersDti}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.LaatUserConditions.EstimatedDtiCo-borrower',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={laatUserProduct?.name}
                            label={t(
                                'Task.Form.N14TaskLAATLeader.LaatUserConditions.PledgedLoanProduct',
                                'Pledged Loan Product',
                            )}
                            format="link-internal"
                            formatterProps={{
                                to: productDetailsUrl,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
