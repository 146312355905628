/* eslint-disable max-lines */
import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { OverdueDaysGapsEditDialog } from 'src/pages/Settings/LoanOverdueDaysGapsPage/OverdueDaysGapsEditDialog';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { LoanOverdueDaysGapsStore } from 'src/stores/LoanOverdueDaysGapsStore/LoanOverdueDaysGapsStore';
import styled from 'styled-components';

const store = LoanOverdueDaysGapsStore;

const getMenuActions = () => {
    return [
        {
            key: 'edit',
            label: t('LoanOverdueDaysGaps.Actions.Edit'),
            onClick: () => {
                OverdueDaysGapsEditDialog.render();
            },
            disabled: !AppUser.hasPermissions(
                UserPermission.LoanSettingsDaysOverdueEdit,
            ),
        },
    ];
};

export const LoanOverdueDaysGapsPage = observer(() => {
    useEffect(() => {
        store.loadItem();

        return () => {
            store.itemLoader.reset();
        };
    }, []);

    const settings = store.currentItem;
    const menuActions = getMenuActions().filter((action) => !action.disabled);

    return (
        <BasePage title={t('Page.LoanOverdueDaysGaps.Title')}>
            <Row gutter={16}>
                <Col span={24}>
                    <Card
                        title={t(
                            'Page.LoanOverdueDaysGaps.Card.Title',
                            'Overdue days gaps',
                        )}
                        menuActions={menuActions}
                        isLoading={store.itemLoader.isLoading}
                    >
                        <Row gutter={16}>
                            <Col xs={8}>
                                <Row gutter={16}>
                                    <Col xs={24}>
                                        <StyledTitle>
                                            {t(
                                                'Page.LoanOverdueDaysGaps.NORMAL.Title',
                                                'Normal',
                                            )}
                                        </StyledTitle>
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.NORMAL.From',
                                            )}
                                            value={settings?.NORMAL?.from}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.NORMAL.To',
                                            )}
                                            value={settings?.NORMAL?.to}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={8}>
                                <Row gutter={16}>
                                    <Col xs={24}>
                                        <StyledTitle>
                                            {t(
                                                'Page.LoanOverdueDaysGaps.PAST_DUE.Title',
                                                'Past due',
                                            )}
                                        </StyledTitle>
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.PAST_DUE.From',
                                            )}
                                            value={settings?.PAST_DUE?.from}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.PAST_DUE.To',
                                            )}
                                            value={settings?.PAST_DUE?.to}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={8}>
                                <Row gutter={16}>
                                    <Col xs={24}>
                                        <StyledTitle>
                                            {t(
                                                'Page.LoanOverdueDaysGaps.STRESSED_1.Title',
                                                'Stressed 1',
                                            )}
                                        </StyledTitle>
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.STRESSED_1.From',
                                            )}
                                            value={settings?.STRESSED_1?.from}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.STRESSED_1.To',
                                            )}
                                            value={settings?.STRESSED_1?.to}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={8}>
                                <Row gutter={16}>
                                    <Col xs={24}>
                                        <StyledTitle>
                                            {t(
                                                'Page.LoanOverdueDaysGaps.STRESSED_2.Title',
                                                'Stressed 2',
                                            )}
                                        </StyledTitle>
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.STRESSED_2.From',
                                            )}
                                            value={settings?.STRESSED_2?.from}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.STRESSED_2.To',
                                            )}
                                            value={settings?.STRESSED_2?.to}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={8}>
                                <Row gutter={16}>
                                    <Col xs={24}>
                                        <StyledTitle>
                                            {t(
                                                'Page.LoanOverdueDaysGaps.SUB_STANDARD.Title',
                                                'Sub standard',
                                            )}
                                        </StyledTitle>
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.SUB_STANDARD.From',
                                            )}
                                            value={settings?.SUB_STANDARD?.from}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.SUB_STANDARD.To',
                                            )}
                                            value={settings?.SUB_STANDARD?.to}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={8}>
                                <Row gutter={16}>
                                    <Col xs={24}>
                                        <StyledTitle>
                                            {t(
                                                'Page.LoanOverdueDaysGaps.DOUBTFUL.Title',
                                                'Doubtful',
                                            )}
                                        </StyledTitle>
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.DOUBTFUL.From',
                                            )}
                                            value={settings?.DOUBTFUL?.from}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <DataView
                                            label={t(
                                                'Page.LoanOverdueDaysGaps.DOUBTFUL.To',
                                            )}
                                            value={settings?.DOUBTFUL?.to}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </BasePage>
    );
});

const StyledTitle = styled(Typography.Title)`
    &.ant-typography {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #4d6082;
        margin-bottom: 0;
    }
`;
