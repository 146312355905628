import { Button, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'src/components/Form/Form';
import { PasswordField } from 'src/components/Form/PssswordField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { AuthPage } from 'src/pages/Auth/AuthPage';
import { LoginPageErrorMessage } from 'src/pages/Auth/LoginPageErrorMessage';
import { AppAuthStoreClass } from 'src/stores/AppAuthStore/AppAuthStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { LocalAuthStoreClass } from 'src/stores/AuthStore/LocalAuthStore';
import styled from 'styled-components';

const authStore = (AuthStore as AppAuthStoreClass)
    .authStoreImplementation as LocalAuthStoreClass;

export const LoginPage = observer(() => {
    useEffect(() => {
        if (AuthStore.token) {
            AuthStore.logout();
        }

        return () => {
            authStore.tokenByLoginPasswordLoader.reset();
        };
    }, []);

    return (
        <AuthPage
            title={t('Page.Auth.Login.Title')}
            isLoading={authStore.isLoginProcessing}
        >
            <Form
                onFinish={async ({ login, password }) => {
                    await authStore.getTokenByLoginAndPassword(login, password);
                }}
            >
                <Typography.Title level={3}>
                    {t('Page.Auth.Login.Description')}
                </Typography.Title>
                <TextField
                    name="login"
                    label={t('Page.Auth.Login.Login')}
                    rules={[ValidationRules.requiredEmail]}
                />
                <PasswordField
                    name="password"
                    label={t('Page.Auth.Login.Password')}
                    inputProps={{ type: 'password' }}
                    rules={[ValidationRules.requiredPassword]}
                />
                <LoginPageErrorMessage />
                <StyledSpace direction="vertical" size={16}>
                    {authStore.isPasswordExpired && (
                        <Button
                            type="default"
                            htmlType="button"
                            block
                            onClick={() => {
                                Router.navigate(
                                    RoutePaths.authRequestToRecoveryExpiredPassword,
                                );
                            }}
                        >
                            {t('Page.Auth.Login.RecoveryExpiredPassword')}
                        </Button>
                    )}
                    <Button type="primary" htmlType="submit" block>
                        {t('Page.Auth.Login.Submit')}
                    </Button>
                    <Link to={RoutePaths.authForgotPassword}>
                        {t('Page.Auth.Login.ForgotPassword')}
                    </Link>
                </StyledSpace>
            </Form>
        </AuthPage>
    );
});

const StyledSpace = styled(Space)`
    width: 100%;
    margin-top: 20px;
`;
