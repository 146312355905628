import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';

export const ApplicationsFilterForm = observer(() => {
    return (
        <>
            <TextField
                name="externalId"
                label={t('Page.ApplicationsPage.Filter.ApplicationID')}
            />
            <EnumSelectField
                mode="multiple"
                name="productTypes"
                label={t('Page.ApplicationsPage.Filter.ProductType')}
                enumName="PRODUCT_TYPE"
            />
            <TextField
                name="civilId"
                label={t('Page.ApplicationsPage.Filter.CivilId')}
            />
            <TextField
                name="registrationNumber"
                label={t('Page.ApplicationsPage.Filter.RegistrationNumber')}
            />
            <TextField
                name="customerName"
                label={t('Page.ApplicationsPage.Filter.CustomerName')}
            />
            <TextField
                name="phone"
                label={t('Page.ApplicationsPage.Filter.PhoneNumber')}
            />
            <NumberField
                name="relationshipManagerId"
                label={t('Page.ApplicationsPage.Filter.RelationshipManagerId')}
                onlyInteger
            />
            <TextField
                name="branchCode"
                label={t('Page.ApplicationsPage.Filter.BranchCode')}
            />
            <EnumSelectField
                mode="multiple"
                name="applicationStatuses"
                label={t('Page.ApplicationsPage.Filter.Status')}
                enumName="LOAN_APPLICATION_STATUS"
            />
            <DatePickerField
                name="createdDateFrom"
                label={t('Page.ApplicationsPage.Filter.CreatedDateFrom')}
            />
            <DatePickerField
                name="createdDateTo"
                label={t('Page.ApplicationsPage.Filter.CreatedDateTo')}
            />
        </>
    );
});
