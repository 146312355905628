import { Layout, Typography } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Spinner } from 'src/components/Spinner/Spinner';
import styled from 'styled-components';

export type BasePageProps = Omit<React.HTMLAttributes<any>, 'title'> & {
    title?: React.ReactNode;
    header?: React.ReactNode;
    isLoading?: boolean;
    isUpdating?: boolean;
    showOnlyLoading?: boolean;
    error?: React.ReactNode;
};

export const BasePage: React.FC<BasePageProps> = observer(
    ({
        children,
        isLoading,
        title,
        header,
        showOnlyLoading,
        error,
        ...props
    }) => {
        if (error) {
            return <StyledContent {...props}>{error}</StyledContent>;
        }

        if (showOnlyLoading && isLoading) {
            return (
                <StyledContent {...props}>
                    <StyledSpinner />
                </StyledContent>
            );
        }

        return (
            <StyledContent {...props}>
                {isLoading && <StyledSpinner />}
                {title && <StyledPageTitle>{title}</StyledPageTitle>}
                {header && <StyledPageHeader>{header}</StyledPageHeader>}
                {children}
            </StyledContent>
        );
    },
);

export const StyledContent = styled<React.FC<BasicProps>>(Layout.Content)`
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    padding-top: 20px;
    min-height: 200px;
    background: transparent;
`;

const StyledSpinner = styled(Spinner).attrs((props) => ({
    ...props,
    className: classnames(props.className, 'page-spin'),
}))`
    position: fixed;
    top: 0;
    left: 255px;
    width: calc(100vw - 255px);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1;
`;

const StyledPageTitle = styled(Typography.Title)`
    &.ant-typography {
        margin-bottom: 20px;
    }

    &.ant-typography > .ant-row > .ant-col {
        font-size: 40px;
        line-height: 105%;
        color: #0d0f1c;
    }
`;

const StyledPageHeader = styled.div`
    position: relative;
    margin-bottom: 30px;
`;
