/* eslint-disable max-lines */
import { Button, Col, notification, Row, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { Form } from 'src/components/Form/Form';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import {
    FieldMaxLength,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { InstallmentProductsStore } from 'src/stores/InstallmentProductsStore/InstallmentProductsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = InstallmentProductsStore;

export const InstallmentProductCreatePage = observer(() => {
    const yesNoOptions = FormHelper.objAsOptions(
        {
            true: true,
            false: false,
        },
        'Primitive.Boolean.YesNo.',
    );

    useEffect(() => {
        return () => {
            store.createItemLoader.reset();
        };
    }, []);

    const fields = FormHelper.getFieldDataItems(
        store.createItemLoader.errorData,
    );

    return (
        <BasePage
            title={t('Page.InstallmentProductCreate.Title')}
            isLoading={store.createItemLoader.isLoading}
        >
            <Form
                onFinish={async (values) => {
                    const item = await store.create(values);

                    if (!store.createItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Page.InstallmentProductCreate.Message.Success',
                            ),
                        });
                        const targetUrl = UrlHelper.getTargetUrl(
                            RoutePaths.productInstallmentDetails,
                            { productId: item?.id },
                        );

                        Router.navigate(targetUrl);
                    }
                }}
                onReset={() => {
                    Router.navigate(RoutePaths.productsInstallment);
                }}
                fields={fields}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            name="name"
                            label={t('Page.InstallmentProductCreate.Name')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.maxFieldLength(
                                    FieldMaxLength.LoanProductName,
                                ),
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="penaltyInterestMultiplier"
                            label={t(
                                'Page.InstallmentProductCreate.PenaltyInterestMultiplier',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberMaxValue(100),
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="interestRate"
                            label={t(
                                'Page.InstallmentProductCreate.InterestRate',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberRange(0, 100),
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="penaltyInterestAccrualStartDay"
                            label={t(
                                'Page.InstallmentProductCreate.PenaltyInterestAccrualStartDay',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.greaterThanZero,
                            ]}
                            onlyInteger
                        />
                    </Col>
                    <Col span={12}>
                        <EnumSelectField
                            name="repaymentAllocationRule"
                            label={t(
                                'Page.InstallmentProductCreate.RepaymentAllocationRule',
                            )}
                            rules={[ValidationRules.required]}
                            enumName="REPAYMENT_ALLOCATION_RULE"
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="dti"
                            label={t('Page.InstallmentProductCreate.Dti')}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberMaxValue(100),
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <EnumSelectField
                            name="repaymentCalculationMethod"
                            label={t(
                                'Page.InstallmentProductCreate.RepaymentCalculationMethod',
                            )}
                            rules={[ValidationRules.required]}
                            enumName="REPAYMENT_CALCULATION_METHOD"
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="maxLoanAmount"
                            label={t(
                                'Page.InstallmentProductCreate.MaxLoanAmount',
                            )}
                            thousandDivider
                            rules={[
                                ValidationRules.required,
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <EnumSelectField
                            name="penaltyInterestAccrualMethod"
                            label={t(
                                'Page.InstallmentProductCreate.PenaltyInterestAccrualMethod',
                            )}
                            rules={[ValidationRules.required]}
                            enumName="PENALTY_INTEREST_ACCRUAL_METHOD"
                        />
                    </Col>
                    <Col span={12}>
                        <NumberField
                            name="maxTenor"
                            label={t('Page.InstallmentProductCreate.MaxTenor')}
                            onlyInteger
                            rules={[
                                ValidationRules.required,
                                ValidationRules.numberMaxValue(100),
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectField
                            name="excludedFromOffer"
                            label={t(
                                'Page.InstallmentProductCreate.ExcludedFromOffer',
                            )}
                            rules={[ValidationRules.required]}
                            options={yesNoOptions}
                        />
                    </Col>
                    <Col span={24}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                {t('Page.InstallmentProductCreate.Btn.Create')}
                            </Button>
                            <Button type="default" htmlType="reset">
                                {t('Page.InstallmentProductCreate.Btn.Reset')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </BasePage>
    );
});
