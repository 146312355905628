import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { BnplProduct } from 'src/generated-api-client';
import { ProductsStatus } from 'src/pages/LoanProducts/ProductsStatus';

export type BNPLProductDetailsBriefProps = {
    productBNPL?: BnplProduct;
};
export const BNPLProductDetailsBrief = observer(
    ({ productBNPL }: BNPLProductDetailsBriefProps) => {
        return (
            <Brief>
                <Row gutter={16}>
                    <Col span={6}>
                        <DataView
                            value={productBNPL?.name}
                            label={t('Page.BNPLProductDetails.Name')}
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productBNPL?.maxLoanAmount}
                            label={t('Page.BNPLProductDetails.MaxLoanAmount')}
                            format="money"
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productBNPL?.transferringFee}
                            label={t('Page.BNPLProductDetails.TransferringFee')}
                            format="money"
                        />
                    </Col>
                    <Col span={6}>
                        <DataView
                            value={productBNPL?.status}
                            label={t('Page.BNPLProductDetails.Status')}
                            format={(value) => (
                                <ProductsStatus status={value} />
                            )}
                        />
                    </Col>
                </Row>
            </Brief>
        );
    },
);
