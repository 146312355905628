import { observer } from 'mobx-react-lite';
import { CreateCollateral200Response } from 'src/generated-api-client';
import { CollateralEvaluation } from 'src/pages/CollateralDetailsPage/Components/CollateralEvaluation/CollateralEvaluation';
import { CollateralInsurance } from 'src/pages/CollateralDetailsPage/Components/CollateralInsurance/CollateralInsurance';
import { CollateralOwnershipInfo } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/CollateralOwnershipInfo';
import { IntangibleAssetCollateralGeneralInfo } from 'src/pages/CollateralDetailsPage/Tabs/IntangibleAssetCollateralTab/IntangibleAssetCollateralGeneralInfo';

type CollateralGeneralInfoTabProps = {
    collateral?: CreateCollateral200Response;
};
export const IntangibleAssetCollateralGeneralInfoTab = observer(
    ({ collateral }: CollateralGeneralInfoTabProps) => {
        return (
            <>
                <IntangibleAssetCollateralGeneralInfo />
                <CollateralOwnershipInfo collateral={collateral} />
                <CollateralEvaluation />
                <CollateralInsurance />
            </>
        );
    },
);
