import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';

export const CarCollateralGeneralInfoUpdateVariables = observer(() => {
    return (
        <Row gutter={16}>
            <HiddenField name="type" />
            <Col xs={6}>
                <EnumSelectField
                    name="steeringWheel"
                    enumName="STEERING_WHEEL"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.SteeringWheel',
                    )}
                />
            </Col>
            <Col xs={6}>
                <EnumSelectField
                    name="category"
                    enumName="CAR_COLLATERAL_CATEGORY"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.Category',
                    )}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="registrationNumber"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.RegistrationNumber',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="chassisNumber"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.ChassisNumber',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="carBrand"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.CarBrand',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="model"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.Model',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="color"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.Color',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <TextField
                    name="engineType"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.EngineType',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="engineCapacity"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.EngineCapacity',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <DatePickerField
                    name="dateOfManufacture"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.DateOfManufacture',
                    )}
                    picker="year"
                    format="YYYY"
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <DatePickerField
                    name="importedDate"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.ImportedDate',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="mileage"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.Mileage',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="overallGrade"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.OverallGrade',
                    )}
                    rules={[ValidationRules.greaterThanZero]}
                />
            </Col>
            <Col xs={6}>
                <EnumSelectField
                    name="ownershipType"
                    enumName="COLLATERAL_OWNERSHIP_TYPE"
                    label={t(
                        'Page.CollateralDetails.Tabs.Car.GeneralInfo.ApproveConditions.OwnershipType',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
        </Row>
    );
});
