import { Form, FormItemProps } from 'antd';
import React from 'react';
import { InputNumber } from 'src/components/Inputs/InputNumber';

export type NumberFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    max?: number;
    // eslint-disable-next-line react/require-default-props
    min?: number;
    // eslint-disable-next-line react/require-default-props
    onlyInteger?: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    stringMode?: boolean;
    thousandDivider?: boolean | string;
};

const disableNotNumberKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
        return true;
    }

    if (!Number.isFinite(Number.parseInt(event.key))) {
        event.preventDefault();

        return false;
    }
};

const DEFAULT_THOUSAND_DIVIDER = ',';
const thousandDividerFormat = (
    value: any,
    divider = DEFAULT_THOUSAND_DIVIDER,
) => value.replace(/\B(?=(\d{3})+(?!\d))/g, divider);
const thousandDividerParser = (
    value: any,
    divider = DEFAULT_THOUSAND_DIVIDER,
) => value!.replaceAll(divider, '');

export const NumberField = ({
    name,
    label,
    min,
    max,
    onlyInteger,
    disabled,
    stringMode,
    thousandDivider,
    ...props
}: // eslint-disable-next-line sonarjs/cognitive-complexity
NumberFieldProps) => {
    const targetOnKeyPress = onlyInteger ? disableNotNumberKey : undefined;
    const getFormatter = (value: any) => {
        if (thousandDivider) {
            const divider =
                typeof thousandDivider === 'string'
                    ? thousandDivider
                    : undefined;

            return thousandDividerFormat(value, divider);
        }

        return value;
    };
    const getParser = (value: any) => {
        if (thousandDivider) {
            const divider =
                typeof thousandDivider === 'string'
                    ? thousandDivider
                    : undefined;

            return thousandDividerParser(value, divider);
        }

        return value;
    };

    return (
        <Form.Item {...props} name={name} label={label}>
            <InputNumber
                min={min}
                max={max}
                onKeyPress={targetOnKeyPress}
                disabled={disabled}
                stringMode={stringMode}
                formatter={(value) => getFormatter(value)}
                parser={(value) => getParser(value)}
            />
        </Form.Item>
    );
};
