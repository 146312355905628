import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import {
    ApplicationCollateralListEntry,
    PledgedLoanApplication,
} from 'src/generated-api-client';
import { ApplicationCollateralsStore } from 'src/stores/ApplicationCollateralsStore/ApplicationCollateralsStore';

export type ApplicationCollateralsTabProps = {
    application?: PledgedLoanApplication;
};

export const CollateralsTab = observer(
    ({ application }: ApplicationCollateralsTabProps) => {
        const store = ApplicationCollateralsStore.getInstance(
            Number(application?.id),
        );

        useEffect(() => {
            store.loadList();

            return () => {
                store.listLoader.reset();
            };
        }, [application?.id]);

        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.Tab.CollateralsInformation.Title',
                        )}
                    >
                        <ListView
                            store={store}
                            columns={columns}
                            loading={store.listLoader.isLoading}
                        />
                    </Card>
                </Col>
            </Row>
        );
    },
);

const columns: ColumnsType<ApplicationCollateralListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CollateralsInformation.List.Id" />
        ),
        render(value) {
            return (
                <DataView
                    hideLabel
                    value={value}
                    format="link-internal"
                    formatterProps={{
                        to: EntityLinks.collaterals.itemDetails(value),
                    }}
                />
            );
        },
    },
    {
        key: 'subType',
        dataIndex: 'subType',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CollateralsInformation.List.SubType" />
        ),
        render(value) {
            return <EnumView enumName="COLLATERAL_SUB_TYPE" value={value} />;
        },
    },
    {
        key: 'sizeMark',
        dataIndex: 'sizeMark',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CollateralsInformation.List.SizeMark" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'registrationNumber ',
        dataIndex: 'registrationNumber',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CollateralsInformation.List.RegistrationNumber" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'marketValue',
        dataIndex: 'marketValue',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CollateralsInformation.List.MarketValue" />
        ),
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'rmValue',
        dataIndex: 'rmValue',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CollateralsInformation.List.RmValue" />
        ),
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'importedDate',
        dataIndex: 'importedDate',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CollateralsInformation.List.ImportedDate" />
        ),
        render: (value) => {
            return <DataView value={value} format="date" hideLabel />;
        },
    },
    {
        key: 'ownerName',
        dataIndex: 'ownerName',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CollateralsInformation.List.OwnerName" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'agreementType',
        dataIndex: 'agreementType',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.CollateralsInformation.List.AgreementType" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel />;
        },
    },
];
