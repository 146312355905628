import { Trans } from 'react-i18next';
import { CreateChildBranchDialog } from 'src/pages/BranchesPage/Dialogs/CreateChildBranchDialog';
import { CreateParentBranchDialog } from 'src/pages/BranchesPage/Dialogs/CreateParentBranchDialog';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { ActionDefinition } from 'src/utils/UserActionsHelper';

export const getBranchActions = (): Array<ActionDefinition> => [
    {
        key: 'add-parent',
        label: <Trans i18nKey="Branch.Actions.AddParentBranch" />,
        onClick: () => {
            CreateParentBranchDialog.render();
        },
        disabled: !AppUser.hasPermissions(UserPermission.BranchCreate),
    },
    {
        key: 'add-child',
        label: <Trans i18nKey="Branch.Actions.AddChildBranch" />,
        onClick: () => {
            CreateChildBranchDialog.render();
        },
        disabled: !AppUser.hasPermissions(UserPermission.BranchCreate),
    },
];
