import { EnumName, EnumStore } from 'src/stores/Resources/EnumStore/EnumStore';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';

export class EnumsLoadersClass {
    enumsLoaders: AsyncOperationWithStatus<any>[];
    loadingInfo: CombinedLoadingStatus;

    constructor(private enumsToLoad: EnumName[]) {
        this.enumsLoaders = this.getEnumsLoaders(enumsToLoad);
        this.loadingInfo = new CombinedLoadingStatus([...this.enumsLoaders]);
    }

    getEnumsLoaders = (enumsToLoad?: EnumName[]) => {
        return (
            enumsToLoad?.map(
                (enumName) => EnumStore.getInstance(enumName).listLoader,
            ) || []
        );
    };

    loadEnums = () => {
        this.enumsLoaders?.forEach((enumLoader) => {
            if (!enumLoader.isLoaded) {
                enumLoader.call();
            }
        });
    };
}
