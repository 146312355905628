import { Col, Row, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { Role } from 'src/generated-api-client';
import styled from 'styled-components';

export type RoleDetailsViewProps = { role?: Role };
export const RoleDetailsView = observer(({ role }: RoleDetailsViewProps) => {
    const { t } = useTranslation();

    return (
        <StyledSpace direction="vertical" size={16}>
            <Card title={t('Page.RoleDetails.Details')}>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            label={t('Page.RoleDetails.Name')}
                            value={role?.name}
                        />
                    </Col>

                    <Col xs={6}>
                        <DataView
                            label={t('Page.RoleDetails.StartPage')}
                            value={role?.startPage}
                            format={(value) => (
                                <EnumView enumName="START_PAGE" value={value} />
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            label={t('Page.RoleDetails.Description')}
                            value={role?.description}
                        />
                    </Col>
                </Row>
            </Card>
        </StyledSpace>
    );
});

const StyledSpace = styled(Space)`
    width: 100%;
`;
