import React from 'react';

type ActionTypeDefinitionOnClick = (() => void) | (() => Promise<void>);

export type ActionDefinition = {
    key: string;
    label?: React.ReactNode;
    onClick?: ActionTypeDefinitionOnClick;
    disabled?: boolean;
    hidden?: boolean;
    primary?: boolean;
};

export type UserActionMeta<T = string> = {
    type: string;
    actionType?: T;
};

function intersectActionDefinitionsWithActionsMeta(
    actionDefinitions: Array<ActionDefinition>,
    actionsMeta?: Array<UserActionMeta>,
): ActionDefinition[] {
    const actionsMetaMap = new Map<string | undefined, UserActionMeta>(
        actionsMeta?.map((meta) => [meta.actionType, meta]),
    );

    return actionDefinitions
        .map((actionDefinition) => {
            const actionMeta = actionsMetaMap.get(actionDefinition.key);

            if (!actionMeta) {
                return;
            }

            return extendActionDefinitionWithActionMeta(
                actionDefinition,
                actionMeta,
            );
        })
        .filter(Boolean) as unknown as ActionDefinition[];
}

function extendActionDefinitionWithActionMeta(
    actionDefinition: ActionDefinition,
    actionMeta: UserActionMeta,
): ActionDefinition {
    return {
        ...actionMeta,
        ...actionDefinition,
        key: actionDefinition.key || actionMeta?.actionType!,
    };
}

export const UserActionsHelper = {
    extendActionDefinitionWithActionMeta,
    intersectActionDefinitionsWithActionsMeta,
};
