import { Modal, ModalFuncProps } from 'antd';
import { t } from 'i18next';
import React from 'react';
import {
    AcceptSquareRoundedIcon,
    CloseSquareRoundedIcon,
    ModalCloseIcon,
} from 'src/components/Icons/Icons';

const DEFAULT_MODAL_WIDTH = 530;

export const ModalConfirm = ({ ...dialogProps }: ModalFuncProps) => {
    Modal.confirm({
        ...dialogProps,
        icon: '',
        okText: t('Component.ModalConfirm.Button.Yes'),
        cancelText: t('Component.ModalConfirm.Button.Cancel'),
        okButtonProps: { icon: <AcceptSquareRoundedIcon /> },
        cancelButtonProps: { icon: <CloseSquareRoundedIcon /> },
        closable: true,
        closeIcon: <ModalCloseIcon />,
        className: 'app-modal-confirm',
        width: dialogProps.width || DEFAULT_MODAL_WIDTH,
    });
};
