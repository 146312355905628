import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import { AppInfo } from 'src/components/AppInfo/AppInfo';
import { AutoSessionComplete } from 'src/components/AutoSessionComplete/AutoSessionCoplete';
import { ExternalLinksMenu } from 'src/components/ExternalLinksMenu/ExternalLinksMenu';
import { Header } from 'src/components/Header';
import { Logo } from 'src/components/Logo';
import { SplashScreen } from 'src/components/SplashScreen/SplashScreen';
import { AppMenu } from 'src/containers/AppMenu/AppMenu';
import { RoutesLayout } from 'src/containers/RoutesLayout/RoutesLayout';
import { AppStore } from 'src/stores/AppStore/AppStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import styled from 'styled-components';

const SIDE_WIDTH = 255;

export const AppLayout = observer(() => {
    if (!AppStore.started || AuthStore.isLoading) {
        return <SplashScreen />;
    }

    if (!AuthStore.authenticated) {
        return (
            <StyledLayoutContent id="layout-content">
                <RoutesLayout />
            </StyledLayoutContent>
        );
    }

    return (
        <StyledLayout hasSider>
            <AutoSessionComplete />
            <StyledContent>
                <StyledSider width={SIDE_WIDTH}>
                    <Logo />
                    <AppMenu />
                    <ExternalLinksMenu />
                    <AppInfo />
                </StyledSider>
                <StyledLayoutContent id="layout-content">
                    <Header />
                    <RoutesLayout />
                </StyledLayoutContent>
            </StyledContent>
        </StyledLayout>
    );
});

const StyledSider = styled(Layout.Sider)`
    .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        > :nth-child(2) {
            flex: 1;
        }
    }
`;

const StyledContent = styled(Layout.Content)`
    background-color: transparent;
    height: calc(100vh);
    position: relative;
    display: flex;
`;

const StyledLayoutContent = styled(Layout.Content)`
    padding: 0;
    position: relative;
    overflow-y: auto;
    padding-bottom: 20px;
`;

const StyledLayout = styled(Layout)`
    background-color: #f7f8fc;
`;
