import { notification } from 'antd';
import { ColumnType } from 'antd/es/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListSelectField } from 'src/components/Form/ListSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { IndividualListEntry, LegalCeoUpdate } from 'src/generated-api-client';
import { CEOListFilterForm } from 'src/pages/LegalEntityDetailsPage/Components/CEOListFilterForm';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const individualsStore = IndividualsStore;

export type UpdateCustomerReferenceDialogFnProps = ModalRendererProps<{
    viewModel?: LegalEntityDetailsViewModelClass;
}>;

const UpdateCEOInformationDialogFn = observer(
    ({ viewModel, ...props }: UpdateCustomerReferenceDialogFnProps) => {
        const { isLoading } = individualsStore.listLoader;

        useEffect(() => {
            return () => {
                individualsStore.listLoader.reset();
                individualsStore.filterCriteria?.resetFilter();
                individualsStore.pager?.reset();

                viewModel?.updateCEOInformationLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            viewModel?.updateCEOInformationLoader?.errorData,
        );

        const columns = getIndividualsColumns(props);

        return (
            <Modal
                isLoading={isLoading}
                title={t('Dialog.LegalEntity.UpdateCEOInformation.Title')}
                width={1200}
                onFinish={async (values) => {
                    await viewModel?.updateCEOInformation(
                        Object.fromEntries(
                            Object.entries(values).filter(([_, value]) => {
                                return value !== null;
                            }),
                        ) as unknown as LegalCeoUpdate,
                    );

                    if (viewModel?.updateCEOInformationLoader?.hasError) {
                        return Promise.reject();
                    }

                    if (!viewModel?.updateCEOInformationLoader?.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.LegalEntity.UpdateCEOInformation.Message.Success',
                            ),
                        });
                    }
                }}
                headerExtra={<CEOListFilterForm store={individualsStore} />}
                formProps={{ fields }}
                {...props}
            >
                <StyledListSelectField
                    name="ceoId"
                    store={individualsStore}
                    columns={columns}
                    rules={[ValidationRules.required]}
                />
            </Modal>
        );
    },
);

export const UpdateCEOInformationDialog = addModalRenderer(
    UpdateCEOInformationDialogFn,
);

const StyledListSelectField = styled(ListSelectField)`
    .ant-table-wrapper {
        padding: 10px 0;
    }
`;

const getIndividualsColumns = (
    props: ModalRendererProps,
): ColumnType<IndividualListEntry>[] => {
    return [
        {
            key: 'id',
            dataIndex: 'id',
            title: (
                <Trans i18nKey="Dialog.LegalEntity.UpdateCEOInformation.List.Id" />
            ),
            render: (individualId: string) => {
                return (
                    <Link
                        data-test="individual-details-link"
                        to={EntityLinks.individuals.itemDetails(individualId)}
                        onClick={() => {
                            Router.navigate(
                                EntityLinks.individuals.itemDetails(
                                    individualId,
                                ),
                            );

                            props.onCancel();
                        }}
                    >
                        {individualId}
                    </Link>
                );
            },
        },
        {
            key: 'customerName',
            dataIndex: 'customerName',
            title: (
                <Trans i18nKey="Dialog.LegalEntity.UpdateCEOInformation.List.Name" />
            ),
        },
        {
            key: 'civilId',
            dataIndex: 'civilId',
            title: (
                <Trans i18nKey="Dialog.LegalEntity.UpdateCEOInformation.List.CivilId" />
            ),
        },
        {
            key: 'citizenship',
            dataIndex: 'citizenship',
            title: (
                <Trans i18nKey="Dialog.LegalEntity.UpdateCEOInformation.List.Citizenship" />
            ),
            render: (value) => {
                return (
                    <DataView
                        value={value}
                        hideLabel
                        format={(value) => (
                            <EnumView enumName="CITIZENSHIP" value={value} />
                        )}
                    />
                );
            },
        },
        {
            key: 'birthDate',
            dataIndex: 'birthDate',
            title: (
                <Trans i18nKey="Dialog.LegalEntity.UpdateCEOInformation.List.BirthDate" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel format="date" />;
            },
        },
        {
            key: 'idDocumentRegistrationNumber',
            dataIndex: 'idDocumentRegistrationNumber',
            title: (
                <Trans i18nKey="Dialog.LegalEntity.UpdateCEOInformation.List.IdDocumentRegistrationNumber" />
            ),
        },
    ];
};
