import { observer } from 'mobx-react-lite';
import { CreateCollateral200Response } from 'src/generated-api-client';
import { CollateralInsurance } from 'src/pages/CollateralDetailsPage/Components/CollateralInsurance/CollateralInsurance';
import { CollateralOwnershipInfo } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/CollateralOwnershipInfo';
import { TrustAccountCollateralGeneralInfo } from 'src/pages/CollateralDetailsPage/Tabs/TrustAccountCollateralTab/TrustAccountCollateralGeneralInfo';

type CollateralGeneralInfoTabProps = {
    collateral?: CreateCollateral200Response;
};
export const TrustAccountCollateralGeneralInfoTab = observer(
    ({ collateral }: CollateralGeneralInfoTabProps) => {
        return (
            <>
                <TrustAccountCollateralGeneralInfo />
                <CollateralOwnershipInfo collateral={collateral} />
                <CollateralInsurance />
            </>
        );
    },
);
