import { t } from 'i18next';
import { ActionType } from 'src/generated-api-client';
import { ChangeIndividualRelationshipManagerDialog } from 'src/pages/IndividualDetailsPage/Dialogs/OtherInformation/ChangeIndividualRelationshipManagerDialog';
import { UpdateOriginationDialog } from 'src/pages/IndividualDetailsPage/Dialogs/OtherInformation/UpdateOriginationDialog';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getIndividualOriginationActions = (
    viewModel: IndividualDetailsViewModelClass,
): ActionDefinition[] => {
    return [...getUIActions(viewModel), ...getServerActions(viewModel)];
};

const getUIActions = (
    _viewModel?: IndividualDetailsViewModelClass,
): ActionDefinition[] => {
    return [];
};

const getServerActions = (viewModel?: IndividualDetailsViewModelClass) => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(viewModel),
        viewModel?.otherInformation?.actions,
    );
};

const getRegisteredServerActions = (
    viewModel?: IndividualDetailsViewModelClass,
) => {
    return [
        {
            key: ActionType.UPDATE_OTHER_INFORMATION,
            label: t('Individual.OtherInformation.Origination.Actions.Update'),
            onClick: () => {
                UpdateOriginationDialog.render({
                    viewModel,
                });
            },
        },
        {
            key: ActionType.UPDATE_RELATIONSHIP_MANAGER,
            label: t(
                'Individual.OtherInformation.Origination.Actions.ChangeRM',
            ),
            onClick: () => {
                ChangeIndividualRelationshipManagerDialog.render({
                    individualId: viewModel?.item?.id,
                });
            },
        },
    ];
};
