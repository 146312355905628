import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ActionType } from 'src/generated-api-client';
import { IncomeCard } from 'src/pages/IndividualDetailsPage/Components/IncomeCards/IncomeCard';
import { LiveStockIncomeDetailsDialog } from 'src/pages/IndividualDetailsPage/Dialogs/LiveStockIncomeDetailsDialog';
import { LiveStockIncomeQrCodeDialog } from 'src/pages/IndividualDetailsPage/Dialogs/LiveStockIncomeQrCodeDialog';
import {
    IndividualIncomeTypesViewModel,
    IndividualIncomeTypesViewModelClass,
} from 'src/pages/IndividualDetailsPage/Tabs/IndividualIncomeTypesViewModel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export type LiveStockIncomeCardProps = {
    individualId?: number;
};

export const LiveStockIncomeCard = observer(
    ({ individualId }: LiveStockIncomeCardProps) => {
        const viewModel = IndividualIncomeTypesViewModel.getInstance(
            individualId as number,
        );
        const actions = getLiveStockIncomeActiveActions(viewModel);

        return (
            <IncomeCard
                title={t(
                    'Page.IndividualDetails.Tabs.IncomeTypes.LiveStock.Title',
                )}
                incomeLoader={viewModel.incomeTypesBriefLoader}
                briefData={viewModel.livestockIncomeBrief}
                menuActions={actions}
            />
        );
    },
);

const getLiveStockIncomeActiveActions = (
    viewModel?: IndividualIncomeTypesViewModelClass,
) => {
    return getLiveStockIncomeActions(viewModel).filter(
        (action) => !action.disabled,
    );
};

const getLiveStockIncomeActions = (
    viewModel?: IndividualIncomeTypesViewModelClass,
) => {
    return [...getUIActions(viewModel), ...getServerActions(viewModel)];
};

const getUIActions = (
    viewModel?: IndividualIncomeTypesViewModelClass,
): ActionDefinition[] => {
    return [
        {
            key: 'details',
            label: t('IncomeTypes.LiveStock.Actions.Details', 'Details'),
            onClick: () => {
                LiveStockIncomeDetailsDialog.render({
                    individualId: viewModel?.individualId,
                });
            },
            disabled: !(
                Boolean(viewModel?.livestockIncomeBrief) &&
                AppUser.hasPermissions(
                    UserPermission.IndividualReadLivestockIncome,
                )
            ),
        },
    ];
};

const getServerActions = (
    viewModel?: IndividualIncomeTypesViewModelClass,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(viewModel),
        viewModel?.livestockIncomeActions,
    );
};

const getRegisteredServerActions = (
    viewModel?: IndividualIncomeTypesViewModelClass,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.GET_EMONGOLIA_QR,
            label: t('IncomeTypes.LiveStock.Actions.QrCode', 'QR Code'),
            onClick: () => {
                LiveStockIncomeQrCodeDialog.render({
                    individualId: viewModel?.individualId,
                });
            },
        },
    ];
};
