import { action } from 'mobx';
import { individualsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export class IndividualDetailsScoringStoreClass {
    constructor(private individualId: number) {}

    creditScoringResultLoader = new AsyncOperationWithStatus(() => {
        return RequestHelper.unwrapFromAxiosPromise(
            individualsApi.getCreditScoringResult({
                id: this.individualId,
            }),
        );
    });

    @action async loadCreditScoringResult() {
        await this.creditScoringResultLoader.call();
    }
}

export const IndividualDetailsScoringStore = new EntityProvider(
    IndividualDetailsScoringStoreClass,
);
