import { RoleOption } from 'src/generated-api-client';
import { rolesApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class RoleStoreClass extends BasicStore<RoleOption> {
    api: BasicStoreApi<RoleOption> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(rolesApi.roleOptions());
        },
    };
}

export const RoleStore = new RoleStoreClass();

export const convertRoleListItemToOption = (role: RoleOption) => ({
    label: role.name,
    value: String(role.id),
    'data-test': role.name,
});
