import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { RepaymentFrequency } from 'src/generated-api-client';
import { ReviewActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ReviewActionDialogComponent';
import { ReviewPaymentDateChangingActionStore } from 'src/stores/LoanActionsStores/ReviewPaymentDateChangingActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId: number;
    repaymentFrequency?: RepaymentFrequency;
}>;

export const ReviewPaymentDateChangingDialogFn = observer(
    ({ loanId, repaymentFrequency, ...props }: RestructuringsDialogFnProps) => {
        const store = ReviewPaymentDateChangingActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ReviewPaymentDateChanging.Title');

        return (
            <ReviewActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={store.initialValues?.firstPaymentDay}
                            label={
                                repaymentFrequency ===
                                RepaymentFrequency.MONTHLY
                                    ? t(
                                          'Dialog.ReviewPaymentDateChanging.FirstPaymentDayMonthly',
                                      )
                                    : t(
                                          'Dialog.ReviewPaymentDateChanging.FirstPaymentDay',
                                      )
                            }
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={store.initialValues?.secondPaymentDay}
                            label={t(
                                'Dialog.ReviewPaymentDateChanging.SecondPaymentDay',
                            )}
                        />
                    </Col>
                </Row>
            </ReviewActionDialogComponent>
        );
    },
);

export const ReviewPaymentDateChangingDialog = addModalRenderer(
    ReviewPaymentDateChangingDialogFn,
);
