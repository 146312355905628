import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { FilterForm } from 'src/components/Form/FilterForm';
import { AddCustomer } from 'src/pages/RestrictionsListPage/Dialogs/AddCustomerDialog';
import { ImportCsv } from 'src/pages/RestrictionsListPage/Dialogs/ImportCsvDialog';
import { RestrictionsListFilterForm } from 'src/pages/RestrictionsListPage/RestrictionsListFilterForm';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { RestrictionsStore } from 'src/stores/RestrictionsStore/RestrictionsStore';

type RestrictionsListHeaderPanelProps = {
    title: React.ReactNode;
};

const store = RestrictionsStore;

const getRestrictionActions = () => {
    return [
        {
            key: 'add',
            label: t('Page.RestrictionsList.Dropdown.AddCustomer'),
            onClick: () => {
                AddCustomer.render();
            },
            disabled: !AppUser.hasPermissions(
                UserPermission.RestrictionListWrite,
            ),
        },
        {
            key: 'upload',
            label: t('Page.RestrictionsList.Dropdown.UploadFile'),
            onClick: () => {
                ImportCsv.render();
            },
        },
    ];
};

export const RestrictionsListHeaderPanel = observer(
    ({ title }: RestrictionsListHeaderPanelProps) => {
        const restrictionActions = getRestrictionActions().filter(
            (action) => !action.disabled,
        );

        return (
            <div>
                <FilterForm
                    store={store}
                    title={title}
                    panelExtra={<Dropdown items={restrictionActions} />}
                >
                    <RestrictionsListFilterForm />
                </FilterForm>
            </div>
        );
    },
);
