import { Col, Row, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { Form } from 'src/components/Form/Form';
import { Spinner } from 'src/components/Spinner/Spinner';
import { LoanStateStore } from 'src/stores/LoanStateStore/LoanStateStore';
import styled from 'styled-components';

export type LoanStateCalculatorProps = { loanId?: number };

export const LoanStateCalculator = observer(
    ({ loanId }: LoanStateCalculatorProps) => {
        const store = LoanStateStore.getInstance(loanId as number);
        const transformInitialValues = () => {
            const initialValues = store.loanState || {};

            return {
                ...initialValues,
                date: moment(store.loanState?.date),
            };
        };

        useEffect(() => {
            store.calculateLoanState({ date: moment().toISOString() });

            return () => {
                store.loanStateLoader.reset();
            };
        }, [loanId]);

        if (store.loanStateLoader.isLoading) {
            return (
                <Card>
                    <StyledWrapper>
                        <Spinner />
                    </StyledWrapper>
                </Card>
            );
        }

        return (
            <StyledCard>
                <StyledSpace>
                    <Form initialValues={transformInitialValues()}>
                        <StyledDatePickerField
                            name="date"
                            disabledDate={(date) => {
                                return !date.isSame(moment());
                            }}
                        />
                    </Form>

                    <StyledDataView
                        value={store.loanState?.closingAmount}
                        label={t('Component.LoanStateCalculator.ClosingAmount')}
                        format="money"
                        align="horizontal"
                    />
                </StyledSpace>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={store.loanState?.interestAccrualDays}
                            label={t(
                                'Component.LoanStateCalculator.InterestAccrualDays',
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={store.loanState?.accruedInterest}
                            label={t(
                                'Component.LoanStateCalculator.AccruedInterest',
                            )}
                            format="money"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={store.loanState?.penaltyAccrualDays}
                            label={t(
                                'Component.LoanStateCalculator.PenaltyAccrualDays',
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={store.loanState?.penalty}
                            label={t('Component.LoanStateCalculator.Penalty')}
                            format="money"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            value={store.loanState?.principal}
                            label={t(
                                'Component.LoanStateCalculator.PrincipalDebt',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            value={store.loanState?.totalAmount}
                            label={t(
                                'Component.LoanStateCalculator.TotalAmount',
                            )}
                            format="money"
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    },
);

const StyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const StyledSpace = styled(Space)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
`;

const StyledCard = styled(Card)`
    .ant-card-body {
        padding: 24px 30px 14px;
    }
`;

const StyledDatePickerField = styled(DatePickerField)`
    margin-bottom: 0;
`;

const StyledDataView = styled(DataView)`
  margin-bottom: 0;
  
    .app-data-view__label {
        font-weight: 600;
        font-size: 18px !important;
        line-height: 24px; !important;
        color: #4D6082;
    }

    .app-data-view__value {
        font-weight: 600;
        font-size: 18px !important;
        line-height: 24px !important;
      
      background: linear-gradient(
              90deg,
                #0d73bd -25.12%,
                #0562a5 -25.09%,
              #17c48d 215.89%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
`;
