import { Button, Col, Row, Tooltip } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { CurrentWorkingTaskStore } from 'src/stores/CurrentWorkingTaskStore/CurrentWorkingTaskStore';
import styled from 'styled-components';

export const CurrentWorkingTaskMiniWindow = observer(() => {
    const { workingTaskMetadata } = CurrentWorkingTaskStore;
    const hasCurrentWorkingTask = Boolean(workingTaskMetadata);

    if (!hasCurrentWorkingTask) {
        return null;
    }

    const title = (
        <>
            {t('Components.CurrentWorkingTaskMiniWindow.CurrentTaskTitle')}
            <strong>
                {t(
                    `Task.Name.${CurrentWorkingTaskStore.workingTaskMetadata?.name}`,
                )}
            </strong>
        </>
    );

    return (
        <StyledCurrentWorkingTaskMiniWindowWrapper>
            <Row gutter={15} align="middle" wrap={false}>
                <Col flex={1}>
                    <Tooltip title={title}>
                        <StyledName>
                            <Circle />
                            {title}
                        </StyledName>
                    </Tooltip>
                </Col>
                <Col>
                    <StyledBtn
                        type="link"
                        onClick={() => {
                            Router.navigate(
                                EntityLinks.tasks.itemDetails(
                                    workingTaskMetadata?.id,
                                ),
                            );
                        }}
                    >
                        {t(
                            'Components.CurrentWorkingTaskMiniWindow.GoToCurrentTask',
                        )}
                    </StyledBtn>
                </Col>
            </Row>
        </StyledCurrentWorkingTaskMiniWindowWrapper>
    );
});

const StyledName = styled.div`
    position: relative;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const StyledCurrentWorkingTaskMiniWindowWrapper = styled.div`
    --max-width-current-task-wrapper: 600px;
    position: relative;
    width: var(--max-width-current-task-wrapper);
    margin: 0 auto;
    padding: 10px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 10px #3637410d;
    line-height: 1rem;
`;

const Circle = styled.div`
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background: #fc842d;
    margin-right: 10px;
`;

const StyledBtn = styled(Button)`
    height: 25px;
`;
