import { LegalLoanListEntry } from 'src/generated-api-client';
import { legalsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LegalEntityLoanInformationStoreClass extends BasicStore<
    LegalLoanListEntry,
    any,
    any,
    any,
    any
> {
    api: BasicStoreApi<LegalLoanListEntry> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                legalsApi.findLegalLoans({
                    id: this.legalId,
                    ...this.listParams,
                }),
            );

            return result || [];
        },
    };

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<LegalLoanListEntry>(
        'id',
        'descend',
    );

    constructor(private legalId: number) {
        super();
    }
}

export const LegalEntityLoanInformationStore = new EntityProvider(
    LegalEntityLoanInformationStoreClass,
);
