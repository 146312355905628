import { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { CustomerDecision } from 'src/generated-api-client';
import { useIndividualId } from 'src/hooks/useIndividualId';
import { IndividualDecisionsStore } from 'src/stores/IndividualDecisionsStore/IndividualDecisionsStore';

export const IndividualDecisionsTab = observer(() => {
    const { individualId } = useIndividualId();
    const store = IndividualDecisionsStore.getInstance(Number(individualId));

    useEffect(() => {
        store.loadList();

        return () => {
            store.listLoader.reset();
        };
    }, [individualId]);

    return (
        <ListView
            store={store}
            columns={columns}
            loading={store.listLoader.isLoading}
        />
    );
});

export const columns: ColumnsType<CustomerDecision> = [
    {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: <Trans i18nKey="Page.IndividualDetails.Decisions.CreatedAt" />,
        render(value) {
            return (
                <DataView value={value} format="date-time-seconds" hideLabel />
            );
        },
    },
    {
        key: 'createdByService',
        dataIndex: 'createdByService',
        title: (
            <Trans i18nKey="Page.IndividualDetails.Decisions.СreatedByService" />
        ),
        render(value) {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView enumName="CHECK_SERVICE_NAME" value={value} />
                    )}
                />
            );
        },
    },
    {
        key: 'createdByCheck',
        dataIndex: 'createdByCheck',
        title: (
            <Trans i18nKey="Page.IndividualDetails.Decisions.CreatedByCheck" />
        ),
        render(value) {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView enumName="CHECK_STEP_NAME" value={value} />
                    )}
                />
            );
        },
    },
    {
        key: 'decisionType',
        dataIndex: 'decisionType',
        title: (
            <Trans i18nKey="Page.IndividualDetails.Decisions.DecisionType" />
        ),
        render(value) {
            return (
                <EnumView
                    enumName="VERIFICATION_CHECK_DECISION_TYPE"
                    value={value}
                />
            );
        },
    },
    {
        key: 'decision',
        dataIndex: 'decision',
        title: <Trans i18nKey="Page.IndividualDetails.Decisions.Decision" />,
        render(value) {
            return (
                <EnumView
                    enumName="VERIFICATION_CHECK_DECISION"
                    value={value}
                />
            );
        },
    },
    {
        key: 'reason',
        dataIndex: 'reason',
        title: <Trans i18nKey="Page.IndividualDetails.Decisions.Reason" />,
    },
];
