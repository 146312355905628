/* eslint-disable max-lines, import/no-cycle */
import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { N13Task } from 'src/generated-api-client';
import { ConditionCardProps } from 'src/pages/TaskViewPage/TaskTypes';

export const AdditionalInfoCard = observer(
    ({ task }: ConditionCardProps<N13Task>) => {
        return (
            <Card
                title={t(
                    'Task.Form.N13AddEstimatedConditionsTask.AdditionalInfo.Title',
                    'Additional Info',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            label={t(
                                'Task.Form.N13AddEstimatedConditionsTask.AdditionalInfo.FirstPaymentAmount',
                                'Estimated First Payment Amount',
                            )}
                            value={task?.estimatedFirstRepaymentAmount}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            label={t(
                                'Task.Form.N13AddEstimatedConditionsTask.AdditionalInfo.EstimatedDTI',
                                'Estimated DTI',
                            )}
                            value={task?.estimatedIndividualDti}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            label={t(
                                'Task.Form.N13AddEstimatedConditionsTask.AdditionalInfo.EstimatedDtiCoBorrower',
                                'Estimated DTI With Co-Borrower',
                            )}
                            value={task?.estimatedCoBorrowersDti}
                            format="percent"
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
