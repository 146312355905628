import { Form, notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { ChildBranchOptionsStore } from 'src/stores/BranchOptionsStore/ChildBranchOptionsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const store = ApplicationsStore;

type ChangeLoanApplicationRelationshipManagerDialogProps = ModalRendererProps<{
    applicationId?: number;
}>;

export const ChangeLoanApplicationRelationshipManagerDialogFn = observer(
    ({
        applicationId,
        ...props
    }: ChangeLoanApplicationRelationshipManagerDialogProps) => {
        const [form] = Form.useForm();
        const branchId = Form.useWatch('branchId', form);

        useEffect(() => {
            ChildBranchOptionsStore.loadList();

            return () => {
                store.updateItemLoader.reset();
                ChildBranchOptionsStore.listLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.updateItemLoader.errorData,
        );

        const loadingInfo = useMemo(() => {
            return new CombinedLoadingStatus([
                store.updateItemLoader,
                ChildBranchOptionsStore.listLoader,
            ]);
        }, []);

        const relationshipManagerOptions =
            ChildBranchOptionsStore.getRelationshipManagerOptions(branchId);

        useEffect(() => {
            form.setFields([
                {
                    name: 'branchManager',
                    value: ChildBranchOptionsStore.getBranchManager(branchId),
                },
                {
                    name: 'relationshipManagerId',
                    value: undefined,
                },
            ]);
        }, [branchId]);

        return (
            <Modal
                form={form}
                title={t(
                    'Dialog.ChangeLoanApplicationRelationshipManager.Title',
                )}
                isLoading={loadingInfo.isLoading}
                onFinish={async (values) => {
                    await store.update(Number(applicationId), values);

                    if (store.updateItemLoader.hasError) {
                        return Promise.reject();
                    }

                    if (!store.updateItemLoader.hasError) {
                        notification.success({
                            message: t(
                                'Dialog.ChangeLoanApplicationRelationshipManager.Message.Success',
                            ),
                        });
                        store.loadItem(applicationId);
                    }
                }}
                formProps={{ fields }}
                {...props}
            >
                <TextField
                    name="type"
                    initialValue="LoanApplicationChangeRelationshipManager"
                    hidden
                />
                <SelectField
                    name="branchId"
                    label={t(
                        'Dialog.ChangeLoanApplicationRelationshipManager.Branch',
                    )}
                    options={ChildBranchOptionsStore.branchOptions}
                    rules={[ValidationRules.required]}
                    showSearch
                />
                <TextField
                    name="branchManager"
                    label={t(
                        'Dialog.ChangeLoanApplicationRelationshipManager.BranchManager',
                    )}
                    disabled
                />
                <SelectField
                    name="relationshipManagerId"
                    label={t(
                        'Dialog.ChangeLoanApplicationRelationshipManager.RelationshipManager',
                    )}
                    options={relationshipManagerOptions}
                    rules={[ValidationRules.required]}
                    showSearch
                />
            </Modal>
        );
    },
);

export const ChangeLoanApplicationRelationshipManagerDialog = addModalRenderer(
    ChangeLoanApplicationRelationshipManagerDialogFn,
);
