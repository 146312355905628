import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { TrustProduct } from 'src/generated-api-client';

export type TrustPledgedProductDetailsTab = {
    productTrustPledged?: TrustProduct;
};
export const TrustPledgedProductDetailsTab = observer(
    ({ productTrustPledged }: TrustPledgedProductDetailsTab) => {
        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.TrustPledgedProductDetails.Details.Title',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={productTrustPledged?.productLineId}
                                    label={t(
                                        'Page.TrustPledgedProductDetails.ExternalId',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productTrustPledged?.name}
                                    label={t(
                                        'Page.TrustPledgedProductDetails.Name',
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productTrustPledged?.status}
                                    label={t(
                                        'Page.TrustPledgedProductDetails.Status',
                                    )}
                                    format={(value) => (
                                        <EnumView
                                            enumName="PRODUCT_STATUS"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={productTrustPledged?.interestRate}
                                    label={t(
                                        'Page.TrustPledgedProductDetails.InterestRate',
                                    )}
                                    format="percent"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <DataView
                                    value={productTrustPledged?.minLoanAmount}
                                    label={t(
                                        'Page.TrustPledgedProductDetails.MinLoanAmount',
                                    )}
                                    format="money"
                                />
                            </Col>
                            <Col span={6}>
                                <DataView
                                    value={
                                        productTrustPledged?.maxLoanAmountPct
                                    }
                                    label={t(
                                        'Page.TrustPledgedProductDetails.MaxLoanAmountPct',
                                    )}
                                    format="percent"
                                />
                            </Col>
                            <Col span={6}>
                                <DataView
                                    value={
                                        productTrustPledged?.excludedFromOffer
                                    }
                                    label={t(
                                        'Page.TrustPledgedProductDetails.ExcludedFromOffer',
                                    )}
                                    format="yes-no"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    },
);
