import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { PhoneField } from 'src/components/Form/PhoneField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { NewCollateralCoOwner } from 'src/generated-api-client';
import { CollateralCoOwnerStore } from 'src/stores/CollateralCoOwnerStore/CollateralCoOwnerStore';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type CreateCollateralOwnershipInfoDialogFnProps = ModalRendererProps<{
    collateralId: number;
    prefillData?: NewCollateralCoOwner;
}>;

const CreateCollateralOwnershipInfoDialogFn = observer(
    ({
        prefillData,
        collateralId,
        ...props
    }: CreateCollateralOwnershipInfoDialogFnProps) => {
        const store = CollateralCoOwnerStore.getInstance(collateralId);

        useEffect(() => {
            return () => {
                store.createItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.createItemLoader.errorData,
        );

        return (
            <Modal
                isLoading={store.createItemLoader.isLoading}
                title={t('Dialog.CreateCollateralOwnershipInfo.Title')}
                onFinish={async (values) => {
                    await store.create(values);
                    if (store.createItemLoader.hasError) {
                        return Promise.reject();
                    }
                    if (!store.createItemLoader.hasError) {
                        CollateralsStore.loadItem(collateralId);
                    }
                }}
                width={1000}
                formProps={{ fields }}
                initialValues={prefillData}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={8}>
                        <TextField
                            name="civilId"
                            label={t(
                                'Dialog.CreateCollateralOwnershipInfo.CivilId',
                                'Civil ID',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="firstName"
                            label={t(
                                'Dialog.CreateCollateralOwnershipInfo.FirstName',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="lastName"
                            label={t(
                                'Dialog.CreateCollateralOwnershipInfo.LastName',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="registrationNumber"
                            label={t(
                                'Dialog.CreateCollateralOwnershipInfo.RegistrationNumber',
                                'Registration number',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <PhoneField
                            name="phoneNumber"
                            label={t(
                                'Dialog.CreateCollateralOwnershipInfo.PhoneNumber',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="fullAddress"
                            label={t(
                                'Dialog.CreateCollateralOwnershipInfo.FullAddress',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <EnumSelectField
                            name="relationship"
                            enumName="REFERENCE_RELATIONSHIP"
                            label={t(
                                'Dialog.CreateCollateralOwnershipInfo.Relationship',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <EnumSelectField
                            name="ownerType"
                            enumName="OWNER_TYPE"
                            label={t(
                                'Dialog.CreateCollateralOwnershipInfo.CoOwnerType',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const CreateCollateralOwnershipInfoDialog = addModalRenderer(
    CreateCollateralOwnershipInfoDialogFn,
);
