import { observer } from 'mobx-react-lite';
import React from 'react';
// eslint-disable-next-line import/no-cycle
import { UnknownComponent } from 'src/components/ComponentProvider/UnknownComponent';

export type TypedPageDefinition = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Component: (...props: any[]) => React.ReactElement;
};

export type ComponentsMap = {
    [key: string]: TypedPageDefinition;
};

export type ComponentProviderProps = {
    componentsMap: ComponentsMap;
    currentComponentKey: string;
    currentComponentProps?: any;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    FallbackComponent?: (...props: any[]) => React.ReactElement;
};

export const ComponentProvider = observer(
    ({
        componentsMap,
        currentComponentKey,
        currentComponentProps,
        FallbackComponent,
    }: ComponentProviderProps) => {
        const CurrentComponent = componentsMap[currentComponentKey]?.Component;

        if (!currentComponentKey) {
            console.error(`'currentComponentKey' is not provided`);

            return null;
        }

        if (CurrentComponent) {
            return <CurrentComponent {...currentComponentProps} />;
        }

        if (FallbackComponent) {
            return <FallbackComponent />;
        }

        return <UnknownComponent componentKey={currentComponentKey} />;
    },
);
