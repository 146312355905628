import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { CommentView } from 'src/components/Comments/CommentView';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { Comment } from 'src/generated-api-client';
import { CommentsStore } from 'src/stores/CommentsStore/CommentsStore';
import styled from 'styled-components';

export type CommentsListProps = {
    roomId: number;
};
export const CommentsList = observer(({ roomId }: CommentsListProps) => {
    const store = CommentsStore.getInstance(roomId);

    useEffect(() => {
        store.loadList();

        return () => {
            store.listLoader.reset();
        };
    }, []);

    return (
        <StyledWrapper>
            <StyledDataView
                value={store?.totalComments}
                label={t('Component.Comments.Total')}
                format="string"
                align="horizontal"
            />

            <StyledListView
                store={store}
                columns={commentColumns}
                loading={store.listLoader.isLoading}
                showHeader={false}
            />
        </StyledWrapper>
    );
});

const commentColumns: ColumnsType<Comment> = [
    {
        key: 'comment',
        render: (_, comment) => {
            return <CommentView comment={comment} />;
        },
    },
];

const StyledListView = styled(ListView)`
    .ant-table-cell {
        border-bottom: none;
        &:before {
            content: '';
            position: absolute;
            top: 100%;
            right: 0;
            border-bottom: 1px solid #dfe0eb;
            width: calc(100% - 75px);
        }
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        border-radius: 8px;
    }
`;

const StyledDataView = styled(DataView)`
    .app-data-view__label,
    .app-data-view__value {
        font-weight: 700;
        line-height: 24px !important;
        color: #2e3e5c;
    }
`;

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;
