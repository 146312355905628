import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { NewCivilServant } from 'src/generated-api-client';
import { EditCivilServantDialog } from 'src/pages/CollectionDetailsPage/RecordActivityPage/Dialogs/EditCivilServantDialog';
import { RemoveCivilServantDialog } from 'src/pages/CollectionDetailsPage/RecordActivityPage/Dialogs/RemoveCivilServantDialog';
import { ActionDefinition } from 'src/utils/UserActionsHelper';

export const getRecordActivityCivilServantsListActions = (
    civilServant?: NewCivilServant,
    civilServantIndex?: number,
    onSave?: Dispatch<SetStateAction<any[]>>,
) => {
    return [
        ...getUIActions(civilServant, civilServantIndex, onSave),
        ...getServerActions(),
    ];
};

const getUIActions = (
    civilServant?: NewCivilServant,
    civilServantIndex?: number,
    onSave?: Dispatch<SetStateAction<any[]>>,
): ActionDefinition[] => {
    return [
        {
            key: 'edit-collection-record-activity-civil-servant',
            label: t('RecordActivity.CivilServant.Actions.Edit'),
            onClick: () => {
                EditCivilServantDialog.render({
                    civilServant,
                    civilServantIndex,
                    onSave,
                });
            },
        },
        {
            key: 'delete-collection-record-activity-civil-servant',
            label: t('RecordActivity.CivilServant.Actions.Remove'),
            onClick: () => {
                RemoveCivilServantDialog.render({
                    civilServantIndex,
                    onSave,
                });
            },
        },
    ];
};

const getServerActions = (): ActionDefinition[] => {
    return [...getRegisteredServerActions()];
};

const getRegisteredServerActions = (): ActionDefinition[] => {
    return [];
};
