import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataView, LONG_DASH } from 'src/components/DataView/DataView';
import { ListPage } from 'src/components/ListPage/ListPage';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    IdNameEntry,
    TaskMetadata,
    TaskMetadataListEntry,
    TaskStatus,
} from 'src/generated-api-client';
import { TaskActions } from 'src/pages/TasksPage/Components/TaskActions';
import { TasksStatus } from 'src/pages/TasksPage/Components/TasksStatus';
import { TasksHeaderPanel } from 'src/pages/TasksPage/TasksHeaderPanel';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { TasksStore } from 'src/stores/TasksStore/TasksStore';
import { UrlHelper } from 'src/utils/UrlHelper';

export const TasksPage = observer(() => {
    return (
        <ListPage
            store={TasksStore}
            columns={columns}
            header={<TasksHeaderPanel />}
        />
    );
});

export const columns: ColumnsType<TaskMetadataListEntry> = [
    {
        key: 'name',
        dataIndex: 'name',
        title: <Trans i18nKey="Page.TasksPage.List.Name" />,
        render: (value, record) => {
            return getTaskNameView(value, record);
        },
    },
    {
        key: 'customerName',
        dataIndex: 'individual',
        title: <Trans i18nKey="Page.TasksPage.List.CustomerName" />,
        render: (value: IdNameEntry) => {
            return (
                <Link to={EntityLinks.individuals.itemDetails(value?.id)}>
                    {value?.name}
                </Link>
            );
        },
    },
    {
        key: 'requestedAmount',
        dataIndex: 'requestedAmount',
        title: <Trans i18nKey="Page.TasksPage.List.RequestedAmount" />,
        align: 'right',
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'product',
        dataIndex: 'product',
        title: <Trans i18nKey="Page.TasksPage.List.Product" />,
        render(value) {
            const target = UrlHelper.getTargetUrl(
                RoutePaths.productPledgedDetails,
                {
                    productId: value?.id,
                },
            );

            return <Link to={target}>{value?.name}</Link>;
        },
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Page.TasksPage.List.Status" />,
        render(value) {
            return <TasksStatus status={value} />;
        },
    },
    {
        key: 'assignee',
        dataIndex: 'assignee',
        title: <Trans i18nKey="Page.TasksPage.List.Assignee" />,
        render: (value: IdNameEntry) => {
            return (
                <Link to={EntityLinks.users.itemDetails(value?.id)}>
                    {value?.name}
                </Link>
            );
        },
    },
    {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: <Trans i18nKey="Page.TasksPage.List.CreatedAt" />,
        render: (value) => {
            return getDateTimeSecondsView(value);
        },
    },
    {
        key: 'completedAt',
        dataIndex: 'completedAt',
        title: <Trans i18nKey="Page.TasksPage.List.CompletedAt" />,
        render: (value) => {
            return getDateTimeSecondsView(value);
        },
    },
    {
        key: 'spentTime',
        dataIndex: 'spentTime',
        title: <Trans i18nKey="Page.TasksPage.List.SpentTime" />,
        render: (_, record) => {
            return getTimeDiffView(record);
        },
    },
    {
        key: 'actions',
        render: (_, record) => {
            return <TaskActions task={record} />;
        },
    },
];

const getTaskNameView = (value: string, record: TaskMetadata) => {
    const canViewDetails =
        record?.status === TaskStatus?.ACTIVE &&
        record?.assignee?.id === AppUser.userProfile?.id;

    return canViewDetails ? (
        <DataView
            value={t(`Task.Name.${value}`)}
            hideLabel
            format="link-internal"
            formatterProps={{
                to: EntityLinks.tasks.itemDetails(record.id),
            }}
        />
    ) : (
        <DataView
            value={value}
            format={(value) => t(`Task.Name.${value}`)}
            hideLabel
        />
    );
};

const getDateTimeSecondsView = (value: string) => {
    return <DataView value={value} hideLabel format="date-time-seconds" />;
};

const getTimeDiffView = (record: TaskMetadata) => {
    const timeDiff = moment.duration(
        moment(record?.completedAt)?.diff(moment(record?.createdAt)),
    );

    return record?.completedAt ? (
        <DataView value={timeDiff} hideLabel format="small-interval" />
    ) : (
        LONG_DASH
    );
};
