import { FormInstance } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Modal } from 'src/components/Modal/Modal';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { FormHelper } from 'src/utils/FormHelper';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { ModalRendererProps } from 'src/utils/ModalRenderer';

export type ProposeActionDialogComponentProps = ModalRendererProps<{
    loanId?: number;
    store?: BasicActionStore<any>;
    title?: React.ReactNode;
    form?: FormInstance;
    children?: React.ReactNode;
}>;

export const ProposeActionDialogComponent: React.FC<ProposeActionDialogComponentProps> =
    observer(
        ({
            loanId,
            store,
            title,
            form,
            children,
            ...props
        }: ProposeActionDialogComponentProps) => {
            const fields = FormHelper.getFieldDataItems(
                store?.okLoader.errorData,
            );

            useEffect(() => {
                store?.load?.();

                return () => {
                    store?.initialValuesLoader.reset();
                    store?.okLoader.reset();
                };
            }, [loanId]);

            return (
                <Modal
                    title={title}
                    okText={t('Dialog.Restructuring.ConfirmButton')}
                    isLoading={store?.isLoading}
                    showOnlyLoading={
                        store?.initialValuesLoader.countLoads === 0
                    }
                    closable={false}
                    maskClosable={false}
                    onFinish={async (values) => {
                        await store?.ok(values);

                        if (store?.okLoader.hasError) {
                            throw new Error('Creation Error');
                        } else {
                            LoansStore.loadItem(loanId);
                        }
                    }}
                    initialValues={store?.initialValues}
                    formProps={{ fields }}
                    form={form}
                    {...props}
                >
                    {children}
                </Modal>
            );
        },
    );
