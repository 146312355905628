import { notification } from 'antd';
import { t } from 'i18next';
import { action, computed } from 'mobx';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';

export type RecoveryForm = {
    code: string;
};

export class RecoveryPasswordStoreClass {
    public verifyCodeLoader = new AsyncOperationWithStatus((_code: string) => {
        console.warn('Methode "verifyCode" not implemented');

        return Promise.reject();
    });

    public recoveryPasswordLoader = new AsyncOperationWithStatus(
        ({ code, ..._values }: RecoveryForm) => {
            console.warn('Methode "recoveryPassword" not implemented');

            return Promise.reject();
        },
    );

    public requestRecoveryPasswordLoader = new AsyncOperationWithStatus(
        (_values) => {
            console.warn('Methode "requestToRecovery" not implemented');

            return Promise.reject();
        },
    );

    @action async verifyCode(code: string) {
        await this.verifyCodeLoader.call(code);
    }

    @computed get isValidCode() {
        return !this.verifyCodeLoader.hasError && this.isCodeValidated;
    }

    @computed get isCodeValidated() {
        return this.verifyCodeLoader.countLoads > 0;
    }

    @computed get isCodeValidating() {
        return this.verifyCodeLoader.isLoading;
    }

    @action async recoveryPassword(values: RecoveryForm) {
        await this.recoveryPasswordLoader.call(values);
        if (!this.recoveryPasswordLoader.hasError) {
            notification.success({
                message: t('Page.RecoveryPassword.Messages.Success'),
            });
        }
        Router.navigate(RoutePaths.authLogin);
    }

    @computed get isPasswordSetting() {
        return this.recoveryPasswordLoader.isLoading;
    }

    @computed get isRecoveryRequestLoading() {
        return this.requestRecoveryPasswordLoader.isLoading;
    }

    @action async requestRecoveryPassword(values: any) {
        await this.requestRecoveryPasswordLoader.reset();
        await this.requestRecoveryPasswordLoader.call(values);
    }

    @computed get isRequestToRecoverySent() {
        return (
            !this.requestRecoveryPasswordLoader.hasError &&
            this.requestRecoveryPasswordLoader.countLoads > 0 &&
            !this.requestRecoveryPasswordLoader.isLoading
        );
    }

    @computed get isAccessDenied() {
        return (
            this.requestRecoveryPasswordLoader.errorData?.detail?.toLowerCase() ===
            'access is forbidden - contact your system administrator'
        );
    }

    @action reset() {
        this.verifyCodeLoader.reset();
        this.recoveryPasswordLoader.reset();
        this.requestRecoveryPasswordLoader.reset();
    }

    @computed get isNewPasswordSet() {
        return (
            this.recoveryPasswordLoader.countLoads > 0 &&
            !this.recoveryPasswordLoader.hasError
        );
    }
}

export const RecoveryPasswordStore = new RecoveryPasswordStoreClass();
