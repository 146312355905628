import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataView } from 'src/components/DataView/DataView';
import { ListPage } from 'src/components/ListPage/ListPage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { TrustProductListEntry } from 'src/generated-api-client';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { ProductsStatus } from 'src/pages/LoanProducts/ProductsStatus';
import { TrustPledgedProductsHeaderPanel } from 'src/pages/LoanProducts/TrustPledgedProductsPage/TrustPledgedProductsHeaderPanel';
import { EnumName } from 'src/stores/Resources/EnumStore/EnumStore';
import { TrustPledgedProductsStore } from 'src/stores/TrustPledgedProductsStore/TrustPledgedProductsStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = TrustPledgedProductsStore;
const enumsToLoad: EnumName[] = ['PRODUCT_STATUS'];

export const TrustPledgedProductsPage = observer(() => {
    const isLoading = useEnumsLoader(enumsToLoad);

    return (
        <ListPage
            store={store}
            columns={columns}
            isLoading={isLoading}
            header={
                <TrustPledgedProductsHeaderPanel
                    title={t('Page.TrustPledgedProducts.Header')}
                />
            }
        />
    );
});

export const columns: ColumnsType<TrustProductListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.TrustPledgedProducts.List.Id" />,
        render(_, record) {
            const target = UrlHelper.getTargetUrl(
                RoutePaths.productTrustPledgedDetails,
                { productId: record?.id },
            );

            return <Link to={target}>{record?.externalId}</Link>;
        },
        sorter: true,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: <Trans i18nKey="Page.TrustPledgedProducts.List.Name" />,
        sorter: true,
    },
    {
        key: 'interestRate',
        dataIndex: 'interestRate',
        title: <Trans i18nKey="Page.TrustPledgedProducts.List.InterestRate" />,
        render: (value) => {
            return <DataView value={value} format="percent" hideLabel />;
        },
    },
    {
        key: 'version',
        dataIndex: 'version',
        title: <Trans i18nKey="Page.TrustPledgedProducts.List.Version" />,
        sorter: true,
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Page.TrustPledgedProducts.List.Status" />,
        render(value) {
            return <ProductsStatus status={value} />;
        },
        sorter: true,
    },
];
