/* eslint-disable init-declarations */
import {
    CollectionActivityGeneralType,
    CollectionActivityGeneralTypeDictionaryItem,
    CollectionActivitySubtypeDictionaryItem,
    CollectionActivityType,
    CollectionActivityTypeDictionaryItem,
    Localization,
} from 'src/generated-api-client';
import { dictionaryApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import {
    BasicStoreApi,
    Entity,
} from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class CollectionActivityGeneralOptionStoreClass extends BasicStore<any> {
    api: BasicStoreApi<CollectionActivityGeneralTypeDictionaryItem> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                dictionaryApi.getCollectionActivityTypes(),
            );

            return result || [];
        },
    };

    getGeneralTypes() {
        return this.list;
    }

    getTypes(generalTypeKey: keyof CollectionActivityGeneralType) {
        const types = this.list.find((item) => item.key === generalTypeKey)
            ?.collectionActivityTypes as unknown as Array<CollectionActivityTypeDictionaryItem>;

        if (types) {
            return convertToTypeDictionaryUnitList(
                types,
                'collectionActivityTypes',
            );
        }
    }

    getSubTypes(
        generalTypeKey: keyof CollectionActivityGeneralType,
        typeKey: keyof CollectionActivityType,
    ) {
        const subTypes = this.getTypes(generalTypeKey)?.find(
            (item) => item.key === typeKey,
        )
            ?.collectionActivitySubtypes as Array<CollectionActivitySubtypeDictionaryItem>;

        if (subTypes) {
            return convertToTypeDictionaryUnitList(subTypes);
        }
    }
}

export const CollectionActivityGeneralOptionStore =
    new CollectionActivityGeneralOptionStoreClass();

export type TypeDictionaryUnit = {
    key?: string;
    l10n?: Localization;
    collectionActivityTypes?: Array<CollectionActivityTypeDictionaryItem>;
    collectionActivitySubtypes?: Array<CollectionActivitySubtypeDictionaryItem>;
};

export type TypeDictionaryUnitWithId = Entity & {
    id: string;
} & TypeDictionaryUnit;

export function convertToTypeDictionaryUnitList<T extends TypeDictionaryUnit>(
    items: Array<T>,
    childrenKey?: 'collectionActivityTypes' | 'collectionActivitySubtypes',
): Array<TypeDictionaryUnitWithId> {
    return items.map((item) => {
        // eslint-disable-next-line sonarjs/no-identical-expressions
        const code = item.key;

        return {
            ...item,
            id: code,
            code,
            children: childrenKey ? item[childrenKey] : undefined,
        } as TypeDictionaryUnitWithId;
    }) as Array<TypeDictionaryUnitWithId>;
}
