import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { CoBorrowerStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: CoBorrowerStatus;
};

export const CoBorrowersStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: CoBorrowerStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: t(`CoBorrower.Status.${status}`),
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [CoBorrowerStatus.APPROVED]: {
        title: <Trans i18nKey="CoBorrower.Status.APPROVED" />,
        color: '#0C1604',
        backgroundColor: '#D3F3BB',
    },
    [CoBorrowerStatus.DECLINED]: {
        title: <Trans i18nKey="CoBorrower.Status.DECLINED" />,
        color: '#F54753',
        backgroundColor: '#FFE4E4',
    },
    [CoBorrowerStatus.DRAFT]: {
        title: <Trans i18nKey="CoBorrower.Status.DRAFT" />,
        color: '#8B939B',
        backgroundColor: '#F5F6F7',
    },
};
