import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import {
    convertRoleListItemToOption,
    RoleStore,
} from 'src/stores/RoleStore/RoleStore';
import { FormHelper } from 'src/utils/FormHelper';

export const UsersFilterForm = observer(() => {
    return (
        <>
            <TextField name="name" label={t('Page.Users.Filter.Name')} />
            <TextField
                name="phone"
                label={t('Page.Users.Filter.PhoneNumber')}
            />
            <EnumSelectField
                mode="multiple"
                name="statuses"
                label={t('Page.Users.Filter.Status')}
                enumName="USER_STATUS"
            />
            <SelectField
                name="registrationCompleted"
                label={t('Page.Users.Filter.RegistrationCompleted')}
                options={FormHelper.objAsOptions(
                    {
                        true: true,
                        false: false,
                    },
                    'Primitive.Boolean.',
                )}
            />
            <SelectField
                mode="multiple"
                name="roleIds"
                label={t('Page.Users.Filter.RoleIds')}
                store={RoleStore}
                convertListItemToOption={convertRoleListItemToOption}
            />
        </>
    );
});
