import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FilterForm } from 'src/components/Form/FilterForm';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import {
    convertIndustryListItemToOption,
    LegalIndustryOptionsStore,
} from 'src/stores/LegalIndustryOptionsStore/LegalIndustryOptionsStore';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

type LegalEntityFilterFormProps = {
    store: BasicStore<any>;
};
export const LegalEntityCoBorrowersListFilterForm = observer(
    ({ store }: LegalEntityFilterFormProps) => {
        return (
            <FilterForm store={store}>
                <NumberField
                    name="id"
                    label={t('Dialog.CreateCoBorrower.LegalEntity.Filter.Id')}
                />
                <TextField
                    name="companyName"
                    label={t(
                        'Dialog.CreateCoBorrower.LegalEntity.Filter.CompanyName',
                    )}
                />
                <SelectField
                    mode="multiple"
                    name="industryIds"
                    store={LegalIndustryOptionsStore}
                    convertListItemToOption={convertIndustryListItemToOption}
                    label={t(
                        'Dialog.CreateCoBorrower.LegalEntity.Filter.Industry',
                    )}
                />
                <TextField
                    name="registrationNumber"
                    label={t(
                        'Dialog.CreateCoBorrower.LegalEntity.Filter.RegistrationNumber',
                    )}
                />
                <TextField
                    name="ceoName"
                    label={t('Dialog.CreateCoBorrower.LegalEntity.Filter.CEO')}
                />
                <TextField
                    name="phoneNumber"
                    label={t(
                        'Dialog.CreateCoBorrower.LegalEntity.Filter.PhoneNumber',
                    )}
                />
            </FilterForm>
        );
    },
);
