import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { PledgedLoanApplication } from 'src/generated-api-client';
import { LoanApplicationInvoiceStatus } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Components/LoanApplicationInvoiceStatus';

export type ApplicationInvoiceTabProps = {
    application?: PledgedLoanApplication;
};

export const InvoiceTab = observer(
    ({ application }: ApplicationInvoiceTabProps) => {
        const invoice = application?.invoice;

        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.Tab.Invoice.Title',
                            'Invoice Detailed Information',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    value={invoice?.goods}
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.Goods',
                                        'Good / Service Name',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={invoice?.seriesCode}
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.SeriesCode',
                                        'Series Code',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={invoice?.merchant?.name}
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.MerchantName',
                                        'Merchant Name',
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    value={invoice?.merchant?.branchName}
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.MerchantBranchName',
                                        'Merchant Branch Name',
                                    )}
                                    format="string"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.CreatedDate',
                                        'Invoice Created Date',
                                    )}
                                    value={invoice?.createdAt}
                                    format="date"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.Status',
                                        'Invoice Status',
                                    )}
                                    value={invoice?.status}
                                    format={(value) => (
                                        <LoanApplicationInvoiceStatus
                                            status={value}
                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.ID',
                                        'Invoice ID',
                                    )}
                                    value={invoice?.id}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.MerchantCommission',
                                        'Merchant Fee / Commission',
                                    )}
                                    value={invoice?.merchant?.commission}
                                    format="percent"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.EditedDate',
                                        'Invoice Edited Date',
                                    )}
                                    value={invoice?.editedAt}
                                    format="date"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.EditedUserName',
                                        'Edited User Name',
                                    )}
                                    value={invoice?.editedBy}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.Quantity',
                                        'Quantity',
                                    )}
                                    value={invoice?.quantity}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.TotalAmount',
                                        'Total Amount',
                                    )}
                                    value={invoice?.totalAmount}
                                    format="money"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.ExpireDate',
                                        'Invoice Expire Date',
                                    )}
                                    value={invoice?.expiresAt}
                                    format="date"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Tab.Invoice.Amount',
                                        'Invoice amount',
                                    )}
                                    value={invoice?.amount}
                                    format="money"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    },
);
