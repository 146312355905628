import { t } from 'i18next';
import {
    ActionType,
    LegalRepresentativeListEntry,
} from 'src/generated-api-client';
import { RemoveRepresentativeDialog } from 'src/pages/LegalEntityDetailsPage/Dialogs/LegalEntityInformation/RemoveRepresentativeDialog';
import { UpdateRepresentativeDialog } from 'src/pages/LegalEntityDetailsPage/Dialogs/LegalEntityInformation/UpdateRepresentativeDialog';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getRepresentativeActions = (
    representative: LegalRepresentativeListEntry,
    viewModel?: LegalEntityDetailsViewModelClass,
) => {
    return [...getUIActions(), ...getServerActions(representative, viewModel)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    representative?: LegalRepresentativeListEntry,
    viewModel?: LegalEntityDetailsViewModelClass,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(representative, viewModel),
        viewModel?.item?.actions,
    );
};

const getRegisteredServerActions = (
    representative?: LegalRepresentativeListEntry,
    viewModel?: LegalEntityDetailsViewModelClass,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.LEGAL_REPRESENTATIVE_UPDATE,
            label: t(
                'Legal.Representatives.Actions.LEGAL_REPRESENTATIVE_UPDATE',
            ),
            onClick: () => {
                UpdateRepresentativeDialog.render({
                    representative,
                    viewModel,
                });
            },
        },
        {
            key: ActionType.LEGAL_REPRESENTATIVE_DELETE,
            label: t(
                'Legal.Representatives.Actions.LEGAL_REPRESENTATIVE_DELETE',
            ),
            onClick: () => {
                RemoveRepresentativeDialog.render({
                    viewModel,
                    representativeId: representative?.representativeId,
                });
            },
        },
    ];
};
