import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { DotsHorizontalIcon } from 'src/components/Icons/Icons';
import {
    CollateralCoOwner,
    CreateCollateral200Response,
} from 'src/generated-api-client';
import { getCollateralOwnershipInfoListActions } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/CollateralOwnershipInfoListActions.config';
import styled from 'styled-components';

type CollateralOwnershipInfoListActionsProps = {
    coOwner: CollateralCoOwner;
    collateral?: CreateCollateral200Response;
};

export const CollateralOwnershipInfoListActions = observer(
    ({ coOwner, collateral }: CollateralOwnershipInfoListActionsProps) => {
        const actions = getCollateralOwnershipInfoListActions(
            collateral,
            coOwner,
        ).filter((action) => !action.disabled);
        const hideActions = actions.length === 0;

        if (hideActions) {
            return null;
        }

        return (
            <Dropdown items={actions} disabled={!actions.length}>
                <StyledIconButton icon={<DotsHorizontalIcon />} />
            </Dropdown>
        );
    },
);

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;
