import i18next from 'i18next';
import { computed } from 'mobx';
import {
    LoanPurposeDictionaryItem,
    Localization,
} from 'src/generated-api-client';
import { dictionaryApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LoanPurposesOptionsStoreClass extends BasicStore<LoanPurposeDictionaryItem> {
    api: BasicStoreApi<LoanPurposeDictionaryItem> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                dictionaryApi.getLoanPurposes(),
            );

            return result || [];
        },
    };

    @computed get loanPurposesOptions() {
        return this.list.map(asOption);
    }

    @computed get loanPurposesMap() {
        return Object.fromEntries(
            this.list.map((purposeItem) => {
                return [purposeItem.id, purposeItem];
            }),
        );
    }

    loanSubPurposesOptions(purposeId: number) {
        return this.loanPurposesMap[purposeId]?.loanSubPurposes?.map(asOption);
    }
}

export const LoanPurposesOptionsStore = new LoanPurposesOptionsStoreClass();

const asOption = (value: any) => ({
    value: value.id,
    label: value.l10n?.[i18next.language as keyof Localization],
});
