import { Form } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { ProposeActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ProposeActionDialogComponent';
import { ProposePenaltyAdjustmentActionStore } from 'src/stores/LoanActionsStores/ProposePenaltyAdjustmentActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;

export const ProposePenaltyAdjustmentDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const title = t('Dialog.ProposePenaltyAdjustment.Title');
        const penaltyErrorMessage = t(
            'Form.ProposePenaltyAdjustment.Rules.Messages.Penalty',
        );
        const store = ProposePenaltyAdjustmentActionStore.getInstance(
            Number(loanId),
        );
        const [form] = Form.useForm();

        useEffect(() => {
            form.setFieldsValue({
                penalty: undefined,
            });
        }, [store.initialValues]);

        return (
            <ProposeActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                form={form}
                {...props}
            >
                <DataView
                    value={store.initialValues?.penalty}
                    label={t('Dialog.ProposePenaltyAdjustment.CurrentPenalty')}
                    format="money"
                />
                <NumberField
                    name="penalty"
                    label={t('Dialog.ProposePenaltyAdjustment.NewPenalty')}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.numberMinValue(0),
                        ValidationRules.canNotBeEqual(
                            store.initialValues?.penalty,
                            penaltyErrorMessage,
                        ),
                    ]}
                />
            </ProposeActionDialogComponent>
        );
    },
);

export const ProposePenaltyAdjustmentDialog = addModalRenderer(
    ProposePenaltyAdjustmentDialogFn,
);
