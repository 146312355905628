import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FilterForm } from 'src/components/Form/FilterForm';
import { PlusIcon } from 'src/components/Icons/Icons';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { LegalEntitiesFilterForm } from 'src/pages/LegalEntitiesPage/LegalEntitiesFilterForm';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { LegalEntitiesStore } from 'src/stores/LegalEntitiesStore/LegalEntitiesStore';

type LegalEntitiesHeaderPanelProps = {
    title: React.ReactNode;
};

export const LegalEntitiesHeaderPanel = observer(
    ({ title }: LegalEntitiesHeaderPanelProps) => {
        const { t } = useTranslation();

        const canCreate = AppUser.hasPermissions(UserPermission.LegalCreate);

        return (
            <div>
                <FilterForm
                    store={LegalEntitiesStore}
                    title={title}
                    panelExtra={
                        canCreate && (
                            <Button
                                icon={<PlusIcon />}
                                type="primary"
                                onClick={() =>
                                    Router.navigate(RoutePaths.legalCreate)
                                }
                            >
                                {t('Page.LegalEntities.Button.CreateLegal')}
                            </Button>
                        )
                    }
                >
                    <LegalEntitiesFilterForm />
                </FilterForm>
            </div>
        );
    },
);
