import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import {
    convertIndustryListItemToOption,
    LegalIndustryOptionsStore,
} from 'src/stores/LegalIndustryOptionsStore/LegalIndustryOptionsStore';

export const LegalEntitiesFilterForm = observer(() => {
    const { t } = useTranslation();

    return (
        <>
            <NumberField name="id" label={t('Page.LegalEntities.Filter.Id')} />
            <TextField
                name="companyName"
                label={t('Page.LegalEntities.Filter.CompanyName')}
            />
            <SelectField
                mode="multiple"
                name="industryIds"
                store={LegalIndustryOptionsStore}
                convertListItemToOption={convertIndustryListItemToOption}
                label={t('Page.LegalEntityCreate.Industry')}
            />
            <TextField
                name="registrationNumber"
                label={t('Page.LegalEntities.Filter.RegistrationNumber')}
            />
            <TextField
                name="ceoName"
                label={t('Page.LegalEntities.Filter.CEO')}
            />
            <TextField
                name="phoneNumber"
                label={t('Page.LegalEntities.Filter.PhoneNumber')}
            />
        </>
    );
});
