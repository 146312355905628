import { ColumnType } from 'antd/es/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ErrorField } from 'src/components/Form/ErrorField';
import { ListSelectField } from 'src/components/Form/ListSelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import {
    CustomerPhoneNumber,
    IndividualListEntry,
} from 'src/generated-api-client';
import { CreateCollateralOwnershipInfoDialog } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/Dialogs/CreateCollateralOwnershipInfoDialog';
import { CreateExistingIndividualCoOwnerFilterForm } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/Dialogs/CreateExistingIndividualCoOwnerFilterForm';
import { CollateralCoOwnerStore } from 'src/stores/CollateralCoOwnerStore/CollateralCoOwnerStore';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type CreateExistingIndividualCoOwnerDialogProps = ModalRendererProps<{
    collateralId: number;
}>;

const CreateExistingIndividualCoOwnerDialogFn = observer(
    ({
        collateralId,
        ...props
    }: CreateExistingIndividualCoOwnerDialogProps) => {
        const store = CollateralCoOwnerStore.getInstance(collateralId);
        const { isLoading } = IndividualsStore.listLoader;

        useEffect(() => {
            IndividualsStore.itemLoader.call();

            return () => {
                IndividualsStore.listLoader.reset();
                IndividualsStore.filterCriteria?.resetFilter();
                IndividualsStore.pager?.reset();
            };
        }, []);

        return (
            <Modal
                isLoading={isLoading}
                title={t('Dialog.CreateCollateralOwnershipInfo.Title')}
                okText={t('Dialog.CreateCollateralOwnershipInfo.Btn.Choose')}
                okButtonProps={{
                    loading: IndividualsStore?.isItemLoading,
                }}
                onFinish={async (values) => {
                    await IndividualsStore.loadItem(values.individualId);
                    const chosenIndividual = IndividualsStore?.currentItem;
                    const mainPhoneNumber =
                        chosenIndividual.customerContactDetails?.customerPhoneNumbers.find(
                            (e: CustomerPhoneNumber) => e.isMain,
                        ).phoneNumber;
                    const prefillData = {
                        civilId: chosenIndividual?.civilId,
                        firstName: chosenIndividual?.firstName,
                        lastName: chosenIndividual?.lastName,
                        phoneNumber: mainPhoneNumber,
                        registrationNumber:
                            chosenIndividual?.idDocumentRegistrationNumber,
                        fullAddress: chosenIndividual?.fullAddressId,
                    };

                    CreateCollateralOwnershipInfoDialog.render({
                        collateralId,
                        prefillData,
                    });
                    if (store.createItemLoader.hasError) {
                        return Promise.reject();
                    }
                }}
                headerExtra={
                    <CreateExistingIndividualCoOwnerFilterForm
                        store={IndividualsStore}
                        collateralId={collateralId}
                        cancelCurrentModal={props?.onCancel}
                    />
                }
                width={1200}
                {...props}
            >
                <StyledListSelectField
                    name="individualId"
                    store={IndividualsStore}
                    columns={getColumns(props)}
                    rules={[ValidationRules.required]}
                    listSelectProps={{
                        rowKey: (record: IndividualListEntry) => record?.id,
                    }}
                />
                <StyledError name={['individualId']} />
            </Modal>
        );
    },
);

export const CreateExistingIndividualCoOwnerDialog = addModalRenderer(
    CreateExistingIndividualCoOwnerDialogFn,
);

const StyledListSelectField = styled(ListSelectField)`
    .ant-table-wrapper {
        padding: 10px 0;
    }
`;

const StyledError = styled(ErrorField)`
    margin-bottom: 10px;
`;

const getColumns = (
    props: ModalRendererProps,
): ColumnType<IndividualListEntry>[] => {
    return [
        {
            key: 'id',
            dataIndex: 'id',
            title: (
                <Trans i18nKey="Dialog.CreateCollateralOwnershipInfo.List.Id" />
            ),
            render: (id: string) => {
                return (
                    <Link
                        to={EntityLinks.individuals.itemDetails(id)}
                        onClick={() => {
                            Router.navigate(
                                EntityLinks.individuals.itemDetails(id),
                            );

                            props.onCancel();
                        }}
                    >
                        {id}
                    </Link>
                );
            },
        },
        {
            key: 'customerName',
            dataIndex: 'customerName',
            title: (
                <Trans i18nKey="Dialog.CreateCollateralOwnershipInfo.List.Name" />
            ),
        },
        {
            key: 'idDocumentRegistrationNumber',
            dataIndex: 'idDocumentRegistrationNumber',
            title: (
                <Trans i18nKey="Dialog.CreateCollateralOwnershipInfo.List.RegistrationNumber" />
            ),
        },
        {
            key: 'civilId',
            dataIndex: 'civilId',
            title: (
                <Trans i18nKey="Dialog.CreateCollateralOwnershipInfo.List.CivilId" />
            ),
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: (
                <Trans i18nKey="Dialog.CreateCollateralOwnershipInfo.List.Phone" />
            ),
        },
    ];
};
