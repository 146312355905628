import { t } from 'i18next';
import {
    LoanMaturityDateExtensionPrecondition,
    LoanMaturityDateExtensionProposal,
} from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProposeMaturityDateExtensionActionStoreClass extends BasicActionStore<
    LoanMaturityDateExtensionPrecondition,
    LoanMaturityDateExtensionProposal
> {
    constructor(private loanId: number) {
        super();
    }

    okMessage = t('Dialog.Restructuring.Message.SuccessCreate');

    api: BasicActionStoreApi<
        LoanMaturityDateExtensionPrecondition,
        LoanMaturityDateExtensionProposal
    > = {
        load: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getMaturityDateExtension({ id: this.loanId }),
            );
        },

        ok: async (data?: LoanMaturityDateExtensionProposal) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.proposeMaturityDateExtension({
                    id: this.loanId,
                    loanMaturityDateExtensionProposal: data!,
                }),
            );
        },
    };
}

export const ProposeMaturityDateExtensionActionStore = new EntityProvider(
    ProposeMaturityDateExtensionActionStoreClass,
);
