import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { ChildBranchStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: ChildBranchStatus;
};

export const ChildBranchesStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: ChildBranchStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: t(`ChildBranch.Status.${status}`),
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [ChildBranchStatus.ACTIVE]: {
        title: <Trans i18nKey="ChildBranch.Status.ACTIVE" />,
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
    [ChildBranchStatus.INACTIVE]: {
        title: <Trans i18nKey="ChildBranch.Status.INACTIVE" />,
        color: '#fa656c',
        backgroundColor: '#FFE4E4',
    },
};
