import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { CollateralDetailsType } from 'src/generated-api-client';

export const CreateEquipmentCollateralGeneralInfoVariables = observer(() => {
    return (
        <Card
            title={t('Page.CollateralCreatePage.Equipment.GeneralInfo.Title')}
        >
            <Row gutter={16}>
                <HiddenField
                    name="type"
                    value={CollateralDetailsType.EQUIPMENT}
                />
                <Col xs={6}>
                    <EnumSelectField
                        name="equipmentType"
                        enumName="EQUIPMENT_TYPE"
                        label={t(
                            'Page.CollateralCreatePage.Equipment.GeneralInfo.EquipmentType',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="name"
                        label={t(
                            'Page.CollateralCreatePage.Equipment.GeneralInfo.Name',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="country"
                        label={t(
                            'Page.CollateralCreatePage.Equipment.GeneralInfo.Country',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="brandName"
                        label={t(
                            'Page.CollateralCreatePage.Equipment.GeneralInfo.BrandName',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="model"
                        label={t(
                            'Page.CollateralCreatePage.Equipment.GeneralInfo.Model',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <TextField
                        name="serialNumber"
                        label={t(
                            'Page.CollateralCreatePage.Equipment.GeneralInfo.SerialNumber',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                <Col xs={6}>
                    <DatePickerField
                        name="manufacturedDate"
                        label={t(
                            'Page.CollateralCreatePage.Equipment.GeneralInfo.ManufacturedDate',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <DatePickerField
                        name="importedDate"
                        label={t(
                            'Page.CollateralCreatePage.Equipment.GeneralInfo.ImportedDate',
                        )}
                    />
                </Col>
                <Col xs={6}>
                    <EnumSelectField
                        name="ownershipType"
                        enumName="COLLATERAL_OWNERSHIP_TYPE"
                        label={t(
                            'Page.CollateralCreatePage.Equipment.GeneralInfo.OwnershipType',
                        )}
                    />
                </Col>
            </Row>
        </Card>
    );
});
