import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { InstallmentLoanApplication } from 'src/generated-api-client';
import { InstallmentLoanApplicationBrief } from 'src/pages/ApplicationDetailsPage/InstallmentLoanApplication/Components/InstallmentLoanApplicationBrief';
import { GeneralInfoTab } from 'src/pages/ApplicationDetailsPage/InstallmentLoanApplication/Tabs/GeneralInfoTab';
import { RepaymentScheduleTab } from 'src/pages/ApplicationDetailsPage/InstallmentLoanApplication/Tabs/RepaymentScheduleTab';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

const store = ApplicationsStore;

export const InstallmentLoanApplicationDetails = observer(() => {
    const application = store.currentItem as InstallmentLoanApplication;

    const tabs: TabDefinition[] = [
        {
            title: t('Page.InstallmentLoanApplicationDetails.Tabs.Details'),
            tabKey: 'details',
            Component: <GeneralInfoTab application={application} />,
            visible: AppUser.hasPermissions(
                UserPermission.ApplicationReadGeneralInformation,
            ),
        },
        {
            title: t(
                'Page.InstallmentLoanApplicationDetails.Tabs.RepaymentSchedule',
            ),
            tabKey: 'schedule',
            Component: <RepaymentScheduleTab applicationId={application?.id} />,
            visible: AppUser.hasPermissions(
                UserPermission.ApplicationReadRepaymentSchedule,
            ),
        },
        {
            title: t('Page.InstallmentLoanApplicationDetails.Tabs.Documents'),
            tabKey: 'documents',
            Component: (
                <DocumentsList
                    documentRoom={application?.documentRoom}
                    canUpload={AppUser.hasPermissions(
                        UserPermission.DocumentLoanApplicationUpload,
                    )}
                />
            ),
            visible: AppUser.hasPermissions(
                UserPermission.DocumentLoanApplicationRead,
            ),
        },
    ];

    return (
        <Row gutter={[16, 32]}>
            <Col xs={24}>
                <InstallmentLoanApplicationBrief application={application} />
            </Col>
            <Col xs={24}>
                <Tabs tabs={tabs} />
            </Col>
        </Row>
    );
});
