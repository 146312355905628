import { Col, Form, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { RepaymentFrequency } from 'src/generated-api-client';
import { repaymentFrequencyOptions } from 'src/pages/TaskDetailsPage/Components/repaymentFrequencyOptions.config';

export const N16TaskAddCCConditionsApproveVariables = observer(() => {
    const form = Form.useFormInstance();
    const repaymentFrequency = Form.useWatch('repaymentFrequency');
    const isBiWeeklyRepaymentFrequency =
        repaymentFrequency === RepaymentFrequency.BI_WEEKLY;

    useEffect(() => {
        if (repaymentFrequency && !isBiWeeklyRepaymentFrequency) {
            form.setFields([
                {
                    name: 'secondRepaymentDate',
                    value: undefined,
                },
            ]);
        } else if (repaymentFrequency && isBiWeeklyRepaymentFrequency) {
            form.setFields([{ name: 'repaymentFrequency', value: undefined }]);
        }
    }, [isBiWeeklyRepaymentFrequency]);

    const isRequiredSecondRepaymentDate = isBiWeeklyRepaymentFrequency
        ? ValidationRules.required
        : {};

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <HiddenField name="type" />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="amount"
                    label={t('Task.Form.N16TaskAddССConditions.Amount')}
                    thousandDivider
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <SelectField
                    name="repaymentFrequency"
                    label={t(
                        'Task.Form.N16TaskAddССConditions.RepaymentFrequency',
                    )}
                    options={repaymentFrequencyOptions}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={6}>
                <DatePickerField
                    name="firstRepaymentDate"
                    label={t(
                        'Task.Form.N16TaskAddССConditions.FirstRepaymentDate',
                    )}
                    rules={[ValidationRules.required]}
                    disabledDate={(date) => {
                        return date.isBefore(moment().endOf('day'));
                    }}
                />
            </Col>
            <Col xs={6}>
                <DatePickerField
                    name="secondRepaymentDate"
                    label={t(
                        'Task.Form.N16TaskAddССConditions.SecondRepaymentDate',
                    )}
                    rules={[isRequiredSecondRepaymentDate]}
                    disabled={!isBiWeeklyRepaymentFrequency}
                    disabledDate={(date) => {
                        return date.isBefore(moment().endOf('day'));
                    }}
                />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="monthlyInterestRate"
                    label={t(
                        'Task.Form.N16TaskAddССConditions.MonthlyInterestRate',
                    )}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.numberMinValue(0),
                        ValidationRules.numberMaxValue(100),
                    ]}
                />
            </Col>
            <Col xs={6}>
                <NumberField
                    name="requestedTerm"
                    label={t('Task.Form.N16TaskAddССConditions.Term')}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.numberMaxValue(180),
                        ValidationRules.greaterThanZero,
                    ]}
                />
            </Col>
            <Col xs={24}>
                <TextAreaField
                    name="comment"
                    label={t('Task.Form.N16TaskAddССConditions.Comment')}
                />
            </Col>
        </Row>
    );
});
