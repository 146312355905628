import { CibLoanListEntry } from 'src/generated-api-client';
import { individualsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class IndividualDetailsCibStoreClass extends BasicStore<CibLoanListEntry> {
    api: BasicStoreApi<
        CibLoanListEntry,
        CibLoanListEntry,
        CibLoanListEntry,
        CibLoanListEntry
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getCibLoanData({
                    id: this.individualId,
                }),
            );
        },
    };

    constructor(private individualId: number) {
        super();
    }
}

export const IndividualDetailsCibStore = new EntityProvider(
    IndividualDetailsCibStoreClass,
);
