import { t } from 'i18next';
import { LoanInterestAdjustment } from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProposeInterestAdjustmentActionStoreClass extends BasicActionStore<
    LoanInterestAdjustment,
    LoanInterestAdjustment
> {
    constructor(private loanId: number) {
        super();
    }

    okMessage = t('Dialog.Restructuring.Message.SuccessCreate');

    api: BasicActionStoreApi<LoanInterestAdjustment, LoanInterestAdjustment> = {
        load: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getInterestAdjustmentPrecondition({ id: this.loanId }),
            );
        },

        ok: async (data) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.proposeInterestAdjustment({
                    id: this.loanId,
                    loanInterestAdjustment: data!,
                }),
            );
        },
    };
}

export const ProposeInterestAdjustmentActionStore = new EntityProvider(
    ProposeInterestAdjustmentActionStoreClass,
);
