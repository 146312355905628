import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { getCollateralEvaluationActions } from 'src/pages/CollateralDetailsPage/Components/CollateralEvaluation/CollateralEvaluationActions.config';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { Collateral } from 'src/utils/CollateralsHelper';
import { UrlHelper } from 'src/utils/UrlHelper';

export const CollateralEvaluation = observer(() => {
    const collateral = CollateralsStore.currentItem as Collateral;

    const userDetailsUrl = UrlHelper.getTargetUrl(RoutePaths.userDetails, {
        userId: collateral?.evaluation?.evaluatedBy?.id,
    });

    return (
        <Card
            title={t('Component.CollateralEvaluation.Title')}
            menuActions={getCollateralEvaluationActions(collateral) || []}
        >
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral?.evaluation?.marketValue}
                        label={t('Component.CollateralEvaluation.MarketValue')}
                        format="money"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.evaluation?.ltv}
                        label={t('Component.CollateralEvaluation.Ltv')}
                        format="percent"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.evaluation?.mutuallyAgreedValuation}
                        label={t(
                            'Component.CollateralEvaluation.MutuallyAgreedValuation',
                        )}
                        format="percent"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.evaluation?.riskEstimatedValue}
                        label={t(
                            'Component.CollateralEvaluation.RiskEstimatedValue',
                        )}
                        format="money"
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={6}>
                    <DataView
                        value={collateral?.evaluation?.rmValue}
                        label={t('Component.CollateralEvaluation.CIAValue')}
                        format="money"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.evaluation?.evaluatedDate}
                        label={t(
                            'Component.CollateralEvaluation.EvaluatedDate',
                        )}
                        format="date"
                    />
                </Col>
                <Col xs={6}>
                    <DataView
                        value={collateral?.evaluation?.evaluatedBy?.name}
                        label={t(
                            'Component.CollateralEvaluation.EvaluatedEmployee',
                        )}
                        format="link-internal"
                        formatterProps={{
                            to: userDetailsUrl,
                        }}
                    />
                </Col>
            </Row>
        </Card>
    );
});
