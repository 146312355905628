import { Localization } from 'src/generated-api-client';
import { Entity } from 'src/utils/mobx/BasicStore/BasicStore.types';

export type AdministrativeUnit = {
    code?: string;
    provinceCode?: string;
    districtCode?: string;
    khorooCode?: string;
    l10n?: Localization;
    children?: Array<AdministrativeUnit>;
};
export type AdministrativeUnitWithId = Entity & {
    id: string;
} & AdministrativeUnit;

export function convertToAdministrativeUnitList<T extends AdministrativeUnit>(
    items: Array<T>,
    childrenKey?: keyof T,
): Array<AdministrativeUnitWithId> {
    return items.map((item) => {
        // eslint-disable-next-line sonarjs/no-identical-expressions
        const code = item.provinceCode || item.districtCode || item.khorooCode; // TODO: use universal code in the future

        return {
            ...item,
            id: code,
            code, // TODO: refactor this after update DataModel of Addresses
            children: childrenKey ? item[childrenKey] : undefined,
        } as AdministrativeUnitWithId;
    }) as Array<AdministrativeUnitWithId>;
}
