import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ReactComponent as DownloadIcon } from 'src/assets/download-icon.svg';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { DocumentTemplateListEntry } from 'src/generated-api-client';
import { DocumentTemplateUploadDialog } from 'src/pages/DocumentTemplatesPage/DocumentTeplateUploadDialog';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

const DEFAULT_TEMPLATE_NAME = 'document-template';

type DocumentViewProps = {
    document: DocumentTemplateListEntry;
    canUploadDocument: boolean;
};

export const DocumentView = observer(
    ({ document, canUploadDocument }: DocumentViewProps) => {
        return (
            <Row gutter={16} wrap={false}>
                <Col xs={5}>
                    <DataView
                        label={t(
                            'Page.DocumentTemplates.Table.DocumentTemplateType',
                        )}
                        value={document?.type}
                        format={(value) => (
                            <EnumView
                                enumName="DOCUMENT_TEMPLATE_TYPE"
                                value={value}
                            />
                        )}
                    />
                </Col>
                <Col flex="auto">
                    <DataView
                        label={t('Page.DocumentTemplates.Table.FileName')}
                        value={document?.file?.fileName}
                    />
                </Col>
                <Col>
                    <StyledSpace size={10} direction="horizontal" align="end">
                        <StyledButton
                            title={t(
                                'Page.DocumentTemplates.Table.Actions.Download',
                            )}
                            onClick={() => {
                                UrlHelper.downloadFile(
                                    String(document?.file?.presignedUrl),
                                    document?.file?.fileName ||
                                        DEFAULT_TEMPLATE_NAME,
                                );
                            }}
                        >
                            <DownloadIcon />
                        </StyledButton>
                        {canUploadDocument && (
                            <StyledButton
                                title={t(
                                    'Page.DocumentTemplates.Table.Actions.UploadTemplate',
                                )}
                                onClick={() => {
                                    DocumentTemplateUploadDialog.render({
                                        documentTemplateType: document?.type,
                                    });
                                }}
                            >
                                <StyledUploadIcon />
                            </StyledButton>
                        )}
                    </StyledSpace>
                </Col>
            </Row>
        );
    },
);
const StyledButton = styled(Button)`
    background: #f7f8fc;
    border: transparent;
    padding-left: 20px;
    padding-right: 20px;

    &:active,
    &:hover {
        border: transparent;
        background: #f7f8fc;
    }
`;
const StyledSpace = styled(Space)`
    width: 100%;
    justify-content: end;
`;
const StyledUploadIcon = styled(CloudUploadOutlined)`
    svg {
        width: 20px;
        height: 20px;
        fill: #0562a5;
        transform: translateY(2px);
    }
`;
