import { ColumnType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { Modal } from 'src/components/Modal/Modal';
import {
    IncomeCheckStatus,
    SalaryIncomeDetailsResponse,
    SalaryIncomeListEntry,
} from 'src/generated-api-client';
import { IncomeStatus } from 'src/pages/IndividualDetailsPage/Components/IncomeStatus';
import { IndividualSalaryIncomeStore } from 'src/stores/IndividualSalaryIncomeStore/IndividualSalaryIncomeStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type SalaryIncomeDetailsDialogFnProps = ModalRendererProps<{
    individualId?: number;
}>;

export const SalaryIncomeDetailsDialogFn = observer(
    ({ individualId, ...props }: SalaryIncomeDetailsDialogFnProps) => {
        const store = IndividualSalaryIncomeStore.getInstance(
            Number(individualId),
        );
        const { isLoading } = store.itemLoader;
        const details: SalaryIncomeDetailsResponse = store.incomeDetails;
        const incomeDataStatuses: Array<string> = [
            IncomeCheckStatus.APPROVED,
            IncomeCheckStatus.REJECT,
        ];
        const showIncomeData =
            !isLoading &&
            details?.status &&
            incomeDataStatuses.includes(details?.status);

        useEffect(() => {
            store.loadItem();

            return () => {
                store.itemLoader.reset();
            };
        }, [individualId]);

        return (
            <Modal
                title={t('Dialog.SalaryIncomeDetails.Title')}
                footer={null}
                maskClosable={false}
                width={1000}
                isLoading={isLoading}
                {...props}
            >
                <StyledDataView
                    value={details?.monthlyIncome}
                    label={t('Dialog.SalaryIncome.MonthlyIncome')}
                    format="money"
                    align="horizontal"
                />
                <StyledDataView
                    value={details?.status}
                    label={t('Dialog.SalaryIncome.Status')}
                    align="horizontal"
                    format={(value) => {
                        return <IncomeStatus status={value} />;
                    }}
                />
                {showIncomeData && (
                    <StyledListView
                        dataSource={details?.incomeData}
                        columns={columns}
                    />
                )}
            </Modal>
        );
    },
);

export const SalaryIncomeDetailsDialog = addModalRenderer(
    SalaryIncomeDetailsDialogFn,
);

const StyledDataView = styled(DataView)`
    justify-content: space-between;
    align-items: baseline;
    width: 320px;

    .app-data-view__label,
    .app-data-view__value {
        font-size: 18px;
        color: #4d6082;
    }
`;

const StyledListView = styled(ListView)`
    padding: 0;
`;

const columns: ColumnType<SalaryIncomeListEntry>[] = [
    {
        title: <Trans i18nKey="Dialog.SalaryIncome.List.IndexNumber" />,
        key: 'indexNumber',
        render: (text, record, index) => index + 1,
    },
    {
        title: <Trans i18nKey="Dialog.SalaryIncome.List.Entity" />,
        key: 'entity',
        dataIndex: 'entity',
    },
    {
        title: <Trans i18nKey="Dialog.SalaryIncome.List.District" />,
        key: 'district',
        dataIndex: 'district',
    },
    {
        title: <Trans i18nKey="Dialog.SalaryIncome.List.Month" />,
        key: 'month',
        dataIndex: 'month',
    },
    {
        title: <Trans i18nKey="Dialog.SalaryIncome.List.Salary" />,
        key: 'salary',
        dataIndex: 'salary',
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        title: <Trans i18nKey="Dialog.SalaryIncome.List.Tax" />,
        key: 'tax',
        dataIndex: 'tax',
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
];
