/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView, LONG_DASH } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    IdNameEntry,
    PledgedApplicationDecisionListEntry,
    PledgedLoanApplication,
} from 'src/generated-api-client';
import { PledgedApplicationDecision } from 'src/pages/ApplicationDetailsPage/PledgedLoanApplication/Components/PledgedApplicationDecision';
import { UrlHelper } from 'src/utils/UrlHelper';

export type PledgedApplicationDecisionTabProps = {
    application?: PledgedLoanApplication;
};

export const DecisionTab = observer(
    ({ application }: PledgedApplicationDecisionTabProps) => {
        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.Tab.Decision.Title',
                            'Decision',
                        )}
                    >
                        <ListView
                            dataSource={application?.decisions}
                            columns={columns}
                        />
                    </Card>
                </Col>
            </Row>
        );
    },
);

const columns: ColumnsType<PledgedApplicationDecisionListEntry> = [
    {
        key: 'user',
        dataIndex: 'user',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.Decision.List.Member" />
        ),
        render(value: IdNameEntry) {
            return (
                <DataView
                    hideLabel
                    value={value.name}
                    format="link-internal"
                    formatterProps={{
                        to: UrlHelper.getTargetUrl(RoutePaths.userDetails, {
                            userId: value.id,
                        }),
                    }}
                />
            );
        },
    },
    {
        key: 'actorType',
        dataIndex: 'actorType',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.Decision.List.ActorType" />
        ),
        render: (value) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => t(`Application.ActorType.${value}`)}
                />
            );
        },
    },
    {
        key: 'decision',
        dataIndex: 'decision',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.Decision.List.Decision" />
        ),
        render: (value) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <PledgedApplicationDecision decision={value} />
                    )}
                />
            );
        },
    },
    {
        key: 'timeSpent',
        dataIndex: 'taskCompletedAt',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.Decision.List.TimeSpent" />
        ),
        render: (_, record) => {
            return getTimeDiffView(record);
        },
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.Decision.List.Amount" />
        ),
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
    {
        key: 'term',
        dataIndex: 'term',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.Decision.List.Term" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="string" />;
        },
    },
    {
        key: 'monthlyInterestRate ',
        dataIndex: 'monthlyInterestRate',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.Decision.List.InterestRate" />
        ),
        render: (value) => {
            return <DataView value={value} format="percent" hideLabel />;
        },
    },
    {
        key: 'repaymentFrequency',
        dataIndex: 'repaymentFrequency',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.Decision.List.RepaymentFrequency" />
        ),
        render: (value) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => (
                        <EnumView
                            enumName="REPAYMENT_FREQUENCY"
                            value={value}
                        />
                    )}
                />
            );
        },
    },
    {
        key: 'comment',
        dataIndex: 'comment',
        title: (
            <Trans i18nKey="Page.PledgedLoanApplicationDetails.Tab.Decision.List.Comment" />
        ),
        render: (value) => {
            return <DataView value={value} hideLabel format="string" />;
        },
    },
];

const getTimeDiffView = (record: PledgedApplicationDecisionListEntry) => {
    const timeDiff = moment.duration(
        moment(record?.taskCompletedAt)?.diff(moment(record?.taskCreatedAt)),
    );

    const hasTimeDiff = Math.floor(timeDiff.asMinutes()) > 0;

    return record?.taskCompletedAt && hasTimeDiff ? (
        <DataView value={timeDiff} hideLabel format="small-interval" />
    ) : (
        LONG_DASH
    );
};
