import { observer } from 'mobx-react-lite';
import { ChildBranchListEntry } from 'src/generated-api-client';
import { EditChildBranchDialog } from 'src/pages/BranchesPage/Dialogs/EditChildBranchDialog';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import styled from 'styled-components';

type ImageBranchViewProps = {
    branch?: ChildBranchListEntry;
};

export const ImageBranchView = observer(({ branch }: ImageBranchViewProps) => {
    const canEdit = AppUser.hasPermissions(UserPermission.BranchEdit);

    return (
        <StyledImageBranchView
            imageUrl={branch?.logoFile?.presignedUrl}
            className="image-branch-view"
            onClick={() => {
                canEdit &&
                    EditChildBranchDialog.render({ branchId: branch?.id });
            }}
        >
            <StyledText>{branch?.name || <>&nbsp;</>}</StyledText>
        </StyledImageBranchView>
    );
});

const StyledImageBranchView = styled.div<{ imageUrl?: string }>`
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 265px;
    margin: 8px 0;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(${(props) => props?.imageUrl}), no-repeat, center;
    background-size: cover;
    cursor: pointer;

    span {
        overflow-wrap: anywhere;
    }

    &:hover {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.7),
                rgba(0, 0, 0, 0.7)
            ),
            url(${(props) => props?.imageUrl}), no-repeat, center;
        background-size: cover;
    }
`;

const StyledText = styled.span`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
`;
