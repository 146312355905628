import { observer } from 'mobx-react-lite';
import { CreateCollateral200Response } from 'src/generated-api-client';
import { CollateralEvaluation } from 'src/pages/CollateralDetailsPage/Components/CollateralEvaluation/CollateralEvaluation';
import { CollateralInsurance } from 'src/pages/CollateralDetailsPage/Components/CollateralInsurance/CollateralInsurance';
import { CollateralOwnershipInfo } from 'src/pages/CollateralDetailsPage/Components/CollateralOwnershipInfo/CollateralOwnershipInfo';
import { EquipmentCollateralGeneralInfo } from 'src/pages/CollateralDetailsPage/Tabs/EquipmentCollateralTab/EquipmentCollateralGeneralInfo';

type CollateralGeneralInfoTabProps = {
    collateral?: CreateCollateral200Response;
};
export const EquipmentCollateralGeneralInfoTab = observer(
    ({ collateral }: CollateralGeneralInfoTabProps) => {
        return (
            <>
                <EquipmentCollateralGeneralInfo />
                <CollateralOwnershipInfo collateral={collateral} />
                <CollateralEvaluation />
                <CollateralInsurance />
            </>
        );
    },
);
