/* eslint-disable max-lines */
import { Button, Col, Form as AntForm, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CirclePlusIcon, ModalCloseIcon } from 'src/components/Icons/Icons';
import { N15Task } from 'src/generated-api-client';
import { TaskAssigneeSelect } from 'src/pages/TaskDetailsPage/Components/TaskAssigneeSelect';
import { TaskVariablesProps } from 'src/pages/TaskViewPage/TaskTypes';
import styled from 'styled-components';

export const DmtUserItemList = observer(
    ({ task }: TaskVariablesProps<N15Task>) => {
        return (
            <AntForm.List name="userIds">
                {(fields, { add, remove }) => {
                    return (
                        <StyledItem>
                            {fields.map((field, index) => {
                                return (
                                    <DmtUserItem
                                        key={field.key}
                                        index={index}
                                        parentPath={[field.name]}
                                        onRemove={() => {
                                            remove(field.name);
                                        }}
                                        task={task}
                                    />
                                );
                            })}
                            <Button
                                type="dashed"
                                icon={<CirclePlusIcon />}
                                onClick={() => {
                                    add?.();
                                }}
                            >
                                {t('Form.N15Task.AddItem', 'Add DMT User')}
                            </Button>
                        </StyledItem>
                    );
                }}
            </AntForm.List>
        );
    },
);

type DmtUserItemItemProps = {
    index: number;
    parentPath: Array<any>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onRemove?: () => void;
    task: N15Task;
};
const DmtUserItem = observer(
    ({ index, onRemove, task, parentPath }: DmtUserItemItemProps) => {
        return (
            <>
                <StyledHeader>
                    <StyledTitle level={4}>
                        {t('Form.N15Task.User', {
                            index: (index || 0) + 1,
                        })}
                    </StyledTitle>
                    <StyledRemoveButton
                        type="text"
                        icon={<ModalCloseIcon />}
                        onClick={() => {
                            onRemove?.();
                        }}
                        title={t('Form.N15Task.RemoveItem', 'Remove')}
                    />
                </StyledHeader>
                <StyledRow gutter={16}>
                    <Col xs={24}>
                        <TaskAssigneeSelect
                            name={[...parentPath]}
                            label={t(
                                'Task.Form.N15ChooseDmtUsers.AvailableMakers',
                            )}
                            assignees={task?.users}
                        />
                    </Col>
                </StyledRow>
            </>
        );
    },
);

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;

const StyledItem = styled.section`
    margin-bottom: 30px;
`;

const StyledRow = styled(Row)`
    padding: 0 0 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
`;

const StyledRemoveButton = styled(Button)`
    &:hover {
        svg {
            fill: #51688b;
        }
    }
`;

const StyledTitle = styled(Typography.Title)`
    &.ant-typography {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #4d6082;
    }
`;
