import { Form as AntdForm } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FileField } from 'src/components/Form/FileField';
import {
    FileFormats,
    FileSizeUnit,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { DocumentTemplateType } from 'src/generated-api-client';
import { DocumentTemplatesStore } from 'src/stores/DocumentTemplatesStore/DocumentTemplatesStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type DocumentTemplateUploadDialogFnProps = ModalRendererProps & {
    documentTemplateType?: DocumentTemplateType;
};

export const DocumentTemplateUploadDialogFn = observer(
    ({
        documentTemplateType,
        ...props
    }: DocumentTemplateUploadDialogFnProps) => {
        useEffect(() => {
            return () => {
                DocumentTemplatesStore.updateItemLoader.reset();
            };
        }, []);

        const [form] = AntdForm.useForm();

        const typeValue = AntdForm.useWatch('type', form);
        const isCSVTypeSelected =
            typeValue === DocumentTemplateType.RESTRICTIONS_LIST_SAMPLE;

        return (
            <Modal
                title={t('Dialog.DocumentTemplateUpload.Title')}
                onFinish={async ({ file, type }: any) => {
                    await DocumentTemplatesStore.upload(
                        type,
                        file?.[0]?.originFileObj,
                    );

                    DocumentTemplatesStore.loadList(false);
                }}
                isLoading={DocumentTemplatesStore.loadingInfo.isLoading}
                form={form}
                {...props}
            >
                <EnumSelectField
                    name="type"
                    label={t('Dialog.DocumentTemplateUpload.DocumentType')}
                    rules={[ValidationRules.required]}
                    initialValue={documentTemplateType}
                    disabled={Boolean(documentTemplateType)}
                    enumName="DOCUMENT_TEMPLATE_TYPE"
                />
                <FileField
                    name="file"
                    label={t('Dialog.DocumentTemplateUpload.UploadFile')}
                    accept={
                        isCSVTypeSelected ? FileFormats.csv : FileFormats.html
                    }
                    rules={[
                        ValidationRules.required,
                        ValidationRules.maxFileSize(10, FileSizeUnit.Mb),
                        ValidationRules.fileFormats([
                            FileFormats.csv,
                            FileFormats.html,
                        ]),
                    ]}
                />
            </Modal>
        );
    },
);

export const DocumentTemplateUploadDialog = addModalRenderer(
    DocumentTemplateUploadDialogFn,
);
