import { action, computed, observable } from 'mobx';
import { Router } from 'src/core/router/Router';
import { UrlHelper } from 'src/utils/UrlHelper';

type FilterOptions = {
    applyToSearchParams: boolean;
};

export class FilterCriteria<
    Filter extends Record<string, any> = any,
    FilterKeys extends string[] = any,
> {
    @observable sort?: string[];
    @observable filter: Filter;
    @observable defaultFilter: Filter;

    constructor(
        private filterKeys: FilterKeys,
        private options: FilterOptions = { applyToSearchParams: true },
        private initialFilterValue: Filter = {} as Filter,
    ) {
        this.defaultFilter = normalizeFilterValue(initialFilterValue);
        this.filter = this.defaultFilter;
    }

    @action setFilter(filter: Filter) {
        const normalizedFilter = normalizeFilterValue(filter);
        this.filter = normalizedFilter;
    }

    @action applyFilter(filter: Filter, options?: FilterOptions) {
        const normalizedFilter = normalizeFilterValue(filter);
        this.filter = { ...this.filter, ...normalizedFilter };
        const calculatedOptions = this.getOptions(options);
        if (calculatedOptions.applyToSearchParams) {
            Router.applySearchParams(filter);
        }
    }

    @action resetFilter(options?: FilterOptions) {
        this.filter = this.defaultFilter;
        const calculatedOptions = this.getOptions(options);
        const searchParams = this.getSearchParams();

        if (calculatedOptions.applyToSearchParams) {
            Router.setSearchParams(
                removeFilterFromSearchQuery(searchParams, this.filterKeys),
            );
        }
    }

    private getOptions(options = {} as FilterOptions) {
        return { ...this.options, ...options };
    }

    private getSearchParams() {
        return UrlHelper.parseSearchParams(window.location.search);
    }

    @action setFilterFromSearchQuery() {
        const searchParams = this.getSearchParams();
        if (Object.keys(searchParams).length) {
            this.applyFilter(searchParams as any);
        }
    }

    @computed get hasFilter() {
        return (
            Object.entries(this.filter)
                .map(([key, value]) => {
                    if (!this.filterKeys?.includes(key)) {
                        return false;
                    }

                    if (Array.isArray(value)) {
                        return value.length > 0;
                    }
                    if (typeof value === 'number') {
                        return true;
                    }

                    return Boolean(value);
                })
                .filter(Boolean).length > 0
        );
    }
}

function normalizeFilterValue(filter: Record<string, any>) {
    const entries = Object.entries(filter).map(([key, value]) => {
        if (isInvalid(value)) {
            return [key, undefined];
        }

        return [key, value];
    });

    return Object.fromEntries(entries);
}

function isInvalid(value: any) {
    if (typeof value === 'string') {
        return !value;
    }

    if (typeof value === 'object') {
        if (Array.isArray(value)) {
            return value.length === 0;
        }

        return !value;
    }

    if (typeof value === 'number') {
        return !Number.isFinite(value);
    }

    return false;
}

const removeFilterFromSearchQuery = (
    searchParams: Record<string, any>,
    filterKeys: string[],
) => {
    const keys = Object.entries(searchParams).filter(
        ([key]) => !filterKeys.includes(key),
    );

    return Object.fromEntries(keys);
};
