import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { InvoiceStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: InvoiceStatus;
};

export const LoanApplicationInvoiceStatus = observer(
    ({ status }: StatusProps) => {
        const statusInfo = getStatusInfo(status);

        return <StatusView statusInfo={statusInfo} />;
    },
);

const getStatusInfo = (status?: any) => {
    return (
        (status && statusInfoMap[status]) || {
            title: t(`Invoice.Status.${status}`),
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [InvoiceStatus.INITIAL]: {
        title: <Trans i18nKey="Invoice.Status.INITIAL" />,
        color: '#8b939b',
        backgroundColor: '#F5F6F7',
    },
    [InvoiceStatus.WAITING_FOR_LOAN_APPLICATION]: {
        title: <Trans i18nKey="Invoice.Status.WAITING_FOR_LOAN_APPLICATION" />,
        color: '#91796c',
        backgroundColor: '#F4F0DF',
    },
    [InvoiceStatus.APPROVED]: {
        title: <Trans i18nKey="Invoice.Status.APPROVED" />,
        color: '#0c1604',
        backgroundColor: '#d3f3bb',
    },
    [InvoiceStatus.CUSTOMER_CANCELED]: {
        title: <Trans i18nKey="Invoice.Status.CUSTOMER_CANCELED" />,
        color: '#91796c',
        backgroundColor: '#F4F0DF',
    },
    [InvoiceStatus.MERCHANT_CANCELED]: {
        title: <Trans i18nKey="Invoice.Status.MERCHANT_CANCELED" />,
        color: '#91796c',
        backgroundColor: '#F4F0DF',
    },
    [InvoiceStatus.EXPIRED]: {
        title: <Trans i18nKey="Invoice.Status.EXPIRED" />,
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
    [InvoiceStatus.REQUEST_TO_RETURN]: {
        title: <Trans i18nKey="Invoice.Status.REQUEST_TO_RETURN" />,
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
    [InvoiceStatus.RETURNED]: {
        title: <Trans i18nKey="Invoice.Status.RETURNED" />,
        color: '#f54753',
        backgroundColor: '#FFE4E4',
    },
};
