/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { getIndividualOriginationActions } from 'src/pages/IndividualDetailsPage/Components/IndividualOriginationActions.config';
import { IndividualDetailsViewModelClass } from 'src/pages/IndividualDetailsPage/IndividualDetailsViewModel';

export type IndividualOriginationCardProps = {
    viewModel: IndividualDetailsViewModelClass;
};

export const IndividualOriginationCard = observer(
    ({ viewModel }: IndividualOriginationCardProps) => {
        const otherInfo = viewModel?.otherInformation;

        return (
            <Card
                title={t(
                    'Individual.OtherInformation.Origination.Title',
                    'Origination',
                )}
                isLoading={viewModel?.otherInformationLoader.isLoading}
                menuActions={getIndividualOriginationActions(viewModel)}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.branchCode}
                            label={t(
                                'Individual.OtherInformation.Origination.BranchCode',
                                'Branch Code',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.branchManager}
                            label={t(
                                'Individual.OtherInformation.Origination.BranchManager',
                                'Branch Manager',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.customerGroup?.name}
                            label={t(
                                'Individual.OtherInformation.Origination.CustomerGroup',
                                'Customer Group',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.kycLevel}
                            label={t(
                                'Individual.OtherInformation.Origination.KYCLevel',
                                'KYC Level',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.relationshipManager}
                            label={t(
                                'Individual.OtherInformation.Origination.RelationshipManager',
                                'Relationship Manager',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.debtCollector}
                            label={t(
                                'Individual.OtherInformation.Origination.DebtCollector',
                                'Debt Collector',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.pep}
                            label={t(
                                'Individual.OtherInformation.Origination.PEP',
                                'PEP',
                            )}
                            format="yes-no"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.relationToNbfi}
                            label={t(
                                'Individual.OtherInformation.Origination.RelationToNBFI',
                                'Relation To NBFI',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="RELATION_TO_NBFI"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.hasMasterAgreement}
                            label={t(
                                'Individual.OtherInformation.Origination.MASigned',
                                'MA Signed',
                            )}
                            format="yes-no"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.maSignedUser}
                            label={t(
                                'Individual.OtherInformation.Origination.MASignedUser',
                                'MA Signed User',
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.createdAt}
                            label={t(
                                'Individual.OtherInformation.Origination.CreatedDate',
                                'Created Date',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.createdUser}
                            label={t(
                                'Individual.OtherInformation.Origination.CreatedUser',
                                'Created User',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.customerSegment}
                            label={t(
                                'Individual.OtherInformation.Origination.CustomerSegment',
                                'Customer Segment',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="CUSTOMER_SEGMENT"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.restrictionListType}
                            label={t(
                                'Individual.OtherInformation.Origination.CustomerList',
                                'Customer List',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="RESTRICTION_LIST_TYPE"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.lastUpdateAt}
                            label={t(
                                'Individual.OtherInformation.Origination.LastUpdatedDate',
                                'Last Updated Date',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.lastUpdatedUser}
                            label={t(
                                'Individual.OtherInformation.Origination.LastUpdatedUser',
                                'Last Updated User',
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={otherInfo?.vatCode}
                            label={t(
                                'Individual.OtherInformation.Origination.VatCode',
                                'VAT Code',
                            )}
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
