import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { BasePage } from 'src/components/BasePage/BasePage';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { BnplMonthlyIncomeRangeTab } from 'src/pages/Settings/Product/Tabs/BnplMonthlyIncomeRangeTab';
import { BnplRiskCoefficientTab } from 'src/pages/Settings/Product/Tabs/BnplRiskCoefficientTab';
import { SettingsTrustDefaultProductTab } from 'src/pages/Settings/Product/Tabs/TrustDefaultProductTab';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';

export const SettingsProductPage = observer(() => {
    const tabs: TabDefinition[] = [
        {
            title: t(
                'Page.SettingsProductPage.Tabs.TrustPledged',
                'Trust Pledged Default',
            ),
            tabKey: 'trust-pledged-default-product',
            Component: <SettingsTrustDefaultProductTab />,
            visible: AppUser.hasPermissions(
                UserPermission.LoanSettingsTrustDefaultProductRead,
            ),
        },
        {
            title: t(
                'Page.SettingsProductPage.Tabs.BnplRiskCoefficient',
                'BNPL Risk Coefficient',
            ),
            tabKey: 'bnpl-risk-coefficient',
            Component: <BnplRiskCoefficientTab />,
            visible: AppUser.hasPermissions(
                UserPermission.LoanSettingsBnplRiskCoefficientRead,
            ),
        },
        {
            title: t(
                'Page.SettingsProductPage.Tabs.BnplMonthlyIncomeRange',
                'BNPL Monthly Income Range',
            ),
            tabKey: 'bnpl-monthly-income-range',
            Component: <BnplMonthlyIncomeRangeTab />,
            visible: AppUser.hasPermissions(
                UserPermission.LoanSettingsBnplIncomeAmountRangeRead,
            ),
        },
    ];

    return (
        <BasePage
            title={t('Page.SettingsProductPage.Title', 'Product settings')}
        >
            <Tabs tabs={tabs} />
        </BasePage>
    );
});
