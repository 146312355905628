import { Col, Radio, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { AddressView } from 'src/components/AddressView/AddressView';
import { DataView } from 'src/components/DataView/DataView';
import {
    CustomerAddress,
    CustomerPhoneNumber,
    DebtCollection,
    SocialMediaLink,
} from 'src/generated-api-client';
import { DataInformationCard } from 'src/pages/CollectionDetailsPage/Components/DataInformationCard';
import styled from 'styled-components';

export type CollectionContactInformationCardProps = {
    collection?: DebtCollection;
};

export const CollectionContactInformationCard = observer(
    ({ collection }: CollectionContactInformationCardProps) => {
        const phoneNumbers = collection?.customer?.contactDetails
            ?.customerPhoneNumbers || [{} as CustomerPhoneNumber];
        const addresses = collection?.customer?.contactDetails
            ?.customerAddresses || [{} as CustomerAddress];
        const socialMediaLinks = collection?.customer?.contactDetails
            ?.socialMediaLinks || [{} as SocialMediaLink];
        const hasSocialMediaLinks = socialMediaLinks.length > 0;

        return (
            <DataInformationCard
                title={t('Page.CollectionDetailsPage.ContactInformation.Title')}
                hasData
                canUpdate={false}
            >
                <Row gutter={16}>
                    {phoneNumbers?.map(
                        (phoneNumber: CustomerPhoneNumber, index: number) => {
                            return (
                                <>
                                    <Col xs={8} key={phoneNumber.id}>
                                        <DataView
                                            value={phoneNumber.phoneNumber}
                                            label={t(
                                                'Page.CollectionDetailsPage.ContactInformation.PhoneNumber',
                                                {
                                                    index: index + 1,
                                                },
                                            )}
                                            format="tel"
                                        />
                                    </Col>
                                    <Col xs={4} key={phoneNumber.id}>
                                        <Radio
                                            defaultChecked={phoneNumber.isMain}
                                            disabled
                                        />
                                    </Col>
                                </>
                            );
                        },
                    )}
                    <Col xs={12}>
                        <DataView
                            value={collection?.customer?.contactDetails?.email}
                            label={t(
                                'Page.CollectionDetailsPage.ContactInformation.Email',
                            )}
                        />
                    </Col>
                </Row>

                {addresses?.map((address: CustomerAddress, index: number) => {
                    return (
                        <Row gutter={16} key={index}>
                            <Col xs={24}>
                                <DataView
                                    value={address}
                                    label={t(
                                        'Page.CollectionDetailsPage.ContactInformation.Address',
                                        'Address',
                                        {
                                            context: address.addressType,
                                        },
                                    )}
                                    format={() => (
                                        <AddressView address={address} short />
                                    )}
                                />
                            </Col>
                        </Row>
                    );
                })}

                {hasSocialMediaLinks && (
                    <>
                        <StyledTitle level={4}>
                            {t(
                                'Page.CollectionDetailsPage.ContactInformation.SocialAccounts',
                                'Social Accounts',
                            )}
                        </StyledTitle>

                        {socialMediaLinks.map((link: SocialMediaLink) => {
                            return (
                                <Row gutter={16}>
                                    <Col xs={6}>
                                        <DataView
                                            value={link.platformName}
                                            label={t(
                                                'Page.CollectionDetailsPage.ContactInformation.SocialAccounts.Platform',
                                                'Platform',
                                            )}
                                        />
                                    </Col>
                                    <Col xs={18}>
                                        <DataView
                                            value={link.profileLink}
                                            label={t(
                                                'Page.CollectionDetailsPage.ContactInformation.SocialAccounts.Link',
                                                'Link',
                                            )}
                                            format={(href: string) => {
                                                return (
                                                    <Link
                                                        to={{ pathname: href }}
                                                        target="_blank"
                                                    >
                                                        {link.profileName}
                                                    </Link>
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        })}
                    </>
                )}
            </DataInformationCard>
        );
    },
);

const StyledTitle = styled(Typography.Title)`
    margin-top: 20px;

    &.ant-typography {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #4d6082;
    }
`;
