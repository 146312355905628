import { Col, Form as AntForm, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { NewInsurance } from 'src/generated-api-client';
import { CollateralsStore } from 'src/stores/CollateralsStore/CollateralsStore';
import { InsuranceStore } from 'src/stores/InsuranceStore/InsuranceStore';
import { DateHelper } from 'src/utils/DateHelper';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

type CreateCollateralInsuranceDialogFnProps = ModalRendererProps<{
    collateralId: number;
}>;

const CreateCollateralInsuranceDialogFn = observer(
    ({ collateralId, ...props }: CreateCollateralInsuranceDialogFnProps) => {
        const [form] = AntForm.useForm();
        const registeredDate = AntForm.useWatch('registeredDate', form);
        const store = InsuranceStore.getInstance(Number(collateralId));

        useEffect(() => {
            Promise.all([
                store.getInsuranceCompanyOptions(),
                store.getInsuranceProductOptions(),
            ]);

            return () => {
                store.createItemLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            store.createItemLoader.errorData,
        );

        return (
            <Modal
                isLoading={store.createItemLoader.isLoading}
                form={form}
                title={t('Dialog.CreateCollateralInsurance.Title')}
                cancelText={t('Component.Modal.Btn.Back')}
                onFinish={async (values) => {
                    const convertedValues =
                        convertFormValuesToSubmitValues(values);

                    await store.create(convertedValues);
                    if (store.createItemLoader.hasError) {
                        return Promise.reject();
                    }
                    if (!store.createItemLoader.hasError) {
                        CollateralsStore.loadItem(collateralId);
                    }
                }}
                width={1000}
                formProps={{ fields }}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={8}>
                        <SelectField
                            name="insuranceProductId"
                            label={t(
                                'Dialog.CreateCollateralInsurance.InsuranceProductId',
                            )}
                            options={store.insuranceProductOptions}
                            loading={
                                store.insuranceProductOptionsLoader.isLoading
                            }
                        />
                    </Col>
                    <Col xs={8}>
                        <SelectField
                            name="insuranceCompanyId"
                            label={t(
                                'Dialog.CreateCollateralInsurance.InsuranceCompanyId',
                            )}
                            options={store.insuranceCompanyOptions}
                            loading={
                                store.insuranceCompanyOptionsLoader.isLoading
                            }
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <TextField
                            name="brokerage"
                            label={t(
                                'Dialog.CreateCollateralInsurance.Brokerage',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <NumberField
                            name="amount"
                            label={t('Dialog.CreateCollateralInsurance.Amount')}
                            thousandDivider
                            rules={[
                                ValidationRules.required,
                                ValidationRules.greaterThanZero,
                            ]}
                        />
                    </Col>
                    <Col xs={8}>
                        <DatePickerField
                            name="registeredDate"
                            label={t(
                                'Dialog.CreateCollateralInsurance.RegisteredDate',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={8}>
                        <DatePickerField
                            name="expireDate"
                            label={t(
                                'Dialog.CreateCollateralInsurance.ExpireDate',
                            )}
                            dependencies={['registeredDate']}
                            disabledDate={(date) => {
                                return date.isBefore(
                                    moment(registeredDate).startOf('day'),
                                );
                            }}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.dateIsAfter(
                                    moment(registeredDate).startOf('day'),
                                ),
                            ]}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const CreateCollateralInsuranceDialog = addModalRenderer(
    CreateCollateralInsuranceDialogFn,
);

const convertFormValuesToSubmitValues = (values: NewInsurance) => {
    return {
        ...values,
        registeredDate: DateHelper.formatISODate(values.registeredDate),
        expireDate: DateHelper.formatISODate(values.expireDate),
    } as NewInsurance;
};
