import { Form, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DocumentsViewModelClass } from 'src/components/DocumentsList/DocumentsViewModel';
import { ErrorField } from 'src/components/Form/ErrorField';
import { Modal } from 'src/components/Modal/Modal';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type DeleteFileDialogFnProps = ModalRendererProps<{
    viewModel: DocumentsViewModelClass;
    documentId: number;
    onDeleteSuccess?: () => void;
}>;

export const DeleteFileDialogFn = observer(
    ({
        viewModel,
        documentId,
        onDeleteSuccess,
        ...props
    }: DeleteFileDialogFnProps) => {
        const [form] = Form.useForm();

        const fields = FormHelper.getFieldDataItems(
            viewModel.documentsStore.removeItemLoader.errorData,
        );

        useEffect(() => {
            viewModel.resetDeleteLoadersToInitialState();

            return () => {
                viewModel.resetDeleteLoadersToInitialState();
            };
        }, []);

        return (
            <Modal
                title={t('Dialog.DeleteFile.Title')}
                okText={t('Dialog.DeleteFile.OkText')}
                isLoading={viewModel?.deleteDocumentLoadingInfo.isLoading}
                onFinish={async () => {
                    await viewModel?.deleteDocument(documentId);

                    if (viewModel?.deleteDocumentLoaderHasError) {
                        return Promise.reject();
                    }

                    if (!viewModel?.deleteDocumentLoaderHasError) {
                        onDeleteSuccess?.();
                    }
                }}
                form={form}
                formProps={{ fields }}
                {...props}
            >
                <StyledText>{t('Dialog.DeleteFile.Text')}</StyledText>
                <StyledErrorField name="documentId" />
            </Modal>
        );
    },
);

const StyledErrorField = styled(ErrorField)`
    margin-bottom: 10px;
`;

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 16px;
    color: #4d6082;
    height: 100px;
`;

export const DeleteFileDialog = addModalRenderer(DeleteFileDialogFn);
