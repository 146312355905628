import { ColumnsType } from 'antd/lib/table';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ListPage } from 'src/components/ListPage/ListPage';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    IdLocalizationEntry,
    IdNameEntry,
    LegalListEntry,
} from 'src/generated-api-client';
import { LegalEntitiesHeaderPanel } from 'src/pages/LegalEntitiesPage/LegalEntitiesHeaderPanel';
import { LegalEntitiesStore } from 'src/stores/LegalEntitiesStore/LegalEntitiesStore';
import { UrlHelper } from 'src/utils/UrlHelper';

export const LegalEntitiesPage = observer(() => {
    const { t } = useTranslation();

    return (
        <ListPage
            store={LegalEntitiesStore}
            columns={columns}
            header={
                <LegalEntitiesHeaderPanel
                    title={t('Page.LegalEntities.Header')}
                />
            }
        />
    );
});

export const columns: ColumnsType<LegalListEntry> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.LegalEntities.List.Id" />,
        render: (legalId: string) => {
            const targetUrl = UrlHelper.getTargetUrl(RoutePaths.legalDetails, {
                legalId,
            });

            return <Link to={targetUrl}>{legalId}</Link>;
        },
    },
    {
        key: 'companyName',
        dataIndex: 'companyName',
        title: <Trans i18nKey="Page.LegalEntities.List.CompanyName" />,
    },
    {
        key: 'industry',
        dataIndex: 'industry',
        title: <Trans i18nKey="Page.LegalEntities.List.Industry" />,
        render: (value: IdLocalizationEntry) => {
            return (value?.l10n as any)[i18next.language];
        },
    },
    {
        key: 'registrationNumber',
        dataIndex: 'registrationNumber',
        title: <Trans i18nKey="Page.LegalEntities.List.RegistrationNumber" />,
    },
    {
        key: 'ceo',
        dataIndex: 'ceo',
        title: <Trans i18nKey="Page.LegalEntities.List.CEO" />,
        render: (value: IdNameEntry) => {
            return (
                <Link to={EntityLinks.individuals.itemDetails(value?.id)}>
                    {value?.name}
                </Link>
            );
        },
    },
    {
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
        title: <Trans i18nKey="Page.LegalEntities.List.PhoneNumber" />,
    },
];
