import { TabPaneProps, Tabs as AntTabs, TabsProps as AntTabsProps } from 'antd';
import React from 'react';
import { useSearchParams } from 'src/hooks/useSeachParams';
import styled from 'styled-components';

const { TabPane } = AntTabs;

export type TabDefinition = Omit<TabPaneProps, 'tab'> & {
    tabKey: string;
    title: React.ReactNode;
    Component: React.ReactNode;
    visible?: boolean;
};

type TabsProps = AntTabsProps & {
    tabs: TabDefinition[];
    // eslint-disable-next-line react/require-default-props
    urlSearchActiveTabKey?: string;
};

const DEFAULT_TAB_SEARCH_KEY = 'tab';

export const Tabs = ({
    tabs,
    urlSearchActiveTabKey = DEFAULT_TAB_SEARCH_KEY,
    ...props
}: TabsProps) => {
    const searchParams = useSearchParams();
    const [firstTab] = tabs.filter((tab) => tab.visible !== false);
    const urlSearchTabKey = searchParams.params[
        urlSearchActiveTabKey
    ] as string;
    const activeTabKey = urlSearchTabKey || firstTab?.tabKey;

    const tabPanes = tabs
        .filter((tab) => tab.visible !== false)
        .map(({ title, tabKey, Component, ...paneProps }) => {
            return (
                <TabPane
                    {...paneProps}
                    key={tabKey}
                    tab={<StyledTitle data-test={tabKey}>{title}</StyledTitle>}
                >
                    {Component}
                </TabPane>
            );
        });

    return (
        <AntTabs
            {...props}
            destroyInactiveTabPane
            defaultActiveKey={activeTabKey}
            activeKey={activeTabKey}
            onChange={(activeKey: string) => {
                searchParams.applySearchParams({
                    [urlSearchActiveTabKey]: activeKey,
                });
            }}
        >
            {tabPanes}
        </AntTabs>
    );
};

const StyledTitle = styled.div`
    font-size: 16px;
`;
