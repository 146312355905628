import { action } from 'mobx';
import {
    AssignLoanRequest,
    ChangeBankStatementStatusForm,
    LoanDetails,
} from 'src/generated-api-client';
import { transactionsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type BankStatementActionsFilter = {
    customerName?: string;
    registrationNumber?: string;
    phone?: string;
    contractId?: string;
    accountNumber?: string;
    repaymentAmount?: number;
};

const BankStatementActionsFilterKeys: Array<keyof BankStatementActionsFilter> =
    [
        'customerName',
        'registrationNumber',
        'phone',
        'contractId',
        'accountNumber',
        'repaymentAmount',
    ];

export class BankStatementActionsStoreClass extends BasicStore<
    LoanDetails,
    BankStatementActionsFilter
> {
    constructor(private bankStatementId: number) {
        super();
    }

    api: BasicStoreApi<LoanDetails> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.findTransactionLoans({
                    ...this.listParams,
                    id: this.bankStatementId,
                }),
            );
        },
    };

    filterCriteria = new FilterCriteria<BankStatementActionsFilter>(
        BankStatementActionsFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<LoanDetails>('id', 'descend');

    assignToLoanLoader = new AsyncOperationWithStatus(
        (data: AssignLoanRequest) =>
            RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.assignToLoan({
                    id: this.bankStatementId,
                    assignLoanRequest: data,
                }),
            ),
    );

    @action async assignToLoan(assignLoanRequest: AssignLoanRequest) {
        await this.assignToLoanLoader.call(assignLoanRequest);
    }

    changeStatusLoader = new AsyncOperationWithStatus(
        (data: ChangeBankStatementStatusForm) =>
            RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.changeStatus({
                    id: this.bankStatementId,
                    changeBankStatementStatusForm: data,
                }),
            ),
    );

    @action async changeStatus(form: ChangeBankStatementStatusForm) {
        await this.changeStatusLoader.call(form);
    }
}

export const BankStatementActionsStore = new EntityProvider(
    BankStatementActionsStoreClass,
);
