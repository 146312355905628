import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { IdEntry } from 'src/generated-api-client';

export type TaskLinkViewProps = {
    loanApplication?: IdEntry;
    individual?: IdEntry;
    collaterals?: IdEntry[];
};

export const TaskLinkView = observer(
    ({ loanApplication, collaterals, individual }: TaskLinkViewProps) => {
        if (
            (!loanApplication &&
                !individual &&
                Array.isArray(collaterals) &&
                collaterals?.length === 0) ||
            (!loanApplication && !individual && !Array.isArray(collaterals))
        ) {
            return null;
        }

        return (
            <Card title={t('Component.TaskLinkView.Title')}>
                <div>
                    {loanApplication?.id && (
                        <Link
                            to={EntityLinks.application.itemDetails(
                                loanApplication.id,
                            )}
                        >
                            {t('Component.TaskLinkView.Application', {
                                applicationId: loanApplication?.id,
                            })}
                        </Link>
                    )}
                </div>

                <div>
                    {collaterals?.map((collateral) => {
                        return (
                            <div>
                                <Link
                                    to={EntityLinks.collaterals.itemDetails(
                                        collateral?.id,
                                    )}
                                >
                                    {t('Component.TaskLinkView.Collateral', {
                                        collateralId: collateral?.id,
                                    })}
                                </Link>
                            </div>
                        );
                    })}
                </div>

                <div>
                    {individual?.id && (
                        <Link
                            to={EntityLinks.individuals.itemDetails(
                                individual?.id,
                            )}
                        >
                            {t('Component.TaskLinkView.Individual', {
                                individualId: individual?.id,
                            })}
                        </Link>
                    )}
                </div>
            </Card>
        );
    },
);
