import { computed } from 'mobx';
import { Legal, LegalListEntry, NewLegal } from 'src/generated-api-client';
import { legalsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LegalEntitiesFilter = {
    id?: number;
    companyName?: string;
    industryIds?: number[];
    registrationNumber?: string;
    ceoName?: string;
    phoneNumber?: string;
};

const LegalEntitiesFilterKeys: Array<keyof LegalEntitiesFilter> = [
    'id',
    'companyName',
    'industryIds',
    'registrationNumber',
    'ceoName',
    'phoneNumber',
];

export class LegalEntitiesStoreClass extends BasicStore<
    LegalListEntry,
    LegalEntitiesFilter,
    any,
    NewLegal,
    Legal
> {
    api: BasicStoreApi<LegalListEntry, any, NewLegal, Legal> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                legalsApi.findAllLegals(this.listParams),
            );
        },
        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                legalsApi.getLegal({ id }),
            );
        },
        createItem: async (data: NewLegal) => {
            await RequestHelper.unwrapFromAxiosPromise(
                legalsApi.createLegal({ newLegal: data }),
            );
        },
    };

    filterCriteria = new FilterCriteria<LegalEntitiesFilter>(
        LegalEntitiesFilterKeys,
    );

    pager?: Pager | undefined = new Pager();
    sorter?: Sorter | undefined = new Sorter<LegalListEntry>('id', 'descend');

    @computed get isItemLoading() {
        return this.itemLoader.isLoading;
    }
}

export const LegalEntitiesStore = new LegalEntitiesStoreClass();
