import { t } from 'i18next';
import {
    LoanPaymentDateChangingPrecondition,
    LoanPaymentDateChangingProposal,
} from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProposePaymentDateChangingActionStoreClass extends BasicActionStore<
    LoanPaymentDateChangingPrecondition,
    LoanPaymentDateChangingProposal
> {
    constructor(private loanId: number) {
        super();
    }

    okMessage = t('Dialog.Restructuring.Message.SuccessCreate');

    api: BasicActionStoreApi<
        LoanPaymentDateChangingPrecondition,
        LoanPaymentDateChangingProposal
    > = {
        load: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getPaymentDateChanging({ id: this.loanId }),
            );
        },

        ok: async (data?: LoanPaymentDateChangingProposal) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.proposePaymentDateChanging({
                    id: this.loanId,
                    loanPaymentDateChangingProposal: data!,
                }),
            );
        },
    };
}

export const ProposePaymentDateChangingActionStore = new EntityProvider(
    ProposePaymentDateChangingActionStoreClass,
);
