import { Col, Row, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { LoanSchedulePrincipal } from 'src/generated-api-client';
import { ReviewActionDialogComponent } from 'src/pages/LoanDetailsPage/Components/ReviewActionDialogComponent';
import { ReviewPaymentAmountChangingActionStore } from 'src/stores/LoanActionsStores/ReviewPaymentAmountChangingActionStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const { Text } = Typography;

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId: number;
}>;

export const ReviewPaymentAmountChangingDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const store = ReviewPaymentAmountChangingActionStore.getInstance(
            Number(loanId),
        );
        const title = t('Dialog.ReviewPaymentAmountChanging.Title');

        return (
            <ReviewActionDialogComponent
                loanId={loanId}
                store={store}
                title={title}
                {...props}
            >
                <Row gutter={16}>
                    <Col xs={24}>
                        <StyledText>
                            {t('Dialog.ReviewPaymentAmountChanging.Text')}
                        </StyledText>
                    </Col>
                    <Col xs={24}>
                        <StyledDataView
                            value={store.initialValues?.currentPrincipal}
                            label={t(
                                'Dialog.ReviewPaymentAmountChanging.CurrentDebt',
                            )}
                            format="money"
                            align="horizontal"
                        />
                    </Col>
                    <Col xs={24}>
                        <StyledListView
                            dataSource={
                                store.initialValues?.loanSchedulePrincipals
                            }
                            columns={loanScheduleColumns}
                            rowKey="periodId"
                        />
                    </Col>
                </Row>
            </ReviewActionDialogComponent>
        );
    },
);

export const ReviewPaymentAmountChangingDialog = addModalRenderer(
    ReviewPaymentAmountChangingDialogFn,
);

export const loanScheduleColumns: ColumnType<LoanSchedulePrincipal>[] = [
    {
        title: (
            <Trans i18nKey="Dialog.ReviewPaymentAmountChanging.PaymentDate" />
        ),
        key: 'paymentDate',
        dataIndex: 'paymentDate',
        render: (value) => {
            return <DataView value={value} format="date" hideLabel />;
        },
    },
    {
        title: <Trans i18nKey="Dialog.ReviewPaymentAmountChanging.Principal" />,
        key: 'principal',
        dataIndex: 'principal',
        render: (value) => {
            return <DataView value={value} format="money" hideLabel />;
        },
    },
];

const StyledText = styled(Text)`
    display: block;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    color: #4d6082;
`;

const StyledListView = styled(ListView)`
    padding: 0 0 20px;
`;

const StyledDataView = styled(DataView)`
    .app-data-view__label {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #4d6082;
    }

    .app-data-view__value {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #2e3e5c;
    }
`;
