import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { SelectField } from 'src/components/Form/SelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { useEnumsLoader } from 'src/hooks/useEnumsLoader';
import { StatusActionStore } from 'src/stores/LoanActionsStores/StatusActionStore';
import { EnumName, EnumStore } from 'src/stores/Resources/EnumStore/EnumStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

const enumsToLoad: EnumName[] = ['LOAN_STATUS'];

type RestructuringsDialogFnProps = ModalRendererProps<{
    loanId?: number;
}>;
export const EnableManualStatusDialogFn = observer(
    ({ loanId, ...props }: RestructuringsDialogFnProps) => {
        const isEnumsLoading = useEnumsLoader(enumsToLoad);

        const statusStore = StatusActionStore.getInstance(Number(loanId));
        useEffect(() => {
            statusStore.loadAvailableManualStatuses();

            return () => {
                statusStore.enableManualStatusLoader.reset();
                statusStore.availableManualStatusesLoader.reset();
            };
        }, []);

        const isLoading =
            statusStore.availableManualStatusesLoader.isLoading ||
            statusStore.enableManualStatusLoader.isLoading ||
            isEnumsLoading;

        const fields = FormHelper.getFieldDataItems(
            statusStore?.enableManualStatusLoader.errorData,
        );
        const title = t(
            'Dialog.EnableManualLoanStatus.Title',
            'Enable manual loan status',
        );

        const options = useMemo(() => {
            return (
                statusStore.availableManualStatuses?.map((status) => ({
                    value: status,
                    label: EnumStore.getInstance(
                        'LOAN_STATUS',
                    ).getLocalizedEnum(status ?? ''),
                })) || []
            );
        }, [statusStore.availableManualStatuses]);

        return (
            <Modal
                {...props}
                isLoading={isLoading}
                onFinish={async (values) => {
                    await statusStore.enableManualStatus(values);
                    if (statusStore.enableManualStatusLoader.hasError) {
                        return Promise.reject();
                    }
                }}
                formProps={{ fields }}
                title={title}
            >
                <SelectField
                    label={t('Dialog.EnableManualLoanStatus.Status', 'Status')}
                    name="manualLoanStatus"
                    options={options}
                    rules={[ValidationRules.required]}
                />
            </Modal>
        );
    },
);

export const EnableManualStatusDialog = addModalRenderer(
    EnableManualStatusDialogFn,
);
