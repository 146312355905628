import {
    ChildBranch,
    ChildBranchListEntry,
    ChildBranchStatus,
    ChildBranchUpdate,
    NewChildBranch,
} from 'src/generated-api-client';
import { brunchesApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

const MAX_INTEGER_NUMBER = 2147483647;

export type ChildBranchesFilter = {
    parentBranchId?: number;
    status?: ChildBranchStatus;
};

const ChildBranchesFilterKeys: Array<keyof ChildBranchesFilter> = [
    'parentBranchId',
    'status',
];

export class ChildBranchesStoreClass extends BasicStore<
    ChildBranchListEntry,
    ChildBranchesFilter,
    ChildBranchUpdate,
    NewChildBranch,
    ChildBranch
> {
    api: BasicStoreApi<
        ChildBranchListEntry,
        ChildBranchUpdate,
        NewChildBranch,
        ChildBranch
    > = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                brunchesApi.findAllChildBranches({
                    ...this.listParams,
                    page: undefined,
                    size: MAX_INTEGER_NUMBER,
                }),
            );
        },

        createItem: async (data) => {
            await RequestHelper.unwrapFromAxiosPromise(
                brunchesApi.createChildBranch({ newChildBranch: data }),
            );
        },

        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                brunchesApi.getChildBranch({ id }),
            );
        },

        updateItem: async (id: number | string, data) => {
            await RequestHelper.unwrapFromAxiosPromise(
                brunchesApi.updateChildBranch({ childBranchUpdate: data }),
            );
        },
    };

    filterCriteria = new FilterCriteria<ChildBranchesFilter>(
        ChildBranchesFilterKeys,
    );

    sorter?: Sorter | undefined = new Sorter<ChildBranchListEntry>(
        'id',
        'descend',
    );
}

export const ChildBranchesStore = new ChildBranchesStoreClass();
