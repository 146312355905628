import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import { ArrowDownIcon } from 'src/components/Icons/Icons';
import { getBranchActions } from 'src/pages/BranchesPage/Components/BranchActions.config';

export const BranchActions = observer(() => {
    const branchActions = getBranchActions();
    const menuActions = branchActions.filter((action) => !action.disabled);
    const hideActions = menuActions.length === 0;

    if (hideActions) {
        return null;
    }

    return (
        <Dropdown items={menuActions}>
            <Button type="primary" icon={<ArrowDownIcon />}>
                {t('Page.Branches.Actions')}
            </Button>
        </Dropdown>
    );
});
