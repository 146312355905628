import { Checkbox, Form, FormItemProps } from 'antd';
import React from 'react';

export type CheckboxFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
};

export const CheckboxField = ({
    name,
    disabled,
    ...props
}: CheckboxFieldProps) => {
    return (
        <Form.Item name={name} {...props}>
            <Checkbox disabled={disabled} data-test={name} />
        </Form.Item>
    );
};
