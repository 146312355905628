import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { Modal } from 'src/components/Modal/Modal';
import { IndividualBusinessIncome } from 'src/generated-api-client';
import { IncomeStatus } from 'src/pages/IndividualDetailsPage/Components/IncomeStatus';
import { IndividualBusinessIncomeStore } from 'src/stores/IndividualBusinessIncomeStore/IndividualBusinessIncomeStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type BusinessIncomeDetailsDialogFnProps = ModalRendererProps<{
    individualId?: number;
}>;

export const BusinessIncomeDetailsDialogFn = observer(
    ({ individualId, ...props }: BusinessIncomeDetailsDialogFnProps) => {
        const store = IndividualBusinessIncomeStore.getInstance(
            Number(individualId),
        );
        const { isLoading } = store.itemLoader;
        const details: IndividualBusinessIncome = store.incomeDetails;

        useEffect(() => {
            store.loadItem();

            return () => {
                store.itemLoader.reset();
            };
        }, [individualId]);

        return (
            <Modal
                title={t('Dialog.BusinessIncomeDetails.Title')}
                footer={null}
                maskClosable={false}
                width={1000}
                isLoading={isLoading}
                {...props}
            >
                <StyledDataView
                    value={details?.monthlyIncome}
                    label={t('Dialog.BusinessIncome.MonthlyIncome')}
                    format="money"
                    align="horizontal"
                />
                <StyledDataView
                    value={details?.checkStatus}
                    label={t('Dialog.BusinessIncome.Status')}
                    align="horizontal"
                    format={(value) => {
                        return <IncomeStatus status={value} />;
                    }}
                />
                <StyledRow gutter={16}>
                    <Col xs={8}>
                        <DataView
                            value={details?.bankCode}
                            label={t('Dialog.BusinessIncome.BankCode')}
                            format="string"
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={details?.bankAccount}
                            label={t(
                                'Dialog.BusinessIncome.BankAccount',
                                'Bank Account',
                            )}
                            format="string"
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={details?.currency}
                            label={t('Dialog.BusinessIncome.Currency')}
                            format={(value) => (
                                <EnumView enumName="CURRENCY" value={value} />
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={details?.workingMonthsForLast2Years}
                            label={t('Dialog.BusinessIncome.WorkingMonths')}
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={details?.monthlyIncome}
                            label={t('Dialog.BusinessIncome.MonthlyIncome')}
                            format="money"
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={details?.continuousEmploymentInMonths}
                            label={t(
                                'Dialog.BusinessIncome.ContinuousEmploymentInMonths',
                            )}
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={details?.incomeAmountEnough}
                            label={t(
                                'Dialog.BusinessIncome.IsIncomeAmountEnough',
                            )}
                            format="yes-no"
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={details?.incomeAmountStable}
                            label={t(
                                'Dialog.BusinessIncome.IsIncomeAmountStable',
                            )}
                            format="yes-no"
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={details?.incomeAmountEnoughForLast2Months}
                            label={t(
                                'Dialog.BusinessIncome.IsIncomeAmountEnoughForLast2Months',
                            )}
                            format="yes-no"
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={details?.dropAvgLast2Months}
                            label={t(
                                'Dialog.BusinessIncome.IsDropAvgLast2Months',
                            )}
                            format="yes-no"
                        />
                    </Col>
                </StyledRow>
            </Modal>
        );
    },
);

export const BusinessIncomeDetailsDialog = addModalRenderer(
    BusinessIncomeDetailsDialogFn,
);

const StyledDataView = styled(DataView)`
    justify-content: space-between;
    align-items: baseline;
    width: 320px;

    .app-data-view__label,
    .app-data-view__value {
        font-size: 18px;
        color: #4d6082;
    }
`;

const StyledRow = styled(Row)`
    margin-top: 32px;
`;
