import { OfferExclusionUpdate } from 'src/generated-api-client';
import { productsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LoanProductOfferExclusionsStoreClass extends BasicStore<
    any,
    any,
    OfferExclusionUpdate
> {
    api: BasicStoreApi<any, OfferExclusionUpdate> = {
        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.updateProduct({
                    id: Number(id),
                    updateProductRequest: {
                        ...data,
                        type: 'OfferExclusionUpdate',
                    } as OfferExclusionUpdate,
                }),
            );
        },
    };
}

export const LoanProductOfferExclusionsStore =
    new LoanProductOfferExclusionsStoreClass();
