import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TextAreaField } from 'src/components/Form/TextAreaField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { TaskDecisionType } from 'src/generated-api-client';

export const N21AddBankStatementTaskVariables = observer(() => {
    return (
        <Row gutter={[16, 16]}>
            <HiddenField
                name="type"
                initialValue={TaskDecisionType.N2_1_APPROVE}
            />
            <Col xs={8}>
                <TextField
                    name="bankStatementStatus"
                    label={t(
                        'Task.Form.N21AddBankStatementTask.BankStatementStatus',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={8}>
                <TextField
                    name="bankStatementQualification"
                    label={t(
                        'Task.Form.N21AddBankStatementTask.BankStatementQualification',
                    )}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={8}>
                <TextField
                    name="located"
                    label={t('Task.Form.N21AddBankStatementTask.Located')}
                    rules={[ValidationRules.required]}
                />
            </Col>
            <Col xs={24}>
                <TextAreaField
                    name="comment"
                    label={t('Task.Form.N21AddBankStatementTask.Comment')}
                />
            </Col>
        </Row>
    );
});
