import { t } from 'i18next';
import { CreateCompanyDialog } from 'src/pages/Settings/CustomerGroups/CustomerGroupCompaniesPage/Dialogs/CreateCompanyDialog';
import { DeleteCustomerGroupDialog } from 'src/pages/Settings/CustomerGroups/CustomerGroupCompaniesPage/Dialogs/DeleteCustomerGroupDialog';
import { UpdateCustomerGroupDialog } from 'src/pages/Settings/CustomerGroups/CustomerGroupCompaniesPage/Dialogs/UpdateCustomerGroupDialog';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { ActionDefinition } from 'src/utils/UserActionsHelper';

export const getGroupCompaniesActions = (
    groupId: string,
): Array<ActionDefinition> => {
    const canEdit = AppUser.hasPermissions(
        UserPermission.CustomerSettingsSegmentationEdit,
    );

    return [
        {
            key: 'add-company',
            label: t('GroupCompanies.Actions.AddCompany'),
            onClick: () => {
                CreateCompanyDialog.render({ groupId });
            },
            disabled: !canEdit,
        },
        {
            key: 'edit-group',
            label: t('GroupCompanies.Actions.EditGroup'),
            onClick: () => {
                UpdateCustomerGroupDialog.render({ groupId });
            },
            disabled: !canEdit,
        },
        {
            key: 'delete-group',
            label: t('GroupCompanies.Actions.DeleteGroup'),
            onClick: () => {
                DeleteCustomerGroupDialog({ groupId });
            },
            disabled: !canEdit,
        },
    ];
};
