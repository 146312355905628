import { t } from 'i18next';
import {
    LoanPaymentAmountChangingPrecondition,
    LoanPaymentAmountChangingProposal,
} from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicActionStore } from 'src/utils/mobx/BasicActionStore/BasicActionStore';
import { BasicActionStoreApi } from 'src/utils/mobx/BasicActionStore/BasicActionStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProposePaymentAmountChangingActionStoreClass extends BasicActionStore<
    LoanPaymentAmountChangingPrecondition,
    LoanPaymentAmountChangingProposal
> {
    constructor(private loanId: number) {
        super();
    }

    okMessage = t('Dialog.Restructuring.Message.SuccessCreate');

    api: BasicActionStoreApi<
        LoanPaymentAmountChangingPrecondition,
        LoanPaymentAmountChangingProposal
    > = {
        load: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getPaymentAmountChanging({ id: this.loanId }),
            );
        },

        ok: async (data?: LoanPaymentAmountChangingProposal) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loansApi.proposePaymentAmountChanging({
                    id: this.loanId,
                    loanPaymentAmountChangingProposal: data!,
                }),
            );
        },
    };
}

export const ProposePaymentAmountChangingActionStore = new EntityProvider(
    ProposePaymentAmountChangingActionStoreClass,
);
