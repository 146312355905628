/* eslint-disable max-lines */
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { N13IndividualCoBorrower } from 'src/generated-api-client';
import { CoBorrowersStatus } from 'src/pages/LoanDetailsPage/Components/CoBorrowers/CoBorrowersStatus';
import { CoBorrowerActions } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/CoBorrowerActions';
import { IndividualCoBorrowerExternalActions } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/Components/IndividualCoBorrowerExternalActions';
import { N13TaskViewModelClass } from 'src/pages/TaskDetailsPage/N13AddEstimatedConditionsTask/N13TaskVievModel';
import { UrlHelper } from 'src/utils/UrlHelper';

export type CoBorrowersCardProps = {
    viewModel: N13TaskViewModelClass;
};

export const IndividualCoBorrowersCard = observer(
    ({ viewModel }: CoBorrowersCardProps) => {
        const task = viewModel.currentTask || {};
        const { individualCoBorrowers } = task;

        return (
            <Card
                title={t(
                    'Page.N13AddEstimatedConditionsTask.CoBorrowers.IndividualTitle',
                )}
                extra={
                    <IndividualCoBorrowerExternalActions
                        viewModel={viewModel}
                    />
                }
            >
                <ListView
                    dataSource={individualCoBorrowers}
                    columns={getColumns(viewModel)}
                />
            </Card>
        );
    },
);

const getColumns = (
    viewModel: N13TaskViewModelClass,
): ColumnsType<N13IndividualCoBorrower> => {
    return [
        {
            key: 'coBorrowerId',
            dataIndex: 'coBorrowerId',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.List.Relationship" />
            ),
            render(value) {
                return (
                    <Link
                        to={UrlHelper.getTargetUrl(
                            RoutePaths.individualDetails,
                            {
                                individualId: value,
                            },
                        )}
                    >
                        {value}
                    </Link>
                );
            },
        },
        {
            key: 'lastName',
            dataIndex: 'lastName',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.List.LastName" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'firstName',
            dataIndex: 'firstName',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.List.FirstName" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'registrationNumber',
            dataIndex: 'registrationNumber',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.List.RegistrationNumber" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'relationshipType',
            dataIndex: 'relationshipType',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.List.RelationshipType" />
            ),
            render: (value) => {
                return <DataView value={value} format="string" hideLabel />;
            },
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.List.PhoneNumber" />
            ),
            render: (value) => {
                return <DataView value={value} hideLabel />;
            },
        },
        {
            key: 'kycLevel',
            dataIndex: 'kycLevel',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.List.kycLevel" />
            ),
            render: (value) => {
                return (
                    <DataView
                        value={value}
                        hideLabel
                        format={(value) => (
                            <EnumView
                                enumName="CUSTOMER_KYC_LEVEL"
                                value={value}
                            />
                        )}
                    />
                );
            },
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: (
                <Trans i18nKey="Page.N13AddEstimatedConditionsTask.List.Status" />
            ),
            render(value) {
                return <CoBorrowersStatus status={value} />;
            },
        },
        {
            key: 'actions',
            render: (_, record) => {
                return (
                    <CoBorrowerActions
                        coBorrower={record}
                        viewModel={viewModel}
                    />
                );
            },
        },
    ];
};
