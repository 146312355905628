import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { SelectField } from 'src/components/Form/SelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    ColumnSettings,
    SettingsTable,
} from 'src/components/SettingsTable/SettingsTable';
import {
    ProductType,
    SettingType,
    TrustDefaultProductRow,
} from 'src/generated-api-client';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { ProductLinesStore } from 'src/stores/ProductLinesStore/ProductLinesStore';
import { TableSettingsStoreClass } from 'src/stores/Settings/TableSettingsStoreClass';
import styled from 'styled-components';

const tableStore = new TableSettingsStoreClass<TrustDefaultProductRow>(
    SettingType.TRUST_DEFAULT_PRODUCT,
);

const renderSelectField = (valuePath: any) => {
    return (
        <StyledSelectField
            name={valuePath}
            rules={[ValidationRules.required]}
            options={ProductLinesStore.currentListAsOptions}
        />
    );
};

const columnSettings: ColumnSettings[] = [
    {
        key: 'TRUST_DEFAULT_PRODUCT_SETTING',
        title: (colDef) => {
            return t(
                'Page.SettingsTrustDefaultProduct.Table.DefaultProduct',
                colDef.payload,
            );
        },
        render: (data) => {
            return t(
                `Page.SettingsTrustDefaultProduct.Table.Row.DefaultProduct.${data}`,
            );
        },
        width: 170,
    },
    {
        key: 'PRODUCT_LINE_ID',
        title: () => {
            return t('Page.SettingsTrustDefaultProduct.Table.ProductLineId');
        },
        renderEdit: (valuePath) => renderSelectField(valuePath),
        editable: true,
        width: 205,
    },
];

export const SettingsTrustDefaultProductTab = observer(() => {
    useEffect(() => {
        ProductLinesStore.filterCriteria.setFilter({
            productTypes: [ProductType.TRUST],
        });
        ProductLinesStore.loadList();

        return () => {
            ProductLinesStore.filterCriteria.resetFilter();
            ProductLinesStore.listLoader.reset();
        };
    }, []);

    return (
        <SettingsTable
            tableStore={tableStore}
            columnSettings={columnSettings}
            hasPermission={AppUser.hasPermissions(
                UserPermission.LoanSettingsTrustDefaultProductEdit,
            )}
        />
    );
});

const StyledSelectField = styled(SelectField)`
    margin: 0;
    min-width: 60px;
`;
