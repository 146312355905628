import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { N110Task } from 'src/generated-api-client';

type N110TaskCCConditionsProps = {
    currentTask?: N110Task;
};
export const CreditCommitteeConditions = observer(
    ({ currentTask }: N110TaskCCConditionsProps) => {
        const { creditCommitteeConditions } = currentTask || ({} as N110Task);

        return (
            <Card
                title={t(
                    'Task.Form.N110TaskApproveConditions.CCConditions.Title',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={creditCommitteeConditions?.createdAt}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.CCConditions.ApprovedDate',
                            )}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={creditCommitteeConditions?.amount}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.CCConditions.Amount',
                            )}
                            format="money"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                creditCommitteeConditions?.monthlyInterestRate
                            }
                            label={t(
                                'Task.Form.N110TaskApproveConditions.CCConditions.InterestRate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={creditCommitteeConditions?.term}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.CCConditions.Term',
                            )}
                            format="number-int"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={
                                creditCommitteeConditions?.firstRepaymentDate
                            }
                            label={t(
                                'Task.Form.N110TaskApproveConditions.CCConditions.FirstRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                creditCommitteeConditions?.secondRepaymentDate
                            }
                            label={t(
                                'Task.Form.N110TaskApproveConditions.CCConditions.SecondRepaymentDate',
                            )}
                            format="date"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={
                                creditCommitteeConditions?.repaymentFrequency
                            }
                            label={t(
                                'Task.Form.N110TaskApproveConditions.CCConditions.RepaymentFrequency',
                            )}
                            format={(value) => (
                                <EnumView
                                    enumName="REPAYMENT_FREQUENCY"
                                    value={value}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={creditCommitteeConditions?.comment}
                            label={t(
                                'Task.Form.N110TaskApproveConditions.LaatUserConditions.CSComment',
                            )}
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
