import { action, computed, observable } from 'mobx';
import {
    AuthorityHierarchy,
    AuthorityListEntry,
} from 'src/generated-api-client';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';

export type AuthorityEntry = AuthorityListEntry & {
    isTouched?: boolean;
    entity?: string;
};

// TODO: must be deleted when connecting Keycloak

export class RoleAuthoritiesViewModelClass {
    @observable currentAuthorities?: Map<AuthorityHierarchy, AuthorityEntry> =
        new Map();

    @computed get activeAuthoritiesDependencies() {
        return this.currentAuthorities;
    }

    @computed get authoritiesGroupedByEntity() {
        return Object.entries(RolesStore.authoritiesGroupedByEntity);
    }

    @action setAuthorityData(key: AuthorityHierarchy, value: AuthorityEntry) {
        return this.currentAuthorities?.set(key, value);
    }

    @action isDisabledAuthority(authority?: AuthorityEntry) {
        const hasActiveAuthority =
            authority?.authority &&
            this.currentAuthorities?.get(authority?.authority);

        const hasEnablesGroupAuthorities = this.getCurrentAuthorities.some(
            (currentAuthority) => {
                return currentAuthority.enablesAuthorities.includes(
                    authority?.authority,
                );
            },
        );

        if (hasEnablesGroupAuthorities) {
            return true;
        }

        return hasActiveAuthority && !hasActiveAuthority?.isTouched;
    }

    @action isDisabledGroupAuthorities(groupKey?: string) {
        let isDisabledGroup = false;

        const authoritiesNotCurrentGroup = this.getCurrentAuthorities.filter(
            (item) => item.entity !== groupKey,
        );

        authoritiesNotCurrentGroup.forEach((authority) => {
            const enablesAuthorities = this.getEnablesAuthorities(authority);

            enablesAuthorities.forEach(({ entity }) => {
                if (entity === groupKey) {
                    isDisabledGroup = true;
                }
            });
        });

        return isDisabledGroup;
    }

    @computed get getCurrentAuthorities() {
        return [...(this.currentAuthorities as any)].map(([_, value]) => value);
    }

    @action resetAuthorities() {
        this.currentAuthorities?.clear();
    }

    @computed get authoritiesKeys() {
        return [...(this.currentAuthorities as any)].map(
            ([_, value]) => value.authority,
        );
    }

    @action getAuthority(name: string): AuthorityEntry {
        return (
            RolesStore.authorities.find(
                (authority) => authority.authority === name,
            ) || ({} as AuthorityListEntry)
        );
    }

    @action getAuthorities(authorityKeys: AuthorityHierarchy[]) {
        return authorityKeys.map((key) => this.getAuthority(key));
    }

    @action setGroupAuthorities(authorities: AuthorityListEntry[]) {
        authorities.forEach((authority) => {
            this.setAuthority(authority.authority);
        });
    }

    @action setInitialAuthorities(authorityKeys: AuthorityHierarchy[]) {
        const authorities = this.getAuthorities(authorityKeys);

        authorities.forEach((authority) => {
            if (authority.authority) {
                const isDependencies =
                    authorities.filter((item) => {
                        return item.enablesAuthorities
                            ?.join()
                            .includes(authority.authority || '');
                    }).length === 0;

                this.setAuthorityData(authority.authority, {
                    ...authority,
                    isTouched: isDependencies,
                });
            }
        });
    }

    @action unsetGroupAuthorities(authorities: AuthorityListEntry[]) {
        authorities.forEach(({ authority }) => {
            if (authority) {
                this.currentAuthorities?.delete(authority);
            }
        });
    }

    @action getEnablesAuthorities(current: AuthorityListEntry) {
        const enablesAuthorities: AuthorityEntry[] = [];

        const allDependencyAuthorities = (current?: AuthorityListEntry) => {
            if (current?.authority) {
                enablesAuthorities.push(current);
            }

            if (current?.enablesAuthorities?.length === 0) {
                return;
            }

            if (
                current?.enablesAuthorities &&
                current?.enablesAuthorities?.length > 0
            ) {
                current?.enablesAuthorities?.forEach((authority) => {
                    allDependencyAuthorities(this.getAuthority(authority));
                });
            }
        };

        allDependencyAuthorities(current);

        return enablesAuthorities;
    }

    @action getEnablesAuthoritiesKeys(current: AuthorityListEntry) {
        const enablesAuthorities = this.getEnablesAuthorities(current);

        return enablesAuthorities.map((aut) => aut.authority!);
    }

    @action unsetAuthority(authorityName?: AuthorityHierarchy) {
        if (authorityName) {
            const currentTouchedAuthority = this.getAuthority(authorityName);

            if (currentTouchedAuthority.authority) {
                this.currentAuthorities?.delete(
                    currentTouchedAuthority.authority,
                );
            }

            const enablesAuthorities = this.getEnablesAuthoritiesKeys(
                currentTouchedAuthority,
            );

            this.activeAuthoritiesDependencies?.forEach((authority, key) => {
                if (enablesAuthorities.includes(key)) {
                    this.setAuthorityData(key, {
                        ...authority,
                        isTouched: this.isDisabledDependentAuthority(
                            enablesAuthorities,
                            authority,
                        ),
                    });
                }
            });
        }
    }

    @action setAuthority(authorityName?: AuthorityHierarchy) {
        if (authorityName) {
            const currentTouchedAuthority = this.getAuthority(authorityName);
            const enablesAuthorities = this.getEnablesAuthoritiesKeys(
                currentTouchedAuthority,
            );
            enablesAuthorities.forEach((enableAuthority) => {
                const authority = this.getAuthority(enableAuthority);
                this.setAuthorityData(enableAuthority, {
                    ...authority,
                    isTouched:
                        enableAuthority === currentTouchedAuthority.authority,
                });
            });
        }
    }

    @action isDisabledDependentAuthority(
        enablesAuthorities: AuthorityHierarchy[],
        authority: AuthorityEntry,
    ) {
        const authorities: AuthorityHierarchy[] = [];

        this.activeAuthoritiesDependencies?.forEach((value) => {
            if (
                value?.enablesAuthorities &&
                value?.enablesAuthorities?.length > 0
            ) {
                value.enablesAuthorities.forEach((key) => {
                    authorities.push(key);
                });
            }
        });

        if (enablesAuthorities.length >= 2) {
            if (
                authority.authority &&
                authorities.includes(authority.authority)
            ) {
                return false;
            }

            if (authority?.enablesAuthorities?.length) {
                return authority?.enablesAuthorities?.length > 0;
            }
        }

        return true;
    }
}

export const RoleAuthoritiesViewModel = new RoleAuthoritiesViewModelClass();
