import { t } from 'i18next';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { ActionType, DebtCollection } from 'src/generated-api-client';
import { UpdateCollectionLevelDialog } from 'src/pages/CollectionDetailsPage/Dialogs/UpdateCollectionLevelDialog';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { UrlHelper } from 'src/utils/UrlHelper';
import {
    ActionDefinition,
    UserActionsHelper,
} from 'src/utils/UserActionsHelper';

export const getCollectionsExternalActions = (
    collectionId: number,
    collection?: DebtCollection,
): ActionDefinition[] => {
    return [...getUIActions(), ...getServerActions(collectionId, collection)];
};

const getUIActions = (): ActionDefinition[] => {
    return [];
};

const getServerActions = (
    collectionId: number,
    collection?: DebtCollection,
): ActionDefinition[] => {
    return UserActionsHelper.intersectActionDefinitionsWithActionsMeta(
        getRegisteredServerActions(collectionId, collection),
        collection?.actions,
    );
};

const getRegisteredServerActions = (
    collectionId: number,
    collection?: DebtCollection,
): ActionDefinition[] => {
    return [
        {
            key: ActionType.DEBT_COLLECTION_RECORD_ACTIVITY,
            label: t('Collection.Activity.Actions.RECORD_ACTIVITY'),
            onClick: () => {
                const targetUrl = UrlHelper.getTargetUrl(
                    RoutePaths.collectionRecordActivity,
                    {
                        collectionId,
                    },
                );

                Router.navigate(targetUrl);
            },
            disabled: !AppUser.hasPermissions(
                UserPermission.CollectionRecordActivity,
            ),
        },
        {
            key: ActionType.DEBT_COLLECTION_LEVEL_UPDATE,
            label: t(
                'Collection.Activity.Actions.DEBT_COLLECTION_LEVEL_UPDATE',
            ),
            onClick: () => {
                UpdateCollectionLevelDialog.render({
                    collectionId,
                    collection,
                });
            },
            disabled: !AppUser.hasPermissions(
                UserPermission.CollectionEditLevel,
            ),
        },
    ];
};
