/* eslint-disable max-lines */
import { Col, Row, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Card } from 'src/components/Card/Card';
import { DataView, LONG_DASH } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { IdNameEntry, PledgedLoanApplication } from 'src/generated-api-client';
import { getLocalizedValue } from 'src/utils/ObjectHelper';
import { UrlHelper } from 'src/utils/UrlHelper';

export type ApplicationDetailsGeneralInfoTabProps = {
    application?: PledgedLoanApplication;
};

export const GeneralInfoTab = observer(
    ({ application }: ApplicationDetailsGeneralInfoTabProps) => {
        const bankInformationName = getLocalizedValue(
            application?.bankInformation,
        );

        const branchManagerUrl = UrlHelper.getTargetUrl(
            RoutePaths.userDetails,
            {
                userId: application?.branchManager?.id,
            },
        );
        const relationshipManagerUrl = UrlHelper.getTargetUrl(
            RoutePaths.userDetails,
            {
                userId: application?.relationshipManager?.id,
            },
        );
        const ciaEmployeeUrl = UrlHelper.getTargetUrl(RoutePaths.userDetails, {
            userId: application?.ciaEmployee?.id,
        });
        const laaEmployeeUrl = UrlHelper.getTargetUrl(RoutePaths.userDetails, {
            userId: application?.laaEmployee?.id,
        });
        const laatLeaderUrl = UrlHelper.getTargetUrl(RoutePaths.userDetails, {
            userId: application?.laatLeader?.id,
        });
        const ccSecretaryUrl = UrlHelper.getTargetUrl(RoutePaths.userDetails, {
            userId: application?.ccSecretary?.id,
        });
        const accountantUrl = UrlHelper.getTargetUrl(RoutePaths.userDetails, {
            userId: application?.accountant?.id,
        });
        const getCurrentAssignees = (currentAssignees: IdNameEntry[]) => {
            return (
                <Space size={20}>
                    {Array.isArray(currentAssignees) && currentAssignees?.length
                        ? currentAssignees.map((user: IdNameEntry) => {
                              return (
                                  <Link
                                      to={EntityLinks.users.itemDetails(
                                          user?.id,
                                      )}
                                  >
                                      {user?.name}
                                  </Link>
                              );
                          })
                        : LONG_DASH}
                </Space>
            );
        };

        return (
            <Row gutter={16}>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.KeyEmployees.Title',
                            'Key Employees',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={8}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.KeyEmployees.Branch',
                                        'Branch',
                                    )}
                                    value={application?.branch?.name}
                                    format="string"
                                />
                            </Col>
                            <Col xs={8}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.KeyEmployees.BranchManager',
                                        'Branch Manager',
                                    )}
                                    value={application?.branchManager?.name}
                                    format="link-internal"
                                    formatterProps={{
                                        to: branchManagerUrl,
                                    }}
                                />
                            </Col>
                            <Col xs={8}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.KeyEmployees.RelationshipManager',
                                        'Relationship Manager',
                                    )}
                                    value={
                                        application?.relationshipManager?.name
                                    }
                                    format="link-internal"
                                    formatterProps={{
                                        to: relationshipManagerUrl,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={8}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.KeyEmployees.CiaEmployee',
                                        'CIA Employee',
                                    )}
                                    value={application?.ciaEmployee?.name}
                                    format="link-internal"
                                    formatterProps={{
                                        to: ciaEmployeeUrl,
                                    }}
                                />
                            </Col>
                            <Col xs={8}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.KeyEmployees.LaaEmployee',
                                        'LAA Employee',
                                    )}
                                    value={application?.laaEmployee?.name}
                                    format="link-internal"
                                    formatterProps={{
                                        to: laaEmployeeUrl,
                                    }}
                                />
                            </Col>
                            <Col xs={8}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.KeyEmployees.LaatLeader',
                                        'LAAT Leader',
                                    )}
                                    value={application?.laatLeader?.name}
                                    format="link-internal"
                                    formatterProps={{
                                        to: laatLeaderUrl,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={8}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.KeyEmployees.CcSecretary',
                                        'CC Secretary',
                                    )}
                                    value={application?.ccSecretary?.name}
                                    format="link-internal"
                                    formatterProps={{
                                        to: ccSecretaryUrl,
                                    }}
                                />
                            </Col>
                            <Col xs={8}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.KeyEmployees.Accountant',
                                        'Accountant',
                                    )}
                                    value={application?.accountant?.name}
                                    format="link-internal"
                                    formatterProps={{
                                        to: accountantUrl,
                                    }}
                                />
                            </Col>
                            <Col xs={8}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.KeyEmployees.Source',
                                        'Source',
                                    )}
                                    value={application?.source}
                                    format={(value) => (
                                        <EnumView
                                            enumName="APPLICATION_SOURCE"
                                            value={value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.KeyEmployees.CurrentAssignees',
                                        'Current Assignees',
                                    )}
                                    value={application?.currentAssignees}
                                    format={(value) =>
                                        getCurrentAssignees(value)
                                    }
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.Downpayment.Title',
                            'Downpayment / Fee',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Downpayment.FeeAmount',
                                        'Fee Amount',
                                    )}
                                    value={application?.feeAmount}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Downpayment.DownpaymentAmount',
                                        'Downpayment Amount',
                                    )}
                                    value={application?.downpaymentAmount}
                                    format="money"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Downpayment.DownpaymentPaidStatus',
                                        'Downpayment Paid Status',
                                    )}
                                    value={application?.downpaymentPaid}
                                    format="yes-no"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Downpayment.DownpaymentPercentage',
                                        'Downpayment Percentage',
                                    )}
                                    value={application?.downpaymentPercentage}
                                    format="percent"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.Downpayment.Commission',
                                        'Commission',
                                    )}
                                    value={application?.commission}
                                    format="percent"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.LoanPurpose.Title',
                            'Loan Purpose',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.LoanPurpose.LoanPurpose',
                                        'Loan Purpose',
                                    )}
                                    value={application?.loanPurpose}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.LoanPurpose.LaaLoanPurpose',
                                        'LAA Loan Purpose',
                                    )}
                                    value={getLocalizedValue(
                                        application?.laaLoanPurpose,
                                    )}
                                    format="string"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.LoanPurpose.LaaLoanSubPurpose',
                                        'LAA Loan Sub Purpose',
                                    )}
                                    value={getLocalizedValue(
                                        application?.laaLoanSubPurpose,
                                    )}
                                    format="string"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card
                        title={t(
                            'Page.PledgedLoanApplicationDetails.DisbursementInformation.Title',
                        )}
                    >
                        <Row gutter={16}>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.DisbursementDate',
                                    )}
                                    value={application?.disbursedAt}
                                    format="date-time-seconds"
                                />
                            </Col>
                            <Col xs={6}>
                                <DataView
                                    label={t(
                                        'Page.PledgedLoanApplicationDetails.DisbursementBank',
                                    )}
                                    value={
                                        bankInformationName &&
                                        application?.bankAccountNumber &&
                                        `${bankInformationName} ${application?.bankAccountNumber}`
                                    }
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    },
);
