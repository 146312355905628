import { observer } from 'mobx-react-lite';
import React from 'react';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { IdEntry } from 'src/generated-api-client';

export type CollateralDocumentsTabProps = {
    documentRoom: IdEntry;
};
export const CollateralDocumentsTab = observer(
    ({ documentRoom }: CollateralDocumentsTabProps) => {
        return <DocumentsList documentRoom={documentRoom} />;
    },
);
