import { Button, Col, Row, Space } from 'antd';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { EditIcon } from 'src/components/Icons/Icons';
import { NotFound } from 'src/components/NotFound/NotFound';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { useProductId } from 'src/hooks/useProductId';
import { ProductActions } from 'src/pages/LoanProducts/Components/ProductActions';
import { PayonProductDetailsBrief } from 'src/pages/LoanProducts/PayonProductDetailsPage/PayonProductDetailsBrief';
import { PayonProductDetailsTab } from 'src/pages/LoanProducts/PayonProductDetailsPage/PayonProductDetailsTab';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { PayonProductsStore } from 'src/stores/PayonProductsStore/PayonProductsStore';

const store = PayonProductsStore;

export const PayonProductDetailsPage = observer(() => {
    const { productId } = useProductId();

    useEffect(() => {
        store.loadItem(productId);

        return () => {
            store.itemLoader.reset();
        };
    }, [productId]);

    const payon = store.currentItem;
    const hasItem = Boolean(payon);
    const canEdit = AppUser.hasPermissions(UserPermission.ProductEdit);

    const tabs: TabDefinition[] = hasItem
        ? [
              {
                  title: t('Page.PayonProductDetails.Tabs.Details'),
                  tabKey: 'details',
                  Component: <PayonProductDetailsTab payon={payon} />,
              },
          ]
        : [];

    const error = useMemo(() => {
        const showNotFound =
            !_.isInteger(Number(productId)) ||
            store.itemLoader.errorData?.status === 404;
        if (showNotFound) {
            return (
                <NotFound
                    title={t('Page.PayonProductDetails.Error.NotFound.Title')}
                    description={t(
                        'Page.PayonProductDetails.Error.NotFound.Description',
                        { productId },
                    )}
                >
                    <Button
                        onClick={() => {
                            Router.navigate(EntityLinks.productsPayon.list());
                        }}
                    >
                        {t('Page.PayonProductDetails.GoToList')}
                    </Button>
                </NotFound>
            );
        }

        return null;
    }, [productId, store.itemLoader.errorData]);

    return (
        <BasePage
            title={
                <Row gutter={16}>
                    <Col flex={1}>
                        {t('Page.PayonProductDetails.Title', {
                            productId: payon?.productLineId,
                        })}
                    </Col>
                    <Col>
                        <Space size={16}>
                            {canEdit && (
                                <Button
                                    icon={<EditIcon />}
                                    type="primary"
                                    onClick={() => {
                                        Router.navigate(
                                            EntityLinks.productsPayon.itemEdit?.(
                                                productId,
                                            )!,
                                        );
                                    }}
                                >
                                    {t('Page.PayonProductDetails.Btn.Edit')}
                                </Button>
                            )}

                            <ProductActions product={payon} store={store} />
                        </Space>
                    </Col>
                </Row>
            }
            isLoading={store.itemLoader.isLoading}
            header={<PayonProductDetailsBrief payon={payon} />}
            showOnlyLoading={!payon}
            error={error}
        >
            <Tabs tabs={tabs} />
        </BasePage>
    );
});
