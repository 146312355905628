/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import i18next, { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Localization, N113Task } from 'src/generated-api-client';
import { ConditionCardProps } from 'src/pages/TaskViewPage/TaskTypes';

export const CustomerInformationCard = observer(
    ({ task }: ConditionCardProps<N113Task>) => {
        const {
            individual,
            bankAccountName,
            bankAccountNumber,
            bankInformation,
        } = task;
        const bankInformationName =
            bankInformation?.l10n?.[i18next.language as keyof Localization];

        return (
            <Card
                title={t(
                    'Task.Form.N113ApproveDisbursementTask.CustomerInformation.Title',
                )}
            >
                <Row gutter={16}>
                    <Col xs={6}>
                        <DataView
                            value={individual.name}
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.CustomerInformation.CustomerName',
                                'Customer name',
                            )}
                            format="link-internal"
                            formatterProps={{
                                to: EntityLinks.individuals.itemDetails(
                                    individual.id,
                                ),
                            }}
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={bankInformationName}
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.CustomerInformation.BankName',
                                'Bank name',
                            )}
                            format="string"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={bankAccountName}
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.CustomerInformation.BankAccountName',
                                'Account name',
                            )}
                            format="string"
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={bankAccountNumber}
                            label={t(
                                'Task.Form.N113ApproveDisbursementTask.CustomerInformation.BankAccountNumber',
                                'Bank account number',
                            )}
                            format="number-int"
                        />
                    </Col>
                </Row>
            </Card>
        );
    },
);
