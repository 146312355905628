/* eslint-disable max-lines */
import moment from 'moment/moment';
import { TypedPageDefinition } from 'src/components/ComponentProvider/ComponentProvider';
import {
    CollateralDetailsType,
    CollateralSubType,
    CreateCollateralRequest,
    NewCarCollateral,
    NewEquipmentCollateral,
    NewIntangibleAssetCollateral,
    NewLeasingCollateral,
    NewLivestockCollateral,
    NewRealEstateCollateral,
} from 'src/generated-api-client';
import { CreateCarCollateral } from 'src/pages/CollateralCreatePage/CarCollateral/CreateCarCollateral';
import { CreateEquipmentCollateral } from 'src/pages/CollateralCreatePage/EquipmentCollateral/CreateEquipmentCollateral';
import { CreateIntangibleAssetCollateral } from 'src/pages/CollateralCreatePage/IntangibleAssetCollateral/CreateIntangibleAssetCollateral';
import { CreateLeasingCollateral } from 'src/pages/CollateralCreatePage/LeasingCollateral/CreateLeasingCollateral';
import { CreateLivestockCollateral } from 'src/pages/CollateralCreatePage/LivestockCollateral/CreateLivestockCollateral';
import { CreateRealEstateCollateral } from 'src/pages/CollateralCreatePage/RealEstateCollateral/CreateRealEstateCollateral';
import { DateHelper } from 'src/utils/DateHelper';

type TypedCollateralPageDefinition = TypedPageDefinition & {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    convertFormValuesToSubmitValues?: (values: any) => CreateCollateralRequest;
};

type CollateralComponentsMap = {
    [key: string]: TypedCollateralPageDefinition;
};

export const CollateralCreateMap: CollateralComponentsMap = {
    // RealEstate Collateral
    [CollateralSubType.FACTORY_BUILDINGS]: {
        Component: CreateRealEstateCollateral,
        convertFormValuesToSubmitValues: (values: NewRealEstateCollateral) => {
            return convertRealEstateFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.APARTMENTS]: {
        Component: CreateRealEstateCollateral,
        convertFormValuesToSubmitValues: (values: NewRealEstateCollateral) => {
            return convertRealEstateFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.OFFICE]: {
        Component: CreateRealEstateCollateral,
        convertFormValuesToSubmitValues: (values: NewRealEstateCollateral) => {
            return convertRealEstateFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.HOUSE_AND_LAND]: {
        Component: CreateRealEstateCollateral,
        convertFormValuesToSubmitValues: (values: NewRealEstateCollateral) => {
            return convertRealEstateFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.LAND]: {
        Component: CreateRealEstateCollateral,
        convertFormValuesToSubmitValues: (values: NewRealEstateCollateral) => {
            return convertRealEstateFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.CAR_PARK]: {
        Component: CreateRealEstateCollateral,
        convertFormValuesToSubmitValues: (values: NewRealEstateCollateral) => {
            return convertRealEstateFormValuesToSubmitValues(values);
        },
    },
    // Leasing Collateral
    [CollateralSubType.LEASING_PRODUCT]: {
        Component: CreateLeasingCollateral,
        convertFormValuesToSubmitValues: (values: NewLeasingCollateral) => {
            return convertLeasingFormValuesToSubmitValues(values);
        },
    },
    // Livestock Collateral
    [CollateralSubType.LIVESTOCK]: {
        Component: CreateLivestockCollateral,
        convertFormValuesToSubmitValues: (values: NewLivestockCollateral) => {
            return convertLivestockFormValuesToSubmitValues(values);
        },
    },
    // Equipment Collateral
    [CollateralSubType.ELECTRON_PRODUCT]: {
        Component: CreateEquipmentCollateral,
        convertFormValuesToSubmitValues: (values: NewEquipmentCollateral) => {
            return convertEquipmentFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.MANUAL_EQUIPMENT]: {
        Component: CreateEquipmentCollateral,
        convertFormValuesToSubmitValues: (values: NewEquipmentCollateral) => {
            return convertEquipmentFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.FACTORY_EQUIPMENT]: {
        Component: CreateEquipmentCollateral,
        convertFormValuesToSubmitValues: (values: NewEquipmentCollateral) => {
            return convertEquipmentFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.OTHER_PRODUCT]: {
        Component: CreateEquipmentCollateral,
        convertFormValuesToSubmitValues: (values: NewEquipmentCollateral) => {
            return convertEquipmentFormValuesToSubmitValues(values);
        },
    },
    // Car Collateral
    [CollateralSubType.NEW_CAR]: {
        Component: CreateCarCollateral,
        convertFormValuesToSubmitValues: (values: NewCarCollateral) => {
            return convertCarFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.IMPORTED_CAR]: {
        Component: CreateCarCollateral,
        convertFormValuesToSubmitValues: (values: NewCarCollateral) => {
            return convertCarFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.USED_CAR]: {
        Component: CreateCarCollateral,
        convertFormValuesToSubmitValues: (values: NewCarCollateral) => {
            return convertCarFormValuesToSubmitValues(values);
        },
    },
    // Intangible Asset Collateral
    [CollateralSubType.BOND]: {
        Component: CreateIntangibleAssetCollateral,
        convertFormValuesToSubmitValues: (
            values: NewIntangibleAssetCollateral,
        ) => {
            return convertIntangibleAssetFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.STOCK]: {
        Component: CreateIntangibleAssetCollateral,
        convertFormValuesToSubmitValues: (
            values: NewIntangibleAssetCollateral,
        ) => {
            return convertIntangibleAssetFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.LICENSE]: {
        Component: CreateIntangibleAssetCollateral,
        convertFormValuesToSubmitValues: (
            values: NewIntangibleAssetCollateral,
        ) => {
            return convertIntangibleAssetFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.PHONE_NUMBER]: {
        Component: CreateIntangibleAssetCollateral,
        convertFormValuesToSubmitValues: (
            values: NewIntangibleAssetCollateral,
        ) => {
            return convertIntangibleAssetFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.TRUST_ACCOUNT]: {
        Component: CreateIntangibleAssetCollateral,
        convertFormValuesToSubmitValues: (
            values: NewIntangibleAssetCollateral,
        ) => {
            return convertIntangibleAssetFormValuesToSubmitValues(values);
        },
    },
    [CollateralSubType.AGREEMENT_FOR_GOODS_AND_SERVICES]: {
        Component: CreateIntangibleAssetCollateral,
        convertFormValuesToSubmitValues: (
            values: NewIntangibleAssetCollateral,
        ) => {
            return convertIntangibleAssetFormValuesToSubmitValues(values);
        },
    },
};

const convertRealEstateFormValuesToSubmitValues = (
    values: NewRealEstateCollateral,
) => {
    return {
        ...values,
        type: CollateralDetailsType.REAL_ESTATE,
        establishedDate: DateHelper.formatISODate(values?.establishedDate),
        registeredDate: DateHelper.formatISODate(values?.registeredDate),
        land: {
            ...values.land,
            certificateStartDate:
                values.land?.certificateStartDate &&
                DateHelper.formatISODate(values.land.certificateStartDate),
            certificateEndDates:
                values.land?.certificateEndDate &&
                DateHelper.formatISODate(values.land.certificateEndDate),
        },
    } as NewRealEstateCollateral;
};

const convertCarFormValuesToSubmitValues = (values?: NewCarCollateral) => {
    return {
        ...values,
        type: CollateralDetailsType.CAR,
        dateOfManufacture:
            values?.dateOfManufacture &&
            moment(values?.dateOfManufacture).year(),
        importedDate:
            values?.importedDate &&
            DateHelper.formatISODate(values?.importedDate),
    } as NewCarCollateral;
};

const convertEquipmentFormValuesToSubmitValues = (
    values: NewEquipmentCollateral,
) => {
    return {
        ...values,
        type: CollateralDetailsType.EQUIPMENT,
        manufacturedDate:
            values?.manufacturedDate &&
            DateHelper.formatISODate(values.manufacturedDate),
        importedDate:
            values?.importedDate &&
            DateHelper.formatISODate(values.importedDate),
    } as NewEquipmentCollateral;
};

const convertLeasingFormValuesToSubmitValues = (
    values: NewLeasingCollateral,
) => {
    return {
        ...values,
        type: CollateralDetailsType.LEASING,
    } as NewLeasingCollateral;
};

const convertLivestockFormValuesToSubmitValues = (
    values: NewLivestockCollateral,
) => {
    return {
        ...values,
        type: CollateralDetailsType.LIVESTOCK,
    } as NewLivestockCollateral;
};

const convertIntangibleAssetFormValuesToSubmitValues = (
    values: NewIntangibleAssetCollateral,
) => {
    return {
        ...values,
        type: CollateralDetailsType.INTANGIBLE_ASSET,
    } as NewIntangibleAssetCollateral;
};

export const UNCREATED_COLLATERAL_LOCAL_DATA = 'UncreatedCollateralLocalData';
