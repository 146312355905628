import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { QrCodeDialogComponent } from 'src/pages/IndividualDetailsPage/Components/QrCodeDialogComponent';
import { IndividualIncomeTypesViewModel } from 'src/pages/IndividualDetailsPage/Tabs/IndividualIncomeTypesViewModel';
import { IndividualSalaryIncomeStore } from 'src/stores/IndividualSalaryIncomeStore/IndividualSalaryIncomeStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

export type SalaryIncomeQrCodeDialogFnProps = ModalRendererProps<{
    individualId?: number;
}>;

export const SalaryIncomeQrCodeDialogFn = observer(
    ({ individualId, ...props }: SalaryIncomeQrCodeDialogFnProps) => {
        const title = t('Dialog.SalaryIncomeTypeQrCode.Title');
        const store = IndividualSalaryIncomeStore.getInstance(
            Number(individualId),
        );
        const viewModel = IndividualIncomeTypesViewModel.getInstance(
            Number(individualId),
        );

        return (
            <QrCodeDialogComponent
                title={title}
                qrLoader={store.qrLoader}
                {...props}
                onCancel={() => {
                    viewModel.reloadAfterCloseQrModal();
                    props.onCancel();
                }}
            />
        );
    },
);

export const SalaryIncomeQrCodeDialog = addModalRenderer(
    SalaryIncomeQrCodeDialogFn,
);
