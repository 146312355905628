/* eslint-disable max-lines */
import { Col, notification, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { PhoneField } from 'src/components/Form/PhoneField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { LegalRepresentativeListEntry } from 'src/generated-api-client';
import { LegalEntityDetailsViewModelClass } from 'src/pages/LegalEntityDetailsPage/LegalEntityDetailsViewModel';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';

export type UpdateRepresentativeDialogFnProps = ModalRendererProps<{
    representative: LegalRepresentativeListEntry;
    viewModel?: LegalEntityDetailsViewModelClass;
}>;

export const UpdateRepresentativeDialogFn = observer(
    ({
        representative,
        viewModel,
        ...props
    }: UpdateRepresentativeDialogFnProps) => {
        useEffect(() => {
            return () => {
                viewModel?.updateRepresentativeLoader.reset();
            };
        }, []);

        const fields = FormHelper.getFieldDataItems(
            viewModel?.updateRepresentativeLoader.errorData,
        );

        const { representativeId } = representative;
        const isExistingIndividual = !!representative?.individualId;
        const type = isExistingIndividual
            ? 'UpdateIndividualLegalRepresentative'
            : 'UpdateNonExistingIndividualLegalRepresentative';

        return (
            <Modal
                title={t('Dialog.UpdateRepresentative.Title')}
                width={1200}
                onFinish={async (values) => {
                    await viewModel?.updateRepresentative(
                        representativeId,
                        values,
                    );
                    if (viewModel?.updateRepresentativeLoader.hasError) {
                        return Promise.reject();
                    }
                    if (!viewModel?.updateRepresentativeLoader.hasError) {
                        viewModel?.load();

                        notification.success({
                            message: t(
                                'Dialog.UpdateRepresentative.Message.Success',
                            ),
                        });
                    }
                }}
                initialValues={representative}
                isLoading={viewModel?.isUpdateRepresentativeLoading}
                formProps={{
                    fields,
                }}
                {...props}
            >
                <HiddenField name="type" initialValue={type} />

                <Row gutter={16}>
                    <Col xs={6}>
                        <EnumSelectField
                            name="relationship"
                            enumName="REFERENCE_RELATIONSHIP"
                            label={t(
                                'Dialog.UpdateRepresentative.ReferenceType',
                            )}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={6}>
                        <EnumSelectField
                            name="citizenship"
                            enumName="CITIZENSHIP"
                            label={t('Dialog.UpdateRepresentative.Citizenship')}
                            required
                            rules={[ValidationRules.required]}
                            disabled={isExistingIndividual}
                        />
                    </Col>
                    <Col xs={6}>
                        <TextField
                            name="familyName"
                            label={t('Dialog.UpdateRepresentative.FamilyName')}
                            disabled={isExistingIndividual}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>

                    <Col xs={6}>
                        <TextField
                            name="lastName"
                            label={t('Dialog.UpdateRepresentative.LastName')}
                            disabled={isExistingIndividual}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={6}>
                        <TextField
                            name="firstName"
                            label={t('Dialog.UpdateRepresentative.FirstName')}
                            disabled={isExistingIndividual}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col sm={6}>
                        <TextField
                            name="registrationNumber"
                            label={t(
                                'Dialog.UpdateRepresentative.RegistrationNumber',
                            )}
                            disabled={isExistingIndividual}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col sm={6}>
                        <TextField
                            name="civilId"
                            label={t('Dialog.UpdateRepresentative.CivilId')}
                            disabled={isExistingIndividual}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                    <Col xs={6}>
                        <PhoneField
                            name="phoneNumber"
                            label={t('Dialog.UpdateRepresentative.PhoneNumber')}
                            required
                            rules={[ValidationRules.required]}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <NumberField
                            name="percentageOfShare"
                            label={t(
                                'Page.UpdateRepresentative.PercentageOfShare',
                            )}
                            required
                            rules={[ValidationRules.numberRange(0, 100)]}
                        />
                    </Col>
                    <Col xs={6}>
                        <SelectField
                            name="pep"
                            label={t('Dialog.UpdateRepresentative.Pep')}
                            options={FormHelper.objAsOptions(
                                {
                                    true: true,
                                    false: false,
                                },
                                'Primitive.Boolean.',
                            )}
                            disabled={isExistingIndividual}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const UpdateRepresentativeDialog = addModalRenderer(
    UpdateRepresentativeDialogFn,
);
