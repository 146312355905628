import { action, computed, observable } from 'mobx';
import moment from 'moment';
import {
    AutoSessionCompleteConfig,
    LOCAL_STORE_USER_LAST_ACTIVE_TIME,
} from 'src/components/AutoSessionComplete/AutoSessionComplete.config';
// eslint-disable-next-line import/no-cycle
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';

const eventTypeNames = [
    'mousemove',
    'scroll',
    'keydown',
    'click',
    'touchstart',
];

type EventSubscription = {
    event: typeof eventTypeNames[number];
    fn: () => void;
};

export class AutoSessionCompleteViewModelClass {
    @observable userLastActiveTimeMs?: number;
    @observable userLastActiveTime?: Date;
    @observable events?: EventSubscription[];
    @observable intervalId?: any;
    @observable inactiveTimeMs = 0;

    @action getLastSessionTime() {
        return LocalStoreHelper.getItem(LOCAL_STORE_USER_LAST_ACTIVE_TIME);
    }

    @action removeSessionTime() {
        return LocalStoreHelper.removeItem(LOCAL_STORE_USER_LAST_ACTIVE_TIME);
    }

    @action setCurrentSessionTime() {
        const time = new Date();
        this.userLastActiveTime = time;

        LocalStoreHelper.setItem(LOCAL_STORE_USER_LAST_ACTIVE_TIME, time);
    }

    @computed get isShowDialog() {
        return (
            AuthStore.authenticated &&
            this.inactiveTimeMs >
                SettingsStore.sessionLifeSpanMs -
                    AutoSessionCompleteConfig.timeToShowNotificationBeforeAutoLogoutSec *
                        1000
        );
    }

    @computed get isAutoLogout() {
        if (this.inactiveTimeMs) {
            return this.inactiveTimeMs > SettingsStore.sessionLifeSpanMs;
        }

        return true;
    }

    init() {
        this.setCurrentSessionTime();
        this.startAppPing();
        this.subscribeOnUserEvents();
    }

    reset() {
        this.stopAppPing();
        this.unSubscribeOnUserEvents();
        this.removeSessionTime();
    }

    pause() {
        this.stopAppPing();
        this.unSubscribeOnUserEvents();
    }

    resume() {
        if (AuthStore.authenticated) {
            this.startAppPing();
            this.subscribeOnUserEvents();
        }
    }

    subscribeOnUserEvents() {
        this.events = eventTypeNames.map((event) => {
            document.addEventListener(event, this.setCurrentSessionTime);

            return { event, fn: this.setCurrentSessionTime };
        });
    }

    unSubscribeOnUserEvents() {
        this.events?.forEach((meta) => {
            document.removeEventListener(meta.event, meta.fn);
        });
    }

    stopAppPing() {
        clearInterval(this.intervalId);
    }

    startAppPing() {
        this.intervalId = setInterval(() => {
            const lastActiveTime = this.getLastSessionTime();

            if (lastActiveTime) {
                this.inactiveTimeMs = moment().diff(
                    moment(lastActiveTime),
                    'milliseconds',
                );
                const lastActiveMs = moment().diff(
                    moment(lastActiveTime),
                    'milliseconds',
                );

                this.userLastActiveTime = lastActiveTime;
                this.userLastActiveTimeMs = lastActiveMs;
            }
        }, 500);
    }
}

export const AutoSessionCompleteViewModel =
    new AutoSessionCompleteViewModelClass();
