import { Form, FormItemProps, Switch } from 'antd';
import React from 'react';

export type SwitchFieldProps = FormItemProps & {
    name: string;
    label: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
};

export const SwitchField = ({
    name,
    label,
    disabled,
    ...props
}: SwitchFieldProps) => {
    return (
        <Form.Item {...props} name={name} valuePropName="checked" label={label}>
            <Switch disabled={disabled} />
        </Form.Item>
    );
};
