import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { NumberField } from 'src/components/Form/NumberField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    ColumnSettings,
    SettingsTable,
} from 'src/components/SettingsTable/SettingsTable';
import {
    OwnedCarEstateScoringRow,
    OwnedRealEstateScoringRow,
    SettingType,
} from 'src/generated-api-client';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { TableSettingsStoreClass } from 'src/stores/Settings/TableSettingsStoreClass';
import styled from 'styled-components';

const realEstateTableStore =
    new TableSettingsStoreClass<OwnedRealEstateScoringRow>(
        SettingType.REAL_ESTATE,
    );
const carEstateTableStore =
    new TableSettingsStoreClass<OwnedCarEstateScoringRow>(
        SettingType.CAR_ESTATE,
    );

const renderNumberInput = (valuePath: any) => {
    return (
        <StyledNumberField
            name={valuePath}
            rules={[ValidationRules.required]}
        />
    );
};

const realEstateColumnSettings: ColumnSettings[] = [
    {
        key: 'OWNED_REAL_ESTATE',
        title: (colDef) => {
            return t(
                `Page.SettingsScoring.Tabs.RealEstate.Table.OWNED_REAL_ESTATE`,
                colDef.payload,
            );
        },
        render: (data) => {
            return t(
                `Page.SettingsScoring.Tabs.RealEstate.Table.Row.OWNED_REAL_ESTATE.${data}`,
            );
        },
        width: 400,
    },
    {
        key: 'SCORES',
        title: (colDef) => {
            return t(
                'Page.SettingsScoring.Tabs.RealEstate.Table.SCORES',
                colDef.payload,
            );
        },
        renderEdit: (valuePath) => renderNumberInput(valuePath),
        editable: true,
        width: 300,
    },
];

const carEstateColumnSettings: ColumnSettings[] = [
    {
        key: 'YEARS_USED',
        title: (colDef) => {
            return t(
                'Page.SettingsScoring.Tabs.CarEstate.Table.YEARS_USED',
                colDef.payload,
            );
        },
        render: (data) => {
            return t(
                'Page.SettingsScoring.Tabs.CarEstate.Table.Row.YEARS_USED',
                {
                    context: data.to,
                    ...data,
                },
            );
        },
        width: 400,
    },
    {
        key: 'SCORES',
        title: (colDef) => {
            return t(
                'Page.SettingsScoring.Tabs.CarEstate.Table.SCORES',
                colDef.payload,
            );
        },
        renderEdit: (valuePath) => renderNumberInput(valuePath),
        editable: true,
        width: 300,
    },
];

export const SettingsScoringCapitalEvaluationTab = observer(() => {
    return (
        <>
            <SettingsTable
                tableStore={realEstateTableStore}
                columnSettings={realEstateColumnSettings}
                hasPermission={AppUser.hasPermissions(
                    UserPermission.LoanSettingsScoringEdit,
                )}
            />
            <StyledGap />
            <SettingsTable
                tableStore={carEstateTableStore}
                columnSettings={carEstateColumnSettings}
                hasPermission={AppUser.hasPermissions(
                    UserPermission.LoanSettingsScoringEdit,
                )}
            />
        </>
    );
});

const StyledGap = styled.div`
    margin-top: 50px;
`;

const StyledNumberField = styled(NumberField)`
    margin: 0;
    min-width: 60px;
`;
