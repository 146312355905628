import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { TaskStatus } from 'src/generated-api-client';

type StatusProps = {
    status?: TaskStatus;
};

export const TasksStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: TaskStatus) => {
    return (
        (status && statusInfoMap[status]) || {
            title: <EnumView enumName="TASK_STATUS" value={status} />,
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [TaskStatus.ACTIVE]: {
        title: <EnumView enumName="TASK_STATUS" value={TaskStatus.ACTIVE} />,
        color: '#54595E',
        backgroundColor: '#E0F2FF',
    },
    [TaskStatus.APPROVED]: {
        title: <EnumView enumName="TASK_STATUS" value={TaskStatus.APPROVED} />,
        color: '#0C1604',
        backgroundColor: '#D3F3BB',
    },
    [TaskStatus.COMPLETED]: {
        title: <EnumView enumName="TASK_STATUS" value={TaskStatus.COMPLETED} />,
        color: '#0C1604',
        backgroundColor: '#D3F3BB',
    },
    [TaskStatus.DECLINED]: {
        title: <EnumView enumName="TASK_STATUS" value={TaskStatus.DECLINED} />,
        color: '#F54753',
        backgroundColor: '#FFE4E4',
    },
    [TaskStatus.DELETED]: {
        title: <EnumView enumName="TASK_STATUS" value={TaskStatus.DELETED} />,
        color: '#8B939B',
        backgroundColor: '#F5F6F7',
    },
    [TaskStatus.REQUEST_ADJUSTMENTS]: {
        title: (
            <EnumView
                enumName="TASK_STATUS"
                value={TaskStatus.REQUEST_ADJUSTMENTS}
            />
        ),
        color: '#54595E',
        backgroundColor: '#E0F2FF',
    },
};
