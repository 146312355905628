/* eslint-disable camelcase */
// eslint-disable-next-line import/no-cycle
import { AppAuthStoreClass } from 'src/stores/AppAuthStore/AppAuthStore';
import { IAuthStore } from 'src/stores/AuthStore/AuthStore.types';
import { AuthStoreKeycloakClass } from 'src/stores/AuthStore/AuthStoreKeycloak';
import { UISettings } from 'src/stores/SettingsStore/SettingsStore';

const getAuthStore = (): IAuthStore => {
    return new AppAuthStoreClass();
};

export const setupAuthStore = async (uiSettings: UISettings) => {
    if (uiSettings.authentication) {
        await (AuthStore as AppAuthStoreClass).resetAuthStoreImplementation(
            new AuthStoreKeycloakClass(),
        );
    }
};
export const AuthStore = getAuthStore();
