import { Button, Col, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { HiddenField } from 'src/components/Form/HiddenField';
import { TaskForm } from 'src/components/Form/TaskForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { N112Task, TaskDecisionType } from 'src/generated-api-client';
import { TaskAssigneeSelect } from 'src/pages/TaskDetailsPage/Components/TaskAssigneeSelect';
import { TaskLinkView } from 'src/pages/TaskDetailsPage/Components/TaskLinkView';
import { TaskViewModelClass } from 'src/pages/TaskViewPage/TaskViewModel';
import styled from 'styled-components';

type N112TaskChooseAccountantUserProps = {
    viewModel: TaskViewModelClass;
};

export const N112TaskChooseAccountantUser = observer(
    ({ viewModel }: N112TaskChooseAccountantUserProps) => {
        const task = (viewModel.currentTask as N112Task) || {};
        const { loanApplication, collaterals } = task;

        return (
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <StyledText>
                        <Trans i18nKey="Task.Form.N112TaskChooseAccountantUser.Descriptions" />
                    </StyledText>
                </Col>
                <Col xs={24}>
                    <TaskLinkView
                        loanApplication={loanApplication}
                        collaterals={collaterals}
                    />
                </Col>

                <Col xs={24}>
                    <TaskForm
                        initialValues={getInitialValues(task)}
                        fields={viewModel.submitTaskErrorFields}
                        onFinish={async (values) => {
                            await viewModel.submitTask(values);
                            if (!viewModel.submitTaskLoaderHasError) {
                                Router.navigate(RoutePaths.tasks);
                            }
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Card>
                                    <HiddenField name="type" />

                                    <Col span={12}>
                                        <TaskAssigneeSelect
                                            label={t(
                                                'Task.Form.N112TaskChooseAccountantUser.AvailableMakers',
                                            )}
                                            assignees={task?.users}
                                        />
                                    </Col>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <Space size={20}>
                                    <Button
                                        type={
                                            viewModel.isCurrentTaskEqualWorkingTask
                                                ? 'primary'
                                                : 'default'
                                        }
                                        htmlType="submit"
                                    >
                                        {t('Task.Form.Btn.Claim')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            Router.navigate(RoutePaths.tasks);
                                        }}
                                    >
                                        {t('Task.Form.Btn.Back')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </TaskForm>
                </Col>
            </Row>
        );
    },
);

const getInitialValues = (data?: N112Task) => {
    return {
        ...data,
        type: TaskDecisionType.N1_12_CHOOSE_ACCOUNTANT,
    };
};

const StyledText = styled(Typography.Text)`
    display: block;
    font-size: 18px;
    color: #4d6082;
`;
